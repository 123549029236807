var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c("h3", { staticClass: "h-title" }, [_vm._v("生产任务单")]),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  ref: "searchForm",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.searchForm,
                    "status-icon": "",
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                    "label-suffix": ":",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cols" },
                    [
                      _c(
                        "el-row",
                        {
                          staticStyle: { "margin-bottom": "0" },
                          attrs: { gutter: 20 },
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "orderNo",
                                    label: "生产任务单号",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                      maxlength: "20",
                                    },
                                    model: {
                                      value: _vm.searchForm.orderNo,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.searchForm, "orderNo", $$v)
                                      },
                                      expression: "searchForm.orderNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "batchNo",
                                    label: "生产批次号",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.searchForm.batchNo,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.searchForm, "batchNo", $$v)
                                      },
                                      expression: "searchForm.batchNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "factoryCode",
                                    label: "工厂名称",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        filterable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.searchForm.factoryCode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "factoryCode",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.factoryCode",
                                      },
                                    },
                                    _vm._l(_vm.factoryList, function (item) {
                                      return _c("el-option", {
                                        key: item.pId,
                                        attrs: {
                                          label: item.factoryName,
                                          value: item.pId,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "shopCode",
                                    label: "车间名称",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                        loading: _vm.workshopListLoading,
                                      },
                                      model: {
                                        value: _vm.searchForm.shopCode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "shopCode",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.shopCode",
                                      },
                                    },
                                    _vm._l(_vm.workshopList, function (item) {
                                      return _c("el-option", {
                                        key: item.pId,
                                        attrs: {
                                          label: item.factoryName,
                                          value: item.pId,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "productLineCode",
                                    label: "车间产线",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        filterable: "",
                                        placeholder: "请选择车间产线",
                                        loading: _vm.lineListLoading,
                                      },
                                      model: {
                                        value: _vm.searchForm.productLineCode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "productLineCode",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "searchForm.productLineCode",
                                      },
                                    },
                                    _vm._l(_vm.lineList, function (item) {
                                      return _c("el-option", {
                                        key: item.pId,
                                        attrs: {
                                          label: item.factoryName,
                                          value: item.pId,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "teamCode", label: "班组" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        filterable: "",
                                        placeholder: "请选择班组",
                                        loading: _vm.teamListLoading,
                                      },
                                      model: {
                                        value: _vm.searchForm.teamCode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "teamCode",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.teamCode",
                                      },
                                    },
                                    _vm._l(_vm.teamList, function (item) {
                                      return _c("el-option", {
                                        key: item.pId,
                                        attrs: {
                                          label: item.factoryName,
                                          value: item.pId,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "materialCode",
                                    label: "产品编号",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.searchForm.materialCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "materialCode",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.materialCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.handleShow || !_vm.formConfig.btnFollow,
                                  expression:
                                    "handleShow || !formConfig.btnFollow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "materialName",
                                    label: "产品名称",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.searchForm.materialName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "materialName",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.materialName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "encryptBachNo",
                                    label: "加密生产批次",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.searchForm.encryptBachNo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "encryptBachNo",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.encryptBachNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "startTime",
                                    label: "创建起始时间",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "date",
                                      placeholder: "选择日期",
                                      "value-format": "yyyy-MM-dd",
                                    },
                                    model: {
                                      value: _vm.searchForm.startTime,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "startTime",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.startTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "endTime",
                                    label: "创建结束时间",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "date",
                                      placeholder: "选择日期",
                                      "value-format": "yyyy-MM-dd",
                                    },
                                    model: {
                                      value: _vm.searchForm.endTime,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.searchForm, "endTime", $$v)
                                      },
                                      expression: "searchForm.endTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "storehouseName",
                                    label: "库房名称",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.searchForm.storehouseName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "storehouseName",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.storehouseName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "orderSource",
                                    label: "单据来源",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.searchForm.orderSource,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "orderSource",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.orderSource",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "全部", value: "" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { label: "平台", value: 1 },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { label: "产线", value: 2 },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "uploadMode",
                                    label: "上传类型",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.searchForm.uploadMode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "uploadMode",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.uploadMode",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "全部", value: "" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { label: "按托上传", value: 0 },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { label: "整单上传", value: 1 },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "orderStatus",
                                    label: "任务单状态",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.searchForm.orderStatus,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "orderStatus",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.orderStatus",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "全部", value: "" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          label: "已审核,待下载",
                                          value: 10,
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          label: "已下载,执行中",
                                          value: 13,
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { label: "执行完成", value: 16 },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { prop: "hasScheme", label: "有活动" },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.searchForm.hasScheme,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "hasScheme",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.hasScheme",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "是", value: 1 },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { label: "否", value: 0 },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "schemeCodeName",
                                    label: "活动方案编码/名称",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.searchForm.schemeCodeName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "schemeCodeName",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.schemeCodeName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.formConfig.btnFollow
                            ? _c(
                                "el-col",
                                {
                                  staticClass: "head-btn-group",
                                  style: {
                                    width: _vm.formConfig.btnAreaHasShowMore,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { "label-width": "0" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v(" ")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.searchBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.searchBtn.icon,
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.searchForm.current = 1
                                              _vm.getList()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.searchBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.resetBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.resetBtn.icon,
                                          },
                                          on: { click: _vm.resetForm },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.resetBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              _vm.handleShow = !_vm.handleShow
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.handleShow ? "收起" : "展开"
                                              ) +
                                              "\n                "
                                          ),
                                          _c("i", {
                                            class: _vm.handleShow
                                              ? "el-icon-arrow-up"
                                              : "el-icon-arrow-down",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("el-divider", { staticClass: "btnDivider" }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btnArea" },
                [
                  _c("el-col", {
                    staticClass: "head-btn-group",
                    staticStyle: { width: "230px" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  key: _vm.tableKey,
                  staticStyle: { width: "100%" },
                  attrs: {
                    height: _vm.tabHeight,
                    data: _vm.tableData,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      align: _vm.tableConfig.align,
                      width: "60",
                      label: "序号",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "单据来源",
                      prop: "orderSource",
                      "min-width": "120",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c("div", [
                                _vm._v(
                                  _vm._s(_vm._f("sourceText")(row.orderSource))
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      245676569
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "生产任务单号",
                      prop: "orderNo",
                      "min-width": "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              row.orderNo == "" || row.orderNo == null
                                ? _c("div", [_vm._v("空")])
                                : _c("div", [_vm._v(_vm._s(row.orderNo))]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3336808157
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "生产批次",
                      prop: "batchNo",
                      width: "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              row.batchNo == "" || row.batchNo == null
                                ? _c("div", [_vm._v("空")])
                                : _c("div", [_vm._v(_vm._s(row.batchNo))]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4094510767
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品编码",
                      prop: "materialCode",
                      width: "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品名称",
                      prop: "materialName",
                      width: "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "规格",
                      prop: "productModel",
                      width: "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "包装比例",
                      prop: "packScale",
                      width: "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "库房名称",
                      prop: "storehouseName",
                      width: "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "工厂名称",
                      prop: "factoryName",
                      width: "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "车间名称",
                      prop: "shopName",
                      width: "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产线名称",
                      prop: "productLineName",
                      width: "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "班组",
                      prop: "teamName",
                      width: "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "计划数量(箱)",
                      prop: "planNum",
                      width: "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "最小单位(瓶)",
                      prop: "miniUnit",
                      width: "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "实际上传量",
                      width: "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c(
                                "el-button",
                                {
                                  staticStyle: { padding: "0" },
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.codeDetail(row.orderNo)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        row.backChestSum
                                          ? row.backChestSum + "箱"
                                          : ""
                                      ) +
                                      _vm._s(
                                        row.backBottleSum
                                          ? "(" + row.backBottleSum + "瓶)"
                                          : ""
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2676272332
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "有效生产量",
                      prop: "realProductNumOfBottle",
                      width: "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c(
                                "el-button",
                                {
                                  staticStyle: { padding: "0" },
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.codeDetail(row.orderNo)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        row.effectiveChestSum
                                          ? row.effectiveChestSum + "箱"
                                          : ""
                                      ) +
                                      _vm._s(
                                        row.effectiveBottleSum
                                          ? "(" + row.effectiveBottleSum + "瓶)"
                                          : ""
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      199239692
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "上传类型",
                      prop: "uploadMode",
                      "min-width": "100",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              row.uploadMode == 0
                                ? _c("el-tag", [_vm._v("按托上传")])
                                : _vm._e(),
                              _vm._v(" "),
                              row.uploadMode == 1
                                ? _c("el-tag", [_vm._v("整单上传")])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3547054407
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "任务单状态",
                      width: "140",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              row.orderStatus == 10
                                ? _c("el-tag", { attrs: { type: "warning" } }, [
                                    _vm._v("已审核，待下载"),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              row.orderStatus == 13
                                ? _c("el-tag", { attrs: { type: "warning" } }, [
                                    _vm._v("已下载，执行中"),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              row.orderStatus == 16
                                ? _c("el-tag", { attrs: { type: "success" } }, [
                                    _vm._v("执行完成"),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2015487112
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "关联方案",
                      width: "140",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              row.schemeAuditStatus == 0
                                ? _c("el-tag", { attrs: { type: "info" } }, [
                                    _vm._v("无"),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              row.schemeAuditStatus == 1
                                ? _c("el-tag", { attrs: { type: "warning" } }, [
                                    _vm._v("待审核"),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              row.schemeAuditStatus == 2
                                ? _c("el-tag", { attrs: { type: "success" } }, [
                                    _vm._v("审核通过"),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              row.schemeAuditStatus == 3
                                ? _c("el-tag", { attrs: { type: "danger" } }, [
                                    _vm._v("审核拒绝"),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1500170244
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "活动方案名称",
                      prop: "schemeName",
                      width: "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "创建人",
                      prop: "creatorAccount",
                      width: "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "创建时间",
                      prop: "createTime",
                      "min-width": "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _vm.hasAuth("detailbtn", _vm.$route.meta.authList) ||
                  _vm.hasAuth("updatebtn", _vm.$route.meta.authList) ||
                  _vm.hasAuth("auditbtn", _vm.$route.meta.authList)
                    ? _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          align: _vm.tableConfig.align,
                          width: "220",
                          fixed: "right",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm.hasAuth(
                                    "detailbtn",
                                    _vm.$route.meta.authList
                                  )
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.scanDetail(
                                                row,
                                                "detail"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("详情")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.hasAuth(
                                    "updatebtn",
                                    _vm.$route.meta.authList
                                  )
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.scanDetail(row, "edit")
                                            },
                                          },
                                        },
                                        [_vm._v("\n            编辑")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.hasAuth(
                                    "auditbtn",
                                    _vm.$route.meta.authList
                                  )
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            disabled: row.hasScheme == 0,
                                            type: "text",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.DoAudit(row)
                                            },
                                          },
                                        },
                                        [_vm._v("活动方案审核")]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          517497431
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  layout: "total,sizes,prev, pager, next, jumper",
                  total: _vm.total,
                  page: _vm.searchForm.current,
                  limit: _vm.searchForm.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.searchForm, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.searchForm, "size", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: _vm.type == "detail" ? "生产任务单详情" : "编辑生产任务单",
            visible: _vm.detailVisible,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.detailVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.mloading,
                  expression: "mloading",
                },
              ],
              ref: "infoItems",
              staticClass: "info-items",
              attrs: {
                model: _vm.scanData,
                "label-position": "right",
                "label-width": "150px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-descriptions",
                { attrs: { title: "任务单信息", column: 2, border: "" } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "生产任务单号" } },
                    [_vm._v(_vm._s(_vm.scanData.orderNo))]
                  ),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "任务数量" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.scanData.packScale &&
                          _vm.scanData.planNum *
                            _vm.scanData.packScale.split("*")[
                              _vm.scanData.packScale.split("*").length - 1
                            ] +
                            "瓶"
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "生产批次" } },
                    [
                      _vm.type == "edit"
                        ? _c("el-form-item", { attrs: { prop: "batchNo" } }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.scanData.batchNo,
                                  expression: "scanData.batchNo",
                                },
                              ],
                              staticStyle: {
                                "line-height": "2.15",
                                border: "1px solid #DCDFE6",
                                "border-radius": "4px",
                                color: "#606266",
                              },
                              attrs: { placeholder: "请输入内容" },
                              domProps: { value: _vm.scanData.batchNo },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.scanData,
                                    "batchNo",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.type == "detail"
                        ? _c("div", [_vm._v(_vm._s(_vm.scanData.batchNo))])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "工厂名称" } }, [
                    _vm._v(_vm._s(_vm.scanData.factoryName)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "车间名称" } }, [
                    _vm._v(_vm._s(_vm.scanData.shopName)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "产线名称" } }, [
                    _vm._v(_vm._s(_vm.scanData.productLineName)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "班组" } }, [
                    _vm._v(_vm._s(_vm.scanData.teamName)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "产品编号" } }, [
                    _vm._v(_vm._s(_vm.scanData.materialCode)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "产品名称" } }, [
                    _vm._v(_vm._s(_vm.scanData.materialName)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "包装比例描述" } },
                    [_vm._v(_vm._s(_vm.scanData.packScaleDesc))]
                  ),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "每托箱数" } }, [
                    _vm._v(_vm._s(_vm.scanData.traychestSum)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "所属库房" } }, [
                    _vm._v(_vm._s(_vm.scanData.storehouseName)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "上传类型" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.scanData.uploadMode == 0
                          ? "按托上传"
                          : _vm.scanData.uploadMode == 1
                          ? "按单上传"
                          : ""
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "任务单状态" } },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.scanData.orderStatus == 10
                            ? "已审核，待下载"
                            : _vm.scanData.orderStatus == 13
                            ? "已下载，执行中"
                            : _vm.scanData.orderStatus == 16
                            ? "执行完成"
                            : ""
                        )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "创建人" } }, [
                    _vm._v(_vm._s(_vm.scanData.creatorAccount)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "创建时间" } }, [
                    _vm._v(_vm._s(_vm.scanData.createTime)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "审核人" } }, [
                    _vm._v(_vm._s(_vm.scanData.schemeAuditUserName)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "审核原因" } }, [
                    _vm._v(_vm._s(_vm.scanData.schemeAuditReason)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "审核时间" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.scanData.schemeAuditTime &&
                          new Date(
                            _vm.scanData.schemeAuditTime
                          ).toLocaleString()
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "单据来源" } }, [
                    _vm._v(
                      _vm._s(_vm._f("sourceText")(_vm.scanData.orderSource))
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "负责人" } }, [
                    _vm._v(_vm._s(_vm.scanData.director)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "产地" } }, [
                    _vm._v(_vm._s(_vm.scanData.originPlace)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "备注" } }, [
                    _vm._v(_vm._s(_vm.scanData.remarks)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "生产日期" } }, [
                    _vm._v(_vm._s(_vm.scanData.productTime)),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-descriptions",
                {
                  staticStyle: { "margin-top": "20px" },
                  attrs: { title: "实际生产量", column: 2, border: "" },
                },
                [
                  _c("el-descriptions-item", { attrs: { label: "任务数量" } }, [
                    _vm._v(_vm._s(_vm.scanData.planNum + "箱")),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "实际上传量" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { padding: "0" },
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.codeDetail(_vm.scanData.orderNo)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.scanData.backChestSum
                                  ? _vm.scanData.backChestSum + "箱"
                                  : ""
                              ) +
                              _vm._s(
                                _vm.scanData.backBottleSum
                                  ? "(" + _vm.scanData.backBottleSum + "瓶)"
                                  : ""
                              ) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "有效生产量" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { padding: "0" },
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.codeDetail(_vm.scanData.orderNo)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.scanData.effectiveChestSum
                                  ? _vm.scanData.effectiveChestSum + "箱"
                                  : ""
                              ) +
                              _vm._s(
                                _vm.scanData.effectiveBottleSum
                                  ? "(" +
                                      _vm.scanData.effectiveBottleSum +
                                      "瓶)"
                                  : ""
                              ) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "异常数码量" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { padding: "0" },
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.showFailList()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.scanData.unusualSum
                                  ? _vm.scanData.unusualSum + "个"
                                  : ""
                              ) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "实际入库量" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { padding: "0" },
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.codeDetail(_vm.scanData.orderNo)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.scanData.inboundChestSum
                                  ? _vm.scanData.inboundChestSum + "箱"
                                  : ""
                              ) +
                              _vm._s(
                                _vm.scanData.inboundBottleSum
                                  ? "(" + _vm.scanData.inboundBottleSum + "瓶)"
                                  : ""
                              ) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-descriptions",
                {
                  staticStyle: { "margin-top": "20px" },
                  attrs: { title: "营销活动", column: 2, border: "" },
                },
                [
                  _c("el-descriptions-item", { attrs: { label: "有活动" } }, [
                    _vm._v(_vm._s(_vm.scanData.hasScheme == 1 ? "是" : "否")),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "活动方案" } },
                    [
                      _vm.type == "edit"
                        ? _c(
                            "el-input",
                            {
                              attrs: { readonly: "" },
                              on: { focus: _vm.openScheme },
                              model: {
                                value: _vm.scanData.schemeName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.scanData, "schemeName", $$v)
                                },
                                expression: "scanData.schemeName",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-edit",
                                staticStyle: {
                                  "font-size": "20px",
                                  display: "inline-block",
                                  "line-height": "2",
                                },
                                attrs: { slot: "suffix" },
                                on: { click: _vm.openScheme },
                                slot: "suffix",
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.type == "detail"
                        ? _c("div", [_vm._v(_vm._s(_vm.scanData.schemeName))])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "活动审核结果" } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.scanData.schemeAuditStatus == 0
                              ? "无"
                              : _vm.scanData.schemeAuditStatus == 1
                              ? "待审核"
                              : _vm.scanData.schemeAuditStatus == 2
                              ? "审核通过"
                              : _vm.scanData.schemeAuditStatus == 3
                              ? "审核拒绝"
                              : ""
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.type == "edit"
                    ? _c(
                        "el-descriptions-item",
                        { attrs: { label: "审核原因" } },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.scanData.schemeAuditReason) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.type == "edit"
                    ? _c(
                        "el-descriptions-item",
                        { attrs: { label: "活动审核人" } },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.scanData.schemeAuditUserName) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.type == "edit"
                    ? _c(
                        "el-descriptions-item",
                        { attrs: { label: "活动审核时间" } },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.scanData.schemeAuditTime &&
                                  new Date(
                                    _vm.scanData.schemeAuditTime
                                  ).toLocaleString()
                              ) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.type == "edit"
                ? _c("el-button", { on: { click: _vm.submitForm } }, [
                    _vm._v("确定"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("关闭"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: "活动审核",
            visible: _vm.auditVisible,
            "before-close": _vm.handleClose,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.auditVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "auditForm",
              attrs: {
                model: _vm.scanData,
                "label-width": "120px",
                "label-suffix": ":",
                rules: _vm.auditRule,
              },
            },
            [
              _c(
                "el-descriptions",
                {
                  staticClass: "check-result",
                  attrs: { title: "活动审核", column: 1 },
                },
                [
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        label: "活动审核结果",
                        prop: "schemeAuditStatus",
                      },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.scanData.schemeAuditStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.scanData, "schemeAuditStatus", $$v)
                            },
                            expression: "scanData.schemeAuditStatus",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("待审核"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("审核通过"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 3 } }, [
                            _vm._v("审核拒绝"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "审核原因", prop: "schemeAuditReason" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          maxlength: "500",
                          autosize: { minRows: 4 },
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.scanData.schemeAuditReason,
                          callback: function ($$v) {
                            _vm.$set(_vm.scanData, "schemeAuditReason", $$v)
                          },
                          expression: "scanData.schemeAuditReason",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.audit } },
                [_vm._v("提交")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("关 闭"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: "数码详情",
            visible: _vm.codeVisible,
            top: "3%",
            width: "70%",
            "before-close": _vm.codeClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.codeVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.codeListLoading,
                      expression: "codeListLoading",
                    },
                  ],
                  key: _vm.tableKey,
                  staticStyle: { width: "100%" },
                  attrs: {
                    "max-height": "500",
                    data: _vm.codeList,
                    "tooltip-effect": "dark",
                    border: "",
                    fit: "",
                    "highlight-current-row": "",
                    "row-key": "markCode",
                    lazy: "",
                    load: _vm.load,
                    "tree-props": _vm.treeProps,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      width: "80",
                      label: "序号",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [_c("span", [_vm._v(_vm._s(scope.row.seqNo))])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "层级",
                      prop: "id",
                      align: "left",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm._f("packLevel")(scope.row.packLevel))
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "数码",
                      align: "center",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.markCode))]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.codetotal > 0,
                    expression: "codetotal > 0",
                  },
                ],
                attrs: {
                  layout: "total,sizes,prev, pager, next, jumper",
                  total: _vm.codetotal,
                  page: _vm.detailForm.current,
                  limit: _vm.detailForm.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.detailForm, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.detailForm, "size", $event)
                  },
                  pagination: _vm.getCodeList,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.codeClose } }, [
                _vm._v("关闭"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: "异常数码明细",
            visible: _vm.failVisible,
            "before-close": _vm.failClose,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.failVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.codeListLoading,
                      expression: "codeListLoading",
                    },
                  ],
                  key: _vm.tableKey,
                  staticStyle: { width: "100%" },
                  attrs: {
                    height: "400",
                    data: _vm.failList,
                    "tooltip-effect": "dark",
                    border: "",
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      width: "50",
                      label: "序号",
                      align: "center",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "数码", prop: "id", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.markCode))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "层级", prop: "id", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm._f("packLevel")(scope.row.packLevel))
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "解析状态", prop: "id", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", { staticStyle: { color: "#E6A23C" } }, [
                              _vm._v(_vm._s(scope.row.failReason)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.failtotal > 0,
                    expression: "failtotal > 0",
                  },
                ],
                attrs: {
                  layout: "total,sizes,prev, pager, next, jumper",
                  total: _vm.failtotal,
                  page: _vm.failQuery.current,
                  limit: _vm.failQuery.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.failQuery, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.failQuery, "size", $event)
                  },
                  pagination: _vm.getFList,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.failClose } }, [
                _vm._v("关闭"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.schemeDialogVisible
        ? _c("schemeDialog", {
            attrs: {
              visible: _vm.schemeDialogVisible,
              "selected-data": _vm.scheme,
            },
            on: {
              close: function ($event) {
                _vm.schemeDialogVisible = false
              },
              changeS: _vm.changeScheme,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }