"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _unlockPrizeRule = require("@/api/market/unlockPrizeRule");
var _externalGamesConfig = require("@/api/market/externalGamesConfig");
var _creat = _interopRequireDefault(require("./components/creat"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManageexternalGamesConfiggameListindex';
var _default = {
  name: 'MarketingManageexternalGamesConfiggameListindex',
  components: {
    Pagination: _Pagination.default,
    creat: _creat.default
  },
  filters: {
    filterButtonStatus: function filterButtonStatus(status) {
      return status === '1' ? '停用' : '启用';
    },
    filterUnlockType: function filterUnlockType(status) {
      return status === '1' ? '按次布奖' : '倍数布奖';
    }
  },
  data: function data() {
    return {
      editType: '',
      creatVisible: false,
      searchForm: {
        pageNo: 1,
        pageSize: 20,
        gameName: '',
        matchId: ''
      },
      total: 0,
      tableData: [],
      listLoading: false
    };
  },
  activated: function activated() {
    this.queryForPage();
  },
  deactivated: function deactivated() {
    var name = cachName;
    var cache = this.getCaches(this, name);
    if (!cache) {
      this.tableData = [];
      this.total = 0;
    }
  },
  methods: {
    // 获取表格
    queryForPage: function queryForPage() {
      var _this = this;
      this.listLoading = true;
      (0, _externalGamesConfig.getGameList)(this.searchForm, this.searchForm.pageNo, this.searchForm.pageSize).then(function (res) {
        if (res.data.data.errCode) {
          _this.$message.error(res.data.data.errMsg);
          return;
        } else {
          _this.listLoading = false;
          _this.tableData = res.data.data.records;
          _this.total = res.data.data.total;
        }
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    // 创建解锁奖品规则
    creat: function creat() {
      this.editType = 'add';
      this.creatVisible = true;
    },
    closeCreat: function closeCreat() {
      this.creatVisible = false;
      this.queryForPage();
    },
    // 搜索
    search: function search() {
      this.searchForm.pageNo = 1;
      this.queryForPage();
    },
    // 重置
    reset: function reset() {
      this.$refs['searchForm'].resetFields();
      this.searchForm = {
        pageNo: 1,
        pageSize: 20
      };
      this.queryForPage();
    }
  }
};
exports.default = _default;