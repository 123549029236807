"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
var _factory = require("@/api/basic/factory");
var _store = require("@/api/store");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// var miniUnit = ''
var _default = {
  name: 'TableSuply',
  inject: ['reload'],
  components: {},
  filters: {},
  data: function data() {
    return {
      title: '审核',
      billType: '',
      outComName: '',
      inComName: '',
      inUseName: '',
      tableKey: 'pId',
      listLoading: false,
      factoryShow: false,
      justShow: false,
      mloading: false,
      dialogVisible: false,
      btn_loading: false,
      proShow: false,
      isUpdate: true,
      submitType: 1,
      scanData: {
        state: '',
        remark: '',
        scheduleNos: ''
      },
      detailinfo: {},
      orgList: [],
      storeList: [],
      productList: [],
      auditType: '',
      rules: {
        state: [{
          required: true,
          message: '审核结果不能为空，请维护',
          trigger: 'change'
        }]
      }
    };
  },
  mounted: function mounted() {
    var that = this;
  },
  methods: {
    show: function show(row, type) {
      var _this = this;
      this.auditType = type;
      if (row.auditStatus == '0') {
        this.scanData.state = '';
      } else {
        this.scanData.state = row.auditStatus;
      }
      if (row.auditRemark) {
        this.scanData.remark = row.auditRemark || '';
      }
      if (type == 'Y') {
        this.title = '批量审核';
        var arr = [];
        this.scanData.scheduleNos = row.forEach(function (el) {
          arr.push(el.scheduleNo);
        });
        this.scanData.scheduleNos = arr.toString();
      } else if (type == 'N') {
        this.title = '审核';
        this.scanData.scheduleNos = row.scheduleNo;
        this.detailinfo = JSON.parse(JSON.stringify(row));
        if (row.shopCodes) {
          this.detailinfo.shopCodes = row.shopCodes.split(',');
        }
        (0, _factory.getWorkshopByFactoryCode)({
          factoryCode: row.factoryCode
        }).then(function (response) {
          debugger;
          if (response.data.code != 200) {
            _this.$message.error(response.data.msg);
            return;
          } else {
            if (response.data.data != null) {
              _this.storeList = response.data.data;
            } else {
              _this.storeList = [];
            }
          }
        });
      }
      this.mloading = true;
      if (this.$refs['scanForm'] !== undefined) {
        this.$refs['scanForm'].resetFields();
      }
      this.dialogVisible = true;
      this.mloading = false;
    },
    submitForm: function submitForm(temp) {
      var _this2 = this;
      this.$refs['scanForm'].validate(function (valid) {
        if (valid) {
          _this2.btn_loading = true;
          (0, _store.auditno)(_this2.scanData).then(function (res) {
            _this2.btn_loading = false;
            if (res.data.code != 200) {
              _this2.$message.error(res.data.msg);
            } else {
              _this2.$message({
                message: '保存成功',
                type: 'success'
              });
              _this2.handleClose();
              _this2.reload();
            }
          }).catch(function () {
            _this2.btn_loading = false;
          });
        }
      });
    },
    handleClose: function handleClose() {
      this.authId('searchbtn', this.$route.meta.authList);
      this.dialogVisible = false;
      this.$parent.getList();
    }
  }
};
exports.default = _default;