"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _ordergl = require("@/api/warehouse/ordergl");
var _param = require("@/api/chanelmange/param");
var _dictionary = require("@/api/systems/dictionary");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var TypeDataComs = {
  orderStatus: []
};
var _default = {
  name: 'Order',
  filters: {
    auditNodeName: function auditNodeName(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          2: '确款',
          1: '预审'
        };
        return statusMap[value];
      }
    },
    payWayText: function payWayText(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          2: '先货后款',
          1: '先款后货'
        };
        return statusMap[value];
      }
    },
    orderStatusText: function orderStatusText(value) {
      var name = '';
      TypeDataComs.orderStatus.forEach(function (c) {
        if (c.figure == value) {
          name = c.name;
          return;
        }
      });
      return name;
    },
    chnMoney: function chnMoney(n) {
      if (!/^(0|[1-9]\d*)(\.\d+)?$/.test(n) || n == 0.0) return '';
      var unit = '千百拾亿千百拾万千百拾元角分';
      var str = '';
      n = String((n * 1).toFixed(2));
      var p = n.indexOf('.');
      if (p >= 0) n = n.substring(0, p) + n.substr(p + 1, 2);
      unit = unit.substr(unit.length - n.length);
      for (var i = 0; i < n.length; i++) {
        str += '零壹贰叁肆伍陆柒捌玖'.charAt(n.charAt(i)) + unit.charAt(i);
      }
      return str.replace(/零(千|百|拾|角)/g, '零').replace(/(零)+/g, '零').replace(/零(万|亿|元)/g, '$1').replace(/(亿)万|壹(拾)/g, '$1$2').replace(/^元零?|零分/g, '').replace(/元$/g, '元整');
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      activeName: '',
      payWay: [{
        name: '先款后货',
        code: 1
      }, {
        name: '先货后款',
        code: 2
      }],
      orderStatus: [],
      // 发货单位所属组织id
      sendComId: '',
      auditLoading: false,
      productList: [],
      zpList: [],
      auditList: [],
      addData: {
        pid: '',
        orderType: 2,
        orderNo: '',
        placeOrderDate: '',
        paymentStyle: '',
        purchaseComId: '',
        purchaseComType: 3,
        applyUser: '',
        receiveComId: '',
        receiveComType: '',
        receiveContacts: '',
        receivePhone: '',
        receiveAddress: '',
        sendOutComId: '',
        sendOutComType: '',
        orderStatus: 1,
        remark: '',
        // 产品列表
        orderDetailDTOS: []
      },
      auditRule: {
        handlerResult: [{
          required: true,
          message: '请选择审核结果',
          trigger: 'change'
        }]
      },
      loading: false,
      auditForm: {
        pId: this.id,
        handlerResult: '',
        remark: ''
      }
    };
  },
  computed: {
    totalInUse: {
      get: function get() {
        var totalSumAll = 0;
        this.zpList.forEach(function (item) {
          console.log(item, 999);
          if (!isNaN(item.totalPrice)) {
            totalSumAll += Number(item.totalPrice);
          }
        });
        totalSumAll = totalSumAll.toFixed(2);
        if (isNaN(totalSumAll)) {
          return 0;
        }
        return totalSumAll;
      },
      set: function set(val) {
        this.totalInUse = val;
      }
    }
  },
  mounted: function mounted() {
    this.getDirSel('CCXSDDZT', 'orderStatus');
    this.getDetailData(this.id);
  },
  methods: {
    getDirSel: function getDirSel(code, list) {
      var _this = this;
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: code
      }).then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this[list] = res.data.data;
            TypeDataComs[list] = res.data.data;
          } else {
            _this[list] = [];
            TypeDataComs[list] = [];
          }
        }
      });
    },
    toogleExpand: function toogleExpand(idx) {
      var $table = this.$refs.addTable;
      for (var i = 0; i < this.productList.length; i++) {
        if (idx != i) {
          $table.toggleRowExpansion(this.productList[i], false);
        }
      }
      $table.toggleRowExpansion(this.productList[idx]);
    },
    getFL: function getFL(val) {
      var _this2 = this;
      if (this.addData.placeOrderDate && this.addData.sendOutComId || this.productList.length) {
        var param = {
          newTime: this.addData.placeOrderDate,
          orgList: [this.addData.sendOutComId],
          productName: '',
          schemeState: 2,
          schemeType: 0
        };
        var _loop = function _loop(i) {
          param.productName = _this2.productList[i].productName;
          (0, _param.getFlHdList)(1, 2147483647, param).then(function (res) {
            if (res.data.code == 200) {
              _this2.productList[i].flList = res.data.data.records || [];
              if (!val) _this2.productList[i].rebatePolicyId = '';
            } else {
              _this2.$message.error(res.data.msg);
              _this2.productList[i].flList = [];
            }
          });
        };
        for (var i = 0; i < this.productList.length; i++) {
          _loop(i);
        }
      }
    },
    // 获取详情数据
    getDetailData: function getDetailData(id) {
      var _this3 = this;
      this.loading = true;
      (0, _ordergl.queryById)(id).then(function (res) {
        if (res.data.code == 200) {
          var data = res.data.data;
          var proArr = [];
          var zpArr = [];
          _this3.addData = res.data.data;
          data.orderDetailDTOS.forEach(function (item) {
            var obj = {
              pId: item.productId,
              productCode: item.productCode,
              productName: item.productName,
              productModel: item.productSpec,
              packScaleExpression: item.packRaidoExpress,
              prodUnit: item.prodUnit,
              chestNum: item.chestNum,
              bottleNum: item.bottleNum,
              totalPrice: item.totalPrice,
              productPrice: item.prodPrice,
              isGift: item.isGift,
              extractNum: item.extractNum,
              hasTakeNum: item.extractNum,
              rebatePolicyId: item.rebatePolicyId,
              orderGiftDetailDTOS: item.orderGiftDetailDTOS
            };
            if (obj.rebatePolicyId) {
              var buyNum = 0;
              (0, _param.getRebateSchemeConfig)({
                pid: obj.rebatePolicyId
              }).then(function (res) {
                if (res.data.code == 200) {
                  var data = res.data.data;
                  if (data && data.length) {
                    buyNum = data[0].buyNum;
                    for (var i = 0; i < obj.orderGiftDetailDTOS.length; i++) {
                      obj.orderGiftDetailDTOS[i].buyNum = buyNum;
                    }
                  }
                } else {
                  that.$message.error('获取返利配置失败，请重试！');
                }
              });
            }
            if (item.isUseCoupon) {
              obj.rebatePrice = item.prodPrice;
              zpArr.push(obj);
            } else {
              proArr.push(obj);
            }
          });
          _this3.productList = proArr;
          _this3.zpList = zpArr;
          if (_this3.addData.receiveComType == 3) {
            _this3.getFL(1);
          }
          _this3.sendComId = data.sendOutComId;
        } else {
          _this3.$message.error(res.data.msg);
        }
        _this3.loading = false;
      }).catch(function () {
        _this3.loading = false;
      }).then(function () {
        if (_this3.addData.orderStatus > 1) {
          _this3.auditLoading = true;
          (0, _ordergl.getAuditLog)({
            pId: id
          }).then(function (res) {
            if (res.data.code == 200) {
              _this3.auditList = res.data.data;
            } else {
              that.$message.error('审核日志获取失败');
            }
            _this3.auditLoading = false;
          }).catch(function () {
            _this3.auditLoading = false;
          });
        }
      });
    },
    handleClose: function handleClose() {
      this.$emit('close');
    },
    change: function change() {
      this.$emit('change');
    },
    financial: function financial() {
      var _this4 = this;
      this.$refs['auditForm'].validate(function (valid) {
        if (valid) {
          _this4.loading = true;
          _this4.dsate = true;
          (0, _ordergl.orderConfirm)(_this4.auditForm).then(function (res) {
            if (res.data.code == 200) {
              _this4.$message({
                type: 'success',
                message: '审核完成'
              });
              _this4.change();
            } else {
              _this4.$message.error(res.data.msg);
            }
            _this4.loading = false;
            _this4.dsate = false;
          }).catch(function () {
            _this4.loading = false;
            _this4.dsate = false;
          });
        } else {
          _this4.$message.error('请完善信息！');
        }
      });
    }
  }
};
exports.default = _default;