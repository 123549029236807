"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _param = require("@/api/chanelmange/param");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: '',
  components: {},
  props: {
    policyCode: String,
    detailVisible: Boolean
  },
  data: function data() {
    return {
      listQuery: {
        current: 1,
        policyCode: null
      },
      list: {},
      listLoading: false
    };
  },
  watch: {
    detailVisible: function detailVisible(val) {
      if (val) {
        this.listQuery.policyCode = this.policyCode;
        this.getList();
      }
    }
  },
  methods: {
    getList: function getList() {
      var that = this;
      (0, _param.getFlZcList)(that.listQuery).then(function (res) {
        if (res.data.code == 200) {
          that.list = res.data.data.records[0];
        } else {
          that.$message.error(res.data.msg);
          return;
        }
      }).catch(function () {});
    },
    handleClose: function handleClose() {
      var that = this;
      that.$emit('close');
    }
  }
};
exports.default = _default;