"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _factory = require("@/api/basic/factory");
var _storeHouse = require("@/api/basic/storeHouse");
var _pack = require("@/api/basic/pack");
var _utils = require("@/utils");
var _basic = require("@/api/mark/basic");
var _select = require("@/api/warehouse/select");
var _flowHead = _interopRequireDefault(require("../../../flowHead"));
var _flowLog = _interopRequireDefault(require("../../../flowLog"));
var _productList = _interopRequireDefault(require("../../../productList"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var miniUnit = '';
var TypeDataComs = {
  status: [],
  source: [],
  factory: []
};
var _default = {
  name: 'table-suply',
  components: {
    flowHead: _flowHead.default,
    flowLog: _flowLog.default,
    productList: _productList.default
  },
  props: {},
  filters: {
    formatDate: function formatDate(time) {
      var date = new Date(time);
      return (0, _basic.formatDate)(date, 'yyyy-MM-dd hh:mm:ss');
    },
    TypeDataCom: function TypeDataCom(code, keyName) {
      var vars = TypeDataComs[keyName];
      var name = '';
      vars.forEach(function (c) {
        if (c.code == code) {
          name = c.name;
          return;
        }
      });
      return name;
    }
  },
  data: function data() {
    return {
      num: '',
      active: 0,
      detailVisible: false,
      mloading: false,
      listLoading: false,
      scanData: {},
      temp: {},
      flowList: [],
      settype: 2,
      showflow: false,
      TypeDataComs: {
        status: [],
        source: [],
        factory: []
      }
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var that = this;
  },
  methods: {
    /**
     * settype 1:拆单，2：查看，3：审核
     */
    show: function show(rows, settype) {
      this.settype = settype;
      this.showflow = false;
      if (settype == 3) {
        this.showflow = true;
      }
      this.temp = rows;
      this.detailVisible = true;
      this.mloading = true;
      this.num = rows.num;
      this.mloading = false;
      this.getstoreTypeInfoList(7, 'status');
      TypeDataComs = this.TypeDataComs;
    },
    getstoreTypeInfoList: function getstoreTypeInfoList(type, keyName) {
      // 4:单据来源，7：单据状态，103：工厂代码
      var that = this;
      (0, _select.storeTypeInfoList)(type).then(function (res) {
        if (res.data.code != 200) {
          that.TypeDataComs[keyName] = [];
          return;
        } else {
          that.TypeDataComs[keyName] = res.data.data;
        }
      }).catch(function () {
        that.TypeDataComs[keyName] = [];
      });
    },
    handleClose: function handleClose() {
      this.detailVisible = false;
    }
  }
};
exports.default = _default;