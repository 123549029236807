"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _storeHouse = require("@/api/basic/storeHouse");
var _lithService = require("@/api/lithService");
var _index = require("@/api/warehouse/checkout/index");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _printDialog = _interopRequireDefault(require("@/components/printDialog"));
var _qrcodejs = _interopRequireDefault(require("qrcodejs2"));
var _basic = require("@/api/mark/basic");
var _utils = require("@/utils");
var _insert = _interopRequireDefault(require("./components/insert"));
var _details_flow = _interopRequireDefault(require("./components/details_flow"));
var _select = require("@/api/warehouse/select");
var _auth = require("@/utils/auth");
var _productSelectDialog = _interopRequireDefault(require("@/components/productSelectDialog"));
var _allotUnitSelect = _interopRequireDefault(require("@/components/allotUnitSelect"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { factoryInfoAllList } from '@/api/basic/factory'

// import { storeTypeInfoList } from '@/api/warehouse/select'
// import { formatDate, getKeysetVal } from '@/api/mark/basic'
// import { getLevelById, supportBoxLevelList } from '@/api/basic/pack'
// var miniUnit = ''
var qrcode = '';
var TypeDataComs = {
  status: [],
  source: []
};
var _default = {
  name: 'WarehouseServicewarehousingproductionproduction',
  components: {
    Pagination: _Pagination.default,
    // QRCode,
    detailsflow: _details_flow.default,
    insert: _insert.default,
    printDialog: _printDialog.default,
    productDialog: _productSelectDialog.default,
    allotUnitSelect: _allotUnitSelect.default
  },
  filters: {
    filterStatus: function filterStatus(code) {
      var vars = TypeDataComs['status'];
      var name = '';
      for (var i = 0; i < vars.length; i++) {
        if (vars[i].figure == code) {
          name = vars[i].name;
          break;
        }
      }
      return name;
    },
    formatDate: function formatDate(time) {
      var date = new Date(time);
      return (0, _basic.formatDate)(date, 'yyyy-MM-dd hh:mm:ss');
    },
    filterSource: function filterSource(code) {
      var vars = TypeDataComs['source'];
      var name = '';
      for (var i = 0; i < vars.length; i++) {
        if (vars[i].figure == code) {
          name = vars[i].name;
          break;
        }
      }
      return name;
    },
    tagStatus: function tagStatus(val) {
      var status = {
        1: '',
        2: '',
        3: '',
        4: 'success',
        5: 'success',
        6: 'success',
        7: 'success',
        8: 'warning',
        9: 'danger',
        10: 'warning',
        11: 'warning',
        12: 'danger',
        13: 'success'
      };
      return status[val];
    }
  },
  data: function data() {
    return {
      productCode: '',
      TypeDataCom: {
        statusList: [],
        StoreList: [],
        factoryList: [],
        sourceList: []
      },
      dateValue: [],
      productName: '',
      inOrgName: '',
      showCol: false,
      expandTxt: '展开',
      unitShow: false,
      unitType: '',
      shouFaType: '1',
      inOutType: '4',
      billType: 101,
      tableData: [],
      searchForm: {
        current: 1,
        size: 20
      },
      uploadVisible: false,
      uploadData: [],
      uploadForm: {
        templateId: '',
        uploadFile: '',
        batchNo: '',
        remark: ''
      },
      myHeaders: {
        Authorization: 'Bearer ' + (0, _auth.getToken)()
      },
      uploadRules: {
        templateId: [{
          required: true,
          message: '模板信息不能为空，请维护',
          trigger: 'change'
        }],
        batchNo: [{
          required: true,
          message: '批次号不能为空，请维护',
          trigger: 'blur'
        }],
        uploadFile: [{
          required: true,
          message: '文件不能为空，请维护',
          trigger: 'blur'
        }]
      },
      fileList: [],
      tradeBillQuery: {
        batchNo: '',
        materialNo: '',
        remark: '',
        templateName: '',
        startTime: '',
        endTime: ''
      },
      tableKey: 'rn',
      total: 0,
      codetotal: 0,
      listLoading: false,
      downLoading: false,
      page2: false,
      multipleSelection: [],
      tabHeight: '100%',
      submitType: '',
      TypeDataComs: {
        status: [],
        source: []
      },
      code_title: '二维码查看',
      codeVisible: false,
      btnLoading: false,
      billNo: '',
      typeNum: 2,
      printShow: false,
      pShow: false,
      muList: []
    };
  },
  computed: {
    // 设置上传地址
    uploadUrl: function uploadUrl(e) {
      return process.env.VUE_APP_BASE_API + '/api-mark/rawMaterialOrder/importExcel?batchNo=' + e.uploadForm.batchNo + '&remark=' + e.uploadForm.remark + '&templateId=' + e.uploadForm.templateId;
    }
  },
  mounted: function mounted() {
    var that = this;
    var h = window.innerHeight - 410 + 'px';
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    this.getList();
    TypeDataComs = this.TypeDataComs;
  },
  methods: {
    // 重置
    resetForm: function resetForm(formName) {
      this.searchForm.current = 1;
      this.searchForm.size = 10;
      this.$refs['searchForm'].resetFields();
      this.getList();
    },
    // 查询条件
    handleFilter: function handleFilter(_type) {
      if (_type == 'rest') {
        // 搜索表单重置
        this.searchForm.current = 1;
        this.searchForm.size = 20;
        this.tradeBillQuery = {
          batchNo: '',
          materialNo: '',
          remark: '',
          templateName: '',
          startTime: '',
          endTime: ''
        };
        this.dateValue = [];
        this.$refs['tradeBillQuery'].resetFields();
      }
      if (this.dateValue.length > 0) {
        this.tradeBillQuery.startTime = this.dateValue[0];
        this.tradeBillQuery.endTime = this.dateValue[1];
      } else {
        this.tradeBillQuery.startTime = '';
        this.tradeBillQuery.endTime = '';
      }
      this.getList();
    },
    // searchList(data, data2) {
    //   this.searchForm = data
    //   this.tradeBillQuery = data2
    //   this.getList()
    // },
    getList: function getList() {
      var _this = this;
      this.authId('searchbtn', this.$route.meta.authList);
      var that = this;
      this.listLoading = true;
      (0, _lithService.queryPage)(this.searchForm.current, this.searchForm.size, this.tradeBillQuery).then(function (response) {
        if (response.data.code != 200) {
          _this.listLoading = false;
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.tableData = response.data.data.records;
            that.total = parseInt(response.data.data.total);
            setTimeout(function () {
              that.listLoading = false;
            }, 1 * 500);
          } else {
            that.tableData = [];
            that.total = 0;
          }
        }
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    // 添加
    add: function add() {
      this.$refs.insertMain.show(1, this.billType);
    },
    submitUpload: function submitUpload() {
      var _this2 = this;
      this.$refs['uploadForm'].validate(function (valid) {
        if (valid) {
          _this2.btnLoading = true;
          _this2.uploadData = [];
          delete _this2.uploadForm.remark;
          delete _this2.uploadForm.batchNo;
          delete _this2.uploadForm.templateId;
          _this2.$refs.upload.submit();
        } else {
          _this2.btnLoading = false;
          _this2.$message.error('请完善资料');
        }
      });
    },
    uploadFileRes: function uploadFileRes(res, file, fileList) {
      if (res.code == 2004) {
        this.$message.error(res.msg);
        this.uploadData = res.data;
      } else if (res.code != 200) {
        this.$message.error(res.msg);
      } else {
        this.btnLoading = false;
        this.$message({
          type: 'success',
          message: '导入成功!'
        });
        this.uploadClose();
        this.getList();
      }
      this.btnLoading = false;
      this.fileList = [];
      this.clearFiles();
    },
    clearFiles: function clearFiles() {
      this.uploadForm = {
        remark: '',
        batchNo: '',
        templateId: '',
        uploadFile: ''
      };
    },
    uploadClose: function uploadClose() {
      this.uploadForm.uploadFile = '';
      this.$refs.uploadForm.resetFields();
      this.uploadVisible = false;
      this.authId('searchbtn', this.$route.meta.authList);
    },
    selectFile: function selectFile(file, fileList) {
      if (!file.response) {
        this.fileList = fileList;
        this.uploadForm.uploadFile = file.name;
      } else {
        this.fileList = [];
        this.uploadForm.uploadFile = '';
      }
      // this.uploadForm.uploadFile = file.name
    },
    imgUploadSuccess: function imgUploadSuccess(res, file, fileList) {
      if (res.code != 200) {
        this.$message.error(res.msg);
      }
    },
    uploadShow: function uploadShow() {
      var _this3 = this;
      this.uploadVisible = true;
      (0, _lithService.enableTemplate)({}).then(function (res) {
        if (res.data.code !== 200) {
          _this3.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            _this3.muList = res.data.data.map(function (el) {
              return {
                label: el.templateCode + ' ' + el.templateLevel + '级' + el.templateName,
                value: el.pid
              };
            });
          } else {
            _this3.muList = [];
          }
        }
      });
    },
    uploadFileFail: function uploadFileFail(res, file, fileList) {
      this.$message.error('上传失败请重试');
      this.btnLoading = false;
    },
    // 导出功能
    download: function download(row) {
      var _this4 = this;
      this.authId('exportbtn', this.$route.meta.authList);
      this.downLoading = true;
      (0, _lithService.searchMenuDataDetail)({
        pId: row.pid
      }).then(function (res) {
        _this4.authId('searchbtn', _this4.$route.meta.authList);
        if (res.status != 200) {
          _this4.$message.error(res.data.msg);
        } else {
          var blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel'
          });
          var downloadElement = document.createElement('a');
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = '下载数码.xls';
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
        _this4.downLoading = false;
      }).catch(function () {
        _this4.authId('searchbtn', _this4.$route.meta.authList);
        _this4.downLoading = false;
      });
    },
    // 查看详情
    getflow: function getflow(row, qx) {
      this.authId('detailbtn', this.$route.meta.authList);
      this.$refs.flowMain.show(row, qx);
    },
    // 编辑
    doUpdate: function doUpdate(row) {
      this.$refs.insertMain.upDateShow(row);
    },
    // 删除
    del: function del(row) {
      var _this5 = this;
      console.log(row);
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _index.InfoDel)(row.pid).then(function (res) {
          var data = res.data;
          if (data.code != 200) {
            _this5.$message.error(data.msg);
            return false;
          }
          _this5.$message({
            message: res.data.msg,
            type: 'success'
          });
          _this5.getList();
        });
      }).catch(function () {
        _this5.authId('searchbtn', _this5.$route.meta.authList);
      });
    },
    // 二维码功能
    getQrCode: function getQrCode(row) {
      this.authId('qrcodebtn', this.$route.meta.authList);
      this.codeVisible = true;
      this.$nextTick(function () {
        if (qrcode) {
          document.getElementById('qrcode').innerHTML = '';
        }
        qrcode = new _qrcodejs.default(this.$refs.qrCodeUrl, {
          text: row.billNo,
          // 需要转换为二维码的内容
          width: 200,
          height: 200,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: _qrcodejs.default.CorrectLevel.H
        });
      });
    },
    // 关闭二维码
    handleCodeClose: function handleCodeClose() {
      this.codeVisible = false;
      this.authId('searchbtn', this.$route.meta.authList);
    },
    // 打印
    printTb: function printTb(row) {
      this.authId('printbtn', this.$route.meta.authList);
      var that = this;
      that.billNo = row.billNo;
      that.typeNum = 2;
      that.pShow = true;
      that.printShow = true;
    },
    // 关闭打印
    printClose: function printClose() {
      var that = this;
      that.billNum = '';
      that.pShow = false;
      that.printShow = false;
      this.authId('searchbtn', this.$route.meta.authList);
    },
    // 获取单据状态，单据来源
    getstoreTypeInfoList: function getstoreTypeInfoList(code) {
      var _this6 = this;
      var param = {};
      param.code = code;
      this.TypeDataComs.status = [];
      this.TypeDataComs.source = [];
      (0, _select.getDicItem)(param).then(function (res) {
        if (res.data.code != 200) {
          _this6.$message.error(res.data.msg);
          return;
        } else {
          if (param.code == 'DJZT') {
            _this6.TypeDataComs.status = res.data.data;
          }
          if (param.code == 'DJLY') {
            _this6.TypeDataComs.source = res.data.data;
          }
          TypeDataComs = _this6.TypeDataComs;
        }
      }).catch(function () {});
    },
    // 获取产品
    openProductWin: function openProductWin(e) {
      this.$refs.selProduct.proSelVisible = true;
    },
    clearProduct: function clearProduct(e) {
      this.productName = '';
      this.tradeBillQuery.productId = '';
    },
    setSel: function setSel(val) {
      this.productName = val.productName;
      this.tradeBillQuery.productId = val.pId;
    },
    getFactoryInfoList: function getFactoryInfoList() {
      var _this7 = this;
      // 获取工厂下啦列表数据
      this.TypeDataCom.factoryList = [];
      (0, _select.factoryInfoList)({
        current: 1,
        size: 211111111,
        isEnable: 1,
        type: 1
      }).then(function (response) {
        if (response.data.code != 200) {
          _this7.$message.error(response.data.msg);
          return;
        } else {
          _this7.TypeDataCom.factoryList = response.data.data.records;
        }
      }).catch(function () {});
    },
    getStoreList: function getStoreList() {
      var _this8 = this;
      // 获取库房下拉列表数据
      this.TypeDataCom.StoreList = [];
      (0, _storeHouse.storeHouseInfoList)().then(function (res) {
        if (res.data.code != 200) {
          return;
        } else {
          _this8.TypeDataCom.StoreList = res.data.data;
        }
      }).catch(function () {});
    },
    getStatusList: function getStatusList() {
      var _this9 = this;
      // 获取单据状态
      this.TypeDataCom.statusList = [];
      var param = {};
      param.code = 'DJZT';
      (0, _select.getDicItem)(param).then(function (res) {
        if (res.data.code != 200) {
          _this9.$message.error(res.data.msg);
          return;
        } else {
          _this9.TypeDataCom.statusList = res.data.data;
        }
      }).catch(function () {});
    },
    getSourceList: function getSourceList() {
      var _this10 = this;
      // 获取单据来源
      this.TypeDataCom.sourceList = [];
      var param = {};
      param.code = 'DJLY';
      (0, _select.getDicItem)(param).then(function (res) {
        if (res.data.code != 200) {
          _this10.$message.error(res.data.msg);
          return;
        } else {
          _this10.TypeDataCom.sourceList = res.data.data;
        }
      }).catch(function () {});
    },
    // 展开收起搜索条件
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    },
    // 获取收货单位
    clearInOrg: function clearInOrg() {
      var that = this;
      that.inOrgName = '';
      that.tradeBillQuery.billProdQuery.inOrgId = '';
      that.tradeBillQuery.billProdQuery.inStoreId = '';
      that.TypeDataCom.StoreList = [];
    },
    getInUnit: function getInUnit(arg) {
      var that = this;
      that.unitType = arg;
      that.unitShow = true;
    },
    closeUnit: function closeUnit() {
      var that = this;
      that.unitShow = false;
    },
    fetchUnit: function fetchUnit(val) {
      var that = this;
      var type = that.unitType;
      if (type == 'in') {
        that.inOrgName = val.companyName;
        that.tradeBillQuery.billProdQuery.inOrgId = val.companyId;
        that.tradeBillQuery.billProdQuery.inStoreId = '';
        that.getHouses(val.companyId);
      }
    },
    // 获取关联库房
    getHouses: function getHouses(arg) {
      var that = this;
      var param = {
        orgOrFactoryId: arg
      };
      (0, _select.getRelateStore)(param).then(function (res) {
        if (res.data.code == 200) {
          that.TypeDataCom.StoreList = [];
          that.TypeDataCom.StoreList = res.data.data;
        }
      });
    },
    // 获取焦点关联库房
    getDownHouses: function getDownHouses() {
      var that = this;
      if (that.tradeBillQuery.billProdQuery.inOrgId == '') {
        that.TypeDataCom.StoreList = [];
        that.$message.error('请选择收货单位');
        return;
      }
    }
  }
};
exports.default = _default;