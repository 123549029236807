var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "hyyy-container" },
    [
      _c(
        "el-card",
        [
          _c("div", { staticClass: "hyyy-top-tit" }, [
            _c("div", { staticClass: "hyyy-top-tit-lift" }, [
              _c("span", {
                staticStyle: { "font-weight": "400", color: "#333" },
                domProps: { textContent: _vm._s(_vm.$route.meta.title) },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "hyyy-top-tit-right" }),
          ]),
          _vm._v(" "),
          _c("el-divider", { staticStyle: { margin: "0" } }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "hyyy-content" },
            [
              _c(
                "el-row",
                { attrs: { type: "flex", justify: "center" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form",
                        { attrs: { model: _vm.form, "label-width": "120px" } },
                        [
                          _c("h4", [_vm._v("小程序信息")]),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "小程序Appid" } },
                            [
                              _c("el-input", {
                                staticClass: "xcxinp",
                                model: {
                                  value: _vm.form.appId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "appId", $$v)
                                  },
                                  expression: "form.appId",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "AppSecret" } },
                            [
                              _c("el-input", {
                                staticClass: "xcxinp",
                                model: {
                                  value: _vm.form.appSecret,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "appSecret", $$v)
                                  },
                                  expression: "form.appSecret",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("h4", [_vm._v("成为会员条件")]),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "必选条件：" } },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    color: "#ccc",
                                    "lne-height": "40px",
                                    "line-height": "40px",
                                  },
                                },
                                [_vm._v("姓名、手机号验证、性别、生日")]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-link",
                            {
                              staticClass: "zdytj",
                              attrs: { type: "primary" },
                              on: { click: _vm.addbtn },
                            },
                            [_vm._v("自定义添加")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 22, offset: 1 } },
                                _vm._l(_vm.tjTags, function (tag, index) {
                                  return _c(
                                    "el-tag",
                                    {
                                      key: tag,
                                      staticClass: "tags",
                                      attrs: {
                                        closable: "",
                                        "disable-transitions": false,
                                      },
                                      on: {
                                        close: function ($event) {
                                          return _vm.handleClosetj(tag, index)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(tag) +
                                          "\n                "
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-dialog",
                            {
                              attrs: {
                                title: "添加信息项",
                                visible: _vm.addcustomdia,
                                width: "30%",
                                "before-close": _vm.handleClose,
                              },
                              on: {
                                "update:visible": function ($event) {
                                  _vm.addcustomdia = $event
                                },
                              },
                            },
                            [
                              _c(
                                "el-form",
                                {
                                  attrs: {
                                    model: _vm.customdata,
                                    "label-width": "120px",
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "名称" } },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "200px" },
                                        attrs: {
                                          maxlength: "8",
                                          "show-word-limit": "",
                                        },
                                        model: {
                                          value: _vm.customdata.name,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.customdata,
                                              "name",
                                              $$v
                                            )
                                          },
                                          expression: "customdata.name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "类型" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: { placeholder: "请选择" },
                                          model: {
                                            value: _vm.customdata.type,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.customdata,
                                                "type",
                                                $$v
                                              )
                                            },
                                            expression: "customdata.type",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "文本",
                                              value: "1",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "dialog-footer",
                                  attrs: { slot: "footer" },
                                  slot: "footer",
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      on: {
                                        click: function ($event) {
                                          _vm.addcustomdia = false
                                        },
                                      },
                                    },
                                    [_vm._v("取 消")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addtj("1")
                                        },
                                      },
                                    },
                                    [_vm._v("确 定")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("h4", [_vm._v("会员过期规则")]),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { "label-width": "34px" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.form.isRule,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "isRule", $$v)
                                    },
                                    expression: "form.isRule",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: "1" } }, [
                                    _vm._v("永久有效"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: "2" } }, [
                                    _vm._v("领取后\n                  "),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.form.isRule == "2",
                                            expression: "form.isRule == '2'",
                                          },
                                        ],
                                        staticClass: "cwhy",
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.form.invalidDay,
                                              expression: "form.invalidDay",
                                            },
                                          ],
                                          ref: "inputss",
                                          staticStyle: { width: "50px" },
                                          attrs: {
                                            placeholder: "请输入",
                                            type: "text",
                                          },
                                          domProps: {
                                            value: _vm.form.invalidDay,
                                          },
                                          on: {
                                            input: [
                                              function ($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.form,
                                                  "invalidDay",
                                                  $event.target.value
                                                )
                                              },
                                              _vm.handleInput,
                                            ],
                                          },
                                        }),
                                        _vm._v("天内有效\n                  "),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: "3" } }, [
                                    _vm._v("至\n                  "),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.form.isRule == "3",
                                            expression: "form.isRule == '3'",
                                          },
                                        ],
                                        staticClass: "cwhy",
                                      },
                                      [
                                        _c("el-date-picker", {
                                          attrs: {
                                            type: "date",
                                            placeholder: "选择日期时间",
                                            "picker-options": _vm.pickerOptions,
                                            "value-format": "yyyy-MM-dd",
                                          },
                                          model: {
                                            value: _vm.form.invalidDateStr,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "invalidDateStr",
                                                $$v
                                              )
                                            },
                                            expression: "form.invalidDateStr",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: {
                                width: "950px",
                                display: "flex",
                                "justify-content": "flex-end",
                              },
                            },
                            [
                              _c("el-button", { on: { click: _vm.upbtn } }, [
                                _vm._v("取消"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onbtn(_vm.form)
                                    },
                                  },
                                },
                                [_vm._v("保存")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }