"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _globalRuleConfig = require("@/api/generalSettings2/globalRuleConfig");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      codeTable: {
        ruleCode: 'dayLotteryNumLimit' // 全局规则code
      },

      ruleData: {
        upperLimit: 200,
        // 默认 10
        status: '0' // 默认启用
      },

      globalRule: {
        upperLimit: [{
          required: true,
          message: '请输入限制数量',
          trigger: 'blur'
        }]
      },
      saveLoad: false
    };
  },
  activated: function activated() {
    this.getCashUpperLimit();
  },
  deactivated: function deactivated() {},
  methods: {
    getCashUpperLimit: function getCashUpperLimit() {
      var _this = this;
      (0, _globalRuleConfig.getCashUpperLimit)().then(function (res) {
        console.log(res);
        if (res.data && res.data.data.errCode) {
          return _this.$message.error(res.data.data.errMsg);
        } else {
          if (res.data && res.data.data) {
            _this.ruleData.upperLimit = res.data.data.data.upperLimit;
            _this.ruleData.status = res.data.data.data.status;
          }
        }
      });
    },
    handleSave: function handleSave(name) {
      var that = this;
      that.$refs[name].validate(function (valid) {
        if (valid) {
          that.saveLoad = true;
          (0, _globalRuleConfig.cashUpperLimit)(that.ruleData).then(function (res) {
            console.log(res.data);
            if (res.data.data.errCode) {
              that.saveLoad = false;
              return that.$message.error(res.data.data.errMsg);
            } else {
              if (res.data.data) {
                that.$message.success('更新成功!');
                that.saveLoad = false;
                that.getCashUpperLimit();
              }
            }
          });
        }
      });
    },
    hanleReset: function hanleReset(name) {
      this.$refs[name].resetFields();
    }
  }
};
exports.default = _default;