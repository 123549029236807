"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _order = require("@/api/order");
var _dictionary = require("@/api/systems/dictionary");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var TypeDataComs = {
  orderStatus: []
};
var _default = {
  name: 'OrderCentersaleorderCctake',
  filters: {
    typeClass: function typeClass(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          0: 'success',
          1: 'success',
          2: 'success',
          3: 'success',
          4: 'success',
          5: 'danger',
          6: 'danger',
          7: 'warning',
          8: 'primary',
          9: 'primary'
        };
        return statusMap[value];
      }
    },
    orderType: function orderType(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          1: '买赠订单',
          2: '酒券订单',
          3: '团购订单',
          4: '费用酒订单',
          5: '陈列酒订单'
        };
        return statusMap[value];
      }
    },
    auditNodeName: function auditNodeName(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          2: '确款',
          1: '预审'
        };
        return statusMap[value];
      }
    },
    orderStatusText: function orderStatusText(value) {
      var name = '';
      TypeDataComs.orderStatus.forEach(function (c) {
        if (c.figure == value) {
          name = c.name;
          return;
        }
      });
      return name;
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      cardPadding: {
        padding: '0 0 20px'
      },
      btncardPadding: {
        padding: '0'
      },
      orderStatus: [],
      // 发货单位所属组织id
      sendComId: '',
      productList: [],
      storeList: [],
      addData: {
        pid: '',
        orderType: 2,
        orderNo: '',
        placeOrderDate: '',
        paymentStyle: '',
        purchaseComId: '',
        purchaseComType: 3,
        applyUser: '',
        receiveComId: '',
        receiveComType: '',
        receiveContacts: '',
        receivePhone: '',
        receiveAddress: '',
        sendOutComId: '',
        sendOutComType: '',
        orderStatus: 1,
        remark: '',
        // 产品列表
        orderDetailDTOS: []
      },
      loading: false
    };
  },
  mounted: function mounted() {
    var _this = this;
    if (this.$route.query.id) {
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: 'CCXSDDZT'
      }).then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this.orderStatus = res.data.data;
            TypeDataComs['orderStatus'] = res.data.data;
          } else {
            _this.orderStatus = [];
            TypeDataComs['orderStatus'] = [];
          }
        }
      }).then(function () {
        _this.getDetailData(_this.$route.query.id);
      });
    } else {
      this.$router.replace({
        name: 'orderCentersaleorder-ccindex'
      });
    }
  },
  methods: {
    getDirSel: function getDirSel(code, list) {
      var _this2 = this;
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: code
      }).then(function (res) {
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this2[list] = res.data.data;
            TypeDataComs[list] = res.data.data;
          } else {
            _this2[list] = [];
            TypeDataComs[list] = [];
          }
        }
      });
    },
    tableRowClassName: function tableRowClassName(_ref) {
      var row = _ref.row,
        rowIndex = _ref.rowIndex;
      if (row.pickedChestNum == row.totalBottleNum) {
        return 'warning-row';
      }
      return '';
    },
    maxNumX: function maxNumX(row) {
      var num = 0;
      var hasTakeNum = row.pickedChestNum || 0;
      var outNum = row.storeInventory || 0;
      num = row.chestNum - hasTakeNum;
      if (row.isComProd == 2) {
        return num;
      } else {
        return Math.min(num, outNum);
      }
    },
    mixNumP: function mixNumP(row) {
      var num = row.takeChestNum || 0;
      var arr = row.packScaleExpression.split('*');
      return num * arr[arr.length - 1];
    },
    maxNumP: function maxNumP(row) {
      var arr = row.packScaleExpression.split('*');
      var num = 0;
      var hasTakeNum = row.pickedBottleNum || 0;
      var outNum = row.storeInventory * arr[arr.length - 1] || 0;
      num = row.totalBottleNum - hasTakeNum;
      if (row.isComProd == 2) {
        return num;
      } else {
        return Math.min(num, outNum);
      }
    },
    takeGoodNumChange: function takeGoodNumChange(currentValue, oldValue, row) {
      var arr = row.packScaleExpression.split('*');
      row.takeBottleNum = currentValue * arr[arr.length - 1];
    },
    getStoreList: function getStoreList(pid) {
      var _this3 = this;
      (0, _order.byOrgFactoryId)({
        orgOrFactoryId: pid
      }).then(function (res) {
        if (res.data.code != 200) {
          _this3.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this3.storeList = res.data.data;
          } else {
            _this3.storeList = [];
          }
        }
      });
    },
    setStore: function setStore(val) {
      var _this4 = this;
      var _loop = function _loop(i) {
        (0, _order.queryInventByStoreIdAndProdId)({
          productIds: [_this4.productList[i].pId],
          storeId: val
        }).then(function (res) {
          if (res.data.code == 200) {
            _this4.productList[i].storeInventory = res.data.data && res.data.data.length ? res.data.data[0].chestInventory : 0;
            _this4.productList[i].takeBottleNum = '';
            _this4.productList[i].takeChestNum = '';
          } else {
            _this4.$message.error(res.data.msg);
          }
          _this4.resetList();
        }).catch(function () {
          _this4.productList[i].storeInventory = 0;
          _this4.productList[i].takeBottleNum = '';
          _this4.productList[i].takeChestNum = '';
          _this4.resetList();
        });
      };
      for (var i = 0; i < this.productList.length; i++) {
        _loop(i);
      }
    },
    resetList: function resetList() {
      this.productList = JSON.parse(JSON.stringify(this.productList));
    },
    // 获取详情数据
    getDetailData: function getDetailData(id) {
      var _this5 = this;
      this.loading = true;
      (0, _order.queryTakeByOrderNo)(id).then(function (res) {
        console.log(res, 'res');
        if (res.data.code == 200) {
          var data = res.data.data;
          var proArr = [];
          _this5.addData = res.data.data;
          data.takeOrderProductVOS.forEach(function (item) {
            var obj = {
              pId: item.productId,
              productCode: item.productCode,
              productName: item.productName,
              productModel: item.productModel,
              packScaleExpression: item.packScaleExpression,
              chestNum: item.chestNum,
              totalBottleNum: item.totalBottleNum,
              // extractNum: item.extractNum,
              pickedChestNum: item.pickedChestNum,
              pickedBottleNum: item.pickedBottleNum,
              isComProd: item.isSupportCode
            };
            if (item.packScaleExpression == '1*1' || item.packScaleExpression == '1*1*1') {
              obj.pickedChestNum = obj.pickedBottleNum;
            }
            if (obj.totalBottleNum != obj.pickedBottleNum) {
              proArr.unshift(obj);
            } else {
              proArr.push(obj);
            }
          });
          _this5.productList = proArr;
          _this5.addData.sendOutStoreId = '';
          _this5.getStoreList(data.sendOrgId);
          _this5.sendComId = data.sendOrgId;
        } else {
          _this5.$message.error(res.data.msg);
        }
        _this5.loading = false;
      }).catch(function () {
        _this5.loading = false;
      });
      // .then(() => {
      //   if (this.addData.orderStatus > 1) {
      //     this.auditLoading = true
      //     getAuditLog({ pId: id })
      //       .then(res => {
      //         if (res.data.code == 200) {
      //           this.auditList = res.data.data
      //         } else {
      //           that.$message.error('审核日志获取失败')
      //         }
      //         this.auditLoading = false
      //       })
      //       .catch(() => {
      //         this.auditLoading = false
      //       })
      //   }
      // })
    },
    handleClose: function handleClose() {
      this.$router.replace({
        name: 'orderCentersaleorder-ccindex'
      });
    },
    change: function change() {
      this.$emit('change');
    },
    take: function take() {
      var _this6 = this;
      this.loading = true;
      var arr = [];
      var sum = 0;
      if (!this.addData.sendOutStoreId) {
        this.$message.error('请选择发货库房');
        this.loading = false;
        return;
      }
      console.log(this.productList.length, 'this.productList.length');
      for (var i = 0; i < this.productList.length; i++) {
        this.productList[i].takeBottleNum = this.productList[i].takeBottleNum || 0;
        sum += this.productList[i].takeBottleNum;
        if (this.productList[i].takeBottleNum) {
          var pickedBottleNum = this.productList[i].pickedBottleNum || 0;
          var pickedChestNum = this.productList[i].pickedChestNum || 0;
          var takeChestNum = this.productList[i].takeChestNum || 0;
          var obj = {
            chestNum: this.productList[i].chestNum,
            pickedBottleNum: this.productList[i].takeBottleNum * 1 + pickedBottleNum * 1,
            pickedChestNum: takeChestNum * 1 + pickedChestNum * 1,
            productId: this.productList[i].pId,
            takeBottleNum: this.productList[i].takeBottleNum,
            takeChestNum: takeChestNum,
            totalBottleNum: this.productList[i].totalBottleNum,
            orderNo: this.addData.orderNo,
            storeId: this.addData.sendOutStoreId
          };
          arr.push(obj);
        }
      }
      console.log(arr, 'this.productList.arr');
      if (sum == 0) {
        this.$message.error('产品提货量不能全部为0');
        this.loading = false;
        return;
      }
      // return
      (0, _order.generateTakeOrder)(arr).then(function (res) {
        _this6.loading = false;
        if (res.data.code != 200) {
          _this6.$message.error(res.data.msg);
          return;
        }
        _this6.$message({
          type: 'success',
          message: '提货成功'
        });
        _this6.handleClose();
      }).catch(function () {
        _this6.loading = false;
      });
    }
  }
};
exports.default = _default;