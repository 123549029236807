"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.function.name");
var _compyuser = require("@/api/systems/compyuser");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'ScanAuth',
  components: {
    Pagination: _Pagination.default,
    Treeselect: _vueTreeselect.default
  },
  props: {
    dataAuth: Boolean,
    names: Object
  },
  data: function data() {
    return {
      activeName: 'first',
      dialogVisible: false,
      mloading: false,
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      treeData: [],
      storeData: [],
      orgIds: '',
      houseIds: [],
      tableParam: {
        storeHouseName: '',
        orgIds: []
      },
      listQuery: {
        current: 1,
        size: 10
      },
      total: 0,
      dstate: false,
      storeLoading: false,
      choseData: [],
      orgs: []
    };
  },
  watch: {
    dataAuth: function dataAuth(val) {
      var that = this;
      if (val == true) {
        that.dialogVisible = true;
        that.orgs = [];
        that.storeData = [];
        that.activeName = 'first';
        that.treeData = that.$parent.options;
        that.listQuery.current = 1;
        that.listQuery.size = 10;
        that.tableParam.storeHouseName = '';
      } else {
        that.$parent.authDataShow = false;
        that.dialogVisible = false;
      }
    }
  },
  methods: {
    handleClick: function handleClick(tab, event) {
      var that = this;
      if (tab.name == 'second') {
        that.storeData = [];
        that.choseData = [];
        that.tableParam.orgIds = that.orgs;
        if (that.tableParam.orgIds.length == 0) {
          that.storeData = [];
          that.choseData = [];
          that.$message.error('请选择组织');
          return;
        } else {
          that.getStoreData(that.listQuery, that.tableParam);
        }
      }
    },
    handleClose: function handleClose() {
      var that = this;
      that.$parent.authDataShow = false;
      that.orgs = [];
      that.storeData = [];
      that.dialogVisible = false;
    },
    serachStore: function serachStore() {
      var that = this;
      var param = that.tableParam;
      this.listQuery.current = 1;
      that.getKcTableData(that.listQuery, param);
    },
    getStoreData: function getStoreData() {
      var that = this;
      var param = that.tableParam;
      var query = that.listQuery;
      that.getKcTableData(query, param);
    },
    getKcTableData: function getKcTableData(query, param) {
      var that = this;
      (0, _compyuser.getStoreTable)(query, param).then(function (res) {
        if (res.data.code == 200) {
          that.storeData = res.data.data.records;
          that.total = parseInt(res.data.data.total);
        } else {
          that.storeData = [];
          that.$message.error(res.data.msg);
          return;
        }
      });
    },
    handleSelectStore: function handleSelectStore(val) {
      var that = this;
      that.choseData = val;
    },
    submitForm: function submitForm() {
      var that = this;
      var data = {};
      data.userIds = that.$parent.ids.split(',');
      data.orgIds = that.orgs;
      var storData = that.choseData;
      var store = [];
      if (storData.length > 0) {
        storData.forEach(function (item) {
          store.push(item.houseId);
        });
        data.houseIds = store;
      } else {
        data.houseIds = [];
      }
      that.storeLoading = true;
      that.dsate = true;
      (0, _compyuser.rankDataAuth)(data).then(function (res) {
        if (res.data.code == 200) {
          that.storeLoading = false;
          that.dsate = false;
          that.$message({
            type: 'success',
            message: '数据权限分配成功！'
          });
          that.choseData = [];
          that.$parent.ids = '';
          that.handleClose();
          that.$parent.getList();
        } else {
          that.storeLoading = false;
          that.dsate = false;
          that.$message.error(res.data.msg);
        }
      }).catch(function () {
        that.storeLoading = false;
        that.dsate = false;
      });
    },
    resetForm: function resetForm() {
      var that = this;
      that.handleClose();
    }
  }
};
exports.default = _default;