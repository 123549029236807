"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _externalGamesConfig = require("@/api/market/externalGamesConfig");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "creat",
  props: {
    dialogVisible: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      sourceList: [],
      form: {
        gameName: '',
        matchId: '',
        remark: ''
      },
      rules: {
        gameName: [{
          required: true,
          message: "请输入赛事名称",
          trigger: "change"
        }],
        matchId: [{
          required: true,
          message: '请输入赛事ID',
          trigger: 'change'
        }],
        remark: [{
          required: true,
          message: "请输入备注",
          trigger: "change"
        }]
      },
      btnLoading: false
    };
  },
  computed: {
    isDisable: function isDisable() {
      return this.title == '详情' ? true : false;
    }
  },
  mounted: function mounted() {},
  methods: {
    handleSave: function handleSave() {
      var _this = this;
      this.$refs["form"].validate(function (valid) {
        if (valid) {
          _this.btnLoading = true;
          (0, _externalGamesConfig.gameListItemSave)(_this.form).then(function (res) {
            if (res.data.data.errCode) {
              _this.btnLoading = false;
              return _this.$message.error(res.data.data.errMsg);
            } else {
              _this.btnLoading = false;
              _this.$message.success('新增成功');
              _this.$emit("close");
            }
          });
        }
      });
    },
    handleCancel: function handleCancel() {
      this.$emit("close");
    }
  }
};
exports.default = _default;