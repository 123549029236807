"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.constructor");
require("core-js/modules/es6.regexp.replace");
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _typeof2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
var _utils = require("@/utils");
var _user = require("@/api/user");
var _basic = require("@/api/mark/basic");
var _typeConfig = require("@/api/mark/configure/typeConfig");
var _labelGroupManage = require("@/api/mark/configure/labelGroupManage");
var _urlManage = require("@/api/mark/configure/urlManage");
var _waves = _interopRequireDefault(require("@/directive/waves"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var codeTypeList = [];
var _default = {
  name: 'MarkconfigurelabelGroupManageindex',
  components: {
    Pagination: _Pagination.default
  },
  directives: {
    waves: _waves.default
  },
  filters: {
    isVerification: function isVerification(status) {
      if (!String(status)) return '';
      var statusMap = {
        0: '否',
        1: '是'
      };
      return statusMap[status];
    },
    isHadSerno: function isHadSerno(status) {
      if (!String(status)) return '';
      var statusMap = {
        0: '否',
        1: '带顺序号',
        2: '顺序号+父级顺序号'
      };
      return statusMap[status];
    },
    relationType: function relationType(status) {
      if (!String(status)) return '';
      var statusMap = {
        1: '套标',
        2: '后关联'
      };
      return statusMap[status];
    },
    codeType: function codeType(status) {
      if (!String(status)) return '';
      var statusMap = {
        1: '营销码',
        2: '防伪码',
        3: '物流码'
      };
      return statusMap[status];
    },
    packType: function packType(status) {
      if (!String(status)) return '';
      var statusMap = {
        1: '盖层级',
        2: '瓶层级',
        3: '盒层级',
        4: '箱层级',
        5: '托层级'
      };
      return statusMap[status];
    },
    levelType: function levelType(packLevel, markType) {
      if (!packLevel || !markType) return '';
      var item = codeTypeList.filter(function (number) {
        return number.markType == markType && number.packType == packLevel;
      });
      if (item.length > 0) {
        return item[0].markLen + '位';
      }
    }
  },
  data: function data() {
    var _this2 = this;
    var validate = function validate(rule, value, callback) {
      if (_this2.selectOptions.urlType == 2 && !_this2.temp.urlPathId) {
        callback(new Error('url地址不能为空，请维护'));
      } else {
        callback();
      }
    };
    return {
      imgUrl: require("../../../../images/origin.png"),
      tabHeight: '100%',
      // chosedate: [],
      listQuery: {
        current: 1,
        size: 20,
        markType: '',
        flagId: ''
      },
      shortcuts: [],
      tableKey: 0,
      labelList: null,
      numberType: null,
      list: [],
      urlList: null,
      total: 0,
      url_total: 0,
      listLoading: true,
      btn_loading: false,
      type_loading: false,
      edit_privilege: false,
      labelTemp: {
        flagName: '',
        flagType: '',
        // isHadSerNo: 0,
        isDefault: 0,
        parentId: 0,
        relationType: ''
      },
      dialogFormVisible: false,
      dialogLabeFormVisible: false,
      dialogUrlListVisible: false,
      dialogStatus: '',
      textMap: {
        label_create: '添加标识组',
        label_update: '修改标识组'
      },
      selectOptions: {
        urlType: 1
      },
      levelList: [],
      temp: {
        flagId: '',
        // imgAddress: '',
        markType: '',
        packLevel: '',
        urlPathId: '',
        isVerificationCode: 0,
        isHadSerno: ''
      },
      parentData: {
        flagCode: ''
      },
      rules: {
        markType: [{
          required: true,
          message: '制码类型不能为空，请维护',
          trigger: 'change'
        }],
        packLevel: [{
          required: true,
          message: '包装层级不能为空，请维护',
          trigger: 'change'
        }],
        urlPathId: [{
          required: true,
          validator: validate,
          trigger: 'change'
        }]
      },
      Laberules: {
        flagName: [{
          required: true,
          message: '请输入标签组名称',
          trigger: 'change'
        }],
        flagType: [{
          required: true,
          message: '请选择标签组类型',
          trigger: 'change'
        }],
        relationType: [{
          required: true,
          message: '请选择关联模式',
          trigger: 'change'
        }]
        // isHadSerNo: [
        //   { required: true, message: '请选择生成流水号', trigger: 'change' }
        // ]
      },

      urllistQuery: {},
      downloadLoading: false,
      defaultProps: {
        children: 'children',
        label: 'flagName'
      },
      btnPowers: null,
      // imageUrl: '',
      flagType: '',
      relationType: '',
      typeList: [],
      urlAddress: '',
      parentId: ''
    };
  },
  activated: function activated() {
    this.authId('searchbtn', this.$route.meta.authList);
    this.btnPowers = {};
    this.getLabelList();
    this.getCodeType();
  },
  mounted: function mounted() {
    var that = this;
    var h = window.innerHeight - 350 + 'px';
    that.$nextTick(function () {
      that.tabHeight = h;
    });

    // this.getList()
  },

  methods: {
    changetab: function changetab() {
      this.$forceUpdate();
    },
    getList: function getList() {
      this.authId('searchbtn', this.$route.meta.authList);
      var that = this;
      that.listLoading = true;
      var submitData = (0, _utils.filterKeyNull)(that.listQuery);
      (0, _labelGroupManage.modelList)(submitData).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            // that.total = parseInt(response.data.data.total)
            that.list = response.data.data.records;
            var data = (0, _utils.transTreeData)(response.data.data.records, 'pid', 'parentId', 'children');
            that.list = data;
          } else {
            that.total = 0;
            that.list = [];
          }
          that.listLoading = false;
        }
      }).catch(function (res) {
        that.listLoading = false;
      });
    },
    reset: function reset() {
      this.listQuery.current = 1;
      this.listQuery.markType = '';
      this.getList();
    },
    getCodeType: function getCodeType() {
      var _this3 = this;
      (0, _typeConfig.markTypeInfoqueryForList)().then(function (response) {
        var data = response.data;
        _this3.numberType = [];
        if (data.code != 200) {
          _this3.$message.error(data.msg);
        } else {
          if (data.data != null && data.data.length > 0) {
            var arr = [];
            data.data.forEach(function (item) {
              if (arr.indexOf(item.markType) == -1) {
                arr.push(item.markType);
              }
            });
            _this3.numberType = arr;
            // this.levelList = data.data

            codeTypeList = JSON.parse(JSON.stringify(data.data));
          } else {
            codeTypeList = [];
          }
        }
      });
    },
    getUrlList: function getUrlList() {
      var _this4 = this;
      // 获取URL列表
      this.listLoading = true;
      (0, _urlManage.urlInfoqueryForPage)(this.urllistQuery).then(function (response) {
        var data = response.data;
        _this4.listLoading = false;
        if (data.code != 200) {
          _this4.$message.error(data.msg);
          return true;
        }
        _this4.urlList = data.data.records;
        _this4.url_total = Number(data.data.total);
      });
    },
    changeType: function changeType(val, id) {
      var _this5 = this;
      this.type_loading = true;
      this.temp.packLevel = '';
      this.$nextTick(function () {
        _this5.$refs.dataForm.clearValidate('packLevel');
      });
      this.levelList = [];
      if (!id) {
        (0, _labelGroupManage.queryPackLevelByMark)({
          flagGroupId: this.listQuery.flagId,
          markType: val
        }).then(function (res) {
          if (res.data.code != 200) {
            _this5.$message.error(res.data.msg);
            return true;
          }
          if (res.data.data != null) {
            _this5.levelList = res.data.data;
          }
          _this5.type_loading = false;
        }).catch(function () {
          _this5.type_loading = false;
        });
      } else {
        (0, _labelGroupManage.queryPackLevelByMarkV2)({
          flagGroupId: this.listQuery.flagId,
          flagTemplateId: id,
          markType: val
        }).then(function (res) {
          if (res.data.code != 200) {
            _this5.$message.error(res.data.msg);
            return true;
          }
          if (res.data.data != null) {
            _this5.levelList = res.data.data.markTypeInfoList;
            _this5.edit_privilege = res.data.data.usedMark;
            _this5.edit_privilege2 = res.data.data.usedMark;
          }
          _this5.type_loading = false;
        }).catch(function () {
          _this5.type_loading = false;
        });
      }
    },
    changeUrlTypeHandler: function changeUrlTypeHandler(val) {
      this.$forceUpdate();
      this.temp.urlPathId = '';
      this.urlAddress = '';
      if (val == 2) {
        this.dialogUrlListVisible = true;
        this.getUrlList();
      }
    },
    selectUrlData: function selectUrlData(val) {
      // 确定选中的urlCode 内容
      this.temp.urlPathId = val.pid;
      this.urlAddress = val.urlAddress;
      this.dialogUrlListVisible = false;
    },
    getLabelList: function getLabelList() {
      var _this6 = this;
      // 获取所有标签列表
      // if (this.btnPowers.BSZLBCX.view == false) {
      //   this.$message.error('权限不足')
      //   return false
      // }
      this.listLoading = true;
      (0, _labelGroupManage.labelList)().then(function (res) {
        var data = res.data;
        if (data.code != 200) {
          _this6.$message.error(data.msg);
        }
        _this6.labelList = data.data;
        _this6.listLoading = false;
      });
    },
    handleNodeClick: function handleNodeClick(row, column, event) {
      // 标签组点击事件
      if (column.label != '"操作"') {
        this.listQuery.flagId = row.pid;
        this.flagType = row.flagType;
        this.parentId = row.parentId;
        this.relationType = row.relationType;
        this.getList();
      }
    },
    LabelsCommand: function LabelsCommand(_type, data) {
      var _this7 = this;
      this.authId('addbtn', this.$route.meta.authList);
      if (_type == 'label_update') {
        this.authId('updatebtn', this.$route.meta.authList);
        this.labelTemp.pId = data.pid;
        this.labelTemp.parentId = data.parentId;
        this.labelTemp.flagName = data.flagName;
        this.labelTemp.flagType = data.flagType;
        this.labelTemp.relationType = data.relationType;
        this.labelTemp.isDefault = Number(data.isDefault);
        console.log(this.labelTemp);
        // this.labelTemp.isHadSerNo = data.isHadSerNo
      }

      this.dialogStatus = _type;
      this.btn_loading = false;
      this.dialogLabeFormVisible = true;
      this.$nextTick(function () {
        _this7.$refs['LabeDataForm'].clearValidate();
      });
    },
    labelClose: function labelClose() {
      this.labelTemp = {
        flagName: '',
        flagType: '',
        relationType: '',
        // isHadSerNo: 0,
        isDefault: 0,
        parentId: 0
      };
      this.$refs.LabeDataForm.resetFields();
      this.authId('searchbtn', this.$route.meta.authList);
      this.dialogLabeFormVisible = false;
    },
    LabelsCommandRemove: function LabelsCommandRemove(data) {
      var _this8 = this;
      // 标签组 二级分类删除事件
      this.$confirm('此操作将永久删除 \r\n [' + data.flagName + ']\r\n  是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this8.authId('deletebtn', _this8.$route.meta.authList);
        (0, _labelGroupManage.deleteById)(data.pid).then(function (res) {
          _this8.authId('searchbtn', _this8.$route.meta.authList);
          var data = res.data;
          if (data.code != 200) {
            _this8.$message.error(data.msg);
            return false;
          }
          _this8.getLabelList();
          _this8.$message({
            message: '保存成功',
            type: 'success'
          });
        });
      }).catch(function () {
        _this8.authId('searchbtn', _this8.$route.meta.authList);
      });
      return true;
    },
    labelcreateData: function labelcreateData() {
      var _this9 = this;
      // 调取- 标识组创建数据保存
      this.$refs['LabeDataForm'].validate(function (valid) {
        if (valid) {
          _this9.btn_loading = true;
          (0, _labelGroupManage.saveFlagGroup)(_this9.labelTemp).then(function (res) {
            _this9.btn_loading = false;
            var data = res.data;
            if (data.code != 200) {
              _this9.$message.error(data.msg);
              return false;
            }
            _this9.$refs.LabeDataForm.resetFields();
            _this9.getLabelList();
            _this9.labelClose();
            _this9.labelTemp = {
              flagName: '',
              flagType: '',
              parentId: 0
            };
            _this9.$message({
              message: '保存成功',
              type: 'success'
            });
          }).catch(function () {
            _this9.btn_loading = false;
          });
        }
      });
    },
    labelupdateData: function labelupdateData() {
      var _this10 = this;
      // 标识组修改
      this.$refs['LabeDataForm'].validate(function (valid) {
        if (valid) {
          _this10.btn_loading = true;
          // this.labelTemp.updateTime = this.labelTemp.createTime = this.getTime('yyyy-MM-ddThh:mm:ss.SZ');
          (0, _labelGroupManage.updateFlagName)(_this10.labelTemp).then(function (res) {
            _this10.btn_loading = false;
            var data = res.data;
            if (data.code != 200) {
              _this10.$message.error(data.msg);
              return false;
            }
            _this10.$message({
              message: '保存成功',
              type: 'success'
            });
            _this10.$refs.LabeDataForm.resetFields();
            _this10.labelClose();
            _this10.labelTemp = {
              flagName: '',
              flagType: '',
              parentId: 0
            };
            _this10.getLabelList();
          }).catch(function () {
            _this10.btn_loading = false;
          });
        }
      });
    },
    getKeysetVal: function getKeysetVal(old_v, new_v) {
      for (var i in old_v) {
        if ((0, _typeof2.default)(old_v[i]) === 'object') {
          for (var n in old_v[i]) {
            old_v[i][n] = new_v[i][n];
          }
        } else {
          old_v[i] = new_v[i];
        }
      }
      return old_v;
    },
    handleUpdate: function handleUpdate(row, data_type) {
      var _this11 = this;
      console.log('123', row);
      // 添加，修改，调取事件
      this.btn_loading = false;
      this.authId('addbtnA', this.$route.meta.authList);
      if (this.$refs.dataForm !== undefined) {
        // this.temp.packLevelCode = ''
        // this.temp.numberType = ''
        this.$refs.dataForm.resetFields();
        // this.imageUrl = ''
      }
      // this.temp = Object.assign({}, temp)
      if (!this.listQuery.flagId) {
        this.$message.error('请选择标签组');
        return false;
      }
      this.selectOptions.urlType = 1;
      if (data_type == 'label_update') {
        this.authId('updatebtnA', this.$route.meta.authList);
        this.temp.pid = row.pid;
        this.temp.flagId = row.flagId;
        this.temp.isVerificationCode = row.isVerificationCode;
        // this.temp.imgAddress = row.imgAddress
        this.temp.markType = row.markType * 1;
        this.temp.isHadSerno = row.isHadSerno * 1;
        this.parentId = row.pid;
        this.changeType(row.markType * 1, row.pid);
        this.temp.packLevel = row.packLevel * 1;
        this.chagelevel(this.temp.packLevel);
        this.urlAddress = row.urlAddress;
        // this.imageUrl = row.imgAddress
        if (row.urlAddress) {
          this.selectOptions.urlType = 2;
        } else {
          this.selectOptions.urlType = 1;
        }
        console.log(this.temp, '编辑');
      } else if (data_type == 'label_create') {
        if (row) {
          this.parentId = row.pid;
        } else {
          this.parentId = 0;
        }
        this.temp = {
          flagId: '',
          markType: '',
          packLevel: '',
          urlPathId: '',
          isVerificationCode: 0,
          isHadSerno: row.isHadSerno * 1
        };
        this.urlAddress = '';
        if (this.flagType == 2) {
          this.temp.packLevel = 2;
          this.chagelevel(2);
        } else {
          this.$nextTick(function () {
            _this11.temp.packLevel = '';
          });
        }
      }
      this.temp.flagId = this.listQuery.flagId;
      this.dialogStatus = data_type;
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this11.$refs['dataForm'].clearValidate();
      });
    },
    submitData: function submitData() {
      var _this12 = this;
      // if (this.temp.imgAddress == 1) {
      //   this.$message.error('图片上传中，请稍后')
      //   return false
      // }

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _this12.btn_loading = true;
          // if (this.selectOptions.urlType == 1) {
          //   this.temp.urlCode = ''
          // }
          if (_this12.temp.pid) {
            (0, _labelGroupManage.updateFlagTemplate)(_this12.temp).then(function (res) {
              _this12.btn_loading = false;
              var data = res.data;
              if (data.code != 200) {
                // if (this.temp.urlCode == 1) {
                //   this.temp.urlCode == ''
                // }
                _this12.$message.error(data.msg);
                return false;
              }
              _this12.$message({
                message: '保存成功',
                type: 'success'
              });
              _this12.getList();
              _this12.resetData();
            }).catch(function () {
              _this12.btn_loading = false;
            });
          } else {
            (0, _labelGroupManage.saveFlagTemplate)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, _this12.temp), {}, {
              parentId: _this12.parentId
            })).then(function (res) {
              _this12.btn_loading = false;
              var data = res.data;
              if (data.code != 200) {
                // if (this.temp.urlCode == 1) {
                //   this.temp.urlCode == ''
                // }
                _this12.$message.error(data.msg);
                return false;
              }
              _this12.$message({
                message: '保存成功',
                type: 'success'
              });
              _this12.getList();
              _this12.resetData();
            }).catch(function () {
              _this12.btn_loading = false;
            });
          }
        }
      });
    },
    chagelevel: function chagelevel(level) {
      var _this13 = this;
      (0, _typeConfig.queryForListByLevel)({
        packLevel: level
      }).then(function (res) {
        if (res.data.code !== 200) {
          _this13.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            _this13.typeList = res.data.data;
            if (_this13.typeList) {
              _this13.typeList.forEach(function (el) {
                if (el.markType == 1) {
                  _this13.$set(el, 'name', '营销码');
                } else if (el.markType == 2) {
                  _this13.$set(el, 'name', '防伪码');
                } else if (el.markType == 3) {
                  _this13.$set(el, 'name', '物流码');
                }
              });
            }
          } else {
            _this13.typeList = [];
          }
        }
      });
    },
    resetData: function resetData() {
      this.temp = {
        flagId: '',
        // imgAddress: '',
        markType: '',
        packLevel: '',
        urlPathId: ''
      };
      // this.imageUrl = ''
      this.authId('searchbtn', this.$route.meta.authList);
      this.dialogFormVisible = false;
      this.edit_privilege = false;
    },
    handleDelete: function handleDelete(row) {
      var _this14 = this;
      this.$confirm('此操作将永久删除,是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this14.authId('deletebtnA', _this14.$route.meta.authList);
        (0, _labelGroupManage.flagTemplatedeleteById)(row.pid).then(function (res) {
          _this14.authId('searchbtn', _this14.$route.meta.authList);
          var data = res.data;
          if (data.code != 200) {
            _this14.$message.error(data.msg);
            return false;
          }
          _this14.getList();
          _this14.$message({
            message: '保存成功',
            type: 'success'
          });
        });
      }).catch(function () {
        _this14.authId('searchbtn', _this14.$route.meta.authList);
      });
    },
    getTime: function getTime(fmt) {
      var date = new Date();
      var o = {
        'M+': date.getMonth() + 1,
        // 月份
        'd+': date.getDate(),
        // 日
        'h+': date.getHours(),
        // 小时
        'm+': date.getMinutes(),
        // 分
        's+': date.getSeconds(),
        // 秒
        'q+': Math.floor((date.getMonth() + 3) / 3),
        // 季度
        S: date.getMilliseconds() // 毫秒
      };

      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
      }
      for (var k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
          fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
        }
      }
      return fmt;
    },
    beforeAvatarUpload: function beforeAvatarUpload(param) {
      var _this15 = this;
      var file = param.file;
      var isJPG = file.type === 'image/jpeg';
      var isPNG = file.type === 'image/png';
      var isLt2M = file.size / 1024 / 1024 < 0.5;
      var _this = this;
      if (!isJPG && !isPNG) {
        this.$message.error('上传图片只能是 PNG/JPG 格式!');
        return false;
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 500kb!');
        return false;
      }
      // _this.temp.imgAddress = 1
      var formData = new FormData();
      formData.append('file', file);
      (0, _user.imgupload)(formData).then(function (response) {
        var data = response.data;
        if (data.code != 200) {
          _this15.$message.error(data.msg);
          return true;
        }
        _this.imageUrl = data.data;
        // _this.temp.imgAddress = data.data
      });

      return isJPG && isLt2M;
    }
  }
};
exports.default = _default;