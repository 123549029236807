"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.basesc = exports.base = void 0;
exports.createGeneralMember = createGeneralMember;
exports.createMemberCondition = createMemberCondition;
exports.createMemberEquity = createMemberEquity;
exports.downdetail = downdetail;
exports.downtemplate = downtemplate;
exports.freezeselectMemberById = freezeselectMemberById;
exports.getAppletMsg = getAppletMsg;
exports.getConditionsearch = getConditionsearch;
exports.getDataById = getDataById;
exports.getDataByMember = getDataByMember;
exports.getEquitysearch = getEquitysearch;
exports.getRuleMsg = getRuleMsg;
exports.getScene = getScene;
exports.getlistsearch = getlistsearch;
exports.getlistvipsearch = getlistvipsearch;
exports.getrulesearch = getrulesearch;
exports.getsyncsearch = getsyncsearch;
exports.getsyncsearchlist = getsyncsearchlist;
exports.searchAnalysis = searchAnalysis;
exports.searchSelect = searchSelect;
exports.searchSelectBySends = searchSelectBySends;
exports.selectMemberById = selectMemberById;
exports.selectTaskById = selectTaskById;
exports.startMember = startMember;
exports.synchronizationList = synchronizationList;
exports.upDataUrl = void 0;
exports.updateRule = updateRule;
exports.updateTask = updateTask;
exports.updateTaskByIds = updateTaskByIds;
var _request = _interopRequireDefault(require("@/utils/request"));
// var baseUrl = '/wh'
var baseUrl = '';

// export const base = 'http://saas.panpass.com/images/'
// export const base = 'http://testsaas.panpass.com/images/'
// export const basesc = 'http://dev20.panpass.cn/images/'
var basesc = 'http://testhc.panpass.cn/images/';
// export const basesc = 'http://testsaas.panpass.com/images/'
exports.basesc = basesc;
var base = '';

// 获取同步列表
exports.base = base;
function getsyncsearch(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/moWxEnum/search',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'get',
    params: params
  });
}
// 获取同步表格table
function getsyncsearchlist(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/moWxSynchronization/search',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'get',
    params: params
  });
}

// 提交同步信息
function synchronizationList(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/moWxSynchronization/synchronizationList',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'post',
    data: data
  });
}

// 创建会员卡
function createGeneralMember(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/moWxGeneralMember/createGeneralMember',
    headers: {
      'companyId': localStorage.getItem('cid')
    },
    method: 'post',
    data: data
  });
}

// 查询普通会员
function getDataByMember(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/moWxGeneralMember/getDataByMember',
    headers: {
      'companyId': localStorage.getItem('cid')
    },
    method: 'get',
    params: params
  });
}

// id查询等级会员
function getDataById(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/moWxGeneralMember/getDataById',
    headers: {
      'companyId': localStorage.getItem('cid')
    },
    method: 'get',
    params: params
  });
}

// 等级会员列表
function getlistvipsearch(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/moWxGeneralMember/search',
    headers: {
      'companyId': localStorage.getItem('cid')
    },
    method: 'get',
    params: params
  });
}

// 会员权益查询
function getEquitysearch(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/moWxMemberEquity/search',
    headers: {
      'companyId': localStorage.getItem('cid')
    },
    method: 'post',
    data: data
  });
}

// 创建权益
function createMemberEquity(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/moWxMemberEquity/createMemberEquity',
    headers: {
      'companyId': localStorage.getItem('cid')
    },
    method: 'post',
    data: data
  });
}

// 创建条件
function createMemberCondition(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/moWxMemberCondition/createMemberCondition',
    headers: {
      'companyId': localStorage.getItem('cid')
    },
    method: 'post',
    data: data
  });
}

// 会员条件查询
function getConditionsearch(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/moWxMemberCondition/search',
    headers: {
      'companyId': localStorage.getItem('cid')
    },
    method: 'get',
    params: params
  });
}

// 查询任务中心
function selectTaskById(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/moWxTask/selectTaskById',
    headers: {
      'companyId': localStorage.getItem('cid')
    },
    method: 'get',
    params: params
  });
}

// 创建条件
function updateTask(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/moWxTask/updateTask',
    headers: {
      'companyId': localStorage.getItem('cid')
    },
    method: 'post',
    data: data
  });
}

// 会员分析
function searchAnalysis(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/moWxMemberInfo/searchAnalysis',
    headers: {
      'companyId': localStorage.getItem('cid')
    },
    method: 'get',
    params: params
  });
}

// 会员列表查询
function getlistsearch(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/moWxMemberInfo/search',
    headers: {
      'companyId': localStorage.getItem('cid')
    },
    method: 'get',
    params: params
  });
}

// 导入模板下载
function downtemplate(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/moWxMemberInfo/excel/template',
    headers: {
      'companyId': localStorage.getItem('cid')
    },
    method: 'get',
    responseType: 'blob',
    params: params
  });
}
// 下载明细
function downdetail(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/moWxMemberInfo/down/detail',
    headers: {
      'companyId': localStorage.getItem('cid')
    },
    method: 'get',
    responseType: 'blob',
    params: params
  });
}
// 查看会员信息详情
function selectMemberById(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/moWxMemberInfo/selectMemberById',
    headers: {
      'companyId': localStorage.getItem('cid')
    },
    method: 'get',
    params: params
  });
}
// 会员冻结或解冻
function freezeselectMemberById(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/moWxMemberInfo/updateMemberByIdAndStatus',
    headers: {
      'companyId': localStorage.getItem('cid')
    },
    method: 'post',
    data: data
  });
}
// 修改任务值
function updateTaskByIds(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/moWxMemberInfo/updateTaskByIds2',
    headers: {
      'companyId': localStorage.getItem('cid')
    },
    method: 'post',
    data: data
  });
}
// // 修改任务值2
// export function updateTaskByIdss(data) {
//   return request({
//     url: baseUrl + '/api-memberscrm/member/admin/moWxMemberInfo/updateTaskByIds2',
//     headers: { 'companyId': localStorage.getItem('cid') },
//     method: 'post',
//     data
//   })
// }
// 创建或修改会员规则
function updateRule(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/moWxMemberRule/updateRule',
    headers: {
      'companyId': localStorage.getItem('cid')
    },
    method: 'post',
    data: data
  });
}
// 查询会员规则
function getrulesearch(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/moWxMemberRule/search',
    headers: {
      'companyId': localStorage.getItem('cid')
    },
    method: 'get',
    params: params
  });
}

// 获取等级会员列表（下拉框）
function searchSelect(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/moWxGeneralMember/searchSelect',
    headers: {
      'companyId': localStorage.getItem('cid')
    },
    method: 'get',
    params: params
  });
}
function searchSelectBySends(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/moWxGeneralMember/searchSelectBySends',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'get',
    params: params
  });
}

// 启动或关闭小程序
function startMember(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/moWxMemberRule/startMember',
    headers: {
      'companyId': localStorage.getItem('cid')
    },
    method: 'post',
    params: params
  });
}
// 小程序信息
function getAppletMsg(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/moWxMemberRule/getAppletMsg',
    headers: {
      'companyId': localStorage.getItem('cid')
    },
    method: 'post',
    params: params
  });
}
// 小程序二维码
function getScene(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/moWxMemberRule/getScene',
    headers: {
      'companyId': localStorage.getItem('cid')
    },
    method: 'get',
    // responseType: 'blob',
    params: params
  });
}
// 左侧手机效果
function getRuleMsg(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/moWxMemberRule/getRuleMsg',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'get',
    params: params
  });
}
var upDataUrl = process.env.VUE_APP_BASE_API + '/api-memberscrm/member/admin/moWxMemberInfo/excel/importData';
exports.upDataUrl = upDataUrl;