var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _c(
      "div",
      { staticClass: "page-section" },
      [
        _c(
          "el-row",
          [
            _c(
              "el-form",
              {
                staticClass: "search-condition",
                staticStyle: { padding: "20px 0 0 20px" },
                attrs: {
                  inline: true,
                  model: _vm.queryParams,
                  "label-position": "top",
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "奖品名称:" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入" },
                      model: {
                        value: _vm.queryParams.awardName,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "awardName", $$v)
                        },
                        expression: "queryParams.awardName",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "业务员姓名:" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入名字/业务员id" },
                      model: {
                        value: _vm.queryParams.keywords,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "keywords", $$v)
                        },
                        expression: "queryParams.keywords",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "time", attrs: { label: "核销时间:" } },
                  [
                    _c("el-date-picker", {
                      staticStyle: { width: "45%" },
                      attrs: {
                        type: "date",
                        format: "yyyy-MM-dd",
                        "value-format": "yyyy-MM-dd",
                        clearable: false,
                      },
                      model: {
                        value: _vm.queryParams.startTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "startTime", $$v)
                        },
                        expression: "queryParams.startTime",
                      },
                    }),
                    _vm._v(" —\n          "),
                    _c("el-date-picker", {
                      staticStyle: { width: "45%" },
                      attrs: {
                        type: "date",
                        format: "yyyy-MM-dd",
                        "value-format": "yyyy-MM-dd",
                        clearable: false,
                      },
                      model: {
                        value: _vm.queryParams.endTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "endTime", $$v)
                        },
                        expression: "queryParams.endTime",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { "label-width": "0" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: _vm.btnConfig.searchBtn.type,
                          icon: _vm.btnConfig.searchBtn.icon,
                        },
                        on: { click: _vm.querylist },
                      },
                      [_vm._v("查询")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: _vm.btnConfig.resetBtn.type,
                          icon: _vm.btnConfig.resetBtn.icon,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.restSalesmanScanRecordsList()
                          },
                        },
                      },
                      [_vm._v("重置")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.formConfig.btnFollow
          ? _c("el-divider", { staticClass: "btnDivider" })
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "phead" }, [
          _c(
            "div",
            { staticClass: "head-btn-group" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: _vm.btnConfig.downLoadBtn.type,
                    size: _vm.btnConfig.size,
                    icon: _vm.btnConfig.downLoadBtn.icon,
                  },
                  on: { click: _vm.exportSalesman },
                },
                [_vm._v("导出")]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoadig,
                expression: "listLoadig",
              },
            ],
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.tableData,
              border: _vm.tableConfig.border,
              stripe: _vm.tableConfig.stripe,
              "highlight-current-row": true,
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                type: "index",
                label: "ID",
                align: _vm.tableConfig.align,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "name",
                label: "业务员",
                align: _vm.tableConfig.align,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "salesmanId",
                label: "业务员id",
                align: _vm.tableConfig.align,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "companyName",
                label: "所属经销商",
                width: "120px",
                align: _vm.tableConfig.align,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "code",
                label: "数码",
                width: "230px",
                align: _vm.tableConfig.align,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "productName",
                label: "产品名称",
                align: _vm.tableConfig.align,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "addTimeStr",
                label: "核销时间",
                width: "220px",
                align: _vm.tableConfig.align,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "awardName",
                label: "核销奖品",
                align: _vm.tableConfig.align,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("Pagination", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.total > 0,
              expression: "total > 0",
            },
          ],
          attrs: {
            layout: "total, sizes, prev, pager, next, jumper",
            total: _vm.total,
            page: _vm.queryParams.pageNo,
            limit: _vm.queryParams.pageSize,
          },
          on: {
            "update:page": function ($event) {
              return _vm.$set(_vm.queryParams, "pageNo", $event)
            },
            "update:limit": function ($event) {
              return _vm.$set(_vm.queryParams, "pageSize", $event)
            },
            pagination: _vm.getSalesmanScanRecordsList,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }