"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.function.name");
var _ermCode = require("@/api/wxhyyy/ermCode");
var _customMenu = require("@/api/wxhyyy/customMenu");
var _wxgzh = require("@/api/wxhyyy/wxgzh");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _lineChart = _interopRequireDefault(require("./components/lineChart"));
var _tinyMce = _interopRequireDefault(require("@/components/tiny-mce"));
var _admincut = _interopRequireDefault(require("@/components/admincut"));
var _methods; //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
var _default = {
  components: {
    Pagination: _Pagination.default,
    lineChart: _lineChart.default,
    admincut: _admincut.default,
    TinyMce: _tinyMce.default
  },
  filters: {
    filterTime: function filterTime(value) {
      if (!value) return '';
      if (value.length > 0) {
        var time = new Date(value);
        var y = time.getFullYear();
        var m = time.getMonth() + 1 > 9 ? time.getMonth() + 1 : '0' + (time.getMonth() + 1);
        var d = time.getDate() > 9 ? time.getDate() : '0' + time.getDate();
        var h = time.getHours();
        var i = time.getMinutes() > 9 ? time.getMinutes() : '0' + time.getMinutes();
        var s = time.getSeconds() > 9 ? time.getSeconds() : '0' + time.getSeconds();
        return y + ':' + m + ':' + d + ' ' + h + ':' + i + ':' + s;
      }
      return value;
    }
  },
  data: function data() {
    return {
      dialogVisible3: false,
      dialogVisible4: false,
      dialogVisible5: false,
      dialogVisible6: false,
      dialogVisible7: false,
      dialogVisible8: false,
      isSelected: "a",
      msgData: '',
      mediaData: [],
      itemData: "",
      audio: "",
      video: "",
      preImg: "",
      picData: "",
      voiceData: "",
      videoData: "",
      qdmsg: "",
      toolbar: "emoticons link",
      plugins: "emoticons link",
      listQuery: {
        codeName: "",
        startTime: "",
        endTime: "",
        pageNo: 1,
        pageSize: 10
      },
      listQuery2: {
        codeName: "",
        type: 0
      },
      listQuery3: {
        id: "",
        startTime: "",
        endTime: "",
        type: 1
      },
      loading: false,
      tableData: [],
      downLoading: false,
      total: 0,
      loadCodeName: "",
      dialogVisible: false,
      disabled: false,
      dialogVisible2: false,
      nameTxt: "",
      value1: "",
      lineChartData: {
        expectedData: [],
        xData: []
      },
      isActive: false,
      primary1: "primary",
      primary2: "default",
      selected: 1,
      modelData: {},
      createTime: [],
      searchTime: "",
      dialogMsg: false
    };
  },
  created: function created() {
    var that = this;
    // 初始化获取菜单数据
    that.getCodeList();
  },
  methods: (_methods = {
    getCodeList: function getCodeList() {
      var that = this;
      var param = that.listQuery;
      that.loading = true;
      param.appId = sessionStorage.getItem("appId");
      (0, _ermCode.searchList)(param).then(function (res) {
        var res = res.data;
        if (res.code == 200) {
          that.tableData = res.data.records;
          that.total = parseInt(res.data.total);
          that.loading = false;
        } else {
          that.loading = false;
        }
      }).catch(function (res) {
        that.loading = false;
      });
    },
    // tab切换
    handleClick: function handleClick(value) {
      var that = this;
      if (value.name == '1') {
        if (that.msgData == '') {
          that.modelData.content = '';
        } else {
          that.modelData.content = that.totet(that.msgData);
        }
        return;
      }
      if (value.name == '2') {
        if (that.picData == '') {
          that.modelData.content = '';
        } else {
          that.modelData.content = that.picData.mediaId;
        }
        return;
      }
      if (value.name == '3') {
        if (that.voiceData == '') {
          that.modelData.content = '';
        } else {
          that.modelData.content = that.voiceData.mediaId;
        }
        return;
      }
      if (value.name == '4') {
        if (that.videoData == '') {
          that.modelData.content = '';
        } else {
          that.modelData.content = that.videoData.mediaId;
        }
        return;
      }
    },
    // 预览图片
    viewImg: function viewImg() {
      var that = this;
      console.log(that.picData);
      // that.preImg = base + '/' + that.picData.url
      that.preImg = that.picData.url;
      that.$nextTick(function () {
        that.dialogVisible8 = true;
      });
    },
    // 试听
    getAudio: function getAudio(val) {
      var that = this;
      console.log(val);
      (0, _wxgzh.materialVoiceDownload)({
        mediaId: val.mediaId,
        appId: sessionStorage.getItem("appId")
      }).then(function (res) {
        console.log(res);
        var res = res.data;
        var blob = new Blob([res], {
          type: "application/attachment;filename="
        });
        console.log(blob);
        var downloadElement = document.createElement("a");
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = val.trueName;
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      });
    },
    // 预览视频
    getVideo: function getVideo(val) {
      var that = this;
      (0, _wxgzh.materialVideoInfo)({
        mediaId: val,
        appId: sessionStorage.getItem("appId")
      }).then(function (res) {
        var res = res.data;
        if (res.code === 200) {
          that.video = res.data;
          that.$nextTick(function () {
            that.dialogVisible6 = true;
          });
        } else {
          that.$message(res.msg);
        }
      });
    },
    // 选择列表项
    selectItem: function selectItem(val, index) {
      var that = this;
      that.isSelected = index;
      that.itemData = val;
    },
    // 确定
    makeSure: function makeSure(val) {
      var that = this;
      if (val === '2' || val === '3' || val === '4') {
        if (that.itemData == "") {
          that.$message.error("请选择列表项");
          return;
        }
      }
      // if (val == "1") {
      //   if(that.msgData){
      //     that.modelData.content = that.msgData
      //   }else {
      //     that.modelData.content = ''
      //   }
      // }
      if (val == "2") {
        that.picData = that.itemData;
      }
      if (val == "3") {
        that.voiceData = that.itemData;
      }
      if (val == "4") {
        that.videoData = that.itemData;
      }
      if (that.itemData.mediaId == null) {
        that.modelData.content = "";
      } else {
        that.modelData.content = that.itemData.mediaId;
      }
      that.closeDialog(val);
    },
    // 取消
    DoCancel: function DoCancel(val) {
      var that = this;
      that.itemData = "";
      that.closeDialog(val);
    },
    closeDialog: function closeDialog(val) {
      var that = this;
      if (val == '1') {
        that.dialogVisible = false;
      }
      if (val == '2') {
        that.dialogVisible7 = false;
      }
      if (val == '3') {
        that.dialogVisible3 = false;
      }
      if (val == '4') {
        that.dialogVisible4 = false;
      }
    },
    // 替换素材
    changeItem: function changeItem(val) {
      var that = this;
      var mtype = val;
      that.choseElement(mtype);
    },
    // 获取图片，音频，视频列表
    getList2: function getList2() {
      var that = this;
      that.listQuery2.appId = sessionStorage.getItem('appId');
      (0, _customMenu.getMedia)(that.listQuery2).then(function (res) {
        var res = res.data;
        if (res.code == 200) {
          that.mediaData = res.data.records;
          that.total = parseInt(res.data.total);
        }
      });
    },
    choseElement: function choseElement(val) {
      var that = this;
      that.isSelected = "a";
      if (val == "0") {
        that.listQuery1.pageNo = 1;
        that.getList();
        that.$nextTick(function () {
          that.dialogVisible = true;
        });
        return;
      } else {
        that.listQuery2.pageNo = 1;
        that.listQuery2.mediaType = val;
        that.getList2();
        if (val == "3") {
          // 图片
          that.$nextTick(function () {
            that.dialogVisible7 = true;
          });
        }
        if (val == "2") {
          // 语音
          that.$nextTick(function () {
            that.dialogVisible3 = true;
          });
        }
        if (val == "1") {
          // 视频
          that.$nextTick(function () {
            that.dialogVisible4 = true;
          });
        }
      }
    },
    // 查询
    queryList: function queryList() {
      var that = this;
      that.getCodeList();
      that.loadCodeName = that.listQuery.codeName;
      that.searchTime = that.createTime;
    },
    ToText: function ToText(HTML) {
      var input = HTML;
      return input.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, "").replace(/<[^>]+?>/g, "").replace(/\s+/g, " ").replace(/ /g, " ").replace(/>/g, " ");
    },
    totet: function totet(val) {
      return val.replace(/<\/?p[^>]*>/gi, "");
    },
    asdf: function asdf(val) {
      // console.log(val);
      // console.log(this.totet(val));
      // console.log(this.$refs.aaa.innerHTML);
      this.modelData.content = this.totet(val);
    },
    Nomsg: function Nomsg() {
      var that = this;
      that.dialogMsg = false;
      that.qdmsg = "";
    },
    Savemsg: function Savemsg() {
      var that = this;
      console.log(that.modelData);
      (0, _ermCode.saveReply)(that.modelData).then(function (res) {
        var res = res.data;
        console.log(res);
        if (res.code === 200) {
          that.$message.success(res.msg);
          that.getCodeList();
          that.dialogMsg = false;
        } else {
          that.$message.warning(res.msg);
        }
      });
      // that.qdmsg = "";
    },
    replymsg: function replymsg(val) {
      var that = this;
      that.dialogMsg = true;
      that.msgData = '';
      that.picData = '';
      that.voiceData = '';
      that.videoData = '';
      that.modelData = {
        appId: val.appId,
        replyType: '1',
        sceneId: val.sceneId
      };
      (0, _ermCode.replycontent)({
        sceneId: val.sceneId
      }).then(function (res) {
        var res = res.data;
        console.log(res);
        if (res.code === 200) {
          if (res.data) {
            that.modelData.contentType = res.data.contentType;
            switch (that.modelData.contentType) {
              case '1':
                that.msgData = res.data.content;
                break;
              case '2':
                that.picData = {
                  url: res.data.url
                };
                break;
              case '3':
                that.voiceData = {
                  name: res.data.name
                };
                break;
              case '4':
                that.videoData = {
                  name: res.data.name
                };
                break;
            }
          }
        } else {
          that.$message(res.msg);
        }
      });
    },
    // 创建二维码
    creatCode: function creatCode() {
      var that = this;
      that.listQuery2.codeName = "";
      that.$nextTick(function () {
        that.dialogVisible = true;
      });
    },
    // 下载图片
    downImg: function downImg(data) {
      var that = this;
      window.open(data.wxQrcodePictureUrl);
    },
    // 删除
    DoDelete: function DoDelete(data) {
      var that = this;
      var id = data.id;
      var param = {};
      param.id = id;
      that.$confirm("删除后将不会再列表中显示，确定要删除二维码吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        param.appId = sessionStorage.getItem("appId");
        (0, _ermCode.deleteItem)(param).then(function (res) {
          var res = res.data;
          if (res.code == 200) {
            that.$message({
              type: "success",
              message: "删除成功！"
            });
            that.getCodeList();
          } else {
            that.$message.error(res.msg);
          }
        });
      });
    },
    // 查看数据
    scan: function scan(data) {
      var that = this;
      that.nameTxt = data.codeName;
      that.listQuery3.id = data.id;
      that.primary1 = "primary";
      that.primary2 = "default";
      that.isActive = false;
      that.getSevenDay();
      that.$nextTick(function () {
        that.dialogVisible2 = true;
      });
    },
    // 下载明细
    downLoad: function downLoad() {
      var that = this;
      var codeName = that.loadCodeName;
      that.listQuery.pageNo = 1;
      that.listQuery.pageSize = that.total;
      that.downLoading = true;
      that.listQuery.appId = sessionStorage.getItem("appId");
      (0, _ermCode.downLoadData)(that.listQuery).then(function (res) {
        var res = res.data;
        var blob = new Blob([res], {
          type: "application/vnd.ms-excel;charset=utf-8"
        });
        var downloadElement = document.createElement("a");
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = "渠道二维码明细.xlsx";
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
        that.downLoading = false;
      }).catch(function () {
        that.downLoading = false;
      });
    },
    // 新增
    DoSave: function DoSave() {
      var that = this;
      var param = that.listQuery2;
      that.disabled = true;
      param.appId = sessionStorage.getItem("appId");
      (0, _ermCode.newAdd)(param).then(function (res) {
        var res = res.data;
        if (res.code == 200) {
          that.$message({
            type: "success",
            message: res.msg
          });
          that.disabled = false;
          that.dialogVisible = false;
          that.listQuery.pageNo = 1;
          that.getCodeList();
        } else {
          that.$message.error(res.msg);
          that.disabled = false;
          return;
        }
      }).catch(function () {
        that.disabled = false;
      });
    }
  }, (0, _defineProperty2.default)(_methods, "DoCancel", function DoCancel() {
    var that = this;
    that.listQuery2.codeName = "";
    that.dialogVisible = false;
  }), (0, _defineProperty2.default)(_methods, "getChangeDate", function getChangeDate(value) {
    var that = this;
    that.primary1 = "default";
    that.primary2 = "default";
    that.isActive = true;
    if (value == null || value == "") {
      that.listQuery3.startTime = "";
      that.listQuery3.endTime = "";
    } else {
      that.listQuery3.startTime = value[0];
      that.listQuery3.endTime = value[1];
    }
    that.getScanData(that.listQuery3);
  }), (0, _defineProperty2.default)(_methods, "getSevenDay", function getSevenDay() {
    var that = this;
    that.primary1 = "primary";
    that.primary2 = "default";
    that.isActive = false;
    var end = new Date();
    var start = new Date();
    end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 8);
    var beginDate = that.filterTime(start);
    var endDate = that.filterTime(end);
    that.listQuery3.startTime = beginDate;
    that.listQuery3.endTime = endDate;
    that.value1 = [beginDate, endDate];
    that.getScanData(that.listQuery3);
  }), (0, _defineProperty2.default)(_methods, "getThirtyDay", function getThirtyDay() {
    var that = this;
    that.primary1 = "default";
    that.primary2 = "primary";
    that.isActive = false;
    var end = new Date();
    var start = new Date();
    end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 31);
    var beginDate = that.filterTime(start);
    var endDate = that.filterTime(end);
    that.listQuery3.startTime = beginDate;
    that.listQuery3.endTime = endDate;
    that.value1 = [beginDate, endDate];
    that.getScanData(that.listQuery3);
  }), (0, _defineProperty2.default)(_methods, "filterTime", function filterTime(value) {
    var time = new Date(value);
    var y = time.getFullYear();
    var m = time.getMonth() + 1 > 9 ? time.getMonth() + 1 : "0" + (time.getMonth() + 1);
    var d = time.getDate() > 9 ? time.getDate() : "0" + time.getDate();
    var h = time.getHours();
    var i = time.getMinutes() > 9 ? time.getMinutes() : "0" + time.getMinutes();
    var s = time.getSeconds() > 9 ? time.getSeconds() : "0" + time.getSeconds();
    return y + "-" + m + "-" + d;
  }), (0, _defineProperty2.default)(_methods, "getLine", function getLine(value) {
    var that = this;
    var type = parseInt(value);
    that.selected = type;
    that.listQuery3.type = type;
    that.getScanData(that.listQuery3);
  }), (0, _defineProperty2.default)(_methods, "getScanData", function getScanData(data) {
    var that = this;
    data.appId = sessionStorage.getItem("appId");
    (0, _ermCode.scanData)(data).then(function (res) {
      var res = res.data;
      if (res.code == 200) {
        console.log(res);
        that.modelData.addFansNumber = res.data.addFansNumber;
        that.modelData.cancelNumber = res.data.cancelNumber;
        that.modelData.scanNumber = res.data.scanNumber;
        var lineData = res.data.graphVOList;
        var xdata = [];
        var ydata = [];
        lineData.forEach(function (item, index) {
          xdata.push(item.nodeTime);
          ydata.push(item.nodeValue);
        });
        that.lineChartData.expectedData = ydata;
        that.lineChartData.xData = xdata;
      }
    });
  }), (0, _defineProperty2.default)(_methods, "getDateRange", function getDateRange(value) {
    var that = this;
    if (value == null || value == "") {
      that.listQuery.startTime = "";
      that.listQuery.endTime = "";
    } else {
      that.listQuery.startTime = value[0];
      that.listQuery.endTime = value[1];
    }
  }), _methods)
};
exports.default = _default;