"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/web.dom.iterable");
var _activityManage = require("@/api/market/activityManage");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _orderConfig = _interopRequireDefault(require("@/views/marketingManage/OrderAward/orderConfig/orderConfig"));
var _prizeManage = _interopRequireDefault(require("@/views/marketingManage/marketingActivities/activityManage/prizeManage"));
var _TipDialog = _interopRequireDefault(require("@/components/TipDialog"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManageOrderAwardindex';
var _default = {
  filters: {
    name: 'marketingManageOrderAwardindex',
    typeFilter: function typeFilter(status) {
      var statusMap = {
        1: '集字',
        2: '扫码抽奖',
        3: '大转盘',
        4: '微信红包'
      };
      return statusMap[status];
    },
    stateFilter: function stateFilter(status) {
      var statusMap = {
        100: '待审核',
        200: '审核通过',
        300: '驳回',
        400: '结束',
        500: '新增',
        600: '暂停中'
      };
      return statusMap[status];
    }
  },
  components: {
    Tipdialog: _TipDialog.default,
    Pagination: _Pagination.default,
    Content: _orderConfig.default,
    Prize: _prizeManage.default
  },
  data: function data() {
    return {
      checkForm: {
        auditStatus: '',
        actId: '',
        actNo: '',
        auditRemark: ''
      },
      visibleCheck: false,
      AuditList: [],
      awardsoptions: [{
        value: '',
        label: '全部'
      }, {
        value: 2,
        label: '均匀布奖'
      }, {
        value: 1,
        label: '随机布奖'
      }],
      actlist: [{
        img: require('@/assets/images/hongbao.png'),
        title: '扫码抽奖',
        type: '2'
      }
      // {
      //   img: require("@/assets/images/zhuanpan.png"),
      //   title: "大转盘",
      //   type: "3"
      // },
      // {
      //   img: require("@/assets/images/jizi.png"),
      //   title: "集字",
      //   type: "1"
      // }
      // {
      //   img: require('@/assets/images/jiugongge.png'),
      //   title: '九宫格抽奖',
      //   type: '4'
      // }
      ],

      checkVisible: false,
      shloading: false,
      dateVlaue: [],
      handleShow: false,
      tabHeight: '100%',
      timer: null,
      list: null,
      listLoading: true,
      total: 0,
      formInline: {
        pageNo: 1,
        pageSize: 20,
        endTime: '',
        calculation: '',
        name: '',
        orderno: '',
        startTime: '',
        state: '',
        type: '',
        id: ''
      },
      currentRow: '',
      radio: '',
      actNo: '',
      showOnly: '',
      isCopy: '',
      type: '',
      disabledshowOnly: '',
      dialogVisible: false,
      actDialogVisible: false,
      prizeVisible: false,
      multipleSelection: [],
      downLoading: false,
      apis: []
    };
  },
  activated: function activated() {
    var that = this;
    var hasCache = !!(that.list && that.list.length > 0);
    var authList = that.$route.meta.authList;
    that.apis = authList;
    var auth = that.hasAuth('searchbtn', authList);
    that.authId('searchbtn', authList);
    if (!hasCache) {
      if (auth) {
        that.fetchData();
      }
    }
  },
  // 缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = that.getCaches(that, name);
    if (!cache) {
      that.list = [];
      that.total = 0;
    }
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
    this.user_Id = sessionStorage.getItem('userId');
  },
  methods: {
    linkpage: function linkpage(id) {
      var that = this;
      that.authId('prizerecord', that.apis);
      that.$router.push({
        path: '/marketingManage/awardManage/winningRecord/index',
        query: {
          activityId: id
        }
      });
    },
    getSoldExcel: function getSoldExcel() {
      var that = this;
      that.authId('importbtn', that.apis);
      (0, _activityManage.getsoldExcelin)({
        status: 5,
        startTime: this.formInline.startTime === null ? null : this.formInline.startTime,
        // 开始时间
        endTime: this.formInline.startTime === null ? null : this.formInline.endTime // 结束时间
      }).then(function (data) {
        var blob = new Blob([data.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
        });
        var fileName = '订单信息列表.xlsx';
        var elink = document.createElement('a');
        if ('download' in elink) {
          // 非IE下载
          elink.download = fileName;
          elink.style.display = 'none';
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href); // 释放URL 对象
          document.body.removeChild(elink);
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName);
        }
      });
    },
    // 新增活动
    creatAct: function creatAct(type) {
      var _this = this;
      this.authId('addbtn', this.apis);
      (0, _activityManage.isExistConsumeWechat)().then(function (res) {
        res = res.data;
        if (res.status * 1 === 200) {
          if (res.data) {
            _this.hasConsumer = true;
          } else {
            _this.hasConsumer = false;
          }
        } else {
          _this.$message({
            message: res.msg + '请刷新页面或重新登录',
            type: 'error',
            duration: 3 * 1000
          });
        }
      }).then(function () {
        if (_this.hasConsumer) {
          _this.actDialogVisible = true;
        } else {
          _this.$refs.hasConsumerTipVisible.tipAlert();
        }
      });
    },
    // 活动类型关闭
    cancel: function cancel() {
      this.actDialogVisible = false;
    },
    detail: function detail(actNo, type, disabled, num) {
      if (disabled) {
        this.authId('detailbtn', this.apis);
      } else {
        this.authId('updatebtn', this.apis);
      }
      this.actDialogVisible = false;
      this.actNo = actNo;
      this.type = type;
      this.num = num;
      this.showOnly = disabled;
      this.disabledshowOnly = disabled;
      this.dialogVisible = true;
    },
    copy: function copy(actNo, type) {
      this.authId('copyBtn', this.apis);
      this.actDialogVisible = false;
      this.actNo = actNo;
      this.type = type;
      this.isCopy = true;
      this.showOnly = false;
      this.dialogVisible = true;
    },
    // 日志
    prizeList: function prizeList(actNo) {
      var that = this;
      that.authId('logbtn', that.apis);
      this.actNo = actNo;
      this.prizeVisible = true;
    },
    close: function close() {
      this.dialogVisible = false;
      this.prizeVisible = false;
      this.fetchData();
      this.actNo = '';
      this.showOnly = '';
      this.disabledshowOnly = '';
      this.type = '';
      // this.cancel()
    },
    // 删除活动
    delA: function delA(val) {
      var _this2 = this;
      var that = this;
      that.authId('deletebtn', that.apis);
      (0, _activityManage.del)({
        actNo: val
      }).then(function (res) {
        res = res.data;
        if (res.status * 1 === 200) {
          _this2.$message.closeAll();
          _this2.$message({
            message: res.msg,
            type: 'success',
            duration: 3 * 1000
          });
          _this2.fetchData();
        } else {
          _this2.$message.closeAll();
          _this2.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
        _this2.listLoading = false;
      });
    },
    // 重置表单
    restForm: function restForm(refname) {
      console.log(258);
      this.$refs[refname].resetFields();
    },
    restFun: function restFun() {
      this.formInline = {
        pageNo: 1,
        pageSize: 20,
        endTime: '',
        calculation: '',
        name: '',
        orderno: '',
        startTime: '',
        state: '',
        type: '',
        id: ''
      };
      this.dateVlaue = [];
      this.fetchData();
    },
    // 获取订单列表
    fetchData: function fetchData() {
      var _this3 = this;
      this.authId('searchbtn', this.apis);
      this.listLoading = true;
      var obj = {
        startTime: this.formInline.startTime ? this.formInline.startTime + ' 00:00:00' : '',
        endTime: this.formInline.endTime ? this.formInline.endTime + ' 23:59:59' : ''
      };
      Object.assign(this.formInline, obj);
      (0, _activityManage.getOrderActList)(this.formInline).then(function (res) {
        console.log(res, 988);
        res = res.data;
        if (res.status * 1 === 200) {
          _this3.total = res.data.total;
          res.data.list.forEach(function (item) {
            item.userId = _this3.user_Id.replace(/"+/g, '');
          });
          _this3.list = res.data.list;
          // this.changeState();
        } else {
          _this3.$message.closeAll();
          _this3.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
        _this3.listLoading = false;
      }).catch(function () {
        _this3.listLoading = false;
      });
    },
    // 暂停活动
    setDisable: function setDisable(val) {
      var _this4 = this;
      this.authId('stopbtn', this.apis);
      (0, _activityManage.actDisable)({
        actNo: val
      }).then(function (res) {
        res = res.data;
        if (res.status * 1 === 200) {
          _this4.$message.closeAll();
          _this4.$message({
            message: res.msg,
            type: 'success',
            duration: 3 * 1000
          });
          _this4.fetchData();
        } else {
          _this4.$message.closeAll();
          _this4.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
      });
    },
    // 重启活动
    setEnable: function setEnable(val) {
      var _this5 = this;
      this.authId('startbtn', this.apis);
      (0, _activityManage.actEnable)({
        actNo: val
      }).then(function (res) {
        res = res.data;
        if (res.status * 1 === 200) {
          _this5.$message.closeAll();
          _this5.$message({
            message: res.msg,
            type: 'success',
            duration: 3 * 1000
          });
          _this5.fetchData();
        } else {
          _this5.$message.closeAll();
          _this5.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
      });
    },
    // 结束活动
    setEnd: function setEnd(val) {
      var _this6 = this;
      this.authId('endbtn', this.apis);
      (0, _activityManage.endAct)({
        actNo: val
      }).then(function (res) {
        res = res.data;
        if (res.status * 1 === 200) {
          _this6.$message.closeAll();
          _this6.$message({
            message: res.msg,
            type: 'success',
            duration: 3 * 1000
          });
          setTimeout(function () {
            _this6.fetchData();
          }, 1000);
        } else {
          _this6.$message.closeAll();
          _this6.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
      });
    },
    changeState: function changeState() {
      this.list.forEach(function (item) {
        var aStartTime = item.startTime.split(' ');
        var aEndtTime = item.endTime.split(' ');
        aStartTime[0].split('-').join('/');
        var start = Date.parse(new Date(aStartTime[0].split('-').join('/') + ' ' + aStartTime[1]));
        var end = Date.parse(new Date(aEndtTime[0].split('-').join('/') + ' ' + aEndtTime[1]));
        if (start <= item.currentTime && item.state === '100') {
          item.state = '200';
        }
        if (end <= item.currentTime) {
          item.state = '300';
        }
      });
    },
    // 时间函数
    dateFun: function dateFun(val) {
      if (val) {
        this.formInline.endTime = val[1];
        this.formInline.startTime = val[0];
      } else {
        this.formInline.endTime = '';
        this.formInline.startTime = '';
      }
    },
    // 审核日志
    checkFun: function checkFun(row) {
      var _this7 = this;
      this.authId('verifylog', this.apis);
      this.checkVisible = true;
      (0, _activityManage.getAudit)({
        actId: row.actId,
        actNo: row.actNo
      }).then(function (res) {
        if (res.data.code === 0) {
          console.log(res);
          _this7.AuditList = res.data.data.list;
        }
      });
    },
    // 发起审核
    startCheckFun: function startCheckFun(row) {
      var _this8 = this;
      this.authId('startverify', this.apis);
      (0, _activityManage.initiateAudit)({
        actId: row.actId,
        actNo: row.actNo
      }).then(function (res) {
        console.log(res);
        if (res.status * 1 === 200) {
          _this8.$message.closeAll();
          _this8.$message({
            message: res.data.msg,
            type: 'success',
            duration: 3 * 1000
          });
          _this8.fetchData();
        }
      });
    },
    routerLinkFun: function routerLinkFun(type, actId) {
      if (type === 0) {
        // 日志
        var that = this;
        that.authId('logbtn', that.apis);
        this.$router.push({
          path: '/system/logmange/operatelog/index'
        });
      } else if (type === 1) {
        // 中奖记录
        this.$router.push({
          path: '/marketingManage/awardManage/winningRecord/index',
          query: {
            activityId: actId
          }
        });
      }
    },
    getTimeFun: function getTimeFun(val) {
      var num = new Date(val).getTime();
      return num;
    },
    handleSelectionChange: function handleSelectionChange(val) {
      if (val.length === 1) {
        this.multipleSelection = val;
      }
    },
    checkOperationFun: function checkOperationFun(row) {
      this.authId('verifybtn', this.apis);
      this.visibleCheck = true;
      this.checkForm.actId = row.actId;
      this.checkForm.actNo = row.actNo;
    },
    // 审核提交
    visibleCheckFun: function visibleCheckFun() {
      var _this9 = this;
      this.shloading = true;
      (0, _activityManage.postAudit)((0, _objectSpread2.default)({}, this.checkForm)).then(function (res) {
        _this9.shloading = false;
        if (res.status === 200) {
          _this9.$message({
            message: '操作成功',
            type: 'success',
            duration: 3 * 1000
          });
          _this9.fetchData();
          _this9.visibleCheck = false;
        }
      }).catch(function () {
        _this9.shloading = false;
      });
    }
  }
};
exports.default = _default;