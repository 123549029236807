var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.apis)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c(
                "el-form",
                {
                  ref: "listQuery",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.listQuery,
                    "status-icon": "",
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cols" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { prop: "channelStr", label: "终端:" },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      "suffix-icon": "el-icon-search",
                                      clearable: "",
                                      placeholder: "请输入",
                                      readonly: true,
                                    },
                                    on: {
                                      focus: function ($event) {
                                        return _vm.getUnit("search")
                                      },
                                    },
                                    model: {
                                      value: _vm.channelStr,
                                      callback: function ($$v) {
                                        _vm.channelStr = $$v
                                      },
                                      expression: "channelStr",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "productName",
                                    label: "产品:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      "suffix-icon": "el-icon-search",
                                      clearable: "",
                                      placeholder: "请输入",
                                      readonly: true,
                                    },
                                    on: { focus: _vm.getProductId },
                                    model: {
                                      value: _vm.productName,
                                      callback: function ($$v) {
                                        _vm.productName = $$v
                                      },
                                      expression: "productName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "productTypeId",
                                    label: "产品分类:",
                                  },
                                },
                                [
                                  _c("treeselect", {
                                    attrs: {
                                      multiple: false,
                                      "flatten-search-results": true,
                                      placeholder: "请选择...",
                                      options: _vm.classList,
                                    },
                                    model: {
                                      value: _vm.listQuery.productTypeId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.listQuery,
                                          "productTypeId",
                                          $$v
                                        )
                                      },
                                      expression: "listQuery.productTypeId",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.formConfig.btnFollow
                            ? _c(
                                "el-col",
                                {
                                  staticClass: "head-btn-group",
                                  attrs: { span: 6 },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { "label-width": "0" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v(" ")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.searchBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.searchBtn.icon,
                                          },
                                          on: { click: _vm.DoSearch },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.searchBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      false
                                        ? _c("el-divider", {
                                            attrs: { direction: "vertical" },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.resetBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.resetBtn.icon,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.DoReset(_vm.listQuery)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.resetBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      false
                                        ? _c("el-divider", {
                                            attrs: { direction: "vertical" },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.formConfig.btnFollow
                ? _c("el-divider", { staticClass: "btnDivider" })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btnArea" },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "head-btn-group",
                      staticStyle: { width: "266px" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "head-btn-group" },
                        [
                          _vm.hasAuth("importbtn", _vm.apis)
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.downLoadBtn.type,
                                    size: _vm.btnConfig.size,
                                    icon: _vm.btnConfig.downLoadBtn.icon,
                                    loading: _vm.exportLoading,
                                    plain: _vm.btnConfig.downLoadBtn.plain,
                                  },
                                  on: { click: _vm.DoExportAll },
                                },
                                [_vm._v(_vm._s(_vm.btnConfig.downLoadBtn.text))]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "section",
                { staticStyle: { "border-top": "1px solid #eee" } },
                [
                  !_vm.formConfig.btnFollow
                    ? _c("el-row", {
                        staticClass: "head-item",
                        attrs: { gutter: 20, type: "flex" },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      key: _vm.tableKey,
                      staticStyle: { width: "100%" },
                      attrs: {
                        height: _vm.tabHeight,
                        data: _vm.list,
                        border: _vm.tableConfig.border,
                        stripe: _vm.tableConfig.stripe,
                        fit: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "序号",
                          type: "index",
                          align: _vm.tableConfig.align,
                          width: "120",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        (_vm.listQuery.pageNo - 1) *
                                          _vm.listQuery.pageSize +
                                          scope.$index +
                                          1
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2146844346
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "终端编码",
                          prop: "dealerCode",
                          "min-width": "120",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "终端名称",
                          prop: "dealerName",
                          "min-width": "120",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "终端分类",
                          prop: "clazzName",
                          "min-width": "120",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "终端等级",
                          prop: "gradeName",
                          "min-width": "120",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "产品编码",
                          prop: "productCode",
                          "min-width": "120",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "产品名称",
                          prop: "productName",
                          "min-width": "120",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "包装比例",
                          prop: "packScaleExpression",
                          "min-width": "120",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "现存量(箱)",
                          prop: "existingSumTrans",
                          "min-width": "120",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "在途库存(箱)",
                          prop: "beingSumTrans",
                          "min-width": "120",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _vm.hasAuth("detailbtn", _vm.apis)
                        ? _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              align: _vm.tableConfig.align,
                              width: "80",
                              fixed: "right",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _vm.hasAuth("detailbtn", _vm.apis)
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.DoScan(row)
                                                },
                                              },
                                            },
                                            [_vm._v("明细")]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3736941869
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("pagination", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.total > 0,
                        expression: "total > 0",
                      },
                    ],
                    attrs: {
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.total,
                      page: _vm.listQuery.pageNo,
                      limit: _vm.listQuery.pageSize,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(_vm.listQuery, "pageNo", $event)
                      },
                      "update:limit": function ($event) {
                        return _vm.$set(_vm.listQuery, "pageSize", $event)
                      },
                      pagination: _vm.getList,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.apis)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: "库存明细",
            visible: _vm.scanVisible,
            width: "1100px !important",
          },
          on: {
            "update:visible": function ($event) {
              _vm.scanVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("终端编码:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.scanData.dealerCode)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 12 } }, [
                _c(
                  "div",
                  { staticClass: "vue-from-block" },
                  [
                    _c("div", { staticClass: "form-div" }, [
                      _vm._v("终端名称:"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: _vm.scanData.dealerName,
                          placement: "top-start",
                        },
                      },
                      [
                        _c("div", { staticClass: "content" }, [
                          _vm._v(_vm._s(_vm.scanData.dealerName)),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("产品编码:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.scanData.productCode)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("产品名称:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.scanData.productName)),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "middleLine" }),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "search",
              staticClass: "search-condition",
              attrs: {
                model: _vm.search,
                "status-icon": "",
                "label-position": _vm.formConfig.labelPosition,
                "label-width": _vm.formConfig.labelWidth,
              },
            },
            [
              _c(
                "div",
                { staticClass: "cols" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.handleShow,
                              expression: "handleShow",
                            },
                          ],
                          attrs: { span: 6, prop: "statementTimeStart" },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "statementTimeStart",
                                label: "单据起始日期:",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "date",
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "请选择日期",
                                },
                                model: {
                                  value: _vm.search.statementTimeStart,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.search,
                                      "statementTimeStart",
                                      $$v
                                    )
                                  },
                                  expression: "search.statementTimeStart",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.handleShow,
                              expression: "handleShow",
                            },
                          ],
                          attrs: { span: 6, prop: "statementTimeEnd" },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "statementTimeEnd",
                                label: "单据结束日期:",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "date",
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "请选择日期",
                                },
                                model: {
                                  value: _vm.search.statementTimeEnd,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.search,
                                      "statementTimeEnd",
                                      $$v
                                    )
                                  },
                                  expression: "search.statementTimeEnd",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.handleShow,
                              expression: "handleShow",
                            },
                          ],
                          attrs: { span: 6, prop: "fhStr" },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { prop: "sendOrgId", label: "发货单位:" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  "suffix-icon": "el-icon-search",
                                  clearable: "",
                                  placeholder: "请输入",
                                },
                                on: {
                                  focus: function ($event) {
                                    return _vm.getChannel("sendOrgId")
                                  },
                                },
                                model: {
                                  value: _vm.fhStr,
                                  callback: function ($$v) {
                                    _vm.fhStr = $$v
                                  },
                                  expression: "fhStr",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6, prop: "shStr" } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "recOrgId", label: "收货单位:" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  "suffix-icon": "el-icon-search",
                                  clearable: "",
                                  placeholder: "请输入",
                                },
                                on: {
                                  focus: function ($event) {
                                    return _vm.getChannel("recOrgId")
                                  },
                                },
                                model: {
                                  value: _vm.shStr,
                                  callback: function ($$v) {
                                    _vm.shStr = $$v
                                  },
                                  expression: "shStr",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6, prop: "outgoType" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "outgoType",
                                label: "出入库类型:",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { clearable: "" },
                                  model: {
                                    value: _vm.search.outgoType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.search, "outgoType", $$v)
                                    },
                                    expression: "search.outgoType",
                                  },
                                },
                                _vm._l(_vm.outgoTypes, function (item) {
                                  return _c("el-option", {
                                    key: item.code,
                                    attrs: {
                                      label: item.name,
                                      value: item.code,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6, prop: "billType" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "outStartDate",
                                label: "单据类型:",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { clearable: "" },
                                  model: {
                                    value: _vm.search.billType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.search, "billType", $$v)
                                    },
                                    expression: "search.billType",
                                  },
                                },
                                _vm._l(_vm.billTypes, function (item) {
                                  return _c("el-option", {
                                    key: item.code,
                                    attrs: {
                                      label: item.name,
                                      value: item.code,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.formConfig.btnFollow
                        ? _c(
                            "el-col",
                            {
                              staticClass: "head-btn-group",
                              staticStyle: {
                                width: "240px",
                                "padding-left": "0",
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { "label-width": "0" } },
                                [
                                  _c(
                                    "div",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [_vm._v(" ")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.searchBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.searchBtn.icon,
                                          },
                                          on: { click: _vm.searchList },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.searchBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.resetBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.resetBtn.icon,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.resetList(_vm.search)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.resetBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              _vm.handleShow = !_vm.handleShow
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.handleShow ? "收起" : "展开"
                                              ) +
                                              "\n                    "
                                          ),
                                          _c("i", {
                                            class: _vm.handleShow
                                              ? "el-icon-arrow-up"
                                              : "el-icon-arrow-down",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "btnArea" },
            [
              !_vm.formConfig.btnFollow
                ? _c(
                    "el-col",
                    {
                      staticClass: "head-btn-group",
                      staticStyle: { width: "230px" },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: _vm.btnConfig.searchBtn.type,
                            size: _vm.btnConfig.size,
                            icon: _vm.btnConfig.searchBtn.icon,
                          },
                          on: { click: _vm.searchList },
                        },
                        [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                      ),
                      _vm._v(" "),
                      _c("el-divider", { attrs: { direction: "vertical" } }),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: _vm.btnConfig.resetBtn.type,
                            size: _vm.btnConfig.size,
                            icon: _vm.btnConfig.resetBtn.icon,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.resetList(_vm.search)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                      ),
                      _vm._v(" "),
                      _c("el-divider", { attrs: { direction: "vertical" } }),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              _vm.handleShow = !_vm.handleShow
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.handleShow ? "收起" : "展开") +
                              "\n            "
                          ),
                          _c("i", {
                            class: _vm.handleShow
                              ? "el-icon-arrow-up"
                              : "el-icon-arrow-down",
                          }),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading2,
                  expression: "listLoading2",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.list2,
                border: _vm.tableConfig.border,
                stripe: _vm.tableConfig.stripe,
                fit: "",
                "max-height": "500",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "单号",
                  "min-width": "160",
                  prop: "billNo",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "出入库类型",
                  prop: "billClass",
                  "min-width": "100",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.billClass == "1"
                          ? _c("span", [_vm._v("入库")])
                          : _vm._e(),
                        _vm._v(" "),
                        row.billClass == "2"
                          ? _c("span", [_vm._v("出库")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "单据类型",
                  prop: "billEnum",
                  "min-width": "120",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.billType == "103"
                          ? _c("span", [_vm._v("退货入库")])
                          : _vm._e(),
                        _vm._v(" "),
                        row.billType == "104"
                          ? _c("span", [_vm._v("采购入库")])
                          : _vm._e(),
                        _vm._v(" "),
                        row.billType == "203"
                          ? _c("span", [_vm._v("销售出库")])
                          : _vm._e(),
                        _vm._v(" "),
                        row.billType == "204"
                          ? _c("span", [_vm._v("退货出库")])
                          : _vm._e(),
                        _vm._v(" "),
                        row.billType == "205"
                          ? _c("span", [_vm._v("其他出库")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "应收发数量(盒/瓶)",
                  prop: "planScanAtoNum",
                  "min-width": "130",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "实收发数量(盒/瓶)",
                  prop: "realScanAtoNum",
                  "min-width": "130",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "发货单位",
                  prop: "outOrgName",
                  "min-width": "100",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "收货单位",
                  prop: "inOrgName",
                  "min-width": "100",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "单据日期",
                  prop: "statementTime",
                  "min-width": "100",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total2,
              page: _vm.search.pageNo,
              limit: _vm.search.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.search, "pageNo", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.search, "pageSize", $event)
              },
              pagination: _vm.getList2,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("purchaseDialog", {
        ref: "dwSelect",
        attrs: { title: "选择单位" },
        on: { change: _vm.getUalue },
      }),
      _vm._v(" "),
      _c("instockUnit", {
        ref: "channelSelect",
        attrs: { "channel-type": 2, "channel-type-can-change": true },
        on: { change: _vm.getUalue },
      }),
      _vm._v(" "),
      _c("productChose", { ref: "selProduct", on: { change: _vm.getProduct } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n      "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }