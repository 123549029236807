"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actAddReserve = actAddReserve;
exports.actDisable = actDisable;
exports.actProcessAndTemplates = actProcessAndTemplates;
exports.actconfigAdd = actconfigAdd;
exports.actconfigCopy = actconfigCopy;
exports.addWechatActConfig = addWechatActConfig;
exports.batchUpdateBase = batchUpdateBase;
exports.batchUpdatePr = batchUpdatePr;
exports.batchUpdatePrValid = batchUpdatePrValid;
exports.batchUpdatePrize = batchUpdatePrize;
exports.creatPrList = creatPrList;
exports.del = del;
exports.delActPr = delActPr;
exports.detailActPr = detailActPr;
exports.detailpage = detailpage;
exports.endAct = endAct;
exports.exportExcel = exportExcel;
exports.geActList = geActList;
exports.geActPrList = geActPrList;
exports.getAudit = getAudit;
exports.getBatchUpdatePrize = getBatchUpdatePrize;
exports.getChosenProduct = getChosenProduct;
exports.getComboTemplateStoreroom = getComboTemplateStoreroom;
exports.getConnectedActivities = getConnectedActivities;
exports.getMoneySource = getMoneySource;
exports.getOrderActList = getOrderActList;
exports.getPreview = getPreview;
exports.getPrizeLists = getPrizeLists;
exports.getProcess = getProcess;
exports.getProduct = getProduct;
exports.getProductBatch = getProductBatch;
exports.getStrategyGroup = getStrategyGroup;
exports.getorderNum = getorderNum;
exports.getshapanList = getshapanList;
exports.getsoldExcelin = getsoldExcelin;
exports.initiateAudit = initiateAudit;
exports.isExistConsumeWechat = isExistConsumeWechat;
exports.postAudit = postAudit;
exports.prizeManage = prizeManage;
exports.rankDown = rankDown;
exports.rankUp = rankUp;
exports.schemeNoForPage = schemeNoForPage;
exports.soldExcelin = soldExcelin;
exports.update = update;
exports.updateActPr = updateActPr;
var _request = _interopRequireDefault(require("@/utils/request"));
var baseUrl = '';
// const base = ''
//  获取活动列表
function geActList(param) {
  return (0, _request.default)({
    url: baseUrl + '/market-config/activityConfig/page',
    method: 'get',
    params: param
  });
}

// 策略组
function getStrategyGroup() {
  return (0, _request.default)({
    url: baseUrl + '/market-config/strategyGroup/comboBox',
    method: 'post'
  });
}
// 策略组与活动页相关联
function getConnectedActivities(params) {
  return (0, _request.default)({
    url: baseUrl + '/market-config/activityConfig/strategyGroup/activityPage',
    method: 'get',
    params: params
  });
}
// 资金来源
function getMoneySource() {
  return (0, _request.default)({
    url: baseUrl + '/market-config/officialFundConfiguration/fundConfigurationComboBox',
    method: 'get'
  });
}

// 活动流程
function getProcess() {
  return (0, _request.default)({
    url: baseUrl + '/market-config/process/comboBox',
    method: 'post'
  });
}

// 活动模板
function getComboTemplateStoreroom(param) {
  return (0, _request.default)({
    url: baseUrl + '/market-config/activityTemplateInfo/getComboTemplateStoreroom',
    method: 'get',
    params: param
  });
}

// 奖品列表
function getPrizeLists(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-config/prize/getPrizeListAll',
    method: 'post',
    data: data
  });
}

// 验证--批量编辑概率规则
function batchUpdatePrValid(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-config/activityPrConfig/batchUpdatePr/valid',
    method: 'post',
    data: data
  });
}

// 批量编辑概率规则
function batchUpdatePr(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-config/activityPrConfig/batchUpdatePr',
    method: 'post',
    data: data
  });
}
function getBatchUpdatePrize(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-config/activityConfig/batchUpdatePrize/valid',
    method: 'post',
    data: data
  });
}

// 更新批量编辑活动基础设置
function batchUpdateBase(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-config/activityConfig/batchUpdateBase',
    method: 'post',
    data: data
  });
}
function batchUpdatePrize(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-config/activityConfig/batchUpdatePrize',
    method: 'post',
    data: data
  });
}

// 导出活动
function exportExcel(params) {
  return (0, _request.default)({
    url: baseUrl + '/market-config/activityConfig/exportExcel',
    method: 'get',
    responseType: 'blob',
    params: params
  });
}

// 修改活动配置
function update(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-config/activityConfig/update',
    method: 'POST',
    data: data
  });
}
// 添加活动
function actconfigAdd(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-config/activityConfig/create',
    method: 'POST',
    data: data
  });
}

// 复制活动
function actconfigCopy(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-config/activityConfig/copyCreate',
    method: 'POST',
    data: data
  });
}
// 活动详情
function detailpage(params) {
  return (0, _request.default)({
    url: baseUrl + '/market-config/activityConfig/detail',
    method: 'get',
    params: params
  });
}

// 启用停用活动
function actDisable(params) {
  return (0, _request.default)({
    url: baseUrl + '/market-config/activityConfig/onOrOff',
    method: 'get',
    params: params
  });
}

// 概率分页查询
function geActPrList(params) {
  return (0, _request.default)({
    url: baseUrl + '/market-config/activityPrConfig/page',
    method: 'get',
    params: params
  });
}

// 新建概率
function creatPrList(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-config/activityPrConfig/create',
    method: 'post',
    data: data
  });
}

// 删除活动概率
function delActPr(param) {
  return (0, _request.default)({
    url: baseUrl + '/market-config/activityPrConfig/delete',
    method: 'delete',
    params: param
  });
}

// 活动概率升序
function rankUp(param) {
  return (0, _request.default)({
    url: baseUrl + '/market-config/activityPrConfig/up',
    method: 'get',
    params: param
  });
}

// 活动概率降序
function rankDown(param) {
  return (0, _request.default)({
    url: baseUrl + '/market-config/activityPrConfig/down',
    method: 'get',
    params: param
  });
}

// 活动概率详情
function detailActPr(param) {
  return (0, _request.default)({
    url: baseUrl + '/market-config/activityPrConfig/detail',
    method: 'get',
    params: param
  });
}

// 活动概率编辑
function updateActPr(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-config/activityPrConfig/update',
    method: 'post',
    data: data
  });
}

// 获取概览数据
function getPreview(param) {
  return (0, _request.default)({
    url: baseUrl + '/market-config/activityConfig/preview',
    method: 'get',
    params: param
  });
}

// 活动删除
function del(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/actconfig/del',
    method: 'delete',
    params: params
  });
}

//  结束活动
function endAct(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/actconfig/endAct',
    method: 'PUT',
    params: params
  });
}
//  获取产品列表
function getProduct(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/actactivityRange/getProduct',
    method: 'get',
    params: params
  });
}
// 获取活动已选择产品
function getChosenProduct(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/actactivityRange/getChosenProduct',
    method: 'get',
    params: params
  });
}
// 获活动产品批次
function getProductBatch(params) {
  return (0, _request.default)({
    url: baseUrl + '/storage-center/ccProductOrder/marketing/batchForPage',
    method: 'post',
    params: {
      current: params.current,
      size: params.size
    },
    data: {
      batchNo: params.batchNo,
      productIdList: params.productIdList
    }
  });
}
// /actconfig/prizeManage 奖品管理
function prizeManage(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/actconfig/prizeManage?actNo=' + data,
    method: 'POST',
    data: data
  });
}
// 增加库存
function actAddReserve(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/actconfig/actAddReserve?actConfigItemId=' + data.actConfigItemId + '&num=' + data.num,
    method: 'POST',
    data: data
  });
}

// 是否存在消费者公众号
function isExistConsumeWechat(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/wechat/official/isExistConsumeWechat',
    method: 'get',
    params: params
  });
}

// 创建一物一码活动
function addWechatActConfig(data) {
  return (0, _request.default)({
    url: baseUrl + '/WechatActConfig/addConfig',
    method: 'POST',
    data: data
  });
}

// 获取订单列表
function getOrderActList(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/actconfig/getOrderActList',
    method: 'POST',
    data: data
  });
}

// 获取订单已使用数码数量
function getorderNum(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/actconfig/getorderNum',
    method: 'get',
    params: params
  });
}

// 发起审核
function initiateAudit(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/actconfig/initiate',
    method: 'post',
    data: params
  });
}

// 活动审核
function postAudit(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/actconfig/audit',
    method: 'post',
    data: params
  });
}

// 获取活动审核记录
function getAudit(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/actconfig/getAudit',
    method: 'post',
    data: params
  });
}

// 导出活动记录
function soldExcelin(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/actconfig/actlistExport',
    method: 'post',
    data: params,
    responseType: 'blob' // 解决文件下载乱码问题
  });
}

// 导出订单记录
function getsoldExcelin(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/actconfig/getOrderActListExport',
    method: 'post',
    data: params,
    responseType: 'blob' // 解决文件下载乱码问题
  });
}

function actProcessAndTemplates(params) {
  return (0, _request.default)({
    url: baseUrl + '/market-config/activityTemplateInfo/actProcessAndTemplates',
    method: 'post',
    params: params
  });
}

// 营销活动创建-方案编码分页查询单据批次
function schemeNoForPage(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-prod/ccProductOrder/marketing/schemeNoForPage',
    method: 'POST',
    data: data
  });
}

// 沙盘地图接口
function getshapanList(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-config/activityPrizeRecordView/getData',
    method: 'post',
    data: data
  });
}