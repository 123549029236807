var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _c(
      "div",
      { staticClass: "page-section", staticStyle: { padding: "20px" } },
      [
        _c(
          "el-checkbox",
          {
            model: {
              value: _vm.checked,
              callback: function ($$v) {
                _vm.checked = $$v
              },
              expression: "checked",
            },
          },
          [_vm._v("启用无效产品默认页")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }