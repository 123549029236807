var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        "append-to-body": true,
        title: "买赠方案",
        visible: _vm.proSelVisible,
        "before-close": _vm.selCancel,
        width: "1200px !important",
      },
      on: {
        "update:visible": function ($event) {
          _vm.proSelVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "detail-head", staticStyle: { "padding-top": "5px" } },
        [
          _c("img", { attrs: { src: require("../../images/icon-5.png") } }),
          _vm._v(" "),
          _c("span", { staticClass: "title" }, [
            _vm._v("\n      " + _vm._s(_vm.detailData.schemeName) + "\n      "),
            _c("span", { staticStyle: { "margin-left": "15px" } }, [
              _vm._v(_vm._s(_vm.detailData.schemeCode)),
            ]),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "dstatus" }, [
            _vm._v(_vm._s(_vm._f("state")(_vm.detailData.schemeState))),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "cols basic" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 30 } },
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _vm.detailData.schemeDescribe
                  ? _c("div", { staticClass: "mark" }, [
                      _vm._v(_vm._s(_vm.detailData.schemeDescribe)),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "form-label" }, [
                  _vm._v("关联政策："),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "border" }, [
                  _c(
                    "span",
                    {
                      staticClass: "scanpolicy",
                      on: { click: _vm.scanPolicy },
                    },
                    [_vm._v(_vm._s(_vm.detailData.policyName))]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "form-label" }, [
                  _vm._v("方案时间："),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "border" }, [
                  _vm._v(
                    _vm._s(_vm.detailData.beginTime) +
                      " - " +
                      _vm._s(_vm.detailData.endTime)
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "products margin-top-20" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 16 } }, [
                _c("div", { staticClass: "detail-container" }, [
                  _c("div", { staticClass: "title border-bottom" }, [
                    _c("img", {
                      attrs: { src: require("../../images/icon-6.png") },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("参与产品")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "table-container" },
                    [
                      _c(
                        "el-table",
                        {
                          attrs: {
                            data: _vm.proList,
                            fit: "",
                            "max-height": "300",
                            "highlight-current-row": "",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "序号",
                              width: "80",
                              type: "index",
                              align: "left",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "产品编码",
                              prop: "productCode",
                              align: "left",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "产品名称",
                              prop: "productName",
                              align: "left",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "产品分类",
                              prop: "productClass",
                              align: "left",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "买赠计量单位",
                              prop: "packScaleName",
                              align: "left",
                              "show-overflow-tooltip": "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "detail-container" }, [
                  _c("div", { staticClass: "title" }, [
                    _c("img", {
                      attrs: { src: require("../../images/icon-5.png") },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("买赠范围")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "tree-contianer" },
                    [
                      _c("el-tree", {
                        attrs: {
                          data: _vm.treeData,
                          "default-expand-all": "",
                          "show-checkbox": "",
                          "node-key": "id",
                          "default-checked-keys": _vm.checkedKeys,
                          props: _vm.defaultProps,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "products margin-top-20" }, [
        _c("div", { staticClass: "detail-container" }, [
          _c("div", { staticClass: "title border-bottom" }, [
            _c("img", { attrs: { src: require("../../images/icon-7.png") } }),
            _vm._v(" "),
            _c("span", {}, [_vm._v("返利配置")]),
            _vm._v(" "),
            _c("div", { staticClass: "rightspan" }, [
              _c("div", { staticClass: "cell" }, [
                _c("div", { staticClass: "txt-color" }, [
                  _vm._v("触发返利条件：下订单"),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "config-container" },
            [
              _c(
                "el-row",
                { attrs: { type: "flex" } },
                [
                  _c(
                    "el-col",
                    { staticClass: "border-right", attrs: { span: 3 } },
                    [
                      _c(
                        "ul",
                        { staticClass: "nav" },
                        _vm._l(_vm.grads, function (item, index) {
                          return _c(
                            "li",
                            {
                              key: index,
                              class: { active: _vm.isActive == item.type },
                              on: {
                                click: function ($event) {
                                  return _vm.getCurList(item.type)
                                },
                              },
                            },
                            [_vm._v(_vm._s(item.name))]
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 21 } },
                    [
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%", "margin-top": "1px" },
                          attrs: {
                            data: _vm.configData,
                            fit: "",
                            height: "320",
                            "highlight-current-row": "",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "渠道类型",
                              prop: "channelType",
                              align: "center",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "渠道分类",
                              prop: "className",
                              align: "center",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "渠道等级",
                              prop: "gradeName",
                              align: "center",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "买赠类型",
                              prop: "ruleType",
                              align: "center",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "买赠数量",
                              prop: "desc",
                              align: "center",
                              "show-overflow-tooltip": "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("scanZc", {
        attrs: {
          "policy-code": _vm.detailData.policyCode,
          "detail-visible": _vm.policyVisible,
        },
        on: { close: _vm.closeScan },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }