"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _org = require("@/api/basic/org");
var _message = require("@/api/systems/message");
var _utils = require("@/utils");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _noticeDetail = _interopRequireDefault(require("@/components/noticeDetail"));
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SystemmessageManageannouncementManagementindex',
  components: {
    Treeselect: _vueTreeselect.default,
    Pagination: _Pagination.default,
    NoticeDetail: _noticeDetail.default
  },
  filters: {
    noticeType: function noticeType(status) {
      if (!String(status)) return '';
      var obj = {
        1: '系统公告'
      };
      return obj[status];
    },
    noticeStatus: function noticeStatus(status) {
      if (!String(status)) return '';
      var obj = {
        0: '删除',
        1: '编辑中',
        2: '待发送',
        3: '已发送'
      };
      return obj[status];
    }
  },
  data: function data() {
    return {
      pickerOption: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < new Date().getTime();
        }
      },
      handleShow: false,
      detailVisible: false,
      id: '',
      listQuery: {
        current: 1,
        size: 20,
        noticeContent: '',
        noticeStatus: '',
        noticeTitle: '',
        orgId: null,
        startTime: ''
      },
      list: [],
      typeList: [{
        figure: 1,
        name: '系统公告'
      }],
      statusList: [{
        figure: 0,
        name: '删除'
      }, {
        figure: 1,
        name: '编辑中'
      }, {
        figure: 2,
        name: '待发送'
      }, {
        figure: 3,
        name: '已发送'
      }],
      orgOptions: [],
      total: 0,
      listLoading: false,
      tabHeight: '100%',
      dialogVisible: false,
      scanData: {
        noticeType: '',
        noticeTitle: '',
        noticeContent: '',
        startTime: '',
        gradeStatus: '',
        orgIds: null
      },
      title: '公告创建',
      mloading: false,
      btnLoading: false,
      rules: {
        noticeType: [{
          required: true,
          message: '公告类型不能为空，请维护',
          trigger: 'change'
        }],
        noticeTitle: [{
          required: true,
          message: '公告标题不能为空，请维护',
          trigger: 'change'
        }],
        orgIds: [{
          required: true,
          message: '公告范围不能为空，请维护',
          trigger: 'change'
        }],
        noticeContent: [{
          required: true,
          message: '公告内容不能为空，请维护',
          trigger: 'change'
        }]
        // gradeStatus: [
        //   {
        //     required: true,
        //     message: '请维护',
        //     trigger: 'change',
        //   },
        // ],
      }
    };
  },
  mounted: function mounted() {
    var h = (0, _utils.tableHeight)();
    this.$nextTick(function () {
      this.tabHeight = h;
    });
  },
  activated: function activated() {
    this.getOrg();
    this.getList();
  },
  methods: {
    getOrg: function getOrg() {
      var that = this;
      (0, _org.fetchOrgList)({}).then(function (res) {
        if (res.data.code == 200) {
          var rData = res.data.data;
          var arr = [];
          if (rData.length >= 0) {
            rData.forEach(function (item) {
              var obj = {};
              obj.id = item.pid;
              obj.label = item.orgName;
              obj.pid = item.pid;
              obj.parentPid = item.parentPid;
              arr.push(obj);
            });
            that.orgOptions = (0, _utils.transTreeData)(arr, 'pid', 'parentPid', 'children');
          }
        }
      });
    },
    getList: function getList() {
      var that = this;
      that.listLoading = true;
      (0, _message.noticeConfiglist)(that.listQuery).then(function (response) {
        that.listLoading = false;
        var res = response.data;
        if (res.code == 200) {
          that.list = res.data.records;
          that.total = parseInt(res.data.total);
        } else {
          that.$message.error(res.msg);
        }
      }).catch(function (res) {
        that.listLoading = false;
      });
    },
    submitForm: function submitForm() {
      var that = this;
      that.listQuery.current = 1;
      that.getList();
    },
    resetForm: function resetForm(formName) {
      this.listQuery = Object.assign(this.listQuery, {
        noticeContent: '',
        noticeStatus: '',
        noticeTitle: '',
        orgId: null,
        startTime: '',
        current: 1
      });
      this.listQuery.current = 1;
      this.getList();
    },
    cellClassName: function cellClassName(_ref) {
      var row = _ref.row,
        column = _ref.column;
      if (column.property === 'startTime' && row.noticeStatus == 2) {
        console.log(111);
        return 'red';
      }
    },
    detailShow: function detailShow(id) {
      this.id = id;
      this.detailVisible = true;
    },
    detailClose: function detailClose() {
      this.id = '';
      this.detailVisible = false;
    },
    dialogClose: function dialogClose() {
      this.scanData = {
        noticeType: '',
        noticeTitle: '',
        noticeContent: '',
        startTime: '',
        gradeStatus: '',
        orgIds: null
      };
      this.$refs.scanForm.resetFields();
      this.dialogVisible = false;
    },
    add: function add() {
      this.title = '公告创建';
      this.dialogVisible = true;
    },
    update: function update(id) {
      var _this = this;
      this.title = '公告编辑';
      this.mLoading = true;
      this.dialogVisible = true;
      (0, _message.noticeDetail)({
        pId: id
      }).then(function (res) {
        _this.mLoading = false;
        var data = res.data;
        if (data.code == 200) {
          _this.scanData.pid = data.data.noticeDetail.pid;
          _this.scanData.noticeType = data.data.noticeDetail.noticeType;
          _this.scanData.noticeTitle = data.data.noticeDetail.noticeTitle;
          _this.scanData.noticeContent = data.data.noticeDetail.noticeContent;
          _this.scanData.startTime = data.data.noticeDetail.startTime;
          _this.scanData.gradeStatus = data.data.noticeDetail.gradeStatus;
          _this.scanData.noticeStatus = data.data.noticeDetail.noticeStatus;
          var arr = [];
          data.data.orgInfoVos.forEach(function (val) {
            arr.push(val.orgId);
          });
          _this.scanData.orgIds = arr;
        } else {
          _this.$message.error(data.msg);
        }
      }).catch(function () {
        _this.mLoading = false;
      });
    },
    changeRadio: function changeRadio(val) {
      if (val == 0) {
        this.scanData.startTime = '';
      }
    },
    beforeSubmit: function beforeSubmit() {
      var _this2 = this;
      this.$refs.scanForm.validate(function (valid) {
        if (valid) {
          if (_this2.scanData.gradeStatus === '') {
            _this2.$message.error('请选择发布方式');
            return;
          }
          _this2.$confirm('请确认是否发布该公告', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {
            _this2.submit();
          }).catch(function () {});
        }
      });
    },
    submit: function submit(type) {
      var _this3 = this;
      this.$refs.scanForm.validate(function (valid) {
        if (valid) {
          _this3.scanData.noticeStatus = type || (_this3.scanData.gradeStatus == 1 ? 2 : 3);
          if (_this3.scanData.gradeStatus == 1 && !_this3.scanData.startTime) {
            _this3.$message.error('请选择发布时间');
            return;
          }
          _this3.btnLoading = true;
          if (_this3.scanData.pid) {
            (0, _message.noticeConfigupdate)(_this3.scanData).then(function (res) {
              _this3.btnLoading = false;
              var data = res.data;
              if (data.code == 200) {
                _this3.getList();
                _this3.dialogClose();
              } else {
                _this3.$message.error(data.msg);
              }
            }).catch(function () {
              _this3.btnLoading = false;
            });
          } else {
            (0, _message.noticeConfiginsert)(_this3.scanData).then(function (res) {
              _this3.btnLoading = false;
              var data = res.data;
              if (data.code == 200) {
                _this3.getList();
                _this3.dialogClose();
              } else {
                _this3.$message.error(data.msg);
              }
            }).catch(function () {
              _this3.btnLoading = false;
            });
          }
        } else {
          _this3.$message.error('请完善信息');
        }
      });
    },
    changStatue: function changStatue(id, type) {
      var _this4 = this;
      var that = this;
      var msg;
      if (type == 0) {
        msg = '确认删除?将不可撤销';
      } else {
        msg = '请确认是否发布该公告';
      }
      that.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        // var auth = this.authId('deletebtn', this.$route.meta.authList)
        (0, _message.noticeConfigenable)({
          isDelete: type,
          pId: id
        }).then(function (res) {
          // var auth = this.authId('searchbtn', this.$route.meta.authList)
          if (res.data.code == 200) {
            _this4.$message({
              type: 'success',
              message: '操作成功!'
            });
            that.getList();
          } else {
            _this4.$message({
              type: 'info',
              message: res.data.msg
            });
          }
        });
      }).catch(function () {
        // var auth = this.authId('searchbtn', this.$route.meta.authList)
      });
    }
  }
};
exports.default = _default;