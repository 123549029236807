"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _chanelapp = require("@/api/chanelmange/chanelapp");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { getAuthBtn } from '@/utils'
var cachName = 'chanelmangebasicsetchanelappindex';
var _default = {
  name: 'Chanelmangebasicsetchanelappindex',
  data: function data() {
    return {
      dataList: [],
      isRequired: true,
      loading: false,
      page2: false,
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      apis: []
    };
  },
  activated: function activated() {
    var that = this;
    var hasCache = that.dataList.length > 0;
    that.apis = that.$route.meta.authList;
    if (!hasCache) {
      that.getList();
    }
  },
  // 缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = that.getCaches(that, name);
    if (!cache) {
      that.dataList = [];
    }
  },
  methods: {
    // 获取所有app
    getList: function getList() {
      var that = this;
      var auth = that.hasAuth('searchbtn', that.apis);
      if (!auth) {
        return;
      }
      that.authId('searchbtn', that.apis);
      (0, _chanelapp.getAllApp)().then(function (res) {
        if (res.data.code == 200) {
          that.dataList = res.data.data;
        } else {
          that.dataList = [];
        }
      });
    },
    // 图片上传
    handleAvatarSuccess: function handleAvatarSuccess(obj, file) {
      var that = this;
      that.authId('uploadbtn', that.apis);
      var index = obj.index;
      var isJPG = file.raw.type === 'image/jpeg';
      var isPNG = file.raw.type === 'image/png';
      var isLtSize = file.raw.size / 1024 / 1024 <= 1;
      if (!isJPG && !isPNG) {
        that.$message.error('上传产品图片只能是 JPG/PNG 格式!');
        return;
      }
      if (!isLtSize) {
        that.$message.error('上传产品图片大小不能超过 1M!');
        return;
      }
      var formData = new FormData();
      formData.append('file', file.raw);
      (0, _chanelapp.upLoadImg)(formData).then(function (res) {
        if (res.data.code == 200) {
          that.dataList[index].images = res.data.data;
        } else {
          that.$message.error(res.data.msg + '，请重新上传！');
          return;
        }
      });
    },
    // 新增
    DoAdd: function DoAdd() {
      var that = this;
      var auth = that.hasAuth('uploadbtn', that.apis);
      if (!auth) {
        that.$message.error('抱歉！您没有上传权限，无法上传');
        return;
      }
      var reg = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\*\+,;=.]+$/;
      var obj = {
        images: '',
        pid: '',
        srcHref: ''
      };
      if (that.dataList.length >= 5) {
        that.$message.error('您已添加5张，最多可添加5张');
        return;
      } else {
        for (var i = 0; i < that.dataList.length; i++) {
          var item = that.dataList[i];
          if (item.images == '') {
            that.$message.error('请在第' + (i + 1) + '行上传图片');
            return;
          }
          if (item.srcHref != '' && item.srcHref != null) {
            if (!reg.test(item.srcHref)) {
              that.$message.error('请在第' + (i + 1) + '行输入正确的链接');
              return;
            }
          }
        }
        that.dataList.push(obj);
      }
    },
    // 删除
    DoDelete: function DoDelete(index) {
      var that = this;
      that.$confirm('确定删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        that.dataList.splice(index, 1);
      }).catch(function () {
        console.log('已取消');
      });
    },
    // 排序
    sortUp: function sortUp(index) {
      var that = this;
      var newArr = [];
      var origin = that.dataList;
      origin.forEach(function (item) {
        newArr.push(item);
      });
      if (index - 1 >= 0) {
        newArr[index - 1] = origin[index];
        newArr[index] = origin[index - 1];
        that.dataList = newArr;
      }
    },
    sortDown: function sortDown(index) {
      var that = this;
      var newArr = [];
      var origin = that.dataList;
      origin.forEach(function (item) {
        newArr.push(item);
      });
      if (index >= 0 && index < 4) {
        newArr[index + 1] = origin[index];
        newArr[index] = origin[index + 1];
        that.dataList = newArr;
      }
    },
    // 保存
    DoSave: function DoSave() {
      var that = this;
      var reg = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\*\+,;=.]+$/;
      that.authId('updatebtn', that.apis);
      for (var i = 0; i < that.dataList.length; i++) {
        var item = that.dataList[i];
        if (item.images == '') {
          that.$message.error('请在第' + (i + 1) + '行上传图片');
          return;
        }
        if (item.srcHref != '' && item.srcHref != null) {
          if (!reg.test(item.srcHref)) {
            that.$message.error('请在第' + (i + 1) + '行输入正确的链接');
            return;
          }
        }
      }
      that.loading = true;
      var param = [];
      if (that.dataList.length > 0) {
        that.dataList.forEach(function (item) {
          var obj = {};
          obj.images = item.images;
          obj.pid = item.pid;
          obj.srcHref = item.srcHref;
          param.push(obj);
        });
      }
      (0, _chanelapp.saveParams)(param).then(function (res) {
        if (res.data.code == 200) {
          that.loading = false;
          that.$message({
            type: 'success',
            message: '保存成功'
          });
          that.getList();
        } else {
          that.loading = false;
          that.$message.error(res.data.msg);
        }
      }).catch(function () {
        that.loading = false;
      });
    }
  }
};
exports.default = _default;