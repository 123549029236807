"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.search");
require("core-js/modules/web.dom.iterable");
var _defineProperty2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _stockscan = require("@/api/chanelmange/stockscan");
var _product = require("@/api/basic/product");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import instockUnit from './component/instockUnit'
// import productChose from './component/productChose'
// import personChose from './component/personChose'
var _default = {
  name: "tgdan",
  components: {
    Pagination: _Pagination.default,
    Treeselect: _vueTreeselect.default
    // instockUnit,
    // productChose,
    // personChose,
  },
  data: function data() {
    var _ref;
    return _ref = {
      listQuery: {
        channelId: "",
        isAsc: "",
        isDisplayZeroExistnum: 0,
        orderbyColName: "",
        orgId: null,
        pageNo: 1,
        pageSize: 20,
        productId: "",
        productTypeId: null
      },
      productName: "",
      productShow: false,
      orgOptions: [],
      activeVal: 1,
      inactiveVal: 0,
      channelStr: "",
      tableKey: "jxskc",
      tabHeight: "100%",
      list: [],
      listLoading: false,
      total: 0,
      search: {
        billStatus: "",
        billType: "",
        channelId: "",
        isCode: "",
        outgoType: "",
        recDateStart: "",
        recDateEnd: "",
        sendDateStart: "",
        sendDateEnd: "",
        recOrgId: "",
        sendOrgId: "",
        pageNo: 1,
        pageSize: 10
      },
      list2: [],
      total2: 0,
      billStats: [{
        name: "删除",
        code: 0
      }, {
        name: "待提交",
        code: 1
      }, {
        name: "待审核",
        code: 2
      }, {
        name: "部分入库",
        code: 3
      }, {
        name: "全部入库",
        code: 4
      }],
      billTypes: [{
        name: "期初入库",
        code: 1
      }, {
        name: "采购入库",
        code: 2
      }, {
        name: "调拨入库",
        code: 3
      }, {
        name: "其他入库",
        code: 4
      }, {
        name: "销售出库",
        code: 5
      }, {
        name: "调拨出库",
        code: 6
      }, {
        name: "其他出库",
        code: 7
      }],
      outgoTypes: [{
        name: "出库",
        code: 1
      }, {
        name: "入库",
        code: 2
      }],
      isCodes: [{
        name: "是",
        code: 1
      }, {
        name: "否",
        code: 0
      }],
      scanVisible: false,
      scanData: {},
      sum1: "",
      sum2: "",
      sum3: "",
      classList: [],
      unitShow: false,
      unitType: "",
      fhStr: "",
      shStr: "",
      exportLoading: false,
      listLoading2: false
    }, (0, _defineProperty2.default)(_ref, "total2", 0), (0, _defineProperty2.default)(_ref, "apis", [{
      authBtn: true,
      resourceCode: "HQRZ"
    }]), (0, _defineProperty2.default)(_ref, "page2", false), (0, _defineProperty2.default)(_ref, "showCol", false), (0, _defineProperty2.default)(_ref, "expandTxt", "展开"), _ref;
  },
  created: function created() {
    var that = this;
    that.getOrg();
    that.getList();
    that.getClass();
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  filters: {
    formatTime: function formatTime(value) {
      if (value == "" || value == null) {
        return "";
      } else {
        return (0, _utils.parseTime)(value, "{y}-{m}-{d}");
      }
    }
  },
  methods: {
    getList: function getList() {
      var that = this;
      that.listLoading = true;
      (0, _stockscan.getKcList)(that.listQuery).then(function (res) {
        if (res.data.code == 200) {
          that.list = res.data.data.pages.records;
          that.total = parseInt(res.data.data.pages.total);
          that.sum1 = res.data.data.existingSumTotal;
          that.sum2 = res.data.data.availableSumTotal;
          that.sum3 = res.data.data.beingSumTotal;
          setTimeout(function () {
            that.listLoading = false;
          }, 1 * 800);
        } else {
          that.$message.error(res.data.msg);
          that.listLoading = false;
          return;
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    getOrg: function getOrg() {
      var that = this;
      (0, _stockscan.getQueryOrg)().then(function (res) {
        if (res.data.code == 200) {
          var rData = res.data.data;
          var arr = [];
          if (rData.length >= 0) {
            rData.forEach(function (item) {
              var obj = {};
              obj.id = item.pid;
              obj.label = item.orgName;
              obj.pid = item.pid;
              obj.parentPid = item.parentPid;
              arr.push(obj);
            });
            that.orgOptions = (0, _utils.transTreeData)(arr, "pid", "parentPid", "children");
          }
        }
      });
    },
    clearJxs: function clearJxs() {
      var that = this;
      that.listQuery.channelId = "";
    },
    clearPro: function clearPro() {
      var that = this;
      that.listQuery.productId = "";
    },
    DoSearch: function DoSearch() {
      var that = this;
      that.listQuery = (0, _utils.filterKeyNull)(that.listQuery);
      that.listQuery.pageNo = 1;
      that.getList();
      if (that.listQuery.orgId == "") {
        that.listQuery.orgId = null;
      }
      if (that.listQuery.productTypeId == "") {
        that.listQuery.productTypeId = null;
      }
    },
    DoReset: function DoReset(formName) {
      var that = this;
      that.listQuery = {
        orgId: null,
        channelId: "",
        productId: "",
        productTypeId: null,
        isDisplayZeroExistnum: 0,
        pageNo: 1,
        pageSize: 20
      };
      that.channelStr = "";
      that.getList();
    },
    //获取产品分类
    getClass: function getClass() {
      var that = this;
      (0, _product.productClassList)({
        classStatus: 1
      }).then(function (res) {
        if (res.data.code != 200) {
          return;
        } else {
          var rData = res.data.data;
          var arr = [];
          if (rData.length >= 0) {
            rData.forEach(function (item) {
              var obj = {};
              obj.id = item.pid;
              obj.label = item.productClassName;
              obj.pid = item.pid;
              obj.parentPid = item.parentId;
              arr.push(obj);
            });
            that.classList = (0, _utils.transTreeData)(arr, "pid", "parentPid", "children");
          }
        }
      });
    },
    //获取单位
    getUnit: function getUnit(val) {
      var that = this;
      that.unitType = val;
      that.unitShow = true;
    },
    hideUnit: function hideUnit() {
      var that = this;
      that.unitShow = false;
    },
    getUalue: function getUalue(val) {
      var that = this;
      if (that.unitType == "search") {
        that.listQuery.channelId = JSON.parse(val).pId;
        that.channelStr = JSON.parse(val).channelName;
      }
      if (that.unitType == "recOrgId") {
        that.search.recOrgId = JSON.parse(val).pId;
        that.shStr = JSON.parse(val).channelName;
      }
      if (that.unitType == "sendOrgId") {
        that.search.sendOrgId = JSON.parse(val).pId;
        that.fhStr = JSON.parse(val).channelName;
      }
      that.unitShow = false;
    },
    //获取产品
    getProductId: function getProductId() {
      var that = this;
      that.productShow = true;
    },
    hideProduct: function hideProduct() {
      var that = this;
      that.productShow = false;
    },
    getProduct: function getProduct(val) {
      var that = this;
      that.listQuery.productId = JSON.parse(val).pId;
      that.productName = JSON.parse(val).productName;
      that.productShow = false;
    },
    //导出文件流并下载
    DoExportAll: function DoExportAll() {
      var that = this;
      if (that.total > 50000) {
        that.$message.error("抱歉！您要导出的数据量超出了导出数据的上限，最多可导出50000条");
        return;
      }
      var param = {};
      param.channelId = that.listQuery.channelId;
      param.isAsc = that.listQuery.isAsc;
      param.isDisplayZeroExistnum = that.listQuery.isDisplayZeroExistnum;
      param.orderbyColName = that.listQuery.orderbyColName;
      param.orgId = that.listQuery.orgId;
      param.pageNo = that.listQuery.pageNo;
      param.pageSize = that.total;
      param.productId = that.listQuery.productId;
      param.productTypeId = that.listQuery.productTypeId;
      that.exportLoading = true;
      (0, _stockscan.exportKcList)(param).then(function (res) {
        var blob = new Blob([res.data], {
          type: "application/vnd.ms-excel;charset=utf-8"
        });
        var downloadElement = document.createElement("a");
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = "经销商库存列表.xls";
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
        that.exportLoading = false;
      }).catch(function () {
        that.exportLoading = false;
      });
    },
    //查看详情
    DoScan: function DoScan(data) {
      var that = this;
      that.scanData = (0, _utils.deepClone)(data);
      that.search.channelId = data.channelId;
      that.search.outgoType = 1;
      that.searchList();
      that.scanVisible = true;
    },
    searchList: function searchList() {
      var that = this;
      that.listLoading2 = true;
      (0, _stockscan.getKcMxList)(that.search).then(function (res) {
        if (res.data.code == 200) {
          that.list2 = res.data.data.records;
          that.total2 = parseInt(res.data.data.total);
          setTimeout(function () {
            that.listLoading2 = false;
          }, 1 * 800);
        } else {
          that.$message.error(res.data.msg);
          that.listLoading2 = false;
          return;
        }
      }).catch(function (res) {
        that.listLoading2 = false;
      });
    },
    resetList: function resetList(formName) {
      var that = this;
      that.search = {
        billStatus: "",
        billType: "",
        channelId: "",
        isCode: "",
        outgoType: "",
        recDateStart: "",
        recDateEnd: "",
        sendDateStart: "",
        sendDateEnd: "",
        recOrgId: "",
        sendOrgId: "",
        pageNo: 1,
        pageSize: 10
      };
      that.searchList();
    },
    getList2: function getList2() {
      var that = this;
      that.searchList();
    },
    //展开收起搜索条件
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = "收起";
      } else {
        that.expandTxt = "展开";
      }
    }
  }
};
exports.default = _default;