"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addGhRole = addGhRole;
exports.addRole = addRole;
exports.checkRequiredWord = void 0;
exports.editGhRole = editGhRole;
exports.editRole = editRole;
exports.getGhAuths = getGhAuths;
exports.getGhRoleAuth = getGhRoleAuth;
exports.getGyData = getGyData;
exports.getRoleDetail = getRoleDetail;
exports.getRoleList = getRoleList;
exports.rankRoleAuths = rankRoleAuths;
exports.startItems = startItems;
exports.startRole = startRole;
exports.stopItems = stopItems;
exports.stopRole = stopRole;
var _request = _interopRequireDefault(require("@/utils/request"));
// 获取供货角色列表
function getRoleList(param) {
  return (0, _request.default)({
    url: '/api-user/roleInfo/getGhForList',
    method: 'get',
    params: param
  });
}

// 获取供应商类型
function getGyData() {
  return (0, _request.default)({
    url: '/api-user/user/comboBox/getGhType',
    method: 'get'
  });
}

// 添加企业
function addRole(param) {
  return (0, _request.default)({
    url: '/api-user/roleInfo/saveGh',
    method: 'post',
    params: param
  });
}

// 供货角色-添加
function addGhRole(data) {
  return (0, _request.default)({
    url: '/api-user/roleInfo/saveGh',
    method: 'post',
    data: data
  });
}

// 供货角色-编辑
function editGhRole(data) {
  return (0, _request.default)({
    url: '/api-user/roleInfo/updateGh',
    method: 'put',
    data: data
  });
}

// 批量启用
function startItems(param) {
  return (0, _request.default)({
    url: '/api-user/roleInfo/enableBatchGh',
    method: 'put',
    params: param
  });
}

// 批量禁用
function stopItems(param) {
  return (0, _request.default)({
    url: '/api-user/roleInfo/disableBatchGh',
    method: 'put',
    params: param
  });
}

// 启用
function startRole(param) {
  return (0, _request.default)({
    url: '/api-user/roleInfo/enableGh',
    method: 'put',
    params: param
  });
}

// 禁用
function stopRole(param) {
  return (0, _request.default)({
    url: '/api-user/roleInfo/disableGh',
    method: 'put',
    params: param
  });
}

// 企业角色修改
function editRole(param) {
  return (0, _request.default)({
    url: '/api-user/roleInfo/updateGh',
    method: 'put',
    params: param
  });
}

// 角色详情
function getRoleDetail(param) {
  return (0, _request.default)({
    url: '/api-user/roleInfo/queryGhDetail',
    method: 'get',
    params: param
  });
}

// 获取供货角色权限
function getGhAuths(param) {
  return (0, _request.default)({
    url: '/api-user/resourceInfo/queryGhResourceList',
    method: 'get',
    params: param
  });
}

// 供货指定角色权限
function getGhRoleAuth(param) {
  return (0, _request.default)({
    url: '/api-user/resourceInfo/queryGhResourceListByRoleCode',
    method: 'get',
    params: param
  });
}

// 分配权限
function rankRoleAuths(data) {
  return (0, _request.default)({
    url: '/api-user/roleInfo/giveResourceGh',
    method: 'post',
    data: data
  });
}
var checkRequiredWord = function checkRequiredWord(rule, value, callback) {
  if (value) {
    var reg = /^[A-Za-z0-9\u4e00-\u9fa5]+$/;
    if (reg.test(value)) {
      callback();
    } else {
      return callback(new Error('角色名称只能输入如汉字、字母、数字'));
    }
  } else {
    return callback(new Error('请输入角色名称'));
  }
};
exports.checkRequiredWord = checkRequiredWord;