"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'scanRole',
  data: function data() {
    return {
      dialogVisible: false,
      mloading: false,
      roleData: []
    };
  },
  props: {
    role: Boolean
  },
  watch: {
    role: function role(val) {
      var that = this;
      if (val == true) {
        that.dialogVisible = true;
        that.roleData = that.$parent.roleData;
      } else {
        that.dialogVisible = false;
        that.roleData = [];
      }
    }
  },
  methods: {
    handleClose: function handleClose() {
      var that = this;
      that.$parent.roleShow = false;
      that.dialogVisible = false;
    }
  }
};
exports.default = _default;