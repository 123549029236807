"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/web.dom.iterable");
var _wxHy = require("@/api/wxhyyy/wxHy");
var _wXintegralMall = require("@/api/wxhyyy/wXintegralMall");
var _wxgzh = require("@/api/wxhyyy/wxgzh");
var _wxyyKhlb = require("@/api/wxhyyy/wxyyKhlb");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable no-self-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */

var baseUrl = '/wh';
var _default = {
  filters: {
    mathnum: function mathnum(val) {
      var a = val || 0;
      return Math.floor(a * 100) / 100;
    }
  },
  data: function data() {
    return {
      tagss: [],
      // 标签
      dialogVisible: false,
      taggg: '',
      // 去除开头固定标签
      checkList: '',
      // 选中标签集合
      multipleSelection: '',
      // 打标签数据
      tagsList: {
        tagId: '',
        userTagQuery: []
      },
      condlist: [],
      tablehead: [],
      total: 0,
      total1: 0,
      total2: 0,
      xgtask: false,
      tableData1: [],
      imdiavip: false,
      infodia: false,
      // fstpUrl: baseUrl + '/api-memberscrm/member/admin/moWxMemberInfo/excel/importData',
      fstpUrl: '/api-memberscrm/member/admin/moWxMemberInfo/excel/importData',
      myHeaders: {
        Authorization: 'Bearer ' + (0, _auth.getToken)(),
        companyId: localStorage.getItem('cid')
      },
      levdata: [],
      vipload: false,
      tableData: [],
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 0);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '昨天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            end.setTime(end.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '近两天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      formInline: {
        isSynchronizationWechat: '',
        lableName: '',
        orderTo: 'create_time',
        descBy: 'desc'
      },
      value: '',
      value2: '',
      selectlist: '',
      infopro: '',
      xglist: [],
      dqlist: [],
      form: {
        lableName: '',
        isSynchronizationWechat: '1',
        lableType: '2',
        lableRule: '1',
        moWxLableConditionDOList: [{
          isSynchronizationWechat: '1',
          lableObj: {
            type: '',
            idList: []
          }
        }, {
          isSynchronizationWechat: '',
          lableObj: {
            type: '',
            idList: []
          }
        }, {
          isSynchronizationWechat: '2',
          lableObj: {
            type: '',
            startNum: '',
            endNum: ''
          }
        }, {
          isSynchronizationWechat: '2',
          lableObj: {
            type: '',
            startNum: '',
            endNum: ''
          }
        }, {
          isSynchronizationWechat: '',
          lableObj: {
            type: '',
            startNum: '',
            endNum: ''
          }
        }, {
          isSynchronizationWechat: '',
          lableObj: {
            type: '',
            radioType: '',
            dayNum: '',
            startNum: '',
            endNum: ''
          }
        }, {
          isSynchronizationWechat: '4',
          lableObj: {
            type: '',
            radioType: '',
            dayNum: '',
            startNum: '',
            endNum: ''
          }
        }, {
          isSynchronizationWechat: '4',
          lableObj: {
            type: ''
          }
        }, {
          isSynchronizationWechat: '4',
          lableObj: {
            type: ''
          }
        }, {
          isSynchronizationWechat: '4',
          lableObj: {
            type: ''
          }
        }, {
          isSynchronizationWechat: '',
          lableObj: {
            type: '',
            startNum: '',
            endNum: ''
          }
        }, {
          isSynchronizationWechat: '5',
          lableObj: {
            type: '',
            radioType: ''
          }
        }, {
          isSynchronizationWechat: '5',
          lableObj: {
            type: '',
            idList: []
          }
        }]
      },
      time1: [],
      time2: [],
      time3: [],
      diacp: false,
      diahd: false,
      hdlist: [],
      cplist: [],
      disabled: false,
      disabled1: false,
      disabled2: true,
      disabled3: true,
      disabled4: true,
      disabled5: true,
      radioss: '1',
      hdzslist: [],
      cpzslist: [],
      hdzslist1: [],
      cpzslist1: []
    };
  },
  created: function created() {
    var _this = this;
    (0, _wxHy.searchSelect)().then(function (res) {
      // console.log(res)
      res = res.data;
      if (res.code === 200) {
        _this.levdata = res.data || [];
        // console.log(this.levdata)
      } else {
        _this.$message(res.msg);
      }
    });
    // this.getAlltags()
    // this.getdqlist()
    this.gethylist(this.formInline);
    this.getprolist();
  },
  methods: {
    dkdishd: function dkdishd() {
      var that = this;
      that.diahd = true;
      // that.hdzslist1
      that.form.moWxLableConditionDOList[0].lableObj.idList = that.form.moWxLableConditionDOList[0].lableObj.idList;
    },
    dkdiscp: function dkdiscp() {
      var that = this;
      that.diacp = true;
      that.form.moWxLableConditionDOList[1].lableObj.idList = that.form.moWxLableConditionDOList[1].lableObj.idList;
    },
    mowxdeiogz: function mowxdeiogz(val) {
      var that = this;
      if (val === '1') {
        that.form.moWxLableConditionDOList[5].lableObj.startNum = '';
        that.form.moWxLableConditionDOList[5].lableObj.endNum = '';
      } else {
        that.form.moWxLableConditionDOList[5].lableObj.dayNum = '';
      }
    },
    mowxdeioqxgz: function mowxdeioqxgz(val) {
      var that = this;
      if (val === '1') {
        that.form.moWxLableConditionDOList[6].lableObj.startNum = '';
        that.form.moWxLableConditionDOList[6].lableObj.endNum = '';
      } else {
        that.form.moWxLableConditionDOList[6].lableObj.dayNum = '';
      }
    },
    srchang: function srchang(val) {
      console.log(val);
      this.formInline.descBy = val.prop;
      if (val.order === 'descending') {
        this.formInline.descBy = 'desc';
      } else {
        this.formInline.descBy = 'asc';
      }
      this.gethylist(this.formInline);
    },
    hdzs: function hdzs(val) {
      var _this2 = this;
      // var mowx = this.form.moWxLableConditionDOList[0].lableObj.idList
      console.log(val);
      this.hdzslist = [];
      this.hdlist.forEach(function (v, i) {
        val.forEach(function (y) {
          if (y === v.id) {
            _this2.hdzslist.push(v);
          }
        });
      });
      this.hdzslist1 = this.hdzslist;
      if (this.hdzslist.length > 4) {
        this.hdzslist1 = this.hdzslist.slice(0, 4);
      }
      console.log('hdzslist1==', this.hdzslist1);
    },
    cpzs: function cpzs(val) {
      var _this3 = this;
      this.cpzslist = [];
      this.cplist.forEach(function (v, i) {
        val.forEach(function (y) {
          if (y === v.id) {
            _this3.cpzslist.push(v);
          }
        });
      });
      this.cpzslist1 = this.cpzslist;
      if (this.cpzslist.length > 4) {
        this.cpzslist1 = this.cpzslist.slice(0, 4);
      }
    },
    xztjlist: function xztjlist(val) {
      var that = this;
      var mowx = that.form.moWxLableConditionDOList;
      mowx = [{
        isSynchronizationWechat: '',
        lableObj: {
          type: '',
          idList: []
        }
      }, {
        isSynchronizationWechat: '',
        lableObj: {
          type: '',
          idList: []
        }
      }, {
        isSynchronizationWechat: '2',
        lableObj: {
          type: '',
          startNum: '',
          endNum: ''
        }
      }, {
        isSynchronizationWechat: '2',
        lableObj: {
          type: '',
          startNum: '',
          endNum: ''
        }
      }, {
        isSynchronizationWechat: '',
        lableObj: {
          type: '',
          startNum: '',
          endNum: ''
        }
      }, {
        isSynchronizationWechat: '',
        lableObj: {
          type: '',
          radioType: '',
          dayNum: '',
          startNum: '',
          endNum: ''
        }
      }, {
        isSynchronizationWechat: '4',
        lableObj: {
          type: '',
          radioType: '',
          dayNum: '',
          startNum: '',
          endNum: ''
        }
      }, {
        isSynchronizationWechat: '4',
        lableObj: {
          type: ''
        }
      }, {
        isSynchronizationWechat: '4',
        lableObj: {
          type: ''
        }
      }, {
        isSynchronizationWechat: '4',
        lableObj: {
          type: ''
        }
      }, {
        isSynchronizationWechat: '',
        lableObj: {
          type: '',
          startNum: '',
          endNum: ''
        }
      }, {
        isSynchronizationWechat: '5',
        lableObj: {
          type: '',
          radioType: ''
        }
      }, {
        isSynchronizationWechat: '5',
        lableObj: {
          type: '',
          idList: []
        }
      }];
      that.hdzslist1 = [];
      that.cpzslist1 = [];
      mowx[0].isSynchronizationWechat = '';
      mowx[1].isSynchronizationWechat = '';
      mowx[4].isSynchronizationWechat = '';
      mowx[5].isSynchronizationWechat = '';
      mowx[10].isSynchronizationWechat = '';
      switch (val) {
        case '1':
          mowx[0].isSynchronizationWechat = val;
          that.disabled2 = true;
          that.disabled1 = false;
          that.disabled3 = true;
          that.disabled4 = true;
          that.disabled5 = true;
          break;
        case '2':
          mowx[1].isSynchronizationWechat = val;
          that.disabled2 = false;
          that.disabled1 = true;
          that.disabled3 = true;
          that.disabled4 = true;
          that.disabled5 = true;
          break;
        case '3':
          mowx[4].isSynchronizationWechat = val;
          that.disabled2 = true;
          that.disabled3 = false;
          that.disabled1 = true;
          that.disabled4 = true;
          that.disabled5 = true;
          break;
        case '4':
          mowx[5].isSynchronizationWechat = val;
          that.disabled2 = true;
          that.disabled4 = false;
          that.disabled3 = true;
          that.disabled1 = true;
          that.disabled5 = true;
          break;
        case '5':
          mowx[10].isSynchronizationWechat = val;
          that.disabled2 = true;
          that.disabled5 = false;
          that.disabled3 = true;
          that.disabled4 = true;
          that.disabled1 = true;
          break;
        // default:
        //   mowx[0].isSynchronizationWechat = val
        //   that.disabled2 = true
        //   that.disabled1 = false
        //   that.disabled3 = true
        //   that.disabled4 = true
        //   that.disabled5 = true
        //   break
      }

      that.form.moWxLableConditionDOList = mowx;
      console.log(that.form.moWxLableConditionDOList);
    },
    getprolist: function getprolist() {
      var that = this;
      var n = {
        pageNo: 1,
        pageSize: 999
      };
      (0, _wxyyKhlb.getselectActList)(n).then(function (res) {
        res = res.data;
        console.log(res);
        if (res.code === 200) {
          if (res.data.records) {
            that.hdlist = res.data.records;
            that.total1 = res.data.total;
          } else {
            that.$message.info(res.msg);
          }
        }
      });
      (0, _wxyyKhlb.selectProuctList)(n).then(function (res) {
        res = res.data;
        console.log(res);
        if (res.code === 200) {
          if (res.data.records) {
            that.cplist = res.data.records;
            that.total2 = res.data.total;
          } else {
            that.$message.info(res.msg);
          }
        }
      });
    },
    xjbq: function xjbq() {
      var that = this;
      that.dialogVisible = true;
      that.form = {
        lableName: '',
        isSynchronizationWechat: '1',
        lableType: '2',
        lableRule: '1',
        moWxLableConditionDOList: [{
          isSynchronizationWechat: '1',
          lableObj: {
            type: '',
            idList: []
          }
        }, {
          isSynchronizationWechat: '',
          lableObj: {
            type: '',
            idList: []
          }
        }, {
          isSynchronizationWechat: '2',
          lableObj: {
            type: '',
            startNum: '',
            endNum: ''
          }
        }, {
          isSynchronizationWechat: '2',
          lableObj: {
            type: '',
            startNum: '',
            endNum: ''
          }
        }, {
          isSynchronizationWechat: '',
          lableObj: {
            type: '',
            startNum: '',
            endNum: ''
          }
        }, {
          isSynchronizationWechat: '',
          lableObj: {
            type: '',
            radioType: '',
            dayNum: '',
            startNum: '',
            endNum: ''
          }
        }, {
          isSynchronizationWechat: '4',
          lableObj: {
            type: '',
            radioType: '',
            dayNum: '',
            startNum: '',
            endNum: ''
          }
        }, {
          isSynchronizationWechat: '4',
          lableObj: {
            type: ''
          }
        }, {
          isSynchronizationWechat: '4',
          lableObj: {
            type: ''
          }
        }, {
          isSynchronizationWechat: '4',
          lableObj: {
            type: ''
          }
        }, {
          isSynchronizationWechat: '',
          lableObj: {
            type: '',
            startNum: '',
            endNum: ''
          }
        }, {
          isSynchronizationWechat: '5',
          lableObj: {
            type: '',
            radioType: ''
          }
        }, {
          isSynchronizationWechat: '5',
          lableObj: {
            type: '',
            idList: []
          }
        }]
      };
      that.hdzslist1 = [];
      that.cpzslist1 = [];
      that.radioss = '1';
      that.disabled1 = false;
      that.disabled2 = true;
      that.disabled3 = true;
      that.disabled4 = true;
      that.disabled5 = true;
    },
    mscjbq: function mscjbq(val) {
      var _this4 = this;
      var that = this;
      var form = val;
      var mowx = val.moWxLableConditionDOList;
      var mowxx;
      var form1 = {
        lableName: '',
        isSynchronizationWechat: '1',
        lableType: '1',
        lableRule: '1',
        moWxLableConditionDOList: []
      };
      if (that.time1) {
        var obj1 = {
          startNum: that.time1.length > 1 ? that.time1[0] : '',
          endNum: that.time1.length > 1 ? that.time1[1] : ''
        };
        Object.assign(form.moWxLableConditionDOList[3].lableObj, obj1);
      }
      if (that.time2) {
        var obj2 = {
          startNum: that.time2.length > 1 ? that.time2[0] : '',
          endNum: that.time2.length > 1 ? that.time2[1] : ''
        };
        Object.assign(form.moWxLableConditionDOList[5].lableObj, obj2);
      }
      if (that.time3) {
        var obj3 = {
          startNum: that.time3.length > 1 ? that.time3[0] : '',
          endNum: that.time3.length > 1 ? that.time3[1] : ''
        };
        Object.assign(form.moWxLableConditionDOList[6].lableObj, obj3);
      }
      if (!form.lableName) {
        that.$message.warning('请输入标签名称');
        return false;
      }
      if (form.lableType === '2') {
        if (mowx[0].isSynchronizationWechat === '1') {
          if (!mowx[0].lableObj.type || mowx[0].lableObj.idList.length === 0) {
            that.$message.warning('请选择类型或活动');
            return false;
          } else {
            // form.moWxLableConditionDOList = form.moWxLableConditionDOList.splice(0, 1)
            // let aa
            mowxx = mowx.slice(0, 1);
          }
          // if (!mowx[0].lableObj.idList) {
          //   that.$message.warning('请选择活动')
          //   return false
          // }
        }

        if (mowx[1].isSynchronizationWechat === '2') {
          if (!mowx[1].lableObj.type && !mowx[2].lableObj.type && !mowx[3].lableObj.type) {
            that.$message.warning('请选择类型');
            return false;
          }
          if (mowx[1].lableObj.type === '2') {
            if (mowx[1].lableObj.idList.length === 0) {
              that.$message.warning('请选择产品');
              return false;
            } else {
              mowxx = mowx.slice(1, 2);
            }
          }
          if (mowx[2].lableObj.type === '3') {
            if (mowx[2].lableObj.startNum < 0 || mowx[2].lableObj.endNum < 0) {
              that.$message.warning('请填写正确次数');
              return false;
            } else {
              if (mowx[2].lableObj.endNum >= mowx[2].lableObj.startNum) {
                mowxx = mowx.slice(2, 3);
              } else {
                that.$message.warning('结束次数必须大于等于开始次数');
                return false;
              }
            }
          }
          if (mowx[3].lableObj.type === '4') {
            if (!mowx[3].lableObj.startNum || !mowx[3].lableObj.endNum) {
              that.$message.warning('请选择时间');
              return false;
            } else {
              mowxx = mowx.slice(3, 4);
            }
          }
          if (mowx[1].lableObj.type === '2' && mowx[2].lableObj.type === '3') {
            if (mowx[1].lableObj.idList.length > 0 && mowx[2].lableObj.startNum >= 0 && mowx[2].lableObj.endNum >= mowx[2].lableObj.startNum) {
              mowxx = mowx.slice(1, 3);
            }
          }
          if (mowx[1].lableObj.type === '2' && mowx[3].lableObj.type === '4') {
            if (mowx[1].lableObj.idList.length > 0 && mowx[3].lableObj.startNum && mowx[3].lableObj.endNum) {
              var aa = mowx.slice(1, 2);
              mowxx = aa.concat(mowx.slice(3, 4));
              // mowxx = aa
            }
          }

          if (mowx[3].lableObj.type === '4' && mowx[2].lableObj.type === '3') {
            if (mowx[3].lableObj.startNum && mowx[3].lableObj.endNum && mowx[2].lableObj.startNum >= 0 && mowx[2].lableObj.endNum >= mowx[2].lableObj.startNum) {
              mowxx = mowx.slice(2, 4);
            }
          }
          if (mowx[1].lableObj.type === '2' && mowx[2].lableObj.type === '3' && mowx[3].lableObj.type === '4') {
            if (mowx[1].lableObj.idList.length > 0 && mowx[2].lableObj.startNum >= 0 && mowx[2].lableObj.endNum && mowx[3].lableObj.startNum && mowx[3].lableObj.endNum) {
              mowxx = mowx.slice(1, 4);
            }
          }
        }
        if (mowx[4].isSynchronizationWechat === '3') {
          if (!mowx[4].lableObj.type) {
            that.$message.warning('请选择类型');
            return false;
          }
          if (mowx[4].lableObj.startNum < 0 || mowx[4].lableObj.endNum < 0) {
            that.$message.warning('请填写积分');
            return false;
          }
          if (mowx[4].lableObj.type && mowx[4].lableObj.startNum >= 0 && mowx[4].lableObj.endNum >= mowx[4].lableObj.startNum) {
            mowxx = mowx.slice(4, 5);
          } else {
            that.$message.warning('结束积分必须大于等于开始积分');
            return false;
          }
          // form.moWxLableConditionDOList = mowx
          // form.moWxLableConditionDOList.forEach((v, i) => {
          //   v.lableObj = JSON.stringify(v.lableObj)
          // })

          // savemoWxLable(form).then(res => {
          //   if (res.code === 200) {
          //     that.$message.success(res.msg)
          //     that.dialogVisible = false
          //     // that.form = {
          //     //   lableName: '',
          //     //   isSynchronizationWechat: '1',
          //     //   lableType: '1',
          //     //   lableRule: '1',
          //     //   moWxLableConditionDOList: [
          //     //     {
          //     //       isSynchronizationWechat: '1',
          //     //       lableObj: {
          //     //         type: '',
          //     //         idList: []
          //     //       }
          //     //     },
          //     //     {
          //     //       isSynchronizationWechat: '',
          //     //       lableObj: {
          //     //         type: '',
          //     //         idList: []
          //     //       }
          //     //     },
          //     //     {
          //     //       isSynchronizationWechat: '2',
          //     //       lableObj: {
          //     //         type: '',
          //     //         startNum: '',
          //     //         endNum: ''
          //     //       }
          //     //     },
          //     //     {
          //     //       isSynchronizationWechat: '2',
          //     //       lableObj: {
          //     //         type: '',
          //     //         startNum: '',
          //     //         endNum: ''
          //     //       }
          //     //     },
          //     //     {
          //     //       isSynchronizationWechat: '',
          //     //       lableObj: {
          //     //         type: '',
          //     //         startNum: '',
          //     //         endNum: ''
          //     //       }
          //     //     },
          //     //     {
          //     //       isSynchronizationWechat: '',
          //     //       lableObj: {
          //     //         type: '',
          //     //         radioType: '',
          //     //         dayNum: '',
          //     //         startNum: '',
          //     //         endNum: ''
          //     //       }
          //     //     },
          //     //     {
          //     //       isSynchronizationWechat: '4',
          //     //       lableObj: {
          //     //         type: '',
          //     //         radioType: '',
          //     //         dayNum: '',
          //     //         startNum: '',
          //     //         endNum: ''
          //     //       }
          //     //     },
          //     //     {
          //     //       isSynchronizationWechat: '4',
          //     //       lableObj: {
          //     //         type: ''
          //     //       }
          //     //     },
          //     //     {
          //     //       isSynchronizationWechat: '4',
          //     //       lableObj: {
          //     //         type: ''
          //     //       }
          //     //     },
          //     //     {
          //     //       isSynchronizationWechat: '4',
          //     //       lableObj: {
          //     //         type: ''

          //     //       }
          //     //     },
          //     //     {
          //     //       isSynchronizationWechat: '',
          //     //       lableObj: {
          //     //         type: '',
          //     //         startNum: '',
          //     //         endNum: ''
          //     //       }
          //     //     },
          //     //     {
          //     //       isSynchronizationWechat: '5',
          //     //       lableObj: {
          //     //         type: '',
          //     //         radioType: ''
          //     //       }
          //     //     },
          //     //     {
          //     //       isSynchronizationWechat: '5',
          //     //       lableObj: {
          //     //         type: '',
          //     //         idList: []
          //     //       }
          //     //     }
          //     //   ]

          //     // }
          //   } else {
          //     that.$message.warning(res.msg)
          //     return false
          //   }
          // })
          // return false
        }
        /* ------------------------------------------*/
        if (mowx[5].isSynchronizationWechat === '4') {
          if (!mowx[5].lableObj.type && !mowx[6].lableObj.type && !mowx[7].lableObj.type && !mowx[8].lableObj.type && !mowx[9].lableObj.type) {
            that.$message.warning('请选择类型');
            return false;
          }
          if (mowx[5].lableObj.type === '6') {
            if (!mowx[5].lableObj.radioType) {
              that.$message.warning('请选择选项');
              return false;
            }
            if (mowx[5].lableObj.radioType === '1') {
              if (mowx[5].lableObj.dayNum < 0) {
                that.$message.warning('请填写时间');
                return false;
              }
            }
            if (mowx[5].lableObj.radioType === '2') {
              if (!mowx[5].lableObj.startNum || !mowx[5].lableObj.endNum) {
                that.$message.warning('请选择时间');
                return false;
              }
            }
          }
          if (mowx[6].lableObj.type === '7') {
            if (!mowx[6].lableObj.radioType) {
              that.$message.warning('请选择选项');
              return false;
            }
            if (mowx[6].lableObj.radioType === '1') {
              if (mowx[6].lableObj.dayNum < 0) {
                that.$message.warning('请填写时间');
                return false;
              }
            }
            if (mowx[6].lableObj.radioType === '2') {
              if (!mowx[6].lableObj.startNum || !mowx[6].lableObj.endNum) {
                that.$message.warning('请选择时间');
                return false;
              }
            }
          }
          // 1
          if (mowx[5].lableObj.type) {
            if (mowx[5].lableObj.radioType) {
              console.log('22222');
              if (mowx[5].lableObj.dayNum >= 0 || mowx[5].lableObj.startNum && mowx[5].lableObj.endNum) {
                console.log('lllllll');
                mowxx = mowx.slice(5, 6);
              }
            }
          }
          // 2
          if (mowx[6].lableObj.type) {
            if (mowx[6].lableObj.radioType) {
              if (mowx[6].lableObj.dayNum >= 0 || mowx[6].lableObj.startNum && mowx[6].lableObj.endNum) {
                mowxx = mowx.slice(6, 7);
              }
            }
          }
          // 3
          if (mowx[7].lableObj.type) {
            mowxx = mowx.slice(7, 8);
          }
          // 4
          if (mowx[8].lableObj.type) {
            mowxx = mowx.slice(8, 9);
          }
          // 5
          if (mowx[9].lableObj.type) {
            mowxx = mowx.slice(9, 10);
          }
          // 12
          if (mowx[5].lableObj.type && mowx[6].lableObj.type) {
            if (mowx[5].lableObj.radioType && mowx[6].lableObj.radioType) {
              if ((mowx[5].lableObj.dayNum >= 0 || mowx[5].lableObj.startNum && mowx[5].lableObj.endNum) && (mowx[6].lableObj.dayNum || mowx[6].lableObj.startNum && mowx[6].lableObj.endNum)) {
                mowxx = mowx.slice(5, 7);
              }
            }
          }
          // 13
          if (mowx[5].lableObj.type && mowx[7].lableObj.type) {
            if (mowx[5].lableObj.radioType) {
              if (mowx[5].lableObj.dayNum >= 0 || mowx[5].lableObj.startNum && mowx[5].lableObj.endNum) {
                var _aa = mowx.slice(5, 6);
                mowxx = _aa.concat(mowx.slice(7, 8));
                // mowxx = aa
              }
            }
          }
          // 14
          if (mowx[5].lableObj.type && mowx[8].lableObj.type) {
            if (mowx[5].lableObj.radioType) {
              if (mowx[5].lableObj.dayNum >= 0 || mowx[5].lableObj.startNum && mowx[5].lableObj.endNum) {
                var _aa2 = mowx.slice(5, 6);
                mowxx = _aa2.concat(mowx.slice(8, 9));
                // mowxx = aa
              }
            }
          }
          // 15
          if (mowx[5].lableObj.type && mowx[9].lableObj.type) {
            if (mowx[5].lableObj.radioType) {
              if (mowx[5].lableObj.dayNum >= 0 || mowx[5].lableObj.startNum && mowx[5].lableObj.endNum) {
                var _aa3 = mowx.slice(5, 6);
                mowxx = _aa3.concat(mowx.slice(9, 10));
                // mowxx = aa
              }
            }
          }
          // 23
          if (mowx[6].lableObj.type && mowx[7].lableObj.type) {
            console.log('sdfsdfdfdsf');
            if (mowx[6].lableObj.radioType) {
              if (mowx[6].lableObj.dayNum >= 0 || mowx[6].lableObj.startNum && mowx[6].lableObj.endNum) {
                mowxx = mowx.slice(6, 8);
              }
            }
          }
          // 24
          if (mowx[6].lableObj.type && mowx[8].lableObj.type) {
            if (mowx[6].lableObj.radioType) {
              if (mowx[6].lableObj.dayNum >= 0 || mowx[6].lableObj.startNum && mowx[6].lableObj.endNum) {
                var _aa4 = mowx.slice(6, 7);
                mowxx = _aa4.concat(mowx.slice(8, 9));
                // mowxx = aa
              }
            }
          }
          // 25
          if (mowx[6].lableObj.type && mowx[9].lableObj.type) {
            if (mowx[6].lableObj.radioType) {
              if (mowx[6].lableObj.dayNum >= 0 || mowx[6].lableObj.startNum && mowx[6].lableObj.endNum) {
                var _aa5 = mowx.slice(6, 7);
                mowxx = _aa5.concat(mowx.slice(9, 10));
                // mowxx = aa
              }
            }
          }
          // 34
          if (mowx[7].lableObj.type && mowx[8].lableObj.type) {
            mowxx = mowx.slice(7, 9);
          }
          // 35
          if (mowx[7].lableObj.type && mowx[9].lableObj.type) {
            var _aa6 = mowx.slice(7, 8);
            mowxx = _aa6.concat(mowx.slice(9, 10));
            // mowxx = aa
          }
          // 45
          if (mowx[8].lableObj.type && mowx[9].lableObj.type) {
            mowxx = mowx.slice(8, 10);
          }
          // 123
          if (mowx[5].lableObj.type && mowx[6].lableObj.type && mowx[7].lableObj.type) {
            if (mowx[5].lableObj.radioType && mowx[6].lableObj.radioType) {
              if ((mowx[5].lableObj.dayNum >= 0 || mowx[5].lableObj.startNum && mowx[5].lableObj.endNum) && (mowx[6].lableObj.dayNum >= 0 || mowx[6].lableObj.startNum && mowx[6].lableObj.endNum)) {
                mowxx = mowx.slice(5, 8);
              }
            }
          }
          // 124
          if (mowx[5].lableObj.type && mowx[6].lableObj.type && mowx[8].lableObj.type) {
            if (mowx[5].lableObj.radioType && mowx[6].lableObj.radioType) {
              if ((mowx[5].lableObj.dayNum >= 0 || mowx[5].lableObj.startNum && mowx[5].lableObj.endNum) && (mowx[6].lableObj.dayNum >= 0 || mowx[6].lableObj.startNum && mowx[6].lableObj.endNum)) {
                aa = mowx.slice(5, 7);
                mowxx = aa.concat(mowx.slice(8, 9));
                // mowxx = aa
              }
            }
          }
          // 125
          if (mowx[5].lableObj.type && mowx[6].lableObj.type && mowx[9].lableObj.type) {
            if (mowx[5].lableObj.radioType && mowx[6].lableObj.radioType) {
              if ((mowx[5].lableObj.dayNum >= 0 || mowx[5].lableObj.startNum && mowx[5].lableObj.endNum) && (mowx[6].lableObj.dayNum >= 0 || mowx[6].lableObj.startNum && mowx[6].lableObj.endNum)) {
                aa = mowx.slice(5, 7);
                mowxx = aa.concat(mowx.slice(9, 10));
                // mowxx = aa
              }
            }
          }
          // 134
          if (mowx[5].lableObj.type && mowx[7].lableObj.type && mowx[8].lableObj.type) {
            if (mowx[5].lableObj.radioType) {
              if (mowx[5].lableObj.dayNum >= 0 || mowx[5].lableObj.startNum && mowx[5].lableObj.endNum) {
                var _aa7 = mowx.slice(5, 6);
                mowxx = _aa7.concat(mowx.slice(7, 9));
                // mowxx = aa
              }
            }
          }
          // 145
          if (mowx[5].lableObj.type && mowx[8].lableObj.type && mowx[9].lableObj.type) {
            if (mowx[5].lableObj.radioType) {
              if (mowx[5].lableObj.dayNum >= 0 || mowx[5].lableObj.startNum && mowx[5].lableObj.endNum) {
                var _aa8 = mowx.slice(5, 6);
                mowxx = _aa8.concat(mowx.slice(8, 10));
                // mowxx = aa
              }
            }
          }
          // 234
          if (mowx[6].lableObj.type && mowx[7].lableObj.type && mowx[8].lableObj.type) {
            if (mowx[6].lableObj.radioType) {
              if (mowx[6].lableObj.dayNum >= 0 || mowx[6].lableObj.startNum && mowx[6].lableObj.endNum) {
                mowxx = mowx.slice(6, 9);
              }
            }
          }
          // 235
          if (mowx[6].lableObj.type && mowx[7].lableObj.type && mowx[9].lableObj.type) {
            if (mowx[6].lableObj.radioType) {
              if (mowx[6].lableObj.dayNum >= 0 || mowx[6].lableObj.startNum && mowx[6].lableObj.endNum) {
                var _aa9 = mowx.slice(6, 8);
                mowxx = _aa9.concat(mowx.slice(9, 10));
                // mowxx = aa
              }
            }
          }
          // 345
          if (mowx[8].lableObj.type && mowx[7].lableObj.type && mowx[9].lableObj.type) {
            mowxx = mowx.slice(7, 10);
          }
          // 1234
          if (mowx[5].lableObj.type && mowx[6].lableObj.type && mowx[7].lableObj.type && mowx[8].lableObj.type) {
            if (mowx[5].lableObj.radioType && mowx[6].lableObj.radioType) {
              if ((mowx[5].lableObj.dayNum >= 0 || mowx[5].lableObj.startNum && !mowx[5].lableObj.endNum) && (mowx[6].lableObj.dayNum >= 0 || mowx[6].lableObj.startNum && !mowx[6].lableObj.endNum)) {
                mowxx = mowx.slice(5, 9);
              }
            }
          }
          // 1235
          if (mowx[5].lableObj.type && mowx[6].lableObj.type && mowx[7].lableObj.type && mowx[9].lableObj.type) {
            if (mowx[5].lableObj.radioType && mowx[6].lableObj.radioType) {
              if ((mowx[5].lableObj.dayNum >= 0 || mowx[5].lableObj.startNum && mowx[5].lableObj.endNum) && (mowx[6].lableObj.dayNum >= 0 || mowx[6].lableObj.startNum && mowx[6].lableObj.endNum)) {
                var _aa10 = mowx.slice(5, 8);
                mowxx = _aa10.concat(mowx.slice(9, 10));
                // mowxx = aa
              }
            }
          }
          // 1245
          if (mowx[5].lableObj.type && mowx[6].lableObj.type && mowx[8].lableObj.type && mowx[9].lableObj.type) {
            if (mowx[5].lableObj.radioType && mowx[6].lableObj.radioType) {
              if ((mowx[5].lableObj.dayNum >= 0 || mowx[5].lableObj.startNum && mowx[5].lableObj.endNum) && (mowx[6].lableObj.dayNum >= 0 || mowx[6].lableObj.startNum && mowx[6].lableObj.endNum)) {
                var _aa11 = mowx.slice(5, 7);
                mowxx = _aa11.concat(mowx.slice(8, 10));
                // mowxx = aa
              }
            }
          }
          // 1345
          if (mowx[5].lableObj.type && mowx[7].lableObj.type && mowx[8].lableObj.type && mowx[9].lableObj.type) {
            if (mowx[5].lableObj.radioType) {
              if (mowx[5].lableObj.dayNum >= 0 || mowx[5].lableObj.startNum && mowx[5].lableObj.endNum) {
                var _aa12 = mowx.slice(5, 6);
                mowxx = mowxx = _aa12.concat(mowx.slice(7, 10));
                // mowxx = aa
              }
            }
          }
          // 2345
          if (mowx[6].lableObj.type && mowx[7].lableObj.type && mowx[8].lableObj.type && mowx[9].lableObj.type) {
            if (mowx[6].lableObj.radioType) {
              if (mowx[6].lableObj.dayNum >= 0 || mowx[6].lableObj.startNum && mowx[6].lableObj.endNum) {
                mowxx = mowx.slice(6, 10);
              }
            }
          }
          // 12345
          if (mowx[5].lableObj.type && mowx[6].lableObj.type && mowx[7].lableObj.type && mowx[8].lableObj.type && mowx[9].lableObj.type) {
            if (mowx[5].lableObj.radioType && (mowx[5].lableObj.dayNum >= 0 || mowx[5].lableObj.startNum && mowx[5].lableObj.endNum) && mowx[6].lableObj.radioType && (mowx[6].lableObj.dayNum >= 0 || mowx[6].lableObj.startNum && mowx[6].lableObj.endNum)) {
              mowxx = mowx.slice(5, 10);
            }
          }
        }
        if (mowx[10].isSynchronizationWechat === '5') {
          if (!mowx[10].lableObj.type && !mowx[11].lableObj.type && !mowx[12].lableObj.type) {
            that.$message.warning('请选择类型');
            return false;
          }
          if (mowx[10].lableObj.type === '11') {
            if (mowx[10].lableObj.startNum < 0 || mowx[10].lableObj.endNum < 0) {
              that.$message.warning('请填写正确时间');
              return false;
            } else {
              if (mowx[10].lableObj.endNum >= mowx[10].lableObj.startNum) {
                mowxx = mowx.slice(10, 11);
              } else {
                that.$message.warning('结束时间必须大于等于开始时间');
                return false;
              }
            }
          }
          if (mowx[11].lableObj.type === '12') {
            if (!mowx[11].lableObj.radioType) {
              that.$message.warning('请选择性别');
              return false;
            } else {
              mowxx = mowx.slice(11, 12);
            }
          }
          if (mowx[12].lableObj.type === '13') {
            if (mowx[12].lableObj.idList.length === 0) {
              that.$message.warning('请选择来源');
              return false;
            } else {
              mowxx = mowx.slice(12);
            }
          }
          if (mowx[10].lableObj.type && mowx[11].lableObj.type) {
            if (mowx[10].lableObj.startNum >= 0 && mowx[10].lableObj.endNum >= mowx[10].lableObj.startNum && mowx[11].lableObj.radioType) {
              mowxx = mowx.slice(10, 12);
            }
          }
          if (mowx[10].lableObj.type && mowx[12].lableObj.type) {
            if (mowx[10].lableObj.startNum >= 0 && mowx[10].lableObj.endNum >= mowx[10].lableObj.startNum && mowx[12].lableObj.idList.length > 0) {
              var _aa13 = mowx.slice(10, 11);
              mowxx = _aa13.concat(mowx.slice(12));
              // mowxx = aa
            }
          }

          if (mowx[11].lableObj.type && mowx[12].lableObj.type) {
            if (mowx[11].lableObj.radioType && mowx[12].lableObj.idList.length > 0) {
              mowxx = mowx.slice(11);
            }
          }
          if (mowx[10].lableObj.type && mowx[11].lableObj.type && mowx[12].lableObj.type) {
            if (mowx[10].lableObj.startNum >= 0 && mowx[10].lableObj.endNum >= mowx[10].lableObj.startNum && mowx[11].lableObj.radioType && mowx[12].lableObj.idList.length > 0) {
              mowxx = mowx.slice(10);
            }
          }
        }
        Object.assign(form1, form);
        var moxxx = [];
        Object.assign(moxxx, mowxx);
        // console.log('mowxx', mowxx)
        moxxx.forEach(function (v, i) {
          v.lableObj = JSON.stringify(v.lableObj);
        });
        form1.moWxLableConditionDOList = moxxx;
        console.log('form1====', form1);
        that.dialogVisible = false;
        (0, _wxyyKhlb.savemoWxLable)(form1).then(function (res) {
          res = res.data;
          if (res.code === 200) {
            that.$message.success(res.msg);
            // that.dialogVisible = false
            _this4.gethylist(_this4.formInline);
          } else {
            that.$message.warning(res.msg);
            return false;
          }
        });
      } else {
        var n = {
          lableName: form.lableName,
          isSynchronizationWechat: form.isSynchronizationWechat,
          lableType: form.lableType
        };
        (0, _wxyyKhlb.savemoWxLable)(n).then(function (res) {
          res = res.data;
          if (res.code === 200) {
            that.$message.success(res.msg);
            that.dialogVisible = false;
            _this4.gethylist(_this4.formInline);
          } else {
            that.$message.warning(res.msg);
            return false;
          }
        });
      }
    },
    numFun: function numFun(value) {
      value = value.replace(/[^0-9]/g, '');
      if (value > 0) {
        return value * 1;
      } else if (value <= 0 && value) {
        value = 0;
        return value;
      } else {
        value = '';
        return value;
      }
    },
    // srchang(val) {
    //   this.formInline.orderByZd = val.prop
    //   if (val.order === 'descending') {
    //     this.formInline.orderByGz = 'desc'
    //   } else {
    //     this.formInline.orderByGz = 'asc'
    //   }
    //   this.gethylist(val)
    // },
    // getdqlist() {
    //   getDqlistsearch().then(res => {
    //     // console.log(res)
    //     if (res.code === 200) {
    //       if (res.data) {
    //         res.data.forEach(item => {
    //           this.dqlist.push({ name: item })
    //         })
    //       } else {
    //         this.dqlist = []
    //       }
    //     }
    //   })
    // },
    gethylist: function gethylist(val) {
      var _this5 = this;
      this.vipload = true;
      (0, _wxyyKhlb.searchmoWxLable)(val).then(function (res) {
        res = res.data;
        console.log(res);
        if (res.code === 200) {
          if (res.data.records) {
            _this5.tableData = res.data.records;
          }
          _this5.total = res.data.total;
        } else {
          _this5.$message.info(res.msg);
        }
        _this5.vipload = false;
      });
    },
    handleClose: function handleClose(done) {
      done();
    },
    // 新建标签
    open: function open() {
      var _this6 = this;
      this.$prompt('标签名称', '新建标签', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function (_ref) {
        var value = _ref.value;
        if (_this6.setGround(value)) {
          _this6.$message({
            type: 'error',
            message: '重复的标签名'
          });
          return;
        }
        if (value.replace(/(^\s*)|(\s*$)/g, '') == '') {
          _this6.$message({
            type: 'error',
            message: '请输入有效的标签名'
          });
          return;
        }
        var n = {
          tagName: value,
          appId: sessionStorage.getItem('appId')
        };
        (0, _wxgzh.ccrrTag)(n).then(function (res) {
          res = res.data;
          // console.log(res)
          if (res.code * 1 === 200) {
            _this6.$message({
              type: 'success',
              message: '新建标签是: ' + value
            });
            _this6.getAlltags();
          } else {
            _this6.$message({
              type: 'error',
              message: res.msg
            });
          }
        });
      }).catch(function () {
        _this6.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    // 打标签
    opentagging: function opentagging() {
      if (this.multipleSelection.length === 0) {
        this.$message.warning('请先选择用户再进行操作');
        return;
      }
      this.dialogVisible = true;
      this.checkList = '';
      this.taggg = this.tagss.slice(2);
      // console.log(this.tagss)
    },
    // 提交打标签
    tagging: function tagging() {
      var _this7 = this;
      // console.log(this.checkList)
      if (this.checkList === '') {
        this.$message.warning('请选择一个标签');
      }
      this.tagsList.userTagQuery.appId = sessionStorage.getItem('appId');
      (0, _wxgzh.batchTagging)(this.tagsList.userTagQuery, this.checkList).then(function (res) {
        res = res.data;
        // console.log(res)
        if (res.code * 1 === 200) {
          _this7.$message.success('操作成功');
        } else {
          _this7.$message.warning('操作失败，请重试');
        }
        _this7.dialogVisible = false;
        _this7.userload = true;
        _this7.getserrch();
      });
    },
    // 删除标签
    handleClosetag: function handleClosetag(type, tag, list, ta, row) {
      var i = list.indexOf(tag);
      list.splice(i, 1);
      var r = row.indexOf(ta);
      row.splice(r, 1);
      if (type === '1') {
        this.hdzslist1 = this.hdzslist.slice(0, 5);
      } else {
        this.cpzslist1 = this.cpzslist.slice(0, 5);
      }
      // this.userload = true
      // this.getserrch()
    },
    // 获取用户列表
    getserrch: function getserrch() {
      // this.formInline.tagId = 0
      // this.formInline.endTime = ''
      // this.formInline.startTime = ''
      // this.formInline.nickname = ''
      // this.formInline.qrScene = ''
      // this.formInline.appId = sessionStorage.getItem('appId')
      // getSearchuser(this.formInline).then(res => {
      //    // console.log(res)
      //   if (res.code * 1 === 200) {
      //     this.total = res.data.total
      //     if (this.total * 1 === 0) {
      //       this.userload = false
      //       this.tableData = ''
      //     }
      //     this.tableData = res.data.records
      //   } else {
      //     this.$message.warning(res.msg)
      //   }
      //   this.userload = false
      // })
    },
    // 获取所有标签
    getAlltags: function getAlltags() {
      var _this8 = this;
      (0, _wxgzh.getAllTag)({
        appId: sessionStorage.getItem('appId')
      }).then(function (res) {
        res = res.data;
        // console.log(res)
        if (res.code * 1 === 200) {
          var _this8$tagss;
          _this8.tagss = [{
            tagName: '全部',
            tagId: 0
          }, {
            tagName: '无标签',
            tagId: 1
          }];
          (_this8$tagss = _this8.tagss).push.apply(_this8$tagss, (0, _toConsumableArray2.default)(res.data));
        } else {
          _this8.$message.warning(res.msg);
        }
      });
    },
    handleCurrentChange: function handleCurrentChange(val) {
      // this.vipload = true
      // console.log(`当前页: ${val}`)
      this.formInline.pageNo = val;
      // const n = {
      //   pageNo: val,
      //   pageSize: 10
      // }
      this.gethylist(this.formInline);
    },
    // load() {
    //   var i = 1
    //   const n = {
    //     pageNo: i++,
    //     pageSize: 10
    //   }
    //   getselectActList(n).then(res => {
    //     console.log(res)
    //     if (res.code === 200) {
    //       if (res.data.records) {
    //         this.hdlist = this.hdlist.concat(res.data.records)
    //         console.log(this.hdlist)
    //       } else {
    //         this.$message.info(res.msg)
    //       }
    //     }
    //   })
    // },
    handleCurrentChangehd: function handleCurrentChangehd(val) {
      var _this9 = this;
      // this.vipload = true
      // console.log(`当前页: ${val}`)
      var n = {
        pageNo: val,
        pageSize: 10
      };
      (0, _wxyyKhlb.getselectActList)(n).then(function (res) {
        res = res.data;
        console.log(res);
        if (res.code === 200) {
          if (res.data.records) {
            _this9.hdlist = res.data.records;
          } else {
            _this9.$message.info(res.msg);
          }
        }
      });
    },
    handleCurrentChangecp: function handleCurrentChangecp(val) {
      var _this10 = this;
      // this.vipload = true
      // console.log(`当前页: ${val}`)
      var n = {
        pageNo: val,
        pageSize: 10
      };
      (0, _wxyyKhlb.selectProuctList)(n).then(function (res) {
        res = res.data;
        console.log(res);
        if (res.code === 200) {
          if (res.data.records) {
            _this10.cplist = res.data.records;
          } else {
            _this10.$message.info(res.msg);
          }
        }
      });
    },
    // 冻结，解冻
    handleDelete: function handleDelete(id, sta) {
      var _this11 = this;
      var n = {
        id: id,
        status: sta
      };
      (0, _wxHy.freezeselectMemberById)(n).then(function (res) {
        // console.log(res)
        res = res.data;
        if (res.code === 200) {
          if (sta === '1') {
            _this11.$message.success('已冻结');
            _this11.getlistcreat();
          } else {
            _this11.$message.success('已解冻');
            _this11.getlistcreat();
          }
        }
      });
    },
    // 上传图片
    handleAvatarSuccess: function handleAvatarSuccess(response, file, fileList) {
      // let o = URL.createObjectURL(file);
      // this.Lists.push(fileList)
      // this.Lists.push({name:fileList.name,url:URL.createObjectURL(fileList.raw)})
      // console.log(response)
      if (response.code === 200) {
        this.$message.info(response.data);
        this.getlistcreat();
      } else {
        this.$message.warning(response.msg);
      }
    },
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      var isLt2M = file.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.closeAll();
        this.$message.error('上传图片大小不能超过 1MB!');
      }
      return isLt2M;
    },
    imgUploaderr: function imgUploaderr(err, file, fileList) {
      // if(file.code)
      this.$message.error(err.msg);
    },
    // 下载模板
    downmuban: function downmuban() {
      var that = this;
      (0, _wxHy.downtemplate)().then(function (res) {
        res = res.data;
        // console.log(res)
        var blob = new Blob([res], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '模板.xlsx';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
        that.downLoading = false;
      }).catch(function (res) {
        that.$message.warning(res.msg);
      });
    },
    // 列表详情
    handleinfo: function handleinfo(val, type) {
      var _this12 = this;
      this.$confirm('您确定要删除吗？', '删除操作', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _wxyyKhlb.deleteById)({
          id: val
        }).then(function (res) {
          res = res.data;
          if (res.code * 1 === 200) {
            _this12.$message.success('删除成功');
          } else {
            _this12.$message.warning(res.msg);
          }
          _this12.gethylist(_this12.formInline);
        });
        // this.userload = true
        // this.getserrch(this.formInline)
      }).catch(function () {
        _this12.$message({
          type: 'info',
          message: '已取消'
        });
      });

      // if (type === 1) {
      //   this.$router.push({
      //     name: 'wxIntegralFlow',
      //     query: { 'id': val }
      //   })
      // } else {
      //   this.$router.push({
      //     name: 'wxIntegralOrder',
      //     query: { 'id': val }
      //   })
      // }
      // console.log(val)
    },
    // 导入会员
    imvip: function imvip() {
      this.imdiavip = true;
      // console.log('1')
    },
    // 下载明细
    download: function download() {
      var that = this;
      (0, _wxHy.downdetail)().then(function (res) {
        res = res.data;
        // console.log(res)
        var blob = new Blob([res], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '明细.xlsx';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
        that.downLoading = false;
      }).catch(function (res) {
        that.$message.warning(res.msg);
      });
    },
    // 修改任务值
    onSubmitt: function onSubmitt() {
      if (this.selectlist.length === 0) {
        this.$message.warning('请选择一行数据进行修改');
        return;
      }
      this.selectlist.forEach(function (i) {
        i.flag = 2;
        i.phone = i.phone + '.';
      });
      // console.log(this.selectlist)
      this.xgtask = true;
    },
    // 修改任务值提交
    xgbtn: function xgbtn() {
      var _this13 = this;
      var n = [];
      this.selectlist.forEach(function (el) {
        var a = {
          memberId: el.memberId,
          syjf: el.syjf
        };
        n.push(a);
      });
      // console.log(n)
      (0, _wXintegralMall.updateTaskByIds)(n).then(function (res) {
        res = res.data;
        if (res.code === 200) {
          _this13.$message.success('修改成功');
          _this13.$refs.multipleTable.clearSelection();
          _this13.xgtask = false;
          _this13.gethylist();
          _this13.$router.go(0);
        } else {
          _this13.$message.warning(res.msg);
          return false;
        }
      });
    },
    // qxbtn() {
    //   var that = this
    //   that.selectlist.forEach((i) => {
    //     i.flag = 1
    //     i.phone = i.phone.substring(0, i.phone.length - 1)
    //   })
    //   that.$refs.multipleTable.clearSelection()
    //   that.xgtask = false
    //   that.gethylist()
    // },
    // 重置
    onReset: function onReset() {
      this.formInline = {
        selectName: '',
        memberValue: '',
        provinceStr: '',
        startTime: '',
        endTime: '',
        pageNo: 1,
        pageSize: 10
      };
      this.value2 = '';
    },
    onSubmit: function onSubmit(val) {
      var that = this;
      that.vipload = true;
      // if (val.selectName === '') {
      //   that.$message.warning('请选择会员选项')
      //   return
      // }
      // if (val.selectName === '1' && val.memberNum.replace(/(^\s*)|(\s*$)/g, '') === '') {
      //   that.$message.warning('请填写会员卡号')
      //   return
      // }
      // if (val.selectName === '2' && val.phone.replace(/(^\s*)|(\s*$)/g, '') === '') {
      //   that.$message.warning('请填写手机号')
      //   return
      // }
      // if (val.selectName === '3' && val.memberName.replace(/(^\s*)|(\s*$)/g, '') === '') {
      //   that.$message.warning('请填写会员姓名')
      //   return
      // }
      // if (val.ly.replace(/(^\s*)|(\s*$)/g, '') === '') {
      //   that.$message.warning('请填写会员来源')
      //   return
      // }
      // if (val.memberType === '') {
      //   that.$message.warning('请选择会员类型')
      //   return
      // }
      // if (val.type === '') {
      //   that.$message.warning('请选择会员等级')
      //   return
      // }
      // if (val.value2 === '') {
      //   that.$message.warning('请选择时间')
      //   return
      // }
      if (that.value2 !== '') {
        val.startTime = that.value2[0];
        val.endTime = that.value2[1];
      }
      // that.getlistcreat(val)
      val.pageNo = 1;
      that.gethylist(val);

      // getlistsearch(val).then(res => {
      //    // console.log(res)
      //   if (res.code === 200) {
      //     this.tableData = res.data
      //     that.vipload = false
      //   }
      // })
    },
    getlistcreat: function getlistcreat(val) {
      var _this14 = this;
      var a = val || '';
      this.vipload = true;
      (0, _wxHy.getlistsearch)(a).then(function (res) {
        res = res.data;
        // console.log(res)
        if (res.code === 200) {
          _this14.tableData = res.data.records;
          _this14.tablehead = [{
            label: '会员卡号',
            prop: 'memberCradNumber'
          }, {
            label: '会员卡号',
            prop: 'memberCradNumber'
          }, {
            label: '会员卡号',
            prop: 'memberCradNumber'
          }];
          _this14.total = res.data.total;
          _this14.tableData.forEach(function (i) {
            i.flag = 1;
          });
          _this14.total = res.data.total;
          // console.log(this.tableData)
        }

        _this14.vipload = false;
      });
    },
    // 表格选中操作
    // handleSelection(selection, row) {
    //    // console.log(selection)
    // },
    // 表格变化操作
    handleSelectionChange: function handleSelectionChange(val) {
      var _this15 = this;
      // console.log(val)
      this.selectlist = val;
      this.multipleSelection = val;
      this.tagsList.userTagQuery = [];
      this.multipleSelection.forEach(function (val) {
        var n = {
          openId: '',
          userId: ''
        };
        n.openId = val.openId;
        n.userId = val.userId;
        _this15.tagsList.userTagQuery.push(n);
      });
    }
  }
};
exports.default = _default;