"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _admincut = _interopRequireDefault(require("@/components/admincut"));
var _PieChart = _interopRequireDefault(require("../../dataAlysis/yhfx/yhData/components/PieChart"));
var _barChart = _interopRequireDefault(require("./components/barChart"));
var _chinaChart = _interopRequireDefault(require("./components/chinaChart"));
var _wxHy = require("@/api/wxhyyy/wxHy");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
/* eslint-disable vue/no-unused-components */
var _default = {
  components: {
    admincut: _admincut.default,
    PieChart: _PieChart.default,
    barChart: _barChart.default,
    ChinaChart: _chinaChart.default
  },
  filters: {
    filternum: function filternum(value) {
      var a = Number(value) || 0;
      a = (a * 100).toFixed(2);
      return a;
    }
  },
  data: function data() {
    return {
      barChartData1: {
        legendData: [],
        seriesData: []
      },
      barChartData2: {
        legendData: [],
        seriesData: []
      },
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 0);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '昨天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            end.setTime(end.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '近两天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      tableData1: [],
      tableData2: [],
      msa: {
        age: '',
        taskValue: ''
      },
      value2: '',
      Chinadate: [],
      aa: [1, 2, 3],
      list: {
        startTime: '',
        endTime: '',
        startTaskValue: 0,
        taskValue: 100,
        age: 10,
        startAge: 0
      },
      cityDataList: '',
      gradeDataList: '',
      lyDataList: '',
      memberInfoAgeVOList: '',
      memberInfoTaskVlueVOList: '',
      provinceDataList: '',
      sexDataList: '',
      options1: [{
        value: 10,
        label: 10
      }, {
        value: 20,
        label: 20
      }, {
        value: 30,
        label: 30
      }, {
        value: 40,
        label: 40
      }
      // {
      //   value: 50,
      //   label: 50
      // },
      // {
      //   value: 60,
      //   label: 60
      // },
      // {
      //   value: 70,
      //   label: 70
      // },
      // {
      //   value: 80,
      //   label: 80
      // },
      // {
      //   value: 90,
      //   label: 90
      // },
      // {
      //   value: 100,
      //   label: 100
      // }
      ],

      options2: [{
        value: 10,
        label: 10
      }, {
        value: 50,
        label: 50
      }, {
        value: 100,
        label: 100
      }, {
        value: 200,
        label: 200
      }, {
        value: 300,
        label: 300
      }, {
        value: 400,
        label: 400
      }
      // {
      //   value: 500,
      //   label: 500
      // },
      // {
      //   value: 1000,
      //   label: 1000
      // },
      // {
      //   value: 2000,
      //   label: 2000
      // },
      // {
      //   value: 5000,
      //   label: 5000
      // },
      // {
      //   value: 10000,
      //   label: 10000
      // }
      ]
    };
  },
  created: function created() {
    this.getdatamsa();
    // this.ininpiedata(this.aa)
    // this.Chinadate = [{
    //   'name': '北京',
    //   'value': 599
    // }, {
    //   'name': '上海',
    //   'value': 142
    // }, {
    //   'name': '黑龙江',
    //   'value': 44
    // }, {
    //   'name': '深圳',
    //   'value': 92
    // }, {
    //   'name': '湖北',
    //   'value': 810
    // }, {
    //   'name': '四川',
    //   'value': 453
    // }]
  },
  mounted: function mounted() {
    // var that = this
  },
  methods: {
    getdatamsa: function getdatamsa() {
      var that = this;
      if (that.value2 !== '') {
        that.list.startTime = that.value2[0];
        that.list.endTime = that.value2[1];
      }
      (0, _wxHy.searchAnalysis)(that.list).then(function (res) {
        console.log(res);
        if (res.code === 200) {
          console.log(res);
          that.cityDataList = res.data.cityDataList;
          console.log(that.cityDataList);
          that.gradeDataList = res.data.gradeDataList;
          that.lyDataList = res.data.lyDataList;
          that.memberInfoAgeVOList = res.data.memberInfoAgeVOList;
          that.memberInfoTaskVlueVOList = res.data.memberInfoTaskVlueVOList;
          console.log(that.memberInfoTaskVlueVOList);
          that.provinceDataList = res.data.provinceDataList;
          that.sexDataList = res.data.sexDataList;
          var a = [];
          var b = [];
          var c = [];
          var d = [];
          console.log(that.sexDataList);
          that.sexDataList.forEach(function (e) {
            a.push({
              value: e.number,
              name: e.nme
            });
          });
          that.memberInfoAgeVOList.forEach(function (e) {
            b.push({
              value: e.number,
              name: e.ageStr
            });
          });
          that.lyDataList.forEach(function (e) {
            c.push({
              value: e.number,
              name: e.nme
            });
          });
          that.gradeDataList.forEach(function (e) {
            d.push({
              value: e.number,
              name: e.nme
            });
          });
          that.$refs.sexSourcePie.legendData = [{
            orient: 'horizontal',
            left: 'center',
            top: 'bottom'
          }];
          that.$refs.sexSourcePie.seriesData = [{
            type: 'pie',
            radius: '60%',
            center: ['50%', '50%'],
            data: a,
            label: {
              normal: {
                formatter: '{d}%'
              }
            }
          }];
          that.$refs.sexSourcePie.initChart();
          that.$refs.ageSourcePie.legendData = [{
            orient: 'horizontal',
            left: 'center',
            top: 'bottom'
          }];
          that.$refs.ageSourcePie.seriesData = [{
            type: 'pie',
            radius: '60%',
            center: ['50%', '50%'],
            data: b,
            label: {
              normal: {
                formatter: '{d}%'
              }
            }
          }];
          that.$refs.ageSourcePie.initChart();
          that.$refs.vipsourSourcePie.legendData = [{
            orient: 'horizontal',
            left: 'center',
            top: 'bottom'
          }];
          that.$refs.vipsourSourcePie.seriesData = [{
            type: 'pie',
            radius: '60%',
            center: ['50%', '50%'],
            data: c,
            label: {
              normal: {
                formatter: '{d}%'
              }
            }
          }];
          that.$refs.vipsourSourcePie.initChart();
          that.$refs.viplevelSourcePie.legendData = [{
            orient: 'horizontal',
            left: 'center',
            top: 'bottom'
          }];
          that.$refs.viplevelSourcePie.seriesData = [{
            type: 'pie',
            radius: '60%',
            center: ['50%', '50%'],
            data: d,
            label: {
              normal: {
                formatter: '{d}%'
              }
            }
          }];
          that.$refs.viplevelSourcePie.initChart();
          // 任务重柱图
          var x1 = [];
          var y1 = [];
          var x2 = [];
          var y2 = [];
          that.memberInfoTaskVlueVOList.forEach(function (e) {
            x1.push(e.taskStr);
            y1.push(e.number);
          });
          that.barChartData1 = {
            legendData: x1,
            seriesData: y1
          };
          that.cityDataList.forEach(function (e) {
            x2.push(e.nme);
            y2.push(e.number);
          });
          that.barChartData2 = {
            legendData: x2,
            seriesData: y2
          };

          // 地图分布
          var e = [];
          that.provinceDataList.forEach(function (i) {
            e.push({
              name: i.nme,
              value: i.number
            });
          });
          that.Chinadate = e;
        } else {
          that.$message.warning(res.msg);
        }
      });
      // } else {
      //   searchAnalysis(that.list).then(res => {
      //     console.log(res)
      //     if (res.code === 200) {
      //       console.log(res)
      //       that.$message.success(res.msg)
      //     } else {
      //       that.$message.warning(res.msg)
      //     }
      //   })
      // }
    },
    // 初始化数据
    ininpiedata: function ininpiedata(val1) {
      var that = this;
      that.$refs.sexSourcePie.legendData = [{
        orient: 'vertical',
        left: '40%',
        top: '10%'
      }];
      that.$refs.sexSourcePie.seriesData = [{
        type: 'pie',
        radius: '60%',
        center: ['20%', '50%'],
        data: val1,
        label: {
          normal: {
            formatter: '{d}%'
          }
        }
      }];
      that.$refs.sexSourcePie.initChart();
      // that.$refs.ageSourcePie.initChart()
      // that.$refs.vipsourSourcePie.initChart()
      // that.$refs.viplevelSourcePie.initChart()
    } // initdata() {
    //   var that = this
    // }
  }
};
exports.default = _default;