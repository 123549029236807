var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.apis)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c(
                "el-form",
                {
                  ref: "listQuery",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.listQuery,
                    "status-icon": "",
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cols" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "outgoType",
                                    label: "出入库类型",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.listQuery.outgoType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.listQuery,
                                            "outgoType",
                                            $$v
                                          )
                                        },
                                        expression: "listQuery.outgoType",
                                      },
                                    },
                                    _vm._l(_vm.stockOptions, function (item) {
                                      return _c("el-option", {
                                        key: item.code,
                                        attrs: {
                                          label: item.name,
                                          value: item.code,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "bussinessStatus",
                                    label: "状态",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.listQuery.bussinessStatus,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.listQuery,
                                            "bussinessStatus",
                                            $$v
                                          )
                                        },
                                        expression: "listQuery.bussinessStatus",
                                      },
                                    },
                                    _vm._l(_vm.states, function (item) {
                                      return _c("el-option", {
                                        key: item.code,
                                        attrs: {
                                          label: item.name,
                                          value: item.code,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { prop: "keyWords", label: "关键字" },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "业务编码/名称" },
                                    model: {
                                      value: _vm.listQuery.keyWords,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.listQuery, "keyWords", $$v)
                                      },
                                      expression: "listQuery.keyWords",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.formConfig.btnFollow
                            ? _c(
                                "el-col",
                                {
                                  staticClass: "head-btn-group",
                                  attrs: { span: 6 },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { "label-width": "0" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v(" ")]
                                      ),
                                      _vm._v(" "),
                                      _vm.hasAuth("searchbtn", _vm.apis)
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: _vm.btnConfig.searchBtn
                                                  .type,
                                                size: _vm.btnConfig.size,
                                                icon: _vm.btnConfig.searchBtn
                                                  .icon,
                                              },
                                              on: { click: _vm.DoSearch },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.btnConfig.searchBtn.text
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.formConfig.showDvider
                                        ? _c("el-divider", {
                                            attrs: { direction: "vertical" },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.hasAuth("searchbtn", _vm.apis)
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: _vm.btnConfig.resetBtn
                                                  .type,
                                                size: _vm.btnConfig.size,
                                                icon: _vm.btnConfig.resetBtn
                                                  .icon,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.DoReset(
                                                    _vm.listQuery
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.btnConfig.resetBtn.text
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.formConfig.btnFollow
                ? _c("el-divider", { staticClass: "btnDivider" })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btnArea" },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "head-btn-group",
                      staticStyle: { width: "230px" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "head-btn-group" },
                        [
                          _vm.hasAuth("addbtn", _vm.apis)
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.addBtn.type,
                                    size: _vm.btnConfig.size,
                                    plain: _vm.btnConfig.addBtn.plain,
                                    icon: _vm.btnConfig.addBtn.icon,
                                  },
                                  on: { click: _vm.DoAdd },
                                },
                                [_vm._v(_vm._s(_vm.btnConfig.addBtn.text))]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "section",
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      key: _vm.tableKey,
                      staticStyle: { width: "100%" },
                      attrs: {
                        height: _vm.tabHeight,
                        data: _vm.list,
                        "label-position": _vm.formConfig.labelPosition,
                        "label-width": _vm.formConfig.labelWidth,
                        fit: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "业务编码",
                          prop: "businessCode",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "业务类型",
                          prop: "businessTypeName",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "出入库类型",
                          prop: "outgoType",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  row.outgoType == 1
                                    ? _c("span", [_vm._v("出库")])
                                    : row.outgoType == 2
                                    ? _c("span", [_vm._v("入库")])
                                    : _c("span"),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          399124883
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "单据",
                          prop: "billEnum",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  row.billEnum == 1
                                    ? _c("span", [_vm._v("期初入库单")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.billEnum == 2
                                    ? _c("span", [_vm._v("采购入库单")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.billEnum == 3
                                    ? _c("span", [_vm._v("调拨入库单")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.billEnum == 4
                                    ? _c("span", [_vm._v("其他入库单")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.billEnum == 5
                                    ? _c("span", [_vm._v("销售出库单")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.billEnum == 6
                                    ? _c("span", [_vm._v("调拨出库单")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.billEnum == 7
                                    ? _c("span", [_vm._v("其他出库单")])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          143943876
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "来源",
                          prop: "sourceEnum",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  row.sourceEnum == 0
                                    ? _c("span", [_vm._v("无")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.sourceEnum == 1
                                    ? _c("span", [_vm._v("本系统")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.sourceEnum == 2
                                    ? _c("span", [_vm._v("外系统")])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1899669137
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "来源单据",
                          prop: "sourceBill",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  row.sourceBill == 1
                                    ? _c("span", [_vm._v("调拨单")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.sourceBill == 2
                                    ? _c("span", [_vm._v("企业销售出库单")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.sourceBill == 3
                                    ? _c("span", [_vm._v("盘点单")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.sourceBill == 4
                                    ? _c("span", [_vm._v("采购入库单")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.sourceBill == 5
                                    ? _c("span", [_vm._v("销售出库单")])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1772342633
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "自定义",
                          prop: "isCustom",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  row.isCustom == 0
                                    ? _c("span", [_vm._v("否")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.isCustom == 1
                                    ? _c("span", [_vm._v("是")])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1246711870
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "提交即审核",
                          prop: "submitYes",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  row.submitYes == 0
                                    ? _c("span", [_vm._v("否")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.submitYes == 1
                                    ? _c("span", [_vm._v("是")])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3853430494
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "状态",
                          prop: "bussinessStatus",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  row.bussinessStatus == 1
                                    ? _c(
                                        "el-tag",
                                        { attrs: { type: "success" } },
                                        [_vm._v("启用")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.bussinessStatus == 0
                                    ? _c(
                                        "el-tag",
                                        { attrs: { type: "danger" } },
                                        [_vm._v("禁用")]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1390926785
                        ),
                      }),
                      _vm._v(" "),
                      _vm.hasAuth("detailbtn", _vm.apis) ||
                      _vm.hasAuth("updatebtn", _vm.apis) ||
                      _vm.hasAuth("startbtn", _vm.apis) ||
                      _vm.hasAuth("stopbtn", _vm.apis)
                        ? _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              align: _vm.tableConfig.align,
                              width: "200",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _vm.hasAuth("detailbtn", _vm.apis)
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.DoScan(row)
                                                },
                                              },
                                            },
                                            [_vm._v("详情")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.hasAuth("updatebtn", _vm.apis)
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.DoEdit(row)
                                                },
                                              },
                                            },
                                            [_vm._v("修改")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      (
                                        row.bussinessStatus == 0
                                          ? true
                                          : false &&
                                            _vm.hasAuth("startbtn", _vm.apis)
                                      )
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.DoStart(row)
                                                },
                                              },
                                            },
                                            [_vm._v("启用")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      (
                                        row.bussinessStatus == 1
                                          ? true
                                          : false &&
                                            _vm.hasAuth("stopbtn", _vm.apis)
                                      )
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.DoStop(row)
                                                },
                                              },
                                            },
                                            [_vm._v("禁用")]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              583205710
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("pagination", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.total > 0,
                        expression: "total > 0",
                      },
                    ],
                    attrs: {
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.total,
                      page: _vm.listQuery.current,
                      limit: _vm.listQuery.size,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(_vm.listQuery, "current", $event)
                      },
                      "update:limit": function ($event) {
                        return _vm.$set(_vm.listQuery, "size", $event)
                      },
                      pagination: _vm.getList,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.apis)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: "业务类型-查看",
            visible: _vm.scanVisible,
            width: "1000px !important",
          },
          on: {
            "update:visible": function ($event) {
              _vm.scanVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div form-div-label" }, [
                    _vm._v("业务编码:"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.scanData.businessCode)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div form-div-label" }, [
                    _vm._v("业务类型:"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.scanData.businessTypeName)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div form-div-label" }, [
                    _vm._v("出入库类型:"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(
                      _vm._s(
                        _vm.scanData.outgoType == 1
                          ? "出库"
                          : _vm.scanData.outgoType == 2
                          ? "入库"
                          : ""
                      )
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div form-div-label" }, [
                    _vm._v("单据:"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm.scanData.billEnum == 1
                      ? _c("span", [_vm._v("期初入库单")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.scanData.billEnum == 2
                      ? _c("span", [_vm._v("采购入库单")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.scanData.billEnum == 3
                      ? _c("span", [_vm._v("调拨入库单")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.scanData.billEnum == 4
                      ? _c("span", [_vm._v("其他入库单")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.scanData.billEnum == 5
                      ? _c("span", [_vm._v("销售出库单")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.scanData.billEnum == 6
                      ? _c("span", [_vm._v("调拨出库单")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.scanData.billEnum == 7
                      ? _c("span", [_vm._v("其他出库单")])
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div form-div-label" }, [
                    _vm._v("来源:"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm.scanData.sourceEnum == 0
                      ? _c("span", [_vm._v("无")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.scanData.sourceEnum == 1
                      ? _c("span", [_vm._v("本系统")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.scanData.sourceEnum == 2
                      ? _c("span", [_vm._v("外系统")])
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div form-div-label" }, [
                    _vm._v("来源单据:"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm.scanData.sourceBill == 1
                      ? _c("span", [_vm._v("调拨单")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.scanData.sourceBill == 2
                      ? _c("span", [_vm._v("企业销售出库单")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.scanData.sourceBill == 3
                      ? _c("span", [_vm._v("盘点单")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.scanData.sourceBill == 4
                      ? _c("span", [_vm._v("采购入库单")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.scanData.sourceBill == 5
                      ? _c("span", [_vm._v("销售出库单")])
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div form-div-label" }, [
                    _vm._v("提交即审核:"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm.scanData.submitYes == 0
                      ? _c("span", [_vm._v("否")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.scanData.submitYes == 1
                      ? _c("span", [_vm._v("是")])
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div form-div-label" }, [
                    _vm._v("状态:"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm.scanData.bussinessStatus == 0
                      ? _c("span", [_vm._v("已禁用")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.scanData.bussinessStatus == 1
                      ? _c("span", [_vm._v("已启用")])
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 24 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div form-div-label" }, [
                    _vm._v("备注:"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.scanData.remarks)),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "default", plain: "" },
                  on: {
                    click: function ($event) {
                      _vm.scanVisible = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: _vm.title,
            visible: _vm.dialogVisible,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "addForm",
              attrs: {
                rules: _vm.rules,
                model: _vm.addData,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "业务编码", prop: "businessCode" } },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.subType == "edit",
                      placeholder: "请输入",
                      maxlength: "40",
                    },
                    model: {
                      value: _vm.addData.businessCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.addData, "businessCode", $$v)
                      },
                      expression: "addData.businessCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "业务类型", prop: "businessTypeName" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入",
                      maxlength: "40",
                      disabled:
                        _vm.subType == "edit" && _vm.addData.isCustom == 0,
                    },
                    model: {
                      value: _vm.addData.businessTypeName,
                      callback: function ($$v) {
                        _vm.$set(_vm.addData, "businessTypeName", $$v)
                      },
                      expression: "addData.businessTypeName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "出入库类型", prop: "outgoType" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        placeholder: "请选择",
                        disabled:
                          _vm.subType == "edit" && _vm.addData.isCustom == 0,
                      },
                      model: {
                        value: _vm.addData.outgoType,
                        callback: function ($$v) {
                          _vm.$set(_vm.addData, "outgoType", $$v)
                        },
                        expression: "addData.outgoType",
                      },
                    },
                    _vm._l(_vm.stockOptions, function (item) {
                      return _c("el-option", {
                        key: item.code,
                        attrs: { label: item.name, value: item.code },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "单据", prop: "billEnum" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        placeholder: "请选择",
                        disabled:
                          _vm.subType == "edit" && _vm.addData.isCustom == 0,
                      },
                      model: {
                        value: _vm.addData.billEnum,
                        callback: function ($$v) {
                          _vm.$set(_vm.addData, "billEnum", $$v)
                        },
                        expression: "addData.billEnum",
                      },
                    },
                    _vm._l(_vm.billOptions, function (item) {
                      return _c("el-option", {
                        key: item.code,
                        attrs: { label: item.name, value: item.code },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "来源", prop: "sourceEnum" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        placeholder: "请选择",
                        disabled:
                          _vm.subType == "edit" && _vm.addData.isCustom == 0,
                      },
                      model: {
                        value: _vm.addData.sourceEnum,
                        callback: function ($$v) {
                          _vm.$set(_vm.addData, "sourceEnum", $$v)
                        },
                        expression: "addData.sourceEnum",
                      },
                    },
                    _vm._l(_vm.sourceOptions, function (item) {
                      return _c("el-option", {
                        key: item.code,
                        attrs: { label: item.name, value: item.code },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "来源单据", prop: "sourceBill" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        placeholder: "请选择",
                        disabled:
                          _vm.subType == "edit" && _vm.addData.isCustom == 0,
                      },
                      model: {
                        value: _vm.addData.sourceBill,
                        callback: function ($$v) {
                          _vm.$set(_vm.addData, "sourceBill", $$v)
                        },
                        expression: "addData.sourceBill",
                      },
                    },
                    _vm._l(_vm.sbillOptions, function (item) {
                      return _c("el-option", {
                        key: item.code,
                        attrs: { label: item.name, value: item.code },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "is-required",
                  attrs: { label: "提交即审核", prop: "submitYes" },
                },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-text": "是",
                      "inactive-text": "否",
                      "active-value": 1,
                      "inactive-value": 0,
                    },
                    model: {
                      value: _vm.addData.submitYes,
                      callback: function ($$v) {
                        _vm.$set(_vm.addData, "submitYes", $$v)
                      },
                      expression: "addData.submitYes",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "is-required",
                  attrs: { label: "状态", prop: "bussinessStatus" },
                },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-text": "启用",
                      "inactive-text": "禁用",
                      "active-value": 1,
                      "inactive-value": 0,
                    },
                    model: {
                      value: _vm.addData.bussinessStatus,
                      callback: function ($$v) {
                        _vm.$set(_vm.addData, "bussinessStatus", $$v)
                      },
                      expression: "addData.bussinessStatus",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remarks" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", maxlength: "500" },
                    model: {
                      value: _vm.addData.remarks,
                      callback: function ($$v) {
                        _vm.$set(_vm.addData, "remarks", $$v)
                      },
                      expression: "addData.remarks",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", [
                _c(
                  "div",
                  { staticClass: "align-right" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", disabled: _vm.dstate },
                        on: {
                          click: function ($event) {
                            return _vm.submitForm("addForm")
                          },
                        },
                      },
                      [_vm._v("确定")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.resetForm("addForm")
                          },
                        },
                      },
                      [_vm._v("取消")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }