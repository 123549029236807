"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.endSalesman = endSalesman;
exports.expSalesmanList = expSalesmanList;
exports.getSalesman = getSalesman;
exports.getSalesmanList = getSalesmanList;
exports.saveSalesman = saveSalesman;
exports.startSalesman = startSalesman;
var _request = _interopRequireDefault(require("@/utils/request"));
var base = process.env.VUE_APP_VERSION2;

// 业务员列表列表
function getSalesmanList(data) {
  return (0, _request.default)({
    url: base + '/zdConfig/salesman/getSalesmanList',
    method: 'post',
    data: data
  });
}

// 导出业务员列表列表
function expSalesmanList(data) {
  return (0, _request.default)({
    url: base + '/zdConfig/salesman/getSalesmanList_exp',
    method: 'post',
    responseType: 'blob',
    data: data
  });
}

// 业务员保存
function saveSalesman(data) {
  return (0, _request.default)({
    url: base + '/zdConfig/salesman/saveSalesman',
    method: 'post',
    data: data
  });
}

// 解冻业务员
function startSalesman(params) {
  return (0, _request.default)({
    url: base + '/zdConfig/salesman/startSalesman',
    method: 'post',
    params: params
  });
}

// 冻结业务员
function endSalesman(params) {
  return (0, _request.default)({
    url: base + '/zdConfig/salesman/endSalesman',
    method: 'post',
    params: params
  });
}

// 业务员核销记录列表
function getSalesman(data) {
  return (0, _request.default)({
    url: base + '/zdConfig/salesmanrecord/getSalesmanScanRecordsList',
    method: 'post',
    data: data
  });
}