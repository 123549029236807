"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _wxHy = require("@/api/wxhyyy/wxHy");
var _wXintegralMall = require("@/api/wxhyyy/wXintegralMall");
var _wxgzh = require("@/api/wxhyyy/wxgzh");
var _wxyyKhlb = require("@/api/wxhyyy/wxyyKhlb");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

var baseUrl = '/wh';
var _default = {
  filters: {
    mathnum: function mathnum(val) {
      var a = val || 0;
      return Math.floor(a * 100) / 100;
    }
  },
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      tagss: [],
      // 标签
      dialogVisible: false,
      taggg: '',
      // 去除开头固定标签
      checkList: '',
      // 选中标签集合
      multipleSelection: '',
      // 打标签数据
      tagsList: {
        tagId: '',
        userTagQuery: []
      },
      condlist: [],
      tablehead: [],
      total: 0,
      total1: 0,
      xgtask: false,
      tableData1: [],
      imdiavip: false,
      infodia: false,
      levdata: [],
      vipload: false,
      tableData: [],
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 0);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '昨天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            end.setTime(end.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '近两天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      value: '',
      value2: '',
      selectlist: '',
      infopro: '',
      xglist: [],
      dqlist: [],
      // -----
      hylist: [],
      bqlist: [],
      // fstpUrl: baseUrl + '/api-memberscrm/member/admin/smsGroup/mobile/import',
      fstpUrl: '/api-memberscrm/member/admin/smsGroup/mobile/import',
      myHeaders: {
        Authorization: 'Bearer ' + (0, _auth.getToken)(),
        'appId': sessionStorage.getItem('appId')
      },
      vipdata: '1',
      dlogset: false,
      dlogphone: false,
      dlogdxmu: false,
      dloghydj: false,
      dlogmsginfo: false,
      dlogbq: false,
      diamb: false,
      dlogcsdx: false,
      dlogcg: false,
      numphone: '',
      csphone: '',
      pageForm: {
        pageNo: 0,
        size: 5,
        startTime: '',
        endtime: '',
        companyId: '',
        current: 1,
        sendStatus: '0'
      },
      formInline: {
        size: 5,
        startTime: '',
        endtime: '',
        companyId: '',
        current: 1,
        sendStatus: ''
      },
      statuslist: [],
      dxfail: {
        groupId: '',
        current: 1,
        size: 5
      },
      value3: '',
      form: {
        groupName: '',
        bizSerialNo: '',
        groupType: '1',
        labelCodes: [],
        memberLevel: [],
        sendTime: '',
        sendType: '0',
        smsSource: '0',
        templateCode: ''
      },
      msglist: [],
      mblist: [],
      outnum: 0,
      innum: 0,
      tserr: '',
      isErr: false,
      mbname: '',
      hylist1: []
    };
  },
  created: function created() {
    this.getmsglists();
    this.getsendstatus();
    this.getvipdata();
    this.getdatalist();
    this.getmslist();
  },
  methods: {
    xjqfdx: function xjqfdx() {
      this.dlogset = true;
      this.form = {
        groupName: '',
        bizSerialNo: '',
        groupType: '1',
        labelCodes: [],
        memberLevel: [],
        sendTime: '',
        sendType: '0',
        smsSource: '0',
        templateCode: ''
      };
      this.outnum = 0;
      this.innum = 0;
    },
    handleClosemb: function handleClosemb() {
      this.form.templateCode = '';
    },
    xzmbd: function xzmbd(val) {
      var _this = this;
      console.log(val);
      var mblist = this.mblist;
      mblist.forEach(function (v, i) {
        if (val === v.id) {
          _this.mbname = v.name;
        }
      });
    },
    getmslist: function getmslist() {
      var _this2 = this;
      (0, _wxyyKhlb.getmsgList)().then(function (res) {
        res = res.data;
        console.log(res);
        if (res.code === 200) {
          if (res.data.length > 0 && res.data) {
            _this2.msglist = res.data;
          } else {
            return false;
          }
        } else {
          return _this2.$message.info(res.msg);
        }
      });
    },
    xzbqlist: function xzbqlist() {
      var _this3 = this;
      var that = this;
      var form = that.form;
      form.groupType = '1';
      if (form.labelCodes.length > 0) {
        (0, _wxyyKhlb.getmsgnum)(form).then(function (res) {
          res = res.data;
          console.log(res);
          if (res.code === 200) {
            _this3.outnum = res.data.sendCount;
            _this3.innum = res.data.ableSendCount;
          } else {
            _this3.$message.warning(res.msg);
            return false;
          }
        });
      }
      that.dlogbq = false;
    },
    xzhylist: function xzhylist() {
      var _this4 = this;
      var that = this;
      var form = that.form;
      form.groupType = '2';
      if (form.memberLevel.length > 0) {
        (0, _wxyyKhlb.getmsgnum)(form).then(function (res) {
          res = res.data;
          console.log(res);
          if (res.code === 200) {
            _this4.outnum = res.data.sendCount;
            _this4.innum = res.data.ableSendCount;
          } else {
            _this4.$message.warning(res.msg);
          }
        });
      }
      console.log('hylist', that.form.memberLevel);
      that.dloghydj = false;
    },
    xztype: function xztype(val) {
      var _this5 = this;
      this.form.bizSerialNo = '';
      this.form.labelCodes = [];
      this.form.memberLevel = [];
      this.outnum = 0;
      this.innum = 0;
      var num;
      console.log('val', val);
      switch (val) {
        case '1':
          num = val;
          break;
        case '2':
          num = val;
          break;
        case '3':
          num = val;
          break;
        case '4':
          num = val;
          break;
        case '5':
          num = val;
          break;
        case '6':
          num = val;
          break;
        case '7':
          num = val;
          break;
        default:
          num = '1';
          break;
      }
      // let a = this.hylist.slice(0, 1)

      var a = [];
      console.log(this.hylist1);
      this.hylist1.forEach(function (v, i) {
        a.push(v.value);
      });
      console.log(a);
      var n = {
        bizSerialNo: '',
        groupType: num,
        labelCodes: [],
        memberLevel: []
      };
      if (n.groupType === '3') {
        n.memberLevel = a.slice(0, 1);
        // n.memberLevel = []
      }

      (0, _wxyyKhlb.getmsgnum)(n).then(function (res) {
        res = res.data;
        console.log(res);
        if (res.code === 200) {
          _this5.outnum = res.data.sendCount;
          _this5.innum = res.data.ableSendCount;
        } else {
          if (val === '1') {
            return;
          }
          _this5.$message.warning(res.msg);
        }
      });
    },
    // 测试短信发送
    testsms: function testsms() {
      var _this6 = this;
      var that = this;
      var form = that.form;
      if (!/^1((3[\d])|(4[5,6,9])|(5[0-3,5-9])|(6[5-7])|(7[0-8])|(8[1-3,5-8])|(9[1,8,9]))\d{8}$/.exec(this.csphone)) {
        return this.$message.warning('请输入正确的手机号');
      }
      if (!form.templateCode) {
        return this.$message.warning('请选择短信模板');
      }
      var n = {
        mobile: that.csphone,
        smsSourceCode: form.smsSource,
        templateCode: form.templateCode
      };
      // getmockSendSms(JSON.stringify(n)).then(res => {
      (0, _wxyyKhlb.getmockSendSms)(n).then(function (res) {
        res = res.data;
        if (res.code === 200) {
          _this6.$message.success(res.msg);
        } else {
          _this6.$message.warning(res.msg);
        }
      });
    },
    // 查询标签
    getdatalist: function getdatalist() {
      var _this7 = this;
      var that = this;
      (0, _wxyyKhlb.getmsgmbList)(that.form.smsSource).then(function (res) {
        res = res.data;
        console.log(res);
        if (res.code === 200) {
          if (res.data && res.data.length > 0) {
            that.mblist = res.data;
          }
        } else {
          _this7.$message.warning(res.msg);
        }
        console.log(that.mblist);
      });
      (0, _wxyyKhlb.getmsgBq)().then(function (res) {
        res = res.data;
        if (res.code === 200) {
          if (res.data && res.data.length > 0) {
            that.bqlist = res.data;
          }
        } else {
          _this7.$message.warning(res.msg);
        }
      });
    },
    vipFun: function vipFun(val) {
      if (this.form.memberLevel) {
        // return
      } else {
        this.form.memberLevel = [];
      }
      if (val === '2') {
        this.form.groupType = '2';
        this.dloghydj = true;
        this.outnum = 0;
        this.innum = 0;
      }
      if (val === '7') {
        this.form.groupType = '7';
        this.dlogphone = true;
        this.outnum = 0;
        this.innum = 0;
      }
      if (val === '1') {
        this.form.groupType = '1';
        this.dlogbq = true;
      }
      // if (val === '2') {
      //   this.dlogdxmu = true
      // }
    },
    importphone: function importphone(val) {
      if (val === '5') {
        this.form.groupType = '5';
        this.dlogphone = true;
      }
    },
    getvipdata: function getvipdata() {
      var _this8 = this;
      var that = this;
      (0, _wxHy.getDataByMember)().then(function (res) {
        res = res.data;
        if (res.code === 200) {
          if (res.data.rule === '1') {
            that.vipdata = '1';
          } else {
            that.vipdata = '2';
          }
          (0, _wxyyKhlb.getmerberVip)().then(function (res) {
            res = res.data;
            console.log('vip---', res);
            if (res.code === 200) {
              if (res.data && res.data.length > 0) {
                that.hylist1 = res.data;
                that.hylist = res.data.slice(1);
              }
            } else {
              _this8.$message.warning(res.msg);
            }
          });
        }
      });
    },
    // 上传图片
    handleAvatarSuccess: function handleAvatarSuccess(response, file, fileList) {
      console.log(response);
      if (response.code === 200) {
        this.form.bizSerialNo = response.data.bizSerialNo;
        // this.dlogphone = false
        if (response.data.successCount) {
          this.outnum = response.data.successCount;
          this.innum = response.data.successCount;
          this.dlogcg = true;
        }
        if (response.data.errorMessageList.length > 0) {
          this.tserr = response.data.errorMessageList;
          this.isErr = true;
        }
        this.$message.success(response.msg);
        // this.getlistcreat()
      } else {
        this.$message.warning(response.msg);
      }
    },
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      var isLt2M = file.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.closeAll();
        this.$message.error('上传图片大小不能超过 1MB!');
      }
      return isLt2M;
    },
    imgUploaderr: function imgUploaderr(err, file, fileList) {
      // if(file.code)
      this.$message.error(err.msg);
    },
    // 下载标准文档
    downwd: function downwd() {
      var that = this;
      (0, _wxyyKhlb.downloadmsgmb)().then(function (res) {
        res = res.data;
        var blob = new Blob([res], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '标准文档.xlsx';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      }).catch(function (res) {
        that.$message.warning(res.msg);
      });
    },
    cellck: function cellck(row, column, cell, event) {
      if (column.label === '发送失败条数') {
        this.dlogmsginfo = true;
        this.getmsgfail(row.id);
      }
    },
    dxdeteil: function dxdeteil() {
      this.dlogmsginfo = true;
      this.getmsgfail();
    },
    // 短信详情
    getmsgfail: function getmsgfail(id) {
      var _this9 = this;
      this.dxfail.groupId = id;
      (0, _wxyyKhlb.queryFailForPage)(this.dxfail).then(function (res) {
        res = res.data;
        if (res.code === 200) {
          if (res.data) {
            _this9.tableData1 = res.data.records;
            _this9.total1 = res.data.total;
          } else {
            _this9.tableData1 = [];
          }
        }
      });
    },
    // 导出异常短信
    downmsgfail: function downmsgfail() {
      var that = this;
      (0, _wxyyKhlb.getExportFailExcel)({
        groupId: that.dxfail.groupId
      }).then(function (res) {
        res = res.data;
        console.log(res);
        var blob = new Blob([res], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '短信详情.xlsx';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      }).catch(function (res) {
        that.$message.warning(res.msg);
      });
    },
    // 查询发送状态
    getsendstatus: function getsendstatus() {
      var _this10 = this;
      (0, _wxyyKhlb.sendstatus)().then(function (res) {
        res = res.data;
        if (res.code === 200) {
          if (res.data && res.data.length > 0) {
            _this10.statuslist = res.data;
          } else {
            _this10.$message.info(res.msg);
          }
        }
      });
    },
    pagingFun: function pagingFun(val) {
      this.formInline.current = val;
      // console.log(this.formInline.current)
      this.getmsglists();
    },
    getmsglists: function getmsglists(val) {
      var _this11 = this;
      var obj = {
        startTime: this.value2.length > 1 ? this.value2[0] : '',
        endTime: this.value2.length > 1 ? this.value2[1] : ''
      };
      Object.assign(this.formInline, obj);
      (0, _wxyyKhlb.getmsgPage)(this.formInline).then(function (res) {
        res = res.data;
        if (res.code === 200) {
          _this11.total = res.data.total;
          _this11.tableData = res.data.records;
        } else {
          _this11.$message.info(res.msg);
        }
        _this11.listLoading = false;
      });
    },
    srchang: function srchang(val) {
      this.formInline.orderByZd = val.prop;
      if (val.order === 'descending') {
        this.formInline.orderByGz = 'desc';
      } else {
        this.formInline.orderByGz = 'asc';
      }
      this.gethylist(this.formInline);
    },
    numFun: function numFun(value) {
      value = value.replace(/[^0-9]/g, '');
      if (value > 0) {
        return value * 1;
      } else if (value <= 0 && value) {
        value = 0;
        return value;
      } else {
        value = '';
        return value;
      }
    },
    getdqlist: function getdqlist() {
      var _this12 = this;
      (0, _wXintegralMall.getDqlistsearch)().then(function (res) {
        // console.log(res)
        res = res.data;
        if (res.code === 200) {
          if (res.data) {
            res.data.forEach(function (item) {
              _this12.dqlist.push({
                name: item
              });
            });
          } else {
            _this12.dqlist = [];
          }
        }
      });
    },
    gethylist: function gethylist(val) {
      var that = this;
      var a = val || '';
      that.vipload = true;
      (0, _wXintegralMall.getJflistsearch)(a).then(function (res) {
        // console.log(res)
        res = res.data;
        if (res.code === 200) {
          // this.dqlist = res.data
          that.tableData = res.data.records;
          that.total = res.data.total;
          that.tableData.forEach(function (i) {
            i.flag = 1;
          });
          that.total = res.data.total;
          // console.log(this.tableData)
        } else {
          that.$message(res.msg);
        }
        that.vipload = false;
      });
    },
    handleClose: function handleClose(done) {
      done();
    },
    // 新建标签
    open: function open() {
      var _this13 = this;
      this.$prompt('标签名称', '新建标签', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function (_ref) {
        var value = _ref.value;
        if (_this13.setGround(value)) {
          _this13.$message({
            type: 'error',
            message: '重复的标签名'
          });
          return;
        }
        if (value.replace(/(^\s*)|(\s*$)/g, '') == '') {
          _this13.$message({
            type: 'error',
            message: '请输入有效的标签名'
          });
          return;
        }
        var n = {
          tagName: value,
          appId: sessionStorage.getItem('appId')
        };
        (0, _wxgzh.ccrrTag)(n).then(function (res) {
          res = res.data;
          // console.log(res)
          if (res.code * 1 === 200) {
            _this13.$message({
              type: 'success',
              message: '新建标签是: ' + value
            });
            _this13.getAlltags();
          } else {
            _this13.$message({
              type: 'error',
              message: res.msg
            });
          }
        });
      }).catch(function () {
        _this13.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    // 打标签
    opentagging: function opentagging() {
      if (this.multipleSelection.length == 0) {
        this.$message.warning('请先选择用户再进行操作');
        return;
      }
      this.dialogVisible = true;
      this.checkList = '';
      this.taggg = this.tagss.slice(2);
      // console.log(this.tagss)
    },
    // 提交打标签
    tagging: function tagging() {
      var _this14 = this;
      // console.log(this.checkList)
      if (this.checkList === '') {
        this.$message.warning('请选择一个标签');
      }
      this.tagsList.userTagQuery.appId = sessionStorage.getItem('appId');
      (0, _wxgzh.batchTagging)(this.tagsList.userTagQuery, this.checkList).then(function (res) {
        res = res.data;
        // console.log(res)
        if (res.code * 1 === 200) {
          _this14.$message.success('操作成功');
        } else {
          _this14.$message.warning('操作失败，请重试');
        }
        _this14.dialogVisible = false;
        _this14.userload = true;
        _this14.getserrch();
      });
    },
    // 删除标签
    handleClosetag: function handleClosetag(tag, list, row) {
      var _this15 = this;
      var a = {
        tagId: tag.tagId,
        userId: row.userId,
        appId: sessionStorage.getItem('appId')
      };

      // console.log(list)
      var i = list.indexOf(tag);
      list.splice(i, 1);
      (0, _wxgzh.batchUnTagging)(a).then(function (res) {
        res = res.data;
        if (res.code * 1 === 200) {
          _this15.$message.success('标签删除成功');
        } else {
          _this15.$message.warning(res.msg);
        }
      });
      // this.userload = true
      // this.getserrch()
    },
    // 获取用户列表
    getserrch: function getserrch() {
      // this.formInline.tagId = 0
      // this.formInline.endTime = ''
      // this.formInline.startTime = ''
      // this.formInline.nickname = ''
      // this.formInline.qrScene = ''
      // this.formInline.appId = sessionStorage.getItem('appId')
      // getSearchuser(this.formInline).then(res => {
      //    // console.log(res)

      //   if (res.code * 1 === 200) {
      //     this.total = res.data.total
      //     if (this.total * 1 === 0) {
      //       this.userload = false
      //       this.tableData = ''
      //     }
      //     this.tableData = res.data.records
      //   } else {
      //     this.$message.warning(res.msg)
      //   }
      //   this.userload = false
      // })
    },
    // 获取所有标签
    getAlltags: function getAlltags() {
      var _this16 = this;
      (0, _wxgzh.getAllTag)({
        appId: sessionStorage.getItem('appId')
      }).then(function (res) {
        // console.log(res)
        res = res.data;
        if (res.code * 1 === 200) {
          var _this16$tagss;
          _this16.tagss = [{
            tagName: '全部',
            tagId: 0
          }, {
            tagName: '无标签',
            tagId: 1
          }];
          (_this16$tagss = _this16.tagss).push.apply(_this16$tagss, (0, _toConsumableArray2.default)(res.data));
        } else {
          _this16.$message.warning(res.msg);
        }
      });
    },
    handleCurrentChange: function handleCurrentChange(val) {
      var _this17 = this;
      this.vipload = true;
      // console.log(`当前页: ${val}`)
      this.formInline.pageNo = val;
      // const a = {
      //   pageNo: this.formInline.pageNo,
      //   pageSize: this.formInline.pageSize
      // }
      this.gethylist(this.formInline);
      (0, _wxHy.getlistsearch)(this.formInline).then(function (res) {
        // console.log(res)
        res = res.data;
        if (res.code === 200) {
          _this17.tableData = res.data.records;
          _this17.total = res.data.total;
          _this17.tableData.forEach(function (i) {
            i.flag = 1;
          });
          _this17.total = res.data.total;
          // console.log(this.tableData)
        }

        _this17.vipload = false;
      });
    },
    // 冻结，解冻
    handleDelete: function handleDelete(id, sta) {
      var _this18 = this;
      var n = {
        id: id,
        status: sta
      };
      (0, _wxHy.freezeselectMemberById)(n).then(function (res) {
        // console.log(res)
        res = res.data;
        if (res.code === 200) {
          if (sta === '1') {
            _this18.$message.success('已冻结');
            _this18.getlistcreat();
          } else {
            _this18.$message.success('已解冻');
            _this18.getlistcreat();
          }
        }
      });
    },
    // 上传图片
    // handleAvatarSuccess(response, file, fileList) {
    //   let o = URL.createObjectURL(file);
    //   this.Lists.push(fileList)
    //   this.Lists.push({name:fileList.name,url:URL.createObjectURL(fileList.raw)})
    //   console.log(response)
    //   if (response.code === 200) {
    //     this.$message.info(response.data)
    //     this.getlistcreat()
    //   } else {
    //     this.$message.warning(response.msg)
    //   }
    // },
    // beforeAvatarUpload(file) {
    //   const isLt2M = file.size / 1024 / 1024 < 1
    //   if (!isLt2M) {
    //     this.$message.closeAll()
    //     this.$message.error('上传图片大小不能超过 1MB!')
    //   }
    //   return isLt2M
    // },
    // imgUploaderr(err, file, fileList) {
    //   // if(file.code)
    //   this.$message.error(err.msg)
    // },
    // 下载模板
    downmuban: function downmuban() {
      var that = this;
      (0, _wxHy.downtemplate)().then(function (res) {
        res = res.data;
        // console.log(res)
        var blob = new Blob([res], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '模板.xlsx';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
        that.downLoading = false;
      }).catch(function (res) {
        that.$message.warning(res.msg);
      });
    },
    // 列表详情
    // handleinfo(val, type) {
    //   if (type === 1) {
    //     this.$router.push({
    //       name: 'wxIntegralFlow',
    //       query: { 'id': val }
    //     })
    //   } else {
    //     this.$router.push({
    //       name: 'wxIntegralOrder',
    //       query: { 'id': val }
    //     })
    //   }
    // },
    // 导入会员
    imvip: function imvip() {
      this.imdiavip = true;
      // console.log('1')
    },
    // 下载明细
    download: function download() {
      var that = this;
      (0, _wxHy.downdetail)().then(function (res) {
        res = res.data;
        // console.log(res)
        var blob = new Blob([res], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '明细.xlsx';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
        that.downLoading = false;
      }).catch(function (res) {
        that.$message.warning(res.msg);
      });
    },
    // 修改任务值
    onSubmitt: function onSubmitt() {
      if (this.selectlist.length === 0) {
        this.$message.warning('请选择一行数据进行修改');
        return;
      }
      this.selectlist.forEach(function (i) {
        i.flag = 2;
        i.phone = i.phone + '.';
      });
      // console.log(this.selectlist)
      this.xgtask = true;
    },
    // 修改任务值提交
    xgbtn: function xgbtn() {
      var _this19 = this;
      var n = [];
      this.selectlist.forEach(function (el) {
        var a = {
          memberId: el.memberId,
          syjf: el.syjf
        };
        n.push(a);
      });
      // console.log(n)
      (0, _wXintegralMall.updateTaskByIds)(n).then(function (res) {
        res = res.data;
        if (res.code === 200) {
          _this19.$message.success('修改成功');
          _this19.$refs.multipleTable.clearSelection();
          _this19.xgtask = false;
          _this19.gethylist();
          _this19.$router.go(0);
        } else {
          _this19.$message.warning(res.msg);
          return false;
        }
      });
    },
    qxbtn: function qxbtn() {
      var that = this;
      that.selectlist.forEach(function (i) {
        i.flag = 1;
        i.phone = i.phone.substring(0, i.phone.length - 1);
      });
      that.$refs.multipleTable.clearSelection();
      that.xgtask = false;
      that.gethylist();
    },
    // 重置
    onReset: function onReset(refname) {
      this.$refs[refname].resetFields();
      this.formInline = {
        size: 5,
        startTime: '',
        endtime: '',
        companyId: '',
        current: 1,
        sendStatus: ''
      };
      this.value2 = '';
    },
    onsubmitf: function onsubmitf(val) {
      var that = this;
      // that.vipload = true
      if (!val.groupName) {
        that.$message.warning('请输入短信名称');
        return;
      }
      if (val.groupType === '1') {
        if (val.labelCodes.length === 0) {
          that.$message.warning('请选择标签');
          return;
        }
      }
      if (val.groupType === '2') {
        if (val.memberLevel.length === 0) {
          that.$message.warning('请选择会员');
          return;
        }
      }
      if (!val.templateCode) {
        that.$message.warning('请选择短信内容');
        return;
      }
      if (val.sendType === '1') {
        if (val.sendTime === '') {
          that.$message.warning('请选择定时发送时间');
          return;
        }
      }

      // if (val.selectName === '1' && val.memberNum.replace(/(^\s*)|(\s*$)/g, '') === '') {
      //   that.$message.warning('请填写会员卡号')
      //   return
      // }
      // if (val.selectName === '2' && val.phone.replace(/(^\s*)|(\s*$)/g, '') === '') {
      //   that.$message.warning('请填写手机号')
      //   return
      // }
      // if (val.selectName === '3' && val.memberName.replace(/(^\s*)|(\s*$)/g, '') === '') {
      //   that.$message.warning('请填写会员姓名')
      //   return
      // }
      // if (val.ly.replace(/(^\s*)|(\s*$)/g, '') === '') {
      //   that.$message.warning('请填写会员来源')
      //   return
      // }
      // if (val.memberType === '') {
      //   that.$message.warning('请选择会员类型')
      //   return
      // }
      // if (val.type === '') {
      //   that.$message.warning('请选择会员等级')
      //   return
      // }
      // if (val.value2 === '') {
      //   that.$message.warning('请选择时间')
      //   return
      // }
      if (that.value2 !== '') {
        val.startTime = that.value2[0];
        val.endTime = that.value2[1];
      }
      // that.getlistcreat(val)
      // that.gethylist(val)

      (0, _wxyyKhlb.sendSms)(val).then(function (res) {
        // console.log(res)
        res = res.data;
        if (res.code === 200) {
          that.$message.success(res.msg);
        } else {
          that.$message(res.msg);
          return false;
        }
        that.dlogset = false;
        that.getmsglists();
      });
    },
    getlistcreat: function getlistcreat(val) {
      var _this20 = this;
      var a = val || '';
      this.vipload = true;
      (0, _wxHy.getlistsearch)(a).then(function (res) {
        res = res.data;
        if (res.code === 200) {
          _this20.tableData = res.data.records;
          _this20.tablehead = [{
            label: '会员卡号',
            prop: 'memberCradNumber'
          }, {
            label: '会员卡号',
            prop: 'memberCradNumber'
          }, {
            label: '会员卡号',
            prop: 'memberCradNumber'
          }];
          _this20.total = res.data.total;
          _this20.tableData.forEach(function (i) {
            i.flag = 1;
          });
          _this20.total = res.data.total;
          // console.log(this.tableData)
        }

        _this20.vipload = false;
      });
    },
    // 表格选中操作
    // handleSelection(selection, row) {
    //    // console.log(selection)
    // },
    // 表格变化操作
    handleSelectionChange: function handleSelectionChange(val) {
      var _this21 = this;
      // console.log(val)
      this.selectlist = val;
      this.multipleSelection = val;
      this.tagsList.userTagQuery = [];
      this.multipleSelection.forEach(function (val) {
        var n = {
          openId: '',
          userId: ''
        };
        n.openId = val.openId;
        n.userId = val.userId;
        _this21.tagsList.userTagQuery.push(n);
      });
    }
  }
};
exports.default = _default;