var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar-main" },
    [
      _c(
        "div",
        { staticClass: "navbar" },
        [
          _vm.showLogo
            ? _c("logo", { attrs: { collapse: _vm.isCollapse } })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "right-menu" },
            [
              _c(
                "el-dropdown",
                { attrs: { trigger: "click" } },
                [
                  _c(
                    "span",
                    { staticClass: "el-dropdown-link sys dropErmlist" },
                    [
                      _c("img", {
                        attrs: { src: require("../../assets/images/mml.png") },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-menu",
                    {
                      staticStyle: { overflow: "hidden" },
                      attrs: { slot: "dropdown" },
                      slot: "dropdown",
                    },
                    [
                      _c("el-dropdown-item", [
                        _c(
                          "div",
                          { staticClass: "ermc" },
                          [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/images/xcxjxs.jpg"),
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-tag", { attrs: { type: "info" } }, [
                              _vm._v("经销商小程序"),
                            ]),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-dropdown-item", { attrs: { divided: "" } }, [
                        _c(
                          "div",
                          { staticClass: "ermc" },
                          [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/images/xcxzd.jpg"),
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-tag", { attrs: { type: "info" } }, [
                              _vm._v("终端小程序"),
                            ]),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-dropdown-item", { attrs: { divided: "" } }, [
                        _c(
                          "div",
                          { staticClass: "ermc" },
                          [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/images/xcxywy.jpg"),
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-tag", { attrs: { type: "info" } }, [
                              _vm._v("业务员小程序"),
                            ]),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-dropdown-item", { attrs: { divided: "" } }, [
                        _c(
                          "div",
                          { staticClass: "ermc" },
                          [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/images/xcxxfz.jpg"),
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-tag", { attrs: { type: "info" } }, [
                              _vm._v("消费者小程序"),
                            ]),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-dropdown",
                { attrs: { trigger: "click" } },
                [
                  _c(
                    "span",
                    {
                      staticClass: "el-dropdown-link sys",
                      staticStyle: { cursor: "pointer" },
                    },
                    [
                      _c(
                        "i",
                        { staticClass: "config" },
                        [_c("svg-icon", { attrs: { "icon-class": "config" } })],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c("el-dropdown-item", { on: { click: _vm.editPWD } }, [
                        _c(
                          "div",
                          {
                            staticClass: "log-out",
                            staticStyle: { padding: "0 20px" },
                            on: { click: _vm.editPWD },
                          },
                          [_vm._v("修改密码")]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.device !== "mobile"
                ? _c("screenfull", {
                    staticClass: "right-menu-item hover-effect",
                    attrs: { id: "screenfull" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("span", { staticClass: "unlogin" }, [
                _c(
                  "i",
                  { staticClass: "userConfig" },
                  [_c("svg-icon", { attrs: { "icon-class": "user" } })],
                  1
                ),
                _vm._v(" "),
                _c("span", { staticClass: "user-name" }, [
                  _vm._v("欢迎您, " + _vm._s(_vm.userName)),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "unlogin", on: { click: _vm.logout } },
                [
                  _c(
                    "i",
                    [_c("svg-icon", { attrs: { "icon-class": "logout" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-size": "15px",
                        "margin-left": "6px",
                      },
                    },
                    [_vm._v("退出登录")]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("pdfScan", {
        attrs: {
          "file-path": _vm.filePath,
          page: _vm.page,
          "pdf-visible": _vm.pdfVisible,
        },
        on: { close: _vm.hidePDF },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: "修改密码",
            visible: _vm.dialogVisiblex,
            modal: _vm.mask,
            "show-close": _vm.showClose,
            "before-close": _vm.handleClose,
            width: "50% !important",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisiblex = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: { width: "100%", margin: "0 auto", height: "auto" },
            },
            [
              _c(
                "el-form",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "ruleForm1",
                  attrs: {
                    model: _vm.ruleForm1,
                    rules: _vm.rules,
                    "label-position": "right",
                    "label-width": "90px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-bottom": "20px" },
                      attrs: { prop: "oldPwd", label: "原密码:" },
                    },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: { type: _vm.passwordType1 },
                          model: {
                            value: _vm.ruleForm1.oldPwd,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm1, "oldPwd", $$v)
                            },
                            expression: "ruleForm1.oldPwd",
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "show-pwd",
                              attrs: { slot: "append" },
                              on: {
                                click: function ($event) {
                                  return _vm.showPwd("1")
                                },
                              },
                              slot: "append",
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  "icon-class":
                                    _vm.passwordType1 === "password"
                                      ? "eye"
                                      : "eye-open",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-bottom": "20px" },
                      attrs: { prop: "newPwd", label: "新密码:" },
                    },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: { type: _vm.passwordType2, maxlength: "20" },
                          model: {
                            value: _vm.ruleForm1.newPwd,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm1, "newPwd", $$v)
                            },
                            expression: "ruleForm1.newPwd",
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "show-pwd",
                              attrs: { slot: "append" },
                              on: {
                                click: function ($event) {
                                  return _vm.showPwd("2")
                                },
                              },
                              slot: "append",
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  "icon-class":
                                    _vm.passwordType2 === "password"
                                      ? "eye"
                                      : "eye-open",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-bottom": "20px" },
                      attrs: { prop: "rePwd", label: "确认密码:" },
                    },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: { type: _vm.passwordType3, maxlength: "20" },
                          model: {
                            value: _vm.ruleForm1.rePwd,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm1, "rePwd", $$v)
                            },
                            expression: "ruleForm1.rePwd",
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "show-pwd",
                              attrs: { slot: "append" },
                              on: {
                                click: function ($event) {
                                  return _vm.showPwd("3")
                                },
                              },
                              slot: "append",
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  "icon-class":
                                    _vm.passwordType3 === "password"
                                      ? "eye"
                                      : "eye-open",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { "label-width": "0" } }, [
                    !_vm.showClose
                      ? _c("div", { staticClass: "wxtips" }, [
                          _vm._v(_vm._s(_vm.tipStr)),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.showClose
                ? _c("el-button", { on: { click: _vm.handleClose } }, [
                    _vm._v("取 消"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.disable },
                  on: {
                    click: function ($event) {
                      return _vm.savePwd("ruleForm1")
                    },
                  },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.detailVisible
        ? _c("noticeDetail", {
            attrs: {
              id: _vm.id,
              visible: _vm.detailVisible,
              "org-name-show": true,
            },
            on: { close: _vm.ggClose },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.fwVisible
        ? _c("fwDetail", {
            attrs: {
              id: _vm.id,
              visible: _vm.fwVisible,
              "org-name-show": true,
            },
            on: { close: _vm.fwClose },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }