"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Scan',
  props: {
    scanData: Object,
    scan: Boolean
  },
  data: function data() {
    return {
      dialogVisible: false,
      loading: false,
      value1: '',
      value2: true
    };
  },
  watch: {
    scan: function scan(val) {
      var that = this;
      if (val == true) {
        that.dialogVisible = true;
      } else {
        that.dialogVisible = false;
      }
    }
  },
  methods: {
    handleClose: function handleClose() {
      var that = this;
      that.$parent.scanShow = false;
      that.dialogVisible = false;
    }
  }
};
exports.default = _default;