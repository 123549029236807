"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _dictionary = require("@/api/systems/dictionary");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'TablePackset',
  components: {
    Pagination: _Pagination.default
  },
  props: {
    dirVisible: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      title: '',
      listLoading: false,
      searchForm: {
        current: 1,
        dataDictionaryCode: '',
        dataDictionaryName: '',
        isDelete: 0,
        size: 20
      },
      mloading: false,
      btnLoading: false,
      tableKey: '111',
      tabHeight: '100%',
      tableData: [],
      total: 0
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var that = this;
      that.listLoading = true;
      (0, _dictionary.getDataDictionaryList)(that.searchForm).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.total = parseInt(response.data.data.total);
            that.tableData = response.data.data.records;
          } else {
            that.total = 0;
            that.tableData = [];
          }
          that.listLoading = false;
        }
      }).catch(function (res) {
        that.listLoading = false;
      });
    },
    selCancel: function selCancel() {
      this.$emit('close');
    },
    reset: function reset(row) {
      var _this = this;
      this.$confirm('确认取回?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _dictionary.regainDataDictionary)({
          pid: row.pid
        }).then(function (res) {
          if (res.data.code != 200) {
            _this.$message.error(res.data.msg);
          } else {
            _this.$message({
              message: '操作成功',
              type: 'success'
            });
            _this.getList();
          }
        });
      }).catch(function () {});
    },
    del: function del(row) {
      var _this2 = this;
      this.$confirm('删除后无法还原，确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _dictionary.thoroughDelDataDictionary)({
          pid: row.pid
        }).then(function (res) {
          if (res.data.code != 200) {
            _this2.$message.error(res.data.msg);
          } else {
            _this2.$message({
              message: '操作成功',
              type: 'success'
            });
            _this2.getList();
          }
        });
      }).catch(function () {});
    }
  }
};
exports.default = _default;