"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _TipDialog = _interopRequireDefault(require("@/components/TipDialog"));
var _activityManage = require("@/api/market/activityManage");
var _config = _interopRequireDefault(require("@/views//marketingManage/marketingActivities/activityManage/config"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Tipdialog: _TipDialog.default,
    Content: _config.default
  },
  data: function data() {
    return {
      dialogVisible: false,
      type: '',
      list: [{
        img: require('@/assets/images/hongbao.png'),
        title: '扫码抽奖',
        type: '2'
      }, {
        img: require('@/assets/images/zhuanpan.png'),
        title: '大转盘',
        type: '3'
      }, {
        img: require('@/assets/images/jizi.png'),
        title: '集字',
        type: '1'
      }
      // {
      //   img: require('@/assets/images/jiugongge.png'),
      //   title: '九宫格抽奖',
      //   type: '4'
      // }
      ],

      hasConsumer: false
    };
  },
  mounted: function mounted() {},
  methods: {
    creatAct: function creatAct(type) {
      var _this = this;
      (0, _activityManage.isExistConsumeWechat)().then(function (res) {
        res = res.data;
        if (res.status * 1 === 200) {
          if (res.data) {
            _this.hasConsumer = true;
          } else {
            _this.hasConsumer = false;
          }
        } else {
          _this.$message({
            message: res.msg + '请刷新页面或重新登录',
            type: 'error',
            duration: 3 * 1000
          });
        }
      }).then(function () {
        if (_this.hasConsumer) {
          _this.type = type;
          _this.dialogVisible = true;
        } else {
          _this.$refs.hasConsumerTipVisible.tipAlert();
        }
      });
    },
    close: function close() {
      this.dialogVisible = false;
      this.type = '';
    }
  }
};
exports.default = _default;