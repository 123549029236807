"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _order = require("@/api/order");
var _detailsMzfn = _interopRequireDefault(require("@/components/detailsMzfn"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'Tips',
  components: {
    detailsMzfn: _detailsMzfn.default,
    Pagination: _Pagination.default
  },
  props: {
    visible: {
      type: Boolean,
      defult: false
    },
    data: {
      type: [Object, String],
      default: function _default() {
        return {};
      }
    },
    buyerId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      mzPid: '',
      mzFShow: false,
      radio: '',
      listLoading: true,
      selLoading: false,
      total: 0,
      buyer: '',
      seller: '',
      dateVal: [],
      listQuery: {
        buyNum: '',
        buyerId: '',
        current: 1,
        productId: '',
        schemeId: '',
        size: 10
      },
      list: [],
      listCopy: [],
      list1: [],
      list1Copy: this.oldList,
      upList: [],
      downList: [],
      multipleSelection: []
    };
  },
  mounted: function mounted() {
    this.listQuery = {
      buyNum: this.data.totalBottleNum,
      buyerId: this.buyerId,
      current: 1,
      productId: this.data.pId,
      schemeId: this.data.activityId,
      size: 10
    };
    this.fetchData();
  },
  methods: {
    showMzF: function showMzF(row) {
      this.mzPid = row.rebateId;
      this.mzFShow = true;
    },
    mzFClose: function mzFClose() {
      this.mzPid = '';
      this.mzFShow = false;
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.radio = this.list.indexOf(val);
      this.multipleSelection = val;
    },
    fetchData: function fetchData() {
      var _this = this;
      this.listLoading = true;
      (0, _order.matchChannelBuyGift)((0, _objectSpread2.default)({}, this.listQuery)).then(function (res) {
        res = res.data;
        if (res.code * 1 === 200) {
          _this.total = Number(res.data.total);
          _this.list = res.data.records;
        } else {
          _this.$message.closeAll();
          _this.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    handleClose: function handleClose() {
      this.$emit('close');
    },
    update: function update() {
      this.$emit('change', this.multipleSelection);
      // this.visible = false
    }
  }
};
exports.default = _default2;