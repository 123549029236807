var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c("h3", { staticClass: "h-title" }, [_vm._v("盘点")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page-section" },
        [
          _c(
            "el-form",
            {
              ref: "searchForm",
              staticClass: "search-condition",
              attrs: {
                model: _vm.searchForm,
                "status-icon": "",
                "label-suffix": ":",
                "label-width": "100px",
              },
            },
            [
              _c(
                "div",
                { staticClass: "cols" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "inventoryOrderNo",
                                label: "盘点单号",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  clearable: "",
                                  placeholder: "请输入",
                                  maxlength: "20",
                                },
                                model: {
                                  value: _vm.searchForm.inventoryOrderNo,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.searchForm,
                                      "inventoryOrderNo",
                                      $$v
                                    )
                                  },
                                  expression: "searchForm.inventoryOrderNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "storeId", label: "盘点库房" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择",
                                  },
                                  model: {
                                    value: _vm.searchForm.storeId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "storeId", $$v)
                                    },
                                    expression: "searchForm.storeId",
                                  },
                                },
                                _vm._l(_vm.storeList, function (item) {
                                  return _c("el-option", {
                                    key: item.pId,
                                    attrs: {
                                      label: item.storeHouseName,
                                      value: item.pId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { prop: "productName", label: "产品名称" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  "suffix-icon": "el-icon-search",
                                  clearable: "",
                                  readonly: true,
                                },
                                on: { clear: _vm.clearPro, focus: _vm.openSel },
                                model: {
                                  value: _vm.productName,
                                  callback: function ($$v) {
                                    _vm.productName = $$v
                                  },
                                  expression: "productName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.handleShow,
                              expression: "handleShow",
                            },
                          ],
                          attrs: { span: 6 },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "beginDate",
                                label: "盘点起始时间",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "date",
                                  placeholder: "选择日期",
                                  "value-format": "yyyy-MM-dd",
                                },
                                model: {
                                  value: _vm.searchForm.beginDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "beginDate", $$v)
                                  },
                                  expression: "searchForm.beginDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.handleShow,
                              expression: "handleShow",
                            },
                          ],
                          attrs: { span: 6, prop: "endTime" },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { prop: "endDate", label: "盘点结束时间" },
                            },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "date",
                                  placeholder: "选择日期",
                                  "value-format": "yyyy-MM-dd",
                                },
                                model: {
                                  value: _vm.searchForm.endDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "endDate", $$v)
                                  },
                                  expression: "searchForm.endDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          staticClass: "head-btn-group",
                          staticStyle: { width: "260px" },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { "label-width": "0" } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "mini" },
                                  on: { click: _vm.DoSearch },
                                },
                                [
                                  _c("i", { staticClass: "el-icon-search" }),
                                  _vm._v(" 查询\n              "),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.DoReset()
                                    },
                                  },
                                },
                                [
                                  _c("i", { staticClass: "el-icon-refresh" }),
                                  _vm._v(" 重置\n              "),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      _vm.handleShow = !_vm.handleShow
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.handleShow ? "收起" : "展开") +
                                      "\n                "
                                  ),
                                  _c("i", {
                                    class: _vm.handleShow
                                      ? "el-icon-arrow-up"
                                      : "el-icon-arrow-down",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              key: _vm.tableKey,
              staticStyle: { width: "100%" },
              attrs: {
                height: _vm.tabHeight,
                data: _vm.tableData,
                border: "",
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  align: _vm.tableConfig.align,
                  width: "120",
                  label: "序号",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "盘点单号",
                  prop: "inventoryOrderNo",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "盘点库房",
                  prop: "storeName",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "盘点日期",
                  prop: "inventoryDate",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          _vm._s(_vm._f("textDateOnlyDate")(row.inventoryDate))
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "盘点人",
                  prop: "inventoryUser",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "单据状态", align: _vm.tableConfig.align },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(_vm._s(_vm._f("djztText")(row.orderStatus))),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "制单人",
                  prop: "creator",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "创建时间",
                  prop: "createTime",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                  width: "170",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(_vm._s(_vm._f("textDate")(row.createTime))),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  fixed: "right",
                  align: _vm.tableConfig.align,
                  width: "65",
                  "class-name": "small-padding fixed-width",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              disabled: row.orderStatus != 3,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.audit(row)
                              },
                            },
                          },
                          [_vm._v("审核")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              layout: "total,sizes,prev, pager, next, jumper",
              total: _vm.total,
              page: _vm.searchForm.current,
              limit: _vm.searchForm.size,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.searchForm, "current", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.searchForm, "size", $event)
              },
              pagination: _vm.getList,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.detailShow
        ? _c("audit", {
            ref: "detailShow",
            attrs: { id: _vm.orderId, show: _vm.detailShow },
            on: { close: _vm.close, change: _vm.inSertSub },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("product-dialog", { ref: "selProduct", on: { change: _vm.setSel } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }