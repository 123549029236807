var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "系统用户-添加",
        model: _vm.add,
        visible: _vm.dialogVisible,
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "addForm",
          attrs: {
            rules: _vm.rules,
            model: _vm.addData,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "用户名", prop: "loginAccount" } },
            [
              _c("el-input", {
                attrs: { maxlength: "20" },
                model: {
                  value: _vm.addData.loginAccount,
                  callback: function ($$v) {
                    _vm.$set(_vm.addData, "loginAccount", $$v)
                  },
                  expression: "addData.loginAccount",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "真实姓名", prop: "userName" } },
            [
              _c("el-input", {
                attrs: { maxlength: "20" },
                model: {
                  value: _vm.addData.userName,
                  callback: function ($$v) {
                    _vm.$set(_vm.addData, "userName", $$v)
                  },
                  expression: "addData.userName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "密码", prop: "loginPwd" } },
            [
              _c("el-input", {
                attrs: {
                  "show-password": "",
                  placeholder: "请输入内容",
                  maxlength: "16",
                },
                model: {
                  value: _vm.addData.loginPwd,
                  callback: function ($$v) {
                    _vm.$set(_vm.addData, "loginPwd", $$v)
                  },
                  expression: "addData.loginPwd",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "确认密码", prop: "reloginPwd" } },
            [
              _c("el-input", {
                attrs: {
                  "show-password": "",
                  placeholder: "请输入内容",
                  maxlength: "16",
                },
                model: {
                  value: _vm.addData.reloginPwd,
                  callback: function ($$v) {
                    _vm.$set(_vm.addData, "reloginPwd", $$v)
                  },
                  expression: "addData.reloginPwd",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "is-required",
              attrs: { label: "状态", prop: "isEnable" },
            },
            [
              _c("el-switch", {
                attrs: { "active-text": "启用", "inactive-text": "禁用" },
                model: {
                  value: _vm.addData.isEnable,
                  callback: function ($$v) {
                    _vm.$set(_vm.addData, "isEnable", $$v)
                  },
                  expression: "addData.isEnable",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "备注", prop: "remark" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  placeholder: "请输入内容",
                  maxlength: "100",
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.addData.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.addData, "remark", $$v)
                  },
                  expression: "addData.remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "align-right",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", disabled: _vm.dstate },
              on: {
                click: function ($event) {
                  return _vm.submitForm("addForm")
                },
              },
            },
            [_vm._v("确定")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.resetForm("addForm")
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }