"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCollectionInfo = getCollectionInfo;
exports.getCountTemplate = getCountTemplate;
exports.getInfoList = getInfoList;
exports.getList = getList;
exports.selSysBuildTemplate = selSysBuildTemplate;
exports.templateAdd = templateAdd;
exports.templateEdit = templateEdit;
exports.templateEnable = templateEnable;
exports.templateGetById = templateGetById;
exports.templateRepositoryList = templateRepositoryList;
var _request = _interopRequireDefault(require("@/utils/request"));
var baseUrl = '';
// 获取模板列表
function getList(param) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/activityTemplateInfo/templateInfoVoList',
    method: 'get',
    params: param
  });
}

// 添加模板
function templateAdd(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/activityTemplateInfo/templateInfoSave',
    method: 'POST',
    data: data
  });
}

//  启用禁用模板
function templateEnable(param) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/activityTemplateInfo/templateInfoSwitch',
    method: 'get',
    params: param
  });
}

// 获取收集信息字段
function getCollectionInfo() {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/activityDateCollection/dateCollectionAll',
    method: 'get'
  });
}

// 编辑模板
function templateEdit(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/activityTemplateInfo/templateInfoUpdate',
    method: 'POST',
    data: data
  });
}

// 查模板库
function templateRepositoryList(param) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/activityTemplateInfo/templateStoreroom',
    method: 'get',
    params: param
  });
}

// 查询信息收集列表
function getInfoList(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/pc/template/getAllColletionInfo',
    method: 'get',
    params: params
  });
}

// 模板详情
function templateGetById(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/pc/template/getById',
    method: 'GET',
    params: {
      id: params
    }
  });
}

// 选中系统内置模板
function selSysBuildTemplate(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/pc/template/selSysBuildTemplate',
    method: 'PUT',
    params: params
  });
}
function getCountTemplate(param) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/activityConfig/countTemplate',
    method: 'get',
    params: param
  });
}