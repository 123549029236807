"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
var _checkout = require("@/api/warehouse/checkout");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// ？？？？
var _default = {
  name: 'TableSuply',
  components: {},
  filters: {
    // // 操作类型 1:创建单据    2:修改单据  3:删除单据  4:拆单  5:审核不通过  6:审核通过  7:单据下载  8:单据扫描 9:正常结单 10:强制结单 11:撤销下载  12：撤销结单
    // operateType(value) {
    //   if (value == '' || value == null) {
    //     return ''
    //   } else {
    //     const statusMap = {
    //       '1': '创建单据',
    //       '2': '修改单据',
    //       '3': '删除单据',
    //       '4': '拆单',
    //       '5': '审核不通过',
    //       '6': '审核通过',
    //       '7': '单据下载',
    //       '8': '单据扫描',
    //       '9': '正常结单',
    //       '10': '强制结单',
    //       '11': '撤销下载',
    //       '12': '撤销结单'
    //     }
    //     return statusMap[value]
    //   }
    // },
    // scanStatus(value) {
    //   if (value == '' || value == null) {
    //     return ''
    //   } else {
    //     const statusMap = {
    //       '1': '待扫描',
    //       '2': '部分扫描',
    //       '3': '扫描完成'
    //     }
    //     return statusMap[value]
    //   }
    // },
    // TypeDataCom(code, keyName) {
    //   var vars = TypeDataComs[keyName]
    //   var name = ''
    //   vars.forEach(function(c) {
    //     if (c.figure == code) {
    //       name = c.name
    //       return
    //     }
    //   })
    //   return name
    // }
  },
  props: {
    billNo: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  // watch: {
  //   max: {
  //     handler(newVal) {
  //       this.change_number++
  //     },
  //     deep: true
  //   }
  // },
  data: function data() {
    return {
      // active: 0,
      // detailVisible: false,
      mloading: false,
      listLoading: false,
      // scanData: {},
      // temp: {
      //   tradeBillAllVO: {},
      //   tradeOperateLogDTOS: [],
      //   tradeProductVOS: []
      // },
      max: {
        // maxNum0: 0,
        // maxNum1: 0,
        // maxNum2: 0
      },
      maxCheck: {},
      proName: {},
      formData: [{
        billNo: this.billNo,
        splitProdDetails: [
          // {
          //   planScanAtoNum: 0,
          //   planScanNum: 0,
          //   productId: ''
          // }
        ]
      }, {
        billNo: this.billNo,
        splitProdDetails: [
          // {
          //   planScanAtoNum: 0,
          //   planScanNum: 0,
          //   productId: ''
          // }
        ]
      }],
      tableData: [],
      temp: {
        tableData0: [],
        tableData1: []
      }

      // settype: 2,
      // showflow: false,
      // setinfo: false //查看详情显示字段
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.getDetail();
  },
  methods: {
    maxNum: function maxNum(productId) {
      var num = this.max[productId];
      this.formData.forEach(function (item) {
        item.splitProdDetails.forEach(function (val) {
          if (val.productId == productId) {
            num = num - val.planScanNum;
          }
        });
      });
      this.maxCheck[productId] = num;
      return num;
    },
    boxChange: function boxChange(value, row, idx, proIdx) {
      var arr = row.packRadioDesc.split('*');
      var le = arr.length - 1;
      this.formData[idx].splitProdDetails[proIdx].planScanAtoNum = value * arr[le];
      this.tableReflash();
    },
    tableReflash: function tableReflash() {
      for (var i = 0; i < this.formData.length; i++) {
        this.temp['tableData' + i] = JSON.parse(JSON.stringify(this.temp['tableData' + i]));
      }
    },
    getDetail: function getDetail() {
      var _this = this;
      (0, _checkout.getPeoductionDetail)(this.billNo).then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
        } else {
          _this.tableData = res.data.data.tradeProductVOS;
          _this.temp.tableData0 = JSON.parse(JSON.stringify(_this.tableData));
          _this.temp.tableData1 = JSON.parse(JSON.stringify(_this.tableData));
          for (var i = 0; i < _this.tableData.length; i++) {
            _this.max[_this.tableData[i].productId] = _this.tableData[i].planScanNum;
            _this.proName[_this.tableData[i].productId] = _this.tableData[i].productName;
            _this.formData[0].splitProdDetails[i] = {
              planScanAtoNum: 0,
              planScanNum: 0,
              productId: _this.tableData[i].productId
            };
            _this.formData[1].splitProdDetails[i] = {
              planScanAtoNum: 0,
              planScanNum: 0,
              productId: _this.tableData[i].productId
            };
          }
        }
        console.log(_this.formData, 'this.formData');
      });
    },
    add: function add() {
      var arr = [];
      for (var i = 0; i < this.tableData.length; i++) {
        arr.push({
          planScanAtoNum: 0,
          planScanNum: 0,
          productId: this.tableData[i].productId
        });
      }
      this.formData.push({
        billNo: this.billNo,
        splitProdDetails: arr
      });
      var le = this.formData.length - 1;
      this.temp['tableData' + le] = JSON.parse(JSON.stringify(this.tableData));
    },
    delBill: function delBill(index) {
      this.formData.splice(index, 1);
    },
    delPro: function delPro(tabIdx, idx) {
      this.temp['tableData' + idx].splice(tabIdx, 1);
      this.formData[idx].splitProdDetails.splice(tabIdx, 1);
    },
    handleClose: function handleClose() {
      this.$emit('close');
    },
    submit: function submit() {
      var _this2 = this;
      console.log(this.formData, 'submit');
      console.log(this.maxCheck, 'max');
      for (var key in this.maxCheck) {
        console.log(key + '---' + this.maxCheck[key]);
        if (this.maxCheck[key] != 0) {
          var text = this.maxCheck[key] > 0 ? '少' : '多';
          this.$message.error('产品:' + this.proName[key] + '的计划数量(箱)比订单数量(箱)' + text + Math.abs(this.maxCheck[key]) + '箱，请重新分配计划数量(箱)');
          return;
        }
      }
      for (var i = 0; i < this.formData.length; i++) {
        var num = 0;
        for (var j = 0; j < this.formData[i].splitProdDetails.length; j++) {
          num += this.formData[i].splitProdDetails[j].planScanNum;
        }
        console.log(num, 'num' + i);
        if (num == 0) {
          this.$message.error('单据' + (i + 1) + '的计划数量(箱)全部为0，请重新分配');
          return;
        }
      }
      (0, _checkout.splitTradeBill)(this.formData).then(function (res) {
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
        } else {
          _this2.$emit('change');
        }
      });
    }
  }
};
exports.default = _default;