"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
require("core-js/modules/es6.number.constructor");
var _channel = require("@/api/basic/channel");
var _channelDialog = _interopRequireDefault(require("./channelDialog"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: "Insert",
  components: {
    channelDialog: _channelDialog.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "客户组新增"
    },
    pid: {
      type: [String, Number],
      default: ""
    },
    classType: {
      type: Array,
      required: true
    },
    type: {
      type: [String, Number],
      default: "1"
    },
    rowData: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      channelDialogVisible: false,
      btnLoading: false,
      rules: {
        groupCode: [{
          required: true,
          message: "客户组编码不能为空，请维护",
          trigger: ["change", "submit"]
        }],
        groupName: [{
          required: true,
          message: "客户组名称不能为空，请维护",
          trigger: "change"
        }]
        /* classType: [
          { required: true, message: '客户组分类不能为空，请维护', trigger: 'change' }
        ],*/
        // channelIds: [
        //   { type: 'array', required: false, message: '客户组经销商不能为空，请维护', trigger: 'change' }
        // ]
      },

      formData: {
        groupCode: "",
        groupName: "",
        // classType: 1,
        channelIds: [],
        channelClassId: "",
        channelGradeId: ""
      },
      channels: [],
      channelIds: [],
      classList: [],
      gradeList: [],
      tableData: [],
      loading: false
    };
  },
  mounted: function mounted() {
    var _this = this;
    if (this.pid) {
      Object.keys(this.formData).map(function (key) {
        _this.formData[key] = _this.rowData[key];
      });
      this.formData.channelIds = [];
      this.getList();
    }
  },
  methods: {
    // getList() {
    //   listFromCustGroup({
    //     pId: this.pid
    //   }).then(response => {
    //     if (response.data.code != 200) {
    //       this.$message.error(response.data.msg);
    //     } else {
    //       if (response.data.data != null) {
    //         this.channels = response.data.data;
    //       } else {
    //         this.channels = [];
    //       }
    //     }
    //   });
    // },
    getList: function getList() {
      var that = this;
      that.listLoading = true;
      var submitData = (0, _utils.filterKeyNull)(that.searchForm);
      (0, _channel.channelArchivesList)(submitData).then(function (response) {
        that.listLoading = false;
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.total = parseInt(response.data.data.total);
            that.tableData = response.data.data.records;
          } else {
            that.total = 0;
            that.tableData = [];
          }
        }
      }).catch(function (res) {
        that.listLoading = false;
      });
    },
    // 打开经销商弹窗
    openChannel: function openChannel() {
      this.channelDialogVisible = true;
    },
    // 设置经销商
    setChannel: function setChannel(val) {
      // var index = this.supplierIndex
      this.channels = val;
    },
    // 删除经销商
    deleteChanneleItem: function deleteChanneleItem(row) {
      this.channels = this.channels.filter(function (item) {
        return item.pId !== row.pId;
      });
    },
    // 关闭经销商弹窗
    closeChannel: function closeChannel() {
      this.channelDialogVisible = false;
    },
    // 关闭
    handleClose: function handleClose(type) {
      this.$emit("close", type);
    },
    // 客户组提交
    submitForm: function submitForm() {
      var _this2 = this;
      if (this.type === 2) {
        this.$emit("close", false);
        return;
      }
      // if (!this.channels.length) {
      //   this.$message.error('客户组经销商不能为空，请维护')
      //   return
      // }
      this.$refs["formData"].validate(function (valid) {
        if (valid) {
          _this2.btnLoading = true;
          _this2.formData.channelIds = [];
          _this2.channels.map(function (item) {
            _this2.formData.channelIds.push(item.pId);
          });
          if (_this2.pid) {
            (0, _channel.updateGroup)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, _this2.formData), {}, {
              pId: _this2.pid
            })).then(function (res) {
              _this2.btnLoading = false;
              if (res.data.code != 200 || res.data.msg == "客户组编码已存在,请重新输入" || res.data.msg == "客户组名称已存在,请重新输入") {
                _this2.$message.error(res.data.msg);
              } else {
                _this2.$message({
                  message: "保存成功",
                  type: "success"
                });
                _this2.handleClose(1);
              }
            });
          } else {
            (0, _channel.insertGroup)(_this2.formData).then(function (res) {
              _this2.btnLoading = false;
              if (res.data.code != 200 || res.data.msg == "客户组编码已存在,请重新输入" || res.data.msg == "客户组名称已存在,请重新输入") {
                _this2.$message.error(res.data.msg);
              } else {
                _this2.$message({
                  message: "保存成功",
                  type: "success"
                });
                _this2.handleClose(1);
              }
            });
          }
        }
      });
    }
  }
};
exports.default = _default2;