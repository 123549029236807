var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
      ? _c(
          "div",
          { staticClass: "page-section" },
          [
            _c(
              "el-row",
              [
                _c(
                  "el-form",
                  {
                    ref: "formInline",
                    staticClass: "search-condition",
                    attrs: {
                      model: _vm.formInline,
                      "status-icon": "",
                      "label-position": _vm.formConfig.labelPosition,
                      "label-width": "100px",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "cols" },
                      [
                        _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 5 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "活动方案:",
                                      prop: "schemeCode",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          filterable: "",
                                          clearable: "",
                                          placeholder: "请选择",
                                        },
                                        model: {
                                          value: _vm.formInline.schemeCode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formInline,
                                              "schemeCode",
                                              $$v
                                            )
                                          },
                                          expression: "formInline.schemeCode",
                                        },
                                      },
                                      _vm._l(_vm.activeList, function (item) {
                                        return _c("el-option", {
                                          key: item.schemeCode,
                                          attrs: {
                                            label: item.schemeName,
                                            value: item.schemeCode,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 5 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "奖品类型:",
                                      prop: "prizeType",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: "请选择",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.formInline.prizeType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formInline,
                                              "prizeType",
                                              $$v
                                            )
                                          },
                                          expression: "formInline.prizeType",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: {
                                            label: "红包",
                                            value: "100",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: {
                                            label: "实物",
                                            value: "200",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 5 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "奖品名称:",
                                      prop: "prizeName",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "请输入",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.formInline.prizeName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formInline,
                                            "prizeName",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "formInline.prizeName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 5 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "账期时间:" } },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        type: "daterange",
                                        format: "yyyy-MM-dd",
                                        "value-format": "yyyy-MM-dd",
                                        "range-separator": "至",
                                        "start-placeholder": "开始日期",
                                        "end-placeholder": "结束日期",
                                        clearable: "",
                                      },
                                      on: { change: _vm.sj },
                                      model: {
                                        value: _vm.rangeTime,
                                        callback: function ($$v) {
                                          _vm.rangeTime = $$v
                                        },
                                        expression: "rangeTime",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 4 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { "label-width": "0" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "label" },
                                        slot: "label",
                                      },
                                      [_vm._v(" ")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: _vm.btnConfig.searchBtn.type,
                                          size: _vm.btnConfig.size,
                                          icon: _vm.btnConfig.searchBtn.icon,
                                        },
                                        on: { click: _vm.onSearch },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.btnConfig.searchBtn.text)
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: _vm.btnConfig.resetBtn.type,
                                          size: _vm.btnConfig.size,
                                          icon: _vm.btnConfig.resetBtn.icon,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onReset("formInline")
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.btnConfig.resetBtn.text)
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.formConfig.btnFollow
              ? _c("el-divider", { staticClass: "btnDivider" })
              : _vm._e(),
            _vm._v(" "),
            _c("el-row", [
              _c("div", { staticClass: "phead" }, [
                _c(
                  "div",
                  { staticClass: "head-btn-group" },
                  [
                    _vm.hasAuth("exportbtn", _vm.$route.meta.authList)
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: _vm.btnConfig.downLoadBtn.type,
                              size: _vm.btnConfig.size,
                              icon: _vm.btnConfig.downLoadBtn.icon,
                              loading: _vm.downLoading,
                            },
                            on: { click: _vm.download },
                          },
                          [_vm._v(_vm._s(_vm.btnConfig.downLoadBtn.text))]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.tableData,
                  height: _vm.tabHeight,
                  border: _vm.tableConfig.border,
                  stripe: _vm.tableConfig.stripe,
                  fit: "",
                  "highlight-current-row": "",
                },
              },
              [
                _c("el-table-column", {
                  attrs: { type: "index", label: "序号", width: "80" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "schemeName",
                    label: "活动方案",
                    width: "300",
                    align: _vm.tableConfig.align,
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "reportStartTime",
                    label: "账期开始时间",
                    "min-width": "200",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "reportEndTime",
                    label: "账期截止时间",
                    "min-width": "200",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "activityCode",
                    label: "活动编码",
                    "min-width": "140",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "activityName",
                    label: "活动名称",
                    "min-width": "240",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "prizeTypeName",
                    label: "奖品类型",
                    "min-width": "120",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "prizeName",
                    label: "奖品名称",
                    "min-width": "120",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "verifyTypeName",
                    label: "角色",
                    "min-width": "120",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "prizeNum",
                    label: "奖品数",
                    "min-width": "100",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "totalMoney",
                    label: "总价值（元）",
                    "min-width": "100",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "actualMoney",
                    label: "实际花费（元）",
                    "min-width": "120",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    fixed: "right",
                    label: "操作",
                    align: _vm.tableConfig.align,
                    width: "100",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEdit(scope.row)
                                  },
                                },
                              },
                              [_vm._v("详情")]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1267648648
                  ),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("Pagination", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.total > 0,
                      expression: "total > 0",
                    },
                  ],
                  attrs: {
                    layout: "total, sizes, prev, pager, next, jumper",
                    total: _vm.total,
                    page: _vm.params.pageNo,
                    limit: _vm.params.pageSize,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.params, "pageNo", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.params, "pageSize", $event)
                    },
                    pagination: _vm.prizeReport,
                  },
                }),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
      ? _c("section", [_vm._m(0)])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }