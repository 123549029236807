"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _templateManage = require("@/api/terminal/templateManage");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _HoverMask = _interopRequireDefault(require("@/components/HoverMask"));
var _config = _interopRequireDefault(require("@/views/terminalManagement/marketingActivities/templateManage/config"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingActivitiestemplateManagerepositoryindex';
var _default = {
  name: 'MarketingActivitiestemplateManagerepositoryindex',
  components: {
    Pagination: _Pagination.default,
    HoverMask: _HoverMask.default,
    Content: _config.default
  },
  data: function data() {
    return {
      id: '',
      type: '',
      bkImg: 'http://10.0.7.33/group2/M00/00/0E/CgAHIV1Unx2AA3QgABCh3YyyOwo103.png',
      total: 0,
      listLoading: false,
      dialogVisible: false,
      queryList: {
        templateType: null,
        pageNo: 1,
        pageSize: 10
      },
      list: [
        // {
        //   img: require("@/assets/images/hongbao.png"),
        //   title: "扫码抽奖",
        //   type: "2",
        // },
        // {
        //   img: require("@/assets/images/zhuanpan.png"),
        //   title: "大转盘",
        //   type: "3",
        // },
        // {
        //   img: require("@/assets/images/jizi.png"),
        //   title: "集字",
        //   type: "1",
        // },
      ],
      apis: []
    };
  },
  activated: function activated() {
    var that = this;
    var hasCache = !!(that.list && that.list.length > 0);
    var authList = that.$route.meta.authList;
    that.apis = authList;
    console.log(authList);
    var auth = that.hasAuth('searchbtn', authList);
    if (!hasCache) {
      if (auth) {
        that.getList();
      }
    }
  },
  // 缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = that.getCaches(that, name);
    if (!cache) {
      that.list = [];
      that.total = 0;
    }
  },
  methods: {
    creatAct: function creatAct(row, statue) {
      this.authId('scanbtn', this.apis);
      this.type = row.templateType;
      this.id = row.templateId;
      this.dialogVisible = true;
    },
    close: function close() {
      this.dialogVisible = false;
    },
    changeSel: function changeSel() {
      this.queryList.pageNo = 1;
      this.getList();
    },
    clear: function clear() {
      this.queryList.templateType = '';
      this.queryList.pageNo = 1;
      this.getList();
    },
    getList: function getList() {
      var _this = this;
      this.authId('searchbtn', this.apis);
      this.listLoading = true;
      (0, _templateManage.templateRepositoryList)(this.queryList).then(function (res) {
        res = res.data;
        if (res.status === 200) {
          _this.list = res.data.list;
          // this.list.push(res.data.list[0])
          _this.total = res.data.total;
        } else {
          _this.$message.closeAll();
          _this.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
        _this.listLoading = false;
      });
    },
    seltMy: function seltMy(val) {
      var _this2 = this;
      this.authId('addbtn', this.apis);
      (0, _templateManage.selSysBuildTemplate)({
        id: val
      }).then(function (res) {
        res = res.data;
        if (res.status * 1 === 200) {
          _this2.$message.closeAll();
          _this2.$message({
            message: res.msg,
            type: 'success',
            duration: 3 * 1000
          });
        } else {
          _this2.$message.closeAll();
          _this2.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
      });
    }
  }
};
exports.default = _default;