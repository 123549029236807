var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        "append-to-body": true,
        width: "183vh",
        title: "变更次数详情",
        "before-close": _vm.rest,
        visible: _vm.dialogHistoryListVisible,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogHistoryListVisible = $event
        },
        open: _vm.getHistoryList,
      },
    },
    [
      _c(
        "div",
        { attrs: { id: "table-box" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "singleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.list,
                border: _vm.tableConfig.border,
                stripe: _vm.tableConfig.stripe,
                "highlight-current-row": "",
                height: "300",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "序号",
                  type: "index",
                  width: "120",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "MAC地址",
                  align: _vm.tableConfig.align,
                  "min-width": "100",
                  prop: "prevMacAddress",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "IP地址",
                  align: _vm.tableConfig.align,
                  "min-width": "100",
                  prop: "prevIpAddress",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "变更原因",
                  align: _vm.tableConfig.align,
                  "min-width": "100",
                  prop: "auditReasons",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作人",
                  align: _vm.tableConfig.align,
                  "min-width": "100",
                  prop: "updaterId",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作时间",
                  align: _vm.tableConfig.align,
                  "min-width": "100",
                  prop: "updateTime",
                  "show-overflow-tooltip": "",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [_c("el-button", { on: { click: _vm.rest } }, [_vm._v("关闭")])],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }