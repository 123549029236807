"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _order = require("@/api/order");
var _dictionary = require("@/api/systems/dictionary");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var TypeDataComs = {
  orderStatus: []
};
var _default = {
  name: 'OrderCentercostorderCcdetail',
  filters: {
    operateType: function operateType(value) {
      if (value == '' || value == null) {
        return '\xa0';
      } else {
        var statusMap = {
          1: '创建订单',
          2: '预审通过',
          3: '预审不通过',
          4: '确款审核通过',
          5: '确款审核不通过',
          6: '修改订单'
        };
        return statusMap[value];
      }
    },
    typeClass: function typeClass(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          0: 'success',
          1: 'success',
          2: 'success',
          3: 'success',
          4: 'success',
          5: 'danger',
          6: 'danger',
          7: 'warning',
          8: 'primary',
          9: 'primary'
        };
        return statusMap[value];
      }
    },
    orderType: function orderType(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          1: '买赠订单',
          2: '酒券订单',
          3: '团购订单',
          4: '费用酒订单',
          5: '样品酒订单'
        };
        return statusMap[value];
      }
    },
    auditNodeName: function auditNodeName(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          2: '确款',
          1: '预审'
        };
        return statusMap[value];
      }
    },
    orderStatusText: function orderStatusText(value) {
      var name = '';
      TypeDataComs.orderStatus.forEach(function (c) {
        if (c.figure == value) {
          name = c.name;
          return;
        }
      });
      return name;
    }
  },
  data: function data() {
    return {
      cardPadding: {
        padding: '0 0 20px'
      },
      activeName: '',
      orderStatus: [],
      productList: [],
      auditList: [],
      addData: {
        pid: '',
        orderType: 2,
        orderNo: '',
        placeOrderDate: '',
        paymentStyle: '',
        purchaseComId: '',
        purchaseComType: 3,
        applyUser: '',
        receiveComId: '',
        receiveComType: '',
        receiveContacts: '',
        receivePhone: '',
        receiveAddress: '',
        sendOutComId: '',
        sendOutComType: '',
        orderStatus: 1,
        remark: '',
        // 产品列表
        orderDetailDTOS: []
      },
      loading: false,
      auditLoading: false
    };
  },
  mounted: function mounted() {
    var _this = this;
    if (this.$route.query.id) {
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: 'CCXSDDZT'
      }).then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this.orderStatus = res.data.data;
            TypeDataComs['orderStatus'] = res.data.data;
          } else {
            _this.orderStatus = [];
            TypeDataComs['orderStatus'] = [];
          }
        }
      }).then(function () {
        _this.getDetailData(_this.$route.query.id);
      });
    } else {
      this.$router.replace({
        name: 'orderCentercostorder-ccindex'
      });
    }
  },
  methods: {
    handleClose: function handleClose() {
      this.$router.go(-1);
    },
    getDirSel: function getDirSel(code, list) {
      var _this2 = this;
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: code
      }).then(function (res) {
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this2[list] = res.data.data;
            TypeDataComs[list] = res.data.data;
          } else {
            _this2[list] = [];
            TypeDataComs[list] = [];
          }
        }
      });
    },
    resetList: function resetList() {
      this.productList = JSON.parse(JSON.stringify(this.productList));
    },
    // 获取详情数据
    getDetailData: function getDetailData(id) {
      var _this3 = this;
      this.loading = true;
      (0, _order.queryByOrderNo)(id).then(function (res) {
        if (res.data.code == 200) {
          var data = res.data.data;
          var proArr = [];
          _this3.addData = res.data.data;
          data.orderProductDetailVOS.forEach(function (item) {
            var obj = {
              pid: item.pid,
              parentDetailId: item.parentDetailId,
              pId: item.productId,
              productCode: item.productCode,
              productName: item.productName,
              productModel: item.productModel,
              packScaleExpression: item.packScaleExpression,
              chestNum: item.chestNum,
              bottleNum: item.bottleNum,
              totalBottleNum: item.totalBottleNum
            };
            proArr.push(obj);
          });
          _this3.productList = proArr;
        } else {
          _this3.$message.error(res.data.msg);
        }
        _this3.loading = false;
      }).catch(function () {
        _this3.loading = false;
      }).then(function () {
        if (_this3.addData.tradeStatus > 1) {
          _this3.auditLoading = true;
          (0, _order.getAuditLog)({
            orderNo: id
          }).then(function (res) {
            if (res.data.code == 200) {
              _this3.auditList = res.data.data;
            } else {
              _this3.$message.error('审核日志获取失败');
            }
            _this3.auditLoading = false;
          }).catch(function () {});
        }
      });
    }
  }
};
exports.default = _default;