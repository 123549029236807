"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _vue = _interopRequireDefault(require("vue"));
var _utils = require("@/utils");
// import _ from 'lodash'

_vue.default.filter('textDate', function (value) {
  if (!value) return '';
  return (0, _utils.parseTime)(value, '{y}-{m}-{d} {h}:{i}:{s}');
});
_vue.default.filter('textDateOnlyDate', function (value) {
  if (!value) return '';
  return (0, _utils.parseTime)(value, '{y}-{m}-{d}');
});