"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _work = require("@/api/systems/work");
var _compyuser = require("@/api/systems/compyuser");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'systemsplcmangespscanindex',
  components: {
    Pagination: _Pagination.default,
    Treeselect: _vueTreeselect.default
  },
  data: function data() {
    return {
      listQuery: {
        moduleCode: '',
        billCode: '',
        stauts: '',
        billNo: '',
        orgCode: null,
        pageNo: 1,
        pageSize: 20
      },
      list: [],
      tableKey: 'wfqd',
      total: 0,
      listLoading: false,
      loading: false,
      moduleData: [],
      pageData: [],
      apis: [{
        authBtn: true,
        resourceCode: 'HQRZ'
      }],
      page2: false,
      endShow: true,
      operate: false,
      tabHeight: '100%',
      scanData: {},
      dialogVisible1: false,
      tableData: [],
      options: [],
      expandTxt: '展开',
      showCol: false
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    var that = this;
    that.getList();
    that.getMoudle();
    that.getOrg();
  },
  filters: {
    formatTime: function formatTime(value) {
      if (!value) return '';
      return (0, _utils.parseTime)(value, '{y}-{m}-{d} {h}:{i}:{s}');
    },
    rangeTime: function rangeTime(value) {
      if (!value) {
        return '';
      }
      var dateBegin = new Date(value);
      var dateEnd = new Date();
      var dateDiff = dateEnd.getTime() - dateBegin.getTime();
      return (dateDiff / 1000 / 60 / 60).toFixed(1);
    }
  },
  methods: {
    getList: function getList() {
      var that = this;
      that.listLoading = true;
      (0, _work.getSearchList)(that.listQuery).then(function (response) {
        if (response.data.code == 200) {
          that.list = response.data.data.records;
          that.total = parseInt(response.data.data.total);
          setTimeout(function () {
            that.listLoading = false;
          }, 1 * 800);
        } else {
          that.$message.error(response.data.msg);
          that.listLoading = false;
          return;
        }
      }).catch(function (res) {
        that.listLoading = false;
      });
    },
    getMoudle: function getMoudle() {
      var that = this;
      (0, _work.getMoudleMsg)().then(function (res) {
        if (res.data.code == 200) {
          that.moduleData = res.data.data;
        }
      });
    },
    getPages: function getPages(val) {
      var that = this;
      var param = {};
      param.moduleCode = val;
      (0, _work.getPageMsg)(param).then(function (res) {
        if (res.data.code == 200) {
          that.pageData = res.data.data;
        }
      });
    },
    DoSearch: function DoSearch() {
      var that = this;
      that.listQuery.pageNo = 1;
      that.listQuery = (0, _utils.filterKeyNull)(that.listQuery);
      that.getList();
      if (that.listQuery.orgCode == '') {
        that.listQuery.orgCode = null;
      }
    },
    DoReset: function DoReset(formName) {
      var that = this;
      that.listQuery.moduleCode = '';
      that.listQuery.billCode = '';
      that.listQuery.stauts = '';
      that.listQuery.billNo = '';
      that.listQuery.orgCode = null;
      that.$refs[formName].resetFields();
      that.listQuery.pageNo = 1;
      that.getList();
    },
    DoScan: function DoScan(data) {
      var that = this;
      that.loading = true;
      that.scanData = (0, _utils.deepClone)(data);
      that.dialogVisible1 = true;
      var param = {};
      param.pId = data.pidString;
      (0, _work.getSplcDetail)(param).then(function (res) {
        if (res.data.code == 200) {
          that.tableData = res.data.data;
          that.loading = false;
        } else {
          that.loading = false;
        }
      }).catch(function () {
        that.loading = false;
      });
    },
    handleClose1: function handleClose1() {
      var that = this;
      that.dialogVisible1 = false;
    },
    getOrg: function getOrg() {
      var that = this;
      (0, _compyuser.getQyOrg)().then(function (res) {
        if (res.data.code == 200) {
          var rData = res.data.data;
          var arr = [];
          that.orgOriginData = rData;
          if (rData.length >= 0) {
            rData.forEach(function (item) {
              var obj = {};
              obj.id = item.pid;
              obj.label = item.orgName;
              obj.pid = item.pid;
              obj.parentPid = item.parentPid;
              arr.push(obj);
            });
            that.options = (0, _utils.transTreeData)(arr, 'pid', 'parentPid', 'children');
          }
        }
      });
    },
    //展开收起搜索条件
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    }
  }
};
exports.default = _default;