var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "seach-box" } },
    [
      _c(
        "el-form",
        {
          ref: "searchform",
          attrs: {
            model: _vm.formData,
            "label-position": _vm.formConfig.labelPosition,
            "label-width": _vm.formConfig.labelWidth,
            "label-suffix": ":",
          },
        },
        [
          _c(
            "div",
            { staticClass: "cols" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品品牌", prop: "ppwlList" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                filterable: "",
                                placeholder: "请选择/输入品牌",
                              },
                              on: { change: _vm.getqueryMaterial },
                              model: {
                                value: _vm.brandData,
                                callback: function ($$v) {
                                  _vm.brandData = $$v
                                },
                                expression: "brandData",
                              },
                            },
                            _vm._l(_vm.brandList, function (item) {
                              return _c("el-option", {
                                key: item.pId,
                                attrs: {
                                  label: item.brandName,
                                  value: item.pId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品名称", prop: "optBrand" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                multiple: "",
                                "collapse-tags": "",
                                filterable: "",
                                placeholder: "请先选择产品",
                              },
                              model: {
                                value: _vm.optBrand,
                                callback: function ($$v) {
                                  _vm.optBrand = $$v
                                },
                                expression: "optBrand",
                              },
                            },
                            _vm._l(_vm.proList, function (item) {
                              return _c("el-option", {
                                key: item.materialCode,
                                attrs: {
                                  label: item.materialName,
                                  value: item.materialCode,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "startTime", label: "开始日期" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "date",
                              format: "yyyy-MM-dd",
                              "value-format": "yyyyMMdd",
                              align: "right",
                              clearable: false,
                            },
                            model: {
                              value: _vm.formData.startTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "startTime", $$v)
                              },
                              expression: "formData.startTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "endTime", label: "结束日期" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "date",
                              format: "yyyy-MM-dd",
                              "value-format": "yyyyMMdd",
                              align: "right",
                              clearable: false,
                            },
                            model: {
                              value: _vm.formData.endTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "endTime", $$v)
                              },
                              expression: "formData.endTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }