"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _memberManage = require("@/api/terminal/memberManage");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _detail = _interopRequireDefault(require("@/views/terminalManagement/memberManage/detail"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManagememberManagememberindex';
var _default = {
  name: 'MarketingManagememberManagememberindex',
  components: {
    Pagination: _Pagination.default,
    Detail: _detail.default
  },
  filters: {
    status: function status(index) {
      var comtype = {
        0: '正常',
        1: '冻结',
        2: '预警'
      };
      var result = comtype[index];
      return result;
    },
    birthday: function birthday(index) {
      if (!index) return '';
      return index.split(' ')[0];
    }
  },
  data: function data() {
    return {
      tabHeight: '100%',
      list: null,
      // integral: '',
      memberId: '',
      type: '',
      listLoading: true,
      total: 0,
      formInline: {
        nickName: '',
        appid: '',
        phone: '',
        pageNum: 1,
        pageSize: 20,
        status: '',
        isBlacklist: 2
      },
      dialogVisible: false,
      detailVisible: false,
      ruleForm: {
        type: '1',
        num: ''
      },
      rules: {
        num: [{
          required: true,
          message: '积分不能为空',
          trigger: 'blur'
        }]
      },
      downLoading: false,
      apis: []
    };
  },
  activated: function activated() {
    var that = this;
    var hasCache = !!(that.list && that.list.length > 0);
    var authList = that.$route.meta.authList;
    that.apis = authList;
    console.log(authList);
    var auth = that.hasAuth('searchbtn', authList);
    that.authId('searchbtn', authList);
    if (!hasCache) {
      if (auth) {
        that.fetchData();
      }
    }
  },
  // 缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = that.getCaches(that, name);
    if (!cache) {
      that.list = [];
      that.total = 0;
    }
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    linkpage: function linkpage(id) {
      var that = this;
      that.authId('prizerecord', that.apis);
      that.$router.push({
        path: '/marketingManage/awardManage/winningRecord/index',
        query: {
          userId: id
        }
      });
    },
    getSoldExcel: function getSoldExcel() {
      var that = this;
      that.authId('importbtn', that.apis);
      var submitData = (0, _utils.filterKeyNull)(this.formInline);
      submitData.size = 2147483647;
      (0, _memberManage.memberExcel)(submitData).then(function (data) {
        var blob = new Blob([data.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
        });
        var fileName = '会员信息列表.xlsx';
        var elink = document.createElement('a');
        if ('download' in elink) {
          // 非IE下载
          elink.download = fileName;
          elink.style.display = 'none';
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href); // 释放URL 对象
          document.body.removeChild(elink);
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName);
        }
      });
    },
    close: function close() {
      this.type = '';
      this.memberId = '';
      this.detailVisible = false;
      this.fetchData();
    },
    // 查询
    fetchData: function fetchData() {
      var _this = this;
      var that = this;
      that.authId('searchbtn', that.apis);
      this.listLoading = true;
      (0, _memberManage.getList)(this.formInline).then(function (res) {
        res = res.data;
        console.log(res);
        if (res.status === 200) {
          _this.list = res.data.list;
          _this.total = res.data.total;
        } else {
          _this.$message.closeAll();
          _this.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
        _this.listLoading = false;
      });
    },
    changeIntegral: function changeIntegral(val) {
      this.dialogVisible = true;
    },
    subIntegral: function subIntegral() {
      //  接口
      this.restForm('ruleForm');
      this.dialogVisible = false;
    },
    cancel: function cancel() {
      this.restForm('ruleForm');
      this.dialogVisible = false;
    },
    restForm: function restForm(refname) {
      this.$refs[refname].resetFields();
    },
    toaddBlacklist: function toaddBlacklist(id, type) {
      var _this2 = this;
      var that = this;
      that.authId('insertbtn', that.apis);
      (0, _memberManage.addBlacklist)({
        memberId: id
      }).then(function (res) {
        res = res.data;
        if (res.status === 200) {
          _this2.$message.closeAll();
          _this2.$message({
            message: res.msg,
            type: 'success',
            duration: 3 * 1000
          });
          _this2.fetchData();
        } else {
          _this2.$message.closeAll();
          _this2.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
      });
    },
    changeStatus: function changeStatus(id, type) {
      var _this3 = this;
      if (type == 0) {
        this.authId('startbtn', this.apis);
      }
      if (type == 1) {
        this.authId('stopbtn', this.apis);
      }
      (0, _memberManage.freezeMember)({
        memberId: id,
        status: type,
        moduleId: this.$route.meta.moduleId,
        proId: this.$route.meta.proId
      }).then(function (res) {
        res = res.data;
        if (res.status === 200) {
          _this3.$message.closeAll();
          _this3.$message({
            message: res.msg,
            type: 'success',
            duration: 3 * 1000
          });
          _this3.fetchData();
        } else {
          _this3.$message.closeAll();
          _this3.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
      });
    },
    detail: function detail(row, status) {
      var that = this;
      if (status == 'show') {
        that.authId('scanbtn', that.apis);
      }
      if (status == 'updata') {
        that.authId('updatebtn', that.apis);
      }
      this.memberId = row.memberId;
      this.integral = row.integral;
      this.type = status;
      this.detailVisible = true;
    }
  }
};
exports.default = _default;