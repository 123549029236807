"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.to-string");
var _utils = require("@/utils");
var _param = require("@/api/chanelmange/param");
var _stockscan = require("@/api/chanelmange/stockscan");
var _scanZc = _interopRequireDefault(require("./scanZc"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'detailsHdfl',
  components: {
    scanZc: _scanZc.default
  },
  filters: {
    formatDate: function (_formatDate) {
      function formatDate(_x) {
        return _formatDate.apply(this, arguments);
      }
      formatDate.toString = function () {
        return _formatDate.toString();
      };
      return formatDate;
    }(function (time) {
      var date = new Date(time);
      return formatDate(date, 'yyyy-MM-dd hh:mm:ss');
    }),
    state: function state(val) {
      var status = {
        0: '待提交',
        1: '待审核',
        2: '已生效',
        3: '已终止'
      };
      return status[val];
    },
    filtersAction: function filtersAction(val) {
      var actions = {
        1: '终端签收',
        2: '消费者扫码'
      };
      return actions[val];
    }
  },
  data: function data() {
    return {
      detailData: {},
      mloading: false,
      proList: [],
      treeData: [],
      checkedKeys: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      configData: [],
      configDatas: [],
      policyVisible: false,
      grads: [{
        name: '一级经销商返利配置',
        type: 1
      }, {
        name: '二级经销商返利配置',
        type: 2
      }, {
        name: '终端返利配置',
        type: 3
      }],
      triggerAction: null,
      actions: [{
        name: '终端签收',
        type: 1
      }, {
        name: '消费者扫码',
        type: 2
      }],
      pid: '',
      isActive: 1,
      radio: '1'
    };
  },
  created: function created() {
    var that = this;
  },
  mounted: function mounted() {
    var that = this;
    var pid = that.$route.params.pid;
    that.pid = pid;
    if (pid) {
      this.checkedKeys = [];
      this.treeData = [];
      this.getBasic(pid);
      this.getPro(pid);
      this.getOrgTree();
      this.getOrg(pid);
      this.getCofig(pid);
    } else {
      this.checkedKeys = [];
      this.treeData = [];
    }
  },
  methods: {
    //返利配置切换
    getCurList: function getCurList(key) {
      var that = this;
      that.isActive = key;
      that.configData = that.configDatas[key - 1];
    },
    //获取基本配置
    getBasic: function getBasic(arg) {
      var that = this;
      (0, _param.getRebateSchemeById)({
        pid: arg
      }).then(function (res) {
        if (res.data.code == 200) {
          that.detailData = res.data.data;
        } else {
          that.$message.error(res.data.msg + ',未能获取基本信息');
        }
      });
    },
    //获取产品
    getPro: function getPro(arg) {
      var that = this;
      (0, _param.getRebateSchemeProBySchemeId)({
        pid: arg
      }).then(function (res) {
        if (res.data.code == 200) {
          var response = res.data.data;
          that.proList = response.map(function (item) {
            return {
              measurementUnit: item.packScaleName,
              productClass: item.productClass,
              productClassId: item.productClassId,
              productCode: item.productCode,
              productId: item.productId,
              productName: item.productName,
              rebateProportion: item.rebateProportion
            };
          });
        } else {
          that.$message.error(res.data.msg + ',未能获取产品信息');
        }
      });
    },
    //获取组织
    getOrg: function getOrg(arg) {
      var that = this;
      (0, _param.getOrgRanges)({
        pid: arg
      }).then(function (res) {
        if (res.data.code == 200) {
          var rData = res.data.data;
          var arr = [];
          if (rData.length) {
            rData.forEach(function (item) {
              var obj = {};
              obj.label = item.orgName;
              obj.pid = item.orgId;
              obj.parentPid = item.parentId;
              arr.push(obj);
            });
            that.treeData = (0, _utils.transTreeData)(arr, 'pid', 'parentPid', 'children');
          }
        }
      });
    },
    getOrgTree: function getOrgTree() {
      var that = this;
      (0, _stockscan.getQueryOrg)().then(function (res) {
        if (res.data.code == 200) {
          var rData = res.data.data;
          var arr = [];
          if (rData.length >= 0) {
            rData.forEach(function (item) {
              var obj = {};
              obj.id = item.pid;
              obj.label = item.orgName;
              obj.pid = item.pid;
              obj.parentPid = item.parentPid;
              arr.push(obj);
            });
            that.treeData = (0, _utils.transTreeData)(arr, 'pid', 'parentPid', 'children');
          }
        }
      });
    },
    //获取返利方案配置详情
    getCofig: function getCofig(arg) {
      var that = this;
      (0, _param.getRebateSchemeConfig)({
        pid: arg
      }).then(function (res) {
        if (res.data.code == 200) {
          var response = res.data.data;
          if (response && response.length) {
            that.grads = response.map(function (item) {
              return {
                name: item.name,
                type: item.order
              };
            });
            that.configDatas = response.map(function (item) {
              return item.detail;
            });
            that.configData = that.configDatas[0];
          }
        } else {
          that.$message.error(res.data.msg + ',获取返利配置失败');
        }
      });
    },
    handleClose: function handleClose() {
      var that = this;
      that.checkedKeys = [];
      that.treeData = [];
      that.$router.back();
    },
    scanPolicy: function scanPolicy() {
      var that = this;
      if (that.detailData.policyCode == '') {
        that.$message.error('请选择政策');
        return;
      } else {
        that.policyVisible = true;
      }
    },
    closeScan: function closeScan() {
      this.policyVisible = false;
    },
    DoShResult: function DoShResult() {
      var that = this;
      var param = {};
      param.id = that.pid;
      if (that.radio == '1') {
        (0, _param.passFlSh)(param).then(function (res) {
          if (res.data.code == 200) {
            that.$message({
              type: 'success',
              message: '审核成功'
            });
            that.handleClose();
          } else {
            that.$message.error(res.data.msg);
            return;
          }
        });
      }
      if (that.radio == '2') {
        (0, _param.rejectFlSh)(param).then(function (res) {
          if (res.data.code == 200) {
            that.$message({
              type: 'success',
              message: '已拒审'
            });
            that.handleClose();
          } else {
            that.$message.error(res.data.msg);
            return;
          }
        });
      }
    }
  }
};
exports.default = _default;