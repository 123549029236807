"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _stock = require("@/api/warehouse/stock/stock");
var _select = require("@/api/warehouse/select");
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
var _productSelectDialog = _interopRequireDefault(require("@/components/productSelectDialog"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// filterKeyNull
var _default = {
  name: '',
  components: {
    productDialog: _productSelectDialog.default,
    Pagination: _Pagination.default,
    Treeselect: _vueTreeselect.default
  },
  data: function data() {
    return {
      multipleSelection: [],
      orgCode: null,
      orgOptions: [],
      TypeDataComs: {
        factoryList: [],
        OrgList: [],
        StoreList: []
      },
      listLoading: false,
      tableData: [],
      tabHeight: '100%',
      downLoading: false,
      ruleForm: {
        // 产品
        productCode: '',
        // 库房
        factoryCode: '',
        // 批次号
        batchNo: '',
        current: 1,
        size: 10
      },
      productCode: '',
      total: 0,
      downLoadTxt: '下载',
      downLoading2: false
    };
  },
  activated: function activated() {
    this.getList();
    this.getFactoryInfoList();
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    // 列表
    getList: function getList() {
      var that = this;
      that.listLoading = true;
      (0, _stock.queryteaAge)(that.ruleForm).then(function (res) {
        console.log(res.data);
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            that.tableData = res.data.data.records;
            that.total = parseInt(res.data.data.total);
            setTimeout(function () {
              that.listLoading = false;
            }, 1 * 500);
          } else {
            that.tableData = [];
            that.total = 0;
          }
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    // 重置搜索
    resetForm: function resetForm() {
      this.$refs['ruleForm'].resetFields();
      this.productCode = '';
      this.orgCode = null;
      this.getList();
    },
    // 导出功能
    download: function download() {
      var _this = this;
      if (this.multipleSelection.length != 0) {
        this.download2();
      } else {
        this.downLoading = true;
        (0, _stock.teaAgeInventoryExportExcel)({
          productCode: this.ruleForm.productCode,
          factoryCode: this.ruleForm.factoryCode,
          batchNo: this.ruleForm.batchNo
        }).then(function (res) {
          if (res.status != 200) {
            _this.$message.error(res.data.msg);
          } else {
            var blob = new Blob([res.data], {
              type: 'application/vnd.ms-excel'
            });
            var downloadElement = document.createElement('a');
            var href = window.URL.createObjectURL(blob);
            downloadElement.href = href;
            downloadElement.download = '库存茶龄清单.xlsx';
            document.body.appendChild(downloadElement);
            downloadElement.click();
            document.body.removeChild(downloadElement);
            window.URL.revokeObjectURL(href);
          }
          _this.downLoading = false;
        }).catch(function () {
          _this.downLoading = false;
        });
      }
    },
    // 勾选
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(this.multipleSelection, '勾选');
    },
    // 勾选导出
    download2: function download2() {
      var that = this;
      that.downLoadTxt = '下载中...';
      that.downLoading = true;
      var tHeader = ['产品名称', '批次号', '库房名称', '产品编码', '茶龄', '产品价格', '包装比例表达式', '库存箱数', '库存盒/袋/桶数量'];
      var filterVal = ['productName', 'proBatchNo', 'storeHouseName', 'productCode', 'age', 'productPrice', 'packScaleExpression', 'chestCount', 'boxCount'];
      var list = that.multipleSelection;
      var data = this.formatJson(filterVal, list);
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        excel.export_json_to_excel({
          filename: '库存茶龄清单',
          header: tHeader,
          // 表头 必填
          data: data,
          // 具体数据 必填
          autoWidth: true,
          // 非必填
          bookType: 'xlsx' // 非必填
        });
      });

      setTimeout(function () {
        that.downLoading = false;
      }, 1 * 500);
      that.downLoadTxt = '下载';
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          // if (j === 'timestamp') {
          //   return parseTime(v[j])
          // } else {
          return v[j];
          // }
        });
      });
    },
    // 产品名称
    setSel: function setSel(val) {
      this.productCode = val.productName;
      this.ruleForm.productCode = val.pId;
    },
    openProductWin: function openProductWin(e) {
      this.$refs.selProduct.proSelVisible = true;
    },
    clearProduct: function clearProduct(e) {
      this.ruleForm.productCode = '';
    },
    getstoreTypeInfoList: function getstoreTypeInfoList(type, keyName) {
      // 4:单据来源，7：单据状态，103：工厂代码
      var that = this;
      (0, _select.storeTypeInfoList)(type).then(function (res) {
        if (res.data.code != 200) {
          that.TypeDataComs[keyName] = [];
          return;
        } else {
          that.TypeDataComs[keyName] = res.data.data;
        }
      }).catch(function () {
        that.TypeDataComs[keyName] = [];
      });
    },
    getFactoryInfoList: function getFactoryInfoList() {
      var _this2 = this;
      // 获取工厂下啦列表数据
      var that = this;
      var data = {
        orgStatus: 1,
        orgCodeAndName: '',
        orgTypeList: ['2', '3']
      };
      this.TypeDataComs.OrgList = [];
      (0, _select.getXgOrgInfo)(data).then(function (res) {
        console.log(res.data);
        if (res.data.code != 200) {
          return;
        } else {
          var rData = res.data.data;
          var arr = [];
          _this2.TypeDataComs.OrgList = res.data.data;
          if (rData.length >= 0) {
            rData.forEach(function (item) {
              var obj = {};
              obj.id = item.pid;
              obj.label = item.orgName;
              obj.pid = item.pid;
              obj.parentPid = item.parentPid;
              arr.push(obj);
            });
            that.orgOptions = (0, _utils.transTreeData)(arr, 'pid', 'parentPid', 'children');
            // that.orgCode = that.orgOptions[0].id
            // that.getHouses(that.orgOptions[0].id)
          }
        }
      }).catch(function () {});
    },
    getStoreList: function getStoreList() {
      var _this3 = this;
      // 获取库房下拉列表数据
      this.TypeDataComs.StoreList = [];
      (0, _select.storeHouseInfoList)().then(function (res) {
        if (res.data.code != 200) {
          return;
        } else {
          _this3.TypeDataComs.StoreList = res.data.data;
        }
      }).catch(function () {});
    },
    // 组织change
    getOrgVal: function getOrgVal(val) {
      var that = this;
      that.TypeDataComs.StoreList = [];
      that.ruleForm.factoryCode = '';
      that.getHouses(val.id);
    },
    emptyStores: function emptyStores(val) {
      var that = this;
      if (typeof val == 'undefined') {
        that.TypeDataComs.StoreList = [];
        that.ruleForm.factoryCode = '';
      }
    },
    // 获取库房
    getHouses: function getHouses(arg) {
      var that = this;
      var param = {
        orgOrFactoryId: arg
      };
      that.TypeDataComs.StoreList = [];
      (0, _select.getRelateStore)(param).then(function (res) {
        if (res.data.code == 200) {
          that.TypeDataComs.StoreList = res.data.data;
        }
      });
    },
    getDownHouses: function getDownHouses() {
      var that = this;
      if (that.orgCode == null || that.orgCode == '') {
        that.$message.error('请选择所属企业');
        return;
      }
    }
  }
};
exports.default = _default;