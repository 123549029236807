"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _ordergl = require("@/api/warehouse/ordergl");
var _outstock = require("@/api/chanelmange/outstock");
var _purchaseDialog = _interopRequireDefault(require("@/components/purchaseDialog"));
var _personChose = _interopRequireDefault(require("@/components/personChose"));
var _purchase = require("@/api/chanelmange/purchase");
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _dictionary = require("@/api/systems/dictionary");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var TypeDataComs = {
  orderStatus: []
};
var cachName = 'chanelmangepurchaseydglindex';
var _default = {
  name: 'Chanelmangepurchaseydglindex',
  components: {
    Pagination: _Pagination.default,
    purchaseDialog: _purchaseDialog.default,
    Treeselect: _vueTreeselect.default,
    personChose: _personChose.default
  },
  filters: {
    orderStatusText: function orderStatusText(value) {
      var name = '';
      TypeDataComs.orderStatus.forEach(function (c) {
        if (c.figure == value) {
          name = c.name;
          return;
        }
      });
      return name;
    },
    formatTime: function formatTime(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        return (0, _utils.parseTime)(value, '{y}-{m}-{d}');
      }
    },
    payWayText: function payWayText(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          2: '先货后款',
          1: '先款后货'
        };
        return statusMap[value];
      }
    },
    purchaseComType: function purchaseComType(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          4: '终端',
          3: '经销商',
          2: '销售公司',
          1: '生产公司'
        };
        return statusMap[value];
      }
    },
    creatorType: function creatorType(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          3: '业务员',
          2: '终端',
          1: '经销商'
        };
        return statusMap[value];
      }
    },
    chnMoney: function chnMoney(n) {
      if (!/^(0|[1-9]\d*)(\.\d+)?$/.test(n) || n == 0.0) return '';
      var unit = '千百拾亿千百拾万千百拾元角分';
      var str = '';
      var p = n.indexOf('.');
      if (p >= 0) n = n.substring(0, p) + n.substr(p + 1, 2);
      unit = unit.substr(unit.length - n.length);
      for (var i = 0; i < n.length; i++) {
        str += '零壹贰叁肆伍陆柒捌玖'.charAt(n.charAt(i)) + unit.charAt(i);
      }
      return str.replace(/零(千|百|拾|角)/g, '零').replace(/(零)+/g, '零').replace(/零(万|亿|元)/g, '$1').replace(/(亿)万|壹(拾)/g, '$1$2').replace(/^元零?|零分/g, '').replace(/元$/g, '元整');
    }
  },
  data: function data() {
    return {
      unitType: '',
      orderTime: '',
      businessUser: '',
      listQuery: {
        current: 1,
        size: 20,
        beginDate: null,
        endDate: null,
        orderNo: null,
        orderStatus: null,
        orderType: '1',
        businessClass: 2,
        sendOutComId: null,
        channelOrderInfoQuery: {
          creatorType: 3,
          creatorId: null,
          purchaseOwnerOrgId: null,
          purchaseComId: ''
        }
      },
      payWay: [{
        name: '先款后货',
        code: 1
      }, {
        name: '先货后款',
        code: 2
      }],
      orderStatus: [],
      orgOptions: [],
      tableKey: 'orderTable',
      tabHeight: '100%',
      list: [],
      downLoading: false,
      listLoading: false,
      exportLoading: false,
      total: 0,
      personShow: false,
      // 采购单位名称
      purchaseComName: '',
      // 收货单位名称
      receiveComName: '',
      // 查询收货单位名称
      searchReceiveComName: '',
      // 发货单位名称
      sendOutComName: '',
      // 查询发货单位名称
      searchSendOutComName: '',
      title: '采购订单',
      dialogVisible: false,
      detailShow: false,
      loading: false,
      subType: '',
      showCol: false,
      expandTxt: '展开',
      apis: []
    };
  },
  computed: {
    totalMoney: {
      get: function get() {
        var totalSumAll = 0;
        this.productList.forEach(function (item) {
          if (!isNaN(item.totalPrice)) {
            totalSumAll += Number(item.totalPrice);
          }
        });
        totalSumAll = totalSumAll.toFixed(2);
        if (isNaN(totalSumAll)) {
          return 0;
        }
        return totalSumAll;
      },
      set: function set(val) {
        this.totalMoney = val;
      }
    }
  },
  activated: function activated() {
    var that = this;
    var hasCache = that.list.length > 0;
    var authList = that.$route.meta.authList;
    that.apis = authList;
    var auth = that.hasAuth('searchbtn', authList);
    var setHeaderId = that.authId('searchbtn', that.apis);
    if (!hasCache) {
      if (auth) {
        this.getDirSel('CCCGDDZT', 'orderStatus');
        that.getOrg();
        that.getList();
      }
    }
  },
  // 缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = that.getCaches(that, name);
    if (!cache) {
      that.list = [];
      that.total = 0;
    }
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    getDirSel: function getDirSel(code, list) {
      var _this = this;
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: code
      }).then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this[list] = res.data.data;
            TypeDataComs[list] = res.data.data;
          } else {
            _this[list] = [];
            TypeDataComs[list] = [];
          }
        }
      });
    },
    // 供货商为企业时，选择组织机构
    getOrg: function getOrg() {
      var that = this;
      (0, _outstock.getQueryOrg)().then(function (res) {
        console.log(res);
        if (res.data.code == 200) {
          var rData = res.data.data;
          var arr = [];
          that.orgOriginData = rData;
          if (rData.length >= 0) {
            rData.forEach(function (item) {
              var obj = {};
              obj.id = item.pid;
              obj.label = item.orgName;
              obj.pid = item.pid;
              obj.parentPid = item.parentPid;
              arr.push(obj);
            });
            that.orgOptions = (0, _utils.transTreeData)(arr, 'pid', 'parentPid', 'children');
          }
        }
      });
    },
    getList: function getList() {
      var that = this;
      var setHeaderId = that.authId('searchbtn', that.apis);
      that.listLoading = true;
      var param = JSON.parse(JSON.stringify(that.listQuery));
      param.beginDate = this.orderTime[0] || null;
      param.endDate = this.orderTime[1] || null;
      delete param.current;
      delete param.size;
      (0, _purchase.getCgList)(that.listQuery.current, that.listQuery.size, param).then(function (res) {
        if (res.data.code == 200) {
          that.list = res.data.data.records;
          that.total = parseInt(res.data.data.total);
          setTimeout(function () {
            that.listLoading = false;
          }, 1 * 800);
        } else {
          that.$message.error(res.data.msg);
          that.listLoading = false;
          return;
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    // 新增
    DoAdd: function DoAdd() {
      var _this2 = this;
      var that = this;
      this.title = '采购订单';
      that.subType = 'add';
      getOrderNo(1).then(function (res) {
        if (res.data.code == 200) {
          _this2.addData.orderNo = res.data.data;
          that.dialogVisible = true;
          _this2.$nextTick(function () {
            _this2.$refs.addForm.clearValidate();
          });
        } else {
          that.$message.error('订单号获取失败，请重试！');
        }
      });
    },
    getUnit: function getUnit(val) {
      var _this3 = this;
      var that = this;
      that.unitType = val;
      var setHeaderId = that.authId('searchbtn', that.apis);
      // that.unitShow = true
      this.$nextTick(function () {
        _this3.$refs.channelSelect.proSelVisible = true;
      });
    },
    getUalue: function getUalue(val) {
      console.log(val, 'val');
      var that = this;
      // that.unitSelect.channelName = val.channelName
      if (that.unitType == 'in') {
        that.listQuery.channelOrderInfoQuery.purchaseComId = val.pId;
        that.searchReceiveComName = val.channelName;
      }
      if (that.unitType == 'out') {
        that.listQuery.sendOutComId = val.pId;
        that.searchSendOutComName = val.channelName;
      }
      // that.unitShow = false
    },
    // 选择业务员
    chosePerson: function chosePerson() {
      var _this4 = this;
      var that = this;
      that.personShow = true;
      this.$nextTick(function () {
        // this.$refs.channelSelect.classList
        console.log(_this4.$refs.channelSelect.classList, ' this.$refs.channelSelect.classList');
      });
    },
    hidePerson: function hidePerson() {
      var that = this;
      that.personShow = false;
    },
    getPerson: function getPerson(val) {
      var that = this;
      that.listQuery.channelOrderInfoQuery.creatorId = val.pid;
      that.businessUser = val.userName;
      that.personShow = false;
    },
    // 展开收起搜索条件
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    },
    DoSearch: function DoSearch() {
      var that = this;
      that.listQuery.current = 1;
      that.getList();
    },
    DoReset: function DoReset(formName) {
      this.searchReceiveComName = '';
      this.searchSendOutComName = '';
      this.businessUser = '';
      this.orderTime = [];
      this.listQuery = {
        current: 1,
        size: 20,
        beginDate: null,
        endDate: null,
        orderNo: null,
        orderStatus: null,
        orderType: '1',
        businessClass: 2,
        sendOutComId: null,
        channelOrderInfoQuery: {
          creatorType: 3,
          creatorId: null,
          purchaseOwnerOrgId: null,
          purchaseComId: ''
        }
      };
      this.getList();
    },
    // 查看
    DoScan: function DoScan(data) {
      var setHeaderId = this.authId('detailbtn', that.apis);
      this.title = '采购订单-查看';
      this.subType = 'scan';
      var id = data.pid;
      this.getDetailData(id, 'scan');
      this.detailShow = true;
    },
    // 修改
    DoEdit: function DoEdit(data) {
      this.title = '采购订单-修改';
      this.subType = 'edit';
      this.getDetailData(data.pid, 'edit');
      this.dialogVisible = true;
    },
    // 获取详情数据
    getDetailData: function getDetailData(id, type) {
      var _this5 = this;
      this.loading = true;
      (0, _ordergl.queryById)(id).then(function (res) {
        if (res.data.code == 200) {
          var data = res.data.data;
          var arr = [];
          data.orderDetailDTOS.forEach(function (item) {
            var aNum = item.packRaidoExpress.split('*');
            var le = aNum.length - 1;
            var obj = {
              pid: item.pid,
              pId: item.productId,
              productCode: item.productCode,
              productName: item.productName,
              productModel: item.productSpec,
              packScaleExpression: item.packRaidoExpress,
              chestNum: item.chestNum,
              bottleNum: item.bottleNum,
              bottleNumOver: item.bottleNum % aNum[le],
              totalPrice: item.totalPrice,
              productPrice: item.prodPrice,
              isUseCoupon: item.isUseCoupon
            };
            console.log(obj, 999);
            arr.push(obj);
          });
          _this5.productList = arr;
          _this5.addData = res.data.data;
          _this5.purchaseComName = data.purchaseComName;
          _this5.receiveComName = data.receiveComName;
          _this5.sendOutComName = data.sendOutComName;
        } else {
          _this5.$message.error(res.data.msg);
        }
        _this5.loading = false;
      }).catch(function () {
        _this5.loading = false;
      }).then(function () {
        if (type == 'edit') {
          _this5.$nextTick(function () {
            _this5.$refs.addForm.clearValidate();
          });
        }
      });
    },
    // 置空字段
    resetVoid: function resetVoid() {
      this.addData = {
        businessClass: 1,
        pid: '',
        orderType: 1,
        orderNo: '',
        placeOrderDate: (0, _utils.parseTime)(new Date(), '{y}-{m}-{d}'),
        paymentStyle: '',
        purchaseComId: '',
        purchaseComType: '',
        applyUser: '',
        receiveComId: '',
        receiveComType: '',
        receiveContacts: '',
        receivePhone: '',
        receiveAddress: '',
        sendOutComId: '',
        sendOutComType: 1,
        orderStatus: 1,
        remark: '',
        // 产品列表
        orderDetailDTOS: []
      };
      this.productList = [];
      this.purchaseComName = '';
      this.receiveComName = '';
      this.sendOutComName = '';
    },
    handleClose: function handleClose() {
      var that = this;
      this.$refs.addForm.resetFields();
      that.resetVoid();
      that.dialogVisible = false;
    },
    detailClose: function detailClose() {
      this.resetVoid();
      this.detailShow = false;
    },
    // 导出文件流并下载
    DoExportAll: function DoExportAll() {
      var that = this;
      var setHeaderId = that.authId('importbtn', that.apis);
      var param = that.listQuery;
      var data = {};
      if (that.orderTime.length > 0) {
        param.beginDate = that.orderTime[0];
        param.endDate = that.orderTime[1];
      } else {
        param.beginDate = '';
        param.endDate = '';
      }
      delete param.current;
      delete param.size;
      that.exportLoading = true;
      (0, _purchase.exportCgData)(param).then(function (res) {
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '引单列表.xls';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
        that.exportLoading = false;
      }).catch(function () {
        that.exportLoading = false;
      });
    }
  }
};
exports.default = _default;