"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _wXintegralMall = require("@/api/wxhyyy/wXintegralMall");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
/* eslint-disable eqeqeq */
var _default = {
  filters: {
    typeFilter: function typeFilter(status) {
      if (!status) return '';
      var statusMap = {
        1: '扫码',
        2: '商品兑换',
        3: '活动获得',
        4: '企业操作',
        5: '完善个人信息',
        // '10': '积分过期 ',
        11: '购买商品',
        12: '扫防伪二维码',
        13: '积分过期',
        14: '兑换产品',
        15: '关注公众号',
        16: '分享好友 ',
        17: '手动变更 ',
        18: '新人礼包 ',
        19: '生日礼包 ',
        20: '营销计划 '
      };
      return statusMap[status];
    },
    stateFilter: function stateFilter(status) {
      var statusMap = {
        100: '未开始',
        200: '进行中',
        300: '已结束',
        600: '暂停中'
      };
      return statusMap[status];
    }
  },
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      handleShow: false,
      timer: null,
      list: null,
      listLoading: true,
      total: 0,
      typeName: '收入/支出',
      valuetime: [],
      formInline: {
        startTime: '',
        memberInfoid: '',
        endTime: '',
        integralDesc: null,
        integralType: null,
        jfbdqd: '',
        nickname: '',
        pageNo: 1,
        pageSize: 10
      },
      currentRow: '',
      radio: '',
      dialogVisible: false,
      integralTypelist: [{
        id: 11,
        name: '购买商品'
      }, {
        id: 12,
        name: '扫防伪二维码'
      }, {
        id: 13,
        name: '积分过期'
      }, {
        id: 14,
        name: '兑换产品'
      }, {
        id: 15,
        name: '关注公众号'
      }, {
        id: 16,
        name: '分享好友'
      }, {
        id: 17,
        name: '手动变更'
      }, {
        id: 18,
        name: '新人礼包'
      }, {
        id: 19,
        name: '生日礼包'
      }, {
        id: 20,
        name: '营销计划'
      }, {
        id: 5,
        name: '完善个人信息'
      }]
    };
  },
  created: function created() {
    // this.fetchData()
  },
  activated: function activated() {
    this.getsearchlist();
  },
  methods: {
    // 查询列表
    getsearchlist: function getsearchlist() {
      var _this = this;
      this.listLoading = true;
      this.formInline.memberInfoid = this.$route.query.id;
      var obj = {
        startTime: this.valuetime.length > 1 ? this.valuetime[0] : '',
        endTime: this.valuetime.length > 1 ? this.valuetime[1] : ''
      };
      Object.assign(this.formInline, obj);
      // delete this.formInline.valuetime
      (0, _wXintegralMall.searchJf)(this.formInline).then(function (res) {
        res = res.data;
        if (res.code === 200) {
          _this.total = res.data.total;
          _this.list = res.data.records;
        } else {
          _this.$message.info(res.msg);
        }
        _this.listLoading = false;
      });
      console.log(this.formInline);
    },
    filterChange: function filterChange(filters) {
      if (!filters.type || filters.type.length != 1) {
        this.formInline.integralDesc = null;
        this.typeName = '收入/支出';
      } else {
        this.formInline.integralDesc = filters.type[0];
        this.typeName = filters.type[0] == 1 ? '收入' : '支出';
      }
      this.fetchData();
    },
    restForm: function restForm(refname) {
      this.$refs[refname].resetFields();
      this.valuetime = [];
      this.getsearchlist();
    },
    fetchData: function fetchData() {
      var _this2 = this;
      this.listLoading = true;
      var obj = {
        dateStart: this.formInline.valuetime.length > 1 ? this.formInline.valuetime[0] : '',
        dateEnd: this.formInline.valuetime.length > 1 ? this.formInline.valuetime[1] : ''
      };
      Object.assign(this.formInline, obj);
      (0, _wXintegralMall.getByOrgId)(this.formInline).then(function (res) {
        res = res.data;
        if (res.status * 1 === 200) {
          _this2.total = res.data.total;
          _this2.list = res.data.list;
        } else {
          _this2.$message.closeAll();
          _this2.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
        _this2.listLoading = false;
      });
    }
  }
};
exports.default = _default;