"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.detailMember = detailMember;
var _request = _interopRequireDefault(require("@/utils/request"));
// 会员列表
// var baseUrl = '/user'
// var baseUrl = '/api'
var baseUrl = '';

// 根据id查看会员详情
function detailMember(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/pc/member/detailMember',
    method: 'get',
    params: params
  });
}