"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAllApp = getAllApp;
exports.saveParams = saveParams;
exports.upLoadImg = upLoadImg;
var _request = _interopRequireDefault(require("@/utils/request"));
// 查询所有渠道app
function getAllApp() {
  return (0, _request.default)({
    url: '/api-channel/qd/channel/getAll',
    method: 'post'
  });
}

// 图片上传
function upLoadImg(data) {
  return (0, _request.default)({
    url: '/api-basic/jc/file/upload/uploadImg',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    method: 'post',
    data: data
  });
}

// 保存参数列表数据
function saveParams(data) {
  return (0, _request.default)({
    url: '/api-channel/qd/channel/save',
    method: 'post',
    data: data
  });
}