"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  // 接收父组件传递过来的值
  props: {
    title: {
      type: String,
      default: function _default() {
        return '导入';
      }
    },
    templateHref: {
      type: String,
      default: function _default() {
        return '';
      }
    },
    templateName: {
      type: String,
      default: function _default() {
        return '经销商档案导入Excel模板';
      }
    },
    uploadForm: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    api: {
      type: String,
      default: function _default() {
        return '';
      }
    },
    cols: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      uploadVisible: false,
      myHeaders: {
        Authorization: 'Bearer ' + (0, _auth.getToken)(),
        'Content-Type': 'multipart/form-data'
      },
      fileList: [],
      uploadData: [],
      fileData: {},
      mloading: false,
      btnLoading: false,
      loading: false,
      dloading: false,
      tabledata: [],
      show: false
    };
  },
  computed: {
    // 设置上传地址
    uploadUrl: function uploadUrl() {
      return process.env.VUE_APP_BASE_API + this.api;
    }
  },
  watch: {
    uploadVisible: function uploadVisible(val) {
      if (val) {
        var token = (0, _auth.getToken)();
        this.tabledata = [];
        this.myHeaders = {
          Authorization: 'Bearer ' + token
        };
      }
    }
  },
  methods: {
    // 关闭批量导入模块
    uploadClose: function uploadClose() {
      var that = this;
      that.uploadForm.uploadFile = '';
      that.$refs.upload.clearFiles();
      that.tabledata = [];
      that.uploadVisible = false;
      that.fileList = [];
      that.uploadData = [];
      this.mloading = false;
      this.loading = false;
      this.btnLoading = false;
    },
    // 预览
    handlePreview: function handlePreview(file) {
      console.log(file);
    },
    // 删除前提示
    beforeRemove: function beforeRemove(file, fileList) {
      return this.$confirm("\u786E\u5B9A\u79FB\u9664 ".concat(file.name, "\uFF1F"));
    },
    // 上传前
    beforeUpload: function beforeUpload(file, fileList) {
      console.log(file, fileList, 'beforeLoad');
    },
    // 选择导入文件
    selectFile: function selectFile(file, fileList) {
      if (fileList.length > 1) {
        var deleteable = false;
        fileList.forEach(function (item, index) {
          if (item.status != 'ready') {
            deleteable = true;
            fileList.splice(index, 1);
          }
        });
        if (!deleteable) {
          fileList.splice(0, 1);
        }
      }
    },
    // 导入文件成功
    uploadFileRes: function uploadFileRes(res, file, fileList) {
      if (res.code != 200) {
        this.$message.error(res.msg);
        this.tabledata = res.data;
        this.show = true;
        this.mloading = false;
        this.loading = false;
        this.btnLoading = false;
        return;
      } else {
        this.show = false;
        if (!res.msg) {
          this.$message({
            type: 'success',
            message: '导入成功!'
          });
        } else {
          this.$message.success(res.msg);
        }
        this.$refs.upload.clearFiles();
        this.uploadClose();
        this.$emit('reLoadData', res.msg);
      }
    },
    // 导入文件失败
    uploadFileFail: function uploadFileFail(res, file, fileList) {
      this.$message.error('上传失败请重试');
      this.mloading = false;
      this.uploadForm.uploadFile = '';
      this.$refs.upload.clearFiles();
    },
    // 触发提交
    submitUpload: function submitUpload() {
      var _this = this;
      if (this.tabledata.length > 0) {
        this.$confirm('是否修改活重新选择模板上传?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          _this.$refs.upload.clearFiles();
          _this.tabledata = [];
        }).catch(function () {});
        return;
      }
      if (this.$refs.upload.uploadFiles.length == 0) {
        this.$message.error('请选择上传文件！');
        return;
      }
      this.mloading = true;
      this.uploadData = [];
      this.$refs.upload.submit();
    },
    progress: function progress(event, file, fileList) {
      console.log(file);
    },
    dclick: function dclick() {
      var that = this;
      that.dloading = true;
      setTimeout(function () {
        that.dloading = false;
      }, 1000);
    }
  }
};
exports.default = _default2;