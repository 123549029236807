"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _basic = require("@/api/mark/basic");
var _lithService = require("@/api/lithService");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var TypeDataComs = {
  djly: [],
  djzt: [],
  status: [],
  source: [],
  factory: [],
  classList: [],
  ddyt: []
};
var _default = {
  name: 'TableSuply',
  components: {
    Pagination: _Pagination.default
  },
  computed: {
    // 设置上传地址
    uploadUrl: function uploadUrl() {
      return process.env.VUE_APP_BASE_API + '/api-mark/endProductMark/importTemplate';
    }
  },
  filters: {
    formatDate: function formatDate(time) {
      var date = new Date(time);
      return (0, _basic.formatDate)(date, 'yyyy-MM-dd');
    },
    TypeDataCom: function TypeDataCom(code, keyName) {
      var vars = TypeDataComs[keyName];
      var name = '';
      vars.forEach(function (c) {
        if (c.code == code) {
          name = c.name;
          return;
        }
      });
      return name;
    },
    djlyText: function djlyText(val) {
      var name = '';
      TypeDataComs.djly.forEach(function (c) {
        if (c.figure == val) {
          name = c.name;
          return;
        }
      });
      return name;
    },
    djztText: function djztText(val) {
      var name = '';
      console.log(TypeDataComs.djzt);
      TypeDataComs.djzt.forEach(function (c) {
        if (c.figure == val) {
          name = c.name;
          return;
        }
      });
      return name;
    },
    orderStatusType: function orderStatusType(val) {
      var obj = {
        1: '',
        2: '',
        3: '',
        4: '',
        5: '',
        6: '',
        7: 'success',
        8: 'warning',
        9: 'warning',
        10: 'warning',
        11: 'warning',
        12: 'danger'
      };
      return obj[val];
    },
    // 客户类型
    khlxText: function khlxText(val) {},
    // 订单用途
    billApplication: function billApplication(val) {
      // var name = "";
      // TypeDataComs.djly.forEach(function(c) {
      //   if (c.figure == val) {
      //     name = c.name;
      //     return;
      //   }
      // });
      // return name;
    },
    // 出库方式
    billOutType: function billOutType(val) {
      // 1:扫码出库 2：生产任务单处理 3：生产批次号出库
      if (val == 1) {
        return '扫码出库';
      } else if (val == 2) {
        return '生产任务单处理';
      } else if (val == 3) {
        return '生产批次号出库';
      }
    }
  },
  data: function data() {
    return {
      typeNum: 1,
      billNo: '',
      inUseName: '',
      searchSendOutComName: '',
      searchSaleOrgName: '',
      searchInOrgName: '',
      productName: '',
      handleShow: false,
      printShow: false,
      factoryShow: false,
      companyShow: false,
      apartShow: false,
      mloading: false,
      factorycanChange: true,
      companycanChange: true,
      factoryType: '',
      companyType: '',
      orgId: '',
      tableData: [],
      tradebill: {
        batteryBoardCode: '',
        boxBarCode: '',
        cantoneseCode: '',
        createEndTime: '',
        createStartTime: '',
        laserCode: '',
        markEndTime: '',
        markModel: '',
        markStartTime: '',
        pinMarkCode: '',
        remark: '',
        storageBatteryCode: ''
      },
      searchForm: {
        current: 1,
        size: 20
      },
      uploadVisible: false,
      uploadData: [],
      uploadForm: {
        uploadFile: ''
      },
      myHeaders: {
        Authorization: 'Bearer ' + (0, _auth.getToken)()
      },
      uploadRules: {},
      fileList: [],
      tableKey: 'rn',
      total: 0,
      djly: [],
      djzt: [],
      storeList: [],
      classList: [],
      ddyt: [],
      // a123
      codetotal: 0,
      billType: 203,
      listLoading: false,
      downLoading: false,
      page2: false,
      multipleSelection: [],
      tabHeight: '100%',
      submitType: '',
      TypeDataComs: {
        status: [],
        source: [],
        factory: []
      },
      code_title: '二维码查看',
      codeVisible: false,
      SyncVisible: false,
      // 同步
      Syncform: {
        time: (0, _utils.parseTime)(new Date(), '{y}-{m}-{d}'),
        startRow: 0
      },
      Syncrules: {
        time: [{
          required: true,
          message: '请选择日期',
          trigger: 'change'
        }]
      },
      sloading: false
    };
  },
  activated: function activated() {
    var _this = this;
    this.authId('searchbtn', this.$route.meta.authList);
    setTimeout(function () {
      _this.getList();
    }, 500);
  },
  mounted: function mounted() {
    var that = this;
    var h = window.innerHeight - 410 + 'px';
    that.$nextTick(function () {
      that.tabHeight = h;
    });
    this.getList();
  },
  methods: {
    handleFilter: function handleFilter(_type) {
      this.searchForm.current = 1;
      this.searchForm.size = 20;
      this.getList();
    },
    resetForm: function resetForm(formName) {
      this.tradebill = {
        batteryBoardCode: '',
        boxBarCode: '',
        cantoneseCode: '',
        createEndTime: '',
        createStartTime: '',
        laserCode: '',
        markEndTime: '',
        markModel: '',
        markStartTime: '',
        pinMarkCode: '',
        remark: '',
        storageBatteryCode: ''
      };
      this.searchForm.current = 1;
      this.searchForm.size = 20;
      this.getList();
    },
    /**
     * 单位选择
     * @param {*} name 需要回传修改的名字
     * @param {*} companyType 单位类型 1 收获单位 2发货单位 0采购单位
     * @param {*} orgId 采购单位ID 获取收货单位使用
     * @param {*} inOutType 单据类型 1销售出库 2调拨出库 3其他出库 4生产入库 5调拨入库 6采购入库 7其他入库 11 采购订单 12销售订单
     *
     */
    openCompany: function openCompany(name, companyType, inOutType) {
      this.inUseName = name;
      this.companyType = companyType;
      this.inOutType = inOutType;
      this.companyShow = true;
    },
    setCompany: function setCompany(val) {
      this[this.inUseName] = val.companyName;
      switch (this.inUseName) {
        case 'searchInOrgName':
          this.searchForm.billSaleQuery.inOrgId = val.companyId;
          break;
        case 'searchSendOutComName':
          this.searchForm.billSaleQuery.outOrgId = val.companyId;
          break;
        case 'searchSaleOrgName':
          this.searchForm.billSaleQuery.saleOrgId = val.companyId;
          break;
      }
    },
    companyClose: function companyClose() {
      this.inUseName = '';
      this.companyType = '';
      this.orgId = '';
      this.isEnable = '';
      this.companycanChange = true;
      this.companyShow = false;
    },
    // 获取产品
    openSel: function openSel() {
      this.$refs.selProduct.proSelVisible = true;
    },
    setSel: function setSel(val) {
      this.searchForm.productId = val.pId;
      this.productName = val.productName;
    },
    getList: function getList() {
      var _this2 = this;
      this.authId('searchbtn', this.$route.meta.authList);
      var that = this;
      that.listLoading = true;
      (0, _lithService.selectPage)(this.searchForm.current, this.searchForm.size, this.tradebill).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          if (response.data.data != null) {
            that.tableData = response.data.data.records;
            that.total = parseInt(response.data.data.total);
          } else {
            that.tableData = [];
            that.total = 0;
          }
        }
        that.listLoading = false;
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    setShop: function setShop(val) {
      this.searchForm.productLineCode = '';
      this.searchForm.teamCode = '';
      if (!val) {
        if (!this.searchForm.factoryCode) {
          this.sellineList = JSON.parse(JSON.stringify(this.lineList));
          this.selteamList = JSON.parse(JSON.stringify(this.teamList));
        } else {
          this.sellineList = [];
          this.selteamList = [];
        }
        return;
      }
      // var item = this.workshopList.filter(function(number) {
      //   return number.factoryCode == val
      // })

      this.sellineList = this.lineList.filter(function (number) {
        return number.parentId == val;
      });
      this.selteamList = this.teamList.filter(function (number) {
        return number.parentId == val;
      });
    },
    setScanShop: function setScanShop(val) {
      this.scanData.productLineCode = '';
      this.scanData.teamCode = '';
      if (!val) {
        this.scanlineList = [];
        this.scanteamList = [];
        return;
      }
      // var item = this.workshopList.filter(function(number) {
      //   return number.factoryCode == val
      // })
      this.scanlineList = this.lineList.filter(function (number) {
        return number.parentId == val;
      });
      this.scanteamList = this.teamList.filter(function (number) {
        return number.parentId == val;
      });
    },
    submitUpload: function submitUpload() {
      if (this.fileList.length > 0) {
        this.mloading = true;
        this.uploadData = [];
        this.$refs.upload.submit();
      } else {
        this.$message.error('请导入上传文件');
      }
    },
    uploadFileRes: function uploadFileRes(res, file, fileList) {
      if (res.code == 2004) {
        this.$message.error(res.msg);
        this.uploadData = res.data;
      } else if (res.code != 200) {
        this.$message.error(res.msg);
      } else {
        this.mloading = false;
        this.$message({
          type: 'success',
          message: '导入成功!'
        });
        this.uploadClose();
        this.getList();
      }
      this.mloading = false;
      this.fileList = [];
      this.$refs.upload.clearFiles();
    },
    uploadClose: function uploadClose() {
      this.uploadForm.uploadFile = '';
      this.resetForm('uploadForm');
      this.uploadVisible = false;
      this.authId('searchbtn', this.$route.meta.authList);
    },
    selectFile: function selectFile(file, fileList) {
      if (!file.response) {
        this.fileList = fileList;
        this.uploadForm.uploadFile = file.name;
      } else {
        this.fileList = [];
        this.uploadForm.uploadFile = '';
      }
      // this.uploadForm.uploadFile = file.name
    },
    imgUploadSuccess: function imgUploadSuccess(res, file, fileList) {
      if (res.code != 200) {
        this.$message.error(res.msg);
      }
    },
    selectImg: function selectImg(file, fileList) {
      this.imgList = fileList;
      this.hideUploadBtn = fileList.length >= 5;
    },
    uploadShow: function uploadShow() {
      this.uploadVisible = true;
    },
    uploadFileFail: function uploadFileFail(res, file, fileList) {
      this.$message.error('上传失败请重试');
      this.mloading = false;
    },
    // add() {
    //   this.$refs.insertMain.show(1, this.billType)
    // },
    // 以生产任务出库
    doUpdate: function doUpdate() {
      this.$refs.insertMain.show(1, this.billType);

      // this.$refs.insertMain.upDateShow()
    },
    // 以生产批次号
    doUpdate2: function doUpdate2() {
      this.$refs.insertbatch.show(1, this.billType);
      // this.$refs.insertMain.upDateShow()
    },
    // 添加
    addInsertFun: function addInsertFun() {
      this.$refs.addInsert.show(1, this.billType);
    },
    // 修改
    amendInsertFun: function amendInsertFun(row) {
      this.$refs.amendInsert.upDateShow(row);
      // this.$refs.addInsert.show(1, this.billType)
    },
    goApart: function goApart(row) {
      this.billNo = row.billNo;
      this.apartShow = true;
    },
    apartChange: function apartChange() {
      this.apartClose();
      this.getList();
    },
    apartClose: function apartClose() {
      this.billNo = '';
      this.apartShow = false;
    },
    goPrint: function goPrint(row) {
      this.authId('printbtn', this.$route.meta.authList);
      this.billNo = row.billNo;
      this.printShow = true;
    },
    printClose: function printClose() {
      this.billNo = '';
      this.printShow = false;
      this.authId('searchbtn', this.$route.meta.authList);
    },
    // 获取审核详情
    getflow: function getflow(row, qx) {
      console.log(row);
      this.authId('detailbtn', this.$route.meta.authList);
      this.$refs.flowMain.show(row, qx);
    },
    // 导出功能
    download: function download() {
      var _this3 = this;
      this.authId('exportbtn', this.$route.meta.authList);
      this.downLoading = true;
      var data = JSON.parse(JSON.stringify(this.tradebill));
      (0, _lithService.downUserRecord)(data).then(function (res) {
        _this3.authId('searchbtn', _this3.$route.meta.authList);
        if (res.status != 200) {
          _this3.$message.error(res.data.msg);
        } else {
          var blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel'
          });
          var downloadElement = document.createElement('a');
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = '成品列表.xls';
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
        _this3.downLoading = false;
      }).catch(function () {
        _this3.authId('searchbtn', _this3.$route.meta.authList);
        _this3.downLoading = false;
      });
    }
  }
};
exports.default = _default;