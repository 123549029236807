"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _channel = require("@/api/basic/channel");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { number } from 'echarts/lib/export'
var _default = {
  name: 'TablePackset',
  components: {
    Pagination: _Pagination.default
  },
  filters: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      selLoading: false,
      listLoading: false,
      tableKey: '111',
      tabHeight: '100%',
      searchForm: {
        pageNo: 1,
        pageSize: 20,
        keyword: '',
        // groupCode: '',
        // groupName: '',
        channelCode: '',
        channelName: ''
      },
      tableData: [],
      total: 0
    };
  },
  watch: {},
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var that = this;
      that.listLoading = true;
      var submitData = (0, _utils.filterKeyNull)(that.searchForm);
      submitData.channelCode = that.searchForm.groupCode;
      submitData.channelName = that.searchForm.groupName;
      delete submitData.productName;
      delete submitData.groupCode;
      delete submitData.groupName;
      (0, _channel.grouplist)(submitData).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.total = parseInt(response.data.data.total);
            that.tableData = response.data.data.records;
          } else {
            that.total = 0;
            that.tableData = [];
          }
          that.listLoading = false;
        }
      }).catch(function (res) {
        that.listLoading = false;
      });
    },
    resetForm: function resetForm(formName) {
      this.searchForm = {
        pageNo: 1,
        pageSize: 20,
        keyword: '',
        groupCode: '',
        groupName: ''
        // channelKeyword: ''
        // classType: ''
      };

      this.getList();
    },
    selCancel: function selCancel() {
      this.resetForm();
      this.$emit('close');
    },
    submit: function submit(row) {
      this.$emit('change', row);
      this.$emit('close');
    }
  }
};
exports.default = _default;