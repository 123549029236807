"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _order = require("@/api/order");
var _dictionary = require("@/api/systems/dictionary");
var _companySelectDialog = _interopRequireDefault(require("@/components/companySelectDialog"));
var _productDialog = _interopRequireDefault(require("@/components/productDialog"));
var _mzDialog = _interopRequireDefault(require("@/components/mzDialog"));
var _tgSelectDialog = _interopRequireDefault(require("@/components/tgSelectDialog"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var TypeDataComs = {
  orderStatus: []
};
var _default = {
  name: 'OrderCentersaleorderCcupdate',
  components: {
    companySelectDialog: _companySelectDialog.default,
    productDialog: _productDialog.default,
    tgSelectDialog: _tgSelectDialog.default,
    mzDialog: _mzDialog.default
  },
  filters: {
    orderType: function orderType(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          1: '买赠订单',
          2: '酒券订单',
          3: '团购订单',
          4: '费用酒订单',
          5: '样品酒订单'
        };
        return statusMap[value];
      }
    },
    typeClass: function typeClass(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          0: 'success',
          1: 'success',
          2: 'success',
          3: 'success',
          4: 'success',
          5: 'danger',
          6: 'danger',
          7: 'warning',
          8: 'primary',
          9: 'primary'
        };
        return statusMap[value];
      }
    },
    orderStatusText: function orderStatusText(value) {
      var name = '';
      TypeDataComs.orderStatus.forEach(function (c) {
        if (c.figure == value) {
          name = c.name;
          return;
        }
      });
      return name;
    }
  },
  data: function data() {
    return {
      // 销售单位
      saleOrgName: '',
      // 采购单位名称
      purchaseOrgName: '',
      // 收货单位名称
      receiveOrgName: '',
      // 发货单位名称
      sendOrgName: '',
      selProIdx: '',
      orderStatus: [],
      proType: '',
      listIndex: '',
      totalZp: 0.0,
      placeOrderDateEdit: false,
      remarkEdit: false,
      typeEdit: false,
      cardPadding: {
        padding: '0 0 20px'
      },
      btncardPadding: {
        padding: '0'
      },
      loading: false,
      proSelectList: [],
      productList: [],
      zpList: [],
      companyShow: false,
      tgShow: false,
      mzShow: false,
      productShow: false,
      addData: {
        orderNo: '',
        orderProductAOS: [],
        orderSource: 1,
        orderType: '',
        purchaseOrgId: '',
        receiveAddress: '',
        receiveOrgId: '',
        receivePhone: '',
        receiveUser: '',
        remark: '',
        saleOrgId: '',
        sendOrgId: '',
        tradeDate: '',
        tradeType: 2
      }
    };
  },
  computed: {
    totalMoney: {
      get: function get() {
        var totalSumAll = 0;
        this.productList.forEach(function (item) {
          if (!isNaN(item.totalPrice)) {
            totalSumAll += Number(item.totalPrice);
          }
        });
        totalSumAll = totalSumAll.toFixed(2);
        if (isNaN(totalSumAll)) {
          return 0;
        }
        return totalSumAll;
      },
      set: function set(val) {
        this.totalMoney = val;
      }
    },
    totalInUse: {
      get: function get() {
        var totalSumAll = 0;
        this.zpList.forEach(function (item) {
          console.log(item, 'item');
          if (!isNaN(item.wineTotalPrice)) {
            totalSumAll += Number(item.wineTotalPrice);
            console.log(totalSumAll, 'totalSumAll');
          }
        });
        totalSumAll = totalSumAll.toFixed(2);
        if (isNaN(totalSumAll)) {
          return 0;
        }
        return totalSumAll;
      },
      set: function set(val) {
        this.totalInUse = val;
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    if (this.$route.query.id) {
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: 'CCXSDDZT'
      }).then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this.orderStatus = res.data.data;
            TypeDataComs['orderStatus'] = res.data.data;
          } else {
            _this.orderStatus = [];
            TypeDataComs['orderStatus'] = [];
          }
        }
      }).then(function () {
        _this.getDetailData(_this.$route.query.id);
      });
    } else {
      this.$router.replace({
        name: 'orderCentersaleorder-ccindex'
      });
    }
  },
  methods: {
    getDirSel: function getDirSel(code, list) {
      var _this2 = this;
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: code
      }).then(function (res) {
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this2[list] = res.data.data;
            TypeDataComs[list] = res.data.data;
          } else {
            _this2[list] = [];
            TypeDataComs[list] = [];
          }
        }
      });
    },
    // 获取详情数据
    getDetailData: function getDetailData(id) {
      var _this3 = this;
      this.loading = true;
      (0, _order.queryByOrderNo)(id).then(function (res) {
        if (res.data.code == 200) {
          var data = res.data.data;
          var proArr = [];
          var zpArr = [];
          _this3.addData = res.data.data;
          data.orderProductDetailVOS.forEach(function (item) {
            var obj = {
              pid: item.pid,
              parentDetailId: item.parentDetailId,
              pId: item.productId,
              productCode: item.productCode,
              productName: item.productName,
              productModel: item.productModel,
              packScaleExpression: item.packScaleExpression,
              chestNum: item.chestNum,
              bottleNum: item.bottleNum,
              totalBottleNum: item.totalBottleNum,
              productType: item.productType,
              activityName: item.activityName,
              activityId: item.activityId
            };
            if (item.productType == 3) {
              obj.winePrice = item.productPrice;
              obj.wineTotalPrice = item.productAmount || 0;
              zpArr.push(obj);
            } else {
              proArr.push(obj);
            }
          });
          _this3.productList = (0, _utils.transTreeData)(proArr, 'pid', 'parentDetailId', 'giftProductAOS');
          _this3.zpList = zpArr;
          _this3.saleOrgName = data.saleOrgName;
          _this3.purchaseOrgName = data.purchaseOrgName;
          _this3.receiveOrgName = data.receiveOrgName;
          _this3.sendOrgName = data.sendOrgName;
          if (data.orderType == 2) {
            _this3.getWine();
          }
        } else {
          _this3.$message.error(res.data.msg);
        }
        _this3.loading = false;
      }).catch(function () {
        _this3.loading = false;
      });
    },
    orderTypeClose: function orderTypeClose(val) {
      if (!val) this.typeEdit = false;
    },
    pTypeChange: function pTypeChange(val) {
      if (val == 2) {
        this.activeTotal = 3;
      } else {
        this.activeTotal = 2;
      }
      if (this.productList.length) {
        this.productList.forEach(function (item) {
          item.giftProductAOS = [];
          item.activityId = '';
          item.activityType = '';
        });
      }
      this.zpList = [];
      this.addData.purchaseOrgId = '';
      this.purchaseOrgName = '';
      this.addData.receiveOrgId = '';
      this.receiveOrgName = '';
      this.addData.receiveUser = '';
      this.addData.receivePhone = '';
      this.addData.receiveAddress = '';
      // this.getFL()/
      this.getWine();
      this.resetList();
      // this.$nextTick(() => {
      //   this.$refs.addForm.clearValidate()
      // })
    },
    setTg: function setTg(val) {
      this.addData.purchaseOrgId = val.pId;
      this.addData.receiveOrgId = val.pId;
      this.receiveOrgName = val.groupArchivesName;
      this.purchaseOrgName = val.groupArchivesName;
      this.addData.receiveUser = val.contacts || '';
      this.addData.receivePhone = val.phone || '';
      var provinceName = val.provinceName || '';
      var cityName = val.cityName || '';
      var countyName = val.countyName || '';
      var detailedAddress = val.detailedAddress || '';
      this.addData.receiveAddress = provinceName + cityName + countyName + detailedAddress;
    },
    tgClose: function tgClose() {
      this.inUseName = '';
      this.tgShow = false;
    },
    /**
     * 单位选择
     * @param {*} name 需要回传修改的名字
     * @param {*} companyType 单位类型 1 收获单位 2发货单位 0采购单位
     * @param {*} orgId 采购单位ID 获取收货单位使用
     * @param {*} inOutType 单据类型 1销售出库 2调拨出库 3其他出库 4生产入库 5调拨入库 6采购入库 7其他入库 11 采购订单 12销售订单
     *
     */
    openCompany: function openCompany(name, companyType, inOutType, orgId) {
      if (name == 'purchaseComName' && !this.addData.orderType) {
        this.$message.error('请先选择订单类型');
        return;
      }
      this.orgId = orgId || '';
      this.inUseName = name;
      this.companyType = companyType;
      this.inOutType = inOutType;
      if (name == 'purchaseOrgName' && this.addData.orderType == 3) {
        this.tgShow = true;
      } else {
        this.companyShow = true;
      }
    },
    setCompany: function setCompany(val) {
      this[this.inUseName] = val.companyName;
      if (this.productList.length) {
        this.productList.forEach(function (item) {
          item.rebatePolicyId = '';
          item.rebatePolicyName = '';
          item.orderGiftDetailDTOS = [];
        });
      }
      switch (this.inUseName) {
        case 'saleOrgName':
          this.addData.saleOrgId = val.companyId;
          // this.getFL({})
          this.getWine();
          this.resetList();
          break;
        case 'sendOrgName':
          this.addData.sendOrgId = val.companyId;
          break;
        case 'purchaseOrgName':
          this.addData.purchaseOrgId = val.companyId;
          this.addData.receiveOrgId = val.companyId;
          this.receiveOrgName = val.companyName;
          // this.getFL({})
          this.getWine();
          this.resetList();
          this.getReceiveInfo(val);
          break;
      }
    },
    // 匹配收货单位信息
    getReceiveInfo: function getReceiveInfo(val) {
      var _this4 = this;
      this.receiveLoading = true;
      (0, _order.getCompanyById)({
        pId: val.companyId
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          var that = _this4;
          var datas = response.data.data;
          _this4.addData.receiveUser = datas.contacts || '';
          _this4.addData.receivePhone = datas.telephone || datas.phone || '';
          var provinceName = datas.provinceName || '';
          var cityName = datas.cityName || '';
          var countyName = datas.countyName || '';
          var detailedAddress = datas.detailedAddress || '';
          _this4.addData.receiveAddress = provinceName + cityName + countyName + detailedAddress;
        }
        _this4.receiveLoading = false;
      }).catch(function () {
        _this4.receiveLoading = false;
      });
    },
    companyClose: function companyClose() {
      this.inUseName = '';
      this.companyType = '';
      this.orgId = '';
      this.isEnable = '';
      this.companycanChange = true;
      this.companyShow = false;
    },
    resetReceive: function resetReceive() {
      this.receiveComName = '';
      this.addData.receiveComId = '';
      this.addData.receiveContacts = '';
      this.addData.receivePhone = '';
      this.addData.receiveAddress = '';
    },
    changeChestNum: function changeChestNum(row, idx) {
      var value = row.packScaleExpression;
      var arr = value.split('*');
      var le = arr.length - 1;
      row.totalBottleNum = (row.chestNum || 0) * arr[le] + (row.bottleNum || 0);
      if (!(row.activityId == '' || row.activityId == null || row.activityId == undefined)) {
        this.selProIdx = idx;
        this.getFL(row);
      }
    },
    getFL: function getFL(row) {
      var _this5 = this;
      (0, _order.matchChannelBuyGift)({
        buyNum: row.totalBottleNum,
        buyerId: this.addData.purchaseOrgId,
        current: 1,
        productId: row.pId,
        schemeId: row.activityId,
        size: 10
      }).then(function (res) {
        res = res.data;
        if (res.code == 200) {
          if (res.data.records && res.data.records.length) {
            _this5.setMz(res.data.records[0]);
          } else {
            _this5.productList[_this5.selProIdx].giftProductAOS = [];
          }
        } else {
          _this5.$message.closeAll();
          _this5.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
      });
    },
    openMz: function openMz(row) {
      if (!this.addData.purchaseOrgId) {
        this.$message.error('请先选择采购单位');
        return;
      }
      this.selProIdx = row;
      this.mzShow = true;
    },
    setMz: function setMz(val) {
      var _this6 = this;
      if (!val) return;
      this.productList[this.selProIdx].activityId = val.rebateId;
      this.productList[this.selProIdx].activityName = val.schemeName;
      if (val.detail && val.detail.length) {
        var aId = [];
        var aPro = [];
        val.detail.forEach(function (item) {
          var idx = aId.indexOf(item.product.pId);
          if (idx == -1) {
            var obj = {
              bottleNum: item.giftCalculatedNum % item.product.packNum,
              chestNum: Math.floor(item.giftCalculatedNum / item.product.packNum),
              productType: 2,
              totalBottleNum: item.giftCalculatedNum * 1,
              // orderNo: this.addData.orderNo,
              pId: item.product.pId,
              productCode: item.product.productCode,
              productName: item.product.productName,
              productModel: item.product.productModel,
              packScaleExpression: item.product.packScaleDesc
            };
            aId.push(item.product.pId);
            aPro.push(obj);
          } else {
            aPro[idx].totalBottleNum += item.giftCalculatedNum * 1;
            aPro[idx].bottleNum = aPro[idx].totalBottleNum % item.product.packNum;
            aPro[idx].chestNum = Math.floor(aPro[idx].totalBottleNum / item.product.packNum);
          }
        });
        this.productList[this.selProIdx].giftProductAOS = aPro;
      } else {
        this.productList[this.selProIdx].giftProductAOS = [];
      }
      this.productList = JSON.parse(JSON.stringify(this.productList));
      this.$nextTick(function () {
        _this6.toogleExpand(_this6.selProIdx);
      });
      this.mzClose();
    },
    mzClose: function mzClose() {
      // this.selProIdx = ''
      this.mzShow = false;
    },
    openPro: function openPro(val, index) {
      this.proType = val;
      this.listIndex = index;
      if (!this.addData.saleOrgId || !this.addData.purchaseOrgId || !this.addData.receiveOrgId) {
        this.$message.error('请先选择采购单位、收货单位、供货单位');
        return;
      }
      switch (val) {
        case 1:
          this.proSelectList = this.productList;
          break;
        case 2:
          this.proSelectList = this.zpList;
          break;
        case 3:
          this.proSelectList = this.productList[index].giftProductAOS || [];
          break;
      }
      this.productShow = true;
    },
    setPro: function setPro(val) {
      var _this7 = this;
      console.log(val, 'val');
      var list, arr, productType;
      if (this.proType == 1) {
        arr = JSON.parse(JSON.stringify(this.productList));
        list = 'productList';
        productType = 1;
      } else if (this.proType == 3) {
        arr = this.productList[this.listIndex].giftProductAOS ? JSON.parse(JSON.stringify(this.productList[this.listIndex].giftProductAOS)) : [];
        productType = 2;
        if (val.length) {
          if (arr.length == 0) {
            var obj = {
              chestNum: 0,
              bottleNum: 0,
              totalBottleNum: 0,
              productType: productType
            };
            val.forEach(function (item) {
              delete item.pid;
              item = Object.assign(item, obj);
            });
          } else {
            val.forEach(function (item) {
              item.chestNum = 0;
              item.bottleNum = 0;
              item.totalBottleNum = 0;
              item.productType = productType;
              arr.forEach(function (el) {
                if (el.pId == item.pId) {
                  item.pid = el.pid;
                  item.chestNum = el.chestNum;
                  item.bottleNum = el.bottleNum;
                  item.totalBottleNum = el.totalBottleNum;
                }
              });
            });
          }
          this.productList[this.listIndex].activityId = '';
          this.productList[this.listIndex].giftProductAOS = val;
          this.productList = JSON.parse(JSON.stringify(this.productList));
          this.$nextTick(function () {
            _this7.toogleExpand(_this7.listIndex);
          });
        } else {
          this.productList[this.listIndex].giftProductAOS = [];
        }
        return;
      } else {
        arr = JSON.parse(JSON.stringify(this.zpList));
        list = 'zpList';
        productType = 3;
      }
      if (val.length) {
        if (this[list].length == 0) {
          val.forEach(function (item) {
            delete item.pid;
            item = Object.assign(item, {
              chestNum: '',
              bottleNum: '',
              totalBottleNum: 0,
              productType: productType
            });
            item.winePrice = item.rebatePrice;
            item.wineTotalPrice = '';
          });
        } else {
          val.forEach(function (item) {
            console.log(item, 't1');
            item.chestNum = 0;
            item.bottleNum = 0;
            item.totalBottleNum = 0;
            item.productType = productType;
            item.winePrice = item.rebatePrice;
            item.wineTotalPrice = '';
            arr.forEach(function (el) {
              if (el.pId == item.pId) {
                item.pid = el.pid;
                item.chestNum = el.chestNum;
                item.bottleNum = el.bottleNum;
                item.totalBottleNum = el.totalBottleNum;
                item.winePrice = el.winePrice;
                item.wineTotalPrice = el.wineTotalPrice;
              }
            });
            console.log(item, 'ti');
          });
        }
        this[list] = JSON.parse(JSON.stringify(val));
        if (this.addData.receiveComType == 3 && this.proType == 1) {
          this.getFL(1);
        }
      } else {
        this[list] = [];
      }
    },
    proClose: function proClose() {
      this.proSelectList = '';
      this.proType = '';
      // this.listIndex = ''
      this.productShow = false;
    },
    clearZp: function clearZp(idx) {
      this.productList[idx].activityId = '';
      this.productList[idx].activityName = '';
      this.productList[idx].activityType = '';
      this.productList[idx].giftProductAOS = [];
      var $table = this.$refs.addTable;
      for (var i = 0; i < this.productList.length; i++) {
        $table.toggleRowExpansion(this.productList[i], false);
      }
    },
    toogleExpand: function toogleExpand(idx) {
      var $table = this.$refs.addTable;
      for (var i = 0; i < this.productList.length; i++) {
        if (idx != i) {
          $table.toggleRowExpansion(this.productList[i], false);
        }
      }
      // this.setZPnum(this.productList[idx])
      $table.toggleRowExpansion(this.productList[idx]);
    },
    getWine: function getWine() {
      var _this8 = this;
      if (this.addData.receiveOrgId && this.addData.saleOrgId) {
        (0, _order.buyerMoneyWine)({
          harvestId: this.addData.receiveOrgId,
          harvestType: 1,
          supplierId: this.addData.saleOrgId,
          supplierType: 0
        }).then(function (res) {
          if (res.data.code == 200) {
            _this8.totalZp = res.data.data;
          } else {
            _this8.$message.error(res.data.msg);
          }
        });
      }
    },
    setZPnum: function setZPnum(row) {
      if (row.orderGiftDetailDTOS.length) {
        row.orderGiftDetailDTOS.forEach(function (item) {
          if (row.bottleNum) {
            item.bottleNum = Math.floor(row.bottleNum / item.buyNum) * item.giftNum;
            item.totalPrice = item.bottleNum * item.prodPrice;
          } else {
            item.bottleNum = 0;
            item.totalPrice = 0;
          }
        });
      } else {
        this.resetList();
      }
      this.resetZpList();
      row.orderGiftDetailDTOS = JSON.parse(JSON.stringify(row.orderGiftDetailDTOS));
    },
    totalWine: function totalWine(row) {
      console.log(row, 'row');
      var num = row.totalBottleNum * 1;
      num = num || 0;
      var prize = row.winePrice || 0;
      var total = num * (prize * 1);
      row.wineTotalPrice = total.toFixed(2);
      return row.wineTotalPrice;
    },
    resetList: function resetList() {
      this.productList = JSON.parse(JSON.stringify(this.productList));
    },
    resetZpList: function resetZpList() {
      this.zpList = JSON.parse(JSON.stringify(this.zpList));
    },
    // 删除产品明细列表
    DoSc: function DoSc(row) {
      var _this9 = this;
      var that = this;
      that.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this9.productList.splice(_this9.productList.indexOf(row), 1);
      });
    },
    // 删除赠品明细列表
    DelZP: function DelZP(row, idx) {
      var _this10 = this;
      console.log(row, idx);
      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        console.log(_this10.productList[idx]);
        _this10.productList[idx].giftProductAOS.splice(_this10.productList[idx].giftProductAOS.indexOf(row), 1);
        if (_this10.productList[idx].giftProductAOS.length == 0) {
          _this10.toogleExpand(idx);
        }
      });
    },
    // 删除酒券明细列表
    DoScZp: function DoScZp(row) {
      var _this11 = this;
      var that = this;
      that.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this11.zpList.splice(_this11.zpList.indexOf(row), 1);
      });
    },
    mixNumP: function mixNumP(row) {
      var arr = row.packScaleExpression.split('*');
      return arr[arr.length - 1] * 1 - 1;
    },
    handleClose: function handleClose() {
      this.$router.replace({
        name: 'orderCentersaleorder-ccindex'
      });
    },
    submitForm: function submitForm(val) {
      var _this12 = this;
      var that = this;
      that.addData.totalMoney = that.totalMoney;
      if (!that.addData.orderType) {
        that.$message.error('请选择订单类型');
        return;
      }
      if (!that.addData.sendOrgId) {
        that.$message.error('请选择发货单位');
        return;
      }
      if (!that.productList.length) {
        that.$message.error('请选择产品');
        return;
      }
      if (this.addData.saleOrgId != this.addData.sendOrgId) {
        this.$confirm('您所编辑的订单为：代发货订单，请确认是否进行代发货？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          _this12.subFun();
        }).catch(function () {});
        return;
      }
      this.subFun();
    },
    subFun: function subFun() {
      var _this13 = this;
      var that = this;
      var plist = JSON.parse(JSON.stringify([].concat((0, _toConsumableArray2.default)(that.productList), (0, _toConsumableArray2.default)(that.zpList))));
      var oldList = JSON.parse(JSON.stringify(that.addData.orderProductDetailVOS));
      var newList = [];
      if (plist.length > 0) {
        for (var i = 0; i < plist.length; i++) {
          if (!plist[i].totalBottleNum || plist[i].totalBottleNum == 0) {
            if (i > that.productList.length - 1) {
              that.$message.error('在酒券兑换列表第' + (i - that.productList.length + 1) + '行请输入销售数量');
            } else {
              that.$message.error('在产品列表第' + (i + 1) + '行请输入销售数量');
            }
            return;
          }
          var obj = {
            pid: plist[i].pid,
            totalBottleNum: plist[i].totalBottleNum,
            bottleNum: plist[i].bottleNum,
            chestNum: plist[i].chestNum,
            orderNo: that.addData.orderNo,
            activityId: plist[i].activityId || '',
            activityType: that.addData.orderType == 3 ? '' : that.addData.orderType,
            productId: plist[i].pId,
            giftProductAOS: plist[i].giftProductAOS,
            productType: plist[i].productType
          };
          if (this.addData.orderType == 2) {
            obj.productAmount = plist[i].wineTotalPrice;
            obj.productPrice = plist[i].winePrice;
          }
          var orderGiftDetailDTOS = [];
          if (plist[i].giftProductAOS) {
            var le = plist[i].giftProductAOS.length;
            if (le) {
              for (var j = 0; j < le; j++) {
                var oGif = {
                  totalBottleNum: plist[i].giftProductAOS[j].totalBottleNum,
                  bottleNum: plist[i].giftProductAOS[j].bottleNum,
                  chestNum: plist[i].giftProductAOS[j].chestNum,
                  orderNo: that.addData.orderNo,
                  productId: plist[i].giftProductAOS[j].pId,
                  productType: 2
                };
                if (plist[i].giftProductAOS[j].totalBottleNum > 0) {
                  // orderGiftDetailDTOS.push(plist[i].giftProductAOS[j])
                  orderGiftDetailDTOS.push(oGif);
                }
              }
            }
            obj.giftProductAOS = orderGiftDetailDTOS;
          }
          oldList.forEach(function (item) {
            if (item.pid == obj.pid) {
              obj = Object.assign(item, obj);
            }
          });
          newList.push(obj);
        }
      }
      // that.addData.orderDetailDTOS = newList

      that.loading = true;
      that.dsate = true;
      var subData = {
        orderNo: that.addData.orderNo,
        orderProductAOS: newList,
        orderSource: 1,
        orderType: that.addData.orderType,
        purchaseOrgId: that.addData.purchaseOrgId,
        sendOrgId: that.addData.sendOrgId,
        receiveAddress: that.addData.receiveAddress,
        receiveOrgId: that.addData.receiveOrgId,
        receivePhone: that.addData.receivePhone,
        receiveUser: that.addData.receiveUser,
        remark: that.addData.remark,
        saleOrgId: that.addData.saleOrgId,
        tradeDate: that.addData.tradeDate.split(' ')[0],
        tradeType: 2
      };
      (0, _order.modSaleOrder)(subData).then(function (res) {
        if (res.data.code == 200) {
          that.$message({
            type: 'success',
            message: '操作成功'
          });
          _this13.handleClose();
        } else {
          that.$message.error(res.data.msg);
        }
        that.loading = false;
        that.dsate = false;
      }).catch(function () {
        that.loading = false;
        that.dsate = false;
      });
    }
  }
};
exports.default = _default;