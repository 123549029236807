var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        { staticClass: "page-section" },
        [
          _c(
            "el-form",
            {
              ref: "formInline",
              staticClass: "search-condition",
              attrs: {
                model: _vm.formInline,
                "status-icon": "",
                "label-position": _vm.formConfig.labelPosition,
                "label-width": "100px",
              },
            },
            [
              _c(
                "div",
                { staticClass: "cols" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "roleName", label: "角色名称:" } },
                            [
                              _c("el-input", {
                                staticClass: "filter-item",
                                staticStyle: { width: "100%" },
                                attrs: { clearable: "", placeholder: "请输入" },
                                model: {
                                  value: _vm.formInline.roleName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formInline,
                                      "roleName",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "formInline.roleName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "isEnable", label: "状态:" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择",
                                  },
                                  model: {
                                    value: _vm.formInline.isEnable,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formInline, "isEnable", $$v)
                                    },
                                    expression: "formInline.isEnable",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "全部", value: "" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "禁用", value: "0" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "启用", value: "1" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.formConfig.btnFollow
                        ? _c(
                            "el-col",
                            {
                              staticClass: "head-btn-group",
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { "label-width": "0" } },
                                [
                                  _c(
                                    "div",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [_vm._v(" ")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.searchBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.searchBtn.icon,
                                      },
                                      on: { click: _vm.onSearch },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.btnConfig.searchBtn.text)
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.resetBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.resetBtn.icon,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onReset("formInline")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.btnConfig.resetBtn.text)
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("el-divider", { staticClass: "btnDivider" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "phead" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: _vm.btnConfig.addBtn.type,
                            size: _vm.btnConfig.size,
                            icon: _vm.btnConfig.addBtn.icon,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.creatBtn("add", "")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.btnConfig.addBtn.text))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "table",
              staticStyle: { width: "100%" },
              attrs: {
                height: _vm.tabHeight,
                data: _vm.tableData,
                border: _vm.tableConfig.border,
                stripe: _vm.tableConfig.stripe,
                fit: "",
                "highlight-current-row": "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "roleName",
                  label: "角色名称",
                  align: _vm.tableConfig.align,
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "memberCount",
                  label: "用户数量",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "isEnable",
                  label: "状态",
                  "min-width": "100",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.isEnable == 1
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v("启用"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        row.isEnable == 0
                          ? _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v("禁用"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createName",
                  label: "创建人",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "创建时间",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                  "min-width": "140",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "updaterName",
                  label: "更新人",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "updateTime",
                  label: "更新时间",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                  "min-width": "140",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "200",
                  fixed: "right",
                  align: _vm.tableConfig.align,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "a-btn",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.creatBtn("look", row)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                        _vm._v(" "),
                        row.roleName != "消费者" && row.roleName != "门店用户"
                          ? _c(
                              "el-button",
                              {
                                staticClass: "a-btn",
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.creatBtn("edit", row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "a-btn",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.open(row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.isEnable == 1 ? "禁用" : "启用"))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: _vm.title,
            visible: _vm.dialogFormVisible,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "roleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.roleForm,
                rules: _vm.rules,
                "label-width": "120px",
                "label-suffix": ":",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "营销角色编码", prop: "roleCode" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: { disabled: _vm.lookdisabled, maxlength: "18" },
                    model: {
                      value: _vm.roleForm.roleCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.roleForm, "roleCode", $$v)
                      },
                      expression: "roleForm.roleCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "营销角色", prop: "roleName" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: { disabled: _vm.lookdisabled, maxlength: "15" },
                    model: {
                      value: _vm.roleForm.roleName,
                      callback: function ($$v) {
                        _vm.$set(_vm.roleForm, "roleName", $$v)
                      },
                      expression: "roleForm.roleName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "状态", prop: "isEnable" } },
                [
                  _c("el-switch", {
                    attrs: {
                      disabled: _vm.lookdisabled,
                      "active-color": "#13ce66",
                      "active-value": 1,
                      "inactive-value": 0,
                      "inactive-color": "#ff4949",
                      "active-text": "启用",
                      "inactive-text": "禁用",
                    },
                    model: {
                      value: _vm.roleForm.isEnable,
                      callback: function ($$v) {
                        _vm.$set(_vm.roleForm, "isEnable", $$v)
                      },
                      expression: "roleForm.isEnable",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remark" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: { disabled: _vm.lookdisabled, type: "textarea" },
                    model: {
                      value: _vm.roleForm.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.roleForm, "remark", $$v)
                      },
                      expression: "roleForm.remark",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "注册信息填写", prop: "registerInfo" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      staticStyle: { "padding-top": "8px" },
                      on: { change: _vm.checkboxFun },
                      model: {
                        value: _vm.roleForm.registerInfo,
                        callback: function ($$v) {
                          _vm.$set(_vm.roleForm, "registerInfo", $$v)
                        },
                        expression: "roleForm.registerInfo",
                      },
                    },
                    _vm._l(_vm.infoList, function (item, index) {
                      return _c(
                        "el-checkbox",
                        {
                          key: index,
                          attrs: {
                            disabled:
                              item.paramName === "姓名" ||
                              item.paramName === "手机号" ||
                              _vm.lookdisabled,
                            label: item,
                          },
                        },
                        [_vm._v(_vm._s(item.paramName))]
                      )
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-top": "20px" },
                      attrs: {
                        type: "success",
                        size: "mini",
                        disabled: _vm.lookdisabled,
                      },
                      on: {
                        click: function ($event) {
                          _vm.addInfo = true
                        },
                      },
                    },
                    [_vm._v("新增")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "注册审核", prop: "registerEnable" } },
                [
                  _c("el-switch", {
                    attrs: {
                      disabled: _vm.lookdisabled,
                      "active-color": "#13ce66",
                      "active-value": 1,
                      "inactive-value": 0,
                      "active-text": "启用",
                      "inactive-text": "禁用",
                      "inactive-color": "#ff4949",
                    },
                    model: {
                      value: _vm.roleForm.registerEnable,
                      callback: function ($$v) {
                        _vm.$set(_vm.roleForm, "registerEnable", $$v)
                      },
                      expression: "roleForm.registerEnable",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              !_vm.lookdisabled
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        loading: _vm.btnLoading,
                        disabled: _vm.btnLoading,
                      },
                      on: { click: _vm.submitFun },
                    },
                    [_vm._v("确 定")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新增注册信息",
            visible: _vm.addInfo,
            width: "400px",
            top: "30vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addInfo = $event
            },
            close: _vm.handleClose2,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "infoItem",
              attrs: {
                model: _vm.infoItem,
                "label-position": "center",
                "label-suffix": ":",
                size: "medium",
                "label-width": "100px",
                rules: _vm.infoItemR,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "字段名称", prop: "paramName" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.infoItem.paramName,
                      callback: function ($$v) {
                        _vm.$set(_vm.infoItem, "paramName", $$v)
                      },
                      expression: "infoItem.paramName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "填写类型", prop: "paramType" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.infoItem.paramType,
                        callback: function ($$v) {
                          _vm.$set(_vm.infoItem, "paramType", $$v)
                        },
                        expression: "infoItem.paramType",
                      },
                    },
                    _vm._l(
                      [
                        {
                          value: "c",
                          label: "字符",
                        },
                        {
                          value: "n",
                          label: "数字",
                        },
                        {
                          value: "d",
                          label: "日期",
                        },
                      ],
                      function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }
                    ),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: { click: _vm.handleClose2 },
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.addInfoFun },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }