"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _activityFlow = require("@/api/marketingManage/activityFlow");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { number } from 'echarts/lib/export'
var _default2 = {
  name: "TablePackset",
  components: {
    Pagination: _Pagination.default
  },
  filters: {},
  props: {
    selectedData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      selLoading: false,
      listLoading: false,
      tableKey: "111",
      tabHeight: "100%",
      classList: [],
      gradeList: [],
      searchForm: {
        pageNo: 1,
        pageSize: 10,
        schemeCode: "",
        schemeName: "",
        creatorAccount: "",
        createTime: "",
        status: 1
      },
      tableData: [],
      multipleSelection: [],
      total: 0,
      selection: [],
      row: {}
    };
  },
  watch: {},
  mounted: function mounted() {
    this.setsel();
  },
  methods: {
    setsel: function setsel() {
      var _this = this;
      this.getList();
      this.selLoading = true;
      this.$nextTick(function () {
        _this.selectedData.forEach(function (row) {
          _this.$refs.multipleTable.toggleRowSelection(row, true); // 回显
        });

        setTimeout(function () {
          _this.$refs.multipleTable.toggleRowSelection(_this.tableData[0]);
          _this.$refs.multipleTable.toggleRowSelection(_this.tableData[0]);
          _this.selLoading = false;
        }, 500);
      });
    },
    clear: function clear() {
      this.$refs.multipleTable.clearSelection();
    },
    getList: function getList() {
      var that = this;
      that.listLoading = true;
      var submitData = (0, _utils.filterKeyNull)(that.searchForm);
      (0, _activityFlow.getwayList)(submitData).then(function (response) {
        that.listLoading = false;
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.total = parseInt(response.data.data.total);
            that.tableData = response.data.data.records;
          } else {
            that.total = 0;
            that.tableData = [];
          }
        }
      }).catch(function (res) {
        that.listLoading = false;
      });
    },
    resetForm: function resetForm(formName) {
      this.searchForm = {
        pageNo: 1,
        pageSize: 10,
        schemeCode: "",
        schemeName: "",
        creatorAccount: "",
        createTime: "",
        status: 1
      };
      this.getList();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
      if (val.length > 1) {
        this.$refs.multipleTable.clearSelection();
        this.$refs.multipleTable.toggleRowSelection(val[val.length - 1], true);
      }
    },
    selCancel: function selCancel() {
      this.resetForm();
      this.clear();
      this.$emit("close");
    },
    submit: function submit() {
      this.$emit("close");
      this.$emit("changeS", {
        schemeCode: this.multipleSelection[0].schemeCode,
        schemeName: this.multipleSelection[0].schemeName
      });
    },
    tag1Close: function tag1Close(row) {
      this.$refs.multipleTable.toggleRowSelection(row, false);
    },
    parseTime: function parseTime(time) {
      var date = new Date(time);
      var year = date.getFullYear();
      var month = ('0' + (date.getMonth() + 1)).slice(-2);
      var day = ('0' + date.getDate()).slice(-2);
      return "".concat(year, "-").concat(month, "-").concat(day);
    }
  }
};
exports.default = _default2;