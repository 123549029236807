"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.audit = audit;
exports.getAuditDetail = getAuditDetail;
exports.getQueryAuthDetail = getQueryAuthDetail;
exports.getQueryAuthList = getQueryAuthList;
exports.postAuditList = postAuditList;
var _request = _interopRequireDefault(require("@/utils/request"));
var baseUrl1 = process.env.VUE_APP_VERSION1;
var baseUrl2 = process.env.VUE_APP_VERSION2;
// 终端注册认证列表
function getQueryAuthList(data) {
  return (0, _request.default)({
    url: baseUrl2 + '/zdConfig/authList/queryAuthList',
    method: 'post',
    data: data
  });
}
// 终端注册认证详情
function getQueryAuthDetail(params) {
  return (0, _request.default)({
    url: baseUrl2 + '/zdConfig/authList/queryAuthDetail',
    method: 'get',
    params: params
  });
}

// 终端注册审核列表
function postAuditList(data) {
  return (0, _request.default)({
    url: baseUrl1 + '/api-basic/terminalStoreAudit/auditList',
    method: 'post',
    data: data
  });
}
// 详情
function getAuditDetail(params) {
  return (0, _request.default)({
    url: baseUrl1 + '/api-basic/terminalStoreAudit/auditDetail',
    method: 'get',
    params: params
  });
}
// 审核终端
function audit(data) {
  return (0, _request.default)({
    url: baseUrl1 + '/api-basic/terminalStoreAudit/audit',
    method: 'post',
    data: data
  });
}