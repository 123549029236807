"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AddStrategy = AddStrategy;
exports.addPolicyGroup = addPolicyGroup;
exports.checkStrategyType = checkStrategyType;
exports.delPolicy = delPolicy;
exports.delPolicyGroup = delPolicyGroup;
exports.getList = getList;
exports.getPolicyGroupList = getPolicyGroupList;
exports.getstrategyList = getstrategyList;
exports.setPolicyGroup = setPolicyGroup;
var _request = _interopRequireDefault(require("@/utils/request"));
var base = '';

// 获取策略组内策略
function getList(params) {
  return (0, _request.default)({
    url: base + '/market-config/strategyGroup/groupGetStrategy',
    method: 'get',
    params: params
  });
}
// 获取策略组内策略
function getstrategyList(data) {
  return (0, _request.default)({
    url: base + '/market-config/strategyGroup/getStrategyListByType',
    method: 'post',
    data: data
  });
}
// 获取策略组列表
function getPolicyGroupList(data) {
  return (0, _request.default)({
    url: base + '/market-config/strategyGroup/getList',
    method: 'post',
    data: data
  });
}
// 保存策略组 新增/编辑
function addPolicyGroup(params) {
  return (0, _request.default)({
    url: base + '/market-config/strategyGroup/saveGroup',
    method: 'get',
    params: params
  });
}

// 设置默认策略组
function setPolicyGroup(params) {
  return (0, _request.default)({
    url: base + '/market-config/strategyGroup/setDefault',
    method: 'get',
    params: params
  });
}
// 删除策略组
function delPolicyGroup(params) {
  return (0, _request.default)({
    url: base + '/market-config/strategyGroup/groupDelete',
    method: 'get',
    params: params
  });
}
//  策略组内删除策略
function delPolicy(params) {
  return (0, _request.default)({
    url: base + '/market-config/strategyGroup/groupDelStrategy',
    method: 'get',
    params: params
  });
}

// 策略组内添加策略
function AddStrategy(params) {
  return (0, _request.default)({
    url: base + '/market-config/strategyGroup/groupAddStrategyNew',
    method: 'get',
    params: params
  });
}

// 检查策略组内是否已存在指定类型的策略
function checkStrategyType(params) {
  return (0, _request.default)({
    url: base + '/market-config/strategyGroup/checkStrategyType',
    method: 'get',
    params: params
  });
}