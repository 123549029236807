var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        "append-to-body": true,
        title: "选择产品",
        visible: _vm.proSelVisible,
        "before-close": _vm.selCancel,
        width: "1200px !important",
      },
      on: {
        "update:visible": function ($event) {
          _vm.proSelVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c(
            "el-row",
            { attrs: { type: "flex", gutter: 10 } },
            [
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "page-section leftSide" }, [
                  _c("div", { staticClass: "phead" }, [_vm._v("产品分类")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "filter-container" },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            clearable: "",
                            placeholder: "分类编码/名称",
                          },
                          model: {
                            value: _vm.filterText,
                            callback: function ($$v) {
                              _vm.filterText = $$v
                            },
                            expression: "filterText",
                          },
                        },
                        [
                          _c("el-button", {
                            staticStyle: { "background-color": "#4181FF" },
                            attrs: {
                              slot: "append",
                              type: "primary",
                              icon: "el-icon-search",
                            },
                            on: { click: _vm.handleFilter },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "tree-container" }, [
                        _c(
                          "div",
                          { staticClass: "trc" },
                          [
                            _c("el-tree", {
                              ref: "tree",
                              attrs: {
                                data: _vm.classList,
                                "highlight-current": true,
                                "filter-node-method": _vm.filterNode,
                                "default-expand-all": "",
                                "expand-on-click-node": false,
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var data = ref.data
                                    return _c(
                                      "div",
                                      {
                                        staticStyle: { width: "100%" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.scan(data)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "custom-tree-node",
                                            class:
                                              _vm.activeClass == data.id
                                                ? "active"
                                                : "",
                                            attrs: { index: data.id },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  width: "70%",
                                                  display: "inlin-block",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(data.productClassName)
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { staticClass: "page-section", attrs: { span: 13 } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "searchForm",
                      staticClass: "page-section search-condition",
                      attrs: { model: _vm.searchForm, "status-icon": "" },
                    },
                    [
                      _c("div", { staticClass: "phead" }, [
                        _c("img", {
                          attrs: { src: require("@/images/icon-1.jpg") },
                        }),
                        _vm._v("\n            数据筛选\n            "),
                        _c(
                          "div",
                          { staticClass: "head-btn-group" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  size: "mini",
                                  icon: "el-icon-search",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.searchForm.current = 1
                                    _vm.getList()
                                  },
                                },
                              },
                              [_vm._v("查询")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  icon: "el-icon-refresh",
                                },
                                on: { click: _vm.resetForm },
                              },
                              [_vm._v("重置")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "filter-container" },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 6, prop: "productCode" } },
                                [
                                  _c("label", { staticClass: "form-label" }, [
                                    _vm._v("产品编码"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.searchForm.productCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "productCode",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.productCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 6, prop: "productName" } },
                                [
                                  _c("label", { staticClass: "form-label" }, [
                                    _vm._v("产品名称"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.searchForm.productName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "productName",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.productName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 6, prop: "productBrandId" } },
                                [
                                  _c("label", { staticClass: "form-label" }, [
                                    _vm._v("产品品牌"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.searchForm.productBrandId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "productBrandId",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.productBrandId",
                                      },
                                    },
                                    _vm._l(_vm.brandList, function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.brandName,
                                          value: item.pId,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "page-section" }, [
                    _c("div", { staticClass: "phead" }, [
                      _c("img", {
                        attrs: { src: require("@/images/icon-2.jpg") },
                      }),
                      _vm._v("\n            数据列表\n          "),
                    ]),
                    _vm._v(" "),
                    _c(
                      "section",
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.listLoading,
                                expression: "listLoading",
                              },
                            ],
                            key: _vm.tableKey,
                            ref: "multipleTable",
                            staticStyle: { width: "100%", "margin-top": "1px" },
                            attrs: {
                              data: _vm.tableData,
                              height: "400px",
                              border: "",
                              fit: "",
                              "row-key": "pId",
                              "highlight-current-row": "",
                            },
                            on: {
                              "selection-change": _vm.handleSelectionChange,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                type: "selection",
                                "reserve-selection": true,
                                width: "50",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "产品图片",
                                prop: "configCode",
                                align: _vm.tableConfig.align,
                                width: "125",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c("el-image", {
                                        staticStyle: {
                                          width: "100px",
                                          height: "100px",
                                        },
                                        attrs: {
                                          src: row.productImages
                                            ? row.productImages
                                            : _vm.imgUrl,
                                          "preview-src-list": [
                                            row.productImages
                                              ? row.productImages
                                              : _vm.imgUrl,
                                          ],
                                          fit: "contain",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "产品编码",
                                "show-overflow-tooltip": "",
                                prop: "productCode",
                                align: _vm.tableConfig.align,
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "产品名称",
                                "show-overflow-tooltip": "",
                                prop: "productName",
                                align: _vm.tableConfig.align,
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "产品分类",
                                "show-overflow-tooltip": "",
                                prop: "productClassName",
                                align: _vm.tableConfig.align,
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "产品品牌",
                                "show-overflow-tooltip": "",
                                prop: "brandName",
                                align: _vm.tableConfig.align,
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "规格",
                                "show-overflow-tooltip": "",
                                prop: "productModel",
                                align: _vm.tableConfig.align,
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("pagination", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.total > 0,
                              expression: "total > 0",
                            },
                          ],
                          attrs: {
                            layout: "total, sizes, prev, pager, next, jumper",
                            total: _vm.total,
                            page: _vm.searchForm.current,
                            limit: _vm.searchForm.size,
                          },
                          on: {
                            "update:page": function ($event) {
                              return _vm.$set(_vm.searchForm, "current", $event)
                            },
                            "update:limit": function ($event) {
                              return _vm.$set(_vm.searchForm, "size", $event)
                            },
                            pagination: _vm.getList,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 5 } }, [
                _c("div", { staticClass: "page-section leftSide" }, [
                  _c("div", { staticClass: "phead" }, [
                    _c("img", {
                      attrs: { src: require("@/images/icon-1.jpg") },
                    }),
                    _vm._v("\n            已选产品\n          "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "filter-container" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 10 } },
                        _vm._l(_vm.multipleSelection, function (item, index) {
                          return _c(
                            "el-tag",
                            {
                              key: index,
                              staticStyle: {
                                width: "100%",
                                "margin-bottom": "5px",
                              },
                              attrs: { closable: "" },
                              on: {
                                close: function ($event) {
                                  return _vm.tag1Close(item)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(item.productName) +
                                  _vm._s("(" + item.productCode + ")")
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.clear } }, [_vm._v("清 空")]),
          _vm._v(" "),
          _c("el-button", { on: { click: _vm.selCancel } }, [_vm._v("取 消")]),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }