var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.show,
        "append-to-body": true,
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "scanForm",
          staticClass: "info-items",
          attrs: {
            model: _vm.scanData,
            rules: _vm.rules,
            "label-position": "right",
            "label-width": "150px",
            loading: _vm.formLoading,
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "盘点单号:", prop: "inventoryOrderNo" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "350px" },
                        attrs: { disabled: true },
                        model: {
                          value: _vm.scanData.inventoryOrderNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.scanData, "inventoryOrderNo", $$v)
                          },
                          expression: "scanData.inventoryOrderNo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "盘点日期:", prop: "inventoryDate" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "350px" },
                        attrs: {
                          type: "date",
                          placeholder: "选择日期",
                          format: "yyyy-MM-dd",
                          "value-format": "timestamp",
                        },
                        model: {
                          value: _vm.scanData.inventoryDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.scanData, "inventoryDate", $$v)
                          },
                          expression: "scanData.inventoryDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "盘点库房:", prop: "storeId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "350px" },
                          attrs: { clearable: "", placeholder: "请选择" },
                          on: { change: _vm.setStore },
                          model: {
                            value: _vm.scanData.storeId,
                            callback: function ($$v) {
                              _vm.$set(_vm.scanData, "storeId", $$v)
                            },
                            expression: "scanData.storeId",
                          },
                        },
                        _vm._l(_vm.storeList, function (item) {
                          return _c("el-option", {
                            key: item.pId,
                            attrs: {
                              disabled: item.isEnable != 1,
                              label: item.storeHouseName,
                              value: item.pId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "盘点人:", prop: "inventoryUserName" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticStyle: { width: "350px" },
                          attrs: { readonly: "" },
                          model: {
                            value: _vm.scanData.inventoryUserName,
                            callback: function ($$v) {
                              _vm.$set(_vm.scanData, "inventoryUserName", $$v)
                            },
                            expression: "scanData.inventoryUserName",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: { slot: "append", icon: "el-icon-search" },
                            on: { click: _vm.openUser },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注:", prop: "remark" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "350px" },
                        attrs: {
                          type: "textarea",
                          maxlength: "100",
                          "show-word-limit": "",
                          placeholder: "",
                          autosize: { minRows: 4, maxRows: 4 },
                        },
                        model: {
                          value: _vm.scanData.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.scanData, "remark", $$v)
                          },
                          expression: "scanData.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品明细:", prop: "materialCode" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.openSel },
                        },
                        [_vm._v("添加产品")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.listLoading,
                              expression: "listLoading",
                            },
                          ],
                          staticStyle: { width: "100%", "margin-top": "1px" },
                          attrs: {
                            data: _vm.productList,
                            border: _vm.tableConfig.border,
                            stripe: _vm.tableConfig.stripe,
                            fit: "",
                            "highlight-current-row": "",
                            "max-height": "400",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "序号",
                              type: "index",
                              width: "120",
                              align: _vm.tableConfig.align,
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "产品名称",
                              "min-width": "100",
                              prop: "productName",
                              align: _vm.tableConfig.align,
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "产品编码",
                              "min-width": "100",
                              prop: "productCode",
                              align: _vm.tableConfig.align,
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "包装比例",
                              "min-width": "100",
                              prop: "packScaleExpression",
                              align: _vm.tableConfig.align,
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "库存数量(箱)",
                              align: _vm.tableConfig.align,
                              prop: "chestInventory",
                              width: "140",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [_vm._v(_vm._s(row.chestInventory))]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "库存数量(盒)",
                              align: _vm.tableConfig.align,
                              prop: "bottleInventory",
                              width: "140",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [_vm._v(_vm._s(row.bottleInventory))]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              fixed: "right",
                              align: _vm.tableConfig.align,
                              width: "140",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.del(row)
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { staticClass: "dialog-footer" },
                    [
                      _vm.submitType != 3
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.btn_loading,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.submitForm(2)
                                },
                              },
                            },
                            [_vm._v("提 交")]
                          )
                        : _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.btn_loading,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.submitForm(3)
                                },
                              },
                            },
                            [_vm._v("提 交")]
                          ),
                      _vm._v(" "),
                      _c("el-button", { on: { click: _vm.handleClose } }, [
                        _vm._v("关 闭"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.proShow
        ? _c("product-dialog", {
            ref: "selProduct",
            attrs: { "pro-sel-visible": _vm.proShow, data: _vm.productList },
            on: {
              "update:data": function ($event) {
                _vm.productList = $event
              },
              change: _vm.setSel,
              close: _vm.proClose,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("personDialog", { ref: "personDialog", on: { change: _vm.setUser } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }