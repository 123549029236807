var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.dialogVisible,
        "before-close": _vm.handleClose,
        width: "1200px !important",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.mloading,
              expression: "mloading",
            },
          ],
          ref: "scanForm",
          staticClass: "info-items",
          attrs: {
            model: _vm.scanData,
            rules: _vm.rules,
            "label-position": "right",
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "NC单号:", prop: "NCNO" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "350px" },
                        attrs: {
                          disabled: _vm.disType,
                          placeholder: "请输入单号",
                        },
                        on: {
                          input: function (e) {
                            return (_vm.scanData.NCNO = _vm.validCode(e))
                          },
                        },
                        model: {
                          value: _vm.scanData.NCNO,
                          callback: function ($$v) {
                            _vm.$set(_vm.scanData, "NCNO", $$v)
                          },
                          expression: "scanData.NCNO",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.showSelect
                ? _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "关联单号:", prop: "relateNo" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticStyle: { width: "350px" },
                              attrs: {
                                disabled: _vm.disType,
                                readonly: "",
                                clearable: "",
                              },
                              model: {
                                value: _vm.scanData.relateNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.scanData, "relateNo", $$v)
                                },
                                expression: "scanData.relateNo",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                  disabled: _vm.disType,
                                },
                                on: { click: _vm.searchRelateNo },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showSelect
                ? _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "发货单位:", prop: "outOrgName" } },
                        [
                          _c(
                            "el-input",
                            {
                              ref: "fhdw",
                              staticStyle: { width: "350px" },
                              attrs: { disabled: _vm.disType, readonly: "" },
                              model: {
                                value: _vm.scanData.outOrgName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.scanData, "outOrgName", $$v)
                                },
                                expression: "scanData.outOrgName",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                  disabled: _vm.disType,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getInUnit("out")
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.showSelect
                ? _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "发货单位:", prop: "outOrgName" } },
                        [
                          _c("el-input", {
                            ref: "fhdw",
                            staticStyle: { width: "350px" },
                            attrs: { disabled: "disabled" },
                            model: {
                              value: _vm.scanData.outOrgName,
                              callback: function ($$v) {
                                _vm.$set(_vm.scanData, "outOrgName", $$v)
                              },
                              expression: "scanData.outOrgName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.thrkShow
                ? _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "收货单位:", prop: "inOrgName" } },
                        [
                          _c(
                            "el-input",
                            {
                              ref: "fhdw",
                              staticStyle: { width: "350px" },
                              attrs: { disabled: _vm.disType, readonly: "" },
                              model: {
                                value: _vm.scanData.inOrgName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.scanData, "inOrgName", $$v)
                                },
                                expression: "scanData.inOrgName",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                  disabled: _vm.disType,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getInUnit("in")
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.thrkShow
                ? _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "收货单位:", prop: "inOrgName" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "350px" },
                            attrs: { disabled: "disabled" },
                            model: {
                              value: _vm.scanData.inOrgName,
                              callback: function ($$v) {
                                _vm.$set(_vm.scanData, "inOrgName", $$v)
                              },
                              expression: "scanData.inOrgName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "入库库房:", prop: "inStoreId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "350px" },
                          attrs: {
                            disabled: _vm.disType,
                            clearable: "",
                            placeholder: "请选择",
                          },
                          on: { focus: _vm.getDownHouses },
                          model: {
                            value: _vm.scanData.inStoreId,
                            callback: function ($$v) {
                              _vm.$set(_vm.scanData, "inStoreId", $$v)
                            },
                            expression: "scanData.inStoreId",
                          },
                        },
                        _vm._l(_vm.factoryList, function (item) {
                          return _c("el-option", {
                            key: item.pId,
                            attrs: {
                              label: item.storeHouseName,
                              value: item.pId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注", prop: "remark" } },
                    [
                      _c("el-input", {
                        attrs: {
                          disabled: _vm.disType,
                          type: "textarea",
                          rows: 3,
                          maxlength: "500",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.scanData.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.scanData, "remark", $$v)
                          },
                          expression: "scanData.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "is-required",
                      attrs: { label: "产品明细:" },
                    },
                    [
                      _c(
                        "div",
                        [
                          !_vm.disType
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: { "margin-bottom": "10px" },
                                  attrs: { type: "primary" },
                                  on: { click: _vm.openSel },
                                },
                                [_vm._v("添加")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.listLoading,
                                  expression: "listLoading",
                                },
                              ],
                              key: _vm.tableKey,
                              staticStyle: {
                                width: "100%",
                                "margin-top": "1px",
                              },
                              attrs: {
                                data: _vm.plist,
                                border: "",
                                fit: "",
                                "highlight-current-row": "",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "序号",
                                  type: "index",
                                  width: "80",
                                  align: "center",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "产品编码",
                                  prop: "productCode",
                                  align: "center",
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "产品名称",
                                  prop: "productName",
                                  align: "center",
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "包装比例",
                                  prop: "packRadioDesc",
                                  align: "center",
                                  "show-overflow-tooltip": "",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              row.packScaleDesc
                                                ? row.packScaleDesc
                                                : row.packRadioDesc
                                            )
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "箱数",
                                  prop: "planScanNum",
                                  align: "center",
                                  width: "120",
                                  "show-overflow-tooltip": "",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _c("el-input", {
                                          directives: [
                                            { name: "Int", rawName: "v-Int" },
                                          ],
                                          staticStyle: { width: "80px" },
                                          on: {
                                            input: function ($event) {
                                              return _vm.setSum(
                                                $event,
                                                row,
                                                "box"
                                              )
                                            },
                                          },
                                          model: {
                                            value: row.planScanNum,
                                            callback: function ($$v) {
                                              _vm.$set(row, "planScanNum", $$v)
                                            },
                                            expression: "row.planScanNum",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "瓶数",
                                  prop: "restNum",
                                  align: "center",
                                  width: "120",
                                  "show-overflow-tooltip": "",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _c("el-input-number", {
                                          directives: [
                                            { name: "Int", rawName: "v-Int" },
                                          ],
                                          staticStyle: { width: "80px" },
                                          attrs: { controls: false, mini: 0 },
                                          on: {
                                            input: function ($event) {
                                              return _vm.setSum(
                                                $event,
                                                row,
                                                "bottle"
                                              )
                                            },
                                          },
                                          model: {
                                            value: row.restNum,
                                            callback: function ($$v) {
                                              _vm.$set(row, "restNum", $$v)
                                            },
                                            expression: "row.restNum",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "总瓶数",
                                  align: "center",
                                  prop: "planScanAtoNum",
                                  "min-width": "100",
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _vm._v(" "),
                              !_vm.disType
                                ? _c("el-table-column", {
                                    attrs: {
                                      label: "操作",
                                      align: "center",
                                      fixed: "right",
                                      width: "80",
                                      "show-overflow-tooltip": "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var row = ref.row
                                            return !_vm.disType
                                              ? [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: { type: "text" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.proDel(row)
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("删除")]
                                                  ),
                                                ]
                                              : undefined
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("product-dialog", {
        ref: "selProduct",
        attrs: {
          "pro-sel-visible": _vm.proShow,
          data: _vm.plist,
          "is-code": "",
        },
        on: {
          "update:data": function ($event) {
            _vm.plist = $event
          },
          change: _vm.setSel,
          close: _vm.proClose,
        },
      }),
      _vm._v(" "),
      _c("allotUnitSelect", {
        attrs: {
          visible: _vm.unitShow,
          "in-out-type": _vm.inOutType,
          "shou-fa-type": _vm.shouFaType,
        },
        on: { close: _vm.closeUnit, change: _vm.fetchUnit },
      }),
      _vm._v(" "),
      _c("outcodeDialog", {
        attrs: {
          "code-visible": _vm.outcodeShow,
          "other-type-id": _vm.scanData.otherTypeId,
        },
        on: { close: _vm.colseCodeVisible, change: _vm.fecthNo },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _vm.submitType != 3
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.btn_loading },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm(2)
                    },
                  },
                },
                [_vm._v("提 交")]
              )
            : _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.btn_loading },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm(3)
                    },
                  },
                },
                [_vm._v("提 交")]
              ),
          _vm._v(" "),
          _c("el-button", { on: { click: _vm.handleClose } }, [
            _vm._v("关 闭"),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }