"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _integralMall = require("@/api/integral/integralMall");
var _prodInfo = _interopRequireDefault(require("./prodInfo"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'integralMallprodprodList';
var _default = {
  name: 'IntegralMallprodprodList',
  components: {
    Pagination: _Pagination.default,
    prodInfo: _prodInfo.default
  },
  data: function data() {
    return {
      tabHeight: '100%',
      dataForm: {
        prodName: ''
      },
      prodInfoIs: false,
      formInline: {
        prodName: null,
        status: null,
        current: 1,
        // 当前页数
        size: 20
      },
      total: 0,
      // 总页数
      dataList: [],
      dataListSelections: [],
      listLoading: false,
      apis: []
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    var that = this;
    var hasCache = !!(that.dataList && that.dataList.length > 0);
    var authList = that.$route.meta.authList;
    that.apis = authList;
    if (!hasCache) {
      that.fetchData();
    }
  },
  // 缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = this.getCaches(that, name);
    if (!cache) {
      that.dataList = [];
      that.total = 0;
    }
  },
  methods: {
    restForm: function restForm(refname) {
      this.$refs[refname].resetFields();
    },
    // 获取数据列表
    fetchData: function fetchData() {
      var _this = this;
      this.authId('searchbtn', this.apis);
      this.listLoading = true;
      (0, _integralMall.getProdPage)(this.formInline).then(function (data) {
        if (data.status === 200) {
          _this.dataList = data.data.records;
          // for (const key in this.dataList) {
          //   if (this.dataList.hasOwnProperty(key)) {
          //     const element = this.dataList[key]
          //     // element.imgs = element.imgs.split(',')[0]
          //   }
          // }
          _this.total = data.data.total;
        }
        _this.listLoading = false;
      });
    },
    // 新增 / 修改
    addOrUpdateHandle: function addOrUpdateHandle(id) {
      var _this2 = this;
      if (typeof id == 'undefined') {
        this.authId('addbtn', this.apis);
      } else {
        this.authId('updatebtn', this.apis);
      }
      this.prodInfoIs = true;
      this.$nextTick(function () {
        _this2.$refs.prodInfo.init(id);
      });
      // this.$router.push({
      //   path: '/integralMall/prodListManage/prodInfo',
      //   query: { prodId: id, disabled: false }
      // })
    },
    // 预览
    showDetail: function showDetail(id) {
      var _this3 = this;
      this.authId('previewbtn', this.apis);
      this.prodInfoIs = true;
      this.$nextTick(function () {
        _this3.$refs.prodInfo.init(id, true);
      });
      // this.$router.push({
      //   path: '/integralMall/prodListManage/prodInfo',
      //   query: { prodId: id, disabled: true }
      // })
    },
    // 删除
    deleteHandle: function deleteHandle(id) {
      var _this4 = this;
      this.$confirm("\u786E\u5B9A\u8981\u8FDB\u884C".concat(id ? '删除' : '批量删除', "\u64CD\u4F5C\u5417?"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var params;
        if (id) {
          _this4.authId('deletebtn', _this4.apis);
          params = id;
          (0, _integralMall.delProd)(params).then(function (data) {
            _this4.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: function onClose() {
                _this4.fetchData();
              }
            });
          }).catch(function (err) {
            _this4.$message.closeAll();
            _this4.$message({
              message: err.response.data,
              type: 'error',
              duration: 1500
            });
          });
        } else {
          _this4.authId('alldelete', _this4.apis);
          params = [];
          _this4.dataListSelections.forEach(function (item) {
            params.push(item.prodId);
          });
          (0, _integralMall.delProds)({
            prodIds: params.join(',')
          }).then(function (data) {
            _this4.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: function onClose() {
                _this4.fetchData();
              }
            });
          });
        }
      }).catch(function () {});
    },
    // 多选变化
    handleSelectionChange: function handleSelectionChange(val) {
      this.dataListSelections = val;
    },
    prodInfoIsFun: function prodInfoIsFun() {
      this.prodInfoIs = false;
    }
  }
};
exports.default = _default;