"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _basic = require("@/api/mark/basic.js");
var _utils = require("@/utils");
var _query = require("@/api/fake_expel/query/query");
var _waves = _interopRequireDefault(require("@/directive/waves"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'FakeExpelqueryManageauditindex',
  components: {
    Pagination: _Pagination.default
  },
  directives: {
    waves: _waves.default
  },
  filters: {
    formatDate: function formatDate(time) {
      if (!time) {
        return '';
      }
      var date = new Date(time);
      return (0, _basic.formatDate)(date, 'yyyy-MM-dd hh:mm:ss');
    },
    codeType: function codeType(status) {
      if (!String(status)) return '';
      var statusMap = {
        1: '营销码',
        2: '防伪码',
        3: '物流码'
      };
      return statusMap[status];
    },
    uploadResult: function uploadResult(status) {
      if (!String(status)) return '';
      var statusMap = {
        1: '正常',
        2: '疑似窜货',
        3: '疑似假码',
        4: '未激活'
      };
      return statusMap[status];
    },
    packLevel: function packLevel(status) {
      if (!String(status)) return '';
      var statusMap = {
        1: '盖码',
        2: '瓶码',
        3: '盒码',
        4: '箱码',
        5: '托码'
      };
      return statusMap[status];
    },
    numStatus: function numStatus(status) {
      if (!String(status)) return '';
      var statusMap = {
        0: '未激活',
        1: '正常',
        2: '冻结',
        3: '废除',
        10: '不存在'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      handleShow: false,
      tabHeight: '100%',
      chosedate: [],
      listQuery: {
        pageNo: 1,
        pageSize: 20,
        startTime: '',
        endTime: '',
        productName: '',
        uploadResult: '',
        warehouseName: '',
        wlm: ''
      },
      tableKey: 0,
      list: [],
      total: 0,
      listLoading: false,
      btn_loading: false,
      dialogFormVisible: false,
      temp: {},
      time: (0, _basic.normalDate)()
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _basic.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    this.btnPowers = {};
    this.listQuery.startTime = this.time[0];
    this.listQuery.endTime = this.time[1];
    this.getList();
  },
  methods: {
    getSrcList: function getSrcList(index) {
      return this.temp.list.slice(index).concat(this.temp.list.slice(0, index));
    },
    getList: function getList() {
      var that = this;
      that.listLoading = true;
      var submitData = (0, _utils.filterKeyNull)(that.listQuery);
      // if (that.chosedate && that.chosedate.length) {
      //   submitData.startTime = that.chosedate[0]
      //   submitData.endTime = that.chosedate[1]
      // } else {
      //   submitData.startTime = ''
      //   submitData.endTime = ''
      // }
      (0, _query.getList)(submitData).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.total = parseInt(response.data.data.total);
            that.list = response.data.data.records;
          } else {
            that.total = 0;
            that.list = [];
          }
          that.listLoading = false;
        }
      }).catch(function (res) {
        that.listLoading = false;
      });
    },
    reset: function reset() {
      this.listQuery = {
        pageNo: 1,
        pageSize: 20,
        startTime: this.time[0],
        endTime: this.time[1],
        productName: '',
        uploadResult: '',
        warehouseName: '',
        wlm: ''
      };
      this.getList();
    },
    resetData: function resetData() {
      this.temp = {};
      this.dialogFormVisible = false;
    },
    detail: function detail(row) {
      var _this = this;
      this.btn_loading = true;
      (0, _query.searchDetail)({
        pId: row.pid
      }).then(function (res) {
        console.log(res);
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
          return;
        } else {
          _this.temp = res.data.data;
          if (res.data.data.imgUrl) {
            _this.temp.list = res.data.data.imgUrl.split(',');
          }
          _this.dialogFormVisible = true;
        }
        _this.btn_loading = false;
      }).catch(function () {
        _this.btn_loading = false;
      });
    },
    del: function del(row) {
      var _this2 = this;
      this.$confirm('确认删除?将不可撤销', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _query.del)({
          pId: row.pid
        }).then(function (res) {
          if (res.data.code == 200) {
            _this2.$message({
              type: 'success',
              message: '删除成功!'
            });
            _this2.getList();
          } else {
            _this2.$message({
              type: 'info',
              message: res.data.msg
            });
          }
        });
      }).catch(function () {
        console.log('已取消');
      });
    }
  }
};
exports.default = _default;