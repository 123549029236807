"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _orderDevy = _interopRequireDefault(require("./order-devy"));
var _integralMall = require("@/api/integral/integralMall");
var _memberManage = require("@/api/integral/memberManage");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import AddOrUpdate from './order-addr'
var _default = {
  components: {
    // AddOrUpdate,
    DevyAdd: _orderDevy.default
  },
  data: function data() {
    return {
      visible: false,
      tableData: [],
      dataForm: {
        orderId: 0,
        orderNumber: '',
        remarks: '',
        total: 0,
        actualTotal: 0,
        dvyType: '',
        status: 1,
        addrOrderId: 0,
        nickName: '',
        orderItems: [],
        orderTime: '',
        updateTime: '',
        payTime: '',
        dvyTime: '',
        finallyTime: '',
        cancelTime: '',
        userAddrOrder: {}
      },
      addOrUpdateVisible: false,
      devyVisible: false
    };
  },
  computed: {
    stepsStatus: function stepsStatus() {
      switch (this.dataForm.status) {
        case 1:
          return 1;
        case 2:
          return 2;
        case 3:
          return 3;
        case 4:
          return 4;
        case 5:
          return 4;
        default:
          return 0;
      }
    }
  },
  watch: {
    visible: function visible() {
      if (!this.visible) {
        this.devyVisible = false;
        this.addOrUpdateVisible = false;
      }
    }
  },
  methods: {
    init: function init(orderNumber) {
      var _this = this;
      this.dataForm.orderNumber = orderNumber || 0;
      this.visible = true;
      this.$nextTick(function () {
        _this.$refs['dataForm'].resetFields();
      });
      if (this.dataForm.orderNumber) {
        // 修改
        (0, _integralMall.uporderInfo)(this.dataForm.orderNumber).then(function (res) {
          _this.dataForm = res.data;
          _this.tableData = [res.data];
        }).then(function () {
          (0, _memberManage.detailMember)({
            memberId: _this.dataForm.userId
          }).then(function (res) {
            if (res.status === 200) {
              if (res.data.code === 0) {
                _this.dataForm.nickname = res.data.data.nickname;
                _this.tableData = [_this.dataForm];
              }
            }
          });
        });
      }
    },
    getDataList: function getDataList() {
      var _this2 = this;
      (0, _integralMall.uporderInfo)(this.dataForm.orderNumber).then(function (data) {
        // data.userId
        // this.dataForm.nickName =
        _this2.dataForm = data;
        _this2.tableData = [data];
      }).then(function () {
        (0, _memberManage.detailMember)({
          memberId: _this2.dataForm.userId
        }).then(function (res) {
          if (res.status === 200) {
            _this2.dataForm.nickname = res.data.data.nickname;
            _this2.tableData = [_this2.dataForm];
          }
        });
      });
    },
    // 发货
    changeOrder: function changeOrder(orderNumber) {
      var _this3 = this;
      this.devyVisible = true;
      this.$nextTick(function () {
        _this3.$refs.devyAdd.init(orderNumber, _this3.dataForm.dvyId, _this3.dataForm.dvyFlowId);
      });
    }
  }
};
exports.default = _default;