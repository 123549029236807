"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.batchEnable = batchEnable;
exports.getQueryForPage = getQueryForPage;
exports.singleEnable = singleEnable;
var _request = _interopRequireDefault(require("@/utils/request"));
var base = '';

//错误查询数码列表
function getQueryForPage(current, size, data) {
  return (0, _request.default)({
    url: base + "/market-center/drawErrMarkCollect/queryForPage?current=".concat(current, "&size=").concat(size),
    method: 'post',
    data: data
  });
}
//错误查询数码--单个解禁
function singleEnable(markCode) {
  return (0, _request.default)({
    url: base + "/market-center/drawErrMarkCollect/singleEnable/".concat(markCode),
    method: 'post'
  });
}
//错误查询数码--批量解禁
function batchEnable(data) {
  return (0, _request.default)({
    url: base + '/market-center/drawErrMarkCollect/batchEnable',
    method: 'post',
    data: data
  });
}