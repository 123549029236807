import { render, staticRenderFns } from "./creat.vue?vue&type=template&id=7ff47cc4&scoped=true&"
import script from "./creat.vue?vue&type=script&lang=js&"
export * from "./creat.vue?vue&type=script&lang=js&"
import style0 from "./creat.vue?vue&type=style&index=0&id=7ff47cc4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ff47cc4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7ff47cc4')) {
      api.createRecord('7ff47cc4', component.options)
    } else {
      api.reload('7ff47cc4', component.options)
    }
    module.hot.accept("./creat.vue?vue&type=template&id=7ff47cc4&scoped=true&", function () {
      api.rerender('7ff47cc4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/terminalManagement/externalGamesConfig/gameConfig/components/creat.vue"
export default component.exports