var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-analyze-wrap" }, [
    _c(
      "div",
      { staticClass: "user-analyze-content", staticStyle: { padding: "0" } },
      [
        _c("div", { staticClass: "hyyy-top-tit" }, [
          _c(
            "div",
            { staticClass: "hyyy-top-tit-lift" },
            [
              _c("span", {
                domProps: { textContent: _vm._s(_vm.$route.meta.title) },
              }),
              _vm._v(" "),
              _c(
                "el-popover",
                {
                  attrs: {
                    placement: "bottom-start",
                    transition: "zoom-in-top",
                    width: "400",
                    trigger: "hover",
                  },
                },
                [
                  _c("div", [
                    _c("p", [
                      _vm._v("群发次数：在时间范围内成功发送的消息条数。"),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "原文阅读次数：在时间范围内成功发送的图文中原文链接阅读的次数总和。"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "分享转发次数：在时间范围内成功发送的图文被分享转发的次数总和。"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "图文收藏人数：在时间范围内成功发送的图文被收藏的次数总和。"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "发送图文篇数：在时间范围内成功发送的图文的数量总和。"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "图文总阅读数：在时间范围内成功发送的图文总阅读次数总和。"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "图文总阅读人数：在时间范围内成功发送的图文总阅读人数总和。"
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "el-icon-question",
                    staticStyle: { "font-size": "20px", color: "#e6a23c" },
                    attrs: { slot: "reference" },
                    slot: "reference",
                  }),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "hyyy-top-tit-right" },
            [_c("admincut", { staticStyle: { "margin-top": "7px" } })],
            1
          ),
        ]),
        _vm._v(" "),
        _c("el-divider", { staticStyle: { margin: "0" } }),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { width: "100%", height: "100%", padding: "20px" } },
          [
            _c("div", { staticClass: "count-wrap" }, [
              _c("div", { staticClass: "time-wrap" }, [
                _vm.isCompare
                  ? _c(
                      "div",
                      { staticClass: "time-top" },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "daterange",
                            align: "right",
                            "unlink-panels": "",
                            "value-format": "yyyy-MM-dd",
                            "range-separator": "-",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            size: "small",
                          },
                          model: {
                            value: _vm.imgTextDate,
                            callback: function ($$v) {
                              _vm.imgTextDate = $$v
                            },
                            expression: "imgTextDate",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.latelyFun(30)
                              },
                            },
                          },
                          [_vm._v("最近30天")]
                        ),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      { staticClass: "time-bottom" },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "daterange",
                            align: "center",
                            "value-format": "yyyy-MM-dd",
                            "range-separator": "-",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            size: "small",
                          },
                          model: {
                            value: _vm.imgTextDate,
                            callback: function ($$v) {
                              _vm.imgTextDate = $$v
                            },
                            expression: "imgTextDate",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v("  —  ")]),
                        _vm._v(" "),
                        _c("el-date-picker", {
                          attrs: {
                            type: "daterange",
                            align: "center",
                            "unlink-panels": "",
                            "value-format": "yyyy-MM-dd",
                            "range-separator": "-",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            size: "small",
                          },
                          model: {
                            value: _vm.compareDate,
                            callback: function ($$v) {
                              _vm.compareDate = $$v
                            },
                            expression: "compareDate",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.isCompareFun(false)
                              },
                            },
                          },
                          [_vm._v("取消对比")]
                        ),
                      ],
                      1
                    ),
              ]),
              _vm._v(" "),
              _vm.isCompare
                ? _c("div", { staticClass: "count-list" }, [
                    _c("ul", [
                      _c("li", [
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              Number(_vm.newsAnalysisDataVO.sendsCount) || 0
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", [_vm._v("群发次数")]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              Number(_vm.newsAnalysisDataVO.newsCount) || 0
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", [_vm._v("发送图文篇数")]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              Number(_vm.newsAnalysisDataVO.newsReadingCount) ||
                                0
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", [_vm._v("图文总阅读数")]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              Number(_vm.newsAnalysisDataVO.ewsReadingNumber) ||
                                0
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", [_vm._v("图文总阅读人数")]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              Number(_vm.newsAnalysisDataVO.originalCount) || 0
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", [_vm._v("原文阅读次数")]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              Number(_vm.newsAnalysisDataVO.shareCount) || 0
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", [_vm._v("分享转发次数")]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              Number(_vm.newsAnalysisDataVO.collectionNumber) ||
                                0
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", [_vm._v("图文收藏人数")]),
                      ]),
                      _vm._v(" "),
                      false
                        ? _c("li", [
                            _c("strong", [
                              _vm._v(
                                _vm._s(
                                  Number(_vm.newsAnalysisDataVO.sendCount) || 0
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("span", [_vm._v("成功发送数量")]),
                          ])
                        : _vm._e(),
                    ]),
                  ])
                : _c(
                    "div",
                    { staticClass: "count-table" },
                    [
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { data: _vm.tableArr, border: "" },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { prop: "date", label: "日期" },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { prop: "sendsCount", label: "群发次数" },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "newsCount",
                              label: "发送图文篇数",
                              width: "110",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "newsReadingCount",
                              label: "图文总阅读数",
                              width: "110",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "ewsReadingNumbe",
                              label: "原文阅读人数",
                              width: "110",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "originalCount",
                              label: "原文阅读次数",
                              width: "110",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "shareCount",
                              label: "分享转发次数",
                              width: "110",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "collectionNumber",
                              label: "图文收藏人数",
                              width: "110",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "line-chart-wrap" }, [
              _c(
                "ul",
                { staticClass: "line-chart-btns clearfix" },
                _vm._l(_vm.typeList, function (item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      class: { active: _vm.typeNum === item.id },
                      on: {
                        click: function ($event) {
                          return _vm.countLineFun(item.id)
                        },
                      },
                    },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "line-chart",
                  staticStyle: { width: "100%", height: "100%" },
                },
                [
                  _c("Line-chart", {
                    ref: "lineChart",
                    attrs: { "chart-data": _vm.lineChartData },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "el-row",
              [
                _c("el-col", { attrs: { span: 10 } }, [
                  _c(
                    "div",
                    {
                      staticClass: "open-ratio",
                      staticStyle: { width: "100%" },
                    },
                    [
                      _c(
                        "h5",
                        [
                          _vm._v("\n              图文打开率\n              "),
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "bottom-start",
                                title: "",
                                width: "400",
                                trigger: "hover",
                                content:
                                  "图文打开率：根据图文分组进行统计的数据，图文打开率=该图文的阅读数量/所有图文阅读总数。",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-question",
                                attrs: { slot: "reference" },
                                slot: "reference",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          ref: "myEchart",
                          staticStyle: { width: "100%", height: "300px" },
                        },
                        [_c("PieChart", { ref: "pieChart" })],
                        1
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("el-col", { attrs: { span: 11, offset: 1 } }, [
                  _c(
                    "div",
                    { staticClass: "top-wrap", staticStyle: { width: "100%" } },
                    [
                      _c(
                        "el-row",
                        {
                          staticClass: "twtop",
                          attrs: { type: "flex", justify: "start" },
                        },
                        [
                          _c("el-col", { attrs: { span: 6 } }, [
                            _c("h5", { staticStyle: { margin: "0" } }, [
                              _vm._v("图文top10排名"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 16 } },
                            [
                              _c(
                                "el-row",
                                {
                                  attrs: {
                                    type: "flex",
                                    justify: "space-between",
                                  },
                                },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          type: "daterange",
                                          align: "right",
                                          "unlink-panels": "",
                                          "value-format": "yyyy-MM-dd",
                                          "range-separator": "-",
                                          "start-placeholder": "开始日期",
                                          "end-placeholder": "结束日期",
                                          size: "small",
                                        },
                                        model: {
                                          value: _vm.topDate,
                                          callback: function ($$v) {
                                            _vm.topDate = $$v
                                          },
                                          expression: "topDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 10 } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "70%" },
                                          attrs: {
                                            size: "small",
                                            placeholder: "图文阅读次数",
                                          },
                                          on: { change: _vm.topOptionFun },
                                          model: {
                                            value: _vm.topOption,
                                            callback: function ($$v) {
                                              _vm.topOption = $$v
                                            },
                                            expression: "topOption",
                                          },
                                        },
                                        _vm._l(_vm.typeList, function (item) {
                                          return _c("el-option", {
                                            key: item.id,
                                            attrs: {
                                              label: item.name,
                                              value: item.name,
                                            },
                                          })
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "top-select" }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { width: "100%", height: "300px" } },
                        [_c("BarChartLeft", { ref: "barchart" })],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }