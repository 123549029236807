var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.apis)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c(
                "el-form",
                {
                  ref: "listQuery",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.listQuery,
                    "status-icon": "",
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cols" },
                    [
                      _c(
                        "el-row",
                        {
                          staticStyle: { "margin-bottom": "0" },
                          attrs: { gutter: 20 },
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "billNo", label: "单号:" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.listQuery.billNo,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.listQuery, "billNo", $$v)
                                      },
                                      expression: "listQuery.billNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "businessType",
                                    label: "业务类型:",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      model: {
                                        value: _vm.listQuery.businessType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.listQuery,
                                            "businessType",
                                            $$v
                                          )
                                        },
                                        expression: "listQuery.businessType",
                                      },
                                    },
                                    _vm._l(_vm.businessOption, function (item) {
                                      return _c("el-option", {
                                        key: item.businessCode,
                                        attrs: {
                                          label: item.businessTypeName,
                                          value: item.businessCode,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.showCol
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "outNo",
                                        label: "单号来源:",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          clearable: "",
                                          placeholder: "请输入",
                                        },
                                        model: {
                                          value: _vm.listQuery.outNo,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.listQuery,
                                              "outNo",
                                              $$v
                                            )
                                          },
                                          expression: "listQuery.outNo",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showCol
                            ? _c(
                                "el-col",
                                { attrs: { span: 6, prop: "orgId" } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "orgId",
                                        label: "收货组织:",
                                      },
                                    },
                                    [
                                      _c("treeselect", {
                                        attrs: {
                                          multiple: false,
                                          "flatten-search-results": true,
                                          placeholder: "请选择组织...",
                                          options: _vm.orgOptions,
                                        },
                                        model: {
                                          value: _vm.listQuery.orgId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.listQuery,
                                              "orgId",
                                              $$v
                                            )
                                          },
                                          expression: "listQuery.orgId",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "buyerStr",
                                    label: "收货单位:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      "suffix-icon": "el-icon-search",
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    on: {
                                      clear: _vm.clearSh,
                                      focus: function ($event) {
                                        return _vm.getUnit("in")
                                      },
                                    },
                                    model: {
                                      value: _vm.listQuery.buyerStr,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.listQuery, "buyerStr", $$v)
                                      },
                                      expression: "listQuery.buyerStr",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.showCol || !_vm.formConfig.btnFollow,
                                  expression:
                                    "showCol || !formConfig.btnFollow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "sellerStr",
                                    label: "发货单位:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      "suffix-icon": "el-icon-search",
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    on: {
                                      clear: _vm.clearFh,
                                      focus: function ($event) {
                                        return _vm.getUnit("out")
                                      },
                                    },
                                    model: {
                                      value: _vm.listQuery.sellerStr,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.listQuery,
                                          "sellerStr",
                                          $$v
                                        )
                                      },
                                      expression: "listQuery.sellerStr",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.showCol
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "outDate",
                                        label: "发货日期:",
                                      },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          type: "daterange",
                                          "range-separator": "至",
                                          "start-placeholder": "开始日期",
                                          "end-placeholder": "结束日期",
                                          format: "yyyy-MM-dd",
                                          "value-format": "yyyy-MM-dd",
                                        },
                                        model: {
                                          value: _vm.outDate,
                                          callback: function ($$v) {
                                            _vm.outDate = $$v
                                          },
                                          expression: "outDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showCol
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "inDate",
                                        label: "收货日期:",
                                      },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          type: "daterange",
                                          "range-separator": "至",
                                          "start-placeholder": "开始日期",
                                          "end-placeholder": "结束日期",
                                          format: "yyyy-MM-dd",
                                          "value-format": "yyyy-MM-dd",
                                        },
                                        model: {
                                          value: _vm.inDate,
                                          callback: function ($$v) {
                                            _vm.inDate = $$v
                                          },
                                          expression: "inDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showCol
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "productStr",
                                        label: "产品名称:",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          "suffix-icon": "el-icon-search",
                                          clearable: "",
                                          placeholder: "请输入",
                                        },
                                        on: {
                                          clear: _vm.clearPro,
                                          focus: function ($event) {
                                            return _vm.choseProName("search")
                                          },
                                        },
                                        model: {
                                          value: _vm.listQuery.productStr,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.listQuery,
                                              "productStr",
                                              $$v
                                            )
                                          },
                                          expression: "listQuery.productStr",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showCol
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "status",
                                        label: "单据状态:",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            clearable: "",
                                            placeholder: "请选择",
                                          },
                                          model: {
                                            value: _vm.listQuery.status,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.listQuery,
                                                "status",
                                                $$v
                                              )
                                            },
                                            expression: "listQuery.status",
                                          },
                                        },
                                        _vm._l(_vm.states, function (item) {
                                          return _c("el-option", {
                                            key: item.code,
                                            attrs: {
                                              label: item.name,
                                              value: item.code,
                                            },
                                          })
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showCol
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "isError",
                                        label: "异常状态:",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          model: {
                                            value: _vm.listQuery.isError,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.listQuery,
                                                "isError",
                                                $$v
                                              )
                                            },
                                            expression: "listQuery.isError",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "正常",
                                              value: "1",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "异常",
                                              value: "0",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.formConfig.btnFollow
                            ? _c(
                                "el-col",
                                {
                                  staticClass: "head-btn-group",
                                  attrs: { span: 6 },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { "label-width": "0" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v(" ")]
                                      ),
                                      _vm._v(" "),
                                      _vm.hasAuth("searchbtn", _vm.apis)
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: _vm.btnConfig.searchBtn
                                                  .type,
                                                size: _vm.btnConfig.size,
                                                icon: _vm.btnConfig.searchBtn
                                                  .icon,
                                              },
                                              on: { click: _vm.DoSearch },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.btnConfig.searchBtn.text
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("el-divider", {
                                        attrs: { direction: "vertical" },
                                      }),
                                      _vm._v(" "),
                                      _vm.hasAuth("searchbtn", _vm.apis)
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: _vm.btnConfig.resetBtn
                                                  .type,
                                                size: _vm.btnConfig.size,
                                                icon: _vm.btnConfig.resetBtn
                                                  .icon,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.DoReset(
                                                    _vm.listQuery
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.btnConfig.resetBtn.text
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("el-divider", {
                                        attrs: { direction: "vertical" },
                                      }),
                                      _vm._v(" "),
                                      _vm.hasAuth("searchbtn", _vm.apis)
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: { click: _vm.expand },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(_vm.expandTxt) +
                                                  "\n                  "
                                              ),
                                              _c("i", {
                                                class:
                                                  _vm.showCol == false
                                                    ? "el-icon-arrow-down"
                                                    : "el-icon-arrow-up",
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.formConfig.btnFollow
                ? _c("el-divider", { staticClass: "btnDivider" })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btnArea" },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "head-btn-group",
                      staticStyle: { width: "230px" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "head-btn-group" },
                        [
                          _vm.hasAuth("importbtn", _vm.apis)
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.downLoadBtn.type,
                                    size: _vm.btnConfig.size,
                                    plain: _vm.btnConfig.downLoadBtn.plain,
                                    icon: _vm.btnConfig.downLoadBtn.icon,
                                    loading: _vm.exportLoading,
                                  },
                                  on: { click: _vm.DoExportAll },
                                },
                                [_vm._v(_vm._s(_vm.btnConfig.downLoadBtn.text))]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "section",
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      key: _vm.tableKey,
                      staticStyle: { width: "100%" },
                      attrs: {
                        height: _vm.tabHeight,
                        data: _vm.list,
                        border: _vm.tableConfig.border,
                        stripe: _vm.tableConfig.stripe,
                        fit: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "单号",
                          width: "180",
                          prop: "no",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "业务类型",
                          width: "180",
                          prop: "businessTypeStr",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "来源单号",
                          prop: "outNo",
                          width: "180",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "发货单位",
                          prop: "sellerName",
                          width: "180",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "收货单位",
                          prop: "buyerName",
                          width: "180",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "收货组织",
                          prop: "buyerOrg",
                          width: "180",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-table-column",
                        {
                          attrs: {
                            label: "单位",
                            prop: "buyerOrg",
                            width: "180",
                            align: _vm.tableConfig.align,
                            "show-overflow-tooltip": "",
                          },
                        },
                        [[_vm._v("盒")]],
                        2
                      ),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "应收总数量",
                          prop: "inNumPlan",
                          width: "180",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "实收总数量",
                          prop: "inNum",
                          width: "180",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "单据状态",
                          prop: "orderStatusStr",
                          width: "180",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c(
                                    "el-tag",
                                    {
                                      attrs: {
                                        type: _vm._f("filterStatus")(
                                          row.orderStatus
                                        ),
                                      },
                                    },
                                    [_vm._v(_vm._s(row.orderStatusStr))]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1490181558
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "发货日期",
                          prop: "outDate",
                          width: "180",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm._v(
                                    _vm._s(_vm._f("formatTime")(row.outDate))
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4117898122
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "收货日期",
                          prop: "inDate",
                          align: _vm.tableConfig.align,
                          width: "180",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm._v(
                                    _vm._s(_vm._f("formatTime")(row.inDate))
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1489486787
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "异常状态",
                          prop: "isError",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm._v(
                                    _vm._s(row.isError == 1 ? "正常" : "异常")
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          717909160
                        ),
                      }),
                      _vm._v(" "),
                      _vm.hasAuth("detailbtn", _vm.apis)
                        ? _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              align: _vm.tableConfig.align,
                              width: "80",
                              fixed: "right",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _vm.hasAuth("detailbtn", _vm.apis)
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.DoScan(row)
                                                },
                                              },
                                            },
                                            [_vm._v("详情")]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3072384774
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("pagination", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.total > 0,
                        expression: "total > 0",
                      },
                    ],
                    attrs: {
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.total,
                      page: _vm.listQuery.pageNo,
                      limit: _vm.listQuery.pageSize,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(_vm.listQuery, "pageNo", $event)
                      },
                      "update:limit": function ($event) {
                        return _vm.$set(_vm.listQuery, "pageSize", $event)
                      },
                      pagination: _vm.getList,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.apis)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: "其他入库-查看",
            width: "1200px !important",
            visible: _vm.scanVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.scanVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "stepArea" },
            [
              _c("div", { staticClass: "billTitle" }, [
                _c("img", { attrs: { src: require("@/images/topimg.png") } }),
                _vm._v(
                  "\n          其他入库单 " +
                    _vm._s(_vm.baseData.no) +
                    "\n          "
                ),
                _c(
                  "span",
                  {
                    staticClass: "billType",
                    class: _vm._f("typeClass")(_vm.baseData.orderStatus),
                  },
                  [_vm._v(_vm._s(_vm.baseData.orderStatusStr))]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "topRight" }, [
                  _c("img", { attrs: { src: require("@/images/avatar.png") } }),
                  _vm._v(" "),
                  _c("span", { staticStyle: { "margin-left": "10px" } }, [
                    _vm._v(_vm._s(_vm.baseData.createTime)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-steps",
                {
                  staticStyle: { "margin-top": "20px" },
                  attrs: {
                    active: Number(_vm.baseData.orderStatus),
                    "finish-status": "success",
                  },
                },
                [
                  _c("el-step", { attrs: { title: "待提交" } }),
                  _vm._v(" "),
                  _c("el-step", { attrs: { title: "待审核" } }),
                  _vm._v(" "),
                  _c("el-step", { attrs: { title: "部分入库" } }),
                  _vm._v(" "),
                  _c("el-step", { attrs: { title: "全部入库" } }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "itemTitle" }, [_vm._v("基本信息")]),
          _vm._v(" "),
          _c(
            "el-form",
            { attrs: { model: _vm.baseData, "label-width": "110px" } },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("来源单号:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.baseData.outNo)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("收货方式:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [_vm._v("整单")]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c(
                        "div",
                        {
                          staticClass: "form-div",
                          staticStyle: { width: "100px" },
                        },
                        [_vm._v("发货单位名称:")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.baseData.sellerName)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c(
                        "div",
                        {
                          staticClass: "form-div",
                          staticStyle: { width: "100px" },
                        },
                        [_vm._v("发货单位分类:")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.baseData.sellerClassName)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c(
                        "div",
                        {
                          staticClass: "form-div",
                          staticStyle: { width: "100px" },
                        },
                        [_vm._v("收货单位名称:")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.baseData.buyerName)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c(
                        "div",
                        {
                          staticClass: "form-div",
                          staticStyle: { width: "100px" },
                        },
                        [_vm._v("收货单位分类:")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.baseData.buyerClassName)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("收货日期:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.baseData.inDate)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("发货日期:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.baseData.outDate)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("签收结论:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.baseData.qianResultStr)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("差异原因:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.baseData.resultReason)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("异常状态:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.baseData.isError == 1 ? "正常" : "异常"
                            ) +
                            "\n              "
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "middleLine" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "itemTitle" },
            [
              _c("span", { staticStyle: { "margin-right": "20px" } }, [
                _vm._v("产品明细"),
              ]),
              _vm._v(" "),
              (_vm.baseData.isCode == "1" ? true : false)
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.scanCode },
                    },
                    [_vm._v("查看数码")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticClass: "detailTable",
              staticStyle: { width: "100%", "margin-top": "1px" },
              attrs: {
                data: _vm.productlist,
                fit: "",
                "max-height": "300",
                "highlight-current-row": "",
                border: _vm.tableConfig.border,
                stripe: _vm.tableConfig.stripe,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "产品编码",
                  prop: "productCode",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "产品名称",
                  prop: "productName",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "品牌",
                  prop: "productBrand",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "包装比例",
                  prop: "packScale",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c(
                "el-table-column",
                {
                  attrs: {
                    label: "单位",
                    prop: "buyerOrg",
                    width: "80",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                },
                [[_vm._v("盒")]],
                2
              ),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "应收数量",
                  prop: "orderNum",
                  width: "180",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "实收数量",
                  prop: "inNum",
                  "show-overflow-tooltip": "",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "countWrap" }, [
            _c("div", { staticStyle: { "min-width": "250px" } }, [
              _vm._v("应收总数量：" + _vm._s(_vm.baseData.inNumPlan)),
            ]),
            _vm._v(" "),
            _c("div", [_vm._v("实收总数量：" + _vm._s(_vm.baseData.inNum))]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "middleLine" }),
          _vm._v(" "),
          _c("div", { staticClass: "itemTitle" }, [_vm._v("整单备注")]),
          _vm._v(" "),
          _c("div", { staticStyle: { padding: "0 0 15px 0" } }, [
            _vm._v(_vm._s(_vm.baseData.remark)),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "middleLine" }),
          _vm._v(" "),
          _c(
            "el-collapse",
            {
              staticClass: "detailCollapse",
              attrs: { accordion: "" },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-collapse-item",
                { attrs: { title: "审核日志", name: "1" } },
                [
                  _c("template", { slot: "title" }, [_vm._v("审核日志")]),
                  _vm._v(" "),
                  (_vm.checklist.length > 0 ? true : false)
                    ? _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            "max-height": "300",
                            data: _vm.checklist,
                            border: _vm.tableConfig.border,
                            stripe: _vm.tableConfig.stripe,
                            fit: "",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "审核结果",
                              prop: "type",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "审核人",
                              prop: "userName",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "审核时间",
                              prop: "checkTime",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "审核意见",
                              prop: "auditmsg",
                              "show-overflow-tooltip": "",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "default", plain: "" },
                  on: {
                    click: function ($event) {
                      _vm.scanVisible = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: "数码详情",
            visible: _vm.scanCodeVisible,
            width: "1200px !important",
            "before-close": _vm.codeClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.scanCodeVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "page-container" },
            [
              _c(
                "el-row",
                { attrs: { type: "flex", gutter: 10 } },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "page-section",
                      staticStyle: {
                        "padding-left": "0",
                        "padding-right": "0",
                      },
                    },
                    [
                      _c(
                        "el-form",
                        {
                          staticClass: "search-condition",
                          attrs: {
                            model: _vm.codeParam,
                            "label-position": _vm.formConfig.labelPosition,
                            "label-width": _vm.formConfig.labelWidth,
                          },
                        },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "数码", prop: "code" } },
                                    [
                                      _c("el-input", {
                                        attrs: { placeholder: "请输入" },
                                        model: {
                                          value: _vm.codeParam.code,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.codeParam, "code", $$v)
                                          },
                                          expression: "codeParam.code",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "产品名称",
                                        prop: "codeProductName",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          "suffix-icon": "el-icon-search",
                                          clearable: "",
                                          placeholder: "请输入",
                                        },
                                        on: {
                                          focus: function ($event) {
                                            return _vm.choseProName("codeScan")
                                          },
                                        },
                                        model: {
                                          value: _vm.codeProductName,
                                          callback: function ($$v) {
                                            _vm.codeProductName = $$v
                                          },
                                          expression: "codeProductName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "isError",
                                        label: "异常状态:",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          model: {
                                            value: _vm.codeParam.isError,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.codeParam,
                                                "isError",
                                                $$v
                                              )
                                            },
                                            expression: "codeParam.isError",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "正常",
                                              value: "1",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "异常",
                                              value: "0",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.formConfig.btnFollow
                                ? _c(
                                    "el-col",
                                    {
                                      staticClass: "head-btn-group",
                                      staticStyle: { "padding-left": "0" },
                                      attrs: { span: 6 },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { "label-width": "0" } },
                                        [
                                          _c(
                                            "div",
                                            {
                                              attrs: { slot: "label" },
                                              slot: "label",
                                            },
                                            [_vm._v(" ")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: _vm.btnConfig.searchBtn
                                                  .type,
                                                size: _vm.btnConfig.size,
                                                icon: _vm.btnConfig.searchBtn
                                                  .icon,
                                              },
                                              on: { click: _vm.searchCode },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.btnConfig.searchBtn.text
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("el-divider", {
                                            attrs: { direction: "vertical" },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: _vm.btnConfig.resetBtn
                                                  .type,
                                                size: _vm.btnConfig.size,
                                                icon: _vm.btnConfig.resetBtn
                                                  .icon,
                                              },
                                              on: { click: _vm.searchReset },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.btnConfig.resetBtn.text
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "btnArea" },
                        [
                          !_vm.formConfig.btnFollow
                            ? _c(
                                "el-col",
                                {
                                  staticClass: "head-btn-group",
                                  staticStyle: { width: "230px" },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.searchBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.searchBtn.icon,
                                      },
                                      on: { click: _vm.searchCode },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.btnConfig.searchBtn.text)
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("el-divider", {
                                    attrs: { direction: "vertical" },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.resetBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.resetBtn.icon,
                                      },
                                      on: { click: _vm.searchReset },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.btnConfig.resetBtn.text)
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.codeData,
                            border: _vm.tableConfig.border,
                            stripe: _vm.tableConfig.stripe,
                            fit: "",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "数码",
                              prop: "code",
                              align: _vm.tableConfig.align,
                              "min-width": "150",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "产品编号",
                              prop: "productCode",
                              align: _vm.tableConfig.align,
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "产品名称",
                              prop: "productName",
                              width: "120",
                              align: _vm.tableConfig.align,
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "产品分类",
                              prop: "productClass",
                              width: "120",
                              align: _vm.tableConfig.align,
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "规格",
                              prop: "productModel",
                              width: "100",
                              align: _vm.tableConfig.align,
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "状态",
                              prop: "isError",
                              align: _vm.tableConfig.align,
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _vm._v(
                                      _vm._s(row.isError == 1 ? "正常" : "异常")
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("pagination", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.total > 0,
                            expression: "total > 0",
                          },
                        ],
                        attrs: {
                          layout: "total, sizes, prev, pager, next, jumper",
                          total: _vm.total2,
                          page: _vm.codeParam.pageNo,
                          limit: _vm.codeParam.pageSize,
                        },
                        on: {
                          "update:page": function ($event) {
                            return _vm.$set(_vm.codeParam, "pageNo", $event)
                          },
                          "update:limit": function ($event) {
                            return _vm.$set(_vm.codeParam, "pageSize", $event)
                          },
                          pagination: _vm.getCodeDetail,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("purchaseDialog", {
        ref: "channelSelect",
        attrs: { title: "选择单位" },
        on: { change: _vm.getUalue },
      }),
      _vm._v(" "),
      _c("productChose", { ref: "selProduct", on: { change: _vm.getProduct } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n      "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }