"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SupplDetail = SupplDetail;
exports.SupplDisable = SupplDisable;
exports.SupplEnable = SupplEnable;
exports.auditList = auditList;
exports.batcRemoveAllSalesMan = batcRemoveAllSalesMan;
exports.batcRemoveSalesMan = batcRemoveSalesMan;
exports.batchUpdateSalesMan = batchUpdateSalesMan;
exports.channelArchivesDelete = channelArchivesDelete;
exports.channelArchivesDetail = channelArchivesDetail;
exports.channelArchivesEnable = channelArchivesEnable;
exports.channelArchivesExportExcel = channelArchivesExportExcel;
exports.channelArchivesInsert = channelArchivesInsert;
exports.channelArchivesList = channelArchivesList;
exports.channelArchivesUpdate = channelArchivesUpdate;
exports.channelClassInsert = channelClassInsert;
exports.channelClassList = channelClassList;
exports.channelClassStatus = channelClassStatus;
exports.channelClassUpdate = channelClassUpdate;
exports.channelCollectionSupplyList = channelCollectionSupplyList;
exports.channelCollectionSupplyenableProductBrand = channelCollectionSupplyenableProductBrand;
exports.channelCollectionSupplyexportExcel = channelCollectionSupplyexportExcel;
exports.channelCollectionSupplyimportExcel = channelCollectionSupplyimportExcel;
exports.channelCollectionSupplyinsert = channelCollectionSupplyinsert;
exports.channelCollectionSupplyupdate = channelCollectionSupplyupdate;
exports.channelGradeInsert = channelGradeInsert;
exports.channelGradeList = channelGradeList;
exports.channelGradeStatus = channelGradeStatus;
exports.channelGradeUpdate = channelGradeUpdate;
exports.checkStrong = checkStrong;
exports.comBoxSalesMan = comBoxSalesMan;
exports.comBoxSalesManlist = comBoxSalesManlist;
exports.deleteChannelCollectionSupplyBatch = deleteChannelCollectionSupplyBatch;
exports.detailList = detailList;
exports.disableProduct = disableProduct;
exports.enableProduct = enableProduct;
exports.findAllSalesRegion = findAllSalesRegion;
exports.getChannelAndOrg = getChannelAndOrg;
exports.getChannelUserList = getChannelUserList;
exports.getCompanyUserList = getCompanyUserList;
exports.getDetailByCode = getDetailByCode;
exports.getUserByRoleCode = getUserByRoleCode;
exports.grouplist = grouplist;
exports.insertGroup = insertGroup;
exports.insertProduct = insertProduct;
exports.jcChannelArchivesAudit = jcChannelArchivesAudit;
exports.listFromCustGroup = listFromCustGroup;
exports.querySalesManById = querySalesManById;
exports.selectChannelAndOrg = selectChannelAndOrg;
exports.updateGroup = updateGroup;
var _request = _interopRequireDefault(require("@/utils/request"));
var modelUrl = '/api-basic';
// var modelUrl = ''

// 终端审核
function auditList(params) {
  return (0, _request.default)({
    url: modelUrl + '/jcChannelArchives/auditList',
    method: 'post',
    params: params
  });
}
function jcChannelArchivesAudit(data) {
  return (0, _request.default)({
    url: modelUrl + '/jcChannelArchives/audit',
    method: 'post',
    data: data
  });
}

/**
 *
 *
 * @export
 * @param {*} params
 * @returns
 */
function getChannelUserList(params) {
  return (0, _request.default)({
    url: '/api-user/userInfo/orgChannelList',
    method: 'get',
    params: params
  });
}

/**
 *
 *
 * @export
 * @param {*} params
 * @returns
 */
function getCompanyUserList(params) {
  return (0, _request.default)({
    url: '/api-user/userInfo/orgCompanylist',
    method: 'get',
    params: params
  });
}
function getUserByRoleCode(params) {
  return (0, _request.default)({
    url: '/api-user/userInfo/getUserByRoleCode',
    method: 'get',
    params: params
  });
}
function channelClassList(params) {
  return (0, _request.default)({
    url: modelUrl + '/jcChannelClass/getList',
    method: 'post',
    params: params
  });
}

/**
 *
 *
 * @export
 * @param {*} data
 * @returns
 */
function channelClassInsert(data) {
  return (0, _request.default)({
    url: modelUrl + '/jcChannelClass/insert',
    method: 'post',
    data: data
  });
}

/**
 *
 *
 * @export
 * @param {*} data
 * @returns
 */
function channelClassUpdate(data) {
  return (0, _request.default)({
    url: modelUrl + '/jcChannelClass/update',
    method: 'post',
    data: data
  });
}

/**
 *
 *
 * @export
 * @param {*} params
 * @returns
 */
function channelClassStatus(params) {
  return (0, _request.default)({
    url: modelUrl + '/jcChannelClass/status',
    method: 'post',
    params: params
  });
}

/**
 *
 *
 * @export
 * @param {*} params
 * @returns
 */
function channelGradeList(params) {
  return (0, _request.default)({
    url: modelUrl + '/jcChannelGrade/getList',
    method: 'post',
    params: params
  });
}

/**
 *
 *
 * @export
 * @param {*} data
 * @returns
 */
function channelGradeInsert(data) {
  return (0, _request.default)({
    url: modelUrl + '/jcChannelGrade/insert',
    method: 'post',
    data: data
  });
}
function channelGradeUpdate(data) {
  return (0, _request.default)({
    url: modelUrl + '/jcChannelGrade/update',
    method: 'post',
    data: data
  });
}
function channelGradeStatus(params) {
  return (0, _request.default)({
    url: modelUrl + '/jcChannelGrade/status',
    method: 'post',
    params: params
  });
}
function channelCollectionSupplyList(data) {
  return (0, _request.default)({
    url: modelUrl + '/channelCollectionSupply/list',
    method: 'post',
    data: data
  });
}
function channelCollectionSupplyexportExcel(data) {
  return (0, _request.default)({
    url: modelUrl + '/channelCollectionSupply/exportExcel',
    method: 'post',
    responseType: 'blob',
    data: data
  });
}

// 批量全部解除
function batcRemoveAllSalesMan(data) {
  return (0, _request.default)({
    url: '/api-basic/jcChannelArchives/batcRemoveAllSalesMan',
    method: 'post',
    data: data
  });
}
function channelCollectionSupplyimportExcel(data) {
  return (0, _request.default)({
    url: modelUrl + '/channelCollectionSupply/importExcel',
    method: 'post',
    data: data
  });
}
function channelCollectionSupplyinsert(data) {
  return (0, _request.default)({
    url: modelUrl + '/channelCollectionSupply/insert',
    method: 'post',
    data: data
  });
}
function channelCollectionSupplyupdate(data) {
  return (0, _request.default)({
    url: modelUrl + '/channelCollectionSupply/update',
    method: 'post',
    data: data
  });
}
function channelCollectionSupplyenableProductBrand(params) {
  return (0, _request.default)({
    url: modelUrl + '/channelCollectionSupply/enableProductBrand',
    method: 'get',
    params: params
  });
}
function channelArchivesList(params) {
  return (0, _request.default)({
    url: modelUrl + '/jcChannelArchives/list',
    method: 'post',
    params: params
  });
}
function channelArchivesInsert(data) {
  return (0, _request.default)({
    url: modelUrl + '/jcChannelArchives/insert',
    method: 'post',
    data: data
  });
}
function channelArchivesUpdate(data) {
  return (0, _request.default)({
    url: modelUrl + '/jcChannelArchives/update',
    method: 'post',
    data: data
  });
}
function channelArchivesDetail(params) {
  return (0, _request.default)({
    url: modelUrl + '/jcChannelArchives/detail',
    method: 'post',
    params: params
  });
}
function channelArchivesEnable(params) {
  return (0, _request.default)({
    url: modelUrl + '/jcChannelArchives/enable',
    method: 'post',
    params: params
  });
}
function channelArchivesDelete(params) {
  return (0, _request.default)({
    url: modelUrl + '/jcChannelArchives/delete',
    method: 'post',
    params: params
  });
}
function channelArchivesExportExcel(params) {
  return (0, _request.default)({
    url: modelUrl + '/jcChannelArchives/exportExcel',
    responseType: 'blob',
    method: 'post',
    params: params
  });
}

// 选择采购商供货商
function selectChannelAndOrg(data) {
  return (0, _request.default)({
    url: modelUrl + '/channelCollectionSupply/selectChannelAndOrg',
    method: 'post',
    data: data
  });
}

// 采购订单收发货单位
function getChannelAndOrg(data) {
  return (0, _request.default)({
    url: '/api-basic/channelCollectionSupply/selectChannelAndOrg',
    method: 'post',
    data: data
  });
}

// 强弱验证
function checkStrong(params) {
  return (0, _request.default)({
    url: '/api-basic/jcChannelArchives/checkStrong',
    method: 'post',
    params: params
  });
}

// 渠道强弱配置项 展示
function detailList(params) {
  return (0, _request.default)({
    url: '/api-basic/codeCheckConfig/detailList',
    method: 'get',
    params: params
  });
}
// 采供关系详情
function SupplDetail(params) {
  return (0, _request.default)({
    url: '/api-basic/channelCollectionSupply/detail',
    method: 'post',
    data: params
  });
}
// 采供关系产品启用
function enableProduct(params) {
  return (0, _request.default)({
    url: '/api-basic/jcChannelCollectionProd/enableProduct',
    method: 'get',
    params: params
  });
}
// 采供关系产品禁用
function disableProduct(params) {
  return (0, _request.default)({
    url: '/api-basic/jcChannelCollectionProd/disableProduct',
    method: 'get',
    params: params
  });
}
// 采供关系添加产品
function insertProduct(params) {
  return (0, _request.default)({
    url: '/api-basic/jcChannelCollectionProd/add',
    method: 'post',
    data: params
  });
}
// 采供关系启用
function SupplEnable(params) {
  return (0, _request.default)({
    url: '/api-basic/channelCollectionSupply/enable',
    method: 'get',
    params: params
  });
}
// 采供关系禁用
function SupplDisable(params) {
  return (0, _request.default)({
    url: '/api-basic/channelCollectionSupply/disable',
    method: 'get',
    params: params
  });
}
// 采供关系批量删除
function deleteChannelCollectionSupplyBatch(data) {
  return (0, _request.default)({
    url: '/api-basic/channelCollectionSupply/batchDelete',
    method: 'post',
    data: data
  });
}
// 客户组管理
function grouplist(data) {
  return (0, _request.default)({
    url: "/api-basic/channelGroup/getPageList",
    method: 'post',
    params: data
  });
}
function getDetailByCode(data) {
  return (0, _request.default)({
    url: "/api-basic/channelGroup/getDetailByCode",
    method: 'post',
    params: data
  });
}
function insertGroup(data) {
  return (0, _request.default)({
    url: "/api-basic/channelGroup/insertChannelGroup",
    method: 'post',
    data: data
  });
}
function updateGroup(data) {
  return (0, _request.default)({
    url: "/api-basic/channelGroup/updateChannelGroup",
    method: 'post',
    data: data
  });
}

// export function listFromCustGroup(data) {
//   return request({
//     url: `/api-basic/jcChannelArchives/listFromCustGroup`,
//     method: 'post',
//     data: data
//   })
// }

function listFromCustGroup(params) {
  return (0, _request.default)({
    url: "/api-basic/channelGroup/detail",
    method: 'post',
    params: params
  });
}

// 业务员详情
function comBoxSalesMan(current, size, data) {
  return (0, _request.default)({
    url: "/api-basic/jcChannelArchives/comBoxSalesMan?current=".concat(current, "&size=").concat(size),
    method: 'post',
    data: data
  });
}
// 业务员详情
function comBoxSalesManlist(current, size, data) {
  return (0, _request.default)({
    url: "/api-basic/jcChannelArchives/comBoxListSalesMan?current=".concat(current, "&size=").concat(size),
    method: 'post',
    data: data
  });
}

// 业务员弹出详情
function querySalesManById(params) {
  return (0, _request.default)({
    url: '/api-basic/jcChannelArchives/querySalesManById',
    method: 'get',
    params: params
  });
}

// 业务员批量变更
function batchUpdateSalesMan(data) {
  return (0, _request.default)({
    url: '/api-basic/jcChannelArchives/batchUpdateSalesMan',
    method: 'post',
    data: data
  });
}

// 业务员批量解除
function batcRemoveSalesMan(data) {
  return (0, _request.default)({
    url: '/api-basic/jcChannelArchives/batcRemoveSalesMan',
    method: 'post',
    data: data
  });
}

// 业务员批量解除
function findAllSalesRegion(data) {
  return (0, _request.default)({
    url: '/api-basic/salesRegion/findAllSalesRegion',
    method: 'post',
    data: data
  });
}