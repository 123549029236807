"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkQuantity = void 0;
exports.deleteCheck = deleteCheck;
exports.deleteProductClass = deleteProductClass;
exports.getproductstatus = getproductstatus;
exports.insertProductRecord = insertProductRecord;
exports.productArchivesDel = productArchivesDel;
exports.productArchivesEnableProductArchives = productArchivesEnableProductArchives;
exports.productArchivesExportExcel = productArchivesExportExcel;
exports.productArchivesInsert = productArchivesInsert;
exports.productArchivesList = productArchivesList;
exports.productArchivesUpdate = productArchivesUpdate;
exports.productBrandAllListList = productBrandAllListList;
exports.productBrandEnable = productBrandEnable;
exports.productBrandInsert = productBrandInsert;
exports.productBrandList = productBrandList;
exports.productBrandUpdate = productBrandUpdate;
exports.productClassEnable = productClassEnable;
exports.productClassInsert = productClassInsert;
exports.productClassList = productClassList;
exports.productClassUpdate = productClassUpdate;
exports.productRecordList = productRecordList;
exports.productRecordPage = productRecordPage;
exports.saveproductconfig = saveproductconfig;
var _request = _interopRequireDefault(require("@/utils/request"));
var modelUrl = '/api-basic';
// var modelUrl = ''
// 分类

function productArchivesExportExcel(data) {
  return (0, _request.default)({
    url: modelUrl + '/productArchives/exportExcel',
    responseType: 'blob',
    method: 'post',
    data: data
  });
}
function productClassList(data) {
  return (0, _request.default)({
    url: modelUrl + '/productClass/list',
    method: 'post',
    data: data
  });
}
function productClassInsert(data) {
  return (0, _request.default)({
    url: modelUrl + '/productClass/insert',
    method: 'post',
    data: data
  });
}
function productClassUpdate(data) {
  return (0, _request.default)({
    url: modelUrl + '/productClass/update',
    method: 'post',
    data: data
  });
}
function productClassEnable(params) {
  return (0, _request.default)({
    url: modelUrl + '/productClass/enableProductClass',
    method: 'get',
    params: params
  });
}

// 品牌
function productBrandList(data) {
  return (0, _request.default)({
    url: modelUrl + '/productBrand/list',
    method: 'post',
    data: data
  });
}
function productBrandAllListList(data) {
  return (0, _request.default)({
    url: modelUrl + '/productBrand/allList',
    method: 'post',
    data: data
  });
}
function productBrandInsert(data) {
  return (0, _request.default)({
    url: modelUrl + '/productBrand/insert',
    method: 'post',
    data: data
  });
}
function productBrandUpdate(data) {
  return (0, _request.default)({
    url: modelUrl + '/productBrand/update',
    method: 'post',
    data: data
  });
}
function productBrandEnable(params) {
  return (0, _request.default)({
    url: modelUrl + '/productBrand/enableProductBrand',
    method: 'get',
    params: params
  });
}

// 档案
function productArchivesList(data) {
  return (0, _request.default)({
    url: modelUrl + '/productArchives/list',
    method: 'post',
    data: data
  });
}
function productArchivesInsert(data) {
  return (0, _request.default)({
    url: modelUrl + '/productArchives/insert',
    method: 'post',
    data: data
  });
}
function productArchivesUpdate(data) {
  return (0, _request.default)({
    url: modelUrl + '/productArchives/update',
    method: 'post',
    data: data
  });
}
function productArchivesEnableProductArchives(data) {
  return (0, _request.default)({
    url: modelUrl + '/productArchives/enableProductArchives',
    method: 'post',
    data: data
  });
}
function productArchivesDel(params) {
  return (0, _request.default)({
    url: modelUrl + '/productArchives/deleteProductArchives',
    method: 'get',
    params: params
  });
}

// 删除产品分类学
function deleteProductClass(params) {
  return (0, _request.default)({
    url: modelUrl + '/productClass/deleteProductClass',
    method: 'get',
    params: params
  });
}

// 数据删除校验
function deleteCheck(params) {
  return (0, _request.default)({
    url: modelUrl + '/productUserDefinedConfig/deleteCheck',
    method: 'get',
    params: params
  });
}
// 产品配置列表查询
function getproductstatus(params) {
  return (0, _request.default)({
    url: modelUrl + '/productUserDefinedConfig/getListByStatus',
    method: 'get',
    params: params
  });
}

// 保存配置
function saveproductconfig(data) {
  return (0, _request.default)({
    url: modelUrl + '/productUserDefinedConfig/save',
    method: 'post',
    data: data
  });
}

// 新增产品备案信息
function insertProductRecord(data) {
  return (0, _request.default)({
    url: modelUrl + '/productRecord/insert',
    method: 'post',
    data: data
  });
}

// 产品备案信息列表-分页
function productRecordPage(data) {
  return (0, _request.default)({
    url: modelUrl + '/productRecord/list',
    method: 'post',
    data: data
  });
}
// 新增产品备案信息列表-不分页
function productRecordList(data) {
  return (0, _request.default)({
    url: modelUrl + '/productRecord/findAllProductRecord',
    method: 'post',
    data: data
  });
}
var checkQuantity = function checkQuantity(rule, value, callback) {
  if (value) {
    var reg = /(^$)|^[0-9]+(.?[0-9]{1,2})?$/;
    if (reg.test(value)) {
      callback();
    } else {
      return callback(new Error('请输入正数，且小数点后最多保留2位'));
    }
  } else {
    return callback(new Error("\u4E0D\u80FD\u4E3A\u7A7A\uFF0C\u8BF7\u7EF4\u62A4"));
  }
};
exports.checkQuantity = checkQuantity;