"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _specAddOrUpdate = _interopRequireDefault(require("./spec-add-or-update"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _wXintegralMall = require("@/api/wxhyyy/wXintegralMall");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
/* eslint-disable no-unused-vars */
var _default = {
  components: {
    AddOrUpdate: _specAddOrUpdate.default,
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      listLoading: false,
      dataForm: {
        prodProp: ''
      },
      dataList: [],
      current: 1,
      size: 10,
      totalPage: 0,
      dataListSelections: [],
      addOrUpdateVisible: false,
      total: 0,
      // 总页数
      formInline: {
        propName: null,
        current: 1,
        // 当前页数
        size: 10 // 每页显示多少条
      }
    };
  },
  mounted: function mounted() {
    this.fetchData();
  },
  methods: {
    restForm: function restForm(refname) {
      this.$refs[refname].resetFields();
    },
    // 获取数据列表
    fetchData: function fetchData() {
      var _this = this;
      this.listLoading = true;
      (0, _wXintegralMall.specList)(this.formInline).then(function (data) {
        var data = data.data;
        _this.dataList = data.records;
        _this.total = data.total;
        _this.listLoading = false;
      });
    },
    // 新增 / 修改
    addOrUpdateHandle: function addOrUpdateHandle(val) {
      var _this2 = this;
      this.addOrUpdateVisible = true;
      this.$nextTick(function () {
        _this2.$refs.addOrUpdate.init(val);
      });
    },
    // 删除
    deleteHandle: function deleteHandle(id) {
      var _this3 = this;
      var ids = id ? [id] : this.dataListSelections.map(function (item) {
        return item.propId;
      });
      this.$confirm("\u786E\u5B9A\u5220\u9664\u8BE5\u89C4\u683C?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _wXintegralMall.delSpec)(id).then(function (data) {
          _this3.$message({
            message: '操作成功',
            type: 'success',
            duration: 1500,
            onClose: function onClose() {
              _this3.fetchData(_this3.page);
            }
          });
        });
      }).catch(function () {});
    },
    searchChange: function searchChange(params) {
      this.fetchData(this.formInline);
    }
  }
};
exports.default = _default;