"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _orderDevy = _interopRequireDefault(require("./order-devy"));
var _orderInfo = _interopRequireDefault(require("./orderInfo"));
var _consignmentInfo = _interopRequireDefault(require("./consignment-info"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _wXintegralMall = require("@/api/wxhyyy/wXintegralMall");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    DevyAdd: _orderDevy.default,
    AddOrUpdate: _orderInfo.default,
    ConsignmentInfo: _consignmentInfo.default,
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      devyVisible: false,
      total: 0,
      // 总页数
      formInline: {
        memberId: "",
        current: 1,
        // 当前页数
        size: 10,
        // 每页显示多少条
        dateRange: null
      },
      options: [{
        value: "",
        label: "全部"
      }, {
        value: 1,
        label: "待支付"
      }, {
        value: 2,
        label: "待发货"
      },
      // {
      //   value: 4,
      //   label: '待评价'
      // },
      {
        value: 3,
        label: "待收货"
      }, {
        value: 5,
        label: "已完成"
      }],
      dataList: [],
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      consignmentInfoVisible: false
    };
  },
  created: function created() {
    this.getDataList();
  },
  methods: {
    restForm: function restForm(refname) {
      this.$refs[refname].resetFields();
    },
    // 获取数据列表
    getDataList: function getDataList() {
      var _this = this;
      // console.log(this.$route.query.id)
      this.formInline.memberId = this.$route.query.id;
      this.dataListLoading = true;
      if (this.formInline.dateRange === null) {
        this.formInline.startTime = null;
        this.formInline.endTime = null;
      } else {
        this.formInline.startTime = this.formInline.dateRange[0]; // 开始时间
        this.formInline.endTime = this.formInline.dateRange[1]; // 结束时间
      }

      (0, _wXintegralMall.orderList)(this.formInline).then(function (data) {
        var data = data.data;
        console.log(data);
        _this.dataList = data.records;
        _this.total = data.total;
        _this.dataListLoading = false;
      });
    },
    orderStatus: function orderStatus() {},
    // 新增 / 修改
    addOrUpdateHandle: function addOrUpdateHandle(val, yf) {
      var _this2 = this;
      this.addOrUpdateVisible = true;
      this.$nextTick(function () {
        _this2.$refs.addOrUpdate.init(val, yf);
      });
    },
    changeOrder: function changeOrder(orderNumber, yf) {
      var _this3 = this;
      this.devyVisible = true;
      this.$nextTick(function () {
        _this3.$refs.devyAdd.init(orderNumber, _this3.dvyId, _this3.dvyFlowId, yf);
      });
    },
    // 删除
    deleteHandle: function deleteHandle(id) {
      var _this4 = this;
      var ids = id ? [id] : this.dataListSelections.map(function (item) {
        return item.orderId;
      });
      this.$confirm("\u786E\u5B9A\u8FDB\u884C[".concat(id ? "删除" : "批量删除", "]\u64CD\u4F5C?"), "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _wXintegralMall.delspec)(ids).then(function (data) {
          _this4.$message({
            message: "操作成功",
            type: "success",
            duration: 1500,
            onClose: function onClose() {
              _this4.getDataList(_this4.page);
            }
          });
        });
      }).catch(function () {});
    },
    showConsignmentInfo: function showConsignmentInfo() {
      var _this5 = this;
      this.consignmentInfoVisible = true;
      this.$nextTick(function () {
        _this5.$refs.consignmentInfo.init();
      });
    },
    getWaitingConsignmentExcel: function getWaitingConsignmentExcel(consignmentInfo) {
      (0, _wXintegralMall.waitingConsignmentExcel)({
        // consignmentName: consignmentInfo.consignmentName,
        // consignmentMobile: consignmentInfo.consignmentMobile,
        // consignmentAddr: consignmentInfo.consignmentAddr,
        startTime: this.formInline.dateRange === null ? null : this.formInline.dateRange[0],
        // 开始时间
        endTime: this.formInline.dateRange === null ? null : this.formInline.dateRange[1] // 结束时间
      }).then(function (data) {
        var data = data.data;
        var blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
        });
        var fileName = "待发货信息整理.xls";
        var elink = document.createElement("a");
        if ("download" in elink) {
          // 非IE下载
          elink.download = fileName;
          elink.style.display = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href); // 释放URL 对象
          document.body.removeChild(elink);
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName);
        }
      });
    },
    getSoldExcel: function getSoldExcel() {
      (0, _wXintegralMall.soldExcel)({
        status: 5,
        startTime: this.formInline.dateRange === null ? null : this.formInline.dateRange[0],
        // 开始时间
        endTime: this.formInline.dateRange === null ? null : this.formInline.dateRange[1] // 结束时间
      }).then(function (data) {
        var data = data.data;
        var blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
        });
        var fileName = "销售信息整理.xls";
        var elink = document.createElement("a");
        if ("download" in elink) {
          // 非IE下载
          elink.download = fileName;
          elink.style.display = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href); // 释放URL 对象
          document.body.removeChild(elink);
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName);
        }
      });
    }
  }
};
exports.default = _default;