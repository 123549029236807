var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c(
            "el-row",
            { staticClass: "flex-warp", attrs: { type: "flex", gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  staticStyle: {
                    "min-width": "650px",
                    "margin-bottom": "20px",
                  },
                  attrs: { xl: 12 },
                },
                [
                  _c(
                    "el-card",
                    {
                      staticClass: "box-card",
                      attrs: { "body-style": _vm.cardStyle },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "clearfix",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [_c("span", [_vm._v("对象存储接口")])]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "formWrap" },
                        [
                          _c(
                            "el-form",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.yxloading,
                                  expression: "yxloading",
                                },
                              ],
                              ref: "yxData",
                              staticClass: "info-items",
                              staticStyle: { width: "600px", margin: "0 auto" },
                              attrs: {
                                model: _vm.yxData,
                                rules: _vm.yxRules,
                                "label-position": "right",
                                "label-width": "100px",
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "OSS:", prop: "storeName" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.yxData.storeName,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.yxData, "storeName", $$v)
                                        },
                                        expression: "yxData.storeName",
                                      },
                                    },
                                    _vm._l(_vm.typeList, function (item) {
                                      return _c("el-option", {
                                        key: item.pid,
                                        attrs: {
                                          label: item.name,
                                          value: item.name,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Secret:",
                                    prop: "accessSecret",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      maxlength: "50",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.yxData.accessSecret,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.yxData,
                                          "accessSecret",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "yxData.accessSecret",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Client ID:",
                                    prop: "accessKey",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      maxlength: "50",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.yxData.accessKey,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.yxData,
                                          "accessKey",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "yxData.accessKey",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Endpoint:",
                                    prop: "endpoint",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      maxlength: "50",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.yxData.endpoint,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.yxData,
                                          "endpoint",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "yxData.endpoint",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "Bucket:" } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { "margin-bottom": "22px" },
                                      attrs: {
                                        label: "Mark:",
                                        prop: "markBucketName",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          maxlength: "50",
                                          placeholder: "请输入",
                                        },
                                        model: {
                                          value: _vm.yxData.markBucketName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.yxData,
                                              "markBucketName",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "yxData.markBucketName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { "margin-bottom": "22px" },
                                      attrs: {
                                        label: "Prod:",
                                        prop: "prodBucketName",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          maxlength: "50",
                                          placeholder: "请输入",
                                        },
                                        model: {
                                          value: _vm.yxData.prodBucketName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.yxData,
                                              "prodBucketName",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "yxData.prodBucketName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { "margin-bottom": "22px" },
                                      attrs: {
                                        label: "img:",
                                        prop: "imgBucketName",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          maxlength: "50",
                                          placeholder: "请输入",
                                        },
                                        model: {
                                          value: _vm.yxData.imgBucketName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.yxData,
                                              "imgBucketName",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "yxData.imgBucketName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { "margin-bottom": "22px" },
                                      attrs: {
                                        label: "Default:",
                                        prop: "defaultBucketName",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          maxlength: "50",
                                          placeholder: "请输入",
                                        },
                                        model: {
                                          value: _vm.yxData.defaultBucketName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.yxData,
                                              "defaultBucketName",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression:
                                            "yxData.defaultBucketName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("el-divider", { staticClass: "btnDivider" }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "dialog-footer" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "success",
                                    loading: _vm.yxbtnLoading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.submit("yx")
                                    },
                                  },
                                },
                                [_vm._v("提交")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }