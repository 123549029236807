"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _basic = require("@/api/mark/basic.js");
var _pack = require("@/api/basic/pack");
var _supplierRFlag = require("@/api/mark/configure/supplierRFlag");
var _typeConfig = require("@/api/mark/configure/typeConfig");
var _label = require("@/api/mark/coding_service/coding_apply_mange/label");
var _waves = _interopRequireDefault(require("@/directive/waves"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var setTemp = {
  packScaleDesc: '',
  packScaleExpression: '',
  packCode: '',
  flagName: ''
};
var codeTypeList = [];
var packNum = {
  2: 0,
  3: 0,
  4: 0
};
var _default = {
  name: 'ComplexTable',
  components: {
    Pagination: _Pagination.default
  },
  directives: {
    waves: _waves.default
  },
  filters: {
    formatDate: function formatDate(time) {
      if (!time) return '';
      var date = new Date(time);
      return (0, _basic.formatDate)(date, 'yyyy-MM-dd hh:mm:ss');
    },
    codeType: function codeType(status) {
      if (!String(status)) return '';
      var statusMap = {
        1: '营销码',
        2: '防伪码',
        3: '物流码'
      };
      return statusMap[status];
    },
    applyStyle: function applyStyle(status) {
      if (!String(status)) return '';
      var statusMap = {
        1: '套标申请',
        2: '后关联申请',
        3: '制盖申请'
      };
      return statusMap[status];
    },
    applicationType: function applicationType(status) {
      if (!String(status)) return '';
      var statusMap = {
        1: '测试',
        2: '正常生产'
      };
      return statusMap[status];
    },
    generateStatus: function generateStatus(status) {
      if (!String(status)) return '';
      var statusMap = {
        1: '未生成',
        2: '生成成功',
        3: '生成失败'
      };
      return statusMap[status];
    },
    packSum: function packSum(level, ordNum) {
      if (!level || !ordNum) return '';
      if (level == 5) return '暂无';
      return (packNum[level] * ordNum).toFixed(2);
    },
    levelType: function levelType(packLevel, markType) {
      if (!packLevel || !markType) return '';
      var statusPack = {
        1: '盖',
        2: '只',
        3: '盒',
        4: '箱',
        5: '托',
        6: '组'
      };
      var statusMark = {
        1: '内层级',
        2: '层级',
        3: '外层级'
      };
      var item = codeTypeList.filter(function (number) {
        return number.markType == markType && number.packType == packLevel;
      });
      return statusPack[packLevel] + statusMark[markType] + '-' + item[0].markLen + '位';
    }
  },
  props: ['id', 'dialogApplyVisible'],
  data: function data() {
    return {
      imgUrl: require("../../../../images/origin.png"),
      active: 0,
      tableKey: 2,
      flagelist: null,
      logList: null,
      temp: {
        PId: '',
        isPlatform: 1,
        applicationType: '',
        applyMarkType: '1',
        applyStatus: '',
        applyStyle: 1,
        bottleNum: '',
        boxNum: '',
        chestNum: '',
        expireDate: '',
        markOrderNo: '',
        orderNum: '',
        supplierCode: '',
        supplierName: '',
        packRatioCode: '',
        flagGroupId: ''
      },
      setTemp: setTemp,
      codelistLoading: false,
      bottleNum: 1,
      boxNum: 1,
      chestNum: 1,
      btn_loading: false,
      loading: false,
      showOrderNum: true,
      showafterBox: false,
      showCareNum: false,
      showBoxNum: false,
      showBottleNum: false,
      packList: [],
      textMap: {
        create: '制码申请',
        set: '查看详情',
        edit: '编辑制码申请'
      },
      groupInnerVisible: false,
      total: 0,
      listQuery: {
        current: 1,
        size: 20,
        flagId: '',
        supplierType: 1
      },
      supplierQuery: {
        supplierName: '',
        supplierType: 1
      }
      // supplierList: []
    };
  },

  watch: {
    'temp.PId': function tempPId(newVal, oldVal) {
      if (Number(newVal) == 0) {
        this.Reset();
      } else {
        this.Details();
      }
    } // 'temp.flagGroupCode'(newVal, oldVal) {
    //   this.getSelectCode(newVal)
    // }
  },
  mounted: function mounted() {
    var _this = this;
    this.getCodeType();
    // this.getSupplier()
    // if (this.dialogStatus == 'set') {
    //   this.dataFormdisabled = true
    // } else if (this.dialogStatus == 'edit' || this.dialogStatus == 'create') {
    //   this.dataFormdisabled = false
    // }
    (0, _pack.packScaleLeveAlllList)().then(function (res) {
      if (res.data.code != 200) {
        _this.$message.error(res.data.msg);
        return;
      } else {
        if (res.data.data != null) {
          _this.packList = res.data.data;
        } else {
          _this.packList = [];
        }
      }
    }).then(function () {
      // if (this.dialogStatus == 'set' || this.dialogStatus == 'edit') {
      _this.loading = true;
      _this.getDetail();
      // }
    });
  },

  methods: {
    getDetail: function getDetail() {
      var _this2 = this;
      (0, _label.getMarkApplyDetail)({
        pId: this.id
      }).then(function (res) {
        if (res.data.code == 200) {
          _this2.temp = Object.assign(_this2.temp, res.data.data);
          _this2.temp.PId = _this2.temp.pid;
        } else {
          _this2.$message.error(res.data.msg);
        }
        _this2.loading = false;
      }).catch(function () {
        _this2.loading = false;
      });
    },
    // getSupplier() {
    //   supplierRFlagList({
    //     pageNo: 1,
    //     pageSize: 2147483647,
    //     supplierName: '',
    //     supplierType: '',
    //     startTime: '',
    //     endTime: ''
    //   })
    //     .then(response => {
    //       var data = response.data
    //       this.list = null
    //       if (data.code != 200) {
    //         this.$message.error(data.msg)
    //         return true
    //       }
    //       if (data.data != null) {
    //         this.supplierList = data.data.records
    //       } else {
    //         this.supplierList = []
    //       }
    //     })
    //     .catch(() => {})
    // },
    // supplierChange(val) {
    //   var item = this.supplierList.filter(function(number) {
    //     return number.supplierCode == val
    //   })
    //   this.temp.supplierName = item[0].supplierName
    //   this.flagelist = []
    // },
    getCodeType: function getCodeType() {
      var _this3 = this;
      (0, _typeConfig.markTypeInfoqueryForList)().then(function (response) {
        var data = response.data;
        if (data.code != 200) {
          _this3.$message.error(data.msg);
        } else {
          if (data.data != null && data.data.length > 0) {
            codeTypeList = JSON.parse(JSON.stringify(data.data));
          } else {
            codeTypeList = [];
          }
        }
      });
    },
    getapplyType: function getapplyType(v, status) {
      this.showOrderNum = true;
      this.showafterBox = false;
      this.showCareNum = false;
      this.showBoxNum = false;
      this.showBottleNum = false;
      this.temp.bottleNum = '';
      this.temp.boxNum = '';
      this.temp.chestNum = '';
      this.flagelist = [];
      if (!status) {
        this.temp.orderNum = '';
        this.temp.supplierCode = '';
        this.temp.supplierName = '';
        this.supplierQuery.supplierType = 1;
        this.listQuery.supplierType = 1;
        this.temp.applyMarkType = 1;
        this.bottleNum = 1;
        this.boxNum = 1;
        this.chestNum = 1;
      }
      if (v == 1) {
        this.supplierQuery.supplierType = 1;
        this.listQuery.supplierType = 1;
      }
      if (v == 2) {
        this.supplierQuery.supplierType = 1;
        this.listQuery.supplierType = 1;
        this.showOrderNum = false;
        this.showafterBox = true;
      }
      if (v == 3) {
        this.supplierQuery.supplierType = 2;
        this.listQuery.supplierType = 2;
        this.temp.applyMarkType = 2;
      }
    },
    packCodeVal: function packCodeVal(packCode) {
      var _this4 = this;
      var item = this.packList.filter(function (number) {
        return number.pId == packCode;
      });
      if (item.length) {
        this.setTemp.packScaleDesc = item[0].packScaleDesc;
        this.setTemp.packScaleName = item[0].packScaleExpression;
        (0, _pack.getLevelById)({
          pId: item[0].pId
        }).then(function (res) {
          var obj = {
            2: 0,
            3: 0,
            4: 0
          };
          if (res.data.code == 200 && res.data.data && res.data.data.packScaleLevelItem && res.data.data.packScaleLevelItem.length > 0) {
            res.data.data.packScaleLevelItem.forEach(function (item) {
              obj[item.topClass * 1 + 1] = item.packNum * 1;
            });
          }
          packNum = obj;
          _this4.flagelist = JSON.parse(JSON.stringify(_this4.flagelist));
        });
      }
    },
    // showLabelCode() {
    //   // 显示标签组页面
    //   if (this.temp.supplierCode) {
    //     this.groupInnerVisible = true
    //     this.$nextTick(() => {
    //       this.$refs.labelGroupMain.getLabelList()
    //     })
    //   } else {
    //     this.$message.error('请先选择码包下载供应商')
    //   }
    // },
    getModelList: function getModelList() {
      var _this5 = this;
      var that = this;
      that.modeLoading = true;
      (0, _supplierRFlag.flagTemplatePage)(that.listQuery).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.total = parseInt(response.data.data.total);
            that.flagelist = response.data.data.records;
          } else {
            that.total = 0;
            that.flagelist = [];
          }
          if (_this5.temp.applyStyle == 2) {
            for (var i in _this5.flagelist) {
              if (_this5.flagelist[i].packLevel == 2) {
                _this5.showBottleNum = true;
                _this5.bottleNum = _this5.bottleNum == 1 ? 1 : _this5.bottleNum;
              }
              if (_this5.flagelist[i].packLevel == 3) {
                _this5.showBoxNum = true;
                _this5.boxNum = _this5.boxNum == 1 ? 1 : _this5.boxNum;
              }
              if (_this5.flagelist[i].packLevel == 4) {
                _this5.showCareNum = true;
                _this5.chestNum = _this5.chestNum == 1 ? 1 : _this5.chestNum;
              }
            }
          }
          that.modeLoading = false;
        }
      }).catch(function (res) {
        that.modeLoading = false;
      });
    },
    // getSelectCode($code) {
    //   var code = this.$refs.labelGroupMain.flagGroupId
    //   this.temp.flagGroupId = code
    //   this.listQuery.flagId = code
    //   if (code) {
    //     this.getModelList()
    //   } else {
    //     this.flagelist = []
    //     this.total = 0
    //   }
    //   this.$refs.dataForm.validateField('flagGroupId')
    //   this.labelClose()
    // },
    // Reset() {
    //   if (this.$refs.dataForm != undefined) {
    //   }
    // },
    CloseMain: function CloseMain() {
      // this.Reset()
      this.setTemp.packScaleDesc = '';
      this.temp = {
        PId: '',
        isPlatform: 1,
        applicationType: '',
        applyMarkType: 1,
        applyStatus: '',
        applyStyle: 1,
        bottleNum: '',
        boxNum: '',
        chestNum: '',
        expireDate: '',
        markOrderNo: '',
        orderNum: '',
        supplierCode: '',
        supplierName: '',
        packRatioCode: '',
        flagGroupId: ''
      };
      this.flagelist = [];
      // this.dialogApplyVisible = false
      this.groupInnerVisible = false;
      this.logList = null;
      this.showOrderNum = true;
      this.showafterBox = false;
      this.showCareNum = false;
      this.showBoxNum = false;
      this.showBottleNum = false;
      this.$emit('close');
    },
    handleClose: function handleClose(done) {
      this.CloseMain();
    },
    // labelClose() {
    //   this.$refs.labelGroupMain.flagGroupId = ''
    //   this.$refs.labelGroupMain.$refs.list_con.modelList = []
    //   this.$refs.labelGroupMain.$refs.list_con.total = 0
    //   this.total = 0
    //   this.groupInnerVisible = false
    // },
    // numChange() {
    //   this.flagelist = JSON.parse(JSON.stringify(this.flagelist))
    // },
    // createData() {
    //   this.$refs['dataForm'].validate(valid => {
    //     if (valid) {
    //       this.btn_loading = true
    //       if (this.temp.applyStyle == 2) {
    //         this.temp.orderNum = ''
    //         if (this.showBottleNum) {
    //           this.temp.bottleNum = this.bottleNum
    //         } else {
    //           this.temp.bottleNum = 0
    //         }
    //         if (this.showBoxNum) {
    //           this.temp.boxNum = this.boxNum
    //         } else {
    //           this.temp.boxNum = 0
    //         }
    //         if (this.showCareNum) {
    //           this.temp.chestNum = this.chestNum
    //         } else {
    //           this.temp.chestNum = 0
    //         }
    //       }
    //       if (this.temp.applyMarkType == 1) {
    //         create(this.temp)
    //           .then(res => {
    //             this.btn_loading = false
    //             var data = res.data
    //             if (data.code != 200) {
    //               this.$message.error(data.msg)
    //               return false
    //             }
    //             this.$message({ message: '操作成功', type: 'success' })
    //             this.$parent.getList()
    //             this.CloseMain()
    //           })
    //           .catch(() => {
    //             this.btn_loading = false
    //           })
    //       } else {
    //         capInsert(this.temp)
    //           .then(res => {
    //             this.btn_loading = false
    //             var data = res.data
    //             if (data.code != 200) {
    //               this.$message.error(data.msg)
    //               return false
    //             }
    //             this.$message({ message: '操作成功', type: 'success' })
    //             this.$parent.getList()
    //             this.CloseMain()
    //           })
    //           .catch(() => {
    //             this.btn_loading = false
    //           })
    //       }
    //     }
    //   })
    // },
    // updateData() {
    //   this.$refs['dataForm'].validate(valid => {
    //     if (valid) {
    //       this.btn_loading = true
    //       this.temp.PId = this.temp.pid
    //       delete this.temp.createTime
    //       delete this.temp.creatorId
    //       delete this.temp.updateTime
    //       delete this.temp.updaterId
    //       delete this.temp.pid
    //       if (this.temp.applyStyle == 2) {
    //         this.temp.orderNum = ''
    //         if (this.showBottleNum) {
    //           this.temp.bottleNum = this.bottleNum
    //         } else {
    //           this.temp.bottleNum = 0
    //         }
    //         if (this.showBoxNum) {
    //           this.temp.boxNum = this.boxNum
    //         } else {
    //           this.temp.boxNum = 0
    //         }
    //         if (this.showCareNum) {
    //           this.temp.chestNum = this.chestNum
    //         } else {
    //           this.temp.chestNum = 0
    //         }
    //       }
    //       if (this.temp.applyMarkType == 1) {
    //         update(this.temp)
    //           .then(res => {
    //             this.btn_loading = false
    //             var data = res.data
    //             if (data.code != 200) {
    //               this.$message.error(data.msg)
    //               return false
    //             }
    //             this.$parent.getList()
    //             this.$message({ message: '操作成功', type: 'success' })
    //             this.CloseMain()
    //           })
    //           .catch(() => {
    //             this.btn_loading = false
    //           })
    //       } else {
    //         capUpdate(this.temp)
    //           .then(res => {
    //             this.btn_loading = false
    //             var data = res.data
    //             if (data.code != 200) {
    //               this.$message.error(data.msg)
    //               return false
    //             }
    //             this.$parent.getList()
    //             this.$message({ message: '操作成功', type: 'success' })
    //             this.CloseMain()
    //           })
    //           .catch(() => {
    //             this.btn_loading = false
    //           })
    //       }
    //     }
    //   })
    // },
    // getflagGroupById(pId) {
    //   flagGroupByCode(pId).then(res => {
    //     var data = res.data
    //     if (data.code == 200) {
    //       this.temp.flagName = data.data.flagName
    //     }
    //   })
    // },
    Details: function Details() {
      this.listQuery.flagId = this.temp.flagGroupId;
      this.listQuery.supplierType = this.temp.applyMarkType;
      // this.listQuery.supplierType = this.temp.supplierType
      if (this.temp.applyStatus != 2) {
        this.active = 1;
      } else if (this.temp.generateStatus != 2) {
        this.active = 2;
      } else if (this.temp.downloadStatus != 2) {
        this.active = 3;
      } else {
        this.active = 4;
      }
      if (this.temp.applyStyle == 2) {
        this.bottleNum = this.temp.bottleNum;
        this.boxNum = this.temp.boxNum;
        this.chestNum = this.temp.chestNum;
      }
      this.getModelList();
      this.getapplyType(this.temp.applyStyle, 'set');
      if (this.temp.applyStyle == 1) {
        this.packCodeVal(this.temp.packRatioCode);
      }
    }
  }
};
exports.default = _default;