"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _compyuser = require("@/api/systems/compyuser");
var _dictionary = require("@/api/systems/dictionary");
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "add",
  components: {
    Treeselect: _vueTreeselect.default
  },
  data: function data() {
    return {
      addData: {
        loginAccount: "",
        userName: "",
        userType: 3,
        orgId: null,
        loginPwd: "",
        reloginPwd: "",
        phoneNum: "",
        groupCode: 4,
        postCode: 1,
        email: "",
        isEnable: true,
        remark: ""
      },
      groupList: [],
      postList: [],
      dialogVisible: false,
      loading: false,
      value1: "",
      value2: true,
      option: [{
        value: "1",
        label: "系统"
      }],
      rules: {
        loginAccount: [{
          required: true,
          message: "请输入用户名",
          trigger: "blur"
        }],
        userName: [{
          required: true,
          message: "请输入姓名",
          trigger: "blur"
        }],
        /*
              orgId: [
                {
                  required: true,
                  message: "请选择所属组织",
                  trigger: "blur"
                }
              ],
              */
        loginPwd: [{
          required: true,
          validator: _compyuser.checkPwd,
          trigger: "blur"
        }],
        reloginPwd: [{
          required: true,
          message: "请输入确认密码",
          trigger: "blur"
        }]
        /*
              phoneNum: [
                {
                  required: true,
                  validator: checkPhone,
                  trigger: "blur"
                }
              ],
              email: [
                {
                  validator: checkEmail,
                  trigger: "blur"
                }
              ]
        */
      },

      dstate: false,
      value: null,
      orgs: []
    };
  },
  props: {
    add: Boolean
  },
  watch: {
    add: function add(val) {
      var that = this;
      if (val == true) {
        that.dialogVisible = true;
        that.orgs = that.$parent.options;
      } else {
        that.dialogVisible = false;
        that.orgs = [];
      }
    }
  },
  mounted: function mounted() {
    // 分组
    this.getDirSel("FZ100", "groupList");
    // 岗位
    this.getDirSel("GC100", "postList");
  },
  methods: {
    getDirSel: function getDirSel(code, list) {
      var _this = this;
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: code
      }).then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this[list] = res.data.data;
          } else {
            _this[list] = [];
          }
        }
      });
    },
    handleClose: function handleClose() {
      var that = this;
      that.$parent.addShow = false;
      that.resetForm("addForm");
    },
    submitForm: function submitForm(formName) {
      var that = this;
      that.$refs[formName].validate(function (valid) {
        if (valid) {
          that.loading = true;
          that.dsate = true;
          var param = {};
          param.loginAccount = that.addData.loginAccount;
          param.userName = that.addData.userName;
          param.orgId = that.addData.orgId;
          param.loginPwd = that.addData.loginPwd;
          param.phoneNum = that.addData.phoneNum;
          param.email = that.addData.email;
          param.isEnable = that.addData.isEnable;
          param.remark = that.addData.remark;
          param.groupCode = that.addData.groupCode;
          param.postCode = that.addData.postCode;
          param.userType = 3;
          if (param.isEnable == false) {
            param.isEnable = 0;
          } else {
            param.isEnable = 1;
          }
          if (param.loginPwd != that.addData.reloginPwd) {
            that.$message.error("确认密码与输入密码不一致，请重新输入！");
            that.loading = false;
            that.dsate = false;
            return;
          }

          /**判断钉钉是否开启**/
          (0, _compyuser.getDDSwitch)().then(function (item) {
            var key = item.data.data;
            return key;
          }).then(function (res) {
            if (Number(res) == 1) {
              //检验手机号的唯一性
              that.checkPhoneOnly(param);
            } else {
              that.doAddUsers(param);
            }
          });
        } else {
          that.loading = false;
          that.dsate = false;
        }
      });
    },
    //检验手机唯一性
    checkPhoneOnly: function checkPhoneOnly(param) {
      var that = this;
      var only = false;
      var phoneData = {};
      phoneData.mobile = that.addData.phoneNum;
      (0, _compyuser.checkOnly)(phoneData).then(function (res) {
        if (res.data.code != 200) {
          only = false;
          that.$message.error(res.data.msg);
        } else {
          only = true;
        }
        return only;
      }).then(function (res) {
        if (res) {
          that.doAddUsers(param);
        }
      });
    },
    //添加用户
    doAddUsers: function doAddUsers(param) {
      var that = this;
      that.loading = true;
      that.dsate = true;
      (0, _compyuser.addCompyUser)(param).then(function (res) {
        if (res.data.code == 200) {
          that.$message({
            type: "success",
            message: "添加成功"
          });
          that.loading = false;
          that.dsate = false;
          that.resetForm("addForm");
          that.$parent.getList();
        } else {
          that.$message.error(res.data.msg);
          that.loading = false;
          that.dsate = false;
        }
      }).catch(function () {
        that.loading = false;
        that.dsate = false;
      });
    },
    resetForm: function resetForm(formName) {
      var that = this;
      that.$refs[formName].resetFields();
      that.$parent.addShow = false;
      that.dialogVisible = false;
    }
  }
};
exports.default = _default;