"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.to-string");
var _utils = require("@/utils");
var _param = require("@/api/chanelmange/param");
var _stockscan = require("@/api/chanelmange/stockscan");
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
var _productMultiSelect = _interopRequireDefault(require("../component/productMultiSelect"));
var _policySelect = _interopRequireDefault(require("../component/policySelect"));
var _scanZc = _interopRequireDefault(require("./scanZc"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//一级二级只能酒卡，终端酒卡现金都可以
var _default = {
  name: 'inserthdfl',
  components: {
    Treeselect: _vueTreeselect.default,
    productMultiSelect: _productMultiSelect.default,
    policySelect: _policySelect.default,
    scanZc: _scanZc.default
  },
  filters: {
    formatDate: function (_formatDate) {
      function formatDate(_x) {
        return _formatDate.apply(this, arguments);
      }
      formatDate.toString = function () {
        return _formatDate.toString();
      };
      return formatDate;
    }(function (time) {
      var date = new Date(time);
      return formatDate(date, 'yyyy-MM-dd hh:mm:ss');
    })
  },
  data: function data() {
    return {
      title: '添加返利方案',
      subType: 'add',
      mloading: false,
      proList: [],
      orgList: [],
      zzOptions: [],
      configData: [],
      levelOneData: [],
      levelTwoData: [],
      zdData: [],
      status: 0,
      statusName: '待提交',
      statusOption: [{
        name: '待提交',
        id: 0
      }, {
        name: '待审核',
        id: 1
      }, {
        name: '已生效',
        id: 2
      }, {
        name: '已终止',
        id: 3
      }],
      isExectued: 1,
      chanelClassList: [],
      chanelLeveList: [],
      pid: '',
      submitData: {
        associatedPolicy: '',
        beginTime: '',
        configList: [],
        endTime: '',
        orgList: [],
        pid: '',
        proList: [],
        rebateOrGift: 1,
        policyName: '',
        schemeCode: '',
        schemeDescribe: '',
        schemeName: '',
        schemeState: null,
        schemeType: 0,
        dateTimeRange: ''
      },
      triggerAction: 1,
      triggerActions: [{
        name: '终端签收',
        id: 1
      }, {
        name: '消费者扫码',
        id: 2
      }],
      channelType: 1,
      isActive: 1,
      channelTypes: [{
        name: '一级经销商',
        type: 1,
        order: 1
      }, {
        name: '二级经销商',
        type: 1,
        order: 2
      }, {
        name: '终端',
        type: 2,
        order: 3
      }],
      requestChannelTypes: [],
      proVisible: false,
      returnData: [],
      singleProVisible: false,
      configIndex: '',
      rowData: '',
      rules: {
        schemeName: [{
          required: true,
          message: '请输入方案名称',
          trigger: 'blur'
        }, {
          min: 1,
          max: 50,
          message: '最大长度为50字符',
          trigger: 'blur'
        }],
        beginTime: [{
          required: true,
          message: '请选择起始时间',
          trigger: 'blur'
        }],
        endTime: [{
          required: true,
          message: '请选择结束时间',
          trigger: 'blur'
        }],
        dateTimeRange: [{
          required: true,
          message: '请选择起始时间',
          trigger: 'blur'
        }],
        policyName: [{
          required: true,
          message: '请选择关联政策',
          trigger: 'blur'
        }]
      },
      actionType: null,
      readonly: false,
      policyVisible: false
    };
  },
  created: function created() {
    var that = this;
    that.getConfigTypes();
  },
  mounted: function mounted() {
    var that = this;
    var orgOptions = that.$route.params.orgOptions;
    var subType = that.$route.params.type;
    var pid = that.$route.params.pid;
    that.subType = subType;
    that.submitData.pid = pid;
    that.pid = pid;
    if (orgOptions && orgOptions.length) {
      that.zzOptions = orgOptions;
    } else {
      that.getOrgOptions();
    }
    if (that.subType == 'edit') {
      that.getBasic(pid);
      that.getPro(pid);
      that.getOrg(pid);
      that.getCofig(pid);
    } else if (that.subType == 'add') {
      that.isActive = 1;
      that.changeStatus(that.channelTypes[0].type, that.isActive);
      that.status = 0;
      that.proList = [];
      that.configData = [];
      that.orgList = [];
      that.submitData = {
        associatedPolicy: '',
        beginTime: '',
        configList: [],
        endTime: '',
        orgList: [],
        pid: '',
        proList: [],
        rebateOrGift: 1,
        schemeCode: '',
        schemeDescribe: '',
        schemeName: '',
        schemeState: null,
        schemeType: 0,
        policyName: ''
      };
    }
  },
  methods: {
    getBasic: function getBasic(arg) {
      var that = this;
      (0, _param.getRebateSchemeById)({
        pid: arg
      }).then(function (res) {
        if (res.data.code == 200) {
          var resData = res.data.data;
          if (resData != null) {
            that.submitData.associatedPolicy = resData.associatedPolicy;
            that.submitData.beginTime = resData.beginTime;
            that.submitData.endTime = resData.endTime;
            that.submitData.dateTimeRange = [resData.beginTime, resData.endTime];
            that.submitData.pid = that.pid;
            that.submitData.schemeCode = resData.schemeCode;
            that.submitData.schemeDescribe = resData.schemeDescribe;
            that.submitData.schemeName = resData.schemeName;
            that.submitData.schemeType = parseInt(resData.schemeType);
            that.submitData.policyName = resData.policyName;
            that.status = parseInt(resData.schemeState);
          }
        }
      });
    },
    getOrgOptions: function getOrgOptions() {
      var that = this;
      (0, _stockscan.getQueryOrg)().then(function (res) {
        if (res.data.code == 200) {
          var rData = res.data.data;
          var arr = [];
          if (rData.length >= 0) {
            rData.forEach(function (item) {
              var obj = {};
              obj.id = item.pid;
              obj.label = item.orgName;
              obj.pid = item.pid;
              obj.parentPid = item.parentPid;
              arr.push(obj);
            });
            that.zzOptions = (0, _utils.transTreeData)(arr, 'pid', 'parentPid', 'children');
          }
        }
      });
    },
    getPro: function getPro(arg) {
      var that = this;
      (0, _param.getRebateSchemeProBySchemeId)({
        pid: arg
      }).then(function (res) {
        if (res.data.code == 200) {
          var response = res.data.data;
          if (response && response.length > 0) {
            that.proList = response.map(function (item) {
              return {
                productCode: item.productCode,
                productName: item.productName,
                productClass: item.productClass,
                productId: item.productId,
                minScaleId: item.packScaleId,
                measurementUnit: item.packScaleName,
                rebateProportion: item.rebateProportion
              };
            });
          }
        }
      });
    },
    getOrg: function getOrg(arg) {
      var that = this;
      (0, _param.getRebateRangeById)({
        pid: arg
      }).then(function (res) {
        if (res.data.code == 200) {
          var arr = [];
          var rData = res.data.data;
          if (rData.length > 0) {
            rData.forEach(function (item) {
              arr.push(item.orgId);
            });
          }
          that.orgList = arr;
        }
      });
    },
    //获取一级经销商，二级经销商，终端，类型及id
    getConfigTypes: function getConfigTypes() {
      var that = this;
      (0, _param.getRebateTypes)().then(function (res) {
        if (res.data.code == 200) {
          var cdata = res.data.data;
          if (cdata.length) {
            that.requestChannelTypes = cdata.map(function (item) {
              return {
                name: item.name,
                id: item.channelLevelId,
                order: item.order
              };
            });
          } else {
            that.requestChannelTypes = [];
          }
        }
      });
    },
    //获取渠道分类，渠道等级,联动
    changeStatus: function changeStatus(type, order) {
      var that = this;
      that.chanelClassList = [];
      that.chanelLeveList = [];
      that.isActive = order;
      that.channelType = type;
      var param = {
        channelType: type,
        classStatus: '1',
        pageNo: 0,
        pageSize: 0
      };
      (0, _param.getQdFl)(param).then(function (res) {
        if (res.data.code == 200) {
          that.chanelClassList = res.data.data;
        }
      }).then(function () {
        var args = {
          channelType: type,
          gradeStatus: '1',
          pageNo: 0,
          pageSize: 0
        };
        (0, _param.getQdDJ)(args).then(function (res) {
          if (res.data.code == 200) {
            that.chanelLeveList = res.data.data;
          }
        });
      });
    },
    //获取时间范围
    getTime: function getTime(val) {
      var that = this;
      if (val) {
        that.submitData.beginTime = val[0];
        that.submitData.endTime = val[1];
      }
    },
    //添加行
    addRow: function addRow() {
      var that = this;
      var active = that.isActive;
      var channelType = that.channelType;
      var triggerAction = that.triggerAction;
      var channelLevelId = '';
      var obj = {
        buyNum: 1,
        channelType: channelType,
        channelLevelId: '',
        classId: '',
        gradeId: '',
        checked: true,
        presentNum: '',
        rebatePrerequisite: null,
        rebateProductId: '102',
        rebateType: null,
        triggerAction: triggerAction
      };
      var zdObj = {
        buyNum: 1,
        channelType: channelType,
        channelLevelId: '',
        classId: '',
        gradeId: '',
        presentNum1: '',
        presentNum2: '',
        rebatePrerequisite: null,
        rebateProductId1: '102',
        checked1: true,
        rebateProductId2: '101',
        rebateType: null,
        checked2: false,
        triggerAction: triggerAction
      };
      switch (active) {
        case 1:
          obj.channelLevelId = that.requestChannelTypes[0].id;
          that.levelOneData.push(obj);
          break;
        case 2:
          obj.channelLevelId = that.requestChannelTypes[1].id;
          that.levelTwoData.push(obj);
          break;
        case 3:
          zdObj.channelLevelId = that.requestChannelTypes[2].id;
          that.zdData.push(zdObj);
          break;
      }
    },
    //获取配置详情
    getCofig: function getCofig(arg) {
      var that = this;
      setTimeout(function () {
        (0, _param.getRebateDetailSchemeConfig)({
          pid: arg
        }).then(function (res) {
          console.log(res, '配置详情');
          if (res.data.code == 200) {
            var resData = res.data.data;
            if (resData && resData.length) {
              that.requestChannelTypes = resData.map(function (item) {
                return {
                  name: item.name,
                  id: item.channelLevelId,
                  order: item.order
                };
              });
              if (resData[0].detail.length > 0) {
                that.triggerAction = resData[0].detail[0].triggerAction;
                that.levelOneData = resData[0].detail.map(function (item) {
                  return {
                    buyNum: 1,
                    channelType: item.channelType,
                    channelLevelId: item.channelLevelId,
                    classId: item.classId,
                    gradeId: item.gradeId,
                    checked: true,
                    presentNum: item.presentNum,
                    rebatePrerequisite: item.rebatePrerequisite,
                    rebateProductId: item.rebateProductId,
                    rebateType: item.rebateType,
                    triggerAction: item.triggerAction
                  };
                });
              } else {
                that.levelOneData = [];
              }
              if (resData[1].detail.length > 0) {
                that.triggerAction = resData[1].detail[0].triggerAction;
                that.levelTwoData = resData[1].detail.map(function (item) {
                  return {
                    buyNum: 1,
                    channelType: item.channelType,
                    channelLevelId: item.channelLevelId,
                    classId: item.classId,
                    gradeId: item.gradeId,
                    presentNum: item.presentNum,
                    checked: true,
                    rebatePrerequisite: item.rebatePrerequisite,
                    rebateProductId: item.rebateProductId,
                    rebateType: item.rebateType,
                    triggerAction: item.triggerAction
                  };
                });
              } else {
                that.levelTwoData = [];
              }
              if (resData[2].detail.length > 0) {
                that.triggerAction = resData[2].detail[0].triggerAction;
                var resData = resData[2].detail;
                for (var i = 0; i < resData.length; i++) {
                  for (var j = i + 1; j < resData.length; j++) {
                    if (resData[i].classId == resData[j].classId && resData[i].gradeId == resData[j].gradeId && resData[i].rebateProductId != resData[j].rebateProductId) {
                      var obj = {};
                      obj.buyNum = 1;
                      obj.channelType = resData[j].channelType;
                      obj.channelLevelId = resData[j].channelLevelId;
                      obj.classId = resData[j].classId;
                      obj.gradeId = resData[j].gradeId;
                      obj.presentNum1 = Number(resData[i].presentNum);
                      obj.presentNum2 = Number(resData[j].presentNum);
                      obj.rebatePrerequisite = resData[j].rebatePrerequisite;
                      obj.rebateProductId1 = '102';
                      obj.checked1 = resData[i].presentNum ? true : false;
                      obj.rebateProductId2 = '101';
                      obj.rebateType = resData[j].rebateType;
                      obj.checked2 = resData[j].presentNum ? true : false;
                      obj.triggerAction = resData[j].triggerAction;
                      that.zdData.push(obj);
                      var pid1 = resData[i].pid;
                      var pid2 = resData[j].pid;
                      for (var k = 0; k < resData.length; k++) {
                        if (resData[k].pid == pid1) {
                          resData.splice(k, 1);
                          break;
                        }
                      }
                      for (var g = 0; g < resData.length; g++) {
                        if (resData[g].pid == pid2) {
                          resData.splice(g, 1);
                          break;
                        }
                      }
                      break;
                    }
                  }
                }
                if (resData.length) {
                  for (var _i = 0; _i < resData.length; _i++) {
                    var obj = {};
                    if (resData[_i].rebateProductId == '102') {
                      obj.presentNum1 = Number(resData[_i].presentNum);
                      obj.checked1 = resData[_i].presentNum ? true : false;
                    } else {
                      obj.presentNum1 = null;
                      obj.checked1 = false;
                    }
                    if (resData[_i].rebateProductId == '101') {
                      obj.presentNum2 = Number(resData[_i].presentNum);
                      obj.checked2 = resData[_i].presentNum ? true : false;
                    } else {
                      obj.presentNum2 = null;
                      obj.checked2 = false;
                    }
                    obj.buyNum = 1;
                    obj.channelType = resData[_i].channelType;
                    obj.channelLevelId = resData[_i].channelLevelId;
                    obj.classId = resData[_i].classId;
                    obj.gradeId = resData[_i].gradeId;
                    obj.rebatePrerequisite = resData[_i].rebatePrerequisite;
                    obj.rebateProductId1 = '102';
                    obj.rebateProductId2 = '101';
                    obj.rebateType = resData[_i].rebateType;
                    obj.triggerAction = resData[_i].triggerAction;
                    that.zdData.push(obj);
                  }
                }
              } else {
                that.zdData = [];
              }
              that.isActive = 1;
              that.changeStatus(that.channelTypes[0].type, that.isActive);
            } else {
              that.levelOneData = [];
              that.levelTwoData = [];
              that.zdData = [];
            }
          } else {
            that.$message.error(res.data.msg + ',获取返利配置失败');
          }
        });
      }, 500);
    },
    //统一触发动作
    changeSameAction: function changeSameAction(val) {
      var that = this;
      that.resetAction(val, that.levelOneData, 'dealer');
      that.resetAction(val, that.levelTwoData, 'dealer');
      that.resetAction(val, that.zdData, 'zd');
    },
    resetAction: function resetAction(val, data, type) {
      var that = this;
      if (type == 'dealer') {
        for (var i = 0; i < data.length; i++) {
          data[i].triggerAction = val;
          data[i].presentNum = null;
          data[i].gradeId = '';
          data[i].classId = '';
          data[i].rebateProductId = '';
        }
      }
      if (type == 'zd') {
        for (var _i2 = 0; _i2 < data.length; _i2++) {
          data[_i2].triggerAction = val;
          data[_i2].presentNum1 = null;
          data[_i2].presentNum2 = null;
          data[_i2].gradeId = '';
          data[_i2].classId = '';
          data[_i2].rebateProductId = '';
        }
      }
    },
    //获取产品
    addProducts: function addProducts() {
      var that = this;
      that.proVisible = true;
    },
    closePro: function closePro() {
      var that = this;
      that.proVisible = false;
    },
    fetchPros: function fetchPros(val) {
      var that = this;
      var arr = val.map(function (item) {
        return {
          productCode: item.productCode,
          productName: item.productName,
          productClass: item.productClassName,
          productId: item.pId,
          minScaleId: item.minScaleId,
          measurementUnit: item.minScaleName,
          rebateProportion: null
        };
      });
      that.proList = (0, _utils.uniqueJsonArray)(that.proList.concat(arr), 'productId');
    },
    transUnit: function transUnit(name) {
      var unitObj = {
        罐: 1,
        盒: 2,
        箱: 3
      };
      return unitObj[name];
    },
    //删除产品
    delPro: function delPro(data) {
      var that = this;
      var id = data.productId;
      var proList = that.proList;
      that.$confirm('是否要删除？', '确认信息', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        for (var i = 0; i < proList.length; i++) {
          if (proList[i].productId == id) {
            that.proList.splice(i, 1);
            break;
          }
        }
      }).catch(function () {});
    },
    //检验返利数量输入是否是正整数
    checkNum: function checkNum(val, row) {
      var that = this;
      if (val) {
        row.presentNum = Math.abs(val);
      }
    },
    //删除配置项
    DoDel: function DoDel(index, data, type) {
      var that = this;
      var channelType = type;
      that.$confirm('是否要删除？', '确认信息', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        switch (channelType) {
          case 1:
            that.levelOneData.splice(index, 1);
            break;
          case 11:
            that.levelTwoData.splice(index, 1);
            break;
          case 2:
            that.zdData.splice(index, 1);
            break;
        }
      }).catch(function () {});
    },
    //获取政策
    chosePolice: function chosePolice() {
      var that = this;
      that.$refs.policy.listQuery.policyType = 1;
      that.$refs.policy.policeSelVisible = true;
    },
    getPolice: function getPolice(value) {
      var that = this;
      that.submitData.associatedPolicy = value.pid;
      that.submitData.policyName = value.policyName;
      that.$refs.zcbm.focus();
      that.$refs.zcbm.blur();
    },
    scanPolicy: function scanPolicy() {
      var that = this;
      if (that.submitData.policyCode == '') {
        that.$message.error('请选择政策');
        return;
      } else {
        that.policyVisible = true;
      }
    },
    closeScan: function closeScan() {
      this.policyVisible = false;
    },
    //下一步
    checkStep1: function checkStep1(formName) {
      var that = this;
      that.$refs[formName].validate(function (valid) {
        if (valid) {
          var begin = new Date(that.submitData.beginTime);
          var end = new Date(that.submitData.endTime);
          if (begin > end) {
            that.$message.error('开始时间不得大于结束时间');
            return;
          }
          var pdata = that.proList;
          var pfound = true;
          if (pdata.length > 0) {
            for (var i = 0; i < pdata.length; i++) {
              if (pdata[i].rebateProportion == '' || pdata[i].rebateProportion == null) {
                that.mloading = false;
                that.$message.error('参与产品列表第' + (i + 1) + '行，请输入返利倍数');
                pfound = false;
                break;
              } else {
                if (parseInt(pdata[i].rebateProportion) == 0 || pdata[i].rebateProportion > 10) {
                  that.mloading = false;
                  that.$message.error('参与产品列表第' + (i + 1) + '行，返利倍数必须为1-10的正整数');
                  pfound = false;
                  break;
                }
                if (pdata[i].rebateProportion - parseInt(pdata[i].rebateProportion) > 0) {
                  that.mloading = false;
                  that.$message.error('参与产品列表第' + (i + 1) + '行，返利倍数必须为1-10的正整数');
                  pfound = false;
                  break;
                }
              }
            }
          } else {
            that.$message.error('请添加产品');
            that.mloading = false;
            return;
          }
          if (!pfound) {
            that.mloading = false;
            return;
          }
          that.isExectued = 2;
        }
      });
    },
    //取消
    handleCloseStep: function handleCloseStep(formName) {
      var that = this;
      that.handleClose();
      that.$router.back();
    },
    handleClose: function handleClose() {
      var that = this;
      that.submitData = {
        associatedPolicy: '',
        beginTime: '',
        configList: [],
        endTime: '',
        orgList: [],
        pid: '',
        proList: [],
        schemeCode: '',
        schemeDescribe: '',
        schemeName: '',
        schemeState: null,
        schemeType: 0,
        policyName: '',
        rebateOrGift: 1
      };
    },
    //上一步
    prevStep: function prevStep() {
      var that = this;
      that.isExectued = 1;
    },
    //提交，保存
    submit: function submit(val, formName) {
      var that = this;
      that.mloading = true;
      that.$refs[formName].validate(function (valid) {
        if (valid) {
          //判断是否选择返利范围
          if (that.orgList.length == 0) {
            that.$message.error('请选择返利范围');
            that.mloading = false;
            return;
          }

          //判断返利配置项是否重复
          var zdData = that.zdData;
          var zdArry = [];
          if (zdData.length > 0) {
            for (var i = 0; i < zdData.length; i++) {
              if (zdData[i].checked1) {
                var obj = {};
                obj.buyNum = 1;
                obj.channelType = zdData[i].channelType;
                obj.channelLevelId = zdData[i].channelLevelId;
                obj.classId = zdData[i].classId;
                obj.gradeId = zdData[i].gradeId;
                obj.presentNum = Number(zdData[i].presentNum1);
                obj.rebatePrerequisite = zdData[i].rebatePrerequisite;
                obj.rebateProductId = '102';
                obj.rebateType = zdData[i].rebateType;
                obj.triggerAction = zdData[i].triggerAction, zdArry.push(obj);
              }
              if (zdData[i].checked2) {
                var obj = {};
                obj.buyNum = 1;
                obj.channelType = zdData[i].channelType;
                obj.channelLevelId = zdData[i].channelLevelId;
                obj.classId = zdData[i].classId;
                obj.gradeId = zdData[i].gradeId;
                obj.presentNum = Number(zdData[i].presentNum2);
                obj.rebatePrerequisite = zdData[i].rebatePrerequisite;
                obj.rebateProductId = '101';
                obj.rebateType = zdData[i].rebateType;
                obj.triggerAction = zdData[i].triggerAction, zdArry.push(obj);
              }
            }
          }
          var levelOne = that.levelOneData;
          var levelOneData = [];
          if (levelOne.length > 0) {
            levelOneData = levelOne.map(function (item) {
              return {
                buyNum: 1,
                channelType: item.channelType,
                channelLevelId: item.channelLevelId,
                classId: item.classId,
                gradeId: item.gradeId,
                presentNum: Number(item.presentNum),
                rebatePrerequisite: item.rebatePrerequisite,
                rebateProductId: '102',
                rebateType: item.rebateType,
                triggerAction: item.triggerAction
              };
            });
          }
          var levelTwo = that.levelTwoData;
          var levelTwoData = [];
          if (levelTwo.length > 0) {
            levelTwoData = levelTwo.map(function (item) {
              return {
                buyNum: 1,
                channelType: item.channelType,
                channelLevelId: item.channelLevelId,
                classId: item.classId,
                gradeId: item.gradeId,
                presentNum: Number(item.presentNum),
                rebatePrerequisite: item.rebatePrerequisite,
                rebateProductId: '102',
                rebateType: item.rebateType,
                triggerAction: item.triggerAction
              };
            });
          }
          var cdata = levelOneData.concat(levelTwoData).concat(zdArry);
          var find = true;
          if (cdata.length == 0) {
            that.$message.error('请添加配置');
            that.mloading = false;
            return;
          }
          for (var _i3 = 0; _i3 < cdata.length; _i3++) {
            if (cdata[_i3].triggerAction == '' || cdata[_i3].triggerAction == null) {
              that.$message.error('触发动作不能为空，请重新配置！');
              that.mloading = false;
              find = false;
              break;
            } else if (cdata[_i3].channelType == '' || cdata[_i3].channelType == null) {
              that.$message.error('渠道类型不能为空，请重新配置！');
              that.mloading = false;
              find = false;
              break;
            } else if (cdata[_i3].classId == '' || cdata[_i3].classId == null) {
              that.$message.error('渠道分类不能为空，请重新配置！');
              that.mloading = false;
              find = false;
              break;
            } else if (cdata[_i3].gradeId == '' || cdata[_i3].gradeId == null) {
              that.$message.error('渠道等级不能为空，请重新配置！');
              that.mloading = false;
              find = false;
              break;
            } else if (cdata[_i3].rebateProductId == '' || cdata[_i3].rebateProductId == null) {
              that.$message.error('返利奖品不能为空，请重新配置！');
              that.mloading = false;
              find = false;
              break;
            }
            for (var j = _i3 + 1; j < cdata.length; j++) {
              if (cdata[_i3].triggerAction == cdata[j].triggerAction && cdata[_i3].channelType == cdata[j].channelType && cdata[_i3].classId == cdata[j].classId && cdata[_i3].gradeId == cdata[j].gradeId && cdata[_i3].rebatePrerequisite == cdata[j].rebatePrerequisite && cdata[_i3].rebateProductId == cdata[j].rebateProductId) {
                find = false;
                that.$message.error('数据有重复，请重新配置！');
                that.mloading = false;
                break;
              }
            }
          }
          if (!find) {
            that.mloading = false;
            return;
          }
          var proList = [];
          var configList = [];
          that.proList.forEach(function (item) {
            var obj = {};
            obj.packScaleId = item.minScaleId;
            obj.productId = item.productId;
            obj.rebateProportion = Number(item.rebateProportion);
            proList.push(obj);
          });
          that.submitData.orgList = that.orgList;
          that.submitData.proList = proList;
          that.submitData.configList = cdata;
          that.submitData.schemeState = parseInt(val);
          that.submitData.pid = that.pid;
          that.submitData.rebateOrGift = 1;
          var sdata = (0, _utils.deepClone)(that.submitData);
          delete sdata.policyName;
          delete sdata.dateTimeRange;
          console.log(sdata);
          (0, _param.saveHdFlData)(sdata).then(function (res) {
            if (res.data.code == 200) {
              that.mloading = false;
              that.$message({
                type: 'success',
                message: res.data.msg
              });
              that.handleCloseStep('submitData');
              that.$parent.getList();
            } else {
              that.mloading = false;
              that.$message.error(res.data.msg);
            }
          }).catch(function () {
            that.mloading = false;
          });
        } else {
          that.mloading = false;
          return false;
        }
      });
    }
  }
};
exports.default = _default;