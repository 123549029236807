"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _configure = require("@/api/warehouse/configure/configure");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _auth = require("@/utils/auth");
var _source = require("@/api/systems/source");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var sysOptions = [];
var _default = {
  name: 'TableSuply',
  components: {
    Pagination: _Pagination.default
  },
  filters: {
    sysName: function sysName(code) {
      var name = '';
      sysOptions.forEach(function (c) {
        if (c.code == code) {
          name = c.name;
          return;
        }
      });
      return name;
    },
    osType: function osType(value) {
      value = String(value);
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          0: '安卓',
          1: 'IOS',
          2: 'PC'
        };
        return statusMap[value];
      }
    }
  },
  data: function data() {
    var _this = this;
    var validate = function validate(rule, value, callback) {
      if (_this.imgList.length == 0) {
        callback(new Error('请上传文件'));
      } else {
        callback();
      }
    };
    return {
      myHeaders: {
        Authorization: 'Bearer ' + (0, _auth.getToken)()
      },
      tableData: [],
      sysOptions: [],
      imgList: [],
      searchForm: {
        current: 1,
        size: 20
      },
      tableKey: 'rn',
      total: 0,
      btn_loading: false,
      listLoading: false,
      dialogFormVisible: false,
      formLoading: false,
      readOnly: false,
      temp: {
        systemCode: '',
        osType: '',
        isForceUpdate: '',
        updateContent: '',
        versionNo: '',
        uploadPath: '',
        isPublish: 0
      },
      title: '新增',
      tabHeight: '100%',
      rules: {
        systemCode: [{
          required: true,
          message: '请选择所属系统',
          trigger: 'change'
        }],
        osType: [{
          required: true,
          message: '请选择APP类型',
          trigger: 'change'
        }],
        isForceUpdate: [{
          required: true,
          message: '请选择是否强制更新',
          trigger: 'change'
        }],
        updateContent: [{
          required: true,
          message: '请输入更新内容',
          trigger: 'change'
        }],
        versionNo: [{
          required: true,
          message: '请输入版本号',
          trigger: 'change'
        }],
        upload: [{
          required: true,
          validator: validate,
          trigger: 'change'
        }]
      },
      downTypes: []
    };
  },
  computed: {
    // 设置上传地址
    uploadFile: function uploadFile() {
      return process.env.VUE_APP_BASE_API + '/api-user/versionControl/uploadFile';
    }
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    this.getSysData();
    this.getList();
  },
  methods: {
    // 获取系统数据
    getSysData: function getSysData() {
      var that = this;
      (0, _source.getSystem)().then(function (res) {
        if (res.data.code == 200) {
          that.sysOptions = res.data.data;
          sysOptions = res.data.data;
        } else {
          that.sysOptions = [];
          sysOptions = [];
        }
      });
    },
    handleFilter: function handleFilter(type) {
      if (type == 'rest') {
        this.searchForm = {
          current: 1,
          size: 20
        };
      }
      this.getList();
    },
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _configure.versionControlpage)(this.searchForm).then(function (response) {
        if (response.data.code != 200) {
          _this2.$message.error(response.data.msg);
        } else {
          if (response.data.data != null) {
            _this2.tableData = response.data.data.records;
            _this2.total = parseInt(response.data.data.total);
          } else {
            _this2.tableData = [];
            _this2.total = 0;
          }
        }
        _this2.listLoading = false;
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    add: function add() {
      this.dialogFormVisible = true;
    },
    detail: function detail(row) {
      this.title = '详情';
      this.dialogFormVisible = true;
      this.readOnly = true;
      this.temp = JSON.parse(JSON.stringify(row));
    },
    edit: function edit(row) {
      this.title = '编辑';
      this.dialogFormVisible = true;
      // this.readOnly = true
      this.temp = JSON.parse(JSON.stringify(row));
      this.imgList = [{
        uid: '',
        url: row.uploadPath,
        name: row.uploadPath
      }];
    },
    handleClose: function handleClose() {
      if (this.$refs.upload) {
        this.$refs.upload.clearFiles();
      }
      this.readOnly = false;
      this.temp = {
        systemCode: '',
        osType: '',
        isForceUpdate: '',
        updateContent: '',
        versionNo: '',
        uploadPath: '',
        isPublish: 0
      };
      this.dialogFormVisible = false;
    },
    selectImg: function selectImg(file, fileList) {
      this.imgList = fileList;
    },
    handleRemove: function handleRemove(file, fileList) {
      this.imgList = fileList;
      this.temp.uploadPath = '';
    },
    beforeAvatarUpload: function beforeAvatarUpload(file, fileList) {
      // const file = param.file
      // const isJPG = file.type === 'image/jpeg'
      // const isPNG = file.type === 'image/png'
      // const isLt2M = file.size / 1024 / 1024 < 0.2
      // const _this = this
      // if (!isJPG && !isPNG) {
      //   this.$message.error('上传图片只能是 PNG/JPG 格式!')
      // }
      // if (!isLt2M) {
      //   this.$message.error('上传图片大小不能超过 200kb!')
      // }
      // // if ((isJPG || isPNG) && isLt2M) {
      // //   const formData = new FormData()
      // //   formData.append('file', file)
      // //   imgupload(formData).then(response => {
      // //     var data = response.data
      // //     if (data.code != 200) {
      // //       this.$message.error(data.msg)
      // //     } else {
      // //       this.imgList.push({ url: data.data })
      // //     }
      // //   })
      // // }
      // return (isJPG || isPNG) && isLt2M
    },
    imgUploadSuccess: function imgUploadSuccess(res, file, fileList) {
      if (res.code != 200) {
        this.$message.error(res.msg);
      } else {
        this.temp.uploadPath = res.data;
      }
    },
    uploadFileFail: function uploadFileFail(res, file, fileList) {
      this.$message.error('上传失败请重试');
      this.mloading = false;
    },
    submit: function submit() {
      var _this3 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _this3.btn_loading = true;
          if (_this3.temp.pid) {
            (0, _configure.versionControlupdate)(_this3.temp).then(function (res) {
              if (res.data.code == 200) {
                _this3.$message({
                  type: 'success',
                  message: '操作成功'
                });
                _this3.handleClose();
                _this3.getList();
              } else {
                _this3.$message.error(res.data.msg);
              }
              _this3.btn_loading = false;
            }).catch(function () {
              _this3.btn_loading = false;
            });
          } else {
            (0, _configure.versionControlsave)(_this3.temp).then(function (res) {
              if (res.data.code == 200) {
                _this3.$message({
                  type: 'success',
                  message: '操作成功'
                });
                _this3.handleClose();
                _this3.getList();
              } else {
                _this3.$message.error(res.data.msg);
              }
              _this3.btn_loading = false;
            }).catch(function () {
              _this3.btn_loading = false;
            });
          }
        } else {
          _this3.$message.error('请完善信息！');
        }
      });
    },
    DoDel: function DoDel(id) {
      var _this4 = this;
      var that = this;
      that.$confirm('确认删除?将不可撤销', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        console.log(111);
        (0, _configure.versionControldelete)({
          id: id
        }).then(function (res) {
          console.log(222);
          if (res.data.code == 200) {
            _this4.$message({
              type: 'success',
              message: '删除成功!'
            });
            that.getList();
          } else {
            _this4.$message({
              type: 'info',
              message: res.data.msg
            });
          }
        });
      }).catch(function () {
        console.log('已取消');
      });
    }
  }
};
exports.default = _default;