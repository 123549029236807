"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _configure = require("@/api/warehouse/configure/configure");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import productDialog from '@/components/productSelectDialog'
// var miniUnit = ''
var _default = {
  name: 'TableSuply',
  components: {
    Pagination: _Pagination.default
    // productDialog
  },

  filters: {
    // TypeDataCom(code, keyName) {
    //   var vars = TypeDataComs[keyName]
    //   var name = ''
    //   vars.forEach(function(c) {
    //     if (c.code == code) {
    //       name = c.name
    //       return
    //     }
    //   })
    //   return name
    // }
  },
  data: function data() {
    return {
      tableData: [],
      searchForm: {
        current: 1,
        size: 10,
        orderType: ''
      },
      tableKey: 'rn',
      total: 0,
      codetotal: 0,
      listLoading: false,
      dialogFormVisible: false,
      page2: false,
      btn_loading: false,
      temp: {
        orderType: '',
        orderName: '',
        relateId: null,
        billCode: ''
      },
      textMap: {
        update: '编辑',
        create: '新增'
      },
      dialogStatus: 'create',
      tabHeight: '100%',
      rules: {
        orderType: [{
          required: true,
          message: '请选择单据类型',
          trigger: 'change'
        }],
        orderName: [{
          required: true,
          message: '输入单据名称',
          trigger: 'blur'
        }],
        billCode: [{
          required: true,
          message: '输入单据编码',
          trigger: 'blur'
        }]
      },
      downTypes: []
    };
  },
  activated: function activated() {
    this.getList();
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    handleFilter: function handleFilter(type) {
      if (type == 'rest') {
        this.searchForm = {
          orderType: '',
          current: 1,
          size: 10
        };
      }
      this.getList();
    },
    getList: function getList() {
      var _this = this;
      this.authId('searchbtn', this.$route.meta.authList);
      var that = this;
      that.listLoading = true;
      (0, _configure.billConfigList)(that.searchForm).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.tableData = response.data.data.records;
            that.total = parseInt(response.data.data.total);
            setTimeout(function () {
              that.listLoading = false;
            }, 1 * 500);
          } else {
            that.tableData = [];
            that.total = 0;
          }
        }
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    handleUpdate: function handleUpdate(row, type) {
      this.authId('addbtn', this.$route.meta.authList);
      this.dialogFormVisible = true;
      this.dialogStatus = type;
      this.temp = {
        orderType: null,
        orderName: '',
        relateId: null,
        billCode: ''
      };
      if (type == 'create') {
        return;
      }
      this.authId('updatebtn', this.$route.meta.authList);
      var vals = JSON.parse(JSON.stringify(row));
      this.temp.orderType = Number(vals.otherType);
      this.temp.orderName = vals.billName;
      this.temp.pId = vals.pid;

      // 获取类型
      this.getDownType(Number(row.otherType));
      // 获取详情
      this.getDetail(row.pid);
    },
    createData: function createData() {
      var _this2 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _this2.btn_loading = true;
          var param = (0, _utils.deepClone)(_this2.temp);
          if (param.relateId == '' || param.relateId == null) {
            delete param.relateId;
          }
          (0, _configure.saveOrderConfig)(param).then(function (res) {
            _this2.btn_loading = false;
            var data = res.data;
            if (data.code != 200) {
              _this2.$message.error(data.msg);
              return false;
            }
            _this2.$message({
              message: '添加成功！',
              type: 'success'
            });
            _this2.getList();
            _this2.$refs.dataForm.resetFields();
            _this2.dialogFormVisible = false;
          }).catch(function () {
            _this2.btn_loading = false;
          });
        }
      });
    },
    updateData: function updateData() {
      var _this3 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _this3.btn_loading = true;
          var param = (0, _utils.deepClone)(_this3.temp);
          if (param.relateId == '' || param.relateId == null) {
            delete param.relateId;
          }
          (0, _configure.updateById)(param).then(function (res) {
            _this3.btn_loading = false;
            var data = res.data;
            if (data.code != 200) {
              _this3.$message.error(data.msg);
              return false;
            }
            _this3.$message({
              message: '修改成功！',
              type: 'success'
            });
            _this3.getList();
            _this3.$refs.dataForm.resetFields();
            _this3.dialogFormVisible = false;
          }).catch(function () {
            _this3.btn_loading = false;
          });
        }
      });
    },
    handleModifyStatus: function handleModifyStatus(row, status) {
      var _this4 = this;
      // 删除
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this4.authId('deletebtn', _this4.$route.meta.authList);
        (0, _configure.deleteById)(row.pid).then(function (res) {
          _this4.authId('searchbtn', _this4.$route.meta.authList);
          var data = res.data;
          if (data.code != 200) {
            _this4.$message.error(data.msg);
            return false;
          }
          _this4.$message({
            message: '删除成功！',
            type: 'success'
          });
          _this4.getList();
        });
      }).catch(function () {
        _this4.authId('searchbtn', _this4.$route.meta.authList);
      });
    },
    // 获取详情
    getDetail: function getDetail(id) {
      var _this5 = this;
      (0, _configure.fetchDetails)(id).then(function (res) {
        if (res.data.code == 200) {
          _this5.temp.orderType = res.data.data.otherType;
          _this5.temp.orderName = res.data.data.billName;
          _this5.temp.relateId = res.data.data.relateId;
          _this5.temp.billCode = res.data.data.billCode;
        }
      });
    },
    // 关闭弹窗
    handleClose: function handleClose() {
      var that = this;
      that.$refs['dataForm'].resetFields();
      that.dialogFormVisible = false;
      this.authId('searchbtn', this.$route.meta.authList);
    },
    closeDilog: function closeDilog() {
      this.handleClose();
    },
    // 根据出入库获取下游单据类型
    getDownType: function getDownType(v) {
      var that = this;
      var param = {};
      if (v == 1) {
        param.orderType = 2;
      }
      if (v == 2) {
        param.orderType = 1;
      }
      (0, _configure.getDType)(param).then(function (res) {
        if (res.data.code == 200) {
          that.downTypes = res.data.data;
        }
      });
    }
  }
};
exports.default = _default;