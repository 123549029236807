"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.queryActivityByMarkCode = queryActivityByMarkCode;
var _request = _interopRequireDefault(require("@/utils/request"));
var base = '';
function queryActivityByMarkCode(params) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/activityConfig/queryActivityByMarkCode',
    method: 'get',
    params: params
  });
}