"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.searchMenuData = searchMenuData;
exports.searchMenuDetail = searchMenuDetail;
var _request = _interopRequireDefault(require("@/utils/request"));
// var baseUrl = '/user'
var baseUrl = '';
// 菜单分析查询
function searchMenuData(param) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/moWxMenuAnalysis/searchMenuData',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'get',
    params: param
  });
}
// 获取菜单详情
function searchMenuDetail(param) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/moWxMenuAnalysis/searchMenuDataDetail',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'get',
    params: param
  });
}