"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _purchase = require("@/api/chanelmange/purchase");
var _outstock = require("@/api/chanelmange/outstock");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _instockUnit = _interopRequireDefault(require("./component/instockUnit"));
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var status = [];
var cachName = 'orderCenterchannelOrderssalesindex';
var _default = {
  name: 'orderCenterchannelOrderssalesindex',
  components: {
    Pagination: _Pagination.default,
    instockUnit: _instockUnit.default,
    Treeselect: _vueTreeselect.default
  },
  data: function data() {
    return {
      listQuery: {
        beginDate: '',
        businessType: 2,
        endDate: '',
        orderNo: '',
        orderType: null,
        purchaseOrgId: '',
        receiveAddress: '',
        receiveOrgId: '',
        receivePhone: '',
        receiveUser: '',
        relationOrderNo: '',
        saleOrgId: '',
        sendOrgId: '',
        tradeStatus: '',
        tradeType: 2,
        transInfo: '',
        current: 1,
        size: 20
      },
      saleOrgName: '',
      sendOrgName: '',
      purchaseOrgName: '',
      receiveOrgName: '',
      unitType: '',
      inOutType: '',
      companyType: '',
      companyShow: false,
      orderTime: [],
      tableKey: 'orderTable',
      tabHeight: '100%',
      list: [],
      listLoading: false,
      total: 0,
      orgOptions: [],
      orderStatus: [],
      showCol: false,
      expandTxt: '展开',
      unitShow: false,
      apis: []
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    var that = this;
    var hasCache = that.list.length > 0 ? true : false;
    var authList = that.$route.meta.authList;
    that.apis = authList;
    var auth = that.hasAuth('searchbtn', authList);
    var setHeaderId = that.authId('searchbtn', that.apis);
    if (!hasCache) {
      if (auth) {
        that.getOrg();
        that.getDirSel('CCCGDDZT');
        that.getList();
      }
    }
  },
  //缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = that.getCaches(that, name);
    if (!cache) {
      that.list = [];
      that.total = 0;
    }
  },
  computed: {
    totalSumAll: function totalSumAll() {
      var totalSumAll = 0;
      this.productList.map(function (item) {
        if (!isNaN(item.bottleNum)) totalSumAll += Number(item.bottleNum);
      });
      if (isNaN(totalSumAll)) {
        return 0;
      }
      return totalSumAll;
    }
  },
  filters: {
    formatTime: function formatTime(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        return (0, _utils.parseTime)(value, '{y}-{m}-{d}');
      }
    },
    orderType: function orderType(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          1: '买赠订单',
          2: '酒券订单',
          3: '团购订单'
        };
        return statusMap[value];
      }
    },
    filterStatus: function filterStatus(value) {
      var name = '';
      status.forEach(function (item) {
        if (item.figure == value) {
          name = item.name;
          return;
        }
      });
      return name;
    },
    orderStatusType: function orderStatusType(val) {
      var obj = {
        1: '',
        2: '',
        3: '',
        4: '',
        5: 'success',
        6: 'danger',
        7: 'danger',
        8: '',
        9: 'success',
        10: 'danger'
      };
      return obj[val];
    }
  },
  methods: {
    getRangeTime: function getRangeTime(val) {
      var that = this;
      data.beginDate = val[0];
      data.endDate = val[1];
    },
    getList: function getList() {
      var that = this;
      that.listLoading = true;
      var current = that.listQuery.current;
      var size = that.listQuery.size;
      if (that.orderTime.length == 0) {
        that.listQuery.beginDate = '';
        that.listQuery.endDate = '';
      }
      var param = (0, _utils.deepClone)(that.listQuery);
      delete param.current;
      delete param.size;
      (0, _purchase.getSaleList)(current, size, param).then(function (res) {
        if (res.data.code == 200) {
          that.list = res.data.data.records;
          that.total = parseInt(res.data.data.total);
          setTimeout(function () {
            that.listLoading = false;
          }, 1 * 800);
        } else {
          that.$message.error(res.data.msg);
          that.listLoading = false;
          return;
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    DoSearch: function DoSearch() {
      var that = this;
      that.listQuery.current = 1;
      that.getList();
    },
    DoReset: function DoReset(formName) {
      var that = this;
      that.listQuery.beginDate = '';
      that.listQuery.businessType = 2;
      that.listQuery.endDate = '';
      that.listQuery.orderNo = '';
      that.listQuery.orderType = null;
      that.listQuery.purchaseOrgId = null;
      that.listQuery.tradeStatus = null;
      that.listQuery.saleOrgId = '';
      that.listQuery.sendOrgId = '';
      that.listQuery.tradeType = 2;
      that.listQuery.receiveOrgId = '';
      that.listQuery.receiveAddress = '';
      that.listQuery.receivePhone = '';
      that.listQuery.receiveUser = '';
      that.listQuery.relationOrderNo = '';
      that.listQuery.current = 1;
      that.purchaseOrgName = '';
      that.sendOrgName = '';
      that.receiveOrgName = '';
      that.saleOrgName = '';
      that.orderTime = [];
      that.getList();
    },
    //选择采收售单位
    openCompany: function openCompany(companyType) {
      this.unitType = companyType;
      this.unitShow = true;
    },
    getUnits: function getUnits(val) {
      switch (this.unitType) {
        case '1':
          this.listQuery.receiveOrgId = val.pId;
          this.receiveOrgName = val.channelName;
          break;
        case '0':
          this.listQuery.purchaseOrgId = val.pId;
          this.purchaseOrgName = val.channelName;
          break;
        case '2':
          this.listQuery.sendOrgId = val.pId;
          this.sendOrgName = val.channelName;
          break;
        case '3':
          this.listQuery.saleOrgId = val.pId;
          this.saleOrgName = val.channelName;
          break;
      }
    },
    hideunits: function hideunits() {
      this.unitType = '';
      this.unitShow = false;
    },
    clearUnit: function clearUnit(type) {
      var that = this;
      switch (type) {
        case '0':
          this.listQuery.purchaseOrgId = '';
          this.purchaseOrgName = '';
          break;
        case '1':
          this.listQuery.receiveOrgId = '';
          this.receiveOrgName = '';
          break;
        case '2':
          this.listQuery.sendOrgId = '';
          this.sendOrgName = '';
          break;
        case '3':
          this.listQuery.saleOrgId = '';
          this.saleOrgName = '';
          break;
      }
    },
    //导出文件流并下载
    DoExportAll: function DoExportAll() {
      var that = this;
      var param = (0, _utils.deepClone)(that.listQuery);
      delete param.current;
      delete param.size;
      that.exportLoading = true;
      (0, _purchase.exportCgData)(param).then(function (res) {
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '销售订单列表.xlsx';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
        that.exportLoading = false;
      }).catch(function () {
        that.exportLoading = false;
      });
    },
    //销售订单查看
    DoScan: function DoScan(data) {
      var setHeaderId = this.authId('searchbtn', this.apis);
      this.$router.push({
        name: 'salesOrderDetail',
        query: {
          id: data.orderNo
        }
      });
    },
    //供货商为企业时，选择组织机构
    getOrg: function getOrg() {
      var that = this;
      (0, _outstock.getQueryOrg)().then(function (res) {
        if (res.data.code == 200) {
          var rData = res.data.data;
          var arr = [];
          that.orgOriginData = rData;
          if (rData.length >= 0) {
            rData.forEach(function (item) {
              var obj = {};
              obj.id = item.pid;
              obj.label = item.orgName;
              obj.pid = item.pid;
              obj.parentPid = item.parentPid;
              arr.push(obj);
            });
            that.orgOptions = (0, _utils.transTreeData)(arr, 'pid', 'parentPid', 'children');
          }
        }
      });
    },
    //展开收起搜索条件
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    },
    //获取订单状态
    getDirSel: function getDirSel(code) {
      var _this = this;
      var that = this;
      var param = {};
      param.code = code;
      (0, _purchase.getCgState)(param).then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            that.orderStatus = res.data.data;
            status = res.data.data;
          }
        }
      });
    }
  }
};
exports.default = _default;