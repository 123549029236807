var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        { staticClass: "page-section" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "search-condition",
              attrs: {
                model: _vm.ruleForm,
                "status-icon": "",
                "label-position": _vm.formConfig.labelPosition,
                "label-width": _vm.formConfig.labelWidth,
                "label-suffix": ":",
              },
            },
            [
              _c(
                "div",
                { staticClass: "cols" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "类型", prop: "isEnable" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.ruleForm.isEnable,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "isEnable", $$v)
                                    },
                                    expression: "ruleForm.isEnable",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "全部", value: "" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "库房", prop: "isEnable" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.ruleForm.isEnable,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "isEnable", $$v)
                                    },
                                    expression: "ruleForm.isEnable",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "全部", value: "" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "收货人", prop: "isEnable" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.ruleForm.isEnable,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "isEnable", $$v)
                                    },
                                    expression: "ruleForm.isEnable",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "全部", value: "" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "关联采购单", prop: "isEnable" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.ruleForm.isEnable,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "isEnable", $$v)
                                    },
                                    expression: "ruleForm.isEnable",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "全部", value: "" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "基地", prop: "isEnable" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.ruleForm.isEnable,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "isEnable", $$v)
                                    },
                                    expression: "ruleForm.isEnable",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "全部", value: "" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "签收批次",
                                prop: "baseWineName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: " 请输入批次号 多个编码逗号隔开",
                                },
                                model: {
                                  value: _vm.ruleForm.baseWineName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "baseWineName",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.baseWineName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { "label-width": "0" } },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "label" }, slot: "label" },
                                [_vm._v(" ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.searchBtn.type,
                                    size: _vm.btnConfig.size,
                                    icon: _vm.btnConfig.searchBtn.icon,
                                  },
                                  on: {
                                    click: function ($event) {
                                      ;(_vm.current = 1), _vm.getList()
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.resetBtn.type,
                                    size: _vm.btnConfig.size,
                                    icon: _vm.btnConfig.resetBtn.icon,
                                  },
                                  on: { click: _vm.resetForm },
                                },
                                [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("el-divider", { staticClass: "btnDivider" }),
          _vm._v(" "),
          _c("div", { staticClass: "btnArea" }, [
            _c(
              "div",
              { staticClass: "head-btn-group" },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: _vm.btnConfig.addBtn.type,
                      size: _vm.btnConfig.size,
                      icon: _vm.btnConfig.addBtn.icon,
                    },
                    on: { click: _vm.addE },
                  },
                  [_vm._v(_vm._s(_vm.btnConfig.addBtn.text))]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: _vm.btnConfig.updateBtn.type,
                      size: _vm.btnConfig.size,
                      icon: _vm.btnConfig.updateBtn.icon,
                    },
                  },
                  [_vm._v(_vm._s(_vm.btnConfig.updateBtn.text))]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: _vm.btnConfig.delBtn.type,
                      size: _vm.btnConfig.size,
                      icon: _vm.btnConfig.delBtn.icon,
                    },
                  },
                  [_vm._v(_vm._s(_vm.btnConfig.delBtn.text))]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "section",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.tableData,
                    height: _vm.tabHeight,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    fit: "",
                    "highlight-current-row": "",
                  },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "55" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      label: "序号",
                      "min-width": "100",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "a",
                      label: "物料名称",
                      "min-width": "120",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "b",
                      label: "物料编号",
                      "min-width": "100",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "c",
                      label: "类型",
                      "min-width": "100",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "d",
                      label: "单位",
                      "min-width": "100",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "e",
                      label: "签收数量",
                      "min-width": "100",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "f",
                      label: "采购类型",
                      "min-width": "100",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "g",
                      label: "采购批次",
                      "min-width": "150",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "h",
                      label: "采购人",
                      "min-width": "120",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "j",
                      label: "收货仓库",
                      "min-width": "150",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "k",
                      label: "到货时间",
                      "min-width": "150",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "l",
                      label: "供货商",
                      "min-width": "150",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "m",
                      label: "状态",
                      "min-width": "100",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "n",
                      label: "发货人",
                      "min-width": "100",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        prop: "",
                        label: "质检报告",
                        width: "100",
                        align: _vm.tableConfig.align,
                        fixed: "right",
                      },
                    },
                    [
                      [
                        _c("el-button", { attrs: { type: "text" } }, [
                          _vm._v("详情"),
                        ]),
                      ],
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "",
                      label: "详情",
                      width: "100",
                      align: _vm.tableConfig.align,
                      fixed: "right",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.look(row)
                                  },
                                },
                              },
                              [_vm._v("详情")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                  page: _vm.current,
                  limit: _vm.size,
                },
                on: {
                  "update:page": function ($event) {
                    _vm.current = $event
                  },
                  "update:limit": function ($event) {
                    _vm.size = $event
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: _vm.title,
            visible: _vm.dialogFormVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
            close: function ($event) {
              return _vm.resetForm2("ruleForm2")
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm2",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm2,
                rules: _vm.rules,
                "label-width": "110px",
                "label-suffix": ":",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "采购物料名称", prop: "a" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.ruleForm2.a,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm2, "a", $$v)
                        },
                        expression: "ruleForm2.a",
                      },
                    },
                    [_c("el-option", { attrs: { label: "全部", value: "" } })],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "物料编码", prop: "a" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.ruleForm2.a,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm2, "a", $$v)
                        },
                        expression: "ruleForm2.a",
                      },
                    },
                    [_c("el-option", { attrs: { label: "全部", value: "" } })],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "物料分类", prop: "a" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.ruleForm2.a,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm2, "a", $$v)
                        },
                        expression: "ruleForm2.a",
                      },
                    },
                    [_c("el-option", { attrs: { label: "全部", value: "" } })],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "等级", prop: "a" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.ruleForm2.a,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm2, "a", $$v)
                        },
                        expression: "ruleForm2.a",
                      },
                    },
                    [_c("el-option", { attrs: { label: "全部", value: "" } })],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "收货仓库", prop: "a" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.ruleForm2.a,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm2, "a", $$v)
                        },
                        expression: "ruleForm2.a",
                      },
                    },
                    [_c("el-option", { attrs: { label: "全部", value: "" } })],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "签收人", prop: "a" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.ruleForm2.a,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm2, "a", $$v)
                        },
                        expression: "ruleForm2.a",
                      },
                    },
                    [_c("el-option", { attrs: { label: "全部", value: "" } })],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "茶龄", prop: "b" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm2.b,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm2, "b", $$v)
                      },
                      expression: "ruleForm2.b",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "采购类型", prop: "c" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.ruleForm2.c,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm2, "c", $$v)
                        },
                        expression: "ruleForm2.c",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "内部采购", value: "1" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "外部采购", value: "2" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.ruleForm2.c == 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "采购批次", prop: "d" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.ruleForm2.d,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm2, "d", $$v)
                            },
                            expression: "ruleForm2.d",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: "" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "供应商", prop: "a" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.ruleForm2.a,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm2, "a", $$v)
                        },
                        expression: "ruleForm2.a",
                      },
                    },
                    [_c("el-option", { attrs: { label: "全部", value: "" } })],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "单位", prop: "a" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.ruleForm2.a,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm2, "a", $$v)
                        },
                        expression: "ruleForm2.a",
                      },
                    },
                    [_c("el-option", { attrs: { label: "全部", value: "" } })],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "茶龄", prop: "b" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm2.b,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm2, "b", $$v)
                      },
                      expression: "ruleForm2.b",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "f" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      maxlength: "200",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.ruleForm2.remarks,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm2, "remarks", $$v)
                      },
                      expression: "ruleForm2.remarks",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "质检报告", prop: "e" } },
                [
                  _c(
                    "el-upload",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.imgloading,
                          expression: "imgloading",
                        },
                      ],
                      attrs: {
                        "list-type": "picture-card",
                        disabled: _vm.disabled,
                        action: "",
                        accept: ".jpg, .jpeg, .png, .PNG, .JPG, .JPEG",
                        "show-file-list": false,
                        "http-request": _vm.beforeAvatarUpload,
                      },
                    },
                    [
                      _vm.imageUrl
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.imageUrl },
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon",
                          }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          attrs: { slot: "tip" },
                          slot: "tip",
                        },
                        [
                          _vm._v(
                            "\n            只能上传jpg/png文件，且不超过500kb\n          "
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm2("ruleForm2")
                    },
                  },
                },
                [_vm._v("提交")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.resetForm2("ruleForm2")
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: "查看详情",
            visible: _vm.scanVisible,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.scanVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("物料名称:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.lookList.a)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("采购单号:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [_vm._v(_vm._s())]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("物料编码:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.lookList.b)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("采购类型:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.lookList.f)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.lookList.f == "外部"
                    ? _c("el-col", { attrs: { span: 12 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("供应商名称:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.lookList.l)),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [_vm._v("类型:")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.lookList.c)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("发货出库:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [_vm._v(_vm._s())]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [_vm._v("茶龄:")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [_vm._v(_vm._s())]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("发货部门:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [_vm._v(_vm._s())]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [_vm._v("单位:")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.lookList.d)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("收货仓库:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.lookList.j)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [_vm._v("数量:")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.lookList.e)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("签收人:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [_vm._v(_vm._s())]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("采购入库时间:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.lookList.k)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [_vm._v("备注:")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "content" },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: "",
                              type: "textarea",
                              "show-word-limit": "",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "middleLine" }),
              _vm._v(" "),
              _c("div", { staticClass: "itemTitle" }, [_vm._v("原料质检报告")]),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _vm.imageUrl
                        ? _c("el-image", {
                            staticStyle: { width: "100px", height: "100px" },
                            attrs: {
                              src: _vm.imageUrl,
                              "preview-src-list": [_vm.imageUrl],
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "middleLine" }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "default", plain: "" },
                  on: { click: _vm.handleClose },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }