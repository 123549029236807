"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.antiChanCityRank = antiChanCityRank;
exports.antiChanDealerRank = antiChanDealerRank;
exports.antiChanProductRank = antiChanProductRank;
exports.antiChanProvinceRank = antiChanProvinceRank;
exports.antiChanTrendChart = antiChanTrendChart;
var _request = _interopRequireDefault(require("@/utils/request"));
// 防伪统计api

// 疑似防窜城市排行
function antiChanCityRank(params) {
  return (0, _request.default)({
    url: '/api-prevent/statistics/antichan/antiChanCityRank',
    method: 'get',
    params: params
  });
}
// 疑似防窜城市排行
function antiChanProductRank(params) {
  return (0, _request.default)({
    url: '/api-prevent/statistics/antichan/antiChanProductRank',
    method: 'get',
    params: params
  });
}

// 疑似防窜经销商排行
function antiChanDealerRank(params) {
  return (0, _request.default)({
    url: '/api-prevent/statistics/antichan/antiChanDealerRank',
    method: 'get',
    params: params
  });
}

// 疑似防窜热力图
function antiChanProvinceRank(params) {
  return (0, _request.default)({
    url: '/api-prevent/statistics/antichan/antiChanProvinceRank',
    method: 'get',
    params: params
  });
}

// 疑似防窜趋势图
function antiChanTrendChart(params) {
  return (0, _request.default)({
    url: '/api-prevent/statistics/antichan/antiChanTrendChart',
    method: 'get',
    params: params
  });
}