var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        { staticClass: "page-section" },
        [
          _c(
            "el-form",
            {
              ref: "listQuery",
              staticClass: "search-condition",
              attrs: {
                model: _vm.listQuery,
                "status-icon": "",
                "label-position": _vm.formConfig.labelPosition,
                "label-width": _vm.formConfig.labelWidth,
              },
            },
            [
              _c(
                "div",
                { staticClass: "cols" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "billNo", label: "单号:" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入单号" },
                                model: {
                                  value: _vm.listQuery.billNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.listQuery, "billNo", $$v)
                                  },
                                  expression: "listQuery.billNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "orgId", label: "收货组织:" } },
                            [
                              _c("treeselect", {
                                attrs: {
                                  multiple: false,
                                  "flatten-search-results": true,
                                  placeholder: "请选择组织...",
                                  options: _vm.orgOptions,
                                },
                                model: {
                                  value: _vm.listQuery.orgId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.listQuery, "orgId", $$v)
                                  },
                                  expression: "listQuery.orgId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showCol || !_vm.formConfig.btnFollow,
                              expression: "showCol || !formConfig.btnFollow",
                            },
                          ],
                          attrs: { span: 6 },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "buyerStr", label: "收货单位:" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  "suffix-icon": "el-icon-search",
                                  clearable: "",
                                  placeholder: "请输入",
                                },
                                on: { clear: _vm.clearSh, focus: _vm.getUnit },
                                model: {
                                  value: _vm.buyerStr,
                                  callback: function ($$v) {
                                    _vm.buyerStr = $$v
                                  },
                                  expression: "buyerStr",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.showCol
                        ? _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "productStr",
                                    label: "产品名称:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      "suffix-icon": "el-icon-search",
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    on: {
                                      clear: _vm.clearPro,
                                      focus: function ($event) {
                                        return _vm.getPname("search")
                                      },
                                    },
                                    model: {
                                      value: _vm.productStr,
                                      callback: function ($$v) {
                                        _vm.productStr = $$v
                                      },
                                      expression: "productStr",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "status", label: "单据状态:" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择",
                                  },
                                  model: {
                                    value: _vm.listQuery.status,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.listQuery, "status", $$v)
                                    },
                                    expression: "listQuery.status",
                                  },
                                },
                                _vm._l(_vm.states, function (item) {
                                  return _c("el-option", {
                                    key: item.code,
                                    attrs: {
                                      label: item.name,
                                      value: item.code,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.showCol
                        ? _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "inStartDate",
                                    label: "收货起始日期:",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "date",
                                      format: "yyyy-MM-dd",
                                      "value-format": "yyyy-MM-dd",
                                      placeholder: "请选择日期",
                                    },
                                    model: {
                                      value: _vm.listQuery.inStartDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.listQuery,
                                          "inStartDate",
                                          $$v
                                        )
                                      },
                                      expression: "listQuery.inStartDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showCol
                        ? _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "inEndDate",
                                    label: "收货结束日期:",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "date",
                                      format: "yyyy-MM-dd",
                                      "value-format": "yyyy-MM-dd",
                                      placeholder: "请选择日期",
                                    },
                                    model: {
                                      value: _vm.listQuery.inEndDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.listQuery,
                                          "inEndDate",
                                          $$v
                                        )
                                      },
                                      expression: "listQuery.inEndDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.formConfig.btnFollow
                        ? _c(
                            "el-col",
                            {
                              staticClass: "head-btn-group",
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { "label-width": "0" } },
                                [
                                  _c(
                                    "div",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [_vm._v(" ")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.searchBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.searchBtn.icon,
                                      },
                                      on: { click: _vm.DoSearch },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.btnConfig.searchBtn.text)
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.formConfig.showDvider
                                    ? _c("el-divider", {
                                        attrs: { direction: "vertical" },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.resetBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.resetBtn.icon,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.DoReset(_vm.listQuery)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.btnConfig.resetBtn.text)
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.formConfig.showDvider
                                    ? _c("el-divider", {
                                        attrs: { direction: "vertical" },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: { click: _vm.expand },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.expandTxt) +
                                          "\n                "
                                      ),
                                      _c("i", {
                                        class:
                                          _vm.showCol == false
                                            ? "el-icon-arrow-down"
                                            : "el-icon-arrow-up",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _vm.formConfig.btnFollow
            ? _c("el-divider", { staticClass: "btnDivider" })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "btnArea" },
            [
              !_vm.formConfig.btnFollow
                ? _c(
                    "el-col",
                    {
                      staticClass: "head-btn-group",
                      staticStyle: { width: "230px" },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: _vm.btnConfig.searchBtn.type,
                            size: _vm.btnConfig.size,
                            icon: _vm.btnConfig.searchBtn.icon,
                          },
                          on: { click: _vm.DoSearch },
                        },
                        [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                      ),
                      _vm._v(" "),
                      _c("el-divider", { attrs: { direction: "vertical" } }),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: _vm.btnConfig.resetBtn.type,
                            size: _vm.btnConfig.size,
                            icon: _vm.btnConfig.resetBtn.icon,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.DoReset(_vm.listQuery)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                      ),
                      _vm._v(" "),
                      _c("el-divider", { attrs: { direction: "vertical" } }),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        { attrs: { type: "text" }, on: { click: _vm.expand } },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.expandTxt) +
                              "\n          "
                          ),
                          _c("i", {
                            class:
                              _vm.showCol == false
                                ? "el-icon-arrow-down"
                                : "el-icon-arrow-up",
                          }),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "head-btn-group" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: _vm.btnConfig.addBtn.type,
                        size: _vm.btnConfig.size,
                        icon: _vm.btnConfig.addBtn.icon,
                      },
                      on: { click: _vm.DoAdd },
                    },
                    [_vm._v(_vm._s(_vm.btnConfig.addBtn.text))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: _vm.btnConfig.downLoadBtn.type,
                        size: _vm.btnConfig.size,
                        icon: _vm.btnConfig.downLoadBtn.icon,
                      },
                      on: { click: _vm.DoExportAll },
                    },
                    [_vm._v(_vm._s(_vm.btnConfig.downLoadBtn.text))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "section",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  key: _vm.tableKey,
                  staticStyle: { width: "100%" },
                  attrs: {
                    height: _vm.tabHeight,
                    data: _vm.list,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    fit: "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "单号",
                      prop: "no",
                      align: _vm.tableConfig.align,
                      width: "160",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "收货单位编码",
                      prop: "buyerCode",
                      align: _vm.tableConfig.align,
                      width: "160",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "收货单位名称",
                      prop: "buyerName",
                      align: _vm.tableConfig.align,
                      width: "160",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "收货单位类型",
                      prop: "buyerTypeStr",
                      align: _vm.tableConfig.align,
                      width: "160",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "收货单位等级",
                      prop: "buyerLevel",
                      align: _vm.tableConfig.align,
                      width: "160",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "收货组织",
                      prop: "buyerOrg",
                      align: _vm.tableConfig.align,
                      width: "160",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "收货总数量",
                      prop: "inNum",
                      align: _vm.tableConfig.align,
                      width: "160",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "单据状态",
                      prop: "orderStatus",
                      align: _vm.tableConfig.align,
                      width: "160",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            row.orderStatus == 0
                              ? _c("el-tag", { attrs: { type: "danger" } }, [
                                  _vm._v("删除"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            row.orderStatus == 1
                              ? _c("el-tag", { attrs: { type: "warning" } }, [
                                  _vm._v("待入库"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            row.orderStatus == 2
                              ? _c("el-tag", { attrs: { type: "warning" } }, [
                                  _vm._v("待审核"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            row.orderStatus == 3
                              ? _c("el-tag", { attrs: { type: "warning" } }, [
                                  _vm._v("部分入库"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            row.orderStatus == 4
                              ? _c("el-tag", { attrs: { type: "success" } }, [
                                  _vm._v("全部入库"),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "创建时间",
                      prop: "createTime",
                      align: _vm.tableConfig.align,
                      width: "170",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "收货时间",
                      prop: "inDate",
                      align: _vm.tableConfig.align,
                      width: "170",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      align: _vm.tableConfig.align,
                      width: "220",
                      fixed: "right",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.DoScan(row)
                                  },
                                },
                              },
                              [_vm._v("详情")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-dropdown",
                              { staticClass: "table-dropdown" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "el-dropdown-link" },
                                  [
                                    _vm._v(
                                      "\n                更多\n                "
                                    ),
                                    _c("i", {
                                      staticClass:
                                        "el-icon-arrow-down el-icon--right",
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-dropdown-menu",
                                  {
                                    attrs: { slot: "dropdown" },
                                    slot: "dropdown",
                                  },
                                  [
                                    _c(
                                      "el-dropdown-item",
                                      [
                                        row.orderStatus == "1"
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "text",
                                                  size: "mini",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.DoEdit(row)
                                                  },
                                                },
                                              },
                                              [_vm._v("修改")]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-dropdown-item",
                                      [
                                        row.orderStatus == "2"
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "text",
                                                  size: "mini",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.DoSh(row)
                                                  },
                                                },
                                              },
                                              [_vm._v("审核")]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-dropdown-item",
                                      [
                                        row.orderStatus == "1"
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "text",
                                                  size: "mini",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.DoDelete(row)
                                                  },
                                                },
                                              },
                                              [_vm._v("删除")]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                  page: _vm.listQuery.pageNo,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageNo", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.page2 ? _c("section", [_vm._m(0)]) : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: _vm.title,
            visible: _vm.dialogVisible,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("h3", { staticClass: "title" }, [_vm._v("入库信息")]),
          _vm._v(" "),
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { model: _vm.addData, "label-width": "110px" },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "is-required",
                          attrs: {
                            label: "收货单位编码:",
                            prop: "channelCode",
                          },
                        },
                        [
                          _c("el-input", {
                            ref: "sinput",
                            attrs: {
                              "suffix-icon": "el-icon-search",
                              clearable: "",
                              placeholder: "请输入",
                            },
                            on: { focus: _vm.getAddUnit },
                            model: {
                              value: _vm.channelCode,
                              callback: function ($$v) {
                                _vm.channelCode = $$v
                              },
                              expression: "channelCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "收货单位名称:",
                            prop: "channelName",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { readonly: "" },
                            model: {
                              value: _vm.channelName,
                              callback: function ($$v) {
                                _vm.channelName = $$v
                              },
                              expression: "channelName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "收货单位分类:",
                            prop: "channelClassName",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { readonly: "" },
                            model: {
                              value: _vm.channelClassName,
                              callback: function ($$v) {
                                _vm.channelClassName = $$v
                              },
                              expression: "channelClassName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "收货单位等级:",
                            prop: "channelGradeName",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { readonly: "" },
                            model: {
                              value: _vm.channelGradeName,
                              callback: function ($$v) {
                                _vm.channelGradeName = $$v
                              },
                              expression: "channelGradeName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "title" },
                [
                  _c("span", { staticStyle: { "margin-right": "20px" } }, [
                    _vm._v("产品明细"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.getMore },
                    },
                    [_vm._v("添加产品")]
                  ),
                  _vm._v(" "),
                  _vm.addData.isCode == 1
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.scanCode },
                        },
                        [_vm._v("查看数码")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    height: "300",
                    data: _vm.productItemList,
                    border: "",
                    fit: "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "产品编码",
                      prop: "productCode",
                      align: _vm.tableConfig.align,
                      width: "160",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品名称",
                      prop: "productName",
                      align: _vm.tableConfig.align,
                      width: "160",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "品牌",
                      prop: "productBrand",
                      align: _vm.tableConfig.align,
                      width: "160",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "规格",
                      prop: "productModel",
                      align: _vm.tableConfig.align,
                      width: "160",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "包装比例",
                      prop: "packScaleDesc",
                      align: _vm.tableConfig.align,
                      width: "160",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "收货数量",
                      prop: "num",
                      align: _vm.tableConfig.align,
                      width: "160",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("el-input", {
                              attrs: { placeholder: "请输入(必填)" },
                              model: {
                                value: row.num,
                                callback: function ($$v) {
                                  _vm.$set(row, "num", $$v)
                                },
                                expression: "row.num",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "单位",
                      prop: "packId",
                      align: _vm.tableConfig.align,
                      width: "160",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: { loading: _vm.selectLoading },
                                on: {
                                  focus: function ($event) {
                                    return _vm.getUnits(row)
                                  },
                                },
                                model: {
                                  value: row.packId,
                                  callback: function ($$v) {
                                    _vm.$set(row, "packId", $$v)
                                  },
                                  expression: "row.packId",
                                },
                              },
                              _vm._l(row.units, function (item) {
                                return _c("el-option", {
                                  key: item.pId,
                                  attrs: {
                                    label: item.unitName,
                                    value: item.pId,
                                  },
                                })
                              }),
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  false
                    ? _c("el-table-column", {
                        attrs: {
                          label: "最小单位数量",
                          prop: "packNum",
                          align: _vm.tableConfig.align,
                          width: "160",
                          "show-overflow-tooltip": "",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  false
                    ? _c("el-table-column", {
                        attrs: {
                          label: "最小单位",
                          prop: "minScaleName",
                          align: _vm.tableConfig.align,
                          width: "160",
                          "show-overflow-tooltip": "",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "赠品",
                      prop: "gifts",
                      align: _vm.tableConfig.align,
                      width: "160",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("el-switch", {
                              attrs: {
                                "active-value": _vm.activeValue,
                                "inactive-value": _vm.intiveValue,
                              },
                              model: {
                                value: row.gifts,
                                callback: function ($$v) {
                                  _vm.$set(row, "gifts", $$v)
                                },
                                expression: "row.gifts",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "备注",
                      prop: "remarks",
                      align: _vm.tableConfig.align,
                      width: "160",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      fixed: "right",
                      align: _vm.tableConfig.align,
                      width: "160",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeItem(row)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "title" }, [_vm._v("整单备注")]),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { padding: "0 0 15px 0" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      maxlength: "500",
                      placeholder: "请输入",
                    },
                    model: {
                      value: _vm.addData.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.addData, "remark", $$v)
                      },
                      expression: "addData.remark",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "title" }, [
                _vm._v("\n        图片附件\n        "),
                _c("span", { staticStyle: { "font-size": "14px" } }, [
                  _vm._v("最多5张，只能上传jpg/png文件，且不超过500kb"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-upload",
                {
                  attrs: {
                    action: "",
                    "list-type": "picture-card",
                    limit: 5,
                    "auto-upload": false,
                    "on-preview": _vm.handlePictureCardPreview,
                    "on-remove": _vm.handleRemove,
                    "on-change": _vm.uploadImg,
                    "on-exceed": _vm.handleExceed,
                    "file-list": _vm.uploadImgs,
                  },
                },
                [_c("i", { staticClass: "el-icon-plus" })]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btn-bottom-group align-right" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("2")
                        },
                      },
                    },
                    [_vm._v("提交")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "default", plain: "", size: "mini" },
                      on: { click: _vm.resetForm },
                    },
                    [_vm._v("关闭")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { "close-on-click-modal": false, visible: _vm.imgVisible },
          on: {
            "update:visible": function ($event) {
              _vm.imgVisible = $event
            },
          },
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: "期初入库-查看",
            visible: _vm.dialogVisible2,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible2 = $event
            },
          },
        },
        [
          _c("h3", { staticClass: "title" }, [_vm._v("入库信息")]),
          _vm._v(" "),
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { model: _vm.addData, "label-width": "110px" },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "收货单位编码:",
                            prop: "channelCode",
                          },
                        },
                        [_vm._v(_vm._s(_vm.channelCode))]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "收货单位名称:",
                            prop: "channelName",
                          },
                        },
                        [_vm._v(_vm._s(_vm.channelName))]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "收货单位分类:",
                            prop: "channelClassName",
                          },
                        },
                        [_vm._v(_vm._s(_vm.channelClassName))]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "收货单位等级:",
                            prop: "channelGradeName",
                          },
                        },
                        [_vm._v(_vm._s(_vm.channelGradeName))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "title" },
                [
                  _c("span", { staticStyle: { "margin-right": "20px" } }, [
                    _vm._v("产品明细"),
                  ]),
                  _vm._v(" "),
                  _vm.addData.isCode == 1
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.scanCode },
                        },
                        [_vm._v("查看数码")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    height: "300",
                    data: _vm.productItemList,
                    border: "",
                    fit: "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "产品编码",
                      prop: "productCode",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品名称",
                      prop: "productName",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "品牌",
                      prop: "productBrand",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "规格",
                      prop: "productModel",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "包装比例",
                      prop: "packScaleDesc",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "收货数量",
                      width: "140",
                      prop: "num",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "单位",
                      width: "100",
                      prop: "packId",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [_vm._v(_vm._s(row.units[0].unitName))]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  false
                    ? _c("el-table-column", {
                        attrs: {
                          label: "最小单位数量",
                          prop: "packNum",
                          width: "120",
                          "show-overflow-tooltip": "",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  false
                    ? _c("el-table-column", {
                        attrs: {
                          label: "最小单位",
                          prop: "minScaleName",
                          "show-overflow-tooltip": "",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "赠品",
                      prop: "gifts",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            row.gifts == 0
                              ? _c("span", [_vm._v("否")])
                              : _vm._e(),
                            _vm._v(" "),
                            row.gifts == 1
                              ? _c("span", [_vm._v("是")])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "备注",
                      prop: "remarks",
                      width: "200",
                      "show-overflow-tooltip": "",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "title" }, [_vm._v("整单备注")]),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { padding: "0 0 15px 0" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      maxlength: "500",
                      placeholder: "请输入",
                    },
                    model: {
                      value: _vm.addData.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.addData, "remark", $$v)
                      },
                      expression: "addData.remark",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "title" }, [_vm._v("图片附件")]),
              _vm._v(" "),
              _c(
                "div",
                _vm._l(_vm.uploadImgs, function (item, index) {
                  return _c("el-image", {
                    key: index,
                    staticStyle: { width: "80px", height: "80px" },
                    attrs: {
                      src: item.src,
                      "preview-src-list": _vm.preViewList,
                    },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "title" }, [_vm._v("审核日志")]),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    height: "300",
                    data: _vm.checklist,
                    border: "",
                    fit: "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "审核结果",
                      prop: "type",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "审核人",
                      prop: "userName",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "审核时间",
                      prop: "checkTime",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "审核意见",
                      prop: "auditmsg",
                      "show-overflow-tooltip": "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: "数码详情",
            visible: _vm.scanCodeVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.scanCodeVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.codeParam, "label-width": "80px" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "数码", prop: "code" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.codeParam.code,
                              callback: function ($$v) {
                                _vm.$set(_vm.codeParam, "code", $$v)
                              },
                              expression: "codeParam.code",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品名称", prop: "productName" } },
                        [
                          _c("el-input", {
                            attrs: {
                              "suffix-icon": "el-icon-search",
                              clearable: "",
                              placeholder: "请输入",
                            },
                            on: {
                              focus: function ($event) {
                                return _vm.getPname("codeScan")
                              },
                            },
                            model: {
                              value: _vm.codeParam.productName,
                              callback: function ($$v) {
                                _vm.$set(_vm.codeParam, "productName", $$v)
                              },
                              expression: "codeParam.productName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", icon: "el-icon-search" },
                          on: { click: _vm.searchCode },
                        },
                        [_vm._v("搜索")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "default",
                            plain: "",
                            icon: "el-icon-refresh",
                          },
                          on: { click: _vm.searchReset },
                        },
                        [_vm._v("重置")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.codeData, border: "", fit: "" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "盒码",
                  prop: "barcode",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "箱码",
                  prop: "boxcode",
                  align: _vm.tableConfig.align,
                  width: "120",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "产品编号",
                  prop: "productCode",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "产品名称",
                  prop: "productName",
                  width: "120",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "产品分类",
                  prop: "productClass",
                  width: "120",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "规格",
                  prop: "productModel",
                  width: "100",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "状态",
                  prop: "orderStatus",
                  width: "120",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.orderStatus == 0
                          ? _c("span", [_vm._v("取消")])
                          : _vm._e(),
                        _vm._v(" "),
                        row.orderStatus == 1
                          ? _c("span", [_vm._v("正常")])
                          : _vm._e(),
                        _vm._v(" "),
                        row.orderStatus == 2
                          ? _c("span", [_vm._v("删除")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total2,
              page: _vm.codeParam.pageNo,
              limit: _vm.codeParam.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.codeParam, "pageNo", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.codeParam, "pageSize", $event)
              },
              pagination: _vm.getCodeDetails,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("purchaseDialog", {
        ref: "channelSelect",
        attrs: { title: "选择单位" },
        on: { change: _vm.getUalue },
      }),
      _vm._v(" "),
      _c("productChose", { ref: "selProduct", on: { change: _vm.getProduct } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }