"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getdetail = getdetail;
exports.onOrOff = onOrOff;
exports.plancreate = plancreate;
exports.plandelete = plandelete;
exports.plansendNum = plansendNum;
exports.planupdate = planupdate;
exports.queryForPage = queryForPage;
exports.selectByMarketId = selectByMarketId;
exports.selectDataAll = selectDataAll;
exports.selectMarketByCount = selectMarketByCount;
exports.selectMarketByCountExcel = selectMarketByCountExcel;
exports.selectMarketList = selectMarketList;
exports.selectMarketListExcel = selectMarketListExcel;
var _request = _interopRequireDefault(require("@/utils/request"));
// var baseUrl = '/wh'
var baseUrl = '';
// 营销计划分页
function queryForPage(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/marketPlan/queryForPage',
    method: 'get',
    params: params
  });
}
// 删除
function plandelete(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/marketPlan/delete',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'get',
    params: params
  });
}
// 上下架
function onOrOff(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/marketPlan/onOrOff',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'get',
    params: params
  });
}
// 详情
function getdetail(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/marketPlan/detail',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    method: 'get',
    params: params
  });
}
// 新增计划
function plancreate(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/marketPlan/create',
    method: 'post',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    data: data
  });
}
// 修改计划
function planupdate(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/marketPlan/update',
    method: 'post',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    data: data
  });
}
// 查询营销对象可发送人数
function plansendNum(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/marketPlan/sendNum',
    method: 'post',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    data: data
  });
}

// 根据cid不分页查询所有
function selectDataAll(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/marketPlan/selectDataAll',
    headers: {
      'companyId': localStorage.getItem('cid')
    },
    method: 'get',
    params: params
  });
}
// 查詢效果統計列表下载
function selectMarketByCountExcel(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/marketPlanRecord/selectMarketByCountExcel',
    headers: {
      'companyId': localStorage.getItem('cid')
    },
    responseType: 'blob',
    method: 'get',
    params: params
  });
}
// 查詢参与记录列表下载
function selectMarketListExcel(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/marketPlanRecord/selectMarketListExcel',
    headers: {
      'companyId': localStorage.getItem('cid')
    },
    responseType: 'blob',
    method: 'get',
    params: params
  });
}

// 根据计划id查看效果统计
function selectByMarketId(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/marketPlanRecord/selectByMarketId',
    method: 'post',
    headers: {
      'companyId': localStorage.getItem('cid')
    },
    data: data
  });
}
// 查詢效果統計列表
function selectMarketByCount(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/marketPlanRecord/selectMarketByCount',
    method: 'post',
    headers: {
      'appId': sessionStorage.getItem('appId')
    },
    data: data
  });
}
// 查詢参与记录列表
function selectMarketList(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/marketPlanRecord/selectMarketList',
    method: 'post',
    headers: {
      'companyId': localStorage.getItem('cid')
    },
    data: data
  });
}