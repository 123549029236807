var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        { staticClass: "page-section" },
        [
          _c(
            "el-row",
            [
              _c(
                "el-form",
                {
                  ref: "formInline",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.formInline,
                    "status-icon": "",
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cols" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "会员昵称:",
                                    prop: "wechatNickName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入" },
                                    model: {
                                      value: _vm.formInline.wechatNickName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formInline,
                                          "wechatNickName",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "formInline.wechatNickName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "会员openid:",
                                    prop: "wechatOpenId",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入" },
                                    model: {
                                      value: _vm.formInline.wechatOpenId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formInline,
                                          "wechatOpenId",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "formInline.wechatOpenId",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "用户ID:", prop: "wechatId" },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入" },
                                    model: {
                                      value: _vm.formInline.wechatId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formInline,
                                          "wechatId",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "formInline.wechatId",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "用户IP:",
                                    prop: "ipAddress",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入" },
                                    model: {
                                      value: _vm.formInline.ipAddress,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formInline,
                                          "ipAddress",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "formInline.ipAddress",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "投诉入口:",
                                    prop: "entrance",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择" },
                                      model: {
                                        value: _vm.formInline.entrance,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formInline,
                                            "entrance",
                                            $$v
                                          )
                                        },
                                        expression: "formInline.entrance",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: {
                                          label: "扫码异常",
                                          value: "0",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          label: "个人中心",
                                          value: "1",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "类型:",
                                    prop: "feedbackType",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择" },
                                      model: {
                                        value: _vm.formInline.feedbackType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formInline,
                                            "feedbackType",
                                            $$v
                                          )
                                        },
                                        expression: "formInline.feedbackType",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "评价", value: "1" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { label: "建议", value: "2" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          label: "售后服务",
                                          value: "3",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "时间:" } },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "daterange",
                                      format: "yyyy-MM-dd",
                                      "value-format": "yyyy-MM-dd",
                                      "range-separator": "至",
                                      "start-placeholder": "开始日期",
                                      "end-placeholder": "结束日期",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.formInline.rangeTime,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formInline,
                                          "rangeTime",
                                          $$v
                                        )
                                      },
                                      expression: "formInline.rangeTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "div",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [_vm._v(" ")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.searchBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.searchBtn.icon,
                                      },
                                      on: { click: _vm.onSearch },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.btnConfig.searchBtn.text)
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.resetBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.resetBtn.icon,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onReset("formInline")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.btnConfig.resetBtn.text)
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.formConfig.btnFollow
            ? _c("el-divider", { staticClass: "btnDivider" })
            : _vm._e(),
          _vm._v(" "),
          _c("el-row", [
            _c("div", { staticClass: "btnArea" }, [
              _c("div", { staticClass: "head-btn-group" }),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                height: _vm.tabHeight,
                data: _vm.tableData,
                border: _vm.tableConfig.border,
                stripe: _vm.tableConfig.stripe,
                fit: "",
                "highlight-current-row": true,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "序号",
                  width: "80",
                  align: _vm.tableConfig.align,
                  "min-width": "140",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "会员头像", align: _vm.tableConfig.align },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-image", {
                          staticStyle: { width: "60px", height: "60px" },
                          attrs: { src: scope.row.headImgUrl, fit: "cover" },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "wechatNickName",
                  label: "会员昵称",
                  align: _vm.tableConfig.align,
                  "min-width": "140",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "wechatOpenId",
                  label: "会员openid",
                  align: _vm.tableConfig.align,
                  "min-width": "140",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "wechatId",
                  label: "用户ID",
                  align: _vm.tableConfig.align,
                  "min-width": "140",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "ipAddress",
                  label: "用户IP",
                  align: _vm.tableConfig.align,
                  "min-width": "140",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "entrance",
                  label: "投诉入口",
                  align: _vm.tableConfig.align,
                  "min-width": "140",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.entrance == 0
                          ? _c("span", [_vm._v("扫码异常")])
                          : _vm._e(),
                        _vm._v(" "),
                        row.entrance == 1
                          ? _c("span", [_vm._v("个人中心")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "feedbackType",
                  label: "投诉类型",
                  align: _vm.tableConfig.align,
                  "min-width": "140",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.feedbackType == 1
                          ? _c("el-tag", [_vm._v("评价")])
                          : _vm._e(),
                        _vm._v(" "),
                        row.feedbackType == 2
                          ? _c("el-tag", [_vm._v("建议")])
                          : _vm._e(),
                        _vm._v(" "),
                        row.feedbackType == 3
                          ? _c("el-tag", [_vm._v("售后服务")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "phone",
                  label: "客户电话",
                  align: _vm.tableConfig.align,
                  "min-width": "140",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "feedbackContent",
                  label: "内容",
                  align: _vm.tableConfig.align,
                  "min-width": "140",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "时间",
                  align: _vm.tableConfig.align,
                  "min-width": "140",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  fixed: "right",
                  align: _vm.tableConfig.align,
                  width: "100",
                  "class-name": "small-padding fixed-width",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.detail(row)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("Pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                  page: _vm.formInline.pageNo,
                  limit: _vm.formInline.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.formInline, "pageNo", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.formInline, "pageSize", $event)
                  },
                  pagination: _vm.queryListPage,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.mloading,
              expression: "mloading",
            },
          ],
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: "反馈详情",
            visible: _vm.scanVisible,
            "before-close": _vm.handleClose,
            width: "850px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.scanVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("div", { staticClass: "itemTitle" }, [_vm._v("反馈信息")]),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "cent", attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("会员头像:"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "content",
                          staticStyle: { height: "60px" },
                        },
                        [
                          _c("el-image", {
                            staticStyle: { width: "60px", height: "60px" },
                            attrs: {
                              src: _vm.ruleForm.headImgUrl,
                              fit: "cover",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("会员openid:"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "content",
                          staticStyle: { height: "60px" },
                        },
                        [_vm._v(_vm._s(_vm.ruleForm.wechatOpenId))]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("用户ID:"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "content",
                          staticStyle: { height: "60px" },
                        },
                        [_vm._v(_vm._s(_vm.ruleForm.wechatId))]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("用户IP:"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "content",
                          staticStyle: { height: "32px" },
                        },
                        [_vm._v(_vm._s(_vm.ruleForm.ipAddress))]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("投诉类型:"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "content" },
                        [
                          _vm.ruleForm.feedbackType == 1
                            ? _c("el-tag", [_vm._v("评价")])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.ruleForm.feedbackType == 2
                            ? _c("el-tag", [_vm._v("建议")])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.ruleForm.feedbackType == 3
                            ? _c("el-tag", [_vm._v("售后服务")])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("联系电话:"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "content",
                          staticStyle: { height: "32px" },
                        },
                        [_vm._v(_vm._s(_vm.ruleForm.phone))]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [_vm._v("邮箱:")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.ruleForm.email)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("创建时间:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.ruleForm.createTime)),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "middleLine" }),
              _vm._v(" "),
              _c("div", { staticClass: "itemTitle" }, [_vm._v("反馈内容")]),
              _vm._v(" "),
              _c("div", { staticStyle: { padding: "0 0 15px 0" } }, [
                _vm._v(_vm._s(_vm.ruleForm.feedbackContent)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "middleLine" }),
              _vm._v(" "),
              _c("div", { staticClass: "itemTitle" }, [_vm._v("反馈图片")]),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                _vm._l(_vm.ruleForm.imgList, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticStyle: { "margin-right": "12px" } },
                    [
                      _c("el-image", {
                        staticStyle: { width: "200px", height: "200px" },
                        attrs: {
                          src: item,
                          fit: "cover",
                          "preview-src-list": _vm.ruleForm.imgList,
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "default", plain: "" },
                  on: { click: _vm.handleClose },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }