"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _templateManage = require("@/api/market/templateManage");
var _HoverMask = _interopRequireDefault(require("@/components/HoverMask"));
var _scanCode = _interopRequireDefault(require("./components/scanCode"));
var _fanpai = _interopRequireDefault(require("./components/fanpai"));
var _rotaryTable = _interopRequireDefault(require("./components/rotaryTable"));
var _sudoku = _interopRequireDefault(require("./components/sudoku"));
var _scratchCard = _interopRequireDefault(require("./components/scratchCard"));
var _eggTwistMachine = _interopRequireDefault(require("./components/eggTwistMachine"));
var _focus = _interopRequireDefault(require("./components/focus"));
var _config = _interopRequireDefault(require("../config"));
var _configFanpai = _interopRequireDefault(require("../configFanpai"));
var _config2 = _interopRequireDefault(require("../otherTemplate/config"));
var _configRotaryTable = _interopRequireDefault(require("../configRotaryTable"));
var _configSudoku = _interopRequireDefault(require("../configSudoku"));
var _configScratchCard = _interopRequireDefault(require("../configScratchCard"));
var _configEggTwistMachine = _interopRequireDefault(require("../configEggTwistMachine"));
var _guidance = _interopRequireDefault(require("./components/guidance"));
var _guidance2 = _interopRequireDefault(require("../otherTemplate/guidance"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import Pagination from '@/components/Pagination'

var cachName = 'marketingModuleterminalManagementmarketingTemplatetemplateManagerepositorindex';
var _default = {
  name: cachName,
  components: {
    HoverMask: _HoverMask.default,
    Content: _config.default,
    Content2: _configFanpai.default,
    Content3: _config2.default,
    stard: _scanCode.default,
    word: _fanpai.default,
    rotaryTable: _rotaryTable.default,
    sudoku: _sudoku.default,
    scratchCard: _scratchCard.default,
    eggTwistMachine: _eggTwistMachine.default,
    focus: _focus.default,
    ContentRotaryTable: _configRotaryTable.default,
    ContentSudoku: _configSudoku.default,
    ContentScratchCard: _configScratchCard.default,
    ContentEggTwistMachine: _configEggTwistMachine.default,
    guidance: _guidance.default,
    Content4: _guidance2.default
  },
  data: function data() {
    return {
      id: '',
      type: '',
      editType: 'edit',
      editData: {},
      listLoading: false,
      dialogVisible: false,
      disabled: true,
      queryList: {
        templateCategory: ''
      },
      templateCategorys: [{
        name: '抽奖类',
        id: '1'
      }
      // { name: '集字类', id: '2' },
      // { name: '竞猜类', id: '3' },
      // { name: '引导关注类', id: '4' },
      // { name: '活动引导', id: '5' }
      ],

      list: [],
      apis: []
    };
  },
  activated: function activated() {
    var that = this;
    var hasCache = !!(that.list && that.list.length > 0);
    var authList = that.$route.meta.authList;
    that.apis = authList;
    var auth = that.hasAuth('searchbtn', authList);
    if (!hasCache) {
      if (auth) {
        that.getList();
      }
    }
  },
  // 缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = that.getCaches(that, name);
    if (!cache) {
      that.list = [];
    }
  },
  methods: {
    creatAct: function creatAct(row) {
      this.authId('scanbtn', this.apis);
      this.type = row.templateType;
      this.id = row.id;
      this.editData = row;
      this.dialogVisible = true;
    },
    close: function close() {
      this.dialogVisible = false;
    },
    changeSel: function changeSel() {
      this.getList();
    },
    clear: function clear() {
      this.queryList.templateType = '';
      this.queryList.pageNo = 1;
      this.getList();
    },
    getList: function getList() {
      var _this = this;
      this.authId('searchbtn', this.apis);
      this.listLoading = true;
      (0, _templateManage.templateRepositoryList)(this.queryList).then(function (res) {
        res = res.data;
        if (res.code === 200) {
          if (!res.data.errCode) {
            _this.list = res.data;
          } else {
            _this.$message({
              message: res.data.errMsg,
              type: 'error',
              duration: 3 * 1000
            });
          }
        } else {
          _this.$message.closeAll();
          _this.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
        _this.listLoading = false;
      });
    },
    seltMy: function seltMy(val) {
      var _this2 = this;
      this.authId('addbtn', this.apis);
      (0, _templateManage.selSysBuildTemplate)({
        id: val
      }).then(function (res) {
        res = res.data;
        if (res.status * 1 === 200) {
          _this2.$message.closeAll();
          _this2.$message({
            message: res.msg,
            type: 'success',
            duration: 3 * 1000
          });
        } else {
          _this2.$message.closeAll();
          _this2.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
      });
    }
  }
};
exports.default = _default;