import { render, staticRenderFns } from "./guidance.vue?vue&type=template&id=13e887df&scoped=true&"
import script from "./guidance.vue?vue&type=script&lang=js&"
export * from "./guidance.vue?vue&type=script&lang=js&"
import style0 from "./guidance.vue?vue&type=style&index=0&id=13e887df&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13e887df",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('13e887df')) {
      api.createRecord('13e887df', component.options)
    } else {
      api.reload('13e887df', component.options)
    }
    module.hot.accept("./guidance.vue?vue&type=template&id=13e887df&scoped=true&", function () {
      api.rerender('13e887df', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/marketingManage/marketingTemplate/templateManage/repository/components/guidance.vue"
export default component.exports