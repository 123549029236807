"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
var _tgItem = require("@/api/chanelmange/tgItem");
var _dealer = require("@/api/basic/dealer");
var _org = require("@/api/basic/org");
var _utils = require("@/utils");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _personChose = _interopRequireDefault(require("@/components/personChose"));
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
var _factory = require("@/api/basic/factory");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'chanelmangebasicsettgdanindex';
var _default = {
  name: 'Chanelmangebasicsettgdanindex',
  components: {
    Pagination: _Pagination.default,
    Treeselect: _vueTreeselect.default,
    personChose: _personChose.default
  },
  filters: {
    formatTime: function formatTime(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        return (0, _utils.parseTime)(value, '{y}-{m}-{d}');
      }
    },
    groupSysType: function groupSysType(val) {
      if (val === '' || val === null) return;
      var obj = {
        0: '企业',
        1: '经销商'
      };
      return obj[val];
    }
  },
  data: function data() {
    var checkPhone = function checkPhone(rule, value, callback) {
      var reg = /^1[3456789]\d{9}$/;
      if (reg.test(value)) {
        callback();
      } else {
        return callback(new Error('请输入正确的手机号'));
      }
    };
    return {
      listQuery: {
        salesmanId: '',
        salesmanStr: '',
        orgId: null,
        name: '',
        archivesStatus: '',
        pageNo: 1,
        pageSize: 20
      },
      orgOptions: [],
      states: [{
        name: '禁用',
        code: 0
      }, {
        name: '启用',
        code: 1
      }],
      orgList: [],
      depList: [],
      tableKey: 'ywtype',
      tabHeight: '100%',
      list: [],
      listLoading: false,
      total: 0,
      exportLoading: false,
      personShow: false,
      searchType: '',
      scanVisible: false,
      scanData: {},
      addData: {
        archivesStatus: '1',
        cityId: '',
        departmentId: '',
        contacts: '',
        countyId: '',
        customerType: '',
        detailedAddress: '',
        groupSysType: '',
        groupArchivesCode: '',
        groupArchivesName: '',
        groupBuyTypeId: '',
        orgId: '',
        outCode: '',
        pId: '',
        phone: '',
        provinceId: '',
        remarks: '',
        salesmanId: '',
        areas: []
      },
      areas: [],
      areaList: [],
      cityProps: {
        value: 'areaCode',
        label: 'areaName',
        children: 'children'
        // checkStrictly: true
      },

      salesmanStr: '',
      rules: {
        groupArchivesName: [{
          required: true,
          message: '请输入档案名称',
          trigger: 'blur'
        }],
        customerType: [{
          required: true,
          message: '请选择客户类型',
          trigger: 'change'
        }],
        groupBuyTypeId: [{
          required: true,
          message: '请选择团购类型',
          trigger: 'change'
        }],
        groupSysType: [{
          required: true,
          message: '请选择所属渠道',
          trigger: 'change'
        }],
        orgId: [{
          required: true,
          message: '请选择组织机构',
          trigger: 'input'
        }],
        contacts: [{
          required: true,
          message: '请输入联系人',
          trigger: 'blur'
        }],
        phone: [{
          validator: checkPhone,
          trigger: 'blur'
        }],
        areas: [{
          required: true,
          type: 'array',
          message: '请选择收货地址',
          trigger: ['blur', 'change']
        }],
        detailedAddress: [{
          required: true,
          message: '请输入详细地址',
          trigger: 'blur'
        }]
      },
      title: '团购档案-添加',
      dialogVisible: false,
      loading: false,
      dstate: false,
      tgOptions: [],
      customOptions: [{
        name: '个人',
        code: 1
      }, {
        name: '企业',
        code: 2
      }],
      fileList: [],
      fileName: '上传文件',
      subType: '',
      dialogVisible2: false,
      apis: [],
      page2: false,
      authName: '',
      expandTxt: '展开',
      showCol: false
    };
  },
  activated: function activated() {
    var that = this;
    var hasCache = that.list.length > 0;
    var authList = that.$route.meta.authList;
    that.apis = authList;
    var auth = that.hasAuth('searchbtn', authList);
    that.authId('searchbtn', that.apis);
    if (!hasCache) {
      if (auth) {
        that.getList();
        that.getOrg();
      }
    }
  },
  // 缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = that.getCaches(that, name);
    if (!cache) {
      that.list = [];
      that.total = 0;
    }
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    changetab: function changetab() {
      this.$forceUpdate();
    },
    changeorg: function changeorg(orgId) {
      var _this = this;
      this.addData.departmentId = '';
      (0, _factory.getChildOrgInfos)({
        orgId: orgId
      }).then(function (response) {
        if (response.data.code != 200) {
          _this.$message.error(response.data.msg);
        } else {
          _this.depList = response.data.data;
        }
      });
    },
    getOrgListAll: function getOrgListAll() {
      var that = this;
      (0, _org.fetchOrgList)({
        orgCodeAndName: '',
        orgStatus: 1,
        orgType: 1
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          that.orgList = response.data.data;
        }
      });
    },
    getList: function getList() {
      var that = this;
      that.authId('searchbtn', that.apis);
      that.listLoading = true;
      var param = {};
      param.salesmanId = that.listQuery.salesmanId;
      param.orgId = that.listQuery.orgId;
      param.name = that.listQuery.name;
      param.archivesStatus = that.listQuery.archivesStatus;
      param.pageNo = that.listQuery.pageNo;
      param.pageSize = that.listQuery.pageSize;
      (0, _tgItem.getGroupList)(param).then(function (res) {
        if (res.data.code == 200) {
          that.list = res.data.data.records;
          that.total = parseInt(res.data.data.total);
          setTimeout(function () {
            that.listLoading = false;
          }, 1 * 800);
        } else {
          that.$message.error(res.data.msg);
          that.listLoading = false;
          return;
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    getSearchOrg: function getSearchOrg() {
      var that = this;
      that.authId('searchbtn', that.apis);
      that.getOrg();
    },
    getOrg: function getOrg() {
      var that = this;
      (0, _tgItem.getQueryOrg)().then(function (res) {
        if (res.data.code == 200) {
          var rData = res.data.data;
          var arr = [];
          that.orgOriginData = rData;
          if (rData.length >= 0) {
            rData.forEach(function (item) {
              var obj = {};
              obj.id = item.pid;
              obj.label = item.orgName;
              obj.pid = item.pid;
              obj.parentPid = item.parentPid;
              arr.push(obj);
            });
            that.orgOptions = (0, _utils.transTreeData)(arr, 'pid', 'parentPid', 'children');
          }
        } else {
          that.$message.error(res.data.msg);
        }
      });
    },
    clearYid: function clearYid() {
      var that = this;
      that.listQuery.salesmanId = '';
    },
    DoSearch: function DoSearch() {
      var that = this;
      that.listQuery = (0, _utils.filterKeyNull)(that.listQuery);
      that.listQuery.pageNo = 1;
      that.getList();
      if (that.listQuery.orgId == '') {
        that.listQuery.orgId = null;
      }
    },
    DoReset: function DoReset(formName) {
      var that = this;
      that.listQuery.salesmanId = '';
      that.listQuery.salesmanStr = '';
      that.listQuery.orgId = null;
      that.listQuery.pageNo = 1;
      that.listQuery.name = '';
      that.listQuery.archivesStatus = '';
      that.getList();
    },
    // 选择业务员
    choseYwy: function choseYwy(str) {
      var that = this;
      that.searchType = str;
      if (str == 'search') {
        that.authId('searchbtn', that.apis);
      }
      that.personShow = true;
    },
    hidePerson: function hidePerson() {
      var that = this;
      that.personShow = false;
    },
    getPerson: function getPerson(val) {
      var that = this;
      var data = val;
      if (that.searchType == 'search') {
        that.listQuery.salesmanStr = data.userName;
        that.listQuery.salesmanId = data.pid;
      }
      if (that.searchType == 'add') {
        that.salesmanStr = data.userName;
        that.addData.salesmanId = data.pid;
      }
      that.personShow = false;
    },
    // 导出文件流并下载
    DoExportAll: function DoExportAll() {
      var that = this;
      that.authId('importbtn', that.apis);
      if (that.total > 50000) {
        that.$message.error('抱歉！您要导出的数据量超出了导出数据的上限，最多可导出50000条');
        return;
      }
      var param = {};
      param.salesmanId = that.listQuery.salesmanId;
      param.orgId = that.listQuery.orgId;
      param.name = that.listQuery.name;
      param.archivesStatus = that.listQuery.archivesStatus;
      param.pageNo = 1;
      param.pageSize = that.total;
      that.exportLoading = true;
      (0, _tgItem.exportData)(param).then(function (res) {
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '团购档案列表.xls';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
        that.exportLoading = false;
      }).catch(function () {
        that.exportLoading = false;
      });
    },
    // 导入
    DoImport: function DoImport() {
      var that = this;
      that.authId('inportbtn', that.apis);
      that.fileList = [];
      that.dialogVisible2 = true;
    },
    handleClose2: function handleClose2() {
      var that = this;
      that.dialogVisible2 = false;
    },
    handleCloseScan: function handleCloseScan() {
      var that = this;
      that.scanVisible = false;
    },
    // 下载模板
    downLoad: function downLoad() {
      (0, _tgItem.downLoadFiles)().then(function (res) {
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '团购档案导入模板.xls';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      });
    },
    // 选择文件-上传
    submitUpload: function submitUpload() {
      var that = this;
      var file = this.$refs.upload.uploadFiles;
      if (file.length > 0) {
        var formDate = new FormData();
        formDate.append('file', file[file.length - 1].raw);
        (0, _tgItem.uploadFiles)(formDate).then(function (res) {
          if (res.data.code == 200) {
            that.$message({
              type: 'success',
              message: '上传成功'
            });
            that.getList();
            that.handleClose2();
          } else {
            if (res.data.data.length > 0) {
              var str = '';
              res.data.data.forEach(function (item) {
                str += item.promptCase + '、';
              });
              that.$message.error(str);
            } else {
              that.$message.error(res.data.msg);
            }
          }
        });
      } else {
        that.$message.error('请选取文件');
        return;
      }
    },
    handleChange: function handleChange(file, fileList) {
      var that = this;
      console.log(file);
      that.fileName = file.name;
    },
    // 获取团购类型
    getTypes: function getTypes() {
      var that = this;
      var param = {
        groupStatus: null,
        pageNo: 0,
        pageSize: 0
      };
      (0, _tgItem.getTgTypeList)(param).then(function (res) {
        if (res.data.code == 200) {
          that.tgOptions = res.data.data;
        }
      });
    },
    // 获取所有区域
    getAreaAll: function getAreaAll() {
      var that = this;
      (0, _dealer.dealerAreaList)({
        isDelete: 1
      }).then(function (res) {
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            that.areaList = (0, _utils.transTreeData)(JSON.parse(JSON.stringify(res.data.data)), 'pId', 'parentId', 'children');
          } else {
            that.areaList = [];
          }
          sessionStorage.setItem('areaList', JSON.stringify(res.data.data));
        }
      });
    },
    handleChangeArea: function handleChangeArea(val) {
      var that = this;
      that.addData.provinceId = val[0];
      if (val.length == 2) {
        that.addData.cityId = val[1];
      }
      if (val.length == 3) {
        that.addData.countyId = val[2];
      }
    },
    // 新增
    DoAdd: function DoAdd() {
      var that = this;
      that.authId('addbtn', that.apis);
      that.getOrg();
      that.getTypes();
      that.getAreaAll();
      that.title = '团购档案-添加';
      that.subType = 'add';
      that.getOrgListAll();
      that.addData = {
        archivesStatus: '1',
        cityId: '',
        contacts: '',
        countyId: '',
        departmentId: '',
        customerType: '',
        groupSysType: '',
        detailedAddress: '',
        groupArchivesCode: '',
        groupArchivesName: '',
        groupBuyTypeId: '',
        orgId: null,
        outCode: '',
        pId: '',
        phone: '',
        provinceId: '',
        remarks: '',
        salesmanId: '',
        areas: []
      };
      that.areas = [];
      that.salesmanStr = '';
      that.$nextTick(function () {
        that.dialogVisible = true;
      });
    },
    // 修改
    DoEdit: function DoEdit(data) {
      var that = this;
      that.authId('updatebtn', that.apis);
      that.getOrg();
      that.getOrgListAll();
      that.getTypes();
      that.getAreaAll();
      that.title = '团购档案-修改';
      that.subType = 'edit';
      var param = {};
      param.pId = data.pId;
      (0, _tgItem.getGroupDetail)(param).then(function (res) {
        if (res.data.code == 200) {
          var rData = res.data.data;
          // that.addData.orgId = rData.orgId
          // that.changeorg(that.addData.orgId)
          that.addData = {
            archivesStatus: rData.archivesStatus,
            cityId: rData.cityId,
            contacts: rData.contacts,
            countyId: rData.countyId,
            customerType: rData.customerType,
            detailedAddress: rData.detailedAddress,
            groupSysType: rData.groupSysType,
            groupArchivesCode: rData.groupArchivesCode,
            groupArchivesName: rData.groupArchivesName,
            groupBuyTypeId: rData.groupBuyTypeId,
            orgId: rData.orgId,
            // departmentId: rData.departmentId,
            outCode: rData.outCode,
            pId: rData.pId,
            phone: rData.phone,
            provinceId: rData.provinceId,
            remarks: rData.remarks,
            salesmanId: rData.salesmanId,
            areas: []
          };
          that.changeorg(that.addData.orgId);
          that.salesmanStr = rData.salesmanName;
          that.addData.departmentId = rData.departmentId;
          that.areas = [rData.provinceId, rData.cityId, rData.countyId];
          that.addData.areas = [rData.provinceId, rData.cityId, rData.countyId];
          that.dialogVisible = true;
        } else {
          that.addData = (0, _utils.deepClone)(data);
          that.dialogVisible = true;
        }
      }).catch(function () {
        that.addData = (0, _utils.deepClone)(data);
        that.dialogVisible = true;
      });
    },
    handleClose: function handleClose() {
      var that = this;
      that.resetForm('addForm');
    },
    resetForm: function resetForm(formName) {
      var that = this;
      var data = that.addData;
      that.$refs[formName].resetFields();
      for (var key in data) {
        data[key] = '';
      }
      that.addData.orgId = null;
      that.addData.departmentId = '';
      that.areas = [];
      that.salesmanStr = '';
      that.dialogVisible = false;
    },
    submitForm: function submitForm(formName) {
      var that = this;
      var subType = that.subType;
      that.$refs[formName].validate(function (valid) {
        if (valid) {
          that.loading = true;
          that.dsate = true;
          var param = {};
          param = (0, _utils.deepClone)(that.addData);
          param.archivesStatus = Number(param.archivesStatus);
          if (param.areas.length > 0) {
            param.provinceId = param.areas[0];
            param.cityId = param.areas[1] || '';
            param.countyId = param.areas[2] || '';
          }
          delete param.areas;
          if (subType == 'add') {
            (0, _tgItem.addTgData)(param).then(function (res) {
              if (res.data.code == 200) {
                that.loading = false;
                that.dsate = false;
                that.$message({
                  type: 'success',
                  message: '添加成功'
                });
                that.getList();
                that.handleClose();
              } else {
                that.$message.error(res.data.msg);
                that.loading = false;
                that.dsate = false;
                return;
              }
            }).catch(function () {
              that.loading = false;
              that.dsate = false;
            });
          }
          if (subType == 'edit') {
            (0, _tgItem.updateTgData)(param).then(function (res) {
              if (res.data.code == 200) {
                that.loading = false;
                that.dsate = false;
                that.$message({
                  type: 'success',
                  message: '修改成功'
                });
                that.getList();
                that.handleClose();
              } else {
                that.$message.error(res.data.msg);
                that.loading = false;
                that.dsate = false;
                return;
              }
            }).catch(function () {
              that.loading = false;
              that.dsate = false;
            });
          }
        }
      });
    },
    DoScan: function DoScan(data) {
      var that = this;
      that.authId('detailbtn', that.apis);
      var pId = data.pId;
      var param = {
        pId: pId
      };
      (0, _tgItem.getGroupDetail)(param).then(function (res) {
        if (res.data.code == 200) {
          that.scanData = res.data.data;
        } else {
          that.scanData = (0, _utils.deepClone)(data);
        }
      }).catch(function () {
        that.scanData = (0, _utils.deepClone)(data);
      });
      that.$nextTick(function () {
        that.scanVisible = true;
      });
    },
    DoStop: function DoStop(data) {
      var that = this;
      that.authId('stopbtn', that.apis);
      var param = {};
      param.archivesStatus = 0;
      param.pId = data.pId;
      that.$confirm('是否禁用?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _tgItem.chageState)(param).then(function (res) {
          if (res.data.code == 200) {
            that.$message({
              type: 'success',
              message: '禁用成功'
            });
            that.listQuery.current = 1;
            that.getList();
          } else {
            that.$message.error(res.data.msg);
          }
        });
      }).catch(function () {
        console.log('禁用已取消');
      });
    },
    DoStart: function DoStart(data) {
      var that = this;
      that.authId('startbtn', that.apis);
      var param = {};
      param.archivesStatus = 1;
      param.pId = data.pId;
      that.$confirm('是否启用?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _tgItem.chageState)(param).then(function (res) {
          if (res.data.code == 200) {
            that.$message({
              type: 'success',
              message: '启用成功'
            });
            that.listQuery.current = 1;
            that.getList();
          } else {
            that.$message.error(res.data.msg);
          }
        });
      }).catch(function () {
        console.log('启用已取消');
      });
    },
    getCurrentOrgId: function getCurrentOrgId() {
      var _this2 = this;
      this.$nextTick(function () {
        _this2.$refs.addForm.validateField('orgId');
      });
    },
    // 展开收起搜索条件
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    }
  }
};
exports.default = _default;