var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c(
                "el-form",
                {
                  ref: "searchForm",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.searchForm,
                    "status-icon": "",
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cols" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "orderType",
                                    label: "单据类型:",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.searchForm.orderType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "orderType",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.orderType",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "全部", value: "" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { label: "其他入库", value: 1 },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { label: "其他出库", value: 2 },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.formConfig.btnFollow
                            ? _c(
                                "el-col",
                                {
                                  staticClass: "head-btn-group",
                                  attrs: { span: 6 },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { "label-width": "0" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v(" ")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.searchBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.searchBtn.icon,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleFilter("search")
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.searchBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.resetBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.resetBtn.icon,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleFilter("rest")
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.resetBtn.text)
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.formConfig.btnFollow
                ? _c("el-divider", { staticClass: "btnDivider" })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "btnArea" }, [
                _vm.hasAuth("addbtn", _vm.$route.meta.authList)
                  ? _c(
                      "div",
                      { staticClass: "head-btn-group" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: _vm.btnConfig.addBtn.type,
                              size: _vm.btnConfig.size,
                              icon: _vm.btnConfig.addBtn.icon,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleUpdate(false, "create")
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.btnConfig.addBtn.text))]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  key: _vm.tableKey,
                  staticStyle: { width: "100%" },
                  attrs: {
                    height: _vm.tabHeight,
                    data: _vm.tableData,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      width: "120",
                      align: _vm.tableConfig.align,
                      label: "序号",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "单据类型", align: _vm.tableConfig.align },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              row.otherType == "1"
                                ? _c("span", [_vm._v("其他入库")])
                                : _c("span", [_vm._v("其他出库")]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1554300012
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "名称",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [_vm._v(_vm._s(scope.row.billName))]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3564417258
                    ),
                  }),
                  _vm._v(" "),
                  _vm.hasAuth("updatebtn", _vm.$route.meta.authList) ||
                  _vm.hasAuth("deletebtn", _vm.$route.meta.authList)
                    ? _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          align: _vm.tableConfig.align,
                          width: "150",
                          fixed: "right",
                          "class-name": "small-padding fixed-width",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm.hasAuth(
                                    "updatebtn",
                                    _vm.$route.meta.authList
                                  )
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            disabled: row.isSys == "1",
                                            type: "text",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleUpdate(
                                                row,
                                                "update"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("编辑")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.hasAuth(
                                    "deletebtn",
                                    _vm.$route.meta.authList
                                  )
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            disabled: row.isSys == "1",
                                            type: "text",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleModifyStatus(
                                                row,
                                                "deleted"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          272725988
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  layout: "total,sizes,prev, pager, next, jumper",
                  total: _vm.total,
                  page: _vm.searchForm.current,
                  limit: _vm.searchForm.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.searchForm, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.searchForm, "size", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: _vm.textMap[_vm.dialogStatus],
            visible: _vm.dialogFormVisible,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              staticStyle: { width: "90%", margin: "0 auto" },
              attrs: {
                rules: _vm.rules,
                model: _vm.temp,
                "label-position": "left",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "单据类型", prop: "orderType" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择单据类型" },
                      on: { change: _vm.getDownType },
                      model: {
                        value: _vm.temp.orderType,
                        callback: function ($$v) {
                          _vm.$set(_vm.temp, "orderType", $$v)
                        },
                        expression: "temp.orderType",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "其他入库", value: 1 },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "其他出库", value: 2 },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "单据编码", prop: "billCode" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入单据编码" },
                    on: {
                      input: function (e) {
                        return (_vm.temp.billCode = _vm.validCode(e))
                      },
                    },
                    model: {
                      value: _vm.temp.billCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "billCode", $$v)
                      },
                      expression: "temp.billCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "单据名称", prop: "orderName" } },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      placeholder: "请输入单据名称",
                      maxlength: "20",
                      autosize: { minRows: 1, maxRows: 20 },
                    },
                    model: {
                      value: _vm.temp.orderName,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "orderName", $$v)
                      },
                      expression: "temp.orderName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "下游单据类型", prop: "relateId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择单据类型", clearable: "" },
                      model: {
                        value: _vm.temp.relateId,
                        callback: function ($$v) {
                          _vm.$set(_vm.temp, "relateId", $$v)
                        },
                        expression: "temp.relateId",
                      },
                    },
                    _vm._l(_vm.downTypes, function (item) {
                      return _c("el-option", {
                        key: item.billCode,
                        attrs: { label: item.billName, value: item.pid },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closeDilog } }, [
                _vm._v("取消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.btn_loading },
                  on: {
                    click: function ($event) {
                      _vm.dialogStatus === "create"
                        ? _vm.createData()
                        : _vm.updateData()
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.page2 ? _c("section", [_vm._m(1)]) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("\n      抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }