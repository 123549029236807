var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c(
            "div",
            { staticClass: "page-section", attrs: { id: "seach-box" } },
            [
              _c(
                "el-form",
                {
                  ref: "searchform",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.listQuery,
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                    "label-suffix": ":",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cols" },
                    [
                      _c(
                        "el-row",
                        {
                          staticClass: "head-btn-group",
                          attrs: { type: "flex", gutter: 20 },
                        },
                        [
                          _c(
                            "el-col",
                            { staticStyle: { width: "460px" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { width: "450px" },
                                  attrs: { label: "数码", prop: "markCode" },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: "请输入物流码、营销码",
                                      clearable: "",
                                      value: _vm.listQuery.markCode,
                                      maxlength: "40",
                                    },
                                    on: {
                                      input: function (e) {
                                        return (_vm.listQuery.markCode =
                                          _vm.validSe(e))
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { staticStyle: { "margin-left": "10px" } },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "label",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [_vm._v(" ")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.searchBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.searchBtn.icon,
                                      },
                                      on: { click: _vm.getList },
                                    },
                                    [_vm._v("查询")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-card",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasDetail1,
              expression: "hasDetail1",
            },
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "box-card",
        },
        [
          _c("div", { staticClass: "el-row" }, [
            _c(
              "div",
              {
                staticStyle: { "margin-bottom": "50px" },
                attrs: { id: "table-box" },
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "codeFrom",
                    staticStyle: { margin: "15px" },
                    attrs: {
                      model: _vm.codeinfo,
                      "label-width": "150px",
                      "label-position": "left",
                    },
                  },
                  [
                    _c("h3", [_vm._v("数码信息")]),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      { attrs: { gutter: 10 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "查询数码:", prop: "wlm" },
                              },
                              [
                                _vm.codeinfo.objCodeInfo
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.codeinfo.objCodeInfo.selectcode
                                            ? _vm.codeinfo.objCodeInfo
                                                .selectcode
                                            : ""
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "数码层级:", prop: "wlm" },
                              },
                              [
                                _vm.codeinfo.objCodeInfo
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.codeinfo.objCodeInfo.codelayer
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "查询时间:", prop: "wlm" },
                              },
                              [
                                _vm.codeinfo.objCodeInfo
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.codeinfo.objCodeInfo.selecttime
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "组码:", prop: "wlm" },
                              },
                              [
                                _vm.codeinfo.objCodeInfo
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.codeinfo.objCodeInfo.parentcode
                                            ? _vm.codeinfo.objCodeInfo
                                                .parentcode
                                            : ""
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-divider"),
                    _vm._v(" "),
                    _c("h3", [_vm._v("产品信息")]),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      { attrs: { gutter: 10 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "产品名称:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeinfo.objProInfo
                                        ? _vm.codeinfo.objProInfo.productname
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "一组只数:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeinfo.objProInfo
                                        ? _vm.codeinfo.objProInfo.zunumber
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: {
                                  label: "包装比例（箱/组/只）:",
                                  prop: "wlm",
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeinfo.objProInfo
                                        ? _vm.codeinfo.objProInfo.packag
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "产品型号:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeinfo.objProInfo
                                        ? _vm.codeinfo.objProInfo.producttype
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-divider"),
                    _vm._v(" "),
                    _c("h3", [_vm._v("生产信息")]),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      { attrs: { gutter: 10 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "生产厂地:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeinfo.objMakeInfo
                                        ? _vm.codeinfo.objMakeInfo.sccd
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "分公司代码:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeinfo.objMakeInfo
                                        ? _vm.codeinfo.objMakeInfo.fgsdm
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "分公司名称:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeinfo.objMakeInfo
                                        ? _vm.codeinfo.objMakeInfo.fgsmc
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "半成品分工厂:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeinfo.objMakeInfo
                                        ? _vm.codeinfo.objMakeInfo.bcpfgc
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "生产线:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeinfo.objMakeInfo
                                        ? _vm.codeinfo.objMakeInfo.scx
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "班组:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeinfo.objMakeInfo
                                        ? _vm.codeinfo.objMakeInfo.bz
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "生产批号:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeinfo.objMakeInfo
                                        ? _vm.codeinfo.objMakeInfo.scph
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "生产许可证号:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeinfo.objMakeInfo
                                        ? _vm.codeinfo.objMakeInfo.scxkzh
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "电池类型名称:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeinfo.objMakeInfo
                                        ? _vm.codeinfo.objMakeInfo.dclxmc
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "生产日期:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeinfo.objMakeInfo
                                        ? _vm.codeinfo.objMakeInfo.scrq |
                                            _vm.formatTime
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "数码扫描日期:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeinfo.objMakeInfo
                                        ? _vm.codeinfo.objMakeInfo.smsmrq
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "产品工艺:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeinfo.objMakeInfo
                                        ? _vm.codeinfo.objMakeInfo.cpgy
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "极板名称:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeinfo.objMakeInfo
                                        ? _vm.codeinfo.objMakeInfo.jbmc
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "隔板名称:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeinfo.objMakeInfo
                                        ? _vm.codeinfo.objMakeInfo.gbmc
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "经销商名称:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeinfo.objMakeInfo
                                        ? _vm.codeinfo.objMakeInfo.jxsmc
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: {
                                  label: "经销商区域代码:",
                                  prop: "wlm",
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeinfo.objMakeInfo
                                        ? _vm.codeinfo.objMakeInfo.jxsqydm
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "经销商ID:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeinfo.objMakeInfo
                                        ? _vm.codeinfo.objMakeInfo.jxsid
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-divider"),
                    _vm._v(" "),
                    _c("h3", [_vm._v("质保政策")]),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      { attrs: { gutter: 10 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: {
                                  label: "调新电池（月）:",
                                  prop: "wlm",
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeinfo.objThhInfo
                                        ? _vm.codeinfo.objThhInfo.txdcy
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: {
                                  label: "调专用电池（月）:",
                                  prop: "wlm",
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeinfo.objThhInfo
                                        ? _vm.codeinfo.objThhInfo.tzydcy
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: {
                                  label: "调维护电池（月）:",
                                  prop: "wlm",
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeinfo.objThhInfo
                                        ? _vm.codeinfo.objThhInfo.twhdcy
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "过保期（月）:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeinfo.objThhInfo
                                        ? _vm.codeinfo.objThhInfo.gbqy
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.codeinfo.objCodeWlList ? _c("el-divider") : _vm._e(),
                _vm._v(" "),
                _c("h3", [_vm._v("物流信息")]),
                _vm._v(" "),
                _vm.codeinfo.objCodeWlList
                  ? _c(
                      "el-timeline",
                      {
                        staticStyle: {
                          "margin-top": "20px",
                          "margin-right": "10px",
                        },
                      },
                      _vm._l(
                        _vm.codeinfo.objCodeWlList,
                        function (item, index) {
                          return _c("el-timeline-item", { key: index }, [
                            _vm._v(
                              "\n            " + _vm._s(item) + "\n          "
                            ),
                          ])
                        }
                      ),
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-card",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasDetail,
              expression: "hasDetail",
            },
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "box-card",
        },
        [
          _c("div", { staticClass: "el-row" }, [
            _c(
              "div",
              {
                staticStyle: { "margin-bottom": "50px" },
                attrs: { id: "table-box" },
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "codeFrom",
                    staticStyle: { margin: "15px" },
                    attrs: {
                      model: _vm.codeFrom,
                      "label-width": "150px",
                      "label-position": "left",
                    },
                  },
                  [
                    _c("h3", [_vm._v("数码信息")]),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      { attrs: { gutter: 10 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "当前数码:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeFrom.currentMarkCode
                                        ? _vm.codeFrom.currentMarkCode
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "层级类型:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("levelType")(
                                        _vm.codeFrom.currentPackLevel,
                                        _vm.codeFrom.currentMarkType
                                      )
                                    ) + "码"
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "生成日期:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatTime")(
                                        _vm.codeFrom.endGenTime
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "制码订单号:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeFrom.markOrderNo
                                        ? _vm.codeFrom.markOrderNo
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "包材商:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeFrom.supplierName
                                        ? _vm.codeFrom.supplierName
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "状态:", prop: "wlm" },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    class:
                                      _vm.numColor[
                                        _vm.codeFrom.currentMarkStatus
                                      ],
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("numStatus")(
                                          _vm.codeFrom.currentMarkStatus
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-divider"),
                    _vm._v(" "),
                    _c("h3", [_vm._v("数码")]),
                    _vm._v(" "),
                    _c(
                      "el-table",
                      {
                        key: _vm.tableKey,
                        attrs: {
                          data: _vm.list,
                          border: _vm.tableConfig.border,
                          stripe: _vm.tableConfig.stripe,
                          fit: "",
                          "default-expand-all": "",
                          "row-key": "markCode",
                          "tree-props": {
                            children: "children",
                            hasChildren: "hasChildren",
                          },
                          "highlight-current-row": "",
                          "max-height": "600",
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            label: "数码",
                            align: _vm.tableConfig.align,
                            "min-width": "180",
                            "show-overflow-tooltip": "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.markCode ==
                                  _vm.codeFrom.currentMarkCode
                                    ? _c("span", { staticClass: "fc" }, [
                                        _vm._v(_vm._s(scope.row.markCode)),
                                      ])
                                    : _c("span", [
                                        _vm._v(_vm._s(scope.row.markCode)),
                                      ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "层级类型",
                            align: _vm.tableConfig.align,
                            "min-width": "80",
                            "show-overflow-tooltip": "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("levelType")(
                                          scope.row.packLevel,
                                          scope.row.markType
                                        )
                                      ) + "码"
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "验证码",
                            align: _vm.tableConfig.align,
                            "min-width": "80",
                            "show-overflow-tooltip": "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.packLevel < 4
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row.validCode)),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "状态",
                            align: _vm.tableConfig.align,
                            prop: "urlAddress",
                            "min-width": "100",
                            "show-overflow-tooltip": "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "span",
                                    {
                                      class: _vm.numColor[scope.row.markStatus],
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("numStatus")(
                                            scope.row.markStatus
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-divider"),
                    _vm._v(" "),
                    _c("h3", [_vm._v("产品信息")]),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      { attrs: { gutter: 10 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "产品编号:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeFrom.productionVo
                                        ? _vm.codeFrom.productionVo.productCode
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "产品名称:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeFrom.productionVo
                                        ? _vm.codeFrom.productionVo.productName
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "产品分类:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeFrom.productionVo
                                        ? _vm.codeFrom.productionVo
                                            .productClassName
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "产品品牌:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeFrom.productionVo
                                        ? _vm.codeFrom.productionVo.brandName
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "产品规格:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeFrom.productionVo
                                        ? _vm.codeFrom.productionVo.productModel
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "包装比例:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeFrom.productionVo
                                        ? _vm.codeFrom.productionVo
                                            .packScaleDesc
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.codeFrom.productionVo
                          ? _c(
                              "div",
                              _vm._l(
                                _vm.codeFrom.productionVo.productExtendVOList,
                                function (item, index) {
                                  return _c(
                                    "el-col",
                                    { key: index, attrs: { span: 8 } },
                                    [
                                      item.attributeType == 3
                                        ? _c(
                                            "el-form-item",
                                            {
                                              staticClass:
                                                "postInfo-container-item",
                                              attrs: {
                                                label: item.attributeName + ":",
                                                prop: "wlm",
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(item.extendValueDesc)
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _c(
                                            "el-form-item",
                                            {
                                              staticClass:
                                                "postInfo-container-item",
                                              attrs: {
                                                label: item.attributeName + ":",
                                                prop: "wlm",
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(item.extendValue)
                                                ),
                                              ]),
                                            ]
                                          ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "启用状态:", prop: "wlm" },
                              },
                              [
                                _vm.codeFrom.productionVo
                                  ? _c(
                                      "span",
                                      {
                                        class:
                                          _vm.numColor2[
                                            _vm.codeFrom.productionVo
                                              .productArchivesStatus
                                          ],
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("typeStatus")(
                                              _vm.codeFrom.productionVo
                                                .productArchivesStatus
                                            )
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-divider"),
                    _vm._v(" "),
                    _c("h3", [_vm._v("生产信息")]),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      { attrs: { gutter: 10 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "生产任务单号:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeFrom.productionVo
                                        ? _vm.codeFrom.productionVo.orderNo
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "是否有活动:", prop: "wlm" },
                              },
                              [
                                _vm.codeFrom.productionVo &&
                                _vm.codeFrom.productionVo.hasScheme == 1
                                  ? _c("span", [_vm._v("是")])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.codeFrom.productionVo &&
                                _vm.codeFrom.productionVo.hasScheme == 0
                                  ? _c("span", [_vm._v("否")])
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "活动方案:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeFrom.productionVo
                                        ? _vm.codeFrom.productionVo.schemeName
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "生产批次号:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeFrom.productionVo
                                        ? _vm.codeFrom.productionVo.batchNo
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "生产日期:", prop: "wlm" },
                              },
                              [
                                _vm.codeFrom.productionVo
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatTime")(
                                            _vm.codeFrom.productionVo
                                              .prodLineTime
                                          )
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "生产工厂:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeFrom.productionVo
                                        ? _vm.codeFrom.productionVo.factoryName
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "生产产线:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeFrom.productionVo
                                        ? _vm.codeFrom.productionVo
                                            .productLineName
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "生产车间:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeFrom.productionVo
                                        ? _vm.codeFrom.productionVo.shopName
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "生产班组:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeFrom.productionVo
                                        ? _vm.codeFrom.productionVo.teamName
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-divider"),
                    _vm._v(" "),
                    _c("h3", [_vm._v("数码替换记录")]),
                    _vm._v(" "),
                    _c(
                      "el-table",
                      {
                        attrs: {
                          data: _vm.codeFrom.replaceResultDTOS,
                          border: _vm.tableConfig.border,
                          stripe: _vm.tableConfig.stripe,
                          fit: "",
                          "highlight-current-row": "",
                          "max-height": "600",
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            label: "原始数码",
                            prop: "oldMarkCode",
                            align: _vm.tableConfig.align,
                            "min-width": "180",
                            "show-overflow-tooltip": "",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "新数码",
                            prop: "newMarkCode",
                            align: _vm.tableConfig.align,
                            "min-width": "180",
                            "show-overflow-tooltip": "",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "替换场景",
                            prop: "replaceReason",
                            align: _vm.tableConfig.align,
                            "min-width": "180",
                            "show-overflow-tooltip": "",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "操作人",
                            prop: "replaceOperator",
                            align: _vm.tableConfig.align,
                            "min-width": "180",
                            "show-overflow-tooltip": "",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "替换时间",
                            prop: "createTime",
                            align: _vm.tableConfig.align,
                            "min-width": "180",
                            "show-overflow-tooltip": "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm._f("textDate")(row.createTime))
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-divider"),
                    _vm._v(" "),
                    _c("h3", [_vm._v("解除关联记录")]),
                    _vm._v(" "),
                    _c(
                      "el-table",
                      {
                        attrs: {
                          data: _vm.dataList,
                          "span-method": _vm.objectSpanMethod,
                          border: _vm.tableConfig.border,
                          stripe: _vm.tableConfig.stripe,
                          fit: "",
                          "highlight-current-row": "",
                          "max-height": "600",
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            prop: "relationMarkCode",
                            label: "解除数码",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { prop: "markCode", label: "解除盒码" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  row.markCode == _vm.codeFrom.currentMarkCode
                                    ? _c("span", { staticClass: "fc" }, [
                                        _vm._v(_vm._s(row.markCode)),
                                      ])
                                    : _c("span", [
                                        _vm._v(_vm._s(row.markCode)),
                                      ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { prop: "creatorUserName", label: "操作人" },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { prop: "optTime", label: "替换时间" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm._f("textDate")(row.optTime))
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-divider"),
                    _vm._v(" "),
                    _c("h3", [_vm._v("防伪查询信息(首次)")]),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      { attrs: { gutter: 10 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "查询时间:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeFrom.fistAntiVo
                                        ? _vm.codeFrom.fistAntiVo.queryTime
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "查询地点:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeFrom.fistAntiVo
                                        ? _vm.codeFrom.fistAntiVo
                                            .queryProvince +
                                            _vm.codeFrom.fistAntiVo.queryCity +
                                            _vm.codeFrom.fistAntiVo.queryCounty
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "IP:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeFrom.fistAntiVo
                                        ? _vm.codeFrom.fistAntiVo.ipAddress
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-divider"),
                    _vm._v(" "),
                    _c("h3", [_vm._v("活动信息")]),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      { attrs: { gutter: 10 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "所属活动:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeFrom.activityConfigInfoByMarkVo
                                        ? _vm.codeFrom
                                            .activityConfigInfoByMarkVo
                                            .configName
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "抽奖状态:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeFrom.activityConfigInfoByMarkVo
                                        ? _vm.codeFrom
                                            .activityConfigInfoByMarkVo
                                            .drawStatus
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "中奖结果:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeFrom.activityConfigInfoByMarkVo
                                        ? _vm.codeFrom
                                            .activityConfigInfoByMarkVo
                                            .prizeName
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "核销层级:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeFrom.activityConfigInfoByMarkVo
                                        ? _vm.codeFrom
                                            .activityConfigInfoByMarkVo
                                            .writeLevel
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "核销结果:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeFrom.activityConfigInfoByMarkVo
                                        ? _vm.codeFrom
                                            .activityConfigInfoByMarkVo
                                            .writeResult
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: {
                                  label: "核销返利金额(元):",
                                  prop: "wlm",
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeFrom.activityConfigInfoByMarkVo
                                        ? _vm.codeFrom
                                            .activityConfigInfoByMarkVo
                                            .writePrice
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "postInfo-container-item",
                                attrs: { label: "已绑定终端:", prop: "wlm" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeFrom.zdActivityInfo
                                        ? _vm.codeFrom.zdActivityInfo
                                            .bindTerminalName
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-divider"),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "calc(100%-20px)",
                      overflow: "hidden",
                      "margin-bottom": "0",
                      height: "100%",
                    },
                  },
                  [
                    _c("h3", [_vm._v("物流信息")]),
                    _vm._v(" "),
                    _vm.codeFrom.markCodeBillVo
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              height: "calc(100% - 48px)",
                              "overflow-y": "auto",
                            },
                          },
                          [
                            false
                              ? _c(
                                  "el-timeline",
                                  {
                                    staticStyle: {
                                      "margin-top": "20px",
                                      "margin-right": "10px",
                                    },
                                  },
                                  _vm._l(
                                    _vm.codeFrom.markCodeBillVo,
                                    function (activity, index) {
                                      return _c(
                                        "el-timeline-item",
                                        {
                                          key: index,
                                          attrs: { placement: "top" },
                                        },
                                        [
                                          activity.billType > 200 ||
                                          (activity.billType > 4 &&
                                            activity.billType < 8)
                                            ? _c(
                                                "el-row",
                                                [
                                                  _c("el-col", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "vue-from-block",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "content",
                                                            staticStyle: {
                                                              color: "green",
                                                              "margin-top":
                                                                "-20px",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                activity.billDate
                                                              ) +
                                                                " " +
                                                                _vm._s(
                                                                  activity.sendComName
                                                                ) +
                                                                "[" +
                                                                _vm._s(
                                                                  activity.outStoreName
                                                                ) +
                                                                "]"
                                                            ),
                                                            !activity.otherTypeName
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "billType"
                                                                      )(
                                                                        activity.billType
                                                                      )
                                                                    )
                                                                  ),
                                                                ])
                                                              : _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      activity.otherTypeName
                                                                    )
                                                                  ),
                                                                ]),
                                                            _vm._v(
                                                              "出到[" +
                                                                _vm._s(
                                                                  activity.receiveComName
                                                                ) +
                                                                "[" +
                                                                _vm._s(
                                                                  activity.receiveComCode
                                                                ) +
                                                                "]]     单据编号: " +
                                                                _vm._s(
                                                                  activity.billNo
                                                                )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              )
                                            : _c(
                                                "el-row",
                                                [
                                                  _c("el-col", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "vue-from-block",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "content",
                                                            staticStyle: {
                                                              color: "green",
                                                              "margin-top":
                                                                "-20px",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                activity.billDate
                                                              ) +
                                                                " " +
                                                                _vm._s(
                                                                  activity.sendComName
                                                                ) +
                                                                "[" +
                                                                _vm._s(
                                                                  activity.inStoreName
                                                                ) +
                                                                "]"
                                                            ),
                                                            !activity.otherTypeName
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "billType"
                                                                      )(
                                                                        activity.billType
                                                                      )
                                                                    )
                                                                  ),
                                                                ])
                                                              : _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      activity.otherTypeName
                                                                    )
                                                                  ),
                                                                ]),
                                                            _vm._v(
                                                              "入到[" +
                                                                _vm._s(
                                                                  activity.receiveComName
                                                                ) +
                                                                "[" +
                                                                _vm._s(
                                                                  activity.receiveComCode
                                                                ) +
                                                                "]]     单据编号: " +
                                                                _vm._s(
                                                                  activity.billNo
                                                                )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "el-timeline",
                              {
                                staticStyle: {
                                  "margin-top": "20px",
                                  "margin-right": "10px",
                                },
                              },
                              _vm._l(
                                _vm.codeFrom.markCodeBillVo,
                                function (item, index) {
                                  return _c(
                                    "el-timeline-item",
                                    {
                                      key: index,
                                      attrs: {
                                        color: "#5b2832",
                                        size: "normal",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "storage_info_wrapper" },
                                        [
                                          item.billClass == 1
                                            ? _c(
                                                "div",
                                                { staticClass: "storage_info" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "title_info",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "title_info_name",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.receiveComCode
                                                                ? "(" +
                                                                    item.receiveComCode +
                                                                    ")"
                                                                : ""
                                                            ) +
                                                              _vm._s(
                                                                item.receiveComName
                                                                  ? item.receiveComName
                                                                  : ""
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "detail_all",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "detail_type",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "billType"
                                                                )(item.billType)
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "detail_info",
                                                        },
                                                        [
                                                          _c(
                                                            "el-descriptions",
                                                            [
                                                              _c(
                                                                "el-descriptions-item",
                                                                {
                                                                  attrs: {
                                                                    "label-style":
                                                                      "color:#91929B",
                                                                    label:
                                                                      "入库单号",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.billNo
                                                                        ? item.billNo
                                                                        : ""
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "el-descriptions-item",
                                                                {
                                                                  attrs: {
                                                                    "label-style":
                                                                      "color:#91929B",
                                                                    label:
                                                                      "入库时间",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.billDate
                                                                        ? item.billDate
                                                                        : ""
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "el-descriptions-item",
                                                                {
                                                                  attrs: {
                                                                    "label-style":
                                                                      "color:#91929B",
                                                                    label:
                                                                      "发货单位",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.sendComCode
                                                                        ? "(" +
                                                                            item.sendComCode +
                                                                            ")"
                                                                        : ""
                                                                    ) +
                                                                      _vm._s(
                                                                        item.sendComName
                                                                          ? item.sendComName
                                                                          : ""
                                                                      )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          item.billClass == 2
                                            ? _c(
                                                "div",
                                                { staticClass: "storage_info" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "title_info",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "title_info_name",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.sendComCode
                                                                ? "(" +
                                                                    item.sendComCode +
                                                                    ")"
                                                                : ""
                                                            ) +
                                                              _vm._s(
                                                                item.sendComName
                                                                  ? item.sendComName
                                                                  : ""
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "detail_all",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "detail_type",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "billType"
                                                                )(item.billType)
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "detail_info",
                                                        },
                                                        [
                                                          _c(
                                                            "el-descriptions",
                                                            [
                                                              _c(
                                                                "el-descriptions-item",
                                                                {
                                                                  attrs: {
                                                                    "label-style":
                                                                      "color:#91929B",
                                                                    label:
                                                                      "出库单号",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.billNo
                                                                        ? item.billNo
                                                                        : ""
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "el-descriptions-item",
                                                                {
                                                                  attrs: {
                                                                    "label-style":
                                                                      "color:#91929B",
                                                                    label:
                                                                      "出库时间",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.billDate
                                                                        ? item.billDate
                                                                        : ""
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "el-descriptions-item",
                                                                {
                                                                  attrs: {
                                                                    "label-style":
                                                                      "color:#91929B",
                                                                    label:
                                                                      "收货单位",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.receiveComCode
                                                                        ? "(" +
                                                                            item.receiveComCode +
                                                                            ")"
                                                                        : ""
                                                                    ) +
                                                                      _vm._s(
                                                                        item.receiveComName
                                                                          ? item.receiveComName
                                                                          : ""
                                                                      )
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "el-descriptions-item",
                                                                {
                                                                  attrs: {
                                                                    "label-style":
                                                                      "color:#91929B",
                                                                    label:
                                                                      "出库操作人",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.operator
                                                                        ? item.operator
                                                                        : ""
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }