"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.sort");
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _activityManage = require("@/api/market/activityManage");
var _wordExchangeRule = require("@/api/prizeSetting/wordExchangeRule");
var _selectWord = _interopRequireDefault(require("./selectWord"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Added',
  components: {
    selectWord: _selectWord.default
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    ruleId: {
      type: String,
      default: ''
    },
    dialogVisible: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    var validatePri = function validatePri(rule, value, callback) {
      var len = 0;
      if (!value) {
        callback(new Error('请输入,不能为空'));
      } else {
        for (var i = 0; i < value.length; i++) {
          var c = value.charCodeAt(i);
          // 单字节加1
          if (c >= 0x0001 && c <= 0x007e || c >= 0xff60 && c <= 0xff9f) {
            len++;
          } else {
            len += 2;
          }
        }
        if (len > 40) {
          callback(new Error('最多为不超过40个字符，相当于20个汉字。'));
        }
        callback();
      }
      callback();
    };
    return {
      sourceList: [],
      form: {
        wordPrizes: [],
        ruleName: '',
        exchangePrizeType: '',
        explain: '',
        exchangePrizeId: '',
        financeSourceId: '',
        rangeTime: ''
      },
      rules: {
        ruleName: [{
          required: true,
          trigger: 'blur',
          validator: validatePri
        }],
        wordPrizes: [{
          type: 'array',
          required: true,
          message: '请选择字卡',
          trigger: 'change'
        }],
        exchangePrizeType: [{
          required: true,
          message: '请选择奖品类型',
          trigger: 'change'
        }],
        exchangePrizeId: [{
          required: true,
          message: '请选择奖品',
          trigger: 'change'
        }],
        rangeTime: [{
          required: true,
          message: '请选择时间',
          trigger: 'change'
        }],
        financeSourceId: [{
          required: true,
          message: '请选择资金来源',
          trigger: 'change'
        }]
      },
      prizeTypes: [{
        name: '红包',
        value: '100'
      }, {
        name: '实物奖品',
        value: '200'
      }
      // { name: '第三方', value: '300' },
      // { name: '积分', value: '400' },
      // { name: '字卡', value: '500' },
      // { name: '礼包', value: '900' },
      // { name: '谢谢惠顾', value: '0' }
      ],

      plist: [],
      btnLoading: false,
      selectWordVisible: false
    };
  },
  computed: {
    isDisable: function isDisable() {
      return this.title == '详情';
    }
  },
  mounted: function mounted() {
    this.getZjSourceList();
    if (this.ruleId) {
      this.getDetail();
    }
  },
  methods: {
    getDetail: function getDetail() {
      var _this = this;
      (0, _wordExchangeRule.getPrize_Rule_Detail)({
        ruleId: this.ruleId
      }).then(function (res) {
        if (res.data.data.code || res.data.data.code == 0) {
          return _this.$message.error(res.data.data.msg);
        } else {
          var _res$data$data = res.data.data,
            ruleStartTimeStr = _res$data$data.ruleStartTimeStr,
            ruleEndTimeStr = _res$data$data.ruleEndTimeStr;
          var data = res.data.data;
          _this.form = res.data.data;
          var wordPrizes = data.wordPrizeList.map(function (item) {
            return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, item), {}, {
              prizeId: item.wordPrizeId,
              prizeName: item.wordPrizeName,
              prizeRank: item.wordPrizeRank
            });
          });
          wordPrizes.sort(function (a, b) {
            return +a.prizeRank > +b.prizeRank ? 1 : -1;
          });
          data.wordPrizes = wordPrizes;
          _this.form = data;
          _this.$set(_this.form, 'rangeTime', [ruleStartTimeStr, ruleEndTimeStr]);
          _this.getCurPrizeType(res.data.data.exchangePrizeType, false);
        }
      });
    },
    // 获取资金来源
    getZjSourceList: function getZjSourceList() {
      var _this2 = this;
      (0, _activityManage.getMoneySource)().then(function (res) {
        if (res.data.code == 200) {
          if (res.data.data.code || res.data.data.code == 0) {
            return _this2.$message.error(res.data.data.msg);
          } else {
            _this2.sourceList = res.data.data;
            var resdata = res.data.data;
            if (!_this2.form.financeSourceId) {
              for (var m = 0; m < resdata.length; m++) {
                if (resdata[m].isDefault == '1') {
                  _this2.form.financeSourceId = resdata[m].id;
                  break;
                }
              }
            }
          }
        } else {
          return _this2.$message.error(res.data.data.msg);
        }
      });
    },
    getCurPrizeType: function getCurPrizeType($event) {
      var _this3 = this;
      var isClear = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      var param = {
        pageNo: 0,
        pageSize: 0,
        status: 1,
        prizeType: $event
      };
      (0, _activityManage.getPrizeLists)(param).then(function (res) {
        if (res.data.code == 200) {
          if (res.data.data.code || res.data.data.code == 0) {
            return _this3.$message.error(res.data.data.msg);
          } else {
            _this3.plist = res.data.data;
            if (isClear) _this3.form.exchangePrizeId = '';
          }
        } else {
          return _this3.$message.error(res.data.data.msg);
        }
      });
    },
    handleSelectWord: function handleSelectWord() {
      this.selectWordVisible = true;
    },
    getSelectWord: function getSelectWord(data) {
      this.form.wordPrizes = data || [];
    },
    handleSave: function handleSave() {
      var _this4 = this;
      this.$refs['form'].validate(function (valid) {
        if (valid) {
          if (!_this4.form.wordPrizes || !_this4.form.wordPrizes.length) {
            _this4.$message.error('字卡不能为空');
            return;
          }
          _this4.btnLoading = true;
          var sumbitData = (0, _objectSpread2.default)({}, _this4.form);
          if (_this4.form.rangeTime && _this4.form.rangeTime.length) {
            sumbitData.ruleStartTime = _this4.form.rangeTime[0];
            sumbitData.ruleEndTime = _this4.form.rangeTime[1];
          }
          (0, _wordExchangeRule.saveOrUpdate_Rule)(sumbitData).then(function (res) {
            if (res.data.data.code || res.data.data.code == 0) {
              _this4.btnLoading = false;
              return _this4.$message.error(res.data.data.msg);
            } else {
              _this4.btnLoading = false;
              var messageTip = sumbitData.id ? '编辑' : '添加';
              _this4.$message.success(messageTip + '成功');
              _this4.$emit('handleCancel');
            }
          });
        }
      });
    },
    handleCancel: function handleCancel() {
      this.$emit('handleCancel');
    }
  }
};
exports.default = _default;