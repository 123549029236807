var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
      ? _c(
          "div",
          { staticClass: "page-section" },
          [
            _c("search", {
              ref: "searchMain",
              attrs: { "show-col": _vm.showCol },
            }),
            _vm._v(" "),
            _vm.formConfig.btnFollow
              ? _c("el-divider", { staticClass: "btnDivider" })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "btnArea" },
              [
                _vm.formConfig.btnFollow
                  ? _c(
                      "el-col",
                      {
                        staticClass: "head-btn-group",
                        staticStyle: { width: "177px" },
                      },
                      [
                        _c(
                          "el-button-group",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: _vm.btnConfig.searchBtn.type,
                                  size: _vm.btnConfig.size,
                                  plain: "",
                                  icon: _vm.btnConfig.searchBtn.icon,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$refs.searchMain.handleFilter(
                                      "search"
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: _vm.btnConfig.resetBtn.type,
                                  size: _vm.btnConfig.size,
                                  plain: "",
                                  icon: _vm.btnConfig.resetBtn.icon,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$refs.searchMain.handleFilter(
                                      "rest"
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.hasAuth("exportbtn", _vm.$route.meta.authList)
                  ? _c(
                      "div",
                      { staticClass: "head-btn-group" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: _vm.btnConfig.downLoadBtn.type,
                              size: _vm.btnConfig.size,
                              plain: "",
                              icon: _vm.btnConfig.downLoadBtn.icon,
                              loading: _vm.downLoading,
                            },
                            on: { click: _vm.download },
                          },
                          [_vm._v(_vm._s(_vm.btnConfig.downLoadBtn.text))]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.formConfig.btnFollow
                  ? _c(
                      "p",
                      {
                        staticStyle: {
                          "font-size": "15px",
                          "margin-left": "10px",
                        },
                      },
                      [
                        _c("span", { staticClass: "sumTxt" }, [
                          _vm._v("\n          本页库存合计：\n          "),
                          _c("span", { staticClass: "bcolor" }, [
                            _vm._v(_vm._s(_vm.pageTotalSum)),
                          ]),
                          _vm._v(" 箱\n        "),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "sumTxt" }, [
                          _vm._v("\n          库存总计：\n          "),
                          _c("span", { staticClass: "bcolor" }, [
                            _vm._v(_vm._s(_vm.totalSum)),
                          ]),
                          _vm._v(" 箱\n        "),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            !_vm.formConfig.btnFollow
              ? _c("div", { staticClass: "phead operate-btns" }, [
                  _c("span", { staticClass: "sumTxt" }, [
                    _vm._v("\n        本页库存合计：\n        "),
                    _c("span", { staticClass: "bcolor" }, [
                      _vm._v(_vm._s(_vm.pageTotalSum)),
                    ]),
                    _vm._v(" 箱\n      "),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "sumTxt" }, [
                    _vm._v("\n        库存总计：\n        "),
                    _c("span", { staticClass: "bcolor" }, [
                      _vm._v(_vm._s(_vm.totalSum)),
                    ]),
                    _vm._v(" 箱\n      "),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                key: _vm.tableKey,
                staticStyle: { width: "100%" },
                attrs: {
                  height: _vm.tabHeight,
                  data: _vm.tableData,
                  border: _vm.tableConfig.border,
                  stripe: _vm.tableConfig.stripe,
                  fit: "",
                  "highlight-current-row": "",
                },
              },
              [
                _c("el-table-column", {
                  attrs: { type: "index", width: "120", label: "序号" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "库房编号",
                    prop: "storeageCode",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "所属企业",
                    prop: "orgName",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "库房名称",
                    prop: "storeageName",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "产品编号",
                    prop: "productCode",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "产品名称",
                    prop: "productName",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "包装比例",
                    prop: "packRadio",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "库存最大单位数量",
                    prop: "storeChestCount",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "库存最小单位数量",
                    prop: "storeBottleCount",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                false
                  ? _c("el-table-column", {
                      attrs: {
                        label: "超限数量（箱）",
                        "min-width": "90",
                        prop: "overChestCount",
                        "show-overflow-tooltip": "",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                false
                  ? _c("el-table-column", {
                      attrs: { label: "库存状态", "show-overflow-tooltip": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                row.storeStatus == 1
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "success" } },
                                      [_vm._v("低库存")]
                                    )
                                  : row.storeStatus == 2
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "warning" } },
                                      [_vm._v("平库存")]
                                    )
                                  : _c(
                                      "el-tag",
                                      { attrs: { type: "danger" } },
                                      [_vm._v("高库存")]
                                    ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        184745660
                      ),
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c("pagination", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.total > 0,
                  expression: "total > 0",
                },
              ],
              attrs: {
                layout: "total,sizes,prev, pager, next, jumper",
                total: _vm.total,
                page: _vm.searchForm.current,
                limit: _vm.searchForm.size,
              },
              on: {
                "update:page": function ($event) {
                  return _vm.$set(_vm.searchForm, "current", $event)
                },
                "update:limit": function ($event) {
                  return _vm.$set(_vm.searchForm, "size", $event)
                },
                pagination: _vm.getList,
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
      ? _c("section", [_vm._m(0)])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }