"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _order = require("@/api/order");
var _dictionary = require("@/api/systems/dictionary");
var _companySelectDialog = _interopRequireDefault(require("@/components/companySelectDialog"));
var _productDialog = _interopRequireDefault(require("@/components/productDialog"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var TypeDataComs = {
  orderStatus: []
};
var _default = {
  name: 'OrderCentercostorderCcupdate',
  components: {
    companySelectDialog: _companySelectDialog.default,
    productDialog: _productDialog.default
  },
  filters: {
    orderType: function orderType(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          1: '买赠订单',
          2: '酒券订单',
          3: '团购订单',
          4: '费用酒订单',
          5: '样品酒订单'
        };
        return statusMap[value];
      }
    },
    typeClass: function typeClass(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          0: 'success',
          1: 'success',
          2: 'success',
          3: 'success',
          4: 'success',
          5: 'danger',
          6: 'danger',
          7: 'warning',
          8: 'primary'
        };
        return statusMap[value];
      }
    },
    orderStatusText: function orderStatusText(value) {
      var name = '';
      TypeDataComs.orderStatus.forEach(function (c) {
        if (c.figure == value) {
          name = c.name;
          return;
        }
      });
      return name;
    }
  },
  data: function data() {
    return {
      // 销售单位
      saleOrgName: '',
      // 采购单位名称
      purchaseOrgName: '',
      // 收货单位名称
      receiveOrgName: '',
      // 发货单位名称
      sendOrgName: '',
      orderStatus: [],
      proType: '',
      placeOrderDateEdit: false,
      remarkEdit: false,
      typeEdit: false,
      cardPadding: {
        padding: '0 0 20px'
      },
      btncardPadding: {
        padding: '0'
      },
      loading: false,
      proSelectList: [],
      productList: [],
      companyShow: false,
      productShow: false,
      addData: {
        orderNo: '',
        orderProductAOS: [],
        orderSource: 1,
        orderType: '',
        purchaseOrgId: '',
        receiveAddress: '',
        receiveOrgId: '',
        receivePhone: '',
        receiveUser: '',
        remark: '',
        saleOrgId: '',
        sendOrgId: '',
        tradeDate: '',
        tradeType: 3
      },
      auditForm: {
        orderNo: '',
        auditResult: '',
        auditReason: ''
      }
    };
  },
  mounted: function mounted() {
    var _this = this;
    if (this.$route.query.id) {
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: 'CCXSDDZT'
      }).then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this.orderStatus = res.data.data;
            TypeDataComs['orderStatus'] = res.data.data;
          } else {
            _this.orderStatus = [];
            TypeDataComs['orderStatus'] = [];
          }
        }
      }).then(function () {
        _this.getDetailData(_this.$route.query.id);
      });
    } else {
      this.$router.replace({
        name: 'orderCentercostorder-ccindex'
      });
    }
  },
  methods: {
    getDirSel: function getDirSel(code, list) {
      var _this2 = this;
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: code
      }).then(function (res) {
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this2[list] = res.data.data;
            TypeDataComs[list] = res.data.data;
          } else {
            _this2[list] = [];
            TypeDataComs[list] = [];
          }
        }
      });
    },
    // 获取详情数据
    getDetailData: function getDetailData(id) {
      var _this3 = this;
      this.loading = true;
      (0, _order.queryByOrderNo)(id).then(function (res) {
        if (res.data.code == 200) {
          var data = res.data.data;
          var proArr = [];
          _this3.addData = res.data.data;
          data.orderProductDetailVOS.forEach(function (item) {
            var obj = {
              pid: item.pid,
              parentDetailId: item.parentDetailId,
              pId: item.productId,
              productCode: item.productCode,
              productName: item.productName,
              productModel: item.productModel,
              packScaleExpression: item.packScaleExpression,
              chestNum: item.chestNum,
              bottleNum: item.bottleNum,
              totalBottleNum: item.totalBottleNum,
              productType: item.productType
            };
            proArr.push(obj);
          });
          _this3.productList = proArr;
          _this3.saleOrgName = data.saleOrgName;
          _this3.purchaseOrgName = data.purchaseOrgName;
          _this3.receiveOrgName = data.receiveOrgName;
          _this3.sendOrgName = data.sendOrgName;
        } else {
          _this3.$message.error(res.data.msg);
        }
        _this3.loading = false;
      }).catch(function () {
        _this3.loading = false;
      });
      // .then(() => {
      //   if (this.addData.orderStatus > 1) {
      //     this.auditLoading = true
      //     getAuditLog({ pId: id })
      //       .then((res) => {
      //         if (res.data.code == 200) {
      //           this.auditList = res.data.data
      //         } else {
      //           that.$message.error('审核日志获取失败')
      //         }
      //         this.auditLoading = false
      //       })
      //       .catch(() => {
      //         this.auditLoading = false
      //       })
      //   }
      // })
    },
    orderTypeClose: function orderTypeClose(val) {
      if (!val) this.typeEdit = false;
    },
    /**
     * 单位选择
     * @param {*} name 需要回传修改的名字
     * @param {*} companyType 单位类型 1 收获单位 2发货单位 0采购单位
     * @param {*} orgId 采购单位ID 获取收货单位使用
     * @param {*} inOutType 单据类型 1销售出库 2调拨出库 3其他出库 4生产入库 5调拨入库 6采购入库 7其他入库 11 采购订单 12销售订单
     *
     */
    openCompany: function openCompany(name, companyType, inOutType, orgId) {
      if (name == 'purchaseComName' && !this.addData.orderType) {
        this.$message.error('请先选择订单类型');
        return;
      }
      this.orgId = orgId || '';
      this.inUseName = name;
      this.companyType = companyType;
      this.inOutType = inOutType;
      this.companyShow = true;
    },
    setCompany: function setCompany(val) {
      this[this.inUseName] = val.companyName;
      switch (this.inUseName) {
        case 'saleOrgName':
          this.addData.saleOrgId = val.companyId;
          this.resetList();
          break;
        case 'sendOrgName':
          this.addData.sendOrgId = val.companyId;
          break;
        case 'purchaseOrgName':
          this.addData.purchaseOrgId = val.companyId;
          this.addData.receiveOrgId = val.companyId;
          this.receiveOrgName = val.companyName;
          this.resetList();
          this.getReceiveInfo(val);
          break;
      }
    },
    // 匹配收货单位信息
    getReceiveInfo: function getReceiveInfo(val) {
      var _this4 = this;
      this.receiveLoading = true;
      (0, _order.getCompanyById)({
        pId: val.companyId
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          var that = _this4;
          var datas = response.data.data;
          _this4.addData.receiveUser = datas.contacts || '';
          _this4.addData.receivePhone = datas.telephone || datas.phone || '';
          var provinceName = datas.provinceName || '';
          var cityName = datas.cityName || '';
          var countyName = datas.countyName || '';
          var detailedAddress = datas.detailedAddress || '';
          _this4.addData.receiveAddress = provinceName + cityName + countyName + detailedAddress;
        }
        _this4.receiveLoading = false;
      }).catch(function () {
        _this4.receiveLoading = false;
      });
    },
    companyClose: function companyClose() {
      this.inUseName = '';
      this.companyType = '';
      this.orgId = '';
      this.isEnable = '';
      this.companycanChange = true;
      this.companyShow = false;
    },
    resetReceive: function resetReceive() {
      this.receiveComName = '';
      this.addData.receiveComId = '';
      this.addData.receiveContacts = '';
      this.addData.receivePhone = '';
      this.addData.receiveAddress = '';
    },
    openPro: function openPro(val, index) {
      this.proType = val;
      this.listIndex = index;
      if (!this.addData.saleOrgId || !this.addData.purchaseOrgId || !this.addData.receiveOrgId) {
        this.$message.error('请先选择采购单位、收货单位、供货单位');
        return;
      }
      switch (val) {
        case 1:
          this.proSelectList = this.productList;
          break;
        case 2:
          this.proSelectList = this.zpList;
          break;
        case 3:
          this.proSelectList = this.productList[index].giftProductAOS || [];
          break;
      }
      this.proSelectList = val == 1 ? this.productList : this.zpList;
      this.productShow = true;
    },
    setPro: function setPro(val) {
      var list, arr, productType;
      arr = JSON.parse(JSON.stringify(this.productList));
      list = 'productList';
      productType = 1;
      if (val.length) {
        if (this[list].length == 0) {
          var obj = {
            chestNum: '',
            bottleNum: '',
            totalBottleNum: 0,
            productType: productType
          };
          val.forEach(function (item) {
            delete item.pid;
            item = Object.assign(item, obj);
          });
        } else {
          val.forEach(function (item) {
            item.chestNum = 0;
            item.bottleNum = 0;
            item.totalBottleNum = 0;
            item.productType = productType;
            arr.forEach(function (el) {
              if (el.pId == item.pId) {
                item.pid = el.pid;
                item.chestNum = el.chestNum;
                item.bottleNum = el.bottleNum;
                item.totalBottleNum = el.totalBottleNum;
              }
            });
          });
        }
        this[list] = JSON.parse(JSON.stringify(val));
      } else {
        this[list] = [];
      }
    },
    proClose: function proClose() {
      this.proSelectList = '';
      this.proType = '';
      // this.listIndex = ''
      this.productShow = false;
    },
    changeChestNum: function changeChestNum(row) {
      var value = row.packScaleExpression;
      var arr = value.split('*');
      var le = arr.length - 1;
      row.totalBottleNum = (row.chestNum || 0) * arr[le] + (row.bottleNum || 0);
      // this.setZPnum(row)
    },
    resetList: function resetList() {
      this.productList = JSON.parse(JSON.stringify(this.productList));
    },
    // 删除产品明细列表
    DoSc: function DoSc(row) {
      var _this5 = this;
      var that = this;
      that.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this5.productList.splice(_this5.productList.indexOf(row), 1);
      });
    },
    mixNumP: function mixNumP(row) {
      var arr = row.packScaleExpression.split('*');
      return arr[arr.length - 1] * 1 - 1;
    },
    handleClose: function handleClose() {
      this.$router.replace({
        name: 'orderCentercostorder-ccindex'
      });
    },
    audit: function audit(val) {
      var _this6 = this;
      var that = this;
      that.addData.totalMoney = that.totalMoney;
      if (!that.addData.orderType) {
        that.$message.error('请选择订单类型');
        return;
      }
      if (!that.addData.sendOrgId) {
        that.$message.error('请选择发货单位');
        return;
      }
      if (!that.productList.length) {
        that.$message.error('请选择产品');
        return;
      }
      if (val == 2 && (this.auditForm.auditReason == null || this.auditForm.auditReason == '')) {
        this.$message.error('审核意见不能为空');
        return;
      }
      if (this.addData.relationOrderNo && this.addData.orderSource != 5 && this.addData.saleOrgId != this.addData.sendOrgId) {
        this.$confirm('您所审核的订单为：代发货订单，请确认是否进行代发货？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          _this6.subFun(val);
        }).catch(function () {});
        return;
      }
      this.subFun(val);
    },
    subFun: function subFun(val) {
      var _this7 = this;
      var that = this;
      var plist = JSON.parse(JSON.stringify(that.productList));
      var oldList = JSON.parse(JSON.stringify(that.addData.orderProductDetailVOS));
      var newList = [];
      if (plist.length > 0) {
        for (var i = 0; i < plist.length; i++) {
          if (!plist[i].totalBottleNum || plist[i].totalBottleNum == 0) {
            that.$message.error('在产品列表第' + (i + 1) + '行请输入销售数量');
            return;
          }
          var obj = {
            pid: plist[i].pid,
            totalBottleNum: plist[i].totalBottleNum,
            bottleNum: plist[i].bottleNum,
            chestNum: plist[i].chestNum,
            orderNo: that.addData.orderNo,
            activityId: that.addData.activityId || '',
            activityType: that.addData.orderType == 3 ? '' : that.addData.orderType,
            productId: plist[i].pId,
            productType: plist[i].productType
          };
          oldList.forEach(function (item) {
            if (item.pid == obj.pid) {
              obj = Object.assign(item, obj);
            }
          });
          newList.push(obj);
        }
      }
      that.loading = true;
      that.dsate = true;
      this.auditForm.auditResult = val;
      this.auditForm.orderNo = this.addData.orderNo;
      this.auditForm.orderType = this.addData.orderType;
      this.auditForm.sendOrgId = this.addData.sendOrgId;
      if (this.addData.relationOrderNo) {
        this.auditForm.preOrderProductAOS = newList;
      }
      (0, _order.judgeAuditOrder)(this.auditForm).then(function (res) {
        if (res.data.code == 200) {
          that.$message({
            type: 'success',
            message: '操作成功'
          });
          _this7.handleClose();
        } else {
          that.$message.error(res.data.msg);
        }
        that.loading = false;
        that.dsate = false;
      }).catch(function () {
        that.loading = false;
        that.dsate = false;
      });
    }
  }
};
exports.default = _default;