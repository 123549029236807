"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.split");
var _activityManage = require("@/api/market/activityManage");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      tabHeight: '600px',
      handleShow: false,
      list: null,
      listLoading: false,
      total: 0,
      searchForm: {
        current: 1,
        size: 20,
        activityName: '',
        activityStatus: '',
        activityDimension: '',
        orderNo: '',
        activityType: '',
        productId: '',
        productName: '',
        endTime: '',
        startTime: '',
        rangeTime: '',
        channelName: '',
        financeId: '',
        seqNo: ''
      },
      activityDimensions: [{
        name: '产品',
        value: '0'
      }, {
        name: '订单',
        value: '1'
      }],
      activityStatus: [{
        name: '未开始',
        value: '0'
      }, {
        name: '停用',
        value: '1'
      }, {
        name: '进行中',
        value: '2'
      }, {
        name: '已过期',
        value: '3'
      }],
      sourceList: [],
      multipleSelection: []
    };
  },
  mounted: function mounted() {
    this.getList();
    this.getZjSourceList();
  },
  methods: {
    choseProduct: function choseProduct() {
      this.$refs.proSelect.proSelVisible = true;
    },
    checkSelectable: function checkSelectable(row) {
      return row.activityStatus != '3';
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleClose: function handleClose() {
      this.$emit("close");
    },
    reSearch: function reSearch(e) {
      var that = this;
      that.$nextTick(function () {
        that.getList();
      });
    },
    getList: function getList() {
      var _this = this;
      if (this.searchForm.seqNo) {
        var seqNoList = this.searchForm.seqNo.split(",");
        var isPass = seqNoList.filter(function (item) {
          return item;
        }).every(function (item) {
          return !isNaN(Number(item));
        });
        if (!isPass) {
          this.$message.error("活动ID输入错误");
          return;
        }
      }
      this.listLoading = true;
      if (this.searchForm.rangeTime && this.searchForm.rangeTime.length > 0) {
        this.searchForm.startTime = this.searchForm.rangeTime[0];
        this.searchForm.endTime = this.searchForm.rangeTime[1];
      } else {
        this.searchForm.startTime = "";
        this.searchForm.endTime = "";
      }
      var sdata = JSON.parse(JSON.stringify(this.searchForm));
      delete sdata.rangeTime;
      delete sdata.productName;
      (0, _activityManage.geActList)(sdata).then(function (res) {
        if (res.data.code == "200") {
          _this.listLoading = false;
          if (!res.data.data.errCode) {
            _this.list = res.data.data.records;
            _this.total = res.data.data.total;
          } else {
            _this.$message.error(res.data.data.errMsg);
            return;
          }
        } else {
          _this.listLoading = false;
          _this.$message.error(res.data.msg);
          return;
        }
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    getZjSourceList: function getZjSourceList() {
      var _this2 = this;
      (0, _activityManage.getMoneySource)().then(function (res) {
        if (res.data.code == 200) {
          _this2.sourceList = res.data.data;
        }
      });
    },
    handleSave: function handleSave() {
      if (!this.multipleSelection.length) {
        return this.$message.error('请至少选择一个活动');
      }
      var selectActID = this.multipleSelection.map(function (item) {
        return item.id;
      });
      this.$emit("selectActID", selectActID);
    }
  }
};
exports.default = _default;