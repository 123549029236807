"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _admincut = _interopRequireDefault(require("@/components/admincut"));
var _wxgzh = require("@/api/wxhyyy/wxgzh");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    admincut: _admincut.default
  },
  data: function data() {
    return {
      is: true,
      htp: 'http://',
      activeName: '1',
      modelload: false,
      modeldata: [{
        name: 1,
        one: 2,
        two: 3
      }, {
        name: 1,
        one: 2,
        two: 3
      }],
      massload: false,
      massnumData: [{
        id: 1,
        name: '1',
        amount1: '1',
        amount2: '3',
        amount3: '4'
      }, {
        id: 1,
        name: '1',
        amount1: '2',
        amount2: '3',
        amount3: '4'
      }, {
        id: 1,
        name: '1',
        amount1: '3',
        amount2: '3',
        amount3: '4'
      }],
      detailsDialog: false,
      product: [],
      // 字体颜色
      colorConfirm: ''
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var that = this;
    that.getlist();
    that.getmasslist();
    console.log(that.activeName);
    if (that.$route.params.ac !== '') {
      that.activeName = that.$route.params.ac;
    }
    that.activeName = '1';
  },
  methods: {
    go: function go(val) {
      window.location.href = 'http://' + val;
    },
    // 消息设置获取数据
    getlist: function getlist() {
      var that = this;
      that.modelload = true;
      (0, _wxgzh.mosearch)().then(function (res) {
        var res = res.data;
        if (res.code === 200) {
          console.log(res);
          that.modeldata = res.data.records;
          that.modelload = false;
        } else {
          that.$message.info(res.msg);
        }
      });
    },
    // 群发消息列表
    getmasslist: function getmasslist() {
      var that = this;
      that.massload = true;
      (0, _wxgzh.motemsearch)().then(function (res) {
        var res = res.data;
        if (res.code === 200) {
          console.log(res);
          that.massnumData = res.data.records;
          that.massload = false;
        } else {
          that.$message.info(res.msg);
        }
      });
    },
    // 编辑数据
    editkey: function editkey(val) {
      console.log(val.id);
      sessionStorage.setItem('mbid', val.id);
      this.$router.push({
        // name: 'integralchange',
        name: 'wxAccountswxgzhwxFunctionmsnmodelintegralchange',
        params: {}
      });
    },
    handleClick: function handleClick(tab, event) {
      console.log(tab, event);
    },
    // 详情
    checkDetails: function checkDetails(val) {
      var that = this;
      var n = {
        id: val.id,
        appId: sessionStorage.getItem('appId')
      };
      (0, _wxgzh.moselectById)(n).then(function (res) {
        var res = res.data;
        if (res.code === 200) {
          console.log(res);
          that.product = res.data;
        } else {
          that.$message.info(res.msg);
        }
      });
      that.detailsDialog = true;
    },
    // 删除
    deleteRow: function deleteRow(row, rows, index) {
      var that = this;
      var n = {
        id: row.id
      };
      (0, _wxgzh.modeleteById)(n).then(function (res) {
        var res = res.data;
        if (res.code === 200) {
          that.$message.success('删除成功');
          rows.splice(index, 1);
          that.getlist();
        }
      });
      console.log(index);
    },
    handleClose: function handleClose(done) {
      done();
    }
  }
};
exports.default = _default;