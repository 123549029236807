var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        { staticClass: "page-section" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              staticStyle: { padding: "20px 0 0 20px" },
              attrs: {
                model: _vm.queryParams,
                "label-position": _vm.formConfig.labelPosition,
                "label-width": _vm.formConfig.labelWidth,
              },
            },
            [
              _c(
                "div",
                { staticClass: "cols" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "字段名称" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入字段名称" },
                                model: {
                                  value: _vm.queryParams.fieldName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryParams, "fieldName", $$v)
                                  },
                                  expression: "queryParams.fieldName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "状态" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "全部" },
                                  model: {
                                    value: _vm.queryParams.status,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.queryParams, "status", $$v)
                                    },
                                    expression: "queryParams.status",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "启用", value: "1" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "停用", value: "2" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { staticStyle: { "padding-top": "23px" } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.searchBtn.type,
                                    icon: _vm.btnConfig.searchBtn.icon,
                                  },
                                  on: { click: _vm.querylist },
                                },
                                [_vm._v("查询")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.resetBtn.type,
                                    icon: _vm.btnConfig.resetBtn.icon,
                                  },
                                  on: { click: _vm.restDateCollectionInfoList },
                                },
                                [_vm._v("重置")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _vm.formConfig.btnFollow
            ? _c("el-divider", { staticClass: "btnDivider" })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "btnArea" }, [
            _c(
              "div",
              { staticClass: "head-btn-group" },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: _vm.btnConfig.addBtn.type,
                      size: _vm.btnConfig.size,
                      icon: _vm.btnConfig.addBtn.icon,
                    },
                    on: { click: _vm.addOfficialAccount },
                  },
                  [_vm._v("新增字段")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoadig,
                      expression: "listLoadig",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.tableData,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    "highlight-current-row": true,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "fieldName",
                      label: "字段名称",
                      align: _vm.tableConfig.align,
                      width: "220px",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "fieldType",
                      label: "类型",
                      align: _vm.tableConfig.align,
                      formatter: _vm.showFieldType,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "verifyType",
                      label: "校验类型",
                      align: _vm.tableConfig.align,
                      formatter: _vm.showVerifyType,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "verifyLength",
                      label: "长度校验",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "fieldExplain",
                      label: "说明",
                      align: _vm.tableConfig.align,
                      "min-width": "180",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "status",
                      label: "状态",
                      align: _vm.tableConfig.align,
                      formatter: _vm.showStatus,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.status == "1"
                              ? _c("el-tag", [_vm._v("启用")])
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.status == "2"
                              ? _c("el-tag", { attrs: { type: "danger" } }, [
                                  _vm._v("停用"),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "isMust",
                      label: "是否必输",
                      align: _vm.tableConfig.align,
                      formatter: _vm.showIsMust,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.isMust == "1"
                              ? _c("el-tag", [_vm._v("是")])
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.isMust == "2"
                              ? _c("el-tag", { attrs: { type: "danger" } }, [
                                  _vm._v("否"),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      width: "220",
                      align: _vm.tableConfig.align,
                      fixed: "right",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                staticClass: "a-btn",
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.switchTop(scope.row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    scope.row.status == 1 ? "停用" : "启用"
                                  )
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "a-btn",
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editOfficialAccount(scope.row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "a-btn",
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.dateCollection(
                                      scope.$index,
                                      scope.row
                                    )
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("Pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              page: _vm.queryParams.pageNo,
              limit: _vm.queryParams.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryParams, "pageNo", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryParams, "pageSize", $event)
              },
              pagination: _vm.getDateCollectionInfoList,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.officialAccountdialogVisible,
            "close-on-click-modal": false,
            "show-close": false,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.officialAccountdialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "officialAccountForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.officialAccountFrom,
                rules: _vm.officialAccountRules,
                "label-position": "left",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "字段名称:", prop: "fieldName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入字段名称不超过10个字" },
                    model: {
                      value: _vm.officialAccountFrom.fieldName,
                      callback: function ($$v) {
                        _vm.$set(_vm.officialAccountFrom, "fieldName", $$v)
                      },
                      expression: "officialAccountFrom.fieldName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "字段类型:", prop: "fieldType" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.selectField },
                      model: {
                        value: _vm.officialAccountFrom.fieldType,
                        callback: function ($$v) {
                          _vm.$set(_vm.officialAccountFrom, "fieldType", $$v)
                        },
                        expression: "officialAccountFrom.fieldType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("输入"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("单选"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "3" } }, [
                        _vm._v("多选"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.isParityBit
                ? _c(
                    "el-form-item",
                    { attrs: { label: "校验类型:", prop: "verifyType" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择校验类型" },
                          on: { change: _vm.selectType },
                          model: {
                            value: _vm.officialAccountFrom.verifyType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.officialAccountFrom,
                                "verifyType",
                                $$v
                              )
                            },
                            expression: "officialAccountFrom.verifyType",
                          },
                        },
                        _vm._l(_vm.checkTypes, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isLengthCheck
                ? _c(
                    "el-form-item",
                    { attrs: { label: "长度校验：", prop: "verifyLength" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.officialAccountFrom.verifyLength,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.officialAccountFrom,
                              "verifyLength",
                              $$v
                            )
                          },
                          expression: "officialAccountFrom.verifyLength",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isOptions
                ? _c(
                    "el-form-item",
                    { attrs: { label: "字段选项：", prop: "fieldOptions" } },
                    [
                      _vm._l(
                        _vm.officialAccountFrom.fieldOptions,
                        function (tag, index) {
                          return _c(
                            "el-tag",
                            {
                              key: tag.index,
                              attrs: {
                                closable: "",
                                "disable-transitions": false,
                              },
                              on: {
                                close: function ($event) {
                                  return _vm.handleClose(index)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n          " + _vm._s(tag) + "\n        "
                              ),
                            ]
                          )
                        }
                      ),
                      _vm._v(" "),
                      _vm.inputVisible
                        ? _c("el-input", {
                            ref: "saveTagInput",
                            staticClass: "input-new-tag",
                            attrs: { size: "small" },
                            on: { blur: _vm.handleInputConfirm },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.handleInputConfirm($event)
                              },
                            },
                            model: {
                              value: _vm.inputValue,
                              callback: function ($$v) {
                                _vm.inputValue = $$v
                              },
                              expression: "inputValue",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "button-new-tag",
                          attrs: { size: "small" },
                          on: { click: _vm.addTags },
                        },
                        [_vm._v("+")]
                      ),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否必输:", prop: "isMust" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.officialAccountFrom.isMust,
                        callback: function ($$v) {
                          _vm.$set(_vm.officialAccountFrom, "isMust", $$v)
                        },
                        expression: "officialAccountFrom.isMust",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [_vm._v("是")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "2" } }, [_vm._v("否")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "说明：", prop: "fieldExplain" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入说明不超过10个字" },
                    model: {
                      value: _vm.officialAccountFrom.fieldExplain,
                      callback: function ($$v) {
                        _vm.$set(_vm.officialAccountFrom, "fieldExplain", $$v)
                      },
                      expression: "officialAccountFrom.fieldExplain",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.cancelField("officialAccountForm")
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: _vm.disabled,
                    loading: _vm.btnLoading,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.submitField("officialAccountForm")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }