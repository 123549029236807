"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.replace");
var _typeConfig = require("@/api/mark/configure/typeConfig");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'ComplexTable',
  data: function data() {
    var _this = this;
    var validateYXlen = function validateYXlen(rule, value, callback) {
      var num = rule.field.replace(/[^0-9]/gi, '');
      if (_this.temp.yx[num].select) {
        if (_this.temp.yx[num].algorithmType == 1) {
          if (value >= 34 && value <= 40) {
            callback();
          } else {
            callback(new Error('请输入34~40区间的数字'));
          }
        } else {
          if (value >= 20 && value <= 30) {
            callback();
          } else {
            callback(new Error('请输入20~30区间的数字'));
          }
        }
      } else {
        callback();
      }
    };
    var validateFWlen = function validateFWlen(rule, value, callback) {
      var num = rule.field.replace(/[^0-9]/gi, '');
      if (_this.temp.fw[num].select) {
        if (value >= 18 && value <= 26) {
          callback();
        } else {
          callback(new Error('请输入18~26区间的数字'));
        }
      } else {
        callback();
      }
    };
    var validateWLlen = function validateWLlen(rule, value, callback) {
      var num = rule.field.replace(/[^0-9]/gi, '');
      if (_this.temp.wl[num].select) {
        if (value >= 18 && value <= 26) {
          callback();
        } else {
          callback(new Error('请输入18~26区间的数字'));
        }
      } else {
        callback();
      }
    };
    return {
      normalData: {},
      tabHeight: '100%',
      listLoading: false,
      btn_loading: false,
      temp: {
        yx: [{
          select: false,
          disabled: false,
          algorithmType: 1,
          markLen: '',
          markType: 1,
          packType: 4,
          pid: ''
        }, {
          select: false,
          disabled: false,
          algorithmType: 1,
          markLen: '',
          markType: 1,
          packType: 3,
          pid: ''
        }, {
          select: false,
          disabled: false,
          algorithmType: 1,
          markLen: '',
          markType: 1,
          packType: 2,
          pid: ''
        }, {
          select: false,
          disabled: false,
          algorithmType: 1,
          markLen: '',
          markType: 1,
          packType: 1,
          pid: ''
        }],
        fw: [{
          select: false,
          disabled: false,
          algorithmType: '',
          markLen: '',
          markType: 2,
          packType: 4,
          pid: ''
        }, {
          select: false,
          disabled: false,
          algorithmType: '',
          markLen: '',
          markType: 2,
          packType: 3,
          pid: ''
        }, {
          select: false,
          disabled: false,
          algorithmType: '',
          markLen: '',
          markType: 2,
          packType: 2,
          pid: ''
        }],
        wl: [{
          select: false,
          disabled: false,
          algorithmType: '',
          markLen: '',
          markType: 3,
          packType: 4,
          pid: ''
        }, {
          select: false,
          disabled: false,
          algorithmType: '',
          markLen: '',
          markType: 3,
          packType: 3,
          pid: ''
        }, {
          select: false,
          disabled: false,
          algorithmType: '',
          markLen: '',
          markType: 3,
          packType: 2,
          pid: ''
        }, {
          select: false,
          disabled: false,
          algorithmType: '',
          markLen: '',
          markType: 3,
          packType: 1,
          pid: ''
        }]
      },
      rules: {
        'yx[0].markLen': [{
          validator: validateYXlen,
          trigger: 'change'
        }],
        'yx[1].markLen': [{
          validator: validateYXlen,
          trigger: 'change'
        }],
        'yx[2].markLen': [{
          validator: validateYXlen,
          trigger: 'change'
        }],
        'yx[3].markLen': [{
          validator: validateYXlen,
          trigger: 'change'
        }],
        'fw[0].markLen': [{
          validator: validateFWlen,
          trigger: 'change'
        }],
        'fw[1].markLen': [{
          validator: validateFWlen,
          trigger: 'change'
        }],
        'fw[2].markLen': [{
          validator: validateFWlen,
          trigger: 'change'
        }],
        'wl[0].markLen': [{
          validator: validateWLlen,
          trigger: 'change'
        }],
        'wl[1].markLen': [{
          validator: validateWLlen,
          trigger: 'change'
        }],
        'wl[2].markLen': [{
          validator: validateWLlen,
          trigger: 'change'
        }],
        'wl[3].markLen': [{
          validator: validateWLlen,
          trigger: 'change'
        }]
      }
    };
  },
  activated: function activated() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this2 = this;
      this.authId('searchbtn', this.$route.meta.authList);
      this.listLoading = true;
      (0, _typeConfig.markTypeInfoqueryForList)().then(function (response) {
        var data = response.data;
        _this2.listLoading = false;
        if (data.code != 200) {
          _this2.$message.error(data.msg);
          return true;
        }
        if (data.data != null && data.data.length > 0) {
          data.data.forEach(function (item) {
            switch (item.markType * 1) {
              case 1:
                switch (item.packType * 1) {
                  case 4:
                    _this2.temp.yx[0].select = true;
                    _this2.temp.yx[0].disabled = true;
                    _this2.temp.yx[0].algorithmType = item.algorithmType;
                    _this2.temp.yx[0].markLen = item.markLen;
                    _this2.temp.yx[0].markType = item.markType;
                    _this2.temp.yx[0].packType = item.packType;
                    _this2.temp.yx[0].pid = item.pid;
                    break;
                  case 3:
                    _this2.temp.yx[1].select = true;
                    _this2.temp.yx[1].disabled = true;
                    _this2.temp.yx[1].algorithmType = item.algorithmType;
                    _this2.temp.yx[1].markLen = item.markLen;
                    _this2.temp.yx[1].markType = item.markType;
                    _this2.temp.yx[1].packType = item.packType;
                    _this2.temp.yx[1].pid = item.pid;
                    break;
                  case 2:
                    _this2.temp.yx[2].select = true;
                    _this2.temp.yx[2].disabled = true;
                    _this2.temp.yx[2].algorithmType = item.algorithmType;
                    _this2.temp.yx[2].markLen = item.markLen;
                    _this2.temp.yx[2].markType = item.markType;
                    _this2.temp.yx[2].packType = item.packType;
                    _this2.temp.yx[2].pid = item.pid;
                    break;
                  case 1:
                    _this2.temp.yx[3].select = true;
                    _this2.temp.yx[3].disabled = true;
                    _this2.temp.yx[3].algorithmType = item.algorithmType;
                    _this2.temp.yx[3].markLen = item.markLen;
                    _this2.temp.yx[3].markType = item.markType;
                    _this2.temp.yx[3].packType = item.packType;
                    _this2.temp.yx[3].pid = item.pid;
                }
                break;
              case 2:
                switch (item.packType * 1) {
                  case 4:
                    _this2.temp.fw[0].select = true;
                    _this2.temp.fw[0].disabled = true;
                    _this2.temp.fw[0].algorithmType = item.algorithmType;
                    _this2.temp.fw[0].markLen = item.markLen;
                    _this2.temp.fw[0].markType = item.markType;
                    _this2.temp.fw[0].packType = item.packType;
                    _this2.temp.fw[0].pid = item.pid;
                    break;
                  case 3:
                    _this2.temp.fw[1].select = true;
                    _this2.temp.fw[1].disabled = true;
                    _this2.temp.fw[1].algorithmType = item.algorithmType;
                    _this2.temp.fw[1].markLen = item.markLen;
                    _this2.temp.fw[1].markType = item.markType;
                    _this2.temp.fw[1].packType = item.packType;
                    _this2.temp.fw[1].pid = item.pid;
                    break;
                  case 2:
                    _this2.temp.fw[2].select = true;
                    _this2.temp.fw[2].disabled = true;
                    _this2.temp.fw[2].algorithmType = item.algorithmType;
                    _this2.temp.fw[2].markLen = item.markLen;
                    _this2.temp.fw[2].markType = item.markType;
                    _this2.temp.fw[2].packType = item.packType;
                    _this2.temp.fw[2].pid = item.pid;
                }
                break;
              case 3:
                switch (item.packType * 1) {
                  case 4:
                    _this2.temp.wl[0].select = true;
                    _this2.temp.wl[0].disabled = true;
                    _this2.temp.wl[0].algorithmType = item.algorithmType;
                    _this2.temp.wl[0].markLen = item.markLen;
                    _this2.temp.wl[0].markType = item.markType;
                    _this2.temp.wl[0].packType = item.packType;
                    _this2.temp.wl[0].pid = item.pid;
                    break;
                  case 3:
                    _this2.temp.wl[1].select = true;
                    _this2.temp.wl[1].disabled = true;
                    _this2.temp.wl[1].algorithmType = item.algorithmType;
                    _this2.temp.wl[1].markLen = item.markLen;
                    _this2.temp.wl[1].markType = item.markType;
                    _this2.temp.wl[1].packType = item.packType;
                    _this2.temp.wl[1].pid = item.pid;
                    break;
                  case 2:
                    _this2.temp.wl[2].select = true;
                    _this2.temp.wl[2].disabled = true;
                    _this2.temp.wl[2].algorithmType = item.algorithmType;
                    _this2.temp.wl[2].markLen = item.markLen;
                    _this2.temp.wl[2].markType = item.markType;
                    _this2.temp.wl[2].packType = item.packType;
                    _this2.temp.wl[2].pid = item.pid;
                    break;
                  case 1:
                    _this2.temp.wl[3].select = true;
                    _this2.temp.wl[3].disabled = true;
                    _this2.temp.wl[3].algorithmType = item.algorithmType;
                    _this2.temp.wl[3].markLen = item.markLen;
                    _this2.temp.wl[3].markType = item.markType;
                    _this2.temp.wl[3].packType = item.packType;
                    _this2.temp.wl[3].pid = item.pid;
                    break;
                }
                break;
            }
          });
          _this2.normalData = JSON.parse(JSON.stringify(_this2.temp));
        }
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    onSubmitFreeze: function onSubmitFreeze() {
      var _this3 = this;
      this.$refs['searchform'].validate(function (valid) {
        if (valid) {
          _this3.btn_loading = true;
          var queryList = [];
          _this3.temp.yx.forEach(function (item) {
            if (item.select && !item.disabled) {
              delete item.select;
              delete item.disabled;
              queryList.push(item);
            }
          });
          _this3.temp.fw.forEach(function (item) {
            if (item.select && !item.disabled) {
              delete item.select;
              delete item.disabled;
              queryList.push(item);
            }
          });
          _this3.temp.wl.forEach(function (item) {
            if (item.select && !item.disabled) {
              delete item.select;
              delete item.disabled;
              queryList.push(item);
            }
          });
          _this3.authId('updatebtn', _this3.$route.meta.authList);
          console.log(queryList, '添加');
          (0, _typeConfig.markTypeInfoinsertMarkType)(queryList).then(function (res) {
            _this3.btn_loading = false;
            var data = res.data;
            if (data.code != 200) {
              _this3.$message.error(data.msg);
              return false;
            }
            _this3.$message({
              message: '保存成功',
              type: 'success'
            });
            // this.$refs['searchform'].resetFields()
            _this3.getList();
          }).catch(function () {
            _this3.btn_loading = false;
          });
        }
      });
    },
    rest: function rest() {
      this.temp = JSON.parse(JSON.stringify(this.normalData));
    }
  }
};
exports.default = _default;