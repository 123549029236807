var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "生产计划详情",
        visible: _vm.detailVisible,
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.detailVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.mloading,
              expression: "mloading",
            },
          ],
          staticClass: "info-items",
          attrs: {
            model: _vm.scanData,
            "label-position": "right",
            "label-width": "150px",
          },
        },
        [
          _c("h3", [_vm._v("任务单信息")]),
          _vm._v(" "),
          _c(
            "el-row",
            {
              staticStyle: { "flex-direction": "row", "flex-wrap": "wrap" },
              attrs: { gutter: 20, type: "flex" },
            },
            [
              _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [
                    _vm._v("生产计划单号:"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.scanData.orderNo)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("任务数量:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.scanData.miniUnit)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("生产批次:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.scanData.batchNo)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("工厂名称:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.scanData.factoryName)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("车间名称:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.scanData.shopName)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("产线名称:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.scanData.productLineName)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("班组:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.scanData.teamName)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("产品编号:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.scanData.materialCode)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("产品名称:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.scanData.materialName)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { staticStyle: { height: "100%" }, attrs: { lg: 8, md: 12 } },
                [
                  _c("div", { staticClass: "vue-from-block" }, [
                    _c("div", { staticClass: "form-div" }, [
                      _vm._v("包装比例描述:"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "content" }, [
                      _vm._v(_vm._s(_vm.scanData.packScaleDesc)),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { staticStyle: { height: "100%" }, attrs: { lg: 8, md: 12 } },
                [
                  _c("div", { staticClass: "vue-from-block" }, [
                    _c("div", { staticClass: "form-div" }, [
                      _vm._v("所属库房:"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "content" }, [
                      _vm._v(_vm._s(_vm.scanData.storehouseName)),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [
                    _vm._v("计划单状态:"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "content" },
                    [
                      _vm.scanData.temp == 0
                        ? _c("el-link", { attrs: { type: "info" } }, [
                            _vm._v("草稿"),
                          ])
                        : _c(
                            "div",
                            [
                              _vm.scanData.orderStatus == 5
                                ? _c(
                                    "el-link",
                                    { attrs: { type: "primary" } },
                                    [_vm._v("已创建，待审核")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.scanData.orderStatus == 7
                                ? _c("el-link", { attrs: { type: "danger" } }, [
                                    _vm._v("已审核，未通过"),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.scanData.orderStatus == 10
                                ? _c(
                                    "el-link",
                                    { attrs: { type: "warning" } },
                                    [_vm._v("已审核，待下载")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.scanData.orderStatus == 13
                                ? _c(
                                    "el-link",
                                    { attrs: { type: "warning" } },
                                    [_vm._v("已下载，执行中")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.scanData.orderStatus == 16
                                ? _c(
                                    "el-link",
                                    { attrs: { type: "success" } },
                                    [_vm._v("执行完成")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("创建人:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.scanData.creatorId)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("创建时间:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.scanData.createTime)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("单据来源:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(
                      _vm._s(_vm._f("sourceText")(_vm.scanData.orderSource))
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("备注:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.scanData.remarks)),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }