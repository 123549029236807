var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.apis)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c(
                "el-form",
                {
                  ref: "listQuery",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.listQuery,
                    "status-icon": "",
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cols" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { prop: "orgId", label: "所属组织:" },
                                },
                                [
                                  _c("treeselect", {
                                    attrs: {
                                      multiple: false,
                                      "flatten-search-results": true,
                                      placeholder: "请选择组织...",
                                      options: _vm.orgOptions,
                                    },
                                    model: {
                                      value: _vm.listQuery.orgId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.listQuery, "orgId", $$v)
                                      },
                                      expression: "listQuery.orgId",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "返利起始时间:",
                                    prop: "startTime",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "date",
                                      placeholder: "选择日期",
                                      format: "yyyy-MM-dd",
                                      "value-format": "yyyy-MM-dd",
                                    },
                                    model: {
                                      value: _vm.listQuery.startTime,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.listQuery,
                                          "startTime",
                                          $$v
                                        )
                                      },
                                      expression: "listQuery.startTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "返利结束时间:",
                                    prop: "endTime",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "date",
                                      placeholder: "选择日期",
                                      format: "yyyy-MM-dd",
                                      "value-format": "yyyy-MM-dd",
                                    },
                                    model: {
                                      value: _vm.listQuery.endTime,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.listQuery, "endTime", $$v)
                                      },
                                      expression: "listQuery.endTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.showCol || !_vm.formConfig.btnFollow,
                                  expression:
                                    "showCol || !formConfig.btnFollow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "channelName",
                                    label: "渠道:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      "suffix-icon": "el-icon-search",
                                      clearable: "",
                                      placeholder: "请选择",
                                    },
                                    on: {
                                      clear: _vm.clearQd,
                                      focus: function ($event) {
                                        return _vm.getUnit("qd")
                                      },
                                    },
                                    model: {
                                      value: _vm.channelName,
                                      callback: function ($$v) {
                                        _vm.channelName = $$v
                                      },
                                      expression: "channelName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.showCol
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "supplierName",
                                        label: "供货商:",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          "suffix-icon": "el-icon-search",
                                          clearable: "",
                                          placeholder: "请选择",
                                        },
                                        on: {
                                          clear: _vm.clearSply,
                                          focus: function ($event) {
                                            return _vm.getUnit("sply")
                                          },
                                        },
                                        model: {
                                          value: _vm.supplierName,
                                          callback: function ($$v) {
                                            _vm.supplierName = $$v
                                          },
                                          expression: "supplierName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showCol
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "rebateConfigName",
                                        label: "方案名称:",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          "suffix-icon": "el-icon-search",
                                          clearable: "",
                                          placeholder: "请选择",
                                        },
                                        on: {
                                          clear: _vm.clearConfig,
                                          focus: _vm.showConfig,
                                        },
                                        model: {
                                          value: _vm.rebateConfigName,
                                          callback: function ($$v) {
                                            _vm.rebateConfigName = $$v
                                          },
                                          expression: "rebateConfigName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showCol
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "productName",
                                        label: "返利产品:",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          "suffix-icon": "el-icon-search",
                                          clearable: "",
                                          placeholder: "请输入",
                                        },
                                        on: {
                                          clear: _vm.clearPro,
                                          focus: _vm.getProductId,
                                        },
                                        model: {
                                          value: _vm.productName,
                                          callback: function ($$v) {
                                            _vm.productName = $$v
                                          },
                                          expression: "productName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showCol
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "触发数码:",
                                        prop: "code",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { placeholder: "请输入" },
                                        model: {
                                          value: _vm.listQuery.code,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.listQuery, "code", $$v)
                                          },
                                          expression: "listQuery.code",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.formConfig.btnFollow
                            ? _c(
                                "el-col",
                                {
                                  staticClass: "head-btn-group",
                                  attrs: { span: 6 },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { "label-width": "0" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v(" ")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.searchBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.searchBtn.icon,
                                          },
                                          on: { click: _vm.DoSearch },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.searchBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.formConfig.showDvider
                                        ? _c("el-divider", {
                                            attrs: { direction: "vertical" },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.resetBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.resetBtn.icon,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.DoReset("listQuery")
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.resetBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.formConfig.showDvider
                                        ? _c("el-divider", {
                                            attrs: { direction: "vertical" },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: { click: _vm.expand },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(_vm.expandTxt) +
                                              "\n                "
                                          ),
                                          _c("i", {
                                            class:
                                              _vm.showCol == false
                                                ? "el-icon-arrow-down"
                                                : "el-icon-arrow-up",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.formConfig.btnFollow
                ? _c("el-divider", { staticClass: "btnDivider" })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btnArea" },
                [
                  !_vm.formConfig.btnFollow
                    ? _c(
                        "el-col",
                        {
                          staticClass: "head-btn-group",
                          staticStyle: { width: "230px" },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.searchBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.searchBtn.icon,
                              },
                              on: { click: _vm.DoSearch },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                          ),
                          _vm._v(" "),
                          _c("el-divider", {
                            attrs: { direction: "vertical" },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.resetBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.resetBtn.icon,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.DoReset("listQuery")
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                          ),
                          _vm._v(" "),
                          _c("el-divider", {
                            attrs: { direction: "vertical" },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: { click: _vm.expand },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.expandTxt) +
                                  "\n          "
                              ),
                              _c("i", {
                                class:
                                  _vm.showCol == false
                                    ? "el-icon-arrow-down"
                                    : "el-icon-arrow-up",
                              }),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "head-btn-group" },
                    [
                      _vm.hasAuth("importbtn", _vm.apis)
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.downLoadBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.downLoadBtn.icon,
                                loading: _vm.exportLoading,
                              },
                              on: { click: _vm.DoExportAll },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.downLoadBtn.text))]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  key: _vm.tableKey,
                  staticStyle: { width: "100%" },
                  attrs: {
                    height: _vm.tabHeight,
                    data: _vm.list,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    fit: "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "渠道编码",
                      prop: "channelCode",
                      align: _vm.tableConfig.align,
                      width: "150",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "渠道名称",
                      prop: "channelName",
                      align: _vm.tableConfig.align,
                      width: "150",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "返利组织",
                      prop: "channelOrg",
                      align: _vm.tableConfig.align,
                      width: "150",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "奖品分类",
                      prop: "giftName",
                      align: _vm.tableConfig.align,
                      width: "150",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "返利数量(元/个)",
                      prop: "giftValue",
                      align: _vm.tableConfig.align,
                      width: "150",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "触发动作",
                      prop: "triggerType",
                      align: _vm.tableConfig.align,
                      width: "150",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "返利产品编码",
                      prop: "productCode",
                      align: _vm.tableConfig.align,
                      width: "150",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "返利产品名称",
                      prop: "productName",
                      align: _vm.tableConfig.align,
                      width: "150",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "返利方案编码",
                      prop: "rebateConfigCode",
                      align: _vm.tableConfig.align,
                      width: "150",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "返利方案名称",
                      prop: "rebateConfigName",
                      align: _vm.tableConfig.align,
                      width: "150",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "返利时间",
                      prop: "rebateTime",
                      align: _vm.tableConfig.align,
                      width: "170",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "供应商编码",
                      prop: "supplierCode",
                      align: _vm.tableConfig.align,
                      width: "150",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "供应商名称",
                      prop: "supplierName",
                      align: _vm.tableConfig.align,
                      width: "150",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "来源",
                      prop: "sourceType",
                      align: _vm.tableConfig.align,
                      width: "150",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "关联返利编号",
                      prop: "rebatePolicyNo",
                      align: _vm.tableConfig.align,
                      width: "150",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _vm.hasAuth("detailbtn", _vm.apis)
                    ? _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          width: "120",
                          fixed: "right",
                          align: _vm.tableConfig.align,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm.hasAuth("detailbtn", _vm.apis)
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.scanCodes(row)
                                            },
                                          },
                                        },
                                        [_vm._v("查看数码")]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3270394095
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                  page: _vm.listQuery.current,
                  limit: _vm.listQuery.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "size", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.apis)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c("instockUnit", {
        ref: "channelSelect",
        attrs: { title: "选择单位" },
        on: { change: _vm.getUalue },
      }),
      _vm._v(" "),
      _c("productChose", { ref: "selProduct", on: { change: _vm.getProduct } }),
      _vm._v(" "),
      _c("selectHdFn", { ref: "hdfn", on: { select: _vm.getConfig } }),
      _vm._v(" "),
      _c("codeScan", {
        attrs: { codeVisible: _vm.codeVisible, params: _vm.codeParams },
        on: { close: _vm.closeCode },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }