var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c("h3", { staticClass: "h-title" }, [_vm._v("客户组")]),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  ref: "searchForm",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.searchForm,
                    "status-icon": "",
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                    "label-suffix": ":",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cols" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 10 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "主户头编码",
                                    prop: "groupCode",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      maxlength: "50",
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.searchForm.groupCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "groupCode",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.groupCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "主户头名称",
                                    prop: "groupName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      maxlength: "30",
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.searchForm.groupName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "groupName",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.groupName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              staticClass: "head-btn-group",
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { "label-width": "0" } },
                                [
                                  _c(
                                    "div",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [_vm._v(" ")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.searchBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.searchBtn.icon,
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.searchForm.pageNo = 1
                                          _vm.getList()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.btnConfig.searchBtn.text)
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.resetBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.resetBtn.icon,
                                      },
                                      on: { click: _vm.resetForm },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.btnConfig.resetBtn.text)
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  key: _vm.tableKey,
                  ref: "table",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.tableData,
                    height: _vm.tabHeight,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    fit: "",
                    "highlight-page-no-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "主户头编码",
                      prop: "groupCode",
                      align: _vm.tableConfig.align,
                      "min-width": "80",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "主户头名称",
                      prop: "groupName",
                      align: _vm.tableConfig.align,
                      "min-width": "80",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "经销商数量",
                      prop: "num",
                      align: _vm.tableConfig.align,
                      "min-width": "80",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "创建人",
                      prop: "createUserName",
                      align: _vm.tableConfig.align,
                      "min-width": "80",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      fixed: "right",
                      align: _vm.tableConfig.align,
                      "min-width": "50",
                      "class-name": "small-padding fixed-width",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm.hasAuth("detailbtn", _vm.$route.meta.authList)
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.getDetail(row)
                                        },
                                      },
                                    },
                                    [_vm._v("详情")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1073753780
                    ),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                  page: _vm.searchForm.pageNo,
                  limit: _vm.searchForm.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.searchForm, "pageNo", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.searchForm, "pageSize", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
              _vm._v(" "),
              !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
                ? _c("section", [_vm._m(1)])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: _vm.title,
            visible: _vm.dialogVisible,
            "before-close": _vm.dialogClose,
            width: "800px !important",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.mloading,
                  expression: "mloading",
                },
              ],
            },
            [
              _c(
                "el-descriptions",
                { attrs: { title: "基本信息", column: 1, border: "" } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "主户头编码" } },
                    [_vm._v(_vm._s(_vm.groupCode))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "主户头名称" } },
                    [_vm._v(_vm._s(_vm.groupName))]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "middleLine" }),
              _vm._v(" "),
              _c(
                "el-descriptions",
                { attrs: { column: 1, border: "", direction: "vertical" } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "经销商信息" } },
                    [
                      _c(
                        "el-table",
                        { attrs: { data: _vm.rowData, border: "" } },
                        [
                          _c("el-table-column", {
                            attrs: { type: "index", width: "", label: "序号" },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "channelCode",
                              label: "经销商编码",
                              width: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "channelName",
                              label: "经销商名称",
                              width: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "channelClassName",
                              label: "经销商分类",
                              width: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "channelGradeName",
                              label: "经销商等级",
                              width: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "default", plain: "" },
                  on: { click: _vm.dialogClose },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "btnArea" }, [
      _c("div", { staticClass: "head-btn-group" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n       "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }