var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.apis)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c(
                "el-form",
                {
                  ref: "formInline",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.formInline,
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                    "label-suffix": ":",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cols" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 10 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { "margin-bottom": "5px" },
                                  attrs: {
                                    prop: "activityName",
                                    label: "活动名称",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.formInline.activityName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formInline,
                                          "activityName",
                                          $$v
                                        )
                                      },
                                      expression: "formInline.activityName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { "margin-bottom": "5px" },
                                  attrs: {
                                    prop: "prizeType",
                                    label: "奖品类型",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择" },
                                      model: {
                                        value: _vm.formInline.prizeType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formInline,
                                            "prizeType",
                                            $$v
                                          )
                                        },
                                        expression: "formInline.prizeType",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "红包", value: "1101" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          label: "实物奖品",
                                          value: "2000",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          label: "第三方",
                                          value: "4000",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { "margin-bottom": "5px" },
                                  attrs: {
                                    prop: "prizeName",
                                    label: "奖品名称",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请选择",
                                      "suffix-icon": "el-icon-search",
                                      clearable: "",
                                    },
                                    on: {
                                      clear: _vm.clearPrize,
                                      focus: _vm.getPrize,
                                    },
                                    model: {
                                      value: _vm.prizeName,
                                      callback: function ($$v) {
                                        _vm.prizeName = $$v
                                      },
                                      expression: "prizeName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.handleShow
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { "margin-bottom": "5px" },
                                      attrs: {
                                        prop: "receiveState",
                                        label: "状态",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: "请选择",
                                            clearable: "",
                                          },
                                          model: {
                                            value: _vm.formInline.receiveState,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formInline,
                                                "receiveState",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formInline.receiveState",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: { label: "全部", value: "" },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "未领取",
                                              value: "0",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "已领取",
                                              value: "1",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { "margin-bottom": "5px" },
                                  attrs: {
                                    prop: "innerCode",
                                    label: "参与数码",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.formInline.innerCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formInline,
                                          "innerCode",
                                          $$v
                                        )
                                      },
                                      expression: "formInline.innerCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { "margin-bottom": "5px" },
                                  attrs: { prop: "userName", label: "用户名" },
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.formInline.userName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formInline,
                                          "userName",
                                          $$v
                                        )
                                      },
                                      expression: "formInline.userName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { "margin-bottom": "5px" },
                                  attrs: { prop: "userId", label: "用户名ID" },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.formInline.userId,
                                        expression: "formInline.userId",
                                      },
                                    ],
                                    staticClass: "el-input__inner",
                                    attrs: {
                                      type: "text",
                                      oninput:
                                        "value=value.replace(/[^\\d]/g,'')",
                                    },
                                    domProps: { value: _vm.formInline.userId },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.formInline,
                                          "userId",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { "margin-bottom": "5px" },
                                  attrs: { prop: "phone", label: "手机号" },
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.formInline.phone,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formInline, "phone", $$v)
                                      },
                                      expression: "formInline.phone",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          false
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { "margin-bottom": "5px" },
                                      attrs: {
                                        prop: "awardsStat",
                                        label: "微信转账状态",
                                        "label-width": "100px",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: { placeholder: "请选择" },
                                          model: {
                                            value: _vm.formInline.awardsStat,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formInline,
                                                "awardsStat",
                                                $$v
                                              )
                                            },
                                            expression: "formInline.awardsStat",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "失败",
                                              value: "0",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "转账中",
                                              value: "1",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "转账成功",
                                              value: "2",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { "margin-bottom": "5px" },
                                  attrs: {
                                    prop: "startTime",
                                    label: "开始日期",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "date",
                                      format: "yyyy-MM-dd",
                                      "value-format": "yyyy-MM-dd",
                                      clearable: false,
                                    },
                                    model: {
                                      value: _vm.formInline.startTime,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formInline,
                                          "startTime",
                                          $$v
                                        )
                                      },
                                      expression: "formInline.startTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { "margin-bottom": "5px" },
                                  attrs: { prop: "endTime", label: "结束日期" },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "date",
                                      format: "yyyy-MM-dd",
                                      "value-format": "yyyy-MM-dd",
                                      clearable: false,
                                    },
                                    model: {
                                      value: _vm.formInline.endTime,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formInline, "endTime", $$v)
                                      },
                                      expression: "formInline.endTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.formConfig.btnFollow
                            ? _c(
                                "el-col",
                                {
                                  staticClass: "head-btn-group",
                                  style: {
                                    width: _vm.formConfig.btnAreaHasShowMore,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { "label-width": "0" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v(" ")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.searchBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.searchBtn.icon,
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.formInline.pageindex = 1
                                              _vm.fetchData()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.searchBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.resetBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.resetBtn.icon,
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.restForm("formInline")
                                              _vm.fetchData()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.resetBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              _vm.handleShow = !_vm.handleShow
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.handleShow ? "收起" : "展开"
                                              ) +
                                              "\n                "
                                          ),
                                          _c("i", {
                                            class: _vm.handleShow
                                              ? "el-icon-arrow-up"
                                              : "el-icon-arrow-down",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.formConfig.btnFollow
                ? _c("el-divider", { staticClass: "btnDivider" })
                : _vm._e(),
              _vm._v(" "),
              _vm.hasAuth("importbtn", _vm.apis)
                ? _c("div", { staticClass: "phead" }, [
                    _c(
                      "div",
                      { staticClass: "head-btn-group" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: _vm.btnConfig.downLoadBtn.type,
                              size: _vm.btnConfig.size,
                              icon: _vm.btnConfig.downLoadBtn.icon,
                            },
                            on: { click: _vm.download },
                          },
                          [_vm._v(_vm._s(_vm.btnConfig.downLoadBtn.text))]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  attrs: {
                    height: _vm.tabHeight,
                    data: _vm.list,
                    fit: "",
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "参与数码",
                      "show-overflow-tooltip": "",
                      prop: "innerCode",
                      "min-width": "140",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "参与活动",
                      "min-width": "150",
                      prop: "configName",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "渠道名称",
                      "min-width": "150",
                      prop: "channelName",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "渠道账户",
                      "min-width": "150",
                      prop: "longinName",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "奖品名称",
                      prop: "",
                      "min-width": "120",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.hitstate == 0
                                      ? "谢谢惠顾"
                                      : scope.row.alias
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      542493497
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "奖品类型",
                      prop: "typeName",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("prizeType")(scope.row.prizeType)
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      695920351
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "金额",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.prizeType == 6000
                                      ? ""
                                      : scope.row.prizePrice
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1751503248
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "参与活动时间",
                      width: "155",
                      align: _vm.tableConfig.align,
                      prop: "createTime",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        label: "用户类型",
                        prop: "memberType",
                        align: _vm.tableConfig.align,
                        "show-overflow-tooltip": "",
                        width: "100",
                      },
                    },
                    [[_vm._v("终端")]],
                    2
                  ),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "用户ID",
                      prop: "memberId",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                      width: "100",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "用户名",
                      prop: "realname",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                      width: "100",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "联系方式",
                      align: _vm.tableConfig.align,
                      prop: "phone",
                      "show-overflow-tooltip": "",
                      width: "120",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "状态",
                      width: "100",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.receiveState == 0
                                ? _c("el-tag", { attrs: { type: "danger" } }, [
                                    _vm._v("未领取"),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.receiveState == 1
                                ? _c("el-tag", { attrs: { type: "success" } }, [
                                    _vm._v("已领取"),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      58627921
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: _vm.tableConfig.align,
                      label: "操作",
                      fixed: "right",
                      width: "180",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  staticClass: "button configText",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDetail(
                                        1,
                                        scope.row.id,
                                        scope.row
                                      )
                                    },
                                  },
                                },
                                [_vm._v("详情")]
                              ),
                              _vm._v(" "),
                              scope.row.prizeType == 2000 &&
                              scope.row.goodsStat == 0 &&
                              _vm.hasAuth("sendprize", _vm.apis)
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass: "button configText",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.isDj(
                                            scope.row.memberStatus,
                                            2,
                                            scope.row.id,
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("发放奖品")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.prizeType == 2000 &&
                              scope.row.goodsStat == 1 &&
                              _vm.hasAuth("updatebtn", _vm.apis)
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass: "button configText",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.getDetail(
                                            3,
                                            scope.row.id,
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("修改快递信息")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.prizeType == 1101 &&
                              scope.row.redbackStat == "FAILED" &&
                              _vm.hasAuth("insertbtn", _vm.apis)
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass: "button configText",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.report(scope.row.id)
                                        },
                                      },
                                    },
                                    [_vm._v("补发红包")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      237102868
                    ),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total>0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.formInline.pageindex,
                  limit: _vm.formInline.pagesize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.formInline, "pageindex", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.formInline, "pagesize", $event)
                  },
                  pagination: _vm.fetchData,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: _vm.dialogTitle,
            visible: _vm.dialogFormVisible,
            "before-close": _vm.cancle,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _vm.form.prizeType != 6000
                ? _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("奖品类型:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm._f("prizeType")(_vm.form.prizeType))),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("奖品名称:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.form.alias)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("展示名称:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.form.prizeName)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm.form.prizeType == 2000
                ? _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [_vm._v("金额:")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.form.prizePrice)),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("用户ID:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.form.memberId)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("用户名:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.form.realname)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("参与活动:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.form.configName)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [
                    _vm._v("参与活动时间:"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.form.createTime)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("状态:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm.form.receiveState == 0
                      ? _c("span", { attrs: { type: "success" } }, [
                          _vm._v(_vm._s("未领取")),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.form.receiveState == 1
                      ? _c("span", { attrs: { type: "success" } }, [
                          _vm._v(_vm._s("已领取")),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 16 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("参与数码:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.form.innerCode)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm.form.prizeType == 2000
                ? _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("发放人:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.form.senderName)),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.form.prizeType == 2000
                ? _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("发放时间:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.form.sendTime)),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.form.prizeType == 2000
                ? _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("操作人:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.form.operatorName)),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.form.prizeType == 2000
                ? _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("操作时间:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.form.operatorTime)),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm.form.prizeType == 2000
            ? _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("收货人:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.form.realname)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("联系方式:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.form.phone)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("收货地址:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(
                          _vm._s(
                            _vm.form.province +
                              _vm.form.city +
                              _vm.form.county +
                              _vm.form.address
                          )
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("联系方式:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.form.phone)),
                      ]),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.disabled
            ? _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c("el-button", { on: { click: _vm.cancle } }, [
                    _vm._v("取 消"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { type: "danger" }, on: { click: _vm.sub } },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              )
            : _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c("el-button", { on: { click: _vm.cancle } }, [
                    _vm._v("关 闭"),
                  ]),
                ],
                1
              ),
        ],
        1
      ),
      _vm._v(" "),
      _c("Tipdialog", {
        ref: "TipVisible",
        attrs: { width: "350px", content: _vm.tipContent },
      }),
      _vm._v(" "),
      _c("prizeSelectDialog", {
        ref: "prizeSelect",
        attrs: { data: "4" },
        on: { change: _vm.setPrize },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }