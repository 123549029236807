"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
var _instock = require("@/api/chanelmange/instock");
var _chanelyw = require("@/api/chanelmange/chanelyw");
var _purchaseDialog = _interopRequireDefault(require("@/components/purchaseDialog"));
var _productSelectDialog = _interopRequireDefault(require("@/components/productSelectDialog"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'chanelmangeoutstockqtckindex';
var _default = {
  name: 'Chanelmangeoutstockqtckindex',
  components: {
    Pagination: _Pagination.default,
    Treeselect: _vueTreeselect.default,
    purchaseDialog: _purchaseDialog.default,
    productChose: _productSelectDialog.default
  },
  filters: {
    formatTime: function formatTime(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        return (0, _utils.parseTime)(value, '{y}-{m}-{d} {h}:{i}:{s}');
      }
    }
  },
  data: function data() {
    return {
      active: '',
      // activeList: [
      //   { operDate: '111111', operator: '21122122' },
      //   { operDate: '', operator: '' },
      //   { operDate: '', operator: '' },
      //   { operDate: '', operator: '' },
      //   { operDate: '', operator: '' },
      //   { operDate: '', operator: '' }
      // ],
      activeName: '',
      listQuery: {
        orgId: null,
        sellerId: null,
        sellerStr: null,
        productId: null,
        productStr: null,
        businessType: null,
        billNo: null,
        outStartDate: null,
        outEndDate: null,
        isCode: null,
        status: null,
        isError: null,
        pageNo: 1,
        pageSize: 20
      },
      orgOptions: [],
      isCode: [{
        name: '是',
        code: '1'
      }, {
        name: '否',
        code: '0'
      }],
      states: [
      // {
      //   name: '删除',
      //   code: '0'
      // },
      {
        name: '已提交',
        code: '1'
      },
      // {
      //   name: '待审核',
      //   code: '2'
      // },
      // {
      //   name: '部分出库',
      //   code: '3'
      // },
      {
        name: '全部出库',
        code: '4'
      }, {
        name: '已收货',
        code: '5'
      }],
      sigleProduct: {
        productName: '',
        productCode: ''
      },
      unitSelect: {
        channelName: ''
      },
      businessOption: [],
      tableKey: 'cgrk',
      tabHeight: '100%',
      list: [],
      listLoading: false,
      total: 0,
      unitShow: false,
      unitType: '',
      productStyle: '',
      productShow: false,
      personShow: false,
      exportLoading: false,
      scanVisible: false,
      baseData: {},
      checklist: [],
      productlist: [],
      remark: '',
      fileUrls: [],
      orderId: '',
      codeData: [],
      billNo: '',
      codeParam: {
        code: '',
        id: '',
        productId: '',
        productName: '',
        isError: '',
        orderType: 1,
        pageNo: 1,
        pageSize: 10,
        billNo: ''
      },
      total2: 0,
      scanCodeVisible: false,
      apis: [],
      page2: false,
      showCol: false,
      expandTxt: '展开'
    };
  },
  activated: function activated() {
    var that = this;
    var hasCache = that.list.length > 0;
    var authList = that.$route.meta.authList;
    that.apis = authList;
    var auth = that.hasAuth('searchbtn', authList);
    that.authId('searchbtn', that.apis);
    if (!hasCache) {
      if (auth) {
        that.getOrg();
        that.getBusType();
        that.getList();
      }
    }
  },
  // 缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = that.getCaches(that, name);
    if (!cache) {
      that.list = [];
      that.total = 0;
    }
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    mouseOver: function mouseOver() {
      this.$refs.arrow.style.color = '#409eff';
      this.$refs.arrowtest.style.color = '#409eff';
    },
    mouseLeave: function mouseLeave() {
      this.$refs.arrow.style.color = '#595959';
      this.$refs.arrowtest.style.color = '#595959';
    },
    getList: function getList() {
      var that = this;
      that.authId('searchbtn', that.apis);
      that.listLoading = true;
      var param = {};
      /*
      * 单据类型： 101：生产入库 102：调货入库 103：退货入库 104：采购入库 105：其它（材料）入库 201：返工出库 202：调货出库 203：销售出库 204：退货出库 205：其它（材料）出库
      * */
      param.billType = '204';
      param.orgId = that.listQuery.orgId;
      param.sellerId = that.listQuery.sellerId;
      param.productId = that.listQuery.productId;
      param.businessType = that.listQuery.businessType;
      param.billNo = that.listQuery.billNo;
      param.outStartDate = that.listQuery.outStartDate;
      param.outEndDate = that.listQuery.outEndDate;
      param.isCode = that.listQuery.isCode;
      param.isError = that.listQuery.isError;
      param.status = that.listQuery.status;
      param.pageNo = that.listQuery.pageNo;
      param.pageSize = that.listQuery.pageSize;
      (0, _instock.getCgRkList)(param).then(function (res) {
        if (res.data.code == 200) {
          that.list = res.data.data.records;
          that.total = parseInt(res.data.data.total);
          setTimeout(function () {
            that.listLoading = false;
          }, 1 * 800);
        } else {
          that.$message.error(res.data.msg);
          that.listLoading = false;
          return;
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    getOrg: function getOrg() {
      var that = this;
      (0, _instock.getQueryOrg)({
        orgStatus: 1,
        orgTypeList: [1, 2, 3, 4, 0]
      }).then(function (res) {
        if (res.data.code == 200) {
          var rData = res.data.data;
          var arr = [];
          that.orgOriginData = rData;
          if (rData.length >= 0) {
            rData.forEach(function (item) {
              var obj = {};
              obj.id = item.pid;
              obj.label = item.orgName;
              obj.pid = item.pid;
              obj.parentPid = item.parentPid;
              arr.push(obj);
            });
            that.orgOptions = (0, _utils.transTreeData)(arr, 'pid', 'parentPid', 'children');
          }
        }
      });
    },
    clearFh: function clearFh() {
      var that = this;
      that.listQuery.sellerId = '';
    },
    clearPro: function clearPro() {
      var that = this;
      that.listQuery.productId = '';
    },
    DoSearch: function DoSearch() {
      var that = this;
      that.listQuery = (0, _utils.filterKeyNull)(that.listQuery);
      that.listQuery.pageNo = 1;
      that.getList();
      if (that.listQuery.orgId == '') {
        that.listQuery.orgId = null;
      }
    },
    DoReset: function DoReset(formName) {
      var that = this;
      that.listQuery.orgId = null;
      that.listQuery.sellerId = null;
      that.listQuery.sellerStr = null;
      that.listQuery.productId = null;
      that.listQuery.productStr = null;
      that.listQuery.businessType = null;
      that.listQuery.billNo = null;
      that.listQuery.outStartDate = null;
      that.listQuery.outEndDate = null;
      that.listQuery.isCode = null;
      that.listQuery.status = null;
      that.listQuery.isError = null;
      that.listQuery.pageNo = 1;
      that.getList();
    },
    // 获取产品名称
    choseProName: function choseProName(val) {
      this.authId('searchbtn', this.apis);
      this.productStyle = val;
      this.$refs.selProduct.proSelVisible = true;
    },
    setSel: function setSel(val) {
      var that = this;
      if (that.productStyle == 'search') {
        that.listQuery.productId = val.pId;
        that.listQuery.productStr = val.productName;
        that.sigleProduct.productCode = val.productCode;
        that.sigleProduct.productName = val.productName;
      }
      if (that.productStyle == 'codeScan') {
        that.codeParam.productName = val.productName;
        that.codeParam.productId = val.pId;
      }
    },
    // 获取单位
    getUnit: function getUnit(val) {
      var _this = this;
      var that = this;
      that.authId('searchbtn', that.apis);
      that.unitType = val;
      // that.unitShow = true
      this.$nextTick(function () {
        _this.$refs.channelSelect.proSelVisible = true;
      });
    },
    hideUnit: function hideUnit() {
      var that = this;
      that.unitShow = false;
    },
    getUalue: function getUalue(val) {
      var that = this;
      that.unitSelect.channelName = val.channelName;
      if (that.unitType == 'in') {
        that.listQuery.buyerId = val.pId;
        that.listQuery.buyerStr = val.channelName;
      }
      if (that.unitType == 'out') {
        that.listQuery.sellerId = val.pId;
        that.listQuery.sellerStr = val.channelName;
      }
      that.unitShow = false;
    },
    // 获取业务类型
    getBusType: function getBusType() {
      var that = this;
      (0, _chanelyw.getYwList)({
        billEnum: 7,
        bussinessStatus: '1',
        outgoType: 1,
        current: 1,
        size: 2147483647
      }).then(function (res) {
        if (res.data.code == 200) {
          that.businessOption = res.data.data.records;
        }
      });
    },
    hidePerson: function hidePerson() {
      var that = this;
      that.personShow = false;
    },
    getPerson: function getPerson(val) {
      var that = this;
      that.listQuery.salesmanId = JSON.parse(val).pid;
      that.listQuery.salesmanStr = JSON.parse(val).userName;
      that.personShow = false;
    },
    // 导出文件流并下载
    DoExportAll: function DoExportAll() {
      var that = this;
      that.authId('importbtn', that.apis);
      if (that.total > 50000) {
        that.$message.error('抱歉！您要导出的数据量超出了导出数据的上限，最多可导出50000条');
        return;
      }
      var param = {};
      param.billType = '204';
      param.orgId = that.listQuery.orgId;
      param.sellerId = that.listQuery.sellerId;
      param.productId = that.listQuery.productId;
      param.businessType = that.listQuery.businessType;
      param.billNo = that.listQuery.billNo;
      param.orderNo = that.listQuery.orderNo;
      param.outStartDate = that.listQuery.outStartDate;
      param.outEndDate = that.listQuery.outEndDate;
      param.isCode = that.listQuery.isCode;
      param.status = that.listQuery.status;
      param.isError = that.listQuery.isError;
      param.pageNo = 1;
      param.pageSize = that.total;
      that.exportLoading = true;
      (0, _instock.inOrOutStockExport)(param).then(function (res) {
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '退货出库列表.xls';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
        that.exportLoading = false;
      }).catch(function () {
        that.exportLoading = false;
      });
    },
    handleClose: function handleClose() {
      this.baseData = {};
      this.activeName = '';
      this.remark = '';
      this.checklist = [];
      this.productlist = [];
      this.fileUrls = [];
      this.scanVisible = false;
    },
    // 查看详情
    DoScan: function DoScan(data) {
      var that = this;
      that.authId('detailbtn', that.apis);
      var param = {};
      param.billNo = data.no;
      that.orderId = data.pid;
      that.billNo = data.no;
      (0, _instock.getCgDetail)(param).then(function (res) {
        if (res.data.code == 200) {
          if (res.data.data.base != null) {
            that.baseData = res.data.data.base;
            that.remark = res.data.data.base.remark;
            if (res.data.data.base.fileUrls != null) {
              that.fileUrls = res.data.data.base.fileUrls.split(',');
            }
            switch (res.data.data.base.orderStatus * 1) {
              case 201:
                that.active = 1;
                break;
              case 203:
                that.active = 2;
                break;
              case 204:
                that.active = 3;
                break;
            }
          }
          if (res.data.data.productlist != null) {
            that.productlist = res.data.data.productlist;
          }
          that.scanVisible = true;
        } else {
          that.baseData = {};
          that.checklist = {};
          that.productlist = {};
          that.$message.error('获取详情失败，请稍后再试！');
        }
      });
    },
    // 查看数码
    scanCode: function scanCode() {
      var that = this;
      that.scanCodeVisible = true;
      that.getCodeDetails();
    },
    codeClose: function codeClose() {
      this.scanCodeVisible = false;
      this.codeParam = {
        code: '',
        id: '',
        productId: '',
        productName: '',
        isError: '',
        orderType: 1,
        pageNo: 1,
        pageSize: 10,
        billNo: ''
      };
      this.codeData = [];
      this.total2 = 0;
    },
    getCodeDetails: function getCodeDetails() {
      var that = this;
      this.codeParam.id = that.orderId;
      this.codeParam.billNo = that.billNo;
      var param = JSON.parse(JSON.stringify(this.codeParam));
      delete param.productName;
      (0, _instock.getCodeDetail)(param).then(function (res) {
        if (res.data.code == 200) {
          that.codeData = res.data.data.records;
          that.total2 = parseInt(res.data.data.total);
        }
      });
    },
    // 查看数码搜索
    searchCode: function searchCode() {
      var that = this;
      that.codeParam.pageNo = 1;
      that.getCodeDetails();
    },
    searchReset: function searchReset() {
      // this.codeParam.id = ''
      this.codeParam.code = '';
      this.codeParam.productId = '';
      this.codeParam.productName = '';
      this.codeParam.isError = '';
      this.codeParam.pageNo = 1;
      this.getCodeDetails();
    },
    // 展开收起搜索条件
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    }
  }
};
exports.default = _default;