"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ImportCodeList = ImportCodeList;
exports.addScanWhite = addScanWhite;
exports.codeListPage = codeListPage;
exports.getCodeExport = getCodeExport;
exports.remove = remove;
var _request = _interopRequireDefault(require("@/utils/request"));
var base = '';

// 二次扫码白名单 分页查询
function codeListPage(data) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/scanWhiteListing/codeListPage',
    method: 'post',
    data: data
  });
}

// 二次扫码白名单  导出
function getCodeExport(params) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/scanWhiteListing/codeExport',
    method: 'get',
    responseType: 'blob',
    params: params
  });
}
// 批量导入
function ImportCodeList(file) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/scanWhiteListing/importCodeList',
    method: 'post',
    file: file
  });
}
// 新增单个
function addScanWhite(params) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/scanWhiteListing/add',
    method: 'post',
    params: params
  });
}

// 移除
function remove(params) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/scanWhiteListing/remove',
    method: 'post',
    params: params
  });
}