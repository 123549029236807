"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _categoryAddOrUpdate = _interopRequireDefault(require("./category-add-or-update"));
var _utils = require("@/utils");
var _wXintegralMall = require("@/api/wxhyyy/wXintegralMall");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    AddOrUpdate: _categoryAddOrUpdate.default
  },
  data: function data() {
    return {
      dataForm: {},
      dataList: [],
      dataListLoading: false,
      addOrUpdateVisible: false
    };
  },
  mounted: function mounted() {
    this.getDataList();
  },
  methods: {
    // 获取数据列表
    getDataList: function getDataList() {
      var _this = this;
      this.dataListLoading = true;
      (0, _wXintegralMall.categoryTable)().then(function (data) {
        var data = data.data;
        _this.dataListLoading = false;
        _this.dataList = (0, _utils.treeDataTranslate)(data, 'categoryId', 'parentId');
      });
    },
    // 新增 / 修改
    addOrUpdateHandle: function addOrUpdateHandle(id) {
      var _this2 = this;
      this.addOrUpdateVisible = true;
      this.$nextTick(function () {
        _this2.$refs.addOrUpdate.init(id);
      });
    },
    // 删除
    deleteHandle: function deleteHandle(id) {
      var _this3 = this;
      console.log(id, 987);
      this.$confirm("\u786E\u5B9A\u8981\u5220\u9664\u8BE5\u5206\u7C7B\u5417?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _wXintegralMall.delCategory)(id).then(function (data) {
          _this3.$message({
            message: '操作成功',
            type: 'success',
            duration: 1500,
            onClose: function onClose() {
              _this3.getDataList();
            }
          });
        }).catch(function (err) {
          if (err.response.status * 1 === 400) {
            _this3.$message.closeAll();
            _this3.$message({
              message: err.response.data,
              type: 'error',
              duration: 2000
            });
          }
        });
      });
    }
  }
};
exports.default = _default;