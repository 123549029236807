"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _echarts = _interopRequireDefault(require("echarts"));
var _resize = _interopRequireDefault(require("./mixins/resize"));
require("echarts/map/js/china.js");
//
//
//
//
// 引入中国地图数据
// const animationDuration = 6000
// import axios from 'axios'
var _default = {
  mixins: [_resize.default],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '400px'
    },
    tipName: {
      type: String,
      default: ''
    },
    fontSize: {
      type: Number,
      default: 12
    },
    layoutSize: {
      type: String,
      default: '80%'
    },
    name: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      loading: true,
      chart: null,
      mapData: [],
      max: 50,
      list: [{
        name: '安徽',
        value: 'anhui.json'
      }, {
        name: '澳门',
        value: 'aomen.json'
      }, {
        name: '北京',
        value: 'beijing.json'
      }, {
        name: '重庆',
        value: 'chongqing.json'
      }, {
        name: '福建',
        value: 'fujian.json'
      }, {
        name: '甘肃',
        value: 'gansu.json'
      }, {
        name: '广东',
        value: 'guangdong.json'
      }, {
        name: '广西',
        value: 'guangxi.json'
      }, {
        name: '贵州',
        value: 'guizhou.json'
      }, {
        name: '海南',
        value: 'hainan.json'
      }, {
        name: '河北',
        value: 'hebei.json'
      }, {
        name: '黑龙江',
        value: 'heilongjiang.json'
      }, {
        name: '河南',
        value: 'henan.json'
      }, {
        name: '湖北',
        value: 'hubei.json'
      }, {
        name: '湖南',
        value: 'hunan.json'
      }, {
        name: '江苏',
        value: 'jiangsu.json'
      }, {
        name: '江西',
        value: 'jiangxi.json'
      }, {
        name: '吉林',
        value: 'jilin.json'
      }, {
        name: '辽宁',
        value: 'liaoning.json'
      }, {
        name: '内蒙古',
        value: 'neimenggu.json'
      }, {
        name: '宁夏',
        value: 'ningxia.json'
      }, {
        name: '青海',
        value: 'qinghai.json'
      }, {
        name: '山东',
        value: 'shandong.json'
      }, {
        name: '上海',
        value: 'shanghai.json'
      }, {
        name: '山西',
        value: 'shanxi.json'
      }, {
        name: '陕西',
        value: 'shanxi1.json'
      }, {
        name: '四川',
        value: 'sichuan.json'
      }, {
        name: '台湾',
        value: 'taiwan.json'
      }, {
        name: '天津',
        value: 'tianjin.json'
      }, {
        name: '香港',
        value: 'xianggang.json'
      }, {
        name: '新疆',
        value: 'xinjiang.json'
      }, {
        name: '西藏',
        value: 'xizang.json'
      }, {
        name: '云南',
        value: 'yunnan.json'
      }, {
        name: '浙江',
        value: 'zhejiang.json'
      }]
    };
  },
  mounted: function mounted() {
    // this.$nextTick(() => {
    //   this.initChart()
    // })
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart(data) {
      this.loading = false;
      var name;
      this.list.forEach(function (item) {
        if (item.name === data) {
          name = item.value;
        }
      });
      var map = require("echarts/map/json/province/".concat(name));
      // axios.get('/province/' + name).then(res => {
      _echarts.default.registerMap('province', map);
      this.chart = _echarts.default.init(this.$el);
      this.chart.setOption({
        tooltip: {
          show: true,
          formatter: function formatter(params) {
            if (params.data) {
              return params.name + '：' + params.data.value;
            } else {
              return '';
            }
          }
        },
        visualMap: {
          type: 'continuous',
          text: ['', ''],
          showLabel: false,
          seriesIndex: [0],
          min: 0,
          max: 1000,
          itemWidth: 0,
          itemHeight: 100,
          inRange: {
            color: ['#ebfafb', '#1E9FFF']
          },
          textStyle: {
            color: '#000'
          },
          bottom: '-100',
          left: 'left'
        },
        geo: {
          roam: false,
          map: 'province',
          layoutCenter: ['50%', '50%'],
          layoutSize: '80%',
          label: {
            normal: {
              show: false,
              // 是否显示对应地名
              fontSize: 10,
              textStyle: {
                color: 'rgba(0,0,0,0.8)'
              }
            }
          },
          itemStyle: {
            emphasis: {
              areaColor: '#ebfafb'
            }
          }
        },
        series: [{
          name: 'mapSer',
          map: 'province',
          type: 'map',
          roam: false,
          geoIndex: 0,
          label: {
            show: true
          },
          zlevel: 10,
          data: this.mapData
        }]
      });
      // })
    }
  }
};
exports.default = _default;