"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _factory = require("@/api/basic/factory");
var _pack = require("@/api/basic/pack");
var _store = require("@/api/store");
var _dictionary = require("@/api/systems/dictionary");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _productSelectDialog = _interopRequireDefault(require("@/components/productSelectDialog"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var miniUnit = '';
var groupList = [];
var _default = {
  name: 'StoreServicestoreManageproductionPlanindex',
  components: {
    Pagination: _Pagination.default,
    productDialog: _productSelectDialog.default
  },
  filters: {
    groupName: function groupName(status) {
      var t = '';
      groupList.forEach(function (item) {
        if (item.figure == status) {
          t = item.name;
        }
      });
      return t;
    },
    minSum: function minSum(sum, packScaleExpression) {
      if (!sum || !packScaleExpression) return '';
      var arr = packScaleExpression.split('*');
      var le = arr.length;
      if (le == 1) {
        return sum;
      }
      var num = sum / arr[0];
      miniUnit = arr[le - 1] * num;
      return arr[le - 1] * num;
    },
    statusText: function statusText(status) {
      if (!String(status)) return '';
      var statusMap = {
        0: '草稿',
        5: '已创建，待审核',
        7: '已审核，未通过',
        10: '已审核，待下载',
        13: '已下载，执行中',
        16: '执行完成'
      };
      return statusMap[status];
    },
    uploadMode: function uploadMode(status) {
      if (!String(status)) return '';
      var statusMap = {
        0: '按托上传',
        1: '整单上传'
      };
      return statusMap[status];
    },
    labelText: function labelText(status) {
      if (!String(status)) return '';
      var statusMap = {
        0: '前关联',
        1: '后关联'
      };
      return statusMap[status];
    },
    sourceText: function sourceText(status) {
      if (!String(status)) return '';
      var statusMap = {
        0: 'ERP',
        1: '平台'
      };
      return statusMap[status];
    },
    packLevel: function packLevel(status) {
      if (!String(status)) return '未知';
      var statusMap = {
        1: '盖码',
        2: '瓶码',
        3: '盒码',
        4: '箱码',
        5: '托码'
      };
      return statusMap[status];
    },
    // 最小包装比例
    unitName: function unitName(arr) {
      if (arr == null && !arr) return '';
      var name = '';
      if (arr.length == 2) {
        name = arr[1].unitName;
      } else if (arr.length == 3) {
        name = arr[2].unitName;
      }
      return name;
    },
    // 最大包装比例
    unitName2: function unitName2(arr) {
      if (arr == null && !arr) return '';
      var name = '';
      if (arr.length) {
        name = arr[0].unitName;
      }
      return name;
    }
  },
  data: function data() {
    var _this = this;
    var validatematerialCode = function validatematerialCode(rule, value, callback) {
      if (_this.proList.length == 0 || _this.proList == '' || _this.proList == null) {
        callback(new Error('产品明细不能为空，请维护'));
      } else {
        callback();
      }
    };
    return {
      treeProps: {
        children: 'children',
        hasChildren: 'hasChildren'
      },
      handleShow: false,
      groupShow: false,
      isAudit: false,
      factoryList: [],
      workshopList: [],
      scanworkshopList: [],
      workshopListLoading: false,
      scanworkshopListLoading: false,
      lineList: [],
      scanlineList: [],
      lineListLoading: false,
      scanlineListLoading: false,
      teamList: [],
      scanteamList: [],
      teamListLoading: false,
      scanteamListLoading: false,
      boxList: [],
      storeList: [],
      storeSELList: [],
      codeList: [],
      proList: [],
      searchForm: {
        batchNo: null,
        current: 1,
        endTime: null,
        factoryCode: null,
        inboundStatus: null,
        materialCode: null,
        materialName: null,
        uploadMode: null,
        orderNo: null,
        orderSource: null,
        orderStatus: null,
        // pageNo: null,
        // pageSize: null,
        productLineCode: null,
        shopCode: null,
        size: 20,
        startTime: null,
        storehouseCode: null,
        teamCode: null
      },
      total: 0,
      codetotal: 0,
      tableData: [],
      tableKey: '01004',
      listLoading: false,
      btnLoading: false,
      codeListLoading: false,
      downLoading: false,
      orderNoloading: false,
      mals: '',
      orderStatus: '',
      title: '生产计划详情',
      multipleSelection: [],
      scanData: {
        orderNo: '',
        // uploadMode: '',
        factoryCode: '',
        shopCode: '',
        productLineCode: '',
        teamCode: '',
        storehouseCode: '',
        traychestScaleCode: '',
        planNum: '',
        miniUnit: '',
        batchNo: '',
        labelType: '',
        materialCode: '',
        originPlace: '',
        director: JSON.parse(sessionStorage.getItem('userName')),
        remarks: ''
      },
      detailData: {
        orderNo: '',
        factoryCode: '',
        shopCode: '',
        productLineCode: '',
        teamCode: '',
        storehouseCode: '',
        traychestScaleCode: '',
        planNum: '',
        miniUnit: '',
        batchNo: '',
        labelType: '',
        materialCode: '',
        originPlace: '',
        director: JSON.parse(sessionStorage.getItem('userName')),
        remarks: '',
        packScaleLevelItemDTOList: []
      },
      rules: {
        orderNo: [{
          required: true,
          message: '生产计划单号不能为空，请维护',
          trigger: 'change'
        }],
        factoryCode: [{
          required: true,
          message: '生产工厂不能为空，请维护',
          trigger: 'change'
        }],
        shopCode: [{
          required: true,
          message: '车间名称不能为空，请维护',
          trigger: 'change'
        }],
        productLineCode: [{
          required: true,
          message: '产线不能为空，请维护',
          trigger: 'change'
        }],
        teamCode: [{
          required: true,
          message: '班组不能为空，请维护',
          trigger: 'change'
        }],
        storehouseCode: [{
          required: true,
          message: '所属库房不能为空，请维护',
          trigger: 'change'
        }],
        // uploadMode: [
        //   {
        //     required: true,
        //     message: '发布不能为空，请维护',
        //     trigger: 'change'
        //   }
        // ],
        // traychestScaleCode: [
        //   {
        //     required: true,
        //     message: '每托箱数不能为空，请维护',
        //     trigger: 'change'
        //   }
        // ],
        materialCode: [{
          required: true,
          trigger: 'blur',
          validator: validatematerialCode
        }
        // {
        //   required: true,
        //   message: '产品明细不能为空，请维护',
        //   trigger: 'change'
        // }
        ],

        planNum: [{
          required: true,
          message: '计划产量不能为空，请维护',
          trigger: 'change'
        }],
        batchNo: [{
          required: true,
          message: '生产批次不能为空，请维护',
          trigger: 'change'
        }]
        // labelType: [
        //   {
        //     required: true,
        //     message: '标签关联类型不能为空，请维护',
        //     trigger: 'change'
        //   }
        // ]
      },

      detailForm: {
        current: 1,
        size: 10,
        orderNo: ''
      },
      dialogVisible: false,
      detailVisible: false,
      codeVisible: false,
      mloading: false,
      groupList: [],
      page2: false,
      tabHeight: '100%',
      submitType: '',
      groupId: '',
      failVisible: false,
      failList: [],
      failQuery: {
        materialId: '',
        orderNo: '',
        current: 1,
        size: 10
      },
      failtotal: 0,
      auditForm: {
        auditReasons: '',
        handlerResult: ''
      },
      auditRule: {
        handlerResult: [{
          required: true,
          message: '请选择审核结果',
          trigger: 'change'
        }]
      },
      materialId: ''
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    var that = this;
    this.getFactoryList(1, '', 'factoryList');
    this.getStoreList();
    this.getBoxList();
    this.getList();
    (0, _dictionary.getItemByDataDictionaryByCode)({
      code: 'FZ100'
    }).then(function (res) {
      if (res.data.code != 200) {
        that.$message.error(res.data.msg);
      } else {
        if (res.data.data != null) {
          groupList = res.data.data;
        } else {
          groupList = [];
        }
      }
    });
  },
  methods: {
    checkGroup: function checkGroup(id) {
      this.groupId = id;
      this.groupShow = true;
    },
    /**
     *
     * 获取工厂、车间、产线、班组
     * @param {*} val 1:工厂,2:车间,3:产线,4:班组
     * @param {*} pId
     * @param {*} list
     */
    getFactoryList: function getFactoryList(val, pId, list) {
      var _this2 = this;
      this[list + 'Loading'] = true;
      (0, _factory.factoryInfoAllList)({
        type: val,
        isEnable: '',
        parentId: pId
      }).then(function (res) {
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this2[list] = res.data.data;
          } else {
            _this2[list] = [];
          }
        }
        _this2[list + 'Loading'] = false;
      }).catch(function () {
        _this2[list + 'Loading'] = false;
      });
    },
    getBoxList: function getBoxList() {
      var that = this;
      (0, _pack.supportBoxLevelList)({
        current: 1,
        size: 2147483647
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          if (response.data.data != null) {
            that.boxList = response.data.data.records;
          } else {
            that.boxList = [];
          }
        }
      });
    },
    getStoreList: function getStoreList(val) {
      var that = this;
      (0, _factory.ccGetHouseList)({
        isEnable: 1
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.storeList = response.data.data;
          } else {
            that.storeList = [];
          }
        }
      });
    },
    resetForm: function resetForm(formName) {
      var that = this;
      that.searchForm.current = 1;
      that.searchForm.size = 20;
      that.$refs['searchForm'].resetFields();
      this.getList();
    },
    getList: function getList() {
      var _this3 = this;
      var that = this;
      if (that.searchForm.startTime && !that.searchForm.endTime) {
        this.$message.error('请选择创建结束时间');
        return;
      }
      if (!that.searchForm.startTime && that.searchForm.endTime) {
        this.$message.error('请选择创建起始时间');
        return;
      }
      that.listLoading = true;
      (0, _store.getOrderList)(that.searchForm).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.tableData = response.data.data.records;
            that.total = parseInt(response.data.data.total);
            that.listLoading = false;
          } else {
            that.tableData = [];
            that.total = 0;
          }
        }
      }).catch(function () {
        _this3.listLoading = false;
      });
    },
    setFactory: function setFactory(val) {
      this.searchForm.shopCode = '';
      this.searchForm.productLineCode = '';
      this.searchForm.teamCode = '';
      this.lineList = [];
      this.teamList = [];
      if (!val) {
        this.workshopList = [];
      } else {
        this.getFactoryList(2, val, 'workshopList');
      }
    },
    setShop: function setShop(val) {
      this.searchForm.productLineCode = '';
      this.searchForm.teamCode = '';
      if (!val) {
        this.lineList = [];
        this.teamList = [];
      } else {
        this.getFactoryList(3, val, 'lineList');
        this.getFactoryList(4, val, 'teamList');
      }
    },
    setScanFactory: function setScanFactory(val) {
      this.scanData.shopCode = '';
      this.scanData.productLineCode = '';
      this.scanData.teamCode = '';
      this.scanData.storehouseCode = '';
      this.scanlineList = [];
      this.scanteamList = [];
      this.proList = [];
      if (!val) {
        this.scanworkshopList = [];
        this.storeSELList = [];
      } else {
        this.getFactoryList(2, val, 'scanworkshopList');
        this.storeSELList = this.storeList.filter(function (number) {
          return number.factoryId == val;
        });
      }
    },
    setScanShop: function setScanShop(val) {
      this.scanData.productLineCode = '';
      this.scanData.teamCode = '';
      if (!val) {
        this.scanlineList = [];
        this.scanteamList = [];
        return;
      } else {
        this.getFactoryList(3, val, 'scanlineList');
        this.getFactoryList(4, val, 'scanteamList');
      }
    },
    add: function add() {
      this.title = '添加生产计划单';
      this.dialogVisible = true;
      this.submitType = 1;
      this.getFactoryList(1, '', 'factoryList');
      // this.getStoreList()
      this.getBoxList();
      this.getCode('storage_product_order_no');
      this.getCode('storage_product_batch_no');
    },
    openSel: function openSel() {
      if (this.scanData.factoryCode) {
        this.$refs.selProduct.searchForm.factoryId = this.scanData.factoryCode;
        this.$refs.selProduct.getList();
        this.$refs.selProduct.proSelVisible = true;
      } else {
        this.$message.error('请选择生产工厂');
      }
    },
    setSel: function setSel(val) {
      this.scanData.materialCode = val.pId;
      this.scanData.planNum = '';
      this.proList = [val];
    },
    setSum: function setSum(e) {
      this.scanData = JSON.parse(JSON.stringify(this.scanData));
      this.scanData.planNum = this.valENnum(e);
    },
    proDel: function proDel() {
      this.proList = [];
      this.scanData.planNum = '';
      this.scanData.materialCode = '';
    },
    getCode: function getCode(type) {
      var _this4 = this;
      (0, _store.generateOrderSequence)({
        bizType: type
      }).then(function (res) {
        if (res.data.code != 200) {
          _this4.$message.error(res.data.msg);
        } else {
          if (type == 'storage_product_order_no') {
            _this4.scanData.orderNo = res.data.data;
          }
          if (type == 'storage_product_batch_no') {
            _this4.scanData.batchNo = res.data.data;
          }
        }
      });
    },
    submitForm: function submitForm(temp) {
      var _this5 = this;
      this.$refs['scanForm'].validate(function (valid) {
        if (valid) {
          if (_this5.scanData.planNum == 0) {
            _this5.$message.error('计划产量不能为0');
            return;
          }
          _this5.btnLoading = true;
          _this5.scanData.temp = temp;
          _this5.scanData.miniUnit = miniUnit;
          if (_this5.submitType == 2) {
            if (_this5.orderStatus == 7) {
              (0, _store.reviewNoPassupdate)(_this5.scanData).then(function (res) {
                if (res.data.code != 200) {
                  _this5.$message.error(res.data.msg);
                } else {
                  _this5.$message({
                    message: '保存成功',
                    type: 'success'
                  });
                  _this5.getList();
                  _this5.dialogClose();
                }
                _this5.btnLoading = false;
              }).catch(function () {
                _this5.btnLoading = false;
              });
            } else {
              (0, _store.getOrderupdate)(_this5.scanData).then(function (res) {
                if (res.data.code != 200) {
                  _this5.$message.error(res.data.msg);
                } else {
                  _this5.$message({
                    message: '保存成功',
                    type: 'success'
                  });
                  _this5.getList();
                  _this5.dialogClose();
                }
                _this5.btnLoading = false;
              }).catch(function () {
                _this5.btnLoading = false;
              });
            }
          } else {
            (0, _store.getOrdersave)(_this5.scanData).then(function (res) {
              if (res.data.code != 200) {
                _this5.$message.error(res.data.msg);
              } else {
                _this5.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this5.getList();
                _this5.dialogClose();
              }
              _this5.btnLoading = false;
            }).catch(function () {
              _this5.btnLoading = false;
            });
          }
        } else {
          _this5.$message.error('请完善信息！');
        }
      });
    },
    dialogClose: function dialogClose() {
      this.orderStatus = '';
      this.proList = [];
      this.scanworkshopList = [];
      this.scanlineList = [];
      this.scanteamList = [];
      this.$refs.scanForm.resetFields();
      this.scanData = {
        orderNo: '',
        // uploadMode: '',

        factoryCode: '',
        shopCode: '',
        productLineCode: '',
        teamCode: '',
        storehouseCode: '',
        traychestScaleCode: '',
        planNum: '',
        miniUnit: '',
        batchNo: '',
        labelType: '',
        materialCode: '',
        originPlace: '',
        director: JSON.parse(sessionStorage.getItem('userName')),
        remarks: ''
      };
      this.dialogVisible = false;
    },
    handleClose: function handleClose() {
      this.detailData = {
        orderNo: '',
        factoryCode: '',
        shopCode: '',
        productLineCode: '',
        teamCode: '',
        storehouseCode: '',
        traychestScaleCode: '',
        planNum: '',
        miniUnit: '',
        batchNo: '',
        labelType: '',
        materialCode: '',
        originPlace: '',
        director: JSON.parse(sessionStorage.getItem('userName')),
        remarks: '',
        packScaleLevelItemDTOList: []
      };
      this.$refs.scanData.resetFields();
      this.isAudit = false;
      this.detailVisible = false;
    },
    scanDetail: function scanDetail(row) {
      var _this6 = this;
      (0, _store.quantityDetail)({
        orderNo: row.orderNo
      }).then(function (res) {
        if (res.data.code == 200) {
          _this6.detailData = Object.assign(JSON.parse(JSON.stringify(row)), res.data.data);
          _this6.detailVisible = true;
        } else {
          _this6.$message.error(res.data.msg);
        }
      });
    },
    DoAudit: function DoAudit(row) {
      var _this7 = this;
      (0, _store.quantityDetail)({
        orderNo: row.orderNo
      }).then(function (res) {
        if (res.data.code == 200) {
          _this7.detailData = Object.assign(JSON.parse(JSON.stringify(row)), res.data.data);
          _this7.isAudit = true;
          _this7.detailVisible = true;
        } else {
          _this7.$message.error(res.data.msg);
        }
      });
    },
    doUpdate: function doUpdate(row) {
      var _this8 = this;
      this.submitType = 2;
      this.dialogVisible = true;
      this.mloading = true;
      this.title = '编辑生产计划单';
      this.getFactoryList(1, '', 'factoryList');
      this.getBoxList();
      this.storeSELList = [];
      (0, _store.getOrderdetail)({
        orderNo: row.orderNo
      }).then(function (res) {
        if (res.data.code != 200) {
          _this8.$message.error(res.data.msg);
          return;
        } else {
          var data = res.data.data;
          _this8.scanData.pid = data.pid;
          _this8.scanData.orderNo = data.orderNo;
          _this8.scanData.factoryCode = data.factoryId;
          _this8.scanData.shopCode = data.shopId;
          _this8.scanData.productLineCode = data.productLineId;
          _this8.scanData.teamCode = data.teamId;
          _this8.scanData.storehouseCode = data.storehouseId;
          _this8.scanData.traychestScaleCode = data.traychestScaleId;
          _this8.scanData.planNum = data.planNum;
          _this8.scanData.miniUnit = data.miniUnit;
          _this8.scanData.batchNo = data.batchNo;
          _this8.scanData.labelType = data.labelType;
          _this8.scanData.remarks = data.remarks;
          _this8.scanData.materialCode = data.materialId;
          _this8.scanData.originPlace = data.originPlace;
          _this8.scanData.director = data.director;
          // this.scanData.uploadMode = data.uploadMode
          _this8.orderStatus = row.orderStatus;
          // this.scanFactoryList = this.storeList.filter(
          //   a => a.pId == data.storehouseId
          // )
          _this8.getFactoryList(2, data.factoryId, 'scanworkshopList');
          _this8.getFactoryList(3, data.shopId, 'scanlineList');
          _this8.getFactoryList(4, data.shopId, 'scanteamList');
          _this8.storeSELList = _this8.storeList.filter(function (number) {
            return number.factoryId == data.factoryId;
          });
          _this8.proList = [{
            productClassName: data.productClassName,
            productName: data.materialName,
            packScaleExpression: data.packScale,
            planNum: data.planNum,
            packScaleDesc: data.packScaleDesc,
            productCode: data.materialCode,
            productModel: data.productModel
          }];
        }
        _this8.mloading = false;
      }).catch(function () {
        _this8.mloading = false;
      });
    },
    codeDetail: function codeDetail(orderNo, materialId) {
      this.codeVisible = true;
      this.codeListLoading = true;
      this.detailForm.orderNo = orderNo;
      this.materialId = materialId;
      this.getCodeList();
    },
    getCodeList: function getCodeList() {
      var _this9 = this;
      // this.detailForm.orderNo = this.detailData.orderNo
      (0, _store.pageParentMarkCode)(this.detailForm).then(function (res) {
        if (res.data.code != 200) {
          _this9.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            if (res.data.data.records.length > 0) {
              res.data.data.records.forEach(function (item) {
                if (item.packLevel > 2) {
                  item.hasChildren = true;
                }
              });
            }
            _this9.codeList = res.data.data.records;
            _this9.codetotal = parseInt(res.data.data.total);
          } else {
            _this9.codeList = [];
            _this9.codetotal = 0;
          }
        }
        _this9.codeListLoading = false;
      }).catch(function () {
        _this9.codeListLoading = false;
      });
    },
    load: function load(tree, treeNode, resolve) {
      var _this10 = this;
      (0, _store.subMarkCode)({
        markCode: tree.markCode,
        materialId: this.materialId
      }).then(function (res) {
        if (res.data.code != 200) {
          _this10.$message.error(res.data.msg);
          resolve([]);
        } else {
          if (res.data.data.length > 0) {
            res.data.data.forEach(function (item) {
              if (item.packLevel > 2) {
                item.hasChildren = true;
              }
            });
          }
          resolve(res.data.data);
        }
      }).catch(function () {
        resolve([]);
      });
    },
    codeClose: function codeClose() {
      this.codeList = [];
      this.codetotal = 0;
      this.codeVisible = false;
    },
    showFailList: function showFailList() {
      this.failVisible = true;
      this.codeListLoading = true;
      this.failQuery.orderNo = this.detailData.orderNo;
      this.failQuery.materialId = this.detailData.materialId;
      this.getFailList();
    },
    getFailList: function getFailList() {
      var _this11 = this;
      (0, _store.backFailMarkCodeList)(this.failQuery).then(function (res) {
        if (res.data.code != 200) {
          _this11.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            _this11.failList = res.data.data.records;
            _this11.failtotal = parseInt(res.data.data.total);
            _this11.listLoading = false;
          } else {
            _this11.failList = [];
            _this11.failtotal = 0;
          }
        }
        _this11.codeListLoading = false;
      }).catch(function () {
        _this11.codeListLoading = false;
      });
    },
    failClose: function failClose() {
      this.failVisible = false;
      this.failQuery = {
        orderNo: '',
        current: 1,
        size: 10
      };
      this.failList = [];
      this.failtotal = 0;
    },
    del: function del(orderNo) {
      var _this12 = this;
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _store.orderdelete)({
          orderNo: orderNo
        }).then(function (res) {
          var data = res.data;
          if (data.code != 200) {
            _this12.$message.error(data.msg);
            return false;
          }
          _this12.$message({
            message: '删除成功',
            type: 'success'
          });
          _this12.getList();
        });
      }).catch(function () {});
    },
    // 导出功能
    download: function download() {
      var _this13 = this;
      this.downLoading = true;
      (0, _store.orderexportExcel)(this.searchForm).then(function (res) {
        if (res.status != 200) {
          _this13.$message.error(res.data.msg);
        } else {
          var blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel'
          });
          var downloadElement = document.createElement('a');
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = '生产计划单.xlsx';
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
        _this13.downLoading = false;
      }).catch(function () {
        _this13.downLoading = false;
      });
    },
    auditReset: function auditReset() {
      this.$refs.auditForm.resetFields();
      this.auditForm = {
        auditReasons: '',
        handlerResult: ''
      };
    },
    audit: function audit() {
      var _this14 = this;
      this.$refs['auditForm'].validate(function (valid) {
        if (valid) {
          _this14.btnLoading = true;
          if (_this14.auditForm.handlerResult) {
            (0, _store.reviewPass)({
              orderNo: _this14.detailData.orderNo,
              auditReasons: _this14.auditForm.auditReasons
            }).then(function (res) {
              if (res.data.code == 200) {
                _this14.$message({
                  type: 'success',
                  message: '审核完成'
                });
                _this14.auditReset();
                _this14.handleClose();
                _this14.getList();
              } else {
                _this14.$message.error(res.data.msg);
              }
              _this14.btnLoading = false;
            }).catch(function () {
              _this14.btnLoading = false;
            });
          } else {
            (0, _store.reviewNoPass)({
              orderNo: _this14.detailData.orderNo,
              auditReasons: _this14.auditForm.auditReasons
            }).then(function (res) {
              if (res.data.code == 200) {
                _this14.$message({
                  type: 'success',
                  message: '审核完成'
                });
                _this14.auditReset();
                _this14.handleClose();
                _this14.getList();
              } else {
                _this14.$message.error(res.data.msg);
              }
              _this14.btnLoading = false;
            }).catch(function () {
              _this14.btnLoading = false;
            });
          }
        } else {
          _this14.$message.error('请完善信息！');
        }
      });
    }
  }
};
exports.default = _default;