var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _c(
      "div",
      { staticClass: "page-section" },
      [
        _c(
          "div",
          { staticClass: "steps-container" },
          [
            _c(
              "el-steps",
              [
                _c("el-step", {
                  attrs: { title: "基础配置", status: "success" },
                }),
                _vm._v(" "),
                _c("el-step", {
                  attrs: {
                    title: "返利配置",
                    status: _vm.isExectued == 2 ? "success" : "wait",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-form",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.mloading,
                expression: "mloading",
              },
            ],
            ref: "submitData",
            staticClass: "reset-form-label-top",
            attrs: {
              rules: _vm.rules,
              model: _vm.submitData,
              "label-suffix": ":",
              "label-position": "left",
              "label-width": "0",
            },
          },
          [
            _vm.isExectued == 1
              ? _c("div", { staticClass: "form-step-1" }, [
                  _c("div", { staticClass: "head-title hasborder" }, [
                    _c("img", {
                      attrs: { src: require("../../../../images/icon-9.png") },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "title" }, [_vm._v("基本信息")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "basic-form" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 16 } },
                        [
                          _vm.subType == "edit"
                            ? _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "方案编号",
                                        prop: "schemeCode",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "请输入",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.submitData.schemeCode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.submitData,
                                              "schemeCode",
                                              $$v
                                            )
                                          },
                                          expression: "submitData.schemeCode",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "方案名称",
                                    prop: "schemeName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.submitData.schemeName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.submitData,
                                          "schemeName",
                                          $$v
                                        )
                                      },
                                      expression: "submitData.schemeName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "起始时间",
                                    prop: "dateTimeRange",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "datetimerange",
                                      placeholder: "选择日期",
                                      "range-separator": "至",
                                      "start-placeholder": "开始日期",
                                      "end-placeholder": "结束日期",
                                      format: "yyyy-MM-dd HH:mm:ss",
                                      "value-format": "yyyy-MM-dd HH:mm:ss",
                                      "default-time": ["00:00:00", "23:59:59"],
                                    },
                                    on: { change: _vm.getTime },
                                    model: {
                                      value: _vm.submitData.dateTimeRange,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.submitData,
                                          "dateTimeRange",
                                          $$v
                                        )
                                      },
                                      expression: "submitData.dateTimeRange",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.subType == "edit"
                            ? _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "is-required",
                                      attrs: { label: "状态" },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { disabled: "disabled" },
                                        model: {
                                          value: _vm.statusName,
                                          callback: function ($$v) {
                                            _vm.statusName = $$v
                                          },
                                          expression: "statusName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "方案描述" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      placeholder: "请输入",
                                      maxlength: "100",
                                      "show-word-limit": "",
                                      clearable: "",
                                      rows: 2,
                                    },
                                    model: {
                                      value: _vm.submitData.schemeDescribe,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.submitData,
                                          "schemeDescribe",
                                          $$v
                                        )
                                      },
                                      expression: "submitData.schemeDescribe",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "head-title hasborder" }, [
                    _c("img", {
                      attrs: { src: require("../../../../images/icon-10.png") },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "title" }, [_vm._v("关联政策")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "basic-form" },
                    [
                      _c(
                        "el-row",
                        {
                          staticStyle: { "margin-bottom": "0" },
                          attrs: { gutter: 16 },
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "关联政策",
                                    prop: "policyName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    ref: "zcbm",
                                    attrs: { readonly: "" },
                                    model: {
                                      value: _vm.submitData.policyName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.submitData,
                                          "policyName",
                                          $$v
                                        )
                                      },
                                      expression: "submitData.policyName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c("div", { staticStyle: { height: "28px" } }),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticStyle: { height: "38px" },
                                  attrs: {
                                    type: "primary",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.chosePolice },
                                },
                                [_vm._v("选择")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: { click: _vm.scanPolicy },
                                },
                                [_vm._v("查看政策")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "head-title hasborder" }, [
                    _c("img", {
                      attrs: { src: require("../../../../images/icon-6.png") },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "title" }, [_vm._v("参与产品")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "basic-form" },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { height: "38px" },
                          attrs: { type: "primary", icon: "el-icon-plus" },
                          on: { click: _vm.addProducts },
                        },
                        [_vm._v("添加产品")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-table",
                        {
                          staticClass: "detailTable",
                          staticStyle: { width: "100%", "margin-top": "10px" },
                          attrs: {
                            data: _vm.proList,
                            fit: "",
                            "max-height": "300",
                            "highlight-current-row": "",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "产品编码",
                              prop: "productCode",
                              align: "center",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "产品名称",
                              prop: "productName",
                              align: "center",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "产品分类",
                              prop: "productClass",
                              align: "center",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "买赠计量单位",
                              prop: "packScaleId",
                              align: "center",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c(
                                        "el-select",
                                        {
                                          model: {
                                            value: row.packScaleId,
                                            callback: function ($$v) {
                                              _vm.$set(row, "packScaleId", $$v)
                                            },
                                            expression: "row.packScaleId",
                                          },
                                        },
                                        _vm._l(
                                          row.packScaleLevelItemDTOList,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.pid,
                                              attrs: {
                                                label: item.unitName,
                                                value: item.pid,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3386745600
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              align: "center",
                              width: "80",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text", size: "mini" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.delPro(row)
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2033395591
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "btn-group-right" },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { height: "38px" },
                          attrs: { type: "default", plain: "" },
                          on: {
                            click: function ($event) {
                              return _vm.handleCloseStep("submitData")
                            },
                          },
                        },
                        [_vm._v("取消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticStyle: { height: "38px" },
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.checkStep1("submitData")
                            },
                          },
                        },
                        [_vm._v("下一步")]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.isExectued == 2
              ? _c("div", { staticClass: "form-step-2" }, [
                  _c("div", { staticClass: "head-title hasborder" }, [
                    _c("img", {
                      attrs: { src: require("../../../../images/icon-5.png") },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "title" }, [_vm._v("买赠范围")]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "basic-form" }, [
                    _c(
                      "div",
                      {
                        staticClass: "cell",
                        staticStyle: { "padding-bottom": "10px" },
                      },
                      [
                        _c("div", { staticClass: "cell-label required" }, [
                          _vm._v("\n\t\t\t\t\t\t\t范围\n\t\t\t\t\t\t"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "cell-primary" },
                          [
                            _c("treeselect", {
                              attrs: {
                                multiple: true,
                                limit: 6,
                                "flatten-search-results": true,
                                placeholder: "请选择...",
                                options: _vm.zzOptions,
                                clearAllText: "",
                              },
                              model: {
                                value: _vm.orgList,
                                callback: function ($$v) {
                                  _vm.orgList = $$v
                                },
                                expression: "orgList",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "head-title hasborder" }, [
                    _c("img", {
                      attrs: { src: require("../../../../images/icon-11.png") },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "title" }, [_vm._v("买赠配置")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "basic-form" },
                    [
                      _c("div", { staticClass: "cell" }, [
                        _c("div", { staticClass: "cell-label required" }, [
                          _vm._v("渠道选择："),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "cell-primary" },
                          [
                            _c("el-checkbox", {
                              attrs: { label: "一级经销商" },
                              on: {
                                change: function ($event) {
                                  return _vm.checkBox($event, 1)
                                },
                              },
                              model: {
                                value: _vm.checked1,
                                callback: function ($$v) {
                                  _vm.checked1 = $$v
                                },
                                expression: "checked1",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-checkbox", {
                              attrs: { label: "二级经销商" },
                              on: {
                                change: function ($event) {
                                  return _vm.checkBox($event, 2)
                                },
                              },
                              model: {
                                value: _vm.checked2,
                                callback: function ($$v) {
                                  _vm.checked2 = $$v
                                },
                                expression: "checked2",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-checkbox", {
                              attrs: { label: "终端" },
                              on: {
                                change: function ($event) {
                                  return _vm.checkBox($event, 3)
                                },
                              },
                              model: {
                                value: _vm.checked3,
                                callback: function ($$v) {
                                  _vm.checked3 = $$v
                                },
                                expression: "checked3",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.showLevelOne
                        ? _c(
                            "el-collapse",
                            {
                              staticStyle: {
                                border: "0",
                                "margin-bottom": "10px",
                              },
                              model: {
                                value: _vm.activeName1,
                                callback: function ($$v) {
                                  _vm.activeName1 = $$v
                                },
                                expression: "activeName1",
                              },
                            },
                            [
                              _c(
                                "el-collapse-item",
                                { attrs: { name: "1" } },
                                [
                                  _c("template", { slot: "title" }, [
                                    _c(
                                      "div",
                                      { staticClass: "cell-label-title" },
                                      [_vm._v("一级经销商")]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "el-row",
                                    { attrs: { type: "flex" } },
                                    [
                                      _c("el-col", { attrs: { span: 24 } }, [
                                        _c(
                                          "div",
                                          { staticClass: "config-bg" },
                                          [
                                            _c(
                                              "el-row",
                                              {
                                                staticStyle: {
                                                  "margin-bottom": "0",
                                                },
                                                attrs: { type: "flex" },
                                              },
                                              [
                                                _c(
                                                  "el-col",
                                                  {
                                                    staticStyle: {
                                                      width: "120px",
                                                      position: "relative",
                                                    },
                                                    attrs: { span: 4 },
                                                  },
                                                  [
                                                    _c(
                                                      "ul",
                                                      {
                                                        staticClass:
                                                          "chanelList",
                                                      },
                                                      _vm._l(
                                                        _vm.oneConfig,
                                                        function (item, index) {
                                                          return _c(
                                                            "li",
                                                            {
                                                              class: {
                                                                active:
                                                                  _vm.isActive1 ==
                                                                  index + 1,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.getCurList(
                                                                      index,
                                                                      "1"
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.name
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "pos-operation",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "cell",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "cell-primary align-left",
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "el-icon-plus",
                                                                  staticStyle: {
                                                                    color:
                                                                      "#969FAAFF",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.addItem(
                                                                          "levelOne"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "cell-primary align-right",
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "el-icon-minus",
                                                                  staticStyle: {
                                                                    color:
                                                                      "#969FAAFF",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.delItem(
                                                                          "levelOne"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-col",
                                                  {
                                                    staticStyle: {
                                                      "min-height": "272px",
                                                      "border-left":
                                                        "1px solid #EBECEEFF",
                                                    },
                                                    attrs: { span: 20 },
                                                  },
                                                  _vm._l(
                                                    _vm.levelOneData,
                                                    function (item, index) {
                                                      return _vm.isActive1 ==
                                                        index + 1
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "config-datas",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "cell-condition",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "cell",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "cell-label",
                                                                          staticStyle:
                                                                            {
                                                                              "margin-right":
                                                                                "20px",
                                                                              width:
                                                                                "80px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "范围选择："
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "cell-hd",
                                                                          staticStyle:
                                                                            {
                                                                              "margin-right":
                                                                                "15px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "el-select",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  width:
                                                                                    "150px",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  placeholder:
                                                                                    "渠道分类",
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    item.classId,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        item,
                                                                                        "classId",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "item.classId",
                                                                                },
                                                                            },
                                                                            _vm._l(
                                                                              _vm.chanelClassList,
                                                                              function (
                                                                                item
                                                                              ) {
                                                                                return _c(
                                                                                  "el-option",
                                                                                  {
                                                                                    key: item.pId,
                                                                                    attrs:
                                                                                      {
                                                                                        label:
                                                                                          item.className,
                                                                                        value:
                                                                                          item.pId,
                                                                                      },
                                                                                  }
                                                                                )
                                                                              }
                                                                            ),
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "cell-hd",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "el-select",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  width:
                                                                                    "150px",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  placeholder:
                                                                                    "渠道等级",
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    item.gradeId,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        item,
                                                                                        "gradeId",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "item.gradeId",
                                                                                },
                                                                            },
                                                                            _vm._l(
                                                                              _vm.chanelLeveList,
                                                                              function (
                                                                                item
                                                                              ) {
                                                                                return _c(
                                                                                  "el-option",
                                                                                  {
                                                                                    key: item.pId,
                                                                                    attrs:
                                                                                      {
                                                                                        label:
                                                                                          item.gradeName,
                                                                                        value:
                                                                                          item.pId,
                                                                                      },
                                                                                  }
                                                                                )
                                                                              }
                                                                            ),
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "cell-label",
                                                                  staticStyle: {
                                                                    width:
                                                                      "100px",
                                                                    "text-align":
                                                                      "left",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "基础买赠方案："
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "cell",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "cell-hd",
                                                                      staticStyle:
                                                                        {
                                                                          "margin-right":
                                                                            "20px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-input",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "170px",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              placeholder:
                                                                                "赠品选择",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                item.rebateProductName,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    item,
                                                                                    "rebateProductName",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "item.rebateProductName",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "el-button",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  slot: "append",
                                                                                  icon: "el-icon-search",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.getGiftProduct(
                                                                                      index,
                                                                                      "levelOne",
                                                                                      "basic"
                                                                                    )
                                                                                  },
                                                                              },
                                                                              slot: "append",
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "cell-hd",
                                                                      staticStyle:
                                                                        {
                                                                          "margin-right":
                                                                            "10px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-select",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "110px",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              placeholder:
                                                                                "赠品单位",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                item.giftUnit,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    item,
                                                                                    "giftUnit",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "item.giftUnit",
                                                                            },
                                                                        },
                                                                        _vm._l(
                                                                          item.unitList,
                                                                          function (
                                                                            data
                                                                          ) {
                                                                            return _c(
                                                                              "el-option",
                                                                              {
                                                                                key: data.pid,
                                                                                attrs:
                                                                                  {
                                                                                    label:
                                                                                      data.unitName,
                                                                                    value:
                                                                                      data.pid,
                                                                                  },
                                                                              }
                                                                            )
                                                                          }
                                                                        ),
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "cell-label",
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "40px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "每满"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    [
                                                                      _c(
                                                                        "el-input",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "Int",
                                                                                rawName:
                                                                                  "v-Int",
                                                                              },
                                                                            ],
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "60px",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                item.buyNum,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    item,
                                                                                    "buyNum",
                                                                                    _vm._n(
                                                                                      typeof $$v ===
                                                                                        "string"
                                                                                        ? $$v.trim()
                                                                                        : $$v
                                                                                    )
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "item.buyNum",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "cell-label",
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "20px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "赠"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    [
                                                                      _c(
                                                                        "el-input",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "Int",
                                                                                rawName:
                                                                                  "v-Int",
                                                                              },
                                                                            ],
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "60px",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                item.presentNum,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    item,
                                                                                    "presentNum",
                                                                                    _vm._n(
                                                                                      typeof $$v ===
                                                                                        "string"
                                                                                        ? $$v.trim()
                                                                                        : $$v
                                                                                    )
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "item.presentNum",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "cell-label",
                                                                  staticStyle: {
                                                                    width:
                                                                      "100px",
                                                                    "text-align":
                                                                      "left",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "满量赠品方案："
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _vm._l(
                                                                item.pzRebateSchemeStepParamList,
                                                                function (
                                                                  config,
                                                                  index
                                                                ) {
                                                                  return _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "cell",
                                                                      staticStyle:
                                                                        {
                                                                          "margin-bottom":
                                                                            "6px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "cell-hd",
                                                                          staticStyle:
                                                                            {
                                                                              "margin-right":
                                                                                "20px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "el-input",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  width:
                                                                                    "170px",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  placeholder:
                                                                                    "赠品选择",
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    config.giftProductName,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        config,
                                                                                        "giftProductName",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "config.giftProductName",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "el-button",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      slot: "append",
                                                                                      icon: "el-icon-search",
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.getGiftProduct(
                                                                                          index,
                                                                                          "levelOne",
                                                                                          "full"
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                  slot: "append",
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "cell-hd",
                                                                          staticStyle:
                                                                            {
                                                                              "margin-right":
                                                                                "10px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "el-select",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  width:
                                                                                    "110px",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  placeholder:
                                                                                    "赠品单位",
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    config.giftUnit,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        config,
                                                                                        "giftUnit",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "config.giftUnit",
                                                                                },
                                                                            },
                                                                            _vm._l(
                                                                              config.giftUnits,
                                                                              function (
                                                                                data
                                                                              ) {
                                                                                return _c(
                                                                                  "el-option",
                                                                                  {
                                                                                    key: data.pid,
                                                                                    attrs:
                                                                                      {
                                                                                        label:
                                                                                          data.unitName,
                                                                                        value:
                                                                                          data.pid,
                                                                                      },
                                                                                  }
                                                                                )
                                                                              }
                                                                            ),
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "cell-label",
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "40px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "满"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "el-input",
                                                                            {
                                                                              directives:
                                                                                [
                                                                                  {
                                                                                    name: "Int",
                                                                                    rawName:
                                                                                      "v-Int",
                                                                                  },
                                                                                ],
                                                                              staticStyle:
                                                                                {
                                                                                  width:
                                                                                    "60px",
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    config.productNumStep,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        config,
                                                                                        "productNumStep",
                                                                                        _vm._n(
                                                                                          typeof $$v ===
                                                                                            "string"
                                                                                            ? $$v.trim()
                                                                                            : $$v
                                                                                        )
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "config.productNumStep",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "cell-label",
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "20px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "赠"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "el-input",
                                                                            {
                                                                              directives:
                                                                                [
                                                                                  {
                                                                                    name: "Int",
                                                                                    rawName:
                                                                                      "v-Int",
                                                                                  },
                                                                                ],
                                                                              staticStyle:
                                                                                {
                                                                                  width:
                                                                                    "60px",
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    config.giftNum,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        config,
                                                                                        "giftNum",
                                                                                        _vm._n(
                                                                                          typeof $$v ===
                                                                                            "string"
                                                                                            ? $$v.trim()
                                                                                            : $$v
                                                                                        )
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "config.giftNum",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      index ==
                                                                      item
                                                                        .pzRebateSchemeStepParamList
                                                                        .length -
                                                                        1
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  "margin-left":
                                                                                    "15px",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "el-button",
                                                                                {
                                                                                  staticStyle:
                                                                                    {
                                                                                      height:
                                                                                        "36px",
                                                                                      "font-size":
                                                                                        "14px",
                                                                                    },
                                                                                  attrs:
                                                                                    {
                                                                                      type: "primary",
                                                                                      icon: "el-icon-plus",
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.addConfig(
                                                                                          "levelOne",
                                                                                          config,
                                                                                          index
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t添加\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      item
                                                                        .pzRebateSchemeStepParamList
                                                                        .length >
                                                                      1
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  "margin-left":
                                                                                    "15px",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "el-button",
                                                                                {
                                                                                  staticStyle:
                                                                                    {
                                                                                      height:
                                                                                        "36px",
                                                                                      "font-size":
                                                                                        "14px",
                                                                                    },
                                                                                  attrs:
                                                                                    {
                                                                                      type: "primary",
                                                                                      icon: "el-icon-delete",
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.delConfig(
                                                                                          "levelOne",
                                                                                          index
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t删除\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                            ],
                                                            2
                                                          )
                                                        : _vm._e()
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      false
                        ? _c(
                            "el-row",
                            { attrs: { type: "flex" } },
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "cell-label",
                                  staticStyle: { "padding-top": "15px" },
                                  attrs: { span: 2 },
                                },
                                [_vm._v("一级经销商")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  staticStyle: { width: "860px" },
                                  attrs: { span: 22 },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "config-bg" },
                                    [
                                      _c(
                                        "el-row",
                                        {
                                          staticStyle: { "margin-bottom": "0" },
                                          attrs: { type: "flex" },
                                        },
                                        [
                                          _c(
                                            "el-col",
                                            {
                                              staticStyle: {
                                                width: "120px",
                                                position: "relative",
                                              },
                                              attrs: { span: 4 },
                                            },
                                            [
                                              _c(
                                                "ul",
                                                { staticClass: "chanelList" },
                                                _vm._l(
                                                  _vm.oneConfig,
                                                  function (item) {
                                                    return _c(
                                                      "li",
                                                      {
                                                        class: {
                                                          active:
                                                            _vm.isActive1 ==
                                                            item.id,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.getCurList(
                                                              item.id,
                                                              "1"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.name)
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "pos-operation",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "cell" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "cell-primary align-left",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-plus",
                                                            staticStyle: {
                                                              color:
                                                                "#969FAAFF",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.addItem(
                                                                  "levelOne"
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "cell-primary align-right",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-minus",
                                                            staticStyle: {
                                                              color:
                                                                "#969FAAFF",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.delItem(
                                                                  "levelOne"
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-col",
                                            {
                                              staticStyle: {
                                                "min-height": "272px",
                                                "border-left":
                                                  "1px solid #EBECEEFF",
                                              },
                                              attrs: { span: 20 },
                                            },
                                            _vm._l(
                                              _vm.levelOneData,
                                              function (item, index) {
                                                return _vm.isActive1 ==
                                                  index + 1
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "config-datas",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "cell-condition",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "cell",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "cell-label",
                                                                    staticStyle:
                                                                      {
                                                                        "margin-right":
                                                                          "20px",
                                                                        width:
                                                                          "80px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "范围选择："
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "cell-hd",
                                                                    staticStyle:
                                                                      {
                                                                        "margin-right":
                                                                          "15px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-select",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            width:
                                                                              "150px",
                                                                          },
                                                                        attrs: {
                                                                          placeholder:
                                                                            "渠道分类",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            item.classId,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                item,
                                                                                "classId",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "item.classId",
                                                                        },
                                                                      },
                                                                      _vm._l(
                                                                        _vm.chanelClassList,
                                                                        function (
                                                                          item
                                                                        ) {
                                                                          return _c(
                                                                            "el-option",
                                                                            {
                                                                              key: item.pId,
                                                                              attrs:
                                                                                {
                                                                                  label:
                                                                                    item.className,
                                                                                  value:
                                                                                    item.pId,
                                                                                },
                                                                            }
                                                                          )
                                                                        }
                                                                      ),
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "cell-hd",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-select",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            width:
                                                                              "150px",
                                                                          },
                                                                        attrs: {
                                                                          placeholder:
                                                                            "渠道等级",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            item.gradeId,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                item,
                                                                                "gradeId",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "item.gradeId",
                                                                        },
                                                                      },
                                                                      _vm._l(
                                                                        _vm.chanelLeveList,
                                                                        function (
                                                                          item
                                                                        ) {
                                                                          return _c(
                                                                            "el-option",
                                                                            {
                                                                              key: item.pId,
                                                                              attrs:
                                                                                {
                                                                                  label:
                                                                                    item.gradeName,
                                                                                  value:
                                                                                    item.pId,
                                                                                },
                                                                            }
                                                                          )
                                                                        }
                                                                      ),
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "cell-label",
                                                            staticStyle: {
                                                              width: "100px",
                                                              "text-align":
                                                                "left",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "基础买赠方案："
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "cell",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "cell-hd",
                                                                staticStyle: {
                                                                  "margin-right":
                                                                    "20px",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-input",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "170px",
                                                                      },
                                                                    attrs: {
                                                                      placeholder:
                                                                        "赠品选择",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        item.rebateProductName,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            item,
                                                                            "rebateProductName",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "item.rebateProductName",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-button",
                                                                      {
                                                                        attrs: {
                                                                          slot: "append",
                                                                          icon: "el-icon-search",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.getGiftProduct(
                                                                                index,
                                                                                "levelOne",
                                                                                "basic"
                                                                              )
                                                                            },
                                                                        },
                                                                        slot: "append",
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "cell-hd",
                                                                staticStyle: {
                                                                  "margin-right":
                                                                    "10px",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-select",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "110px",
                                                                      },
                                                                    attrs: {
                                                                      placeholder:
                                                                        "赠品单位",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        item.giftUnit,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            item,
                                                                            "giftUnit",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "item.giftUnit",
                                                                    },
                                                                  },
                                                                  _vm._l(
                                                                    item.unitList,
                                                                    function (
                                                                      data
                                                                    ) {
                                                                      return _c(
                                                                        "el-option",
                                                                        {
                                                                          key: data.pid,
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                data.unitName,
                                                                              value:
                                                                                data.pid,
                                                                            },
                                                                        }
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "cell-label",
                                                                staticStyle: {
                                                                  width: "40px",
                                                                },
                                                              },
                                                              [_vm._v("每满")]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              [
                                                                _c("el-input", {
                                                                  directives: [
                                                                    {
                                                                      name: "Int",
                                                                      rawName:
                                                                        "v-Int",
                                                                    },
                                                                  ],
                                                                  staticStyle: {
                                                                    width:
                                                                      "60px",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      item.buyNum,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          item,
                                                                          "buyNum",
                                                                          _vm._n(
                                                                            typeof $$v ===
                                                                              "string"
                                                                              ? $$v.trim()
                                                                              : $$v
                                                                          )
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "item.buyNum",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "cell-label",
                                                                staticStyle: {
                                                                  width: "20px",
                                                                },
                                                              },
                                                              [_vm._v("赠")]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              [
                                                                _c("el-input", {
                                                                  directives: [
                                                                    {
                                                                      name: "Int",
                                                                      rawName:
                                                                        "v-Int",
                                                                    },
                                                                  ],
                                                                  staticStyle: {
                                                                    width:
                                                                      "60px",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      item.presentNum,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          item,
                                                                          "presentNum",
                                                                          _vm._n(
                                                                            typeof $$v ===
                                                                              "string"
                                                                              ? $$v.trim()
                                                                              : $$v
                                                                          )
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "item.presentNum",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "cell-label",
                                                            staticStyle: {
                                                              width: "100px",
                                                              "text-align":
                                                                "left",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "满量赠品方案："
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _vm._l(
                                                          item.pzRebateSchemeStepParamList,
                                                          function (
                                                            config,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "cell",
                                                                staticStyle: {
                                                                  "margin-bottom":
                                                                    "6px",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "cell-hd",
                                                                    staticStyle:
                                                                      {
                                                                        "margin-right":
                                                                          "20px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-input",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            width:
                                                                              "170px",
                                                                          },
                                                                        attrs: {
                                                                          placeholder:
                                                                            "赠品选择",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            config.giftProductName,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                config,
                                                                                "giftProductName",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "config.giftProductName",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "el-button",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                slot: "append",
                                                                                icon: "el-icon-search",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.getGiftProduct(
                                                                                    index,
                                                                                    "levelOne",
                                                                                    "full"
                                                                                  )
                                                                                },
                                                                            },
                                                                            slot: "append",
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "cell-hd",
                                                                    staticStyle:
                                                                      {
                                                                        "margin-right":
                                                                          "10px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-select",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            width:
                                                                              "110px",
                                                                          },
                                                                        attrs: {
                                                                          placeholder:
                                                                            "赠品单位",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            config.giftUnit,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                config,
                                                                                "giftUnit",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "config.giftUnit",
                                                                        },
                                                                      },
                                                                      _vm._l(
                                                                        config.giftUnits,
                                                                        function (
                                                                          data
                                                                        ) {
                                                                          return _c(
                                                                            "el-option",
                                                                            {
                                                                              key: data.pid,
                                                                              attrs:
                                                                                {
                                                                                  label:
                                                                                    data.unitName,
                                                                                  value:
                                                                                    data.pid,
                                                                                },
                                                                            }
                                                                          )
                                                                        }
                                                                      ),
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "cell-label",
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "40px",
                                                                      },
                                                                  },
                                                                  [_vm._v("满")]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "el-input",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "Int",
                                                                              rawName:
                                                                                "v-Int",
                                                                            },
                                                                          ],
                                                                        staticStyle:
                                                                          {
                                                                            width:
                                                                              "60px",
                                                                          },
                                                                        model: {
                                                                          value:
                                                                            config.productNumStep,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                config,
                                                                                "productNumStep",
                                                                                _vm._n(
                                                                                  typeof $$v ===
                                                                                    "string"
                                                                                    ? $$v.trim()
                                                                                    : $$v
                                                                                )
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "config.productNumStep",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "cell-label",
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "20px",
                                                                      },
                                                                  },
                                                                  [_vm._v("赠")]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "el-input",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "Int",
                                                                              rawName:
                                                                                "v-Int",
                                                                            },
                                                                          ],
                                                                        staticStyle:
                                                                          {
                                                                            width:
                                                                              "60px",
                                                                          },
                                                                        model: {
                                                                          value:
                                                                            config.giftNum,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                config,
                                                                                "giftNum",
                                                                                _vm._n(
                                                                                  typeof $$v ===
                                                                                    "string"
                                                                                    ? $$v.trim()
                                                                                    : $$v
                                                                                )
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "config.giftNum",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                index ==
                                                                item
                                                                  .pzRebateSchemeStepParamList
                                                                  .length -
                                                                  1
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "margin-left":
                                                                              "15px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "el-button",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                height:
                                                                                  "36px",
                                                                                "font-size":
                                                                                  "14px",
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                type: "primary",
                                                                                icon: "el-icon-plus",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.addConfig(
                                                                                    "levelOne",
                                                                                    config,
                                                                                    index
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t添加\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                                _vm._v(" "),
                                                                item
                                                                  .pzRebateSchemeStepParamList
                                                                  .length > 1
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "margin-left":
                                                                              "15px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "el-button",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                height:
                                                                                  "36px",
                                                                                "font-size":
                                                                                  "14px",
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                type: "primary",
                                                                                icon: "el-icon-delete",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.delConfig(
                                                                                    "levelOne",
                                                                                    index
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t删除\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                      ],
                                                      2
                                                    )
                                                  : _vm._e()
                                              }
                                            ),
                                            0
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showLevelTwo
                        ? _c(
                            "el-collapse",
                            {
                              staticStyle: {
                                border: "0",
                                "margin-bottom": "10px",
                              },
                              model: {
                                value: _vm.activeName2,
                                callback: function ($$v) {
                                  _vm.activeName2 = $$v
                                },
                                expression: "activeName2",
                              },
                            },
                            [
                              _c(
                                "el-collapse-item",
                                { attrs: { name: "2" } },
                                [
                                  _c("template", { slot: "title" }, [
                                    _c("div", { staticClass: "cell-label" }, [
                                      _vm._v("二级经销商"),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "el-row",
                                    { attrs: { type: "flex" } },
                                    [
                                      _c("el-col", { attrs: { span: 24 } }, [
                                        _c(
                                          "div",
                                          { staticClass: "config-bg" },
                                          [
                                            _c(
                                              "el-row",
                                              {
                                                staticStyle: {
                                                  "margin-bottom": "0",
                                                },
                                                attrs: { type: "flex" },
                                              },
                                              [
                                                _c(
                                                  "el-col",
                                                  {
                                                    staticStyle: {
                                                      width: "120px",
                                                      position: "relative",
                                                    },
                                                    attrs: { span: 4 },
                                                  },
                                                  [
                                                    _c(
                                                      "ul",
                                                      {
                                                        staticClass:
                                                          "chanelList",
                                                      },
                                                      _vm._l(
                                                        _vm.toConfig,
                                                        function (item, index) {
                                                          return _c(
                                                            "li",
                                                            {
                                                              class: {
                                                                active:
                                                                  _vm.isActive2 ==
                                                                  index + 1,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.getCurList(
                                                                      index,
                                                                      "2"
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.name
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "pos-operation",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "cell",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "cell-primary align-left",
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "el-icon-plus",
                                                                  staticStyle: {
                                                                    color:
                                                                      "#969FAAFF",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.addItem(
                                                                          "levelTo"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "cell-primary align-right",
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "el-icon-minus",
                                                                  staticStyle: {
                                                                    color:
                                                                      "#969FAAFF",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.delItem(
                                                                          "levelTo"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-col",
                                                  {
                                                    staticStyle: {
                                                      "min-height": "272px",
                                                      "border-left":
                                                        "1px solid #EBECEEFF",
                                                    },
                                                    attrs: { span: 20 },
                                                  },
                                                  _vm._l(
                                                    _vm.levelTwoData,
                                                    function (item, index) {
                                                      return _vm.isActive2 ==
                                                        index + 1
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "config-datas",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "cell-condition",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "cell",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "cell-label",
                                                                          staticStyle:
                                                                            {
                                                                              "margin-right":
                                                                                "20px",
                                                                              width:
                                                                                "80px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "范围选择："
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "cell-hd",
                                                                          staticStyle:
                                                                            {
                                                                              "margin-right":
                                                                                "15px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "el-select",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  width:
                                                                                    "150px",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  placeholder:
                                                                                    "渠道分类",
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    item.classId,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        item,
                                                                                        "classId",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "item.classId",
                                                                                },
                                                                            },
                                                                            _vm._l(
                                                                              _vm.chanelClassList,
                                                                              function (
                                                                                item
                                                                              ) {
                                                                                return _c(
                                                                                  "el-option",
                                                                                  {
                                                                                    key: item.pId,
                                                                                    attrs:
                                                                                      {
                                                                                        label:
                                                                                          item.className,
                                                                                        value:
                                                                                          item.pId,
                                                                                      },
                                                                                  }
                                                                                )
                                                                              }
                                                                            ),
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "cell-hd",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "el-select",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  width:
                                                                                    "150px",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  placeholder:
                                                                                    "渠道等级",
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    item.gradeId,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        item,
                                                                                        "gradeId",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "item.gradeId",
                                                                                },
                                                                            },
                                                                            _vm._l(
                                                                              _vm.chanelLeveList,
                                                                              function (
                                                                                item
                                                                              ) {
                                                                                return _c(
                                                                                  "el-option",
                                                                                  {
                                                                                    key: item.pId,
                                                                                    attrs:
                                                                                      {
                                                                                        label:
                                                                                          item.gradeName,
                                                                                        value:
                                                                                          item.pId,
                                                                                      },
                                                                                  }
                                                                                )
                                                                              }
                                                                            ),
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "cell-label",
                                                                  staticStyle: {
                                                                    width:
                                                                      "100px",
                                                                    "text-align":
                                                                      "left",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "基础买赠方案："
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "cell",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "cell-hd",
                                                                      staticStyle:
                                                                        {
                                                                          "margin-right":
                                                                            "20px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-input",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "170px",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              placeholder:
                                                                                "赠品选择",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                item.rebateProductName,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    item,
                                                                                    "rebateProductName",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "item.rebateProductName",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "el-button",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  slot: "append",
                                                                                  icon: "el-icon-search",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.getGiftProduct(
                                                                                      index,
                                                                                      "levelTo",
                                                                                      "basic"
                                                                                    )
                                                                                  },
                                                                              },
                                                                              slot: "append",
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "cell-hd",
                                                                      staticStyle:
                                                                        {
                                                                          "margin-right":
                                                                            "10px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-select",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "110px",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              placeholder:
                                                                                "赠品单位",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                item.giftUnit,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    item,
                                                                                    "giftUnit",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "item.giftUnit",
                                                                            },
                                                                        },
                                                                        _vm._l(
                                                                          item.unitList,
                                                                          function (
                                                                            data
                                                                          ) {
                                                                            return _c(
                                                                              "el-option",
                                                                              {
                                                                                key: data.pid,
                                                                                attrs:
                                                                                  {
                                                                                    label:
                                                                                      data.unitName,
                                                                                    value:
                                                                                      data.pid,
                                                                                  },
                                                                              }
                                                                            )
                                                                          }
                                                                        ),
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "cell-label",
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "40px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "每满"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    [
                                                                      _c(
                                                                        "el-input",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "Int",
                                                                                rawName:
                                                                                  "v-Int",
                                                                              },
                                                                            ],
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "60px",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                item.buyNum,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    item,
                                                                                    "buyNum",
                                                                                    _vm._n(
                                                                                      typeof $$v ===
                                                                                        "string"
                                                                                        ? $$v.trim()
                                                                                        : $$v
                                                                                    )
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "item.buyNum",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "cell-label",
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "20px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "赠"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    [
                                                                      _c(
                                                                        "el-input",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "Int",
                                                                                rawName:
                                                                                  "v-Int",
                                                                              },
                                                                            ],
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "60px",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                item.presentNum,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    item,
                                                                                    "presentNum",
                                                                                    _vm._n(
                                                                                      typeof $$v ===
                                                                                        "string"
                                                                                        ? $$v.trim()
                                                                                        : $$v
                                                                                    )
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "item.presentNum",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "cell-label",
                                                                  staticStyle: {
                                                                    width:
                                                                      "100px",
                                                                    "text-align":
                                                                      "left",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "满量赠品方案："
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _vm._l(
                                                                item.pzRebateSchemeStepParamList,
                                                                function (
                                                                  config,
                                                                  index
                                                                ) {
                                                                  return _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "cell",
                                                                      staticStyle:
                                                                        {
                                                                          "margin-bottom":
                                                                            "6px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "cell-hd",
                                                                          staticStyle:
                                                                            {
                                                                              "margin-right":
                                                                                "20px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "el-input",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  width:
                                                                                    "170px",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  placeholder:
                                                                                    "赠品选择",
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    config.giftProductName,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        config,
                                                                                        "giftProductName",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "config.giftProductName",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "el-button",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      slot: "append",
                                                                                      icon: "el-icon-search",
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.getGiftProduct(
                                                                                          index,
                                                                                          "levelTo",
                                                                                          "full"
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                  slot: "append",
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "cell-hd",
                                                                          staticStyle:
                                                                            {
                                                                              "margin-right":
                                                                                "10px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "el-select",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  width:
                                                                                    "110px",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  placeholder:
                                                                                    "赠品单位",
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    config.giftUnit,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        config,
                                                                                        "giftUnit",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "config.giftUnit",
                                                                                },
                                                                            },
                                                                            _vm._l(
                                                                              config.giftUnits,
                                                                              function (
                                                                                data
                                                                              ) {
                                                                                return _c(
                                                                                  "el-option",
                                                                                  {
                                                                                    key: data.pid,
                                                                                    attrs:
                                                                                      {
                                                                                        label:
                                                                                          data.unitName,
                                                                                        value:
                                                                                          data.pid,
                                                                                      },
                                                                                  }
                                                                                )
                                                                              }
                                                                            ),
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "cell-label",
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "40px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "满"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "el-input",
                                                                            {
                                                                              directives:
                                                                                [
                                                                                  {
                                                                                    name: "Int",
                                                                                    rawName:
                                                                                      "v-Int",
                                                                                  },
                                                                                ],
                                                                              staticStyle:
                                                                                {
                                                                                  width:
                                                                                    "60px",
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    config.productNumStep,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        config,
                                                                                        "productNumStep",
                                                                                        _vm._n(
                                                                                          typeof $$v ===
                                                                                            "string"
                                                                                            ? $$v.trim()
                                                                                            : $$v
                                                                                        )
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "config.productNumStep",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "cell-label",
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "20px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "赠"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "el-input",
                                                                            {
                                                                              directives:
                                                                                [
                                                                                  {
                                                                                    name: "Int",
                                                                                    rawName:
                                                                                      "v-Int",
                                                                                  },
                                                                                ],
                                                                              staticStyle:
                                                                                {
                                                                                  width:
                                                                                    "60px",
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    config.giftNum,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        config,
                                                                                        "giftNum",
                                                                                        _vm._n(
                                                                                          typeof $$v ===
                                                                                            "string"
                                                                                            ? $$v.trim()
                                                                                            : $$v
                                                                                        )
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "config.giftNum",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      index ==
                                                                      item
                                                                        .pzRebateSchemeStepParamList
                                                                        .length -
                                                                        1
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  "margin-left":
                                                                                    "15px",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "el-button",
                                                                                {
                                                                                  staticStyle:
                                                                                    {
                                                                                      height:
                                                                                        "36px",
                                                                                      "font-size":
                                                                                        "14px",
                                                                                    },
                                                                                  attrs:
                                                                                    {
                                                                                      type: "primary",
                                                                                      icon: "el-icon-plus",
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.addConfig(
                                                                                          "levelTo",
                                                                                          config,
                                                                                          index
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n\t\t\t\t\t\t   \t\t\t\t\t\t\t\t\t添加\n\t\t\t\t\t\t   \t\t\t\t\t\t\t\t"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      item
                                                                        .pzRebateSchemeStepParamList
                                                                        .length >
                                                                      1
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  "margin-left":
                                                                                    "15px",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "el-button",
                                                                                {
                                                                                  staticStyle:
                                                                                    {
                                                                                      height:
                                                                                        "36px",
                                                                                      "font-size":
                                                                                        "14px",
                                                                                    },
                                                                                  attrs:
                                                                                    {
                                                                                      type: "primary",
                                                                                      icon: "el-icon-delete",
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.delConfig(
                                                                                          "levelTo",
                                                                                          index
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n\t\t\t\t\t\t   \t\t\t\t\t\t\t\t\t删除\n\t\t\t\t\t\t   \t\t\t\t\t\t\t\t"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                            ],
                                                            2
                                                          )
                                                        : _vm._e()
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      false
                        ? _c(
                            "el-row",
                            { attrs: { type: "flex" } },
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "cell-label",
                                  staticStyle: { "padding-top": "15px" },
                                  attrs: { span: 2 },
                                },
                                [_vm._v("二级经销商")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  staticStyle: { width: "860px" },
                                  attrs: { span: 22 },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "config-bg" },
                                    [
                                      _c(
                                        "el-row",
                                        {
                                          staticStyle: { "margin-bottom": "0" },
                                          attrs: { type: "flex" },
                                        },
                                        [
                                          _c(
                                            "el-col",
                                            {
                                              staticStyle: {
                                                width: "120px",
                                                position: "relative",
                                              },
                                              attrs: { span: 4 },
                                            },
                                            [
                                              _c(
                                                "ul",
                                                { staticClass: "chanelList" },
                                                _vm._l(
                                                  _vm.toConfig,
                                                  function (item, index) {
                                                    return _c(
                                                      "li",
                                                      {
                                                        class: {
                                                          active:
                                                            _vm.isActive2 ==
                                                            index + 1,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.getCurList(
                                                              item.id,
                                                              "2"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.name)
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "pos-operation",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "cell" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "cell-primary align-left",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-plus",
                                                            staticStyle: {
                                                              color:
                                                                "#969FAAFF",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.addItem(
                                                                  "levelTo"
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "cell-primary align-right",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-minus",
                                                            staticStyle: {
                                                              color:
                                                                "#969FAAFF",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.delItem(
                                                                  "levelTo"
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-col",
                                            {
                                              staticStyle: {
                                                "min-height": "272px",
                                                "border-left":
                                                  "1px solid #EBECEEFF",
                                              },
                                              attrs: { span: 20 },
                                            },
                                            _vm._l(
                                              _vm.levelTwoData,
                                              function (item, index) {
                                                return _vm.isActive2 ==
                                                  index + 1
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "config-datas",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "cell-condition",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "cell",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "cell-label",
                                                                    staticStyle:
                                                                      {
                                                                        "margin-right":
                                                                          "20px",
                                                                        width:
                                                                          "80px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "范围选择："
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "cell-hd",
                                                                    staticStyle:
                                                                      {
                                                                        "margin-right":
                                                                          "15px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-select",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            width:
                                                                              "150px",
                                                                          },
                                                                        attrs: {
                                                                          placeholder:
                                                                            "渠道分类",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            item.classId,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                item,
                                                                                "classId",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "item.classId",
                                                                        },
                                                                      },
                                                                      _vm._l(
                                                                        _vm.chanelClassList,
                                                                        function (
                                                                          item
                                                                        ) {
                                                                          return _c(
                                                                            "el-option",
                                                                            {
                                                                              key: item.pId,
                                                                              attrs:
                                                                                {
                                                                                  label:
                                                                                    item.className,
                                                                                  value:
                                                                                    item.pId,
                                                                                },
                                                                            }
                                                                          )
                                                                        }
                                                                      ),
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "cell-hd",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-select",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            width:
                                                                              "150px",
                                                                          },
                                                                        attrs: {
                                                                          placeholder:
                                                                            "渠道等级",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            item.gradeId,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                item,
                                                                                "gradeId",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "item.gradeId",
                                                                        },
                                                                      },
                                                                      _vm._l(
                                                                        _vm.chanelLeveList,
                                                                        function (
                                                                          item
                                                                        ) {
                                                                          return _c(
                                                                            "el-option",
                                                                            {
                                                                              key: item.pId,
                                                                              attrs:
                                                                                {
                                                                                  label:
                                                                                    item.gradeName,
                                                                                  value:
                                                                                    item.pId,
                                                                                },
                                                                            }
                                                                          )
                                                                        }
                                                                      ),
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "cell-label",
                                                            staticStyle: {
                                                              width: "100px",
                                                              "text-align":
                                                                "left",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "基础买赠方案："
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "cell",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "cell-hd",
                                                                staticStyle: {
                                                                  "margin-right":
                                                                    "20px",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-input",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "170px",
                                                                      },
                                                                    attrs: {
                                                                      placeholder:
                                                                        "赠品选择",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        item.rebateProductName,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            item,
                                                                            "rebateProductName",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "item.rebateProductName",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-button",
                                                                      {
                                                                        attrs: {
                                                                          slot: "append",
                                                                          icon: "el-icon-search",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.getGiftProduct(
                                                                                index,
                                                                                "levelTo",
                                                                                "basic"
                                                                              )
                                                                            },
                                                                        },
                                                                        slot: "append",
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "cell-hd",
                                                                staticStyle: {
                                                                  "margin-right":
                                                                    "10px",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-select",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "110px",
                                                                      },
                                                                    attrs: {
                                                                      placeholder:
                                                                        "赠品单位",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        item.giftUnit,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            item,
                                                                            "giftUnit",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "item.giftUnit",
                                                                    },
                                                                  },
                                                                  _vm._l(
                                                                    item.unitList,
                                                                    function (
                                                                      data
                                                                    ) {
                                                                      return _c(
                                                                        "el-option",
                                                                        {
                                                                          key: data.pid,
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                data.unitName,
                                                                              value:
                                                                                data.pid,
                                                                            },
                                                                        }
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "cell-label",
                                                                staticStyle: {
                                                                  width: "40px",
                                                                },
                                                              },
                                                              [_vm._v("每满")]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              [
                                                                _c("el-input", {
                                                                  directives: [
                                                                    {
                                                                      name: "Int",
                                                                      rawName:
                                                                        "v-Int",
                                                                    },
                                                                  ],
                                                                  staticStyle: {
                                                                    width:
                                                                      "60px",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      item.buyNum,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          item,
                                                                          "buyNum",
                                                                          _vm._n(
                                                                            typeof $$v ===
                                                                              "string"
                                                                              ? $$v.trim()
                                                                              : $$v
                                                                          )
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "item.buyNum",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "cell-label",
                                                                staticStyle: {
                                                                  width: "20px",
                                                                },
                                                              },
                                                              [_vm._v("赠")]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              [
                                                                _c("el-input", {
                                                                  directives: [
                                                                    {
                                                                      name: "Int",
                                                                      rawName:
                                                                        "v-Int",
                                                                    },
                                                                  ],
                                                                  staticStyle: {
                                                                    width:
                                                                      "60px",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      item.presentNum,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          item,
                                                                          "presentNum",
                                                                          _vm._n(
                                                                            typeof $$v ===
                                                                              "string"
                                                                              ? $$v.trim()
                                                                              : $$v
                                                                          )
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "item.presentNum",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "cell-label",
                                                            staticStyle: {
                                                              width: "100px",
                                                              "text-align":
                                                                "left",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "满量赠品方案："
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _vm._l(
                                                          item.pzRebateSchemeStepParamList,
                                                          function (
                                                            config,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "cell",
                                                                staticStyle: {
                                                                  "margin-bottom":
                                                                    "6px",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "cell-hd",
                                                                    staticStyle:
                                                                      {
                                                                        "margin-right":
                                                                          "20px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-input",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            width:
                                                                              "170px",
                                                                          },
                                                                        attrs: {
                                                                          placeholder:
                                                                            "赠品选择",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            config.giftProductName,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                config,
                                                                                "giftProductName",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "config.giftProductName",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "el-button",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                slot: "append",
                                                                                icon: "el-icon-search",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.getGiftProduct(
                                                                                    index,
                                                                                    "levelTo",
                                                                                    "full"
                                                                                  )
                                                                                },
                                                                            },
                                                                            slot: "append",
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "cell-hd",
                                                                    staticStyle:
                                                                      {
                                                                        "margin-right":
                                                                          "10px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-select",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            width:
                                                                              "110px",
                                                                          },
                                                                        attrs: {
                                                                          placeholder:
                                                                            "赠品单位",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            config.giftUnit,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                config,
                                                                                "giftUnit",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "config.giftUnit",
                                                                        },
                                                                      },
                                                                      _vm._l(
                                                                        config.giftUnits,
                                                                        function (
                                                                          data
                                                                        ) {
                                                                          return _c(
                                                                            "el-option",
                                                                            {
                                                                              key: data.pid,
                                                                              attrs:
                                                                                {
                                                                                  label:
                                                                                    data.unitName,
                                                                                  value:
                                                                                    data.pid,
                                                                                },
                                                                            }
                                                                          )
                                                                        }
                                                                      ),
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "cell-label",
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "40px",
                                                                      },
                                                                  },
                                                                  [_vm._v("满")]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "el-input",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "Int",
                                                                              rawName:
                                                                                "v-Int",
                                                                            },
                                                                          ],
                                                                        staticStyle:
                                                                          {
                                                                            width:
                                                                              "60px",
                                                                          },
                                                                        model: {
                                                                          value:
                                                                            config.productNumStep,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                config,
                                                                                "productNumStep",
                                                                                _vm._n(
                                                                                  typeof $$v ===
                                                                                    "string"
                                                                                    ? $$v.trim()
                                                                                    : $$v
                                                                                )
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "config.productNumStep",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "cell-label",
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "20px",
                                                                      },
                                                                  },
                                                                  [_vm._v("赠")]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "el-input",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "Int",
                                                                              rawName:
                                                                                "v-Int",
                                                                            },
                                                                          ],
                                                                        staticStyle:
                                                                          {
                                                                            width:
                                                                              "60px",
                                                                          },
                                                                        model: {
                                                                          value:
                                                                            config.giftNum,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                config,
                                                                                "giftNum",
                                                                                _vm._n(
                                                                                  typeof $$v ===
                                                                                    "string"
                                                                                    ? $$v.trim()
                                                                                    : $$v
                                                                                )
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "config.giftNum",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                index ==
                                                                item
                                                                  .pzRebateSchemeStepParamList
                                                                  .length -
                                                                  1
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "margin-left":
                                                                              "15px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "el-button",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                height:
                                                                                  "36px",
                                                                                "font-size":
                                                                                  "14px",
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                type: "primary",
                                                                                icon: "el-icon-plus",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.addConfig(
                                                                                    "levelTo",
                                                                                    config,
                                                                                    index
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t添加\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                                _vm._v(" "),
                                                                item
                                                                  .pzRebateSchemeStepParamList
                                                                  .length > 1
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "margin-left":
                                                                              "15px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "el-button",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                height:
                                                                                  "36px",
                                                                                "font-size":
                                                                                  "14px",
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                type: "primary",
                                                                                icon: "el-icon-delete",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.delConfig(
                                                                                    "levelTo",
                                                                                    index
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t删除\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                      ],
                                                      2
                                                    )
                                                  : _vm._e()
                                              }
                                            ),
                                            0
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showLevelThd
                        ? _c(
                            "el-collapse",
                            {
                              staticStyle: { border: "0" },
                              model: {
                                value: _vm.activeName3,
                                callback: function ($$v) {
                                  _vm.activeName3 = $$v
                                },
                                expression: "activeName3",
                              },
                            },
                            [
                              _c(
                                "el-collapse-item",
                                { attrs: { name: "3" } },
                                [
                                  _c("template", { slot: "title" }, [
                                    _c("div", { staticClass: "cell-label" }, [
                                      _vm._v("终端"),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "el-row",
                                    { attrs: { type: "flex" } },
                                    [
                                      _c("el-col", { attrs: { span: 24 } }, [
                                        _c(
                                          "div",
                                          { staticClass: "config-bg" },
                                          [
                                            _c(
                                              "el-row",
                                              {
                                                staticStyle: {
                                                  "margin-bottom": "0",
                                                },
                                                attrs: { type: "flex" },
                                              },
                                              [
                                                _c(
                                                  "el-col",
                                                  {
                                                    staticStyle: {
                                                      width: "120px",
                                                      position: "relative",
                                                    },
                                                    attrs: { span: 4 },
                                                  },
                                                  [
                                                    _c(
                                                      "ul",
                                                      {
                                                        staticClass:
                                                          "chanelList",
                                                      },
                                                      _vm._l(
                                                        _vm.zdConfig,
                                                        function (item, index) {
                                                          return _c(
                                                            "li",
                                                            {
                                                              class: {
                                                                active:
                                                                  _vm.isActive3 ==
                                                                  index + 1,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.getCurList(
                                                                      index,
                                                                      "3"
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.name
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "pos-operation",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "cell",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "cell-primary align-left",
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "el-icon-plus",
                                                                  staticStyle: {
                                                                    color:
                                                                      "#969FAAFF",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.addItem(
                                                                          "levelTh"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "cell-primary align-right",
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "el-icon-minus",
                                                                  staticStyle: {
                                                                    color:
                                                                      "#969FAAFF",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.delItem(
                                                                          "levelTh"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-col",
                                                  {
                                                    staticStyle: {
                                                      "min-height": "272px",
                                                      "border-left":
                                                        "1px solid #EBECEEFF",
                                                    },
                                                    attrs: { span: 20 },
                                                  },
                                                  _vm._l(
                                                    _vm.zdData,
                                                    function (item, index) {
                                                      return _vm.isActive3 ==
                                                        index + 1
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "config-datas",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "cell-condition",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "cell",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "cell-label",
                                                                          staticStyle:
                                                                            {
                                                                              "margin-right":
                                                                                "20px",
                                                                              width:
                                                                                "80px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "范围选择："
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "cell-hd",
                                                                          staticStyle:
                                                                            {
                                                                              "margin-right":
                                                                                "15px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "el-select",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  width:
                                                                                    "150px",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  placeholder:
                                                                                    "渠道分类",
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    item.classId,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        item,
                                                                                        "classId",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "item.classId",
                                                                                },
                                                                            },
                                                                            _vm._l(
                                                                              _vm.zdClassList,
                                                                              function (
                                                                                item
                                                                              ) {
                                                                                return _c(
                                                                                  "el-option",
                                                                                  {
                                                                                    key: item.pId,
                                                                                    attrs:
                                                                                      {
                                                                                        label:
                                                                                          item.className,
                                                                                        value:
                                                                                          item.pId,
                                                                                      },
                                                                                  }
                                                                                )
                                                                              }
                                                                            ),
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "cell-hd",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "el-select",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  width:
                                                                                    "150px",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  placeholder:
                                                                                    "渠道等级",
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    item.gradeId,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        item,
                                                                                        "gradeId",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "item.gradeId",
                                                                                },
                                                                            },
                                                                            _vm._l(
                                                                              _vm.zdLeveList,
                                                                              function (
                                                                                item
                                                                              ) {
                                                                                return _c(
                                                                                  "el-option",
                                                                                  {
                                                                                    key: item.pId,
                                                                                    attrs:
                                                                                      {
                                                                                        label:
                                                                                          item.gradeName,
                                                                                        value:
                                                                                          item.pId,
                                                                                      },
                                                                                  }
                                                                                )
                                                                              }
                                                                            ),
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "cell-label",
                                                                  staticStyle: {
                                                                    width:
                                                                      "100px",
                                                                    "text-align":
                                                                      "left",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "基础买赠方案："
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "cell",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "cell-hd",
                                                                      staticStyle:
                                                                        {
                                                                          "margin-right":
                                                                            "20px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-input",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "170px",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              placeholder:
                                                                                "赠品选择",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                item.rebateProductName,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    item,
                                                                                    "rebateProductName",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "item.rebateProductName",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "el-button",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  slot: "append",
                                                                                  icon: "el-icon-search",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.getGiftProduct(
                                                                                      index,
                                                                                      "levelTh",
                                                                                      "basic"
                                                                                    )
                                                                                  },
                                                                              },
                                                                              slot: "append",
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "cell-hd",
                                                                      staticStyle:
                                                                        {
                                                                          "margin-right":
                                                                            "10px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-select",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "110px",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              placeholder:
                                                                                "赠品单位",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                item.giftUnit,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    item,
                                                                                    "giftUnit",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "item.giftUnit",
                                                                            },
                                                                        },
                                                                        _vm._l(
                                                                          item.unitList,
                                                                          function (
                                                                            data
                                                                          ) {
                                                                            return _c(
                                                                              "el-option",
                                                                              {
                                                                                key: data.pid,
                                                                                attrs:
                                                                                  {
                                                                                    label:
                                                                                      data.unitName,
                                                                                    value:
                                                                                      data.pid,
                                                                                  },
                                                                              }
                                                                            )
                                                                          }
                                                                        ),
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "cell-label",
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "40px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "每满"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    [
                                                                      _c(
                                                                        "el-input",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "Int",
                                                                                rawName:
                                                                                  "v-Int",
                                                                              },
                                                                            ],
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "60px",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                item.buyNum,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    item,
                                                                                    "buyNum",
                                                                                    _vm._n(
                                                                                      typeof $$v ===
                                                                                        "string"
                                                                                        ? $$v.trim()
                                                                                        : $$v
                                                                                    )
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "item.buyNum",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "cell-label",
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "20px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "赠"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    [
                                                                      _c(
                                                                        "el-input",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "Int",
                                                                                rawName:
                                                                                  "v-Int",
                                                                              },
                                                                            ],
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "60px",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                item.presentNum,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    item,
                                                                                    "presentNum",
                                                                                    _vm._n(
                                                                                      typeof $$v ===
                                                                                        "string"
                                                                                        ? $$v.trim()
                                                                                        : $$v
                                                                                    )
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "item.presentNum",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "cell-label",
                                                                  staticStyle: {
                                                                    width:
                                                                      "100px",
                                                                    "text-align":
                                                                      "left",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "满量赠品方案："
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _vm._l(
                                                                item.pzRebateSchemeStepParamList,
                                                                function (
                                                                  config,
                                                                  index
                                                                ) {
                                                                  return _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "cell",
                                                                      staticStyle:
                                                                        {
                                                                          "margin-bottom":
                                                                            "6px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "cell-hd",
                                                                          staticStyle:
                                                                            {
                                                                              "margin-right":
                                                                                "20px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "el-input",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  width:
                                                                                    "170px",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  placeholder:
                                                                                    "赠品选择",
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    config.giftProductName,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        config,
                                                                                        "giftProductName",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "config.giftProductName",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "el-button",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      slot: "append",
                                                                                      icon: "el-icon-search",
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.getGiftProduct(
                                                                                          index,
                                                                                          "levelTh",
                                                                                          "full"
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                  slot: "append",
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "cell-hd",
                                                                          staticStyle:
                                                                            {
                                                                              "margin-right":
                                                                                "10px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "el-select",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  width:
                                                                                    "110px",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  placeholder:
                                                                                    "赠品单位",
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    config.giftUnit,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        config,
                                                                                        "giftUnit",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "config.giftUnit",
                                                                                },
                                                                            },
                                                                            _vm._l(
                                                                              config.giftUnits,
                                                                              function (
                                                                                data
                                                                              ) {
                                                                                return _c(
                                                                                  "el-option",
                                                                                  {
                                                                                    key: data.pid,
                                                                                    attrs:
                                                                                      {
                                                                                        label:
                                                                                          data.unitName,
                                                                                        value:
                                                                                          data.pid,
                                                                                      },
                                                                                  }
                                                                                )
                                                                              }
                                                                            ),
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "cell-label",
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "40px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "满"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "el-input",
                                                                            {
                                                                              directives:
                                                                                [
                                                                                  {
                                                                                    name: "Int",
                                                                                    rawName:
                                                                                      "v-Int",
                                                                                  },
                                                                                ],
                                                                              staticStyle:
                                                                                {
                                                                                  width:
                                                                                    "60px",
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    config.productNumStep,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        config,
                                                                                        "productNumStep",
                                                                                        _vm._n(
                                                                                          typeof $$v ===
                                                                                            "string"
                                                                                            ? $$v.trim()
                                                                                            : $$v
                                                                                        )
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "config.productNumStep",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "cell-label",
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "20px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "赠"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "el-input",
                                                                            {
                                                                              directives:
                                                                                [
                                                                                  {
                                                                                    name: "Int",
                                                                                    rawName:
                                                                                      "v-Int",
                                                                                  },
                                                                                ],
                                                                              staticStyle:
                                                                                {
                                                                                  width:
                                                                                    "60px",
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    config.giftNum,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        config,
                                                                                        "giftNum",
                                                                                        _vm._n(
                                                                                          typeof $$v ===
                                                                                            "string"
                                                                                            ? $$v.trim()
                                                                                            : $$v
                                                                                        )
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "config.giftNum",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      index ==
                                                                      item
                                                                        .pzRebateSchemeStepParamList
                                                                        .length -
                                                                        1
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  "margin-left":
                                                                                    "15px",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "el-button",
                                                                                {
                                                                                  staticStyle:
                                                                                    {
                                                                                      height:
                                                                                        "36px",
                                                                                      "font-size":
                                                                                        "14px",
                                                                                    },
                                                                                  attrs:
                                                                                    {
                                                                                      type: "primary",
                                                                                      icon: "el-icon-plus",
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.addConfig(
                                                                                          "levelTh",
                                                                                          config,
                                                                                          index
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n\t\t\t\t\t\t    \t\t\t\t\t\t\t\t\t添加\n\t\t\t\t\t\t    \t\t\t\t\t\t\t\t"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      item
                                                                        .pzRebateSchemeStepParamList
                                                                        .length >
                                                                      1
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  "margin-left":
                                                                                    "15px",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "el-button",
                                                                                {
                                                                                  staticStyle:
                                                                                    {
                                                                                      height:
                                                                                        "36px",
                                                                                      "font-size":
                                                                                        "14px",
                                                                                    },
                                                                                  attrs:
                                                                                    {
                                                                                      type: "primary",
                                                                                      icon: "el-icon-delete",
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.delConfig(
                                                                                          "levelTh",
                                                                                          index
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n\t\t\t\t\t\t    \t\t\t\t\t\t\t\t\t删除\n\t\t\t\t\t\t    \t\t\t\t\t\t\t\t"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                            ],
                                                            2
                                                          )
                                                        : _vm._e()
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      false
                        ? _c(
                            "el-row",
                            { attrs: { type: "flex" } },
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "cell-label",
                                  staticStyle: { "padding-top": "15px" },
                                  attrs: { span: 2 },
                                },
                                [_vm._v("终端")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  staticStyle: { width: "860px" },
                                  attrs: { span: 22 },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "config-bg" },
                                    [
                                      _c(
                                        "el-row",
                                        {
                                          staticStyle: { "margin-bottom": "0" },
                                          attrs: { type: "flex" },
                                        },
                                        [
                                          _c(
                                            "el-col",
                                            {
                                              staticStyle: {
                                                width: "120px",
                                                position: "relative",
                                              },
                                              attrs: { span: 4 },
                                            },
                                            [
                                              _c(
                                                "ul",
                                                { staticClass: "chanelList" },
                                                _vm._l(
                                                  _vm.zdConfig,
                                                  function (item) {
                                                    return _c(
                                                      "li",
                                                      {
                                                        class: {
                                                          active:
                                                            _vm.isActive3 ==
                                                            item.id,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.getCurList(
                                                              item.id,
                                                              "3"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.name)
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "pos-operation",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "cell" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "cell-primary align-left",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-plus",
                                                            staticStyle: {
                                                              color:
                                                                "#969FAAFF",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.addItem(
                                                                  "levelTh"
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "cell-primary align-right",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-minus",
                                                            staticStyle: {
                                                              color:
                                                                "#969FAAFF",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.delItem(
                                                                  "levelTh"
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-col",
                                            {
                                              staticStyle: {
                                                "min-height": "272px",
                                                "border-left":
                                                  "1px solid #EBECEEFF",
                                              },
                                              attrs: { span: 20 },
                                            },
                                            _vm._l(
                                              _vm.zdData,
                                              function (item, index) {
                                                return _vm.isActive3 ==
                                                  index + 1
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "config-datas",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "cell-condition",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "cell",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "cell-label",
                                                                    staticStyle:
                                                                      {
                                                                        "margin-right":
                                                                          "20px",
                                                                        width:
                                                                          "80px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "范围选择："
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "cell-hd",
                                                                    staticStyle:
                                                                      {
                                                                        "margin-right":
                                                                          "15px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-select",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            width:
                                                                              "150px",
                                                                          },
                                                                        attrs: {
                                                                          placeholder:
                                                                            "渠道分类",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            item.classId,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                item,
                                                                                "classId",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "item.classId",
                                                                        },
                                                                      },
                                                                      _vm._l(
                                                                        _vm.zdClassList,
                                                                        function (
                                                                          item
                                                                        ) {
                                                                          return _c(
                                                                            "el-option",
                                                                            {
                                                                              key: item.pId,
                                                                              attrs:
                                                                                {
                                                                                  label:
                                                                                    item.className,
                                                                                  value:
                                                                                    item.pId,
                                                                                },
                                                                            }
                                                                          )
                                                                        }
                                                                      ),
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "cell-hd",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-select",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            width:
                                                                              "150px",
                                                                          },
                                                                        attrs: {
                                                                          placeholder:
                                                                            "渠道等级",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            item.gradeId,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                item,
                                                                                "gradeId",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "item.gradeId",
                                                                        },
                                                                      },
                                                                      _vm._l(
                                                                        _vm.zdLeveList,
                                                                        function (
                                                                          item
                                                                        ) {
                                                                          return _c(
                                                                            "el-option",
                                                                            {
                                                                              key: item.pId,
                                                                              attrs:
                                                                                {
                                                                                  label:
                                                                                    item.gradeName,
                                                                                  value:
                                                                                    item.pId,
                                                                                },
                                                                            }
                                                                          )
                                                                        }
                                                                      ),
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "cell-label",
                                                            staticStyle: {
                                                              width: "100px",
                                                              "text-align":
                                                                "left",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "基础买赠方案："
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "cell",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "cell-hd",
                                                                staticStyle: {
                                                                  "margin-right":
                                                                    "20px",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-input",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "170px",
                                                                      },
                                                                    attrs: {
                                                                      placeholder:
                                                                        "赠品选择",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        item.rebateProductName,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            item,
                                                                            "rebateProductName",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "item.rebateProductName",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-button",
                                                                      {
                                                                        attrs: {
                                                                          slot: "append",
                                                                          icon: "el-icon-search",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.getGiftProduct(
                                                                                index,
                                                                                "levelTh",
                                                                                "basic"
                                                                              )
                                                                            },
                                                                        },
                                                                        slot: "append",
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "cell-hd",
                                                                staticStyle: {
                                                                  "margin-right":
                                                                    "10px",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-select",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "110px",
                                                                      },
                                                                    attrs: {
                                                                      placeholder:
                                                                        "赠品单位",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        item.giftUnit,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            item,
                                                                            "giftUnit",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "item.giftUnit",
                                                                    },
                                                                  },
                                                                  _vm._l(
                                                                    item.unitList,
                                                                    function (
                                                                      data
                                                                    ) {
                                                                      return _c(
                                                                        "el-option",
                                                                        {
                                                                          key: data.pid,
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                data.unitName,
                                                                              value:
                                                                                data.pid,
                                                                            },
                                                                        }
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "cell-label",
                                                                staticStyle: {
                                                                  width: "40px",
                                                                },
                                                              },
                                                              [_vm._v("每满")]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              [
                                                                _c("el-input", {
                                                                  directives: [
                                                                    {
                                                                      name: "Int",
                                                                      rawName:
                                                                        "v-Int",
                                                                    },
                                                                  ],
                                                                  staticStyle: {
                                                                    width:
                                                                      "60px",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      item.buyNum,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          item,
                                                                          "buyNum",
                                                                          _vm._n(
                                                                            typeof $$v ===
                                                                              "string"
                                                                              ? $$v.trim()
                                                                              : $$v
                                                                          )
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "item.buyNum",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "cell-label",
                                                                staticStyle: {
                                                                  width: "20px",
                                                                },
                                                              },
                                                              [_vm._v("赠")]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              [
                                                                _c("el-input", {
                                                                  directives: [
                                                                    {
                                                                      name: "Int",
                                                                      rawName:
                                                                        "v-Int",
                                                                    },
                                                                  ],
                                                                  staticStyle: {
                                                                    width:
                                                                      "60px",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      item.presentNum,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          item,
                                                                          "presentNum",
                                                                          _vm._n(
                                                                            typeof $$v ===
                                                                              "string"
                                                                              ? $$v.trim()
                                                                              : $$v
                                                                          )
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "item.presentNum",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "cell-label",
                                                            staticStyle: {
                                                              width: "100px",
                                                              "text-align":
                                                                "left",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "满量赠品方案："
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _vm._l(
                                                          item.pzRebateSchemeStepParamList,
                                                          function (
                                                            config,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "cell",
                                                                staticStyle: {
                                                                  "margin-bottom":
                                                                    "6px",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "cell-hd",
                                                                    staticStyle:
                                                                      {
                                                                        "margin-right":
                                                                          "20px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-input",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            width:
                                                                              "170px",
                                                                          },
                                                                        attrs: {
                                                                          placeholder:
                                                                            "赠品选择",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            config.giftProductName,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                config,
                                                                                "giftProductName",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "config.giftProductName",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "el-button",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                slot: "append",
                                                                                icon: "el-icon-search",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.getGiftProduct(
                                                                                    index,
                                                                                    "levelTh",
                                                                                    "full"
                                                                                  )
                                                                                },
                                                                            },
                                                                            slot: "append",
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "cell-hd",
                                                                    staticStyle:
                                                                      {
                                                                        "margin-right":
                                                                          "10px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-select",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            width:
                                                                              "110px",
                                                                          },
                                                                        attrs: {
                                                                          placeholder:
                                                                            "赠品单位",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            config.giftUnit,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                config,
                                                                                "giftUnit",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "config.giftUnit",
                                                                        },
                                                                      },
                                                                      _vm._l(
                                                                        config.giftUnits,
                                                                        function (
                                                                          data
                                                                        ) {
                                                                          return _c(
                                                                            "el-option",
                                                                            {
                                                                              key: data.pid,
                                                                              attrs:
                                                                                {
                                                                                  label:
                                                                                    data.unitName,
                                                                                  value:
                                                                                    data.pid,
                                                                                },
                                                                            }
                                                                          )
                                                                        }
                                                                      ),
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "cell-label",
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "40px",
                                                                      },
                                                                  },
                                                                  [_vm._v("满")]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "el-input",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "Int",
                                                                              rawName:
                                                                                "v-Int",
                                                                            },
                                                                          ],
                                                                        staticStyle:
                                                                          {
                                                                            width:
                                                                              "60px",
                                                                          },
                                                                        model: {
                                                                          value:
                                                                            config.productNumStep,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                config,
                                                                                "productNumStep",
                                                                                _vm._n(
                                                                                  typeof $$v ===
                                                                                    "string"
                                                                                    ? $$v.trim()
                                                                                    : $$v
                                                                                )
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "config.productNumStep",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "cell-label",
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "20px",
                                                                      },
                                                                  },
                                                                  [_vm._v("赠")]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "el-input",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "Int",
                                                                              rawName:
                                                                                "v-Int",
                                                                            },
                                                                          ],
                                                                        staticStyle:
                                                                          {
                                                                            width:
                                                                              "60px",
                                                                          },
                                                                        model: {
                                                                          value:
                                                                            config.giftNum,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                config,
                                                                                "giftNum",
                                                                                _vm._n(
                                                                                  typeof $$v ===
                                                                                    "string"
                                                                                    ? $$v.trim()
                                                                                    : $$v
                                                                                )
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "config.giftNum",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                index ==
                                                                item
                                                                  .pzRebateSchemeStepParamList
                                                                  .length -
                                                                  1
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "margin-left":
                                                                              "15px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "el-button",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                height:
                                                                                  "36px",
                                                                                "font-size":
                                                                                  "14px",
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                type: "primary",
                                                                                icon: "el-icon-plus",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.addConfig(
                                                                                    "levelTh",
                                                                                    config,
                                                                                    index
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t添加\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                                _vm._v(" "),
                                                                item
                                                                  .pzRebateSchemeStepParamList
                                                                  .length > 1
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "margin-left":
                                                                              "15px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "el-button",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                height:
                                                                                  "36px",
                                                                                "font-size":
                                                                                  "14px",
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                type: "primary",
                                                                                icon: "el-icon-delete",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.delConfig(
                                                                                    "levelTh",
                                                                                    index
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t删除\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                      ],
                                                      2
                                                    )
                                                  : _vm._e()
                                              }
                                            ),
                                            0
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "btn-group-right" },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { height: "38px" },
                          attrs: { type: "default", plain: "" },
                          on: {
                            click: function ($event) {
                              return _vm.handleCloseStep("submitData")
                            },
                          },
                        },
                        [_vm._v("取消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticStyle: { height: "38px" },
                          attrs: { type: "primary" },
                          on: { click: _vm.prevStep },
                        },
                        [_vm._v("上一步")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticStyle: { height: "38px" },
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submit("0", "submitData")
                            },
                          },
                        },
                        [_vm._v("保存")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticStyle: { height: "38px" },
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submit("1", "submitData")
                            },
                          },
                        },
                        [_vm._v("提交")]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c("productMultiSelect", {
          attrs: {
            proSelVisible: _vm.proVisible,
            containsPackScale: _vm.containsPackScale,
            data: _vm.returnData,
          },
          on: { close: _vm.closePro, change: _vm.fetchPros },
        }),
        _vm._v(" "),
        _c("productChose", {
          ref: "singlePro",
          attrs: {
            proSelVisible: _vm.singleProVisible,
            containsPackScale: _vm.containsPackScale,
          },
          on: { close: _vm.closeSigle, change: _vm.getSinglePro },
        }),
        _vm._v(" "),
        _c("policySelect", { ref: "policy", on: { change: _vm.getPolice } }),
        _vm._v(" "),
        _c("scanZc", {
          attrs: {
            policyCode: _vm.submitData.policyCode,
            detailVisible: _vm.policyVisible,
          },
          on: { close: _vm.closeScan },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }