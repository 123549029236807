"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.parse-int");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.number.parse-float");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.split");
var _utils = require("@/utils");
var _UploadImg = _interopRequireDefault(require("@/components/UploadImg"));
var _skuTag = _interopRequireDefault(require("./sku-tag"));
var _skuTable = _interopRequireDefault(require("./sku-table"));
var _tinyMce = _interopRequireDefault(require("@/components/tiny-mce"));
var _integralMall = require("@/api/integral/integralMall");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  beforeRouteLeave: function beforeRouteLeave(to, from, next) {
    this.$store.commit('prod/resetSkuTag');
    next();
  },
  components: {
    UploadImg: _UploadImg.default,
    TinyMce: _tinyMce.default,
    SkuTag: _skuTag.default,
    SkuTable: _skuTable.default
  },
  props: ['prodInfoIsFun', 'fetchData'],
  data: function data() {
    return {
      // 分类树展示与回显
      visible: false,
      category: {
        list: [],
        selected: [],
        props: {
          value: 'categoryId',
          label: 'categoryName'
        }
      },
      // 规格列表
      dataForm: {
        prodName: '',
        brief: '',
        pic: '',
        imgs: '',
        categoryId: '',
        prodId: 0,
        skuList: [],
        tagList: [],
        content: '',
        status: 1,
        deliveryMode: {
          hasShopDelivery: true,
          hasUserPickUp: false
        },
        deliveryTemplateId: null
      },
      tags: [],
      disabled: false
    };
  },
  computed: {
    // 设置上传地址
    resourcesUrl: function resourcesUrl() {
      // return process.env.VUE_APP_BASE_API + '/user/uspyx/pc/template/upload'
      return process.env.VUE_APP_BASE_API + '/uspyx/pc/template/upload';
    },
    defalutSku: function defalutSku() {
      return this.$store.state.prod.defalutSku;
    }
  },
  mounted: function mounted() {},
  methods: {
    init: function init(id, disabled) {
      this.visible = true;
      this.disabled = disabled || false;
      this.getTagList();
      this.getCategoryList();
      this.dataForm.prodId = id;
      this.getDataList();
    },
    // 获取分类数据
    getDataList: function getDataList() {
      var _this = this;
      if (this.dataForm.prodId) {
        // 获取产品数据
        (0, _integralMall.prodInfo)(this.dataForm.prodId).then(function (data) {
          console.log(data, 147);
          if (data.status === 200) {
            _this.dataForm = data.data;
            _this.dataForm.deliveryMode = JSON.parse(data.data.deliveryMode);
            _this.$refs.skuTag.init(data.data.skuList);
            _this.$refs.skuTable.init();
            var arr = data.data.imgs.split(',');
            _this.$refs.imgs.fileList = [];
            arr.forEach(function (item) {
              if (item) {
                _this.$refs.imgs.fileList.push({
                  url: item
                });
              }
            });
            _this.category.selected = (0, _utils.idList)(_this.category.list, _this.dataForm.categoryId, 'categoryId', 'children').reverse();
            _this.dataForm.tagList = data.data.tagList;
          }
        });
      } else {
        this.$nextTick(function () {
          _this.$refs['dataForm'].resetFields();
          _this.$refs.skuTag.init();
          _this.dataForm.pic = '';
          _this.dataForm.imgs = '';
        });
      }
    },
    // 获取分类信息
    getCategoryList: function getCategoryList() {
      var _this2 = this;
      return (0, _integralMall.listCategory)().then(function (res) {
        if (res.status === 200) {
          var arr = [];
          var list = (0, _utils.treeDataTranslate)(res.data, 'categoryId', 'parentId');
          if (list && list.length > 0) {
            list.forEach(function (item) {
              if (item.parentId !== 0 || item.children && item.children.length > 0) {
                arr.push(item);
              }
            });
          }
          _this2.category.list = arr;
        }
      });
    },
    // 选择分类改变事件
    handleCategoryChange: function handleCategoryChange(val) {
      this.dataForm.categoryId = val[val.length - 1];
    },
    // 表单提交
    dataFormSubmit: function dataFormSubmit() {
      var _this3 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (!valid) {
          return;
        }
        if (!_this3.dataForm.imgs) {
          _this3.errorMsg('请选择图片上传');
          return;
        }
        if (!_this3.dataForm.deliveryMode) {
          _this3.errorMsg('请选择配送方式');
          return;
        }
        if (!_this3.dataForm.categoryId) {
          _this3.errorMsg('请选择产品分类');
          return;
        }
        if (!_this3.dataForm.content) {
          _this3.errorMsg('请填写产品详情');
          return;
        }
        // if (
        //   this.dataForm.deliveryMode.hasShopDelivery &&
        //   !this.dataForm.deliveryTemplateId
        // ) {
        //   this.errorMsg('请选择运费模板')
        //   return
        // }
        var param = Object.assign({}, _this3.dataForm);
        // 设置价格和库存
        _this3.paramSetPriceAndStocks(param);
        param.deliveryMode = undefined;
        param.deliveryModeVo = _this3.dataForm.deliveryMode;
        // 商品主图
        param.pic = _this3.dataForm.imgs.split(',')[0];
        (0, _integralMall.prodAdd)(param).then(function (data) {
          _this3.$message({
            message: '操作成功',
            type: 'success',
            duration: 1500,
            onClose: function onClose() {
              _this3.visible = false;
              _this3.$store.commit('common/removeMainActiveTab');
              // this.$emit('refreshDataList')
              // this.$refs.skuTag.cleanTagInput()
              _this3.fetchData();
            }
          });
        }).catch(function (err) {
          if (err.response.status == 400) {
            _this3.$message.closeAll();
            _this3.$message({
              message: err.response.data,
              type: 'error',
              duration: 2000
            });
          }
        });
      });
    },
    paramSetPriceAndStocks: function paramSetPriceAndStocks(param) {
      // 获取规格属性信息
      // param.skuList = this.$refs.prodSpec.getTableSpecData()
      // 商品库存
      param.totalStocks = 0;
      // 商品价格
      param.price = 0;
      // 商品原价
      param.oriPrice = 0;
      // 商品实际库存
      for (var i = 0; i < param.skuList.length; i++) {
        var element = param.skuList[i];
        if (element.status !== 1) {
          continue;
        }
        if (param.price === 0) {
          param.price = element.price ? Number.parseFloat(element.price) : 0;
        }
        // 商品价格为最低价的那件商品的价格
        param.price = Math.min(param.price, element.price);
        if (param.price === element.price) {
          param.oriPrice = element.oriPrice ? Number.parseFloat(element.oriPrice) : 0;
        }
        param.totalStocks += element.stocks ? Number.parseInt(element.stocks) : 0;
      }
      // 如果sku没有商品名称，则使用商品的商品名称
      if (param.skuList.length === 1) {
        param.skuList[0].prodName = this.dataForm.prodName;
      }
    },
    skuTagChangeSkuHandler: function skuTagChangeSkuHandler(skuList) {
      var prodName = this.dataForm.prodName;
      skuList.forEach(function (sku) {
        if (sku.properties) {
          sku.skuName = '';
          var properties = sku.properties.split(';');
          for (var propertiesKey in properties) {
            sku.skuName += properties[propertiesKey].split(':')[1] + ' ';
          }
          sku.prodName = prodName + ' ' + sku.skuName;
        }
      });
      for (var i = 0; i < skuList.length; i++) {
        skuList[i];
        for (var j = 0; j < this.dataForm.skuList.length; j++) {
          this.dataForm.skuList[j];
          if (skuList[i].properties == this.dataForm.skuList[j].properties) {
            skuList[i] = this.dataForm.skuList[j];
          }
        }
      }
      this.dataForm.skuList = skuList;
    },
    errorMsg: function errorMsg(message) {
      this.$message({
        message: message,
        type: 'error',
        duration: 1500
      });
    },
    // 获取所有的分组信息
    getTagList: function getTagList() {
      var _this4 = this;
      (0, _integralMall.listTagList)().then(function (res) {
        console.log(res);
        if (res.status === 200) {
          _this4.tags = res.data;
        }
      });
    },
    closeFun: function closeFun() {
      this.visible = false;
      this.prodInfoIsFun();
    }
  }
};
exports.default = _default;