"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _sceneMarketing = require("@/api/market/sceneMarketing");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManagesceneMarketingscanCodeindex';
var _default = {
  name: 'MarketingManagesceneMarketingscanCodeindex',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      total: 0,
      datalist: [],
      tableData: [],
      tabHeight: '100%',
      listLoading: false,
      dialogTableVisible: false,
      fileList: [],
      options: '',
      timeData: [],
      searchForm: {
        pageNow: 1,
        pageSize: 20,
        company: '',
        phone: '',
        signInTime: ''
      },
      formInline: {
        pageNow: 1,
        pageSize: 20,
        name: ''
      },
      apis: []
    };
  },
  activated: function activated() {
    var that = this;
    var hasCache = !!(that.datalist && that.datalist.length > 0);
    var authList = that.$route.meta.authList;
    that.apis = authList;
    var auth = that.hasAuth('searchbtn', authList);
    that.authId('searchbtn', authList);
    if (!hasCache) {
      if (auth) {
        this.fetchData();
        this.getActlist();
      }
    }
  },
  // 缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = that.getCaches(that, name);
    if (!cache) {
      that.datalist = [];
      that.total = 0;
    }
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    toSend: function toSend(actId, id, memberId) {
      var _this = this;
      var resendCardNum = this.formInline.lotteryNum;
      var object = {
        actId: actId,
        id: id,
        memberId: memberId,
        resendCardNum: resendCardNum
      };
      (0, _sceneMarketing.resendCard)(object).then(function (res) {
        res = res.data;
        if (res.status == 200) {
          _this.$message({
            message: res.msg,
            type: 'success',
            duration: 3 * 1000
          });
          _this.dialogTableVisible = false;
          _this.fetchData();
        } else {
          _this.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
      });
    },
    getActlist: function getActlist() {
      var _this2 = this;
      (0, _sceneMarketing.cjActInfoList)(this.searchForm).then(function (res) {
        res = res.data;
        if (res.status == 200) {
          _this2.options = res.data.list;
          console.log(_this2.options);
        } else {
          _this2.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
        _this2.listLoading = false;
      });
    },
    fetchData: function fetchData() {
      var _this3 = this;
      this.authId('searchbtn', this.apis);
      this.listLoading = true;
      this.searchForm.serchstartTime = this.timeData[0];
      this.searchForm.serchendTime = this.timeData[1];
      (0, _sceneMarketing.ActSignInlist)(this.searchForm).then(function (res) {
        res = res.data;
        console.log(res);
        if (res.status == 200) {
          _this3.datalist = res.data.list;
          _this3.total = res.data.total;
        } else {
          _this3.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
        _this3.listLoading = false;
      });
    },
    getDetail: function getDetail(id) {
      var _this4 = this;
      this.authId('scanbtn', this.apis);
      (0, _sceneMarketing.ActSignIndetailes)({
        id: id
      }).then(function (res) {
        res = res.data;
        if (res.status == 200) {
          // this.choseData = false;
          _this4.dialogTableVisible = true;
          _this4.formInline = res.data;
        } else {
          _this4.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
      });
      (0, _sceneMarketing.getLottery)({
        id: id,
        pageNow: 1,
        pageSize: 99999
      }).then(function (res) {
        res = res.data;
        if (res.status == 200) {
          // this.choseData = false;
          _this4.dialogTableVisible = true;
          _this4.tableData = res.data.list;
        } else {
          _this4.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
      });
    },
    // 弹窗取消
    cancel: function cancel() {
      this.dialogTableVisible = false;
    },
    // 弹窗取消
    // 详情
    detail: function detail() {
      this.dialogTableVisible = true;
    },
    // 详情
    restForm: function restForm(refname) {
      this.$refs[refname].resetFields();
      this.timeData = [];
    }
  }
};
exports.default = _default;