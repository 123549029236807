"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
var _chaneluser = require("@/api/systems/chaneluser");
var _chanelrole = require("@/api/systems/chanelrole");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _scanAuth = _interopRequireDefault(require("../../chanelrole/components/scanAuth"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'ScanRole',
  components: {
    Pagination: _Pagination.default,
    scanAuth: _scanAuth.default
  },
  filters: {
    formatTime: function formatTime(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        return (0, _utils.parseTime)(value, '{y}-{m}-{d}');
      }
    }
  },
  props: {
    asrole: Boolean,
    asData: Array,
    dealerId: String,
    roleTypeDetail: String
  },
  data: function data() {
    return {
      dialogVisible: false,
      mloading: false,
      names: '',
      ids: '',
      multipleSelection2: [],
      tableData: [],
      loading: false,
      dstate: false,
      listQuery: {
        dealerId: '',
        isEnable: 1,
        roleTypeDetail: '',
        pageNo: 1,
        pageSize: 10
      },
      total: 0,
      scanAuthShow: false,
      scanAuthData: [],
      scanRoleName: ''
    };
  },
  watch: {
    asrole: function asrole(val) {
      var that = this;
      if (val == true) {
        that.dialogVisible = true;
        that.listQuery.dealerId = that.dealerId;
        that.listQuery.roleTypeDetail = that.roleTypeDetail;
        var data = that.$parent.multipleSelection;
        var arry = [];
        var nameStr = [];
        data.forEach(function (item) {
          arry.push(item.pid);
          nameStr.push(item.loginAccount);
        });
        that.ids = arry.join(',');
        that.names = nameStr.join(',');
        that.getUserRole();
      } else {
        that.dialogVisible = false;
        that.ids = '';
      }
    }
  },
  methods: {
    handleClose: function handleClose() {
      var that = this;
      that.$parent.asroleShow = false;
      that.dialogVisible = false;
      that.names = '';
      that.ids = '';
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection2 = val;
    },
    getUserRole: function getUserRole() {
      var that = this;
      var param = that.listQuery;
      (0, _chaneluser.getRoleUser)(param).then(function (res) {
        if (res.data.code == 200) {
          that.tableData = res.data.data.records;
          that.total = parseInt(res.data.data.total);
        } else {
          that.tableData = [];
        }
      });
    },
    // 查看渠道角色权限
    scanRoleAuth: function scanRoleAuth(data) {
      var that = this;
      that.scanRoleName = data.roleName;
      var param = {};
      param.roleCode = data.roleCode;
      (0, _chanelrole.scanRoleAuth)(param).then(function (res) {
        if (res.data.code == 200) {
          if (res.data.data.length > 0) {
            that.scanAuthData = (0, _utils.transTreeData)(res.data.data, 'resourceCode', 'parentCode', 'children');
          } else {
            that.scanAuthData = [];
          }
        } else {
          that.scanAuthData = [];
        }
      }).then(function () {
        that.scanAuthShow = false;
        that.$nextTick(function () {
          that.scanAuthShow = true;
        });
      }).catch(function () {
        that.scanAuthData = [];
        that.scanAuthShow = false;
        that.$nextTick(function () {
          that.scanAuthShow = true;
        });
      });
    },
    saveForm: function saveForm() {
      var that = this;
      var param = {};
      var arry = [];
      that.dstate = true;
      that.loading = true;
      param.userIds = that.ids.split(',');
      if (that.multipleSelection2.length > 0) {
        that.multipleSelection2.forEach(function (item) {
          arry.push(item.roleCode);
        });
        param.roleCodes = arry;
      } else {
        param.roleCodes = '';
        that.dstate = false;
        that.loading = false;
        that.$message.error('请选择分配角色');
        return;
      }
      (0, _chaneluser.distributionUserRole)(param).then(function (res) {
        if (res.data.code == 200) {
          that.dstate = false;
          that.loading = false;
          that.$message({
            type: 'success',
            message: '提交成功'
          });
          that.$parent.getList();
          that.handleClose();
        } else {
          that.$message.error(res.data.msg);
          that.dstate = false;
          that.loading = false;
        }
      }).catch(function () {
        that.dstate = false;
        that.loading = false;
      });
    },
    resetForm: function resetForm() {
      var that = this;
      that.handleClose();
    }
  }
};
exports.default = _default;