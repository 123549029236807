"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _activityFlow = require("@/api/marketingManage2/activityFlow");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _productSelectDialog = _interopRequireDefault(require("@/components/productSelectDialog"));
var _index = _interopRequireDefault(require("./channelSelectDialog/index"));
var _index2 = _interopRequireDefault(require("./schemeSelectDialog/index"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { productBrandAllListList } from '@/api/basic/product'
var _default = {
  name: 'Kctz',
  components: {
    Pagination: _Pagination.default,
    productSelectDialog: _productSelectDialog.default,
    ChannelSelectDialog: _index.default,
    schemeSelectDialog: _index2.default
  },
  filters: {
    typeName: function typeName(status) {
      if (!String(status)) return '';
      var statusMap = {
        0: '企业',
        1: '经销商',
        2: '终端',
        3: '团购客户',
        4: '消费者'
      };
      return statusMap[status];
    },
    statusName: function statusName(status) {
      if (!String(status)) return '';
      var statusMap = {
        1: '正常',
        2: '疑似窜货',
        3: '地理信息无法匹配'
      };
      return statusMap[status];
    },
    billType: function billType(status) {
      if (!String(status)) return '';
      var statusMap = {
        101: '生产入库',
        102: '调货入库',
        103: '退货入库',
        104: '采购入库',
        105: '材料入库',
        201: '返工出库',
        202: '调货出库',
        203: '销售出库',
        204: '退货出库',
        205: '材料出库'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      listQuery: {
        bindStartTime: null,
        bindEndTime: null,
        markCode: null,
        productKeyword: null,
        terminalKeyword: null,
        schemeCode: null,
        pageNo: 1,
        pageSize: 20
      },
      productName: '',
      date: [],
      activeName: null,
      tableKey: 'jcrz',
      tabHeight: '100%',
      list: [],
      listLoading: false,
      dialogVisible: false,
      dialogloading: false,
      total: 0,
      showCol: false,
      expandTxt: '展开',
      scanData: {},
      uploadImgs: [],
      excelOut_loading: false,
      brandList: [],
      terminalName: '',
      schemeName: ''
    };
  },
  activated: function activated() {
    this.getList();
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    },
    showPro: function showPro() {
      this.$refs.selProduct.proSelVisible = true;
    },
    setPro: function setPro(val) {
      this.listQuery.productCode = val.productCode;
      this.listQuery.productName = val.productName;
    },
    getUnit: function getUnit() {
      this.$refs.channelSelect.proSelVisible = true;
    },
    setCSel: function setCSel(val) {
      this.listQuery.channelCode = val.channelCode;
      this.listQuery.channelName = val.channelName;
    },
    DoSearch: function DoSearch() {
      this.listQuery.pageNo = 1;
      this.getList();
    },
    DoReset: function DoReset() {
      this.listQuery = {
        bindStartTime: null,
        bindEndTime: null,
        markCode: null,
        productKeyword: null,
        terminalKeyword: null,
        pageNo: 1,
        pageSize: 20,
        schemeCode: null
      };
      this.schemeName = '';
      this.terminalName = '';
      this.date = [];
      this.productName = '';
      this.getList();
      this.brandData = '';
    },
    // 选择产品
    choseProduct: function choseProduct() {
      this.$refs.proSelect.proSelVisible = true;
    },
    selectPro: function selectPro(val) {
      this.listQuery.productKeyword = val.productCode;
      this.productName = val.productName;
    },
    // 选择终端
    choseTerminal: function choseTerminal() {
      this.$refs.channelSelect.proSelVisible = true;
    },
    selectTerminal: function selectTerminal(val) {
      this.listQuery.terminalKeyword = val.channelCode;
      this.terminalName = val.channelName;
    },
    // 选择活动方案
    choseScheme: function choseScheme() {
      this.$refs.schemeSelect.proSelVisible = true;
    },
    selectScheme: function selectScheme(val) {
      this.listQuery.schemeCode = val.schemeCode;
      this.schemeName = val.schemeName;
    },
    getList: function getList() {
      this.authId('searchbtn', this.$route.meta.authList);
      var that = this;
      that.listLoading = true;
      if (this.date.length) {
        that.listQuery.bindStartTime = this.date[0];
        that.listQuery.bindEndTime = this.date[1];
      } else {
        that.listQuery.bindStartTime = '';
        that.listQuery.bindEndTime = '';
      }
      (0, _activityFlow.allBindCodeList)(that.listQuery).then(function (res) {
        if (res.data.code == 200) {
          that.list = res.data.data.records;
          that.total = parseInt(res.data.data.total);
          that.listLoading = false;
        } else {
          that.$message.error(res.data.msg);
          that.listLoading = false;
          return;
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    showClose: function showClose() {
      this.authId('searchbtn', this.$route.meta.authList);
      this.dialogVisible = false;
      this.activeName = null;
      this.scanData = {};
      this.uploadImgs = [];
    },
    excelOut: function excelOut() {
      var _this = this;
      this.excelOut_loading = true;
      if (this.date.length) {
        this.listQuery.bindStartTime = this.date[0];
        this.listQuery.bindEndTime = this.date[1];
      } else {
        this.listQuery.bindStartTime = '';
        this.listQuery.bindEndTime = '';
      }
      (0, _activityFlow.exportBindCodeList)(this.listQuery).then(function (res) {
        _this.excelOut_loading = false;
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '箱码绑定记录.xlsx';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      });
    }
  }
};
exports.default = _default;