var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c("h3", { staticClass: "h-title" }, [_vm._v("箱托比例")]),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.ruleForm,
                    "status-icon": "",
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                    "label-suffix": ":",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cols" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6, prop: "supportBoxCode" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "箱托配置编号",
                                    prop: "supportBoxCode",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                      maxlength: "20",
                                    },
                                    model: {
                                      value: _vm.ruleForm.supportBoxCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "supportBoxCode",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm.supportBoxCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6, prop: "productCode" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "产品编号",
                                    prop: "productCode",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                      maxlength: "20",
                                    },
                                    model: {
                                      value: _vm.ruleForm.productCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "productCode",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm.productCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6, prop: "productName" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "产品名称",
                                    prop: "productName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                      maxlength: "20",
                                    },
                                    model: {
                                      value: _vm.ruleForm.productName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "productName",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm.productName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.formConfig.btnFollow
                            ? _c(
                                "el-col",
                                {
                                  staticClass: "head-btn-group",
                                  style: { width: _vm.formConfig.btnArea },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { "label-width": "0" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v(" ")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.searchBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.searchBtn.icon,
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.ruleForm.current = 1
                                              _vm.getList()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.searchBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.resetBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.resetBtn.icon,
                                          },
                                          on: { click: _vm.resetForm },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.resetBtn.text)
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.formConfig.btnFollow
                ? _c("el-divider", { staticClass: "btnDivider" })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btnArea" },
                [
                  _vm.formConfig.btnFollow
                    ? _c("el-col", { staticClass: "head-btn-group" }, [
                        _vm.hasAuth("addbtn", _vm.$route.meta.authList)
                          ? _c(
                              "div",
                              { staticClass: "head-btn-group" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: _vm.btnConfig.addBtn.type,
                                      size: _vm.btnConfig.size,
                                      icon: _vm.btnConfig.addBtn.icon,
                                      plain: _vm.btnConfig.addBtn.plain,
                                    },
                                    on: { click: _vm.addE },
                                  },
                                  [_vm._v(_vm._s(_vm.btnConfig.addBtn.text))]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "section",
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      key: _vm.tableKey,
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.tableData,
                        height: _vm.tabHeight,
                        border: _vm.tableConfig.border,
                        stripe: _vm.tableConfig.stripe,
                        fit: "",
                        "highlight-current-row": "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          label: "序号",
                          align: _vm.tableConfig.align,
                          width: "120",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "箱托配置编号",
                          prop: "supportBoxCode",
                          align: _vm.tableConfig.align,
                          "min-width": "120",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "产品编号",
                          prop: "productCode",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "产品名称",
                          prop: "productName",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "每托箱数",
                          prop: "boxSum",
                          width: "120",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _vm.hasAuth("updatebtn", _vm.$route.meta.authList) ||
                      _vm.hasAuth("deletebtn", _vm.$route.meta.authList)
                        ? _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              fixed: "right",
                              align: _vm.tableConfig.align,
                              width: "180",
                              "class-name": "small-padding fixed-width",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _vm.hasAuth(
                                        "updatebtn",
                                        _vm.$route.meta.authList
                                      )
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.DoEdit(row)
                                                },
                                              },
                                            },
                                            [_vm._v("编辑")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.hasAuth(
                                        "deletebtn",
                                        _vm.$route.meta.authList
                                      )
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.DoDel(row)
                                                },
                                              },
                                            },
                                            [_vm._v("删除")]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              750509612
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("pagination", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.total > 0,
                        expression: "total > 0",
                      },
                    ],
                    attrs: {
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.total,
                      page: _vm.ruleForm.current,
                      limit: _vm.ruleForm.size,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(_vm.ruleForm, "current", $event)
                      },
                      "update:limit": function ($event) {
                        return _vm.$set(_vm.ruleForm, "size", $event)
                      },
                      pagination: _vm.getList,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: _vm.title,
            visible: _vm.dialogFormVisible,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.mloading,
                  expression: "mloading",
                },
              ],
              ref: "ruleForm2",
              staticClass: "typeConfig",
              attrs: {
                model: _vm.ruleForm2,
                rules: _vm.rules,
                "label-width": "90px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "每托箱数", prop: "boxSum" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "6",
                              clearable: "",
                              placeholder: "请输入1-999999的正整数",
                            },
                            nativeOn: {
                              input: function ($event) {
                                return _vm.number($event)
                              },
                            },
                            model: {
                              value: _vm.ruleForm2.boxSum,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm2, "boxSum", $$v)
                              },
                              expression: "ruleForm2.boxSum",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品名称", prop: "productName" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "input-with-select",
                              attrs: { readonly: "", clearable: "" },
                              model: {
                                value: _vm.ruleForm2.productName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm2, "productName", $$v)
                                },
                                expression: "ruleForm2.productName",
                              },
                            },
                            [
                              _vm.addbtn
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { slot: "append" },
                                      on: { click: _vm.openSel2 },
                                      slot: "append",
                                    },
                                    [_vm._v("请选择")]
                                  )
                                : _c(
                                    "el-button",
                                    {
                                      attrs: { slot: "append" },
                                      on: { click: _vm.openSel },
                                      slot: "append",
                                    },
                                    [_vm._v("请选择")]
                                  ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品编码", prop: "productCode" } },
                        [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(_vm.ruleForm2.productCode),
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.dstate },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm2("ruleForm2")
                    },
                  },
                },
                [_vm._v("提交")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.resetForm2("ruleForm2")
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.proShow
        ? _c("product-dialog", {
            ref: "selProduct2",
            attrs: {
              "pro-sel-visible": _vm.proShow,
              "channel-id": _vm.channelId,
              "channel-type": _vm.channelType,
              data: _vm.proList,
            },
            on: {
              "update:data": function ($event) {
                _vm.proList = $event
              },
              change: _vm.setSel2,
              close: _vm.proClose2,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("product-select-dialog", {
        ref: "selProduct",
        attrs: { data: _vm.ruleForm.list },
        on: {
          "update:data": function ($event) {
            return _vm.$set(_vm.ruleForm, "list", $event)
          },
          change: _vm.setSel,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }