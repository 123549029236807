"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _number = require("@/api/generalSettings2/number");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: '',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      queryParams: {
        programName: '',
        appId: '',
        creatorName: '',
        updaterName: '',
        status: '',
        startTime: '',
        endTime: '',
        pageNo: 1,
        pageSize: 10,
        rangeTime: ''
      },
      total: 0,
      listLoading: false,
      tabHeight: 'calc(100vh - 350px)',
      wechatList: [],
      title: '',
      dialogVisible: false,
      ruleForm: {
        id: null,
        programName: '',
        // 小程序名称
        appid: '',
        // 小程序ID
        appSecret: '' //
      },

      rules: {
        programName: [{
          message: '请输入小程序',
          required: true,
          trigger: 'blur'
        }],
        appid: [{
          message: '请输入APPID',
          required: true,
          trigger: 'blur'
        }],
        appSecret: [{
          message: '请输入小程序APPsecret',
          required: true,
          trigger: 'blur'
        }]
      },
      disabled: false,
      btnLoading: false
    };
  },
  activated: function activated() {
    var hasCache2 = this.wechatList.length > 0;
    if (!hasCache2) {
      this.getwechatQueryList();
    }
  },
  deactivated: function deactivated() {},
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    // 获取微信小程序列表
    getwechatQueryList: function getwechatQueryList() {
      var _this = this;
      this.listLoading = true;
      if (this.queryParams.rangeTime && this.queryParams.rangeTime.length > 0) {
        this.queryParams.startTime = this.queryParams.rangeTime[0];
        this.queryParams.endTime = this.queryParams.rangeTime[1];
      } else {
        this.queryParams.startTime = '';
        this.queryParams.endTime = '';
      }
      var data = JSON.parse(JSON.stringify(this.queryParams));
      delete data.rangeTime;
      (0, _number.wechatQueryList)(data).then(function (res) {
        console.log(res);
        if (res.data.data.errCode) {
          _this.listLoading = false;
          _this.$message.error(res.data.data.errMsg);
          return;
        } else {
          if (res.data.data.data !== null) {
            _this.wechatList = res.data.data.data;
            // this.total = res.data.data.total
            _this.listLoading = false;
          } else {
            _this.wechatList = [];
            // this.total = res.data.data.total
            _this.listLoading = false;
          }
        }
      }).catch(function (err) {
        console.log(err);
        _this.listLoading = false;
      });
    },
    restCapitalList: function restCapitalList() {
      this.queryParams = {};
      this.getwechatQueryList();
    },
    // 新增小程序
    handleaddWechatAccount: function handleaddWechatAccount() {
      var _this2 = this;
      this.disabled = false;
      this.btnLoading = false;
      this.title = '新增小程序';
      this.$nextTick(function () {
        _this2.$refs.rulesForm.resetFields();
        _this2.reset();
      });
      this.dialogVisible = true;
      // this.$nextTick(() => {
      //   this.$refs.rulesForm.resetFields();
      // })
    },
    // 新增/修改小程序保存
    handleSave: function handleSave(rulesForm) {
      var _this3 = this;
      this.$refs[rulesForm].validate(function (valid) {
        if (valid) {
          if (_this3.title !== '新增小程序') {
            (0, _number.updateWechat)(_this3.ruleForm).then(function (res) {
              if (res.data.data.errCode) {
                _this3.disabled = false;
                _this3.btnLoading = false;
                return _this3.$message.error(res.data.data.errMsg);
              } else {
                _this3.$message.success('修改成功');
                _this3.getwechatQueryList();
                _this3.dialogVisible = false;
                _this3.disabled = true;
                _this3.btnLoading = true;
              }
            }).catch(function () {
              // this.disabled =false
              // this.btnLoading = false
            });
          } else {
            (0, _number.addWechat)(_this3.ruleForm).then(function (res) {
              if (res.data.data.errCode) {
                _this3.disabled = false;
                _this3.btnLoading = false;
                return _this3.$message.error(res.data.data.errMsg);
              } else {
                _this3.$message.success('新增成功');
                _this3.getwechatQueryList();
                _this3.dialogVisible = false;
                _this3.disabled = true;
                _this3.btnLoading = true;
              }
            }).catch(function () {
              // this.disabled =false
              // this.btnLoading = false
            });
          }
        } else {
          _this3.disabled = false;
          _this3.btnLoading = false;
        }
      });
    },
    clearValid: function clearValid(rulesForm) {
      var _this4 = this;
      this.$nextTick(function () {
        _this4.reset();
        _this4.$refs[rulesForm].resetFields();
      });
      this.dialogVisible = false;
    },
    // 重置小程序表单
    reset: function reset() {
      this.ruleForm = {
        id: null,
        programName: '',
        // 小程序名称
        appid: '',
        // 小程序ID
        appSecret: '' // 归属方
      };
      // this.ruleForm.programName = '';
      // this.ruleForm.appid = '';
      // this.ruleForm.officialBelong = '1';
      // this.ruleForm.id = null;
    },
    // 编辑小程序
    handleupdateWechatAccount: function handleupdateWechatAccount(row) {
      this.disabled = false;
      this.btnLoading = false;
      console.log(row);
      // this.ruleForm = row;
      this.ruleForm.programName = row.programName;
      this.ruleForm.appid = row.appId;
      this.ruleForm.id = row.id;
      this.ruleForm.appSecret = row.appSecret;
      // console.log(this.ruleForm.officialBelong);
      this.title = '编辑小程序';
      this.dialogVisible = true;
      this.offFlagId = row.id;
    },
    // 启用/停用
    handleDelItem: function handleDelItem(row) {
      var _this5 = this;
      var txt = '';
      if (row.status == '1') {
        txt = '停用';
      } else {
        txt = '启用';
      }
      this.$confirm('是否' + txt + '该数据项?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function (res) {
        var params = {
          id: row.id
        };
        (0, _number.changeStatus)(params).then(function (res) {
          if (res.data.data.errCode) {
            _this5.$message.error('无法修改');
            return;
          } else {
            var str = row.status == 1 ? '停用' : '启用';
            _this5.getwechatQueryList();
            _this5.$message.success(str + '成功');
          }
        });
      }).catch(function (err) {
        console.log(err);
      });
    },
    // 小程序授权
    generateurl: function generateurl() {
      window.open(process.env.VUE_APP_BASE_API + '/x/api/v1/zdConfig/third/api/auth/goview', '_blank');
    }
  }
};
exports.default = _default;