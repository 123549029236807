var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: !_vm.dataForm.id ? "新增" : "修改",
        visible: _vm.visible,
        width: "600",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        close: _vm.closeFun,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: { model: _vm.dataForm, "label-width": "100px" },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "产品图片：",
                prop: "imgs",
                rules: [{ required: true, message: "请上传图片" }],
              },
            },
            [
              _c("Upload-img", {
                ref: "imgs",
                attrs: {
                  data: _vm.dataForm.imgs,
                  text: "",
                  disabled: _vm.disabled,
                  "limit-count": 5,
                },
                on: {
                  "update:data": function ($event) {
                    return _vm.$set(_vm.dataForm, "imgs", $event)
                  },
                },
              }),
              _vm._v(" "),
              _c("p", { staticClass: "formTip" }, [
                _c("i", { staticClass: "el-icon-warning-outline" }),
                _vm._v("建议：图片尺寸500*500\n      "),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "状态：",
                prop: "status",
                rules: [{ required: true }],
              },
            },
            [
              _c(
                "el-radio-group",
                {
                  staticClass: "radio",
                  attrs: { disabled: _vm.disabled },
                  model: {
                    value: _vm.dataForm.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataForm, "status", $$v)
                    },
                    expression: "dataForm.status",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("上架")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("下架")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "产品分类：",
                prop: "categoryId",
                rules: [{ required: true, message: "请选择产品分类" }],
              },
            },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c("el-cascader", {
                    attrs: {
                      disabled: _vm.disabled,
                      "expand-trigger": "hover",
                      options: _vm.category.list,
                      props: _vm.category.props,
                      "show-all-levels": false,
                    },
                    on: { change: _vm.handleCategoryChange },
                    model: {
                      value: _vm.category.selected,
                      callback: function ($$v) {
                        _vm.$set(_vm.category, "selected", $$v)
                      },
                      expression: "category.selected",
                    },
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "formTip" }, [
                    _c("i", { staticClass: "el-icon-warning-outline" }),
                    _vm._v("提示：只能选择二级分类\n        "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "产品分组：" } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "250px" },
                      attrs: {
                        disabled: _vm.disabled,
                        multiple: "",
                        placeholder: "请选择",
                      },
                      model: {
                        value: _vm.dataForm.tagList,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataForm, "tagList", $$v)
                        },
                        expression: "dataForm.tagList",
                      },
                    },
                    _vm._l(_vm.tags, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.title, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "产品名称：",
                prop: "prodName",
                rules: [{ required: true, message: "产品名称不能为空" }],
              },
            },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.disabled,
                      placeholder: "产品名称",
                      maxlength: "50",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.dataForm.prodName,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataForm, "prodName", $$v)
                      },
                      expression: "dataForm.prodName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "产品卖点：", prop: "brief" } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.disabled,
                      type: "textarea",
                      autosize: { minRows: 2, maxRows: 4 },
                      placeholder: "产品卖点",
                      maxlength: "45",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.dataForm.brief,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataForm, "brief", $$v)
                      },
                      expression: "dataForm.brief",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "配送方式：" } },
            [
              _c(
                "el-radio",
                {
                  staticClass: "checkbox",
                  attrs: { disabled: _vm.disabled, label: true },
                  model: {
                    value: _vm.dataForm.deliveryMode.hasShopDelivery,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.dataForm.deliveryMode,
                        "hasShopDelivery",
                        $$v
                      )
                    },
                    expression: "dataForm.deliveryMode.hasShopDelivery",
                  },
                },
                [_vm._v("商家配送")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("sku-tag", {
            ref: "skuTag",
            attrs: { disabled: _vm.disabled },
            on: { change: _vm.skuTagChangeSkuHandler },
          }),
          _vm._v(" "),
          _c("sku-table", {
            ref: "skuTable",
            attrs: {
              value: _vm.dataForm.skuList,
              "prod-name": _vm.dataForm.prodName,
              disabled: _vm.disabled,
            },
            on: {
              "update:prodName": function ($event) {
                return _vm.$set(_vm.dataForm, "prodName", $event)
              },
              "update:prod-name": function ($event) {
                return _vm.$set(_vm.dataForm, "prodName", $event)
              },
            },
          }),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "产品详情：",
                prop: "content",
                rules: [{ required: true, message: "请填写产品详情" }],
              },
            },
            [
              _c("tiny-mce", {
                ref: "content",
                staticStyle: { width: "100%" },
                attrs: { disabled: _vm.disabled },
                model: {
                  value: _vm.dataForm.content,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "content", $$v)
                  },
                  expression: "dataForm.content",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "head-btn-group",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.closeFun } }, [_vm._v("关闭")]),
          _vm._v(" "),
          !_vm.disabled
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.dataFormSubmit },
                },
                [_vm._v("确定")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }