var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "el-steps",
        { attrs: { active: _vm.active, "finish-status": "success" } },
        _vm._l(_vm.flowList, function (v, index) {
          return _c(
            "el-step",
            { key: index, attrs: { title: v.name } },
            [
              _c("template", { slot: "description" }, [
                _c("div", [_vm._v(_vm._s(v.userName))]),
                _vm._v(" "),
                _c("div", [_vm._v(_vm._s(v.dateCreate))]),
              ]),
            ],
            2
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }