"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.to-string");
var _utils = require("@/utils");
var _param = require("@/api/chanelmange/param");
var _stockscan = require("@/api/chanelmange/stockscan");
var _scanZc = _interopRequireDefault(require("./scanZc"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'detailsMzfn',
  components: {
    scanZc: _scanZc.default
  },
  props: {
    detailVisible: {
      type: Boolean,
      default: false
    },
    pid: {
      type: String,
      default: ''
    }
  },
  filters: {
    formatDate: function (_formatDate) {
      function formatDate(_x) {
        return _formatDate.apply(this, arguments);
      }
      formatDate.toString = function () {
        return _formatDate.toString();
      };
      return formatDate;
    }(function (time) {
      var date = new Date(time);
      return formatDate(date, 'yyyy-MM-dd hh:mm:ss');
    }),
    state: function state(val) {
      var status = {
        0: '待提交',
        1: '待审核',
        2: '已生效',
        3: '已终止'
      };
      return status[val];
    }
  },
  data: function data() {
    return {
      detailData: {},
      mloading: false,
      proList: [],
      treeData: [],
      checkedKeys: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      configData: [],
      configDatas: [],
      policyVisible: false,
      grads: [{
        name: '一级经销商',
        type: 1
      }, {
        name: '二级经销商',
        type: 2
      }, {
        name: '终端',
        type: 3
      }],
      triggerAction: 3,
      isActive: 1
    };
  },
  created: function created() {
    var that = this;
  },
  mounted: function mounted() {
    var that = this;
    var pid = that.$route.params.pid;
    if (pid) {
      this.checkedKeys = [];
      this.treeData = [];
      this.getBasic(pid);
      this.getPro(pid);
      this.getOrg(pid);
      this.getCofig(pid);
    } else {
      this.checkedKeys = [];
      this.treeData = [];
    }
  },
  methods: {
    //返利配置切换
    getCurList: function getCurList(key) {
      var that = this;
      that.isActive = key;
      that.configData = that.configDatas[key - 1];
    },
    getBasic: function getBasic(arg) {
      var that = this;
      (0, _param.getRebateSchemeById)({
        pid: arg
      }).then(function (res) {
        if (res.data.code == 200) {
          that.detailData = res.data.data;
        } else {
          that.$message.error(res.data.msg + ',未能获取基本信息');
        }
      });
    },
    getPro: function getPro(arg) {
      var that = this;
      (0, _param.getRebateSchemeProBySchemeId)({
        pid: arg
      }).then(function (res) {
        if (res.data.code == 200) {
          that.proList = res.data.data;
        } else {
          that.$message.error(res.data.msg + ',未能获取产品信息');
        }
      });
    },
    //获取组织
    getOrg: function getOrg(arg) {
      var that = this;
      (0, _param.getOrgRanges)({
        pid: arg
      }).then(function (res) {
        if (res.data.code == 200) {
          var rData = res.data.data;
          var arr = [];
          if (rData.length) {
            rData.forEach(function (item) {
              var obj = {};
              obj.label = item.orgName;
              obj.pid = item.orgId;
              obj.parentPid = item.parentId;
              arr.push(obj);
            });
            that.treeData = (0, _utils.transTreeData)(arr, 'pid', 'parentPid', 'children');
          }
        }
      });
    },
    getCofig: function getCofig(arg) {
      var that = this;
      (0, _param.getRebateSchemeConfig)({
        pid: arg
      }).then(function (res) {
        if (res.data.code == 200) {
          var response = res.data.data;
          if (response && response.length > 0) {
            that.configDatas = response.map(function (item) {
              return item.detail;
            });
            that.configData = that.configDatas[0];
          }
        } else {
          that.$message.error(res.data.msg + ',获取返利配置失败');
        }
      });
    },
    handleClose: function handleClose() {
      var that = this;
      that.checkedKeys = [];
      that.treeData = [];
      that.$router.back();
    },
    scanPolicy: function scanPolicy() {
      var that = this;
      if (that.detailData.policyCode == '') {
        that.$message.error('请选择政策');
        return;
      } else {
        that.policyVisible = true;
      }
    },
    closeScan: function closeScan() {
      this.policyVisible = false;
    }
  }
};
exports.default = _default;