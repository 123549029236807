"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/web.dom.iterable");
var _wxgzh = require("@/api/wxhyyy/wxgzh");
var _wxHy = require("@/api/wxhyyy/wxHy");
var _tinyMce = _interopRequireDefault(require("@/components/tiny-mce"));
var _admincut = _interopRequireDefault(require("@/components/admincut"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-const */
var _default = {
  components: {
    TinyMce: _tinyMce.default,
    admincut: _admincut.default
  },
  filters: {
    filterTime: function filterTime(value) {
      if (!value) return '';
      if (value.length > 0) {
        var time = new Date(value);
        var y = time.getFullYear();
        var m = time.getMonth() + 1 > 9 ? time.getMonth() + 1 : '0' + (time.getMonth() + 1);
        var d = time.getDate() > 9 ? time.getDate() : '0' + time.getDate();
        var h = time.getHours();
        var i = time.getMinutes() > 9 ? time.getMinutes() : '0' + time.getMinutes();
        var s = time.getSeconds() > 9 ? time.getSeconds() : '0' + time.getSeconds();
        return y + '-' + m + '-' + d + ' ' + h + ':' + i + ':' + s;
      }
      return value;
    }
  },
  data: function data() {
    return {
      total: 0,
      toolbar: 'emoticons link',
      plugins: 'emoticons link',
      twImgurl: 'http://testsaas.panpass.com/images/',
      contentload: false,
      // 公众号切换
      options: [{
        value: '选项1',
        label: '美语派'
      }, {
        value: '选项2',
        label: '野狼派'
      }],
      value: '',
      // 消息查询
      formInline: {
        // 结束时间
        endTime: '',
        // 用户名
        nickname: '',
        // 互动状态
        msgStatus: '',
        pageNo: 1,
        pageSize: 10,
        // 互动时间
        startTime: ''
      },
      // eslint-disable-next-line no-undef
      value2: '',
      // 消息表格
      content: [],
      tableData: [],
      // 消息详情
      dialogVisible: false,
      // 互动时间
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 0);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '昨天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            end.setTime(end.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '近两天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }],
        disabledDate: function disabledDate(time) {
          var three = 365 * 24 * 3600 * 1000;
          return time.getTime() < three;
        }
      },
      msgload: false,
      msglistload: true,
      msglist: '',
      username: '',
      tags: '',
      getRowKeys: function getRowKeys(row) {
        return row.id;
      },
      expands: [],
      appid: ''
    };
  },
  watch: {
    'appid': function appid(newval, oldval) {
      var that = this;
      that.formInline.appId = newval;
    }
  },
  created: function created() {
    var that = this;
    that.twImgurl = _wxHy.base;
    that.getfivemsg();
    that.getMsg(this.formInline);
    that.appid = sessionStorage.getItem('appId');
    that.formInline.appId = that.appid;
  },
  beforeUpdate: function beforeUpdate() {
    var _this = this;
    if (this.tableData.length > 0) {
      this.content = [];
      this.tableData.forEach(function (val) {
        var n = {
          val: ''
        };
        _this.content.push(n);
      });
    }
  },
  // mounted() {
  //   this.$nextTick(_ => {
  //     console.log(this.$refs.main.scrollHeight)
  //   })
  // },
  methods: {
    handleCurrentChange: function handleCurrentChange(val) {
      this.vipload = true;
      console.log("\u5F53\u524D\u9875: ".concat(val));
      this.formInline.pageNo = val;
      // const a = {
      //   pageNo: this.formInline.pageNo,
      //   pageSize: this.formInline.pageSize
      // }
      this.getMsg(this.formInline);
    },
    asdf: function asdf(e) {
      console.log(this.$refs.aaa.getContent());
    },
    totet: function totet(val) {
      return val.replace(/<\/?p[^>]*>/gi, '');
    },
    filterTime: function filterTime(value) {
      var time = new Date(value);
      var y = time.getFullYear();
      var m = time.getMonth() + 1 > 9 ? time.getMonth() + 1 : '0' + (time.getMonth() + 1);
      var d = time.getDate() > 9 ? time.getDate() : '0' + time.getDate();
      var h = time.getHours();
      var i = time.getMinutes() > 9 ? time.getMinutes() : '0' + time.getMinutes();
      var s = time.getSeconds() > 9 ? time.getSeconds() : '0' + time.getSeconds();
      return y + '-' + m + '-' + d;
    },
    looks: function looks(val, row) {
      var t = {
        mediaId: val,
        appId: sessionStorage.getItem('appId')
      };
      (0, _wxgzh.getMedia)(t).then(function (res) {
        var res = res.data;
        var blob = new Blob([res], {
          type: 'application/attachment;filename='
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        switch (row.msgType) {
          case 'image':
            downloadElement.download = row.fromUserName + '.jpeg';
            break;
          case 'voice':
            downloadElement.download = row.fromUserName + '.amr';
            break;
          case 'video':
            downloadElement.download = row.fromUserName + '.mp4';
            break;
        }
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      });
    },
    getfivemsg: function getfivemsg() {
      var a = new Date();
      var s = new Date(new Date().getTime() - 3600 * 1000 * 24 * 5);
      this.value2 = [this.filterTime(s), this.filterTime(a)];
    },
    // 发送消息
    send: function send(index, row) {
      var _this2 = this;
      console.log(index);
      console.log(row);
      var n = {
        content: this.totet(this.content[index].val),
        userId: row.userId,
        appId: sessionStorage.getItem('appId')
      };
      console.log(n);
      (0, _wxgzh.postReply)(n).then(function (res) {
        var res = res.data;
        if (res.code * 1 === 200) {
          _this2.$message.success('发送成功');
          _this2.expands = [];
          _this2.content[index].val = '';
          if (row.msgStatus === 0) {
            row.msgStatus = 1;
          }
        } else {
          _this2.$message.warning(res.msg);
        }
      });
    },
    unfold: function unfold(index, row) {
      console.log(row);
      var that = this;
      that.expands = [];
      console.log(that.expands);
      that.expands.push(that.tableData[index].id);
      // that.expands.push(row.id)
    },
    // 收起消息框
    packup: function packup(index) {
      this.expands = [];
      this.content[index].val = '';
    },
    getMsg: function getMsg(val) {
      var _this3 = this;
      val.startTime = this.value2[0];
      val.endTime = this.value2[1];
      console.log(this.formInline);
      (0, _wxgzh.getsearchMsg)(val).then(function (res) {
        var res = res.data;
        console.log(res);
        if (res.code * 1 === 200) {
          _this3.total = res.data.total;
          _this3.tableData = res.data.records;
        } else {
          _this3.$message.warning(res.msg);
        }
        _this3.msgload = false;
      });
    },
    // 查看素材
    getmediadeil: function getmediadeil(val) {
      console.log(val);
      var n = {
        mediaId: val,
        appId: sessionStorage.getItem('appId')
      };
      (0, _wxgzh.getMedia)(n).then(function (res) {
        var res = res.data;
        console.log(res);
        // if(res.code * 1 ===200){
        return res;
        // }
      });
    },
    // 查询操作
    onSubmit: function onSubmit() {
      this.msgload = true;
      this.formInline.startTime = this.value2[0];
      this.formInline.endTime = this.value2[1];
      this.getMsg(this.formInline);
    },
    // 重置查询条件
    onReset: function onReset() {
      this.formInline.nickname = '';
      this.value2 = '';
      this.formInline.msgStatus = '';
    },
    // 查看消息详情
    handleEdit: function handleEdit(row, name, list) {
      var _this4 = this;
      this.dialogVisible = true;
      var n = {
        pageNo: 1,
        pageSize: 20,
        userId: row,
        appId: sessionStorage.getItem('appId')
      };
      this.username = name;
      this.tags = list;
      console.log(this.tags);
      (0, _wxgzh.getsearchDetail)(n).then(function (res) {
        var res = res.data;
        console.log(res);
        if (res.code * 1 === 200) {
          var p;
          p = res.data.records;
          _this4.msglist = p.reverse();
        } else {
          _this4.$message.warning(res.msg);
        }
        setTimeout(function () {
          _this4.$refs.main.scrollTop = _this4.$refs.main.scrollHeight;
          // console.log(this.$refs.main.scrollHeight)
        }, 100);
        _this4.msglistload = false;
      });
    },
    handleClose: function handleClose(done) {
      done();
    }
  }
};
exports.default = _default;