"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _message = require("@/api/systems/message");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _noticeDetail = _interopRequireDefault(require("@/components/noticeDetail"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SystemmessageManagemessageTemplateindex',
  components: {
    Pagination: _Pagination.default,
    NoticeDetail: _noticeDetail.default
  },
  data: function data() {
    return {
      tabPosition: '1',
      handleShow: false,
      listQuery: {
        readStatus: 2,
        current: 1,
        size: 20
      },
      list: [],
      total: 0,
      listLoading: false,
      tabHeight: '100%',
      detailVisible: false,
      fwVisible: false,
      id: '',
      scanData: {},
      title: '公告详情',
      mloading: false,
      btnLoading: false
    };
  },
  watch: {
    $route: function $route(to, from) {
      this.tabPosition = this.$route.query.type || '';
      this.getList();
    }
  },
  mounted: function mounted() {
    var h = (0, _utils.tableHeight)();
    this.$nextTick(function () {
      this.tabHeight = h;
    });
  },
  activated: function activated() {
    this.tabPosition = this.$route.query.type || 1;
    this.getList();
  },
  methods: {
    getList: function getList() {
      var that = this;
      that.listLoading = true;
      this.list = [];
      this.total = 0;
      if (this.tabPosition == 1) {
        this.getxxList();
      } else {
        this.getggList();
      }
    },
    getggList: function getggList() {
      var _this = this;
      (0, _message.noticeList)(this.listQuery).then(function (res) {
        var data = res.data;
        _this.listLoading = false;
        if (data.code == 200) {
          _this.list = data.data.records;
          _this.total = data.data.total * 1;
        } else {
          _this.$message.error(data.msg);
        }
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    getxxList: function getxxList() {
      var _this2 = this;
      (0, _message.msgList)(this.listQuery).then(function (res) {
        var data = res.data;
        _this2.listLoading = false;
        if (data.code == 200) {
          _this2.list = data.data.records;
          _this2.total = data.data.total * 1;
        } else {
          _this2.$message.error(data.msg);
        }
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    submitForm: function submitForm() {
      this.list = [];
      this.total = 0;
      this.listQuery.current = 1;
      this.getList();
    },
    dialogClose: function dialogClose() {
      this.dialogVisible = false;
    },
    scan: function scan(row) {
      var _this3 = this;
      // 公告
      (0, _message.isReady)({
        readStatus: 1,
        msgId: row.pid,
        msgType: this.tabPosition
      }).then(function (res) {
        var data = res.data;
        if (data.code == 200) {
          _this3.getList();
          if (_this3.tabPosition == 1) {
            // switch (row.bussType * 1) {
            //   //工作流
            //   case 1:
            //     break
            //   // 防伪预警
            //   case 2:
            //     break
            //   // 防窜预警
            //   case 3:
            //     break
            // }
          } else {
            _this3.ggShow(row.pid);
          }
        } else {
          _this3.$message.error(data.msg);
        }
      });
    },
    ggShow: function ggShow(id) {
      this.id = id;
      this.detailVisible = true;
    },
    ggClose: function ggClose() {
      this.id = '';
      this.detailVisible = false;
    },
    fwClose: function fwClose() {
      this.id = '';
      this.fwVisible = false;
    },
    check: function check(row) {
      var _this4 = this;
      (0, _message.isReady)({
        readStatus: 1,
        msgId: row.pid,
        msgType: this.tabPosition
      }).then(function (res) {
        var data = res.data;
        if (data.code == 200) {
          _this4.getList();
        } else {
          _this4.$message.error(data.msg);
        }
      });
    },
    checkAll: function checkAll() {
      var _this5 = this;
      this.btnLoading = true;
      (0, _message.isReady)({
        readStatus: 2,
        msgType: this.tabPosition
      }).then(function (res) {
        _this5.btnLoading = false;
        var data = res.data;
        if (data.code == 200) {
          _this5.getList();
        } else {
          _this5.$message.error(data.msg);
        }
      }).catch(function () {
        _this5.btnLoading = false;
      });
    },
    del: function del(id) {
      var _this6 = this;
      this.$confirm('确认删除?将不可撤销', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        // var auth = this.authId('deletebtn', this.$route.meta.authList)
        (0, _message.deleteById)({
          moudleType: _this6.tabPosition,
          msgId: id
        }).then(function (res) {
          // var auth = this.authId('searchbtn', this.$route.meta.authList)
          if (res.data.code == 200) {
            _this6.$message({
              type: 'success',
              message: '操作成功!'
            });
            _this6.getList();
          } else {
            _this6.$message({
              type: 'error',
              message: res.data.msg
            });
          }
        });
      }).catch(function () {
        // var auth = this.authId('searchbtn', this.$route.meta.authList)
      });
    }
  }
};
exports.default = _default;