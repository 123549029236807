"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _templateManage = require("@/api/market/templateManage");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _config = _interopRequireDefault(require("@/views/marketingManage/marketingActivities/templateManage/config"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'MarketingManagemarketingActivitiestemplateManageindex',
  components: {
    Pagination: _Pagination.default,
    Content: _config.default
  },
  filters: {
    templateType: function templateType(index) {
      var comtype = {
        1: '集字',
        2: '扫码抽奖',
        3: '大转盘',
        4: '九宫格'
      };
      var result = comtype[index];
      return result;
    },
    templateState: function templateState(index) {
      var comtype = {
        // '0': '新增',
        1: '启用',
        2: '停用'
      };
      var result = comtype[index];
      return result;
    },
    issysbuild: function issysbuild(index) {
      var comtype = {
        1: '系统预置',
        0: '自定义'
      };
      var result = comtype[index];
      return result;
    }
  },
  data: function data() {
    return {
      handleShow: false,
      actlist: [{
        img: require('@/assets/images/hongbao.png'),
        title: '扫码抽奖',
        type: '4'
      }
      // {
      //   img: require("@/assets/images/zhuanpan.png"),
      //   title: "大转盘",
      //   type: "3"
      // },
      // {
      //   img: require("@/assets/images/jizi.png"),
      //   title: "集字",
      //   type: "1"
      // }
      // {
      //   img: require('@/assets/images/jiugongge.png'),
      //   title: '九宫格抽奖',
      //   type: '4'
      // }
      ],

      tabHeight: '100%',
      list: null,
      listLoading: false,
      total: 0,
      formInline: {
        pageNo: 1,
        pageSize: 20,
        startTime: '',
        endTime: '',
        name: null,
        state: null,
        templateType: null
      },
      dialogVisible: false,
      actDialogVisible: false,
      disabled: false,
      type: '',
      id: ''
    };
  },
  activated: function activated() {
    this.fetchData();
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    creatAct: function creatAct(row, statue) {
      console.log(row);
      this.type = row.templateType;
      this.id = row.templateId;
      this.disabled = statue;
      this.dialogVisible = true;
    },
    cancel: function cancel() {
      this.actDialogVisible = false;
    },
    close: function close() {
      this.dialogVisible = false;
      this.type = '';
      this.cancel();
      this.fetchData();
    },
    fetchData: function fetchData() {
      var _this = this;
      this.listLoading = true;
      var obj = {
        startTime: this.formInline.startTime ? this.formInline.startTime + ' 00:00:00' : '',
        endTime: this.formInline.endTime ? this.formInline.endTime + ' 23:59:59' : ''
      };
      Object.assign(this.formInline, obj);
      (0, _templateManage.getList)(this.formInline).then(function (res) {
        res = res.data;
        if (res.status === 200) {
          _this.list = res.data.list;
          _this.total = res.data.total;
        } else {
          _this.$message.closeAll();
          _this.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
        _this.listLoading = false;
      });
    },
    restForm: function restForm(refname) {
      this.$refs[refname].resetFields();
    },
    enable: function enable(id) {
      var _this2 = this;
      (0, _templateManage.templateEnable)(id).then(function (res) {
        res = res.data;
        if (res.status === 200) {
          _this2.$message.closeAll();
          _this2.$message({
            message: res.msg,
            type: 'success',
            duration: 3 * 1000
          });
          _this2.fetchData();
        } else {
          _this2.$message.closeAll();
          _this2.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
      });
    },
    disable: function disable(id) {
      var _this3 = this;
      (0, _templateManage.templateDisable)(id).then(function (res) {
        res = res.data;
        if (res.status === 200) {
          _this3.$message.closeAll();
          _this3.$message({
            message: res.msg,
            type: 'success',
            duration: 3 * 1000
          });
          _this3.fetchData();
        } else {
          _this3.$message.closeAll();
          _this3.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
      });
    }
  }
};
exports.default = _default;