"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Added',
  props: {
    title: {
      type: String,
      default: ''
    },
    dialogVisible: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      form: {
        prizeName: '',
        prizeAlias: '',
        remark: '',
        openDataCollection: '',
        coll: '',
        domains: [],
        ruleFont: '',
        checkList: [],
        num: ''
      },
      rules: {},
      btnLoading: false,
      disabled: false,
      arr: ['一', '二', '三', '四', '五', '六', '七'],
      isDisabled: false,
      isFontAll: false,
      isFont: false,
      modeFont: false,
      spanFont: false
    };
  },
  methods: {
    removeDomain: function removeDomain(item) {
      var index = this.form.domains.indexOf(item);
      if (index !== -1) {
        this.form.domains.splice(index, 1);
        console.log(this.form.domains);
        if (this.form.domains.length <= 5) {
          this.isDisabled = false;
        }
      }
    },
    addDomain: function addDomain() {
      if (this.form.domains.length >= 5) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
      this.form.domains.push({
        value: '',
        key: Date.now()
      });
    },
    handleValueFont: function handleValueFont(val) {
      this.form.domains.length = 1;
      this.modeFont = val == 1;
      this.spanFont = val == 2;
    },
    handleValueChange: function handleValueChange(val) {
      this.isFontAll = val == 1;
      this.isFont = val == 2;
    },
    handleCancel: function handleCancel() {
      this.form = {
        prizeName: '',
        prizeAlias: '',
        remark: '',
        openDataCollection: '',
        coll: '',
        domains: [{
          value: ''
        }],
        ruleFont: '',
        checkList: [],
        num: ''
      };
      this.$emit('handleCancel');
    }
  }
};
exports.default = _default;