var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.detailVisible,
        width: "1200px !important",
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.detailVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.mloading,
              expression: "mloading",
            },
          ],
        },
        [
          _c(
            "div",
            { staticClass: "stepArea" },
            [
              _c("div", { staticClass: "billTitle" }, [
                _c("img", { attrs: { src: require("@/images/topimg.png") } }),
                _vm._v(
                  "\n        单据号 " + _vm._s(_vm.detailData.no) + "\n        "
                ),
                _c(
                  "span",
                  {
                    staticClass: "billType",
                    class: _vm._f("typeClass")(_vm.detailData.status),
                  },
                  [
                    _vm.detailData.status == "0"
                      ? _c("span", [_vm._v("待审核")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.detailData.status == "1"
                      ? _c("span", [_vm._v("审核通过")])
                      : _vm._e(),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-steps",
                {
                  staticStyle: { "margin-top": "20px" },
                  attrs: { active: _vm.active, "finish-status": "success" },
                },
                [
                  _c("el-step", { attrs: { title: "待审核" } }),
                  _vm._v(" "),
                  _c("el-step", { attrs: { title: "审核通过" } }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "itemTitle" }, [_vm._v("基本信息")]),
          _vm._v(" "),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("组织机构:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm._f("filterOrg")(_vm.detailData.orgId))),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c(
                    "div",
                    { staticClass: "form-div", staticStyle: { width: "90px" } },
                    [_vm._v("经销商编号:")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.detailData.channelArchiveCode)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c(
                    "div",
                    { staticClass: "form-div", staticStyle: { width: "90px" } },
                    [_vm._v("经销商名称:")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.detailData.channelArchiveName)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("核销返利:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.detailData.money)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("直返金额:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.detailData.dmoney)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              false
                ? _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c(
                        "div",
                        {
                          staticClass: "form-div",
                          staticStyle: { width: "110px" },
                        },
                        [_vm._v("终端兑付金额:")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.detailData.money)),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c(
                    "div",
                    {
                      staticClass: "form-div",
                      staticStyle: { width: "120px" },
                    },
                    [_vm._v("经销商解冻金额:")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.detailData.freezmoney)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("创建时间:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.detailData.createTime)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { lg: 24, md: 24 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("备注:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.detailData.remakes)),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "middleLine" }),
          _vm._v(" "),
          _c("div", { staticClass: "itemTitle" }, [_vm._v("单据明细")]),
          _vm._v(" "),
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.tabName,
                callback: function ($$v) {
                  _vm.tabName = $$v
                },
                expression: "tabName",
              },
            },
            [
              _c("el-tab-pane", { attrs: { label: "终端兑付单", name: "0" } }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { label: "经销商直返返利流水", name: "1" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.tabName == "0"
            ? _c(
                "div",
                [
                  _c(
                    "el-table",
                    {
                      key: "0",
                      staticClass: "detailTable",
                      staticStyle: { width: "100%", "margin-top": "1px" },
                      attrs: {
                        data: _vm.checkList,
                        fit: "",
                        border: _vm.tableConfig.border,
                        stripe: _vm.tableConfig.stripe,
                        "max-height": "300",
                        "highlight-current-row": "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "终端编号",
                          prop: "channelCode",
                          align: _vm.tableConfig.align,
                          width: "160",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "终端名称",
                          prop: "channelArchiveName",
                          align: _vm.tableConfig.align,
                          width: "160",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "经销商编号",
                          prop: "checkerCode",
                          align: _vm.tableConfig.align,
                          width: "160",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "经销商名称",
                          prop: "checkerName",
                          align: _vm.tableConfig.align,
                          width: "160",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "申请时间",
                          prop: "createTime",
                          align: _vm.tableConfig.align,
                          width: "160",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "兑付时间",
                          prop: "updateTime",
                          align: _vm.tableConfig.align,
                          width: "160",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "终端兑付金额",
                          prop: "money",
                          align: _vm.tableConfig.align,
                          width: "160",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "经销商解冻金额",
                          prop: "checkerFreezingMoney",
                          align: _vm.tableConfig.align,
                          width: "160",
                          "show-overflow-tooltip": "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.tabName == "1"
            ? _c(
                "div",
                [
                  _c(
                    "el-table",
                    {
                      key: "1",
                      staticClass: "detailTable",
                      staticStyle: { width: "100%", "margin-top": "1px" },
                      attrs: {
                        data: _vm.rebateList,
                        fit: "",
                        border: _vm.tableConfig.border,
                        stripe: _vm.tableConfig.stripe,
                        "max-height": "300",
                        "highlight-current-row": "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "单号",
                          prop: "rebateNo",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "红包返现(元)",
                          prop: "quantity",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          width: "120",
                          align: _vm.tableConfig.align,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.scanDetail(row)
                                        },
                                      },
                                    },
                                    [_vm._v("查看详情")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1454779664
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "middleLine" }),
          _vm._v(" "),
          _c(
            "el-collapse",
            {
              staticClass: "detailCollapse",
              attrs: { accordion: "" },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-collapse-item",
                { attrs: { title: "操作日志", name: "1" } },
                [
                  _c("template", { slot: "title" }, [_vm._v("操作日志")]),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.tradeOperateLogDTOS,
                        fit: "",
                        "highlight-current-row": "",
                        "max-height": "300",
                        border: _vm.tableConfig.border,
                        stripe: _vm.tableConfig.stripe,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "确认结果",
                          prop: "type",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                row.type == "0"
                                  ? _c("span", [_vm._v("创建")])
                                  : _vm._e(),
                                _vm._v(" "),
                                row.type == "1"
                                  ? _c("span", [_vm._v("审核通过")])
                                  : _vm._e(),
                                _vm._v(" "),
                                row.type == "2"
                                  ? _c("span", [_vm._v("审核拒绝")])
                                  : _vm._e(),
                                _vm._v(" "),
                                row.type == "3"
                                  ? _c("span", [_vm._v("删除/待用")])
                                  : _vm._e(),
                                _vm._v(" "),
                                row.type == "4"
                                  ? _c("span", [_vm._v("修改")])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "确认人角色",
                          prop: "creatorType",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                row.creatorType == "0"
                                  ? _c("span", [_vm._v("企业")])
                                  : _vm._e(),
                                _vm._v(" "),
                                row.creatorType == "1"
                                  ? _c("span", [_vm._v("经销商")])
                                  : _vm._e(),
                                _vm._v(" "),
                                row.creatorType == "2"
                                  ? _c("span", [_vm._v("终端")])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "确认人",
                          prop: "creatorName",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "确认时间",
                          prop: "createTime",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "备注",
                          prop: "comments",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.settype == "sh"
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "remarks" },
                  [
                    _c("div", { staticClass: "middleLine" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "itemTitle" }, [_vm._v("备注")]),
                    _vm._v(" "),
                    _c("el-input", {
                      attrs: {
                        type: "textarea",
                        "show-word-limit": "",
                        maxlength: "500",
                        placeholder: "请输入内容",
                      },
                      model: {
                        value: _vm.remarks,
                        callback: function ($$v) {
                          _vm.remarks = $$v
                        },
                        expression: "remarks",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "middleLine" }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "btns-group" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", disabled: _vm.disabled },
                        on: { click: _vm.doSh },
                      },
                      [_vm._v("审核通过")]
                    ),
                    _vm._v(" "),
                    _c("el-button", { on: { click: _vm.closeSh } }, [
                      _vm._v("关闭"),
                    ]),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      false
        ? _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "default", plain: "" },
                  on: { click: _vm.handleClose },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("codeScan", {
        attrs: {
          codeVisible: _vm.codeVisible,
          channelArchiveId: _vm.id,
          rebateNo: _vm.rebateNo,
        },
        on: { dialogHide: _vm.hideDialog },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }