"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.function.name");
var _basic = require("@/api/mark/basic.js");
var _index = require("@/api/mark/coding_service/create_code_mange/index");
var _waves = _interopRequireDefault(require("@/directive/waves"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import seach from './components/seach'

var selectOptions = {
  supplierType: [{
    id: 1,
    name: '标签厂'
  }, {
    id: 2,
    name: '制盖厂'
  }],
  auditStatus: [{
    id: 1,
    name: '生成中'
  }, {
    id: 3,
    name: '生成失败'
  }, {
    id: 2,
    name: '生成成功'
  }],
  applyType: [{
    id: 1,
    name: '套标申请'
  }, {
    id: 2,
    name: '后关联申请'
  }, {
    id: 3,
    name: '制盖申请'
  }]
};
var _default = {
  name: 'MarkcodingServicecreateCodeMangeindex',
  components: {
    Pagination: _Pagination.default
  },
  directives: {
    waves: _waves.default
  },
  filters: {
    codeType: function codeType(status) {
      if (!String(status)) return '';
      var statusMap = {
        1: '营销码',
        2: '防伪码',
        3: '物流码'
      };
      return statusMap[status];
    },
    packLevel: function packLevel(status) {
      if (!String(status)) return '';
      var statusMap = {
        1: '盖码',
        2: '瓶码',
        3: '盒码',
        4: '箱码',
        5: '托码'
      };
      return statusMap[status];
    },
    formatDate: function formatDate(time) {
      if (!time) return '';
      var date = new Date(time);
      return (0, _basic.formatDate)(date, 'yyyy-MM-dd hh:mm:ss');
    },
    supplierType: function supplierType(v) {
      var t = '';
      for (var i in selectOptions.supplierType) {
        if (selectOptions.supplierType[i].id == v) {
          t = selectOptions.supplierType[i].name;
          break;
        }
      }
      return t;
    }
  },
  data: function data() {
    return {
      handleShow: false,
      chosedate: [],
      pickerOptions2: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      tabHeight: '100%',
      tableKey: 0,
      list: null,
      downlist: [],
      total: 0,
      downTotal: 0,
      listLoading: true,
      downlistLoading: true,
      listQuery: {
        applyMarkType: '',
        applyStyle: '',
        current: 1,
        size: 20,
        supplierName: '',
        supplierType: '',
        generateStatus: '',
        markOrderNo: '',
        endDate: '',
        beginDate: ''
      },
      selectOptions: selectOptions,
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        approval: '授权码审核详情'
      },
      dialogPvVisible: false,
      rules: {
        Reason: [{
          required: true,
          message: '输入url描述内容',
          trigger: 'blur'
        }]
      },
      downloadLoading: false,
      btnPowers: null
    };
  },
  activated: function activated() {
    // this.btnPowers = setPermission(this, 'ZMSCLB')
    this.btnPowers = {};
    // var time = normalDate()
    // this.listQuery.beginDate = time[0]
    // this.listQuery.endDate = time[1]
    this.getList();
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _basic.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    mouseOver: function mouseOver() {
      this.$refs.arrow.style.color = '#409eff';
      this.$refs.arrowtest.style.color = '#409eff';
    },
    mouseLeave: function mouseLeave() {
      this.$refs.arrow.style.color = '#595959';
      this.$refs.arrowtest.style.color = '#595959';
    },
    getList: function getList() {
      var _this = this;
      this.authId('searchbtn', this.$route.meta.authList);
      this.listLoading = true;
      var query = JSON.parse(JSON.stringify(this.listQuery));
      if (this.listQuery.applyStyle == 3) {
        query.applyMarkType = 2;
        query.applyStyle = '';
      }
      // if (this.chosedate && this.chosedate.length) {
      //   query.beginDate = this.chosedate[0]
      //   query.endDate = this.chosedate[1]
      // } else {
      //   query.beginDate = ''
      //   query.endDate = ''
      // }
      (0, _index.fetchList)(query).then(function (response) {
        var data = response.data;
        _this.list = null;
        _this.total = 0;
        _this.listLoading = false;
        if (data.code != 200) {
          _this.$message.error(data.msg);
          return true;
        }
        if (data.data != null) {
          _this.list = data.data.records;
          _this.total = Number(data.data.total);
        } else {
          _this.list = null;
          _this.total = 0;
        }
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.chosedate = [];
      this.$refs.searchform.resetFields();
      this.getList();
    },
    getDetails: function getDetails(row) {
      var _this2 = this;
      if (row.pid == null || row.pid == '') {
        this.$message.error('提交信息不全');
        return false;
      }
      this.downlistLoading = true;
      (0, _index.fetchDetails)(row.pid).then(function (response) {
        var data = response.data;
        if (data.code != 200) {
          _this2.$message.error(data.msg);
          return true;
        }
        _this2.dialogFormVisible = true;
        _this2.downlist = data.data;
        _this2.downlistLoading = false;
      }).catch(function () {
        _this2.downlistLoading = false;
      });
    },
    clear: function clear() {
      this.downlist = [];
      this.dialogFormVisible = false;
    },
    handleUpdate: function handleUpdate(row, data_type) {
      var _this3 = this;
      this.$confirm('制码订单号：[' + row.markOrderNo + ']是否重新生成码?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        if (row.pid == null || row.pid == '') {
          _this3.$message.error('提交信息不全');
          return false;
        }
        _this3.authId('updatebtn', _this3.$route.meta.authList);
        (0, _index.generate)(row.pid).then(function (res) {
          _this3.authId('searchbtn', _this3.$route.meta.authList);
          var data = res.data;
          if (data.code != 200) {
            _this3.$message.error(data.msg);
            return false;
          }
          _this3.getList();
          _this3.$message({
            message: '重新申请成功',
            type: 'success'
          });
        });
      }).catch(function () {});
    }
  }
};
exports.default = _default;