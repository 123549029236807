var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        "append-to-body": true,
        title: "选择产品",
        visible: _vm.proSelVisible,
        "before-close": _vm.selCancel,
        width: "1200px !important",
      },
      on: {
        "update:visible": function ($event) {
          _vm.proSelVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "page-container" }, [
        _c(
          "div",
          { staticClass: "page-section" },
          [
            _c(
              "el-form",
              {
                ref: "searchForm",
                staticClass: "search-condition",
                attrs: {
                  model: _vm.searchForm,
                  "status-icon": "",
                  "label-width": "100px",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "cols" },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "产品分类:",
                                  prop: "productClassId",
                                },
                              },
                              [
                                _c("el-cascader", {
                                  attrs: {
                                    options: _vm.normalList,
                                    props: {
                                      value: "pId",
                                      label: "productClassName",
                                      children: "children",
                                    },
                                    filterable: "",
                                  },
                                  model: {
                                    value: _vm.searchForm.productClassId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "productClassId",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.productClassId",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 8, prop: "productCode" } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "产品编码:",
                                  prop: "productCode",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请输入",
                                  },
                                  model: {
                                    value: _vm.searchForm.productCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "productCode",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.productCode",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "产品名称:",
                                  prop: "productName",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请输入",
                                  },
                                  model: {
                                    value: _vm.searchForm.productName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "productName",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.productName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "产品品牌:",
                                  prop: "productBrandId",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请选择",
                                    },
                                    model: {
                                      value: _vm.searchForm.productBrandId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "productBrandId",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.productBrandId",
                                    },
                                  },
                                  _vm._l(_vm.brandList, function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.brandName,
                                        value: item.pId,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { "label-width": "20px" } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "primary",
                                      size: "mini",
                                      icon: "el-icon-search",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.searchForm.current = 1
                                        _vm.getList()
                                      },
                                    },
                                  },
                                  [_vm._v("查询")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      size: "mini",
                                      icon: "el-icon-refresh",
                                    },
                                    on: { click: _vm.resetForm },
                                  },
                                  [_vm._v("重置")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                key: _vm.tableKey,
                ref: "multipleTable",
                staticStyle: { width: "100%", "margin-top": "1px" },
                attrs: {
                  data: _vm.tableData,
                  height: "400px",
                  border: "",
                  fit: "",
                  "row-key": "pId",
                  "highlight-current-row": "",
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    label: "产品图片",
                    prop: "configCode",
                    align: _vm.tableConfig.align,
                    width: "125",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c("el-image", {
                            staticStyle: { width: "100px", height: "100px" },
                            attrs: {
                              src: row.productImages
                                ? row.productImages
                                : _vm.imgUrl,
                              "preview-src-list": [
                                row.productImages
                                  ? row.productImages
                                  : _vm.imgUrl,
                              ],
                              fit: "contain",
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "产品编码",
                    prop: "productCode",
                    align: _vm.tableConfig.align,
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "产品名称",
                    prop: "productName",
                    align: _vm.tableConfig.align,
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "产品分类",
                    prop: "productClassName",
                    align: _vm.tableConfig.align,
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "产品品牌",
                    prop: "brandName",
                    align: _vm.tableConfig.align,
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "规格",
                    prop: "productModel",
                    align: _vm.tableConfig.align,
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "操作",
                    fixed: "right",
                    align: _vm.tableConfig.align,
                    width: "80",
                    "class-name": "small-padding fixed-width",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.submit(row)
                                },
                              },
                            },
                            [_vm._v("选择")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("pagination", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.total > 0,
                  expression: "total > 0",
                },
              ],
              attrs: {
                layout: "total, sizes, prev, pager, next, jumper",
                total: _vm.total,
                page: _vm.searchForm.current,
                limit: _vm.searchForm.size,
              },
              on: {
                "update:page": function ($event) {
                  return _vm.$set(_vm.searchForm, "current", $event)
                },
                "update:limit": function ($event) {
                  return _vm.$set(_vm.searchForm, "size", $event)
                },
                pagination: _vm.getList,
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }