var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.dialogVisible,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.mloading,
              expression: "mloading",
            },
          ],
          ref: "scanForm",
          staticClass: "info-items",
          attrs: {
            model: _vm.scanData,
            rules: _vm.rules,
            "label-position": "right",
            "label-width": "150px",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "退货入库单号:", prop: "billNo" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "350px" },
                        attrs: {
                          clearable: "",
                          maxlength: "15",
                          "show-word-limit": "",
                          disabled: _vm.disabled.num,
                          readonly: "",
                        },
                        on: {
                          input: function (e) {
                            return (_vm.scanData.billNo = _vm.validSe(e))
                          },
                        },
                        model: {
                          value: _vm.scanData.billNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.scanData, "billNo", $$v)
                          },
                          expression: "scanData.billNo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "入库企业:", prop: "inOrgId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "350px" },
                          attrs: { clearable: "", placeholder: "请选择" },
                          on: { change: _vm.setScanFactory },
                          model: {
                            value: _vm.scanData.inOrgId,
                            callback: function ($$v) {
                              _vm.$set(_vm.scanData, "inOrgId", $$v)
                            },
                            expression: "scanData.inOrgId",
                          },
                        },
                        _vm._l(_vm.inOrgList, function (item) {
                          return _c("el-option", {
                            key: item.orgId,
                            attrs: { label: item.orgName, value: item.orgId },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "入库企业类型:", prop: "inOrgType" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "350px" },
                          attrs: { clearable: "", placeholder: "请选择" },
                          on: { change: _vm.setScanFactory },
                          model: {
                            value: _vm.scanData.inOrgType,
                            callback: function ($$v) {
                              _vm.$set(_vm.scanData, "inOrgType", $$v)
                            },
                            expression: "scanData.inOrgType",
                          },
                        },
                        _vm._l(_vm.storeTypes, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "入库库房:", prop: "inStoreId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "350px" },
                          attrs: { clearable: "", placeholder: "请选择" },
                          on: { change: _vm.setScanFactory },
                          model: {
                            value: _vm.scanData.inStoreId,
                            callback: function ($$v) {
                              _vm.$set(_vm.scanData, "inStoreId", $$v)
                            },
                            expression: "scanData.inStoreId",
                          },
                        },
                        _vm._l(_vm.factoryList, function (item) {
                          return _c("el-option", {
                            key: item.pId,
                            attrs: {
                              label: item.storeHouseName,
                              value: item.pId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "退货原因:", prop: "returnReason" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea" },
                        model: {
                          value: _vm.scanData.returnReason,
                          callback: function ($$v) {
                            _vm.$set(_vm.scanData, "returnReason", $$v)
                          },
                          expression: "scanData.returnReason",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品明细:", prop: "materialCode" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.openSel },
                        },
                        [_vm._v("添加产品")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.listLoading,
                              expression: "listLoading",
                            },
                          ],
                          key: _vm.tableKey,
                          staticStyle: { width: "100%", "margin-top": "1px" },
                          attrs: {
                            data: _vm.proList,
                            border: "",
                            fit: "",
                            "highlight-current-row": "",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "序号",
                              type: "index",
                              width: "120",
                              align: _vm.tableConfig.align,
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "产品分类",
                              prop: "productClassName",
                              align: _vm.tableConfig.align,
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "产品编码",
                              prop: "productCode",
                              align: _vm.tableConfig.align,
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "产品名称",
                              prop: "productName",
                              align: _vm.tableConfig.align,
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "包装比例",
                              prop: "packScaleExpression",
                              align: _vm.tableConfig.align,
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "包装比例描述",
                              prop: "packScaleDesc",
                              align: _vm.tableConfig.align,
                              width: "110",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "生产批次",
                              prop: "packScaleDesc",
                              align: _vm.tableConfig.align,
                              width: "160",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { margin: "20px auto" },
                                        attrs: {
                                          label: "",
                                          "label-width": "0",
                                          prop: "prodBatch",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { maxlength: "20", min: "1" },
                                          model: {
                                            value: row.prodBatch,
                                            callback: function ($$v) {
                                              _vm.$set(row, "prodBatch", $$v)
                                            },
                                            expression: "row.prodBatch",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "计划产量(箱)",
                              prop: "packScaleDesc",
                              align: _vm.tableConfig.align,
                              width: "160",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { margin: "20px auto" },
                                        attrs: {
                                          label: "",
                                          "label-width": "0",
                                          prop: "sumPlan",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { maxlength: "6", min: "1" },
                                          on: { input: _vm.setSum },
                                          model: {
                                            value: row.sumPlan,
                                            callback: function ($$v) {
                                              _vm.$set(row, "sumPlan", $$v)
                                            },
                                            expression: "row.sumPlan",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "最小单位",
                              prop: "packScaleDesc",
                              align: _vm.tableConfig.align,
                              width: "80",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("minSum")(
                                            row.sumPlan,
                                            row.packScaleExpression,
                                            row
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              align: _vm.tableConfig.align,
                              width: "80",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.proDel(row)
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { staticClass: "dialog-footer" },
                    [
                      _vm.submitType != 3
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.btn_loading,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.submitForm(2)
                                },
                              },
                            },
                            [_vm._v("提 交")]
                          )
                        : _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.btn_loading,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.submitForm(3)
                                },
                              },
                            },
                            [_vm._v("提 交")]
                          ),
                      _vm._v(" "),
                      _c("el-button", { on: { click: _vm.handleClose } }, [
                        _vm._v("关 闭"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("product-dialog", {
        ref: "selProduct",
        attrs: {
          "is-code": "2",
          "pro-sel-visible": _vm.proShow,
          data: _vm.proList,
        },
        on: {
          "update:data": function ($event) {
            _vm.proList = $event
          },
          change: _vm.setSel,
          close: _vm.proClose,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }