"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.map");
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
var _dealer = require("@/api/basic/dealer");
var _product = require("@/api/basic/product");
var _org = require("@/api/basic/org");
var _channel = require("@/api/basic/channel");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _channelSelectDialog = _interopRequireDefault(require("@/components/channelSelectDialog"));
var _personnelCheckboxDialog = _interopRequireDefault(require("@/components/personnelCheckboxDialog"));
var _utils = require("@/utils");
var _auth = require("@/utils/auth");
var _factory = require("@/api/basic/factory");
var _methods; //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'BasicDatachannelmangeterminalfileindex',
  components: {
    Pagination: _Pagination.default,
    PersonnelCheckboxDialog: _personnelCheckboxDialog.default,
    ChannelSelectDialog: _channelSelectDialog.default,
    Treeselect: _vueTreeselect.default
  },
  data: function data() {
    var _this = this;
    var validateOrg = function validateOrg(rule, value, callback) {
      if (_this.orgselList.length == 0) {
        callback(new Error('所属公司不能为空，请维护'));
      } else {
        callback();
      }
    };
    var validateSSQ = function validateSSQ(rule, value, callback) {
      console.log(rule, value, 123123);
      if (_this.ruleForm.countyName == '') {
        callback(new Error("地址不能为空，请维护"));
      } else {
        callback();
      }
    };
    var validatelongitude = function validatelongitude(rule, value, callback) {
      var longreg = /^(\-|\+)?(((\d|[1-9]\d|1[0-7]\d|0{1,3})\.\d{6})|180\.0{6})$/;
      if (value) {
        if (!longreg.test(value)) {
          callback(new Error('经度格式不正确'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var validatelatitude = function validatelatitude(rule, value, callback) {
      var latreg = /^(\-|\+)?([0-8]?\d{1}\.\d{6}|90\.0{6}|[0-8]?\d{6})$/;
      if (value) {
        if (!latreg.test(value)) {
          callback(new Error('纬度格式不正确'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var validatepostalCode = function validatepostalCode(rule, value, callback) {
      if (value) {
        if (value.length != 6) {
          callback(new Error('邮政编码格式不正确'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var validatemaster = function validatemaster(rule, value, callback) {
      if (value) {
        if (_this.ruleForm.masterChannelId == _this.ruleForm.pid) {
          callback(new Error('所属主体不能是自己'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      showAuditFields: false,
      ProvinceList: [],
      CityList: [],
      CountyList: [],
      handleShow: false,
      centerDialogVisible: false,
      pId: false,
      checkStrong: 1,
      strongMsgList: [],
      value: '',
      channelSelectType: '',
      masterName: '',
      masterChannelName: '',
      myHeaders: {
        Authorization: 'Bearer ' + (0, _auth.getToken)(),
        btnId: ''
      },
      props: {
        value: 'pId',
        label: 'orgName',
        children: 'children',
        checkStrictly: true
      },
      listLoading: false,
      btnLoading: false,
      downLoading: false,
      areaList: [],
      map: new Map(),
      areaInUseList: [],
      classList: [],
      gradeList: [],
      page2: false,
      disabled: false,
      tableKey: '',
      tabHeight: '100%',
      channelOrgId: '',
      area: [],
      areaSel: [],
      dialogSalesmanList: false,
      size: 10,
      current: 1,
      salesmanTotal: 0,
      salesmanSize: 10,
      salesmanList: [],
      salesManName: '',
      salesManNameq: '',
      ids: [],
      oldList: [],
      isShow: false,
      querysalesman: {
        orgId: '',
        phoneNo: '',
        salesManAccount: '',
        salesManName: ''
      },
      searchForm: {
        keyword: '',
        current: 1,
        size: 20,
        isPC: 1
      },
      tableData: [],
      total: 0,
      orgList: [],
      orgInUseList: [],
      archivesList: [],
      multipleSelection: [],
      normalList: [],
      proClassList: [],
      proNormal: [],
      dialogVisible: false,
      scanVisible: false,
      title: '终端档案',
      mloading: false,
      orgselList: [],
      porList: [],
      salesmanIds: [],
      scanData: {},
      ruleForm: {
        creditCode: '',
        sourceEnum: 1,
        businessLicense: '',
        channelName: '',
        channelType: 2,
        contacts: '',
        detailedAddress: '',
        phone: '',
        cardNo: '',
        auditStatus: ''
      },
      rules: {
        cardNo: [{
          validator: this.validatecardNo,
          trigger: 'blur'
        }],
        channelName: [{
          required: true,
          message: '终端名称不能为空，请维护',
          trigger: 'change'
        }],
        countyName: [{
          required: true,
          validator: validateSSQ,
          trigger: "change"
        }],
        orgselList: [{
          required: true,
          validator: validateOrg,
          trigger: 'change'
        }],
        latitude: [{
          validator: validatelatitude,
          trigger: 'change'
        }],
        longitude: [{
          validator: validatelongitude,
          trigger: 'change'
        }],
        postalCode: [{
          validator: validatepostalCode,
          trigger: 'change'
        }],
        masterChannelId: [{
          validator: validatemaster,
          trigger: 'change'
        }],
        email: [{
          type: 'email',
          message: '电子邮箱格式不正确',
          trigger: ['blur', 'change']
        }],
        contacts: [{
          required: true,
          message: '联系人不能为空，请维护',
          trigger: 'change'
        }],
        phone: [{
          required: true,
          message: '手机号不能为空，请维护',
          trigger: 'change'
        }, {
          pattern: /^1[23456789]\d{9}$/,
          message: '请输入正确的手机号',
          trigger: 'blur'
        }],
        regionId: [{
          required: true,
          message: '所属大区不能为空，请维护',
          trigger: 'change'
        }]
      },
      uploadVisible: false,
      uploadData: [],
      uploadForm: {
        channelType: 2,
        uploadFile: ''
      },
      uploadRules: {},
      fileList: [],
      imgList: [],
      depList: [],
      firmList: []
    };
  },
  computed: {
    // 设置上传地址
    uploadUrl: function uploadUrl() {
      return process.env.VUE_APP_BASE_API + '/api-basic/jcChannelArchives/importExcel';
    },
    uploadImg: function uploadImg() {
      return process.env.VUE_APP_BASE_API + '/api-basic/jc/file/upload/uploadImg';
    }
  },
  mounted: function mounted() {
    var that = this;
    var h = window.innerHeight - 380 + 'px';
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    this.getList();
  },
  methods: (_methods = {
    DoAudit: function DoAudit(row) {
      var _this2 = this;
      this.showAuditFields = true;
      var that = this;
      that.title = '终端注册审核';
      this.mloading = true;
      this.ruleForm = {
        channelCode: row.channelCode,
        channelName: row.channelName,
        contacts: row.contacts,
        phone: row.phone,
        cardNo: row.cardNo,
        detailedAddress: row.detailedAddress,
        createUserName: row.userName,
        createTime: row.createTime,
        creditCode: row.creditCode,
        auditStatus: row.auditStatus,
        remarks: row.remarks,
        pId: row.pId
      };
      if (row.businessLicense) {
        var imgArr = row.businessLicense.split(',');
        imgArr.forEach(function (item) {
          _this2.imgList.push({
            url: item,
            response: {
              data: item
            }
          });
        });
      }
      this.mloading = false;
      this.dialogVisible = true;
    },
    validateSSQ: function validateSSQ(rule, value, callback) {
      if (this.ruleForm.countyName == '') {
        callback(new Error("地址不能为空，请维护"));
      } else {
        callback();
      }
    },
    validatecardNo: function validatecardNo(rule, value) {
      return new Promise(function (resolve, reject) {
        var regExp = /(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
        if (value && !regExp.test(value)) {
          reject(new Error('请输入有效的身份证号'));
        } else {
          resolve();
        }
      });
    },
    changetab: function changetab() {
      this.$forceUpdate();
    }
  }, (0, _defineProperty2.default)(_methods, "changetab", function changetab(tab) {
    if (tab == 0) {
      this.ruleForm.list = [];
    }
  }), (0, _defineProperty2.default)(_methods, "setStrong", function setStrong() {
    var _this3 = this;
    this.btnLoading = true;
    (0, _channel.checkStrong)({
      pId: this.pId,
      checkStrong: this.checkStrong
    }).then(function (response) {
      _this3.btnLoading = false;
      if (response.data.code != 200) {
        _this3.$message.error(response.data.msg);
        return;
      } else {
        _this3.$message({
          type: 'success',
          message: '修改成功'
        });
        _this3.getList();
        _this3.centerDialogVisible = false;
      }
    }).catch(function () {
      _this3.btnLoading = false;
    });
  }), (0, _defineProperty2.default)(_methods, "openTSel", function openTSel() {
    this.channelSelectType = 1;
    this.$refs.channelSelect.proSelVisible = true;
  }), (0, _defineProperty2.default)(_methods, "openCSel", function openCSel() {
    this.channelSelectType = 2;
    this.$refs.channelSelect.proSelVisible = true;
  }), (0, _defineProperty2.default)(_methods, "setCSel", function setCSel(val) {
    if (this.channelSelectType == 1) {
      this.searchForm.masterChannelId = val.pId;
      this.masterName = val.channelName;
    }
    if (this.channelSelectType == 2) {
      this.ruleForm.masterChannelId = val.pId;
      this.masterChannelName = val.channelName;
    }
  }), (0, _defineProperty2.default)(_methods, "getList", function getList() {
    this.authId('searchbtn', this.$route.meta.authList);
    var that = this;
    that.listLoading = true;
    var submitData = (0, _utils.filterKeyNull)(that.searchForm);
    if (that.channelOrgId) {
      submitData.channelOrgId = that.channelOrgId[that.channelOrgId.length - 1];
    }
    // if (that.area) {
    //   submitData.provinceCode = that.area[0]
    //   submitData.cityCode = that.area[1] || ''
    //   submitData.countyCode = that.area[2] || ''
    // }
    (0, _channel.auditList)(submitData).then(function (response) {
      if (response.data.code != 200) {
        that.$message.error(response.data.msg);
        return;
      } else {
        if (response.data.data != null) {
          that.total = parseInt(response.data.data.total);
          that.tableData = response.data.data.records;
        } else {
          that.total = 0;
          that.tableData = [];
        }
        that.listLoading = false;
      }
    }).catch(function (res) {
      that.listLoading = false;
    });
  }), (0, _defineProperty2.default)(_methods, "ordSel", function ordSel(val) {
    var that = this;
    if (val && val.length) {
      that.$refs.perCheckbox.searchForm.orgId = val[val.length - 1];
    } else {
      that.$refs.perCheckbox.searchForm.orgId = '';
    }
  }), (0, _defineProperty2.default)(_methods, "resetForm", function resetForm(formName) {
    this.searchForm = {
      current: 1,
      size: 20,
      keyword: '',
      isPC: 1
    };
    this.masterName = '';
    this.channelOrgId = '';
    this.salesManName = '';
    this.area = [];
    this.getList();
  }), (0, _defineProperty2.default)(_methods, "handleSelectionChange", function handleSelectionChange(val) {
    this.multipleSelection = val;
    if (this.multipleSelection.length) {
      this.ids = this.multipleSelection.map(function (item) {
        return item.pId;
      });
    }
  }), (0, _defineProperty2.default)(_methods, "filterparentList", function filterparentList(val) {
    var that = this;
    return this.normalList.filter(function (number) {
      if (number.pId == val) {
        that.orgselList.unshift(val);
        if (number.parentPid) {
          that.filterparentList(number.parentPid);
        }
      }
    });
  }), (0, _defineProperty2.default)(_methods, "filterPorList", function filterPorList(val, item) {
    var that = this;
    var arr = item || [];
    this.proNormal.forEach(function (number) {
      if (number.pId == val) {
        arr.unshift(val);
        if (number.parentId) {
          that.filterPorList(number.parentId, arr);
        }
      }
    });
    return arr;
  }), (0, _defineProperty2.default)(_methods, "add", function add() {
    this.myHeaders.btnId = this.authId('addbtn', this.$route.meta.authList);
    this.title = '添加终端';
    this.dialogVisible = true;
    this.showAuditFields = false;
  }), (0, _defineProperty2.default)(_methods, "scanClose", function scanClose() {
    this.scanData = {};
    this.imgList = [];
    this.scanVisible = false;
    this.authId('searchbtn', this.$route.meta.authList);
  }), (0, _defineProperty2.default)(_methods, "detail", function detail(row) {
    this.authId('detailbtn', this.$route.meta.authList);
    this.title = '终端注册认证详情';
    this.scanVisible = true;
    this.mloading = true;
    this.scanData = {
      channelCode: row.channelCode,
      channelName: row.channelName,
      contacts: row.contacts,
      phone: row.phone,
      cardNo: row.cardNo,
      detailedAddress: row.detailedAddress,
      createUserName: row.userName,
      createTime: row.createTime,
      regionName: row.regionName,
      creditCode: row.creditCode,
      auditStatus: row.auditStatus,
      remarks: row.remarks
    };
    if (row.businessLicense) {
      this.scanData.imgList = row.businessLicense.split(',');
    }
    this.mloading = false;
  }), (0, _defineProperty2.default)(_methods, "DoEdit", function DoEdit(row) {
    var _this4 = this;
    console.log(row);
    var that = this;
    that.title = '终端注册修改';
    this.mloading = true;
    this.showAuditFields = false;
    this.myHeaders.btnId = this.authId('updatebtn', this.$route.meta.authList);
    this.ruleForm = {
      sourceEnum: 1,
      channelType: 2,
      channelCode: row.channelCode,
      channelName: row.channelName,
      contacts: row.contacts,
      phone: row.phone,
      cardNo: row.cardNo,
      detailedAddress: row.detailedAddress,
      createUserName: row.userName,
      creditCode: row.creditCode,
      pId: row.pId
    };
    if (row.businessLicense) {
      var imgArr = row.businessLicense.split(',');
      imgArr.forEach(function (item) {
        _this4.imgList.push({
          url: item,
          response: {
            data: item
          }
        });
      });
    }
    this.mloading = false;
    this.dialogVisible = true;
  }), (0, _defineProperty2.default)(_methods, "handleClose", function handleClose() {
    this.salesmanIds = [];
    this.areaSel = [];
    this.porList = [];
    this.orgselList = [];
    this.imgList = [];
    this.masterChannelName = '';
    this.ruleForm = {
      auditStatus: '',
      creditCode: '',
      businessLicense: '',
      sourceEnum: 1,
      channelName: '',
      channelType: 2,
      contacts: '',
      detailedAddress: '',
      phone: '',
      cardNo: '' // 身份证号
    };

    this.disabled = false;
    this.$refs.ruleForm.resetFields();
    this.dialogVisible = false;
    this.authId('searchbtn', this.$route.meta.authList);
  }), (0, _defineProperty2.default)(_methods, "submitForm", function submitForm() {
    var _this5 = this;
    this.$refs['ruleForm'].validate(function (valid) {
      if (valid) {
        _this5.btnLoading = true;
        var submitData = JSON.parse(JSON.stringify((0, _utils.filterKeyNull)(_this5.ruleForm)));
        submitData.openTime = _this5.ruleForm.openTime ? (0, _utils.parseTime)(_this5.ruleForm.openTime, '{y}-{m}-{d}') : '';
        delete submitData.channelGradeId;
        delete submitData.productClasses;
        delete submitData.masterChannelArchives;
        delete submitData.salesmans;
        delete submitData.strongCheck;
        delete submitData.cityName;
        delete submitData.className;
        delete submitData.countyName;
        delete submitData.gradeName;
        delete submitData.orgName;
        delete submitData.provinceName;
        delete submitData.products;
        delete submitData.groupCode;
        delete submitData.groupName;
        delete submitData.isUsed;
        delete submitData.minTypeName;
        delete submitData.minType;
        delete submitData.departmentId;
        delete submitData.masterChannelId;
        delete submitData.departmentName;
        delete submitData.createUserName;
        delete submitData.regionName;
        delete submitData.marketName;
        delete submitData.marketId;
        delete submitData.teamName;
        delete submitData.teamId;
        delete submitData.isCode;
        delete submitData.openTime;
        // submitData.orgId = this.orgselList[this.orgselList.length - 1]
        if (_this5.porList.length > 0) {
          // var strArr = []
          // this.porList.forEach((item) => {
          //   strArr.push(item[item.length - 1])
          // })
          // submitData.productClassIds = this.porList.join(',')
        }
        if (_this5.imgList.length > 0) {
          var imgArr = [];
          _this5.imgList.forEach(function (item) {
            imgArr.push(item.response.data);
          });
          submitData.businessLicense = imgArr.join(',');
        }
        if (_this5.ruleForm.pId) {
          if (_this5.showAuditFields) {
            (0, _channel.jcChannelArchivesAudit)({
              pId: _this5.ruleForm.pId,
              remark: _this5.ruleForm.remarks,
              auditStatus: _this5.ruleForm.auditStatus
            }).then(function (res) {
              if (res.data.code != 200) {
                _this5.$message.error(res.data.msg);
              } else {
                _this5.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this5.handleClose();
                _this5.getList();
              }
              _this5.btnLoading = false;
            }).catch(function () {
              _this5.btnLoading = false;
            });
          } else {
            _this5.authId('updatebtn', _this5.$route.meta.authList);
            (0, _channel.channelArchivesUpdate)(submitData).then(function (res) {
              if (res.data.code != 200) {
                _this5.$message.error(res.data.msg);
              } else {
                _this5.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this5.handleClose();
                _this5.getList();
              }
              _this5.btnLoading = false;
            }).catch(function () {
              _this5.btnLoading = false;
            });
          }
        } else {
          _this5.authId('addbtn', _this5.$route.meta.authList);
          delete submitData.auditStatus;
          delete submitData.pid;
          (0, _channel.channelArchivesInsert)(submitData).then(function (res) {
            if (res.data.code != 200) {
              _this5.$message.error(res.data.msg);
            } else {
              _this5.$message({
                message: '保存成功',
                type: 'success'
              });
              _this5.handleClose();
              _this5.getList();
            }
            _this5.btnLoading = false;
          }).catch(function () {
            _this5.btnLoading = false;
          });
        }
      }
    });
  }), (0, _defineProperty2.default)(_methods, "selectFile", function selectFile(file, fileList) {
    this.fileList = fileList;
    this.uploadForm.uploadFile = file.name;
  }), (0, _defineProperty2.default)(_methods, "uploadClose", function uploadClose() {
    this.resetForm('uploadForm');
    this.uploadForm.uploadFile = '';
    this.uploadData = [];
    this.uploadVisible = false;
    this.authId('searchbtn', this.$route.meta.authList);
  }), (0, _defineProperty2.default)(_methods, "tag1Close", function tag1Close(tag) {
    if (!this.disabled) {
      this.salesmanIds.splice(this.salesmanIds.indexOf(tag), 1);
    }
  }), (0, _defineProperty2.default)(_methods, "handleRemove", function handleRemove(file, fileList) {
    this.imgList = fileList;
  }), (0, _defineProperty2.default)(_methods, "beforeAvatarUpload", function beforeAvatarUpload(file, fileList) {
    // const file = param.file
    var isJPG = file.type === 'image/jpeg';
    var isPNG = file.type === 'image/png';
    var isLt2M = file.size / 1024 / 1024 < 2;
    if (!isJPG && !isPNG) {
      this.$message.error('上传图片只能是 PNG/JPG 格式!');
    }
    if (!isLt2M) {
      this.$message.error('上传图片大小不能超过 2MB!');
    }
    // if ((isJPG || isPNG) && isLt2M) {
    //   const formData = new FormData()
    //   formData.append('file', file)
    //   imgupload(formData).then(response => {
    //     var data = response.data
    //     if (data.code != 200) {
    //       this.$message.error(data.msg)
    //     } else {
    //       this.imgList.push({ url: data.data })
    //     }
    //   })
    // }
    return (isJPG || isPNG) && isLt2M;
  }), (0, _defineProperty2.default)(_methods, "openSel", function openSel() {
    // if (this.orgselList.length == 0) {
    //   this.$message.error('请先选择组织')
    // } else {
    //   this.$refs.perCheckbox.searchForm.orgId = this.orgselList[
    //     this.orgselList.length - 1
    //   ]
    this.$refs.perCheckbox.proSelVisible = true;
    this.$refs.perCheckbox.setsel();
    // }
  }), (0, _defineProperty2.default)(_methods, "setSel", function setSel(val) {
    this.salesmanIds = val;
  }), (0, _defineProperty2.default)(_methods, "download", function download() {
    var _this6 = this;
    this.authId('exportbtn', this.$route.meta.authList);
    this.downLoading = true;
    var submitData = (0, _utils.filterKeyNull)(this.searchForm);
    submitData = JSON.parse(JSON.stringify(submitData));
    delete submitData.size;
    // submitData.size = 2147483647
    (0, _channel.channelArchivesExportExcel)(submitData).then(function (res) {
      _this6.authId('searchbtn', _this6.$route.meta.authList);
      _this6.downLoading = false;
      if (res.status != 200) {
        _this6.$message.error(res.data.msg);
      } else {
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '终端档案.xlsx';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      }
    }).catch(function () {
      _this6.authId('searchbtn', _this6.$route.meta.authList);
      _this6.downLoading = false;
    });
  }), (0, _defineProperty2.default)(_methods, "uploadShow", function uploadShow() {
    this.myHeaders.btnId = this.authId('allinport', this.$route.meta.authList);
    this.uploadVisible = true;
  }), (0, _defineProperty2.default)(_methods, "submitUpload", function submitUpload() {
    if (this.fileList.length > 0) {
      this.mloading = true;
      this.uploadData = [];
      this.$refs.upload.submit();
    } else {
      this.$message.error('请导入上传文件');
    }
  }), (0, _defineProperty2.default)(_methods, "uploadFileRes", function uploadFileRes(res, file, fileList) {
    if (res.code == 2004) {
      this.$message.error(res.msg);
      this.uploadData = res.data;
    } else if (res.code != 200) {
      this.$message.error(res.msg);
    } else {
      this.$message({
        type: 'success',
        message: '导入成功!'
      });
      this.uploadClose();
      file.name = '';
      this.getList();
    }
    this.mloading = false;
    this.fileList = [];
    this.$refs.upload.clearFiles();
  }), (0, _defineProperty2.default)(_methods, "imgUploadSuccess", function imgUploadSuccess(res, file, fileList) {
    if (res.code != 200) {
      this.$message.error(res.msg);
    }
  }), (0, _defineProperty2.default)(_methods, "selectImg", function selectImg(file, fileList) {
    this.imgList = fileList;
  }), (0, _defineProperty2.default)(_methods, "uploadFileFail", function uploadFileFail(res, file, fileList) {
    this.$message.error('上传失败请重试');
    this.mloading = false;
  }), (0, _defineProperty2.default)(_methods, "openSalesManId", function openSalesManId() {
    this.dialogSalesmanList = true;
  }), (0, _defineProperty2.default)(_methods, "batchAddSalesman", function batchAddSalesman() {
    if (!this.multipleSelection.length) return this.$message.error('请选择经销商!');
    this.salesManUserId = '';
    this.isShow = true;
  }), (0, _defineProperty2.default)(_methods, "batchUpdateSalesman", function batchUpdateSalesman() {
    if (!this.searchForm.salesManId) return this.$message.error('请选择业务员!');
    if (!this.multipleSelection.length) return this.$message.error('请选择经销商!');
    this.salesManUserId = this.searchForm.salesManId;
    this.isShow = true;
  }), (0, _defineProperty2.default)(_methods, "batchDeleteSalesman", function batchDeleteSalesman() {
    var _this7 = this;
    if (this.multipleSelection.length > 0) {
      this.$confirm('是否解除所选经销商所有业务?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var pList = [];
        _this7.multipleSelection.forEach(function (item) {
          pList.push({
            'channelId': item.pId
          });
        });
        (0, _channel.batcRemoveAllSalesMan)(pList).then(function (res) {
          if (res.data.code != 200) {
            _this7.$message.error(res.data.msg);
            _this7.getList();
          } else {
            _this7.$message({
              message: '操作成功',
              type: 'success'
            });
            _this7.searchForm.salesManId = '';
            _this7.salesManName = '';
            _this7.getList();
          }
        });
      }).catch(function () {
        _this7.searchForm.salesManId = '';
        _this7.salesManName = '';
        _this7.getList();
      });
    } else {
      this.$message.error('请选择经销商');
    }
  }), (0, _defineProperty2.default)(_methods, "querylist", function querylist() {
    this.current = 1;
    this.getcomBoxSalesManlist();
  }), (0, _defineProperty2.default)(_methods, "resetQuerylist", function resetQuerylist() {
    this.querysalesman = {
      orgId: '',
      phoneNo: '',
      salesManAccount: '',
      salesManName: ''
    };
    this.salesmanSize = 10;
    this.current = 1;
    this.channelOrgId = '';
    this.getcomBoxSalesManlist();
  }), (0, _defineProperty2.default)(_methods, "selCancel", function selCancel() {
    this.querysalesman = {
      orgId: '',
      phoneNo: '',
      salesManAccount: '',
      salesManName: ''
    };
    this.size = 10;
    this.current = 1;
    this.channelOrgId = '';
    this.getcomBoxSalesManlist();
    this.dialogSalesmanList = false;
    this.searchForm.current = 1;
    this.getList();
  }), (0, _defineProperty2.default)(_methods, "submitSalesmanList", function submitSalesmanList(row) {
    console.log(row);
    this.salesManName = row.salesManName;
    this.searchForm.salesManId = row.pid;
    this.querysalesman = {
      orgId: '',
      phoneNo: '',
      salesManAccount: '',
      salesManName: ''
    };
    this.size = 10;
    // this.current = 1
    this.channelOrgId = '';
    this.searchForm.current = 1;
    this.getList();
    // this.getcomBoxSalesManlist()
    this.dialogSalesmanList = false;
  }), (0, _defineProperty2.default)(_methods, "getcomBoxSalesManlist", function getcomBoxSalesManlist() {
    var _this8 = this;
    (0, _dealer.comBoxSalesManlist)(this.current, this.salesmanSize, this.querysalesman).then(function (res) {
      console.log(res.data, 'xialkuan');
      if (res.data.code !== 200) {
        return _this8.$message.error(res.data.msg);
      } else {
        if (res.data.data !== null) {
          _this8.salesmanTotal = res.data.data.total * 1;
          _this8.salesmanList = res.data.data.records;
          _this8.salesmanSize = res.data.data.size * 1;
          // var hash = {}
          // this.salesmanList = this.salesmanList.reduce(function(item, next) {
          //   hash[next.pid] ? '' : hash[next.pid] = true && item.push(next)
          //   return item
          // }, [])
          // console.log(this.salesmanList, 'this.salesmanList')
        } else {
          _this8.salesmanTotal = 0;
          _this8.salesmanList = [];
        }
      }
    });
  }), (0, _defineProperty2.default)(_methods, "closePriseUsers", function closePriseUsers(data, errorSalesManName, isTips) {
    this.isShow = false;
    if (data && data.length) {
      this.isShowErrorPrise = true;
      this.errorPriseUsers = data;
      this.errorSalesManName = errorSalesManName;
    } else {
      isTips && this.$message.success('操作成功');
    }
  }), (0, _defineProperty2.default)(_methods, "getMultipleSelection", function getMultipleSelection() {}), (0, _defineProperty2.default)(_methods, "getData", function getData() {
    this.searchForm.salesManId = '';
    this.salesManName = '';
    this.getList();
  }), _methods)
};
exports.default = _default;