"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _utils = require("@/utils");
var _dictionary = require("@/api/systems/dictionary");
var _product = require("@/api/basic/product");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _validate = require("@/utils/validate");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'BasicDataPurchasingsupplyIndex',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    var validatenum = function validatenum(rule, value, callback) {
      if (value > 0 && value <= 5) {
        callback();
      } else {
        callback(new Error('请输入1~5区间的数字'));
      }
    };
    return {
      // 搜素
      handleShow: false,
      // 表单
      form: {
        tableData: []
      },
      rules: {
        attributeCode: {
          required: true,
          message: '字段编码不能为空',
          trigger: 'blur'
        },
        attributeName: {
          required: true,
          message: '字段名称不能为空',
          trigger: 'blur'
        },
        attributeType: {
          required: true,
          message: '类型不能为空',
          trigger: 'blur'
        },
        attributeStatus: {
          required: true,
          message: '状态不能为空',
          trigger: 'blur'
        },
        attributeConstraint: {
          required: true,
          trigger: 'blur',
          validator: _validate.checkConstraint1
        },
        attributeConstraint1: {
          required: true,
          trigger: 'blur',
          validator: validatenum
        },
        attributeConstraint2: {
          required: true,
          trigger: 'change',
          message: '约束不能为空'
        }
      },
      listLoading: false,
      tableKey: '1101',
      tabHeight: '100%',
      total: 0,
      tableyuanData: [],
      listQuery: {
        current: 1,
        isDelete: 1,
        dataDictionaryCode: '',
        size: 20,
        dataDictionaryName: ''
      },
      attributeStatus: '',
      dialogVisible: false,
      downLoading: false,
      uploadVisible: false,
      proShow: false,
      pid: '',
      Ids: [],
      auditArr: [],
      dialogFormVisible: false,
      statuslist: [],
      applyNum: 0,
      standbyRedundancy: 0,
      yuanvisible: false,
      Num: 0,
      btnLoading: false,
      selectdicinfo: {}
    };
  },
  mounted: function mounted() {
    this.$nextTick(function () {
      this.tabHeight = (0, _utils.tableHeight)();
    });
  },
  activated: function activated() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _product.getproductstatus)({
        attributeStatus: this.attributeStatus
      }).then(function (res) {
        _this.listLoading = false;
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
          return;
        } else {
          _this.form.tableData = res.data.data || [];
          if (_this.form.tableData) {
            _this.form.tableData.forEach(function (el) {
              if (el.id) {
                _this.$set(el, 'flag', 'Y');
              }
            });
          }
        }
      });
    },
    submit: function submit(row) {
      this.selectdicinfo = row;
      this.form.tableData[this.attsort].attributeDictionaryId = this.selectdicinfo.pid;
      this.form.tableData[this.attsort].attributeDictionaryName = this.selectdicinfo.dictionaryName;
      this.form.tableData[this.attsort].attributeDictionaryCode = this.selectdicinfo.dictionaryCode;
      this.yuanvisible = false;
    },
    getdictionarylist: function getdictionarylist() {
      var that = this;
      that.listLoading = true;
      (0, _dictionary.getDataDictionaryList)(that.listQuery).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.total = parseInt(response.data.data.total);
            that.tableyuanData = response.data.data.records;
          } else {
            that.total = 0;
            that.tableyuanData = [];
          }
          that.listLoading = false;
        }
      }).catch(function (res) {
        that.listLoading = false;
      });
    },
    resetdictionary: function resetdictionary() {
      this.listQuery = {
        current: 1,
        isDelete: 1,
        dataDictionaryCode: '',
        size: 20,
        dataDictionaryName: ''
      };
      this.getdictionarylist();
    },
    addproduct: function addproduct() {
      this.form.tableData.push({
        attributeCode: '',
        attributeName: '',
        attributeType: '',
        attributeStatus: '1',
        attributeConstraint: '',
        attributeDictionaryName: '',
        attributeDictionaryId: '',
        attributeDictionaryCode: '',
        flag: 'N'
      });
    },
    deleteproduct: function deleteproduct(index) {
      var _this2 = this;
      if (this.form.tableData[index].attributeType == '1' || this.form.tableData[index].attributeType == '2') {
        if (this.form.tableData[index].attributeCode == '' || this.form.tableData[index].attributeCode == '' || this.form.tableData[index].attributeType == '' || this.form.tableData[index].attributeStatus == '' || this.form.tableData[index].attributeConstraint == '') {
          this.$message.warning('数据不能为空');
          return false;
        }
      } else {
        if (this.form.tableData[index].attributeCode == '' || this.form.tableData[index].attributeCode == '' || this.form.tableData[index].attributeType == '' || this.form.tableData[index].attributeStatus == '' || this.form.tableData[index].attributeConstraint == '' || this.form.tableData[index].attributeDictionaryName == '') {
          this.form.tableData.splice(index, 1);
          // this.$message.warning('数据不能为空')
          return false;
        }
      }
      (0, _product.deleteCheck)({
        attributeCode: this.form.tableData[index].attributeCode
      }).then(function (res) {
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
        } else {
          if (res.data.data == true) {
            _this2.$confirm('您确认要删除所选数据？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(function () {
              _this2.form.tableData.splice(index, 1);
            });
          } else {
            _this2.$message.error('已被产品使用，不能删除');
          }
        }
      });
    },
    changetype: function changetype(index) {
      this.form.tableData[index].attributeConstraint = '';
    },
    saveconfig: function saveconfig() {
      var _this3 = this;
      if (this.form.tableData) {
        this.form.tableData.forEach(function (el, index) {
          _this3.$set(el, 'attributeSort', index);
          delete el.flag;
        });
      }
      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          _this3.form.tableData.forEach(function (el) {
            delete el.createTime;
            delete el.creator;
            delete el.creatorId;
            delete el.updateTime;
            delete el.updater;
            delete el.updaterId;
          });
          (0, _product.saveproductconfig)({
            configList: _this3.form.tableData
          }).then(function (res) {
            if (res.data.code != 200) {
              _this3.$message.error(res.data.msg);
            } else {
              _this3.$message({
                message: '保存成功',
                type: 'success'
              });
            }
          }).catch(function () {});
        }
      });
    },
    cancleconfig: function cancleconfig() {
      this.getList();
      this.$message.success('成功');
    },
    openTSel: function openTSel(index) {
      this.yuanvisible = true;
      this.attsort = index;
      this.getdictionarylist();
    }
  }
};
exports.default = _default;