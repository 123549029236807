var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-upload",
    {
      attrs: {
        headers: _vm.myHeaders,
        action: _vm.uploadUrl,
        accept: ".jpg, .jpeg, .png, .PNG, .JPG, .JPEG",
        "on-success": _vm.handleAvatarSuccess,
        "on-change": _vm.selectImg,
        "before-upload": _vm.beforeAvatarUpload,
        "file-list": _vm.fileList,
        "show-file-list": false,
        "auto-upload": false,
        limit: _vm.limitCount,
        name: "file",
        disabled: _vm.disabled,
      },
    },
    [
      _c("el-button", { attrs: { size: "small", type: "primary" } }, [
        _vm._v("点击上传"),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }