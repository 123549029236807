"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _log = require("@/api/systems/log");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'systemlogmangevisitedlogindex';
var _default = {
  name: 'Systemlogmangevisitedlogindex',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      listQuery: {
        startTimeStr: '',
        endTimeStr: '',
        operationType: '',
        keyWord: '',
        current: 1,
        size: 20
      },
      timeValue: '',
      list: [],
      tableKey: 'visitLog',
      total: 0,
      listLoading: false,
      apis: [],
      page2: false,
      endShow: true,
      tabHeight: '100%'
    };
  },
  created: function created() {
    var that = this;
  },
  mounted: function mounted() {
    var that = this;
    var h = window.innerHeight - 360 + 'px';
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    var that = this;
    var hasCache = that.list.length > 0;
    var authList = that.$route.meta.authList;
    var setHeaderId = that.authId('searchbtn', authList);
    that.apis = authList;
    if (!hasCache) {
      that.getList();
    }
  },
  // 缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = this.getCaches(that, name);
    if (!cache) {
      that.list = [];
      that.total = 0;
    }
  },
  methods: {
    getList: function getList() {
      var that = this;
      that.listLoading = true;
      if (that.timeValue.length > 0) {
        that.listQuery.startTimeStr = that.timeValue[0];
        that.listQuery.endTimeStr = that.timeValue[1];
      } else {
        that.listQuery.startTimeStr = '';
        that.listQuery.endTimeStr = '';
      }
      (0, _log.getVisitLog)(that.listQuery).then(function (response) {
        if (response.data.code == 200) {
          that.list = response.data.data.list.records;
          that.total = parseInt(response.data.data.list.total);
          setTimeout(function () {
            that.listLoading = false;
          }, 1 * 1000);
        } else {
          that.$message.error(response.data.msg);
          that.listLoading = false;
          return;
        }
      }).catch(function (res) {
        that.listLoading = false;
      });
    },
    submitForm: function submitForm() {
      var that = this;
      that.listQuery = (0, _utils.filterKeyNull)(that.listQuery);
      that.listQuery.pageNo = 1;
      that.getList();
    },
    resetForm: function resetForm(formName) {
      var that = this;
      that.listQuery.startTimeStr = '';
      that.listQuery.endTimeStr = '';
      that.listQuery.operationType = '';
      that.listQuery.keyWord = '';
      that.timeValue = '';
      that.$refs[formName].resetFields();
      that.listQuery.current = 1;
      that.getList();
    }
  }
};
exports.default = _default;