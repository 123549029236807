"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.exportJxRkList = exportJxRkList;
exports.exportZdRkList = exportZdRkList;
exports.flowSumListExport = flowSumListExport;
exports.getJxRkList = getJxRkList;
exports.getQueryOrg = getQueryOrg;
exports.getType = getType;
exports.getZdRkList = getZdRkList;
exports.jxInOrderSum = jxInOrderSum;
exports.jxInOrderSumExport = jxInOrderSumExport;
exports.jxOutOrderSum = jxOutOrderSum;
exports.jxOutOrderSumExport = jxOutOrderSumExport;
var _request = _interopRequireDefault(require("@/utils/request"));
// 获取组织
function getQueryOrg() {
  var data = {
    userId: '',
    orgCodeAndName: '',
    orgStatus: 1
  };
  return (0, _request.default)({
    url: '/api-basic/orgInfo/orgInfoAll',
    method: 'post',
    data: data
  });
}

// 获取经销商入库流水列表
function getJxRkList(data) {
  return (0, _request.default)({
    url: '/api-channel/orderinfo/outflow/jxlist',
    method: 'post',
    data: data
  });
}

// 获取终端入库流水列表
function getZdRkList(data) {
  return (0, _request.default)({
    url: '/api-channel/orderinfo/outflow/zdlist',
    method: 'post',
    data: data
  });
}

// 获取业务类型
function getType(data) {
  return (0, _request.default)({
    url: '/api-channel/jcBusinessType/list',
    method: 'POST',
    data: data
  });
}

// 经销商入库流水导出
function exportJxRkList(data) {
  return (0, _request.default)({
    url: '/api-channel/orderinfo/outflow/exportJXlist',
    method: 'post',
    responseType: 'blob',
    data: data
  });
}

// 终端入库流水导出
function exportZdRkList(data) {
  return (0, _request.default)({
    url: '/api-channel/orderinfo/outflow/exportZdlist',
    method: 'post',
    responseType: 'blob',
    data: data
  });
}

// 经销商出库流水汇总查询
function jxOutOrderSum(data) {
  return (0, _request.default)({
    url: '/api-channel/orderinfo/outflow/jxOutOrderSum',
    method: 'post',
    data: data
  });
}

// 经销商入库流水汇总查询-分页
function jxInOrderSum(data) {
  return (0, _request.default)({
    url: '/api-channel/orderinfo/inflow/jxInOrderSum',
    method: 'post',
    data: data
  });
}

// 经销商出库流水汇总导出
function jxOutOrderSumExport(data) {
  return (0, _request.default)({
    url: '/api-channel/orderinfo/outflow/jxOutOrderSumExport',
    method: 'post',
    responseType: 'blob',
    data: data
  });
}

// 经销商入库流水汇总导出
function jxInOrderSumExport(data) {
  return (0, _request.default)({
    url: '/api-channel/orderinfo/inflow/jxInOrderSumExport',
    method: 'post',
    responseType: 'blob',
    data: data
  });
}

// 出入库列表流水汇总导出
function flowSumListExport(params) {
  return (0, _request.default)({
    url: '/api-channel/stock/reportForm/flowSumListExport',
    method: 'get',
    responseType: 'blob',
    params: params
  });
}