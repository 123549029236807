var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _c(
      "div",
      { staticClass: "page-section", staticStyle: { padding: "0 20px" } },
      [
        _vm.workId
          ? _c("workBasic", { attrs: { "p-id": _vm.workId } })
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "middleLine" }),
        _vm._v(" "),
        _c("div", { staticClass: "itemTitle" }, [_vm._v("单据信息")]),
        _vm._v(" "),
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _c("el-col", { attrs: { span: 8 } }, [
              _c("div", { staticClass: "vue-from-block" }, [
                _c(
                  "div",
                  {
                    staticClass: "form-div",
                    staticStyle: { width: "auto", "margin-right": "0.5em" },
                  },
                  [_vm._v("经销商:")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.channelName)),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("el-col", { attrs: { span: 8 } }, [
              _c("div", { staticClass: "vue-from-block" }, [
                _c(
                  "div",
                  {
                    staticClass: "form-div",
                    staticStyle: { width: "auto", "margin-right": "0.5em" },
                  },
                  [_vm._v("经销商编码:")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.channelCode)),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("el-col", { attrs: { span: 8 } }, [
              _c("div", { staticClass: "vue-from-block" }, [
                _c(
                  "div",
                  {
                    staticClass: "form-div",
                    staticStyle: { width: "auto", "margin-right": "0.5em" },
                  },
                  [_vm._v("经销商分类:")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.channelClassName)),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("el-col", { attrs: { span: 8 } }, [
              _c("div", { staticClass: "vue-from-block" }, [
                _c(
                  "div",
                  {
                    staticClass: "form-div",
                    staticStyle: { width: "auto", "margin-right": "0.5em" },
                  },
                  [_vm._v("所属组织:")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.orgName)),
                ]),
              ]),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "middleLine" }),
        _vm._v(" "),
        _c("div", { staticClass: "itemTitle" }, [
          _c("span", { staticStyle: { "margin-right": "20px" } }, [
            _vm._v("产品明细"),
          ]),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "fr", staticStyle: { "margin-left": "auto" } },
            [
              _vm.deliverGoodsSum
                ? _c("strong", [
                    _vm._v("调整发货合计：" + _vm._s(_vm.deliverGoodsSum)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.receivingGoodsSum
                ? _c("strong", [
                    _vm._v("调整收货合计：" + _vm._s(_vm.receivingGoodsSum)),
                  ])
                : _vm._e(),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "el-table",
          {
            staticClass: "detailTable",
            staticStyle: { width: "100%" },
            attrs: {
              "max-height": "400",
              data: _vm.mtStockAdjustmentProductParams,
              fit: "",
              border: _vm.tableConfig.border,
              stripe: _vm.tableConfig.stripe,
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                "min-width": "120px",
                align: _vm.tableConfig.align,
                label: "产品编码",
                prop: "productCode",
                "show-overflow-tooltip": "",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                "min-width": "120px",
                align: _vm.tableConfig.align,
                label: "产品名称",
                prop: "productName",
                "show-overflow-tooltip": "",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                "min-width": "120px",
                align: _vm.tableConfig.align,
                label: "产品分类",
                prop: "productClassName",
                "show-overflow-tooltip": "",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                "min-width": "100px",
                align: _vm.tableConfig.align,
                label: "规格",
                prop: "productModel",
                "show-overflow-tooltip": "",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                "min-width": "120px",
                align: _vm.tableConfig.align,
                label: "包装比例",
                prop: "packScaleDesc",
                "show-overflow-tooltip": "",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                "min-width": "100px",
                align: _vm.tableConfig.align,
                label: "最小单位",
                prop: "minScaleName",
                "show-overflow-tooltip": "",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                "min-width": "120px",
                align: _vm.tableConfig.align,
                label: "现存量",
                prop: "existingSum",
                "show-overflow-tooltip": "",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                "min-width": "120px",
                align: _vm.tableConfig.align,
                label: "可用量",
                prop: "availableSum",
                "show-overflow-tooltip": "",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                "min-width": "120px",
                align: _vm.tableConfig.align,
                label: "调整后数量",
                prop: "afterSum",
                width: "140",
                "show-overflow-tooltip": "",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "middleLine" }),
        _vm._v(" "),
        _vm.remark
          ? _c("div", [
              _c("div", { staticClass: "itemTitle" }, [_vm._v("整单备注")]),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { padding: "0 0 15px 0", "font-size": "14px" } },
                [_vm._v(_vm._s(_vm.remark))]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.uploadImgs.length > 0
          ? _c(
              "div",
              [
                _c("div", { staticClass: "middleLine" }),
                _vm._v(" "),
                _c("div", { staticClass: "itemTitle" }, [_vm._v("图片附件")]),
                _vm._v(" "),
                _vm._l(_vm.uploadImgs, function (item, index) {
                  return _c("el-image", {
                    key: index,
                    staticStyle: {
                      width: "80px",
                      height: "80px",
                      margin: "0 10px",
                    },
                    attrs: {
                      src: item.src,
                      "preview-src-list": _vm.preViewList,
                    },
                  })
                }),
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "middleLine" }),
        _vm._v(" "),
        _vm.workId
          ? _c("workFlow", { attrs: { "p-id": _vm.workId } })
          : _vm._e(),
        _vm._v(" "),
        _vm.check
          ? _c(
              "div",
              [
                _c("div", { staticClass: "middleLine" }),
                _vm._v(" "),
                _vm.workId && _vm.taskId
                  ? _c("workVerify", {
                      attrs: { "p-id": _vm.workId, "task-id": _vm.taskId },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.check
          ? _c(
              "div",
              { staticClass: "align-right", staticStyle: { padding: "20px" } },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.handleClosePage },
                  },
                  [_vm._v("返回")]
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }