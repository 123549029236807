"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _admincut = _interopRequireDefault(require("@/components/admincut"));
var _wxHy = require("@/api/wxhyyy/wxHy");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    admincut: _admincut.default
  },
  data: function data() {
    return {
      loading: true,
      activeName: '1',
      checkList: [],
      tableData: [{
        date: '111',
        adress: 'eee',
        name: '3',
        ress: 'www'
      }, {
        date: '111',
        adress: 'eee',
        name: '3',
        ress: 'www'
      }, {
        date: '111',
        adress: 'eee',
        name: '3',
        ress: 'www'
      }],
      synclist: ''
    };
  },
  created: function created() {
    var that = this;
    (0, _wxHy.getsyncsearch)({
      bigType: 1,
      appId: sessionStorage.getItem('appId')
    }).then(function (res) {
      console.log(res);
      var res = res.data;
      if (res.code === 200) {
        that.synclist = res.data.data;
      } else {
        that.$message.warning(res.msg);
      }
    });
    that.getlist();
  },
  methods: {
    // 表格斑马纹
    tableRowClassName: function tableRowClassName(_ref) {
      var row = _ref.row,
        rowIndex = _ref.rowIndex;
      if (rowIndex % 2 === 0) {
        return 'warning-row';
      }
    },
    handleClick: function handleClick(tab, event) {
      console.log(tab, event);
    },
    btn: function btn() {
      console.log(this.checkList);
      var that = this;
      that.checkList.appId = sessionStorage.getItem('appId');
      (0, _wxHy.synchronizationList)(that.checkList).then(function (res) {
        var res = res.data;
        console.log(res);
        if (res.code === 200) {
          that.$message.success(res.msg);
          that.loading = true;
          that.getlist();
        } else {
          that.$message.warning(res.msg);
        }
      });
    },
    getlist: function getlist() {
      var that = this;
      (0, _wxHy.getsyncsearchlist)({
        pageNo: 1,
        pageSize: 10,
        appId: sessionStorage.getItem('appId')
      }).then(function (res) {
        // console.log(res)
        var res = res.data;
        if (res.code === 200) {
          that.tableData = res.data.records;
          that.loading = false;
        }
      });
    }
  }
};
exports.default = _default;