"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _allotUnitSelect = _interopRequireDefault(require("@/components/allotUnitSelect"));
var _index = require("@/api/warehouse/checkout/index");
var _select = require("@/api/warehouse/select");
var _dictionary = require("@/api/systems/dictionary");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'TableSuply',
  components: {
    allotUnitSelect: _allotUnitSelect.default
  },
  filters: {},
  data: function data() {
    // const validatematerialCode = (rule, value, callback) => {
    //   if (
    //     this.proList.length == 0 ||
    //     this.proList == '' ||
    //     this.proList == null
    //   ) {
    //     callback(new Error('产品明细不能为空，请维护'))
    //   } else {
    //     callback()
    //   }
    // }
    return {
      title: '以生产任务 - 添加销售出库',
      billType: 203,
      tableKey: 'pId',
      listLoading: false,
      mloading: false,
      dialogVisible: false,
      btn_loading: false,
      proShow: false,
      isUpdate: true,
      submitType: 1,
      returnOrgType: 1,
      scanData: {
        billNo: '',
        // 单据单号

        orderNo: '',
        // 任务单号

        billSource: '',
        // 单据来源 1:平台同步创建 2：平台用户创建 3：PDA创建 4：ERP同步 5：补单
        billStatus: '',
        // 单据状态
        billType: 203,
        // 单据类型 101:生产入库 102:调拨入库 103:退货入库 104:采购入库 105:其它入库 106:销售退货 201:返工出库 202:调货出库 203:销售出库 204:退货出库 205:其它出库 206：采购退货

        inOrgId: '',
        // 收货单位id
        inOrgName: '',
        // 收货名称
        outOrgId: '',
        // 出库企业ID
        outOrgName: '',
        // 出库单位
        outOrgType: '',
        // 出库企业类型 1：生产公司 2：销售公司
        outStoreId: '',
        // 出库库房ID
        outStoreName: '',
        // 出库库房名称
        receiptAddress: '',
        // 收货地址
        receiptUser: '',
        // 收货人
        saleOrgId: '',
        // 销售单位ID
        relateType: '',
        // 关联单号类型
        relateNo: '',
        // 销售订单号

        // 新增 业务类型 订单用途
        // billApplication: '',
        billOutType: '2',
        // inOrgType: '',
        // outStrong: '',
        // otherTypeId: '',
        // sumPlanAtoNum: '',
        // sumPlanNum: '',
        // tradeBillDetailAOS: [],
        // otherTypeName: '',
        inStoreName: ''
      },
      disabled: {
        num: false
      },
      factoryList: [],
      proList: [],
      plist: [],
      rules: {
        billNo: [{
          required: true,
          message: '销售出库单号单号不能为空，请维护',
          trigger: 'change'
        }],
        orderNo: [{
          required: true,
          message: '生产任务单号不能为空，请维护',
          trigger: 'change'
        }],
        outOrgName: [{
          required: true,
          message: '发货单位不能为空',
          trigger: 'change'
        }],
        outStoreName: [{
          required: true,
          message: '出库库房不能为空',
          trigger: 'change'
        }],
        inOrgName: [{
          required: true,
          message: '请选择收货单位',
          trigger: 'change'
        }],
        // 新增
        billApplication: [{
          required: true,
          message: '请选择订单用途',
          trigger: 'change'
        }],
        billOutType: [{
          required: true,
          message: '请选择订单用途',
          trigger: 'change'
        }]
      },
      typeVisible: false,
      unitType: '',
      unitShow: false,
      inOutType: '1',
      shouFaType: '1',
      disType: false,
      supplierIds: [],
      purchaseType: '',
      orderNo: '',
      orderNoList: [],
      loading: false,
      productId: '',
      ddyt: []
    };
  },
  // 监听
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        if (this.submitType != 2) {
          this.getCreateNum();
          this.title = '以生产任务 - 添加销售出库';
          this.plist = [];
        }
      }
    }
  },
  created: function created() {},
  mounted: function mounted() {
    // var that = this
    this.getProductionInfoList('');
    this.getDirSel('DDYT');
  },
  methods: {
    getDirSel: function getDirSel(code) {
      var _this = this;
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: code
      }).then(function (res) {
        console.log(res.data.data);
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this.ddyt = res.data.data;
          } else {
            _this.ddyt = [];
          }
        }
      });
    },
    init: function init() {
      this.proList = [];
      this.inOrgList = [];
      this.outOrgList = [];
    },
    show: function show(submitType, billType) {
      this.init();
      this.submitType = submitType;
      this.billType = billType;
      this.disabled.num = false;
      this.mloading = true;
      this.otherTypeName = '';
      if (this.$refs['scanForm'] !== undefined) {
        this.$refs['scanForm'].resetFields();
      }
      this.dialogVisible = true;
      this.mloading = false;
    },
    // 自动生成退货入库单号
    getCreateNum: function getCreateNum() {
      var _this2 = this;
      (0, _index.createNum)(this.billType).then(function (res) {
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
        }
        _this2.scanData.billNo = res.data.data;
        _this2.disabled.num = true;
      }).catch(function (err) {
        console.log('err', err);
      });
    },
    submitForm: function submitForm(temp) {
      var _this3 = this;
      this.$refs['scanForm'].validate(function (valid) {
        if (valid) {
          _this3.btn_loading = true;
          _this3.scanData.editAble = temp;
          var that = _this3;
          that.transFormData(that.plist);
          var tradeBillAO = {};
          tradeBillAO.billoutSaleAO = {};
          tradeBillAO.billoutSaleAO.inOrgId = _this3.scanData.inOrgId;
          tradeBillAO.billoutSaleAO.outOrgId = _this3.scanData.outOrgId;
          tradeBillAO.billoutSaleAO.outStoreId = _this3.scanData.outStoreId;
          tradeBillAO.billoutSaleAO.outOrgType = _this3.scanData.inOrgType;
          tradeBillAO.billoutSaleAO.receiptAddress = '';
          tradeBillAO.billoutSaleAO.receiptUser = '';
          tradeBillAO.billoutSaleAO.relateNo = _this3.scanData.relateNo;

          // tradeBillAO.billoutSaleAO.outStrong = this.scanData.outStrong
          // tradeBillAO.billoutSaleAO.otherTypeId = this.scanData.otherTypeId

          // 添加业务类型,订单用途
          // tradeBillAO.billoutSaleAO.billApplication = this.scanData.billApplication
          tradeBillAO.billoutSaleAO.billOutType = _this3.scanData.billOutType;
          tradeBillAO.billNo = _this3.scanData.billNo;
          tradeBillAO.billSource = _this3.scanData.billSource;
          tradeBillAO.billNo = _this3.scanData.billNo;
          tradeBillAO.billType = _this3.scanData.billType;
          tradeBillAO.sumPlanAtoNum = Number(_this3.scanData.sumPlanAtoNum);
          tradeBillAO.sumPlanNum = Number(_this3.scanData.sumPlanNum);
          tradeBillAO.tradeBillDetailAOS = _this3.scanData.tradeBillDetailAOS;
          console.log(tradeBillAO);
          (0, _index.insertTradeBillByProduction)(tradeBillAO).then(function (res) {
            _this3.btn_loading = false;
            if (res.data.code != 200) {
              _this3.$message.error(res.data.msg);
            } else {
              _this3.$message({
                message: '保存成功',
                type: 'success'
              });
              // this.$parent.getList()
              _this3.handleClose();
            }
          }).catch(function () {
            _this3.btn_loading = false;
          });
        }
      });
    },
    // 关闭弹窗
    handleClose: function handleClose() {
      var that = this;
      that.dialogVisible = false;
      that.proList = [];
      that.plist = [];
      that.$parent.getList();
    },
    // 数据转换
    transFormData: function transFormData(rsData) {
      var that = this;
      var planScanAtoNum = 0;
      var planScanNum = 0;
      that.scanData.tradeBillDetailAOS = [];
      if (rsData.length > 0) {
        rsData.forEach(function (item) {
          var obj = {};
          planScanAtoNum += Number(item.planScanAllNum);
          planScanNum += Number(item.planScanNum);
          obj.billNo = that.scanData.billNo;
          obj.packUnit = item.packUnit;
          // obj.packScaleExpression = item.packScaleExpression
          obj.planScanAtoNum = Number(item.planScanAllNum);
          obj.planScanNum = Number(item.planScanNum);
          obj.prodBatchNo = item.prodBatchNo;
          obj.productId = item.productId;
          // obj.planScanAllNum = Number(item.planScanAllNum)
          that.scanData.tradeBillDetailAOS.push(obj);
        });
        that.scanData.sumPlanAtoNum = Number(planScanAtoNum);
        that.scanData.sumPlanNum = Number(planScanNum);
      } else {
        that.scanData.tradeBillDetailAOS = [];
        that.scanData.planScanAtoNum = Number(planScanAtoNum);
        that.scanData.planScanNum = Number(planScanNum);
      }
    },
    // 选择收货单位弹窗
    closeUnit: function closeUnit() {
      var that = this;
      that.unitShow = false;
    },
    // 选择收货
    fetchUnit: function fetchUnit(val) {
      var that = this;
      var type = that.unitType;
      if (type == 'in') {
        // that.scanData.inOrgName = val.channelName
        // that.scanData.inOrgId = val.pid
        // that.$refs.shdw.focus()
        // that.$refs.shdw.blur()
        if (that.scanData.inOrgId !== val.companyId) {
          // that.plist = []
          this.proList = [];
        }
        that.scanData.inOrgName = val.companyName;
        that.scanData.inOrgId = val.companyId;
        this.channelType = val.companyType;
        this.channelId = val.companyId;
        that.$refs.shdw.focus();
        that.$refs.shdw.blur();
      }
      if (type == 'out') {
        that.scanData.outOrgName = val.channelName;
        that.scanData.outOrgId = val.pid;
        this.supplierIds = [val.companyId];
        that.$refs.fhdw.focus();
        that.$refs.fhdw.blur();
        that.getHouses(val.companyId);
      }
    },
    // 获取收货任务单
    getProductionInfoList: function getProductionInfoList(orderNo) {
      var _this4 = this;
      this.loading = true;
      (0, _index.getProductionInfoList)({
        orderNo: orderNo
      }).then(function (res) {
        _this4.loading = false;
        if (res.data.code === 200) {
          _this4.orderNoList = res.data.data;
          console.log(_this4.orderNoList);
        }
      });
    },
    // 获取关联库房
    getHouses: function getHouses(arg) {
      var that = this;
      var param = {
        orgOrFactoryId: arg
      };
      (0, _select.getRelateStore)(param).then(function (res) {
        if (res.data.code == 200) {
          that.factoryList = [];
          that.factoryList = res.data.data;
          return res.data.data;
        }
      }).then(function (res) {
        var name = that.scanData.inStoreName;
        if (name != '') {
          if (res != null && res.length > 0) {
            for (var i = 0; i < res.length; i++) {
              if (res[i].storeHouseName == name) {
                that.scanData.outStrong = res[i].pId;
                break;
              }
            }
          }
        }
      });
    },
    // 选择单号
    orderNoFun: function orderNoFun(val) {
      if (val) {
        var arr = this.orderNoList.filter(function (item) {
          return item.orderNo === val;
        });
        console.log(arr);
        this.scanData.outStoreName = arr[0].outStoreName;
        this.scanData.outOrgName = arr[0].outOrgName;
        this.scanData.outOrgId = arr[0].outOrgId;
        this.scanData.outStoreId = arr[0].outStoreId;
        this.scanData.relateNo = arr[0].orderNo;
        var num = arr[0].packScaleExpression.split('*')[1];
        arr[0].planScanAllNum = num * arr[0].planScanNum;
        if (arr[0].packUnit === '盒') {
          arr[0].planScanAtoNum = 0;
        }
        // this.sumPlanAtoNum = num * arr[0].planScanNum + arr[0].planScanAtoNum
        this.plist = arr;
        this.productId = arr[0].productId;
      }
    },
    getInUnit: function getInUnit(arg) {
      var that = this;
      that.unitType = arg;
      if (arg == 'in') {
        if (that.scanData.orderNo) {
          that.shouFaType = '1';
          that.unitShow = true;
        } else {
          that.$message.error('请选择生产任务单号');
        }
      }
      if (arg == 'out') {
        that.shouFaType = '2';
        that.unitShow = true;
      }
    }
  }
};
exports.default = _default;