var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _c(
      "div",
      { staticClass: "page-section" },
      [
        _c(
          "el-form",
          {
            ref: "ruleForm",
            staticClass: "search-condition",
            attrs: {
              model: _vm.ruleForm,
              "status-icon": "",
              "label-position": _vm.formConfig.labelPosition,
              "label-width": _vm.formConfig.labelWidth,
              "label-suffix": ":",
            },
          },
          [
            _c(
              "div",
              { staticClass: "cols" },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 6, prop: "keyword" } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "门店编码/名称", prop: "keyword" },
                          },
                          [
                            _c("el-input", {
                              attrs: { clearable: "", placeholder: "请输入" },
                              model: {
                                value: _vm.ruleForm.keyword,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "keyword", $$v)
                                },
                                expression: "ruleForm.keyword",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.handleShow
                      ? _c(
                          "el-col",
                          { attrs: { span: 6, prop: "area" } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "省市县", prop: "area" } },
                              [
                                _c("el-cascader", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    options: _vm.areaList,
                                    props: {
                                      value: "areaCode",
                                      label: "areaName",
                                      children: "children",
                                      checkStrictly: true,
                                    },
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.area,
                                    callback: function ($$v) {
                                      _vm.area = $$v
                                    },
                                    expression: "area",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 6, prop: "contacts" } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "联系人", prop: "contacts" } },
                          [
                            _c("el-input", {
                              attrs: { clearable: "", placeholder: "请输入" },
                              model: {
                                value: _vm.ruleForm.contacts,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "contacts", $$v)
                                },
                                expression: "ruleForm.contacts",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.handleShow
                      ? _c(
                          "el-col",
                          { attrs: { span: 6, prop: "phone" } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "联系电话", prop: "phone" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请输入",
                                  },
                                  model: {
                                    value: _vm.ruleForm.phone,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "phone", $$v)
                                    },
                                    expression: "ruleForm.phone",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.handleShow
                      ? _c(
                          "el-col",
                          { attrs: { span: 6, prop: "organizationCode" } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "组织机构代码",
                                  prop: "organizationCode",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请输入",
                                  },
                                  model: {
                                    value: _vm.ruleForm.organizationCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "organizationCode",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.organizationCode",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.handleShow
                      ? _c(
                          "el-col",
                          { attrs: { span: 6, prop: "parentKeyword" } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "经销商编码/名称",
                                  prop: "parentKeyword",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请输入",
                                  },
                                  model: {
                                    value: _vm.ruleForm.parentKeyword,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "parentKeyword",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.parentKeyword",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.handleShow
                      ? _c(
                          "el-col",
                          { attrs: { span: 6, prop: "warZoneKeyword" } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "战区编码/名称",
                                  prop: "warZoneKeyword",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请输入",
                                  },
                                  model: {
                                    value: _vm.ruleForm.warZoneKeyword,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "warZoneKeyword",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.warZoneKeyword",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.handleShow
                      ? _c(
                          "el-col",
                          { attrs: { span: 6, prop: "warTeamKeyword" } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "战队编码/名称",
                                  prop: "warTeamKeyword",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请输入",
                                  },
                                  model: {
                                    value: _vm.ruleForm.warTeamKeyword,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "warTeamKeyword",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.warTeamKeyword",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.handleShow
                      ? _c(
                          "el-col",
                          { attrs: { span: 6, prop: "createType" } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "创建方式",
                                  prop: "createType",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "请选择",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.ruleForm.createType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "createType",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm.createType",
                                    },
                                  },
                                  _vm._l(
                                    _vm.createTypeList,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.name,
                                          value: item.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 6, prop: "archivesStatus" } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "状态", prop: "archivesStatus" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择", clearable: "" },
                                model: {
                                  value: _vm.ruleForm.archivesStatus,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "archivesStatus",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.archivesStatus",
                                },
                              },
                              _vm._l(
                                _vm.archivesStatusList,
                                function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.name,
                                      value: item.value,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.handleShow
                      ? _c(
                          "el-col",
                          { attrs: { span: 6, prop: "auditStatus" } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "审核状态",
                                  prop: "auditStatus",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "请选择",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.ruleForm.auditStatus,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "auditStatus",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm.auditStatus",
                                    },
                                  },
                                  _vm._l(
                                    _vm.auditStatusList,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.name,
                                          value: item.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 6, prop: "rangeTime" } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "操作时间", prop: "rangeTime" } },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                type: "daterange",
                                format: "yyyy-MM-dd",
                                "value-format": "yyyy-MM-dd",
                                clearable: false,
                              },
                              model: {
                                value: _vm.ruleForm.rangeTime,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "rangeTime", $$v)
                                },
                                expression: "ruleForm.rangeTime",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { "label-width": "0" } },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "label" }, slot: "label" },
                              [_vm._v(" ")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: _vm.btnConfig.searchBtn.type,
                                  size: _vm.btnConfig.size,
                                  icon: _vm.btnConfig.searchBtn.icon,
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.ruleForm.current = 1
                                    _vm.getList()
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: _vm.btnConfig.resetBtn.type,
                                  size: _vm.btnConfig.size,
                                  icon: _vm.btnConfig.resetBtn.icon,
                                },
                                on: { click: _vm.resetForm },
                              },
                              [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    _vm.handleShow = !_vm.handleShow
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.handleShow ? "收起" : "展开") +
                                    "\n                "
                                ),
                                _c("i", {
                                  class: _vm.handleShow
                                    ? "el-icon-arrow-up"
                                    : "el-icon-arrow-down",
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c("el-divider", { staticClass: "btnDivider" }),
        _vm._v(" "),
        _c(
          "section",
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                key: _vm.tableKey,
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.tableData,
                  height: _vm.tabHeight,
                  border: _vm.tableConfig.border,
                  stripe: _vm.tableConfig.stripe,
                  fit: "",
                  "highlight-current-row": "",
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    type: "index",
                    label: "序号",
                    align: _vm.tableConfig.align,
                    width: "80",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "门店编码",
                    prop: "terminalCode",
                    align: _vm.tableConfig.align,
                    "min-width": "120",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "门店名称",
                    prop: "terminalName",
                    align: _vm.tableConfig.align,
                    "min-width": "120",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "终端类型",
                    prop: "terminalTypeName",
                    align: _vm.tableConfig.align,
                    "min-width": "120",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "状态",
                    prop: "archivesStatus",
                    width: "100",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          row.archivesStatus
                            ? _c(
                                "el-tag",
                                {
                                  attrs: {
                                    type: _vm._f("Status")(row.archivesStatus),
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.showArchivesStatus(row)) +
                                      "\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "审核状态",
                    prop: "auditStatus",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          row.auditStatus
                            ? _c(
                                "el-tag",
                                {
                                  attrs: {
                                    type: _vm._f("Status2")(row.auditStatus),
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.showAuditStatus(row)) +
                                      "\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "审核方式",
                    prop: "auditMode",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                    formatter: _vm.showAuditMode,
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "省",
                    prop: "provinceName",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "市",
                    prop: "cityName",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "区县",
                    prop: "countyName",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "详细地址",
                    prop: "detailedAddress",
                    align: _vm.tableConfig.align,
                    "min-width": "150",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "门店定位(经纬度)",
                    align: _vm.tableConfig.align,
                    "min-width": "140",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(row.longitude) +
                              "," +
                              _vm._s(row.latitude) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "联系人",
                    prop: "contacts",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "联系电话",
                    prop: "phone",
                    width: "120",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "组织机构信用代码",
                    prop: "organizationCode",
                    width: "140",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "经销商编码",
                    prop: "channelCode",
                    align: _vm.tableConfig.align,
                    "min-width": "140",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "经销商名称",
                    prop: "channelName",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "战区编码",
                    prop: "saleOrg2Id",
                    align: _vm.tableConfig.align,
                    "min-width": "140",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "所属战区",
                    prop: "saleOrg2Name",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "战队编码",
                    prop: "saleOrg3Id",
                    align: _vm.tableConfig.align,
                    "min-width": "140",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "所属战队",
                    prop: "saleOrg3Name",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "创建人",
                    prop: "userName",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "创建时间",
                    prop: "createdTime",
                    width: "140",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "创建方式",
                    prop: "createType",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                    formatter: _vm.showCreateType,
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "操作",
                    fixed: "right",
                    align: _vm.tableConfig.align,
                    width: "180",
                    "class-name": "small-padding fixed-width",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.DoEdit(row, "1")
                                },
                              },
                            },
                            [_vm._v("查看")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.DoEdit(row, "2")
                                },
                              },
                            },
                            [_vm._v("审核")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("pagination", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.total > 0,
                  expression: "total > 0",
                },
              ],
              attrs: {
                layout: "total, sizes, prev, pager, next, jumper",
                total: _vm.total,
                page: _vm.ruleForm.current,
                limit: _vm.ruleForm.size,
              },
              on: {
                "update:page": function ($event) {
                  return _vm.$set(_vm.ruleForm, "current", $event)
                },
                "update:limit": function ($event) {
                  return _vm.$set(_vm.ruleForm, "size", $event)
                },
                pagination: _vm.getList,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("detailsflow", { ref: "flowMain" }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }