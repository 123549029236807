"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _wXintegralMall = require("@/api/wxhyyy/wXintegralMall");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      visible: false,
      roleList: [],
      dataForm: {
        id: '',
        title: '',
        shopId: '',
        status: 1,
        isDefault: '',
        prodCount: '',
        seq: '',
        style: 0
      },
      dataRule: {
        title: [{
          required: true,
          message: '标签名称不能为空',
          trigger: 'change'
        }]
      }
    };
  },
  methods: {
    init: function init(id) {
      var _this = this;
      this.dataForm.id = id || 0;
      this.visible = true;
      this.$nextTick(function () {
        _this.$refs['dataForm'].resetFields();
        if (_this.dataForm.id) {
          (0, _wXintegralMall.prodTagInfo)(_this.dataForm.id).then(function (data) {
            var data = data.data;
            _this.dataForm = Object.assign(_this.dataForm, data);
          });
        }
      });
    },
    // 表单提交
    dataFormSubmit: function dataFormSubmit() {
      var _this2 = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _wXintegralMall.prodTagAdd)(_this2.dataForm).then(function (data) {
            _this2.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: function onClose() {
                _this2.visible = false;
                _this2.$emit('refreshDataList');
              }
            });
          });
        }
      });
    }
  }
};
exports.default = _default;