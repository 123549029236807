"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find-index");
require("core-js/modules/web.dom.iterable");
var _wXintegralMall = require("@/api/wxhyyy/wXintegralMall");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    isSingle: {
      default: false,
      type: Boolean
    }
  },
  data: function data() {
    return {
      visible: false,
      dataForm: {
        product: ''
      },
      singleSelectProdId: 0,
      allData: [],
      selectProds: [],
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false
    };
  },
  activated: function activated() {
    this.getDataList();
  },
  methods: {
    // 获取数据列表
    init: function init(selectProds) {
      var _this = this;
      this.selectProds = selectProds;
      this.visible = true;
      this.dataListLoading = true;
      if (this.selectProds) {
        this.selectProds.forEach(function (row) {
          _this.dataListSelections.push(row);
        });
      }
      this.getDataList();
    },
    getDataList: function getDataList() {
      var _this2 = this;
      (0, _wXintegralMall.getProdPage)(Object.assign({
        status: 1,
        current: this.pageIndex,
        size: this.pageSize
      }, {
        prodName: this.dataForm.prodName
      })).then(function (data) {
        var data = data.data;
        _this2.dataList = data.records;
        _this2.totalPage = data.total;
        _this2.dataListLoading = false;
        if (_this2.selectProds) {
          _this2.$nextTick(function () {
            _this2.selectProds.forEach(function (row) {
              var index = _this2.dataList.findIndex(function (prodItem) {
                return prodItem.prodId === row.prodId;
              });
              _this2.$refs.prodTable.toggleRowSelection(_this2.dataList[index]);
            });
          });
        }
      });
    },
    // 每页数
    sizeChangeHandle: function sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle: function currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 单选商品事件
    getSelectProdRow: function getSelectProdRow(row) {
      this.dataListSelections = [row];
    },
    // 多选点击事件
    selectChangeHandle: function selectChangeHandle(selection) {
      var _this3 = this;
      this.dataList.forEach(function (tableItem) {
        var selectedProdIndex = selection.findIndex(function (selectedProd) {
          if (!selectedProd) {
            return false;
          }
          return selectedProd.prodId === tableItem.prodId;
        });
        var dataSelectedProdIndex = _this3.dataListSelections.findIndex(function (dataSelectedProd) {
          return dataSelectedProd.prodId === tableItem.prodId;
        });
        if (selectedProdIndex > -1 && dataSelectedProdIndex === -1) {
          _this3.dataListSelections.push(tableItem);
        } else if (selectedProdIndex === -1 && dataSelectedProdIndex > -1) {
          _this3.dataListSelections.splice(dataSelectedProdIndex, 1);
        }
      });
    },
    // 确定事件
    submitProds: function submitProds() {
      var _this4 = this;
      var prods = [];
      this.dataListSelections.forEach(function (item) {
        var prodIndex = prods.findIndex(function (prod) {
          return prod.prodId === item.prodId;
        });
        if (prodIndex === -1) {
          prods.push({
            prodId: item.prodId,
            prodName: item.prodName,
            pic: item.pic
          });
        }
        _this4.$emit('refreshSelectProds', prods);
        _this4.cancle();
      });
    },
    cancle: function cancle() {
      this.dataListSelections = [];
      this.visible = false;
      this.$emit('close');
    }
  }
};
exports.default = _default;