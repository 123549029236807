var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: !_vm.dataForm.id ? "新增" : "修改",
        "close-on-click-modal": false,
        visible: _vm.visible,
        width: "600",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: {
            model: _vm.dataForm,
            rules: _vm.dataRule,
            "label-width": "100px",
          },
          nativeOn: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.dataFormSubmit()
            },
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "分组名称：", prop: "title" } },
            [
              _c("el-input", {
                attrs: { placeholder: "最多输入10个字", maxlength: "10" },
                model: {
                  value: _vm.dataForm.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "title", $$v)
                  },
                  expression: "dataForm.title",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "状态：", prop: "status" } },
            [
              _c(
                "el-radio-group",
                {
                  staticClass: "radio",
                  model: {
                    value: _vm.dataForm.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataForm, "status", $$v)
                    },
                    expression: "dataForm.status",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("正常")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("禁用")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "列表样式：", prop: "style" } },
            [
              _c(
                "el-radio-group",
                {
                  staticClass: "radio",
                  model: {
                    value: _vm.dataForm.style,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataForm, "style", $$v)
                    },
                    expression: "dataForm.style",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("一列一个")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("一列两个")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("一列三个")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "排序：", prop: "seq" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "only-number",
                    rawName: "v-only-number",
                    value: {
                      max: 99,
                      min: 1,
                      precision: 0,
                      set: _vm.dataForm,
                      name: "seq",
                    },
                    expression:
                      "{ max:99,min:1,precision:0,set: dataForm, name:'seq' }",
                  },
                ],
                attrs: { placeholder: "" },
                model: {
                  value: _vm.dataForm.seq,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "seq", $$v)
                  },
                  expression: "dataForm.seq",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.dataFormSubmit()
                },
              },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }