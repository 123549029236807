"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _pack = require("@/api/basic/pack");
var _utils = require("@/utils");
var _select = require("@/api/warehouse/select");
var _productSelectDialog = _interopRequireDefault(require("../../../productSelectDialog"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var miniUnit = '';
var _default = {
  name: 'TableSuply',
  components: {
    productDialog: _productSelectDialog.default
  },
  data: function data() {
    return {
      productCode: '',
      returnOrgType: 1,
      TypeDataComs: {
        statusList: [],
        StoreList: [],
        OrgList: [],
        sourceList: [],
        returnOrgList: [],
        returnOrgType: [{
          id: 1,
          name: '销售公司'
        }, {
          id: 2,
          name: '渠道商'
        }]
      },
      searchForm: {
        billReturnQuery: {
          inOrgId: '',
          inStoreId: '',
          outOrgId: ''
        },
        billNo: '',
        billSource: '',
        billStatus: '',
        billType: 103,
        endTime: '',
        productId: '',
        startTime: ''
      },
      productName: '',
      expandTxt: '展开',
      showCol: false
    };
  },
  created: function created() {
    this.getFactoryInfoList();
    this.getStoreList();
    this.getStatusList();
    this.getSourceList();
    this.getreturnOrgList();
  },
  mounted: function mounted() {
    var that = this;
  },
  methods: {
    mouseOver: function mouseOver() {
      this.$refs.arrow.style.color = '#409eff';
      this.$refs.arrowtest.style.color = '#409eff';
    },
    mouseLeave: function mouseLeave() {
      this.$refs.arrow.style.color = '#595959';
      this.$refs.arrowtest.style.color = '#595959';
    },
    handleFilter: function handleFilter(_type) {
      if (_type == 'rest') {
        // 搜索表单重置
        this.productName = '';
        this.searchForm = {
          billReturnQuery: {
            inOrgId: '',
            inStoreId: '',
            outOrgId: ''
          },
          billNo: '',
          billSource: '',
          billStatus: '',
          billType: 103,
          endTime: '',
          productId: '',
          startTime: ''
        };
        this.$parent.searchForm.current = 1;
        this.$parent.searchForm.size = 20;
        this.$refs.searchForm.resetFields();
      }
      this.$parent.searchList(this.$parent.searchForm, this.searchForm);
    },
    openProductWin: function openProductWin(e) {
      this.$refs.selProduct.proSelVisible = true;
    },
    clearProduct: function clearProduct(e) {
      this.searchForm.productId = '';
      this.productName = '';
    },
    setSel: function setSel(val) {
      console.log(val);
      this.productName = val.productName;
      this.searchForm.productId = val.pId;
    },
    getFactoryInfoList: function getFactoryInfoList() {
      var _this = this;
      // 获取工厂下啦列表数据
      this.TypeDataComs.OrgList = [];
      (0, _select.ccOrgInfoList)().then(function (res) {
        if (res.data.code != 200) {
          return;
        } else {
          _this.TypeDataComs.OrgList = res.data.data.filter(function (n) {
            return n;
          });
        }
      }).catch(function () {});
    },
    getStoreList: function getStoreList() {
      var _this2 = this;
      // 获取库房下拉列表数据
      this.TypeDataComs.StoreList = [];
      (0, _select.storeHouseInfoList)().then(function (res) {
        if (res.data.code != 200) {
          return;
        } else {
          _this2.TypeDataComs.StoreList = res.data.data;
        }
      }).catch(function () {});
    },
    getStatusList: function getStatusList() {
      var _this3 = this;
      // 获取单据状态
      this.TypeDataComs.statusList = [];
      (0, _select.storeTypeInfoList)(2).then(function (res) {
        console.log(res, '状态');
        if (res.data.code != 200) {
          return;
        } else {
          _this3.TypeDataComs.statusList = res.data.data;
        }
      }).catch(function () {});
    },
    getSourceList: function getSourceList() {
      var _this4 = this;
      // 获取单据来源
      this.TypeDataComs.sourceList = [];
      (0, _select.storeTypeInfoList)(4).then(function (res) {
        if (res.data.code != 200) {
          return;
        } else {
          _this4.TypeDataComs.sourceList = res.data.data;
        }
      }).catch(function () {});
    },
    getreturnOrgList: function getreturnOrgList() {
      var _this5 = this;
      if (this.searchForm.returnOrg != undefined) {
        // 车间id
        this.$refs.returnOrg.resetField();
      }
      this.TypeDataComs.returnOrgList = [];
      if (this.returnOrgType == 1) {
        // 获取销售公司
        (0, _select.getXgOrgInfo)({
          orgType: 2
        }).then(function (res) {
          if (res.data.code != 200) {
            _this5.$message.error(res.data.msg);
            return;
          } else {
            for (var i in res.data.data) {
              var v = res.data.data[i];
              _this5.TypeDataComs.returnOrgList.push({
                id: v.pId,
                name: v.orgName
              });
            }
          }
        }).catch(function () {});
      }
      if (this.returnOrgType == 2) {
        // 获取渠道档案数据
        (0, _select.getjcChannelArchivesList)({
          size: 211111111,
          current: 1,
          channelType: 1
        }).then(function (res) {
          if (res.data.code != 200) {
            return;
          } else {
            for (var i in res.data.data.records) {
              var v = res.data.data.records[i];
              _this5.TypeDataComs.returnOrgList.push({
                id: v.pId,
                name: v.channelName
              });
            }
          }
        }).catch(function () {});
      }
    },
    setFactory: function setFactory(v) {},
    // 展开收起搜索条件
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    }
  }
};
exports.default = _default;