"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {},
  data: function data() {
    return {
      visible: true,
      longitude: '',
      latitude: '',
      lalConfig: {},
      geometries: []
    };
  },
  created: function created() {
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;
      this.$nextTick(function () {
        console.log();
        var center = new window.TMap.LatLng(39.984120, 116.307484);
        // 39.90960456049752东经:116.3972282409668
        var map = new window.TMap.Map(document.getElementById('map'), {
          center: center,
          // 设置地图中心点坐标
          zoom: 12
        });
        map.on('click', _this.clickHandler);
        _this.markerLayer = new window.TMap.MultiMarker({
          map: map,
          // 指定地图容器
          // 样式定义
          styles: {
            // 创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
            // 'myStyle': new TMap.MarkerStyle({
            //   'width': 25, // 点标记样式宽度（像素）
            //   'height': 35, // 点标记样式高度（像素）
            //   'src': require('../../assets/images/address.png'), // 图片路径
            //   // 焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
            //   'anchor': { x: 16, y: 32 }
            // })
          },
          // 点标记数据数组
          geometries: _this.geometries
        });
      });
    },
    clickHandler: function clickHandler(evt) {
      var lat = evt.latLng.getLat().toFixed(6);
      var lng = evt.latLng.getLng().toFixed(6);
      this.lalConfig = {
        id: new Date().getTime(),
        latitude: lat,
        longitude: lng
      };
      this.markerLayer.updateGeometries([{
        'id': '1',
        // 点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
        'styleId': 'myStyle',
        // 指定样式id
        'position': new window.TMap.LatLng(lat, lng) // 点标记坐标位置
      }]);
    },
    handleClose: function handleClose() {
      this.$emit('close');
      this.visible = false;
    },
    confirmFun: function confirmFun() {
      this.$emit('confirm', this.lalConfig);
      this.visible = false;
    }
  }
};
exports.default = _default;