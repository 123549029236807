var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "magmanager" } },
    [
      _c("el-container", [
        _c(
          "div",
          { staticClass: "hyyy-container" },
          [
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  [
                    _c(
                      "el-card",
                      { staticClass: "box-card" },
                      [
                        _c("div", { staticClass: "hyyy-top-tit" }, [
                          _c("div", { staticClass: "hyyy-top-tit-lift" }, [
                            _c("span", {
                              staticStyle: {
                                "font-weight": "400",
                                color: "#333",
                              },
                              domProps: {
                                textContent: _vm._s(_vm.$route.meta.title),
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-size": "13px",
                                  color: "#99A3AD",
                                  "margin-left": "10px",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                  注意：1.统计数据具有延时性，数据于第二天8点才会更新。2.该群发消息列表的消息封面和消息标题默认抓取第一篇图文的封面和标题。\n                "
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "hyyy-top-tit-right" },
                            [
                              _c("admincut", {
                                staticStyle: { "margin-top": "7px" },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-divider", { staticStyle: { margin: "0" } }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "hyyy-contentt" },
                          [
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c(
                                      "el-form",
                                      {
                                        staticClass: "demo-form-inline",
                                        attrs: {
                                          inline: true,
                                          model: _vm.formInline,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "cols" },
                                          [
                                            _c(
                                              "el-row",
                                              { attrs: { gutter: 20 } },
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 6 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          label: "标题名称：",
                                                        },
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          attrs: {
                                                            placeholder:
                                                              "请输入标题名称",
                                                            size: "small ",
                                                          },
                                                          model: {
                                                            value: _vm.keyWord,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.keyWord = $$v
                                                            },
                                                            expression:
                                                              "keyWord",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 6 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          label: "时间",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "block",
                                                          },
                                                          [
                                                            _c(
                                                              "el-date-picker",
                                                              {
                                                                attrs: {
                                                                  type: "daterange",
                                                                  align:
                                                                    "right",
                                                                  "unlink-panels":
                                                                    "",
                                                                  size: "small ",
                                                                  "range-separator":
                                                                    "-",
                                                                  "start-placeholder":
                                                                    "开始日期",
                                                                  "end-placeholder":
                                                                    "结束日期",
                                                                  format:
                                                                    "yyyy-MM-dd",
                                                                  "value-format":
                                                                    "yyyy-MM-dd",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.daterangeVal,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.daterangeVal =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "daterangeVal",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-col",
                                                  {
                                                    staticClass:
                                                      "hyyy-head-btn",
                                                    staticStyle: {
                                                      width: "371px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          "label-width": "0",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            attrs: {
                                                              slot: "label",
                                                            },
                                                            slot: "label",
                                                          },
                                                          [_vm._v(" ")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "primary",
                                                              size: "mini ",
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.onSubmit,
                                                            },
                                                          },
                                                          [_vm._v("查询")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "primary",
                                                              size: "mini ",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.creatMess(
                                                                  ""
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("新建群发")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              size: "mini",
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.downLoad,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "下载图文明细"
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-table",
                              {
                                staticStyle: {
                                  width: "100%",
                                  "margin-bottom": "20px",
                                },
                                attrs: {
                                  data: _vm.tableData,
                                  "row-key": "rowKey",
                                  border: "",
                                  fit: "",
                                  "tree-props": { children: "wxNewsVoList" },
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: { prop: "id", label: "消息封面" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.filesUrl &&
                                          scope.row.mediaType === "1"
                                            ? _c(
                                                "div",
                                                { staticClass: "img-wrap" },
                                                [
                                                  _c("el-image", {
                                                    staticStyle: {
                                                      width: "100%",
                                                      height: "100%",
                                                    },
                                                    attrs: {
                                                      src:
                                                        _vm.baseUrl +
                                                        scope.row.filesUrl,
                                                      fit: "contain",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : scope.row.subFilesUrl
                                            ? _c(
                                                "div",
                                                { staticClass: "img-wrap" },
                                                [
                                                  _c("el-image", {
                                                    staticStyle: {
                                                      width: "100%",
                                                      height: "100%",
                                                    },
                                                    attrs: {
                                                      src:
                                                        _vm.baseUrl +
                                                        scope.row.subFilesUrl,
                                                      fit: "contain",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : scope.row.mediaType === "2" &&
                                              scope.row.filesUrl2
                                            ? _c(
                                                "div",
                                                { staticClass: "img-wrap" },
                                                [
                                                  _c("el-image", {
                                                    staticStyle: {
                                                      width: "100%",
                                                      height: "100%",
                                                    },
                                                    attrs: {
                                                      src:
                                                        _vm.baseUrl +
                                                        scope.row.filesUrl2,
                                                      fit: "contain",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : scope.row.mediaType === "3"
                                            ? _c(
                                                "div",
                                                { staticClass: "img-wrap" },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-service",
                                                    staticStyle: {
                                                      "font-size": "50px",
                                                      margin: "30px",
                                                    },
                                                  }),
                                                ]
                                              )
                                            : scope.row.mediaType === "4"
                                            ? _c(
                                                "div",
                                                { staticClass: "img-wrap" },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-caret-right",
                                                    staticStyle: {
                                                      "font-size": "50px",
                                                      margin: "30px",
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "title",
                                    label: "消息标题",
                                    width: "100",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "sendTime",
                                    label: "发送时间",
                                    width: "100",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "newsReadingCount",
                                    label: "图文阅读次数",
                                    sortable: "",
                                    width: "130",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "ewsReadingNumber",
                                    sortable: "",
                                    label: "收藏图文次数",
                                    width: "130",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "shareCount",
                                    label: "转发图文次数",
                                    sortable: "",
                                    width: "130",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "sendCount",
                                    label: "成功发送数量",
                                    width: "110",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { prop: "status", label: "发送状态" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.status === "2"
                                            ? _c("div", [_vm._v("未发送")])
                                            : scope.row.status === "1"
                                            ? _c("div", [_vm._v("已发送")])
                                            : scope.row.status === "3"
                                            ? _c(
                                                "div",
                                                [
                                                  _vm._v(
                                                    "\n                      发送失败\n                      "
                                                  ),
                                                  _c(
                                                    "el-popover",
                                                    {
                                                      attrs: {
                                                        placement: "top",
                                                        title: "",
                                                        width: "400",
                                                        trigger: "hover",
                                                      },
                                                    },
                                                    [
                                                      _c("p", [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-warning",
                                                          staticStyle: {
                                                            color: "red",
                                                            "font-size": "16px",
                                                          },
                                                          attrs: {
                                                            slot: "reference",
                                                          },
                                                          slot: "reference",
                                                        }),
                                                        _vm._v(
                                                          "\n                          失败原因： " +
                                                            _vm._s(258) +
                                                            "\n                        "
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-warning",
                                                        staticStyle: {
                                                          color: "red",
                                                          "font-size": "20px",
                                                        },
                                                        attrs: {
                                                          slot: "reference",
                                                        },
                                                        slot: "reference",
                                                      }),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _c("div"),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "操作", width: "160" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.status === "1"
                                            ? _c(
                                                "div",
                                                { staticClass: "btns" },
                                                [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        size: "mini",
                                                        disabled: "",
                                                      },
                                                    },
                                                    [_vm._v("编辑")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        size: "mini",
                                                        type: "danger",
                                                        disabled: "",
                                                      },
                                                    },
                                                    [_vm._v("删除")]
                                                  ),
                                                ],
                                                1
                                              )
                                            : scope.row.status === "3"
                                            ? _c(
                                                "div",
                                                { staticClass: "btns" },
                                                [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        size: "mini",
                                                        disabled: "",
                                                      },
                                                    },
                                                    [_vm._v("编辑")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        size: "mini",
                                                        type: "danger",
                                                        disabled: "",
                                                      },
                                                    },
                                                    [_vm._v("删除")]
                                                  ),
                                                ],
                                                1
                                              )
                                            : scope.row.status === "2"
                                            ? _c(
                                                "div",
                                                { staticClass: "btns" },
                                                [
                                                  scope.row.mediaType === "1"
                                                    ? _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            size: "mini",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleEdit(
                                                                scope.row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("编辑")]
                                                      )
                                                    : _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            size: "mini",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.creatMess(
                                                                scope.row.id
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("编辑")]
                                                      ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        size: "mini",
                                                        type: "danger",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleDelete(
                                                            scope.row.id
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("删除")]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _c("div"),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }