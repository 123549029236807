"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _order = require("@/api/order");
var _companySelectDialog = _interopRequireDefault(require("@/components/companySelectDialog"));
var _productDialog = _interopRequireDefault(require("@/components/productDialog"));
var _dictionary = require("@/api/systems/dictionary");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var TypeDataComs = {
  orderStatus: []
};
var _default = {
  name: 'OrderCentercostorderCcconfig',
  components: {
    companySelectDialog: _companySelectDialog.default,
    productDialog: _productDialog.default
  },
  filters: {
    purchaseComOwnerType: function purchaseComOwnerType(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          1: '买赠订单',
          2: '酒券订单',
          3: '团购订单',
          4: '费用酒订单',
          5: '样品酒订单'
        };
        return statusMap[value];
      }
    },
    orderStatusText: function orderStatusText(value) {
      var name = '';
      TypeDataComs.orderStatus.forEach(function (c) {
        if (c.figure == value) {
          name = c.name;
          return;
        }
      });
      return name;
    }
  },
  data: function data() {
    return {
      // 销售单位
      saleOrgName: '',
      // 采购单位名称
      purchaseOrgName: '',
      // 收货单位名称
      receiveOrgName: '',
      // 发货单位名称
      sendOrgName: '',
      active: 0,
      proType: '',
      listIndex: '',
      activeTotal: 2,
      orderStatus: [],
      inUseName: '',
      companyShow: false,
      productShow: false,
      orgId: '',
      inOutType: '',
      receiveLoading: false,
      companycanChange: true,
      factorycanChange: true,
      companyType: '',
      factoryType: '',
      isEnable: '',
      loading: false,
      proSelectList: [],
      productList: [],
      addData: {
        orderNo: '',
        orderProductAOS: [],
        orderSource: 1,
        orderType: '',
        purchaseOrgId: '',
        receiveAddress: '',
        receiveOrgId: '',
        receivePhone: '',
        receiveUser: '',
        remark: '',
        saleOrgId: '',
        sendOrgId: '',
        tradeDate: (0, _utils.parseTime)(new Date(), '{y}-{m}-{d}'),
        tradeType: 3
      },
      rules: {
        orderNo: [{
          required: true,
          message: '费用酒订单号是必填项，请填写！',
          trigger: 'change'
        }],
        orderType: [{
          required: true,
          message: '费用酒订单类型是必填项，请填写！',
          trigger: 'change'
        }],
        saleOrgId: [{
          required: true,
          message: '销售单位是必填项，请填写！',
          trigger: 'change'
        }],
        sendOrgId: [{
          required: true,
          message: '发货单位是必填项，请填写！',
          trigger: 'change'
        }],
        purchaseOrgId: [{
          required: true,
          message: '采购单位是必填项，请填写！',
          trigger: 'change'
        }],
        receiveOrgId: [{
          required: true,
          message: '收货单位是必填项，请填写！',
          trigger: 'change'
        }],
        tradeDate: [{
          required: true,
          message: '请选择订单日期',
          trigger: 'change'
        }]
      }
    };
  },
  mounted: function mounted() {
    this.getDirSel('CCCGDDZT', 'orderStatus');
    this.DoAdd();
  },
  methods: {
    getDirSel: function getDirSel(code, list) {
      var _this = this;
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: code
      }).then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this[list] = res.data.data;
            TypeDataComs[list] = res.data.data;
          } else {
            _this[list] = [];
            TypeDataComs[list] = [];
          }
        }
      });
    },
    // 新增
    DoAdd: function DoAdd() {
      this.getNo();
      this.initialization();
    },
    getNo: function getNo() {
      var _this2 = this;
      (0, _order.getOrderNo)(3).then(function (res) {
        if (res.data.code == 200) {
          _this2.addData.orderNo = res.data.data;
        } else {
          _this2.$message.error('订单号获取失败，请重试！');
        }
      });
    },
    initialization: function initialization() {
      var _this3 = this;
      (0, _order.getCompanyById)({
        pId: JSON.parse(localStorage.getItem('currentUser')).orgId
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          var that = _this3;
          var datas = response.data.data;
          _this3.addData.saleOrgId = datas.pId || '';
          _this3.saleOrgName = datas.channelName || '';
        }
      });
    },
    /**
     * 单位选择
     * @param {*} name 需要回传修改的名字
     * @param {*} companyType 单位类型 1 收获单位 2发货单位 0采购单位
     * @param {*} inOutType 单据类型 1销售出库 2调拨出库 3其他出库 4生产入库 5调拨入库 6采购入库 7其他入库 11 采购订单 12销售订单
     *
     */
    openCompany: function openCompany(name, companyType, inOutType, orgId) {
      if (name == 'purchaseComName' && !this.addData.orderType) {
        this.$message.error('请先选择销售订单类型');
        return;
      }
      this.orgId = orgId || '';
      this.inUseName = name;
      this.companyType = companyType;
      this.inOutType = inOutType;
      this.companyShow = true;
    },
    setCompany: function setCompany(val) {
      this[this.inUseName] = val.companyName;
      switch (this.inUseName) {
        case 'saleOrgName':
          this.addData.saleOrgId = val.companyId;
          this.resetList();
          break;
        case 'sendOrgName':
          this.addData.sendOrgId = val.companyId;
          break;
        case 'purchaseOrgName':
          this.addData.purchaseOrgId = val.companyId;
          this.addData.receiveOrgId = val.companyId;
          this.receiveOrgName = val.companyName;
          this.resetList();
          this.getReceiveInfo(val);
          break;
      }
    },
    // 匹配收货单位信息
    getReceiveInfo: function getReceiveInfo(val) {
      var _this4 = this;
      this.receiveLoading = true;
      (0, _order.getCompanyById)({
        pId: val.companyId
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          var that = _this4;
          var datas = response.data.data;
          _this4.addData.receiveUser = datas.contacts || '';
          _this4.addData.receivePhone = datas.telephone || datas.phone || '';
          var provinceName = datas.provinceName || '';
          var cityName = datas.cityName || '';
          var countyName = datas.countyName || '';
          var detailedAddress = datas.detailedAddress || '';
          _this4.addData.receiveAddress = provinceName + cityName + countyName + detailedAddress;
        }
        _this4.receiveLoading = false;
      }).catch(function () {
        _this4.receiveLoading = false;
      });
    },
    companyClose: function companyClose() {
      this.inUseName = '';
      this.companyType = '';
      this.orgId = '';
      this.isEnable = '';
      this.companycanChange = true;
      this.companyShow = false;
    },
    resetReceive: function resetReceive() {
      this.receiveComName = '';
      this.addData.receiveComId = '';
      this.addData.receiveContacts = '';
      this.addData.receivePhone = '';
      this.addData.receiveAddress = '';
    },
    next: function next() {
      var _this5 = this;
      switch (this.active) {
        case 0:
          this.$refs['addForm'].validate(function (valid) {
            if (valid) {
              if (_this5.addData.purchaseOrgId == _this5.addData.sendOrgId) {
                _this5.$message.error('采购单位和发货单位不能一致');
                return;
              }
              if (_this5.addData.saleOrgId != _this5.addData.sendOrgId) {
                _this5.$confirm('您所创建的订单为：代发货订单，请确认是否进行代发货？', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(function () {
                  _this5.active = _this5.active * 1 + 1;
                }).catch(function () {});
                return;
              }
              _this5.active = _this5.active * 1 + 1;
            } else {
              _this5.$message.error('请完善信息！');
            }
          });
          break;
        case 1:
          if (!this.productList.length) {
            this.$message.error('请选择产品');
            return;
          }
          if (this.productList.length > 0) {
            for (var i = 0; i < this.productList.length; i++) {
              if (!this.productList[i].totalBottleNum || this.productList[i].totalBottleNum == 0) {
                this.$message.error('在列表第' + (i + 1) + '行请输入销售数量');
                return;
              }
            }
          }
          this.submitForm();
          break;
      }
    },
    pro: function pro() {
      this.active = this.active * 1 - 1;
    },
    openPro: function openPro(val, index) {
      this.proType = val;
      this.listIndex = index;
      if (!this.addData.saleOrgId || !this.addData.purchaseOrgId || !this.addData.receiveOrgId) {
        this.$message.error('请先选择采购单位、收货单位、供货单位');
        return;
      }
      switch (val) {
        case 1:
          this.proSelectList = this.productList;
          break;
        case 2:
          this.proSelectList = this.zpList;
          break;
        case 3:
          this.proSelectList = this.productList[index].giftProductAOS || [];
          break;
      }
      this.proSelectList = val == 1 ? this.productList : this.zpList;
      this.productShow = true;
    },
    setPro: function setPro(val) {
      var list, arr, productType;
      arr = JSON.parse(JSON.stringify(this.productList));
      list = 'productList';
      productType = 1;
      if (val.length) {
        if (this[list].length == 0) {
          var obj = {
            chestNum: '',
            bottleNum: '',
            totalBottleNum: 0,
            productType: productType
          };
          val.forEach(function (item) {
            item = Object.assign(item, obj);
          });
        } else {
          val.forEach(function (item) {
            item.chestNum = 0;
            item.bottleNum = 0;
            item.totalBottleNum = 0;
            item.productType = productType;
            arr.forEach(function (el) {
              if (el.pId == item.pId) {
                item.chestNum = el.chestNum;
                item.bottleNum = el.bottleNum;
                item.totalBottleNum = el.totalBottleNum;
              }
            });
          });
        }
        this[list] = JSON.parse(JSON.stringify(val));
      } else {
        this[list] = [];
      }
    },
    proClose: function proClose() {
      this.proSelectList = '';
      this.proType = '';
      // this.listIndex = ''
      this.productShow = false;
    },
    changeChestNum: function changeChestNum(row) {
      var value = row.packScaleExpression;
      var arr = value.split('*');
      var le = arr.length - 1;
      row.totalBottleNum = (row.chestNum || 0) * arr[le] + (row.bottleNum || 0);
      // this.setZPnum(row)
    },
    resetList: function resetList() {
      this.productList = JSON.parse(JSON.stringify(this.productList));
    },
    // 删除产品明细列表
    DoSc: function DoSc(row) {
      var _this6 = this;
      var that = this;
      that.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this6.productList.splice(_this6.productList.indexOf(row), 1);
      });
    },
    mixNumP: function mixNumP(row) {
      var arr = row.packScaleExpression.split('*');
      return arr[arr.length - 1] * 1 - 1;
    },
    // 置空字段
    resetVoid: function resetVoid() {
      this.addData = {
        pid: '',
        orderType: 2,
        orderNo: '',
        placeOrderDate: (0, _utils.parseTime)(new Date(), '{y}-{m}-{d}'),
        paymentStyle: '',
        purchaseComId: '',
        purchaseComOwnerType: '',
        purchaseComType: '',
        applyUser: '',
        receiveComId: '',
        receiveComType: '',
        receiveContacts: '',
        receivePhone: '',
        receiveAddress: '',
        sendOutComId: '',
        sendOutComType: '',
        orderStatus: 1,
        remark: '',
        // 产品列表
        businessClass: 1,
        orderDetailDTOS: []
      };
      this.productList = [];
      this.sendComId = '';
      this.purchaseComName = '';
      this.receiveComName = '';
      this.sendOutComName = '';
    },
    handleClose: function handleClose() {
      this.resetVoid();
      if (this.$refs.addForm) this.$refs.addForm.resetFields();
      this.$router.replace({
        name: 'orderCentercostorder-ccindex'
      });
    },
    submitForm: function submitForm(val) {
      var _this7 = this;
      var that = this;
      var plist = JSON.parse(JSON.stringify(that.productList));
      var newList = [];
      if (plist.length > 0) {
        for (var i = 0; i < plist.length; i++) {
          if (!plist[i].totalBottleNum || plist[i].totalBottleNum == 0) {
            that.$message.error('在产品列表第' + (i + 1) + '行请输入销售数量');
            return;
          }
          var obj = {
            totalBottleNum: plist[i].totalBottleNum,
            bottleNum: plist[i].bottleNum,
            chestNum: plist[i].chestNum,
            orderNo: that.addData.orderNo,
            activityId: that.addData.activityId || '',
            activityType: that.addData.orderType == 3 ? '' : that.addData.orderType,
            productId: plist[i].pId,
            giftProductAOS: plist[i].giftProductAOS,
            productType: plist[i].productType
          };
          if (this.addData.orderType == 2) {
            obj.productAmount = plist[i].wineTotalPrice;
            obj.productPrice = plist[i].winePrice;
          }
          newList.push(obj);
        }
      }
      that.addData.orderProductAOS = newList;
      that.loading = true;
      that.dsate = true;
      var subData = that.addData;
      (0, _order.addJudgeOrder)(subData).then(function (res) {
        if (res.data.code == 200) {
          that.$message({
            type: 'success',
            message: '添加成功'
          });
          _this7.active++;
        } else {
          that.$message.error(res.data.msg);
        }
        that.loading = false;
        that.dsate = false;
      }).catch(function () {
        that.loading = false;
        that.dsate = false;
      });
    },
    onCopy: function onCopy(e) {
      this.$message.success('内容已复制到剪切板！');
    },
    onError: function onError(e) {
      this.$message.error('抱歉，复制失败！');
    }
  }
};
exports.default = _default;