var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c("h3", { staticClass: "h-title" }, [_vm._v("生产计划单")]),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  ref: "searchForm",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.searchForm,
                    "status-icon": "",
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cols" },
                    [
                      _c(
                        "el-row",
                        {
                          staticStyle: { "margin-bottom": "0" },
                          attrs: { gutter: 20 },
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "templateCode",
                                    label: "模板编码:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                      maxlength: "50",
                                    },
                                    model: {
                                      value:
                                        _vm.searchForm.otherTradeBillQuery
                                          .templateCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm.otherTradeBillQuery,
                                          "templateCode",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression:
                                        "searchForm.otherTradeBillQuery.templateCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "templateName",
                                    label: "模板名称:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                      maxlength: "50",
                                    },
                                    model: {
                                      value:
                                        _vm.searchForm.otherTradeBillQuery
                                          .templateName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm.otherTradeBillQuery,
                                          "templateName",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression:
                                        "searchForm.otherTradeBillQuery.templateName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "productionStatus",
                                    label: "状态:",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value:
                                          _vm.searchForm.otherTradeBillQuery
                                            .templateStatus,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm.otherTradeBillQuery,
                                            "templateStatus",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "searchForm.otherTradeBillQuery.templateStatus",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "启用", value: "1" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { label: "禁用", value: "0" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.formConfig.btnFollow
                            ? _c(
                                "el-col",
                                {
                                  staticClass: "head-btn-group",
                                  style: {
                                    width: _vm.formConfig.btnAreaHasShowMore,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { "label-width": "0" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v(" ")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.searchBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.searchBtn.icon,
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.searchForm.current = 1
                                              _vm.getList()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.searchBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.resetBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.resetBtn.icon,
                                          },
                                          on: { click: _vm.resetForm },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.resetBtn.text)
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.formConfig.btnFollow
                ? _c("el-divider", { staticClass: "btnDivider" })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btnArea" },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "head-btn-group",
                      staticStyle: { width: "230px" },
                    },
                    [
                      _vm.hasAuth("addbtn", _vm.$route.meta.authList)
                        ? _c(
                            "div",
                            { staticClass: "head-btn-group" },
                            [
                              _vm.hasAuth("addbtn", _vm.$route.meta.authList)
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.addBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.addBtn.icon,
                                      },
                                      on: { click: _vm.add },
                                    },
                                    [_vm._v(_vm._s(_vm.btnConfig.addBtn.text))]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  key: _vm.tableKey,
                  staticStyle: { width: "100%" },
                  attrs: {
                    height: _vm.tabHeight,
                    data: _vm.tableData,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      align: _vm.tableConfig.align,
                      width: "60",
                      label: "序号",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "模板编码",
                      "min-width": "150",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                      prop: "templateCode",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "模板名称",
                      "min-width": "150",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                      prop: "templateName",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "创建人",
                      prop: "createName",
                      "min-width": "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "状态",
                      "min-width": "140",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              row.templateStatus == 1
                                ? _c("el-tag", { attrs: { type: "" } }, [
                                    _vm._v("启用"),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              row.templateStatus == 0
                                ? _c("el-tag", { attrs: { type: "info" } }, [
                                    _vm._v("停用"),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1747060522
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "创建时间",
                      align: _vm.tableConfig.align,
                      "min-width": "180",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatDate")(scope.row.createTime)
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      921844889
                    ),
                  }),
                  _vm._v(" "),
                  _vm.hasAuth("detailbtn", _vm.$route.meta.authList)
                    ? _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          fixed: "right",
                          align: _vm.tableConfig.align,
                          "min-width": "220",
                          "class-name": "small-padding fixed-width",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm.hasAuth(
                                    "detailbtn",
                                    _vm.$route.meta.authList
                                  )
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.scanDetail(row)
                                            },
                                          },
                                        },
                                        [_vm._v("详情")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.hasAuth(
                                    "updatebtn",
                                    _vm.$route.meta.authList
                                  )
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.doUpdate(row)
                                            },
                                          },
                                        },
                                        [_vm._v("编辑")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.templateStatus == 1 &&
                                  _vm.hasAuth(
                                    "isenableBtn",
                                    _vm.$route.meta.authList
                                  )
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.startFun(row.pid, 0)
                                            },
                                          },
                                        },
                                        [_vm._v("停用")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.templateStatus == 0 &&
                                  _vm.hasAuth(
                                    "isenableBtn",
                                    _vm.$route.meta.authList
                                  )
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.startFun(row.pid, 1)
                                            },
                                          },
                                        },
                                        [_vm._v("启用")]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2344121685
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  layout: "total,sizes,prev, pager, next, jumper",
                  total: _vm.total,
                  page: _vm.searchForm.current,
                  limit: _vm.searchForm.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.searchForm, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.searchForm, "size", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: _vm.title,
            visible: _vm.dialogVisible,
            "before-close": _vm.dialogClose,
            "custom-class": "customWidth",
            width: "45%!important",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.mloading,
                  expression: "mloading",
                },
              ],
              ref: "scanForm",
              staticClass: "info-items",
              attrs: {
                model: _vm.scanData,
                rules: _vm.rules,
                "label-position": "right",
                "label-width": "150px",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "templateName", label: "模板名称:" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: { clearable: "", placeholder: "请输入" },
                            model: {
                              value: _vm.scanData.templateName,
                              callback: function ($$v) {
                                _vm.$set(_vm.scanData, "templateName", $$v)
                              },
                              expression: "scanData.templateName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "比例层级:", prop: "templateLevel" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                disabled: _vm.isUsed == 1,
                                placeholder: "请选择",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.setScanFactory(
                                    _vm.scanData.templateLevel
                                  )
                                },
                              },
                              model: {
                                value: _vm.scanData.templateLevel,
                                callback: function ($$v) {
                                  _vm.$set(_vm.scanData, "templateLevel", $$v)
                                },
                                expression: "scanData.templateLevel",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "3层级", value: 3 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "4层级", value: 4 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "5层级", value: 5 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "6层级", value: 6 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "7层级", value: 7 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "8层级", value: 8 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "9层级", value: 9 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "10层级", value: 10 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "11层级", value: 11 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "12层级", value: 12 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._l(
                      _vm.scanData.lithiumLevelTemplateDTOS,
                      function (item, index) {
                        return _c(
                          "el-form-item",
                          {
                            key: index,
                            staticStyle: { height: "40px" },
                            attrs: { "label-width": "22px", required: "" },
                          },
                          [
                            _c(
                              "el-row",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                },
                              },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 10 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "lithiumLevelTemplateDTOS." +
                                            index +
                                            ".levelName",
                                          "label-width": "130px",
                                          label:
                                            "层级" +
                                            (index + 1) +
                                            "名称" +
                                            (index ==
                                            _vm.scanData
                                              .lithiumLevelTemplateDTOS.length -
                                              1
                                              ? "(组码)"
                                              : ""),
                                          rules: {
                                            required: true,
                                            message: "层级名称不能为空",
                                            trigger: "blur",
                                          },
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入层级名称",
                                            disabled:
                                              index == 0 ||
                                              index == 1 ||
                                              index == 2 ||
                                              _vm.isUsed == 1,
                                          },
                                          model: {
                                            value: item.levelName,
                                            callback: function ($$v) {
                                              _vm.$set(item, "levelName", $$v)
                                            },
                                            expression: "item.levelName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 10 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "规格串数",
                                          prop:
                                            "lithiumLevelTemplateDTOS." +
                                            index +
                                            ".levelCount",
                                          "label-width": "130px",
                                          type: "number",
                                          rules: {
                                            required: true,
                                            message: "规格串数不能为空",
                                            trigger: "blur",
                                          },
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入规格串数",
                                            disabled:
                                              index !== 0 || _vm.isUsed == 1,
                                          },
                                          on: { blur: _vm.changetab },
                                          nativeOn: {
                                            input: function ($event) {
                                              return _vm.number($event)
                                            },
                                          },
                                          model: {
                                            value: item.levelCount,
                                            callback: function ($$v) {
                                              _vm.$set(item, "levelCount", $$v)
                                            },
                                            expression: "item.levelCount",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 10 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "lithiumLevelTemplateDTOS." +
                                            index +
                                            ".markLength",
                                          label: "位数",
                                          "label-width": "130px",
                                          rules: {
                                            required: true,
                                            message: "位数不能为空",
                                            trigger: "blur",
                                          },
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            type: "number",
                                            placeholder: "请输入位数",
                                            disabled: _vm.isUsed == 1,
                                          },
                                          nativeOn: {
                                            input: function ($event) {
                                              return _vm.number($event)
                                            },
                                          },
                                          model: {
                                            value: item.markLength,
                                            callback: function ($$v) {
                                              _vm.$set(item, "markLength", $$v)
                                            },
                                            expression: "item.markLength",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("el-row"),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }
                    ),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "dialog-footer",
                          staticStyle: { "text-align": "right" },
                        },
                        [
                          _c("el-button", { on: { click: _vm.dialogClose } }, [
                            _vm._v("取 消"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.btnLoading,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.submitForm(1)
                                },
                              },
                            },
                            [_vm._v("确 定")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: "模板-详情",
            visible: _vm.detailVisible,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.detailVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.mloading,
                  expression: "mloading",
                },
              ],
              ref: "scanData",
              staticClass: "info-items",
              attrs: {
                model: _vm.detailData,
                "label-position": "right",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-descriptions",
                { attrs: { title: "基本信息", column: 2, border: "" } },
                [
                  _c("el-descriptions-item", { attrs: { label: "模板名称" } }, [
                    _vm._v(_vm._s(_vm.detailData.templateName)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "模板层级" } }, [
                    _vm._v(_vm._s(_vm.detailData.templateLevel)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "创建人" } }, [
                    _vm._v(_vm._s(_vm.detailData.createName)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "创建时间" } }, [
                    _vm._v(_vm._s(_vm.detailData.createTime)),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "itemTitle",
                  staticStyle: {
                    border: "1px solid #e8e8e8",
                    padding: "10px",
                    "margin-bottom": "-1px",
                    background: "#ececec",
                  },
                },
                [_vm._v("\n        模板详情\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  staticClass: "detailTable",
                  staticStyle: { width: "100%", "margin-top": "1px" },
                  attrs: {
                    data: _vm.detailtable,
                    fit: "",
                    "max-height": "400",
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      align: _vm.tableConfig.align,
                      width: "60",
                      label: "序号",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "层级名称",
                      prop: "levelName",
                      align: _vm.tableConfig.align,
                      "min-width": "150",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "规格串数",
                      prop: "levelCount",
                      align: _vm.tableConfig.align,
                      "min-width": "120",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "位数",
                      prop: "markLength",
                      "min-width": "120",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.isAudit
                ? _c(
                    "el-button",
                    {
                      attrs: { loading: _vm.btnLoading, type: "primary" },
                      on: { click: _vm.audit },
                    },
                    [_vm._v("提交")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("关 闭"),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("product-dialog", {
            ref: "selProduct",
            on: { change: _vm.setSel },
          }),
          _vm._v(" "),
          _c("Arrange", { ref: "insertMain" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }