var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "数码详情",
        visible: _vm.codeVisible,
        "before-close": _vm.handleClose,
        width: "1200px !important",
      },
      on: {
        "update:visible": function ($event) {
          _vm.codeVisible = $event
        },
      },
    },
    [
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoading,
                expression: "listLoading",
              },
            ],
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.tableData,
              border: _vm.tableConfig.border,
              stripe: _vm.tableConfig.stripe,
              "row-key": "id",
              "tree-props": {
                children: "children",
                hasChildren: "hasChildren",
              },
            },
          },
          [
            _c("el-table-column", {
              attrs: { type: "index", width: "120", label: "序号" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "codeType", label: "数码类型" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    return [
                      row.codeType == 1
                        ? _c("span", [_vm._v("盖码")])
                        : _vm._e(),
                      _vm._v(" "),
                      row.codeType == 2
                        ? _c("span", [_vm._v("瓶码")])
                        : _vm._e(),
                      _vm._v(" "),
                      row.codeType == 3
                        ? _c("span", [_vm._v("盒码")])
                        : _vm._e(),
                      _vm._v(" "),
                      row.codeType == 4
                        ? _c("span", [_vm._v("箱码")])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", { attrs: { prop: "code", label: "数码" } }),
          ],
          1
        ),
        _vm._v(" "),
        _c("pagination", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.total > 0,
              expression: "total > 0",
            },
          ],
          attrs: {
            layout: "total, sizes, prev, pager, next, jumper",
            total: _vm.total,
            page: _vm.listQuery.current,
            limit: _vm.listQuery.size,
          },
          on: {
            "update:page": function ($event) {
              return _vm.$set(_vm.listQuery, "current", $event)
            },
            "update:limit": function ($event) {
              return _vm.$set(_vm.listQuery, "size", $event)
            },
            pagination: _vm.getList,
          },
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "dialog-footer",
            attrs: { slot: "footer" },
            slot: "footer",
          },
          [
            _c(
              "el-button",
              {
                attrs: { type: "default", plain: "" },
                on: { click: _vm.handleClose },
              },
              [_vm._v("关闭")]
            ),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }