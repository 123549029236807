"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _activityManage = require("@/api/market/activityManage");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  filters: {
    typeFilter: function typeFilter(status) {
      var statusMap = {
        1: '集字',
        2: '扫码抽奖',
        3: '大转盘'
      };
      return statusMap[status];
    },
    stateFilter: function stateFilter(status) {
      var statusMap = {
        100: '未开始',
        200: '进行中',
        300: '已结束',
        600: '暂停中'
      };
      return statusMap[status];
    }
  },
  props: {
    prizeVisible: Boolean,
    actNo: null
  },
  data: function data() {
    return {
      list: null,
      listLoading: true,
      radio: '',
      dialogVisible: false,
      formInline: {
        num: null,
        actConfigItemId: null
      },
      rules: {
        num: [{
          required: true,
          trigger: 'blur',
          message: '请输入数量'
        }]
      }
    };
  },
  mounted: function mounted() {
    this.fetchData(this.actNo);
  },
  methods: {
    goBack: function goBack() {
      this.$emit('close');
    },
    restForm: function restForm(refname) {
      this.$refs[refname].resetFields();
    },
    fetchData: function fetchData(val) {
      var _this = this;
      this.listLoading = true;
      (0, _activityManage.prizeManage)(val).then(function (res) {
        res = res.data;
        if (res.status * 1 === 200) {
          _this.list = res.data;
        } else {
          _this.$message.closeAll();
          _this.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
        _this.listLoading = false;
      });
    },
    setId: function setId(val) {
      this.formInline.actConfigItemId = val;
      this.dialogVisible = true;
    },
    handleClose: function handleClose() {
      this.formInline.num = '';
      this.dialogVisible = false;
    },
    sub: function sub() {
      var _this2 = this;
      this.$refs['formInline'].validate(function (valid) {
        if (valid) {
          (0, _activityManage.actAddReserve)(_this2.formInline).then(function (res) {
            res = res.data;
            if (res.status * 1 === 200) {
              _this2.$message.closeAll();
              _this2.$message({
                message: '保存成功',
                type: 'success',
                duration: 3 * 1000
              });
              _this2.dialogVisible = false;
              _this2.restForm('formInline');
              _this2.fetchData(_this2.actNo);
            } else {
              _this2.$message.closeAll();
              _this2.$message({
                message: res.msg,
                type: 'error',
                duration: 3 * 1000
              });
            }
          });
        }
      });
    }
  }
};
exports.default = _default;