"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _basic = require("@/api/mark/basic");
var _client_licensing = require("@/api/mark/client/client_licensing");
var _waves = _interopRequireDefault(require("@/directive/waves"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'ComplexTable',
  directives: {
    waves: _waves.default
  },
  filters: {
    formatDate: function formatDate(time) {
      var date = new Date(time);
      return (0, _basic.formatDate)(date, 'yyyy-MM-dd hh:mm:ss');
    }
  },
  props: ['dialogTitle'],
  data: function data() {
    return {
      tableKey: 0,
      list: null,
      codeStatus: [{
        id: 0,
        name: '未激活'
      }, {
        id: 1,
        name: '已激活'
      }],
      parentData: {
        supplierName: '',
        supplierType: '',
        supplierCode: ''
      },
      listLoading: false,
      listQuery: {
        supplierCode: ''
      },
      dialogHistoryListVisible: false
    };
  },
  watch: {
    'parentData.supplierCode': function parentDataSupplierCode(newVal, oldVal) {
      // this.getHistoryList(newVal);
    }
  },
  created: function created() {},
  methods: {
    rest: function rest() {
      this.authId('searchbtn', this.$route.meta.authList);
      this.dialogHistoryListVisible = false;
    },
    getHistoryList: function getHistoryList() {
      var _this = this;
      // 获取指定列表内容
      if (this.parentData.supplierCode == '') return false;
      this.listLoading = true;
      this.listQuery.supplierCode = this.parentData.supplierCode;
      (0, _client_licensing.changedList)(this.listQuery).then(function (response) {
        var data = response.data;
        if (data.code != 200) {
          _this.$message.error(data.msg);
          return true;
        }
        _this.list = data.data;
        _this.listLoading = false;
      });
    },
    searchList: function searchList(data) {
      // 搜索事件
      this.listQuery = Object.assign(this.listQuery, data);
      this.getHistoryList();
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getHistoryList();
    },
    formatJson: function formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return (0, _utils.parseTime)(v[j]);
          } else {
            return v[j];
          }
        });
      });
    },
    handleClose: function handleClose(done) {
      // this.parentData.supplierCode='';
      done();
    }
  }
};
exports.default = _default;