"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.replace");
var _wxHy = require("@/api/wxhyyy/wxHy");
var _wXintegralMall = require("@/api/wxhyyy/wXintegralMall");
var _wxgzh = require("@/api/wxhyyy/wxgzh");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable no-undef */

// import admincut from '@/components/admincut'

var baseUrl = '/wh';
var _default = {
  // components: {
  //   admincut
  // },
  filters: {
    mathnum: function mathnum(val) {
      var a = val || 0;
      return Math.floor(a * 100) / 100;
    }
  },
  data: function data() {
    return {
      handleShow: false,
      tagss: [],
      // 标签
      dialogVisible: false,
      taggg: '',
      // 去除开头固定标签
      checkList: '',
      // 选中标签集合
      multipleSelection: '',
      // 打标签数据
      tagsList: {
        tagId: '',
        userTagQuery: []
      },
      condlist: [],
      tablehead: [],
      total: 0,
      xgtask: false,
      tableData1: [],
      imdiavip: false,
      infodia: false,
      // fstpUrl: baseUrl + '/api-memberscrm/member/admin/moWxMemberInfo/excel/importData',
      fstpUrl: '/api-memberscrm/member/admin/moWxMemberInfo/excel/importData',
      myHeaders: {
        Authorization: 'Bearer ' + (0, _auth.getToken)(),
        companyId: localStorage.getItem('cid')
      },
      levdata: [],
      vipload: false,
      tableData: [],
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 0);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '昨天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            end.setTime(end.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '近两天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      formInline: {
        selectName: '',
        memberValue: '',
        provinceStr: '',
        cityStr: '',
        startTime: '',
        endTime: '',
        pageNo: 1,
        pageSize: 10,
        orderByZd: '',
        orderByGz: ''
      },
      value: '',
      value2: '',
      selectlist: '',
      infopro: '',
      xglist: [],
      dqlist: [],
      props: {
        checkStrictly: true,
        label: 'lable',
        value: 'lable',
        children: 'child'
      },
      pocity: '',
      dialogjftz: false,
      ruleForm: {
        // integralType: '1',
        type: '',
        adjustmentValue: '',
        ids: [],
        desc: ''
      },
      rules: {
        // integralType: [{ required: true, message: '请选择积分账户', trigger: 'blur' }],
        type: [{
          required: true,
          message: '请选择积分操作类型',
          trigger: 'blur'
        }],
        adjustmentValue: [{
          required: true,
          message: '请输入积分数目',
          trigger: 'blur'
        }],
        desc: [{
          required: true,
          message: '请输入积分操作原因',
          trigger: 'blur'
        }]
      }
      // memberId: ''
    };
  },
  created: function created() {
    var _this = this;
    (0, _wxHy.searchSelect)().then(function (res) {
      // console.log(res)
      var res = res.data;
      if (res.code === 200) {
        _this.levdata = res.data || [];
        // console.log(this.levdata)
      } else {
        _this.$message(res.msg);
      }
    });
    // this.getAlltags()
    this.getdqlist();
    this.gethylist();
  },
  methods: {
    // 加减积分确定
    subIntegral: function subIntegral() {
      var _this2 = this;
      if (this.ruleForm.integralType === '1') {
        this.$refs['ruleForm'].validate(function (valid) {
          if (valid) {
            var integral = _this2.ruleForm.integralNum;
            _this2.ruleForm.pmType !== '1' ? integral = integral * -1 : integral;
            addOrdeleteIntegral({
              memberInfoid: _this2.memberId,
              integral: integral,
              description: _this2.ruleForm.description
            }).then(function (res) {
              if (res.code === 0) {
                _this2.restForm('ruleForm');
                _this2.dialogVisible = false;
                _this2.fetchData();
                _this2.$message({
                  message: res.msg,
                  type: 'success',
                  duration: 3 * 1000
                });
              }
            });
          }
        });
      } else {
        this.$refs['ruleForm'].validate(function (valid) {
          if (valid) {
            var integral = _this2.ruleForm.integralNum;
            _this2.ruleForm.pmType !== '1' ? integral = integral * -1 : integral;
            addOrdeleteIntegralmt({
              memberInfoid: _this2.memberId,
              integral: integral,
              description: _this2.ruleForm.description
            }).then(function (res) {
              if (res.code === 0) {
                _this2.restForm('ruleForm');
                _this2.fetchData();
                _this2.dialogVisible = false;
                _this2.$message({
                  message: res.msg,
                  type: 'success',
                  duration: 3 * 1000
                });
              }
            });
          }
        });
      }
    },
    cancel: function cancel() {
      this.restForm('ruleForm');
      this.dialogjftz = false;
      // this.memberId = ''
    },
    // 重置表单
    restForm: function restForm(refname) {
      this.formInline.startTime = '';
      this.formInline.endTime = '';
      this.ruleForm.desc = '';
      this.$refs[refname].resetFields();
    },
    xzsfcs: function xzsfcs(val) {
      console.log(val);
      if (val.length === 1) {
        this.formInline.provinceStr = val[0];
      } else if (val.length === 2) {
        this.formInline.cityStr = val[1];
      }
      console.log(this.formInline.province);
    },
    srchang: function srchang(val) {
      this.formInline.orderByZd = val.prop;
      if (val.order === 'descending') {
        this.formInline.orderByGz = 'desc';
      } else {
        this.formInline.orderByGz = 'asc';
      }
      this.gethylist(this.formInline);
    },
    numFun: function numFun(value) {
      value = value.replace(/[^0-9]/g, '');
      if (value > 0) {
        return value * 1;
      } else if (value <= 0 && value) {
        value = 0;
        return value;
      } else {
        value = '';
        return value;
      }
    },
    getdqlist: function getdqlist() {
      var _this3 = this;
      (0, _wXintegralMall.getDqlistsearch)().then(function (res) {
        // console.log(res)
        var res = res.data;
        if (res.code === 200) {
          if (res.data && res.data.length > 0) {
            _this3.dqlist = res.data;
            _this3.dqlist.forEach(function (v, i) {
              var c = [];
              v.child.forEach(function (j) {
                console.log(j);
                var b = {
                  lable: ''
                };
                b.lable = j;
                c.push(b);
                v.child = c;
              });
            });
          } else {
            _this3.dqlist = [];
          }
        }
      });
    },
    gethylist: function gethylist(val) {
      var _this4 = this;
      var a = val || '';
      this.vipload = true;
      (0, _wXintegralMall.getJflistsearch)(a).then(function (res) {
        // console.log(res)
        var res = res.data;
        if (res.code === 200) {
          // this.dqlist = res.data
          _this4.tableData = res.data.records;
          _this4.total = res.data.total;
          _this4.tableData.forEach(function (i) {
            i.flag = 1;
          });
          _this4.total = res.data.total;
          // console.log(this.tableData)
        } else {
          _this4.$message.info(res.msg);
        }
        _this4.vipload = false;
      });
    },
    handleClose: function handleClose(done) {
      done();
    },
    // 新建标签
    open: function open() {
      var _this5 = this;
      this.$prompt('标签名称', '新建标签', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function (_ref) {
        var value = _ref.value;
        if (_this5.setGround(value)) {
          _this5.$message({
            type: 'error',
            message: '重复的标签名'
          });
          return;
        }
        if (value.replace(/(^\s*)|(\s*$)/g, '') == '') {
          _this5.$message({
            type: 'error',
            message: '请输入有效的标签名'
          });
          return;
        }
        var n = {
          tagName: value,
          appId: sessionStorage.getItem('appId')
        };
        (0, _wxgzh.ccrrTag)(n).then(function (res) {
          // console.log(res)
          if (res.code * 1 === 200) {
            _this5.$message({
              type: 'success',
              message: '新建标签是: ' + value
            });
            _this5.getAlltags();
          } else {
            _this5.$message({
              type: 'error',
              message: res.msg
            });
          }
        });
      }).catch(function () {
        _this5.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    // 打标签
    opentagging: function opentagging() {
      if (this.multipleSelection.length == 0) {
        this.$message.warning('请先选择用户再进行操作');
        return;
      }
      this.dialogVisible = true;
      this.checkList = '';
      this.taggg = this.tagss.slice(2);
      // console.log(this.tagss)
    },
    // 提交打标签
    tagging: function tagging() {
      var _this6 = this;
      // console.log(this.checkList)
      if (this.checkList === '') {
        this.$message.warning('请选择一个标签');
      }
      this.tagsList.userTagQuery.appId = sessionStorage.getItem('appId');
      (0, _wxgzh.batchTagging)(this.tagsList.userTagQuery, this.checkList).then(function (res) {
        // console.log(res)
        if (res.code * 1 === 200) {
          _this6.$message.success('操作成功');
        } else {
          _this6.$message.warning('操作失败，请重试');
        }
        _this6.dialogVisible = false;
        _this6.userload = true;
        _this6.getserrch();
      });
    },
    // 删除标签
    handleClosetag: function handleClosetag(tag, list, row) {
      var _this7 = this;
      var a = {
        tagId: tag.tagId,
        userId: row.userId,
        appId: sessionStorage.getItem('appId')
      };

      // console.log(list)
      var i = list.indexOf(tag);
      list.splice(i, 1);
      (0, _wxgzh.batchUnTagging)(a).then(function (res) {
        if (res.code * 1 === 200) {
          _this7.$message.success('标签删除成功');
        } else {
          _this7.$message.warning(res.msg);
        }
      });
      // this.userload = true
      // this.getserrch()
    },
    // 获取用户列表
    getserrch: function getserrch() {
      // this.formInline.tagId = 0
      // this.formInline.endTime = ''
      // this.formInline.startTime = ''
      // this.formInline.nickname = ''
      // this.formInline.qrScene = ''
      // this.formInline.appId = sessionStorage.getItem('appId')
      // getSearchuser(this.formInline).then(res => {
      //    // console.log(res)
      //   if (res.code * 1 === 200) {
      //     this.total = res.data.total
      //     if (this.total * 1 === 0) {
      //       this.userload = false
      //       this.tableData = ''
      //     }
      //     this.tableData = res.data.records
      //   } else {
      //     this.$message.warning(res.msg)
      //   }
      //   this.userload = false
      // })
    },
    // 获取所有标签
    getAlltags: function getAlltags() {
      var _this8 = this;
      (0, _wxgzh.getAllTag)({
        appId: sessionStorage.getItem('appId')
      }).then(function (res) {
        // console.log(res)
        if (res.code * 1 === 200) {
          var _this8$tagss;
          _this8.tagss = [{
            tagName: '全部',
            tagId: 0
          }, {
            tagName: '无标签',
            tagId: 1
          }];
          (_this8$tagss = _this8.tagss).push.apply(_this8$tagss, (0, _toConsumableArray2.default)(res.data));
        } else {
          _this8.$message.warning(res.msg);
        }
      });
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.vipload = true;
      // console.log(`当前页: ${val}`)
      this.formInline.pageNo = val;
      // const a = {
      //   pageNo: this.formInline.pageNo,
      //   pageSize: this.formInline.pageSize
      // }
      this.gethylist(this.formInline);

      // getlistsearch(this.formInline).then(res => {
      //   // console.log(res)
      //   if (res.code === 200) {
      //     this.tableData = res.data.records
      //     this.total = res.data.total
      //     this.tableData.forEach((i) => {
      //       i.flag = 1
      //     })
      //     this.total = res.data.total
      //     // console.log(this.tableData)
      //   }
      //   this.vipload = false
      // })
    },
    // 冻结，解冻
    handleDelete: function handleDelete(id, sta) {
      var _this9 = this;
      var n = {
        id: id,
        status: sta
      };
      (0, _wxHy.freezeselectMemberById)(n).then(function (res) {
        // console.log(res)
        if (res.code === 200) {
          if (sta === '1') {
            _this9.$message.success('已冻结');
            _this9.getlistcreat();
          } else {
            _this9.$message.success('已解冻');
            _this9.getlistcreat();
          }
        }
      });
    },
    // 上传图片
    handleAvatarSuccess: function handleAvatarSuccess(response, file, fileList) {
      // let o = URL.createObjectURL(file);
      // this.Lists.push(fileList)
      // this.Lists.push({name:fileList.name,url:URL.createObjectURL(fileList.raw)})
      // console.log(response)
      if (response.code === 200) {
        this.$message.info(response.data);
        this.getlistcreat();
      } else {
        this.$message.warning(response.msg);
      }
    },
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      var isLt2M = file.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.closeAll();
        this.$message.error('上传图片大小不能超过 1MB!');
      }
      return isLt2M;
    },
    imgUploaderr: function imgUploaderr(err, file, fileList) {
      // if(file.code)
      this.$message.error(err.msg);
    },
    // 下载模板
    downmuban: function downmuban() {
      var that = this;
      (0, _wxHy.downtemplate)().then(function (res) {
        // console.log(res)
        var blob = new Blob([res], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '模板.xlsx';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
        that.downLoading = false;
      }).catch(function (res) {
        that.$message.warning(res.msg);
      });
    },
    // 列表详情
    handleinfo: function handleinfo(val, type) {
      // selectMemberById({ id: val }).then(res => {
      //    // console.log(res)
      //   if (res.code === 200) {
      //     this.infopro = res.data.moWxMemberInfoDO
      //     this.tableData1 = res.data.moWxTaskRecordDOList
      //     this.condlist = res.data.moWxMemberInfoConditionDOList
      //     this.tableData1.reverse()
      //   } else {
      //     this.$message(res.msg)
      //   }
      // })
      // this.infodia = true
      if (type === 1) {
        this.$router.push({
          name: 'wxAccountswxIntegralMallintegralFlowindex',
          query: {
            id: val
          }
        });
      } else {
        this.$router.push({
          name: 'wxAccountswxIntegralMallintegralOrderorder',
          query: {
            id: val
          }
        });
      }
      // console.log(val)
    },
    // 导入会员
    imvip: function imvip() {
      this.imdiavip = true;
      // console.log('1')
    },
    // 下载明细
    download: function download() {
      var that = this;
      (0, _wxHy.downdetail)().then(function (res) {
        // console.log(res)
        var blob = new Blob([res], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '明细.xlsx';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
        that.downLoading = false;
      }).catch(function (res) {
        that.$message.warning(res.msg);
      });
    },
    // 修改任务值
    onSubmitt: function onSubmitt() {
      if (this.selectlist.length === 0) {
        this.$message.warning('请选择一行数据进行修改');
        return;
      }
      // this.selectlist.forEach((i) => {
      //   i.flag = 2
      //   i.phone = i.phone + '.'
      // })
      // this.xgtask = true
      this.dialogjftz = true;
    },
    // 修改任务值提交
    tzjf: function tzjf() {
      var _this10 = this;
      // var n = []
      // this.selectlist.forEach(el => {
      //   const a = {
      //     memberId: el.memberId,
      //     syjf: el.syjf
      //   }
      //   n.push(a)
      // })
      // console.log(n)
      (0, _wXintegralMall.updateTaskByIds)(this.ruleForm).then(function (res) {
        var res = res.data;
        if (res.code === 200) {
          _this10.dialogjftz = false;
          _this10.$message.success('修改成功');
          _this10.$refs.multipleTable.clearSelection();
          // this.xgtask = false
          // this.gethylist()
          _this10.ruleForm = {
            type: '',
            adjustmentValue: '',
            ids: [],
            desc: ''
          };
          _this10.$router.go(0);
        } else {
          _this10.$message.warning(res.msg);
          return false;
        }
      });
    },
    qxbtn: function qxbtn() {
      var that = this;
      that.selectlist.forEach(function (i) {
        i.flag = 1;
        i.phone = i.phone.substring(0, i.phone.length - 1);
      });
      that.$refs.multipleTable.clearSelection();
      that.xgtask = false;
      that.gethylist();
    },
    // 重置
    onReset: function onReset() {
      this.formInline = {
        selectName: '',
        memberValue: '',
        provinceStr: '',
        cityStr: '',
        startTime: '',
        endTime: '',
        pageNo: 1,
        pageSize: 10
      };
      this.value2 = '';
      this.pocity = '';
    },
    onSubmit: function onSubmit(val) {
      var that = this;
      that.vipload = true;
      // if (val.selectName === '') {
      //   that.$message.warning('请选择会员选项')
      //   return
      // }
      // if (val.selectName === '1' && val.memberNum.replace(/(^\s*)|(\s*$)/g, '') === '') {
      //   that.$message.warning('请填写会员卡号')
      //   return
      // }
      // if (val.selectName === '2' && val.phone.replace(/(^\s*)|(\s*$)/g, '') === '') {
      //   that.$message.warning('请填写手机号')
      //   return
      // }
      // if (val.selectName === '3' && val.memberName.replace(/(^\s*)|(\s*$)/g, '') === '') {
      //   that.$message.warning('请填写会员姓名')
      //   return
      // }
      // if (val.ly.replace(/(^\s*)|(\s*$)/g, '') === '') {
      //   that.$message.warning('请填写会员来源')
      //   return
      // }
      // if (val.memberType === '') {
      //   that.$message.warning('请选择会员类型')
      //   return
      // }
      // if (val.type === '') {
      //   that.$message.warning('请选择会员等级')
      //   return
      // }
      // if (val.value2 === '') {
      //   that.$message.warning('请选择时间')
      //   return
      // }
      if (that.value2 !== '') {
        val.startTime = that.value2[0];
        val.endTime = that.value2[1];
      }
      // that.getlistcreat(val)
      that.gethylist(val);

      // getlistsearch(val).then(res => {
      //    // console.log(res)
      //   if (res.code === 200) {
      //     this.tableData = res.data
      //     that.vipload = false
      //   }
      // })
    },
    getlistcreat: function getlistcreat(val) {
      var _this11 = this;
      var a = val || '';
      this.vipload = true;
      (0, _wxHy.getlistsearch)(a).then(function (res) {
        // console.log(res)
        if (res.code === 200) {
          _this11.tableData = res.data.records;
          _this11.tablehead = [{
            label: '会员卡号',
            prop: 'memberCradNumber'
          }, {
            label: '会员卡号',
            prop: 'memberCradNumber'
          }, {
            label: '会员卡号',
            prop: 'memberCradNumber'
          }];
          _this11.total = res.data.total;
          _this11.tableData.forEach(function (i) {
            i.flag = 1;
          });
          _this11.total = res.data.total;
          // console.log(this.tableData)
        }

        _this11.vipload = false;
      });
    },
    // 表格选中操作
    // handleSelection(selection, row) {
    //    // console.log(selection)
    // },
    // 表格变化操作
    handleSelectionChange: function handleSelectionChange(val) {
      var _this12 = this;
      // console.log(val)
      this.ruleForm.ids = [];
      val.forEach(function (val) {
        _this12.ruleForm.ids.push(val.memberId);
      });
      this.selectlist = val;
      // this.multipleSelection = val
      // this.tagsList.userTagQuery = []
      // this.multipleSelection.forEach((val) => {
      //   const n = {
      //     openId: '',
      //     userId: ''
      //   }
      //   n.openId = val.openId
      //   n.userId = val.userId
      //   this.tagsList.userTagQuery.push(n)
      // })
      console.log(this.ruleForm.ids);
    }
  }
};
exports.default = _default;