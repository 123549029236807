"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _record = require("@/api/warehouse/record/record");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _productSelectDialog = _interopRequireDefault(require("@/components/productSelectDialog"));
var _utils = require("@/utils");
var _code_search = _interopRequireDefault(require("./components/code_search"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var miniUnit = '';
var _default = {
  name: 'table-suply',
  components: {
    Pagination: _Pagination.default,
    productDialog: _productSelectDialog.default,
    search: _code_search.default
  },
  filters: {
    TypeDataCom: function TypeDataCom(code, keyName) {
      var vars = TypeDataComs[keyName];
      var name = '';
      vars.forEach(function (c) {
        if (c.code == code) {
          name = c.name;
          return;
        }
      });
      return name;
    }
  },
  data: function data() {
    return {
      showCol: false,
      tableData: [],
      searchForm: {
        current: 1,
        size: 20
      },
      tableKey: 'rn',
      total: 0,
      codetotal: 0,
      listLoading: false,
      downLoading: false,
      page2: false,
      multipleSelection: [],
      tabHeight: '100%'
    };
  },
  activated: function activated() {
    this.getList();
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    searchList: function searchList(data) {
      this.searchForm = data;
      this.getList();
    },
    getList: function getList() {
      var _this = this;
      var that = this;
      that.listLoading = true;
      console.log('that.searchForm', that.searchForm);
      (0, _record.markReplaceLog)(that.searchForm).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.tableData = response.data.data.records;
            that.total = parseInt(response.data.data.total);
            setTimeout(function () {
              that.listLoading = false;
            }, 1 * 500);
          } else {
            that.tableData = [];
            that.total = 0;
          }
        }
      }).catch(function () {
        _this.listLoading = false;
      });
    }
  }
};
exports.default = _default;