var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c(
            "div",
            { staticClass: "page-section", attrs: { id: "seach-box" } },
            [
              _c(
                "el-form",
                {
                  ref: "searchform",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.listQuery,
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                    "label-suffix": ":",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cols" },
                    [
                      _c(
                        "el-row",
                        {
                          staticClass: "head-btn-group",
                          attrs: { type: "flex", gutter: 20 },
                        },
                        [
                          _c(
                            "el-col",
                            { staticStyle: { width: "460px" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { width: "450px" },
                                  attrs: { label: "数码查询", prop: "code" },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: "请输入",
                                      clearable: "",
                                      value: _vm.listQuery.code,
                                      maxlength: "40",
                                    },
                                    on: {
                                      input: function (e) {
                                        return (_vm.listQuery.code =
                                          _vm.validSe(e))
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { staticStyle: { "margin-left": "10px" } },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "label",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [_vm._v(" ")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.searchBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.searchBtn.icon,
                                      },
                                      on: { click: _vm.getList },
                                    },
                                    [_vm._v("查询")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c(
            "el-row",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: {
                "margin-left": "0",
                "margin-right": "0",
                "margin-bottom": "0",
                height: "calc(100vh - 280px)",
                "min-height": "500px",
              },
              attrs: { type: "flex", gutter: 10 },
            },
            [
              _c(
                "div",
                {
                  staticClass: "page-section infoWrap",
                  staticStyle: {
                    width: "420px",
                    overflow: "hidden",
                    "margin-bottom": "0",
                    height: "100%",
                    "overflow-y": "auto",
                  },
                },
                [
                  _c(
                    "el-collapse",
                    {
                      on: { change: _vm.handleChange },
                      model: {
                        value: _vm.activeNames,
                        callback: function ($$v) {
                          _vm.activeNames = $$v
                        },
                        expression: "activeNames",
                      },
                    },
                    [
                      _c(
                        "el-collapse-item",
                        { attrs: { title: "数码信息", name: "codeInfo" } },
                        [
                          _c("div", [
                            _c("div", { staticClass: "vue-from-block" }, [
                              _c("div", { staticClass: "form-div" }, [
                                _vm._v("数码"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "content" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.temp.markVo && _vm.temp.markVo.markCode
                                      ? _vm.temp.markVo.markCode
                                      : ""
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c("div", { staticClass: "vue-from-block" }, [
                              _c("div", { staticClass: "form-div" }, [
                                _vm._v("类型"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "content" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("codeType")(
                                      !_vm.temp.markVo
                                        ? ""
                                        : _vm.temp.markVo.markType
                                    )
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c("div", { staticClass: "vue-from-block" }, [
                              _c("div", { staticClass: "form-div" }, [
                                _vm._v("生产时间"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "content" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatTime")(
                                      !_vm.temp.markVo
                                        ? ""
                                        : _vm.temp.markVo.syncTime
                                    )
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c("div", { staticClass: "vue-from-block" }, [
                              _c("div", { staticClass: "form-div" }, [
                                _vm._v("状态"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "content",
                                  class:
                                    _vm.numColor[_vm.temp.markVo.markStatus],
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("numStatus")(
                                        !_vm.temp.markVo
                                          ? ""
                                          : _vm.temp.markVo.markStatus
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c("div", { staticClass: "vue-from-block" }, [
                              _c("div", { staticClass: "form-div" }, [
                                _vm._v("数码类型"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "content" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("levelType")(
                                      !_vm.temp.markVo
                                        ? ""
                                        : _vm.temp.markVo.packLevel,
                                      _vm.temp.markVo.markType
                                    )
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-collapse-item",
                        {
                          attrs: { title: "产品基本信息", name: "productInfo" },
                        },
                        [
                          _c("div", [
                            _c("div", { staticClass: "vue-from-block" }, [
                              _c("div", { staticClass: "form-div" }, [
                                _vm._v("产品名称"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "content" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.temp.productInfoVo &&
                                      _vm.temp.productInfoVo.productName
                                      ? _vm.temp.productInfoVo.productName
                                      : ""
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c("div", { staticClass: "vue-from-block" }, [
                              _c("div", { staticClass: "form-div" }, [
                                _vm._v("执行标准"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "content" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.temp.productInfoVo &&
                                      _vm.temp.productInfoVo.executiveStandard
                                      ? _vm.temp.productInfoVo.executiveStandard
                                      : ""
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c("div", { staticClass: "vue-from-block" }, [
                              _c("div", { staticClass: "form-div" }, [
                                _vm._v("产品图片"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "content" },
                                [
                                  _vm.temp.productInfoVo &&
                                  _vm.temp.productInfoVo.productImages
                                    ? _c(
                                        "el-link",
                                        {
                                          attrs: { type: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showImg(
                                                "产品图片",
                                                _vm.temp.productInfoVo
                                                  .productImages
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("查看")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c("div", { staticClass: "vue-from-block" }, [
                              _c("div", { staticClass: "form-div" }, [
                                _vm._v("食品生产许可证"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "content" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.temp.productInfoVo &&
                                      _vm.temp.productInfoVo.productionLicenseNo
                                      ? _vm.temp.productInfoVo
                                          .productionLicenseNo
                                      : ""
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c("div", { staticClass: "vue-from-block" }, [
                              _c("div", { staticClass: "form-div" }, [
                                _vm._v("贮存条件"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "content" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.temp.productInfoVo &&
                                      _vm.temp.productInfoVo.storageConditions
                                      ? _vm.temp.productInfoVo.storageConditions
                                      : ""
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-collapse-item",
                        { attrs: { title: "生产信息", name: "batchInfo" } },
                        [
                          _c("div", [
                            _c("div", { staticClass: "vue-from-block" }, [
                              _c("div", { staticClass: "form-div" }, [
                                _vm._v("生产批次"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "content" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.temp.productionInfoVo &&
                                      _vm.temp.productionInfoVo.batchNo
                                      ? _vm.temp.productionInfoVo.batchNo
                                      : ""
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c("div", { staticClass: "vue-from-block" }, [
                              _c("div", { staticClass: "form-div" }, [
                                _vm._v("产量"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "content" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.temp.productionInfoVo &&
                                      _vm.temp.productionInfoVo.realProductNum
                                      ? _vm.temp.productionInfoVo.realProductNum
                                      : ""
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c("div", { staticClass: "vue-from-block" }, [
                              _c("div", { staticClass: "form-div" }, [
                                _vm._v("生产日期"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "content" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatTime")(
                                      _vm.temp.productionInfoVo &&
                                        _vm.temp.productionInfoVo.productTime
                                    )
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c("div", { staticClass: "vue-from-block" }, [
                              _c("div", { staticClass: "form-div" }, [
                                _vm._v("所属工厂"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "content" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.temp.productionInfoVo &&
                                      _vm.temp.productionInfoVo.factoryName
                                      ? _vm.temp.productionInfoVo.factoryName
                                      : ""
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c("div", { staticClass: "vue-from-block" }, [
                              _c("div", { staticClass: "form-div" }, [
                                _vm._v("生产车间"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "content" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.temp.productionInfoVo &&
                                      _vm.temp.productionInfoVo.shopName
                                      ? _vm.temp.productionInfoVo.shopName
                                      : ""
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c("div", { staticClass: "vue-from-block" }, [
                              _c("div", { staticClass: "form-div" }, [
                                _vm._v("产线名称"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "content" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.temp.productionInfoVo &&
                                      _vm.temp.productionInfoVo.productLineName
                                      ? _vm.temp.productionInfoVo
                                          .productLineName
                                      : ""
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c("div", { staticClass: "vue-from-block" }, [
                              _c("div", { staticClass: "form-div" }, [
                                _vm._v("负责人"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "content" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.temp.productionInfoVo &&
                                      _vm.temp.productionInfoVo.director
                                      ? _vm.temp.productionInfoVo.director
                                      : ""
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c("div", { staticClass: "vue-from-block" }, [
                              _c("div", { staticClass: "form-div" }, [
                                _vm._v("质检组"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "content" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.temp.productionInfoVo &&
                                      _vm.temp.productionInfoVo.inspectorGroup
                                      ? _vm.temp.productionInfoVo.inspectorGroup
                                      : ""
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c("div", { staticClass: "vue-from-block" }, [
                              _c("div", { staticClass: "form-div" }, [
                                _vm._v("产地"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "content" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.temp.productionInfoVo &&
                                      _vm.temp.productionInfoVo.originPlace
                                      ? _vm.temp.productionInfoVo.originPlace
                                      : ""
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-collapse-item",
                        { attrs: { title: "厂家信息", name: "factoryInfo" } },
                        [
                          _c("div", [
                            _c("div", { staticClass: "vue-from-block" }, [
                              _c("div", { staticClass: "form-div" }, [
                                _vm._v("厂名"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "content" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.temp.productionInfoVo &&
                                      _vm.temp.productionInfoVo.factoryName
                                      ? _vm.temp.productionInfoVo.factoryName
                                      : ""
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c("div", { staticClass: "vue-from-block" }, [
                              _c("div", { staticClass: "form-div" }, [
                                _vm._v("厂址"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "content" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.temp.productionInfoVo &&
                                      _vm.temp.productionInfoVo.detailedAddress
                                      ? _vm.temp.productionInfoVo
                                          .detailedAddress
                                      : ""
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c("div", { staticClass: "vue-from-block" }, [
                              _c("div", { staticClass: "form-div" }, [
                                _vm._v("联系电话"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "content" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.temp.productionInfoVo &&
                                      _vm.temp.productionInfoVo.phone
                                      ? _vm.temp.productionInfoVo.phone
                                      : ""
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "page-section detailCollapse",
                  staticStyle: {
                    width: "calc(100% - 420px)",
                    overflow: "hidden",
                    "margin-bottom": "0",
                    height: "100%",
                  },
                },
                [
                  _c("div", { staticClass: "wlTitle" }, [_vm._v("物流信息")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        height: "calc(100% - 48px)",
                        "overflow-y": "auto",
                      },
                    },
                    [
                      _c(
                        "el-timeline",
                        {
                          staticStyle: {
                            "margin-top": "20px",
                            "margin-right": "10px",
                          },
                        },
                        _vm._l(
                          _vm.temp.markCodeBillVo,
                          function (activity, index) {
                            return _c(
                              "el-timeline-item",
                              {
                                key: index,
                                attrs: {
                                  placement: "top",
                                  timestamp:
                                    activity.topCode + " " + activity.topName,
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { attrs: { slot: "dot" }, slot: "dot" },
                                  [
                                    activity.billType > 200 ||
                                    (activity.billType > 4 &&
                                      activity.billType < 8)
                                      ? _c("img", {
                                          staticClass: "icon",
                                          attrs: {
                                            src: require("@/assets/images/fahuo.png"),
                                          },
                                        })
                                      : _c("img", {
                                          staticClass: "icon",
                                          attrs: {
                                            src: require("@/assets/images/shouhuo.png"),
                                          },
                                        }),
                                  ]
                                ),
                                _vm._v(" "),
                                activity.billType > 200 ||
                                (activity.billType > 4 && activity.billType < 8)
                                  ? _c(
                                      "el-row",
                                      { staticStyle: { "margin-top": "20px" } },
                                      [
                                        _c("el-col", [
                                          _c(
                                            "div",
                                            { staticClass: "vue-from-block" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "form-div" },
                                                [_vm._v("出库单号:")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "content" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      activity.billNo
                                                        ? activity.billNo
                                                        : ""
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("el-col", [
                                          _c(
                                            "div",
                                            { staticClass: "vue-from-block" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "form-div" },
                                                [_vm._v("出库时间:")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "content" },
                                                [
                                                  _vm._v(
                                                    _vm._s(activity.billDate)
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("el-col", [
                                          _c(
                                            "div",
                                            { staticClass: "vue-from-block" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "form-div" },
                                                [_vm._v("出库类型:")]
                                              ),
                                              _vm._v(" "),
                                              !activity.otherTypeName
                                                ? _c(
                                                    "div",
                                                    { staticClass: "content" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("billType")(
                                                            activity.billType
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _c(
                                                    "div",
                                                    { staticClass: "content" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          activity.otherTypeName
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        activity.receiveComCode
                                          ? _c("el-col", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "vue-from-block",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "form-div" },
                                                    [_vm._v("收货单位:")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    { staticClass: "content" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          activity.receiveComCode
                                                            ? "(" +
                                                                activity.receiveComCode +
                                                                ")"
                                                            : ""
                                                        ) +
                                                          _vm._s(
                                                            activity.receiveComName
                                                              ? activity.receiveComName
                                                              : ""
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("el-col", [
                                          _c(
                                            "div",
                                            { staticClass: "vue-from-block" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "form-div" },
                                                [_vm._v("收货地址:")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "content" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      activity.receiptAddress
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("el-col", [
                                          _c(
                                            "div",
                                            { staticClass: "vue-from-block" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "form-div" },
                                                [_vm._v("备注:")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "content" },
                                                [
                                                  _vm._v(
                                                    _vm._s(activity.remark)
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "el-row",
                                      { staticStyle: { "margin-top": "20px" } },
                                      [
                                        _c("el-col", [
                                          _c(
                                            "div",
                                            { staticClass: "vue-from-block" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "form-div" },
                                                [_vm._v("入库单号:")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "content" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      activity.billNo
                                                        ? activity.billNo
                                                        : ""
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("el-col", [
                                          _c(
                                            "div",
                                            { staticClass: "vue-from-block" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "form-div" },
                                                [_vm._v("入库时间:")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "content" },
                                                [
                                                  _vm._v(
                                                    _vm._s(activity.billDate)
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("el-col", [
                                          _c(
                                            "div",
                                            { staticClass: "vue-from-block" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "form-div" },
                                                [_vm._v("入库类型:")]
                                              ),
                                              _vm._v(" "),
                                              !activity.otherTypeName
                                                ? _c(
                                                    "div",
                                                    { staticClass: "content" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("billType")(
                                                            activity.billType
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _c(
                                                    "div",
                                                    { staticClass: "content" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          activity.otherTypeName
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        activity.sendComCode
                                          ? _c("el-col", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "vue-from-block",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "form-div" },
                                                    [_vm._v("发货单位:")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    { staticClass: "content" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          activity.sendComCode
                                                            ? "(" +
                                                                activity.sendComCode +
                                                                ")"
                                                            : ""
                                                        ) +
                                                          _vm._s(
                                                            activity.sendComName
                                                              ? activity.sendComName
                                                              : ""
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                              ],
                              1
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: _vm.title,
            visible: _vm.dialogVisible,
            "before-close": _vm.handleClose,
            width: "600px!important",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("el-image", {
            staticStyle: {
              width: "400px",
              height: "400px",
              display: "block",
              margin: "20px auto",
            },
            attrs: {
              fit: "cover",
              src: _vm.url,
              "preview-src-list": _vm.srcList,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }