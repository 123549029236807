var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.apis)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c(
                "el-form",
                {
                  ref: "listQuery",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.listQuery,
                    "status-icon": "",
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "roleName", label: "角色名称" } },
                            [
                              _c("el-input", {
                                staticClass: "filter-item",
                                staticStyle: { width: "100%" },
                                attrs: { clearable: "", placeholder: "请输入" },
                                model: {
                                  value: _vm.listQuery.roleName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.listQuery, "roleName", $$v)
                                  },
                                  expression: "listQuery.roleName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { prop: "clientCode", label: "所属系统" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择",
                                  },
                                  model: {
                                    value: _vm.listQuery.clientCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.listQuery, "clientCode", $$v)
                                    },
                                    expression: "listQuery.clientCode",
                                  },
                                },
                                _vm._l(_vm.sysOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.code,
                                    attrs: {
                                      label: item.name,
                                      value: item.code,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.formConfig.btnFollow
                        ? _c(
                            "el-col",
                            {
                              staticClass: "head-btn-group",
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { "label-width": "0" } },
                                [
                                  _c(
                                    "div",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [_vm._v(" ")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.searchBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.searchBtn.icon,
                                      },
                                      on: { click: _vm.DoSearch },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.btnConfig.searchBtn.text)
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.resetBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.resetBtn.icon,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.DoReset(_vm.listQuery)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.btnConfig.resetBtn.text)
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.formConfig.btnFollow
                ? _c("el-divider", { staticClass: "btnDivider" })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btnArea" },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "head-btn-group",
                      staticStyle: { width: "230px" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "head-btn-group" },
                        [
                          _vm.hasAuth("addbtn", _vm.apis)
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.addBtn.type,
                                    size: _vm.btnConfig.size,
                                    icon: _vm.btnConfig.addBtn.icon,
                                  },
                                  on: { click: _vm.DoAdd },
                                },
                                [_vm._v(_vm._s(_vm.btnConfig.addBtn.text))]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hasAuth("allstart", _vm.apis)
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.setBtn.type,
                                    size: _vm.btnConfig.size,
                                    icon: _vm.btnConfig.setBtn.icon,
                                  },
                                  on: { click: _vm.getStarts },
                                },
                                [_vm._v(_vm._s(_vm.btnConfig.setBtn.text))]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hasAuth("allstop", _vm.apis)
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.unSetBtn.type,
                                    size: _vm.btnConfig.size,
                                    icon: _vm.btnConfig.unSetBtn.icon,
                                  },
                                  on: { click: _vm.getStops },
                                },
                                [_vm._v(_vm._s(_vm.btnConfig.unSetBtn.text))]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  key: _vm.tableKey,
                  staticStyle: { width: "100%" },
                  attrs: {
                    height: _vm.tabHeight,
                    data: _vm.list,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    fit: "",
                    "highlight-current-row": "",
                  },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "selection",
                      align: _vm.tableConfig.align,
                      width: "80",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "角色名称",
                      prop: "roleName",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "角色描述",
                      prop: "remark",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "所属系统",
                      prop: "clientNames",
                      width: "200",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "用户数量",
                      prop: "userCount",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "状态",
                      prop: "isEnable",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              row.isEnable == 1
                                ? _c("el-tag", { attrs: { type: "success" } }, [
                                    _vm._v("启用"),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              row.isEnable == 0
                                ? _c("el-tag", { attrs: { type: "danger" } }, [
                                    _vm._v("禁用"),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1070255681
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "创建人",
                      prop: "creatorName",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "创建时间",
                      prop: "createTime",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm._f("formatTime")(row.createTime))
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2758846106
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "更新人",
                      prop: "updaterName",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "更新时间",
                      prop: "updateTime",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                      width: "120",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm._f("formatTime")(row.updateTime))
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1242874447
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      align: _vm.tableConfig.align,
                      width: "200",
                      fixed: "right",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm.hasAuth("detailbtn", _vm.apis)
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.DoScan(row)
                                        },
                                      },
                                    },
                                    [_vm._v("详情")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.hasAuth("updatebtn", _vm.apis)
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.DoEdit(row)
                                        },
                                      },
                                    },
                                    [_vm._v("修改")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "el-dropdown",
                                { staticClass: "table-dropdown" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "el-dropdown-link" },
                                    [
                                      _vm._v(
                                        "\n              更多\n              "
                                      ),
                                      _c("i", {
                                        staticClass:
                                          "el-icon-arrow-down el-icon--right",
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-dropdown-menu",
                                    {
                                      attrs: { slot: "dropdown" },
                                      slot: "dropdown",
                                    },
                                    [
                                      _c(
                                        "el-dropdown-item",
                                        [
                                          _vm.hasAuth("authassign", _vm.apis)
                                            ? _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.getRoleAuth(
                                                        row
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("分配权限")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-dropdown-item",
                                        [
                                          _vm.hasAuth("scanauth", _vm.apis)
                                            ? _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.scanRoleAuth(
                                                        row
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("查看权限")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-dropdown-item",
                                        [
                                          (
                                            row.isEnable == 0
                                              ? true
                                              : false &&
                                                _vm.hasAuth(
                                                  "startbtn",
                                                  _vm.apis
                                                )
                                          )
                                            ? _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "text",
                                                    size: "mini",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.DoStart(row)
                                                    },
                                                  },
                                                },
                                                [_vm._v("启用")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-dropdown-item",
                                        [
                                          (
                                            row.isEnable == 1
                                              ? true
                                              : false &&
                                                _vm.hasAuth("stopbtn", _vm.apis)
                                          )
                                            ? _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "text",
                                                    size: "mini",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.DoStop(row)
                                                    },
                                                  },
                                                },
                                                [_vm._v("禁用")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3658480322
                    ),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                  page: _vm.listQuery.pageNo,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageNo", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.apis)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c("add", { attrs: { add: _vm.addShow } }),
      _vm._v(" "),
      _c("edit", { attrs: { edit: _vm.editShow, "edit-data": _vm.editData } }),
      _vm._v(" "),
      _c("scan", { attrs: { scan: _vm.scanShow, "scan-data": _vm.scanData } }),
      _vm._v(" "),
      _c("auth", {
        attrs: { "dialog-visible": _vm.authShow, "auth-data": _vm.authData },
        on: { close: _vm.hideAuth },
      }),
      _vm._v(" "),
      _c("scanAuth", {
        attrs: {
          "scan-auth": _vm.scanAuthShow,
          "scan-auth-data": _vm.scanAuthData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }