"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _order = require("@/api/order");
var _companySelectDialog = _interopRequireDefault(require("@/components/companySelectDialog"));
var _productDialog = _interopRequireDefault(require("@/components/productDialog"));
var _dictionary = require("@/api/systems/dictionary");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var TypeDataComs = {
  orderStatus: []
};
var _default = {
  name: 'OrderCenterpurchaseCcconfig',
  components: {
    companySelectDialog: _companySelectDialog.default,
    productDialog: _productDialog.default
  },
  filters: {
    orderStatusText: function orderStatusText(value) {
      var name = '';
      TypeDataComs.orderStatus.forEach(function (c) {
        if (c.figure == value) {
          name = c.name;
          return;
        }
      });
      return name;
    },
    formatTime: function formatTime(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        return (0, _utils.parseTime)(value, '{y}-{m}-{d}');
      }
    },
    chnMoney: function chnMoney(n) {
      if (!/^(0|[1-9]\d*)(\.\d+)?$/.test(n) || n == 0.0) return '';
      var unit = '千百拾亿千百拾万千百拾元角分';
      var str = '';
      var p = n.indexOf('.');
      if (p >= 0) n = n.substring(0, p) + n.substr(p + 1, 2);
      unit = unit.substr(unit.length - n.length);
      for (var i = 0; i < n.length; i++) {
        str += '零壹贰叁肆伍陆柒捌玖'.charAt(n.charAt(i)) + unit.charAt(i);
      }
      return str.replace(/零(千|百|拾|角)/g, '零').replace(/(零)+/g, '零').replace(/零(万|亿|元)/g, '$1').replace(/(亿)万|壹(拾)/g, '$1$2').replace(/^元零?|零分/g, '').replace(/元$/g, '元整');
    }
  },
  data: function data() {
    return {
      active: 0,
      orderStatus: [],
      inUseName: '',
      companyShow: false,
      productShow: false,
      // 采购单位名称
      purchaseComName: '',
      // 收货单位名称
      receiveComName: '',
      // 销售单位名称
      sendOutComName: '',
      orgId: '',
      inOutType: '',
      receiveLoading: false,
      companycanChange: true,
      companyType: '',
      factoryType: '',
      isEnable: '',
      productList: [],
      loading: false,
      addData: {
        orderNo: '',
        orderProductAOS: [],
        orderSource: 1,
        purchaseOrgId: '',
        receiveAddress: '',
        receiveOrgId: '',
        receivePhone: '',
        receiveUser: '',
        remark: '',
        saleOrgId: '',
        tradeDate: (0, _utils.parseTime)(new Date(), '{y}-{m}-{d}'),
        tradeType: 1
      },
      rules: {
        orderNo: [{
          required: true,
          message: '采购订单号是必填项，请填写！',
          trigger: 'change'
        }],
        purchaseOrgId: [{
          required: true,
          message: '采购单位是必填项，请填写！',
          trigger: 'change'
        }],
        tradeDate: [{
          required: true,
          message: '请选择订单日期',
          trigger: 'change'
        }],
        receiveOrgId: [{
          required: true,
          message: '收货单位是必填项，请填写！',
          trigger: 'change'
        }],
        saleOrgId: [{
          required: true,
          message: '销售单位是必填项，请填写！',
          trigger: 'change'
        }]
      }
    };
  },
  computed: {
    totalMoney: {
      get: function get() {
        var totalSumAll = 0;
        this.productList.forEach(function (item) {
          if (!isNaN(item.totalPrice)) {
            totalSumAll += Number(item.totalPrice);
          }
        });
        totalSumAll = totalSumAll.toFixed(2);
        if (isNaN(totalSumAll)) {
          return 0;
        }
        return totalSumAll;
      },
      set: function set(val) {
        this.totalMoney = val;
      }
    }
  },
  mounted: function mounted() {
    var that = this;
    this.getDirSel('CCCGDDZT', 'orderStatus');
    that.DoAdd();
  },
  methods: {
    getDirSel: function getDirSel(code, list) {
      var _this = this;
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: code
      }).then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this[list] = res.data.data;
            TypeDataComs[list] = res.data.data;
          } else {
            _this[list] = [];
            TypeDataComs[list] = [];
          }
        }
      });
    },
    // 新增
    DoAdd: function DoAdd() {
      this.title = '采购订单';
      this.getNo();
      this.initialization();
    },
    getNo: function getNo() {
      var _this2 = this;
      (0, _order.getOrderNo)(1).then(function (res) {
        if (res.data.code == 200) {
          _this2.addData.orderNo = res.data.data;
        } else {
          _this2.$message.error('订单号获取失败，请重试！');
        }
      });
    },
    initialization: function initialization() {
      var _this3 = this;
      (0, _order.getCompanyById)({
        pId: JSON.parse(localStorage.getItem('currentUser')).orgId
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          var that = _this3;
          var datas = response.data.data;
          _this3.addData.purchaseOrgId = datas.pId || '';
          _this3.addData.receiveOrgId = datas.pId || '';
          _this3.purchaseComName = datas.channelName || '';
          _this3.receiveComName = datas.channelName || '';
          _this3.addData.receiveUser = datas.contacts || '';
          _this3.addData.receivePhone = datas.telephone || datas.phone || '';
          var provinceName = datas.provinceName || '';
          var cityName = datas.cityName || '';
          var countyName = datas.countyName || '';
          var detailedAddress = datas.detailedAddress || '';
          _this3.addData.receiveAddress = provinceName + cityName + countyName + detailedAddress;
        }
      });
    },
    /**
     * 单位选择
     * @param {*} name 需要回传修改的名字
     * @param {*} companyType 单位类型 0采购单位 1 收获单位 2发货单位(订单：销售单位) 3 销售单位
     * @param {*} inOutType 单据类型 1销售出库 2调拨出库 3其他出库 4生产入库 5调拨入库 6采购入库 7其他入库 11 采购订单 12销售订单
     *
     */
    openCompany: function openCompany(name, companyType, inOutType) {
      this.inUseName = name;
      this.companyType = companyType;
      this.inOutType = inOutType;
      this.companyShow = true;
    },
    setCompany: function setCompany(val) {
      this[this.inUseName] = val.companyName;
      switch (this.inUseName) {
        case 'purchaseComName':
          this.addData.purchaseOrgId = val.companyId;
          // //  0企业 1一级经销商 2终端,3工厂，22销售公司,33生产公司
          // switch (val.companyType * 1) {
          //   case 1:
          //     this.addData.purchaseComType = 3
          //     this.addData.receiveComType = 3
          //     break
          //   case 22:
          //     this.addData.purchaseComType = 2
          //     this.addData.receiveComType = 2
          //     break
          //   case 33:
          //     this.addData.purchaseComType = 1
          //     this.addData.receiveComType = 1
          //     break
          //   default:
          //     this.addData.purchaseComType = 2
          //     this.addData.receiveComType = 2
          // }
          this.addData.receiveOrgId = val.companyId;
          this.receiveComName = val.companyName;
          this.getReceiveInfo(val);
          break;
        case 'receiveComName':
          this.addData.receiveOrgId = val.companyId;

          // switch (val.companyType * 1) {
          //   case 1:
          //     this.addData.receiveComType = 3
          //     break
          //   case 22:
          //     this.addData.receiveComType = 2
          //     break
          //   case 33:
          //     this.addData.receiveComType = 1
          //     break
          //   default:
          //     this.addData.receiveComType = 2
          // }
          this.getReceiveInfo(val);
          break;
        case 'sendOutComName':
          this.addData.saleOrgId = val.companyId;
          // switch (val.companyType * 1) {
          //   case 1:
          //     this.addData.sendOutComType = 3
          //     break
          //   case 22:
          //     this.addData.sendOutComType = 2
          //     break
          //   case 33:
          //     this.addData.sendOutComType = 1
          //     break
          //   default:
          //     this.addData.sendOutComType = 1
          // }
          break;
      }
    },
    getReceiveInfo: function getReceiveInfo(val) {
      var _this4 = this;
      this.receiveLoading = true;
      (0, _order.getCompanyById)({
        pId: val.companyId
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          var that = _this4;
          var datas = response.data.data;
          _this4.addData.receiveUser = datas.contacts || '';
          _this4.addData.receivePhone = datas.telephone || datas.phone || '';
          var provinceName = datas.provinceName || '';
          var cityName = datas.cityName || '';
          var countyName = datas.countyName || '';
          var detailedAddress = datas.detailedAddress || '';
          _this4.addData.receiveAddress = provinceName + cityName + countyName + detailedAddress;
        }
        _this4.receiveLoading = false;
      }).catch(function () {
        _this4.receiveLoading = false;
      });
    },
    companyClose: function companyClose() {
      this.inUseName = '';
      this.companyType = '';
      this.orgId = '';
      this.isEnable = '';
      this.companycanChange = true;
      this.companyShow = false;
    },
    resetReceive: function resetReceive() {
      this.receiveComName = '';
      this.addData.receiveComId = '';
      this.addData.receiveContacts = '';
      this.addData.receivePhone = '';
      this.addData.receiveAddress = '';
    },
    next: function next() {
      var _this5 = this;
      this.$refs['addForm'].validate(function (valid) {
        if (valid) {
          _this5.active = _this5.active * 1 + 1;
        } else {
          _this5.$message.error('请完善信息！');
        }
      });
    },
    pro: function pro() {
      this.active = this.active * 1 - 1;
    },
    openPro: function openPro() {
      this.productShow = true;
    },
    setPro: function setPro(val) {
      var arr = JSON.parse(JSON.stringify(this.productList));
      if (val.length) {
        if (this.productList.length == 0) {
          var obj = {
            chestNum: '',
            bottleNum: '',
            totalBottleNum: 0
            // isUseCoupon: 0,
          };

          val.forEach(function (item) {
            item = Object.assign(item, obj);
          });
        } else {
          val.forEach(function (item) {
            item.chestNum = '';
            item.bottleNum = '';
            item.totalBottleNum = 0;
            // item.isUseCoupon = 0
            arr.forEach(function (el) {
              if (el.pId == item.pId) {
                item.chestNum = el.chestNum;
                item.bottleNum = el.bottleNum;
                item.totalBottleNum = el.totalBottleNum;
                // item.isUseCoupon = el.isUseCoupon
              }
            });
          });
        }

        this.productList = JSON.parse(JSON.stringify(val));
      } else {
        this.productList = [];
      }
    },
    proClose: function proClose() {
      this.productShow = false;
    },
    totalPrice: function totalPrice(row) {
      var value = row.packScaleExpression;
      var num = row.bottleNum * 1;
      num = num || 0;
      var prize = row.productPrice;
      if (!value || !num) {
        row.totalPrice = 0;
        return 0;
      } else {
        var total = num * (prize * 1);
        row.totalPrice = total.toFixed(2);
        return row.totalPrice;
      }
    },
    changeChestNum: function changeChestNum(row) {
      var value = row.packScaleExpression;
      var arr = value.split('*');
      var le = arr.length - 1;
      row.totalBottleNum = (row.chestNum || 0) * arr[le] + (row.bottleNum || 0);
      this.resetList();
    },
    resetList: function resetList() {
      this.productList = JSON.parse(JSON.stringify(this.productList));
    },
    // 删除产品明细列表
    DoSc: function DoSc(row) {
      var _this6 = this;
      var that = this;
      that.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this6.productList.splice(_this6.productList.indexOf(row), 1);
      });
    },
    // 置空字段
    resetVoid: function resetVoid() {
      this.addData = {
        orderNo: '',
        orderProductAOS: [],
        orderSource: 1,
        purchaseOrgId: '',
        receiveAddress: '',
        receiveOrgId: '',
        receivePhone: '',
        receiveUser: '',
        remark: '',
        saleOrgId: '',
        tradeDate: (0, _utils.parseTime)(new Date(), '{y}-{m}-{d}'),
        tradeType: 1
      };
      this.productList = [];
      this.purchaseComName = '';
      this.receiveComName = '';
      this.sendOutComName = '';
    },
    handleClose: function handleClose() {
      // this.active = 0
      this.resetVoid();
      if (this.$refs.addForm) this.$refs.addForm.resetFields();
      this.$router.replace({
        name: 'orderCenterpurchase-ccindex'
      });
    },
    submitForm: function submitForm() {
      var _this7 = this;
      var that = this;
      // that.addData.totalMoney = that.totalMoney
      if (!that.productList.length) {
        that.$message.error('请选择产品');
        return;
      }
      var plist = that.productList;
      var newList = [];
      if (plist.length > 0) {
        for (var i = 0; i < plist.length; i++) {
          if (!plist[i].totalBottleNum || plist[i].totalBottleNum == 0) {
            that.$message.error('在列表第' + (i + 1) + '行请输入采购数量');
            return;
          }
          var obj = {
            totalBottleNum: plist[i].totalBottleNum,
            bottleNum: plist[i].bottleNum,
            chestNum: plist[i].chestNum,
            orderNo: that.addData.orderNo,
            // packRaidoExpress: plist[i].packScaleExpression,
            // prodPrice: plist[i].productPrice,
            // productCode: plist[i].productCode,
            productId: plist[i].pId,
            // productName: plist[i].productName,
            // productSpec: plist[i].productModel,
            productType: 1
            // totalPrice: plist[i].totalPrice,
            // isUseCoupon: plist[i].isUseCoupon,
          };

          newList.push(obj);
        }
      }
      that.addData.orderProductAOS = newList;
      that.loading = true;
      that.dsate = true;
      var subData = that.addData;
      (0, _order.addPurchaseOrder)(subData).then(function (res) {
        if (res.data.code == 200) {
          _this7.active = 2;
        } else {
          that.$message.error(res.data.msg);
        }
        that.loading = false;
        that.dsate = false;
      }).catch(function () {
        that.loading = false;
        that.dsate = false;
      });
    },
    mixNumP: function mixNumP(row) {
      var arr = row.packScaleExpression.split('*');
      return arr[arr.length - 1] * 1 - 1;
    },
    onCopy: function onCopy(e) {
      this.$message.success('内容已复制到剪切板！');
    },
    onError: function onError(e) {
      this.$message.error('抱歉，复制失败！');
    }
  }
};
exports.default = _default;