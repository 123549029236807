"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _utils = require("@/utils");
var _query = require("@/api/fake_expel/query/query");
var _waves = _interopRequireDefault(require("@/directive/waves"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'fakeExpelqueryManagecodeindex';
var _default = {
  name: 'FakeExpelqueryManagecodeindex',
  directives: {
    waves: _waves.default
  },
  filters: {
    formatTime: function formatTime(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        return (0, _utils.parseTime)(value, '{y}-{m}-{d}');
      }
    },
    typeStatus: function typeStatus(status) {
      if (!String(status)) return '';
      var statusMap = {
        0: '禁用',
        1: '启用',
        2: '删除'
      };
      return statusMap[status];
    },
    numStatus: function numStatus(status) {
      if (!String(status)) return '';
      var statusMap = {
        0: '未激活',
        1: '正常',
        2: '冻结',
        3: '废除',
        10: '不存在'
      };
      return statusMap[status];
    },
    codeType: function codeType(status) {
      if (!String(status)) return '';
      var statusMap = {
        1: '营销码',
        2: '防伪码',
        3: '物流码'
      };
      return statusMap[status];
    },
    billType: function billType(status) {
      if (!String(status)) return '';
      var statusMap = {
        101: '【生产入库】',
        102: '【调货入库】',
        103: '【退货入库】',
        104: '【采购入库】',
        105: '【材料入库】',
        201: '【返工出库】',
        202: '【调货出库】',
        203: '【销售出库】',
        204: '【退货出库】',
        205: '【材料出库】'
      };
      return statusMap[status];
    },
    levelType: function levelType(packLevel, markType) {
      if (!packLevel || !markType) return '';
      var statusPack = {
        1: '盖',
        2: '瓶',
        3: '盒',
        4: '箱',
        5: '托',
        6: '组'
      };
      var statusMark = {
        1: '内',
        2: '',
        3: '外'
      };
      return statusPack[packLevel] + statusMark[markType];
    }
  },
  data: function data() {
    return {
      dataList: [],
      // 存放表格数据
      spanArr: [],
      // 存放整合数据
      position: 0,
      // 记录下标
      hasDetail: false,
      numColor: {
        0: 'wjh',
        1: 'zc',
        2: 'dj',
        3: 'fc'
      },
      numColor2: {
        0: 'fc',
        1: 'zc',
        2: ''
      },
      codeFrom: {
        warrantyPolicyItemVOS: []
      },
      chosedate: [],
      listQuery: {
        markCode: ''
      },
      hasDetail1: false,
      codeinfo: {},
      tableKey: 0,
      list: [],
      listLoading: false,
      btn_loading: false,
      loading: false,
      dialogFormVisible: false,
      token: '',
      temp: {
        // 数码信息
        markInfoVO: {},
        //  物流信息
        // 生产信息
        productionVo: {}
      },
      rules: {}
    };
  },
  activated: function activated() {
    var hasCache1 = localStorage.getItem('code');
    if (hasCache1) {
      this.listQuery.markCode = hasCache1;
      this.getList();
    }
  },
  deactivated: function deactivated() {
    var name = cachName;
    var cache = this.getCaches(this, name);
    if (!cache) {
      localStorage.removeItem('code');
      this.codeFrom = {
        warrantyPolicyItemVOS: []
      };
      this.list = [];
      this.dataList = [];
      this.temp = {
        markInfoVO: {},
        productionVo: {}
      };
    }
  },
  methods: {
    getList: function getList() {
      var _this = this;
      var that = this;
      var submitData = (0, _utils.filterKeyNull)(that.listQuery);
      if (false) {
        (0, _query.getcodedataall)({
          codedata: submitData.markCode
        }).then(function (response) {
          if (response.data.code != 200) {
            that.$message.error(response.data.msg);
            _this.hasDetail = false;
            _this.hasDetail1 = false;
            _this.loading = false;
            return;
          } else {
            if (response.data.data) {
              _this.codeinfo = response.data.data;
              _this.hasDetail1 = true;
              _this.hasDetail = false;
            }
            _this.loading = false;
          }
        });
      } else {
        (0, _query.queryMarkForPage)(submitData).then(function (res) {
          that.codeFrom = {
            warrantyPolicyItemVOS: []
          };
          that.list = [];
          if (res.data.code != 200) {
            that.$message.error(res.data.msg);
            _this.hasDetail = false;
            _this.hasDetail1 = false;
            _this.loading = false;
            return;
          } else {
            if (res.data.data && res.data.data.markInfoList && res.data.data.markInfoList.length > 0) {
              that.codeFrom = res.data.data;
              that.list = (0, _utils.transTreeData)(JSON.parse(JSON.stringify(res.data.data.markInfoList)), 'markCode', 'parentMarkCode', 'children');
              _this.dataList = res.data.data.unbindVOList || [];
              _this.rowspan();
              _this.temp = res.data.data;
              localStorage.setItem('code', submitData.markCode);
              _this.hasDetail = true;
              _this.hasDetail1 = false;
            }
            _this.loading = false;
          }
        }).catch(function (res) {
          that.loading = false;
        });
      }
    },
    reset: function reset() {
      this.listQuery.current = 1;
      this.listQuery.markType = '';
      this.getList();
    },
    rowspan: function rowspan() {
      var _this2 = this;
      // 每次调用清空数据
      this.spanArr = [];
      this.position = 0;
      // this.dataList存放表格数据数组
      this.dataList.forEach(function (item, index) {
        if (index === 0) {
          _this2.spanArr.push(1);
          _this2.position = 0;
        } else {
          if (
          // relationMarkCode 判断合并列的索引字段
          _this2.dataList[index].relationMarkCode == _this2.dataList[index - 1].relationMarkCode && _this2.dataList[index].optTime == _this2.dataList[index - 1].optTime) {
            _this2.spanArr[_this2.position] += 1;
            _this2.spanArr.push(0);
          } else {
            _this2.spanArr.push(1);
            _this2.position = index;
          }
        }
      });
    },
    objectSpanMethod: function objectSpanMethod(_ref) {
      var row = _ref.row,
        column = _ref.column,
        rowIndex = _ref.rowIndex,
        columnIndex = _ref.columnIndex;
      console.log(row, column, rowIndex, columnIndex);
      // row 需要合并的列索引
      // column 当前行的行数
      // rowIndex 当前列的数据
      // columnIndex 当前列的数据
      if (columnIndex == 0 || columnIndex == 3) {
        var _row = this.spanArr[rowIndex];
        var _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col
        };
      }
    } // resetData() {
    //   this.$refs.dataForm.resetFields()
    //   this.dialogFormVisible = false
    // }
    // detail(row) {
    //   this.dialogFormVisible = true
    //   this.loading = true
    //   queryMarkDetail(row.markCode)
    //     .then(res => {
    //       if (res.data.code == 200) {
    //         this.temp = Object.assign(this.temp, res.data.data)
    //       } else {
    //         this.$message.error(res.data.msg)
    //       }
    //       this.loading = false
    //     })
    //     .catch(() => {
    //       this.loading = false
    //     })
    // }
  }
};
exports.default = _default;