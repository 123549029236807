var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _c(
      "div",
      { staticClass: " page-section" },
      [
        _c(
          "el-form",
          {
            ref: "scanForm",
            staticClass: "search-condition",
            attrs: {
              model: _vm.scanData,
              rules: _vm.rules,
              "label-position": "right",
              "label-width": "150px",
            },
          },
          [
            _c(
              "el-row",
              { attrs: { gutter: 20, justify: "center", type: "flex" } },
              [
                _c(
                  "el-col",
                  { attrs: { xs: 24, sm: 20, md: 16, lg: 14, xl: 14 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "数码:", prop: "markCode" } },
                      [
                        _c("el-input", {
                          attrs: { value: _vm.scanData.markCode, type: "text" },
                          on: {
                            input: function (e) {
                              return (_vm.scanData.markCode = _vm.validSe(e))
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "产品:", prop: "productName" } },
                      [
                        _c(
                          "el-input",
                          {
                            attrs: { readonly: true },
                            model: {
                              value: _vm.scanData.productName,
                              callback: function ($$v) {
                                _vm.$set(_vm.scanData, "productName", $$v)
                              },
                              expression: "scanData.productName",
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: { slot: "append", icon: "el-icon-search" },
                              on: { click: _vm.openProduct },
                              slot: "append",
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "生产日期:", prop: "productTime" } },
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            type: "datetime",
                            placeholder: "选择日期",
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                          },
                          model: {
                            value: _vm.scanData.productTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.scanData, "productTime", $$v)
                            },
                            expression: "scanData.productTime",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "dialog-footer" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", loading: _vm.btnLoading },
                            on: {
                              click: function ($event) {
                                return _vm.submitForm(1)
                              },
                            },
                          },
                          [_vm._v("激活")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("product-dialog", { ref: "selProduct", on: { change: _vm.setSel } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }