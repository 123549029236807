"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.exportJxRkList = exportJxRkList;
exports.exportZdRkList = exportZdRkList;
exports.flowListExport = flowListExport;
exports.getHzList = getHzList;
exports.getJxRkList = getJxRkList;
exports.getJxsOrZdRkList = getJxsOrZdRkList;
exports.getQueryOrg = getQueryOrg;
exports.getSfchzList = getSfchzList;
exports.getType = getType;
exports.getZdRkList = getZdRkList;
var _request = _interopRequireDefault(require("@/utils/request"));
// 获取组织
function getQueryOrg() {
  var data = {
    userId: '',
    orgCodeAndName: '',
    orgStatus: 1
  };
  return (0, _request.default)({
    url: '/api-basic/orgInfo/orgInfoAll',
    method: 'post',
    data: data
  });
}

/**
* @description 获取经销商或终端入库流水列表
* @param {String} param.billClass Must - 1：入库 2：出库
* */

function getJxsOrZdRkList(param) {
  return (0, _request.default)({
    url: '/api-channel/stock/reportForm/flowList',
    method: 'get',
    params: param
  });
}

/**
 * @description 获取出入库汇总列表
 * @param {String} param.billClass Must - 1：入库 2：出库
 * @param {String} param.sumType Must - 1：按产品汇总，2：按收发货单位汇总
 * */

function getHzList(param) {
  return (0, _request.default)({
    url: '/api-channel/stock/reportForm/flowSumList',
    method: 'get',
    params: param
  });
}

// 获取收发存汇总列表
function getSfchzList(param) {
  return (0, _request.default)({
    url: '/api-channel/stock/reportForm/periodList',
    method: 'get',
    params: param
  });
}

// 获取经销商入库流水列表
function getJxRkList(data) {
  return (0, _request.default)({
    url: '/api-channel/orderinfo/inflow/jxlist',
    method: 'post',
    data: data
  });
}

// 获取终端入库流水列表
function getZdRkList(data) {
  return (0, _request.default)({
    url: '/api-channel/orderinfo/inflow/zdlist',
    method: 'post',
    data: data
  });
}

// 获取业务类型
function getType(data) {
  return (0, _request.default)({
    url: '/api-channel/jcBusinessType/list',
    method: 'POST',
    data: data
  });
}

// 经销商入库流水导出
function exportJxRkList(data) {
  return (0, _request.default)({
    url: '/api-channel/orderinfo/inflow/exportJXlist',
    method: 'post',
    responseType: 'blob',
    data: data
  });
}

// 终端入库流水导出
function exportZdRkList(data) {
  return (0, _request.default)({
    url: '/api-channel/orderinfo/inflow/exportZdlist',
    method: 'post',
    responseType: 'blob',
    data: data
  });
}

// 出入库列表流水导出
function flowListExport(params) {
  return (0, _request.default)({
    url: '/api-channel/stock/reportForm/flowListExport',
    method: 'get',
    responseType: 'blob',
    params: params
  });
}