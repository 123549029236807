"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _wxHy = require("@/api/wxhyyy/wxHy");
var _wxmake = require("@/api/wxhyyy/wxmake");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable vue/no-unused-components */

var baseUrl = '/wh';
var _default = {
  filters: {
    mathnum: function mathnum(val) {
      var a = val || 0;
      return Math.floor(a * 100) / 100;
    }
  },
  data: function data() {
    return {
      handleShow: false,
      restaurants: [],
      statem: '',
      condlist: [],
      tablehead: [],
      total: 0,
      xgtask: false,
      tableData1: [],
      imdiavip: false,
      infodia: false,
      // fstpUrl: baseUrl + '/api-memberscrm/member/admin/moWxMemberInfo/excel/importData',
      fstpUrl: '/api-memberscrm/member/admin/moWxMemberInfo/excel/importData',
      myHeaders: {
        Authorization: 'Bearer ' + (0, _auth.getToken)(),
        'companyId': localStorage.getItem('cid')
      },
      levdata: [],
      vipload: false,
      tableData: [
        // {
        //   memberCradNumber: '1',
        //   memberName: '1',
        //   ly: '1',
        //   phoneNumber: '1',
        //   sex: 1,
        //   type: 1,
        //   province: '2',
        //   city: '3',
        //   memberType: '33',
        //   taskValue: 234,
        //   status: 22,
        //   createTime: '2019'
        // }
      ],
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 0);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '昨天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            end.setTime(end.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '近两天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      formInline: {
        planId: '',
        nickName: '',
        mobile: '',
        moneyType: '',
        integralType: '',
        msgType: '',
        startTime: '',
        endTime: '',
        pageNo: 1,
        pageSize: 10
      },
      value: '',
      value2: [new Date(), new Date()],
      selectlist: '',
      infopro: '',
      xglist: []
    };
  },
  created: function created() {
    this.getselectMarket(this.formInline);
    // this.formInline = {
    //   planId: '',
    //   nickName: '',
    //   mobile: '',
    //   moneyType: '',
    //   integralType: '',
    //   msgType: '',
    //   startTime: '',
    //   endTime: '',
    //   pageNo: 1,
    //   pageSize: 10
    // }
  },

  methods: {
    inplm: function inplm(val) {
      console.log(val);
      console.log(this.formInline);
      this.formInline.planId = '';
    },
    // 获取渠道
    querySearchAsync: function querySearchAsync(queryString, cb) {
      var _this = this;
      (0, _wxmake.selectDataAll)().then(function (res) {
        var res = res.data;
        console.log(res);
        if (res.code === 200) {
          // this.levdata = res.data || []
          _this.restaurants = res.data || [];
          var restaurants = _this.restaurants;
          var list = [];
          restaurants.forEach(function (v) {
            var n = {
              value: v.planName,
              id: v.id
            };
            list.push(n);
          });
          restaurants = list;
          var results = queryString ? restaurants.filter(_this.createStateFilter(queryString)) : restaurants;
          cb(results);
          // clearTimeout(this.timeout);
          // this.timeout = setTimeout(() => {
          //   cb(results);
          // }, 2000);
        } else {
          _this.$message(res.msg);
        }
      });
    },
    createStateFilter: function createStateFilter(queryString) {
      return function (state) {
        return state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },
    handleSelect: function handleSelect(item) {
      this.formInline.planId = item.id;
    },
    cysj: function cysj(val) {
      this.formInline.startTime = val[0];
      this.formInline.endTime = val[1];
    },
    handleCurrentChange: function handleCurrentChange(val) {
      console.log("\u5F53\u524D\u9875: ".concat(val));
      this.formInline.pageNo = val;
      this.getselectMarket(this.formInline);
    },
    getselectMarket: function getselectMarket(val) {
      var _this2 = this;
      this.vipload = true;
      val.startTime = this.filterTime(this.value2[0]);
      val.endTime = this.filterTime(this.value2[1]);
      (0, _wxmake.selectMarketList)(val).then(function (res) {
        var res = res.data;
        if (res.code === 200) {
          _this2.tableData = res.data;
          _this2.total = res.data.total;
        }
        _this2.vipload = false;
      });
    },
    // 上传图片
    handleAvatarSuccess: function handleAvatarSuccess(response, file, fileList) {
      console.log(response);
      if (response.code === 200) {
        this.$message.info(response.data);
        this.getlistcreat();
      } else {
        this.$message.warning(response.msg);
      }
    },
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      var isLt2M = file.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.closeAll();
        this.$message.error('上传图片大小不能超过 1MB!');
      }
      return isLt2M;
    },
    imgUploaderr: function imgUploaderr(err, file, fileList) {
      // if(file.code)
      this.$message.error(err.msg);
    },
    // 列表详情
    handleinfo: function handleinfo(val) {
      var _this3 = this;
      (0, _wxHy.selectMemberById)({
        id: val
      }).then(function (res) {
        var res = res.data;
        console.log(res);
        if (res.code === 200) {
          _this3.infopro = res.data.moWxMemberInfoDO;
          _this3.tableData1 = res.data.moWxTaskRecordDOList;
          _this3.condlist = res.data.moWxMemberInfoConditionDOList;
          _this3.tableData1.reverse();
        } else {
          _this3.$message(res.msg);
        }
      });
      this.infodia = true;
      console.log(val);
    },
    // 导入会员
    filterTime: function filterTime(value) {
      var time = new Date(value);
      var y = time.getFullYear();
      var m = time.getMonth() + 1 > 9 ? time.getMonth() + 1 : '0' + (time.getMonth() + 1);
      var d = time.getDate() > 9 ? time.getDate() : '0' + time.getDate();
      var h = time.getHours();
      var i = time.getMinutes() > 9 ? time.getMinutes() : '0' + time.getMinutes();
      var s = time.getSeconds() > 9 ? time.getSeconds() : '0' + time.getSeconds();
      console.log(y + '-' + m + '-' + d);
      return y + '-' + m + '-' + d;
    },
    imvip: function imvip() {
      this.imdiavip = true;
      console.log('1');
    },
    // 下载明细
    download: function download(val) {
      var that = this;
      that.formInline.startTime = that.filterTime(that.value2[0]);
      that.formInline.endTime = that.filterTime(that.value2[1]);
      (0, _wxmake.selectMarketListExcel)(that.formInline).then(function (res) {
        var res = res.data;
        console.log(res);
        var blob = new Blob([res], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '参与记录.xlsx';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
        that.downLoading = false;
      }).catch(function (res) {
        that.$message.warning(res.msg);
      });
    },
    // 修改任务值
    onSubmitt: function onSubmitt() {
      if (this.selectlist.length === 0) {
        this.$message.warning('请选择一行数据进行修改');
        return;
      }
      this.selectlist.forEach(function (i) {
        i.flag = 2;
        i.createTime = i.createTime + '.';
      });
      console.log(this.selectlist);
      this.xgtask = true;
    },
    qxbtn: function qxbtn() {
      var that = this;
      that.selectlist.forEach(function (i) {
        i.flag = 1;
        i.createTime = i.createTime.substring(0, i.createTime.length - 1);
      });
      that.$refs.multipleTable.clearSelection();
      that.xgtask = false;
      that.getlistcreat();
    },
    // 重置
    onReset: function onReset() {
      this.formInline = {
        planId: '',
        nickName: '',
        mobile: '',
        moneyType: '',
        integralType: '',
        msgType: '',
        startTime: '',
        endTime: '',
        pageNo: 1,
        pageSize: 10
      };
      this.statem = '';
      this.value2 = [new Date(), new Date()];
    },
    onSubmit: function onSubmit(val) {
      var that = this;
      that.vipload = true;
      // if (val.selectName === '') {
      //   that.$message.warning('请选择会员选项')
      //   return
      // }
      // if (val.selectName === '1' && val.memberNum.replace(/(^\s*)|(\s*$)/g, '') === '') {
      //   that.$message.warning('请填写会员卡号')
      //   return
      // }
      // if (val.selectName === '2' && val.phone.replace(/(^\s*)|(\s*$)/g, '') === '') {
      //   that.$message.warning('请填写手机号')
      //   return
      // }
      // if (val.selectName === '3' && val.memberName.replace(/(^\s*)|(\s*$)/g, '') === '') {
      //   that.$message.warning('请填写会员姓名')
      //   return
      // }
      // if (val.ly.replace(/(^\s*)|(\s*$)/g, '') === '') {
      //   that.$message.warning('请填写会员来源')
      //   return
      // }
      // if (val.memberType === '') {
      //   that.$message.warning('请选择会员类型')
      //   return
      // }
      // if (val.type === '') {
      //   that.$message.warning('请选择会员等级')
      //   return
      // }
      // if (val.value2 === '') {
      //   that.$message.warning('请选择时间')
      //   return
      // }
      this.formInline.startTime = this.filterTime(this.value2[0]);
      this.formInline.endTime = this.filterTime(this.value2[1]);
      that.getselectMarket(this.formInline);
    },
    getlistcreat: function getlistcreat(val) {
      var _this4 = this;
      var a = val || '';
      this.vipload = true;
      this.formInline.pageNo = 1;
      (0, _wxHy.getlistsearch)(a).then(function (res) {
        var res = res.data;
        console.log(res);
        if (res.code === 200) {
          _this4.tableData = res.data.records;
          _this4.tablehead = [{
            label: '会员卡号',
            prop: 'memberCradNumber'
          }, {
            label: '会员卡号',
            prop: 'memberCradNumber'
          }, {
            label: '会员卡号',
            prop: 'memberCradNumber'
          }];
          _this4.total = res.data.total;
          _this4.tableData.forEach(function (i) {
            i.flag = 1;
          });
          _this4.total = res.data.total;
          console.log(_this4.tableData);
        }
        _this4.vipload = false;
      });
    },
    // 表格选中操作
    // handleSelection(selection, row) {
    //   console.log(selection)
    // },
    // 表格变化操作
    handleSelectionChange: function handleSelectionChange(val) {
      console.log(val);
      this.selectlist = val;
    },
    tableRowClassName: function tableRowClassName(_ref) {
      var row = _ref.row,
        rowIndex = _ref.rowIndex;
      if (rowIndex % 2 === 0) {
        return 'warning-row';
      }
    }
  }
};
exports.default = _default;