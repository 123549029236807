"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _dealer = require("@/api/basic/dealer");
var _org = require("@/api/basic/org");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import Pagination from '@/components/Pagination'
var _default2 = {
  name: 'Tips',
  // components: { Pagination },
  props: {
    aId: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    oldList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      radio: '',
      listLoading: true,
      visible: false,
      selLoading: false,
      total: 2,
      listQuery: {
        current: 1,
        size: 10,
        productIdList: this.aId,
        orgCode: null
      },
      list: [],
      listCopy: [],
      list1: [],
      list1Copy: this.oldList,
      upList: [],
      downList: [],
      multipleSelection: []
    };
  },
  methods: {
    setsel: function setsel() {
      var _this = this;
      this.selLoading = true;
      this.$nextTick(function () {
        _this.oldList.forEach(function (row) {
          _this.$refs.multipleTable.toggleRowSelection(row, true); // 回显
        });
      });

      this.fetchData();
      setTimeout(function () {
        _this.$refs.multipleTable.toggleRowSelection(_this.list[0]);
        _this.$refs.multipleTable.toggleRowSelection(_this.list[0]);
        _this.selLoading = false;
      }, 500);
    },
    getList: function getList(flag) {
      var that = this;
      that.listLoading = true;
      // dealerAreaList(submitData)
      (0, _dealer.newlist)(that.searchForm).then(function (res) {
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            that.tableData = res.data.data;
          } else {
            that.tableData = [];
          }
          if (flag == 111) {
            sessionStorage.setItem('areaList', JSON.stringify(res.data.data));
          }
          if (sessionStorage.getItem('areaList')) {
            that.normalList = JSON.parse(sessionStorage.getItem('areaList'));
          } else {
            that.getAreaAll();
          }
          that.listLoading = false;
        }
      }).catch(function (res) {
        that.listLoading = false;
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.radio = this.list.indexOf(val);
      this.multipleSelection = [val];
    },
    clear: function clear() {
      this.radio = '';
      this.multipleSelection = [];
      // this.$refs.multipleTable.clearSelection()
    },
    fetchData: function fetchData() {
      var _this2 = this;
      this.listLoading = true;
      (0, _org.orgInfoAll)({
        orgType: 2
      }).then(function (res) {
        res = res.data;
        console.log(res);
        if (res.code * 1 === 200) {
          // this.total = Number(res.data.total);
          _this2.list = res.data;
        } else {
          _this2.$message.closeAll();
          _this2.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
        _this2.listLoading = false;
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    handleClose: function handleClose() {
      this.list = [];
      this.visible = false;
      this.$emit('close');
    },
    update: function update() {
      this.$emit('fn', this.multipleSelection);
      this.visible = false;
    },
    tag1Close: function tag1Close(row) {
      this.$refs.multipleTable.toggleRowSelection(row, false);
    }
  }
};
exports.default = _default2;