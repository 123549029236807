var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c(
            "div",
            { staticClass: "detailWrap" },
            [
              _c(
                "div",
                { staticClass: "billTitle" },
                [
                  _c("img", { attrs: { src: require("@/images/topimg.png") } }),
                  _vm._v(" "),
                  _c("span", { staticClass: "billTitleText" }, [
                    _vm._v("订单 " + _vm._s(_vm.addData.orderNo)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "billType",
                      class: _vm._f("typeClass")(_vm.addData.tradeStatus),
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm._f("orderStatusText")(_vm.addData.tradeStatus)
                        )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "topRight" }, [
                    _c("img", {
                      attrs: { src: require("@/images/avatar.png") },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.addData.creatorUser))]),
                    _vm._v(" "),
                    _c("span", { staticStyle: { "margin-left": "10px" } }, [
                      _vm._v(
                        _vm._s(_vm._f("textDate")(_vm.addData.createTime))
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  !_vm.check
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "default", plain: "" },
                          on: {
                            click: function ($event) {
                              return _vm.$router.go(-1)
                            },
                          },
                        },
                        [_vm._v("返回")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "topWrap", attrs: { gutter: 42 } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "topItem" }, [
                      _c("div", { staticClass: "topLabel" }, [
                        _vm._v("订单日期:"),
                      ]),
                      _vm._v(" "),
                      !_vm.placeOrderDateEdit
                        ? _c("div", { staticClass: "topContent" }, [
                            _vm._v(
                              _vm._s(
                                _vm.addData.tradeDate
                                  ? _vm.addData.tradeDate.split(" ")[0]
                                  : " "
                              )
                            ),
                          ])
                        : _c(
                            "div",
                            { staticClass: "topContent" },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "date",
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "请选择日期",
                                  clearable: false,
                                },
                                on: {
                                  blur: function ($event) {
                                    _vm.placeOrderDateEdit = false
                                  },
                                },
                                model: {
                                  value: _vm.addData.tradeDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addData, "tradeDate", $$v)
                                  },
                                  expression: "addData.tradeDate",
                                },
                              }),
                            ],
                            1
                          ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "topItem" }, [
                      _c("div", { staticClass: "topLabel" }, [
                        _vm._v("订单类型:"),
                      ]),
                      _vm._v(" "),
                      !_vm.typeEdit
                        ? _c(
                            "div",
                            { staticClass: "topContent" },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm._f("orderType")(_vm.addData.orderType)
                                  ) +
                                  "\n              "
                              ),
                              _vm.check &&
                              _vm.addData.relationOrderNo &&
                              _vm.addData.orderSource != 5 &&
                              _vm.addData.orderType < 3
                                ? _c("el-button", {
                                    staticClass: "edit",
                                    attrs: {
                                      type: "text",
                                      icon: "el-icon-d-caret",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.typeEdit = true
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            { staticClass: "topContent" },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  on: {
                                    change: _vm.pTypeChange,
                                    "visible-change": _vm.orderTypeClose,
                                  },
                                  model: {
                                    value: _vm.addData.orderType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.addData, "orderType", $$v)
                                    },
                                    expression: "addData.orderType",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "买赠订单", value: 1 },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "酒券订单", value: 2 },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "topItem" }, [
                      _c("div", { staticClass: "topLabel" }, [_vm._v("备注:")]),
                      _vm._v(" "),
                      !_vm.remarkEdit
                        ? _c(
                            "div",
                            { staticClass: "topContent" },
                            [
                              _vm.addData.remark
                                ? _c(
                                    "el-popover",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placement: "bottom-start",
                                        width: "500",
                                        trigger: "hover",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "popoverremark" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.addData.remark
                                                ? _vm.addData.remark
                                                : " "
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "remark",
                                          attrs: { slot: "reference" },
                                          slot: "reference",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.addData.remark
                                                ? _vm.addData.remark
                                                : " "
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _c("div", { staticClass: "remark" }, [
                                    _vm._v(_vm._s(" ")),
                                  ]),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            { staticClass: "topContent" },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "100",
                                  placeholder: "请输入",
                                },
                                on: {
                                  blur: function ($event) {
                                    _vm.remarkEdit = false
                                  },
                                },
                                model: {
                                  value: _vm.addData.remark,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addData, "remark", $$v)
                                  },
                                  expression: "addData.remark",
                                },
                              }),
                            ],
                            1
                          ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-card",
            { staticClass: "box-card", attrs: { shadow: "hover" } },
            [
              _c(
                "div",
                {
                  staticClass: "clearfix detailCardTitle",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c("img", { attrs: { src: require("@/images/ddxg.png") } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("订单相关方信息")]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "detailRow", attrs: { gutter: 42 } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("销售单位:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.saleOrgName) +
                            "\n              "
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("发货单位:"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "content" },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.sendOrgName ? _vm.sendOrgName : " ") +
                              "\n              "
                          ),
                          _vm.check && _vm.addData.relationOrderNo
                            ? _c("el-button", {
                                staticClass: "edit",
                                attrs: {
                                  type: "text",
                                  icon: "el-icon-edit-outline",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openCompany("sendOrgName", 2, 12)
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("采购单位:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.purchaseOrgName) +
                            "\n              "
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("收货单位:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.receiveOrgName)),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "detailRow", attrs: { gutter: 42 } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("收货联系人:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.addData.receiveUser)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("收货联系电话:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.addData.receivePhone)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("收货地址:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.addData.receiveAddress)),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-card",
            {
              staticClass: "box-card",
              staticStyle: { "margin-top": "12px" },
              attrs: { shadow: "hover", "body-style": _vm.cardPadding },
            },
            [
              _c(
                "div",
                {
                  staticClass: "clearfix detailCardTitle titleBtn",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c("img", { attrs: { src: require("@/images/cpxx.png") } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("产品信息")]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  ref: "addTable",
                  staticStyle: { width: "100%", margin: "-1px 0 0" },
                  attrs: {
                    "max-height": "600",
                    data: _vm.productList,
                    fit: "",
                    "row-class-name": "iconNone",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "序号",
                      type: "index",
                      align: "center",
                      width: "80",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品编码",
                      align: "center",
                      prop: "productCode",
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品名称",
                      align: "center",
                      prop: "productName",
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "规格",
                      align: "center",
                      prop: "productModel",
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "包装比例",
                      align: "center",
                      prop: "packScaleExpression",
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "箱数",
                      prop: "chestNum",
                      align: "center",
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "盒数",
                      prop: "bottleNum",
                      align: "center",
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "总盒数",
                      prop: "totalBottleNum",
                      align: "center",
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _vm.addData.orderType == 1 && _vm.addData.saleOrgType != 1
                    ? _c("el-table-column", {
                        attrs: {
                          label: "买赠方案",
                          align: "center",
                          prop: "rebatePolicyId",
                          "min-width": "140",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  (scope.row.giftProductAOS &&
                                    scope.row.giftProductAOS.length > 0) ||
                                  scope.row.activityName
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.toogleExpand(
                                                scope.$index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              scope.row.activityName
                                                ? scope.row.activityName
                                                : "查看赠品"
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm.check && _vm.addData.relationOrderNo
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.openMz(scope.$index)
                                            },
                                          },
                                        },
                                        [_vm._v("选择方案")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.check &&
                                  _vm.addData.relationOrderNo &&
                                  !(
                                    scope.row.activityId == "" ||
                                    scope.row.activityId == null
                                  )
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.clearZp(scope.$index)
                                            },
                                          },
                                        },
                                        [_vm._v("取消方案")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.check &&
                                  _vm.addData.relationOrderNo &&
                                  (scope.row.activityId == "" ||
                                    scope.row.activityId == null)
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.openPro(
                                                3,
                                                scope.$index
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("添加赠品")]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4081003949
                        ),
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { type: "expand", width: "1" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (props) {
                          return [
                            _c(
                              "el-table",
                              {
                                staticStyle: {
                                  width: "100%",
                                  "margin-top": "1px",
                                },
                                attrs: {
                                  size: "mini",
                                  data: props.row.giftProductAOS,
                                  fit: "",
                                  "highlight-current-row": "",
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    label: "赠品行",
                                    type: "index",
                                    align: "center",
                                    width: "80",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "zpindex" },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      "赠品" +
                                                        (scope.$index * 1 + 1)
                                                    ) +
                                                    "\n                    "
                                                ),
                                                _c("img", {
                                                  staticClass: "zpimg",
                                                  attrs: {
                                                    src: require("@/images/zpimg.png"),
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    "min-width": "140",
                                    label: "赠品编码",
                                    prop: "productCode",
                                    align: "center",
                                    "show-overflow-tooltip": "",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    "min-width": "140",
                                    label: "赠品名称",
                                    prop: "productName",
                                    align: "center",
                                    "show-overflow-tooltip": "",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "规格",
                                    align: "center",
                                    prop: "productModel",
                                    "min-width": "140",
                                    "show-overflow-tooltip": "",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "包装比例",
                                    align: "center",
                                    prop: "packScaleExpression",
                                    "min-width": "140",
                                    "show-overflow-tooltip": "",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "箱数",
                                    align: "center",
                                    "min-width": "140",
                                    "show-overflow-tooltip": "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          return [
                                            props.row.activityId ||
                                            !_vm.addData.relationOrderNo
                                              ? _c("span", [
                                                  _vm._v(_vm._s(row.chestNum)),
                                                ])
                                              : _c("el-input-number", {
                                                  staticStyle: {
                                                    width: "110px",
                                                  },
                                                  attrs: {
                                                    controls: false,
                                                    precision: 0,
                                                    min: 0,
                                                    max: 99999999,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.changeChestNum(
                                                        row
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: row.chestNum,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        row,
                                                        "chestNum",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "row.chestNum",
                                                  },
                                                }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "盒数",
                                    align: "center",
                                    "min-width": "140",
                                    "show-overflow-tooltip": "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var row = ref.row
                                          return [
                                            props.row.activityId ||
                                            !_vm.addData.relationOrderNo
                                              ? _c("span", [
                                                  _vm._v(_vm._s(row.bottleNum)),
                                                ])
                                              : _c("el-input-number", {
                                                  staticStyle: {
                                                    width: "110px",
                                                  },
                                                  attrs: {
                                                    controls: false,
                                                    precision: 0,
                                                    min: 0,
                                                    max: _vm.mixNumP(row),
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.changeChestNum(
                                                        row
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: row.bottleNum,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        row,
                                                        "bottleNum",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "row.bottleNum",
                                                  },
                                                }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "总盒数",
                                    prop: "totalBottleNum",
                                    align: "center",
                                    "min-width": "140",
                                    "show-overflow-tooltip": "",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "el-table-column",
                                  {
                                    attrs: {
                                      align: "center",
                                      prop: "rebatePolicyId",
                                      "min-width": "140",
                                      "show-overflow-tooltip": "",
                                    },
                                  },
                                  [
                                    _c("template", { slot: "label" }, [
                                      _vm._v(" "),
                                    ]),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _vm.check && _vm.addData.relationOrderNo
                                  ? _c("el-table-column", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !props.row.activityId,
                                          expression: "!props.row.activityId",
                                        },
                                      ],
                                      attrs: {
                                        label: "操作",
                                        align: "center",
                                        width: "80",
                                        "show-overflow-tooltip": "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var row = ref.row
                                              return [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.DelZP(
                                                          row,
                                                          props.$index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("删除")]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.addData.orderType == 2 && _vm.addData.saleOrgType != 1
            ? _c(
                "el-card",
                {
                  staticClass: "box-card",
                  staticStyle: { "margin-top": "12px" },
                  attrs: { shadow: "hover", "body-style": _vm.cardPadding },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix detailCardTitle",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c("img", {
                        attrs: { src: require("@/images/jjdh.png") },
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v("酒券兑换信息")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "countWrap" }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "min-width": "250px",
                              "text-align": "left",
                            },
                          },
                          [
                            _vm._v(
                              "\n            可使用酒券金额(元)：\n            " +
                                _vm._s(_vm.totalZp) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "本次使用酒券金额(元)：" + _vm._s(_vm.totalInUse)
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.check && _vm.addData.relationOrderNo
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                size: "mini",
                                icon: "el-icon-refresh",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.openPro(2)
                                },
                              },
                            },
                            [_vm._v("兑换产品")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%", margin: "-1px 0 0" },
                      attrs: { "max-height": "400", data: _vm.zpList, fit: "" },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "序号",
                          type: "index",
                          align: "center",
                          width: "65",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "产品编码",
                          align: "center",
                          prop: "productCode",
                          "min-width": "140",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "产品名称",
                          align: "center",
                          prop: "productName",
                          "min-width": "140",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "规格",
                          align: "center",
                          prop: "productModel",
                          "min-width": "140",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "包装比例",
                          align: "center",
                          prop: "packScaleExpression",
                          "min-width": "140",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "箱数",
                          align: "center",
                          "min-width": "140",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm.check && _vm.addData.relationOrderNo
                                    ? _c("el-input-number", {
                                        staticStyle: { width: "110px" },
                                        attrs: {
                                          controls: false,
                                          precision: 0,
                                          min: 0,
                                          max: 99999999,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.changeChestNum(row)
                                          },
                                        },
                                        model: {
                                          value: row.chestNum,
                                          callback: function ($$v) {
                                            _vm.$set(row, "chestNum", $$v)
                                          },
                                          expression: "row.chestNum",
                                        },
                                      })
                                    : _c("span", [
                                        _vm._v(_vm._s(row.chestNum)),
                                      ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1013883285
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "盒数",
                          align: "center",
                          "min-width": "140",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm.check && _vm.addData.relationOrderNo
                                    ? _c("el-input-number", {
                                        staticStyle: { width: "110px" },
                                        attrs: {
                                          controls: false,
                                          precision: 0,
                                          min: 0,
                                          max: _vm.mixNumP(row),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.changeChestNum(row)
                                          },
                                        },
                                        model: {
                                          value: row.bottleNum,
                                          callback: function ($$v) {
                                            _vm.$set(row, "bottleNum", $$v)
                                          },
                                          expression: "row.bottleNum",
                                        },
                                      })
                                    : _c("span", [
                                        _vm._v(_vm._s(row.bottleNum)),
                                      ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1633319812
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "总盒数",
                          prop: "totalBottleNum",
                          align: "center",
                          "min-width": "140",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "酒券金额",
                          align: "center",
                          prop: "wineTotalPrice",
                          "min-width": "140",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [_vm._v(_vm._s(_vm.totalWine(row)))]
                              },
                            },
                          ],
                          null,
                          false,
                          1315556519
                        ),
                      }),
                      _vm._v(" "),
                      _vm.check && _vm.addData.relationOrderNo
                        ? _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              align: "center",
                              "min-width": "140",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.DoScZp(scope.row)
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3604119748
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "products margin-top-20" }, [
            _c(
              "div",
              { staticClass: "detail-container" },
              [
                _vm._m(0),
                _vm._v(" "),
                _vm.workId
                  ? _c("workFlow", { attrs: { "p-id": _vm.workId } })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.check
            ? _c("div", { staticClass: "products margin-top-20" }, [
                _c(
                  "div",
                  { staticClass: "detail-container" },
                  [
                    _vm._m(1),
                    _vm._v(" "),
                    _vm.workId && _vm.taskId
                      ? _c("workVerify", {
                          ref: "workVerify",
                          attrs: {
                            "p-id": _vm.workId,
                            "task-id": _vm.taskId,
                            usenormal: false,
                          },
                          on: {
                            changeLoading: function ($event) {
                              _vm.loading = false
                            },
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "align-right",
                        staticStyle: {
                          padding: "10px 20px 20px",
                          "text-align": "right",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              disabled: _vm.loading,
                              loading: _vm.loading,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.audit(1)
                              },
                            },
                          },
                          [_vm._v("通过")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              disabled: _vm.loading,
                              loading: _vm.loading,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.audit(2)
                              },
                            },
                          },
                          [_vm._v("驳回")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "default", plain: "" },
                            on: {
                              click: function ($event) {
                                return _vm.$router.go(-1)
                              },
                            },
                          },
                          [_vm._v("返回")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.companyShow
        ? _c("companySelectDialog", {
            attrs: {
              visible: _vm.companyShow,
              "in-out-type": _vm.inOutType,
              "company-type": _vm.companyType,
            },
            on: { change: _vm.setCompany, close: _vm.companyClose },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.productShow
        ? _c("product-dialog", {
            ref: "selProduct",
            attrs: {
              "is-code": _vm.proType == 3 ? "" : 1,
              "pro-sel-visible": _vm.productShow,
              data: _vm.proSelectList,
            },
            on: {
              "update:data": function ($event) {
                _vm.proSelectList = $event
              },
              change: _vm.setPro,
              close: _vm.proClose,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.tgShow
        ? _c("tgSelectDialog", {
            attrs: { visible: _vm.tgShow },
            on: { change: _vm.setTg, close: _vm.tgClose },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.mzShow
        ? _c("mz-dialog", {
            ref: "selMz",
            attrs: {
              visible: _vm.mzShow,
              data: _vm.productList[_vm.selProIdx],
              "buyer-id": _vm.addData.purchaseOrgId,
            },
            on: {
              "update:data": function ($event) {
                return _vm.$set(_vm.productList, _vm.selProIdx, $event)
              },
              change: _vm.setMz,
              close: _vm.mzClose,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title border-bottom" }, [
      _c("img", { attrs: { src: require("@/images/icon-10.png") } }),
      _vm._v(" "),
      _c("span", {}, [_vm._v("审核流程")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title border-bottom" }, [
      _c("img", { attrs: { src: require("@/images/xxnormal.png") } }),
      _vm._v(" "),
      _c("span", {}, [_vm._v("销售订单预审")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }