"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.changeStates = changeStates;
exports.deleteApi = deleteApi;
exports.downLoadFile = downLoadFile;
exports.getAllMoudles = getAllMoudles;
exports.getAllNodes = getAllNodes;
exports.getApiList = getApiList;
exports.getCheckedExecutionInfoList = getCheckedExecutionInfoList;
exports.getDetails = getDetails;
exports.getExecutionInfoList = getExecutionInfoList;
exports.getModelId = getModelId;
exports.getMoudleMsg = getMoudleMsg;
exports.getPageMsg = getPageMsg;
exports.getSearchList = getSearchList;
exports.getSplcDetail = getSplcDetail;
exports.getWDetail = getWDetail;
exports.getWaitInfoList = getWaitInfoList;
exports.getWorkFlu = getWorkFlu;
exports.getYwDetail = getYwDetail;
exports.insertApi = insertApi;
exports.insertData = insertData;
exports.onPass = onPass;
exports.refuesePass = refuesePass;
exports.saveNodeConfig = saveNodeConfig;
exports.upLoadFile = upLoadFile;
exports.update = update;
exports.updateApi = updateApi;
var _request = _interopRequireDefault(require("@/utils/request"));
//获取审批配置列表
function getWorkFlu(param) {
  return (0, _request.default)({
    url: '/api-workflow/checkModel/getCheckModelList',
    method: 'get',
    params: param
  });
}

//获取模块信息
function getMoudleMsg() {
  return (0, _request.default)({
    url: '/api-workflow/checkModule/getCheckModule',
    method: 'get'
  });
}

//获取模板信息
function getAllMoudles() {
  return (0, _request.default)({
    url: '/api-workflow/checkModel/getAllTemplate',
    method: 'post'
  });
}

//获取所有配置节点信息
function getAllNodes(param) {
  return (0, _request.default)({
    url: '/api-workflow/checkModel/getCheckModelNodeList',
    method: 'get',
    params: param
  });
}

//保存配置节点
function saveNodeConfig(data) {
  return (0, _request.default)({
    url: '/api-workflow/checkModel/updateNodes',
    method: 'post',
    data: data
  });
}

//获取单据信息
function getPageMsg(param) {
  return (0, _request.default)({
    url: '/api-user/dataDictionaryItem/getItemByDataDictionaryByCode',
    method: 'get',
    params: param
  });
}

//启用,禁用
function changeStates(param) {
  return (0, _request.default)({
    url: '/api-workflow/checkModel/enable',
    method: 'put',
    params: param
  });
}

//获取详情
function getDetails(param) {
  return (0, _request.default)({
    url: '/api-workflow/checkModel/getModelDetail',
    method: 'get',
    params: param
  });
}

//修改
function update(data) {
  return (0, _request.default)({
    url: '/api-workflow/checkModel/update',
    method: 'put',
    data: data
  });
}

//新增
function insertData(data) {
  return (0, _request.default)({
    url: '/api-workflow/checkModel/add',
    method: 'post',
    data: data
  });
}

//下载
function downLoadFile(param) {
  return (0, _request.default)({
    url: '/api-workflow/checkModel/download',
    method: 'post',
    responseType: 'blob',
    params: param
  });
}

//上传
function upLoadFile(pId, data) {
  return (0, _request.default)({
    url: '/api-workflow/checkModel/uploadFile?pId=' + pId,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    method: 'post',
    data: data
  });
}

//我发起的列表
function getExecutionInfoList(param) {
  return (0, _request.default)({
    url: '/api-workflow/checkExecutionInfo/getCheckMyExecutionInfoList',
    method: 'get',
    params: param
  });
}

//我发起的详情
function getWDetail(param) {
  return (0, _request.default)({
    url: '/api-workflow/checkExecutionInfo/getCheckDetil',
    method: 'get',
    params: param
  });
}

//审批流程详情
function getSplcDetail(param) {
  return (0, _request.default)({
    url: '/api-workflow/checkExecutionInfo/getCheckDetilLc',
    method: 'get',
    params: param
  });
}

//审批完成
function getCheckedExecutionInfoList(param) {
  return (0, _request.default)({
    url: '/api-workflow/checkExecutionInfo/getCheckedExecutionInfoList',
    method: 'get',
    params: param
  });
}

//审批查询单据
function getSearchList(param) {
  return (0, _request.default)({
    url: '/api-workflow/checkExecutionInfo/getCheckDataExecutionInfoList',
    method: 'get',
    params: param
  });
}

//待我审批
function getWaitInfoList(param) {
  return (0, _request.default)({
    url: '/api-workflow/checkExecutionInfo/getCheckWaitExecutionInfoList',
    method: 'get',
    params: param
  });
}

//审批拒绝
function refuesePass(data) {
  return (0, _request.default)({
    url: '/api-workflow/checkExecutionInfo/refuse',
    method: 'put',
    data: data
  });
}

//审批通过
function onPass(data) {
  return (0, _request.default)({
    url: '/api-workflow/checkExecutionInfo/pass',
    method: 'put',
    data: data
  });
}

//获取modelId
function getModelId(param) {
  return (0, _request.default)({
    url: '/api-workflow/process/createModel',
    method: 'get',
    params: param
  });
}

//API列表
function getApiList(data) {
  return (0, _request.default)({
    url: '/api-user/sys/apiInfo/list',
    method: 'post',
    data: data
  });
}

//删除api
function deleteApi(param) {
  return (0, _request.default)({
    url: '/api-user/sys/apiInfo/delete',
    method: 'post',
    params: param
  });
}

//编辑api
function updateApi(data) {
  return (0, _request.default)({
    url: '/api-user/sys/apiInfo/update',
    method: 'post',
    data: data
  });
}

//添加api
function insertApi(data) {
  return (0, _request.default)({
    url: '/api-user/sys/apiInfo/save',
    method: 'post',
    data: data
  });
}

//获取业务数据详情
function getYwDetail(url) {
  return (0, _request.default)({
    url: url,
    method: 'get'
  });
}