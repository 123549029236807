"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _codeProcessquery = require("@/api/operationalTools2/codeProcessquery2");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      codeForm: {
        markCode: ''
      },
      codeFormRules: {
        markCode: [{
          required: true,
          message: '请输入数码',
          trigger: 'change'
        }]
      },
      activityName: '',
      seqId: ''
    };
  },
  activated: function activated() {},
  methods: {
    queryActivityByMarkCode: function queryActivityByMarkCode() {
      var _this = this;
      if (!this.codeForm.markCode) {
        return this.$message.error('请输入数码');
      }
      (0, _codeProcessquery.queryActivityByMarkCode)({
        markCode: this.codeForm.markCode
      }).then(function (res) {
        if (res.data.data && res.data.data.errCode) {
          return _this.$message.error(res.data.data.errMsg);
        } else {
          _this.activityName = res.data.data && res.data.data.activityName ? res.data.data.activityName : '暂无数据';
          _this.seqId = res.data.data && res.data.data.seqId ? res.data.data.seqId : '暂无数据';
        }
      });
    },
    handleToAct: function handleToAct() {
      // this.$router.push({
      //   name: 'marketingManagemarketingActivitiesactivityManageindex',
      //   params: {
      //     seqId: this.seqId
      //   }
      // })
    }
  }
};
exports.default = _default;