"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.regexp.split");
var _ProductDialog = _interopRequireDefault(require("./ProductDialog"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _shop = require("@/api/wxhyyy/shop.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default,
    ProDialog: _ProductDialog.default
  },
  data: function data() {
    return {
      btnIs: false,
      type: JSON.parse(this.$route.query.data).type * 1,
      productVisibleShow: false,
      // 是否显示弹窗
      dataForm: {
        name: '',
        // 名字
        amount: '',
        // 积分数
        type: '100',
        // 范围类型
        range: '2',
        // 产品范围
        products: [],
        // 部分产品列表
        isattention: '2',
        // 是否关注公众号
        backgroundUrl: '',
        // 背景图片
        actSectionList: [{
          sectionStart: '',
          sectionEnd: ''
        }],
        // 数码段
        wechat: '',
        // 公众号id
        wechatName: ''
      },
      list: [],
      PageForm: {
        pageNo: 0,
        pageSize: 999
      },
      total: 0,
      // 页码数
      disabled: false,
      isAll: false,
      activiteId: '',
      rules: {
        name: [{
          required: true,
          message: '请输入积分规则名称',
          trigger: 'change'
        }, {
          min: 1,
          max: 30,
          message: '长度为1-30位字符',
          trigger: 'blur'
        }],
        amount: [{
          required: true,
          message: '请输入积分数',
          trigger: 'change'
        }],
        type: [{
          required: true,
          message: '请选择范围类型',
          trigger: 'change'
        }],
        range: [{
          required: true,
          message: '请选择产品范围类型',
          trigger: 'change'
        }],
        products: [{
          required: true,
          message: '请选择产品范围',
          trigger: 'change'
        }],
        actSectionList: [{
          required: true,
          message: '请添加区段',
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {},
  created: function created() {
    this.cid = localStorage.getItem('cid');
    this.PageForm.cid = localStorage.getItem('cid');
    if (this.type === 2) {
      this.disabled = true;
      this.integralActDetailsFun();
    } else if (this.type !== 0) {
      this.integralActDetailsFun();
    }
  },
  methods: {
    // 规则详情
    integralActDetailsFun: function integralActDetailsFun() {
      var _this = this;
      var id = JSON.parse(this.$route.query.data).id;
      (0, _shop.integralActDetails)({
        id: id
      }).then(function (resp) {
        var resp = resp.data;
        if (resp.code === 0) {
          _this.dataForm = Object.assign(_this.dataForm, resp.data);
          if (_this.dataForm.type === '100' && _this.dataForm.range === '2') {
            _this.getJFChosenProductFun();
          }
          if (!resp.data.actSectionList) {
            _this.dataForm.actSectionList = [{
              sectionStart: '',
              sectionEnd: ''
            }];
          }
        }
      });
    },
    sectionStart: function sectionStart(rule, value, callback) {
      var index = rule.field.split('.')[1];
      if (this.dataForm.actSectionList[index].sectionStart > this.dataForm.actSectionList[index].sectionEnd && this.dataForm.actSectionList[index].sectionEnd) {
        callback(new Error('开始区段必须小于等于结束区段'));
      } else {
        callback();
      }
    },
    sectionEnd: function sectionEnd(rule, value, callback) {
      var index = rule.field.split('.')[1];
      if (this.dataForm.actSectionList[index].sectionStart > this.dataForm.actSectionList[index].sectionEnd) {
        callback(new Error('结束区段必须大于等于开始区段'));
      } else {
        callback();
      }
    },
    getJFChosenProductFun: function getJFChosenProductFun() {
      var _this2 = this;
      var activiteId = JSON.parse(this.$route.query.data).id;
      this.activiteId = activiteId;
      (0, _shop.getChosenProduct)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.PageForm), {}, {
        activiteId: activiteId
      })).then(function (resp) {
        var resp = resp.data;
        if (resp.code === 0) {
          _this2.setProduct(resp.data.lstResult);
          _this2.total = resp.data.pageTotal;
        }
      });
    },
    pagingFun: function pagingFun() {
      this.getJFChosenProductFun();
    },
    // 获取公众号
    // 选中产品数组
    setProduct: function setProduct(data) {
      var _this3 = this;
      this.productVisibleShow = false;
      var ob = {
        ID: 'id',
        ACT_ID: 'actId',
        PRODUCT_NAME: 'productName',
        NEWPSID: 'productNo',
        PRODUCT_NO: 'productId',
        PRODUCT_ID: 'productId',
        BATCH_NO: 'batchNo',
        QYORG_ID: 'qyorgId',
        QYORG_NAME: 'qyorgName',
        PRODUCT_NORMS: 'productNorms',
        PRODUCT_TYPE: 'productType',
        TYPE: 'type',
        ROW_ID: 'rowId'
      };
      this.list = data;
      this.dataForm.products = [];
      data.forEach(function (item, i) {
        var obj = {};
        Object.keys(item).forEach(function (key) {
          obj[ob[key]] = item[key];
        });
        _this3.dataForm.products.push(obj);
      });
    },
    typeFun: function typeFun(val) {
      // .replace(/\s+/g,'')
      if (val === '100') {
        // this.dataForm.range = '1'
        // this.dataForm.actSectionList = []
      } else if (val === '101') {
        // this.dataForm.range = '2'
        // this.list = []
        // this.dataForm.products = []
        // this.dataForm.actSectionList = [{
        //   sectionStart: '',
        //   sectionEnd: ''
        // }]
      }
    },
    // 添加 数码段
    addFun: function addFun() {
      this.dataForm.actSectionList.push({
        sectionStart: '',
        sectionEnd: ''
      });
    },
    // 删除数码段
    deleteFun: function deleteFun(index) {
      this.dataForm.actSectionList.splice(index, 1);
    },
    // 选择产品
    productListShow: function productListShow() {
      var _this4 = this;
      this.productVisibleShow = true;
      setTimeout(function () {
        _this4.$refs.productVisible.visible = true;
      }, 0);
    },
    // 提交
    submitFun: function submitFun() {
      var _this5 = this;
      this.dataForm.name = this.dataForm.name.replace(/\s+/g, '');
      this.$refs.dataForm.validate(function (valid) {
        if (valid) {
          _this5.btnIs = true;
          if (_this5.type === 0) {
            (0, _shop.addIntegralAct)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, _this5.dataForm), {}, {
              cid: _this5.cid
            })).then(function (resp) {
              var resp = resp.data;
              _this5.btnIs = false;
              if (resp.code === 0) {
                _this5.$message({
                  type: 'success',
                  message: "\u6DFB\u52A0\u6210\u529F!"
                });
                _this5.$router.go(-1);
              } else {
                _this5.$message({
                  type: 'error',
                  message: "\u6DFB\u52A0\u5931\u8D25!"
                });
              }
            });
          } else if (_this5.type === 1) {
            (0, _shop.updateIntegralAct)((0, _objectSpread2.default)({}, _this5.dataForm)).then(function (resp) {
              var resp = resp.data;
              _this5.btnIs = false;
              if (resp.code === 0) {
                _this5.$message({
                  type: 'success',
                  message: "\u7F16\u8F91\u6210\u529F!"
                });
                _this5.$router.go(-1);
              } else {
                _this5.$message({
                  type: 'error',
                  message: "\u7F16\u8F91\u5931\u8D25!"
                });
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    validTe: function validTe(value) {
      value = value.replace(/[^0-9]/g, '');
      return value;
    },
    numFun: function numFun(value) {
      value = value.replace(/[^0-9]/g, '');
      if (value > 0) {
        return value * 1;
      } else if (value <= 0 && value) {
        value = 0;
        return value;
      } else {
        value = '';
        return value;
      }
    }
  }
};
exports.default = _default;