var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.dialogVisible,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.mloading,
              expression: "mloading",
            },
          ],
          ref: "scanForm",
          staticClass: "info-items",
          attrs: {
            model: _vm.scanData,
            rules: _vm.rules,
            "label-position": "right",
            "label-width": "150px",
          },
        },
        [
          _c("el-row", { staticClass: "title" }, [
            _vm._v("\n      —— 单据信息 ——\n    "),
          ]),
          _vm._v(" "),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "产品编码:",
                        prop: "billoutTransAO.outStoreId",
                      },
                    },
                    [_c("div", [_vm._v("浙品码")])]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品名称:", prop: "billNo" } },
                    [_c("div", [_vm._v("2000")])]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "规格型号:", prop: "billNo" } },
                    [_c("div", [_vm._v("2000")])]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "系数:",
                        prop: "billoutTransAO.outStoreId",
                      },
                    },
                    [_c("div", [_vm._v("浙品码")])]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "组数:", prop: "billNo" } },
                    [_c("div", [_vm._v("2000")])]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "销售数量:", prop: "billNo" } },
                    [_c("div", [_vm._v("2000")])]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "客户代码:",
                        prop: "billoutTransAO.outStoreId",
                      },
                    },
                    [_c("div", [_vm._v("浙品码")])]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "客户名称:", prop: "billNo" } },
                    [_c("div", [_vm._v("2000")])]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "区域代码:", prop: "billNo" } },
                    [_c("div", [_vm._v("2000")])]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-row", { staticClass: "title" }, [
            _vm._v("\n      —— 排产信息 ——\n    "),
          ]),
          _vm._v(" "),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "计划生产:", prop: "billNo" } },
                    [_c("div", [_vm._v("2000")])]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "已排产:", prop: "billNo" } },
                    [_c("div", [_vm._v("2000")])]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "未排产:",
                        prop: "billoutTransAO.outStoreId",
                      },
                    },
                    [_c("div", [_vm._v("浙品码")])]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "middleLine" }),
          _vm._v(" "),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "政府追溯:",
                        prop: "billoutTransAO.outStoreId",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "350px" },
                          attrs: { clearable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.scanData.billoutTransAO.outStoreId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.scanData.billoutTransAO,
                                "outStoreId",
                                $$v
                              )
                            },
                            expression: "scanData.billoutTransAO.outStoreId",
                          },
                        },
                        _vm._l(_vm.storeList, function (item) {
                          return _c("el-option", {
                            key: item.pId,
                            attrs: {
                              label: item.storeHouseName,
                              value: item.pId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "采集箱码:",
                        prop: "billoutTransAO.outStoreId",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "350px" },
                          attrs: { clearable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.scanData.billoutTransAO.outStoreId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.scanData.billoutTransAO,
                                "outStoreId",
                                $$v
                              )
                            },
                            expression: "scanData.billoutTransAO.outStoreId",
                          },
                        },
                        _vm._l(_vm.storeList, function (item) {
                          return _c("el-option", {
                            key: item.pId,
                            attrs: {
                              label: item.storeHouseName,
                              value: item.pId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "排产车间" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.scanData.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.scanData, "type", $$v)
                        },
                        expression: "scanData.type",
                      },
                    },
                    [
                      _c("el-checkbox", {
                        attrs: { label: "美食/餐厅线上活动", name: "type" },
                      }),
                      _vm._v(" "),
                      _c("el-checkbox", {
                        attrs: { label: "地推活动", name: "type" },
                      }),
                      _vm._v(" "),
                      _c("el-checkbox", {
                        attrs: { label: "线下主题活动", name: "type" },
                      }),
                      _vm._v(" "),
                      _c("el-checkbox", {
                        attrs: { label: "单纯品牌曝光", name: "type" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "排产组数:", prop: "billNo" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "350px" },
                        attrs: {
                          clearable: "",
                          maxlength: "20",
                          "show-word-limit": "",
                          disabled: _vm.disabled.num,
                        },
                        on: {
                          input: function (e) {
                            return (_vm.scanData.billNo = _vm.validSe(e))
                          },
                        },
                        model: {
                          value: _vm.scanData.billNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.scanData, "billNo", $$v)
                          },
                          expression: "scanData.billNo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "排产数量:", prop: "billNo" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "350px" },
                        attrs: {
                          clearable: "",
                          maxlength: "20",
                          "show-word-limit": "",
                          disabled: _vm.disabled.num,
                        },
                        on: {
                          input: function (e) {
                            return (_vm.scanData.billNo = _vm.validSe(e))
                          },
                        },
                        model: {
                          value: _vm.scanData.billNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.scanData, "billNo", $$v)
                          },
                          expression: "scanData.billNo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c("div", { staticClass: "middleLine" }),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "dialog-footer",
                      staticStyle: { "text-align": "right" },
                    },
                    [
                      _c("el-button", { on: { click: _vm.handleClose } }, [
                        _vm._v("取 消"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", loading: _vm.btn_loading },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm()
                            },
                          },
                        },
                        [_vm._v("确 定")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }