var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        "append-to-body": true,
        title: "选择产品",
        visible: _vm.visible,
        "before-close": _vm.selCancel,
        width: "1200px !important",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c("div", { staticStyle: { display: "flex", padding: "10px" } }, [
        _c(
          "div",
          {
            staticClass: "page-section",
            staticStyle: {
              width: "calc(100% - 300px)",
              "padding-left": "0",
              "padding-right": "0",
            },
          },
          [
            _c(
              "el-form",
              {
                ref: "searchForm",
                staticClass: "search-condition",
                attrs: {
                  model: _vm.searchForm,
                  "status-icon": "",
                  "label-position": _vm.formConfig.labelPosition,
                  "label-width": _vm.formConfig.labelWidth,
                  "label-suffix": ":",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "cols" },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "产品分类",
                                  prop: "productClassId",
                                },
                              },
                              [
                                _c("el-cascader", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择",
                                    options: _vm.normalList,
                                    props: {
                                      value: "pId",
                                      label: "productClassName",
                                      children: "children",
                                    },
                                    filterable: "",
                                  },
                                  model: {
                                    value: _vm.searchForm.productClassId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "productClassId",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.productClassId",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "产品编码",
                                  prop: "productCode",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请输入",
                                  },
                                  model: {
                                    value: _vm.searchForm.productCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "productCode",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.productCode",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "产品名称",
                                  prop: "productName",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请输入",
                                  },
                                  model: {
                                    value: _vm.searchForm.productName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "productName",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.productName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { "label-width": "0" } },
                              [
                                _c(
                                  "div",
                                  { attrs: { slot: "label" }, slot: "label" },
                                  [_vm._v(" ")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: _vm.btnConfig.searchBtn.type,
                                      size: _vm.btnConfig.size,
                                      icon: _vm.btnConfig.searchBtn.icon,
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.searchForm.current = 1
                                        _vm.getList()
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: _vm.btnConfig.resetBtn.type,
                                      size: _vm.btnConfig.size,
                                      icon: _vm.btnConfig.resetBtn.icon,
                                    },
                                    on: { click: _vm.resetForm },
                                  },
                                  [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            !_vm.formConfig.btnFollow
              ? _c(
                  "div",
                  { staticClass: "phead" },
                  [
                    _c(
                      "el-col",
                      { staticClass: "head-btn-group" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: _vm.btnConfig.searchBtn.type,
                              size: _vm.btnConfig.size,
                              icon: _vm.btnConfig.searchBtn.icon,
                            },
                            on: {
                              click: function ($event) {
                                _vm.searchForm.current = 1
                                _vm.getList()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: _vm.btnConfig.resetBtn.type,
                              size: _vm.btnConfig.size,
                              icon: _vm.btnConfig.resetBtn.icon,
                            },
                            on: { click: _vm.resetForm },
                          },
                          [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "section",
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.listLoading,
                        expression: "listLoading",
                      },
                    ],
                    key: _vm.tableKey,
                    ref: "multipleTable",
                    staticStyle: { width: "100%", "margin-top": "1px" },
                    attrs: {
                      data: _vm.tableData,
                      border: _vm.tableConfig.border,
                      stripe: _vm.tableConfig.stripe,
                      fit: "",
                      height: "calc(50vh - 125px)",
                      "row-key": "pId",
                      "highlight-current-row": "",
                    },
                    on: { "selection-change": _vm.handleSelectionChange },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "selection",
                        "reserve-selection": true,
                        width: "50",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "产品编码",
                        "min-width": "100",
                        prop: "productCode",
                        align: _vm.tableConfig.align,
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "产品名称",
                        "min-width": "100",
                        prop: "productName",
                        align: _vm.tableConfig.align,
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "规格",
                        prop: "productModel",
                        align: _vm.tableConfig.align,
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "产品分类",
                        "min-width": "100",
                        prop: "productClassName",
                        align: _vm.tableConfig.align,
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "包装比例",
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                        prop: "packScaleDesc",
                        align: _vm.tableConfig.align,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("pagination", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.total > 0,
                      expression: "total > 0",
                    },
                  ],
                  attrs: {
                    layout: "total, sizes, prev, pager, next, jumper",
                    total: _vm.total,
                    page: _vm.searchForm.current,
                    limit: _vm.searchForm.size,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.searchForm, "current", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.searchForm, "size", $event)
                    },
                    pagination: _vm.getList,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "page-section leftSide" }, [
          _c(
            "div",
            { staticClass: "filter-container" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.selLoading,
                      expression: "selLoading",
                    },
                  ],
                  staticStyle: { width: "100%", "margin-top": "1px" },
                  attrs: {
                    data: _vm.multipleSelection,
                    fit: "",
                    height: "50vh",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "已选产品",
                      "min-width": "100",
                      prop: "productName",
                      align: "left",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(scope.row.productName) +
                                  " " +
                                  _vm._s("(" + scope.row.productCode + ")")
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      width: "60",
                      prop: "productName",
                      align: "left",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-button", {
                              staticStyle: { color: "#99a3ad" },
                              attrs: {
                                type: "text",
                                icon: "el-icon-delete-solid",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteProductItem(scope.row)
                                },
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.clear } }, [_vm._v("清 空")]),
          _vm._v(" "),
          _c("el-button", { on: { click: _vm.selCancel } }, [_vm._v("取 消")]),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }