var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mod-prod-sku-tag" },
    [
      _c(
        "el-form-item",
        { attrs: { label: "商品规格：" } },
        [
          !_vm.disabled
            ? _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.shopTagInput()
                    },
                  },
                },
                [_vm._v("添加规格")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.skuTags, function (tag, tagIndex) {
            return _c(
              "div",
              { key: tagIndex },
              [
                _c("span", [_vm._v(_vm._s(tag.tagName))]),
                _vm._v(" "),
                !_vm.disabled
                  ? _c(
                      "el-button",
                      {
                        staticClass: "button-new-tag",
                        attrs: { type: "text", icon: "el-icon-delete" },
                        on: {
                          click: function ($event) {
                            return _vm.removeTag(tagIndex)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticStyle: { "margin-top": "10px" } }),
                _vm._v(" "),
                _vm._l(tag.tagItems, function (tagItem, tagItemIndex) {
                  return _c(
                    "el-tag",
                    {
                      key: tagItem,
                      attrs: {
                        closable: !_vm.disabled,
                        "disable-transitions": false,
                      },
                      on: {
                        close: function ($event) {
                          return _vm.handleTagClose(tagIndex, tagItemIndex)
                        },
                      },
                    },
                    [_vm._v(_vm._s(tagItem))]
                  )
                }),
                _vm._v(" "),
                _vm.tagItemInputs[tagIndex] &&
                _vm.tagItemInputs[tagIndex].visible
                  ? _c("el-input", {
                      ref: "saveTagInput" + tagIndex,
                      refInFor: true,
                      staticClass: "input-new-tag",
                      attrs: { size: "small" },
                      on: {
                        blur: function ($event) {
                          return _vm.handleInputConfirm(tagIndex)
                        },
                      },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.handleInputConfirm(tagIndex)
                        },
                      },
                      model: {
                        value: _vm.tagItemInputs[tagIndex].value,
                        callback: function ($$v) {
                          _vm.$set(_vm.tagItemInputs[tagIndex], "value", $$v)
                        },
                        expression: "tagItemInputs[tagIndex].value",
                      },
                    })
                  : _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.disabled,
                            expression: "!disabled",
                          },
                        ],
                        staticClass: "button-new-tag",
                        attrs: { size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.showTagInput(tagIndex)
                          },
                        },
                      },
                      [_vm._v("+ 添加")]
                    ),
              ],
              2
            )
          }),
        ],
        2
      ),
      _vm._v(" "),
      _vm.isShowTagInput
        ? _c(
            "el-form-item",
            { attrs: { label: "规格名：" } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        "allow-create": "",
                        "default-first-option": "",
                        placeholder: "请选择",
                      },
                      on: { change: _vm.handleTagClick },
                      model: {
                        value: _vm.addTagInput.propName,
                        callback: function ($$v) {
                          _vm.$set(_vm.addTagInput, "propName", $$v)
                        },
                        expression: "addTagInput.propName",
                      },
                    },
                    _vm._l(_vm.unUseTags, function (item) {
                      return _c("el-option", {
                        key: item.propId,
                        attrs: { label: item.propName, value: item.propName },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isShowTagInput
        ? _c(
            "el-form-item",
            { attrs: { label: "规格值：" } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        multiple: "",
                        filterable: "",
                        "allow-create": "",
                        "default-first-option": "",
                        placeholder: "请选择",
                      },
                      model: {
                        value: _vm.addTagInput.selectValues,
                        callback: function ($$v) {
                          _vm.$set(_vm.addTagInput, "selectValues", $$v)
                        },
                        expression: "addTagInput.selectValues",
                      },
                    },
                    _vm._l(_vm.dbTagValues, function (item) {
                      return _c("el-option", {
                        key: item.valueId,
                        attrs: { label: item.propValue, value: item.propValue },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-form-item",
        [
          _vm.isShowTagInput
            ? _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.addTag()
                    },
                  },
                },
                [_vm._v("确定")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isShowTagInput
            ? _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.hideTagInput()
                    },
                  },
                },
                [_vm._v("取消")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }