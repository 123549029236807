"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.regexp.replace");
var _dataAnalysis = require("@/api/terminal/dataAnalysis");
var _memberManage = require("@/api/terminal/memberManage");
var _index = require("@/utils/index.js");
var _LineChart = _interopRequireDefault(require("@/components/Charts/LineChart"));
var _MapChart = _interopRequireDefault(require("@/components/Charts/MapChart"));
var _timers = require("timers");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  filters: {
    stateFilter: function stateFilter(status) {
      if (!status) return '未知';
      var statusMap = {
        100: '未开始',
        200: '进行中',
        300: '已结束',
        600: '暂停中'
      };
      return statusMap[status];
    }
  },
  components: {
    LineChart: _LineChart.default,
    MapChart: _MapChart.default
  },
  data: function data() {
    return {
      listLoading: false,
      pickerOptions1: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e7;
        }
      },
      formInline: {
        actNo: '',
        time: ''
      },
      daterange: [],
      activeList: [],
      rules: {
        actNo: [{
          required: true,
          message: '请选择活动',
          trigger: 'change'
        }]
      },
      list: {},
      listExpect: {},
      list1: {},
      tList: {},
      show: false,
      isAnticipate: 0
    };
  },
  created: function created() {
    var _this = this;
    this.listLoading = true;
    (0, _dataAnalysis.selectConfig)().then(function (res) {
      res = res.data;
      if (res.status === 200) {
        _this.activeList = res.data;
      } else {
        _this.$message.closeAll();
        _this.$message({
          message: res.msg,
          type: 'error',
          duration: 3 * 1000
        });
      }
      _this.listLoading = false;
    });
  },
  methods: {
    fetchData: function fetchData() {
      var _this2 = this;
      this.$refs['formInline'].validate(function (valid) {
        if (valid) {
          _this2.listLoading = true;
          var p = new Promise(function (resolve, reject) {
            (0, _dataAnalysis.selectEffect)(_this2.formInline).then(function (res) {
              res = res.data;
              if (res.state * 1 === 200) {
                _this2.isAnticipate = res.data.isAnticipate;
                _this2.list = res.data.actEffectSum;
                _this2.listExpect = res.data.actEffectSumExpect;
                _this2.tList = res.data.acvNosVo;
                if (res.data.acvNosVo.startTime) {
                  var start = new Date(res.data.acvNosVo.startTime.replace(/-/g, '/')).getTime();
                  var end = new Date(res.data.acvNosVo.endTime.replace(/-/g, '/')).getTime();
                  if (start <= Date.now() && _this2.tList.status === '100') {
                    _this2.tList.status = '200';
                  }
                  if (end <= Date.now()) {
                    _this2.tList.status = '300';
                  }
                  resolve();
                } else {
                  reject();
                  _this2.$message.closeAll();
                  _this2.$message({
                    message: '没有活动数据，请选择其他活动',
                    type: 'error',
                    duration: 3 * 1000
                  });
                }
              } else {
                reject();
                _this2.$message.closeAll();
                _this2.$message({
                  message: res.msg,
                  type: 'error',
                  duration: 3 * 1000
                });
              }
              _this2.listLoading = false;
            });
          });
          p.then(function () {
            _this2.show = true;
            _this2.daterange = [];
            if (_this2.tList.status === '300') {
              _this2.daterange = [_this2.tList.startTime.split(' ')[0], _this2.tList.endTime.split(' ')[0]];
            } else {
              _this2.daterange = [_this2.tList.startTime.split(' ')[0], (0, _index.getFormatDate)(0)[0]];
            }
            _this2.getUndertext();
            _this2.getmapChart();
          }).catch(function () {
            _this2.show = false;
          });
          (0, _dataAnalysis.surplusCount)(_this2.formInline).then(function (res) {
            res = res.data;
            if (res.state * 1 === 200) {
              _this2.list1 = res.data;
            } else {
              _this2.show = false;
              _this2.$message.closeAll();
              _this2.$message({
                message: res.msg,
                type: 'error',
                duration: 3 * 1000
              });
            }
          }).catch(function () {
            _this2.show = false;
          });
          // setTimeout(() => {
          //   this.getmapChart()
          // }, 0)
        }
      });
    },
    getUndertext: function getUndertext() {
      var _this3 = this;
      var obj = {
        startTime: this.daterange[0],
        endTime: this.daterange[1]
      };
      Object.assign(obj, this.formInline);
      this.getCodemapChart(obj);
      (0, _dataAnalysis.dayJoinSum)(obj).then(function (res) {
        res = res.data;
        if (res.state * 1 === 200) {
          var xData = [];
          var sData = [];
          var aReally = [];
          var aExpect = [];
          if (res.data.actHourSumVos.length > 0) {
            res.data.actHourSumVos.forEach(function (item) {
              xData.push(item.crenttime.split(' ')[0]);
              aReally.push(item.hourActivitySum);
              aExpect.push(item.hourActivitySumExpect);
            });
          }
          var oReally = {
            name: '实际参与量',
            type: 'line',
            data: aReally,
            animationDuration: 3000
          };
          var oExpect = {
            name: '预期参与量',
            type: 'line',
            data: aExpect,
            animationDuration: 3000
          };
          if (res.data.isAnticipate * 1 === 1) {
            sData = [oReally, oExpect];
          } else {
            sData = [oReally];
          }
          _this3.show = true;
          (0, _timers.setTimeout)(function () {
            _this3.$refs.barChart.xData = xData;
            _this3.$refs.barChart.sData = sData;
            _this3.$refs.barChart.initChart();
          }, 0);
        } else {
          _this3.show = false;
          _this3.$message.closeAll();
          _this3.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
      }).catch(function () {
        _this3.show = false;
      });
    },
    getmapChart: function getmapChart() {
      var _this4 = this;
      (0, _memberManage.getMap)().then(function (res) {
        res = res.data;
        if (res.status === 200) {
          var data = [];
          var maxCount = 50;
          res.data.forEach(function (item) {
            var obj = {
              name: item.provinceName,
              value: item.count * 1 === 0 ? '' : item.count
            };
            data.push(obj);
            if (item.count > maxCount) maxCount = item.count;
          });
          _this4.$refs.mapChart.mapData = data;
          _this4.$refs.mapChart.max = maxCount;
          _this4.$refs.mapChart.initChart();
        } else {
          _this4.show = false;
          _this4.$message.closeAll();
          _this4.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
      }).catch(function () {
        _this4.show = false;
      });
    },
    getCodemapChart: function getCodemapChart(val) {
      var _this5 = this;
      (0, _dataAnalysis.sweepCode)(val).then(function (res) {
        res = res.data;
        if (res.state * 1 === 200) {
          var data = [];
          var maxCount = 50;
          res.data.forEach(function (item) {
            var obj = {
              name: item.name,
              value: item.count * 1 === 0 ? '' : item.count
            };
            data.push(obj);
            if (item.count > maxCount) maxCount = item.count;
          });
          _this5.$refs.codeMapChart.mapData = data;
          _this5.$refs.codeMapChart.max = maxCount;
          _this5.$refs.codeMapChart.initChart();
        } else {
          _this5.$message.closeAll();
          _this5.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
      });
    },
    changeChart: function changeChart(num) {
      this.active = num;
      this.fetchData((0, _index.getFormatDate)(num));
    },
    selectAct: function selectAct(val) {
      var _this6 = this;
      this.formInline.time = '';
      this.fetchData();
      // 根据活动时间限制时间选择
      this.activeList.forEach(function (item) {
        if (item.actNo === val) {
          var start = new Date(item.startTime.replace(/-/g, '/')).getTime();
          var end = new Date(item.endTime.replace(/-/g, '/')).getTime();
          _this6.pickerOptions1 = {
            disabledDate: function disabledDate(time) {
              return time.getTime() < start || time.getTime() > end - 1 || time.getTime() > Date.now() - 8.64e7;
            }
          };
        }
      });
    }
  }
};
exports.default = _default;