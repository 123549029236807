"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _basic = require("@/api/mark/basic");
var _select = require("@/api/warehouse/select");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'TableSuply',
  components: {},
  filters: {
    formatDate: function formatDate(time) {
      var date = new Date(time);
      return (0, _basic.formatDate)(date, 'yyyy-MM-dd hh:mm:ss');
    }
  },
  props: {
    num: {
      type: String,
      default: function _default() {
        return '';
      }
    }
  },
  data: function data() {
    return {
      tableData: [],
      detailVisible: false,
      listLoading: false,
      total: 0,
      searchForm: {
        pageNo: 1,
        pageSize: 20
      }
    };
  },
  methods: {
    show: function show(rows) {
      this.detailVisible = true;
      this.listLoading = true;
      this.total = 0;
      this.searchForm = {
        num: rows.num,
        numRow: rows.numRow,
        numSplit: rows.numSplit,
        pageNo: 1,
        pageSize: 20
      };
      this.getList();
    },
    getList: function getList() {
      var _this = this;
      var that = this;
      that.tableData = [];
      (0, _select.getAnomalousCodeInfoList)(that.searchForm).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.tableData = response.data.data.records;
            that.total = parseInt(response.data.data.total);
            setTimeout(function () {
              that.listLoading = false;
            }, 1 * 500);
          } else {
            that.tableData = [];
            that.total = 0;
          }
        }
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    handleClose: function handleClose() {
      this.detailVisible = false;
    }
  }
};
exports.default = _default2;