var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-drawer",
        {
          attrs: {
            title: "详情",
            visible: _vm.visible,
            direction: "rtl",
            "before-close": _vm.closeFun,
            size: "90%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "drawer-content" },
            [
              _c("div", { staticClass: "itemTitle" }, [_vm._v("物码信息")]),
              _vm._v(" "),
              _c(
                "el-form",
                { attrs: { model: _vm.markInfo, "label-width": "110px" } },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("参与数码:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.markInfo.markCode)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("所属经销商:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.markInfo.agentName)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("所属产品:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.markInfo.productName)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("所属门店:"),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "middleLine" }),
              _vm._v(" "),
              _c("div", { staticClass: "itemTitle" }, [
                _c("span", { staticStyle: { "margin-right": "20px" } }, [
                  _vm._v("活动信息"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-form",
                { attrs: { model: _vm.activityInfo, "label-width": "110px" } },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("所属活动:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.activityInfo.activityName)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("扫码时间:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.activityInfo.a)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("奖品名称:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.activityInfo.prizeName)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("中奖时间:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("textDate")(_vm.activityInfo.createTime)
                              )
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("核销方式:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.activityInfo.remark)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("奖品形式:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("prizeType")(_vm.activityInfo.prizeType)
                              )
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("领取状态:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("receivingStatus")(
                                  _vm.activityInfo.receivingStatus
                                )
                              )
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("发货时间:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.activityInfo.expressTime)),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "middleLine" }),
              _vm._v(" "),
              _c("div", { staticClass: "itemTitle" }, [
                _c("span", { staticStyle: { "margin-right": "20px" } }, [
                  _vm._v("用户信息"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  attrs: { model: _vm.wechatUserInfo, "label-width": "110px" },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("微信昵称:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.wechatUserInfo.wechatName)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("用户ID:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.wechatUserInfo.wechatUserId)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("unionID:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.wechatUserInfo.unionid)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("IP:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.wechatUserInfo.ipAddress)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("openid:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.wechatUserInfo.openid)),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "middleLine" }),
              _vm._v(" "),
              _c("div", { staticClass: "itemTitle" }, [
                _c("span", { staticStyle: { "margin-right": "20px" } }, [
                  _vm._v("邮寄信息"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-form",
                { attrs: { model: _vm.baseData, "label-width": "110px" } },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("收件人:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.baseData.winnerName)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("收件人电话:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.baseData.winnerPhone)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("收件地址:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.baseData.winnerAddress)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("详细地址:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.baseData.winnerAddressDetail)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("物流公司:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.baseData.expressCompany)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("物流单号:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.baseData.expressOrder)),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "drawer-footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "default", size: "small" },
                  on: { click: _vm.closeFun },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }