"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addWechat = addWechat;
exports.delWechatOfficial = delWechatOfficial;
exports.deleteProductAudio = deleteProductAudio;
exports.detail = detail;
exports.edit = edit;
exports.getAudioFileInfo = getAudioFileInfo;
exports.getProduct = getProduct;
exports.getV2Enterprise = getV2Enterprise;
exports.getV2Product = getV2Product;
exports.getWcList = getWcList;
exports.getWechatList = getWechatList;
exports.isAvailable = isAvailable;
exports.setConsum = setConsum;
exports.wxgetWcList = wxgetWcList;
var _request = _interopRequireDefault(require("@/utils/request"));
var _auth = require("@/utils/auth");
var mode = process.env.VUE_APP_VERSION1;
// 公众号列表
// var baseUrl = '/api'
var baseUrl = '/api-uspyx';
function getWcList(data) {
  return (0, _request.default)({
    url: mode + baseUrl + '/wechat/official/list',
    method: 'POST',
    data: data
  });
}
// 活动列表公众号配置
function wxgetWcList(data) {
  return (0, _request.default)({
    url: mode + baseUrl + '/wechat/official/act_wechat_list',
    method: 'POST',
    data: data
  });
}
// 设置为消费者公众号
function setConsum(params) {
  return (0, _request.default)({
    url: mode + baseUrl + '/wechat/official/isConsum',
    method: 'GET',
    params: params
  });
}
// 添加公众号配置
function addWechat(data) {
  return (0, _request.default)({
    url: mode + baseUrl + '/wechat/official/add',
    method: 'POST',
    data: data
  });
}
// 校验公众号名称/公众号原始ID/APPid是否存在
function isAvailable(params) {
  return (0, _request.default)({
    url: mode + baseUrl + '/wechat/official/isAvailable',
    method: 'GET',
    params: params
  });
}
// 公众号配置详情
function detail(params) {
  return (0, _request.default)({
    url: mode + baseUrl + '/wechat/official/detail',
    method: 'GET',
    params: params
  });
}
// 编辑公众号配置
function edit(data) {
  return (0, _request.default)({
    url: mode + baseUrl + '/wechat/official/edit',
    method: 'POST',
    data: data
  });
}
// 获取产品列表
function getProduct(params) {
  return (0, _request.default)({
    url: mode + baseUrl + '/product/getProduct',
    method: 'GET',
    params: params
  });
}
// 获取ar音频信息
function getAudioFileInfo(params) {
  return (0, _request.default)({
    url: mode + baseUrl + '/ar/getAudioFileInfo',
    method: 'GET',
    params: params
  });
}
// 删除产品语音
function deleteProductAudio(params) {
  return (0, _request.default)({
    url: mode + baseUrl + '/ar/deleteProductAudio',
    method: 'POST',
    params: params
  });
}
// 获取v2企业列表
function getV2Enterprise(params) {
  return (0, _request.default)({
    url: mode + baseUrl + '/product/getV2Enterprise',
    method: 'GET',
    params: params
  });
}
// 获取v2产品列表
function getV2Product(params) {
  return (0, _request.default)({
    url: mode + baseUrl + '/product/getV2Product',
    method: 'GET',
    params: params
  });
}
// 获取活动可用微信号
function getWechatList(params) {
  return (0, _request.default)({
    url: mode + baseUrl + '/wechat/official/act_wechat_list',
    method: 'post',
    data: params
  });
}

// 修改公众号状态
function delWechatOfficial(params) {
  return (0, _request.default)({
    url: mode + baseUrl + '/wechat/official/delWechatOfficial',
    method: 'GET',
    params: params
  });
}