"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _store = require("@/api/store");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _factory = require("@/api/basic/factory");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'ErrCodesLog',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      searchForm: {
        endDate: '',
        factory: '',
        newMarkCode: '',
        oldMark: '',
        factoryCode: '',
        current: 1,
        size: 20,
        farmCode: '',
        produceLineCode: '',
        beginDate: '',
        teamCode: ''
      },
      teamList: [],
      productlineList: [],
      shopList: [],
      total: 0,
      tableData: [],
      tableKey: '01004',
      tabHeight: '100%',
      listLoading: false,
      expandTxt: '展开',
      showCol: false,
      downLoading: false,
      factoryList: []
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    this.getList();
    this.getFactoryList();
    this.getfarm(2);
    this.getfarm(3);
    this.getfarm(4);
  },
  methods: {
    getFactoryList: function getFactoryList() {
      var _this = this;
      (0, _factory.findAllFactory)({}).then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this.factoryList = res.data.data;
          } else {
            _this.factoryList = [];
          }
        }
      }).catch(function () {});
    },
    getfarm: function getfarm(type) {
      var _this2 = this;
      (0, _factory.getFactoryByType)({
        type: type
      }).then(function (res) {
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
        } else if (type == 2) {
          if (res.data.data != null) {
            _this2.shopList = res.data.data;
          } else {
            _this2.shopList = [];
          }
        } else if (type == 3) {
          if (res.data.data != null) {
            _this2.productlineList = res.data.data;
          } else {
            _this2.productlineList = [];
          }
        } else if (type == 4) {
          if (res.data.data != null) {
            _this2.teamList = res.data.data;
          } else {
            _this2.teamList = [];
          }
        }
      }).catch(function () {});
    },
    DoSearch: function DoSearch() {
      // if ((this.searchForm.endTime && this.searchForm.startTime) || (!this.searchForm.endTime && !this.searchForm.startTime)) {
      this.getList();
      this.searchForm.pageNo = 1;
      // } else {
      //   this.$message.error('起始时间和结束时间必须同时存在')
      // }
    },
    resetForm: function resetForm(formName) {
      var that = this;
      that.searchForm.pageNo = 1;
      that.searchForm.pageSize = 20;
      that.$refs['searchForm'].resetFields();
      this.getList();
    },
    getList: function getList() {
      var _this3 = this;
      this.authId('searchbtn', this.$route.meta.authList);
      var that = this;
      if (that.searchForm.startTime && !that.searchForm.endTime) {
        this.$message.error('请选择结束时间');
        return;
      }
      if (!that.searchForm.startTime && that.searchForm.endTime) {
        this.$message.error('请选择起始时间');
        return;
      }
      that.listLoading = true;
      (0, _store.listLog)(that.searchForm).then(function (response) {
        console.log(response);
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null || response.data.data != '') {
            that.tableData = response.data.data.records;
            that.total = parseInt(response.data.data.total);
            setTimeout(function () {
              that.listLoading = false;
            }, 1 * 500);
          } else {
            that.tableData = [];
            that.total = 0;
          }
        }
      }).catch(function () {
        _this3.listLoading = false;
      });
    },
    // 导出功能
    downLoad: function downLoad() {
      var _this4 = this;
      this.authId('exportbtn', this.$route.meta.authList);
      this.downLoading = true;
      var param = (0, _utils.deepClone)(this.searchForm);
      param.pageNo = 1;
      param.pageSize = this.total;
      console.log(param);
      (0, _store.exportErrLogData)(param).then(function (res) {
        _this4.authId('searchbtn', _this4.$route.meta.authList);
        if (res.status != 200) {
          _this4.$message.error(res.data.msg);
        } else {
          var blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel'
          });
          var downloadElement = document.createElement('a');
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = '异常数码.xlsx';
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
        _this4.downLoading = false;
      }).catch(function () {
        _this4.authId('searchbtn', _this4.$route.meta.authList);
        _this4.downLoading = false;
      });
    },
    // 展开收起搜索条件
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    }
  }
};
exports.default = _default;