var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c("h3", { staticClass: "h-title" }, [_vm._v("产品品牌")]),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  ref: "searchForm",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.searchForm,
                    "status-icon": "",
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cols" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6, prop: "brandName" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "brandName",
                                    "label-width": "80px",
                                    label: "品牌名称:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      maxlength: "25",
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.searchForm.brandName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "brandName",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.brandName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6, prop: "brandStatus" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "brandStatus",
                                    "label-width": "80px",
                                    label: "状态:",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.searchForm.brandStatus,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "brandStatus",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.brandStatus",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "全部", value: "" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { label: "启用", value: 1 },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { label: "禁用", value: 0 },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.formConfig.btnFollow
                            ? _c(
                                "el-col",
                                {
                                  staticClass: "head-btn-group",
                                  style: { width: _vm.formConfig.btnArea },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { "label-width": "0" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v(" ")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.searchBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.searchBtn.icon,
                                            plain:
                                              _vm.btnConfig.searchBtn.plain,
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.searchForm.current = 1
                                              _vm.getList()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.searchBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.resetBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.resetBtn.icon,
                                            plain: _vm.btnConfig.resetBtn.plain,
                                          },
                                          on: { click: _vm.resetForm },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.resetBtn.text)
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.formConfig.btnFollow
                ? _c("el-divider", { staticClass: "btnDivider" })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btnArea" },
                [
                  _c("el-col", { staticClass: "head-btn-group" }, [
                    _vm.hasAuth("addbtn", _vm.$route.meta.authList)
                      ? _c(
                          "div",
                          { staticClass: "head-btn-group" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: _vm.btnConfig.addBtn.type,
                                  size: _vm.btnConfig.size,
                                  icon: _vm.btnConfig.addBtn.icon,
                                  plain: _vm.btnConfig.plain,
                                },
                                on: { click: _vm.add },
                              },
                              [_vm._v(_vm._s(_vm.btnConfig.addBtn.text))]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "section",
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      key: _vm.tableKey,
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.tableData,
                        height: _vm.tabHeight,
                        fit: "",
                        "highlight-current-row": "",
                        border: _vm.tableConfig.border,
                        stripe: _vm.tableConfig.stripe,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "品牌编号",
                          prop: "brandCode",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "品牌名称",
                          prop: "brandName",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "已关联产品数量",
                          prop: "isProduct",
                          align: _vm.tableConfig.align,
                          "min-width": "120",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "状态",
                          width: "80",
                          align: _vm.tableConfig.align,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  row.brandStatus == 1
                                    ? _c(
                                        "el-tag",
                                        { attrs: { type: "success" } },
                                        [_vm._v("启用")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.brandStatus == 0
                                    ? _c(
                                        "el-tag",
                                        { attrs: { type: "danger" } },
                                        [_vm._v("禁用")]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4139028545
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "创建人",
                          prop: "creatorName",
                          width: "120",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "创建时间",
                          width: "170",
                          align: _vm.tableConfig.align,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm._v(
                                    _vm._s(_vm._f("textDate")(row.createTime))
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          953579966
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "更新人",
                          prop: "updaterName",
                          width: "120",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "更新时间",
                          width: "170",
                          align: _vm.tableConfig.align,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm._v(
                                    _vm._s(_vm._f("textDate")(row.updateTime))
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          739224459
                        ),
                      }),
                      _vm._v(" "),
                      _vm.hasAuth("deletebtn", _vm.$route.meta.authList) ||
                      _vm.hasAuth("updatebtn", _vm.$route.meta.authList) ||
                      _vm.hasAuth("startbtn", _vm.$route.meta.authList) ||
                      _vm.hasAuth("stopbtn", _vm.$route.meta.authList)
                        ? _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              fixed: "right",
                              align: _vm.tableConfig.align,
                              width: "160",
                              "class-name": "small-padding fixed-width",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _vm.hasAuth(
                                        "updatebtn",
                                        _vm.$route.meta.authList
                                      ) && row.brandStatus != 2
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.edit(row)
                                                },
                                              },
                                            },
                                            [_vm._v("编辑")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.hasAuth(
                                        "stopbtn",
                                        _vm.$route.meta.authList
                                      ) && row.brandStatus == 1
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "text",
                                                disabled: row.isProduct != 0,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.setUnUse(row.pId)
                                                },
                                              },
                                            },
                                            [_vm._v("禁用")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.hasAuth(
                                        "startbtn",
                                        _vm.$route.meta.authList
                                      ) && row.brandStatus == 0
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.setInUse(row.pId)
                                                },
                                              },
                                            },
                                            [_vm._v("启用")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.hasAuth(
                                        "deletebtn",
                                        _vm.$route.meta.authList
                                      )
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                disabled:
                                                  row.brandStatus == 2 ||
                                                  row.isProduct != 0,
                                                type: "text",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.DoDel(row.pId)
                                                },
                                              },
                                            },
                                            [_vm._v("删除")]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              776428451
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("pagination", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.total > 0,
                        expression: "total > 0",
                      },
                    ],
                    attrs: {
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.total,
                      page: _vm.searchForm.current,
                      limit: _vm.searchForm.size,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(_vm.searchForm, "current", $event)
                      },
                      "update:limit": function ($event) {
                        return _vm.$set(_vm.searchForm, "size", $event)
                      },
                      pagination: _vm.getList,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: _vm.title,
            visible: _vm.dialogVisible,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.mloading,
                  expression: "mloading",
                },
              ],
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "100px",
                "label-suffix": ":",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "产品品牌", prop: "brandName" } },
                [
                  _c("el-input", {
                    attrs: { disabled: _vm.disabled, maxlength: "15" },
                    model: {
                      value: _vm.ruleForm.brandName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "brandName", $$v)
                      },
                      expression: "ruleForm.brandName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "状态", prop: "brandStatus" } },
                [
                  _c("el-switch", {
                    attrs: {
                      disabled: _vm.disabled,
                      "active-color": "#13ce66",
                      "inactive-color": "#ff4949",
                      "active-value": 1,
                      "inactive-value": 0,
                      "active-text": "启用",
                      "inactive-text": "禁用",
                    },
                    model: {
                      value: _vm.ruleForm.brandStatus,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "brandStatus", $$v)
                      },
                      expression: "ruleForm.brandStatus",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remarks" } },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.disabled,
                      type: "textarea",
                      maxlength: "500",
                      "show-word-limit": "",
                      rows: 3,
                    },
                    model: {
                      value: _vm.ruleForm.remarks,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "remarks", $$v)
                      },
                      expression: "ruleForm.remarks",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              !_vm.disabled
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.btnLoading },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v("确 定")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v(_vm._s(_vm.disabled ? "关 闭" : "取 消")),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }