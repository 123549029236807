var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.show,
        "append-to-body": true,
        "before-close": _vm.handleClose,
        width: "1200px !important",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
      },
    },
    [
      _c("div", { staticClass: "itemTitle" }, [_vm._v("基本信息")]),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("盘点单号:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.scanData.inventoryOrderNo)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("盘点库房:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.scanData.storeName)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("盘点人:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.scanData.inventoryUserName)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("盘点日期:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(
                  _vm._s(_vm._f("textDateOnlyDate")(_vm.scanData.inventoryDate))
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("制单人:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.scanData.creatorName)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("制单时间:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm._f("textDate")(_vm.scanData.createTime))),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("备注:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.scanData.remark)),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "middleLine" }),
      _vm._v(" "),
      _c("div", { staticClass: "itemTitle" }, [
        _vm._v("\n    产品信息\n    "),
        _c("img", { attrs: { src: require("@/images/pro.png") } }),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticClass: "detailTable",
          staticStyle: { width: "100%", "margin-top": "1px" },
          attrs: {
            data: _vm.scanData.inventoryOrderDetailDTOList,
            fit: "",
            "max-height": "400",
            border: _vm.tableConfig.border,
            stripe: _vm.tableConfig.stripe,
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "序号",
              type: "index",
              width: "120",
              align: _vm.tableConfig.align,
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "产品名称",
              "min-width": "140",
              prop: "productName",
              align: _vm.tableConfig.align,
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "产品编码",
              "min-width": "100",
              prop: "productCode",
              align: _vm.tableConfig.align,
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "包装比例",
              "min-width": "100",
              prop: "packRaidoExpress",
              align: _vm.tableConfig.align,
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "账面箱",
              align: _vm.tableConfig.align,
              prop: "chestInventory",
              width: "140",
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "账面盒",
              align: _vm.tableConfig.align,
              prop: "bottleInventory",
              width: "140",
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "实际箱",
              align: _vm.tableConfig.align,
              prop: "chestActualNum",
              width: "140",
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "实际盒",
              align: _vm.tableConfig.align,
              prop: "bottleActualNum",
              width: "140",
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "账实差异箱",
              align: _vm.tableConfig.align,
              prop: "chestDiffNum",
              width: "140",
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "账实差异盒",
              align: _vm.tableConfig.align,
              prop: "bottleDiffNum",
              width: "160",
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "盘盈箱",
              align: _vm.tableConfig.align,
              prop: "chestProfitNum",
              width: "140",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", disabled: !row.profitOrderNo },
                        on: {
                          click: function ($event) {
                            return _vm.getflow(row, 2, "checkin")
                          },
                        },
                      },
                      [_vm._v(_vm._s(row.chestProfitNum))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "盘盈盒",
              align: _vm.tableConfig.align,
              prop: "bottleProfitNum",
              width: "160",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", disabled: !row.profitOrderNo },
                        on: {
                          click: function ($event) {
                            return _vm.getflow(row, 2, "checkin")
                          },
                        },
                      },
                      [_vm._v(_vm._s(row.bottleProfitNum))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "盘亏箱",
              align: _vm.tableConfig.align,
              prop: "chestLossesNum",
              width: "140",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", disabled: !row.lossesOrderNo },
                        on: {
                          click: function ($event) {
                            return _vm.getflow(row, 11, "checkout")
                          },
                        },
                      },
                      [_vm._v(_vm._s(row.chestLossesNum))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "盘亏盒",
              align: _vm.tableConfig.align,
              prop: "bottleLossesNum",
              width: "160",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", disabled: !row.lossesOrderNo },
                        on: {
                          click: function ($event) {
                            return _vm.getflow(row, 11, "checkout")
                          },
                        },
                      },
                      [_vm._v(_vm._s(row.bottleLossesNum))]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "middleLine" }),
      _vm._v(" "),
      _c(
        "el-collapse",
        {
          staticClass: "detailCollapse",
          attrs: { accordion: "" },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-collapse-item",
            { attrs: { name: "1" } },
            [
              _c("template", { slot: "title" }, [_vm._v("审核日志")]),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  staticStyle: { width: "100%", "margin-top": "1px" },
                  attrs: {
                    data: _vm.auditList,
                    fit: "",
                    "max-height": "400",
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "序号",
                      type: "index",
                      width: "120",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "状态",
                      "min-width": "100",
                      prop: "auditOpinion",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              _vm._s(
                                row.auditStatus == 1
                                  ? "通过"
                                  : row.auditStatus == 2
                                  ? "未通过"
                                  : ""
                              )
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "审批人",
                      "min-width": "100",
                      prop: "auditUserAccount",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "审批时间",
                      "min-width": "170",
                      prop: "productCode",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _vm._v(_vm._s(_vm._f("textDate")(row.createTime))),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "审批意见",
                      "min-width": "250",
                      prop: "auditOpinion",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "middleLine" }),
      _vm._v(" "),
      _c("div", { staticClass: "itemTitle" }, [_vm._v("审核")]),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "auditForm",
          attrs: {
            model: _vm.auditForm,
            "label-width": "85px",
            "label-suffix": ":",
            rules: _vm.auditRule,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "审核结果", prop: "handlerResult" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.auditForm.handlerResult,
                    callback: function ($$v) {
                      _vm.$set(_vm.auditForm, "handlerResult", $$v)
                    },
                    expression: "auditForm.handlerResult",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: true } }, [_vm._v("通过")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: false } }, [
                    _vm._v("不通过"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "审核意见", prop: "remark" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  maxlength: "100",
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.auditForm.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.auditForm, "remark", $$v)
                  },
                  expression: "auditForm.remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.btn_loading },
              on: { click: _vm.submitForm },
            },
            [_vm._v("审 核")]
          ),
          _vm._v(" "),
          _c("el-button", { on: { click: _vm.handleClose } }, [
            _vm._v("关 闭"),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: "数码详情",
            visible: _vm.codeVisible,
            top: "3%",
            width: "70%",
            "before-close": _vm.codeClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.codeVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.codeListLoading,
                      expression: "codeListLoading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    "max-height": "500",
                    data: _vm.codeList,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      width: "120",
                      label: "序号",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "数码",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [_c("span", [_vm._v(_vm._s(scope.row))])]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.codetotal > 0,
                    expression: "codetotal > 0",
                  },
                ],
                attrs: {
                  layout: "total,sizes,prev, pager, next, jumper",
                  total: _vm.codetotal,
                  page: _vm.detailForm.current,
                  limit: _vm.detailForm.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.detailForm, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.detailForm, "size", $event)
                  },
                  pagination: _vm.getCodeList,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.codeClose } }, [
                _vm._v("关闭"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("checkout", { ref: "checkout" }),
      _vm._v(" "),
      _c("checkin", { ref: "checkin" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }