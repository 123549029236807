"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find-index");
require("core-js/modules/es6.object.keys");
require("core-js/modules/es6.array.find");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.map");
var _dealer = require("@/api/basic/dealer");
var _utils = require("@/utils");
var _storage = require("../../../../api/storage/storage");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'BasicDataregionmangeregionindex',
  data: function data() {
    return {
      dialogVisible: false,
      title: '新增行政区划',
      searchForm: {
        areaCode: '',
        areaName: '',
        isDelete: ''
      },
      ruleForm: {
        areaCode: '',
        areaName: '',
        parentId: '',
        isDelete: 1
      },
      rules: {
        areaCode: [{
          required: true,
          message: '行政区划编码不能为空，请维护',
          trigger: 'change'
        }],
        areaName: [{
          required: true,
          message: '行政区划名称不能为空，请维护',
          trigger: 'change'
        }]
      },
      listLoading: false,
      mloading: false,
      btnLoading: false,
      tabHeight: '100%',
      tableData: [],
      page2: false,
      areaSelList: [],
      normalList: [],
      maps: new Map(),
      editData: {
        parentId: '',
        //存一下编辑时当前的父节点，与提交时的父节点对比
        pId: ''
      }
    };
  },
  activated: function activated() {
    this.getList(111);
  },
  methods: {
    // 废弃接口
    getAreaAll: function getAreaAll() {
      var that = this;
      (0, _dealer.dealerAreaList)({
        isDelete: 1
      }).then(function (res) {
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            that.normalList = res.data.data;
          } else {
            that.normalList = [];
          }
        }
      });
    },
    // 主要用于新增子节点后的页面更新，如果二级节点修改了父节点 此更新没有用
    load: function load(tree, treeNode, resolve) {
      var _this = this;
      // 存根节点 和二级节点
      if (tree.areaType < 3) {
        var pId = tree.pId;
        // new 了一个map对象
        this.maps.set(pId, {
          tree: tree,
          treeNode: treeNode,
          resolve: resolve
        }); // 将当前选中节点数据存储到maps中
      }
      // 调接口获取下一级节点数据
      (0, _dealer.nextArea)({
        parentId: tree.pId,
        isDelete: this.searchForm.isDelete
      }).then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
          resolve([]);
        } else {
          resolve(res.data.data);
        }
      }).catch(function () {
        resolve([]);
      });
    },
    getList: function getList(flag) {
      var that = this;
      this.authId('searchbtn', this.$route.meta.authList);
      that.listLoading = true;
      var submitData = (0, _utils.filterKeyNull)(that.searchForm);
      // dealerAreaList(submitData)
      (0, _dealer.newlist)(submitData).then(function (res) {
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            that.tableData = res.data.data;
          } else {
            that.tableData = [];
          }
          that.getAreaAll();
          that.listLoading = false;
        }
      }).catch(function (res) {
        that.listLoading = false;
      });
    },
    resetForm: function resetForm() {
      var that = this;
      that.searchForm.areaCode = '';
      that.searchForm.areaName = '';
      that.searchForm.isDelete = '';
      that.getList();
    },
    handleClose: function handleClose() {
      this.ruleForm = {
        areaCode: '',
        areaName: '',
        parentId: '',
        isDelete: 1
      };
      this.$refs.ruleForm.resetFields();
      this.dialogVisible = false;
      this.authId('searchbtn', this.$route.meta.authList);
    },
    transform: function transform(list, itemId, pid) {
      var group = {};
      list.forEach(function (item) {
        var parentid = item[pid];
        if (!group.hasOwnProperty(parentid)) {
          group[parentid] = [];
        }
        group[parentid].push(item);
      });
      list.forEach(function (item) {
        var id = item[itemId];
        if (group.hasOwnProperty(id)) {
          if (!item[pid]) {
            item.child = group[id];
          }
        }
      });
      return group;
    },
    filterparentList: function filterparentList(val) {
      var that = this;
      return this.normalList.filter(function (number) {
        if (number.pId == val) {
          that.ruleForm.parentId.unshift(val);
          if (number.parentId) {
            that.filterparentList(number.parentId);
          }
        }
      });
    },
    add: function add(row) {
      this.title = '添加行政区划';
      this.ruleForm.parentId = [row.pId];
      var list = JSON.parse(JSON.stringify(this.normalList));
      if (row.parentId) {
        this.filterparentList(row.parentId);
      }
      var arr = this.transform(list, 'pId', 'parentId');
      this.areaSelList = arr['null'];
      this.dialogVisible = true;
    },
    edit: function edit(row) {
      var _this2 = this;
      this.title = '编辑行政区划';
      this.editData.parentId = row.parentId;
      this.editData.pId = row.pId;
      this.ruleForm.pId = row.pId;
      this.ruleForm.areaCode = row.areaCode;
      this.ruleForm.areaName = row.areaName;
      this.ruleForm.isDelete = row.isDelete;
      this.ruleForm.parentId = [];
      if (row.parentId) {
        this.filterparentList(row.parentId);
      }
      var list = JSON.parse(JSON.stringify(this.normalList));
      list.forEach(function (item) {
        item.children = [];
        if (item.pId == _this2.ruleForm.pId) {
          item.disabled = true;
        }
      });
      var arr = this.transform(list, 'pId', 'parentId');
      this.areaSelList = arr['null'];
      this.dialogVisible = true;
    },
    // 二级修改了父节点后 手动删除当前，参数item传的当前行
    deleteLazyTableItem: function deleteLazyTableItem(xxx, yyy) {
      var item = {
        pId: xxx,
        parentId: yyy
      };
      var store = this.$refs.tableData.store;

      // return false
      if (item.parentId != -1) {
        /*当前的父节点*/
        // data表示当前行的数据
        var parentRow = store.states.data.find(function (child) {
          return child.pId == item.parentId;
        });
        if (!parentRow) {
          var keys = Object.keys(store.states.lazyTreeNodeMap);
          for (var i = 0; i < keys.length; i++) {
            parentRow = store.states.lazyTreeNodeMap[keys[i]].find(function (child) {
              return child.pId == item.parentId;
            });
            if (parentRow) {
              break;
            }
          }
        }
        parentRow.hasChildren--;
        // 从上级节点，到根节点中找到 上级节点
        var parent = store.states.lazyTreeNodeMap[item.parentId];
        // 然后从当前直属的这个上级删掉自身这个子节点
        var index = parent.findIndex(function (child) {
          return child.pId == item.pId;
        });
        parent.splice(index, 1);
      } else {
        var _parent = store.states.data;
        var _index = _parent.findIndex(function (child) {
          return child.pId == item.pId;
        });
        _parent.splice(_index, 1);
      }
    },
    // 添加数据放到最外层的数据中去
    addOuterTableItem: function addOuterTableItem(newItemData) {
      var store = this.$refs.tableData.store;
      store.states.data.push(newItemData);
    },
    // 查找新的父节点及数据后，添加子节点
    findNewParent: function findNewParent(parentId, newItemData) {
      var store = this.$refs.tableData.store;
      // 首先在最外层里面找
      var parentRow = store.states.data.find(function (item) {
        return item.pId === parentId;
      });
      if (parentRow) {
        parentRow.hasChildren++;
      }
      // 不在最外层
      else {
        var keys = Object.keys(store.states.lazyTreeNodeMap);
        for (var i = 0; i < keys.length; i++) {
          parentRow = store.states.lazyTreeNodeMap[keys[i]].find(function (item) {
            return item.pId == parentId;
          });
          if (parentRow) {
            break;
          }
        }
        if (parentRow) {
          parentRow.hasChildren++;
        }
      }
      var parentTreeNode = store.states.treeData[parentId];
      this.addLazyTableItemToParent(parentTreeNode, parentId, newItemData);
    },
    // 把数据加到父级节点上去
    addLazyTableItemToParent: function addLazyTableItemToParent(parentTreeNode, parentId, newItemData) {
      var store = this.$refs.tableData.store;
      // 如果在已加载过的节点的子节点中
      if (parentTreeNode) {
        // 如果该节点已加载
        if (parentTreeNode.loaded) {
          this.$set(store.states.lazyTreeNodeMap, parentId, [newItemData]);
        }
      } else {
        store.states.treeData[parentId] = {
          display: true,
          loading: false,
          loaded: false,
          expanded: false,
          children: [],
          lazy: true,
          level: 0
        };
      }
    },
    // 编辑成功后调用，需要判断有没有修改父节点
    updateData: function updateData(row, isNodeEditParent, submitData, pId, parentIdd) {
      var parentId = row.parentId;
      var newItemData = Object.assign({}, submitData);
      // 非根节点
      if (parentId) {
        var _this$maps$get = this.maps.get(parentId),
          tree = _this$maps$get.tree,
          treeNode = _this$maps$get.treeNode,
          resolve = _this$maps$get.resolve;
        if (isNodeEditParent) {
          //  如果修改了父节点，就要删除当前父节点
          this.deleteLazyTableItem(pId, parentIdd);
          this.findNewParent(parentId, newItemData);
        } else {
          //把当前节点的所有子节点清空
          this.$set(this.$refs.tableData.store.states.lazyTreeNodeMap,
          //obj
          parentId,
          //key
          [] //value
          );
          // this.load(tree, treeNode, resolve)
        }

        this.load(tree, treeNode, resolve);
      } else {
        //  清空了父级，点击确定→成为根节点
        this.deleteLazyTableItem(pId, parentIdd);
      }
    },
    submitForm: function submitForm() {
      var _this3 = this;
      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          _this3.btnLoading = true;
          var le = _this3.ruleForm.parentId.length - 1;
          var submitData = (0, _utils.filterKeyNull)(_this3.ruleForm);
          var parentId = _this3.ruleForm.parentId[le];
          submitData.parentId = _this3.ruleForm.parentId[le];
          // 判断二级节点是否修改了其父节点
          var nodeEditedParent = _this3.editData.parentId != submitData.parentId;
          if (_this3.ruleForm.pId) {
            _this3.authId('updatebtn', _this3.$route.meta.authList);
            (0, _dealer.dealerAreaUpdate)(submitData).then(function (res) {
              if (res.data.code != 200) {
                _this3.$message.error(res.data.msg);
              } else {
                _this3.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this3.handleClose();
                _this3.getList();
                _this3.updateData({
                  parentId: parentId
                }, nodeEditedParent, submitData, _this3.editData.pId, _this3.editData.parentId);
                sessionStorage.removeItem('areaList');
              }
              _this3.btnLoading = false;
            }).catch(function () {
              _this3.btnLoading = false;
            });
          } else {
            _this3.authId('addbtn', _this3.$route.meta.authList);
            (0, _dealer.dealerAreaInsert)(submitData).then(function (res) {
              if (res.data.code != 200) {
                _this3.$message.error(res.data.msg);
              } else {
                _this3.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this3.handleClose();
                _this3.getList();
                _this3.updateData({
                  parentId: parentId
                });
                sessionStorage.removeItem('areaList');
              }
              _this3.btnLoading = false;
            }).catch(function () {
              _this3.btnLoading = false;
            });
          }
        }
      });
    },
    setInUse: function setInUse(row) {
      var _this4 = this;
      this.$confirm('请确认是否启用?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this4.authId('startbtn', _this4.$route.meta.authList);
        (0, _dealer.dealerAreaEnableArea)({
          isDelete: 1,
          pId: row.pId
        }).then(function (res) {
          _this4.authId('searchbtn', _this4.$route.meta.authList);
          if (res.data.code != 200) {
            _this4.$message.error(res.data.msg);
          } else {
            _this4.$message({
              message: '操作成功',
              type: 'success'
            });
            sessionStorage.removeItem('areaList');
            _this4.getList();
            _this4.updateData(row);
          }
        });
      }).catch(function () {
        _this4.authId('searchbtn', _this4.$route.meta.authList);
      });
    },
    setUnUse: function setUnUse(row) {
      var _this5 = this;
      this.$confirm('请确认是否禁用?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this5.authId('stopbtn', _this5.$route.meta.authList);
        (0, _dealer.dealerAreaEnableArea)({
          isDelete: 0,
          pId: row.pId
        }).then(function (res) {
          _this5.authId('searchbtn', _this5.$route.meta.authList);
          if (res.data.code != 200) {
            _this5.$message.error(res.data.msg);
          } else {
            _this5.$message({
              message: '操作成功',
              type: 'success'
            });
            sessionStorage.removeItem('areaList');
            _this5.getList();
            _this5.updateData(row);
          }
        });
      }).catch(function () {
        _this5.authId('searchbtn', _this5.$route.meta.authList);
      });
    }
  }
};
exports.default = _default;