var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c("h3", { staticClass: "h-title" }, [_vm._v("生产计划单")]),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  ref: "searchForm",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.searchForm,
                    "status-icon": "",
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cols" },
                    [
                      _c(
                        "el-row",
                        {
                          staticStyle: { "margin-bottom": "0" },
                          attrs: { gutter: 20 },
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "taskNo",
                                    label: "生产任务单编码:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                      maxlength: "50",
                                    },
                                    model: {
                                      value: _vm.searchForm.taskNo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "taskNo",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "searchForm.taskNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "scheduleNo",
                                    label: "排产单编码:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                      maxlength: "50",
                                    },
                                    model: {
                                      value: _vm.searchForm.scheduleNo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "scheduleNo",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "searchForm.scheduleNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "orderNo",
                                    label: "生产计划单编码:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.searchForm.orderNo,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.searchForm, "orderNo", $$v)
                                      },
                                      expression: "searchForm.orderNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "orderType",
                                    label: "订单类型:",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        filterable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.searchForm.orderType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "orderType",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.orderType",
                                      },
                                    },
                                    _vm._l(_vm.orderList, function (item) {
                                      return _c("el-option", {
                                        key: item.figure,
                                        attrs: {
                                          label: item.newName,
                                          value: item.name,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "custCode",
                                    label: "客户编码:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.searchForm.custCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "custCode",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.custCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "custName",
                                    label: "客户名称:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.searchForm.custName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "custName",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.custName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "productCode",
                                    label: "产品编码:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.searchForm.productCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "productCode",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.productCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "productName",
                                    label: "产品名称:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.searchForm.productName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "productName",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.productName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "productSpec",
                                    label: "规格型号:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.searchForm.productSpec,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "productSpec",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.productSpec",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.handleShow || !_vm.formConfig.btnFollow,
                                  expression:
                                    "handleShow || !formConfig.btnFollow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "productionStatus",
                                    label: "生产状态:",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.searchForm.productionStatus,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "productionStatus",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "searchForm.productionStatus",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: {
                                          label: "超量生产",
                                          value: "超量生产",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          label: "等量生产",
                                          value: "等量生产",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          label: "未完成",
                                          value: "未完成",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.handleShow || !_vm.formConfig.btnFollow,
                                  expression:
                                    "handleShow || !formConfig.btnFollow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "taskStatus",
                                    label: "单据状态:",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.searchForm.taskStatus,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "taskStatus",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.taskStatus",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "待生产", value: 0 },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { label: "进行中", value: 1 },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { label: "已结单", value: 2 },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "startTime",
                                    label: "创建开始时间:",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "date",
                                      placeholder: "选择日期",
                                      "value-format": "yyyy-MM-dd",
                                      "picker-options": _vm.pickerOptions,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeData(true)
                                      },
                                    },
                                    model: {
                                      value: _vm.searchForm.startTime,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "startTime",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.startTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "endTime",
                                    label: "创建结束时间:",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "date",
                                      placeholder: "选择日期",
                                      "value-format": "yyyy-MM-dd",
                                      "picker-options": _vm.pickerOptions,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeData(false)
                                      },
                                    },
                                    model: {
                                      value: _vm.searchForm.endTime,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.searchForm, "endTime", $$v)
                                      },
                                      expression: "searchForm.endTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.formConfig.btnFollow
                            ? _c(
                                "el-col",
                                {
                                  staticClass: "head-btn-group",
                                  style: {
                                    width: _vm.formConfig.btnAreaHasShowMore,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { "label-width": "0" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v(" ")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.searchBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.searchBtn.icon,
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.searchForm.current = 1
                                              _vm.getList()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.searchBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.resetBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.resetBtn.icon,
                                          },
                                          on: { click: _vm.resetForm },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.resetBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              _vm.handleShow = !_vm.handleShow
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.handleShow ? "收起" : "展开"
                                              ) +
                                              "\n                "
                                          ),
                                          _c("i", {
                                            class: _vm.handleShow
                                              ? "el-icon-arrow-up"
                                              : "el-icon-arrow-down",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.formConfig.btnFollow
                ? _c("el-divider", { staticClass: "btnDivider" })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btnArea" },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "head-btn-group",
                      staticStyle: { width: "230px" },
                    },
                    [
                      _vm.hasAuth("addbtn", _vm.$route.meta.authList) ||
                      _vm.hasAuth("exportbtn", _vm.$route.meta.authList)
                        ? _c(
                            "div",
                            { staticClass: "head-btn-group" },
                            [
                              _vm.hasAuth("exportbtn", _vm.$route.meta.authList)
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-left": "20px" },
                                      attrs: {
                                        type: _vm.btnConfig.downLoadBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.downLoadBtn.icon,
                                        loading: _vm.downLoading,
                                      },
                                      on: { click: _vm.download },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.btnConfig.downLoadBtn.text)
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listtableLoading,
                      expression: "listtableLoading",
                    },
                  ],
                  key: _vm.tableKey,
                  staticStyle: { width: "100%" },
                  attrs: {
                    height: _vm.tabHeight,
                    data: _vm.tableData,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      align: _vm.tableConfig.align,
                      width: "60",
                      label: "序号",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "生产任务单编码",
                      "min-width": "150",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                      prop: "taskNo",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "排产单编码",
                      "min-width": "150",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                      prop: "scheduleNo",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "生产计划单编码",
                      "min-width": "150",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                      prop: "orderNo",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "订单类型",
                      prop: "orderTypeCode",
                      width: "180",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "客户编码",
                      prop: "custCode",
                      width: "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "客户名称",
                      prop: "channelName",
                      "min-width": "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品编码",
                      prop: "productCode",
                      width: "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品名称",
                      prop: "productName",
                      width: "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "规格型号",
                      prop: "productModel",
                      width: "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "系数",
                      prop: "coefficient",
                      width: "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "组数",
                      prop: "taskChestSum",
                      width: "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "任务数量（只）",
                      prop: "taskBottleSum",
                      width: "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "结单数量",
                      prop: "statementSum",
                      width: "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "生产回传数量",
                      prop: "uploadSum",
                      width: "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "有效生产量",
                      prop: "effectSum",
                      width: "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "生产状态",
                      prop: "productionStatus",
                      width: "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "车间",
                      prop: "shopName",
                      width: "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产线",
                      prop: "productLineName",
                      width: "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "渠道分类",
                      prop: "className",
                      width: "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "区域代码",
                      prop: "areaCode",
                      width: "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "单据状态",
                      prop: "taskStatus",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              row.taskStatus == "0"
                                ? _c("el-tag", { attrs: { type: "warning" } }, [
                                    _vm._v("待生产"),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              row.taskStatus == "1"
                                ? _c("el-tag", [_vm._v("进行中")])
                                : _vm._e(),
                              _vm._v(" "),
                              row.taskStatus == "2"
                                ? _c("el-tag", { attrs: { type: "success" } }, [
                                    _vm._v("已结单"),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3243458140
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "生产日期",
                      prop: "productionDate",
                      width: "180",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "创建时间",
                      prop: "createTime",
                      width: "180",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _vm.hasAuth("detailbtn", _vm.$route.meta.authList)
                    ? _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          fixed: "right",
                          align: _vm.tableConfig.align,
                          width: "220",
                          "class-name": "small-padding fixed-width",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm.hasAuth(
                                    "detailbtn",
                                    _vm.$route.meta.authList
                                  )
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.scanDetail(row)
                                            },
                                          },
                                        },
                                        [_vm._v("详情")]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1667728189
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  layout: "total,sizes,prev, pager, next, jumper",
                  total: _vm.total,
                  page: _vm.searchForm.current,
                  limit: _vm.searchForm.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.searchForm, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.searchForm, "size", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: _vm.title,
            visible: _vm.dialogVisible,
            "before-close": _vm.dialogClose,
            "custom-class": "customWidth",
            width: "60%!important",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.mloading,
                  expression: "mloading",
                },
              ],
              ref: "scanForm",
              staticClass: "info-items",
              attrs: {
                model: _vm.scanData,
                rules: _vm.rules,
                "label-position": "right",
                "label-width": "150px",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "生产工厂:", prop: "factoryCode" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "350px" },
                              attrs: { clearable: "", placeholder: "请选择" },
                              on: { change: _vm.setScanFactory },
                              model: {
                                value: _vm.scanData.factoryCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.scanData, "factoryCode", $$v)
                                },
                                expression: "scanData.factoryCode",
                              },
                            },
                            _vm._l(_vm.factoryList, function (item) {
                              return _c("el-option", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.isEnable == 1,
                                    expression: "item.isEnable == 1",
                                  },
                                ],
                                key: item.pid,
                                attrs: {
                                  label: item.factoryName,
                                  value: item.pid,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "物料编码:", prop: "factoryCode" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "350px" },
                              attrs: { clearable: "", placeholder: "请选择" },
                              on: { change: _vm.setScanFactory },
                              model: {
                                value: _vm.scanData.factoryCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.scanData, "factoryCode", $$v)
                                },
                                expression: "scanData.factoryCode",
                              },
                            },
                            _vm._l(_vm.factoryList, function (item) {
                              return _c("el-option", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.isEnable == 1,
                                    expression: "item.isEnable == 1",
                                  },
                                ],
                                key: item.pid,
                                attrs: {
                                  label: item.factoryName,
                                  value: item.pid,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "物料名称:", prop: "factoryCode" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "350px" },
                              attrs: { clearable: "", placeholder: "请选择" },
                              on: { change: _vm.setScanFactory },
                              model: {
                                value: _vm.scanData.factoryCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.scanData, "factoryCode", $$v)
                                },
                                expression: "scanData.factoryCode",
                              },
                            },
                            _vm._l(_vm.factoryList, function (item) {
                              return _c("el-option", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.isEnable == 1,
                                    expression: "item.isEnable == 1",
                                  },
                                ],
                                key: item.pid,
                                attrs: {
                                  label: item.factoryName,
                                  value: item.pid,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "规格型号:", prop: "factoryCode" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "350px" },
                              attrs: { clearable: "", placeholder: "请选择" },
                              on: { change: _vm.setScanFactory },
                              model: {
                                value: _vm.scanData.factoryCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.scanData, "factoryCode", $$v)
                                },
                                expression: "scanData.factoryCode",
                              },
                            },
                            _vm._l(_vm.factoryList, function (item) {
                              return _c("el-option", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.isEnable == 1,
                                    expression: "item.isEnable == 1",
                                  },
                                ],
                                key: item.pid,
                                attrs: {
                                  label: item.factoryName,
                                  value: item.pid,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "系数:", prop: "factoryCode" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "350px" },
                              attrs: { clearable: "", placeholder: "请选择" },
                              on: { change: _vm.setScanFactory },
                              model: {
                                value: _vm.scanData.factoryCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.scanData, "factoryCode", $$v)
                                },
                                expression: "scanData.factoryCode",
                              },
                            },
                            _vm._l(_vm.factoryList, function (item) {
                              return _c("el-option", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.isEnable == 1,
                                    expression: "item.isEnable == 1",
                                  },
                                ],
                                key: item.pid,
                                attrs: {
                                  label: item.factoryName,
                                  value: item.pid,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "组数:", prop: "orderNo" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              value: _vm.scanData.orderNo,
                              readonly: true,
                            },
                            on: {
                              input: function (e) {
                                return (_vm.scanData.orderNo = _vm.validSe(e))
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "计划数量:", prop: "orderNo" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              value: _vm.scanData.orderNo,
                              readonly: true,
                            },
                            on: {
                              input: function (e) {
                                return (_vm.scanData.orderNo = _vm.validSe(e))
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "质保政策:",
                            prop: "productLineCode",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "350px" },
                              attrs: {
                                clearable: "",
                                placeholder: "请选择",
                                loading: _vm.scanlineListLoading,
                              },
                              model: {
                                value: _vm.scanData.productLineCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.scanData, "productLineCode", $$v)
                                },
                                expression: "scanData.productLineCode",
                              },
                            },
                            _vm._l(_vm.scanlineList, function (item) {
                              return _c("el-option", {
                                key: item.pId,
                                attrs: {
                                  label: item.factoryName,
                                  value: item.pId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "负责人:", prop: "director" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "350px" },
                            attrs: { maxlength: "20" },
                            model: {
                              value: _vm.scanData.director,
                              callback: function ($$v) {
                                _vm.$set(_vm.scanData, "director", $$v)
                              },
                              expression: "scanData.director",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "产地:", prop: "originPlace" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              maxlength: "30",
                              placeholder: "请填写，例如：新疆伊犁",
                            },
                            model: {
                              value: _vm.scanData.originPlace,
                              callback: function ($$v) {
                                _vm.$set(_vm.scanData, "originPlace", $$v)
                              },
                              expression: "scanData.originPlace",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "talbe-item",
                          attrs: { label: "产品明细:", prop: "materialCode" },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { "margin-left": "12px" },
                              attrs: { type: "primary", size: "mini" },
                              on: { click: _vm.openSel },
                            },
                            [_vm._v("添加产品")]
                          ),
                          _vm._v(" "),
                          _vm.proList.length > 0
                            ? _c(
                                "el-table",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.listLoading,
                                      expression: "listLoading",
                                    },
                                  ],
                                  key: _vm.tableKey,
                                  staticStyle: {
                                    width: "100%",
                                    "margin-top": "1px",
                                  },
                                  attrs: {
                                    data: _vm.proList,
                                    border: _vm.tableConfig.border,
                                    stripe: _vm.tableConfig.stripe,
                                    fit: "",
                                    "highlight-current-row": "",
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "序号",
                                      type: "index",
                                      width: "60",
                                      align: _vm.tableConfig.align,
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "产品分类",
                                      prop: "productClassName",
                                      "min-width": "120",
                                      align: _vm.tableConfig.align,
                                      "show-overflow-tooltip": "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "产品编码",
                                      prop: "productCode",
                                      "min-width": "120",
                                      align: _vm.tableConfig.align,
                                      "show-overflow-tooltip": "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "产品名称",
                                      prop: "productName",
                                      "min-width": "120",
                                      align: _vm.tableConfig.align,
                                      "show-overflow-tooltip": "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "规格",
                                      prop: "productModel",
                                      "min-width": "80",
                                      align: _vm.tableConfig.align,
                                      "show-overflow-tooltip": "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "包装比例",
                                      prop: "packScaleExpression",
                                      align: _vm.tableConfig.align,
                                      "show-overflow-tooltip": "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "包装比例描述",
                                      prop: "packScaleDesc",
                                      align: _vm.tableConfig.align,
                                      width: "110",
                                      "show-overflow-tooltip": "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "el-table-column",
                                    {
                                      attrs: {
                                        label: "计划产量",
                                        align: _vm.tableConfig.align,
                                        "min-width": "120",
                                        "show-overflow-tooltip": "",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: { margin: "20px auto" },
                                          attrs: {
                                            label: "",
                                            "label-width": "0",
                                            prop: "planNum",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              value: _vm.scanData.planNum,
                                              maxlength: "6",
                                              min: "1",
                                            },
                                            on: { input: _vm.setSum },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "最小单位",
                                      align: _vm.tableConfig.align,
                                      width: "160",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var row = ref.row
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("minSum")(
                                                      _vm.scanData.planNum,
                                                      row.packScaleExpression
                                                    )
                                                  ) + _vm._s(row.minScaleName)
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      4239101872
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "操作",
                                      align: _vm.tableConfig.align,
                                      "min-width": "120",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var row = ref.row
                                            return [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.proDel(row)
                                                    },
                                                  },
                                                },
                                                [_vm._v("删除")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2388118391
                                    ),
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "所属库房", prop: "storehouseCode" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "350px" },
                              attrs: { clearable: "", placeholder: "请选择" },
                              model: {
                                value: _vm.scanData.storehouseCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.scanData, "storehouseCode", $$v)
                                },
                                expression: "scanData.storehouseCode",
                              },
                            },
                            _vm._l(_vm.storeSELList, function (item) {
                              return _c("el-option", {
                                key: item.pId,
                                attrs: {
                                  label: item.storeHouseName,
                                  value: item.pId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "生产批次:", prop: "batchNo" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "350px" },
                            attrs: { maxlength: "20" },
                            model: {
                              value: _vm.scanData.batchNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.scanData, "batchNo", $$v)
                              },
                              expression: "scanData.batchNo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注:", prop: "remarks" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              type: "textarea",
                              maxlength: "20",
                              rows: "3",
                            },
                            model: {
                              value: _vm.scanData.remarks,
                              callback: function ($$v) {
                                _vm.$set(_vm.scanData, "remarks", $$v)
                              },
                              expression: "scanData.remarks",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  false
                    ? _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "产品材料清单:",
                                prop: "checklistUrl",
                              },
                            },
                            [
                              _c("Upload-img", {
                                ref: "shImg",
                                attrs: {
                                  disabled: false,
                                  "limit-count": 1,
                                  text: "上传",
                                },
                                model: {
                                  value: _vm.scanData.checklistUrl,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.scanData, "checklistUrl", $$v)
                                  },
                                  expression: "scanData.checklistUrl",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "dialog-footer" },
                        [
                          _c("el-button", { on: { click: _vm.dialogClose } }, [
                            _vm._v("取 消"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.btnLoading,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.submitForm(1)
                                },
                              },
                            },
                            [_vm._v("确 定")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: "生产计划单-详情",
            visible: _vm.detailVisible,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.detailVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.mloading,
                  expression: "mloading",
                },
              ],
              ref: "scanData",
              staticClass: "info-items",
              attrs: {
                model: _vm.detailData,
                "label-position": "right",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-descriptions",
                { attrs: { title: "单据信息", column: 2, border: "" } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "生产任务单号" } },
                    [_vm._v(_vm._s(_vm.scanData.taskNo))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "排产单编码" } },
                    [_vm._v(_vm._s(_vm.scanData.scheduleNo))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "生产计划单编码" } },
                    [_vm._v(_vm._s(_vm.scanData.orderNo))]
                  ),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "订单类型" } }, [
                    _vm._v(_vm._s(_vm.scanData.orderTypeCode)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "客户编码" } }, [
                    _vm._v(_vm._s(_vm.scanData.custCode)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "客户名称" } }, [
                    _vm._v(_vm._s(_vm.scanData.channelName)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "渠道分类" } }, [
                    _vm._v(_vm._s(_vm.scanData.className)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "区域代码" } }, [
                    _vm._v(_vm._s(_vm.scanData.areaCode)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "销售部门" } }, [
                    _vm._v(_vm._s(_vm.scanData.orgName)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "销售大区" } }, [
                    _vm._v(_vm._s(_vm.scanData.regionName)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "产品编码" } }, [
                    _vm._v(_vm._s(_vm.scanData.productCode)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "产品名称" } }, [
                    _vm._v(_vm._s(_vm.scanData.productName)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "规格型号" } }, [
                    _vm._v(_vm._s(_vm.scanData.productModel)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "级别" } }, [
                    _vm._v(_vm._s(_vm.scanData.productGrade)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "系数" } }, [
                    _vm._v(_vm._s(_vm.scanData.coefficient)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "组数" } }, [
                    _vm._v(_vm._s(_vm.scanData.taskChestSum)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "任务数量" } }, [
                    _vm._v(_vm._s(_vm.scanData.taskBottleSum)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "单据状态" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.scanData.taskStatus == 0
                          ? "待生产"
                          : _vm.scanData.taskStatus == 1
                          ? "进行中"
                          : _vm.scanData.taskStatus == 2
                          ? "已结单"
                          : ""
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "许可证号" } }, [
                    _vm._v(_vm._s(_vm.scanData.lisence)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "当前工厂名称" } },
                    [_vm._v(_vm._s(_vm.scanData.factoryName))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "当前分工厂名称" } },
                    [_vm._v(_vm._s(_vm.scanData.subFactoryName))]
                  ),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "车间" } }, [
                    _vm._v(_vm._s(_vm.scanData.shopName)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "生产产线" } }, [
                    _vm._v(_vm._s(_vm.scanData.productLineName)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "班组" } }, [
                    _vm._v(_vm._s(_vm.scanData.teamName)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "采集箱码" } }, [
                    _vm._v(_vm._s(_vm.scanData.boxCollect)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "入库库房" } }, [
                    _vm._v(_vm._s(_vm.scanData.storeHouseName)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "包装比例" } }, [
                    _vm._v(_vm._s(_vm.scanData.packRatio)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "箱托比例" } }, [
                    _vm._v(_vm._s(_vm.scanData.boxRatio)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "极板厂" } }, [
                    _vm._v(_vm._s(_vm.scanData.polarPlateFactory)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "隔板厂" } }, [
                    _vm._v(_vm._s(_vm.scanData.bulkheadFactory)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "半成品分公司" } },
                    [_vm._v(_vm._s(_vm.scanData.semiProductCompany))]
                  ),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "生产批次" } }, [
                    _vm._v(_vm._s(_vm.scanData.batchNo)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "工艺信息" } }, [
                    _vm._v(_vm._s(_vm.scanData.technologyInfo)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "创建人" } }, [
                    _vm._v(_vm._s(_vm.scanData.operator)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "政府追溯" } }, [
                    _vm._v(_vm._s(_vm.scanData.recordName)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "政府追溯型号" } },
                    [_vm._v(_vm._s(_vm.scanData.traceCodeNo))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "生产日期（质保）" } },
                    [_vm._v(_vm._s(_vm.scanData.productionDate))]
                  ),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "创建时间" } }, [
                    _vm._v(_vm._s(_vm.scanData.createTime)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "结单时间" } }, [
                    _vm._v(_vm._s(_vm.scanData.statementTime)),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "itemTitle",
                  staticStyle: {
                    border: "1px solid #e8e8e8",
                    padding: "10px",
                    "margin-bottom": "-1px",
                    background: "#ececec",
                  },
                },
                [_vm._v("\n        质保政策\n      ")]
              ),
              _vm._v(" "),
              _c("div", { staticStyle: { "margin-top": "10px" } }, [
                _vm._v(
                  "\n        政策名称：" +
                    _vm._s(_vm.zhiInfo.policyName) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-descriptions",
                {
                  staticStyle: { "margin-top": "10px" },
                  attrs: { title: "", column: 3, border: "" },
                },
                [
                  _vm._l(_vm.zhiInfo.itemList, function (item, index) {
                    return _vm.zhiInfo.itemList
                      ? _c(
                          "el-descriptions-item",
                          {
                            key: index,
                            attrs: { label: item.detailDesc + "(月)" },
                          },
                          [_vm._v(_vm._s(item.period))]
                        )
                      : _vm._e()
                  }),
                  _vm._v(" "),
                  _vm.zhiInfo.itemList.length > 0
                    ? _c(
                        "el-descriptions-item",
                        { attrs: { label: "过保期(月)" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.zhiInfo.itemList[
                                _vm.zhiInfo.itemList.length - 1
                              ].endMonth
                                ? _vm.zhiInfo.itemList[
                                    _vm.zhiInfo.itemList.length - 1
                                  ].endMonth
                                : ""
                            )
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-descriptions",
                {
                  staticStyle: { "margin-top": "20px" },
                  attrs: { title: "计划单生产量", column: 2, border: "" },
                },
                [
                  _c("el-descriptions-item", { attrs: { label: "任务数量" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.scanData.taskBottleSum
                          ? _vm.scanData.taskBottleSum + "只"
                          : ""
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "生产回传数量" } },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.scanData.uploadSum
                            ? _vm.scanData.uploadSum + "只"
                            : ""
                        )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "结单数量" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.scanData.statementSum
                          ? _vm.scanData.statementSum + "只"
                          : ""
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "有效生产量" } },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.scanData.effectSum
                            ? _vm.scanData.effectSum + "只"
                            : ""
                        )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "异常数码量" } },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.scanData.exceptionSum
                            ? _vm.scanData.exceptionSum + "个"
                            : ""
                        )
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "itemTitle",
                  staticStyle: {
                    border: "1px solid #e8e8e8",
                    padding: "10px",
                    "margin-bottom": "-1px",
                    background: "#ececec",
                  },
                },
                [_vm._v("\n        备注\n      ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    "margin-top": "10px",
                    background: "rgba(249, 249, 249, 1)",
                    width: "100%",
                    "max-height": "200px",
                    padding: "20px 20px",
                    overflow: "auto",
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.scanData.remark ? _vm.scanData.remark : "") +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.isAudit
                ? _c(
                    "el-button",
                    {
                      attrs: { loading: _vm.btnLoading, type: "primary" },
                      on: { click: _vm.audit },
                    },
                    [_vm._v("提交")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("关 闭"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: "数码详情",
            visible: _vm.codeVisible,
            top: "3%",
            width: "70%",
            "before-close": _vm.codeClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.codeVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.codeListLoading,
                      expression: "codeListLoading",
                    },
                  ],
                  key: _vm.tableKey,
                  staticStyle: { width: "100%" },
                  attrs: {
                    "max-height": "500",
                    data: _vm.codeList,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    fit: "",
                    "highlight-current-row": "",
                    "row-key": "markCode",
                    lazy: "",
                    load: _vm.load,
                    "tree-props": _vm.treeProps,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      width: "120",
                      label: "序号",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [_c("span", [_vm._v(_vm._s(scope.row.seqNo))])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "层级",
                      prop: "id",
                      align: "left",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            !scope.row.packUnit
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("packLevel")(scope.row.packLevel)
                                    )
                                  ),
                                ])
                              : _c("span", [
                                  _vm._v(_vm._s(scope.row.packUnit) + "码"),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "数码",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.markCode))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作", align: _vm.tableConfig.align },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.inspectorGroupId
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.checkGroup(
                                          scope.row.inspectorGroupId
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("查看质检组")]
                                )
                              : _c(
                                  "el-button",
                                  { attrs: { type: "text", disabled: true } },
                                  [_vm._v("查看质检组")]
                                ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.codetotal > 0,
                    expression: "codetotal > 0",
                  },
                ],
                attrs: {
                  layout: "total,sizes,prev, pager, next, jumper",
                  total: _vm.codetotal,
                  page: _vm.detailForm.current,
                  limit: _vm.detailForm.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.detailForm, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.detailForm, "size", $event)
                  },
                  pagination: _vm.getCodeList,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.codeClose } }, [
                _vm._v("关闭"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: "查询",
            visible: _vm.groupShow,
            top: "3%",
            width: "400px!important",
          },
          on: {
            "update:visible": function ($event) {
              _vm.groupShow = $event
            },
          },
        },
        [
          _c("el-form", [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("质检组:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm._f("groupName")(_vm.groupId))),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.groupShow = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: "异常数码明细",
            visible: _vm.failVisible,
            "before-close": _vm.failClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.failVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.codeListLoading,
                      expression: "codeListLoading",
                    },
                  ],
                  key: _vm.tableKey,
                  staticStyle: { width: "100%" },
                  attrs: {
                    height: "400",
                    data: _vm.failList,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      width: "120",
                      label: "序号",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "数码",
                      prop: "id",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.markCode))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "层级",
                      prop: "id",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            !scope.row.packUnit
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("packLevel")(scope.row.packLevel)
                                    )
                                  ),
                                ])
                              : _c("span", [
                                  _vm._v(_vm._s(scope.row.packUnit) + "码"),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "解析状态",
                      prop: "id",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", { staticStyle: { color: "#E6A23C" } }, [
                              _vm._v(_vm._s(scope.row.failReason)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.failtotal > 0,
                    expression: "failtotal > 0",
                  },
                ],
                attrs: {
                  layout: "total,sizes,prev, pager, next, jumper",
                  total: _vm.failtotal,
                  page: _vm.failQuery.current,
                  limit: _vm.failQuery.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.failQuery, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.failQuery, "size", $event)
                  },
                  pagination: _vm.getFailList,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.failClose } }, [
                _vm._v("关闭"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("product-dialog", { ref: "selProduct", on: { change: _vm.setSel } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }