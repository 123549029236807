var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        { staticClass: "page-section" },
        [
          _c(
            "el-row",
            [
              _c(
                "el-form",
                {
                  ref: "formInline",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.formInline,
                    "status-icon": "",
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "活动名称：",
                                prop: "activityName",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "input-with-select",
                                attrs: { placeholder: "请输入活动名称" },
                                on: { blur: _vm.search },
                                model: {
                                  value: _vm.formInline.activityName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formInline,
                                      "activityName",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "formInline.activityName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "时间范围：" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "daterange",
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  "range-separator": "至",
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期",
                                  clearable: "",
                                },
                                on: { change: _vm.sj, blur: _vm.search },
                                model: {
                                  value: _vm.rangeTime,
                                  callback: function ($$v) {
                                    _vm.rangeTime = $$v
                                  },
                                  expression: "rangeTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { staticStyle: { "padding-top": "5px" } },
                            [
                              _c("div", { staticClass: "btnArea" }, [
                                _c(
                                  "div",
                                  { staticClass: "head-btn-group" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: _vm.btnConfig.searchBtn.type,
                                          size: _vm.btnConfig.size,
                                          icon: _vm.btnConfig.searchBtn.icon,
                                        },
                                        on: { click: _vm.search },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.btnConfig.searchBtn.text)
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: _vm.btnConfig.resetBtn.type,
                                          size: _vm.btnConfig.size,
                                          icon: _vm.btnConfig.resetBtn.icon,
                                        },
                                        on: { click: _vm.reset },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.btnConfig.resetBtn.text)
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                border: _vm.tableConfig.border,
                stripe: _vm.tableConfig.stripe,
                "highlight-current-row": true,
                fit: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "priority",
                  label: "奖项名称",
                  align: _vm.tableConfig.align,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm._f("priority")(scope.row.priority))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "prizeType",
                  label: "奖项类型",
                  align: _vm.tableConfig.align,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm._f("prizeType")(scope.row.prizeType))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "prizeName",
                  label: "奖品名称",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "prizePr",
                  label: "中奖概率/规则",
                  align: _vm.tableConfig.align,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [_vm._v(_vm._s(scope.row.prizePr) + "%")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "groupType",
                  label: "奖项归属",
                  align: _vm.tableConfig.align,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                scope.row.groupType == 1
                                  ? "基础奖项"
                                  : "特殊概率奖项"
                              ) +
                              "\n          "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "activityCount",
                  label: "对应活动数",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  fixed: "right",
                  label: "操作",
                  align: _vm.tableConfig.align,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "a-btn",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit(scope.row)
                              },
                            },
                          },
                          [_vm._v("活动下载")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("Pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                  page: _vm.params.current,
                  limit: _vm.params.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.params, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.params, "size", $event)
                  },
                  pagination: _vm.getPage,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "奖品修改",
            visible: _vm.dialogUpdate,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogUpdate = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "codeForm",
              attrs: { model: _vm.codeForm, "label-width": "80px" },
            },
            _vm._l(_vm.codeForm.prizeItemsList, function (item, index) {
              return _c(
                "div",
                { key: index },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "奖项:" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择奖品等级" },
                          model: {
                            value: item.priority,
                            callback: function ($$v) {
                              _vm.$set(item, "priority", $$v)
                            },
                            expression: "item.priority",
                          },
                        },
                        _vm._l(_vm.prioritys, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.name, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "类型:" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择奖品类型" },
                          on: {
                            change: function ($event) {
                              return _vm.getCurPrizeType($event, index)
                            },
                          },
                          model: {
                            value: item.prizeType,
                            callback: function ($$v) {
                              _vm.$set(item, "prizeType", $$v)
                            },
                            expression: "item.prizeType",
                          },
                        },
                        _vm._l(_vm.prizeTypes, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.name, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  item.prizeType != "0"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "奖品:" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择奖品" },
                              on: {
                                change: function ($event) {
                                  return _vm.doSum(index)
                                },
                              },
                              model: {
                                value: item.prizeId,
                                callback: function ($$v) {
                                  _vm.$set(item, "prizeId", $$v)
                                },
                                expression: "item.prizeId",
                              },
                            },
                            _vm._l(item.plist, function (obj, p) {
                              return _c("el-option", {
                                key: p,
                                attrs: {
                                  label: obj.prizeAlias,
                                  value: obj.prizeId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "概率:" } },
                    [
                      _c(
                        "el-input",
                        {
                          directives: [{ name: "Number", rawName: "v-Number" }],
                          attrs: { placeholder: "请输入概率", disabled: false },
                          on: {
                            blur: function ($event) {
                              return _vm.doSum(index)
                            },
                          },
                          model: {
                            value: item.prizePr,
                            callback: function ($$v) {
                              _vm.$set(item, "prizePr", $$v)
                            },
                            expression: "item.prizePr",
                          },
                        },
                        [_c("template", { slot: "append" }, [_vm._v("%")])],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "数量:" } },
                    [
                      _c("el-input", {
                        directives: [{ name: "Number", rawName: "v-Number" }],
                        attrs: { placeholder: "请输入数量" },
                        on: {
                          blur: function ($event) {
                            return _vm.doSum(index)
                          },
                        },
                        model: {
                          value: item.prizeNum,
                          callback: function ($$v) {
                            _vm.$set(item, "prizeNum", $$v)
                          },
                          expression: "item.prizeNum",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "预算:" } },
                    [
                      _c("el-input", {
                        directives: [{ name: "Number", rawName: "v-Number" }],
                        attrs: { readonly: "" },
                        model: {
                          value: item.prizeBudgetTotalAmount,
                          callback: function ($$v) {
                            _vm.$set(item, "prizeBudgetTotalAmount", $$v)
                          },
                          expression: "item.prizeBudgetTotalAmount",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.restPrize } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: _vm.disabled,
                    loading: _vm.btnLoading,
                  },
                  on: {
                    click: function ($event) {
                      _vm.dialogUpdate = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }