"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _factory = require("@/api/basic/factory");
var _pack = require("@/api/basic/pack");
var _store = require("@/api/store");
var _dictionary = require("@/api/systems/dictionary");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _schemeDialog = _interopRequireDefault(require("./schemeDialog.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var groupList = [];
var _default = {
  name: "StoreServicestoreManagerelationshipindex",
  components: {
    Pagination: _Pagination.default,
    schemeDialog: _schemeDialog.default
  },
  filters: {
    groupName: function groupName(status) {
      var t = "";
      groupList.forEach(function (item) {
        if (item.figure == status) {
          t = item.name;
        }
      });
      return t;
    },
    sourceText: function sourceText(status) {
      if (!String(status)) return "";
      var statusMap = {
        0: "ERP",
        1: "平台",
        2: "产线",
        3: "PDA"
      };
      return statusMap[status];
    },
    parseStatus: function parseStatus(status) {
      if (!String(status)) return "";
      var statusMap = {
        0: "等待解析",
        1: "解析成功",
        2: "解析失败"
      };
      return statusMap[status];
    },
    packLevel: function packLevel(status) {
      if (!String(status)) return "";
      var statusMap = {
        1: "盖",
        2: "罐",
        3: "盒",
        4: "箱",
        5: "托"
      };
      return statusMap[status];
    },
    packLevel1: function packLevel1(status) {
      if (!String(status)) return "未知";
      var statusMap = {
        1: "盖码",
        2: "瓶码",
        3: "盒码",
        4: "箱码",
        5: "托码"
      };
      return statusMap[status];
    },
    timeText: function timeText(status) {
      if (status == null || status == "") return "";
      return (0, _utils.parseTime)(status, "{y}-{m}-{d} {h}:{i}:{s}");
    },
    // 最小包装比例
    unitName: function unitName(arr) {
      if (arr == null && !arr) return "";
      var name = "";
      if (arr.length == 2) {
        name = arr[1].unitName;
      } else if (arr.length == 3) {
        name = arr[2].unitName;
      }
      return name;
    },
    // 最大包装比例
    unitName2: function unitName2(arr) {
      if (arr == null && !arr) return "";
      var name = "";
      if (arr.length) {
        name = arr[0].unitName;
      }
      return name;
    }
  },
  data: function data() {
    var validateBatch = function validateBatch(rule, value, callback) {
      console.log(value);
      // this.tableData.forEach(item => {
      //   if (item.batchNo == value && item.batchNo != item.batchNo) {
      //     callback(new Error("生产批次已存在"));
      //   }
      // });
      if (value == "") {
        callback(new Error("请输入生产批次"));
      } else {
        callback();
      }
    };
    return {
      treeProps: {
        children: "children",
        hasChildren: "hasChildren"
      },
      handleShow: false,
      groupShow: false,
      factoryList: [],
      workshopList: [],
      workshopListLoading: false,
      lineList: [],
      lineListLoading: false,
      teamList: [],
      teamListLoading: false,
      boxList: [],
      codeList: [],
      failList: [],
      dataList: [],
      searchForm: {
        batchNo: "",
        current: 1,
        endTime: "",
        factoryCode: "",
        inboundStatus: "",
        materialCode: "",
        materialName: "",
        orderNo: "",
        orderSource: "",
        orderStatus: "",
        pageNo: "",
        pageSize: "",
        productLineCode: "",
        shopCode: "",
        size: 20,
        startTime: "",
        storehouseCode: "",
        teamCode: "",
        encryptBachNo: "",
        storehouseName: "",
        uploadMode: "",
        hasScheme: "",
        schemeCodeName: ""
      },
      total: 0,
      codetotal: 0,
      failtotal: 0,
      tableData: [],
      tableKey: "01004",
      listLoading: false,
      codeListLoading: false,
      mals: "",
      title: "生产订单详情",
      failQuery: {
        orderNo: "",
        current: 1,
        size: 10
      },
      detailForm: {
        current: 1,
        size: 10,
        orderNo: ""
      },
      scanData: {},
      dialogVisible: false,
      detailVisible: false,
      failVisible: false,
      codeVisible: false,
      schemeDialogVisible: false,
      auditVisible: false,
      auditForm: {
        schemeAuditReason: "",
        schemeAuditStatus: ""
      },
      auditRule: {
        schemeAuditStatus: [{
          required: true,
          message: "请选择审核结果",
          trigger: "change"
        }]
      },
      mloading: false,
      downLoading: false,
      apis: [{
        authBtn: true,
        resourceCode: "SCDDCX"
      }],
      page2: false,
      tabHeight: "100%",
      groupId: "",
      type: "edit",
      materialId: "",
      rules: {
        batchNo: [{
          validator: validateBatch,
          trigger: "blur"
        }]
      },
      scheme: [],
      schemeName: "",
      schemeCode: ""
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    var _this = this;
    this.getList();
    this.getFactoryList(1, "", "factoryList");
    this.getFactoryList(2, "", "workshopList");
    this.getFactoryList(3, "", "lineList");
    this.getFactoryList(4, "", "teamList");
    this.getBoxList();
    (0, _dictionary.getItemByDataDictionaryByCode)({
      code: "FZ100"
    }).then(function (res) {
      if (res.data.code != 200) {
        _this.$message.error(res.data.msg);
      } else {
        if (res.data.data != null) {
          groupList = res.data.data;
        } else {
          groupList = [];
        }
      }
    });
  },
  methods: {
    mouseOver: function mouseOver() {
      this.$refs.arrow.style.color = "#409eff";
      this.$refs.arrowtest.style.color = "#409eff";
    },
    mouseLeave: function mouseLeave() {
      this.$refs.arrow.style.color = "#595959";
      this.$refs.arrowtest.style.color = "#595959";
    },
    checkGroup: function checkGroup(id) {
      this.groupId = id;
      this.groupShow = true;
    },
    /**
     * 获取工厂、车间、产线、班组
     * @param {*} val 1:工厂,2:车间,3:产线,4:班组
     * @param {*} pId
     * @param {*} list
     */
    getFactoryList: function getFactoryList(val, pId, list) {
      var _this2 = this;
      this[list + "Loading"] = true;
      (0, _factory.factoryInfoAllList)({
        type: val,
        isEnable: "",
        parentId: pId
      }).then(function (res) {
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this2[list] = res.data.data;
          } else {
            _this2[list] = [];
          }
        }
        _this2[list + "Loading"] = false;
      }).catch(function () {
        _this2[list + "Loading"] = false;
      });
    },
    getBoxList: function getBoxList() {
      var that = this;
      (0, _pack.supportBoxLevelList)({
        current: 1,
        size: 2147483647
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          if (response.data.data != null) {
            that.boxList = response.data.data.records;
          } else {
            that.boxList = [];
          }
        }
      });
    },
    resetForm: function resetForm(formName) {
      var that = this;
      that.searchForm.current = 1;
      that.searchForm.size = 20;
      that.$refs["searchForm"].resetFields();
      this.getList();
    },
    getList: function getList() {
      var _this3 = this;
      this.authId("searchbtn", this.$route.meta.authList);
      var that = this;
      if (that.searchForm.startTime && !that.searchForm.endTime) {
        this.$message.error("请选择上传结束时间");
        return;
      }
      if (!that.searchForm.startTime && that.searchForm.endTime) {
        this.$message.error("请选择上传起始时间");
        return;
      }
      that.listLoading = true;
      (0, _store.gettaskList)(that.searchForm).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null || response.data.data != "") {
            that.tableData = response.data.data.records;
            that.total = parseInt(response.data.data.total);
            that.listLoading = false;
          } else {
            that.tableData = [];
            that.total = 0;
          }
        }
      }).catch(function () {
        _this3.listLoading = false;
      });
    },
    // 打开活动方案弹窗
    openScheme: function openScheme() {
      this.schemeDialogVisible = true;
    },
    // 设置活动方案
    changeScheme: function changeScheme(a) {
      console.log(a);
      this.scanData.schemeName = a.schemeName;
      this.scanData.schemeCode = a.schemeCode;
    },
    // 关闭活动方案弹窗
    closeChannel: function closeChannel() {
      this.schemeDialogVisible = false;
    },
    setFactory: function setFactory(val) {
      this.searchForm.shopCode = "";
      this.searchForm.productLineCode = "";
      this.searchForm.teamCode = "";
      this.lineList = [];
      this.teamList = [];
      if (!val) {
        this.workshopList = [];
      } else {
        this.getFactoryList(2, val, "workshopList");
      }
    },
    setShop: function setShop(val) {
      this.searchForm.productLineCode = "";
      this.searchForm.teamCode = "";
      if (!val) {
        this.lineList = [];
        this.teamList = [];
      } else {
        this.getFactoryList(3, val, "lineList");
        this.getFactoryList(4, val, "teamList");
      }
    },
    // 导出功能
    downLoad: function downLoad() {
      var _this4 = this;
      this.downLoading = true;
      (0, _store.relationexportExcel)({
        id: this.failQuery.id,
        packType: this.failQuery.packType
      }).then(function (res) {
        if (res.status != 200) {
          _this4.$message.error(res.data.msg);
        } else {
          var blob = new Blob([res.data], {
            type: "application/vnd.ms-excel"
          });
          var downloadElement = document.createElement("a");
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = "异常数码.xlsx";
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
        _this4.downLoading = false;
      }).catch(function () {
        _this4.authId("searchbtn", _this4.$route.meta.authList);
        _this4.downLoading = false;
      });
    },
    scanDetail: function scanDetail(row, type) {
      var _this5 = this;
      this.type = type;
      var that = this;
      this.authId("detailbtn", this.$route.meta.authList);
      // delete row.effectiveBottleSum
      // this.scanData = JSON.parse(JSON.stringify(row));
      this.detailVisible = true;
      this.mloading = true;
      (0, _store.quantityDetail)({
        orderNo: row.orderNo
      }).then(function (res) {
        if (res.status != 200) {
          _this5.$message.error(res.data.msg);
        } else {
          if (res.data.data != null || res.data.data != "") {
            _this5.scanData = res.data.data;
            delete row.effectiveBottleSum;
            Object.assign(_this5.scanData, row);
          } else {
            _this5.scanData = [];
          }
        }
        _this5.mloading = false;
      }).catch(function () {
        _this5.mloading = false;
      });
    },
    submitForm: function submitForm() {
      var _this6 = this;
      this.scanData.id = this.scanData.pId;
      console.log(this.scanData.pid, 123123);
      this.$refs["infoItems"].validate(function (valid) {
        console.log(valid, _this6.scanData.batchNo);
        if (valid) {
          _this6.mloading = true;
          _this6.authId("updatebtn", _this6.$route.meta.authList);
          (0, _store.taskOrderUpdateById)(_this6.scanData, _this6.schemeCode).then(function (res) {
            if (res.data.code != 200) {
              _this6.$message.error(res.data.msg);
            } else {
              _this6.$message.success("编辑成功");
              _this6.getList();
              _this6.handleClose();
            }
            _this6.mloading = false;
          }).catch(function () {
            _this6.mloading = false;
          });
        } else {
          return false;
        }
      });
    },
    DoAudit: function DoAudit(row) {
      var _this7 = this;
      (0, _store.gettaskList)({
        orderNo: row.orderNo
      }).then(function (res) {
        if (res.data.code == 200) {
          _this7.scanData = Object.assign(JSON.parse(JSON.stringify(row)), res.data.data);
          console.log(_this7.scanData.schemeAuditStatus, 12312);
          _this7.isAudit = true;
          _this7.auditVisible = true;
        } else {
          _this7.$message.error(res.data.msg);
        }
      });
    },
    audit: function audit() {
      var _this8 = this;
      console.log(this.auditForm, 6666);
      this.scanData.id = this.scanData.pId;
      this.scanData.auditStatus = this.scanData.schemeAuditStatus;
      console.log(this.scanData.auditStatus, 123123999);
      this.$refs["auditForm"].validate(function (valid) {
        if (valid) {
          _this8.mloading = true;
          var data = {
            id: _this8.scanData.id,
            auditStatus: _this8.scanData.schemeAuditStatus,
            auditReasons: _this8.scanData.schemeAuditReason
          };
          console.log(data, 123123456);
          (0, _store.taskOrderSchemeAudit)(data).then(function (res) {
            if (res.data.code != 200) {
              _this8.$message.error(res.data.msg);
            } else {
              _this8.$message.success("审核成功");
              _this8.getList();
              _this8.handleClose();
            }
            _this8.mloading = false;
          }).catch(function () {
            _this8.mloading = false;
          });
        } else {
          return false;
        }
      });
    },
    handleClose: function handleClose() {
      this.scanData = {};
      this.dataList = [];
      this.detailVisible = false;
      this.isAudit = false;
      this.auditVisible = false;
      this.authId("searchbtn", this.$route.meta.authList);
    },
    handClose: function handClose() {
      this.failQuery = {
        current: 1,
        size: 10,
        orderNo: ""
      };
      this.codetotal = 0;
      this.failtotal = 0;
      this.codeVisible = false;
      this.failVisible = false;
    },
    codeDetail: function codeDetail(orderNo, materialId) {
      this.detailForm.orderNo = orderNo;
      this.materialId = materialId;
      this.codeVisible = true;
      this.codeListLoading = true;
      this.getCodeList();
    },
    getCodeList: function getCodeList() {
      var _this9 = this;
      (0, _store.pageParentMarkCode)(this.detailForm).then(function (res) {
        if (res.data.code != 200) {
          _this9.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            if (res.data.data.records.length > 0) {
              res.data.data.records.forEach(function (item) {
                if (item.packLevel > 2) {
                  item.hasChildren = true;
                }
              });
            }
            _this9.codeList = res.data.data.records;
            _this9.codetotal = parseInt(res.data.data.total);
          } else {
            _this9.codeList = [];
            _this9.codetotal = 0;
          }
        }
        _this9.codeListLoading = false;
      }).catch(function () {
        _this9.codeListLoading = false;
      });
    },
    load: function load(tree, treeNode, resolve) {
      var _this10 = this;
      (0, _store.subMarkCode)({
        markCode: tree.markCode
      }).then(function (res) {
        if (res.data.code != 200) {
          _this10.$message.error(res.data.msg);
          resolve([]);
        } else {
          if (res.data.data.length > 0) {
            res.data.data.forEach(function (item) {
              if (item.packLevel > 2) {
                item.hasChildren = true;
              }
            });
          }
          resolve(res.data.data);
        }
      }).catch(function () {
        resolve([]);
      });
    },
    codeClose: function codeClose() {
      this.detailForm = {
        current: 1,
        size: 10,
        orderNo: ""
      };
      this.codeList = [];
      this.codetotal = 0;
      this.codeVisible = false;
    },
    failClose: function failClose() {
      this.failQuery = {
        orderNo: "",
        current: 1,
        size: 10
      };
      this.failList = [];
      this.failtotal = 0;
      this.failVisible = false;
    },
    showFailList: function showFailList(row) {
      // console.log(row);
      // this.failVisible = true;
      // this.codeListLoading = true;
      // this.failQuery.id = row.id;
      // this.failQuery.packType = row.packType;
      this.failVisible = true;
      this.codeListLoading = true;
      this.failQuery.orderNo = this.scanData.orderNo;
      this.getFList();
    },
    getFList: function getFList() {
      var _this11 = this;
      (0, _store.backFailMarkCodeList)(this.failQuery).then(function (res) {
        if (res.data.code != 200) {
          _this11.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            _this11.failList = res.data.data.records;
            _this11.failtotal = parseInt(res.data.data.total);
            _this11.listLoading = false;
          } else {
            _this11.failList = [];
            _this11.failtotal = 0;
          }
        }
        _this11.codeListLoading = false;
      }).catch(function () {
        _this11.codeListLoading = false;
      });
    }
  }
};
exports.default = _default;