var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c(
                "el-form",
                {
                  ref: "listQuery",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.listQuery,
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                    "status-icon": "",
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "公告范围:", prop: "orgId" } },
                            [
                              _c("treeselect", {
                                attrs: {
                                  multiple: false,
                                  "flatten-search-results": true,
                                  placeholder: "请选择",
                                  options: _vm.orgOptions,
                                },
                                model: {
                                  value: _vm.listQuery.orgId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.listQuery, "orgId", $$v)
                                  },
                                  expression: "listQuery.orgId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "公告标题:",
                                prop: "noticeTitle",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "filter-item",
                                staticStyle: { width: "100%" },
                                attrs: { clearable: "", placeholder: "请输入" },
                                model: {
                                  value: _vm.listQuery.noticeTitle,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.listQuery,
                                      "noticeTitle",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "listQuery.noticeTitle",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.handleShow,
                              expression: "handleShow",
                            },
                          ],
                          attrs: { span: 6 },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "公告内容:",
                                prop: "noticeContent",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "filter-item",
                                staticStyle: { width: "100%" },
                                attrs: { clearable: "", placeholder: "请输入" },
                                model: {
                                  value: _vm.listQuery.noticeContent,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.listQuery,
                                      "noticeContent",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "listQuery.noticeContent",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "noticeStatus",
                                label: "公告状态:",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择",
                                  },
                                  model: {
                                    value: _vm.listQuery.noticeStatus,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.listQuery,
                                        "noticeStatus",
                                        $$v
                                      )
                                    },
                                    expression: "listQuery.noticeStatus",
                                  },
                                },
                                [
                                  _c("el-option", { attrs: { value: "" } }, [
                                    _vm._v("全部"),
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(_vm.statusList, function (item) {
                                    return _c("el-option", {
                                      key: item.figure,
                                      attrs: {
                                        label: item.name,
                                        value: item.figure,
                                      },
                                    })
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.handleShow,
                              expression: "handleShow",
                            },
                          ],
                          attrs: { span: 6 },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "发布时间:", prop: "startTime" },
                            },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "date",
                                  placeholder: "选择日期",
                                  "value-format": "yyyy-MM-dd",
                                },
                                model: {
                                  value: _vm.listQuery.startTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.listQuery, "startTime", $$v)
                                  },
                                  expression: "listQuery.startTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.formConfig.btnFollow
                        ? _c(
                            "el-col",
                            {
                              staticClass: "head-btn-group",
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { "label-width": "0" } },
                                [
                                  _c(
                                    "div",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [_vm._v(" ")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.searchBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.searchBtn.icon,
                                      },
                                      on: { click: _vm.submitForm },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.btnConfig.searchBtn.text)
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.resetBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.resetBtn.icon,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.resetForm("listQuery")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.btnConfig.resetBtn.text)
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          _vm.handleShow = !_vm.handleShow
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.handleShow ? "收起" : "展开"
                                          ) +
                                          "\n              "
                                      ),
                                      _c("i", {
                                        class: _vm.handleShow
                                          ? "el-icon-arrow-up"
                                          : "el-icon-arrow-down",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.formConfig.btnFollow
                ? _c("el-divider", { staticClass: "btnDivider" })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btnArea" },
                [
                  !_vm.formConfig.btnFollow
                    ? _c(
                        "el-col",
                        {
                          staticClass: "head-btn-group",
                          staticStyle: { width: "230px" },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.searchBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.searchBtn.icon,
                              },
                              on: { click: _vm.submitForm },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.resetBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.resetBtn.icon,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.resetForm("listQuery")
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  _vm.handleShow = !_vm.handleShow
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.handleShow ? "收起" : "展开") +
                                  "\n          "
                              ),
                              _c("i", {
                                class: _vm.handleShow
                                  ? "el-icon-arrow-up"
                                  : "el-icon-arrow-down",
                              }),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAuth("addbtn", _vm.$route.meta.authList)
                    ? _c(
                        "div",
                        { staticClass: "head-btn-group fun-btn-group" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.addBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.addBtn.icon,
                              },
                              on: { click: _vm.add },
                            },
                            [_vm._v("创建公告")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    height: _vm.tabHeight,
                    data: _vm.list,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    fit: "",
                    "highlight-current-row": "",
                    "cell-class-name": _vm.cellClassName,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "公告类型",
                      prop: "noticeType",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                      width: "120",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                _vm._s(_vm._f("noticeType")(row.noticeType))
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2999962598
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "公告标题",
                      "min-width": "120",
                      "show-overflow-tooltip": "",
                      prop: "noticeTitle",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "公告内容",
                      prop: "noticeContent",
                      "min-width": "150",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "状态",
                      prop: "noticeStatus",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                _vm._s(_vm._f("noticeStatus")(row.noticeStatus))
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      428684518
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "创建人",
                      prop: "userName",
                      width: "120",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "发布时间",
                      prop: "startTime",
                      width: "165",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                _vm._s(
                                  row.noticeStatus != 1 ? row.startTime : ""
                                )
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2730536616
                    ),
                  }),
                  _vm._v(" "),
                  _vm.hasAuth("deletebtn", _vm.$route.meta.authList) ||
                  _vm.hasAuth("detailbtn", _vm.$route.meta.authList) ||
                  _vm.hasAuth("updatebtn", _vm.$route.meta.authList) ||
                  _vm.hasAuth("startbtn", _vm.$route.meta.authList)
                    ? _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          fixed: "right",
                          align: _vm.tableConfig.align,
                          width: "220",
                          "class-name": "small-padding fixed-width",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm.hasAuth(
                                    "detailbtn",
                                    _vm.$route.meta.authList
                                  )
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.detailShow(row.pid)
                                            },
                                          },
                                        },
                                        [_vm._v("详情")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.hasAuth(
                                    "updatebtn",
                                    _vm.$route.meta.authList
                                  ) &&
                                  (row.noticeStatus == 1 ||
                                    row.noticeStatus == 2)
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.update(row.pid)
                                            },
                                          },
                                        },
                                        [_vm._v("编辑")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.hasAuth(
                                    "deletebtn",
                                    _vm.$route.meta.authList
                                  ) && row.noticeStatus != 0
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.changStatue(row.pid, 0)
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.hasAuth(
                                    "startbtn",
                                    _vm.$route.meta.authList
                                  ) &&
                                  (row.noticeStatus == 1 ||
                                    row.noticeStatus == 2)
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.changStatue(row.pid, 3)
                                            },
                                          },
                                        },
                                        [_vm._v("发布")]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1983322011
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                  page: _vm.listQuery.current,
                  limit: _vm.listQuery.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "size", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: _vm.title,
            visible: _vm.dialogVisible,
            "before-close": _vm.dialogClose,
            "custom-class": "customWidth",
            width: "714px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.mloading,
                  expression: "mloading",
                },
              ],
              ref: "scanForm",
              staticClass: "info-items",
              staticStyle: { margin: "10px 48px 0" },
              attrs: {
                model: _vm.scanData,
                rules: _vm.rules,
                "label-position": "right",
                "label-width": "78px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "公告类型:", prop: "noticeType" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择" },
                      model: {
                        value: _vm.scanData.noticeType,
                        callback: function ($$v) {
                          _vm.$set(_vm.scanData, "noticeType", $$v)
                        },
                        expression: "scanData.noticeType",
                      },
                    },
                    _vm._l(_vm.typeList, function (item) {
                      return _c("el-option", {
                        key: item.pId,
                        attrs: { label: item.name, value: item.figure },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "公告标题:", prop: "noticeTitle" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "20", placeholder: "请输入" },
                    model: {
                      value: _vm.scanData.noticeTitle,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.scanData,
                          "noticeTitle",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "scanData.noticeTitle",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "公告范围:", prop: "orgIds" } },
                [
                  _c("treeselect", {
                    attrs: {
                      "value-consists-of": "BRANCH_PRIORITY",
                      multiple: true,
                      limit: 3,
                      "flatten-search-results": true,
                      placeholder: "请选择组织",
                      options: _vm.orgOptions,
                      "clear-all-text": "",
                    },
                    model: {
                      value: _vm.scanData.orgIds,
                      callback: function ($$v) {
                        _vm.$set(_vm.scanData, "orgIds", $$v)
                      },
                      expression: "scanData.orgIds",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-divider", { staticClass: "formDivider" }),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "公告内容:", prop: "noticeContent" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      maxlength: "200",
                      rows: "3",
                      placeholder: "请输入",
                    },
                    model: {
                      value: _vm.scanData.noticeContent,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.scanData,
                          "noticeContent",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "scanData.noticeContent",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "gradeStatus" } },
                [
                  _c("div", { attrs: { slot: "label" }, slot: "label" }, [
                    _vm._v(" "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.changeRadio },
                      model: {
                        value: _vm.scanData.gradeStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.scanData, "gradeStatus", $$v)
                        },
                        expression: "scanData.gradeStatus",
                      },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("立即发布"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { "margin-top": "20px" } },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("定时发布"),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.scanData.gradeStatus == 1
                ? _c(
                    "el-form-item",
                    { attrs: { prop: "startTime" } },
                    [
                      _c("div", { attrs: { slot: "label" }, slot: "label" }, [
                        _vm._v(" "),
                      ]),
                      _vm._v(" "),
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "datetime",
                          "value-format": "yyyy-MM-dd hh:mm:ss",
                          placeholder: "选择时间",
                          "picker-options": _vm.pickerOption,
                        },
                        model: {
                          value: _vm.scanData.startTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.scanData, "startTime", $$v)
                          },
                          expression: "scanData.startTime",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.dialogClose } }, [
                _vm._v("关闭"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.submit(1)
                    },
                  },
                },
                [_vm._v("保存草稿")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.btnLoading },
                  on: {
                    click: function ($event) {
                      _vm.scanData.gradeStatus == 0
                        ? _vm.beforeSubmit()
                        : _vm.submit()
                    },
                  },
                },
                [_vm._v("发布")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.detailVisible
        ? _c("noticeDetail", {
            attrs: {
              id: _vm.id,
              visible: _vm.detailVisible,
              "org-name-show": true,
            },
            on: { close: _vm.detailClose },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }