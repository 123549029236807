var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.dialogVisible,
        "before-close": _vm.handClose,
        width: "1200px !important",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.mloading,
              expression: "mloading",
            },
          ],
          ref: "scanForm",
          staticClass: "info-items",
          attrs: {
            model: _vm.scanData,
            rules: _vm.rules,
            "label-position": "right",
            "label-width": "150px",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "单号:", prop: "billNo" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "350px" },
                        attrs: {
                          clearable: "",
                          maxlength: "15",
                          readonly: "",
                          "show-word-limit": "",
                          disabled: _vm.disabled.num,
                        },
                        on: {
                          input: function (e) {
                            return (_vm.scanData.billNo = _vm.validSe(e))
                          },
                        },
                        model: {
                          value: _vm.scanData.billNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.scanData, "billNo", $$v)
                          },
                          expression: "scanData.billNo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "关联单号:", prop: "relateNo" } },
                    [
                      _c(
                        "el-input",
                        {
                          ref: "taskInput",
                          staticStyle: { width: "350px" },
                          attrs: {
                            readonly: "",
                            clearable: "",
                            maxlength: "20",
                            "show-word-limit": "",
                          },
                          on: {
                            input: function (e) {
                              return (_vm.scanData.relateNo = _vm.validSe(e))
                            },
                          },
                          model: {
                            value: _vm.scanData.relateNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.scanData, "relateNo", $$v)
                            },
                            expression: "scanData.relateNo",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: { slot: "append", icon: "el-icon-search" },
                            on: { click: _vm.getRelateNo },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  false
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "单据来源:", prop: "billSource" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "350px" },
                              attrs: { clearable: "", placeholder: "请选择" },
                              model: {
                                value: _vm.scanData.billSource,
                                callback: function ($$v) {
                                  _vm.$set(_vm.scanData, "billSource", $$v)
                                },
                                expression: "scanData.billSource",
                              },
                            },
                            _vm._l(_vm.sourceList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "is-required",
                      attrs: { label: "收货单位:", prop: "inOrgName" },
                    },
                    [
                      _c("el-input", {
                        ref: "shdw",
                        staticStyle: { width: "350px" },
                        attrs: { disabled: true },
                        model: {
                          value: _vm.inOrgName,
                          callback: function ($$v) {
                            _vm.inOrgName = $$v
                          },
                          expression: "inOrgName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  false
                    ? _c(
                        "el-form-item",
                        {
                          attrs: { label: "入库企业类型:", prop: "inOrgType" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "350px" },
                              attrs: { clearable: "", placeholder: "请选择" },
                              model: {
                                value: _vm.scanData.inOrgType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.scanData, "inOrgType", $$v)
                                },
                                expression: "scanData.inOrgType",
                              },
                            },
                            _vm._l(_vm.inOrgTypes, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "is-required",
                      attrs: { label: "发货单位:", prop: "outOrgName" },
                    },
                    [
                      _c("el-input", {
                        ref: "fhdw",
                        staticStyle: { width: "350px" },
                        attrs: { disabled: true },
                        model: {
                          value: _vm.outOrgName,
                          callback: function ($$v) {
                            _vm.outOrgName = $$v
                          },
                          expression: "outOrgName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "收货库房:", prop: "inStoreId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "350px" },
                          attrs: { clearable: "", placeholder: "请选择" },
                          on: { change: _vm.setScanFactory },
                          model: {
                            value: _vm.scanData.inStoreId,
                            callback: function ($$v) {
                              _vm.$set(_vm.scanData, "inStoreId", $$v)
                            },
                            expression: "scanData.inStoreId",
                          },
                        },
                        _vm._l(_vm.factoryList, function (item) {
                          return _c("el-option", {
                            key: item.pId,
                            attrs: {
                              label: item.storeHouseName,
                              value: item.pId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              false
                ? _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "出库企业类型:", prop: "outOrgType" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "350px" },
                              attrs: { clearable: "", placeholder: "请选择" },
                              model: {
                                value: _vm.scanData.outOrgType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.scanData, "outOrgType", $$v)
                                },
                                expression: "scanData.outOrgType",
                              },
                            },
                            _vm._l(_vm.outOrgTypes, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "is-required",
                      attrs: { label: "产品明细:" },
                    },
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.listLoading,
                              expression: "listLoading",
                            },
                          ],
                          key: _vm.tableKey,
                          staticStyle: { width: "100%", "margin-top": "1px" },
                          attrs: {
                            data: _vm.proList,
                            border: "",
                            fit: "",
                            "highlight-current-row": "",
                          },
                        },
                        [
                          false
                            ? _c("el-table-column", {
                                attrs: {
                                  label: "生产批次",
                                  prop: "prodBatchNo",
                                  align: "center",
                                  "show-overflow-tooltip": "",
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          false
                            ? _c("el-table-column", {
                                attrs: {
                                  label: "产品分类",
                                  prop: "prodClassName",
                                  align: "center",
                                  "show-overflow-tooltip": "",
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "产品编码",
                              prop: "productCode",
                              align: "center",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "产品名称",
                              prop: "productName",
                              align: "center",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "包装比例",
                              prop: "packRadioDesc",
                              align: "center",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "箱数",
                              prop: "planScanNum",
                              align: "center",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "盒数",
                              prop: "planScanAtoNum",
                              align: "center",
                              "show-overflow-tooltip": "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _vm.submitType != 3
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.btn_loading },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm(2)
                    },
                  },
                },
                [_vm._v("提 交")]
              )
            : _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.btn_loading },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm(3)
                    },
                  },
                },
                [_vm._v("提 交")]
              ),
          _vm._v(" "),
          _c("el-button", { on: { click: _vm.handleClose } }, [
            _vm._v("关 闭"),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("product-dialog", { ref: "selProduct", on: { change: _vm.setSel } }),
      _vm._v(" "),
      _c("task", {
        attrs: { "task-visible": _vm.taskShow },
        on: { dialogHide: _vm.hideTask, select: _vm.getTask },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }