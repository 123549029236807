"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.split");
var _productDialog = _interopRequireDefault(require("@/components/productDialog"));
var _index = require("@/api/warehouse/checkout/index");
var _select = require("@/api/warehouse/select");
var _stock = require("@/api/warehouse/stock/stock");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { getLevelById, supportBoxLevelList } from '@/api/basic/pack'
// import { parseTime, getAuthBtn, tableHeight } from '@/utils'
// var miniUnit = ''
var _default = {
  name: 'TableSuply',
  components: {
    productDialog: _productDialog.default
  },
  filters: {
    minSum: function minSum(sum, packScaleExpression, row) {
      if (!sum || !packScaleExpression) return '';
      var arr = packScaleExpression.split('*');
      var le = arr.length;
      if (le == 1) {
        return sum;
      }
      var num = sum * arr[le - 1];
      // var miniUnit = num
      row.sumPlanAto = num;
      return num;
    }
  },
  data: function data() {
    // const validatematerialCode = (rule, value, callback) => {
    //   if (
    //     this.proList.length == 0 ||
    //     this.proList == '' ||
    //     this.proList == null
    //   ) {
    //     callback(new Error('产品明细不能为空，请维护'))
    //   } else {
    //     callback()
    //   }
    // }
    return {
      title: '库存预警',
      pid: '',
      tableKey: 'pId',
      listLoading: false,
      mloading: false,
      dialogVisible: false,
      btn_loading: false,
      isUpdate: true,
      submitType: 1,
      scanData: {},
      scanData1: {
        storeCode: '',
        productParams: []
      },
      disabled: {
        num: false
      },
      factoryList: [],
      inOrgList: [],
      outOrgList: [],
      proList: [],
      buyerOrg: [],
      salerOrgList: [],
      inOrg: {},
      rules: {
        storeCode: [{
          required: true,
          message: '请选择出库库房',
          trigger: 'change'
        }]
      },
      proShow: false
    };
  },
  created: function created() {},
  mounted: function mounted() {
    // var that = this
  },
  methods: {
    show: function show(submitType, pid) {
      this.submitType = submitType;
      this.pid = pid;
      this.proList = [];
      this.inOrgList = [];
      this.outOrgList = [];
      this.disabled.num = false;
      this.mloading = true;
      if (this.$refs['scanForm'] !== undefined) {
        this.$refs['scanForm'].resetFields();
      }
      this.dialogVisible = true;
      this.getstoreHouseInfo();
      this.mloading = false;
    },
    upDateShow: function upDateShow(pid) {
      this.title = '库存预警';
      this.submitType = 2;
      this.mloading = true;
      if (this.$refs['scanForm'] !== undefined) {
        this.$refs['scanForm'].resetFields();
      }
      this.dialogVisible = true;
      this.getstoreHouseInfo();
      // 获取详情
      (0, _stock.getDetial)({
        pId: pid
      }).then(function (res) {
        console.log(res, '详情');
      });
      this.mloading = false;
    },
    getNumInfo: function getNumInfo(num) {
      var _this = this;
      (0, _index.NumInfo)(num).then(function (res) {
        _this.listLoading = false;
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
          return;
        } else {
          _this.scanData = JSON.parse(JSON.stringify(_this.scanData1));
          for (var i in _this.scanData) {
            _this.scanData[i] = res.data.data[i];
          }
        }
      }).catch(function () {});
    },
    // 已有产品转移数组定义
    getDetailArray: function getDetailArray(v) {
      this.proList.push({
        num: v.num
      });
    },
    proDel: function proDel(row, index) {
      // var that = this
      this.proList.splice(row, 1);
      return true;
    },
    submitForm: function submitForm(temp) {
      var _this2 = this;
      this.setDetail();
      this.$refs['scanForm'].validate(function (valid) {
        if (valid) {
          _this2.btn_loading = true;
          if (_this2.proList.length == 0) {
            _this2.$message.error('请添加产品！');
            _this2.btn_loading = false;
            return;
          }
          (0, _stock.saveConfig)(_this2.scanData).then(function (res) {
            _this2.btn_loading = false;
            if (res.data.code != 200) {
              _this2.$message.error(res.data.msg);
            } else {
              _this2.$message({
                message: '保存成功',
                type: 'success'
              });
              _this2.handleClose();
            }
          }).catch(function () {
            _this2.btn_loading = false;
          });
        }
      });
    },
    // 详情组合
    setDetail: function setDetail() {
      var that = this;
      that.scanData.productParams = [];
      if (this.proList.length > 0) {
        this.proList.forEach(function (v, index) {
          // var row = (Array(4).join('0') + (1 + index) * 10).slice(-4)
          that.scanData.productParams.push({
            prodCode: v.pId,
            vmax: v.vmax,
            vmin: v.vmin
          });
        });
      }
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
      this.$parent.getList();
    },
    // 获取库房下拉信信
    getstoreHouseInfo: function getstoreHouseInfo() {
      var _this3 = this;
      this.factoryList = [];
      (0, _select.storeHouseInfoList)().then(function (res) {
        if (res.data.code != 200) {
          _this3.$message.error(res.data.msg);
          return;
        } else {
          _this3.factoryList = res.data.data;
          console.log('this.factoryList', _this3.factoryList);
        }
      }).catch(function () {
        _this3.factoryList = [];
      });
    },
    // 选择产品
    openSel: function openSel() {
      this.proShow = true;
    },
    setSel: function setSel(val) {
      this.proList = val;
    },
    proClose: function proClose() {
      this.proShow = false;
    },
    setSum: function setSum(e) {
      console.log('----', e);
    }
  }
};
exports.default = _default;