"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.antiTendency = antiTendency;
exports.searchCity = searchCity;
exports.searchHour = searchHour;
exports.searchNum = searchNum;
exports.searchProvince = searchProvince;
var _request = _interopRequireDefault(require("@/utils/request"));
// 防伪统计api

// 防伪数据次数时段统计
function searchHour(params) {
  return (0, _request.default)({
    url: '/api-prevent/statistics/antifake/searchHour',
    method: 'get',
    params: params
  });
}
// 防伪数据次数
function searchNum(params) {
  return (0, _request.default)({
    url: '/api-prevent/statistics/antifake/searchNum',
    method: 'get',
    params: params
  });
}
// 防伪数据热力图
function searchProvince(params) {
  return (0, _request.default)({
    url: '/api-prevent/statistics/antifake/searchProvince',
    method: 'get',
    params: params
  });
}
// 防伪数据热力图
function searchCity(params) {
  return (0, _request.default)({
    url: '/api-prevent/statistics/antifake/searchCity',
    method: 'get',
    params: params
  });
}
// 来源分析
function antiTendency(params) {
  return (0, _request.default)({
    url: '/api-prevent/statistics/antifake/antiTendency',
    method: 'get',
    params: params
  });
}