var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: "新建赛事",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "show-close": false,
        width: "700px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticStyle: { "padding-right": "20px" },
          attrs: { "label-width": "120px", model: _vm.form, rules: _vm.rules },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "赛事名称:", prop: "gameName" } },
            [
              _c("el-input", {
                attrs: { disabled: _vm.isDisable, maxlength: "50" },
                model: {
                  value: _vm.form.gameName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "gameName", $$v)
                  },
                  expression: "form.gameName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "赛事ID:", prop: "matchId" } },
            [
              _c("el-input", {
                directives: [{ name: "Int", rawName: "v-Int" }],
                attrs: { disabled: _vm.isDisable, maxlength: "50" },
                model: {
                  value: _vm.form.matchId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "matchId", $$v)
                  },
                  expression: "form.matchId",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "备注:", prop: "remark" } },
            [
              _c("el-input", {
                attrs: { disabled: _vm.isDisable, maxlength: "100" },
                model: {
                  value: _vm.form.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "remark", $$v)
                  },
                  expression: "form.remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                disabled: _vm.isDisable,
                loading: _vm.btnLoading,
              },
              on: {
                click: function ($event) {
                  return _vm.handleSave("form")
                },
              },
            },
            [_vm._v("保存")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.handleCancel("form")
                },
              },
            },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }