"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.function.name");
var _param = require("@/api/chanelmange/param");
var _stockscan = require("@/api/chanelmange/stockscan");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _detailsHdfl = _interopRequireDefault(require("./detailsHdfl"));
var _utils = require("@/utils");
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: '',
  components: {
    Pagination: _Pagination.default,
    Treeselect: _vueTreeselect.default,
    detailsHdfl: _detailsHdfl.default
  },
  filters: {
    formatTime: function formatTime(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        return (0, _utils.parseTime)(value, '{y}-{m}-{d}');
      }
    },
    fliterState: function fliterState(value) {
      var name = '';
      var statusOption = [{
        name: '待提交',
        id: 0
      }, {
        name: '待审核',
        id: 1
      }, {
        name: '已生效',
        id: 2
      }, {
        name: '已终止',
        id: 3
      }];
      statusOption.forEach(function (item) {
        if (item.id == value) {
          name = item.name;
          return;
        }
      });
      return name;
    }
  },
  props: {
    dialogVisible: Boolean,
    rebateOrGift: Number
  },
  data: function data() {
    return {
      listQuery: {
        associatedPolicy: '',
        beginTime: '',
        endTime: '',
        orgList: [],
        productName: '',
        schemeCode: '',
        schemeName: '',
        schemeState: '',
        schemeType: 0,
        current: 1,
        size: 10
      },
      statusOption: [{
        name: '待提交',
        id: 0
      }, {
        name: '待审核',
        id: 1
      }, {
        name: '已生效',
        id: 2
      }, {
        name: '已终止',
        id: 3
      }],
      list: [],
      listLoading: false,
      total: 0,
      orgOptions: [],
      visible: false,
      pid: '',
      showCol: false,
      expandTxt: '展开'
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        var that = this;
        that.listQuery.associatedPolicy = that.associatedPolicy;
        that.getOrg();
        that.getList();
      }
    }
  },
  methods: {
    getList: function getList() {
      var that = this;
      var param = {};
      var current = that.listQuery.current;
      var size = that.listQuery.size;
      param.beginTime = that.listQuery.beginTime;
      param.endTime = that.listQuery.endTime;
      param.rebateOrGift = that.rebateOrGift;
      param.orgList = that.listQuery.orgList;
      param.productName = that.listQuery.productName;
      param.schemeCode = that.listQuery.schemeCode;
      param.schemeName = that.listQuery.schemeName;
      param.schemeState = that.listQuery.schemeState;
      param.schemeType = that.listQuery.schemeType;
      that.listLoading = true;
      (0, _param.getRebateSchemes)(current, size, param).then(function (res) {
        if (res.data.code == 200) {
          that.list = res.data.data.records;
          that.total = parseInt(res.data.data.total);
          setTimeout(function () {
            that.listLoading = false;
          }, 1 * 800);
        } else {
          that.$message.error(res.data.msg);
          that.listLoading = false;
          return;
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    getOrg: function getOrg() {
      var that = this;
      (0, _stockscan.getQueryOrg)().then(function (res) {
        if (res.data.code == 200) {
          var rData = res.data.data;
          var arr = [];
          if (rData.length >= 0) {
            rData.forEach(function (item) {
              var obj = {};
              obj.id = item.pid;
              obj.label = item.orgName;
              obj.pid = item.pid;
              obj.parentPid = item.parentPid;
              arr.push(obj);
            });
            that.orgOptions = (0, _utils.transTreeData)(arr, 'pid', 'parentPid', 'children');
          }
        }
      });
    },
    DoSearch: function DoSearch() {
      var that = this;
      that.listQuery = (0, _utils.filterKeyNull)(that.listQuery);
      that.listQuery.current = 1;
      that.listQuery.orgList = [];
      that.getList();
    },
    DoReset: function DoReset(formName) {
      var that = this;
      that.listQuery = {
        beginTime: '',
        endTime: '',
        orgList: [],
        productName: '',
        schemeCode: '',
        schemeName: '',
        schemeState: '',
        schemeType: 0,
        current: 1,
        size: 20
      };
      that.getList();
    },
    handleClose: function handleClose() {
      var that = this;
      that.$emit('close');
    },
    // 查看详情
    scan: function scan(data) {
      var that = this;
      that.visible = true;
      that.pid = data.pid;
    },
    closeComponent: function closeComponent() {
      var that = this;
      that.visible = false;
    },
    // 展开收起搜索条件
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    }
  }
};
exports.default = _default;