var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.insertVisible,
        "before-close": _vm.handleClose,
        width: "1290px !important",
      },
      on: {
        "update:visible": function ($event) {
          _vm.insertVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.mloading,
              expression: "mloading",
            },
          ],
          ref: "submitData",
          attrs: {
            rules: _vm.rules,
            model: _vm.submitData,
            "label-suffix": ":",
            "label-position": "left",
            "label-width": "90px",
          },
        },
        [
          _c(
            "div",
            { staticClass: "itemTitle", staticStyle: { margin: "0 0 15px 0" } },
            [_vm._v("关联政策")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "cols" },
            [
              _c(
                "el-row",
                {
                  staticStyle: { "margin-bottom": "0" },
                  attrs: { gutter: 10 },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { lg: 14, md: 14 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "政策名称", prop: "policyName" } },
                        [
                          _c("el-input", {
                            ref: "zcbm",
                            attrs: { readonly: "" },
                            model: {
                              value: _vm.policyName,
                              callback: function ($$v) {
                                _vm.policyName = $$v
                              },
                              expression: "policyName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { lg: 6, md: 12 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: { click: _vm.chosePolice },
                        },
                        [_vm._v("去选择")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: { click: _vm.scanPolicy },
                        },
                        [_vm._v("查看政策")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", {
            staticClass: "middleLine",
            staticStyle: { margin: "0 -20px" },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "itemTitle",
              staticStyle: { margin: "15px 0 15px 0" },
            },
            [_vm._v("基本信息")]
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticStyle: { "margin-bottom": "0" }, attrs: { gutter: 10 } },
            [
              false
                ? _c(
                    "el-col",
                    { attrs: { lg: 7, md: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "方案编号", prop: "schemeCode" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入", clearable: "" },
                            model: {
                              value: _vm.submitData.schemeCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.submitData, "schemeCode", $$v)
                              },
                              expression: "submitData.schemeCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { lg: 7, md: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "方案名称", prop: "schemeName" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入", clearable: "" },
                        model: {
                          value: _vm.submitData.schemeName,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitData, "schemeName", $$v)
                          },
                          expression: "submitData.schemeName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { lg: 7, md: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "开始时间", prop: "beginTime" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "datetime",
                          format: "yyyy-MM-dd HH:mm:ss",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          placeholder: "选择日期",
                          "default-time": "00:00:00",
                        },
                        model: {
                          value: _vm.submitData.beginTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitData, "beginTime", $$v)
                          },
                          expression: "submitData.beginTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { lg: 7, md: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "结束时间", prop: "endTime" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "datetime",
                          format: "yyyy-MM-dd HH:mm:ss",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          placeholder: "选择日期",
                          "default-time": "23:59:59",
                        },
                        model: {
                          value: _vm.submitData.endTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitData, "endTime", $$v)
                          },
                          expression: "submitData.endTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { lg: 3, md: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "is-required",
                      attrs: { label: "状态", "label-width": "60px" },
                    },
                    [
                      _vm.status == 0
                        ? _c("span", [_vm._v("待提交")])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.status == 1
                        ? _c("span", [_vm._v("待审核")])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.status == 2
                        ? _c("span", [_vm._v("已生效")])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.status == 3
                        ? _c("span", [_vm._v("已终止")])
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              false
                ? _c(
                    "el-col",
                    { attrs: { lg: 8, md: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "方案类型" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择", clearable: "" },
                              model: {
                                value: _vm.submitData.schemeState,
                                callback: function ($$v) {
                                  _vm.$set(_vm.submitData, "schemeState", $$v)
                                },
                                expression: "submitData.schemeState",
                              },
                            },
                            _vm._l(_vm.schemeTypes, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { lg: 21, md: 21 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "方案描述" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: 2,
                          placeholder: "请输入",
                          maxlength: "200",
                          "show-word-limit": "",
                          clearable: "",
                        },
                        model: {
                          value: _vm.submitData.schemeDescribe,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitData, "schemeDescribe", $$v)
                          },
                          expression: "submitData.schemeDescribe",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", {
            staticClass: "middleLine",
            staticStyle: { margin: "0 -20px" },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "itemTitle" }, [_vm._v("参与产品")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "phead" },
            [
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-plus", size: "mini" },
                  on: { click: _vm.addProducts },
                },
                [_vm._v("添加产品")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticClass: "detailTable",
              staticStyle: { width: "100%", "margin-top": "10px" },
              attrs: {
                data: _vm.proList,
                fit: "",
                "max-height": "300",
                "highlight-current-row": "",
                border: _vm.tableConfig.border,
                stripe: _vm.tableConfig.stripe,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "产品编码",
                  prop: "productCode",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "产品名称",
                  prop: "productName",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "产品分类",
                  prop: "productClass",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "返利计量单位",
                  prop: "measurementUnit",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.measurementUnit == 1
                          ? _c("span", [_vm._v("罐")])
                          : _vm._e(),
                        _vm._v(" "),
                        row.measurementUnit == 2
                          ? _c("span", [_vm._v("盒")])
                          : _vm._e(),
                        _vm._v(" "),
                        row.measurementUnit == 3
                          ? _c("span", [_vm._v("箱")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "返利倍数",
                  prop: "rebateProportion",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("el-input", {
                          attrs: { type: "number", min: 1, max: 10 },
                          model: {
                            value: row.rebateProportion,
                            callback: function ($$v) {
                              _vm.$set(row, "rebateProportion", $$v)
                            },
                            expression: "row.rebateProportion",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  align: _vm.tableConfig.align,
                  width: "80",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.delPro(row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "middleLine" }),
          _vm._v(" "),
          _c("div", { staticClass: "itemTitle" }, [_vm._v("返利范围")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "tree-contianer" },
            [
              _c("treeselect", {
                attrs: {
                  multiple: true,
                  limit: 6,
                  "flatten-search-results": true,
                  placeholder: "请选择...",
                  options: _vm.zzOptions,
                  "clear-all-text": "",
                },
                model: {
                  value: _vm.orgList,
                  callback: function ($$v) {
                    _vm.orgList = $$v
                  },
                  expression: "orgList",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "middleLine" }),
          _vm._v(" "),
          _c("div", { staticClass: "itemTitle" }, [_vm._v("返利配置")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "phead" },
            [
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-plus", size: "mini" },
                  on: { click: _vm.addRow },
                },
                [_vm._v("添加配置")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticClass: "detailTable",
              staticStyle: { width: "100%", "margin-top": "10px" },
              attrs: {
                data: _vm.configData,
                fit: "",
                "max-height": "300",
                "highlight-current-row": "",
                border: _vm.tableConfig.border,
                stripe: _vm.tableConfig.stripe,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "触发动作",
                  prop: "triggerAction",
                  width: "140",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: { clearable: "" },
                            on: {
                              change: function (val) {
                                _vm.changeSameAction(
                                  val,
                                  scope.row,
                                  scope.$index
                                )
                              },
                            },
                            model: {
                              value: scope.row.triggerAction,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "triggerAction", $$v)
                              },
                              expression: "scope.row.triggerAction",
                            },
                          },
                          _vm._l(_vm.triggerActions, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "渠道类型",
                  prop: "channelType",
                  width: "140",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: { clearable: "" },
                            on: {
                              change: function (val) {
                                _vm.changeStatus(val, row)
                              },
                            },
                            model: {
                              value: row.channelType,
                              callback: function ($$v) {
                                _vm.$set(row, "channelType", $$v)
                              },
                              expression: "row.channelType",
                            },
                          },
                          _vm._l(_vm.channelTypes, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "渠道分类",
                  prop: "gradeId",
                  width: "140",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: { clearable: "" },
                            model: {
                              value: row.classId,
                              callback: function ($$v) {
                                _vm.$set(row, "classId", $$v)
                              },
                              expression: "row.classId",
                            },
                          },
                          _vm._l(row.chanelClassList, function (item) {
                            return _c("el-option", {
                              key: item.pId,
                              attrs: { label: item.className, value: item.pId },
                            })
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "渠道等级",
                  prop: "gradeName",
                  width: "140",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: { clearable: "" },
                            model: {
                              value: row.gradeId,
                              callback: function ($$v) {
                                _vm.$set(row, "gradeId", $$v)
                              },
                              expression: "row.gradeId",
                            },
                          },
                          _vm._l(row.chanelLeveList, function (item) {
                            return _c("el-option", {
                              key: item.pId,
                              attrs: { label: item.gradeName, value: item.pId },
                            })
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              false
                ? _c("el-table-column", {
                    attrs: {
                      label: "返利条件",
                      prop: "rebatePrerequisite",
                      width: "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c(
                                "el-select",
                                {
                                  attrs: { clearable: "" },
                                  model: {
                                    value: row.rebatePrerequisite,
                                    callback: function ($$v) {
                                      _vm.$set(row, "rebatePrerequisite", $$v)
                                    },
                                    expression: "row.rebatePrerequisite",
                                  },
                                },
                                _vm._l(_vm.conditions, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      876625691
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "返利奖品数量(元/个)",
                  prop: "rebateProportion",
                  align: _vm.tableConfig.align,
                  width: "560",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-row",
                          {
                            staticStyle: { "margin-bottom": "0" },
                            attrs: { gutter: 10 },
                          },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-input",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: "请选择",
                                      readonly: "",
                                    },
                                    model: {
                                      value: scope.row.productName,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "productName", $$v)
                                      },
                                      expression: "scope.row.productName",
                                    },
                                  },
                                  [
                                    _c("el-button", {
                                      attrs: {
                                        slot: "append",
                                        icon: "el-icon-search",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showSinglePro(
                                            scope.row,
                                            scope.$index
                                          )
                                        },
                                      },
                                      slot: "append",
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _vm._v("\n              买\n              "),
                                _c("el-input", {
                                  staticClass: "table-input",
                                  attrs: { type: "number", min: 1 },
                                  on: {
                                    change: function (val) {
                                      _vm.getBuyInputNum(val, scope.row)
                                    },
                                  },
                                  model: {
                                    value: scope.row.buyNum,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "buyNum", $$v)
                                    },
                                    expression: "scope.row.buyNum",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _vm._v("\n              赠\n              "),
                                _c("el-input", {
                                  staticClass: "table-input",
                                  attrs: { type: "number", min: 1 },
                                  model: {
                                    value: scope.row.presentNum,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "presentNum", $$v)
                                    },
                                    expression: "scope.row.presentNum",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  fixed: "right",
                  align: _vm.tableConfig.align,
                  width: "140",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.DoCopy(scope.$index, scope.row)
                              },
                            },
                          },
                          [_vm._v("复制")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.DoDel(scope.$index, scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "btn-group-right" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submit("0", "submitData")
                    },
                  },
                },
                [_vm._v("保存")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submit("1", "submitData")
                    },
                  },
                },
                [_vm._v("提交")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "default", plain: "" },
                  on: { click: _vm.handleClose },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.proVisible
        ? _c("productMultiSelect", {
            ref: "selProduct",
            attrs: {
              "is-code": "1",
              "pro-sel-visible": _vm.proVisible,
              data: _vm.returnData,
            },
            on: {
              "update:data": function ($event) {
                _vm.returnData = $event
              },
              change: _vm.fetchPros,
              close: _vm.closePro,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("productChose", {
        ref: "singlePro",
        attrs: {
          "pro-sel-visible": _vm.singleProVisible,
          "action-type": _vm.actionType,
        },
        on: { close: _vm.closeSigle, change: _vm.getSinglePro },
      }),
      _vm._v(" "),
      _vm.PolicyDialog
        ? _c("policySelect", {
            attrs: { "pro-sel-visible": _vm.PolicyDialog },
            on: {
              change: _vm.getPolice,
              close: function ($event) {
                _vm.PolicyDialog = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("scanZc", {
        attrs: {
          "policy-code": _vm.submitData.policyCode,
          "detail-visible": _vm.policyVisible,
        },
        on: { close: _vm.closeScan },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }