"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkAccount = checkAccount;
exports.checkFirstLogin = checkFirstLogin;
exports.checkValidPhone = checkValidPhone;
exports.getCurrentUserDetail = getCurrentUserDetail;
exports.getDictionaryListByCode = getDictionaryListByCode;
exports.getInfo = getInfo;
exports.getMenuAuths = getMenuAuths;
exports.getSendCode = getSendCode;
exports.getUserByRoleCode = getUserByRoleCode;
exports.getUserDetailByLoginAccount = getUserDetailByLoginAccount;
exports.getemailCode = getemailCode;
exports.getsmsCode = getsmsCode;
exports.getvImg = getvImg;
exports.imgupload = imgupload;
exports.login = login;
exports.logout = logout;
exports.modPwd = modPwd;
exports.postemailCode = postemailCode;
exports.postsmsCode = postsmsCode;
exports.refreshToken = refreshToken;
exports.resetPwd = resetPwd;
exports.updatePwdByCheckCode = updatePwdByCheckCode;
var _request = _interopRequireDefault(require("@/utils/request"));
var _qs = _interopRequireDefault(require("qs"));
function login(data, validCode) {
  return (0, _request.default)({
    url: '/api-umm/access/authorize',
    headers: {
      'client-id': '5def87b2a61d4a5ea92b6ba44b9682cd',
      'client-secret': '076e19588d4a412da112965a99ef118f',
      'uuidKey': validCode.cookieId,
      'validCode': validCode.code,
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    method: 'post',
    type: '1',
    data: _qs.default.stringify(data)
  });
}
function getInfo(token) {
  return (0, _request.default)({
    url: '/api-user/resourceInfo/queryResourceByCurrentUser',
    method: 'get'
  });
}
function logout() {
  return (0, _request.default)({
    url: '/api-umm/remove/token',
    method: 'post'
  });
}
function getvImg() {
  return (0, _request.default)({
    url: '/api-umm/validata/code',
    method: 'get',
    type: '1'
  });
}
function checkAccount(param) {
  return (0, _request.default)({
    url: '/api-user/user-anon/checkLoginAccount',
    method: 'get',
    params: param
  });
}
function checkValidPhone(param) {
  return (0, _request.default)({
    url: '/api-umm/validata/check/' + param.mobile + '/' + param.validCode,
    method: 'get'
  });
}
function getSendCode(param) {
  return (0, _request.default)({
    url: '/api-umm/validata/smsCode/' + param.mobile + '/' + param.loginAccount,
    method: 'get'
  });
}
function resetPwd(param) {
  return (0, _request.default)({
    url: '/api-user/user-anon/restUserPassword',
    method: 'get',
    params: param
  });
}
function checkFirstLogin() {
  return (0, _request.default)({
    url: '/api-user/user-anon/checkUserIsFirstLogin',
    method: 'get'
  });
}
function modPwd(param) {
  return (0, _request.default)({
    url: '/api-user/userInfo/updatePwd',
    method: 'put',
    params: param
  });
}
function imgupload(data) {
  return (0, _request.default)({
    url: '/api-basic/jc/file/upload/uploadImg',
    method: 'post',
    data: data
  });
}
function getCurrentUserDetail(param) {
  return (0, _request.default)({
    url: '/api-user/userInfo/getCurrentUserDetail',
    method: 'get',
    param: param
  });
}
function getUserByRoleCode(params) {
  return (0, _request.default)({
    url: '/api-user/userInfo/getUserByRoleCode',
    method: 'get',
    params: params
  });
}
function getMenuAuths(param) {
  return (0, _request.default)({
    url: '/api-user/resourceInfo/hasMenuPrivilege',
    method: 'post',
    params: param
  });
}

// 刷新token
function refreshToken(headers) {
  return (0, _request.default)({
    url: '/api-umm/refresh/token',
    method: 'post',
    headers: headers
  });
}

// 根据字典编码获取字典项下拉列表
function getDictionaryListByCode(params) {
  return (0, _request.default)({
    url: '/api-user/dataDict/combox/queryItemByCode',
    method: 'get',
    params: params
  });
}

// 获取用户手机号或者邮箱
function getUserDetailByLoginAccount(params) {
  return (0, _request.default)({
    url: '/api-umm/validata/getUserDetailByLoginAccount',
    method: 'get',
    params: params,
    type: '1'
  });
}

// 新发送短信验证码
function getsmsCode(params, validCode) {
  return (0, _request.default)({
    url: '/api-umm/validata/smsCode',
    headers: {
      'uuidKey': validCode.cookieId,
      'validCode': validCode.code,
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    params: params,
    type: '1',
    method: 'get'
  });
}
// 新发送邮件验证码
function getemailCode(params, validCode) {
  return (0, _request.default)({
    url: '/api-umm/validata/emailCode',
    headers: {
      'uuidKey': validCode.cookieId,
      'validCode': validCode.code,
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    params: params,
    type: '1',
    method: 'get'
  });
}
// 校验短信验证码
function postsmsCode(data) {
  return (0, _request.default)({
    url: '/api-umm/validata/check/smsCode',
    method: 'post',
    data: data,
    type: '1'
  });
}
// 校验邮箱验证码
function postemailCode(data) {
  return (0, _request.default)({
    url: '/api-umm/validata/check/emailCode',
    method: 'post',
    data: data,
    type: '1'
  });
}
// 修改密码
function updatePwdByCheckCode(param) {
  return (0, _request.default)({
    url: '/api-user/userInfo/updatePwdByCheckCode',
    method: 'put',
    params: param,
    type: '1'
  });
}