"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.number.constructor");
var _auth = require("@/utils/auth");
var _chanelapp = require("@/api/chanelmange/chanelapp");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Pagination',
  props: {
    data: {
      default: '',
      required: true
    },
    text: {
      required: true,
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    limitCount: {
      default: 1,
      type: Number
    }
  },
  data: function data() {
    return {
      fileList: [],
      hideUpload: false,
      myHeaders: {
        Authorization: 'Bearer ' + (0, _auth.getToken)()
      }
    };
  },
  computed: {
    // 设置上传地址
    uploadUrl: function uploadUrl() {
      return process.env.VUE_APP_BASE_API + '/s/api/v1/api-basic/jc/file/upload/uploadImg';
    },
    src: {
      get: function get() {
        return this.data;
      },
      set: function set(val) {
        this.$emit('update:data', val);
        this.$emit('getdata', val);
      }
    },
    name: {
      get: function get() {
        return this.fileName;
      },
      set: function set(val) {
        this.$emit('update:fileName', val);
      }
    }
  },
  methods: {
    handleAvatarSuccess: function handleAvatarSuccess(res, file) {
      if (res.code * 1 == 200) {
        if (this.src) {
          this.src = this.src + ',' + res.data;
          this.name = this.file + ',' + res.data;
        } else {
          this.src = res.data;
          this.name = file.name;
        }
        this.$message.closeAll();
        this.$message({
          message: res.msg,
          type: 'success',
          duration: 3 * 1000
        });
      } else {
        this.$message.closeAll();
        this.$message({
          message: res.msg,
          type: 'error',
          duration: 3 * 1000
        });
      }
    },
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      var isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.closeAll();
        this.$message.error('上传图片大小不能超过 2MB!');
      }
      return isLt2M;
    },
    selectImg: function selectImg(file, fileList) {
      var _this = this;
      this.fileList = [];
      var isLt2M = this.beforeAvatarUpload(file);
      if (isLt2M) {
        console.log(file, fileList);
        console.log(this.fileList);
        var formData = new FormData();
        formData.append('file', file.raw);
        (0, _chanelapp.upLoadImg)(formData).then(function (res) {
          if (res.data.code == 200) {
            _this.src = res.data.data;
          } else {
            _this.$message.error(res.data.msg);
          }
        });
      }
    }
  }
};
exports.default = _default;