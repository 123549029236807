"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InfoDel = InfoDel;
exports.InfoInsert = InfoInsert;
exports.InfoUpdate = InfoUpdate;
exports.NumInfo = NumInfo;
exports.allotOrderAll = allotOrderAll;
exports.billAutoOut = billAutoOut;
exports.billTrans = billTrans;
exports.createNum = createNum;
exports.deleteTradeBillById = deleteTradeBillById;
exports.disableTradeBillById = disableTradeBillById;
exports.errorCodeListark = errorCodeListark;
exports.excelOut = excelOut;
exports.exportProductMarkCode = exportProductMarkCode;
exports.factoryInfoList = factoryInfoList;
exports.finishedOrder = finishedOrder;
exports.formProductOrder = formProductOrder;
exports.getDetailByCgNo = getDetailByCgNo;
exports.getManual = getManual;
exports.getPeoductionDetail = getPeoductionDetail;
exports.getProductDetail = getProductDetail;
exports.getProductionInfoByBatNo = getProductionInfoByBatNo;
exports.getProductionInfoList = getProductionInfoList;
exports.getProductsByNo = getProductsByNo;
exports.getRelateOutCode = getRelateOutCode;
exports.getflowInfo = getflowInfo;
exports.insertTradeBill = insertTradeBill;
exports.insertTradeBillByBatchNo = insertTradeBillByBatchNo;
exports.insertTradeBillByProduction = insertTradeBillByProduction;
exports.planOrderDetail = planOrderDetail;
exports.queryChildMark = queryChildMark;
exports.queryForPage = queryForPage;
exports.queryPage = queryPage;
exports.queryPrintByBillNo = queryPrintByBillNo;
exports.queryProdByTaskNo = queryProdByTaskNo;
exports.queryScanMark = queryScanMark;
exports.setCancelSplit = setCancelSplit;
exports.setDetailSplit = setDetailSplit;
exports.setProductDelete = setProductDelete;
exports.splitTradeBill = splitTradeBill;
exports.storeHouseInfoList = storeHouseInfoList;
exports.storeTypeInfoList = storeTypeInfoList;
exports.tradeTransLogPage = tradeTransLogPage;
exports.updateReceiveInfo = updateReceiveInfo;
var _request = _interopRequireDefault(require("@/utils/request"));
/*
101 生产入库 【关联生产任务单数据】参考生产任务单接口
201 返工出库
102 调货入库
202 调货出库
203 销售出库
103 退货入库
204 退货出库
105 其它入库
205 其它出库
*/

/**
 * 出库查询服务   勿动
 * @param {*} data
 */
function queryPage(data) {
  var current = data.current;
  var size = data.size;
  delete data.current;
  delete data.size;
  return (0, _request.default)({
    url: '/storage-center/tradeBill/queryForPage?current=' + current + '&size=' + size,
    method: 'post',
    data: data
  });
}

/**
 * 出入库查询服务
 * @param {*} query
 */
function queryForPage(current, size, data) {
  return (0, _request.default)({
    url: '/storage-center/tradeBill/queryForPage?current=' + current + '&size=' + size,
    method: 'post',
    data: data
  });
}

/**
 * 生成出入库单号
 * @param {*} billType
 */
function createNum(billType) {
  return (0, _request.default)({
    url: '/storage-center/tradeBill/generateTradeBillNo/' + billType,
    method: 'get'
  });
}

// 新增交货订单
function insertTradeBill(data) {
  return (0, _request.default)({
    url: '/storage-center/tradeBill/insertTradeBill',
    method: 'post',
    data: data
  });
}

/**
 * 查询单个出入库单信息
 * @param {*} data
 */
function queryProdByTaskNo(data) {
  return (0, _request.default)({
    url: "/storage-center/tradeBill/queryProdByTaskNo/".concat(data.relateType, "/").concat(data.relateNo),
    method: 'get'
  });
}

/**
 * @description 获取出入库详情
 * @param {string} param.billNo 	单据单号
 * @param {string} param.ncProductId 	nc产品id
 * @param {string} param.productId 	产品id
 */
// 获取生产入库详情
function getPeoductionDetail(param) {
  return (0, _request.default)({
    url: '/storage-center/tradeBill/queryByBillNo',
    method: 'get',
    params: param
  });
}

//  拆单
function splitTradeBill(data) {
  return (0, _request.default)({
    url: '/storage-center/tradeBill/splitTradeBill',
    method: 'post',
    data: data
  });
}
// ？？
/**
 * 查询单个出入库单信息
 * @param {*} num
 */
function NumInfo(num) {
  return (0, _request.default)({
    url: '/storage-center/bill/num/' + num,
    method: 'get'
  });
}

/**
 * 保存
 * @param {*} billType
 * @param {*} data
 */
function InfoInsert(data) {
  return (0, _request.default)({
    url: '/storage-center/tradeBill/insertTradeBill',
    method: 'post',
    data: data
  });
}

/**
 * 修改出入库单
 * @param {*} num
 * @param {*} data
 */
function InfoUpdate(data) {
  return (0, _request.default)({
    url: '/storage-center/tradeBill/updateTradeBill',
    method: 'put',
    data: data
  });
}

/**
 * 出入库单删除
 * @param {*} num
 */
function InfoDel(pid) {
  return (0, _request.default)({
    url: '/storage-center/tradeBill/deleteTradeBillById/' + pid,
    method: 'delete'
  });
}

/**
 * 出入库单-详情-产品清单
 * @param {*} num
 */
function getProductDetail(num) {
  return (0, _request.default)({
    url: '/storage-center/bill/detail/products/' + num,
    method: 'get'
  });
}

/**
 * 出入库单-详情-删除产品
 * @param {*} num
 */
function setProductDelete(num, numRow) {
  return (0, _request.default)({
    url: '/storage-center/bill/detail/product/delete/' + num + '/' + numRow,
    method: 'delete'
  });
}

/**
 * 出入库单-详情-拆单
 * @param {*} num
 */
function setDetailSplit(num, numRow, data) {
  return (0, _request.default)({
    url: '/storage-center/bill/detail/split/' + num + '/' + numRow,
    method: 'post',
    data: data
  });
}

/**
 * 出入库单-详情-取消拆单
 * @param {*} num
 */
function setCancelSplit(num, numRow) {
  return (0, _request.default)({
    url: '/storage-center/bill/detail/cancelSplit/' + num + '/' + numRow,
    method: 'post',
    data: {}
  });
}

/**
 * 查询审核记录
 * @param {*} num
 */
function getflowInfo(num) {
  return (0, _request.default)({
    url: '/storage-center/bill/confirm/flow/' + num,
    method: 'post',
    data: {}
  });
}

/**
 * 导出服务
 * @param {*} query
 */
function excelOut(data) {
  return (0, _request.default)({
    url: '/storage-center/tradeBill/exportForExcel',
    method: 'post',
    responseType: 'blob',
    data: data
  });
}

/**
 * 库房列表
 * @param {*} data
 */
function storeHouseInfoList(data) {
  return (0, _request.default)({
    url: '/api-basic/storeHouseInfo/list',
    method: 'post',
    data: data
  });
}

/**
 * 仓储字典
 * @param {*} type 4:单据来源，7：单据状态，103：工厂代码
 */
function storeTypeInfoList(type) {
  return (0, _request.default)({
    url: '/storage-center/common/lib/type/' + type,
    method: 'get'
  });
}

/**
 * 获取工厂档案下拉框
 */
function factoryInfoList(data) {
  return (0, _request.default)({
    url: '/api-basic/factoryInfo/selectList',
    method: 'post',
    data: data
  });
}

/**
 * 生产计划单详情
 * @param {*} data
 */
function planOrderDetail(params) {
  return (0, _request.default)({
    url: '/api-prod/ccProductOrder/planOrder/detail',
    method: 'get',
    params: params
  });
}

/**
 * 关联生产任务单
 * @param {*} data
 */
function formProductOrder(orderNum) {
  return (0, _request.default)({
    url: '/storage-center/bill/formProductOrder/' + orderNum,
    method: 'get'
  });
}

/**
 * 根据单号查询采购单详情
 * @param {*} params {cgNo:''}
 */
function getDetailByCgNo(data) {
  return (0, _request.default)({
    url: '/api-channel/pc/cg/detailByCgNo',
    method: 'post',
    params: data
  });
}

/**
 * 打印
 * @param {*} billNo
 */
function queryPrintByBillNo(billNo) {
  return (0, _request.default)({
    url: '/storage-center/tradeDetail/queryPrintByBillNo/' + billNo,
    method: 'get'
  });
}

/**
 *数码明细
 *
 * @export
 * @param {*} params
 * @returns
 */
function queryScanMark(params) {
  return (0, _request.default)({
    url: '/storage-center/tradeMarkRelation/queryScanMark',
    method: 'get',
    params: params
  });
}

/**
 *错误数码明细
 *

 */
function errorCodeListark(params) {
  return (0, _request.default)({
    url: '/storage-center/tradeBill/errorCodeList',
    method: 'post',
    params: params
  });
}

/**
 *根据父级数码获取子级数码
 *
 * @export
 * @param {*} params
 * @returns
 */
function queryChildMark(params) {
  return (0, _request.default)({
    url: '/storage-center/tradeMarkRelation/queryChildMark',
    method: 'get',
    params: params
  });
}

/**
 * @@
  出库关联单号选择
**/
function getRelateOutCode(current, size, data) {
  return (0, _request.default)({
    url: '/storage-center/tradeBill/queryOutOtherForPage?current=' + current + '&size=' + size,
    method: 'post',
    data: data
  });
}

/**
 * 根据关联单号查询产品
 */

function getProductsByNo(param) {
  return (0, _request.default)({
    url: '/storage-center/tradeBill/queryByBillNo/' + param,
    method: 'get'
  });
}

// 生产任务单-销售出库单查询
function getProductionInfoList(params) {
  return (0, _request.default)({
    url: '/storage-center/tradeBill/getProductionInfoList',
    method: 'get',
    params: params
  });
}
function insertTradeBillByProduction(params) {
  return (0, _request.default)({
    url: '/storage-center/tradeBill/insertTradeBillByProduction',
    method: 'post',
    data: params
  });
}
function updateReceiveInfo(params) {
  return (0, _request.default)({
    url: '/storage-center/tradeBill/updateReceiveInfo',
    method: 'get',
    params: params
  });
}
// 以生产批次号添加
// 生产批次号列表
function finishedOrder() {
  return (0, _request.default)({
    url: '/storage-center/productOrderApi/finishedOrder/query',
    method: 'post'
  });
}
// 获取批次号选择的
function getProductionInfoByBatNo(params) {
  return (0, _request.default)({
    url: '/storage-center/tradeBill/getProductionInfoByBatNo',
    method: 'get',
    params: params
  });
}
// 提交批次号
function insertTradeBillByBatchNo(params) {
  return (0, _request.default)({
    url: '/storage-center/tradeBill/insertTradeBillByBatchNo',
    method: 'post',
    data: params
  });
}
// 销售出库同步
function getManual(params) {
  return (0, _request.default)({
    url: '/storage-center/stroageKinDee/manual',
    method: 'get',
    params: params
  });
}
// 调拨出库同步
function allotOrderAll(params) {
  return (0, _request.default)({
    url: '/storage-center/stroageKinDee/allotOrderAll',
    method: 'get',
    params: params
  });
}

// 单据调拨日志分页
function tradeTransLogPage(data) {
  var current = data.current;
  var size = data.size;
  delete data.current;
  delete data.size;
  return (0, _request.default)({
    url: '/storage-center/tradeTransLog/queryForPage?current=' + current + '&size=' + size,
    method: 'post',
    data: data
  });
}

// 单据调拨
function billTrans(data) {
  return (0, _request.default)({
    url: '/storage-center/tradeBill/billTrans',
    method: 'post',
    data: data
  });
}

// 单据删除
function deleteTradeBillById(params) {
  return (0, _request.default)({
    url: "/storage-center/tradeBill/deleteTradeBillById/".concat(params),
    method: 'delete'
  });
}
// 单据删除
function disableTradeBillById(params) {
  return (0, _request.default)({
    url: "/storage-center/tradeBill/disableTradeBillById/".concat(params),
    method: 'put'
  });
}

// 数码明细导出
function exportProductMarkCode(data) {
  return (0, _request.default)({
    url: '/storage-center/tradeBill/exportProductMarkCode',
    method: 'post',
    responseType: 'blob',
    data: data
  });
}

// 生产入库单自动出库
function billAutoOut(data) {
  return (0, _request.default)({
    url: '/storage-center/tradeBill/billAutoOut',
    method: 'post',
    data: data
  });
}