"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.sort");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
var _instock = require("@/api/chanelmange/instock");
var _instockUnit = _interopRequireDefault(require("./components/instockUnit"));
var _productChose = _interopRequireDefault(require("./components/productChose"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Tgdan',
  components: {
    Pagination: _Pagination.default,
    Treeselect: _vueTreeselect.default,
    instockUnit: _instockUnit.default,
    productChose: _productChose.default
  },
  filters: {
    formatTime: function formatTime(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        return (0, _utils.parseTime)(value, '{y}-{m}-{d}');
      }
    },
    filterStatus: function filterStatus(val) {
      var status = {
        1: '',
        4: 'success'
      };
      return status[val];
    },
    typeClass: function typeClass(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          '1': 'primary',
          '2': 'primary',
          '3': 'primary',
          '4': 'success',
          '5': 'primary',
          '6': 'primary',
          '7': 'success',
          '8': 'warning',
          '9': 'danger',
          '10': 'info',
          '11': 'info'
        };
        return statusMap[value];
      }
    }
  },
  data: function data() {
    return {
      listQuery: {
        inStartDate: '',
        inEndDate: '',
        orgId: null,
        buyerId: '',
        buyerStr: '',
        sellerId: '',
        sellerStr: '',
        productId: '',
        productStr: '',
        businessType: '',
        billNo: '',
        orderNo: '',
        outStartDate: '',
        outEndDate: '',
        isCode: '',
        status: '',
        pageNo: 1,
        pageSize: 20
      },
      orgOptions: [],
      isCode: [{
        name: '是',
        code: '1'
      }, {
        name: '否',
        code: '0'
      }],
      states: [{
        name: '待提交',
        code: '1'
      }, {
        name: '全部入库',
        code: '4'
      }],
      sigleProduct: {
        productName: '',
        productCode: ''
      },
      unitSelect: {
        channelName: ''
      },
      businessOption: [],
      tableKey: 'cgrk',
      tabHeight: '100%',
      list: [],
      listLoading: false,
      total: 0,
      unitShow: false,
      unitType: '',
      productStyle: '',
      productShow: false,
      exportLoading: false,
      scanVisible: false,
      baseData: {},
      checklist: [],
      productlist: [],
      orderId: '',
      codeData: [],
      codeParam: {
        code: '',
        id: '',
        productId: '',
        productName: '',
        pageNo: 1,
        pageSize: 10
      },
      total2: 0,
      scanCodeVisible: false,
      active: 1,
      activeName: '',
      showCol: false,
      expandTxt: '展开',
      statuslist: [],
      statusStart: 'process',
      statusEnd: 'success',
      inNumAllPlan: 0,
      inAllNum: 0,
      cloading: false
    };
  },
  created: function created() {
    var that = this;
    that.getOrg();
    that.getList();
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    getList: function getList() {
      var that = this;
      that.listLoading = true;
      var param = {};
      param.inStartDate = that.listQuery.inStartDate;
      param.inEndDate = that.listQuery.inEndDate;
      param.orgId = that.listQuery.orgId;
      param.buyerId = that.listQuery.buyerId;
      param.sellerId = that.listQuery.sellerId;
      param.productId = that.listQuery.productId;
      param.billNo = that.listQuery.billNo;
      param.orderNo = that.listQuery.orderNo;
      param.outStartDate = that.listQuery.outStartDate;
      param.outEndDate = that.listQuery.outEndDate;
      param.isCode = that.listQuery.isCode;
      param.status = that.listQuery.status;
      param.pageNo = that.listQuery.pageNo;
      param.pageSize = that.listQuery.pageSize;
      (0, _instock.getDbRkList)(param).then(function (res) {
        if (res.data.code == 200) {
          that.list = res.data.data.records;
          that.total = parseInt(res.data.data.total);
          setTimeout(function () {
            that.listLoading = false;
          }, 1 * 800);
        } else {
          that.$message.error(res.data.msg);
          that.listLoading = false;
          return;
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    getOrg: function getOrg() {
      var that = this;
      (0, _instock.getQueryOrg)().then(function (res) {
        if (res.data.code == 200) {
          var rData = res.data.data;
          var arr = [];
          that.orgOriginData = rData;
          if (rData.length >= 0) {
            rData.forEach(function (item) {
              var obj = {};
              obj.id = item.pid;
              obj.label = item.orgName;
              obj.pid = item.pid;
              obj.parentPid = item.parentPid;
              arr.push(obj);
            });
            that.orgOptions = (0, _utils.transTreeData)(arr, 'pid', 'parentPid', 'children');
          }
        }
      });
    },
    clearSh: function clearSh() {
      var that = this;
      that.listQuery.buyerId = '';
    },
    clearFh: function clearFh() {
      var that = this;
      that.listQuery.sellerId = '';
    },
    clearPro: function clearPro() {
      var that = this;
      that.listQuery.productId = '';
    },
    DoSearch: function DoSearch() {
      var that = this;
      that.listQuery = (0, _utils.filterKeyNull)(that.listQuery);
      that.listQuery.pageNo = 1;
      that.getList();
      if (that.listQuery.orgId == '') {
        that.listQuery.orgId = null;
      }
    },
    DoReset: function DoReset(formName) {
      var that = this;
      that.listQuery.inStartDate = '';
      that.listQuery.inEndDate = '';
      that.listQuery.orgId = null;
      that.listQuery.buyerId = '';
      that.listQuery.buyerStr = '';
      that.listQuery.sellerId = '';
      that.listQuery.sellerStr = '';
      that.listQuery.productId = '';
      that.listQuery.productStr = '';
      that.listQuery.businessType = '';
      that.listQuery.billNo = '';
      that.listQuery.orderNo = '';
      that.listQuery.outStartDate = '';
      that.listQuery.outEndDate = '';
      that.listQuery.isCode = '';
      that.listQuery.status = '';
      that.listQuery.pageNo = 1;
      that.getList();
    },
    // 获取产品名称
    choseProName: function choseProName(val) {
      var that = this;
      that.productStyle = val;
      that.productShow = true;
    },
    hideProduct: function hideProduct() {
      var that = this;
      that.productShow = false;
    },
    getProduct: function getProduct(val) {
      var that = this;
      if (that.productStyle == 'search') {
        that.listQuery.productId = JSON.parse(val).pId;
        that.listQuery.productStr = JSON.parse(val).productName;
        that.sigleProduct.productCode = JSON.parse(val).productCode;
        that.sigleProduct.productName = JSON.parse(val).productName;
      }
      if (that.productStyle == 'codeScan') {
        that.codeParam.productName = JSON.parse(val).productName;
        that.codeParam.productId = JSON.parse(val).pId;
      }
      that.productShow = false;
    },
    // 获取单位
    getUnit: function getUnit(val) {
      var that = this;
      that.unitType = val;
      that.unitShow = true;
    },
    hideUnit: function hideUnit() {
      var that = this;
      that.unitShow = false;
    },
    getUalue: function getUalue(val) {
      var that = this;
      that.unitSelect.channelName = JSON.parse(val).channelName;
      if (that.unitType == 'in') {
        that.listQuery.buyerId = JSON.parse(val).pId;
        that.listQuery.buyerStr = JSON.parse(val).channelName;
      }
      if (that.unitType == 'out') {
        that.listQuery.sellerId = JSON.parse(val).pId;
        that.listQuery.sellerStr = JSON.parse(val).channelName;
      }
      that.unitShow = false;
    },
    // 导出文件流并下载
    DoExportAll: function DoExportAll() {
      var that = this;
      if (that.total > 50000) {
        that.$message.error('抱歉！您要导出的数据量超出了导出数据的上限，最多可导出50000条');
        return;
      }
      var param = {};
      param.inStartDate = that.listQuery.inStartDate;
      param.inEndDate = that.listQuery.inEndDate;
      param.orgId = that.listQuery.orgId;
      param.buyerId = that.listQuery.buyerId;
      param.sellerId = that.listQuery.sellerId;
      param.productId = that.listQuery.productId;
      param.billNo = that.listQuery.billNo;
      param.orderNo = that.listQuery.orderNo;
      param.outStartDate = that.listQuery.outStartDate;
      param.outEndDate = that.listQuery.outEndDate;
      param.isCode = that.listQuery.isCode;
      param.status = that.listQuery.status;
      param.pageNo = 1;
      param.pageSize = that.total;
      that.exportLoading = true;
      (0, _instock.exportDbRkList)(param).then(function (res) {
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '调拨入库列表.xlsx';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
        that.exportLoading = false;
      }).catch(function () {
        that.exportLoading = false;
      });
    },
    // 查看详情
    DoScan: function DoScan(data) {
      var that = this;
      var param = {};
      console.log(data);
      param.billNo = data.no;
      that.orderId = data.pid;
      (0, _instock.getDbDetail)(param).then(function (res) {
        if (res.data.code == 200) {
          if (res.data.data.base != null) {
            that.baseData = res.data.data.base;
            that.active = Number(that.baseData.orderStatus);
          }
          if (res.data.data.checklist != null) {
            that.checklist = res.data.data.checklist;
          }
          if (res.data.data.productlist != null) {
            that.productlist = res.data.data.productlist;
            that.inNumAllPlan = 0;
            that.inAllNum = 0;
            if (that.productlist.length > 0) {
              that.productlist.forEach(function (item) {
                that.inNumAllPlan += Number(item.orderNum);
                that.inAllNum += Number(item.inNum);
              });
            }
          }
          if (res.data.data.statuslist != null) {
            var asc = function asc(x, y) {
              return Number(x['type']) > Number(y['type']) ? 1 : -1;
            };
            var len = res.data.data.statuslist.length;
            if (len > 0) {
              that.statuslist = res.data.data.statuslist.sort(asc);
              if (len >= 2) {
                that.statusEnd = 'success';
                that.statusStart = 'success';
              }
              if (len <= 1) {
                that.statusEnd = 'wait';
                that.statusStart = 'process';
              }
            } else {
              that.statuslist = [];
              that.statusEnd = 'wait';
              that.statusStart = 'process';
            }
          }
          that.scanVisible = true;
        } else {
          that.baseData = {};
          that.checklist = [];
          that.productlist = [];
          that.$message.error('获取去详情失败，请稍后再试！');
        }
      });
    },
    // 查看数码
    scanCode: function scanCode() {
      var that = this;
      that.scanCodeVisible = true;
      that.getCodeDetail();
    },
    getCodeDetail: function getCodeDetail() {
      var that = this;
      var param = {};
      param.id = that.orderId;
      param.code = that.codeParam.code;
      param.productId = that.codeParam.productId;
      param.pageNo = that.codeParam.pageNo;
      param.pageSize = 10;
      param.orderType = 2;
      (0, _instock.getCodeDetail)(param).then(function (res) {
        if (res.data.code == 200) {
          that.codeData = res.data.data.records;
          that.total2 = parseInt(res.data.data.total);
        }
      });
    },
    // 查看数码搜索
    searchCode: function searchCode() {
      var that = this;
      that.codeParam.pageNo = 1;
      that.getCodeDetail();
    },
    searchReset: function searchReset() {
      var that = this;
      that.codeParam.id = '';
      that.codeParam.code = '';
      that.codeParam.productId = '';
      that.codeParam.productName = '';
      that.codeParam.pageNo = 1;
      that.getCodeDetail();
    },
    // 展开收起搜索条件
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    }
  }
};
exports.default = _default;