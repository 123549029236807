var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "已关联的活动列表",
        visible: _vm.dialogVisible,
        "append-to-body": true,
        width: "1100px !important",
        "before-close": _vm.goBack,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.goBack,
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              attrs: {
                data: _vm.list,
                height: "500",
                fit: "",
                border: _vm.tableConfig.border,
                stripe: _vm.tableConfig.stripe,
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "序号", prop: "seqNo", width: "80" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "活动名称",
                  prop: "activityName",
                  "min-width": "180",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "布奖方式",
                  prop: "activityDimension",
                  width: "120",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.activityDimension == "0"
                          ? _c("span", [_vm._v("产品")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.activityDimension == "1"
                          ? _c("span", [_vm._v("订单")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "活动状态",
                  prop: "activityStatus",
                  "min-width": "120",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.activityStatus == "0"
                          ? _c("el-tag", [_vm._v("未开始")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.activityStatus == "1"
                          ? _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v("停用"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.activityStatus == "2"
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v("进行中"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.activityStatus == "3"
                          ? _c("el-tag", { attrs: { type: "warning" } }, [
                              _vm._v("已过期"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "开始时间-结束时间",
                  prop: "startTime",
                  "min-width": "180",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", [_vm._v(_vm._s(row.startTime))]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(row.endTime))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  align: _vm.tableConfig.align,
                  width: "120",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "a-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.cancelRelationOperation(
                                      scope.row
                                    )
                                  },
                                },
                              },
                              [_vm._v("取消关联")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              total: _vm.total,
              page: _vm.searchForm.pageNo,
              limit: _vm.searchForm.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.searchForm, "pageNo", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.searchForm, "pageSize", $event)
              },
              pagination: _vm.getList,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { staticClass: "normalBtn", on: { click: _vm.goBack } },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }