var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "tableDialog",
      attrs: {
        "close-on-click-modal": false,
        "append-to-body": true,
        title: "选择批次",
        visible: _vm.visible,
        width: "900px",
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c(
            "el-row",
            { attrs: { type: "flex", gutter: 10 } },
            [
              _c(
                "el-col",
                {
                  staticClass: "page-section",
                  staticStyle: {
                    width: "calc(100% - 200px)",
                    "padding-left": "0",
                    "padding-right": "0",
                  },
                },
                [
                  _c(
                    "el-form",
                    {
                      staticClass: "search-condition",
                      attrs: { model: _vm.listQuery },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "cols" },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 10 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "batchNo" } },
                                    [
                                      _c("el-input", {
                                        attrs: { placeholder: "批次号" },
                                        model: {
                                          value: _vm.listQuery.batchNo,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.listQuery,
                                              "batchNo",
                                              $$v
                                            )
                                          },
                                          expression: "listQuery.batchNo",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.formConfig.btnFollow
                                ? _c(
                                    "el-col",
                                    {
                                      staticClass: "head-btn-group",
                                      style: { width: _vm.formConfig.btnArea },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "mini",
                                                icon: "el-icon-refresh",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.listQuery.current = 1
                                                  _vm.listQuery.batchNo = null
                                                  _vm.fetchData()
                                                },
                                              },
                                            },
                                            [_vm._v("重置")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "primary",
                                                size: "mini",
                                                icon: "el-icon-search",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.listQuery.current = 1
                                                  _vm.fetchData()
                                                },
                                              },
                                            },
                                            [_vm._v("查询")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      ref: "multipleTable",
                      attrs: {
                        height: "400px",
                        data: _vm.list,
                        fit: "",
                        border: "",
                        "row-key": "pid",
                        "highlight-current-row": "",
                      },
                      on: { "selection-change": _vm.handleSelectionChange },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "selection",
                          "reserve-selection": true,
                          width: "55",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "batchNo",
                          label: "批次号",
                          "min-width": "120",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "orderNo",
                          label: "生产订单号",
                          "min-width": "120",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "createTime",
                          label: "创建时间",
                          "min-width": "170",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "countMarkCodeRelation",
                          "min-width": "120",
                          label: "关联数码数量",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("pagination", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.total > 0,
                        expression: "total>0",
                      },
                    ],
                    attrs: {
                      total: _vm.total,
                      page: _vm.listQuery.current,
                      limit: _vm.listQuery.size,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(_vm.listQuery, "current", $event)
                      },
                      "update:limit": function ($event) {
                        return _vm.$set(_vm.listQuery, "size", $event)
                      },
                      pagination: _vm.fetchData,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "page-section leftSide" }, [
                _c(
                  "div",
                  { staticClass: "filter-container" },
                  [
                    _c(
                      "el-row",
                      {
                        staticStyle: { height: "527px" },
                        attrs: { gutter: 10 },
                      },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.selLoading,
                                expression: "selLoading",
                              },
                            ],
                            staticStyle: { width: "100%", "margin-top": "1px" },
                            attrs: {
                              data: _vm.multipleSelection,
                              fit: "",
                              height: "100%",
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "已选批次",
                                "min-width": "100",
                                prop: "productName",
                                align: "left",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(_vm._s(scope.row.batchNo)),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                width: "60",
                                prop: "productName",
                                align: "left",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-button", {
                                        staticStyle: { color: "#99a3ad" },
                                        attrs: {
                                          type: "text",
                                          icon: "el-icon-delete-solid",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.tag1Close(scope.row)
                                          },
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.clear } }, [_vm._v("清 空")]),
          _vm._v(" "),
          _c("el-button", { on: { click: _vm.handleClose } }, [
            _vm._v("取 消"),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.update } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }