var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c("div", { staticClass: "page-section" }, [
            _c("h3", { staticClass: "h-title" }, [_vm._v("产品备案信息")]),
            _vm._v(" "),
            _c(
              "section",
              [
                _c(
                  "el-form",
                  {
                    ref: "searchForm",
                    staticClass: "search-condition",
                    attrs: {
                      model: _vm.searchForm,
                      "status-icon": "",
                      "label-suffix": ":",
                      "label-position": _vm.formConfig.labelPosition,
                      "label-width": _vm.formConfig.labelWidth,
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "cols" },
                      [
                        _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 6, prop: "recordType" } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "备案型号",
                                      prop: "recordType",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticClass: "filter-item",
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        maxlength: "50",
                                        clearable: "",
                                        placeholder: "请输入",
                                      },
                                      model: {
                                        value: _vm.searchForm.recordType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "recordType",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.recordType",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 6, prop: "recordName" } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "型号名称",
                                      prop: "recordName",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticClass: "filter-item",
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        maxlength: "50",
                                        clearable: "",
                                        placeholder: "请输入",
                                      },
                                      model: {
                                        value: _vm.searchForm.recordName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "recordName",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.recordName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 6, prop: "license" } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "许可证号",
                                      prop: "license",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticClass: "filter-item",
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        maxlength: "50",
                                        clearable: "",
                                        placeholder: "请输入",
                                      },
                                      model: {
                                        value: _vm.searchForm.license,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "license",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.license",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.handleShow,
                                    expression: "handleShow",
                                  },
                                ],
                                attrs: { span: 6 },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "operateDate",
                                      label: "操作起止日期:",
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        type: "daterange",
                                        "range-separator": "至",
                                        "start-placeholder": "开始日期",
                                        "end-placeholder": "结束日期",
                                        format: "yyyy-MM-dd",
                                        "value-format": "yyyy-MM-dd",
                                      },
                                      on: { change: _vm.changeOperateDate },
                                      model: {
                                        value: _vm.operateDate,
                                        callback: function ($$v) {
                                          _vm.operateDate = $$v
                                        },
                                        expression: "operateDate",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.formConfig.btnFollow
                              ? _c(
                                  "el-col",
                                  {
                                    staticClass: "head-btn-group",
                                    style: {
                                      width: _vm.formConfig.btnAreaHasShowMore,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { "label-width": "0" } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: { slot: "label" },
                                            slot: "label",
                                          },
                                          [_vm._v(" ")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: _vm.btnConfig.searchBtn
                                                .type,
                                              size: _vm.btnConfig.size,
                                              icon: _vm.btnConfig.searchBtn
                                                .icon,
                                              plain:
                                                _vm.btnConfig.searchBtn.plain,
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.searchForm.current = 1
                                                _vm.getList()
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.btnConfig.searchBtn.text
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: _vm.btnConfig.resetBtn.type,
                                              size: _vm.btnConfig.size,
                                              icon: _vm.btnConfig.resetBtn.icon,
                                              plain:
                                                _vm.btnConfig.resetBtn.plain,
                                            },
                                            on: { click: _vm.resetForm },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.btnConfig.resetBtn.text
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                _vm.handleShow = !_vm.handleShow
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.handleShow
                                                    ? "收起"
                                                    : "展开"
                                                ) +
                                                "\n                  "
                                            ),
                                            _c("i", {
                                              class: _vm.handleShow
                                                ? "el-icon-arrow-up"
                                                : "el-icon-arrow-down",
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.formConfig.btnFollow
                  ? _c("el-divider", { staticClass: "btnDivider" })
                  : _vm._e(),
                _vm._v(" "),
                _vm.formConfig.btnFollow
                  ? _c(
                      "div",
                      { staticClass: "btnArea" },
                      [
                        _c("el-col", { staticClass: "head-btn-group" }, [
                          _vm.hasAuth("addbtn", _vm.$route.meta.authList)
                            ? _c(
                                "div",
                                { staticClass: "head-btn-group" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.addBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.addBtn.icon,
                                        plain: _vm.btnConfig.plain,
                                      },
                                      on: { click: _vm.add },
                                    },
                                    [_vm._v(_vm._s(_vm.btnConfig.addBtn.text))]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.listLoading,
                        expression: "listLoading",
                      },
                    ],
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.tableData,
                      height: _vm.tabHeight,
                      border: _vm.tableConfig.border,
                      stripe: _vm.tableConfig.stripe,
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "index",
                        label: "序号",
                        align: _vm.tableConfig.align,
                        width: "100",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "recordType",
                        label: "备案型号",
                        align: _vm.tableConfig.align,
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "recordName",
                        label: "型号名称",
                        align: _vm.tableConfig.align,
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "capacity",
                        label: "容量(AH)",
                        align: _vm.tableConfig.align,
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "weight",
                        label: "重量(KG)",
                        align: _vm.tableConfig.align,
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "captain",
                        label: "长(mm)",
                        align: _vm.tableConfig.align,
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "wide",
                        label: "宽(mm)",
                        align: _vm.tableConfig.align,
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "high",
                        label: "高(mm)",
                        align: _vm.tableConfig.align,
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "license",
                        label: "许可证号",
                        align: _vm.tableConfig.align,
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "创建人",
                        prop: "userName",
                        align: "center",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "创建时间",
                        prop: "createTime",
                        align: "center",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "更新时间",
                        prop: "updateTime",
                        align: "center",
                        "show-overflow-tooltip": "",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("pagination", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.total > 0,
                      expression: "total > 0",
                    },
                  ],
                  attrs: {
                    layout: "total, sizes, prev, pager, next, jumper",
                    total: _vm.total,
                    page: _vm.searchForm.current,
                    limit: _vm.searchForm.size,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.searchForm, "current", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.searchForm, "size", $event)
                    },
                    pagination: _vm.getList,
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: _vm.title,
            visible: _vm.dialogVisible,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.mloading,
                  expression: "mloading",
                },
              ],
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                "append-to-body": true,
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "100px",
                "label-suffix": ":",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "备案型号", prop: "recordType" } },
                [
                  _c("el-input", {
                    attrs: { value: _vm.ruleForm.recordType, maxlength: "20" },
                    on: {
                      input: function (e) {
                        return (_vm.ruleForm.recordType = e)
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "型号名称", prop: "recordName" } },
                [
                  _c("el-input", {
                    attrs: { value: _vm.ruleForm.recordName, maxlength: "20" },
                    on: {
                      input: function (e) {
                        return (_vm.ruleForm.recordName = _vm.validTe(e))
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "容量（AH）", prop: "capacity" } },
                [
                  _c("el-input", {
                    attrs: { value: _vm.ruleForm.capacity, maxlength: "10" },
                    on: {
                      input: function (e) {
                        return (_vm.ruleForm.capacity = _vm.numOnly(e))
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "重量（KG）", prop: "weight" } },
                [
                  _c("el-input", {
                    attrs: { value: _vm.ruleForm.weight, maxlength: "10" },
                    on: {
                      input: function (e) {
                        return (_vm.ruleForm.weight = _vm.numOnly(e))
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "长（mm）", prop: "captain" } },
                [
                  _c("el-input", {
                    attrs: { value: _vm.ruleForm.captain, maxlength: "10" },
                    on: {
                      input: function (e) {
                        return (_vm.ruleForm.captain = _vm.numOnly(e))
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "宽（mm）", prop: "wide" } },
                [
                  _c("el-input", {
                    attrs: { value: _vm.ruleForm.wide, maxlength: "10" },
                    on: {
                      input: function (e) {
                        return (_vm.ruleForm.wide = _vm.numOnly(e))
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "高（mm）", prop: "high" } },
                [
                  _c("el-input", {
                    attrs: { value: _vm.ruleForm.high, maxlength: "10" },
                    on: {
                      input: function (e) {
                        return (_vm.ruleForm.high = _vm.numOnly(e))
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "许可证号", prop: "license" } },
                [
                  _c("el-input", {
                    attrs: { value: _vm.ruleForm.license, maxlength: "20" },
                    on: {
                      input: function (e) {
                        return (_vm.ruleForm.license = _vm.validTe(e))
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.btnLoading },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("ruleForm")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }