"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _purchase = require("@/api/chanelmange/purchase");
var _outstock = require("@/api/chanelmange/outstock");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _purchaseDialog = _interopRequireDefault(require("@/components/purchaseDialog"));
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var status = [];
var cachName = 'chanelmangepurchaseorderindex';
var _default = {
  name: 'Chanelmangepurchaseorderindex',
  components: {
    Pagination: _Pagination.default,
    purchaseDialog: _purchaseDialog.default,
    Treeselect: _vueTreeselect.default
  },
  filters: {
    formatTime: function formatTime(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        return (0, _utils.parseTime)(value, '{y}-{m}-{d}');
      }
    },
    filterStatus: function filterStatus(value) {
      var name = '';
      status.forEach(function (item) {
        if (item.figure == value) {
          name = item.name;
          return;
        }
      });
      return name;
    }
  },
  data: function data() {
    return {
      listQuery: {
        creatorType: null,
        purchaseType: null,
        purchaseId: null,
        orderStartTime: null,
        orderEndTime: null,
        orderCode: null,
        orderStatus: null,
        sendOutComType: null,
        sendOutComId: null,
        purchaseOwnerOrgId: null,
        current: 1,
        size: 20
      },
      orderTime: [],
      purchaseNames: '',
      sendOutComNames: '',
      tableKey: 'orderTable',
      tabHeight: '100%',
      list: [],
      listLoading: false,
      exportLoading: false,
      total: 0,
      orgOptions: [],
      orderStatus: [],
      addData: [],
      productList: [],
      unitShow: false,
      scanVisible: false,
      showCol: false,
      expandTxt: '展开',
      downLoading: false,
      apis: []
    };
  },
  computed: {
    totalSumAll: function totalSumAll() {
      var totalSumAll = 0;
      this.productList.map(function (item) {
        if (!isNaN(item.bottleNum)) totalSumAll += Number(item.bottleNum);
      });
      if (isNaN(totalSumAll)) {
        return 0;
      }
      return totalSumAll;
    }
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    var that = this;
    var hasCache = that.list.length > 0;
    var authList = that.$route.meta.authList;
    that.apis = authList;
    var auth = that.hasAuth('searchbtn', authList);
    var setHeaderId = that.authId('searchbtn', that.apis);
    if (!hasCache) {
      if (auth) {
        that.getOrg();
        that.getDirSel('CCCGDDZT');
        that.getList();
      }
    }
  },
  // 缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = that.getCaches(that, name);
    if (!cache) {
      that.list = [];
      that.total = 0;
    }
  },
  methods: {
    getList: function getList() {
      var that = this;
      var setHeaderId = that.authId('searchbtn', that.apis);
      that.listLoading = true;
      var param = that.listQuery;
      var data = {};
      if (that.orderTime.length > 0) {
        data.beginDate = that.orderTime[0];
        data.endDate = that.orderTime[1];
      } else {
        data.beginDate = '';
        data.endDate = '';
      }
      data.businessClass = 2;
      data.channelOrderInfoQuery = {};
      data.channelOrderInfoQuery.purchaseComId = param.purchaseId;
      data.channelOrderInfoQuery.purchaseOwnerOrgId = param.channelOrderInfoQuery;
      data.orderNo = param.orderCode;
      if (param.orderStatus != null) {
        data.orderStatus = [];
        data.orderStatus[0] = param.orderStatus;
      }
      data.orderType = 1;
      data.sendOutComId = param.sendOutComId;
      (0, _purchase.getCgList)(param.current, param.size, data).then(function (res) {
        if (res.data.code == 200) {
          that.list = res.data.data.records;
          that.total = parseInt(res.data.data.total);
          setTimeout(function () {
            that.listLoading = false;
          }, 1 * 800);
        } else {
          that.$message.error(res.data.msg);
          that.listLoading = false;
          return;
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    DoSearch: function DoSearch() {
      var that = this;
      that.listQuery.current = 1;
      that.getList();
    },
    DoReset: function DoReset(formName) {
      var that = this;
      that.listQuery.creatorType = null;
      that.listQuery.purchaseOwnerOrgId = null;
      that.listQuery.purchaseType = null;
      that.listQuery.purchaseId = null;
      that.listQuery.sendOutComType = null;
      that.listQuery.sendOutComId = null;
      that.listQuery.orderStartTime = null;
      that.listQuery.orderEndTime = null;
      that.listQuery.orderCode = null;
      that.listQuery.orderStatus = null;
      that.listQuery.current = 1;
      that.purchaseNames = '';
      that.sendOutComNames = '';
      that.orderTime = [];
      that.getList();
    },
    // 监听采购商选择
    getPurchase: function getPurchase(type) {
      var _this = this;
      var that = this;
      var setHeaderId = that.authId('searchbtn', that.apis);
      that.purType = type;
      this.$nextTick(function () {
        _this.$refs.channelSelect.proSelVisible = true;
      });
    },
    clearPurId: function clearPurId() {
      var that = this;
      that.listQuery.purchaseId = '';
      that.listQuery.purchaseType = null;
      that.purchaseNames = '';
    },
    clearSupId: function clearSupId() {
      var that = this;
      that.listQuery.sendOutComId = '';
      that.listQuery.sendOutComType = null;
      that.sendOutComNames = '';
    },
    // 选取终端单位
    hideunits: function hideunits() {
      var that = this;
      that.unitShow = false;
    },
    getUnits: function getUnits(val) {
      var that = this;
      var data = val;
      var cType = that.purType;
      if (cType === 'in') {
        that.purchaseNames = data.channelName;
        that.listQuery.purchaseId = data.pId;
      }
      if (cType === 'out') {
        that.sendOutComNames = data.channelName;
        that.listQuery.sendOutComId = data.pId;
      }
    },
    // 导出文件流并下载
    DoExportAll: function DoExportAll() {
      var that = this;
      var setHeaderId = that.authId('importbtn', that.apis);
      var param = that.listQuery;
      var data = {};
      if (that.orderTime.length > 0) {
        data.beginDate = that.orderTime[0];
        data.endDate = that.orderTime[1];
      } else {
        data.beginDate = '';
        data.endDate = '';
      }
      data.businessClass = 2;
      data.channelOrderInfoQuery = {};
      data.channelOrderInfoQuery.purchaseComId = param.purchaseId;
      data.channelOrderInfoQuery.purchaseOwnerOrgId = param.channelOrderInfoQuery;
      data.orderNo = param.orderCode;
      if (param.orderStatus != null) {
        data.orderStatus = [];
        data.orderStatus[0] = param.orderStatus;
      }
      data.orderType = 1;
      data.sendOutComId = param.sendOutComId;
      that.exportLoading = true;
      (0, _purchase.exportCgData)(data).then(function (res) {
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '采购订单列表.xls';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
        that.exportLoading = false;
      }).catch(function () {
        that.exportLoading = false;
      });
    },
    // 采购订单查看
    DoScan: function DoScan(data) {
      var that = this;
      var setHeaderId = that.authId('detailbtn', that.apis);
      var id = data.pid;
      that.getDetailData(id, 'scan');
    },
    // 获取详情数据
    getDetailData: function getDetailData(id, type) {
      var that = this;
      var visibleType = type;
      (0, _purchase.getCgDetail)(id).then(function (res) {
        if (res.data.code == 200) {
          that.addData = res.data.data;
          that.productList = res.data.data.orderDetailDTOS;
        }
      }).then(function () {
        if (visibleType == 'edit') {
          that.dialogVisible = true;
        }
        if (visibleType == 'scan') {
          that.scanVisible = true;
        }
      });
    },
    clearSaleId: function clearSaleId() {
      var that = this;
      that.listQuery.salesman = '';
    },
    clearPId: function clearPId() {
      var that = this;
      that.listQuery.productId = '';
    },
    // 供货商为企业时，选择组织机构
    getSearchOrg: function getSearchOrg() {
      var that = this;
      if (that.orgOptions.length == 0) {
        var setHeaderId = that.authId('searchbtn', that.apis);
        that.getOrg();
      }
    },
    getOrg: function getOrg() {
      var that = this;
      (0, _outstock.getQueryOrg)().then(function (res) {
        if (res.data.code == 200) {
          var rData = res.data.data;
          var arr = [];
          that.orgOriginData = rData;
          if (rData.length >= 0) {
            rData.forEach(function (item) {
              var obj = {};
              obj.id = item.pid;
              obj.label = item.orgName;
              obj.pid = item.pid;
              obj.parentPid = item.parentPid;
              arr.push(obj);
            });
            that.orgOptions = (0, _utils.transTreeData)(arr, 'pid', 'parentPid', 'children');
          }
        }
      });
    },
    // 展开收起搜索条件
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    },
    // 获取订单状态
    getAuthStatus: function getAuthStatus() {
      var that = this;
      if (that.orderStatus.length == 0) {
        var setHeaderId = that.authId('searchbtn', that.apis);
        that.getDirSel('CCCGDDZT');
      }
    },
    getDirSel: function getDirSel(code) {
      var _this2 = this;
      var that = this;
      var param = {};
      param.code = code;
      (0, _purchase.getCgState)(param).then(function (res) {
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            that.orderStatus = res.data.data;
            status = res.data.data;
          }
        }
      });
    }
  }
};
exports.default = _default;