"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _globalRuleConfig = require("@/api/generalSettings/globalRuleConfig");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManagegeneralSettingsglobalRuleConfigindex'; // 缓存名字为当前path去掉/
var _default = {
  name: 'MarketingManagegeneralSettingsglobalRuleConfigindex',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      codeTable: {
        ruleCode: 'dayLotteryNumLimit' //全局规则code
      },

      ruleData: {
        limitNumber: 10,
        //默认 10
        status: '1' //默认启用
      },

      globalRule: {
        limitNumber: [{
          required: true,
          message: '请输入限制数量',
          trigger: 'blur'
        }]
      },
      saveLoad: false
    };
  },
  activated: function activated() {
    this.getRuleDetail();
  },
  deactivated: function deactivated() {
    var name = cachName;
    var cache = this.getCaches(this, name);
    if (!cache) {}
  },
  methods: {
    getRuleDetail: function getRuleDetail() {
      var _this = this;
      (0, _globalRuleConfig.getRuleDetail)({
        ruleCode: this.codeTable.ruleCode
      }).then(function (res) {
        console.log(res);
        if (res.data && res.data.data.errCode) {
          return _this.$message.error(res.data.data.errMsg);
        } else {
          if (res.data && res.data.data) {
            _this.ruleData.limitNumber = res.data.data.globalRuleValue;
            _this.ruleData.status = res.data.data.status;
          }
        }
      });
    },
    handleSave: function handleSave(name) {
      var _this2 = this;
      this.$refs[name].validate(function (valid) {
        if (valid) {
          _this2.saveLoad = true;
          var sumitData = {
            globalRuleCode: _this2.codeTable.ruleCode,
            globalRuleValue: _this2.ruleData.limitNumber + '',
            status: _this2.ruleData.status
          };
          (0, _globalRuleConfig.saveGlobalRule)(sumitData).then(function (res) {
            if (res.data.data.errCode) {
              _this2.saveLoad = false;
              return _this2.$message.error(res.data.data.errMsg);
            } else {
              _this2.$message.success('更新成功');
              _this2.saveLoad = false;
            }
          });
        }
      });
    },
    hanleReset: function hanleReset(name) {
      this.$refs[name].resetFields();
    }
  }
};
exports.default = _default;