"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.split");
var _vuedraggable = _interopRequireDefault(require("vuedraggable"));
var _admincut = _interopRequireDefault(require("@/components/admincut"));
var _customMenu = require("@/api/wxhyyy/customMenu");
var _wxgzh = require("@/api/wxhyyy/wxgzh");
var _wxHy = require("@/api/wxhyyy/wxHy");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable eqeqeq */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
// 拖拽组件安装npm install vuedraggable -S;

var nameReg = /^[a-zA-Z0-9\u4e00-\u9fa5]+$/;
var apidReg = /^[a-zA-Z0-9]+$/;
var urlReg = /^(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?$/;
var _default = {
  components: {
    draggable: _vuedraggable.default,
    Pagination: _Pagination.default,
    admincut: _admincut.default
  },
  nameReg: nameReg,
  urlReg: urlReg,
  filters: {
    filterTime: function filterTime(value) {
      if (!value) return '';
      if (value.length > 0) {
        var time = new Date(value);
        var y = time.getFullYear();
        var m = time.getMonth() + 1 > 9 ? time.getMonth() + 1 : '0' + (time.getMonth() + 1);
        var d = time.getDate() > 9 ? time.getDate() : '0' + time.getDate();
        var h = time.getHours();
        var i = time.getMinutes() > 9 ? time.getMinutes() : '0' + time.getMinutes();
        var s = time.getSeconds() > 9 ? time.getSeconds() : '0' + time.getSeconds();
        return y + ':' + m + ':' + d + ' ' + h + ':' + i + ':' + s;
      }
      return value;
    }
  },
  data: function data() {
    return {
      hasChecked: '',
      menuLeve1: [{
        bname: '菜单名称',
        conten: '',
        contentType: '',
        grpoupNum: '',
        parentId: 0,
        wxMenuVoList: []
      }],
      aaa: '',
      isActive: 'a',
      isSubActive: 'b',
      show: 'a',
      dragAble: true,
      // 初始化不能拖拽排序
      menuName: '',
      selected: [{
        parent: '',
        level: '',
        index: ''
      }],
      modelData: {
        bname: '',
        conten: '',
        contentType: '',
        ltype: ''
      },
      listQuery1: {
        keyWord: '',
        pageNo: 1,
        pageSize: 10
      },
      listQuery2: {
        mediaType: '',
        pageNo: 1,
        pageSize: 10
      },
      showSigle: false,
      init: false,
      initTxt: '',
      btnShow: false,
      sortAble: false,
      errorLog1: '',
      errorLog2: '',
      errorLog3: '',
      errorLog4: '',
      errorLog5: '',
      loading: false,
      disabled: false,
      showSubmit: true,
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      dialogVisible5: false,
      dialogVisible6: false,
      dialogVisible7: false,
      dialogVisible8: false,
      twData: [],
      mediaData: [],
      total: 0,
      baseUrl: '',
      isSelected: 'a',
      netWork: '',
      smallProject: '',
      itemData: '',
      msgData: '',
      picData: '',
      voiceData: '',
      videoData: '',
      appid: '',
      audio: '',
      video: '',
      preImg: '',
      pj: '',
      wappid: sessionStorage.getItem('appId')
    };
  },
  created: function created() {
    var that = this;
    that.init = true;
    that.initMenu();
    that.baseUrl = _wxHy.base;
    that.showSubmit = false;
    that.msgData = '';
    that.picData = '';
    that.voiceData = '';
    that.videoData = '';
    that.netWork = '';
    that.smallProject = '';
  },
  mounted: function mounted() {
    this.semu();
  },
  methods: {
    semu: function semu() {
      var _this = this;
      (0, _customMenu.searchMenu)({
        appId: this.wappid
      }).then(function (res) {
        var res = res.data;
        if (res.code === 200) {
          _this.menuLeve1 = res.data;
        }
      });
    },
    // 添加一级菜单
    add: function add() {
      var that = this;
      var param = {
        bname: '菜单名称',
        conten: '',
        contentType: '',
        grpoupNum: '',
        parentId: 0,
        wxMenuVoList: []
      };
      var keyIndex = that.menuLeve1.length;
      if (keyIndex == 0) {
        that.menuLeve1.push(param);
        that.DoEdit('parent', 0);
      } else {
        var key = keyIndex - 1;
        if (that.menuLeve1[key].wxMenuVoList.length == 0) {
          if (that.menuLeve1[key].conten == '' || that.menuLeve1[key].conten == null) {
            that.DoEdit('parent', key);
            that.$message.error('请配置菜单功能！');
            return;
          } else {
            that.menuLeve1.push(param);
            that.DoEdit('parent', key + 1);
          }
        } else {
          var checkLength = that.menuLeve1[key].wxMenuVoList.length;
          var checkTrue = 0;
          for (var i = 0; i < that.menuLeve1[key].wxMenuVoList.length; i++) {
            if (that.menuLeve1[key].wxMenuVoList[i].conten == '' || that.menuLeve1[key].wxMenuVoList[i].conten == null) {
              that.DoSubEdit(i, key);
              that.$message.error('请配置菜单功能');
              break;
            } else {
              checkTrue++;
            }
          }
          if (checkTrue == checkLength) {
            that.menuLeve1.push(param);
          }
        }
      }
    },
    // 编辑一级菜单
    DoEdit: function DoEdit(level, val) {
      var that = this;
      that.show = val;
      if (that.dragAble == false) {
        return;
      }
      that.isSubActive = 'b';
      that.isActive = val;
      that.init = false;
      that.showSubmit = true;
      that.modelData = that.menuLeve1[val];
      var data = that.menuLeve1[val];
      if (data.wxMenuVoList.length == 0) {
        if (data.conten == '' || data.conten == null) {
          that.msgData = '';
          that.picData = '';
          that.voiceData = '';
          that.videoData = '';
          that.netWork = '';
          that.smallProject = '';
          that.appid = '';
          that.errorLog3 = '';
          that.errorLog4 = '';
          that.errorLog5 = '';
        } else {
          var argument = {};
          var mid = data.conten;
          var type = data.contentType;
          if (type == '5') {
            that.msgData = '';
            that.picData = '';
            that.voiceData = '';
            that.videoData = '';
            that.smallProject = '';
            that.appid = '';
            that.errorLog3 = '';
            that.errorLog4 = '';
            that.errorLog5 = '';
            that.netWork = mid;
          } else if (type == '6') {
            that.msgData = '';
            that.picData = '';
            that.voiceData = '';
            that.videoData = '';
            that.netWork = '';
            that.errorLog3 = '';
            that.errorLog4 = '';
            that.errorLog5 = '';
            if (mid != '' && mid != null) {
              that.appid = mid.split(',')[0];
              that.smallProject = mid.split(',')[1];
            } else {
              that.smallProject = '';
              that.appid = '';
            }
          } else {
            argument.mediaId = mid;
            argument.type = type;
            that.getMediaDetail(type, argument);
          }
        }
      }
      var param = {};
      param.parent = 0;
      param.level = 1;
      param.index = val;
      that.menuName = that.modelData.bname;
      that.selected[0] = param;
      if (that.menuLeve1[val].wxMenuVoList.length == 0) {
        that.showSigle = false;
      } else {
        that.showSigle = true;
      }
    },
    // 编辑二级菜单
    DoSubEdit: function DoSubEdit(key, index) {
      var that = this;
      if (that.dragAble == false) {
        return;
      }
      that.isActive = 'a';
      that.showSigle = false;
      that.isSubActive = key + '' + index;
      that.init = false;
      that.showSubmit = true;
      that.modelData = that.menuLeve1[index].wxMenuVoList[key];
      var data = that.menuLeve1[index].wxMenuVoList[key];
      if (data.conten == '' || data.conten == null) {
        that.msgData = '';
        that.picData = '';
        that.voiceData = '';
        that.videoData = '';
        that.netWork = '';
        that.smallProject = '';
        that.appid = '';
        that.errorLog3 = '';
        that.errorLog4 = '';
        that.errorLog5 = '';
      } else {
        var argument = {};
        var mid = data.conten;
        var type = data.contentType;
        if (type == '5') {
          that.msgData = '';
          that.picData = '';
          that.voiceData = '';
          that.videoData = '';
          that.smallProject = '';
          that.appid = '';
          that.errorLog3 = '';
          that.errorLog4 = '';
          that.errorLog5 = '';
          that.netWork = mid;
        } else if (type == '6') {
          that.msgData = '';
          that.picData = '';
          that.voiceData = '';
          that.videoData = '';
          that.netWork = '';
          that.errorLog3 = '';
          that.errorLog4 = '';
          that.errorLog5 = '';
          if (mid != '' && mid != null) {
            that.appid = mid.split(',')[0];
            that.smallProject = mid.split(',')[1];
          } else {
            that.smallProject = '';
            that.appid = '';
          }
        } else {
          argument.mediaId = mid;
          argument.type = type;
          that.getMediaDetail(type, argument);
        }
      }
      var param = {};
      param.parent = index;
      param.level = 2;
      param.index = key;
      that.menuName = that.modelData.bname;
      that.selected[0] = param;
    },
    // 添加子菜单
    addSubMenu: function addSubMenu(level, index) {
      var that = this;
      var subArr = that.menuLeve1[index].wxMenuVoList;
      var length = subArr.length;
      var setData = that.modelData;
      var param = {
        bname: '子菜单',
        conten: '',
        contentType: '',
        grpoupNum: ''
      };
      if (length == 0) {
        var key = 0;
        if (setData.conten != '' && setData.conten != null) {
          that.$confirm('添加子菜单后，一级菜单的内容将被清除。确定添加子菜单？', '子菜单确认', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {
            that.modelData.conten = '';
            that.modelData.contentType = '';
            param.bname = '子菜单1';
            subArr.push(param);
            that.DoSubEdit(key, index);
          });
          return;
        } else {
          param.bname = '子菜单1';
          subArr.push(param);
          that.DoSubEdit(key, index);
        }
      } else if (length > 0 && length <= 4) {
        length++;
        if (that.modelData.conten == '') {
          that.$message.error('请配置菜单功能！');
          return;
        }
        var key = length - 1;
        param.bname = '子菜单' + length;
        param.conten = '';
        param.contentType = '';
        subArr.push(param);
        that.DoSubEdit(key, index);
      }
    },
    // 删除菜单
    removeMeun: function removeMeun() {
      var that = this;
      var sdata = that.selected;
      if (sdata.length == 0) {
        that.$message({
          type: 'error',
          message: '请您选择要删除的菜单'
        });
        return;
      }
      that.$confirm('删除后此菜单下设置的内容将被删除?', '删除确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        if (sdata[0].level == 1) {
          var index = sdata[0].index;
          that.menuLeve1.splice(index, 1);
        } else if (sdata[0].level == 2) {
          var pindex = sdata[0].parent;
          var key = sdata[0].index;
          that.menuLeve1[pindex].wxMenuVoList.splice(key, 1);
        }
        that.modelData = {
          bname: '',
          conten: '',
          contentType: '',
          grpoupNum: ''
        };
        that.isActive = 'a';
        that.isSubActive = 'b';
        that.$message({
          type: 'success',
          message: '删除成功!'
        });
        that.init = true;
        return;
      });
    },
    // 菜单功能切换
    getRadio: function getRadio(index, val) {
      var that = this;
      that.hasChecked = 'checked';
      if (index == '1') {
        if (that.msgData != '') {
          val.contentType = '1';
          val.conten = that.msgData.mediaId;
          return;
        }
        if (that.picData != '') {
          val.contentType = '2';
          val.conten = that.picData.mediaId;
          return;
        }
        if (that.voiceData != '') {
          val.contentType = '3';
          val.conten = that.voiceData.mediaId;
          return;
        }
        if (that.videoData != '') {
          val.contentType = '4';
          val.conten = that.videoData.mediaId;
          return;
        }
        val.contentType = '1';
        val.conten = '';
      }
      if (index == '2') {
        val.contentType = '5';
        if (that.netWork == '') {
          val.conten = '';
        } else {
          val.conten = that.netWork;
        }
      }
      if (index == '3') {
        val.contentType = '6';
        val.conten = that.appid + ',' + that.smallProject;
      }
    },
    // 判断菜单显示
    initMenu: function initMenu() {
      var that = this;
      if (that.menuLeve1.length == 3) {
        that.initTxt = '点击左侧菜单进行编辑';
      } else if (that.menuLeve1.length == 0) {
        that.initTxt = '点击左侧【添加】按钮添加菜单';
      } else {
        that.initTxt = '点击左侧【添加】按钮添加菜单或点击菜单进行编辑';
      }
    },
    // 验证输入名称
    validateName1: function validateName1(e, data) {
      var that = this;
      var name = e.target.value;
      if (e.target.value == '') {
        that.errorLog1 = '菜单名称不能为空';
        setTimeout(function () {
          e.target.value = that.menuName;
          data.bname = that.menuName;
          that.errorLog1 = '';
        }, 1500);
        return;
      } else {
        if (nameReg.test(name)) {
          if (that.computedStrLen(name) > 8) {
            that.errorLog1 = '菜单名称只能输入4个汉字或8个字符(1个汉字等同于2个字符)';
            return;
          } else {
            that.errorLog1 = '';
          }
        } else {
          that.errorLog1 = '菜单名称只能输入汉字，字母，数字(不能含有特殊字符，空格)';
          return;
        }
      }
    },
    // 验证输入名称
    validateName2: function validateName2(e, data) {
      var that = this;
      var name = e.target.value;
      if (e.target.value == '') {
        that.$message.error('菜单名称不能为空');
        that.errorLog2 = '菜单名称不能为空';
        setTimeout(function () {
          e.target.value = that.menuName;
          data.bname = that.menuName;
          that.errorLog2 = '';
        }, 500);
        return;
      } else {
        if (nameReg.test(name)) {
          if (that.computedStrLen(name) > 16) {
            that.errorLog2 = '菜单名称只能输入8个汉字或16个字符(1个汉字等同于2个字符)';
          } else {
            that.errorLog2 = '';
          }
          return;
        } else {
          that.errorLog2 = '菜单名称只能输入汉字，字母，数字(不能含有特殊字符，空格)';
          return;
        }
      }
    },
    // 验证url
    validateUrl: function validateUrl(event, data) {
      var that = this;
      var url = that.netWork;
      if (url != '') {
        if (!urlReg.test(url)) {
          data.conten = url;
          that.errorLog3 = '请输入正确的跳转链接';
          return;
        } else {
          data.conten = url;
          that.errorLog3 = '';
        }
      } else {
        that.errorLog3 = '';
      }
    },
    // 验证过appid
    validateAd: function validateAd(event, data) {
      var that = this;
      var appid = that.appid;
      // if (!apidReg.test(appid)) {
      //   data.conten = appid + ',' + that.smallProject
      //   that.errorLog4 = '请输入正确的appId(只能包含数字、字母)'
      //   return
      // } else {
      // if (that.smallProject == '') {
      //   data.conten = appid + ',' + that.smallProject
      //   that.errorLog5 = '请输入页面路径'
      //   return
      // } else {
      data.conten = appid;
      // data.conten = appid + ',' + that.smallProject
      // }
      // }
    },
    // 验证路径
    validatePath: function validatePath(event, data) {
      var that = this;
      if (that.smallProject == '') {
        data.conten = that.appid + ',' + that.smallProject;
        that.errorLog5 = '请输入页面路径';
        return;
      } else {
        that.errorLog5 = '';
        if (that.appid == '') {
          data.conten = that.appid + ',' + that.smallProject;
          that.errorLog4 = '请输入appId';
          return;
        } else {
          data.conten = that.appid + ',' + that.smallProject;
        }
      }
    },
    // 计算字符串长度，英文1个字符，中文2个字符
    computedStrLen: function computedStrLen(str) {
      var len = 0;
      for (var i = 0; i < str.length; i++) {
        var c = str.charCodeAt(i);
        // 单字节加1
        if (c >= 0x0001 && c <= 0x007e || c >= 0xff60 && c <= 0xff9f) {
          len++;
        } else {
          len += 2;
        }
      }
      return len;
    },
    // tab切换
    handleClick: function handleClick(value) {
      var that = this;
      if (value.name == '1') {
        if (that.msgData == '') {
          that.modelData.conten = '';
        } else {
          that.modelData.conten = that.msgData.mediaId;
        }
        return;
      }
      if (value.name == '2') {
        if (that.picData == '') {
          that.modelData.conten = '';
        } else {
          that.modelData.conten = that.picData.mediaId;
        }
        return;
      }
      if (value.name == '3') {
        if (that.voiceData == '') {
          that.modelData.conten = '';
        } else {
          that.modelData.conten = that.voiceData.mediaId;
        }
        return;
      }
      if (value.name == '4') {
        if (that.videoData == '') {
          that.modelData.conten = '';
        } else {
          that.modelData.conten = that.videoData.mediaId;
        }
        return;
      }
    },
    // 选择图文
    choseElement: function choseElement(val) {
      var that = this;
      that.isSelected = 'a';
      if (val == '0') {
        that.listQuery1.pageNo = 1;
        that.getList();
        that.$nextTick(function () {
          that.dialogVisible = true;
        });
        return;
      } else {
        that.listQuery2.pageNo = 1;
        that.listQuery2.mediaType = val;
        that.getList2();
        if (val == '3') {
          // 图片
          that.$nextTick(function () {
            that.dialogVisible2 = true;
          });
        }
        if (val == '2') {
          // 语音
          that.$nextTick(function () {
            that.dialogVisible3 = true;
          });
        }
        if (val == '1') {
          // 视频
          that.$nextTick(function () {
            that.dialogVisible4 = true;
          });
        }
      }
    },
    // 获取图文列表
    getList: function getList() {
      var that = this;
      that.listQuery1.appId = sessionStorage.getItem('appId');
      (0, _customMenu.getTxt)(that.listQuery1).then(function (res) {
        var res = res.data;
        if (res.code == 200) {
          that.twData = res.data.records;
          that.total = parseInt(res.data.total);
        }
      });
    },
    // 获取图片，音频，视频列表
    getList2: function getList2() {
      var that = this;
      that.listQuery2.appId = sessionStorage.getItem('appId');
      (0, _customMenu.getMedia)(that.listQuery2).then(function (res) {
        var res = res.data;
        if (res.code == 200) {
          that.mediaData = res.data.records;
          that.total = parseInt(res.data.total);
        }
      });
    },
    // 选择列表项
    selectItem: function selectItem(val, index) {
      var that = this;
      that.isSelected = index;
      that.itemData = val;
    },
    // 确定
    makeSure: function makeSure(val) {
      var that = this;
      if (that.itemData == '') {
        that.$message.error('请选择列表项');
        return;
      }
      if (val == '1') {
        that.msgData = that.itemData;
      }
      if (val == '2') {
        that.picData = that.itemData;
      }
      if (val == '3') {
        that.voiceData = that.itemData;
      }
      if (val == '4') {
        that.videoData = that.itemData;
      }
      if (that.itemData.mediaId == null) {
        that.modelData.conten = '';
      } else {
        that.modelData.conten = that.itemData.mediaId;
      }
      that.closeDialog(val);
    },
    // 取消
    DoCancel: function DoCancel(val) {
      var that = this;
      that.itemData = '';
      that.closeDialog(val);
    },
    closeDialog: function closeDialog(val) {
      var that = this;
      if (val == '1') {
        that.dialogVisible = false;
      }
      if (val == '2') {
        that.dialogVisible2 = false;
      }
      if (val == '3') {
        that.dialogVisible3 = false;
      }
      if (val == '4') {
        that.dialogVisible4 = false;
      }
    },
    // 删除项
    deleteItem: function deleteItem() {
      var that = this;
      if (that.modelData.conten == that.msgData.mediaId) {
        that.msgData = '';
        that.modelData.conten = '';
      } else {
        that.msgData = '';
      }
      that.isSelected = 'a';
    },
    // 获取media详情
    getMediaDetail: function getMediaDetail(type, param) {
      var that = this;
      param.appId = sessionStorage.getItem('appId');
      (0, _customMenu.getDetail)(param).then(function (res) {
        var res = res.data;
        if (res.code == 200) {
          switch (type) {
            case '1':
              that.picData = '';
              that.voiceData = '';
              that.netWork = '';
              that.smallProject = '';
              that.appid = '';
              that.errorLog3 = '';
              that.errorLog4 = '';
              that.errorLog5 = '';
              that.videoData = '';
              that.msgData = res.data;
              break;
            case '2':
              that.msgData = '';
              that.voiceData = '';
              that.netWork = '';
              that.smallProject = '';
              that.appid = '';
              that.errorLog3 = '';
              that.errorLog4 = '';
              that.errorLog5 = '';
              that.videoData = '';
              that.picData = res.data;
              break;
            case '3':
              that.msgData = '';
              that.picData = '';
              that.netWork = '';
              that.smallProject = '';
              that.appid = '';
              that.errorLog3 = '';
              that.errorLog4 = '';
              that.errorLog5 = '';
              that.videoData = '';
              that.voiceData = res.data;
              break;
            case '4':
              that.msgData = '';
              that.picData = '';
              that.voiceData = '';
              that.netWork = '';
              that.smallProject = '';
              that.appid = '';
              that.errorLog3 = '';
              that.errorLog4 = '';
              that.errorLog5 = '';
              that.videoData = res.data;
              break;
          }
        }
      });
    },
    // 菜单排序
    rankMenu: function rankMenu() {
      var that = this;
      that.init = true;
      that.initTxt = '可通过拖拽左边的菜单进行排序';
      that.dragAble = false;
      that.btnShow = true;
      that.isActive = 'a';
      that.isSubActive = 'b';
      that.showSubmit = false;
    },
    // 菜单排序完成
    completeMenu: function completeMenu() {
      var that = this;
      that.dragAble = true;
      that.btnShow = false;
      that.init = true;
      that.initTxt = '点击左侧【添加】按钮添加菜单或点击菜单进行编辑';
      that.showSubmit = true;
    },
    // 替换素材
    changeItem: function changeItem(val) {
      var that = this;
      var mtype = val;
      that.choseElement(mtype);
    },
    // 预览图文
    previewTw: function previewTw() {
      var that = this;
      that.dialogVisible8 = true;
    },
    // 预览图片
    viewImg: function viewImg() {
      var that = this;
      console.log(that.picData);
      // that.preImg = base + '/' + that.picData.url
      that.preImg = that.picData.uploadUrl;
      that.$nextTick(function () {
        that.dialogVisible7 = true;
      });
    },
    // 试听
    getAudio: function getAudio(val) {
      var that = this;
      console.log(val);
      (0, _wxgzh.materialVoiceDownload)({
        mediaId: val.mediaId,
        appId: sessionStorage.getItem('appId')
      }).then(function (res) {
        var res = res.data;
        console.log(res);
        var blob = new Blob([res], {
          type: 'application/attachment;filename='
        });
        console.log(blob);
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = val.trueName;
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      });
    },
    // 预览视频
    getVideo: function getVideo(val) {
      var that = this;
      (0, _wxgzh.materialVideoInfo)({
        mediaId: val,
        appId: sessionStorage.getItem('appId')
      }).then(function (res) {
        var res = res.data;
        if (res.code === 200) {
          that.video = _wxHy.base + res.data;
          that.$nextTick(function () {
            that.dialogVisible6 = true;
          });
        } else {
          that.$message(res.msg);
        }
      });
    },
    // 发布自定义菜单
    DoPublish: function DoPublish() {
      var that = this;
      var data = that.menuLeve1;
      var len = data.length;
      var checkArr = [];
      for (var index = 0; index < len; index++) {
        var pname = data[index].bname;
        var content = data[index].conten;
        var ctype = data[index].contentType;
        var child = data[index].wxMenuVoList;
        if (nameReg.test(pname)) {
          if (that.computedStrLen(pname) > 8) {
            that.DoEdit('parent', index);
            that.errorLog1 = '菜单名称只能输入4个汉字或8个字符(1个汉字等同于2个字符)';
            return;
          } else {
            that.errorLog1 = '';
          }
        } else {
          that.errorLog1 = '菜单名称只能输入汉字，字母，数字(不能含有特殊字符，空格)';
          return;
        }
        if (child.length == 0) {
          if (content == '' || content == null) {
            that.DoEdit('parent', index);
            that.$message.error('请配置菜单功能！');
            return;
          } else {
            if (ctype == '5') {
              if (!urlReg.test(content)) {
                that.DoEdit('parent', index);
                that.$message.error('请输入正确的跳转链接！');
                return;
              }
            }
            if (ctype == '6') {
              var spr = content.split(',');
              if (spr[0] == '') {
                that.DoEdit('parent', index);
                that.$message.error('请输入appId');
                return;
              } else {
                if (!apidReg.test(spr[0])) {
                  that.DoEdit('parent', index);
                  that.$message.error('请输入正确的appId(只允许输入数字、字母)');
                  return;
                }
              }
              // if (spr[1] == '') {
              //   that.DoEdit('parent', index)
              //   that.$message.error('请输入页面路径')
              //   return
              // }
            }
          }
        } else if (child.length > 0) {
          for (var key = 0; key < child.length; key++) {
            var cname = child[key].bname;
            var con = child[key].conten;
            var contype = child[key].contentType;
            if (nameReg.test(cname)) {
              if (that.computedStrLen(cname) > 16) {
                that.show = index;
                that.DoSubEdit(key, index);
                that.errorLog2 = '菜单名称只能输入8个汉字或16个字符(1个汉字等同于2个字符)';
                return;
              } else {
                that.errorLog2 = '';
              }
            } else {
              that.errorLog2 = '菜单名称只能输入汉字，字母，数字(不能含有特殊字符，空格)';
              return;
            }
            if (con == '' || con == null) {
              that.show = index;
              that.DoSubEdit(key, index);
              that.$message.error('请配置菜单功能！');
              return;
            } else {
              that.show = index;
              if (contype == '5') {
                if (!urlReg.test(con)) {
                  that.DoSubEdit(key, index);
                  that.$message.error('请输入正确的跳转链接！');
                  return;
                }
              }
              if (contype == '6') {
                var sp = con.split(',');
                if (sp[0] == '') {
                  that.DoSubEdit(key, index);
                  that.$message.error('请输入appId');
                  return;
                } else {
                  if (!apidReg.test(sp[0])) {
                    that.DoSubEdit(key, index);
                    that.$message.error('请输入正确的appId(只允许输入数字、字母)');
                    return;
                  }
                }
                // if (sp[1] == '') {
                //   that.DoSubEdit(key, index)
                //   that.$message.error('请输入页面路径')
                //   return
                // }
              }
            }
          }
        }
      }

      console.log(data);
      that.$confirm('发布成功后将会覆盖原版本，且将在24小时内对所有的用户有效，确认发布？', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        that.loading = true;
        that.disabled = true;
        data.appId = sessionStorage.getItem('appId');
        (0, _customMenu.addMenu)(data).then(function (res) {
          var res = res.data;
          if (res.code == 200) {
            that.$message({
              type: 'success',
              message: res.msg
            });
            that.loading = false;
            that.disabled = false;
            that.showSubmit = false;
            that.init = true;
            that.initTxt = '点击左侧【添加】按钮添加菜单或点击菜单进行编辑';
          } else {
            that.$message.error(res.msg);
            that.loading = false;
            that.disabled = false;
          }
        }).catch(function (res) {
          that.loading = false;
          that.disabled = false;
        });
      });
    }
  }
};
exports.default = _default;