"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManagelogounsealedMasterUserindex';
var _default = {
  name: 'MarketingManagelogounsealedMasterUserindex',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      formInline: {
        a: ''
      },
      rangeTime: '',
      tableData: [{
        img: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
        hd: '30元状元郎',
        hdid: 123456789,
        user: 12345678901,
        userid: '张经理',
        status: '正常用户',
        ip: '211.34.56.11',
        ren: '张经理',
        time: '2021-12-12 12:12:12'
      }],
      listLoading: false,
      downLoading: false,
      tabHeight: '100%',
      total: 10,
      params: {
        pageNo: 1,
        pageSize: 10
      }
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    this.formInline.status = '';
    var hasCache1 = this.tableData.length > 0;
    if (!hasCache1) {
      console.log();
    }
  },
  deactivated: function deactivated() {
    var name = cachName;
    var cache = this.getCaches(this, name);
    if (!cache) {
      this.tableData = [];
      this.total = 0;
    }
  },
  methods: {
    sj: function sj() {},
    // 复选框选中事件
    handleSelectionChange: function handleSelectionChange(data) {
      // console.log("复选框", data);
      var arr = data.map(function (item) {
        return item.id;
      });
      this.strategyIds = arr.toString();
      // console.log(arr.toString());
    }
  }
};
exports.default = _default;