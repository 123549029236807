"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _chanelyw = require("@/api/chanelmange/chanelyw");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'chanelmangebasicsetywtypeindex';
var _default = {
  name: 'Chanelmangebasicsetywtypeindex',
  components: {
    Pagination: _Pagination.default
  },
  filters: {
    formatTime: function formatTime(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        return (0, _utils.parseTime)(value, '{y}-{m}-{d}');
      }
    }
  },
  data: function data() {
    return {
      listQuery: {
        bussinessStatus: '',
        outgoType: '',
        keyWords: '',
        current: 1,
        size: 20
      },
      stockOptions: [{
        name: '出库',
        code: 1
      }, {
        name: '入库',
        code: 2
      }],
      states: [{
        name: '启用',
        code: 1
      }, {
        name: '禁用',
        code: 0
      }],
      tableKey: 'ywtype',
      tabHeight: '100%',
      list: [],
      listLoading: false,
      total: 0,
      addData: {
        businessCode: '',
        businessTypeName: '',
        outgoType: '',
        billEnum: '',
        sourceEnum: '',
        sourceBill: '',
        submitYes: 1,
        bussinessStatus: 1,
        remarks: '',
        pid: '',
        isCustom: 0
      },
      rules: {
        businessCode: [{
          required: true,
          message: '请输入业务编码',
          trigger: 'blur'
        }],
        businessTypeName: [{
          required: true,
          message: '请输入业务类型名称',
          trigger: 'change'
        }],
        outgoType: [{
          required: true,
          message: '请选择',
          trigger: 'change'
        }],
        billEnum: [{
          required: true,
          message: '请选择',
          trigger: 'change'
        }],
        sourceEnum: [{
          required: true,
          message: '请选择',
          trigger: 'change'
        }],
        sourceBill: [{
          required: true,
          message: '请选择',
          trigger: 'change'
        }]
      },
      title: '业务类型-添加',
      dialogVisible: false,
      scanVisible: false,
      loading: false,
      dstate: false,
      scanData: {},
      billOptions: [{
        name: '采购入库单',
        code: 2
      }, {
        name: '其他入库单',
        code: 4
      }, {
        name: '销售出库单',
        code: 5
      }, {
        name: '其他出库单',
        code: 7
      }],
      sourceOptions: [{
        name: '无',
        code: 0
      }, {
        name: '本系统',
        code: 1
      }, {
        name: '外系统',
        code: 2
      }],
      sbillOptions: [{
        name: '企业销售出库单',
        code: 2
      }, {
        name: '采购入库单',
        code: 4
      }, {
        name: '销售出库单',
        code: 5
      }],
      subType: '',
      apis: [],
      page2: false
    };
  },
  activated: function activated() {
    var that = this;
    var hasCache = that.list.length > 0;
    var authList = that.$route.meta.authList;
    that.apis = authList;
    var auth = that.hasAuth('searchbtn', authList);
    if (!hasCache) {
      if (auth) {
        that.getList();
      }
    }
  },
  // 缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = that.getCaches(that, name);
    if (!cache) {
      that.list = [];
      that.total = 0;
    }
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    getList: function getList() {
      var that = this;
      var setHeaderId = that.authId('searchbtn', that.apis);
      that.listLoading = true;
      var param = that.listQuery;
      (0, _chanelyw.getYwList)(param).then(function (res) {
        if (res.data.code == 200) {
          that.list = res.data.data.records;
          that.total = parseInt(res.data.data.total);
          setTimeout(function () {
            that.listLoading = false;
          }, 1 * 800);
        } else {
          that.$message.error(res.data.msg);
          that.listLoading = false;
          return;
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    DoSearch: function DoSearch() {
      var that = this;
      that.listQuery.current = 1;
      that.getList();
    },
    DoReset: function DoReset(formName) {
      var that = this;
      that.listQuery = {
        bussinessStatus: '',
        outgoType: '',
        keyWords: '',
        current: 1,
        size: 20
      };
      that.getList();
    },
    // 添加
    DoAdd: function DoAdd() {
      var that = this;
      that.title = '业务类型-添加';
      that.subType = 'add';
      that.addData = {
        businessCode: '',
        businessTypeName: '',
        outgoType: '',
        billEnum: '',
        sourceEnum: '',
        sourceBill: '',
        submitYes: 1,
        bussinessStatus: 1,
        remarks: '',
        pid: '',
        isCustom: 0
      };
      that.$nextTick(function () {
        that.dialogVisible = true;
      });
    },
    // 修改
    DoEdit: function DoEdit(data) {
      var that = this;
      that.title = '业务类型-修改';
      that.addData.businessCode = data.businessCode;
      that.addData.businessTypeName = data.businessTypeName;
      that.addData.outgoType = data.outgoType;
      that.addData.billEnum = Number(data.billEnum);
      that.addData.sourceEnum = Number(data.sourceEnum);
      that.addData.sourceBill = Number(data.sourceBill);
      that.addData.submitYes = data.submitYes;
      that.addData.bussinessStatus = data.bussinessStatus;
      that.addData.remarks = data.remarks;
      that.addData.pid = data.pId;
      that.subType = 'edit';
      that.$nextTick(function () {
        that.dialogVisible = true;
      });
    },
    handleClose: function handleClose() {
      var that = this;
      that.resetForm('addForm');
    },
    resetForm: function resetForm(formName) {
      var that = this;
      that.$refs[formName].resetFields();
      that.dialogVisible = false;
    },
    submitForm: function submitForm(formName) {
      var that = this;
      that.$refs[formName].validate(function (valid) {
        if (valid) {
          that.loading = true;
          that.dsate = true;
          var subData = that.addData;
          if (that.subType == 'add') {
            var setHeaderId = that.authId('addbtn', that.apis);
            (0, _chanelyw.addYwData)(subData).then(function (res) {
              if (res.data.code == 200) {
                that.loading = false;
                that.dsate = false;
                that.$message({
                  type: 'success',
                  message: '添加成功'
                });
                that.getList();
                that.dialogVisible = false;
              } else {
                that.$message.error(res.data.msg);
                that.loading = false;
                that.dsate = false;
                return;
              }
            }).catch(function () {
              that.loading = false;
              that.dsate = false;
            });
          }
          if (that.subType == 'edit') {
            var setHeaderId = that.authId('updatebtn', that.apis);
            (0, _chanelyw.editYwData)(subData).then(function (res) {
              if (res.data.code == 200) {
                that.loading = false;
                that.dsate = false;
                that.$message({
                  type: 'success',
                  message: '修改成功'
                });
                that.getList();
                that.dialogVisible = false;
              } else {
                that.$message.error(res.data.msg);
                that.loading = false;
                that.dsate = false;
                return;
              }
            }).catch(function () {
              that.loading = false;
              that.dsate = false;
            });
          }
        }
      });
    },
    DoScan: function DoScan(data) {
      var that = this;
      that.scanData = (0, _utils.deepClone)(data);
      var setHeaderId = that.authId('detailbtn', that.apis);
      var param = {
        pId: data.pId
      };
      (0, _chanelyw.getDetail)(param).then(function (res) {
        if (res.data.code == 200) {
          that.scanData = res.data.data;
          that.$nextTick(function () {
            that.scanVisible = true;
          });
        } else {
          that.$message.error(res.data.msg);
          return;
        }
      });
    },
    DoStop: function DoStop(data) {
      var that = this;
      var param = {};
      param.pId = data.pId;
      param.businessStatus = 0;
      that.$confirm('是否禁用?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var setHeaderId = that.authId('stopbtn', that.apis);
        (0, _chanelyw.chageState)(param).then(function (res) {
          if (res.data.code == 200) {
            that.$message({
              type: 'success',
              message: '禁用成功'
            });
            that.listQuery.current = 1;
            that.getList();
          } else {
            that.$message.error(res.data.msg);
          }
        });
      }).catch(function () {
        console.log('禁用已取消');
      });
    },
    DoStart: function DoStart(data) {
      console.log(data);
      var that = this;
      var param = {};
      param.pId = data.pId;
      param.businessStatus = 1;
      that.$confirm('是否启用?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var setHeaderId = that.authId('startbtn', that.apis);
        (0, _chanelyw.chageState)(param).then(function (res) {
          if (res.data.code == 200) {
            that.$message({
              type: 'success',
              message: '启用成功'
            });
            that.listQuery.current = 1;
            that.getList();
          } else {
            that.$message.error(res.data.msg);
          }
        });
      }).catch(function () {
        console.log('启用已取消');
      });
    }
  }
};
exports.default = _default;