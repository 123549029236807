"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _WechatUser = require("@/api/operationalTools/WechatUser");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManageoperationalToolsactiveRoleUsersindex';
var _default = {
  name: cachName,
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      tabHeight: '100%',
      dispenseList: [{
        value: 0,
        label: '启用'
      }, {
        value: 2,
        label: '禁用'
      }, {
        value: 3,
        label: '冻结'
      }, {
        value: 4,
        label: '删除'
      }],
      dialogVisible: false,
      searchForm: {
        name: '',
        phone: '',
        status: '',
        pageNo: 1,
        pageSize: 10
      },
      list: [],
      listLoading: false,
      searchBtn: false,
      sweepBtn: false,
      total: 0,
      qyMemberId: '',
      title: '详情',
      dialogFormVisible: false,
      lookdisabled: false,
      roleForm: {
        id: '',
        userName: '',
        phone: '',
        address2: '',
        address: '',
        channelName: '',
        roleName: '',
        status: ''
      },
      rules: {
        userName: [{
          required: true,
          message: '请输入姓名',
          trigger: 'change'
        }],
        address: [{
          required: true,
          message: '请输入详细地址',
          trigger: 'change'
        }],
        channelName: [{
          required: true,
          message: '请输入门店名称',
          trigger: 'change'
        }]
      },
      btnLoading: false
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    var hasCache1 = this.list.length > 0;
    if (!hasCache1) {
      this.getRoleMemberListFun();
    }
  },
  deactivated: function deactivated() {
    var name = cachName;
    var cache = this.getCaches(this, name);
    if (!cache) {
      this.list = [];
    }
  },
  methods: {
    getRoleMemberListFun: function getRoleMemberListFun() {
      var _this = this;
      this.listLoading = true;
      (0, _WechatUser.activityRoleUserPage)(this.searchForm).then(function (res) {
        console.log(res.data);
        _this.listLoading = false;
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
          return;
        }
        _this.list = res.data.data.records;
        _this.total = res.data.data.total;
      });
    },
    // 审核
    auditFun: function auditFun(type) {
      var _this2 = this;
      if (type) {
        checkpass({
          qyMemberId: this.qyMemberId
        }).then(function (res) {
          if (res.code === 0) {
            _this2.$message({
              type: 'success',
              message: res.msg
            });
            _this2.dialogVisible = false;
            _this2.getRoleMemberListFun();
          } else {
            _this2.$message({
              type: 'info',
              message: res.msg
            });
          }
        });
      } else {
        checkrefuse({
          qyMemberId: this.qyMemberId
        }).then(function (res) {
          if (res.code === 0) {
            _this2.$message({
              type: 'success',
              message: res.msg
            });
            _this2.dialogVisible = false;
            _this2.getRoleMemberListFun();
          } else {
            _this2.$message({
              type: 'info',
              message: res.msg
            });
          }
        });
      }
    },
    // 搜索
    searchFun: function searchFun(type) {
      if (type === 0) {
        this.searchForm = {
          name: '',
          phone: '',
          status: '',
          pageNo: 1,
          pageSize: 10
        };
      }
      this.getRoleMemberListFun();
    },
    // 编辑
    editFun: function editFun(type, row) {
      console.log(row);
      if (type == 1) {
        this.title = '编辑';
        this.lookdisabled = false;
      } else if (type == 2) {
        this.title = '详情';
        this.lookdisabled = true;
      }
      this.roleForm.id = row.id;
      this.roleForm.userName = row.userName;
      this.roleForm.phone = row.phone;
      this.roleForm.address2 = (row.province || '') + (row.city || '') + (row.district || '') + (row.street || '');
      this.roleForm.address = row.address;
      this.roleForm.channelName = row.channelName;
      this.roleForm.roleName = row.roleName;
      this.roleForm.status = row.status;
      this.dialogFormVisible = true;
    },
    handleClose: function handleClose() {
      this.dialogFormVisible = false;
      this.roleForm = {
        id: '',
        userName: '',
        phone: '',
        address2: '',
        address: '',
        channelName: '',
        roleName: '',
        status: ''
      };
    },
    submitFun: function submitFun() {
      var _this3 = this;
      this.$refs.roleForm.validate(function (valid) {
        if (valid) {
          _this3.btnLoading = true;
          var sdata = JSON.parse(JSON.stringify(_this3.roleForm));
          console.log('提交', sdata);
          delete sdata.address2;
          (0, _WechatUser.activityRoleUserEdit)(sdata).then(function (res) {
            console.log(res);
            _this3.btnLoading = false;
            if (res.data.data.code && res.data.data.code == 500) {
              _this3.$message.error(res.data.data.msg);
              return;
            }
            _this3.$message.success(_this3.title + '成功');
            _this3.getRoleMemberListFun();
            _this3.handleClose();
          });
        }
      });
    },
    // 启用（1）禁用 2 冻结 3 解冻4 删除0
    //  stopRoleMember,
    // startRoleMember,
    // unfreezeRoleMember,
    // frozenRoleMember,
    // deleteRoleMember
    switchFun: function switchFun(type, id) {
      var _this4 = this;
      var name = '';
      var status = '';
      if (type === '0') {
        name = '删除';
        status = 4;
      } else if (type === '1') {
        name = '启用';
        status = 0;
      } else if (type === '2') {
        name = '禁用';
        status = 2;
      } else if (type === '3') {
        name = '冻结';
        status = 3;
      } else if (type === '4') {
        name = '解冻';
        status = 0;
      }
      this.$confirm("\u6B64\u64CD\u4F5C\u5C06".concat(name, "\u8BE5\u7528\u6237, \u662F\u5426\u7EE7\u7EED?"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _WechatUser.activityRoleUserEdit)({
          id: id,
          status: status
        }).then(function (res) {
          if (res.data.data.code || res.data.data.code == 0) {
            _this4.$message.error(res.data.data.msg);
            return;
          }
          _this4.getRoleMemberListFun();
          _this4.$message({
            type: 'success',
            message: name + '成功!'
          });
        });
      }).catch(function () {
        _this4.$message({
          type: 'info',
          message: "\u5DF2\u53D6\u6D88\u53D6\u6D88\u64CD\u4F5C"
        });
      });
    },
    // 翻页
    pagingFun: function pagingFun() {
      this.getRoleMemberListFun();
    },
    // 多选
    handleSelectionChange: function handleSelectionChange(val) {
      var _this5 = this;
      if (val.length >= 1) {
        this.selectionArr = [];
        val.map(function (item) {
          _this5.selectionArr.push(item.code);
        });
      } else {
        this.selectionArr = [];
      }
    }
  }
};
exports.default = _default;