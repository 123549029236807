"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
var _pack = require("@/api/basic/pack");
var _utils = require("@/utils");
var _select = require("@/api/warehouse/select");
var _productSelectDialog = _interopRequireDefault(require("../../../productSelectDialog"));
var _storeTypeSelect = _interopRequireDefault(require("@/components/storeTypeSelect"));
var _allotUnitSelect = _interopRequireDefault(require("@/components/allotUnitSelect"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var miniUnit = '';
var _default = {
  name: 'TableSuply',
  components: {
    productDialog: _productSelectDialog.default,
    storeTypeSelect: _storeTypeSelect.default,
    allotUnitSelect: _allotUnitSelect.default
  },
  data: function data() {
    return {
      TypeDataComs: {
        statusList: [],
        StoreList: [],
        OrgList: [],
        sourceList: [],
        typeList: []
      },
      dateValue: [],
      searchForm: {
        billNo: '',
        billSource: '',
        billStatus: '',
        billType: 105,
        endTime: '',
        productId: '',
        startTime: '',
        billInOthersQuery: {
          inOrgId: '',
          inStoreId: '',
          otherTypeId: '',
          outOrgId: ''
        }
      },
      otherTypeName: '',
      productName: '',
      expandTxt: '展开',
      showCol: false,
      typeVisible: false,
      inOrgName: '',
      outOrgName: '',
      unitShow: false,
      unitType: '',
      inOutType: '7',
      shouFaType: '1'
    };
  },
  created: function created() {
    this.getStatusList();
    this.getSourceList();
  },
  mounted: function mounted() {
    var that = this;
  },
  methods: {
    mouseOver: function mouseOver() {
      this.$refs.arrow.style.color = '#409eff';
      this.$refs.arrowtest.style.color = '#409eff';
    },
    mouseLeave: function mouseLeave() {
      this.$refs.arrow.style.color = '#595959';
      this.$refs.arrowtest.style.color = '#595959';
    },
    handleFilter: function handleFilter(_type) {
      var that = this;
      if (_type == 'rest') {
        // 搜索表单重置
        that.searchForm = {
          billNo: '',
          billSource: '',
          billStatus: '',
          billType: 105,
          endTime: '',
          productId: '',
          startTime: '',
          billInOthersQuery: {
            inOrgId: '',
            inStoreId: '',
            otherTypeId: '',
            outOrgId: ''
          }
        };
        that.dateValue = [];
        that.inOrgName = '';
        that.outOrgName = '';
        that.otherTypeName = '';
        that.productName = '';
        that.$refs.searchForm.resetFields();
      }
      that.$parent.searchForm.current = 1;
      that.$parent.searchForm.size = 20;
      if (that.dateValue.length > 0) {
        that.searchForm.startTime = that.dateValue[0];
        that.searchForm.endTime = that.dateValue[1];
      } else {
        that.searchForm.startTime = '';
        that.searchForm.endTime = '';
      }
      that.$parent.searchList(this.searchForm);
    },
    // 获取产品
    openProductWin: function openProductWin(e) {
      this.$refs.selProduct.proSelVisible = true;
    },
    clearProduct: function clearProduct(e) {
      this.searchForm.productId = '';
      this.productName = '';
    },
    setSel: function setSel(val) {
      this.productName = val.productName;
      this.searchForm.productId = val.pId;
    },
    getStatusList: function getStatusList() {
      var _this = this;
      // 获取单据状态
      this.TypeDataComs.statusList = [];
      var param = {};
      param.code = 'DJZT';
      (0, _select.getDicItem)(param).then(function (res) {
        if (res.data.code != 200) {
          return;
        } else {
          /*
          *figure 1开头为企业单据状态 ；2开头为渠道单据状态 ；
          */
          _this.TypeDataComs.statusList = res.data.data.filter(function (item) {
            return /^1/.test(item.figure.toString()) && item.figure != 111 && item.figure != 109;
          });
        }
      }).catch(function () {});
    },
    getSourceList: function getSourceList() {
      var _this2 = this;
      // 获取单据来源
      this.TypeDataComs.sourceList = [];
      var param = {};
      param.code = 'DJLY';
      (0, _select.getDicItem)(param).then(function (res) {
        if (res.data.code != 200) {
          return;
        } else {
          _this2.TypeDataComs.sourceList = res.data.data;
        }
      }).catch(function () {});
    },
    // 展开收起搜索条件
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    },
    // 获取业务类型
    getTypes: function getTypes(e) {
      var that = this;
      that.typeVisible = true;
    },
    closeType: function closeType() {
      var that = this;
      that.typeVisible = false;
    },
    getType: function getType(val) {
      var that = this;
      that.searchForm.billInOthersQuery.otherTypeId = val.pid;
      that.otherTypeName = val.billName;
    },
    // 获取收货单位
    clearUnitIn: function clearUnitIn() {
      var that = this;
      that.inOrgName = '';
      that.searchForm.billInOthersQuery.inOrgId = '';
      that.searchForm.billInOthersQuery.inStoreId = '';
      that.TypeDataComs.StoreList = [];
    },
    clearUnitOut: function clearUnitOut() {
      var that = this;
      that.outOrgName = '';
      that.searchForm.billInOthersQuery.outOrgId = '';
    },
    getInUnit: function getInUnit(arg) {
      var that = this;
      that.unitType = arg;
      if (arg == 'in') {
        that.shouFaType = '1';
      }
      if (arg == 'out') {
        that.shouFaType = '2';
      }
      that.unitShow = true;
    },
    closeUnit: function closeUnit() {
      var that = this;
      that.unitShow = false;
    },
    fetchUnit: function fetchUnit(val) {
      var that = this;
      var type = that.unitType;
      if (type == 'in') {
        that.inOrgName = val.companyName;
        that.searchForm.billInOthersQuery.inOrgId = val.companyId;
        that.getHouses(val.companyId);
      }
      if (type == 'out') {
        that.outOrgName = val.companyName;
        that.searchForm.billInOthersQuery.outOrgId = val.companyId;
      }
    },
    // 获取关联库房
    getHouses: function getHouses(arg) {
      var that = this;
      var param = {
        orgOrFactoryId: arg
      };
      (0, _select.getRelateStore)(param).then(function (res) {
        if (res.data.code == 200) {
          that.TypeDataComs.StoreList = [];
          that.TypeDataComs.StoreList = res.data.data;
        }
      });
    },
    // 获取焦点关联库房
    getDownHouses: function getDownHouses() {
      var that = this;
      if (that.searchForm.billInOthersQuery.inOrgId == '') {
        that.$message.error('请选择收货单位');
        return;
      }
    }
  }
};
exports.default = _default;