var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "searchForm",
      staticClass: "search-condition",
      attrs: {
        model: _vm.searchForm,
        "status-icon": "",
        "label-position": _vm.formConfig.labelPosition,
        "label-width": _vm.formConfig.labelWidth,
      },
    },
    [
      _c(
        "div",
        { staticClass: "cols" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "orgCode", label: "所属企业:" } },
                    [
                      _c("treeselect", {
                        attrs: {
                          multiple: false,
                          "flatten-search-results": true,
                          "clear-value-text": "",
                          placeholder: "请选择组织...",
                          options: _vm.orgOptions,
                        },
                        on: { select: _vm.getOrgVal, input: _vm.emptyStores },
                        model: {
                          value: _vm.searchForm.orgCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "orgCode", $$v)
                          },
                          expression: "searchForm.orgCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "storageCode", label: "库房名称:" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择" },
                          on: { focus: _vm.getDownHouses },
                          model: {
                            value: _vm.searchForm.storageCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "storageCode", $$v)
                            },
                            expression: "searchForm.storageCode",
                          },
                        },
                        _vm._l(_vm.TypeDataComs.StoreList, function (item) {
                          return _c("el-option", {
                            key: item.pId,
                            attrs: {
                              label: item.storeHouseName,
                              value: item.pId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "productCode", label: "产品名称:" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          clearable: "",
                          "suffix-icon": "el-icon-search",
                          placeholder: "请选择",
                        },
                        on: {
                          focus: _vm.openProductWin,
                          clear: _vm.clearProduct,
                        },
                        model: {
                          value: _vm.productCode,
                          callback: function ($$v) {
                            _vm.productCode = $$v
                          },
                          expression: "productCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "storeStatus", label: "库存状态:" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.searchForm.storeStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "storeStatus", $$v)
                            },
                            expression: "searchForm.storeStatus",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: "" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "低库存", value: 1 },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "平库存", value: 2 },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "高库存", value: 3 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.showCol
                ? _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { prop: "beginDate", label: "创建起始时间:" },
                        },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "date",
                              placeholder: "选择日期",
                              "value-format": "yyyy-MM-dd",
                            },
                            model: {
                              value: _vm.searchForm.beginDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "beginDate", $$v)
                              },
                              expression: "searchForm.beginDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showCol
                ? _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "endDate", label: "创建结束时间:" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "date",
                              placeholder: "选择日期",
                              "value-format": "yyyy-MM-dd",
                            },
                            model: {
                              value: _vm.searchForm.endDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "endDate", $$v)
                              },
                              expression: "searchForm.endDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "arrowbox",
              on: { mouseover: _vm.mouseOver, mouseleave: _vm.mouseLeave },
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "btnbox",
                  attrs: { type: "text" },
                  on: {
                    click: function ($event) {
                      _vm.showCol = !_vm.showCol
                    },
                  },
                },
                [
                  _c("i", {
                    ref: "arrow",
                    class: _vm.showCol
                      ? "el-icon-caret-top"
                      : "el-icon-caret-bottom",
                    staticStyle: { color: "#595959 !important" },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    { ref: "arrowtest", staticStyle: { color: "#595959" } },
                    [
                      _vm._v(
                        _vm._s(_vm.showCol ? "收起" : "展开") + "\n        "
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("product-dialog", { ref: "selProduct", on: { change: _vm.setSel } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }