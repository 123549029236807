import { render, staticRenderFns } from "./take.vue?vue&type=template&id=6eaa5cfa&scoped=true&"
import script from "./take.vue?vue&type=script&lang=js&"
export * from "./take.vue?vue&type=script&lang=js&"
import style0 from "./take.vue?vue&type=style&index=0&id=6eaa5cfa&scoped=scoped&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6eaa5cfa",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6eaa5cfa')) {
      api.createRecord('6eaa5cfa', component.options)
    } else {
      api.reload('6eaa5cfa', component.options)
    }
    module.hot.accept("./take.vue?vue&type=template&id=6eaa5cfa&scoped=true&", function () {
      api.rerender('6eaa5cfa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/orderCenter/saleorder-cc/take.vue"
export default component.exports