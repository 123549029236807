var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "请选择",
        visible: _vm.taskVisible,
        "before-close": _vm.handleClose,
        width: "1200px !important",
        "append-to-body": true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.taskVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "page-section" },
        [
          _c(
            "el-form",
            {
              ref: "searchForm",
              staticClass: "search-condition",
              attrs: {
                model: _vm.searchForm,
                "status-icon": "",
                "label-position": _vm.formConfig.labelPosition,
                "label-width": _vm.formConfig.labelWidth,
              },
            },
            [
              _c(
                "div",
                { staticClass: "cols" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "orderNo",
                                label: "生产任务单号:",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  value: _vm.searchForm.orderNo,
                                  clearable: "",
                                  placeholder: "请输入",
                                  maxlength: "20",
                                },
                                on: {
                                  input: function (e) {
                                    return (_vm.searchForm.orderNo =
                                      _vm.validSe(e))
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.handleShow,
                              expression: "handleShow",
                            },
                          ],
                          attrs: { span: 6 },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { prop: "batchNo", label: "生产批次号:" },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                attrs: { clearable: "", placeholder: "请输入" },
                                model: {
                                  value: _vm.searchForm.batchNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "batchNo", $$v)
                                  },
                                  expression: "searchForm.batchNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "orderStatus",
                                label: "任务单状态:",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择",
                                  },
                                  model: {
                                    value: _vm.searchForm.orderStatus,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "orderStatus",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.orderStatus",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "执行完成", value: 16 },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "btnArea" },
            [
              _c(
                "el-col",
                {
                  staticClass: "head-btn-group",
                  staticStyle: { width: "205px" },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: _vm.btnConfig.searchBtn.type,
                        size: _vm.btnConfig.size,
                        icon: _vm.btnConfig.searchBtn.icon,
                      },
                      on: {
                        click: function ($event) {
                          _vm.searchForm.current = 1
                          _vm.getList()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                  ),
                  _vm._v(" "),
                  _c("el-divider", { attrs: { direction: "vertical" } }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: _vm.btnConfig.resetBtn.type,
                        size: _vm.btnConfig.size,
                        icon: _vm.btnConfig.resetBtn.icon,
                      },
                      on: { click: _vm.resetForm },
                    },
                    [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              key: _vm.tableKey,
              staticStyle: { width: "100%" },
              attrs: {
                height: _vm.tabHeight,
                data: _vm.tableData,
                border: _vm.tableConfig.border,
                stripe: _vm.tableConfig.stripe,
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  align: _vm.tableConfig.align,
                  width: "120",
                  label: "序号",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "单据来源",
                  prop: "proOrderNum",
                  width: "120",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", [
                          _vm._v(_vm._s(_vm._f("sourceText")(row.orderSource))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "生产任务单号",
                  prop: "orderNo",
                  width: "140",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.orderNo == "" || row.orderNo == null
                          ? _c("div", [_vm._v("空")])
                          : _c("div", [_vm._v(_vm._s(row.orderNo))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "生产批次",
                  prop: "batchNo",
                  width: "120",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.batchNo == "" || row.batchNo == null
                          ? _c("div", [_vm._v("空")])
                          : _c("div", [_vm._v(_vm._s(row.batchNo))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "产品编码",
                  prop: "materialCode",
                  "min-width": "140",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "产品名称",
                  prop: "materialName",
                  width: "140",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "规格",
                  prop: "productModel",
                  "min-width": "120",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "包装比例",
                  prop: "packScaleDesc",
                  width: "140",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "库房名称",
                  prop: "storehouseName",
                  width: "120",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.storehouseName == "" || row.storehouseName == null
                          ? _c("div", [_vm._v("空")])
                          : _c("div", [_vm._v(_vm._s(row.storehouseName))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "工厂名称",
                  prop: "factoryName",
                  width: "120",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "车间名称",
                  prop: "shopName",
                  width: "120",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "产线名称",
                  prop: "productLineName",
                  width: "120",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "班组",
                  prop: "teamName",
                  width: "120",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "计划数量(箱)",
                  prop: "planNum",
                  width: "120",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "最小单位(盒)",
                  prop: "miniUnit",
                  width: "120",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "实际上传量",
                  width: "140",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            staticStyle: { padding: "0" },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.codeDetail(row.orderNo)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                row.backChestSum ? row.backChestSum + "箱" : ""
                              ) +
                                _vm._s(
                                  row.backBottleSum
                                    ? "(" + row.backBottleSum + "盒)"
                                    : ""
                                )
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "有效生产量",
                  prop: "realProductNumOfBottle",
                  width: "140",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            staticStyle: { padding: "0" },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.codeDetail(row.orderNo)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                row.effectiveChestSum
                                  ? row.effectiveChestSum + "箱"
                                  : ""
                              ) +
                                _vm._s(
                                  row.effectiveBottleSum
                                    ? "(" + row.effectiveBottleSum + "盒)"
                                    : ""
                                )
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "任务单状态",
                  prop: "materialCode",
                  width: "130",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.orderStatus == 16
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v("执行完成"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "创建人",
                  prop: "creatorId",
                  width: "120",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.creatorId == "" || row.creatorId == null
                          ? _c("div", [_vm._v("空")])
                          : _c("div", [_vm._v(_vm._s(row.creatorId))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "创建时间",
                  prop: "createTime",
                  width: "180",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.createTime == "" || row.createTime == null
                          ? _c("div", [_vm._v("空")])
                          : _c("div", [_vm._v(_vm._s(row.createTime))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  fixed: "right",
                  align: _vm.tableConfig.align,
                  width: "100",
                  "class-name": "small-padding fixed-width",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.getDetail(row)
                              },
                            },
                          },
                          [_vm._v("选择")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              layout: "total,sizes,prev, pager, next, jumper",
              total: _vm.total,
              page: _vm.searchForm.current,
              limit: _vm.searchForm.size,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.searchForm, "current", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.searchForm, "size", $event)
              },
              pagination: _vm.getList,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }