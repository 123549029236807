var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.dialogVisible,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.mloading,
              expression: "mloading",
            },
          ],
          ref: "scanForm",
          staticClass: "info-items",
          attrs: {
            model: _vm.scanData,
            rules: _vm.rules,
            "label-position": "right",
            "label-width": "150px",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "调拨出库单号:", prop: "billNo" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "350px" },
                        attrs: {
                          clearable: "",
                          maxlength: "20",
                          "show-word-limit": "",
                          disabled: _vm.disabled.num,
                        },
                        on: {
                          input: function (e) {
                            return (_vm.scanData.billNo = _vm.validSe(e))
                          },
                        },
                        model: {
                          value: _vm.scanData.billNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.scanData, "billNo", $$v)
                          },
                          expression: "scanData.billNo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "发货单位:",
                        prop: "billoutTransAO.outOrgId",
                      },
                    },
                    [
                      _c(
                        "el-input",
                        {
                          staticStyle: { width: "350px" },
                          attrs: { readonly: "" },
                          model: {
                            value: _vm.outComName,
                            callback: function ($$v) {
                              _vm.outComName = $$v
                            },
                            expression: "outComName",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: { slot: "append", icon: "el-icon-search" },
                            on: {
                              click: function ($event) {
                                return _vm.openFactory(
                                  "outComName",
                                  true,
                                  "",
                                  1
                                )
                              },
                            },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "收货单位:",
                        prop: "billoutTransAO.inOrgId",
                      },
                    },
                    [
                      _c(
                        "el-input",
                        {
                          staticStyle: { width: "350px" },
                          attrs: { readonly: "" },
                          model: {
                            value: _vm.inComName,
                            callback: function ($$v) {
                              _vm.inComName = $$v
                            },
                            expression: "inComName",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: { slot: "append", icon: "el-icon-search" },
                            on: {
                              click: function ($event) {
                                return _vm.openFactory("inComName", true, "", 1)
                              },
                            },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "收货人:", prop: "receiptUser" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "350px" },
                        model: {
                          value: _vm.scanData.receiptUser,
                          callback: function ($$v) {
                            _vm.$set(_vm.scanData, "receiptUser", $$v)
                          },
                          expression: "scanData.receiptUser",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "发货库房:",
                        prop: "billoutTransAO.outStoreId",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "350px" },
                          attrs: { clearable: "", placeholder: "请选择" },
                          on: { change: _vm.setStore },
                          model: {
                            value: _vm.scanData.billoutTransAO.outStoreId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.scanData.billoutTransAO,
                                "outStoreId",
                                $$v
                              )
                            },
                            expression: "scanData.billoutTransAO.outStoreId",
                          },
                        },
                        _vm._l(_vm.storeList, function (item) {
                          return _c("el-option", {
                            key: item.pId,
                            attrs: {
                              label: item.storeHouseName,
                              value: item.pId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品明细:", prop: "materialCode" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.openSel },
                        },
                        [_vm._v("添加产品")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.listLoading,
                              expression: "listLoading",
                            },
                          ],
                          key: _vm.tableKey,
                          staticStyle: { width: "100%", "margin-top": "1px" },
                          attrs: {
                            data: _vm.productList,
                            border: _vm.tableConfig.border,
                            stripe: _vm.tableConfig.stripe,
                            fit: "",
                            "highlight-current-row": "",
                            "max-height": "500",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "产品编码",
                              "min-width": "100",
                              prop: "productCode",
                              align: _vm.tableConfig.align,
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "产品名称",
                              "min-width": "150",
                              prop: "productName",
                              align: _vm.tableConfig.align,
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "包装比例",
                              "min-width": "100",
                              prop: "packScaleExpression",
                              align: _vm.tableConfig.align,
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          row.packScaleDesc
                                            ? row.packScaleDesc
                                            : row.packScaleExpression
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "计划最大单位数",
                              prop: "packScaleDesc",
                              align: _vm.tableConfig.align,
                              "min-width": "160",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { margin: "20px auto" },
                                        attrs: {
                                          label: "",
                                          "label-width": "0",
                                          prop: "planScanNum",
                                        },
                                      },
                                      [
                                        _c("el-input-number", {
                                          staticStyle: { width: "100px" },
                                          attrs: {
                                            disabled: true,
                                            rules: {
                                              required: true,
                                              message: "计划产量不能为空",
                                              trigger: "blur",
                                            },
                                            controls: false,
                                            precision: 0,
                                            min: 0,
                                            max: _vm.maxNum(row),
                                          },
                                          model: {
                                            value: row.planScanNum,
                                            callback: function ($$v) {
                                              _vm.$set(row, "planScanNum", $$v)
                                            },
                                            expression: "row.planScanNum",
                                          },
                                        }),
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm._f("unitName2")(
                                                row.packScaleLevelItemDTOList
                                              )
                                            ) +
                                            "\n                "
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "计划最小单位数",
                              prop: "planScanAtoNum",
                              align: _vm.tableConfig.align,
                              "min-width": "160",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("el-input-number", {
                                      staticStyle: { width: "100px" },
                                      attrs: {
                                        controls: false,
                                        precision: 0,
                                        max: _vm.maxNum2(row),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.changeChestNum($event, row)
                                        },
                                      },
                                      model: {
                                        value: row.planScanAtoNum,
                                        callback: function ($$v) {
                                          _vm.$set(row, "planScanAtoNum", $$v)
                                        },
                                        expression: "row.planScanAtoNum",
                                      },
                                    }),
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(row.minScaleName) +
                                        "\n              "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "库存",
                              align: _vm.tableConfig.align,
                              prop: "storeInventory",
                              "min-width": "100",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(row.storeInventory) +
                                          _vm._s(
                                            _vm._f("unitName2")(
                                              row.packScaleLevelItemDTOList
                                            )
                                          )
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              align: _vm.tableConfig.align,
                              "min-width": "80",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.proDel(row)
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { staticClass: "dialog-footer" },
                    [
                      _vm.submitType != 3
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.btn_loading,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.submitForm(2)
                                },
                              },
                            },
                            [_vm._v("提 交")]
                          )
                        : _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.btn_loading,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.submitForm(3)
                                },
                              },
                            },
                            [_vm._v("提 交")]
                          ),
                      _vm._v(" "),
                      _c("el-button", { on: { click: _vm.handleClose } }, [
                        _vm._v("关 闭"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.proShow
        ? _c("product-dialog", {
            ref: "selProduct",
            attrs: {
              "is-code": "1",
              "pro-sel-visible": _vm.proShow,
              data: _vm.productList,
            },
            on: {
              "update:data": function ($event) {
                _vm.productList = $event
              },
              change: _vm.setSel,
              close: _vm.proClose,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.factoryShow
        ? _c("factorySelectDialog", {
            attrs: {
              visible: _vm.factoryShow,
              "can-change": _vm.factorycanChange,
              "company-type": _vm.factoryType,
              "is-enable": _vm.isEnable,
            },
            on: { change: _vm.setFactory, close: _vm.factoryClose },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }