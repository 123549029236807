var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "tableDialog",
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: "经销商选择",
            visible: _vm.visible,
            width: "1100px !important",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "page-container" },
            [
              _c(
                "el-row",
                { attrs: { type: "flex", gutter: 24 } },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "page-section",
                      staticStyle: {
                        "padding-left": "0",
                        "padding-right": "0",
                      },
                      attrs: { span: 18 },
                    },
                    [
                      _c(
                        "el-form",
                        {
                          staticClass: "search-condition",
                          attrs: {
                            model: _vm.listQuery,
                            "label-position": _vm.formConfig.labelPosition,
                            "label-width": _vm.formConfig.labelWidth,
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "cols" },
                            [
                              _c(
                                "el-row",
                                { attrs: { gutter: 20 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "channelName",
                                            label: "经销商名称",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "经销商名称",
                                            },
                                            model: {
                                              value: _vm.listQuery.channelName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.listQuery,
                                                  "channelName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "listQuery.channelName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "head-btn-group",
                                      attrs: { span: 8 },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { "label-width": "0" } },
                                        [
                                          _c(
                                            "div",
                                            {
                                              attrs: { slot: "label" },
                                              slot: "label",
                                            },
                                            [_vm._v(" ")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: _vm.btnConfig.searchBtn
                                                  .type,
                                                size: _vm.btnConfig.size,
                                                icon: _vm.btnConfig.searchBtn
                                                  .icon,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.listQuery.current = 1
                                                  _vm.fetchData()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.btnConfig.searchBtn.text
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: _vm.btnConfig.resetBtn
                                                  .type,
                                                size: _vm.btnConfig.size,
                                                icon: _vm.btnConfig.resetBtn
                                                  .icon,
                                              },
                                              on: { click: _vm.restFun },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.btnConfig.resetBtn.text
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.listLoading,
                              expression: "listLoading",
                            },
                          ],
                          ref: "multipleTable",
                          staticClass: "multipleTable",
                          attrs: {
                            height: "400px",
                            data: _vm.list,
                            fit: "",
                            border: _vm.tableConfig.border,
                            stripe: _vm.tableConfig.stripe,
                            "row-key": function (row) {
                              return row.channelCode
                            },
                            "highlight-current-row": "",
                          },
                          on: { "selection-change": _vm.handleSelectionChange },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              type: "selection",
                              "reserve-selection": true,
                              width: "55",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "channelName",
                              label: "经销商名称",
                              "show-overflow-tooltip": "",
                              align: _vm.tableConfig.align,
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "channelCode",
                              label: "经销商编码",
                              "show-overflow-tooltip": "",
                              align: _vm.tableConfig.align,
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "saleOrg2Name",
                              label: "所属战区",
                              "show-overflow-tooltip": "",
                              align: _vm.tableConfig.align,
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "saleOrg3Name",
                              label: "所属战队",
                              "show-overflow-tooltip": "",
                              align: _vm.tableConfig.align,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("pagination", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.total > 0,
                            expression: "total>0",
                          },
                        ],
                        attrs: {
                          total: _vm.total,
                          page: _vm.listQuery.current,
                          limit: _vm.listQuery.size,
                        },
                        on: {
                          "update:page": function ($event) {
                            return _vm.$set(_vm.listQuery, "current", $event)
                          },
                          "update:limit": function ($event) {
                            return _vm.$set(_vm.listQuery, "size", $event)
                          },
                          pagination: _vm.fetchData,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticClass: "page-section leftSide",
                      attrs: { span: 6 },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "filter-container" },
                        [
                          _c(
                            "el-row",
                            {
                              staticStyle: {
                                height: "652px",
                                position: "relative",
                              },
                              attrs: { gutter: 10 },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "multipleSelectNumber" },
                                [
                                  _vm._v(
                                    "已选中" +
                                      _vm._s(_vm.multipleSelection.length) +
                                      "条数据"
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-table",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.selLoading,
                                      expression: "selLoading",
                                    },
                                  ],
                                  staticClass: "multipleTable",
                                  staticStyle: {
                                    width: "100%",
                                    "margin-top": "1px",
                                  },
                                  attrs: {
                                    data: _vm.multipleSelection,
                                    fit: "",
                                    height: "100%",
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "已选",
                                      "min-width": "100",
                                      prop: "channelName",
                                      align: "left",
                                      "show-overflow-tooltip": "",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(scope.row.channelName)
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: { width: "70", align: "left" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("el-button", {
                                              staticStyle: { color: "#99a3ad" },
                                              attrs: {
                                                type: "text",
                                                icon: "el-icon-delete-solid",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.tag1Close(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.clear } }, [_vm._v("清 空")]),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.update } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }