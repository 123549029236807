"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.split");
var _auditLog = require("@/api/fake_expel/auditLog");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _channelSelectDialog = _interopRequireDefault(require("@/components/channelSelectDialog"));
var _productSelectDialog = _interopRequireDefault(require("@/components/productSelectDialog"));
var _utils = require("@/utils");
var _product = require("@/api/basic/product");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Kctz',
  components: {
    Pagination: _Pagination.default,
    instockUnit: _channelSelectDialog.default,
    productChose: _productSelectDialog.default
  },
  filters: {
    typeName: function typeName(status) {
      if (!String(status)) return '';
      var statusMap = {
        0: '企业',
        1: '经销商',
        2: '终端',
        3: '团购客户',
        4: '消费者'
      };
      return statusMap[status];
    },
    statusName: function statusName(status) {
      if (!String(status)) return '';
      var statusMap = {
        1: '正常',
        2: '疑似窜货',
        3: '地理信息无法匹配'
      };
      return statusMap[status];
    },
    billType: function billType(status) {
      if (!String(status)) return '';
      var statusMap = {
        101: '生产入库',
        102: '调货入库',
        103: '退货入库',
        104: '采购入库',
        105: '材料入库',
        201: '返工出库',
        202: '调货出库',
        203: '销售出库',
        204: '退货出库',
        205: '材料出库'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      listQuery: {
        startTime: null,
        endTime: null,
        channelCode: null,
        channelName: null,
        inspectionCode: null,
        productCode: null,
        productName: null,
        status: null,
        userAccount: null,
        userName: null,
        current: 1,
        ycyjlx: -1,
        productBrandId: '',
        size: 20
      },
      date: [],
      activeName: null,
      tableKey: 'jcrz',
      tabHeight: '100%',
      list: [],
      listLoading: false,
      dialogVisible: false,
      dialogloading: false,
      total: 0,
      showCol: false,
      expandTxt: '展开',
      scanData: {},
      uploadImgs: [],
      excelOut_loading: false,
      brandList: []
    };
  },
  activated: function activated() {
    this.getList();
    this.getBrand();
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    },
    showPro: function showPro() {
      this.$refs.selProduct.proSelVisible = true;
    },
    setPro: function setPro(val) {
      this.listQuery.productCode = val.productCode;
      this.listQuery.productName = val.productName;
    },
    getUnit: function getUnit() {
      this.$refs.channelSelect.proSelVisible = true;
    },
    setCSel: function setCSel(val) {
      this.listQuery.channelCode = val.channelCode;
      this.listQuery.channelName = val.channelName;
    },
    DoSearch: function DoSearch() {
      this.listQuery.current = 1;
      this.getList();
    },
    DoReset: function DoReset() {
      this.listQuery = {
        startTime: null,
        endTime: null,
        channelCode: null,
        channelName: null,
        inspectionCode: null,
        productCode: null,
        productName: null,
        status: null,
        userAccount: null,
        userName: null,
        current: 1,
        ycyjlx: -1,
        productBrandId: '',
        size: 20
      };
      this.date = [];
      this.getList();
      this.brandData = '';
    },
    getList: function getList() {
      this.authId('searchbtn', this.$route.meta.authList);
      var that = this;
      that.listLoading = true;
      if (this.date.length) {
        that.listQuery.startTime = this.date[0];
        that.listQuery.endTime = this.date[1];
      } else {
        that.listQuery.startTime = null;
        that.listQuery.endTime = null;
      }
      var sdata = JSON.parse(JSON.stringify(that.listQuery));
      (0, _auditLog.queryForPage)(sdata).then(function (res) {
        if (res.data.code == 200) {
          that.list = res.data.data.records;
          that.total = parseInt(res.data.data.total);
          that.listLoading = false;
        } else {
          that.$message.error(res.data.msg);
          that.listLoading = false;
          return;
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    detail: function detail(row) {
      var _this = this;
      this.authId('detailbtn', this.$route.meta.authList);
      this.dialogVisible = true;
      this.dialogloading = true;
      (0, _auditLog.getDetailById)({
        pId: row.pid
      }).then(function (res) {
        _this.dialogloading = false;
        if (res.data.code == 200) {
          _this.uploadImgs = res.data.data.imgs ? res.data.data.imgs.split(',') : [];
          if (res.data.data.logiscList && res.data.data.logiscList.length > 0) {
            res.data.data.logiscList.forEach(function (item) {
              if (item.outgoType == 1) {
                item.topCode = item.sendCode ? '(' + item.sendCode + ')' : '';
                item.topName = item.sendName ? item.sendName : '';
              } else {
                item.topCode = item.recCode ? '(' + item.recCode + ')' : '';
                item.topName = item.recName ? item.recName : '';
              }
            });
          }
          _this.scanData = res.data.data;
        } else {
          _this.dialogloading = false;
          _this.$message.error(res.data.msg);
        }
      });
    },
    showClose: function showClose() {
      this.authId('searchbtn', this.$route.meta.authList);
      this.dialogVisible = false;
      this.activeName = null;
      this.scanData = {};
      this.uploadImgs = [];
    },
    excelOut: function excelOut() {
      var _this2 = this;
      this.excelOut_loading = true;
      if (this.date.length) {
        this.listQuery.startTime = this.date[0];
        this.listQuery.endTime = this.date[1];
      } else {
        this.listQuery.startTime = null;
        this.listQuery.endTime = null;
      }
      var sdata = JSON.parse(JSON.stringify(this.listQuery));
      (0, _auditLog.excelQueryForPage)(sdata).then(function (res) {
        _this2.excelOut_loading = false;
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '稽查日志.xlsx';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      });
    },
    getBrand: function getBrand() {
      var _this3 = this;
      (0, _product.productBrandAllListList)().then(function (res) {
        if (res.data.code != 200) {
          _this3.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            _this3.brandList = res.data.data;
          } else {
            _this3.brandList = [];
          }
        }
      });
    }
  }
};
exports.default = _default;