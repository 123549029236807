var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.apis)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c(
                "el-form",
                {
                  ref: "formInline",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.formInline,
                    "status-icon": "",
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                    "label-suffix": ":",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cols" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 10 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "type", label: "奖品类型" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择" },
                                      model: {
                                        value: _vm.formInline.type,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.formInline, "type", $$v)
                                        },
                                        expression: "formInline.type",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "红包", value: "1101" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "state", label: "状态" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "filter-item",
                                      attrs: {
                                        placeholder: "请选择",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.formInline.state,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.formInline, "state", $$v)
                                        },
                                        expression: "formInline.state",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "全部", value: "" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { label: "启用", value: "1" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { label: "禁用", value: "2" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "title", label: "搜索" } },
                                [
                                  _c("el-input", {
                                    staticClass: "filter-item",
                                    attrs: { placeholder: "请输入红包名称" },
                                    model: {
                                      value: _vm.formInline.title,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formInline, "title", $$v)
                                      },
                                      expression: "formInline.title",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.formConfig.btnFollow
                            ? _c(
                                "el-col",
                                {
                                  staticClass: "head-btn-group",
                                  style: { width: _vm.formConfig.btnArea },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { "label-width": "0" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v(" ")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.searchBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.searchBtn.icon,
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.formInline.pageindex = 1
                                              _vm.fetchData()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.searchBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.resetBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.resetBtn.icon,
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.restForm("formInline")
                                              _vm.fetchData()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.resetBtn.text)
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.formConfig.btnFollow
                ? _c("el-divider", { staticClass: "btnDivider" })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "phead" },
                [
                  !_vm.formConfig.btnFollow
                    ? _c(
                        "el-col",
                        {
                          staticClass: "head-btn-group",
                          style: { width: _vm.formConfig.btnArea },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.searchBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.searchBtn.icon,
                              },
                              on: {
                                click: function ($event) {
                                  _vm.formInline.pageindex = 1
                                  _vm.fetchData()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.resetBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.resetBtn.icon,
                              },
                              on: {
                                click: function ($event) {
                                  _vm.restForm("formInline")
                                  _vm.fetchData()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "head-btn-group" },
                    [
                      _vm.hasAuth("addbtn", _vm.apis)
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.addBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.addBtn.icon,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.getDetail(0)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.addBtn.text))]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasAuth("importbtn", _vm.apis)
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.downLoadBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.downLoadBtn.icon,
                                loading: _vm.downloadLoading,
                              },
                              on: { click: _vm.download },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.downLoadBtn.text))]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  attrs: {
                    height: _vm.tabHeight,
                    data: _vm.list,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    "element-loading-text": "Loading",
                    fit: "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "奖项名称",
                      prop: "alias",
                      "show-overflow-tooltip": "",
                      "min-width": "150",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "红包类型",
                      prop: "typeName",
                      "min-width": "100",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm._f("redpacktype")(scope.row.redpacktype)
                                )
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2744342756
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "红包金额",
                      "min-width": "100",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [_vm._v(_vm._s(scope.row.minValue) + " 元")]
                          },
                        },
                      ],
                      null,
                      false,
                      2150488020
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "状态",
                      "min-width": "70",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.status == 2 && scope.row.isdelete == 0
                                ? _c("el-tag", { attrs: { type: "danger" } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("stateName")(scope.row.status)
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.status == 1 && scope.row.isdelete == 0
                                ? _c("el-tag", { attrs: { type: "success" } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("stateName")(scope.row.status)
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.isdelete == 1
                                ? _c("el-tag", { attrs: { type: "danger" } }, [
                                    _vm._v("已删除"),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3541291774
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "创建时间",
                      prop: "createTime",
                      width: "160",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "最新修改时间",
                      prop: "updateTime",
                      width: "160",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: _vm.tableConfig.align,
                      label: "操作",
                      fixed: "right",
                      width: "230",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm.hasAuth("scanbtn", _vm.apis)
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass: "button configText",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.getDetail(
                                            1,
                                            scope.row.type,
                                            scope.row.id
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("详情")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.hasAuth("jumpbtn", _vm.apis)
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass: "button configText",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.jumpLink(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("记录")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "el-dropdown",
                                { staticClass: "table-dropdown" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "el-dropdown-link" },
                                    [
                                      _vm._v(
                                        "\n              更多\n              "
                                      ),
                                      _c("i", {
                                        staticClass:
                                          "el-icon-arrow-down el-icon--right",
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-dropdown-menu",
                                    {
                                      attrs: { slot: "dropdown" },
                                      slot: "dropdown",
                                    },
                                    [
                                      _c(
                                        "el-dropdown-item",
                                        [
                                          scope.row.status * 1 === 2 &&
                                          scope.row.isdelete != 1 &&
                                          _vm.hasAuth("updatebtn", _vm.apis)
                                            ? _c(
                                                "el-button",
                                                {
                                                  staticClass:
                                                    "button configText",
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.getDetail(
                                                        2,
                                                        scope.row.type,
                                                        scope.row.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("编辑")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-dropdown-item",
                                        [
                                          scope.row.status * 1 === 2 &&
                                          scope.row.isdelete != 1 &&
                                          _vm.hasAuth("startbtn", _vm.apis)
                                            ? _c(
                                                "el-button",
                                                {
                                                  staticClass:
                                                    "button configText",
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.enableItem(
                                                        scope.row.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("启用")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-dropdown-item",
                                        [
                                          scope.row.status * 1 === 1 &&
                                          scope.row.isdelete != 1 &&
                                          _vm.hasAuth("stopbtn", _vm.apis)
                                            ? _c(
                                                "el-button",
                                                {
                                                  staticClass:
                                                    "button configText",
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.disableItem(
                                                        scope.row.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("禁用")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-dropdown-item",
                                        [
                                          scope.row.status * 1 === 2 &&
                                          scope.row.isdelete != 1 &&
                                          _vm.hasAuth("deletebtn", _vm.apis)
                                            ? _c(
                                                "el-button",
                                                {
                                                  staticClass:
                                                    "button configText",
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.del(
                                                        scope.row.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("删除")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-dropdown-item",
                                        [
                                          _vm.hasAuth("logbtn", _vm.apis)
                                            ? _c(
                                                "el-button",
                                                {
                                                  staticClass:
                                                    "button configText",
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.routerLinkFun(
                                                        0
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("日志")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2275725649
                    ),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total>0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.formInline.pageindex,
                  limit: _vm.formInline.pagesize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.formInline, "pageindex", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.formInline, "pagesize", $event)
                  },
                  pagination: _vm.fetchData,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.apis)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "cardDialog",
          attrs: {
            "close-on-click-modal": false,
            title: _vm.dialogTitle,
            visible: _vm.dialogVisible,
            "before-close": _vm.cancel,
            width: "900px",
          },
        },
        [
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.dialogName == "index",
                  expression: "dialogName=='index'",
                },
              ],
              attrs: { gutter: 20 },
            },
            _vm._l(_vm.prisList, function (item, index) {
              return _c(
                "el-col",
                { key: index, attrs: { span: 24 } },
                [
                  _c(
                    "el-card",
                    { attrs: { "body-style": { padding: "0px" } } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "clearfix",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [
                          _c("span", {
                            staticClass: "cardDialogTitle",
                            domProps: { textContent: _vm._s(item.title) },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "imgWrap" }, [
                        _c("img", {
                          staticClass: "image",
                          attrs: { src: item.img },
                        }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            padding: "20px",
                            "text-align": "center",
                            "border-top": "1px solid #ebeef5",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "bottom clearfix" },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "normalBtn",
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      _vm.dialogName = item.title
                                    },
                                  },
                                },
                                [_vm._v("使用模版")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.dialogName == "红包",
                  expression: "dialogName=='红包'",
                },
              ],
              staticClass: "row-bg",
              attrs: { type: "flex", justify: "space-around" },
            },
            [
              _c(
                "el-col",
                { staticStyle: { width: "600px" } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "hongbaoForm",
                      staticStyle: { margin: "10 auto" },
                      attrs: {
                        rules: _vm.hongbaoRules,
                        model: _vm.hongbaoForm,
                        "label-position": "right",
                        "label-width": "180px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "奖项名称:", prop: "alias" } },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.hongbaoForm.alias,
                                expression: "hongbaoForm.alias",
                              },
                            ],
                            staticClass: "el-input__inner",
                            class: { disabled: _vm.disabled },
                            attrs: {
                              type: "text",
                              placeholder: "不能超过30个字母/10个汉字",
                              disabled: _vm.disabled,
                            },
                            domProps: { value: _vm.hongbaoForm.alias },
                            on: {
                              input: [
                                function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.hongbaoForm,
                                    "alias",
                                    $event.target.value
                                  )
                                },
                                function ($event) {
                                  _vm.hongbaoForm.alias =
                                    _vm.fixedOnInputChange(
                                      $event.target.value,
                                      30
                                    )
                                },
                              ],
                            },
                          }),
                          _vm._v(" "),
                          !_vm.disabled
                            ? _c("p", { staticClass: "formTip" }, [
                                _c("i", {
                                  staticClass: "el-icon-warning-outline",
                                }),
                                _vm._v(
                                  " 提示：奖项名称用于配置活动时使用，命名不允许重复。\n            "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "红包名称:", prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "不能超过5个字符",
                              disabled: _vm.disabled,
                              type: "text",
                              maxlength: "5",
                            },
                            model: {
                              value: _vm.hongbaoForm.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.hongbaoForm, "name", $$v)
                              },
                              expression: "hongbaoForm.name",
                            },
                          }),
                          _vm._v(" "),
                          !_vm.disabled
                            ? _c("p", { staticClass: "formTip" }, [
                                _c("i", {
                                  staticClass: "el-icon-warning-outline",
                                }),
                                _vm._v(
                                  " 提示：红包名称用于展示给消费者，命名允许重复。\n            "
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "发送者名称:", prop: "sender" } },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.hongbaoForm.sender,
                                expression: "hongbaoForm.sender",
                              },
                            ],
                            staticClass: "el-input__inner",
                            class: { disabled: _vm.disabled },
                            attrs: {
                              type: "text",
                              placeholder: "不能超过30个字母/10个汉字",
                              disabled: _vm.disabled,
                            },
                            domProps: { value: _vm.hongbaoForm.sender },
                            on: {
                              input: [
                                function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.hongbaoForm,
                                    "sender",
                                    $event.target.value
                                  )
                                },
                                function ($event) {
                                  _vm.hongbaoForm.sender =
                                    _vm.fixedOnInputChange(
                                      $event.target.value,
                                      30
                                    )
                                },
                              ],
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "祝福语:", prop: "blessing" } },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.hongbaoForm.blessing,
                                expression: "hongbaoForm.blessing",
                              },
                            ],
                            staticClass: "el-input__inner",
                            class: { disabled: _vm.disabled },
                            attrs: {
                              type: "textarea",
                              placeholder: "不能超过120个字母/40个汉字",
                              disabled: _vm.disabled,
                            },
                            domProps: { value: _vm.hongbaoForm.blessing },
                            on: {
                              input: [
                                function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.hongbaoForm,
                                    "blessing",
                                    $event.target.value
                                  )
                                },
                                function ($event) {
                                  _vm.hongbaoForm.blessing =
                                    _vm.fixedOnInputChange(
                                      $event.target.value,
                                      120
                                    )
                                },
                              ],
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "单个红包金额:", prop: "value" } },
                        [
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 18 } },
                              [
                                _c("el-input", {
                                  directives: [
                                    {
                                      name: "only-number",
                                      rawName: "v-only-number",
                                      value: {
                                        max: 200,
                                        min: 1,
                                        precision: 2,
                                        set: _vm.hongbaoForm,
                                        name: "minValue",
                                      },
                                      expression:
                                        "{max:200,min:1,precision:2,set: hongbaoForm, name:'minValue'}",
                                    },
                                  ],
                                  staticStyle: { width: "46%", float: "left" },
                                  attrs: {
                                    placeholder: "请输入金额",
                                    disabled: _vm.disabled,
                                  },
                                  model: {
                                    value: _vm.hongbaoForm.minValue,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.hongbaoForm, "minValue", $$v)
                                    },
                                    expression: "hongbaoForm.minValue",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          _vm._v(" "),
                          !_vm.disabled
                            ? _c(
                                "el-col",
                                {
                                  staticStyle: { "text-align": "center" },
                                  attrs: { span: 5 },
                                },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        placement: "right-start",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "font-size": "14px",
                                            "line-height": "22px",
                                          },
                                          attrs: { slot: "content" },
                                          slot: "content",
                                        },
                                        [
                                          _vm._v(
                                            "\n                  微信默认场景红包金额最\n                  "
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            "小值为1元-最大值为200元\n                  "
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            "如需调整请于微信商户后\n                  "
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            "台进行，如果微信商户后\n                  "
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            "台未开通场景，会导致红\n                  "
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            "包发放失败。\n                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: { "font-size": "14px" },
                                          attrs: { type: "text" },
                                        },
                                        [_vm._v("如何设置金额")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "金额类型:", prop: "redpacktype" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticStyle: {
                                height: "40px",
                                "padding-top": "12px",
                              },
                              attrs: { disabled: _vm.disabled },
                              model: {
                                value: _vm.hongbaoForm.redpacktype,
                                callback: function ($$v) {
                                  _vm.$set(_vm.hongbaoForm, "redpacktype", $$v)
                                },
                                expression: "hongbaoForm.redpacktype",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "1" } }, [
                                _vm._v("普通红包"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: "4" } }, [
                                _vm._v("企业转账"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "奖品图片:", prop: "imgUrl" } },
                        [
                          _c("Upload-img", {
                            ref: "hongbaoUpload",
                            attrs: {
                              data: _vm.hongbaoForm.imgUrl,
                              disabled: _vm.disabled,
                              text: "（建议尺寸：150px*150px，只支持.png,.jpg 格式）",
                            },
                            on: {
                              "update:data": function ($event) {
                                return _vm.$set(
                                  _vm.hongbaoForm,
                                  "imgUrl",
                                  $event
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("el-input", {
                            staticStyle: { display: "none" },
                            attrs: { type: "hidden" },
                            model: {
                              value: _vm.hongbaoForm.imgUrl,
                              callback: function ($$v) {
                                _vm.$set(_vm.hongbaoForm, "imgUrl", $$v)
                              },
                              expression: "hongbaoForm.imgUrl",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注:" } },
                        [
                          _c(
                            "el-col",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  rows: 2,
                                  placeholder: "不能超过120个字母/40个汉字",
                                  autosize: { maxRows: 120 },
                                  disabled: true,
                                },
                                model: {
                                  value: _vm.hongbaoForm.remark,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.hongbaoForm, "remark", $$v)
                                  },
                                  expression: "hongbaoForm.remark",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "领取时是否需要填写信息:" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticStyle: {
                                height: "40px",
                                "padding-top": "12px",
                              },
                              attrs: { disabled: _vm.disabled },
                              model: {
                                value: _vm.hongbaoForm.getuserinfo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.hongbaoForm, "getuserinfo", $$v)
                                },
                                expression: "hongbaoForm.getuserinfo",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "1" } }, [
                                _vm._v("是"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: "2" } }, [
                                _vm._v("否"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.hongbaoForm.getuserinfo == 1
                        ? _c(
                            "el-form-item",
                            { key: "1", attrs: { label: "内容:" } },
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  attrs: { disabled: _vm.disabled },
                                  model: {
                                    value: _vm.hongbaoForm.checkList,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.hongbaoForm,
                                        "checkList",
                                        $$v
                                      )
                                    },
                                    expression: "hongbaoForm.checkList",
                                  },
                                },
                                _vm._l(_vm.info, function (item, index) {
                                  return _c(
                                    "el-checkbox",
                                    {
                                      key: index,
                                      staticStyle: {
                                        width: "85px",
                                        "margin-right": "0",
                                      },
                                      attrs: { label: index },
                                    },
                                    [_vm._v(_vm._s(item.name))]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-col",
                            {
                              staticStyle: {
                                "text-align": "center",
                                "margin-top": "20px",
                              },
                            },
                            [
                              !_vm.disabled
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass: "normalBtn",
                                      attrs: { type: "primary" },
                                      on: { click: _vm.subHongbao },
                                    },
                                    [_vm._v("保存")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "normalBtn",
                                  on: { click: _vm.cancel },
                                },
                                [_vm._v("取消")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.dialogName == "实物",
                  expression: "dialogName=='实物'",
                },
              ],
              staticClass: "row-bg",
              attrs: { type: "flex", justify: "space-around" },
            },
            [
              _c(
                "el-col",
                { staticStyle: { width: "600px" } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "shiwuForm",
                      staticStyle: { margin: "10 auto" },
                      attrs: {
                        rules: _vm.shiwuRules,
                        model: _vm.shiwuForm,
                        "label-position": "right",
                        "label-width": "180px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "奖项名称:", prop: "alias" } },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.shiwuForm.alias,
                                expression: "shiwuForm.alias",
                              },
                            ],
                            staticClass: "el-input__inner",
                            class: { disabled: _vm.disabled },
                            attrs: {
                              type: "text",
                              placeholder: "不能超过30个字母/10个汉字",
                              disabled: _vm.disabled,
                            },
                            domProps: { value: _vm.shiwuForm.alias },
                            on: {
                              input: [
                                function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.shiwuForm,
                                    "alias",
                                    $event.target.value
                                  )
                                },
                                function ($event) {
                                  _vm.shiwuForm.alias = _vm.fixedOnInputChange(
                                    $event.target.value,
                                    30
                                  )
                                },
                              ],
                            },
                          }),
                          _vm._v(" "),
                          !_vm.disabled
                            ? _c("p", { staticClass: "formTip" }, [
                                _c("i", {
                                  staticClass: "el-icon-warning-outline",
                                }),
                                _vm._v(
                                  " 提示：奖项名称用于配置活动时使用，命名不允许重复。\n            "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "展示名称:", prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "不能超过5个字符",
                              disabled: _vm.disabled,
                              type: "text",
                              maxlength: "5",
                            },
                            model: {
                              value: _vm.shiwuForm.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.shiwuForm, "name", $$v)
                              },
                              expression: "shiwuForm.name",
                            },
                          }),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.shiwuForm.name,
                                expression: "shiwuForm.name",
                              },
                            ],
                            staticClass: "el-input__inner",
                            class: { disabled: _vm.disabled },
                            attrs: {
                              type: "text",
                              placeholder: "不能超过30个字母/10个汉字",
                              disabled: _vm.disabled,
                            },
                            domProps: { value: _vm.shiwuForm.name },
                            on: {
                              input: [
                                function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.shiwuForm,
                                    "name",
                                    $event.target.value
                                  )
                                },
                                function ($event) {
                                  _vm.shiwuForm.name = _vm.fixedOnInputChange(
                                    $event.target.value,
                                    30
                                  )
                                },
                              ],
                            },
                          }),
                          _vm._v(" "),
                          !_vm.disabled
                            ? _c("p", { staticClass: "formTip" }, [
                                _c("i", {
                                  staticClass: "el-icon-warning-outline",
                                }),
                                _vm._v(
                                  " 提示：用于展示给消费者，命名允许重复。\n            "
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "成本价(元):", prop: "costPrize" } },
                        [
                          _c("el-input", {
                            directives: [
                              {
                                name: "only-number",
                                rawName: "v-only-number",
                                value: {
                                  max: "",
                                  min: 0,
                                  precision: 2,
                                  set: _vm.shiwuForm,
                                  name: "costPrize",
                                },
                                expression:
                                  "{max:'',min:0,precision:2,set: shiwuForm, name:'costPrize'}",
                              },
                            ],
                            attrs: {
                              placeholder: "0.00",
                              disabled: _vm.disabled,
                            },
                            model: {
                              value: _vm.shiwuForm.costPrize,
                              callback: function ($$v) {
                                _vm.$set(_vm.shiwuForm, "costPrize", $$v)
                              },
                              expression: "shiwuForm.costPrize",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "奖品图片:", prop: "imgUrl" } },
                        [
                          _c("Upload-img", {
                            ref: "shiwuUpload",
                            attrs: {
                              data: _vm.shiwuForm.imgUrl,
                              disabled: _vm.disabled,
                              text: "（建议尺寸：150px*150px，只支持.png,.jpg 格式）",
                            },
                            on: {
                              "update:data": function ($event) {
                                return _vm.$set(_vm.shiwuForm, "imgUrl", $event)
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("el-input", {
                            staticStyle: { display: "none" },
                            attrs: { type: "hidden" },
                            model: {
                              value: _vm.shiwuForm.imgUrl,
                              callback: function ($$v) {
                                _vm.$set(_vm.shiwuForm, "imgUrl", $$v)
                              },
                              expression: "shiwuForm.imgUrl",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "领取时是否需要填写信息:" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticStyle: {
                                height: "40px",
                                "padding-top": "12px",
                              },
                              model: {
                                value: _vm.shiwuForm.getuserinfo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.shiwuForm, "getuserinfo", $$v)
                                },
                                expression: "shiwuForm.getuserinfo",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "1" } }, [
                                _vm._v("是"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: "2" } }, [
                                _vm._v("否"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.shiwuForm.getuserinfo == 1
                        ? _c(
                            "el-form-item",
                            {
                              key: "2",
                              attrs: { label: "内容:", prop: "collectionInfo" },
                            },
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  model: {
                                    value: _vm.shiwuForm.collectionInfo,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.shiwuForm,
                                        "collectionInfo",
                                        $$v
                                      )
                                    },
                                    expression: "shiwuForm.collectionInfo",
                                  },
                                },
                                _vm._l(_vm.info, function (item, index) {
                                  return _c(
                                    "el-checkbox",
                                    {
                                      key: index,
                                      staticStyle: {
                                        width: "85px",
                                        "margin-right": "0",
                                      },
                                      attrs: { label: index },
                                    },
                                    [_vm._v(_vm._s(item.name))]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-col",
                            {
                              staticStyle: {
                                "text-align": "center",
                                "margin-top": "20px",
                              },
                            },
                            [
                              !_vm.disabled
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass: "normalBtn",
                                      attrs: { type: "primary" },
                                      on: { click: _vm.subShiwu },
                                    },
                                    [_vm._v("保存")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "normalBtn",
                                  on: { click: _vm.cancel },
                                },
                                [_vm._v("取消")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.dialogName == "第三方资源",
                  expression: "dialogName=='第三方资源'",
                },
              ],
              staticClass: "row-bg",
              attrs: { type: "flex", justify: "space-around" },
            },
            [
              _c(
                "el-col",
                { staticStyle: { width: "600px" } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "thirdForm",
                      staticStyle: { margin: "10 auto" },
                      attrs: {
                        rules: _vm.thirdRules,
                        model: _vm.thirdForm,
                        "label-position": "right",
                        "label-width": "180px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "奖项名称:", prop: "alias" } },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.thirdForm.alias,
                                expression: "thirdForm.alias",
                              },
                            ],
                            staticClass: "el-input__inner",
                            class: { disabled: _vm.disabled },
                            attrs: {
                              type: "text",
                              placeholder: "不能超过30个字母/10个汉字",
                              disabled: _vm.disabled,
                            },
                            domProps: { value: _vm.thirdForm.alias },
                            on: {
                              input: [
                                function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.thirdForm,
                                    "alias",
                                    $event.target.value
                                  )
                                },
                                function ($event) {
                                  _vm.thirdForm.alias = _vm.fixedOnInputChange(
                                    $event.target.value,
                                    30
                                  )
                                },
                              ],
                            },
                          }),
                          _vm._v(" "),
                          !_vm.disabled
                            ? _c("p", { staticClass: "formTip" }, [
                                _c("i", {
                                  staticClass: "el-icon-warning-outline",
                                }),
                                _vm._v(
                                  " 提示：奖项名称用于配置活动时使用，命名不允许重复。\n            "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "展示名称:", prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "不能超过5个字符",
                              disabled: _vm.disabled,
                              type: "text",
                              maxlength: "5",
                            },
                            model: {
                              value: _vm.thirdForm.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.thirdForm, "name", $$v)
                              },
                              expression: "thirdForm.name",
                            },
                          }),
                          _vm._v(" "),
                          !_vm.disabled
                            ? _c("p", { staticClass: "formTip" }, [
                                _c("i", {
                                  staticClass: "el-icon-warning-outline",
                                }),
                                _vm._v(
                                  " 提示：用于展示给消费者，命名允许重复。\n            "
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "奖品链接:", prop: "thirdUrl" } },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: _vm.disabled,
                              type: "text",
                              maxlength: "500",
                            },
                            model: {
                              value: _vm.thirdForm.thirdUrl,
                              callback: function ($$v) {
                                _vm.$set(_vm.thirdForm, "thirdUrl", $$v)
                              },
                              expression: "thirdForm.thirdUrl ",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "成本价(元):", prop: "costPrize" } },
                        [
                          _c("el-input", {
                            directives: [
                              {
                                name: "only-number",
                                rawName: "v-only-number",
                                value: {
                                  max: "",
                                  min: 0,
                                  precision: 2,
                                  set: _vm.thirdForm,
                                  name: "costPrize",
                                },
                                expression:
                                  "{max:'',min:0,precision:2,set: thirdForm, name:'costPrize'}",
                              },
                            ],
                            attrs: {
                              placeholder: "0.00",
                              disabled: _vm.disabled,
                            },
                            model: {
                              value: _vm.thirdForm.costPrize,
                              callback: function ($$v) {
                                _vm.$set(_vm.thirdForm, "costPrize", $$v)
                              },
                              expression: "thirdForm.costPrize ",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "奖品图片:", prop: "imgUrl" } },
                        [
                          _c("Upload-img", {
                            ref: "thirdUpload",
                            attrs: {
                              data: _vm.thirdForm.imgUrl,
                              disabled: _vm.disabled,
                              text: "（建议尺寸：150px*150px，只支持.png,.jpg 格式）",
                            },
                            on: {
                              "update:data": function ($event) {
                                return _vm.$set(_vm.thirdForm, "imgUrl", $event)
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("el-input", {
                            staticStyle: { display: "none" },
                            attrs: { type: "hidden" },
                            model: {
                              value: _vm.thirdForm.imgUrl,
                              callback: function ($$v) {
                                _vm.$set(_vm.thirdForm, "imgUrl", $$v)
                              },
                              expression: "thirdForm.imgUrl",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "领取时是否需要填写信息:" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticStyle: {
                                height: "40px",
                                "padding-top": "12px",
                              },
                              model: {
                                value: _vm.thirdForm.getuserinfo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.thirdForm, "getuserinfo", $$v)
                                },
                                expression: "thirdForm.getuserinfo",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "1" } }, [
                                _vm._v("是"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: "2" } }, [
                                _vm._v("否"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.thirdForm.getuserinfo == "1"
                        ? _c(
                            "el-form-item",
                            {
                              attrs: { label: "内容:", prop: "collectionInfo" },
                            },
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  model: {
                                    value: _vm.thirdForm.collectionInfo,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.thirdForm,
                                        "collectionInfo",
                                        $$v
                                      )
                                    },
                                    expression: "thirdForm.collectionInfo",
                                  },
                                },
                                _vm._l(_vm.info, function (item, index) {
                                  return _c(
                                    "el-checkbox",
                                    {
                                      key: index,
                                      staticStyle: {
                                        width: "85px",
                                        "margin-right": "0",
                                      },
                                      attrs: { label: _vm.info },
                                    },
                                    [_vm._v(_vm._s(item.name))]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-col",
                            {
                              staticStyle: {
                                "text-align": "center",
                                "margin-top": "20px",
                              },
                            },
                            [
                              !_vm.disabled
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass: "normalBtn",
                                      attrs: { type: "primary" },
                                      on: { click: _vm.subthird },
                                    },
                                    [_vm._v("保存")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "normalBtn",
                                  on: { click: _vm.cancel },
                                },
                                [_vm._v("取消")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("Tipdialog", {
        ref: "TipVisible",
        attrs: { width: "350px", content: _vm.tipContent },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }