var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-container", [
    _c("div", { staticClass: "hyyy-container" }, [
      _c("div", { staticClass: "hyyy-content" }, [
        _c(
          "div",
          { staticClass: "hyyy-main", staticStyle: { padding: "0" } },
          [
            _c(
              "el-card",
              {},
              [
                _c("div", { staticClass: "hyyy-top-tit" }, [
                  _c("div", { staticClass: "hyyy-top-tit-lift" }, [
                    _c("span", {
                      staticStyle: { "font-weight": "400", color: "#333" },
                      domProps: { textContent: _vm._s(_vm.$route.meta.title) },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "hyyy-top-tit-right" }),
                ]),
                _vm._v(" "),
                _c("el-divider"),
                _vm._v(" "),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form",
                          {
                            staticClass: "demo-form-inline",
                            attrs: { inline: true, model: _vm.formInline },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "cols" },
                              [
                                _c(
                                  "el-row",
                                  { attrs: { gutter: 20 } },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          [
                                            _c(
                                              "div",
                                              {
                                                attrs: { slot: "label" },
                                                slot: "label",
                                              },
                                              [_vm._v(" ")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-select",
                                              {
                                                attrs: {
                                                  placeholder: "请选择",
                                                },
                                                model: {
                                                  value:
                                                    _vm.formInline.selectName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.formInline,
                                                      "selectName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "formInline.selectName",
                                                },
                                              },
                                              [
                                                _c("el-option", {
                                                  attrs: {
                                                    label: "会员卡号",
                                                    value: "1",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("el-option", {
                                                  attrs: {
                                                    label: "手机号",
                                                    value: "2",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("el-option", {
                                                  attrs: {
                                                    label: "会员姓名",
                                                    value: "3",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          [
                                            _c(
                                              "div",
                                              {
                                                attrs: { slot: "label" },
                                                slot: "label",
                                              },
                                              [_vm._v(" ")]
                                            ),
                                            _vm._v(" "),
                                            _c("el-input", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.formInline.selectName ==
                                                      "1" ||
                                                    _vm.formInline.selectName ==
                                                      "",
                                                  expression:
                                                    "formInline.selectName == '1' || formInline.selectName == ''",
                                                },
                                              ],
                                              attrs: {
                                                placeholder: "请输入会员卡号",
                                              },
                                              model: {
                                                value:
                                                  _vm.formInline.memberValue,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formInline,
                                                    "memberValue",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formInline.memberValue",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("el-input", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.formInline.selectName ==
                                                    "2",
                                                  expression:
                                                    "formInline.selectName == '2'",
                                                },
                                              ],
                                              attrs: {
                                                placeholder: "请输入手机号",
                                              },
                                              model: {
                                                value:
                                                  _vm.formInline.memberValue,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formInline,
                                                    "memberValue",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formInline.memberValue",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("el-input", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.formInline.selectName ==
                                                    "3",
                                                  expression:
                                                    "formInline.selectName == '3'",
                                                },
                                              ],
                                              attrs: {
                                                placeholder: "请输入用户昵称",
                                              },
                                              model: {
                                                value:
                                                  _vm.formInline.memberValue,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formInline,
                                                    "memberValue",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formInline.memberValue",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.handleShow,
                                            expression: "handleShow",
                                          },
                                        ],
                                        attrs: { span: 6 },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "会员来源" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder: "请输入会员来源",
                                              },
                                              model: {
                                                value: _vm.formInline.ly,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formInline,
                                                    "ly",
                                                    $$v
                                                  )
                                                },
                                                expression: "formInline.ly",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "会员卡类型" } },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                attrs: {
                                                  placeholder: "请选择标签",
                                                },
                                                model: {
                                                  value:
                                                    _vm.formInline.memberType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.formInline,
                                                      "memberType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "formInline.memberType",
                                                },
                                              },
                                              [
                                                _c("el-option", {
                                                  attrs: {
                                                    label: "所有类型",
                                                    value: "",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("el-option", {
                                                  attrs: {
                                                    label: "普通会员",
                                                    value: "1",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("el-option", {
                                                  attrs: {
                                                    label: "等级会员",
                                                    value: "2",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.handleShow,
                                            expression: "handleShow",
                                          },
                                        ],
                                        attrs: { span: 6 },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "会员等级" } },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                attrs: {
                                                  placeholder: "请选择等级",
                                                },
                                                model: {
                                                  value: _vm.formInline.type,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.formInline,
                                                      "type",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "formInline.type",
                                                },
                                              },
                                              _vm._l(
                                                _vm.levdata,
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item.index,
                                                    attrs: {
                                                      label: item.name,
                                                      value: item.value,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.handleShow,
                                            expression: "handleShow",
                                          },
                                        ],
                                        attrs: { span: 6 },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "领取时间" } },
                                          [
                                            _c("el-date-picker", {
                                              attrs: {
                                                size: "large",
                                                type: "daterange",
                                                align: "right",
                                                "unlink-panels": "",
                                                "value-format": "yyyy-MM-dd",
                                                "range-separator": "-",
                                                "start-placeholder": "开始日期",
                                                "end-placeholder": "结束日期",
                                                "picker-options":
                                                  _vm.pickerOptions,
                                              },
                                              model: {
                                                value: _vm.value2,
                                                callback: function ($$v) {
                                                  _vm.value2 = $$v
                                                },
                                                expression: "value2",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "hyyy-head-btn",
                                        staticStyle: { width: "400px" },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { "label-width": "0" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                attrs: { slot: "label" },
                                                slot: "label",
                                              },
                                              [_vm._v(" ")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "primary" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onSubmit(
                                                      _vm.formInline
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("查询")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-button",
                                              { on: { click: _vm.onReset } },
                                              [_vm._v("重置")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.handleShow =
                                                      !_vm.handleShow
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                          " +
                                                    _vm._s(
                                                      _vm.handleShow
                                                        ? "收起"
                                                        : "展开"
                                                    ) +
                                                    "\n                          "
                                                ),
                                                _c("i", {
                                                  class: _vm.handleShow
                                                    ? "el-icon-arrow-up"
                                                    : "el-icon-arrow-down",
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-dialog",
                                              {
                                                attrs: {
                                                  title: "导入会员",
                                                  visible: _vm.imdiavip,
                                                  width: "30%",
                                                  center: "",
                                                },
                                                on: {
                                                  "update:visible": function (
                                                    $event
                                                  ) {
                                                    _vm.imdiavip = $event
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-row",
                                                  [
                                                    _c(
                                                      "el-col",
                                                      {
                                                        staticStyle: {
                                                          "line-height": "2.5",
                                                        },
                                                        attrs: { span: 3 },
                                                      },
                                                      [_vm._v("上传文件:")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-col",
                                                      { attrs: { span: 18 } },
                                                      [
                                                        _c(
                                                          "el-upload",
                                                          {
                                                            staticClass:
                                                              "upload-demo",
                                                            attrs: {
                                                              accept:
                                                                ".xlsx, .xls",
                                                              action:
                                                                _vm.fstpUrl,
                                                              headers:
                                                                _vm.myHeaders,
                                                              "show-file-list": false,
                                                              "on-success":
                                                                _vm.handleAvatarSuccess,
                                                              "before-upload":
                                                                _vm.beforeAvatarUpload,
                                                              "on-error":
                                                                _vm.imgUploaderr,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  size: "small",
                                                                  type: "primary",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "点击上传"
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "el-upload__tip",
                                                                attrs: {
                                                                  slot: "tip",
                                                                },
                                                                slot: "tip",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "点击下载文件模板，下载填写完后再进行上传添加。上传时请认真检查会员信息，避免出现格式不对上传不了的情况。"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-col",
                                                      { attrs: { span: 24 } },
                                                      [
                                                        _c(
                                                          "el-button",
                                                          {
                                                            staticStyle: {
                                                              "margin-left":
                                                                "60px",
                                                              "margin-top":
                                                                "10px",
                                                            },
                                                            attrs: {
                                                              type: "primary",
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.downmuban,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "下载文件模板"
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { staticStyle: { padding: "0 20px 20px" } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 2 } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.onSubmitt },
                          },
                          [_vm._v("修改任务值")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: {
                          "line-height": "44px",
                          "padding-left": "50px",
                          color: "#ccc",
                        },
                        attrs: { span: 10 },
                      },
                      [
                        _c("span", [
                          _vm._v(" 如需修改任务值请先勾选需要修改的会员"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.download },
                          },
                          [_vm._v("下载明细")]
                        ),
                        _vm._v(" "),
                        _c("el-button", { on: { click: _vm.imvip } }, [
                          _vm._v("导入会员"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-card",
              { staticStyle: { "margin-top": "30px", padding: "20px" } },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.vipload,
                                expression: "vipload",
                              },
                            ],
                            ref: "multipleTable",
                            staticStyle: { width: "100%" },
                            attrs: {
                              background: "",
                              data: _vm.tableData,
                              "tooltip-effect": "dark",
                            },
                            on: {
                              "selection-change": _vm.handleSelectionChange,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: { type: "selection", width: "55" },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "会员卡号",
                                prop: "memberCradNumber",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { prop: "memberName", label: "会员姓名" },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "phoneNumber",
                                label: "手机号",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "性别",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.sex == "1"
                                        ? _c("span", [_vm._v("男")])
                                        : scope.row.sex == "2"
                                        ? _c("span", [_vm._v("女")])
                                        : _c("span", [_vm._v("未知")]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "age",
                                label: "年龄",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "省份/城市",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-row",
                                        [
                                          _c("el-col", [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(scope.row.province) +
                                                "/" +
                                                _vm._s(scope.row.city) +
                                                "\n                      "
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "ly",
                                label: "会员来源",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "会员卡类型",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.memberType == "0"
                                        ? _c("div", [_vm._v("普通会员")])
                                        : _c("div", [_vm._v("等级会员")]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "type",
                                label: "会员等级",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.memberType == "0"
                                        ? _c("div")
                                        : scope.row.memberType == "1"
                                        ? _c("div", [_vm._v("一级会员")])
                                        : scope.row.memberType == "2"
                                        ? _c("div", [_vm._v("二级会员")])
                                        : scope.row.memberType == "3"
                                        ? _c("div", [_vm._v("三级会员")])
                                        : scope.row.memberType == "4"
                                        ? _c("div", [_vm._v("四级会员")])
                                        : scope.row.memberType == "5"
                                        ? _c("div", [_vm._v("五级会员")])
                                        : scope.row.memberType == "6"
                                        ? _c("div", [_vm._v("六级会员")])
                                        : scope.row.memberType == "7"
                                        ? _c("div", [_vm._v("七级会员")])
                                        : scope.row.memberType == "8"
                                        ? _c("div", [_vm._v("八级会员")])
                                        : scope.row.memberType == "9"
                                        ? _c("div", [_vm._v("九级会员")])
                                        : scope.row.memberType == "10"
                                        ? _c("div", [_vm._v("十级会员")])
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "任务值",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.flag == 1
                                        ? _c("div", [
                                            _vm._v(_vm._s(scope.row.taskValue)),
                                          ])
                                        : _c(
                                            "div",
                                            [
                                              _c("el-input", {
                                                model: {
                                                  value: scope.row.taskValue,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "taskValue",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.taskValue",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "状态",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.status == 1
                                        ? _c("div", [_vm._v("正常")])
                                        : scope.row.status == 2
                                        ? _c("div", [_vm._v("冻结")])
                                        : _c("div", [_vm._v("已过期")]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "createTime",
                                label: "领取时间",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "操作", width: "200" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleinfo(
                                                scope.row.id
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("详情")]
                                      ),
                                      _vm._v(" "),
                                      scope.row.status == 1
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "mini",
                                                type: "danger",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleDelete(
                                                    scope.row.id,
                                                    scope.row.status
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("冻结")]
                                          )
                                        : _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "mini",
                                                type: "danger",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleDelete(
                                                    scope.row.id,
                                                    scope.row.status
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("解冻")]
                                          ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("el-pagination", {
                          staticStyle: { "text-align": "left" },
                          attrs: {
                            background: "",
                            "current-page": _vm.formInline.pageNo,
                            "page-size": _vm.formInline.pageSize,
                            layout: "total,prev, pager, next",
                            total: _vm.total,
                          },
                          on: { "current-change": _vm.handleCurrentChange },
                        }),
                        _vm._v(" "),
                        _c(
                          "el-dialog",
                          {
                            attrs: {
                              title: "详情",
                              visible: _vm.infodia,
                              width: "60%",
                              center: "",
                            },
                            on: {
                              "update:visible": function ($event) {
                                _vm.infodia = $event
                              },
                            },
                          },
                          [
                            _c("h3", [
                              _vm._v(
                                "\n                  基础信息\n                "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _vm._v(
                                    "会员卡号：" +
                                      _vm._s(_vm.infopro.memberCradNumber)
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _vm._v(
                                    "用户昵称：" +
                                      _vm._s(_vm.infopro.memberName)
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _vm._v(
                                    "会员姓名：" +
                                      _vm._s(_vm.infopro.memberName)
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _vm._v(
                                    "手机号：" + _vm._s(_vm.infopro.phoneNumber)
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _vm._v("性别：\n                    "),
                                  _vm.infopro.sex == 1
                                    ? _c("span", [_vm._v("男性")])
                                    : _vm.infopro.sex == 2
                                    ? _c("span", [_vm._v("女性")])
                                    : _c("span", [_vm._v("未知")]),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _vm._v("年龄：" + _vm._s(_vm.infopro.age)),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _vm._v(
                                    "地区：" + _vm._s(_vm.infopro.province)
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm._l(_vm.condlist, function (item) {
                                  return _c(
                                    "el-col",
                                    { key: item.index, attrs: { span: 8 } },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(item.conditionName) +
                                          "：" +
                                          _vm._s(item.conditionValue) +
                                          "\n                  "
                                      ),
                                    ]
                                  )
                                }),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("el-divider"),
                            _vm._v(" "),
                            _c("h3", [
                              _vm._v(
                                "\n                  会员卡信息\n                "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _vm._v("会员卡类型： "),
                                  _vm.infopro.memberType == ""
                                    ? _c("span", [_vm._v("普通会员")])
                                    : _c("span", [_vm._v("等级会员")]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.infopro.memberType != "",
                                        expression: "infopro.memberType != ''",
                                      },
                                    ],
                                    attrs: { span: 8 },
                                  },
                                  [
                                    _vm._v("会员等级 "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.infopro.memberType) +
                                          "级会员"
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _vm._v("会员来源：" + _vm._s(_vm.infopro.ly)),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _vm._v(
                                    "领取时间：" +
                                      _vm._s(_vm.infopro.createTime)
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _vm._v("状态："),
                                  _vm.infopro.status == 1
                                    ? _c("span", [_vm._v("正常")])
                                    : _c("span", [_vm._v("冻结")]),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _vm._v(
                                    "发送时间：" +
                                      _vm._s(_vm.infopro.createTime)
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _vm._v(
                                    "任务值：" +
                                      _vm._s(
                                        _vm._f("mathnum")(_vm.infopro.taskValue)
                                      )
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("el-divider"),
                            _vm._v(" "),
                            _c("h3", [
                              _vm._v(
                                "\n                  任务值信息\n                "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    [
                                      _c(
                                        "el-table",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            data: _vm.tableData1,
                                            "row-class-name":
                                              _vm.tableRowClassName,
                                          },
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "taskType",
                                              label: "任务值变动类型",
                                              align: "center",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "createTime",
                                              label: "时间",
                                              align: "center",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "createName",
                                              label: "操作人",
                                              align: "center",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "taskValue",
                                              label: "任务值变动",
                                              align: "center",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.xgtask,
                            expression: "xgtask",
                          },
                        ],
                        attrs: { span: 4 },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.xgbtn },
                          },
                          [_vm._v("确认修改")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.xgtask,
                            expression: "xgtask",
                          },
                        ],
                        attrs: { span: 4 },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "danger" },
                            on: { click: _vm.qxbtn },
                          },
                          [_vm._v("取消修改")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }