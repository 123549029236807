var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c(
                "el-form",
                {
                  ref: "searchForm",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.searchForm,
                    "status-icon": "",
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "产品名称:",
                                prop: "productName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请选择",
                                  clearable: "",
                                  "suffix-icon": "el-icon-search",
                                },
                                on: {
                                  focus: _vm.openProductWin,
                                  clear: _vm.clearProduct,
                                },
                                model: {
                                  value: _vm.productName,
                                  callback: function ($$v) {
                                    _vm.productName = $$v
                                  },
                                  expression: "productName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "orgCode", label: "所属企业:" } },
                            [
                              _c("treeselect", {
                                attrs: {
                                  multiple: false,
                                  "flatten-search-results": true,
                                  placeholder: "请选择组织",
                                  "clear-value-text": "",
                                  options: _vm.orgOptions,
                                },
                                on: { input: _vm.emptyStores },
                                model: {
                                  value: _vm.searchForm.orgId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "orgId", $$v)
                                  },
                                  expression: "searchForm.orgId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "客户:" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  clearable: "",
                                  "suffix-icon": "el-icon-search",
                                  placeholder: "请选择",
                                },
                                on: {
                                  focus: _vm.showPurchse,
                                  clear: _vm.clearPur,
                                },
                                model: {
                                  value: _vm.purchaseComer,
                                  callback: function ($$v) {
                                    _vm.purchaseComer = $$v
                                  },
                                  expression: "purchaseComer",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.formConfig.btnFollow
                        ? _c(
                            "el-col",
                            {
                              staticClass: "head-btn-group",
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { "label-width": "0" } },
                                [
                                  _c(
                                    "div",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [_vm._v(" ")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.searchBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.searchBtn.icon,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleFilter("search")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.btnConfig.searchBtn.text)
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.formConfig.showDvider
                                    ? _c("el-divider", {
                                        attrs: { direction: "vertical" },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.resetBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.resetBtn.icon,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleFilter("rest")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.btnConfig.resetBtn.text)
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.formConfig.btnFollow
                ? _c("el-divider", { staticClass: "btnDivider" })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btnArea" },
                [
                  !_vm.formConfig.btnFollow
                    ? _c(
                        "el-col",
                        {
                          staticClass: "head-btn-group",
                          staticStyle: { width: "230px" },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.searchBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.searchBtn.icon,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleFilter("search")
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                          ),
                          _vm._v(" "),
                          _c("el-divider", {
                            attrs: { direction: "vertical" },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.resetBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.resetBtn.icon,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleFilter("rest")
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAuth("exportbtn", _vm.$route.meta.authList)
                    ? _c(
                        "div",
                        { staticClass: "head-btn-group" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.downLoadBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.downLoadBtn.icon,
                                loading: _vm.downLoading,
                              },
                              on: { click: _vm.downLoad },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.downLoadBtn.text))]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.formConfig.btnFollow
                    ? _c(
                        "p",
                        {
                          staticStyle: {
                            "font-size": "15px",
                            "margin-left": "10px",
                          },
                        },
                        [
                          _c("span", [
                            _vm._v("\n          库存总箱数：\n          "),
                            _c("span", { staticStyle: { color: "#4181FF" } }, [
                              _vm._v(_vm._s(_vm.totalNum || "-")),
                            ]),
                            _vm._v(" 箱\n          "),
                            _c(
                              "span",
                              { staticStyle: { "margin-left": "30px" } },
                              [_vm._v("库存总盒数：")]
                            ),
                            _vm._v(" "),
                            _c("span", { staticStyle: { color: "#4181FF" } }, [
                              _vm._v(_vm._s(_vm.totalBottle || "-")),
                            ]),
                            _vm._v(" 盒\n        "),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.formConfig.btnFollow
                ? _c("div", { staticClass: "phead operate-btns" }, [
                    _c("span", [
                      _vm._v("\n        库存总箱数：\n        "),
                      _c("span", { staticStyle: { color: "#4181FF" } }, [
                        _vm._v(_vm._s(_vm.totalNum || "-")),
                      ]),
                      _vm._v(" 箱\n        "),
                      _c("span", { staticStyle: { "margin-left": "30px" } }, [
                        _vm._v("库存总盒数："),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticStyle: { color: "#4181FF" } }, [
                        _vm._v(_vm._s(_vm.totalBottle || "-")),
                      ]),
                      _vm._v(" 盒\n      "),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  key: _vm.tableKey,
                  staticStyle: { width: "100%" },
                  attrs: {
                    height: _vm.tabHeight,
                    data: _vm.tableData,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      align: _vm.tableConfig.align,
                      width: "120",
                      label: "序号",
                    },
                  }),
                  _vm._v(" "),
                  false
                    ? _c("el-table-column", {
                        attrs: {
                          label: "销售订单号",
                          prop: "orderNo",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "客户",
                      prop: "purchaseComName",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  false
                    ? _c("el-table-column", {
                        attrs: {
                          label: "库房名称",
                          prop: "storeName",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品编码",
                      prop: "productCode",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品名称",
                      prop: "productName",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "包装比例",
                      prop: "productSpec",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "寄存库存(箱)",
                      prop: "depositNum",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "寄存库存(盒)",
                      prop: "depositAtoNum",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      width: "120",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDetail(row)
                                    },
                                  },
                                },
                                [_vm._v("查看详情")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2762932249
                    ),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  layout: "total,sizes,prev, pager, next, jumper",
                  total: _vm.total,
                  page: _vm.searchForm.current,
                  limit: _vm.searchForm.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.searchForm, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.searchForm, "size", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c("product-dialog", { ref: "selProduct", on: { change: _vm.setSel } }),
      _vm._v(" "),
      _c("getPurchSelect", {
        attrs: { visible: _vm.purVisible, sid: _vm.sid },
        on: { close: _vm.closeW, change: _vm.getcgs },
      }),
      _vm._v(" "),
      _c("scanJkStock", {
        attrs: {
          visible: _vm.svisible,
          "product-id": _vm.productId,
          "purchase-org-id": _vm.purchaseOrgId,
          "sale-org-id": _vm.saleOrgId,
        },
        on: { close: _vm.closeStock },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }