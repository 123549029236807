"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _tinyMce = _interopRequireDefault(require("@/components/tiny-mce"));
var _integralMall = require("@/api/integral/integralMall");
var _integral = require("@/api/integral/integral.js");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'IntegralMallintegralRulesindex',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Pagination: _Pagination.default,
    TinyMce: _tinyMce.default
  },
  data: function data() {
    return {
      jfDalog: false,
      // 积分弹窗
      addBtnIs: false,
      dataForm: {
        rexplain: '',
        rtype: '1'
      },
      list: [],
      pageForm: {
        pageNo: 0,
        pageSize: 5
      },
      total: 0,
      rtype: '0',
      cid: '',
      apis: []
    };
  },
  created: function created() {
    // this.cid = localStorage.getItem('cid')
    // this.getIntegralFun()
  },
  activated: function activated() {
    var that = this;
    var authList = that.$route.meta.authList;
    that.apis = authList;
    that.getRules();
  },
  methods: {
    // 获取分类数据
    getRules: function getRules() {
      var _this = this;
      this.authId('searchbtn', this.apis);
      (0, _integralMall.getIntegralRule)().then(function (res) {
        if (res.status * 1 === 200) {
          if (res.data) {
            _this.dataForm = Object.assign(_this.dataForm, res.data.data);
            _this.rtype = res.data.data.rtype;
          }
        }
      });
    },
    // 表单提交
    dataFormSubmit: function dataFormSubmit() {
      var _this2 = this;
      this.authId('savebtn', this.apis);
      var param = Object.assign({}, this.dataForm);
      this.addBtnIs = true;
      if (param.id) {
        (0, _integralMall.updateRuleById)(param).then(function (res) {
          _this2.addBtnIs = false;
          if (res.status * 1 === 200) {
            // this.dataForm.rtype = this.rtype
            _this2.jfDalog = false;
            _this2.$message.closeAll();
            _this2.$message({
              message: res.data.msg,
              type: 'success',
              duration: 3 * 1000
            });
          } else {
            _this2.$message.closeAll();
            _this2.$message({
              message: res.data.msg,
              type: 'error',
              duration: 3 * 1000
            });
          }
        });
      } else {
        (0, _integralMall.insertRule)(param).then(function (res) {
          _this2.addBtnIs = false;
          if (res.status * 1 === 200) {
            // this.dataForm.rtype = this.rtype
            _this2.jfDalog = false;
            _this2.$message.closeAll();
            _this2.$message({
              message: res.data.msg,
              type: 'success',
              duration: 3 * 1000
            });
          } else {
            _this2.$message.closeAll();
            _this2.$message({
              message: res.data.msg,
              type: 'error',
              duration: 3 * 1000
            });
          }
        });
      }
    },
    closeFun: function closeFun() {
      this.rtype = this.dataForm.rtype;
    },
    routeTo: function routeTo(type, data) {
      var obj = JSON.stringify({
        type: type,
        id: data
      });
      this.$router.push({
        name: 'integralSet',
        query: {
          data: obj
        }
      });
    },
    pagingFun: function pagingFun() {
      this.getIntegralFun();
    },
    getIntegralFun: function getIntegralFun() {
      var _this3 = this;
      (0, _integral.getIntegralAct)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.pageForm), {}, {
        cid: this.cid
      })).then(function (resp) {
        if (resp.code === 0) {
          _this3.list = resp.data.lstResult;
          _this3.total = resp.data.pageTotal;
        }
      });
    },
    switchFun: function switchFun(statuse, id) {
      var _this4 = this;
      this.$confirm("\u6B64\u64CD\u4F5C\u5C06".concat(statuse === '1' ? '开启' : '关闭', "\u8BE5\u6761\u79EF\u5206\u89C4\u5219, \u662F\u5426\u7EE7\u7EED?"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(function () {
        (0, _integral.integralActOpenOrOlose)({
          cid: _this4.cid,
          id: id,
          statuse: statuse
        }).then(function (resp) {
          if (resp.code === 0) {
            _this4.list.forEach(function (item) {
              if (item.id === id) {
                item.statuse = statuse;
              }
            });
            _this4.$message({
              type: 'success',
              message: "".concat(statuse === '1' ? '开启' : '关闭', "\u6210\u529F!")
            });
          } else {
            _this4.$message({
              type: 'info',
              message: "".concat(statuse === '1' ? '开启' : '关闭', "\u5931\u8D25!")
            });
          }
        });
      }).catch(function () {
        _this4.$message({
          type: 'info',
          message: "\u5DF2\u53D6\u6D88".concat(statuse === '1' ? '开启' : '关闭')
        });
      });
    },
    deleteFun: function deleteFun(id) {
      var _this5 = this;
      this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(function () {
        (0, _integral.deleteIntegralAct)({
          cid: _this5.cid,
          id: id
        }).then(function (resp) {
          if (resp.code === 0) {
            _this5.$message({
              type: 'success',
              message: '删除成功!'
            });
            _this5.list = _this5.list.filter(function (item, i) {
              return item.id !== id;
            });
          } else {
            _this5.$message({
              type: 'error',
              message: '删除失败！'
            });
          }
        });
      }).catch(function () {
        _this5.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    }
  }
};
exports.default = _default;