var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _c(
      "div",
      { staticClass: "page-section" },
      [
        _c(
          "el-form",
          {
            ref: "searchForm",
            staticClass: "search-condition",
            attrs: {
              model: _vm.searchForm,
              "status-icon": "",
              "label-position": _vm.formConfig.labelPosition,
              "label-width": _vm.formConfig.labelWidth,
            },
          },
          [
            _c(
              "div",
              { staticClass: "cols" },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 10 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "赛事名称:" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "赛事名称", clearable: "" },
                              model: {
                                value: _vm.searchForm.gameName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchForm, "gameName", $$v)
                                },
                                expression: "searchForm.gameName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "赛事ID:" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "赛事名称", clearable: "" },
                              model: {
                                value: _vm.searchForm.matchId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchForm, "matchId", $$v)
                                },
                                expression: "searchForm.matchId",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { "label-width": "0" } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: _vm.btnConfig.searchBtn.type,
                                  size: _vm.btnConfig.size,
                                  icon: _vm.btnConfig.searchBtn.icon,
                                },
                                on: { click: _vm.search },
                              },
                              [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: _vm.btnConfig.resetBtn.type,
                                  size: _vm.btnConfig.size,
                                  icon: _vm.btnConfig.resetBtn.icon,
                                },
                                on: { click: _vm.reset },
                              },
                              [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c("el-divider", { staticClass: "btnDivider" }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "phead" },
          [
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: _vm.btnConfig.addBtn.type,
                          size: _vm.btnConfig.size,
                          icon: _vm.btnConfig.addBtn.icon,
                          disabled: false,
                        },
                        on: { click: _vm.creat },
                      },
                      [_vm._v("新建赛事")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoading,
                expression: "listLoading",
              },
            ],
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.tableData,
              border: _vm.tableConfig.border,
              stripe: _vm.tableConfig.stripe,
              fit: "",
              "highlight-current-row": "",
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                label: "赛事ID",
                prop: "matchId",
                "min-width": "180",
                "show-overflow-tooltip": "",
                align: _vm.tableConfig.align,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "赛事名称",
                prop: "gameName",
                "min-width": "120",
                "show-overflow-tooltip": "",
                align: _vm.tableConfig.align,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "创建人",
                prop: "creatorUser",
                "min-width": "120",
                "show-overflow-tooltip": "",
                align: _vm.tableConfig.align,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "开始时间-结束时间",
                "min-width": "160",
                "show-overflow-tooltip": "",
                align: _vm.tableConfig.align,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    return [
                      _c("div", [_vm._v(_vm._s(row.startTime))]),
                      _vm._v(" "),
                      _c("div", [_vm._v(_vm._s(row.endTime))]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "备注",
                prop: "remark",
                "min-width": "160",
                "show-overflow-tooltip": "",
                align: _vm.tableConfig.align,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("pagination", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.total > 0,
              expression: "total > 0",
            },
          ],
          attrs: {
            total: _vm.total,
            page: _vm.searchForm.pageNo,
            limit: _vm.searchForm.pageSize,
          },
          on: {
            "update:page": function ($event) {
              return _vm.$set(_vm.searchForm, "pageNo", $event)
            },
            "update:limit": function ($event) {
              return _vm.$set(_vm.searchForm, "pageSize", $event)
            },
            pagination: _vm.queryForPage,
          },
        }),
        _vm._v(" "),
        _vm.creatVisible
          ? _c("creat", {
              attrs: { type: _vm.editType, "dialog-visible": _vm.creatVisible },
              on: { close: _vm.closeCreat },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }