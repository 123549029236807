"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      levelList: null
    };
  },
  watch: {
    $route: function $route(to, from) {
      this.getBreadcrumb();
    }
  },
  mounted: function mounted() {
    this.getBreadcrumb();
  },
  methods: {
    getBreadcrumb: function getBreadcrumb() {
      var matched = this.$route.matched.filter(function (item) {
        return item.name;
      });
      var first = matched[0];
      if (first && first.name !== '首页') {
        matched = [{
          path: '/index',
          meta: {
            title: '首页'
          }
        }].concat(matched);
      }
      this.levelList = matched;
    }
  }
};
exports.default = _default;