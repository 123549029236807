var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        { staticClass: "page-section" },
        [
          _c("h3", { staticClass: "h-title" }, [_vm._v("生产计划单")]),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "searchForm",
              staticClass: "search-condition",
              attrs: {
                model: _vm.searchForm,
                "status-icon": "",
                "label-width": "100px",
              },
            },
            [
              _c(
                "div",
                { staticClass: "cols" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "orderNo",
                                label: "生产计划单号:",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  value: _vm.searchForm.orderNo,
                                  clearable: "",
                                  placeholder: "请输入",
                                  maxlength: "20",
                                },
                                on: {
                                  input: function (e) {
                                    return (_vm.searchForm.orderNo =
                                      _vm.validSe(e))
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.handleShow,
                              expression: "handleShow",
                            },
                          ],
                          attrs: { span: 6 },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { prop: "batchNo", label: "生产批次号:" },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                attrs: { clearable: "", placeholder: "请输入" },
                                model: {
                                  value: _vm.searchForm.batchNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "batchNo", $$v)
                                  },
                                  expression: "searchForm.batchNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.handleShow,
                              expression: "handleShow",
                            },
                          ],
                          attrs: { span: 6 },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "factoryCode",
                                label: "工厂名称:",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    filterable: "",
                                    placeholder: "请选择",
                                  },
                                  on: { change: _vm.setFactory },
                                  model: {
                                    value: _vm.searchForm.factoryCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "factoryCode",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.factoryCode",
                                  },
                                },
                                _vm._l(_vm.factoryList, function (item) {
                                  return _c("el-option", {
                                    key: item.pId,
                                    attrs: {
                                      label: item.factoryName,
                                      value: item.pId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.handleShow,
                              expression: "handleShow",
                            },
                          ],
                          attrs: { span: 6 },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "shopCode", label: "车间名称:" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择",
                                    loading: _vm.workshopListLoading,
                                  },
                                  on: { change: _vm.setShop },
                                  model: {
                                    value: _vm.searchForm.shopCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "shopCode", $$v)
                                    },
                                    expression: "searchForm.shopCode",
                                  },
                                },
                                _vm._l(_vm.workshopList, function (item) {
                                  return _c("el-option", {
                                    key: item.pId,
                                    attrs: {
                                      label: item.factoryName,
                                      value: item.pId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.handleShow,
                              expression: "handleShow",
                            },
                          ],
                          attrs: { span: 6 },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "productLineCode",
                                label: "车间产线:",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择",
                                    loading: _vm.lineListLoading,
                                  },
                                  model: {
                                    value: _vm.searchForm.productLineCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "productLineCode",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.productLineCode",
                                  },
                                },
                                _vm._l(_vm.lineList, function (item) {
                                  return _c("el-option", {
                                    key: item.pId,
                                    attrs: {
                                      label: item.factoryName,
                                      value: item.pId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.handleShow,
                              expression: "handleShow",
                            },
                          ],
                          attrs: { span: 6 },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "teamCode", label: "班组:" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择",
                                    loading: _vm.teamListLoading,
                                  },
                                  model: {
                                    value: _vm.searchForm.teamCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "teamCode", $$v)
                                    },
                                    expression: "searchForm.teamCode",
                                  },
                                },
                                _vm._l(_vm.teamList, function (item) {
                                  return _c("el-option", {
                                    key: item.pId,
                                    attrs: {
                                      label: item.factoryName,
                                      value: item.pId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.handleShow,
                              expression: "handleShow",
                            },
                          ],
                          attrs: { span: 6 },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "materialCode",
                                label: "产品编号:",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                attrs: { clearable: "", placeholder: "请输入" },
                                model: {
                                  value: _vm.searchForm.materialCode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.searchForm,
                                      "materialCode",
                                      $$v
                                    )
                                  },
                                  expression: "searchForm.materialCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "materialName",
                                label: "产品名称:",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { clearable: "", placeholder: "请输入" },
                                model: {
                                  value: _vm.searchForm.materialName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.searchForm,
                                      "materialName",
                                      $$v
                                    )
                                  },
                                  expression: "searchForm.materialName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { prop: "uploadMode", label: "上传类型:" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择",
                                  },
                                  model: {
                                    value: _vm.searchForm.uploadMode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "uploadMode",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.uploadMode",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "全部", value: "" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "按托上传", value: 0 },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "整单上传", value: 1 },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.handleShow,
                              expression: "handleShow",
                            },
                          ],
                          attrs: { span: 6 },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "orderStatus",
                                label: "计划单状态:",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择",
                                  },
                                  model: {
                                    value: _vm.searchForm.orderStatus,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "orderStatus",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.orderStatus",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "全部", value: "" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "草稿", value: 0 },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "已创建,待审核", value: 5 },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "已审核,未通过", value: 7 },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: {
                                      label: "已审核,待下载",
                                      value: 10,
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: {
                                      label: "已下载,执行中",
                                      value: 13,
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "执行完成", value: 16 },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.handleShow,
                              expression: "handleShow",
                            },
                          ],
                          attrs: { span: 6 },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "startTime",
                                label: "创建起始时间:",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "date",
                                  placeholder: "选择日期",
                                  "value-format": "yyyy-MM-dd",
                                },
                                model: {
                                  value: _vm.searchForm.startTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "startTime", $$v)
                                  },
                                  expression: "searchForm.startTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.handleShow,
                              expression: "handleShow",
                            },
                          ],
                          attrs: { span: 6 },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "endTime",
                                label: "创建结束时间:",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "date",
                                  placeholder: "选择日期",
                                  "value-format": "yyyy-MM-dd",
                                },
                                model: {
                                  value: _vm.searchForm.endTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "endTime", $$v)
                                  },
                                  expression: "searchForm.endTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { "label-width": "20px" } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      _vm.searchForm.current = 1
                                      _vm.getList()
                                    },
                                  },
                                },
                                [
                                  _c("i", { staticClass: "el-icon-search" }),
                                  _vm._v(" 查询\n              "),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: { click: _vm.resetForm },
                                },
                                [
                                  _c("i", { staticClass: "el-icon-refresh" }),
                                  _vm._v(" 重置\n              "),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      _vm.handleShow = !_vm.handleShow
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.handleShow ? "收起" : "展开") +
                                      "\n                "
                                  ),
                                  _c("i", {
                                    class: _vm.handleShow
                                      ? "el-icon-arrow-up"
                                      : "el-icon-arrow-down",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "phead" },
            [
              false
                ? _c(
                    "el-button",
                    { attrs: { size: "mini" }, on: { click: _vm.add } },
                    [
                      _c("i", { staticClass: "el-icon-plus" }),
                      _vm._v(" 添加\n      "),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", loading: _vm.downLoading },
                  on: { click: _vm.download },
                },
                [
                  _c("i", { staticClass: "el-icon-download" }),
                  _vm._v(" 导出\n      "),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              key: _vm.tableKey,
              staticStyle: { width: "100%" },
              attrs: {
                height: _vm.tabHeight,
                data: _vm.tableData,
                border: "",
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  align: _vm.tableConfig.align,
                  width: "120",
                  label: "序号",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "生产计划单号",
                  "min-width": "150",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                  prop: "orderNo",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "生产批次",
                  prop: "batchNo",
                  width: "140",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "产品编码",
                  prop: "materialCode",
                  "min-width": "140",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "产品名称",
                  prop: "materialName",
                  width: "140",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "包装比例",
                  prop: "packScaleDesc",
                  width: "140",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "工厂名称",
                  prop: "factoryName",
                  width: "140",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "车间名称",
                  prop: "shopName",
                  width: "140",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "产线名称",
                  prop: "productLineName",
                  width: "140",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "班组",
                  prop: "teamName",
                  width: "140",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "计划数量",
                  prop: "planNum",
                  width: "140",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(row.planNum) +
                              _vm._s(
                                _vm._f("unitName2")(
                                  row.packScaleLevelItemDTOList
                                )
                              )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "最小单位",
                  prop: "miniUnit",
                  width: "140",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(row.planNum) +
                              _vm._s(
                                _vm._f("unitName")(
                                  row.packScaleLevelItemDTOList
                                )
                              )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "计划上传量",
                  width: "140",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            staticStyle: { padding: "0" },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.codeDetail(
                                  row.orderNo,
                                  row.materialId
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  row.backChestSum ? row.backChestSum : ""
                                ) +
                                _vm._s(
                                  _vm._f("unitName2")(
                                    row.packScaleLevelItemDTOList
                                  )
                                ) +
                                "\n            (" +
                                _vm._s(
                                  row.backBottleSum ? row.backBottleSum : ""
                                ) +
                                _vm._s(
                                  _vm._f("unitName")(
                                    row.packScaleLevelItemDTOList
                                  )
                                ) +
                                ")\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "有效生产量",
                  prop: "realProductNumOfBottle",
                  width: "140",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            staticStyle: { padding: "0" },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.codeDetail(
                                  row.orderNo,
                                  row.materialId
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  row.effectiveChestSum
                                    ? row.effectiveChestSum
                                    : ""
                                ) +
                                _vm._s(
                                  _vm._f("unitName2")(
                                    row.packScaleLevelItemDTOList
                                  )
                                ) +
                                "\n            (" +
                                _vm._s(
                                  row.effectiveBottleSum
                                    ? row.effectiveBottleSum
                                    : ""
                                ) +
                                _vm._s(
                                  _vm._f("unitName")(
                                    row.packScaleLevelItemDTOList
                                  )
                                ) +
                                ")\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "上传类型",
                  width: "140",
                  align: _vm.tableConfig.align,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(_vm._s(_vm._f("uploadMode")(row.uploadMode))),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "计划单状态",
                  width: "140",
                  align: _vm.tableConfig.align,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.orderStatus == 0
                          ? _c("el-tag", { attrs: { type: "info" } }, [
                              _vm._v("草稿"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        row.orderStatus == 5
                          ? _c("el-tag", { attrs: { type: "" } }, [
                              _vm._v("已创建，待审核"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        row.orderStatus == 7
                          ? _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v("已审核，未通过"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        row.orderStatus == 10
                          ? _c("el-tag", { attrs: { type: "warning" } }, [
                              _vm._v("已审核，待下载"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        row.orderStatus == 13
                          ? _c("el-tag", { attrs: { type: "warning" } }, [
                              _vm._v("已下载，执行中"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        row.orderStatus == 16
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v("执行完成"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "创建人",
                  prop: "creatorId",
                  width: "140",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "创建时间",
                  prop: "createTime",
                  width: "180",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  fixed: "right",
                  align: _vm.tableConfig.align,
                  width: "180",
                  "class-name": "small-padding fixed-width",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.scanDetail(row)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              disabled: !(
                                row.orderStatus == 5 || row.orderStatus == 7
                              ),
                              type: "text",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.DoAudit(row)
                              },
                            },
                          },
                          [_vm._v("审核")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              layout: "total,sizes,prev, pager, next, jumper",
              total: _vm.total,
              page: _vm.searchForm.current,
              limit: _vm.searchForm.size,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.searchForm, "current", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.searchForm, "size", $event)
              },
              pagination: _vm.getList,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: _vm.title,
            visible: _vm.dialogVisible,
            "before-close": _vm.dialogClose,
            "custom-class": "customWidth",
            width: "60%!important",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.mloading,
                  expression: "mloading",
                },
              ],
              ref: "scanForm",
              staticClass: "info-items",
              attrs: {
                model: _vm.scanData,
                rules: _vm.rules,
                "label-position": "right",
                "label-width": "150px",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "生产计划单号:", prop: "orderNo" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              value: _vm.scanData.orderNo,
                              readonly: true,
                            },
                            on: {
                              input: function (e) {
                                return (_vm.scanData.orderNo = _vm.validSe(e))
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "生产工厂:", prop: "factoryCode" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "350px" },
                              attrs: { clearable: "", placeholder: "请选择" },
                              on: { change: _vm.setScanFactory },
                              model: {
                                value: _vm.scanData.factoryCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.scanData, "factoryCode", $$v)
                                },
                                expression: "scanData.factoryCode",
                              },
                            },
                            _vm._l(_vm.factoryList, function (item) {
                              return _c("el-option", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.isEnable == 1,
                                    expression: "item.isEnable==1",
                                  },
                                ],
                                key: item.pId,
                                attrs: {
                                  label: item.factoryName,
                                  value: item.pId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "车间名称:", prop: "shopCode" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "350px" },
                              attrs: {
                                clearable: "",
                                placeholder: "请选择",
                                loading: _vm.scanworkshopListLoading,
                              },
                              on: { change: _vm.setScanShop },
                              model: {
                                value: _vm.scanData.shopCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.scanData, "shopCode", $$v)
                                },
                                expression: "scanData.shopCode",
                              },
                            },
                            _vm._l(_vm.scanworkshopList, function (item) {
                              return _c("el-option", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.isEnable == 1,
                                    expression: "item.isEnable==1",
                                  },
                                ],
                                key: item.pId,
                                attrs: {
                                  label: item.factoryName,
                                  value: item.pId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "产线:", prop: "productLineCode" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "350px" },
                              attrs: {
                                clearable: "",
                                placeholder: "请选择",
                                loading: _vm.scanlineListLoading,
                              },
                              model: {
                                value: _vm.scanData.productLineCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.scanData, "productLineCode", $$v)
                                },
                                expression: "scanData.productLineCode",
                              },
                            },
                            _vm._l(_vm.scanlineList, function (item) {
                              return _c("el-option", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.isEnable == 1,
                                    expression: "item.isEnable==1",
                                  },
                                ],
                                key: item.pId,
                                attrs: {
                                  label: item.factoryName,
                                  value: item.pId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "班组:", prop: "teamCode" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "350px" },
                              attrs: {
                                clearable: "",
                                placeholder: "请选择",
                                loading: _vm.scanteamListLoading,
                              },
                              model: {
                                value: _vm.scanData.teamCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.scanData, "teamCode", $$v)
                                },
                                expression: "scanData.teamCode",
                              },
                            },
                            _vm._l(_vm.scanteamList, function (item) {
                              return _c("el-option", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.isEnable == 1,
                                    expression: "item.isEnable==1",
                                  },
                                ],
                                key: item.pId,
                                attrs: {
                                  label: item.factoryName,
                                  value: item.pId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "负责人:", prop: "director" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "350px" },
                            attrs: { maxlength: "20" },
                            model: {
                              value: _vm.scanData.director,
                              callback: function ($$v) {
                                _vm.$set(_vm.scanData, "director", $$v)
                              },
                              expression: "scanData.director",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "产地:", prop: "originPlace" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              maxlength: "30",
                              placeholder: "请填写，例如：新疆",
                            },
                            model: {
                              value: _vm.scanData.originPlace,
                              callback: function ($$v) {
                                _vm.$set(_vm.scanData, "originPlace", $$v)
                              },
                              expression: "scanData.originPlace",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "talbe-item",
                          attrs: { label: "产品明细:", prop: "materialCode" },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { "margin-left": "12px" },
                              attrs: { type: "primary", size: "mini" },
                              on: { click: _vm.openSel },
                            },
                            [_vm._v("添加产品")]
                          ),
                          _vm._v(" "),
                          _vm.proList.length > 0
                            ? _c(
                                "el-table",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.listLoading,
                                      expression: "listLoading",
                                    },
                                  ],
                                  key: _vm.tableKey,
                                  staticStyle: {
                                    width: "100%",
                                    "margin-top": "1px",
                                  },
                                  attrs: {
                                    data: _vm.proList,
                                    border: "",
                                    fit: "",
                                    "highlight-current-row": "",
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "序号",
                                      type: "index",
                                      width: "120",
                                      align: _vm.tableConfig.align,
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "产品分类",
                                      prop: "productClassName",
                                      align: _vm.tableConfig.align,
                                      "show-overflow-tooltip": "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "产品编码",
                                      prop: "productCode",
                                      align: _vm.tableConfig.align,
                                      "show-overflow-tooltip": "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "产品名称",
                                      prop: "productName",
                                      align: _vm.tableConfig.align,
                                      "show-overflow-tooltip": "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "规格",
                                      prop: "productModel",
                                      "min-width": "120",
                                      align: _vm.tableConfig.align,
                                      "show-overflow-tooltip": "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "包装比例",
                                      prop: "packScaleExpression",
                                      align: _vm.tableConfig.align,
                                      "show-overflow-tooltip": "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "包装比例描述",
                                      prop: "packScaleDesc",
                                      align: _vm.tableConfig.align,
                                      width: "110",
                                      "show-overflow-tooltip": "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "el-table-column",
                                    {
                                      attrs: {
                                        label: "计划产量(箱)",
                                        prop: "packScaleDesc",
                                        align: _vm.tableConfig.align,
                                        width: "160",
                                        "show-overflow-tooltip": "",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: { margin: "20px auto" },
                                          attrs: {
                                            label: "",
                                            "label-width": "0",
                                            prop: "planNum",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              value: _vm.scanData.planNum,
                                              maxlength: "6",
                                              min: "1",
                                            },
                                            on: { input: _vm.setSum },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "最小单位",
                                      prop: "packScaleDesc",
                                      align: _vm.tableConfig.align,
                                      width: "80",
                                      "show-overflow-tooltip": "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var row = ref.row
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("minSum")(
                                                      _vm.scanData.planNum,
                                                      row.packScaleExpression
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1697598727
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "操作",
                                      align: _vm.tableConfig.align,
                                      width: "80",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var row = ref.row
                                            return [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.proDel(row)
                                                    },
                                                  },
                                                },
                                                [_vm._v("删除")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2388118391
                                    ),
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "所属库房", prop: "storehouseCode" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "350px" },
                              attrs: { clearable: "", placeholder: "请选择" },
                              model: {
                                value: _vm.scanData.storehouseCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.scanData, "storehouseCode", $$v)
                                },
                                expression: "scanData.storehouseCode",
                              },
                            },
                            _vm._l(_vm.storeSELList, function (item) {
                              return _c("el-option", {
                                key: item.pId,
                                attrs: {
                                  label: item.storeHouseName,
                                  value: item.pId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "生产批次:", prop: "batchNo" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "350px" },
                            attrs: { maxlength: "20", readonly: true },
                            model: {
                              value: _vm.scanData.batchNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.scanData, "batchNo", $$v)
                              },
                              expression: "scanData.batchNo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注:", prop: "remarks" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              type: "textarea",
                              maxlength: "20",
                              rows: "3",
                            },
                            model: {
                              value: _vm.scanData.remarks,
                              callback: function ($$v) {
                                _vm.$set(_vm.scanData, "remarks", $$v)
                              },
                              expression: "scanData.remarks",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "dialog-footer" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.btnLoading,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.submitForm(0)
                                },
                              },
                            },
                            [_vm._v("暂 存")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.btnLoading,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.submitForm(1)
                                },
                              },
                            },
                            [_vm._v("提 交")]
                          ),
                          _vm._v(" "),
                          _c("el-button", { on: { click: _vm.dialogClose } }, [
                            _vm._v("关 闭"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: "生产计划详情",
            visible: _vm.detailVisible,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.detailVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.mloading,
                  expression: "mloading",
                },
              ],
              ref: "scanData",
              staticClass: "info-items",
              attrs: {
                model: _vm.detailData,
                "label-position": "right",
                "label-width": "100px",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "itemTitle",
                  staticStyle: { "margin-top": "0" },
                },
                [_vm._v("计划单信息")]
              ),
              _vm._v(" "),
              _c(
                "el-row",
                {
                  staticStyle: { "flex-direction": "row", "flex-wrap": "wrap" },
                  attrs: { gutter: 20, type: "flex" },
                },
                [
                  _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("生产计划单号:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.detailData.orderNo)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("任务数量:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(
                          _vm._s(_vm.detailData.miniUnit) +
                            _vm._s(
                              _vm._f("unitName")(
                                _vm.detailData.packScaleLevelItemDTOList
                              )
                            )
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("生产批次:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.detailData.batchNo)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("生产工厂:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.detailData.factoryName)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("车间名称:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.detailData.shopName)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("产线名称:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.detailData.productLineName)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [_vm._v("班组:")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.detailData.teamName)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("产品编号:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.detailData.materialCode)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("产品名称:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.detailData.materialName)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("包装比例描述:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.detailData.packScaleDesc)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("所属库房:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.detailData.storehouseName)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("上传类型:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("uploadMode")(_vm.detailData.uploadMode)
                          )
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                    _c(
                      "div",
                      { staticClass: "vue-from-block" },
                      [
                        _c("div", { staticClass: "form-div" }, [
                          _vm._v("计划单状态:"),
                        ]),
                        _vm._v(" "),
                        _vm.detailData.orderStatus == 0
                          ? _c("el-link", { attrs: { type: "info" } }, [
                              _vm._v("草稿"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.detailData.orderStatus == 5
                          ? _c("el-link", { attrs: { type: "primary" } }, [
                              _vm._v("已创建，待审核"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.detailData.orderStatus == 7
                          ? _c("el-link", { attrs: { type: "danger" } }, [
                              _vm._v("已审核，未通过"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.detailData.orderStatus == 10
                          ? _c("el-link", { attrs: { type: "warning" } }, [
                              _vm._v("已审核，待下载"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.detailData.orderStatus == 13
                          ? _c("el-link", { attrs: { type: "warning" } }, [
                              _vm._v("已下载，执行中"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.detailData.orderStatus == 16
                          ? _c("el-link", { attrs: { type: "success" } }, [
                              _vm._v("执行完成"),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("创建人:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.detailData.creatorId)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("创建时间:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.detailData.createTime)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("审核人:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.detailData.auditId)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("审核时间:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.detailData.auditTime)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("单据来源:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("sourceText")(_vm.detailData.orderSource)
                          )
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("负责人:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.detailData.director)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [_vm._v("产地:")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.detailData.originPlace)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [_vm._v("备注:")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.detailData.remarks)),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "middleLine" }),
              _vm._v(" "),
              _c("div", { staticClass: "itemTitle" }, [_vm._v("实际生产量")]),
              _vm._v(" "),
              _c(
                "el-row",
                {
                  staticStyle: { "flex-direction": "row", "flex-wrap": "wrap" },
                  attrs: { gutter: 20, type: "flex" },
                },
                [
                  _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("任务数量:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.detailData.planNum
                                ? _vm.detailData.planNum
                                : ""
                            ) +
                            _vm._s(
                              _vm._f("unitName2")(
                                _vm.detailData.packScaleLevelItemDTOList
                              )
                            )
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                    _c(
                      "div",
                      { staticClass: "vue-from-block" },
                      [
                        _c("div", { staticClass: "form-div" }, [
                          _vm._v("计划上传量:"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticStyle: { padding: "0" },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.codeDetail(
                                  _vm.detailData.orderNo,
                                  _vm.detailData.materialId
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.detailData.backChestSum
                                    ? _vm.detailData.backChestSum
                                    : ""
                                ) +
                                _vm._s(
                                  _vm._f("unitName2")(
                                    _vm.detailData.packScaleLevelItemDTOList
                                  )
                                ) +
                                "\n              (" +
                                _vm._s(
                                  _vm.detailData.backBottleSum
                                    ? _vm.detailData.backBottleSum
                                    : ""
                                ) +
                                _vm._s(
                                  _vm._f("unitName")(
                                    _vm.detailData.packScaleLevelItemDTOList
                                  )
                                ) +
                                ")\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                    _c(
                      "div",
                      { staticClass: "vue-from-block" },
                      [
                        _c("div", { staticClass: "form-div" }, [
                          _vm._v("有效生产量:"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticStyle: { padding: "0" },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.codeDetail(
                                  _vm.detailData.orderNo,
                                  _vm.detailData.materialId
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.detailData.effectiveChestSum
                                    ? _vm.detailData.effectiveChestSum
                                    : ""
                                ) +
                                _vm._s(
                                  _vm._f("unitName2")(
                                    _vm.detailData.packScaleLevelItemDTOList
                                  )
                                ) +
                                "\n              (" +
                                _vm._s(
                                  _vm.detailData.effectiveBottleSum
                                    ? _vm.detailData.effectiveBottleSum
                                    : ""
                                ) +
                                _vm._s(
                                  _vm._f("unitName")(
                                    _vm.detailData.packScaleLevelItemDTOList
                                  )
                                ) +
                                ")\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                    _c(
                      "div",
                      { staticClass: "vue-from-block" },
                      [
                        _c("div", { staticClass: "form-div" }, [
                          _vm._v("异常数码量:"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticStyle: { padding: "0" },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.showFailList()
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.detailData.unusualSum
                                  ? _vm.detailData.unusualSum + "个"
                                  : ""
                              )
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { lg: 8, md: 12 } }, [
                    _c(
                      "div",
                      { staticClass: "vue-from-block" },
                      [
                        _c("div", { staticClass: "form-div" }, [
                          _vm._v("实际入库量:"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticStyle: { padding: "0" },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.codeDetail(
                                  _vm.detailData.orderNo,
                                  _vm.detailData.materialId
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.detailData.inboundChestSum
                                    ? _vm.detailData.inboundChestSum
                                    : ""
                                ) +
                                _vm._s(
                                  _vm._f("unitName2")(
                                    _vm.detailData.packScaleLevelItemDTOList
                                  )
                                ) +
                                "\n              (" +
                                _vm._s(
                                  _vm.detailData.inboundBottleSum
                                    ? _vm.detailData.inboundBottleSum
                                    : ""
                                ) +
                                _vm._s(
                                  _vm._f("unitName")(
                                    _vm.detailData.packScaleLevelItemDTOList
                                  )
                                ) +
                                ")\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isAudit
            ? _c(
                "el-form",
                {
                  ref: "auditForm",
                  attrs: {
                    model: _vm.auditForm,
                    "label-width": "85px",
                    "label-suffix": ":",
                    rules: _vm.auditRule,
                  },
                },
                [
                  _c("h3", { staticClass: "title" }, [_vm._v("审核")]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "审核结果", prop: "handlerResult" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.auditForm.handlerResult,
                            callback: function ($$v) {
                              _vm.$set(_vm.auditForm, "handlerResult", $$v)
                            },
                            expression: "auditForm.handlerResult",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("通过"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("不通过"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "审核意见", prop: "remark" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          maxlength: "100",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.auditForm.auditReasons,
                          callback: function ($$v) {
                            _vm.$set(_vm.auditForm, "auditReasons", $$v)
                          },
                          expression: "auditForm.auditReasons",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.isAudit
                ? _c(
                    "el-button",
                    {
                      attrs: { loading: _vm.btnLoading, type: "primary" },
                      on: { click: _vm.audit },
                    },
                    [_vm._v("提交")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("关 闭"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: "数码详情",
            visible: _vm.codeVisible,
            top: "3%",
            width: "70%",
            "before-close": _vm.codeClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.codeVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.codeListLoading,
                      expression: "codeListLoading",
                    },
                  ],
                  key: _vm.tableKey,
                  staticStyle: { width: "100%" },
                  attrs: {
                    "max-height": "500",
                    data: _vm.codeList,
                    "tooltip-effect": "dark",
                    border: "",
                    fit: "",
                    "highlight-current-row": "",
                    "row-key": "markCode",
                    lazy: "",
                    load: _vm.load,
                    "tree-props": _vm.treeProps,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      width: "120",
                      label: "序号",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [_c("span", [_vm._v(_vm._s(scope.row.seqNo))])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "层级",
                      prop: "id",
                      align: "left",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            !scope.row.packUnit
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("packLevel")(scope.row.packLevel)
                                    )
                                  ),
                                ])
                              : _c("span", [
                                  _vm._v(_vm._s(scope.row.packUnit) + "码"),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "数码",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.markCode))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作", align: _vm.tableConfig.align },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.inspectorGroupId
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.checkGroup(
                                          scope.row.inspectorGroupId
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("查看质检组")]
                                )
                              : _c(
                                  "el-button",
                                  { attrs: { type: "text", disabled: true } },
                                  [_vm._v("查看质检组")]
                                ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.codetotal > 0,
                    expression: "codetotal > 0",
                  },
                ],
                attrs: {
                  layout: "total,sizes,prev, pager, next, jumper",
                  total: _vm.codetotal,
                  page: _vm.detailForm.current,
                  limit: _vm.detailForm.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.detailForm, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.detailForm, "size", $event)
                  },
                  pagination: _vm.getCodeList,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.codeClose } }, [
                _vm._v("关闭"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: "查询",
            visible: _vm.groupShow,
            top: "3%",
            width: "400px!important",
          },
          on: {
            "update:visible": function ($event) {
              _vm.groupShow = $event
            },
          },
        },
        [
          _c("el-form", [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("质检组:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm._f("groupName")(_vm.groupId))),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.groupShow = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: "异常数码明细",
            visible: _vm.failVisible,
            "before-close": _vm.failClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.failVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.codeListLoading,
                      expression: "codeListLoading",
                    },
                  ],
                  key: _vm.tableKey,
                  staticStyle: { width: "100%" },
                  attrs: {
                    height: "400",
                    data: _vm.failList,
                    "tooltip-effect": "dark",
                    border: "",
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      width: "120",
                      label: "序号",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "数码",
                      prop: "id",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.markCode))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "层级",
                      prop: "id",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            !scope.row.packUnit
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("packLevel")(scope.row.packLevel)
                                    )
                                  ),
                                ])
                              : _c("span", [
                                  _vm._v(_vm._s(scope.row.packUnit) + "码"),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "解析状态",
                      prop: "id",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", { staticStyle: { color: "#E6A23C" } }, [
                              _vm._v(_vm._s(scope.row.failReason)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.failtotal > 0,
                    expression: "failtotal > 0",
                  },
                ],
                attrs: {
                  layout: "total,sizes,prev, pager, next, jumper",
                  total: _vm.failtotal,
                  page: _vm.failQuery.current,
                  limit: _vm.failQuery.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.failQuery, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.failQuery, "size", $event)
                  },
                  pagination: _vm.getFailList,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.failClose } }, [
                _vm._v("关闭"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("product-dialog", { ref: "selProduct", on: { change: _vm.setSel } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }