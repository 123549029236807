var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        { staticClass: "page-section" },
        [
          _c(
            "el-form",
            {
              ref: "formInline",
              staticClass: "search-condition",
              attrs: {
                model: _vm.formInline,
                "label-position": _vm.formConfig.labelPosition,
                "label-width": _vm.formConfig.labelWidth,
                "label-suffix": ":",
              },
            },
            [
              _c(
                "div",
                { staticClass: "cols" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "templateCategory",
                                label: "模板类型",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.formInline.templateCategory,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formInline,
                                        "templateCategory",
                                        $$v
                                      )
                                    },
                                    expression: "formInline.templateCategory",
                                  },
                                },
                                _vm._l(
                                  _vm.templateCategorys,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.name,
                                        value: item.value,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.handleShow,
                              expression: "handleShow",
                            },
                          ],
                          attrs: { span: 6 },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "templateStatus",
                                label: "模板状态",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "filter-item",
                                  attrs: {
                                    placeholder: "请选择",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.formInline.templateStatus,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formInline,
                                        "templateStatus",
                                        $$v
                                      )
                                    },
                                    expression: "formInline.templateStatus",
                                  },
                                },
                                _vm._l(
                                  _vm.templateStatus,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.name,
                                        value: item.value,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "templateName",
                                label: "模板名称",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入模板名称" },
                                model: {
                                  value: _vm.formInline.templateName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formInline,
                                      "templateName",
                                      $$v
                                    )
                                  },
                                  expression: "formInline.templateName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "startTime", label: "起止日期" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "daterange",
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  clearable: false,
                                },
                                model: {
                                  value: _vm.formInline.rangeTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formInline, "rangeTime", $$v)
                                  },
                                  expression: "formInline.rangeTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.formConfig.btnFollow
                        ? _c(
                            "el-col",
                            {
                              staticClass: "head-btn-group",
                              style: {
                                width: _vm.formConfig.btnAreaHasShowMore,
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { "label-width": "0" } },
                                [
                                  _c(
                                    "div",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [_vm._v(" ")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.searchBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.searchBtn.icon,
                                      },
                                      on: { click: _vm.searchForm },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.btnConfig.searchBtn.text)
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.resetBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.resetBtn.icon,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.restForm("formInline")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.btnConfig.resetBtn.text)
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          _vm.handleShow = !_vm.handleShow
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.handleShow ? "收起" : "展开"
                                          ) +
                                          "\n                "
                                      ),
                                      _c("i", {
                                        class: _vm.handleShow
                                          ? "el-icon-arrow-up"
                                          : "el-icon-arrow-down",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _vm.formConfig.btnFollow
            ? _c("el-divider", { staticClass: "btnDivider" })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "phead" }, [
            _c(
              "div",
              { staticClass: "head-btn-group" },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: _vm.btnConfig.addBtn.type,
                      size: _vm.btnConfig.size,
                      icon: _vm.btnConfig.addBtn.icon,
                    },
                    on: {
                      click: function ($event) {
                        _vm.actDialogVisible = true
                      },
                    },
                  },
                  [_vm._v("添加模板")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              attrs: {
                height: _vm.tabHeight,
                data: _vm.list,
                fit: "",
                border: _vm.tableConfig.border,
                stripe: _vm.tableConfig.stripe,
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "ID",
                  type: "index",
                  width: "80",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "模板名称",
                  prop: "templateName",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "templateType",
                  label: "模板类型",
                  align: _vm.tableConfig.align,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          _vm._s(_vm._f("templateType")(scope.row.templateType))
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "templateStatus",
                  label: "模板状态",
                  align: _vm.tableConfig.align,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.templateStatus == "2"
                          ? _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("templateState")(
                                    scope.row.templateStatus
                                  )
                                )
                              ),
                            ])
                          : _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("templateState")(
                                    scope.row.templateStatus
                                  )
                                )
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "templateCategory",
                  label: "模板类别",
                  align: _vm.tableConfig.align,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          _vm._s(
                            _vm._f("templateCategory")(
                              scope.row.templateCategory
                            )
                          )
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "创建时间", align: _vm.tableConfig.align },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.createTime))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: _vm.tableConfig.align,
                  label: "操作",
                  fixed: "right",
                  width: "150",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "button configText",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.preView(scope.row)
                              },
                            },
                          },
                          [_vm._v("预览")]
                        ),
                        _vm._v(" "),
                        scope.row.templateStatus === "2"
                          ? _c(
                              "el-button",
                              {
                                staticClass: "button configText",
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editAct(scope.row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.templateStatus !== "2"
                          ? _c(
                              "el-button",
                              {
                                staticClass: "button configText",
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.enable(scope.row, 2)
                                  },
                                },
                              },
                              [_vm._v("停用")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.templateStatus !== "1"
                          ? _c(
                              "el-button",
                              {
                                staticClass: "button configText",
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.enable(scope.row, 1)
                                  },
                                },
                              },
                              [_vm._v("启用")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              total: _vm.total,
              page: _vm.formInline.pageNo,
              limit: _vm.formInline.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.formInline, "pageNo", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.formInline, "pageSize", $event)
              },
              pagination: _vm.getDataList,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "cardDialog",
          attrs: {
            "close-on-click-modal": false,
            title: "模板类型",
            visible: _vm.actDialogVisible,
            "before-close": _vm.cancel,
            width: "1000px !important",
          },
        },
        [
          _c(
            "div",
            { staticClass: "itemWrap" },
            _vm._l(_vm.actlist, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "item",
                  on: {
                    click: function ($event) {
                      return _vm.creatAct({
                        templateType: item.type,
                        templateId: "",
                      })
                    },
                  },
                },
                [
                  _c("div", { staticClass: "imgWrap" }, [
                    _c("img", {
                      staticClass: "image",
                      attrs: { src: item.img },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "clearfix" }, [
                    _c("span", {
                      staticClass: "cardDialogTitle",
                      domProps: { textContent: _vm._s(item.title) },
                    }),
                  ]),
                ]
              )
            }),
            0
          ),
        ]
      ),
      _vm._v(" "),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                "close-on-click-modal": false,
                title: "模板基本设置",
                visible: _vm.dialogVisible,
                "append-to-body": true,
                width: "1000px !important",
                "before-close": _vm.close,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _vm.type == "0"
                ? _c("Content", {
                    ref: "content",
                    attrs: {
                      id: _vm.id,
                      type: _vm.type,
                      "edit-type": _vm.editType,
                      "edit-data": _vm.editData,
                      disabled: _vm.disabled,
                    },
                    on: { close: _vm.close },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.type == "9"
                ? _c("Guidance", {
                    ref: "content",
                    attrs: {
                      id: _vm.id,
                      type: _vm.type,
                      "edit-type": _vm.editType,
                      "edit-data": _vm.editData,
                      disabled: _vm.disabled,
                    },
                    on: { close: _vm.close },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }