"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.markTypeInfoinsertMarkType = markTypeInfoinsertMarkType;
exports.markTypeInfoqueryForList = markTypeInfoqueryForList;
exports.queryForListByLevel = queryForListByLevel;
var _request = _interopRequireDefault(require("@/utils/request"));
function markTypeInfoqueryForList(params) {
  return (0, _request.default)({
    url: '/api-mark/markTypeInfo/queryForList',
    method: 'get',
    params: params
  });
}
function markTypeInfoinsertMarkType(data) {
  return (0, _request.default)({
    url: '/api-mark/markTypeInfo/insertMarkType',
    method: 'post',
    data: data
  });
}

// 根据层级获取制码类型
function queryForListByLevel(params) {
  return (0, _request.default)({
    url: '/api-mark/markTypeInfo/queryForListByLevel',
    method: 'get',
    params: params
  });
}