var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-form",
                    {
                      ref: "formInline",
                      staticClass: "search-condition",
                      attrs: {
                        model: _vm.formInline,
                        "status-icon": "",
                        "label-position": _vm.formConfig.labelPosition,
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "cols" },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "流水号:",
                                        prop: "seqNo",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { placeholder: "请输入" },
                                        model: {
                                          value: _vm.formInline.seqNo,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formInline,
                                              "seqNo",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "formInline.seqNo",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "奖品名称:",
                                        prop: "prizeName",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { placeholder: "请输入" },
                                        model: {
                                          value: _vm.formInline.prizeName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formInline,
                                              "prizeName",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "formInline.prizeName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "奖品类型:",
                                        prop: "prizeType",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: "请选择",
                                            clearable: "",
                                          },
                                          model: {
                                            value: _vm.formInline.prizeType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formInline,
                                                "prizeType",
                                                $$v
                                              )
                                            },
                                            expression: "formInline.prizeType",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "红包",
                                              value: "100",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "实物",
                                              value: "200",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "扫码类型:",
                                        prop: "scanType",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: "请选择",
                                            clearable: "",
                                          },
                                          model: {
                                            value: _vm.formInline.scanType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formInline,
                                                "scanType",
                                                $$v
                                              )
                                            },
                                            expression: "formInline.scanType",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "盖外码",
                                              value: "1",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "盒码",
                                              value: "2",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "箱码",
                                              value: "3",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "活动方案:",
                                        prop: "schemeCode",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            filterable: "",
                                            clearable: "",
                                            placeholder: "请选择",
                                          },
                                          model: {
                                            value: _vm.formInline.schemeCode,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formInline,
                                                "schemeCode",
                                                $$v
                                              )
                                            },
                                            expression: "formInline.schemeCode",
                                          },
                                        },
                                        _vm._l(_vm.activeList, function (item) {
                                          return _c("el-option", {
                                            key: item.schemeCode,
                                            attrs: {
                                              label: item.schemeName,
                                              value: item.schemeCode,
                                            },
                                          })
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "terminalName",
                                        label: "终端名称",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          attrs: {
                                            placeholder: "请选择终端",
                                            readonly: "",
                                          },
                                          model: {
                                            value: _vm.terminalName,
                                            callback: function ($$v) {
                                              _vm.terminalName = $$v
                                            },
                                            expression: "terminalName",
                                          },
                                        },
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              slot: "append",
                                              icon: "el-icon-arrow-down",
                                            },
                                            on: { click: _vm.choseTerminal },
                                            slot: "append",
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "接收人身份:",
                                        prop: "receiveUserType",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: { placeholder: "请选择" },
                                          model: {
                                            value:
                                              _vm.formInline.receiveUserType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formInline,
                                                "receiveUserType",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formInline.receiveUserType",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "消费者",
                                              value: "1",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "终端",
                                              value: "2",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "大区负责人",
                                              value: "3",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "策划部负责人",
                                              value: "4",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "发放时间:" } },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          type: "daterange",
                                          format: "yyyy-MM-dd",
                                          "value-format": "yyyy-MM-dd",
                                          "range-separator": "至",
                                          "start-placeholder": "开始日期",
                                          "end-placeholder": "结束日期",
                                          clearable: "",
                                        },
                                        on: { change: _vm.sj },
                                        model: {
                                          value: _vm.rangeTime,
                                          callback: function ($$v) {
                                            _vm.rangeTime = $$v
                                          },
                                          expression: "rangeTime",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { "label-width": "0" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v(" ")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.searchBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.searchBtn.icon,
                                          },
                                          on: { click: _vm.onSearch },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.searchBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.resetBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.resetBtn.icon,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onReset("formInline")
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.resetBtn.text)
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.formConfig.btnFollow
                ? _c("el-divider", { staticClass: "btnDivider" })
                : _vm._e(),
              _vm._v(" "),
              _c("el-row", [
                _c("div", { staticClass: "phead" }, [
                  _c(
                    "div",
                    { staticClass: "head-btn-group" },
                    [
                      _vm.hasAuth("exportbtn", _vm.$route.meta.authList)
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.downLoadBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.downLoadBtn.icon,
                                loading: _vm.downLoading,
                              },
                              on: { click: _vm.download },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.downLoadBtn.text))]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.tableData,
                    height: _vm.tabHeight,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "index", label: "序号", width: "80" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "seqNo",
                      label: "流水号",
                      "min-width": "240",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "prizeName",
                      label: "奖品名称",
                      "min-width": "120",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "prizeTypeName",
                      label: "奖品类型",
                      "min-width": "120",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "prizeMoney",
                      label: "奖品价值（元）",
                      "min-width": "120",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "scanTypeName",
                      label: "扫码类型",
                      "min-width": "100",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "activityName",
                      label: "所属活动",
                      "min-width": "240",
                      width: "300",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "schemeName",
                      label: "活动方案",
                      width: "300",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "zdName",
                      label: "终端名称",
                      "min-width": "140",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "userName",
                      label: "姓名",
                      "min-width": "100",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "phone",
                      label: "手机号",
                      "min-width": "120",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "nickName",
                      label: "昵称",
                      "min-width": "100",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "headImageUrl",
                      label: "头像",
                      "min-width": "100",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c("el-image", {
                                staticStyle: { width: "70px", height: "70px" },
                                attrs: { src: row.headImageUrl, fit: "cover" },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1249969028
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "openId",
                      label: "用户openid",
                      "min-width": "120",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "sendReason",
                      label: "发放原因",
                      "min-width": "120",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "receiveUserTypeName",
                      label: "接受人身份",
                      "min-width": "120",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "sendTime",
                      label: "发放时间",
                      "min-width": "200",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("Pagination", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.total > 0,
                        expression: "total > 0",
                      },
                    ],
                    attrs: {
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.total,
                      page: _vm.params.pageNo,
                      limit: _vm.params.pageSize,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(_vm.params, "pageNo", $event)
                      },
                      "update:limit": function ($event) {
                        return _vm.$set(_vm.params, "pageSize", $event)
                      },
                      pagination: _vm.prizeSendRecord,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c("ChannelSelectDialog", {
        ref: "channelSelect",
        on: { change: _vm.selectTerminal },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }