var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-section" },
    [
      _c("h3", [_vm._v("疑似窜货趋势图")]),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "formInline",
          attrs: {
            model: _vm.formInline,
            "label-position": _vm.formConfig.labelPosition,
            "label-width": _vm.formConfig.labelWidth,
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c(
                        "el-button-group",
                        { staticClass: "button-group" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type:
                                  _vm.formInline.timeType === "1"
                                    ? "primary"
                                    : "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.getScurrystatFun("1")
                                },
                              },
                            },
                            [_vm._v("本月")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type:
                                  _vm.formInline.timeType === "2"
                                    ? "primary"
                                    : "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.getScurrystatFun("2")
                                },
                              },
                            },
                            [_vm._v("本年")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "daterange",
                          "range-separator": "-",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          format: "yyyy-MM-dd",
                          "value-format": "yyyy-MM-dd",
                          "picker-options": _vm.pickerOptions,
                        },
                        on: { change: _vm.dateFun },
                        model: {
                          value: _vm.dateValue,
                          callback: function ($$v) {
                            _vm.dateValue = $$v
                          },
                          expression: "dateValue",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "productCode" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "全部产品",
                          readonly: true,
                          clearable: "",
                        },
                        on: {
                          focus: function ($event) {
                            return _vm.inputFocusFun(1)
                          },
                        },
                        model: {
                          value: _vm.formInline.productName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline, "productName", $$v)
                          },
                          expression: "formInline.productName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "productCode" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "btn-size",
                          attrs: {
                            type: _vm.btnConfig.resetBtn.type,
                            size: _vm.btnConfig.size,
                            icon: _vm.btnConfig.resetBtn.icon,
                            loading: _vm.sweepBtn,
                          },
                          on: { click: _vm.clearFun },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.btnConfig.resetBtn.text) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("bar-chart", { ref: "lineChart", attrs: { "tip-name": [] } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "downLoad-wrap" },
        [
          _c(
            "el-button",
            {
              staticClass: "btn-size",
              attrs: {
                type: _vm.btnConfig.downLoadBtn.type,
                size: _vm.btnConfig.size,
                icon: _vm.btnConfig.downLoadBtn.icon,
                loading: _vm.downLoading,
              },
              on: { click: _vm.downFun },
            },
            [_vm._v(_vm._s(_vm.btnConfig.downLoadBtn.text))]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            id: "outoTable",
            data: _vm.list,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": "",
            align: "center",
            "header-cell-style": {
              "background-color": "#fafafa",
              color: "rgba(0, 0, 0,0.85)",
              "font-weight": "500",
              "text-align": "center",
            },
            "cell-style": { "text-align": "center" },
            "row-style": { color: "rgba(0, 0, 0,0.65)", "font-weight": "400" },
          },
        },
        [
          _c("el-table-column", { attrs: { label: "日期", prop: "date" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "疑似窜货数量", prop: "count" },
          }),
          _vm._v(">\n  "),
        ],
        1
      ),
      _vm._v(" "),
      _c("pro-dialog", { ref: "selProduct", on: { change: _vm.setCurrent } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }