"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es6.regexp.replace");
require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/core-js/modules/es6.array.iterator.js");
require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/core-js/modules/es6.promise.js");
require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/core-js/modules/es6.object.assign.js");
require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/core-js/modules/es7.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
require("normalize.css/normalize.css");
require("./filter");
require("./directive/el-input-int/int.js");
var _elementUi = _interopRequireDefault(require("element-ui"));
require("element-ui/lib/theme-chalk/index.css");
var _zhCN = _interopRequireDefault(require("element-ui/lib/locale/lang/zh-CN"));
require("@/styles/index.scss");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
require("./directive/el-input-number/number.js");
require("./directive/el-input-alph/int.js");
require("@/icons");
require("@/permission");
var _elInput = _interopRequireDefault(require("@/directive/el-input"));
var _vuePrintNb = _interopRequireDefault(require("vue-print-nb"));
var _UIConfig = require("@/utils/UIConfig");
var _vueClipboard = _interopRequireDefault(require("vue-clipboard2"));
var _echarts = _interopRequireDefault(require("echarts"));
var _print = _interopRequireDefault(require("@/utils/print"));
var _imgPreview = _interopRequireDefault(require("@/components/imgPreview"));
var _elTableFooter = _interopRequireDefault(require("el-table-footer"));
// A modern alternative to CSS resets

// lang i18n

// global css

// icon
// permission control
// 添加此行=>自定义全局指令
_vue.default.use(_elInput.default);
_vue.default.use(_vuePrintNb.default);

// 引入echarts

_vue.default.prototype.$echarts = _echarts.default;
_vue.default.use(_vueClipboard.default);
_vue.default.prototype.formConfig = _UIConfig.formConfig;
_vue.default.prototype.tableConfig = _UIConfig.tableConfig;
_vue.default.prototype.btnConfig = _UIConfig.btnConfig;

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */
/* import { mockXHR } from '../mock'
if (process.env.NODE_ENV === 'production') {
  mockXHR()
}*/
// url格式化
_vue.default.prototype.validUrl = function (value) {
  value = value.replace(/[`～*~!@$%^&*()\-+<>"{}|,;'\\[\]·~！@#￥%……&*（）——\-+{}|《》？：“”【】、；‘’，。、]/g, '').replace(/\s/g, '').replace(/[\u4E00-\u9FA5]/g, '').toUpperCase();
  return value;
};
// 除特殊字符格式化
_vue.default.prototype.validTe = function (value) {
  value = value.replace(/[`～*~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g, '').replace(/\s/g, '');
  return value;
};
// 字母数字格式化
_vue.default.prototype.validSe = function (value) {
  value = value.replace(/[`～*~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g, '').replace(/\s/g, '').replace(/[\u4E00-\u9FA5]/g, '');
  return value;
};
// 数据字典名称格式化
_vue.default.prototype.validZDMC = function (value) {
  value = value.replace(/[`～*~!@#$%^&*_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g, '').replace(/\s/g, '');
  return value;
};
// 数据字典编码格式化
_vue.default.prototype.validSJZD = function (value) {
  value = value.replace(/[`～*~!@#$%^&*()\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g, '').replace(/\s/g, '').replace(/[\u4E00-\u9FA5]/g, '');
  return value;
};
// 只能输汉字
_vue.default.prototype.chineseOnly = function (value) {
  value = value.replace(/[^\u4E00-\u9FA5]/g, '');
  return value;
};
// 只能输数字和小数点
_vue.default.prototype.numOnly = function (value) {
  value = value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '');
  return value;
};
// 校验编码:只能输入字母和数字
_vue.default.prototype.validCode = function (value) {
  value = value.replace(/[\W]/g, '');
  return value;
};
// 数字格式化
_vue.default.prototype.valENnum = function (value) {
  value = value.replace(/[^0-9]/g, '');
  return value;
};
_vue.default.prototype.valTel = function (value) {
  value = value.replace(/[^0-9()-\s]/g, '');
  return value;
};
_vue.default.prototype.valDecimal = function (value) {
  value = value.replace(/[^\d.]/g, '').replace(/^0\d+|^\./g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\d+)\.(\d\d).*$/, '$1.$2');
  return value;
};

// 获取缓存
_vue.default.prototype.getCaches = function (e, name) {
  var hasCache = false;
  var cacheList = e.$store.getters.cachedViews;
  if (cacheList && cacheList.length >= 1) {
    for (var i = 0; i < cacheList.length; i++) {
      if (cacheList[i] == name) {
        hasCache = true;
        break;
      }
    }
  }
  return hasCache;
};
_vue.default.prototype.hasPage = function (path, list) {
  var bool = false;
  if (list && list.length > 0) {
    for (var i = 0; i < list.length; i++) {
      if (list[i].resourceUrl == path) {
        bool = true;
        break;
      }
    }
  }
  return bool;
};

// 判断是否有权限
_vue.default.prototype.hasAuth = function (id, list) {
  var bool = false;
  if (list && list.length > 0) {
    for (var i = 0; i < list.length; i++) {
      if (list[i].resourceId == id) {
        bool = true;
        break;
      }
    }
  }
  return bool;
};

// 获取权限按钮id
_vue.default.prototype.authId = function (id, list) {
  var pid = '';
  if (list && list.length > 0) {
    for (var i = 0; i < list.length; i++) {
      if (list[i].resourceId == id) {
        pid = list[i].pid;
        break;
      }
    }
  }
  localStorage.setItem('btnId', pid);
  return pid;
};
_vue.default.use(_print.default);

// set ElementUI lang to EN
_vue.default.use(_elementUi.default, {
  locale: _zhCN.default
});
_vue.default.config.productionTip = false;
_vue.default.use(_imgPreview.default);

// el-table-footer

_vue.default.use(_elTableFooter.default);
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});