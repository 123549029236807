var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.dialogVisible,
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.mloading,
              expression: "mloading",
            },
          ],
          ref: "scanForm",
          staticClass: "info-items",
          attrs: {
            model: _vm.scanData,
            rules: _vm.rules,
            "label-position": "right",
            "label-width": "150px",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "库房名称:", prop: "storeCode" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "350px" },
                          attrs: { clearable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.scanData.storeCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.scanData, "storeCode", $$v)
                            },
                            expression: "scanData.storeCode",
                          },
                        },
                        _vm._l(_vm.factoryList, function (item) {
                          return _c("el-option", {
                            key: item.pId,
                            attrs: {
                              label: item.storeHouseName,
                              value: item.pId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "is-required",
                      attrs: { label: "产品明细:", prop: "materialCode" },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.openSel },
                        },
                        [_vm._v("添加产品")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.listLoading,
                              expression: "listLoading",
                            },
                          ],
                          key: _vm.tableKey,
                          staticStyle: { width: "100%", "margin-top": "1px" },
                          attrs: {
                            data: _vm.proList,
                            border: _vm.tableConfig.border,
                            stripe: _vm.tableConfig.stripe,
                            fit: "",
                            "highlight-current-row": "",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "序号",
                              type: "index",
                              width: "120",
                              align: _vm.tableConfig.align,
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "产品分类",
                              prop: "productClassName",
                              align: _vm.tableConfig.align,
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "产品编码",
                              prop: "productCode",
                              align: _vm.tableConfig.align,
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "产品名称",
                              prop: "productName",
                              align: _vm.tableConfig.align,
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "包装比例",
                              prop: "packScaleExpression",
                              align: _vm.tableConfig.align,
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-table-column",
                            {
                              attrs: {
                                label: "单位",
                                align: _vm.tableConfig.align,
                                width: "110",
                                "show-overflow-tooltip": "",
                              },
                            },
                            [_vm._v("箱")]
                          ),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "设置最低库存（箱）",
                              prop: "packScaleDesc",
                              align: _vm.tableConfig.align,
                              width: "180",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { margin: "20px auto" },
                                        attrs: {
                                          label: "",
                                          "label-width": "0",
                                          prop: "vmin",
                                        },
                                      },
                                      [
                                        _c("el-input-number", {
                                          attrs: {
                                            size: "small",
                                            rules: {
                                              required: true,
                                              message: "请设置最低库存（箱）",
                                              trigger: "blur",
                                            },
                                            min: 1,
                                            max: 1000,
                                          },
                                          model: {
                                            value: row.vmin,
                                            callback: function ($$v) {
                                              _vm.$set(row, "vmin", $$v)
                                            },
                                            expression: "row.vmin",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "置最高库存（箱）",
                              prop: "packScaleDesc",
                              align: _vm.tableConfig.align,
                              width: "180",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { margin: "20px auto" },
                                        attrs: {
                                          label: "",
                                          "label-width": "0",
                                          prop: "vmax",
                                        },
                                      },
                                      [
                                        _c("el-input-number", {
                                          attrs: {
                                            size: "small",
                                            rules: {
                                              required: true,
                                              message: "请置最高库存（箱）",
                                              trigger: "blur",
                                            },
                                            min: 1,
                                            max: 1000,
                                          },
                                          model: {
                                            value: row.vmax,
                                            callback: function ($$v) {
                                              _vm.$set(row, "vmax", $$v)
                                            },
                                            expression: "row.vmax",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              align: _vm.tableConfig.align,
                              width: "80",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.proDel(row)
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.btn_loading },
              on: {
                click: function ($event) {
                  return _vm.submitForm(3)
                },
              },
            },
            [_vm._v("提 交")]
          ),
          _vm._v(" "),
          _c("el-button", { on: { click: _vm.handleClose } }, [
            _vm._v("关 闭"),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.proShow
        ? _c("product-dialog", {
            ref: "selProduct",
            attrs: { "is-code": "1", "pro-sel-visible": _vm.proShow },
            on: { change: _vm.setSel, close: _vm.proClose },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }