"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.to-string");
var _number = require("@/api/generalSettings2/number");
var _auth = require("@/utils/auth");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _UploadFile = _interopRequireDefault(require("@/components/UploadFile"));
var _distributorSelect = _interopRequireDefault(require("@/components/distributorSelect"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManagegeneralSettingsnumberindex'; // 缓存名字为当前path去掉/
var _default = {
  name: cachName,
  components: {
    Pagination: _Pagination.default,
    UploadImg: _UploadFile.default,
    distributorSelect: _distributorSelect.default
  },
  data: function data() {
    return {
      officialAccountList: [],
      // 经销商列表
      distributorList: [{
        name: '企业',
        leve: 1,
        region: '北京'
      }],
      myHeaders: {
        Authorization: 'Bearer ' + (0, _auth.getToken)(),
        btnId: ''
      },
      imgList: [],
      ruleForm: {
        wxMerchantsType: 1,
        id: null,
        officialName: '',
        // 公众号名称
        appid: '',
        // 公众号ID
        officialBelong: '' // 归属方
      },

      rules: {
        officialName: [{
          message: '请输入公众号',
          required: true,
          trigger: 'blur'
        }],
        appid: [{
          message: '请输入APPid',
          required: true,
          trigger: 'blur'
        }],
        officialBelong: [{
          message: '请选择公众号归属',
          required: true,
          trigger: 'change'
        }]
      },
      title: '',
      merchanTitle: '',
      dialogVisible: false,
      isShowMerchant: false,
      listLoadig: false,
      merchantParams: {
        merchantName: null,
        // 微信支付商户号
        merchantType: null,
        // 商户类型
        merchantDealerName: null // 经销商名称
      },

      // 微信商户列表
      merchantList: [],
      flagId: '',
      // 微信商户表单
      merchantForm: {
        merchantName: '',
        // 微信支付商户号
        merchantSecret: '',
        // 商户号秘钥
        merchantCertificate: '',
        // 商户平台证书
        merchantType: 1,
        // 商户类型
        merchantDealer: '',
        // 经销商pid
        merchantDealerName: '',
        // 经销商名称
        merchantCode: '',
        // 商户号id
        id: '',
        certificateV3SerialNo: '',
        merchantV3SecretNo: '',
        merchantV3CertificateFile: '',
        merchantV3SecretFile: ''
      },
      // 微信商户校验对象
      merchantRules: {
        merchantDealerName: [{
          message: '请选择经销商',
          required: true,
          trigger: 'blur'
        }],
        merchantName: [{
          message: '请输入微信支付商户号',
          required: true,
          trigger: 'blur'
        }],
        merchantSecret: [{
          message: '请输入APP秘钥',
          required: true,
          trigger: 'blur'
        }],
        merchantCertificate: [{
          message: '请上传商户平台证书',
          required: true,
          trigger: 'blur'
        }],
        merchantType: [{
          message: '请选择商户号类型',
          required: true,
          trigger: 'change'
        }],
        merchantCode: [{
          message: '请输入商户号id，只支持数字',
          required: true,
          trigger: 'change'
        }]
      },
      isShow: false,
      isShowDistributor: false,
      distributorForm: {
        region: '',
        user: ''
      },
      // 获取选取经销商信息
      seletDistributorName: '默认',
      queryParams: {
        pageSize: 10,
        pageNo: 1,
        merchantName: null,
        merchantType: null
      },
      total: 0,
      // 商户号列表总条数
      showStop: {
        id: '',
        pid: '',
        status: ''
      },
      offFlagId: '',
      disabled: false,
      btnLoading: false
    };
  },
  computed: {
    // uploadImg() {
    //   return (
    //    process.env.VUE_APP_VERSION2 + '/api-basic/jc/file/upload/uploadImg'
    //   )
    // }
  },
  // 缓存处理机制
  activated: function activated() {
    var hasCache1 = this.merchantList.length > 0;
    var hasCache2 = this.officialAccountList.length > 0;
    if (!hasCache2) {
      this.getofficialList();
    }
    if (!hasCache1) {
      this.getMerchantList();
    }
  },
  deactivated: function deactivated() {
    var name = cachName;
    var cache = this.getCaches(this, name);
    if (!cache) {
      this.merchantList = [];
      this.officialAccountList = [];
      this.total = 0;
    }
  },
  methods: {
    // 获取经销商名称
    getDistributorName: function getDistributorName(data) {
      if (data) {
        this.merchantForm.merchantDealerName = data.channelName;
        // this.$set(this.merchantForm,'merchantDealerName',data.channelName);
        this.merchantForm.merchantDealer = data.pid;
      }
      this.isShowDistributor = false;
    },
    // 获取微信公众号列表
    getofficialList: function getofficialList() {
      var _this = this;
      (0, _number.officialList)().then(function (res) {
        // console.log(res);
        if (res.data.data.errCode) {
          _this.$message.error(res.data.data.errMsg);
          return;
        } else {
          if (res.data.data !== null) {
            _this.officialAccountList = res.data.data;
          } else {
            _this.officialAccountList = [];
          }
        }
      });
    },
    // 公众号授权
    generateurl: function generateurl() {
      window.open(process.env.VUE_APP_BASE_API + '/market-zdConfig/third/api/auth/goview', '_blank');
    },
    // 获取商户号列表
    getMerchantList: function getMerchantList() {
      var _this2 = this;
      // loading加载效果
      this.listLoading = true;
      (0, _number.getMerchantList)(this.queryParams).then(function (res) {
        _this2.listLoadig = false;
        if (res.data.data.errCode) {
          _this2.$message.error(res.data.data.errMsg);
          return;
        } else {
          if (res.data.data != null) {
            _this2.total = parseInt(res.data.data.total);
            _this2.merchantList = res.data.data.records;
          } else {
            _this2.total = 0;
            _this2.merchantList = [];
          }
        }
      }).catch(function () {
        _this2.listLoadig = false;
      });
    },
    // 微信商户的启用停用
    isStop: function isStop() {
      var _this3 = this;
      (0, _number.isStop)(this.showStop).then(function (res) {
        if (res.data.data.errCode) {
          _this3.$message.error(res.data.data.errMsg);
          return;
        } else {
          _this3.getMerchantList();
          _this3.$message.success('修改成功');
        }
      });
    },
    switchTop: function switchTop(row) {
      var _this4 = this;
      console.log(row);
      this.showStop.id = row.id;
      this.showStop.status = row.status == 2 ? 1 : 2;
      var str = row.status == 1 ? '停用' : '启用';
      this.$confirm("\u662F\u5426 ".concat(str, "\u8BE5\u6570\u636E\u9879?"), {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function (res) {
        _this4.isStop();
      }).catch(function (err) {
        console.log(err);
      });
    },
    clearValid: function clearValid(rulesForm) {
      var _this5 = this;
      this.$nextTick(function () {
        _this5.reset();
        _this5.$refs[rulesForm].resetFields();
      });
      if (rulesForm == 'rulesForm') {
        this.dialogVisible = false;
      } else {
        this.isShowMerchant = false;
      }
    },
    // 重置公众号表单
    reset: function reset() {
      this.ruleForm = {
        wxMerchantsType: 1,
        id: null,
        officialName: '',
        // 公众号名称
        appid: '',
        // 公众号ID
        officialBelong: '' // 归属方
      };
      // this.ruleForm.officialName = '';
      // this.ruleForm.appid = '';
      // this.ruleForm.officialBelong = '1';
      // this.ruleForm.id = null;
    },
    // 重置商户号表单
    resetMerchantForm: function resetMerchantForm() {
      this.merchantForm = {
        merchantName: '',
        // 微信支付商户号
        merchantSecret: '',
        // 商户号秘钥
        merchantCertificate: '',
        // 商户平台证书
        merchantType: 1,
        // 商户类型
        merchantDealer: '',
        // 经销商pid
        merchantDealerName: '',
        // 经销商名称
        merchantCode: '',
        // 经销商名称
        id: '',
        certificateV3SerialNo: '',
        merchantV3SecretNo: '',
        merchantV3CertificateFile: '',
        merchantV3SecretFile: ''
      };
    },
    // 新增/修改公众号保存
    handleSave: function handleSave(rulesForm) {
      var _this6 = this;
      this.$refs[rulesForm].validate(function (valid) {
        if (valid) {
          if (_this6.title !== '新增公众号') {
            (0, _number.updateOfficial)(_this6.ruleForm).then(function (res) {
              if (res.data.data.errCode) {
                _this6.disabled = false;
                _this6.btnLoading = false;
                return _this6.$message.error(res.data.data.errMsg);
              } else {
                _this6.$message.success('修改成功');
                _this6.getofficialList();
                _this6.dialogVisible = false;
                _this6.disabled = true;
                _this6.btnLoading = true;
              }
            }).catch(function () {
              // this.disabled =false
              // this.btnLoading = false
            });
          } else {
            (0, _number.addOfficial)(_this6.ruleForm).then(function (res) {
              if (res.data.data.errCode) {
                _this6.disabled = false;
                _this6.btnLoading = false;
                return _this6.$message.error(res.data.data.errMsg);
              } else {
                _this6.$message.success('新增成功');
                _this6.getofficialList();
                _this6.dialogVisible = false;
                _this6.disabled = true;
                _this6.btnLoading = true;
              }
            }).catch(function () {
              // this.disabled =false
              // this.btnLoading = false
            });
          }
        } else {
          _this6.disabled = false;
          _this6.btnLoading = false;
        }
      });
    },
    // 新增/编辑微信商户
    handleSaveMerchant: function handleSaveMerchant(merchantRulesForm) {
      var _this7 = this;
      console.log(this.merchantForm);
      this.$refs[merchantRulesForm].validate(function (valid) {
        if (valid) {
          _this7.merchantForm.merchantDealerName;
          if (_this7.merchantForm.merchantType == 1) {
            delete _this7.merchantForm.merchantDealer;
          }
          if (_this7.merchanTitle != '新增微信商户') {
            //  const arr = this.merchantList.filter(item => item.id != this.flagId);
            // for (let item of arr) {
            //   if(this.merchantForm.merchantName == item.merchantName){
            //     return this.$message.error('商户号名称不能重复');
            //   }
            // }
            (0, _number.updateMerchant)(_this7.merchantForm).then(function (res) {
              if (res.data.data.errCode) {
                _this7.disabled = false;
                _this7.btnLoading = false;
                return _this7.$message.error(res.data.data.errMsg);
              } else {
                _this7.$message.success('修改成功');
                _this7.getMerchantList();
                _this7.disabled = true;
                _this7.btnLoading = true;
                _this7.isShowMerchant = false;
              }
            }).catch(function () {
              // this.disabled =false
              // this.btnLoading = false
            });
          } else {
            _this7.merchantForm.id = '';
            // for (let item of this.merchantList){
            //   if(this.merchantForm.merchantName === item.merchantName){
            //     return this.$message.error('商户号名称不能重复')
            //   }
            // }
            (0, _number.addMerchant)(_this7.merchantForm).then(function (res) {
              if (res.data.data.errCode) {
                _this7.disabled = false;
                _this7.btnLoading = false;
                return _this7.$message.error(res.data.data.errMsg);
              } else {
                _this7.$message.success('新增成功');
                _this7.getMerchantList();
                _this7.isShowMerchant = false;
                _this7.disabled = true;
                _this7.btnLoading = true;
              }
            }).catch(function () {
              // this.disabled =false
              // this.btnLoading = false
            });
          }
        } else {
          _this7.disabled = false;
          _this7.btnLoading = false;
        }
      });
    },
    acceptFun: function acceptFun(file) {
      if (file.type != 'application/x-pkcs12') {
        this.$message.error('请上传格式为.p12格式的文件证书');
        return false;
      }
    },
    // 新增公众号
    handleAddOfficialAccount: function handleAddOfficialAccount() {
      var _this8 = this;
      this.disabled = false;
      this.btnLoading = false;
      this.title = '新增公众号';
      this.$nextTick(function () {
        _this8.$refs.rulesForm.resetFields();
        _this8.reset();
      });
      this.dialogVisible = true;
      // this.$nextTick(() => {
      //   this.$refs.rulesForm.resetFields();
      // })
    },
    // 编辑公众号
    handleUpdateOfficialAccount: function handleUpdateOfficialAccount(row) {
      this.disabled = false;
      this.btnLoading = false;
      console.log(row);
      // this.ruleForm = row;
      this.ruleForm.wxMerchantsType = row.wxMerchantsType;
      this.ruleForm.officialBelong = row.officialBelong.toString();
      this.ruleForm.officialName = row.officialName;
      this.ruleForm.appid = row.appid;
      this.ruleForm.id = row.id;
      // console.log(this.ruleForm.officialBelong);
      this.title = '编辑公众号';
      this.dialogVisible = true;
      this.offFlagId = row.id;
    },
    // 新增微信商户
    handleAddMerchant: function handleAddMerchant() {
      var _this9 = this;
      this.disabled = false;
      this.btnLoading = false;
      this.merchanTitle = '新增微信商户';
      this.isShowDistributor = false;
      this.isShow = false;
      this.$nextTick(function () {
        _this9.$refs.shImg.fileList = [];
        _this9.resetMerchantForm();
        _this9.$refs.merchantRulesForm.resetFields();
      });
      this.isShowMerchant = true;
    },
    // 编辑微信商户
    handleUpdateMerchant: function handleUpdateMerchant(row) {
      var _this10 = this;
      console.log(row);
      this.disabled = false;
      this.btnLoading = false;
      this.merchanTitle = '编辑微信商户';
      // this.merchantForm = row;
      // this.merchantForm.merchantType = row.merchantType
      if (row.merchantType == 1) {
        this.merchantForm.merchantDealerName = '';
        this.isShow = false;
      } else {
        this.merchantForm.merchantDealerName = row.merchantDealerName;
      }
      this.merchantForm.merchantName = row.merchantName;
      this.merchantForm.merchantSecret = row.merchantSecret;
      this.merchantForm.merchantType = Number(row.merchantType);
      this.merchantForm.merchantCertificate = row.merchantCertificate;
      this.merchantForm.merchantCode = row.merchantCode;
      this.merchantForm.merchantDealer = row.merchantDealer;
      this.$nextTick(function () {
        // 回显文件名
        var nameArr = row.merchantCertificate.split('/');
        var name = '';
        if (Array.isArray(nameArr) && nameArr.length) {
          name = nameArr[nameArr.length - 1];
        }
        _this10.$refs.shImg.fileList = [{
          url: row.merchantCertificate,
          name: name
        }];
      });

      //
      this.$set(this.merchantForm, 'merchantCertificate', row.merchantCertificate);
      // this.merchantForm.merchantDealer = row.merchantDealer
      // this.merchantForm.merchantDealer = ''
      console.log(this.merchantForm);
      this.merchantForm.id = row.id;
      this.flagId = row.id;
      if (this.merchantForm.merchantType == 2) {
        this.isShow = true;
      }
      // setTimeout(() => {
      //   this.isShowMerchant = true;
      // },200)
      this.isShowMerchant = true;
    },
    // 商户号change事件
    handleVauleChange: function handleVauleChange(val) {
      console.log(val);
      this.merchantForm.merchantDealerName = '';
      this.isShow = val == 2;
    },
    // 取消新增微信商户
    clearMerchan: function clearMerchan(res) {
      var _this11 = this;
      this.$nextTick(function () {
        _this11.resetMerchantForm();
        _this11.$refs[res].resetFields();
      });
      this.isShowDistributor = false;
      this.merchantForm.merchantType = 1;
      // this.merchantForm.merchantDealerName = '';
      this.isShowMerchant = false;
      this.isShow = false;
    },
    // 经销商选取弹框
    handleSelectDistributor: function handleSelectDistributor() {
      this.isShowDistributor = true;
    },
    // 选取经销商
    seletdistributor: function seletdistributor(row) {
      this.merchantForm.merchantDealerName = row.name;
      // console.log(this.seletDistributorName);
      this.isShowDistributor = false;
    },
    restMerchantList: function restMerchantList() {
      this.queryParams = {
        pageSize: 10,
        pageNo: 1,
        merchantName: null,
        merchantType: null
      };
      this.getMerchantList();
    },
    querylist: function querylist() {
      this.queryParams.pageNo = 1;
      this.getMerchantList();
    },
    typeFormatter: function typeFormatter(row) {
      if (row.status == 1) return '未授权';
      return '已授权';
    },
    nameFormatter: function nameFormatter(row) {
      if (row.officialBelong == 1) return '企业';
      return '经销商';
    },
    showFormatter: function showFormatter(row) {
      if (row.status == 1) return '启用';
      return '停用';
    },
    nameMerchantType: function nameMerchantType(row) {
      if (row.merchantType == 1) return '企业';
      return '经销商';
    }
  }
};
exports.default = _default;