var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        { staticClass: "page-section" },
        [
          _c("h3", { staticClass: "h-title" }, [_vm._v("终端档案")]),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "searchForm",
              staticClass: "search-condition",
              attrs: {
                model: _vm.searchForm,
                "status-icon": "",
                "label-position": _vm.formConfig.labelPosition,
                "label-width": _vm.formConfig.labelWidth,
                "label-suffix": ":",
              },
            },
            [
              _c(
                "div",
                { staticClass: "cols" },
                [
                  _c(
                    "el-row",
                    {
                      staticStyle: { "margin-bottom": "0" },
                      attrs: { gutter: 20 },
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6, prop: "keyword" } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "关键字", prop: "keyword" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  clearable: "",
                                  placeholder: "编码/名称/联系人/手机号",
                                },
                                model: {
                                  value: _vm.searchForm.keyword,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "keyword", $$v)
                                  },
                                  expression: "searchForm.keyword",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6, prop: "unionId" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "微信union ID", prop: "unionId" },
                            },
                            [
                              _c("el-input", {
                                attrs: { clearable: "", placeholder: "请输入" },
                                model: {
                                  value: _vm.searchForm.unionId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "unionId", $$v)
                                  },
                                  expression: "searchForm.unionId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6, prop: "wxId" } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "微信ID", prop: "wxId" } },
                            [
                              _c("el-input", {
                                attrs: { clearable: "", placeholder: "请输入" },
                                model: {
                                  value: _vm.searchForm.wxId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "wxId", $$v)
                                  },
                                  expression: "searchForm.wxId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6, prop: "activeStatus" } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "状态", prop: "activeStatus" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择",
                                  },
                                  model: {
                                    value: _vm.searchForm.activeStatus,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "activeStatus",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.activeStatus",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "全部", value: "" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "待激活", value: 0 },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "激活成功", value: 1 },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6, prop: "area" } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "省/市/区", prop: "area" } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "provinceCode",
                                        "label-width": "0",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100px" },
                                          attrs: {
                                            placeholder: "省",
                                            clearable: "",
                                          },
                                          on: { change: _vm.ProvinceFun },
                                          model: {
                                            value: _vm.searchForm.provinceCode,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.searchForm,
                                                "provinceCode",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "searchForm.provinceCode",
                                          },
                                        },
                                        _vm._l(
                                          _vm.ProvinceList,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.pid,
                                              attrs: {
                                                label: item.areaName,
                                                value: item.areaCode,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "",
                                        prop: "cityCode",
                                        "label-width": "0",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100px" },
                                          attrs: {
                                            placeholder: "市",
                                            disabled:
                                              !_vm.searchForm.provinceCode,
                                            clearable: "",
                                          },
                                          on: { change: _vm.CityFun },
                                          model: {
                                            value: _vm.searchForm.cityCode,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.searchForm,
                                                "cityCode",
                                                $$v
                                              )
                                            },
                                            expression: "searchForm.cityCode",
                                          },
                                        },
                                        _vm._l(_vm.CityList, function (item) {
                                          return _c("el-option", {
                                            key: item.pid,
                                            attrs: {
                                              label: item.areaName,
                                              value: item.areaCode,
                                            },
                                          })
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "",
                                        prop: "countyCode",
                                        "label-width": "0",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100px" },
                                          attrs: {
                                            placeholder: "区",
                                            disabled: !_vm.searchForm.cityCode,
                                            clearable: "",
                                          },
                                          model: {
                                            value: _vm.searchForm.countyCode,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.searchForm,
                                                "countyCode",
                                                $$v
                                              )
                                            },
                                            expression: "searchForm.countyCode",
                                          },
                                        },
                                        _vm._l(_vm.CountyList, function (item) {
                                          return _c("el-option", {
                                            key: item.pid,
                                            attrs: {
                                              label: item.areaName,
                                              value: item.areaCode,
                                            },
                                          })
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.formConfig.btnFollow
                        ? _c(
                            "el-col",
                            {
                              staticClass: "head-btn-group",
                              style: {
                                width: _vm.formConfig.btnAreaHasShowMore,
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { "label-width": "0" } },
                                [
                                  _c(
                                    "div",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [_vm._v(" ")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.searchBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.searchBtn.icon,
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.searchForm.current = 1
                                          _vm.getList()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.btnConfig.searchBtn.text)
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.resetBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.resetBtn.icon,
                                      },
                                      on: { click: _vm.resetForm },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.btnConfig.resetBtn.text)
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _vm.formConfig.btnFollow
            ? _c("el-divider", { staticClass: "btnDivider" })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "btnArea" },
            [
              _c(
                "el-col",
                {
                  staticClass: "head-btn-group",
                  staticStyle: { width: "230px" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "head-btn-group" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: _vm.btnConfig.addBtn.type,
                            size: _vm.btnConfig.size,
                            icon: _vm.btnConfig.addBtn.icon,
                          },
                          on: { click: _vm.add },
                        },
                        [_vm._v(_vm._s(_vm.btnConfig.addBtn.text))]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: _vm.btnConfig.upLoadBtn.type,
                            size: _vm.btnConfig.size,
                            icon: _vm.btnConfig.upLoadBtn.icon,
                          },
                          on: { click: _vm.uploadShow },
                        },
                        [_vm._v(_vm._s(_vm.btnConfig.upLoadBtn.text))]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: _vm.btnConfig.downLoadBtn.type,
                            size: _vm.btnConfig.size,
                            icon: _vm.btnConfig.downLoadBtn.icon,
                            loading: _vm.downLoading,
                          },
                          on: { click: _vm.download },
                        },
                        [_vm._v(_vm._s(_vm.btnConfig.downLoadBtn.text))]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "section",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  key: _vm.tableKey,
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.tableData,
                    height: _vm.tabHeight,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    fit: "",
                    "highlight-current-row": "",
                  },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "微信union ID",
                      prop: "unionId",
                      "min-width": "100",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "微信ID",
                      prop: "wxId",
                      "min-width": "100",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "微信地理位置",
                      prop: "wxPosition",
                      "min-width": "100",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "终端编码",
                      prop: "channelCode",
                      "min-width": "100",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "终端名称",
                      prop: "channelName",
                      "min-width": "100",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "联系人",
                      "min-width": "100",
                      prop: "contacts",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "店主身份证",
                      "min-width": "100",
                      prop: "idCard",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "手机号",
                      "min-width": "100",
                      prop: "phone",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "销售组织",
                      prop: "regionName",
                      "min-width": "100",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "省",
                      "min-width": "80",
                      prop: "provinceName",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "市",
                      "min-width": "80",
                      prop: "cityName",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "区县",
                      "min-width": "80",
                      prop: "countyName",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "门店地址",
                      "min-width": "150",
                      prop: "address",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "经纬度",
                      "min-width": "150",
                      prop: "coords",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "状态",
                      "min-width": "80",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            row.activeStatus == "1"
                              ? _c("el-tag", { attrs: { type: "success" } }, [
                                  _vm._v("激活成功"),
                                ])
                              : row.activeStatus == "0"
                              ? _c("el-tag", { attrs: { type: "danger" } }, [
                                  _vm._v("待激活"),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "状态",
                      "min-width": "80",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            row.enableStatus == "0"
                              ? _c("el-tag", { attrs: { type: "success" } }, [
                                  _vm._v("启用"),
                                ])
                              : row.enableStatus == "1"
                              ? _c("el-tag", { attrs: { type: "danger" } }, [
                                  _vm._v("禁用"),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      fixed: "right",
                      align: _vm.tableConfig.align,
                      "min-width": "200",
                      "class-name": "small-padding fixed-width",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.DoEdit(row)
                                  },
                                },
                              },
                              [_vm._v("修改")]
                            ),
                            _vm._v(" "),
                            row.enableStatus == "0"
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.DoStop(row)
                                      },
                                    },
                                  },
                                  [_vm._v("禁用")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            row.enableStatus == "1"
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.DoStart(row)
                                      },
                                    },
                                  },
                                  [_vm._v("启用")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                  page: _vm.searchForm.current,
                  limit: _vm.searchForm.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.searchForm, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.searchForm, "size", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: _vm.title,
            visible: _vm.dialogVisible,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.mloading,
                  expression: "mloading",
                },
              ],
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "100px",
                "label-suffix": ":",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "店主手机号", prop: "phone" } },
                [
                  _c("el-input", {
                    attrs: {
                      value: _vm.ruleForm.phone,
                      maxlength: "11",
                      placeholder: "请输入",
                    },
                    on: {
                      input: function (e) {
                        return (_vm.ruleForm.phone = _vm.valENnum(e))
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "店主姓名", prop: "userName" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.userName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "userName", $$v)
                      },
                      expression: "ruleForm.userName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "终端店名称", prop: "channelName" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.channelName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "channelName", $$v)
                      },
                      expression: "ruleForm.channelName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "regionCode", label: "销售组织" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      on: { change: _vm.changeregion },
                      model: {
                        value: _vm.ruleForm.regionCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "regionCode", $$v)
                        },
                        expression: "ruleForm.regionCode",
                      },
                    },
                    _vm._l(_vm.regionList, function (item) {
                      return _c("el-option", {
                        key: item.pId,
                        attrs: {
                          label: item.regionName,
                          value: item.regionCode,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "门店地址", prop: "address" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "10", placeholder: "请输入" },
                    model: {
                      value: _vm.ruleForm.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "address", $$v)
                      },
                      expression: "ruleForm.address",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "店主身份证", prop: "idCard" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "18" },
                    on: { blur: _vm.validatecardNo },
                    model: {
                      value: _vm.ruleForm.idCard,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "idCard", $$v)
                      },
                      expression: "ruleForm.idCard",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "经纬度" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.ruleForm.coords,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "coords", $$v)
                      },
                      expression: "ruleForm.coords",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    "margin-left": "100px",
                    "margin-bottom": "10px",
                  },
                },
                [_vm._v("\n          经纬度示例116.40400,39.92800\n        ")]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "dialog-footer",
                  staticStyle: { "text-align": "right" },
                },
                [
                  !_vm.disabled
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", loading: _vm.btnLoading },
                          on: { click: _vm.submitForm },
                        },
                        [_vm._v("确 定")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-button", { on: { click: _vm.handleClose } }, [
                    _vm._v("取 消"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.mloading,
              expression: "mloading",
            },
          ],
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: "批量导入——终端档案",
            visible: _vm.uploadVisible,
            "before-close": _vm.uploadClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.uploadVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "uploadForm",
              attrs: { model: _vm.uploadForm, rulse: _vm.uploadRules },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "下载模板", prop: "orgName" } },
                [
                  _c(
                    "a",
                    {
                      staticClass: "down",
                      attrs: {
                        href: "https://baiyunbian-default-test.ks3-cn-beijing.ksyuncs.com/2023/06/1686723966282/终端用户模板.xlsx",
                      },
                    },
                    [_vm._v("终端档案导入Excel模板")]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { display: "flex" },
                  attrs: { prop: "regionCode", label: "销售组织" },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      on: { change: _vm.changeregion1 },
                      model: {
                        value: _vm.uploadForm.regionCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.uploadForm, "regionCode", $$v)
                        },
                        expression: "uploadForm.regionCode",
                      },
                    },
                    _vm._l(_vm.regionList, function (item) {
                      return _c("el-option", {
                        key: item.pId,
                        attrs: {
                          label: item.regionName,
                          value: item.regionCode,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { display: "flex" },
                  attrs: { prop: "orgName", label: "文件上传" },
                },
                [
                  _c(
                    "el-row",
                    { staticClass: "head-btn-group", attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-input", {
                            attrs: { disabled: true },
                            model: {
                              value: _vm.uploadForm.uploadFile,
                              callback: function ($$v) {
                                _vm.$set(_vm.uploadForm, "uploadFile", $$v)
                              },
                              expression: "uploadForm.uploadFile",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-upload",
                            {
                              ref: "upload",
                              staticClass: "upload-demo",
                              attrs: {
                                headers: _vm.myHeaders,
                                action: _vm.uploadUrl,
                                limit: 1,
                                name: "file",
                                data: _vm.uploadForm,
                                accept: ".xls, .xlsx",
                                "file-list": _vm.fileList,
                                "show-file-list": false,
                                "auto-upload": false,
                                "on-change": _vm.selectFile,
                                "on-success": _vm.uploadFileRes,
                                "on-error": _vm.uploadFileFail,
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    slot: "trigger",
                                    size: "mini",
                                    type: "primary",
                                  },
                                  slot: "trigger",
                                },
                                [_vm._v("打开")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "primary" },
                                  on: { click: _vm.submitUpload },
                                },
                                [_vm._v("导入")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.hasError == "N"
                    ? _c("el-row", { staticStyle: { color: "green" } }, [
                        _vm._v("导入成功!"),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasError == "Y"
                    ? _c("el-row", { staticStyle: { color: "red" } }, [
                        _vm._v("部分数据失败!"),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              cursor: "pointer",
                              color: "#409eff",
                              "text-decoration": "underline",
                              "margin-left": "30px",
                            },
                            on: { click: _vm.downloaderror },
                          },
                          [_vm._v("查看结果明细")]
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { staticStyle: { "font-size": "12px" } }, [
                _c("p", [_vm._v("备注：")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n            1.必录项：终端名称、店主姓名、店主手机号 ;\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v("2.经销商必须在移动端维护终端档案后才可以导入;"),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n            3.导入成功后才可以参加终端营销活动;\n          "
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: "业务员列表",
            visible: _vm.dialogSalesmanList,
            "before-close": _vm.selCancel,
            width: "850px !important",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogSalesmanList = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "page-container" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { staticClass: "page-section" },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "searchForm",
                          staticClass: "search-condition",
                          attrs: {
                            model: _vm.querysalesman,
                            "status-icon": "",
                            "label-suffix": ":",
                            "label-position": _vm.formConfig.labelPosition,
                            "label-width": _vm.formConfig.labelWidth,
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "cols" },
                            [
                              _c(
                                "el-row",
                                { attrs: { gutter: 20 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8, prop: "salesman" } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "salesManName",
                                            label: "用户名",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              clearable: "",
                                              placeholder: "业务员用户名",
                                            },
                                            model: {
                                              value:
                                                _vm.querysalesman
                                                  .salesManAccount,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.querysalesman,
                                                  "salesManAccount",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "querysalesman.salesManAccount",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8, prop: "salesman" } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "salesManName",
                                            label: "姓名",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              clearable: "",
                                              placeholder: "业务员姓名",
                                            },
                                            model: {
                                              value:
                                                _vm.querysalesman.salesManName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.querysalesman,
                                                  "salesManName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "querysalesman.salesManName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "head-btn-group",
                                      style: { width: _vm.formConfig.btnArea },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { "label-width": "0" } },
                                        [
                                          _c("div", {
                                            attrs: { slot: "label" },
                                            slot: "label",
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "el-button-group",
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: _vm.btnConfig
                                                      .searchBtn.type,
                                                    icon: _vm.btnConfig
                                                      .searchBtn.icon,
                                                    plain:
                                                      _vm.btnConfig.searchBtn
                                                        .plain,
                                                  },
                                                  on: { click: _vm.querylist },
                                                },
                                                [_vm._v("搜索")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: _vm.btnConfig.resetBtn
                                                      .type,
                                                    icon: _vm.btnConfig.resetBtn
                                                      .icon,
                                                    plain:
                                                      _vm.btnConfig.resetBtn
                                                        .plain,
                                                  },
                                                  on: {
                                                    click: _vm.resetQuerylist,
                                                  },
                                                },
                                                [_vm._v("重置")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "section",
                        [
                          _c(
                            "el-table",
                            {
                              ref: "multipleTable",
                              staticStyle: { width: "100%" },
                              attrs: {
                                data: _vm.salesmanList,
                                "row-key": "salesManId",
                                height: "400px",
                                border: "",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "salesManAccount",
                                  label: "用户名",
                                  align: "center",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "salesManName",
                                  label: "姓名",
                                  align: "center",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "操作",
                                  align: "center",
                                  width: "200px",
                                  "class-name": "small-padding fixed-width",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.submitSalesmanList(
                                                  row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("选择")]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("pagination", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.salesmanTotal > 0,
                                expression: "salesmanTotal > 0",
                              },
                            ],
                            attrs: {
                              layout: "total, sizes, prev, pager, next, jumper",
                              total: _vm.salesmanTotal,
                              page: _vm.current,
                              limit: _vm.salesmanSize,
                            },
                            on: {
                              "update:page": function ($event) {
                                _vm.current = $event
                              },
                              "update:limit": function ($event) {
                                _vm.salesmanSize = $event
                              },
                              pagination: function ($event) {
                                return _vm.getcomBoxSalesManlist()
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("personnel-checkbox-dialog", {
        ref: "perCheckbox",
        attrs: {
          data: _vm.salesmanIds,
          "dealer-code": _vm.ruleForm.dealerCode,
          "is-readonly": true,
        },
        on: {
          "update:data": function ($event) {
            _vm.salesmanIds = $event
          },
          "update:dealerCode": function ($event) {
            return _vm.$set(_vm.ruleForm, "dealerCode", $event)
          },
          "update:dealer-code": function ($event) {
            return _vm.$set(_vm.ruleForm, "dealerCode", $event)
          },
          change: _vm.setSel,
        },
      }),
      _vm._v(" "),
      _vm.isShow
        ? _c("enterpriseUsers", {
            ref: "bagDialog",
            attrs: {
              id: _vm.ids,
              "sales-man-user-id": _vm.salesManUserId,
              "multiple-list": _vm.oldList,
              "sales-man-nameq": _vm.salesManNameq,
            },
            on: {
              close: _vm.closePriseUsers,
              change: _vm.getMultipleSelection,
              getData: _vm.getData,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("channel-select-dialog", {
        ref: "channelSelect",
        attrs: { "channel-type": 2, "channel-type-can-change": true },
        on: { change: _vm.setCSel },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }