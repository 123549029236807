var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    [
      _c(
        "div",
        { staticClass: "hyyy-container" },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-card",
                    [
                      _c("div", { staticClass: "hyyy-top-tit" }, [
                        _c("div", { staticClass: "hyyy-top-tit-lift" }, [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(_vm.$route.meta.title),
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "tipsa" }, [
                            _vm._v(
                              "\n                提示：创建会员卡选择等级会员卡模式时，任务中心有效且必须设置才能完成创建；反之创建会员卡选择普通会员卡模式时，则无须设置任务中心。\n              "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "hyyy-top-tit-right" }),
                      ]),
                      _vm._v(" "),
                      _c("el-divider", { staticStyle: { margin: "0" } }),
                      _vm._v(" "),
                      _c("el-row", { staticClass: "hyyy-contentt" }, [
                        _c(
                          "div",
                          { staticClass: "hyyy-main" },
                          [
                            _c("h4", [_vm._v("基础信息")]),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c("el-col", [
                                  _c(
                                    "span",
                                    { staticStyle: { "font-size": "13px" } },
                                    [
                                      _vm._v("任务值名称:"),
                                      _c("el-input", {
                                        staticStyle: {
                                          width: "240px",
                                          "font-size": "13px",
                                        },
                                        attrs: {
                                          maxlength: "6",
                                          "show-word-limit": "",
                                          placeholder:
                                            "类似淘气值，不超过6个字",
                                        },
                                        model: {
                                          value: _vm.taskName,
                                          callback: function ($$v) {
                                            _vm.taskName = $$v
                                          },
                                          expression: "taskName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("el-divider", { staticClass: "rwder" }),
                            _vm._v(" "),
                            _c("h4", [_vm._v("任务内容")]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v("任务值的获取规则有以下两种，请二选一："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-radio-group",
                              {
                                on: { change: _vm.edfr },
                                model: {
                                  value: _vm.formr.ruleType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formr, "ruleType", $$v)
                                  },
                                  expression: "formr.ruleType",
                                },
                              },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    staticClass: "taskfirst",
                                    attrs: { label: "1" },
                                  },
                                  [
                                    _vm._v(
                                      "任务值与获取的积分挂钩\n                  "
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          margin: "20px 0 0 18px",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticStyle: { width: "80px" },
                                          model: {
                                            value: _vm.formr.changeIntegral,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formr,
                                                "changeIntegral",
                                                $$v
                                              )
                                            },
                                            expression: "formr.changeIntegral",
                                          },
                                        }),
                                        _vm._v(
                                          "\n                    任务值 =\n                    "
                                        ),
                                        _c("el-input", {
                                          staticStyle: { width: "80px" },
                                          model: {
                                            value: _vm.formr.changeTask,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formr,
                                                "changeTask",
                                                $$v
                                              )
                                            },
                                            expression: "formr.changeTask",
                                          },
                                        }),
                                        _vm._v("积分\n                    "),
                                        _c("p", [
                                          _vm._v(
                                            "\n                      注意：任务值只与获取积分数量挂钩，积分消耗不会对任务值造成影响。\n                    "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("el-radio", { attrs: { label: "2" } }, [
                                  _vm._v(
                                    "任务值获取按照下列任务实现\n                  "
                                  ),
                                  _c(
                                    "div",
                                    { staticStyle: { "margin-left": "24px" } },
                                    [
                                      _c(
                                        "el-form",
                                        {
                                          ref: "form",
                                          attrs: {
                                            model: _vm.formr,
                                            "label-width": "10px",
                                          },
                                        },
                                        [
                                          [
                                            _c(
                                              "el-row",
                                              {
                                                attrs: {
                                                  type: "flex",
                                                  justify: "space-between",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 12 } },
                                                  [
                                                    _c(
                                                      "el-checkbox",
                                                      {
                                                        attrs: {
                                                          label: "注册会员",
                                                          name: "type",
                                                        },
                                                        on: {
                                                          "": function (
                                                            $event
                                                          ) {},
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.formr
                                                              .isRegisterMember,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.formr,
                                                              "isRegisterMember",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "formr.isRegisterMember",
                                                        },
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("注册会员"),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          [
                                                            _vm._v(
                                                              "新会员注册即可获得\n                                "
                                                            ),
                                                            _c("el-input", {
                                                              staticStyle: {
                                                                width: "80px",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.formr
                                                                    .valueRegisterMember,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.formr,
                                                                      "valueRegisterMember",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "formr.valueRegisterMember",
                                                              },
                                                            }),
                                                            _vm._v(
                                                              "\n                                任务值"
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-checkbox",
                                                      {
                                                        attrs: {
                                                          label: "购买商品",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.formr
                                                              .isPurchaseCommodity,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.formr,
                                                              "isPurchaseCommodity",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "formr.isPurchaseCommodity",
                                                        },
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("购买商品"),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-row",
                                                          _vm._l(
                                                            _vm.formr
                                                              .taskCommodityList,
                                                            function (
                                                              item,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "el-col",
                                                                {
                                                                  key: index,
                                                                  attrs: {
                                                                    span: 24,
                                                                  },
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _c(
                                                                      "div",
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                      每消费\n                                      "
                                                                        ),
                                                                        _c(
                                                                          "el-input",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                width:
                                                                                  "80px",
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .formr
                                                                                    .taskCommodityList[
                                                                                    index
                                                                                  ]
                                                                                    .taskConsumption,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm
                                                                                        .formr
                                                                                        .taskCommodityList[
                                                                                        index
                                                                                      ],
                                                                                      "taskConsumption",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "\n                                          formr.taskCommodityList[index]\n                                            .taskConsumption\n                                        ",
                                                                              },
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "el-select",
                                                                          {
                                                                            on: {
                                                                              change:
                                                                                _vm.changecp,
                                                                            },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .formr
                                                                                    .taskCommodityList[
                                                                                    index
                                                                                  ]
                                                                                    .taskCompany,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm
                                                                                        .formr
                                                                                        .taskCommodityList[
                                                                                        index
                                                                                      ],
                                                                                      "taskCompany",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "\n                                          formr.taskCommodityList[index]\n                                            .taskCompany\n                                        ",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "el-option",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    label:
                                                                                      "元",
                                                                                    value:
                                                                                      "1",
                                                                                  },
                                                                              }
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "el-option",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    label:
                                                                                      "产品",
                                                                                    value:
                                                                                      "2",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _vm._v(
                                                                          "\n                                      ，获得\n                                      "
                                                                        ),
                                                                        _c(
                                                                          "el-input",
                                                                          {
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .formr
                                                                                    .taskCommodityList[
                                                                                    index
                                                                                  ]
                                                                                    .taskValue,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm
                                                                                        .formr
                                                                                        .taskCommodityList[
                                                                                        index
                                                                                      ],
                                                                                      "taskValue",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "\n                                          formr.taskCommodityList[index].taskValue\n                                        ",
                                                                              },
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          "任务值\n                                      "
                                                                        ),
                                                                        _c(
                                                                          "i",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "show",
                                                                                  rawName:
                                                                                    "v-show",
                                                                                  value:
                                                                                    _vm
                                                                                      .formr
                                                                                      .taskCommodityList
                                                                                      .length >
                                                                                    1,
                                                                                  expression:
                                                                                    "\n                                          formr.taskCommodityList.length > 1\n                                        ",
                                                                                },
                                                                              ],
                                                                            staticClass:
                                                                              "el-icon-remove-outline",
                                                                            attrs:
                                                                              {
                                                                                size: "small",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.reduceshoplist(
                                                                                    index,
                                                                                    _vm
                                                                                      .formr
                                                                                      .taskCommodityList
                                                                                  )
                                                                                },
                                                                            },
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "el-link",
                                                                          {
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.addshoplist(
                                                                                    index,
                                                                                    _vm
                                                                                      .formr
                                                                                      .taskCommodityList
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "添加门槛"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]),
                                                                  _vm._v(" "),
                                                                  _c("span"),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-row",
                                                          [
                                                            _c(
                                                              "el-col",
                                                              {
                                                                attrs: {
                                                                  span: 24,
                                                                },
                                                              },
                                                              [
                                                                _c("p", [
                                                                  _vm._v(
                                                                    "如有商城可进行关联"
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "el-col",
                                                              {
                                                                attrs: {
                                                                  span: 24,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                  小程序APPID："
                                                                ),
                                                                _c("el-input", {
                                                                  staticStyle: {
                                                                    width:
                                                                      "200px",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.formr
                                                                        .programAppId,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.formr,
                                                                          "programAppId",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "formr.programAppId",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-checkbox",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value: _vm.fwyzshop,
                                                            expression:
                                                              "fwyzshop",
                                                          },
                                                        ],
                                                        attrs: {
                                                          label: "防伪验证",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.formr
                                                              .isCounterfeiting,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.formr,
                                                              "isCounterfeiting",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "formr.isCounterfeiting",
                                                        },
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("防伪验证"),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-row",
                                                          _vm._l(
                                                            _vm.formr
                                                              .taskCounterfeitingList,
                                                            function (
                                                              item,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "el-col",
                                                                {
                                                                  key: index,
                                                                  staticStyle: {
                                                                    "margin-bottom":
                                                                      "10px",
                                                                  },
                                                                  attrs: {
                                                                    span: 24,
                                                                  },
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _c(
                                                                      "span",
                                                                      [
                                                                        _vm._v(
                                                                          "每扫码"
                                                                        ),
                                                                        _c(
                                                                          "el-input",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                width:
                                                                                  "80px",
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .formr
                                                                                    .taskCounterfeitingList[
                                                                                    index
                                                                                  ]
                                                                                    .taskConsumption,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm
                                                                                        .formr
                                                                                        .taskCounterfeitingList[
                                                                                        index
                                                                                      ],
                                                                                      "taskConsumption",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "\n                                        formr.taskCounterfeitingList[index]\n                                          .taskConsumption\n                                      ",
                                                                              },
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          "次防伪验证码"
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "span",
                                                                      [
                                                                        _vm._v(
                                                                          "，获得\n                                      "
                                                                        ),
                                                                        _c(
                                                                          "el-input",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                width:
                                                                                  "80px",
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .formr
                                                                                    .taskCounterfeitingList[
                                                                                    index
                                                                                  ]
                                                                                    .taskValue,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm
                                                                                        .formr
                                                                                        .taskCounterfeitingList[
                                                                                        index
                                                                                      ],
                                                                                      "taskValue",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "\n                                          formr.taskCounterfeitingList[index]\n                                            .taskValue\n                                        ",
                                                                              },
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          "\n                                      任务值\n                                      "
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _c(
                                                                              "i",
                                                                              {
                                                                                directives:
                                                                                  [
                                                                                    {
                                                                                      name: "show",
                                                                                      rawName:
                                                                                        "v-show",
                                                                                      value:
                                                                                        _vm
                                                                                          .formr
                                                                                          .taskCounterfeitingList
                                                                                          .length >
                                                                                        1,
                                                                                      expression:
                                                                                        "\n                                            formr.taskCounterfeitingList.length >\n                                              1\n                                          ",
                                                                                    },
                                                                                  ],
                                                                                staticClass:
                                                                                  "el-icon-remove-outline",
                                                                                attrs:
                                                                                  {
                                                                                    size: "small",
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.reducefwlist(
                                                                                        index,
                                                                                        _vm
                                                                                          .formr
                                                                                          .taskCounterfeitingList
                                                                                      )
                                                                                    },
                                                                                },
                                                                              }
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "el-link",
                                                                              {
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.addfwlist(
                                                                                        index,
                                                                                        _vm
                                                                                          .formr
                                                                                          .taskCounterfeitingList
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "添加门槛"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-checkbox",
                                                      {
                                                        attrs: {
                                                          label: "分享好友",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.formr.isFriend,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.formr,
                                                              "isFriend",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "formr.isFriend",
                                                        },
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("分享好友"),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          [
                                                            _vm._v(
                                                              "\n                                每分享1名好友获得\n                                "
                                                            ),
                                                            _c("el-input", {
                                                              staticStyle: {
                                                                width: "80px",
                                                                "margin-bottom":
                                                                  "10px",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.formr
                                                                    .taskFriend,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.formr,
                                                                      "taskFriend",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "formr.taskFriend",
                                                              },
                                                            }),
                                                            _vm._v(
                                                              "任务值。\n\n                              "
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-row",
                                                          [
                                                            _c(
                                                              "el-col",
                                                              [
                                                                _vm._v(
                                                                  "\n                                  要求：每用户通过分享每天最多可获得\n                                  "
                                                                ),
                                                                _c("el-input", {
                                                                  staticStyle: {
                                                                    width:
                                                                      "80px",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.formr
                                                                        .dayMaxTask,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.formr,
                                                                          "dayMaxTask",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "formr.dayMaxTask",
                                                                  },
                                                                }),
                                                                _vm._v(
                                                                  "任务值，且总共可获得\n                                  "
                                                                ),
                                                                _c("el-input", {
                                                                  staticStyle: {
                                                                    width:
                                                                      "80px",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.formr
                                                                        .maxTask,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.formr,
                                                                          "maxTask",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "formr.maxTask",
                                                                  },
                                                                }),
                                                                _vm._v(
                                                                  "\n                                  任务值。\n                                "
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 10 } },
                                                  [
                                                    _c(
                                                      "el-checkbox",
                                                      {
                                                        attrs: {
                                                          label: "关注公众号",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.formr.isFollow,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.formr,
                                                              "isFollow",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "formr.isFollow",
                                                        },
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("关注公众号"),
                                                        ]),
                                                        _c(
                                                          "span",
                                                          [
                                                            _vm._v(
                                                              "首次关注微信公众号送\n                                "
                                                            ),
                                                            _c("el-input", {
                                                              model: {
                                                                value:
                                                                  _vm.formr
                                                                    .taskFollow,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.formr,
                                                                      "taskFollow",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "formr.taskFollow",
                                                              },
                                                            }),
                                                            _vm._v(
                                                              "\n                                任务值"
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-row",
                                                          [
                                                            _c(
                                                              "el-col",
                                                              {
                                                                attrs: {
                                                                  span: 24,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " 公众号绑定 "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "el-col",
                                                              {
                                                                staticClass:
                                                                  "upimg",
                                                                attrs: {
                                                                  span: 24,
                                                                },
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "公众号二维码图片："
                                                                  ),
                                                                ]),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "el-upload",
                                                                  {
                                                                    attrs: {
                                                                      action:
                                                                        _vm.fstpUrl,
                                                                      headers:
                                                                        _vm.myHeaders,
                                                                      "before-upload":
                                                                        _vm.imgSize,
                                                                      "on-success":
                                                                        _vm.handleSuccess,
                                                                      "on-error":
                                                                        _vm.handError,
                                                                      "show-file-list": false,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-button",
                                                                      {
                                                                        attrs: {
                                                                          type: "primary",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "上传图片"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "el-col",
                                                              {
                                                                attrs: {
                                                                  span: 24,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "img",
                                                                  _vm._b(
                                                                    {
                                                                      directives:
                                                                        [
                                                                          {
                                                                            name: "show",
                                                                            rawName:
                                                                              "v-show",
                                                                            value:
                                                                              _vm
                                                                                .formr
                                                                                .invitationTitleId !=
                                                                                "" ||
                                                                              _vm.fmurl !=
                                                                                "",
                                                                            expression:
                                                                              "\n                                      formr.invitationTitleId != '' || fmurl != ''\n                                    ",
                                                                          },
                                                                        ],
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "10%",
                                                                          display:
                                                                            "block",
                                                                          margin:
                                                                            "10px 68px",
                                                                        },
                                                                      attrs: {
                                                                        alt: "",
                                                                      },
                                                                    },
                                                                    "img",
                                                                    {
                                                                      src: _vm.fmurl,
                                                                    },
                                                                    false
                                                                  )
                                                                ),
                                                                _vm._v(
                                                                  "\n                                  尺寸要求：建议尺寸200:200像素，图片支持JPG、PNG格式，图片小于1MB\n                                  "
                                                                ),
                                                                _c("p", [
                                                                  _vm._v(
                                                                    '\n                                    注：通过该处设置的公众号成功关注的用户，添加自动标签为"会员卡渠道用户"\n                                  '
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-checkbox",
                                                      {
                                                        attrs: {
                                                          label: "生日礼包",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.formr
                                                              .isBirthday,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.formr,
                                                              "isBirthday",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "formr.isBirthday",
                                                        },
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("生日礼包"),
                                                        ]),
                                                        _c(
                                                          "span",
                                                          [
                                                            _vm._v(
                                                              "会员生日当天可获得\n                                "
                                                            ),
                                                            _c("el-input", {
                                                              model: {
                                                                value:
                                                                  _vm.formr
                                                                    .taskBirthday,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.formr,
                                                                      "taskBirthday",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "formr.taskBirthday",
                                                              },
                                                            }),
                                                            _vm._v("任务值"),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          _vm._v(" "),
                                          _c(
                                            "el-form-item",
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.taskconterdl = false
                                                    },
                                                  },
                                                },
                                                [_vm._v("取 消")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "primary" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.savetask(
                                                        _vm.formr
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("保 存")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-footer",
        [
          _c(
            "el-dialog",
            {
              attrs: {
                title: "任务中心",
                visible: _vm.taskconterdl,
                width: "70%",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.taskconterdl = $event
                },
              },
            },
            [
              _c("h5", [_vm._v("基础信息")]),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", [
                    _c(
                      "span",
                      [
                        _vm._v("任务值名称:"),
                        _c("el-input", {
                          staticStyle: { width: "240px" },
                          attrs: {
                            maxlength: "6",
                            "show-word-limit": "",
                            placeholder: "类似淘气值，不超过6个字",
                          },
                          model: {
                            value: _vm.taskName,
                            callback: function ($$v) {
                              _vm.taskName = $$v
                            },
                            expression: "taskName",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("h5", [_vm._v("任务内容")]),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { model: _vm.formr, "label-width": "10px" },
                },
                [
                  [
                    _c(
                      "el-checkbox",
                      {
                        attrs: { label: "注册会员", name: "type" },
                        on: { "": function ($event) {} },
                        model: {
                          value: _vm.formr.isRegisterMember,
                          callback: function ($$v) {
                            _vm.$set(_vm.formr, "isRegisterMember", $$v)
                          },
                          expression: "formr.isRegisterMember",
                        },
                      },
                      [
                        _c("span", [_vm._v("注册会员")]),
                        _vm._v(" "),
                        _c(
                          "span",
                          [
                            _vm._v("新会员注册即可获得\n              "),
                            _c("el-input", {
                              staticStyle: { width: "80px" },
                              model: {
                                value: _vm.formr.valueRegisterMember,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formr,
                                    "valueRegisterMember",
                                    $$v
                                  )
                                },
                                expression: "formr.valueRegisterMember",
                              },
                            }),
                            _vm._v("\n              任务值"),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-checkbox",
                      {
                        attrs: { label: "购买商品" },
                        model: {
                          value: _vm.formr.isPurchaseCommodity,
                          callback: function ($$v) {
                            _vm.$set(_vm.formr, "isPurchaseCommodity", $$v)
                          },
                          expression: "formr.isPurchaseCommodity",
                        },
                      },
                      [
                        _c("span", [_vm._v("购买商品")]),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          _vm._l(
                            _vm.formr.taskCommodityList,
                            function (item, index) {
                              return _c(
                                "el-col",
                                { key: index, attrs: { span: 24 } },
                                [
                                  _c("span", [
                                    _c(
                                      "div",
                                      [
                                        _vm._v(
                                          "\n                    每消费\n                    "
                                        ),
                                        _c("el-input", {
                                          staticStyle: { width: "80px" },
                                          model: {
                                            value:
                                              _vm.formr.taskCommodityList[index]
                                                .taskConsumption,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formr.taskCommodityList[
                                                  index
                                                ],
                                                "taskConsumption",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formr.taskCommodityList[index].taskConsumption",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "el-select",
                                          {
                                            model: {
                                              value:
                                                _vm.formr.taskCommodityList[
                                                  index
                                                ].taskCompany,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formr.taskCommodityList[
                                                    index
                                                  ],
                                                  "taskCompany",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "formr.taskCommodityList[index].taskCompany",
                                            },
                                          },
                                          [
                                            _c("el-option", {
                                              attrs: {
                                                label: "元",
                                                value: "1",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("el-option", {
                                              attrs: {
                                                label: "产品",
                                                value: "2",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(
                                          "\n                    ，获得\n                    "
                                        ),
                                        _c("el-input", {
                                          model: {
                                            value:
                                              _vm.formr.taskCommodityList[index]
                                                .taskValue,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formr.taskCommodityList[
                                                  index
                                                ],
                                                "taskValue",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formr.taskCommodityList[index].taskValue",
                                          },
                                        }),
                                        _vm._v("任务值\n                    "),
                                        _c("i", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.formr.taskCommodityList
                                                  .length > 1,
                                              expression:
                                                "formr.taskCommodityList.length > 1",
                                            },
                                          ],
                                          staticClass: "el-icon-remove-outline",
                                          attrs: { size: "small" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.reduceshoplist(
                                                index,
                                                _vm.formr.taskCommodityList
                                              )
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "el-link",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.addshoplist(
                                                  index,
                                                  _vm.formr.taskCommodityList
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("添加门槛")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("span"),
                                ]
                              )
                            }
                          ),
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 24 } }, [
                              _c("p", [_vm._v("如有商城可进行关联")]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              {
                                staticStyle: { "margin-bottom": "30px" },
                                attrs: { span: 24 },
                              },
                              [
                                _vm._v("\n                小程序APPID："),
                                _c("el-input", {
                                  staticStyle: { width: "200px" },
                                  model: {
                                    value: _vm.formr.programAppId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formr, "programAppId", $$v)
                                    },
                                    expression: "formr.programAppId",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-checkbox",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.fwyzshop,
                            expression: "fwyzshop",
                          },
                        ],
                        attrs: { label: "防伪验证" },
                        model: {
                          value: _vm.formr.isCounterfeiting,
                          callback: function ($$v) {
                            _vm.$set(_vm.formr, "isCounterfeiting", $$v)
                          },
                          expression: "formr.isCounterfeiting",
                        },
                      },
                      [
                        _c("span", [_vm._v("防伪验证")]),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          _vm._l(
                            _vm.formr.taskCounterfeitingList,
                            function (item, index) {
                              return _c(
                                "el-col",
                                {
                                  key: index,
                                  staticStyle: { "margin-bottom": "10px" },
                                  attrs: { span: 24 },
                                },
                                [
                                  _c("span", [
                                    _c(
                                      "span",
                                      [
                                        _vm._v("每扫码"),
                                        _c("el-input", {
                                          staticStyle: { width: "80px" },
                                          model: {
                                            value:
                                              _vm.formr.taskCounterfeitingList[
                                                index
                                              ].taskConsumption,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formr
                                                  .taskCounterfeitingList[
                                                  index
                                                ],
                                                "taskConsumption",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                      formr.taskCounterfeitingList[index].taskConsumption\n                    ",
                                          },
                                        }),
                                        _vm._v("次防伪验证码"),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      [
                                        _vm._v("，获得\n                    "),
                                        _c("el-input", {
                                          staticStyle: { width: "80px" },
                                          model: {
                                            value:
                                              _vm.formr.taskCounterfeitingList[
                                                index
                                              ].taskValue,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formr
                                                  .taskCounterfeitingList[
                                                  index
                                                ],
                                                "taskValue",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formr.taskCounterfeitingList[index].taskValue",
                                          },
                                        }),
                                        _vm._v(
                                          "\n                    任务值\n                    "
                                        ),
                                        _c(
                                          "span",
                                          [
                                            _c("i", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.formr
                                                      .taskCounterfeitingList
                                                      .length > 1,
                                                  expression:
                                                    "formr.taskCounterfeitingList.length > 1",
                                                },
                                              ],
                                              staticClass:
                                                "el-icon-remove-outline",
                                              attrs: { size: "small" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.reducefwlist(
                                                    index,
                                                    _vm.formr
                                                      .taskCounterfeitingList
                                                  )
                                                },
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "el-link",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.addfwlist(
                                                      index,
                                                      _vm.formr
                                                        .taskCounterfeitingList
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("添加门槛")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-checkbox",
                      {
                        attrs: { label: "分享好友" },
                        model: {
                          value: _vm.formr.isFriend,
                          callback: function ($$v) {
                            _vm.$set(_vm.formr, "isFriend", $$v)
                          },
                          expression: "formr.isFriend",
                        },
                      },
                      [
                        _c("span", [_vm._v("分享好友")]),
                        _vm._v(" "),
                        _c(
                          "span",
                          [
                            _vm._v(
                              "\n              每分享1名好友获得\n              "
                            ),
                            _c("el-input", {
                              staticStyle: { width: "80px" },
                              model: {
                                value: _vm.formr.taskFriend,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formr, "taskFriend", $$v)
                                },
                                expression: "formr.taskFriend",
                              },
                            }),
                            _vm._v("任务值\n            "),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-checkbox",
                      {
                        attrs: { label: "关注公众号" },
                        model: {
                          value: _vm.formr.isFollow,
                          callback: function ($$v) {
                            _vm.$set(_vm.formr, "isFollow", $$v)
                          },
                          expression: "formr.isFollow",
                        },
                      },
                      [
                        _c("span", [_vm._v("关注公众号")]),
                        _c(
                          "span",
                          [
                            _vm._v("首次关注微信公众号送\n              "),
                            _c("el-input", {
                              model: {
                                value: _vm.formr.taskFollow,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formr, "taskFollow", $$v)
                                },
                                expression: "formr.taskFollow",
                              },
                            }),
                            _vm._v(" 任务值"),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 24 } }, [
                              _vm._v(" 公众号绑定 "),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { staticClass: "upimg", attrs: { span: 24 } },
                              [
                                _c("span", [_vm._v("公众号二维码图片：")]),
                                _vm._v(" "),
                                _c(
                                  "el-upload",
                                  {
                                    attrs: {
                                      action: _vm.fstpUrl,
                                      headers: _vm.myHeaders,
                                      "before-upload": _vm.imgSize,
                                      "on-success": _vm.handleSuccess,
                                      "on-error": _vm.handError,
                                      "show-file-list": false,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      { attrs: { type: "primary" } },
                                      [_vm._v("上传图片")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("el-col", { attrs: { span: 24 } }, [
                              _c(
                                "img",
                                _vm._b(
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.formr.invitationTitleId != "" ||
                                          _vm.fmurl != "",
                                        expression:
                                          "formr.invitationTitleId != '' || fmurl != ''",
                                      },
                                    ],
                                    staticStyle: {
                                      width: "10%",
                                      display: "block",
                                      margin: "10px 68px",
                                    },
                                    attrs: { alt: "" },
                                  },
                                  "img",
                                  { src: _vm.fmurl },
                                  false
                                )
                              ),
                              _vm._v(
                                "\n                尺寸要求：建议尺寸200:200像素，图片支持JPG、PNG格式，图片小于1MB\n                "
                              ),
                              _c("p", [
                                _vm._v(
                                  '\n                  注：通过该处设置的公众号成功关注的用户，添加自动标签为"会员卡渠道用户"\n                '
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-checkbox",
                      {
                        attrs: { label: "生日礼包" },
                        model: {
                          value: _vm.formr.isBirthday,
                          callback: function ($$v) {
                            _vm.$set(_vm.formr, "isBirthday", $$v)
                          },
                          expression: "formr.isBirthday",
                        },
                      },
                      [
                        _c("span", [_vm._v("生日礼包")]),
                        _c(
                          "span",
                          [
                            _vm._v("会员生日当天可获得\n              "),
                            _c("el-input", {
                              model: {
                                value: _vm.formr.taskBirthday,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formr, "taskBirthday", $$v)
                                },
                                expression: "formr.taskBirthday",
                              },
                            }),
                            _vm._v("任务值"),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.taskconterdl = false
                            },
                          },
                        },
                        [_vm._v("取 消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.savetask(_vm.formr)
                            },
                          },
                        },
                        [_vm._v("保 存")]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }