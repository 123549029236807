"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _checkout = require("@/api/warehouse/checkout");
var _dictionary = require("@/api/systems/dictionary");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _basic = require("@/api/mark/basic");
var _activityFlow = require("@/api/marketingManage/activityFlow");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var TypeDataComs = {
  status: [],
  source: [],
  factory: [],
  djzt: []
};
var _default = {
  name: 'TableSuply',
  components: {
    Pagination: _Pagination.default
  },
  filters: {
    formatPrizeType: function formatPrizeType(status) {
      if (!String(status)) return '未知';
      var statusMap = {
        100: '红包',
        200: '实物',
        300: '优惠券',
        400: '积分',
        500: '字卡',
        900: '礼包'
      };
      return statusMap[status];
    },
    activityStatusMap: function activityStatusMap(status) {
      if (!String(status)) return '未知';
      var statusMap = {
        0: '未开始',
        1: '停用',
        2: '进行中',
        3: '已过期'
      };
      return statusMap[status];
    },
    packLevel: function packLevel(status) {
      if (!String(status)) return '未知';
      var statusMap = {
        1: '盖码',
        2: '瓶码',
        3: '盒码',
        4: '箱码',
        5: '托码'
      };
      return statusMap[status];
    },
    formatDate: function formatDate(time) {
      if (!time) {
        return '';
      }
      var date = new Date(time);
      return (0, _basic.formatDate)(date, 'yyyy-MM-dd hh:mm:ss');
    },
    // 操作类型 1:创建单据    2:修改单据  3:删除单据  4:拆单  5:审核不通过  6:审核通过  7:单据下载  8:单据扫描 9:正常结单 10:强制结单 11:撤销下载  12：撤销结单
    operateType: function operateType(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          1: '创建单据',
          2: '修改单据',
          3: '删除单据',
          4: '拆单',
          5: '审核不通过',
          6: '审核通过',
          7: '单据下载',
          8: '单据扫描',
          9: '正常结单',
          10: '强制结单',
          11: '撤销下载',
          12: '撤销结单'
        };
        return statusMap[value];
      }
    },
    typeClass: function typeClass(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          1: 'primary',
          2: 'primary',
          3: 'primary',
          4: 'primary',
          5: 'primary',
          6: 'primary',
          7: 'success',
          8: 'warning',
          9: 'danger',
          10: 'info',
          11: 'info',
          13: 'success'
        };
        return statusMap[value];
      }
    },
    scanStatus: function scanStatus(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          1: '待扫描',
          2: '部分扫描',
          3: '扫描完成'
        };
        return statusMap[value];
      }
    },
    TypeDataCom: function TypeDataCom(code, keyName) {
      var vars = TypeDataComs[keyName];
      var name = '';
      vars.forEach(function (c) {
        if (c.figure == code) {
          name = c.name;
          return;
        }
      });
      return name;
    },
    // 最小包装比例
    unitName: function unitName(arr) {
      if (arr == null && !arr) return '';
      var name = '';
      if (arr.length == 2) {
        name = arr[1];
      } else if (arr.length == 3) {
        name = arr[2];
      }
      return name;
    },
    // 最大包装比例
    unitName2: function unitName2(arr) {
      if (arr == null && !arr) return '';
      var name = '';
      if (arr.length) {
        name = arr[0];
      }
      return name;
    }
  },
  props: {},
  data: function data() {
    return {
      activeName: 'member',
      treeProps: {
        children: 'children',
        hasChildren: 'hasChildren'
      },
      num: '',
      active: 0,
      codetotal: 0,
      detailVisible: false,
      codeVisible: false,
      mloading: false,
      listLoading: false,
      codeListLoading: false,
      scanData: {},
      detailForm: {
        billNo: '',
        productId: '',
        current: 1,
        size: 10
      },
      codeList: [],
      temp: {
        tradeBillAllVO: {},
        tradeOperateLogDTOS: [],
        tradeProductVOS: []
      },
      ruleForm: {
        memberSchemeActivityList: [],
        memberPrizeDetail: [],
        zdActivityDetail: [],
        zdPrizeDetail: []
      },
      flowList: [{
        name: '创建',
        userName: '',
        dateCreate: ''
      }, {
        name: '下载',
        userName: '',
        dateCreate: ''
      }, {
        name: '扫描',
        userName: '',
        dateCreate: ''
      }, {
        name: '结单',
        userName: '',
        dateCreate: ''
      }],
      settype: 2,
      showflow: false,
      setinfo: false // 查看详情显示字段
    };
  },
  mounted: function mounted() {
    this.getDirSel('DJZT', 'djzt');
  },
  methods: {
    /**
     * settype 1:拆单，2：查看，3：审核
     */
    show: function show(rows) {
      this.showflow = false;
      // if (settype == 3) {
      //   this.showflow = true
      // }
      this.detailVisible = true;
      this.ruleForm = JSON.parse(JSON.stringify(rows));
      // this.mloading = true
      // this.num = rows.num
      // this.mloading = false

      this.getDetail(rows);
    },
    downloadAttachment: function downloadAttachment() {
      var fileUrl = this.ruleForm.attachment;
      var a = document.createElement('a');
      a.target = '_blank';
      a.href = fileUrl;
      a.download = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    getDetail: function getDetail(rows) {
      var _this = this;
      var submitData = {
        pageSize: 10,
        pageNo: 1,
        schemeCode: rows.schemeCode,
        schemeName: '',
        status: ''
      };
      this.mloading = true;
      (0, _activityFlow.getWayDetail)(submitData).then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
        } else if (res.data.data.code) {
          _this.$message.error(res.data.data.msg);
        } else {
          _this.ruleForm = res.data.data;
        }
        _this.mloading = false;
      });
    },
    getDirSel: function getDirSel(code, list) {
      var _this2 = this;
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: code
      }).then(function (res) {
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            TypeDataComs[list] = res.data.data;
          } else {
            TypeDataComs[list] = [];
          }
        }
      });
      // storeTypeInfoList(type)
      //   .then(res => {
      //     if (res.data.code != 200) {
      //       that.TypeDataComs[keyName] = []
      //       return
      //     } else {
      //       that.TypeDataComs[keyName] = res.data.data
      //     }
      //   })
      //   .catch(() => {
      //     that.TypeDataComs[keyName] = []
      //   })
    },
    handleClose: function handleClose() {
      this.activeName = 'member';
      this.authId('searchbtn', this.$route.meta.authList);
      this.detailVisible = false;
    },
    codeClose: function codeClose() {
      this.detailForm = {
        billNo: '',
        productId: '',
        current: 1,
        size: 10
      };
      this.codeList = [];
      this.codetotal = 0;
      this.codeVisible = false;
    },
    codeDetail: function codeDetail(row) {
      console.log(row);
      this.codeVisible = true;
      this.codeListLoading = true;
      this.detailForm.billNo = row.billNo;
      this.detailForm.productId = row.productId;
      this.getCodeList();
    },
    getCodeList: function getCodeList() {
      var _this3 = this;
      (0, _checkout.queryScanMark)(this.detailForm).then(function (res) {
        if (res.data.code != 200) {
          _this3.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            if (res.data.data.records.length > 0) {
              res.data.data.records.forEach(function (item) {
                if (item.packLevel > 2) {
                  item.hasChildren = true;
                }
              });
            }
            _this3.codeList = res.data.data.records;
            _this3.codetotal = parseInt(res.data.data.total);
          } else {
            _this3.codeList = [];
            _this3.codetotal = 0;
          }
        }
        _this3.codeListLoading = false;
      }).catch(function () {
        _this3.codeListLoading = false;
      });
    },
    load: function load(tree, treeNode, resolve) {
      var _this4 = this;
      (0, _checkout.queryChildMark)({
        billNo: this.detailForm.billNo,
        parentMarkCode: tree.markCode,
        productId: this.detailForm.productId
      }).then(function (res) {
        if (res.data.code != 200) {
          _this4.$message.error(res.data.msg);
          resolve([]);
        } else {
          if (res.data.data.length > 0) {
            res.data.data.forEach(function (item) {
              if (item.packLevel > 2) {
                item.hasChildren = true;
              }
            });
          }
          resolve(res.data.data);
        }
      }).catch(function () {
        resolve([]);
      });
    }
  }
};
exports.default = _default;