"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.urlInfoSaveUrl = urlInfoSaveUrl;
exports.urlInfodeleteById = urlInfodeleteById;
exports.urlInfomodifyById = urlInfomodifyById;
exports.urlInfoqueryById = urlInfoqueryById;
exports.urlInfoqueryForPage = urlInfoqueryForPage;
var _request = _interopRequireDefault(require("@/utils/request"));
function urlInfoqueryForPage(query) {
  return (0, _request.default)({
    url: '/api-mark/urlInfo/queryForPage',
    method: 'get',
    params: query
  });
}
function urlInfoSaveUrl(params) {
  return (0, _request.default)({
    url: '/api-mark/urlInfo/saveUrl',
    method: 'post',
    params: params
  });
}
function urlInfomodifyById(params) {
  return (0, _request.default)({
    url: '/api-mark/urlInfo/modifyById',
    method: 'put',
    params: params
  });
}
function urlInfoqueryById(params) {
  return (0, _request.default)({
    url: '/api-mark/urlInfo/queryById/' + params,
    method: 'get'
  });
}
function urlInfodeleteById(params) {
  return (0, _request.default)({
    url: '/api-mark/urlInfo/deleteById/' + params,
    method: 'delete'
  });
}