"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _activityManage = require("@/api/terminal/activityManage");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { getItemByDataDictionaryByCode } from '@/api/systems/dictionary'
// import Pagination from '@/components/Pagination'
// import { formatDate } from '@/api/mark/basic'
var _default = {
  name: '',
  components: {
    // Pagination
  },
  filters: {
    approval: function approval(status) {
      if (!String(status)) return '';
      var statusMap = {
        0: '未审核',
        1: '已审核'
      };
      return statusMap[status];
    },
    activityDimension: function activityDimension(status) {
      if (!String(status)) return '';
      var statusMap = {
        0: '产品维度布奖',
        1: '订单维度布奖'
      };
      return statusMap[status];
    },
    templateType: function templateType(status) {
      if (!String(status)) return '';
      var statusMap = {
        0: '引导关注类',
        1: '扫码抽奖',
        2: '大转盘',
        3: '集字',
        4: '翻牌',
        5: '九宫格',
        6: '刮刮乐',
        7: '扭蛋机',
        8: '扫码即抽奖'
      };
      return statusMap[status];
    },
    timeControlType: function timeControlType(status) {
      if (!String(status)) return '';
      var statusMap = {
        0: '产品生产时间',
        1: '产品出库时间'
      };
      return statusMap[status];
    }
  },
  props: {},
  data: function data() {
    return {
      detailVisible: false,
      title: '活动详情',
      mloading: false,
      detailShow: false,
      temp: {},
      list: [{}, {}, {}, {}, {}],
      listLoading: false,
      reverse: true,
      activities: [{
        content: '活动按期开始',
        timestamp: '2018-04-15'
      }, {
        content: '通过审核',
        timestamp: '2018-04-13'
      }, {
        content: '创建成功',
        timestamp: '2018-04-11'
      }],
      ruleForm: {},
      rules: {},
      actId: ''
    };
  },
  mounted: function mounted() {},
  methods: {
    showPrizeType: function showPrizeType(row) {
      if (row.prizeType == 100) {
        return '红包';
      } else if (row.prizeType == 200) {
        return '实物';
      } else if (row.prizeType == 300) {
        return '优惠券';
      } else if (row.prizeType == 400) {
        return '积分';
      } else if (row.prizeType == 500) {
        return '字卡';
      } else if (row.prizeType == 900) {
        return '礼包';
      } else if (row.prizeType == 0) {
        return '谢谢惠顾';
      } else {
        return '';
      }
    },
    showPriority: function showPriority(row) {
      if (row.priority == 1) {
        return '一等奖';
      } else if (row.priority == 2) {
        return '二等奖';
      } else if (row.priority == 3) {
        return '三等奖';
      } else if (row.priority == 4) {
        return '四等奖';
      } else if (row.priority == 5) {
        return '五等奖';
      } else if (row.priority == 6) {
        return '六等奖';
      } else if (row.priority == 7) {
        return '七等奖';
      } else if (row.priority == 8) {
        return '八等奖';
      } else if (row.priority == 99999) {
        return '谢谢惠顾';
      } else {
        return '';
      }
    },
    downFile: function downFile(excelType) {
      var _this = this;
      var str = {
        1: '产品分类白名单',
        2: '产品黑名单',
        3: '经销商白名单',
        4: '终端黑名单',
        5: '订单白名单'
      };
      (0, _activityManage.detailExportExcel)({
        excelType: excelType,
        id: this.actId
      }).then(function (res) {
        if (res.status !== 200) {
          _this.$message.error(res.statusText);
          return;
        } else {
          if (res.data) {
            _this.$message.success('导出成功！');
            var blob = new Blob([res.data], {
              type: 'application/vnd.ms-excel;charset=utf-8'
            });
            var downloadElement = document.createElement('a');
            var href = window.URL.createObjectURL(blob);
            downloadElement.href = href;
            downloadElement.download = "".concat(str[excelType], ".xlsx");
            document.body.appendChild(downloadElement);
            downloadElement.click();
            document.body.removeChild(downloadElement);
            window.URL.revokeObjectURL(href);
          }
        }
      });
    },
    show: function show(rows, settype) {
      console.log(rows, 'rows');
      this.detailVisible = true;
      if (settype == 1) {
        this.title = '活动查看';
        this.detailShow = true;
      } else if (settype == 2) {
        this.title = '活动审批';
        this.detailShow = false;
      }
      this.getList(rows.id);
      this.actId = rows.id;
      //   this.settype = settype
      //   this.showflow = false
      //   if (settype == 3) {
      //     this.showflow = true
      //   }
      //   this.detailVisible = true
      //   this.mloading = true
      //   this.num = rows.num
      //   this.mloading = false
    },
    getList: function getList(data) {
      var _this2 = this;
      this.mloading = true;
      (0, _activityManage.detailpage)({
        id: data
      }).then(function (res) {
        console.log(res.data);
        if (res.data.data.errCode) {
          _this2.$message.error(res.data.data.errMsg);
          setTimeout(function () {
            _this2.mloading = false;
            _this2.handleClose();
          }, 1000);
        } else {
          if (res.data.code == 200) {
            _this2.temp = JSON.parse(JSON.stringify(res.data.data));
            _this2.mloading = false;
          }
        }
      }).catch(function () {
        _this2.mloading = false;
      });
    },
    handleClose: function handleClose() {
      this.activeName = '';
      this.temp = {};
      this.detailVisible = false;
    }
  }
};
exports.default = _default;