var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.dialogVisible,
        "append-to-body": true,
        width: "1100px !important",
        "before-close": _vm.goBack,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.goBack,
      },
    },
    [
      _c(
        "el-row",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dialogLoading,
              expression: "dialogLoading",
            },
          ],
          staticClass: "row-bg",
          attrs: { type: "flex", justify: "space-around" },
        },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form",
                {
                  ref: "codeForm",
                  staticClass: "codeForm",
                  staticStyle: { margin: "10 auto" },
                  attrs: {
                    model: _vm.codeForm,
                    "label-width": "120px",
                    rules: _vm.codeFormRules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "规则范围:", prop: "unlockRuleScope" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { disabled: _vm.isDisableType },
                          on: { change: _vm.changeUnlockType },
                          model: {
                            value: _vm.codeForm.unlockRuleScope,
                            callback: function ($$v) {
                              _vm.$set(_vm.codeForm, "unlockRuleScope", $$v)
                            },
                            expression: "codeForm.unlockRuleScope",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("单活动解锁规则"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("多活动共享规则"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "解锁规则名称:", prop: "unlockRuleName" },
                    },
                    [
                      _c("el-input", {
                        staticClass: "mediumWidth",
                        attrs: { placeholder: "解锁规则名称", maxlength: "25" },
                        model: {
                          value: _vm.codeForm.unlockRuleName,
                          callback: function ($$v) {
                            _vm.$set(_vm.codeForm, "unlockRuleName", $$v)
                          },
                          expression: "codeForm.unlockRuleName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.codeForm.unlockRuleScope == 0
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "布奖类型:", prop: "unlockType" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { disabled: _vm.isDisableType },
                              on: { change: _vm.changeUnlockType },
                              model: {
                                value: _vm.codeForm.unlockType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.codeForm, "unlockType", $$v)
                                },
                                expression: "codeForm.unlockType",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("倍数布奖"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("按次布奖"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.codeForm.unlockRuleScope == 0
                    ? _c(
                        "div",
                        { staticClass: "container-content" },
                        [
                          Number(_vm.codeForm.unlockType) == 0
                            ? _c(
                                "el-form-item",
                                {
                                  staticStyle: { color: "black" },
                                  attrs: { label: "倍数奖品:" },
                                },
                                [
                                  _c(
                                    "el-row",
                                    { attrs: { gutter: 20, type: "flex" } },
                                    [
                                      _c("el-col", { attrs: { span: 4 } }, [
                                        _vm._v("解锁倍数"),
                                      ]),
                                      _vm._v(" "),
                                      _c("el-col", { attrs: { span: 4 } }, [
                                        _vm._v("奖品类型"),
                                      ]),
                                      _vm._v(" "),
                                      _c("el-col", { attrs: { span: 6 } }, [
                                        _vm._v("奖品"),
                                      ]),
                                      _vm._v(" "),
                                      _c("el-col", { attrs: { span: 4 } }, [
                                        _vm._v("奖品投放上限"),
                                      ]),
                                      _vm._v(" "),
                                      _c("el-col", { attrs: { span: 6 } }, [
                                        _vm._v("单人领奖上限"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.codeForm.details,
                                    function (item, index) {
                                      return _c(
                                        "el-row",
                                        {
                                          key: index,
                                          attrs: { gutter: 20, type: "flex" },
                                        },
                                        [
                                          _c(
                                            "el-col",
                                            { attrs: { span: 4 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "",
                                                    prop:
                                                      "details." +
                                                      index +
                                                      ".unlockByMultiple",
                                                    rules: {
                                                      required: true,
                                                      message: "请输入解锁倍数",
                                                      trigger: "change",
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-input",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "Number",
                                                          rawName: "v-Number",
                                                        },
                                                      ],
                                                      attrs: {
                                                        maxlength: "10",
                                                        disabled: Boolean(
                                                          _vm.isDisableType &&
                                                            item.id
                                                        ),
                                                      },
                                                      model: {
                                                        value:
                                                          item.unlockByMultiple,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "unlockByMultiple",
                                                            _vm._n($$v)
                                                          )
                                                        },
                                                        expression:
                                                          "item.unlockByMultiple",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "template",
                                                        { slot: "append" },
                                                        [_vm._v("倍")]
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 4 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "",
                                                    prop:
                                                      "details." +
                                                      index +
                                                      ".prizeType",
                                                    rules: {
                                                      required: true,
                                                      message: "请选择奖品类型",
                                                      trigger: "change",
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      attrs: {
                                                        disabled: Boolean(
                                                          _vm.isDisableType &&
                                                            item.id
                                                        ),
                                                      },
                                                      on: {
                                                        change: function (
                                                          prizeType
                                                        ) {
                                                          return _vm.getCurPrizeList(
                                                            prizeType,
                                                            index
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value: item.prizeType,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "prizeType",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.prizeType",
                                                      },
                                                    },
                                                    [
                                                      _c("el-option", {
                                                        attrs: {
                                                          label: "红包奖",
                                                          value: "100",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c("el-option", {
                                                        attrs: {
                                                          label: "实物奖",
                                                          value: "200",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "",
                                                    prop:
                                                      "details." +
                                                      index +
                                                      ".prizeId",
                                                    rules: {
                                                      required: true,
                                                      message: "请选择奖品",
                                                      trigger: "change",
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      attrs: {
                                                        disabled: Boolean(
                                                          (!item.prizeType ||
                                                            _vm.isDisableType) &&
                                                            item.id
                                                        ),
                                                      },
                                                      model: {
                                                        value: item.prizeId,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "prizeId",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.prizeId",
                                                      },
                                                    },
                                                    _vm._l(
                                                      item.plist,
                                                      function (obj, indexObj) {
                                                        return _c("el-option", {
                                                          key: indexObj,
                                                          attrs: {
                                                            label:
                                                              obj.prizeName,
                                                            value: obj.prizeId,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 4 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "",
                                                    prop:
                                                      "details." +
                                                      index +
                                                      ".prizeUpperLimit",
                                                    rules: {
                                                      required: true,
                                                      message: "请输入领奖上限",
                                                      trigger: "change",
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-input",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "Number",
                                                          rawName: "v-Number",
                                                        },
                                                      ],
                                                      attrs: {
                                                        maxlength: "10",
                                                      },
                                                      model: {
                                                        value:
                                                          item.prizeUpperLimit,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "prizeUpperLimit",
                                                            _vm._n($$v)
                                                          )
                                                        },
                                                        expression:
                                                          "item.prizeUpperLimit",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "template",
                                                        { slot: "append" },
                                                        [_vm._v("个")]
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "",
                                                    prop:
                                                      "details." +
                                                      index +
                                                      ".preUpperLimit",
                                                    rules: {
                                                      required: true,
                                                      message:
                                                        "请输入领奖上限(人/次)",
                                                      trigger: "change",
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-input",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "Number",
                                                          rawName: "v-Number",
                                                        },
                                                      ],
                                                      attrs: {
                                                        maxlength: "10",
                                                      },
                                                      model: {
                                                        value:
                                                          item.preUpperLimit,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "preUpperLimit",
                                                            _vm._n($$v)
                                                          )
                                                        },
                                                        expression:
                                                          "item.preUpperLimit",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "template",
                                                        { slot: "append" },
                                                        [_vm._v("人/次")]
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          Number(_vm.codeForm.unlockType) == 0
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "备注:" } },
                                [
                                  _c("div", [
                                    _vm._v(
                                      "\n              单人领奖上限指每个人在每个活动内获得“解锁奖品”的上限，0表示不限制\n            "
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          Number(_vm.codeForm.unlockType) == 1
                            ? _c(
                                "el-form-item",
                                {
                                  staticStyle: { color: "black" },
                                  attrs: { label: "次数奖品:" },
                                },
                                [
                                  _c(
                                    "el-row",
                                    { attrs: { gutter: 20, type: "flex" } },
                                    [
                                      _c("el-col", { attrs: { span: 4 } }, [
                                        _vm._v("中奖次数"),
                                      ]),
                                      _vm._v(" "),
                                      _c("el-col", { attrs: { span: 4 } }, [
                                        _vm._v("奖品类型"),
                                      ]),
                                      _vm._v(" "),
                                      _c("el-col", { attrs: { span: 6 } }, [
                                        _vm._v("奖品"),
                                      ]),
                                      _vm._v(" "),
                                      _c("el-col", { attrs: { span: 4 } }, [
                                        _vm._v("奖品投放上限"),
                                      ]),
                                      _vm._v(" "),
                                      _c("el-col", { attrs: { span: 6 } }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.codeForm.details,
                                    function (item, index) {
                                      return _c(
                                        "el-row",
                                        {
                                          key: index + 9,
                                          attrs: { gutter: 20, type: "flex" },
                                        },
                                        [
                                          _c(
                                            "el-col",
                                            { attrs: { span: 4 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "",
                                                    prop:
                                                      "details." +
                                                      index +
                                                      ".unlockByTimes",
                                                    rules: {
                                                      required: true,
                                                      message: "请输入中奖次数",
                                                      trigger: "change",
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-input",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "Number",
                                                          rawName: "v-Number",
                                                        },
                                                      ],
                                                      attrs: {
                                                        maxlength: "10",
                                                        disabled: Boolean(
                                                          _vm.isDisableType &&
                                                            item.id
                                                        ),
                                                      },
                                                      model: {
                                                        value:
                                                          item.unlockByTimes,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "unlockByTimes",
                                                            _vm._n($$v)
                                                          )
                                                        },
                                                        expression:
                                                          "item.unlockByTimes",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "template",
                                                        { slot: "append" },
                                                        [_vm._v("次")]
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 4 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "",
                                                    prop:
                                                      "details." +
                                                      index +
                                                      ".prizeType",
                                                    rules: {
                                                      required: true,
                                                      message: "请选择奖品类型",
                                                      trigger: "change",
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      attrs: {
                                                        disabled: Boolean(
                                                          _vm.isDisableType &&
                                                            item.id
                                                        ),
                                                      },
                                                      on: {
                                                        change: function (
                                                          prizeType
                                                        ) {
                                                          return _vm.getCurPrizeList(
                                                            prizeType,
                                                            index
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value: item.prizeType,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "prizeType",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.prizeType",
                                                      },
                                                    },
                                                    [
                                                      _c("el-option", {
                                                        attrs: {
                                                          label: "红包奖",
                                                          value: "100",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c("el-option", {
                                                        attrs: {
                                                          label: "实物奖",
                                                          value: "200",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "",
                                                    prop:
                                                      "details." +
                                                      index +
                                                      ".prizeId",
                                                    rules: {
                                                      required: true,
                                                      message: "请选择奖品",
                                                      trigger: "change",
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      attrs: {
                                                        disabled: Boolean(
                                                          (!item.prizeType ||
                                                            _vm.isDisableType) &&
                                                            item.id
                                                        ),
                                                      },
                                                      model: {
                                                        value: item.prizeId,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "prizeId",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.prizeId",
                                                      },
                                                    },
                                                    _vm._l(
                                                      item.plist,
                                                      function (obj, indexObj) {
                                                        return _c("el-option", {
                                                          key: indexObj,
                                                          attrs: {
                                                            label:
                                                              obj.prizeName,
                                                            value: obj.prizeId,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 4 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "",
                                                    prop:
                                                      "details." +
                                                      index +
                                                      ".prizeUpperLimit",
                                                    rules: {
                                                      required: true,
                                                      message: "请输入领奖上限",
                                                      trigger: "change",
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-input",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "Number",
                                                          rawName: "v-Number",
                                                        },
                                                      ],
                                                      attrs: {
                                                        maxlength: "10",
                                                      },
                                                      model: {
                                                        value:
                                                          item.prizeUpperLimit,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "prizeUpperLimit",
                                                            _vm._n($$v)
                                                          )
                                                        },
                                                        expression:
                                                          "item.prizeUpperLimit",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "template",
                                                        { slot: "append" },
                                                        [_vm._v("个")]
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 6 } },
                                            [
                                              index == 0
                                                ? _c("el-button", {
                                                    attrs: {
                                                      type: "primary",
                                                      icon: "el-icon-plus",
                                                      circle: "",
                                                    },
                                                    on: {
                                                      click: _vm.addRowItem,
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              index >= 1
                                                ? _c("el-button", {
                                                    attrs: {
                                                      type: "primary",
                                                      icon: "el-icon-delete",
                                                      circle: "",
                                                      disabled: Boolean(
                                                        _vm.isDisableType &&
                                                          item.id
                                                      ),
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.deleteRowItem(
                                                          index
                                                        )
                                                      },
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.codeForm.unlockRuleScope == 0
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "次数累计规则:",
                            prop: "accrualType",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { disabled: _vm.isDisableType },
                              model: {
                                value: _vm.codeForm.accrualType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.codeForm, "accrualType", $$v)
                                },
                                expression: "codeForm.accrualType",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "0" } }, [
                                _vm._v("所有人累计"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: "1" } }, [
                                _vm._v("单人累计"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _vm.codeForm.unlockRuleScope == 1
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "起始时间:",
                                prop: "shareRuleStartTime",
                                rules: {
                                  required: true,
                                  message: "请输入起始时间",
                                  trigger: "change",
                                },
                              },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  type: "date",
                                  disabled: _vm.isDisableType,
                                  placeholder: "选择起始时间",
                                },
                                model: {
                                  value: _vm.codeForm.shareRuleStartTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.codeForm,
                                      "shareRuleStartTime",
                                      $$v
                                    )
                                  },
                                  expression: "codeForm.shareRuleStartTime",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticStyle: { color: "#ccc" } }, [
                                _vm._v("备注：关联活动后时间不可以修改"),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.codeForm.unlockRuleScope == 1
                    ? _c(
                        "div",
                        { staticClass: "container-content" },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { color: "black" },
                              attrs: { label: "次数奖品:" },
                            },
                            [
                              _c(
                                "el-row",
                                { attrs: { gutter: 20, type: "flex" } },
                                [
                                  _c("el-col", { attrs: { span: 4 } }, [
                                    _vm._v("中奖次数"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-col", { attrs: { span: 4 } }, [
                                    _vm._v("奖品类型"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-col", { attrs: { span: 6 } }, [
                                    _vm._v("奖品"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-col", { attrs: { span: 4 } }, [
                                    _vm._v("奖品投放上限"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-col", { attrs: { span: 6 } }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm._l(
                                _vm.codeForm.details,
                                function (item, index) {
                                  return _c(
                                    "el-row",
                                    {
                                      key: index + 5,
                                      attrs: { gutter: 20, type: "flex" },
                                    },
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 4 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "",
                                                prop:
                                                  "details." +
                                                  index +
                                                  ".unlockByTimes",
                                                rules: {
                                                  required: true,
                                                  message: "请输入中奖次数",
                                                  trigger: "change",
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "el-input",
                                                {
                                                  directives: [
                                                    {
                                                      name: "Number",
                                                      rawName: "v-Number",
                                                    },
                                                  ],
                                                  attrs: {
                                                    maxlength: "10",
                                                    disabled: Boolean(
                                                      _vm.isDisableType &&
                                                        item.id
                                                    ),
                                                  },
                                                  model: {
                                                    value: item.unlockByTimes,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "unlockByTimes",
                                                        _vm._n($$v)
                                                      )
                                                    },
                                                    expression:
                                                      "item.unlockByTimes",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "template",
                                                    { slot: "append" },
                                                    [_vm._v("次")]
                                                  ),
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 4 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "",
                                                prop:
                                                  "details." +
                                                  index +
                                                  ".prizeType",
                                                rules: {
                                                  required: true,
                                                  message: "请选择奖品类型",
                                                  trigger: "change",
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    disabled: Boolean(
                                                      _vm.isDisableType &&
                                                        item.id
                                                    ),
                                                  },
                                                  on: {
                                                    change: function (
                                                      prizeType
                                                    ) {
                                                      return _vm.getCurPrizeList(
                                                        prizeType,
                                                        index
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: item.prizeType,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "prizeType",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.prizeType",
                                                  },
                                                },
                                                [
                                                  _c("el-option", {
                                                    attrs: {
                                                      label: "红包奖",
                                                      value: "100",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("el-option", {
                                                    attrs: {
                                                      label: "实物奖",
                                                      value: "200",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "",
                                                prop:
                                                  "details." +
                                                  index +
                                                  ".prizeId",
                                                rules: {
                                                  required: true,
                                                  message: "请选择奖品",
                                                  trigger: "change",
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    disabled: Boolean(
                                                      (!item.prizeType ||
                                                        _vm.isDisableType) &&
                                                        item.id
                                                    ),
                                                  },
                                                  model: {
                                                    value: item.prizeId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "prizeId",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.prizeId",
                                                  },
                                                },
                                                _vm._l(
                                                  item.plist,
                                                  function (obj, indexObj) {
                                                    return _c("el-option", {
                                                      key: indexObj,
                                                      attrs: {
                                                        label: obj.prizeName,
                                                        value: obj.prizeId,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 4 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "",
                                                prop:
                                                  "details." +
                                                  index +
                                                  ".prizeUpperLimit",
                                                rules: {
                                                  required: true,
                                                  message: "请输入领奖上限",
                                                  trigger: "change",
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "el-input",
                                                {
                                                  directives: [
                                                    {
                                                      name: "Number",
                                                      rawName: "v-Number",
                                                    },
                                                  ],
                                                  attrs: { maxlength: "10" },
                                                  model: {
                                                    value: item.prizeUpperLimit,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "prizeUpperLimit",
                                                        _vm._n($$v)
                                                      )
                                                    },
                                                    expression:
                                                      "item.prizeUpperLimit",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "template",
                                                    { slot: "append" },
                                                    [_vm._v("个")]
                                                  ),
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 6 } },
                                        [
                                          index == 0
                                            ? _c("el-button", {
                                                attrs: {
                                                  type: "primary",
                                                  icon: "el-icon-plus",
                                                  circle: "",
                                                },
                                                on: { click: _vm.addRowItem },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          index >= 1
                                            ? _c("el-button", {
                                                attrs: {
                                                  type: "primary",
                                                  icon: "el-icon-delete",
                                                  circle: "",
                                                  disabled: Boolean(
                                                    _vm.isDisableType && item.id
                                                  ),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteRowItem(
                                                      index
                                                    )
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "替换原有奖品:", prop: "replaceType" } },
                    [
                      _c(
                        "div",
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-value": "0",
                              "inactive-value": "1",
                            },
                            model: {
                              value: _vm.codeForm.replaceType,
                              callback: function ($$v) {
                                _vm.$set(_vm.codeForm, "replaceType", $$v)
                              },
                              expression: "codeForm.replaceType",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "note" }, [
                            _vm._v("开启后客户原有奖品无效，直接领取本奖品"),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { staticClass: "normalBtn", on: { click: _vm.goBack } },
            [_vm._v("关闭")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "normalBtn",
              attrs: {
                type: "primary",
                disabled: _vm.isDisable,
                loading: _vm.subLoading,
              },
              on: { click: _vm.finish },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }