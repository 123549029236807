"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.set");
require("core-js/modules/web.dom.iterable");
var _wxgzh = require("@/api/wxhyyy/wxgzh");
var _wxyyKhlb = require("@/api/wxhyyy/wxyyKhlb");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
/* eslint-disable no-unused-vars */
/* eslint-disable no-self-assign */
/* eslint-disable eqeqeq */
// import admincut from '@/components/admincut'
var _default = {
  name: 'WxAccountscustomerctcustomerarrcustomerlistindex',
  filters: {
    filterTime: function filterTime(value) {
      if (!value) return '';
      if (value.length > 0) {
        var time = new Date(value);
        var y = time.getFullYear();
        var m = time.getMonth() + 1 > 9 ? time.getMonth() + 1 : '0' + (time.getMonth() + 1);
        var d = time.getDate() > 9 ? time.getDate() : '0' + time.getDate();
        var h = time.getHours();
        var i = time.getMinutes() > 9 ? time.getMinutes() : '0' + time.getMinutes();
        var s = time.getSeconds() > 9 ? time.getSeconds() : '0' + time.getSeconds();
        return y + '-' + m + '-' + d + ' ' + h + ':' + i + ':' + s;
      }
      return value;
    }
  },
  data: function data() {
    return {
      // 公众号切换
      // options: [
      // ],
      // value: '',
      // 时间选择器
      handleShow: false,
      rowtag: null,
      dialogVisiblebq: false,
      bqlist: [],
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 0);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '昨天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            end.setTime(end.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '近两天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      value2: '',
      // 标签查询
      formInline: {
        customerName: '',
        orderTo: 'create_time',
        descBy: 'desc',
        isFollowWechat: '',
        pageNo: 1,
        pageSize: 10,
        isMember: '',
        province: '',
        city: '',
        sex: '',
        source: '',
        // 标签
        tagId: []
      },
      // 标签
      tagss: [{
        tagName: '全部',
        tagId: 0
      }, {
        tagName: '无标签',
        tagId: 1
      }],
      // 渠道
      restaurants: [],
      state: '',
      timeout: null,
      // 标签
      tags: ['标签一', '标签二', '标签三'],
      // 表格
      tableData: [],
      multipleSelection: [],
      // 修改标签
      checkList: '',
      dialogVisible: false,
      userload: true,
      // 打标签数据
      tagsList: {
        tagId: '',
        userTagQuery: []
      },
      taggg: [],
      taglist: [],
      dbqlist: [],
      khids: [],
      total: 0,
      dqlist: [],
      tagslist: [],
      props: {
        checkStrictly: true,
        label: 'lable',
        value: 'lable',
        children: 'child'
      },
      pocity: ''
    };
  },
  created: function created() {
    this.getserrch(this.formInline);
    // this.getAlltags()
    // this.formInline.appId = sessionStorage.getItem('appId')
    this.gettag();
  },
  mounted: function mounted() {},
  methods: {
    xzsfcs: function xzsfcs(val) {
      console.log(val);
      if (val.length === 1) {
        this.formInline.province = val[0];
      } else if (val.length === 2) {
        this.formInline.city = val[1];
      }
      console.log(this.formInline.province);
    },
    srchang: function srchang(val) {
      this.formInline.descBy = val.prop;
      if (val.order === 'descending') {
        this.formInline.descBy = 'desc';
      } else {
        this.formInline.descBy = 'asc';
      }
      this.getserrch(this.formInline);
    },
    // fsdf() {
    //   console.log(this.dbqlist)
    // },
    xzbqlist: function xzbqlist() {
      console.log('tagId--', this.formInline.tagId);
    },
    // 标签查询
    gettag: function gettag() {
      var _this = this;
      var that = this;
      (0, _wxyyKhlb.searchDy)().then(function (res) {
        res = res.data;
        console.log('dqssss', res);
        if (res.code === 200) {
          if (res.data && res.data.length > 0) {
            that.dqlist = res.data;
            that.dqlist.forEach(function (v, i) {
              var c = [];
              v.child.forEach(function (j) {
                console.log(j);
                var b = {
                  lable: ''
                };
                b.lable = j;
                c.push(b);
                v.child = c;
              });
            });
          }
        } else {
          _this.$message.warning(res.msg);
        }
        console.log('dqlist', _this.dqlist);
      });
      (0, _wxyyKhlb.searchByType)().then(function (res) {
        res = res.data;
        console.log(res);
        if (res.code === 200) {
          if (res.data && res.data.length > 0) {
            that.taglist = res.data;
          }
        } else {
          _this.$message.warning(res.msg);
        }
      });
      (0, _wxyyKhlb.searchByLable)().then(function (res) {
        res = res.data;
        if (res.code === 200) {
          if (res.data && res.data.length > 0) {
            that.tagslist = res.data;
          }
        } else {
          _this.$message.warning(res.msg);
        }
      });
    },
    // handleClosetag(tag) {
    //   this.bqlist.splice(this.bqlist.indexOf(tag), 1)
    // },
    cellck: function cellck(row, column, cell, event) {
      if (column.label === '标签') {
        this.bqlist = row.moWxLableCustomerDOList;
        if (row.talist.length === 0) {
          this.dialogVisiblebq = false;
        } else {
          this.dialogVisiblebq = true;
        }
        this.rowtag = row;
      }
    },
    // 获取所有标签
    // getAlltags() {
    //   searchmoWxLable({ appId: sessionStorage.getItem('appId') }).then(res => {
    //     console.log(res)
    //     if (res.code * 1 === 200) {
    //       this.tagss = [
    //         {
    //           tagName: '全部',
    //           tagId: 0
    //         },
    //         {
    //           tagName: '无标签',
    //           tagId: 1
    //         }
    //       ]
    //       this.tagss.push(...res.data)
    //     } else {
    //       this.$message.warning(res.msg)
    //     }
    //   })
    // },
    // 获取渠道
    querySearchAsync: function querySearchAsync(queryString, cb) {
      var _this2 = this;
      var restaurants = this.restaurants;
      var n = {
        codeName: queryString,
        appId: sessionStorage.getItem('appId')
      };
      (0, _wxgzh.getUserCode)(n).then(function (res) {
        res = res.data;
        console.log(res);
        if (res.code * 1 === 200) {
          restaurants = res.data;
          var list = [];
          for (var i in res.data) {
            var _n = {
              value: res.data[i].codeName,
              sceneId: res.data[i].sceneId
            };
            list.push(_n);
          }
          restaurants = list;
          cb(restaurants);
        } else {
          _this2.$message.warning(res.msg);
        }
      });
      // var results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants
      // console.log(results)
      // clearTimeout(this.timeout)
      // this.timeout = setTimeout(() => {
      //   cb(results)
      //   console.log(cb(results))
      // }, 3000 * Math.random())
    },
    handleSelect: function handleSelect(item) {
      console.log(item.sceneId);
      this.formInline.qrScene = item.sceneId;
      console.log(this.formInline);
    },
    // 打标签选中
    getcheck: function getcheck(val) {
      console.log(val);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.vipload = true;
      console.log("\u5F53\u524D\u9875: ".concat(val));
      this.formInline.pageNo = val;
      this.getserrch(this.formInline);
      // const n = {
      //   pageNo: val,
      //   pageSize: 10
      // }

      // console.log(this.formInline)
      // getmoWxCustomersearch(this.formInline).then(res => {
      //   console.log(res)

      //   if (res.code * 1 === 200) {
      //     this.total = res.data.total
      //     if (this.total * 1 === 0) {
      //       this.userload = false
      //       this.tableData = ''
      //     }
      //     this.tableData = res.data.records
      //   } else {
      //     this.$message.warning(res.msg)
      //   }
      //   this.userload = false
      // })
    },
    // 获取用户列表
    getserrch: function getserrch(val) {
      // this.formInline.tagId = 0
      // this.formInline.endTime = ''
      // this.formInline.startTime = ''
      // this.formInline.nickname = ''
      // this.formInline.qrScene = ''
      // this.formInline.appId = sessionStorage.getItem('appId')
      var that = this;
      (0, _wxyyKhlb.getmoWxCustomersearch)(val).then(function (res) {
        res = res.data;
        console.log(res);
        if (res.code * 1 === 200) {
          that.total = res.data.total;
          if (that.total * 1 === 0) {
            that.tableData = '';
          }
          var tableDataa = res.data.records;
          // that.tableData = res.data.records
          // let talist
          // for (let i = 0; i < tableDataa[i].moWxLableCustomerDOLis.length; i++) {
          //   // let sdr = tableDataa.moWxLableCustomerDOLis[i]
          //   console.log('111111')

          //   if (tableDataa[i].moWxLableCustomerDOLis.length > 2) {
          //     console.log('rwerwerwer')
          //     that.tableData[i].talist = tableDataa[i].moWxLableCustomerDOLis.slice(0, 1)
          //   }
          // }
          tableDataa.forEach(function (item, i) {
            if (item.moWxLableCustomerDOList.length > 2) {
              item.talist = item.moWxLableCustomerDOList.slice(0, 2);
            } else {
              item.talist = item.moWxLableCustomerDOList;
            }
          });
          that.tableData = tableDataa;
        } else {
          that.$message.warning(res.msg);
        }
        that.userload = false;
      });
    },
    // 删除标签
    handleClosetag: function handleClosetag(tag, list, row) {
      var _this3 = this;
      // if (tag.lableType === '2') {
      //   this.$message('自动标签不能删除')
      //   return false
      // }
      this.$confirm('删除后将无法恢复，确定删除？', '删除操作', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var a = {
          customerId: row.id,
          lableId: tag.lableId
        };
        console.log(list);
        var i = list.indexOf(tag);
        list.splice(i, 1);
        (0, _wxyyKhlb.deleteByCustomerIdAndLableId)(a).then(function (res) {
          res = res.data;
          if (res.code * 1 === 200) {
            _this3.$message.success('标签删除成功');
            // this.$router.go(0)
            _this3.getserrch(_this3.formInline);
          } else {
            _this3.$message.warning(res.msg);
          }
        });
        // this.userload = true
        // this.getserrch(this.formInline)
      }).catch(function () {
        _this3.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    // 查询操作
    onSubmit: function onSubmit() {
      var form = this.formInline;
      form.pageNo = 1;
      this.userload = true;
      // this.formInline.startTime = this.value2[0]
      // this.formInline.endTime = this.value2[1]
      // this.formInline.qrScene = this.state
      // this.formInline.appId = sessionStorage.getItem('appId')
      // if (form.tagId.length === 0) {
      //   form.tagId = ''
      // }
      console.log(form);
      this.getserrch(form);
    },
    dowmdetail: function dowmdetail(form) {
      var that = this;
      (0, _wxyyKhlb.getmoWxCustomerdetail)(form).then(function (res) {
        res = res.data;
        console.log(res);
        var blob = new Blob([res], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '明细.xlsx';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      }).catch(function (res) {
        that.$message.warning(res.msg);
      });
    },
    onReset: function onReset(refname) {
      var that = this;
      that.$refs[refname].resetFields();
      that.formInline = {
        customerName: '',
        orderTo: 'create_time',
        descBy: 'desc',
        isFollowWechat: '',
        pageNo: 1,
        pageSize: 10,
        isMember: '',
        province: '',
        sex: '',
        source: '',
        // 标签
        tagId: []
      };
      that.pocity = '';
      // console.log('formInline---', that.formInline)
    },
    // 表格选中操作
    handleSelection: function handleSelection(selection, row) {
      console.log(selection);
    },
    // 表格变化操作
    handleSelectionChange: function handleSelectionChange(val) {
      var _this4 = this;
      console.log(val);
      this.multipleSelection = val;
      this.tagsList.userTagQuery = [];
      this.khids = [];
      this.multipleSelection.forEach(function (val) {
        _this4.khids.push(val.id);
      });
      console.log(this.khids);
    },
    // 打标签
    opentagging: function opentagging() {
      if (this.multipleSelection.length == 0) {
        this.$message.warning('请先选择用户再进行操作');
        return;
      }
      this.dialogVisible = true;
      // this.checkList = ''
      // this.taggg = this.tagss.slice(2)
      // console.log(this.tagss)
    },
    // 提交打标签
    tagging: function tagging() {
      var _this5 = this;
      // if (this.checkList.length > 1) {
      //   this.$message.warning('只能选择一个标签')
      //   return
      // }
      console.log(this.checkList);
      // let a
      // this.tagss.forEach((val) => {
      //   if (val.tagName == this.checkList[0]) {
      //     a = val
      //   }
      // })
      // // this.tagsList.tagId = a.tagId
      // console.log(this.tagsList)
      if (this.dbqlist === '') {
        this.$message.warning('请选择一个标签');
      }
      var n = {
        customerIds: this.khids,
        lableIds: this.dbqlist
      };
      (0, _wxyyKhlb.updateUserLable)(n).then(function (res) {
        res = res.data;
        console.log(res);
        if (res.code * 1 === 200) {
          _this5.$message.success('操作成功');
        } else {
          _this5.$message.warning('操作失败，请重试');
        }
        _this5.dialogVisible = false;
        _this5.khids = [];
        _this5.dbqlist = [];
        _this5.userload = true;
        _this5.getserrch(_this5.formInline);
      });
    },
    // 删除用户
    handleDelete: function handleDelete(index, rows, row) {
      var _this6 = this;
      console.log(index, row);
      var n = {
        userId: row.userId,
        appId: sessionStorage.getItem('appId')
      };
      (0, _wxgzh.getDeleteuser)(n).then(function (res) {
        res = res.data;
        if (res.code * 1 === 200) {
          _this6.$message.success('删除成功');
          rows.splice(index, 1);
        } else {
          _this6.$message.warning(res.msg);
        }
        _this6.userload = true;
        _this6.getserrch(_this6.formInline);
      });
    },
    // 去重
    setGround: function setGround(val) {
      var groArr = [];
      for (var i in this.tagss) {
        groArr.push(this.tagss[i].tagName);
      }
      var Arr = new Set(groArr);
      // console.log(Arr)
      return Arr.has(val);
    },
    // 新建标签
    open: function open() {
      var _this7 = this;
      this.$prompt('标签名称', '新建标签', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
        // inputPattern: //,
        // inputErrorMessage: ''
      }).then(function (_ref) {
        var value = _ref.value;
        if (_this7.setGround(value)) {
          _this7.$message({
            type: 'error',
            message: '重复的标签名'
          });
          return;
        }
        if (value.replace(/(^\s*)|(\s*$)/g, '') == '') {
          _this7.$message({
            type: 'error',
            message: '请输入有效的标签名'
          });
          return;
        }
        var n = {
          tagName: value,
          appId: sessionStorage.getItem('appId')
        };
        (0, _wxgzh.ccrrTag)(n).then(function (res) {
          res = res.data;
          console.log(res);
          if (res.code * 1 === 200) {
            _this7.$message({
              type: 'success',
              message: '新建标签是: ' + value
            });
            _this7.getAlltags();
          } else {
            _this7.$message({
              type: 'error',
              message: res.msg
            });
          }
        });
      }).catch(function () {
        _this7.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    handleClose: function handleClose(done) {
      done();
    }
  }
};
exports.default = _default;