var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        { staticClass: "page-section" },
        [
          _c(
            "el-form",
            {
              ref: "listQuery",
              staticClass: "search-condition",
              attrs: {
                model: _vm.listQuery,
                "status-icon": "",
                "label-width": "80px",
              },
            },
            [
              _c(
                "div",
                { staticClass: "cols" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "moduleCode", label: "模块:" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择",
                                  },
                                  on: { change: _vm.getPages },
                                  model: {
                                    value: _vm.listQuery.moduleCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.listQuery, "moduleCode", $$v)
                                    },
                                    expression: "listQuery.moduleCode",
                                  },
                                },
                                _vm._l(_vm.moduleData, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.moduleName,
                                      value: item.moduleCode,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "billCode", label: "单据:" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择",
                                  },
                                  model: {
                                    value: _vm.listQuery.billCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.listQuery, "billCode", $$v)
                                    },
                                    expression: "listQuery.billCode",
                                  },
                                },
                                _vm._l(_vm.pageData, function (item) {
                                  return _c("el-option", {
                                    key: item.billCode,
                                    attrs: {
                                      label: item.billName,
                                      value: item.billCode,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "name", label: "审批名称:" } },
                            [
                              _c("el-input", {
                                staticClass: "filter-item",
                                staticStyle: { width: "100%" },
                                attrs: { clearable: "", placeholder: "请输入" },
                                model: {
                                  value: _vm.listQuery.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.listQuery, "name", $$v)
                                  },
                                  expression: "listQuery.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { "label-width": "40px" } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "mini" },
                                  on: { click: _vm.DoSearch },
                                },
                                [
                                  _c("i", { staticClass: "el-icon-search" }),
                                  _vm._v(" 查询\n                "),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.DoReset("listQuery")
                                    },
                                  },
                                },
                                [
                                  _c("i", { staticClass: "el-icon-refresh" }),
                                  _vm._v(" 重置\n                "),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              key: _vm.tableKey,
              staticStyle: { width: "100%" },
              attrs: {
                height: _vm.tabHeight,
                data: _vm.list,
                border: "",
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "编码",
                  prop: "modelKey",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "模块",
                  prop: "moduleName",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "单据",
                  prop: "billName",
                  width: "120",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "审批流名称",
                  prop: "modelName",
                  width: "120",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "触发动作",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("span", [_vm._v("提交")])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "状态",
                  prop: "status",
                  align: _vm.tableConfig.align,
                  width: "160",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("el-switch", {
                          attrs: {
                            "active-text": "生效",
                            "inactive-text": "失效",
                            "active-value": "1",
                            "inactive-value": "0",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.changeState(row)
                            },
                          },
                          model: {
                            value: row.status,
                            callback: function ($$v) {
                              _vm.$set(row, "status", $$v)
                            },
                            expression: "row.status",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "创建人",
                  prop: "createName",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "创建时间",
                  prop: "createTime",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(_vm._s(_vm._f("formatTime")(row.createTime))),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "更新人",
                  prop: "updateName",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "更新时间",
                  prop: "updateTime",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(_vm._s(_vm._f("formatTime")(row.updateTime))),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", width: "200", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.DoScan(row)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                        _vm._v(" "),
                        row.status == "0"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.DoEdit(row)
                                  },
                                },
                              },
                              [_vm._v("修改")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        row.status == "0"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.DoSetLpc(row)
                                  },
                                },
                              },
                              [_vm._v("流程图配置")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              page: _vm.listQuery.pageNo,
              limit: _vm.listQuery.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.listQuery, "pageNo", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.listQuery, "pageSize", $event)
              },
              pagination: _vm.getList,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.page2 ? _c("section", [_vm._m(0)]) : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: "审批流配置-查看",
            visible: _vm.dialogVisible1,
            "before-close": _vm.handleClose1,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible1 = $event
            },
          },
        },
        [
          _c("h3", [_vm._v("基本信息")]),
          _vm._v(" "),
          _c(
            "el-form",
            { attrs: { model: _vm.scanData } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "编码:", prop: "modelKey" } },
                [_vm._v(_vm._s(_vm.scanData.modelKey))]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "名称:", prop: "modelName" } },
                [_vm._v(_vm._s(_vm.scanData.modelName))]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "模块:", prop: "moduleName" } },
                [_vm._v(_vm._s(_vm.scanData.moduleName))]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "单据:", prop: "billName" } },
                [_vm._v(_vm._s(_vm.scanData.billName))]
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "触发动作:" } }, [
                _c("span", [_vm._v("提交")]),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "状态:", prop: "stauts" } },
                [
                  _vm.scanData.status == "1"
                    ? _c("span", [_vm._v("已生效")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.scanData.status == "0"
                    ? _c("span", [_vm._v("已失效")])
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "描述:", prop: "name" } }, [
                _vm._v(_vm._s(_vm.scanData.remark)),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: "审批流配置-修改",
            visible: _vm.dialogVisible2,
            "before-close": _vm.handleClose2,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible2 = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { model: _vm.editData },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "编码:", prop: "modelKey" } },
                [_vm._v(_vm._s(_vm.editData.modelKey))]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "名称:", prop: "modelName" } },
                [_vm._v(_vm._s(_vm.editData.modelName))]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "模块:", prop: "moduleName" } },
                [_vm._v(_vm._s(_vm.editData.moduleName))]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "单据:", prop: "billName" } },
                [_vm._v(_vm._s(_vm.editData.billName))]
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "触发动作:" } }, [
                _c("span", [_vm._v("提交")]),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "is-required",
                  attrs: { label: "状态:", prop: "stauts" },
                },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-text": "生效",
                      "inactive-text": "失效",
                      "active-value": "1",
                      "inactive-value": "0",
                    },
                    model: {
                      value: _vm.editData.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.editData, "status", $$v)
                      },
                      expression: "editData.status",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "描述:", prop: "remark" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "60%" },
                    attrs: { type: "textarea", placeholder: "请输入" },
                    model: {
                      value: _vm.editData.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.editData, "remark", $$v)
                      },
                      expression: "editData.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "align-right",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("确定")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "default", plain: "" },
                  on: { click: _vm.doCancel },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: "审批流配置-流程",
            visible: _vm.dialogVisible3,
            "before-close": _vm.handleClose3,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible3 = $event
            },
          },
        },
        [
          _c("div", { staticStyle: { height: "660px" } }, [
            _c(
              "iframe",
              {
                staticClass: "iframesty",
                attrs: {
                  name: "myiframe",
                  id: "myrame",
                  src: _vm.src,
                  frameborder: "0",
                  align: "left",
                  width: "100%",
                  height: "600px",
                  scrolling: "auto",
                  marginwidth: "0",
                  marginheight: "0",
                },
              },
              [
                _c("p", { staticClass: "tip" }, [
                  _vm._v("你的浏览器不支持iframe标签"),
                ]),
              ]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n      "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }