"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _order = require("@/api/order");
var _dictionary = require("@/api/systems/dictionary");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var TypeDataComs = {
  orderStatus: []
};
var _default = {
  name: 'PurchaseOrderDetail',
  filters: {
    typeClass: function typeClass(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          0: 'success',
          1: 'success',
          2: 'success',
          3: 'success',
          4: 'success',
          5: 'danger',
          6: 'danger',
          7: 'warning',
          8: 'primary'
        };
        return statusMap[value];
      }
    },
    orderStatusText: function orderStatusText(value) {
      var name = '';
      TypeDataComs.orderStatus.forEach(function (c) {
        if (c.figure == value) {
          name = c.name;
          return;
        }
      });
      return name;
    },
    chnMoney: function chnMoney(n) {
      if (!/^(0|[1-9]\d*)(\.\d+)?$/.test(n) || n == 0.0) return '';
      var unit = '千百拾亿千百拾万千百拾元角分';
      var str = '';
      var n = String(n);
      var p = n.indexOf('.');
      if (p >= 0) n = n.substring(0, p) + n.substr(p + 1, 2);
      unit = unit.substr(unit.length - n.length);
      for (var i = 0; i < n.length; i++) {
        str += '零壹贰叁肆伍陆柒捌玖'.charAt(n.charAt(i)) + unit.charAt(i);
      }
      return str.replace(/零(千|百|拾|角)/g, '零').replace(/(零)+/g, '零').replace(/零(万|亿|元)/g, '$1').replace(/(亿)万|壹(拾)/g, '$1$2').replace(/^元零?|零分/g, '').replace(/元$/g, '元整');
    }
  },
  data: function data() {
    return {
      cardPadding: {
        padding: '0 0 20px'
      },
      loading: false,
      // 采购单位名称
      purchaseComName: '',
      // 收货单位名称
      receiveComName: '',
      // 发货单位名称
      sendOutComName: '',
      orderStatus: [],
      addData: {
        orderNo: '',
        orderProductAOS: [],
        orderSource: 1,
        purchaseOrgId: '',
        receiveAddress: '',
        receiveOrgId: '',
        receivePhone: '',
        receiveUser: '',
        remark: '',
        saleOrgId: '',
        tradeDate: '',
        tradeType: ''
      },
      productList: []
    };
  },
  mounted: function mounted() {
    this.getDirSel('CCCGDDZT', 'orderStatus');
    if (this.$route.query.id) {
      this.getDetailData(this.$route.query.id);
    } else {
      this.$router.replace({
        name: 'orderCenterpurchase-ccindex'
      });
    }
  },
  methods: {
    getDirSel: function getDirSel(code, list) {
      var _this = this;
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: code
      }).then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this[list] = res.data.data;
            TypeDataComs[list] = res.data.data;
          } else {
            _this[list] = [];
            TypeDataComs[list] = [];
          }
        }
      });
    },
    // 获取详情数据
    getDetailData: function getDetailData(id) {
      var _this2 = this;
      this.loading = true;
      (0, _order.queryByOrderNo)(id).then(function (res) {
        if (res.data.code == 200) {
          var data = res.data.data;
          var arr = [];
          data.orderProductDetailVOS.forEach(function (item) {
            var obj = {
              pId: item.productId,
              productCode: item.productCode,
              productName: item.productName,
              productModel: item.productModel,
              packScaleExpression: item.packScaleExpression,
              chestNum: item.chestNum,
              bottleNum: item.bottleNum,
              totalBottleNum: item.totalBottleNum
            };
            arr.push(obj);
          });
          _this2.productList = arr;
          _this2.addData = res.data.data;
          _this2.purchaseComName = data.purchaseOrgName;
          _this2.receiveComName = data.receiveOrgName;
          _this2.sendOutComName = data.saleOrgName;
        } else {
          _this2.$message.error(res.data.msg);
        }
        _this2.loading = false;
      }).catch(function () {
        _this2.loading = false;
      });
    },
    handleClose: function handleClose() {
      var that = this;
      that.$router.back();
    }
  }
};
exports.default = _default;