var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "searchForm",
      staticClass: "search-condition",
      attrs: {
        "label-position": _vm.formConfig.labelPosition,
        "label-width": _vm.formConfig.labelWidth,
        "label-suffix": ":",
        model: _vm.formData,
      },
    },
    [
      _c(
        "div",
        { staticClass: "cols" },
        [
          _c(
            "el-row",
            { staticStyle: { "margin-bottom": "0" }, attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "防伪码", prop: "antiNum" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入防伪码",
                          maxlength: "100",
                        },
                        model: {
                          value: _vm.formData.antiNum,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "antiNum", $$v)
                          },
                          expression: "formData.antiNum",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "次数", prop: "queryCs" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            clearable: "",
                            type: "text",
                            title: "次数",
                            oninput:
                              "this.value=this.value.replace(/\\D+/g,'');if(this.value.length>3) this.value=this.value.slice(0,3);;if(this.value==0) this.value=''",
                          },
                          model: {
                            value: _vm.formData.queryCs,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "queryCs", $$v)
                            },
                            expression: "formData.queryCs",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "80px" },
                              attrs: { slot: "prepend", placeholder: "全部" },
                              slot: "prepend",
                              model: {
                                value: _vm.formData.csType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "csType", $$v)
                                },
                                expression: "formData.csType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "全部", value: "" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: ">=", value: "1" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "<=", value: "2" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "异常预警类型", prop: "ycyjlx" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            placeholder: "请选择异常预警类型",
                          },
                          model: {
                            value: _vm.formData.ycyjlx,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "ycyjlx", $$v)
                            },
                            expression: "formData.ycyjlx",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: -1 },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "重复查询", value: 1 },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "数码不存在", value: 2 },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "数码未激活", value: 3 },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "未出库扫码", value: 4 },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "归属地不符", value: 7 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.handleShow,
                      expression: "handleShow",
                    },
                  ],
                  attrs: { span: 6 },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品品牌", prop: "ppwlList" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: "请选择/输入品牌",
                          },
                          on: { change: _vm.getqueryMaterial },
                          model: {
                            value: _vm.brandData,
                            callback: function ($$v) {
                              _vm.brandData = $$v
                            },
                            expression: "brandData",
                          },
                        },
                        _vm._l(_vm.brandList, function (item) {
                          return _c("el-option", {
                            key: item.pId,
                            attrs: { label: item.brandName, value: item.pId },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.handleShow,
                          expression: "handleShow",
                        },
                      ],
                      attrs: { label: "产品名称", prop: "name" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            filterable: "",
                            multiple: "",
                            "collapse-tags": "",
                            placeholder: "请选择/输入产品名称",
                          },
                          model: {
                            value: _vm.selProList,
                            callback: function ($$v) {
                              _vm.selProList = $$v
                            },
                            expression: "selProList",
                          },
                        },
                        _vm._l(_vm.proList, function (item) {
                          return _c("el-option", {
                            key: item.materialCode,
                            attrs: {
                              label: item.materialName,
                              value: item.materialCode,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.handleShow || !_vm.formConfig.btnFollow,
                      expression: "handleShow||!formConfig.btnFollow",
                    },
                  ],
                  attrs: { span: 6 },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "查询日期", prop: "queryTime" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          "value-format": "yyyyMM",
                          format: "yyyy-MM",
                          type: "month",
                          placeholder: "选择月",
                        },
                        model: {
                          value: _vm.formData.queryTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "queryTime", $$v)
                          },
                          expression: "formData.queryTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.formConfig.btnFollow
                ? _c(
                    "el-col",
                    {
                      staticClass: "head-btn-group",
                      style: { width: _vm.formConfig.btnAreaHasShowMore },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { "label-width": "0" } },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "label" }, slot: "label" },
                            [_vm._v(" ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.searchBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.searchBtn.icon,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleFilter("search")
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.resetBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.resetBtn.icon,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleFilter("rest")
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  _vm.$parent.handleShow =
                                    !_vm.$parent.handleShow
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.handleShow ? "收起" : "展开") +
                                  "\n            "
                              ),
                              _c("i", {
                                class: _vm.handleShow
                                  ? "el-icon-arrow-up"
                                  : "el-icon-arrow-down",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }