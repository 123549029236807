"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.number.is-integer");
var _activityReport = require("@/api/marketingStatic/activityReport");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingStaticactivityReportlogopaymentPeriodindex'; // 缓存名字为当前path去掉/
var _default = {
  name: 'MarketingStaticactivityReportlogopaymentPeriodindex',
  data: function data() {
    var checkAge = function checkAge(rule, value, callback) {
      if (!value) {
        return callback(new Error('日期不能为空'));
      }
      setTimeout(function () {
        if (!Number.isInteger(value)) {
          callback(new Error('请输入数字值'));
        } else {
          if (value < 1 || value > 28) {
            callback(new Error('日期范围在1 ~ 28日'));
          } else {
            callback();
          }
        }
      }, 1000);
    };
    return {
      ruleData: {
        monthReportDay: 1,
        quarterReportDay: 1
      },
      globalRule: {
        monthReportDay: [{
          validator: checkAge,
          trigger: 'blur'
        }],
        quarterReportDay: [{
          validator: checkAge,
          trigger: 'blur'
        }]
      },
      saveLoad: false
    };
  },
  activated: function activated() {
    this.getRuleDetail();
  },
  deactivated: function deactivated() {
    var name = cachName;
    var cache = this.getCaches(this, name);
    if (!cache) {}
  },
  methods: {
    getRuleDetail: function getRuleDetail() {
      var _this = this;
      (0, _activityReport.queryReportTimeConfig)().then(function (res) {
        console.log(res.data);
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
          return;
        }
        _this.ruleData.monthReportDay = res.data.data.monthReportDay;
        _this.ruleData.quarterReportDay = res.data.data.quarterReportDay;
        console.log(1);
      });
    },
    handleSave: function handleSave(name) {
      var _this2 = this;
      this.$refs[name].validate(function (valid) {
        if (valid) {
          _this2.saveLoad = true;
          var sumitData = JSON.parse(JSON.stringify(_this2.ruleData));
          (0, _activityReport.saveReportTimeConfig)(sumitData).then(function (res) {
            if (res.data.code != 200) {
              _this2.saveLoad = false;
              return _this2.$message.error(res.data.msg);
            } else {
              _this2.getRuleDetail();
              _this2.$message.success('更新成功');
              _this2.saveLoad = false;
            }
          });
        }
      });
    },
    hanleReset: function hanleReset(name) {
      this.$refs[name].resetFields();
    }
  }
};
exports.default = _default;