"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.split");
var _productDialog = _interopRequireDefault(require("@/components/productDialog"));
var _index = require("@/api/warehouse/checkout/index");
var _select = require("@/api/warehouse/select");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var miniUnit = '';
var _default = {
  name: 'TableSuply',
  components: {
    productDialog: _productDialog.default
  },
  filters: {
    minSum: function minSum(sum, packScaleExpression, row) {
      if (!sum || !packScaleExpression) return '';
      var arr = packScaleExpression.split('*');
      var le = arr.length;
      if (le == 1) {
        return sum;
      }
      var num = sum * arr[le - 1];
      miniUnit = num;
      row.sumPlanAto = num;
      return num;
    }
  },
  data: function data() {
    var _this = this;
    var validatematerialCode = function validatematerialCode(rule, value, callback) {
      if (_this.proList.length == 0 || _this.proList == '' || _this.proList == null) {
        callback(new Error('产品明细不能为空，请维护'));
      } else {
        callback();
      }
    };
    return {
      title: '添加退货入库',
      billType: 105,
      tableKey: 'pId',
      listLoading: false,
      mloading: false,
      dialogVisible: false,
      btn_loading: false,
      proShow: false,
      isUpdate: true,
      submitType: 1,
      returnOrgType: 1,
      TypeDataComs: {
        returnOrgList: [],
        returnOrgType: [{
          id: 1,
          name: '销售公司'
        }, {
          id: 2,
          name: '渠道商'
        }]
      },
      scanData: {
        inOrgId: '',
        inOrgType: '',
        inStoreId: '',
        returnReason: '',
        billNo: '',
        billSource: '',
        billType: 103,
        sumPlanAtoNum: '',
        sumPlanNum: '',
        tradeBillDetailAOS: []
      },
      disabled: {
        num: false
      },
      factoryList: [],
      inOrgList: [],
      outOrgList: [],
      proList: [],
      inOrg: {},
      storeTypes: [{
        name: '生产公司',
        id: 1
      }, {
        name: '销售公司',
        id: 2
      }, {
        name: '经销商',
        id: 3
      }],
      rules: {
        num: [{
          required: true,
          message: '生产入库单号不能为空，请维护',
          trigger: 'change'
        }],
        outStore: [{
          required: true,
          message: '请选择调出库房',
          trigger: 'change'
        }],
        inStore: [{
          required: true,
          message: '请选择调入库房',
          trigger: 'change'
        }],
        materialCode: [{
          required: true,
          trigger: 'blur',
          validator: validatematerialCode
        }
        // {
        //   required: true,
        //   message: '产品明细不能为空，请维护',
        //   trigger: 'change'
        // }
        ]
      }
    };
  },

  // 监听
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        if (this.submitType != 2) {
          this.getCreateNum();
          this.title = '添加退货入库';
        }
      }
    }
  },
  created: function created() {},
  mounted: function mounted() {
    var that = this;
  },
  methods: {
    init: function init() {
      this.proList = [];
      this.inOrgList = [];
      this.outOrgList = [];
    },
    show: function show(submitType, billType) {
      this.init();
      this.submitType = submitType;
      this.billType = billType;
      this.disabled.num = false;
      this.mloading = true;
      if (this.$refs['scanForm'] !== undefined) {
        this.$refs['scanForm'].resetFields();
      }
      this.dialogVisible = true;
      this.getstoreHouseInfo();
      this.getfactoryInfoList();
      this.getreturnOrgList();
      this.mloading = false;
    },
    upDateShow: function upDateShow(row) {
      this.init();
      this.title = '修改退货入库';
      this.submitType = 2;
      this.isUpdate = false;
      this.disabled.num = true;
      this.mloading = true;
      if (this.$refs['scanForm'] !== undefined) {
        this.$refs['scanForm'].resetFields();
      }
      var vs = JSON.parse(JSON.stringify(row));
      for (var i in this.scanData) {
        this.scanData[i] = vs[i];
      }
      this.dialogVisible = true;
      this.getstoreHouseInfo();
      this.getfactoryInfoList();
      this.getProductDetail(row.num);
      this.getreturnOrgList();
      this.mloading = false;
    },
    // 获取产品详情
    getProductDetail: function getProductDetail(num) {
      var _this2 = this;
      this.listLoading = true;
      var that = this;
      that.proList = [];
      (0, _index.getProductDetail)(num).then(function (res) {
        _this2.listLoading = false;
        if (res.data.code != 200) {
          _this2.proList = [];
          return;
        } else {
          var list = [];
          res.data.data.forEach(function (v, index) {
            if (v.numSplit == null) {
              v['children'] = [];
              list['id_' + v.numRow] = v;
            }
          });
          res.data.data.forEach(function (v, index) {
            if (v.numSplit != null) {
              list['id_' + v.numRow]['children'].push(v);
            }
          });
          for (var i in list) {
            _this2.getDetailArray(list[i]);
          }
        }
      }).catch(function () {
        _this2.factoryList = [];
      });
    },
    // 自动生成退货入库单号
    getCreateNum: function getCreateNum() {
      var _this3 = this;
      (0, _index.createNum)(this.billType).then(function (res) {
        if (res.data.code != 200) {
          _this3.$message.error(res.data.msg);
        }
        _this3.scanData.billNo = res.data.data;
        _this3.disabled.num = true;
      }).catch(function (err) {
        console.log('err', err);
      });
    },
    // 删除添加产品
    proDel: function proDel(row, index) {
      var that = this;
      this.proList.splice(row, 1);
      this.proShow = false;
      return true;
      if (this.submitType == 3) {
        this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          (0, _index.setProductDelete)(row.num, row.numRow).then(function (res) {
            if (res.data.code != 200) {
              that.$message.error(res.data.msg);
              return;
            } else {
              that.proList.splice(row, 1);
            }
          }).catch(function () {});
        });
      } else {
        this.proList.splice(row, 1);
      }
    },
    submitForm: function submitForm(temp) {
      var _this4 = this;
      // this.setDetail()
      console.log('SUB_scanData', JSON.stringify(this.scanData));
      this.$refs['scanForm'].validate(function (valid) {
        if (valid) {
          _this4.btn_loading = true;
          _this4.scanData.editAble = temp;
          // this.scanData.miniUnit = miniUnit
          delete _this4.scanData.planNum;
          delete _this4.scanData.prodBatch;
          if (_this4.submitType == 2) {
            // delete this.scanData.listDetail
            (0, _index.InfoUpdate)(_this4.scanData.num, _this4.scanData).then(function (res) {
              _this4.btn_loading = false;
              if (res.data.code != 200) {
                _this4.$message.error(res.data.msg);
              } else {
                _this4.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this4.getList();
                _this4.handleClose();
              }
            }).catch(function () {
              _this4.btn_loading = false;
            });
          } else {
            (0, _index.InfoInsert)(_this4.billType, _this4.scanData).then(function (res) {
              _this4.btn_loading = false;
              if (res.data.code != 200) {
                _this4.$message.error(res.data.msg);
              } else {
                _this4.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this4.handleClose();
              }
            }).catch(function () {
              _this4.btn_loading = false;
            });
          }
        }
      });
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
      this.$parent.getList();
    },
    getreturnOrgList: function getreturnOrgList() {
      var _this5 = this;
      this.scanData.returnOrg = '';
      this.TypeDataComs.returnOrgList = [];
      if (this.returnOrgType == 1) {
        // 获取销售公司
        (0, _select.getXgOrgInfo)({
          orgType: 2
        }).then(function (res) {
          if (res.data.code != 200) {
            _this5.$message.error(res.data.msg);
            return;
          } else {
            for (var i in res.data.data) {
              var v = res.data.data[i];
              _this5.TypeDataComs.returnOrgList.push({
                id: v.pId,
                name: v.orgName
              });
            }
          }
        }).catch(function () {});
      }
      if (this.returnOrgType == 2) {
        // 获取渠道档案数据
        (0, _select.getjcChannelArchivesList)({
          size: 211111111,
          current: 1,
          channelType: 1
        }).then(function (res) {
          if (res.data.code != 200) {
            return;
          } else {
            for (var i in res.data.data.records) {
              var v = res.data.data.records[i];
              _this5.TypeDataComs.returnOrgList.push({
                id: v.pId,
                name: v.channelName
              });
            }
          }
        }).catch(function () {});
      }
    },
    // 获取库房下拉信信
    getstoreHouseInfo: function getstoreHouseInfo() {
      var _this6 = this;
      this.factoryList = [];
      (0, _select.storeHouseInfoList)({
        current: 1,
        size: 2111111111
      }).then(function (res) {
        if (res.data.code != 200) {
          _this6.$message.error(res.data.msg);
          return;
        } else {
          _this6.factoryList = res.data.data;
          console.log('this.factoryList', _this6.factoryList);
        }
      }).catch(function () {
        _this6.factoryList = [];
      });
    },
    // 获取企业下拉信息
    // 获取企业下拉信息
    getfactoryInfoList: function getfactoryInfoList() {
      var _this7 = this;
      (0, _select.ccOrgInfoList)().then(function (res) {
        _this7.inOrgList = [];
        _this7.outOrgList = [];
        if (res.data.code != 200) {
          _this7.$message.error(res.data.msg);
          return;
        } else {
          _this7.inOrgList = res.data.data;
          _this7.outOrgList = res.data.data;
        }
      }).catch(function () {
        _this7.inOrgList = [];
        _this7.outOrgList = [];
      });
    },
    openSel: function openSel() {
      this.proShow = true;
      // this.$refs.selProduct.proSelVisible = true
    },
    proClose: function proClose() {
      this.proShow = false;
    },
    setSel: function setSel(val) {
      if (this.proList.length > 0) {
        for (var i in val) {
          this.proList.push(val[i]);
        }
        return true;
      }
      this.proList = val;
      this.proShow = false;
    },
    setSum: function setSum(e) {
      console.log('----', e);
      // this.scanData = JSON.parse(JSON.stringify(this.scanData))
      // this.scanData.planNum = this.valENnum(e)
    },
    setScanFactory: function setScanFactory(val) {
      if (!val) return;
    }
  }
};
exports.default = _default;