var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.apis)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c(
                "el-form",
                {
                  ref: "listQuery",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.listQuery,
                    "status-icon": "",
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cols" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "name", label: "关键字" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "档案编码/名称/外编码",
                                    },
                                    model: {
                                      value: _vm.listQuery.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.listQuery, "name", $$v)
                                      },
                                      expression: "listQuery.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "orgId", label: "所属公司" } },
                                [
                                  _c("treeselect", {
                                    attrs: {
                                      multiple: false,
                                      "flatten-search-results": true,
                                      placeholder: "请选择组织...",
                                      options: _vm.orgOptions,
                                    },
                                    on: { open: _vm.getSearchOrg },
                                    model: {
                                      value: _vm.listQuery.orgId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.listQuery, "orgId", $$v)
                                      },
                                      expression: "listQuery.orgId",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "salesmanStr",
                                    label: "业务员",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      "suffix-icon": "el-icon-search",
                                      placeholder: "请输入",
                                    },
                                    on: {
                                      clear: _vm.clearYid,
                                      focus: function ($event) {
                                        return _vm.choseYwy("search")
                                      },
                                    },
                                    model: {
                                      value: _vm.listQuery.salesmanStr,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.listQuery,
                                          "salesmanStr",
                                          $$v
                                        )
                                      },
                                      expression: "listQuery.salesmanStr",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "archivesStatus",
                                    label: "状态",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.listQuery.archivesStatus,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.listQuery,
                                            "archivesStatus",
                                            $$v
                                          )
                                        },
                                        expression: "listQuery.archivesStatus",
                                      },
                                    },
                                    _vm._l(_vm.states, function (item) {
                                      return _c("el-option", {
                                        key: item.code,
                                        attrs: {
                                          label: item.name,
                                          value: item.code,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.formConfig.btnFollow
                ? _c("el-divider", { staticClass: "btnDivider" })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btnArea", staticStyle: { padding: "20px" } },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "head-btn-group",
                      staticStyle: { width: "230px" },
                    },
                    [
                      _c(
                        "el-button-group",
                        {
                          staticStyle: {
                            "margin-right": "20px",
                            display: "flex",
                          },
                        },
                        [
                          _vm.hasAuth("searchbtn", _vm.apis)
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.searchBtn.type,
                                    size: _vm.btnConfig.size,
                                    plain: "",
                                    icon: _vm.btnConfig.searchBtn.icon,
                                  },
                                  on: { click: _vm.DoSearch },
                                },
                                [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hasAuth("searchbtn", _vm.apis)
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.resetBtn.type,
                                    size: _vm.btnConfig.size,
                                    plain: "",
                                    icon: _vm.btnConfig.resetBtn.icon,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.DoReset(_vm.listQuery)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "head-btn-group" },
                        [
                          _vm.hasAuth("addbtn", _vm.apis)
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.addBtn.type,
                                    plain: "",
                                    size: _vm.btnConfig.size,
                                    icon: _vm.btnConfig.addBtn.icon,
                                  },
                                  on: { click: _vm.DoAdd },
                                },
                                [_vm._v(_vm._s(_vm.btnConfig.addBtn.text))]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-button-group",
                            {
                              staticStyle: {
                                "margin-left": "20px",
                                display: "flex",
                              },
                            },
                            [
                              _vm.hasAuth("inportbtn", _vm.apis)
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.upLoadBtn.type,
                                        size: _vm.btnConfig.size,
                                        plain: "",
                                        icon: _vm.btnConfig.upLoadBtn.icon,
                                      },
                                      on: { click: _vm.DoImport },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.btnConfig.upLoadBtn.text)
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.hasAuth("importbtn", _vm.apis)
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.downLoadBtn.type,
                                        plain: "",
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.downLoadBtn.icon,
                                      },
                                      on: { click: _vm.DoExportAll },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.btnConfig.downLoadBtn.text)
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "section",
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      key: _vm.tableKey,
                      staticStyle: { width: "100%" },
                      attrs: {
                        height: _vm.tabHeight,
                        data: _vm.list,
                        border: _vm.tableConfig.border,
                        stripe: _vm.tableConfig.stripe,
                        fit: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "档案编码",
                          prop: "groupArchivesCode",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                          "min-width": "120",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "外部编码",
                          prop: "outCode",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "档案名称",
                          prop: "groupArchivesName",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "联系人",
                          prop: "contacts",
                          width: "200",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "手机号",
                          prop: "phone",
                          width: "100",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "团购类型",
                          prop: "groupBuyTypeName",
                          width: "100",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "所属公司",
                          prop: "orgName",
                          width: "100",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "所属部门",
                          prop: "departmentName",
                          width: "100",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "业务员",
                          prop: "salesmanName",
                          width: "100",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "状态",
                          prop: "archivesStatusStr",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  row.archivesStatusStr == "启用"
                                    ? _c(
                                        "el-tag",
                                        { attrs: { type: "success" } },
                                        [_vm._v("启用")]
                                      )
                                    : _c(
                                        "el-tag",
                                        { attrs: { type: "danger" } },
                                        [_vm._v("禁用")]
                                      ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3854785919
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "创建时间",
                          prop: "createTime",
                          width: "120",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "创建人",
                          prop: "createUserName",
                          width: "120",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _vm.hasAuth("detailbtn", _vm.apis) ||
                      _vm.hasAuth("updatebtn", _vm.apis) ||
                      _vm.hasAuth("startbtn", _vm.apis) ||
                      _vm.hasAuth("stopbtn", _vm.apis)
                        ? _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              align: _vm.tableConfig.align,
                              width: "180",
                              fixed: "right",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _vm.hasAuth("detailbtn", _vm.apis)
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.DoScan(row)
                                                },
                                              },
                                            },
                                            [_vm._v("详情")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.hasAuth("updatebtn", _vm.apis)
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.DoEdit(row)
                                                },
                                              },
                                            },
                                            [_vm._v("修改")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      row.archivesStatusStr == "禁用" &&
                                      _vm.hasAuth("startbtn", _vm.apis)
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.DoStart(row)
                                                },
                                              },
                                            },
                                            [_vm._v("启用")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      row.archivesStatusStr == "启用" &&
                                      _vm.hasAuth("stopbtn", _vm.apis)
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.DoStop(row)
                                                },
                                              },
                                            },
                                            [_vm._v("禁用")]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3743641505
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("pagination", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.total > 0,
                        expression: "total > 0",
                      },
                    ],
                    attrs: {
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.total,
                      page: _vm.listQuery.pageNo,
                      limit: _vm.listQuery.pageSize,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(_vm.listQuery, "pageNo", $event)
                      },
                      "update:limit": function ($event) {
                        return _vm.$set(_vm.listQuery, "pageSize", $event)
                      },
                      pagination: _vm.getList,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.apis)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c("personChose", {
        attrs: { "person-visible": _vm.personShow },
        on: { dialogHide: _vm.hidePerson, select: _vm.getPerson },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "团购档案-查看",
            visible: _vm.scanVisible,
            width: "1200px !important",
          },
          on: {
            "update:visible": function ($event) {
              _vm.scanVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.scanData } },
            [
              _c(
                "el-descriptions",
                { staticClass: "margin-top", attrs: { column: 2, border: "" } },
                [
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _vm._v("\n            团购档案编码\n          "),
                      ]),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.scanData.groupArchivesCode) +
                          "\n        "
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _vm._v("\n            团购档案名称\n          "),
                      ]),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.scanData.groupArchivesName) +
                          "\n        "
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _vm._v("\n            客户类型\n          "),
                      ]),
                      _vm._v(" "),
                      _vm.scanData.customerType == 1
                        ? _c("span", [_vm._v("个人")])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.scanData.customerType == 2
                        ? _c("span", [_vm._v("企业")])
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _vm._v("\n            团购类型\n          "),
                      ]),
                      _vm._v(" "),
                      _c("el-tag", { attrs: { size: "small" } }, [
                        _vm._v(_vm._s(_vm.scanData.groupBuyTypeName)),
                      ]),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _vm._v("\n            所属渠道\n          "),
                      ]),
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm._f("groupSysType")(_vm.scanData.groupSysType)
                          ) +
                          "\n        "
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _vm._v("\n            联系人\n          "),
                      ]),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.scanData.contacts) +
                          "\n        "
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _vm._v("\n            手机号\n          "),
                      ]),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.scanData.phone) +
                          "\n        "
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _vm._v("\n            业务员\n          "),
                      ]),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.scanData.salesmanName) +
                          "\n        "
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _vm._v("\n            所属公司\n          "),
                      ]),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.scanData.orgName) +
                          "\n        "
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _vm._v("\n            所属部门\n          "),
                      ]),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.scanData.departmentName) +
                          "\n        "
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { span: 2 } },
                    [
                      _c("template", { slot: "label" }, [
                        _vm._v("\n            收货地址\n          "),
                      ]),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.scanData.provinceName) +
                          _vm._s(_vm.scanData.cityName) +
                          _vm._s(_vm.scanData.countyName) +
                          "\n        "
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { span: 2 } },
                    [
                      _c("template", { slot: "label" }, [
                        _vm._v("\n            详细地址\n          "),
                      ]),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.scanData.detailedAddress) +
                          "\n        "
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _vm._v("\n            外部编码\n          "),
                      ]),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.scanData.detailedAddress) +
                          "\n        "
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    [
                      _c("template", { slot: "label" }, [
                        _vm._v("\n            状态\n          "),
                      ]),
                      _vm._v(" "),
                      _vm.scanData.archivesStatus == 0
                        ? _c("span", [_vm._v("禁用")])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.scanData.archivesStatus == 1
                        ? _c("span", [_vm._v("启用")])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.scanData.archivesStatus == 2
                        ? _c("span", [_vm._v("删除")])
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { span: 2 } },
                    [
                      _c("template", { slot: "label" }, [
                        _vm._v("\n            备注\n          "),
                      ]),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.scanData.remarks) +
                          "\n        "
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "default", plain: "" },
                  on: { click: _vm.handleCloseScan },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.dialogVisible,
            "before-close": _vm.handleClose,
            "append-to-body": "",
            width: "1200px !important",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "addForm",
              attrs: {
                rules: _vm.rules,
                model: _vm.addData,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "档案编码",
                            prop: "groupArchivesCode",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { maxlength: "15" },
                            on: {
                              input: function (e) {
                                return (_vm.addData.groupArchivesCode =
                                  _vm.validSJZD(e))
                              },
                            },
                            model: {
                              value: _vm.addData.groupArchivesCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.addData, "groupArchivesCode", $$v)
                              },
                              expression: "addData.groupArchivesCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "档案名称",
                            prop: "groupArchivesName",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { maxlength: "50", placeholder: "请输入" },
                            on: {
                              input: function (e) {
                                return (_vm.addData.groupArchivesName =
                                  _vm.validZDMC(e))
                              },
                            },
                            model: {
                              value: _vm.addData.groupArchivesName,
                              callback: function ($$v) {
                                _vm.$set(_vm.addData, "groupArchivesName", $$v)
                              },
                              expression: "addData.groupArchivesName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户类型", prop: "customerType" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { clearable: "", placeholder: "请选择" },
                              model: {
                                value: _vm.addData.customerType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addData, "customerType", $$v)
                                },
                                expression: "addData.customerType",
                              },
                            },
                            _vm._l(_vm.customOptions, function (item) {
                              return _c("el-option", {
                                key: item.code,
                                attrs: { label: item.name, value: item.code },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "团购类型", prop: "groupBuyTypeId" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { clearable: "", placeholder: "请选择" },
                              model: {
                                value: _vm.addData.groupBuyTypeId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addData, "groupBuyTypeId", $$v)
                                },
                                expression: "addData.groupBuyTypeId",
                              },
                            },
                            _vm._l(_vm.tgOptions, function (item) {
                              return _c("el-option", {
                                key: item.groupBuyCode,
                                attrs: {
                                  label: item.groupBuyName,
                                  value: item.pId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "所属渠道", prop: "groupSysType" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { clearable: "", placeholder: "请选择" },
                              model: {
                                value: _vm.addData.groupSysType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addData, "groupSysType", $$v)
                                },
                                expression: "addData.groupSysType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "企业", value: 0 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "经销商", value: 1 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "is-required",
                          attrs: { label: "联系人", prop: "contacts" },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.addData.contacts,
                              callback: function ($$v) {
                                _vm.$set(_vm.addData, "contacts", $$v)
                              },
                              expression: "addData.contacts",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "is-required",
                          attrs: { label: "手机号", prop: "phone" },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.addData.phone,
                              callback: function ($$v) {
                                _vm.$set(_vm.addData, "phone", $$v)
                              },
                              expression: "addData.phone",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "业务员", prop: "salesmanStr" } },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              "suffix-icon": "el-icon-search",
                              placeholder: "请输入",
                            },
                            on: {
                              focus: function ($event) {
                                return _vm.choseYwy("add")
                              },
                            },
                            model: {
                              value: _vm.salesmanStr,
                              callback: function ($$v) {
                                _vm.salesmanStr = $$v
                              },
                              expression: "salesmanStr",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "所属公司", prop: "orgId" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { filterable: "", placeholder: "" },
                              on: {
                                change: function ($event) {
                                  return _vm.changeorg(_vm.addData.orgId)
                                },
                              },
                              model: {
                                value: _vm.addData.orgId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addData, "orgId", $$v)
                                },
                                expression: "addData.orgId",
                              },
                            },
                            _vm._l(_vm.orgList, function (item) {
                              return _c("el-option", {
                                key: item.pId,
                                attrs: { label: item.orgName, value: item.pId },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "is-required",
                          attrs: { label: "收货地址", prop: "areas" },
                        },
                        [
                          _c("el-cascader", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              options: _vm.areaList,
                              props: _vm.cityProps,
                              clearable: "",
                            },
                            on: { change: _vm.handleChangeArea },
                            model: {
                              value: _vm.addData.areas,
                              callback: function ($$v) {
                                _vm.$set(_vm.addData, "areas", $$v)
                              },
                              expression: "addData.areas",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "所属部门", prop: "departmentId" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { filterable: "", placeholder: "" },
                              on: { change: _vm.changetab },
                              model: {
                                value: _vm.addData.departmentId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addData, "departmentId", $$v)
                                },
                                expression: "addData.departmentId",
                              },
                            },
                            _vm._l(_vm.depList, function (item) {
                              return _c("el-option", {
                                key: item.pId,
                                attrs: { label: item.orgName, value: item.pId },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "详细地址", prop: "detailedAddress" },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.addData.detailedAddress,
                              callback: function ($$v) {
                                _vm.$set(_vm.addData, "detailedAddress", $$v)
                              },
                              expression: "addData.detailedAddress",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "外部编码", prop: "outCode" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入", maxlength: "40" },
                            model: {
                              value: _vm.addData.outCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.addData, "outCode", $$v)
                              },
                              expression: "addData.outCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "is-required",
                          attrs: { label: "状态", prop: "archivesStatus" },
                        },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#13ce66",
                              "inactive-color": "#ff4949",
                              "active-value": "1",
                              "inactive-value": "0",
                              "active-text": "启用",
                              "inactive-text": "禁用",
                            },
                            model: {
                              value: _vm.addData.archivesStatus,
                              callback: function ($$v) {
                                _vm.$set(_vm.addData, "archivesStatus", $$v)
                              },
                              expression: "addData.archivesStatus",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注", prop: "remarks" } },
                        [
                          _c("el-input", {
                            attrs: { type: "textarea" },
                            model: {
                              value: _vm.addData.remarks,
                              callback: function ($$v) {
                                _vm.$set(_vm.addData, "remarks", $$v)
                              },
                              expression: "addData.remarks",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-form-item", [
                        _c(
                          "div",
                          { staticClass: "align-right" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  size: "mini",
                                  disabled: _vm.dstate,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.submitForm("addForm")
                                  },
                                },
                              },
                              [_vm._v("确定")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "default", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.resetForm("addForm")
                                  },
                                },
                              },
                              [_vm._v("取消")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "批量导入-团购档案",
            visible: _vm.dialogVisible2,
            "before-close": _vm.handleClose2,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible2 = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { padding: "0 0 20px 0" } },
            [
              _c(
                "el-button",
                { attrs: { type: "text" }, on: { click: _vm.downLoad } },
                [_vm._v("导入团购档案Excel模板")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "hr" }),
              _vm._v(" "),
              _c(
                "el-upload",
                {
                  ref: "upload",
                  staticClass: "upload-demo-files",
                  attrs: {
                    action: "#",
                    "show-file-list": true,
                    "on-change": _vm.handleChange,
                    "auto-upload": false,
                    "file-list": _vm.fileList,
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        slot: "trigger",
                        size: "small",
                        type: "primary",
                      },
                      slot: "trigger",
                    },
                    [_vm._v("选取文件")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: { size: "small", type: "success" },
                      on: { click: _vm.submitUpload },
                    },
                    [_vm._v("上传到服务器")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "txtIntr" }, [
            _c("p", [_vm._v("备注：")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n        1、必录项：档案名称、客户类型、团购类型编码、业务员编码、组织编码；\n      "
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n        2、团购类型、业务员、省市区都以编码作为导入校验依据，名称仅做参考不做校验；\n      "
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }