var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
      ? _c(
          "div",
          { staticClass: "page-section" },
          [
            _c("seach", {
              ref: "search",
              attrs: {
                "list-query": _vm.listQuery,
                "handle-show": _vm.handleShow,
              },
            }),
            _vm._v(" "),
            _vm.formConfig.btnFollow
              ? _c("el-divider", { staticClass: "btnDivider" })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "btnArea" },
              [
                _c(
                  "el-col",
                  {
                    staticClass: "head-btn-group",
                    staticStyle: { width: "230px" },
                  },
                  [
                    _vm.hasAuth("exportbtn", _vm.$route.meta.authList)
                      ? _c(
                          "div",
                          { staticClass: "head-btn-group" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: _vm.btnConfig.downLoadBtn.type,
                                  size: _vm.btnConfig.size,
                                  icon: _vm.btnConfig.downLoadBtn.icon,
                                  loading: _vm.excelOut_loading,
                                },
                                on: { click: _vm.excelOut },
                              },
                              [_vm._v(_vm._s(_vm.btnConfig.downLoadBtn.text))]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                key: _vm.tableKey,
                staticStyle: { width: "100%" },
                attrs: {
                  height: _vm.tabHeight,
                  data: _vm.list,
                  border: _vm.tableConfig.border,
                  stripe: _vm.tableConfig.stripe,
                  fit: "",
                  "highlight-current-row": "",
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    type: "index",
                    width: "120",
                    label: "序号",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "防伪码",
                    align: _vm.tableConfig.align,
                    "min-width": "180",
                    prop: "antiNum",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "产品品牌",
                    align: _vm.tableConfig.align,
                    "min-width": "150",
                    prop: "ppName",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "产品编码",
                    align: _vm.tableConfig.align,
                    "min-width": "180",
                    prop: "wldm",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "产品名称",
                    "min-width": "220",
                    align: _vm.tableConfig.align,
                    prop: "wlms",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "预警描述",
                    "min-width": "220",
                    align: _vm.tableConfig.align,
                    prop: "yjms",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "查询次数",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    prop: "cxcs",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "异常预警类型",
                    align: _vm.tableConfig.align,
                    "min-width": "150",
                    prop: "ycyjlx",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.ycyjlx))]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    4007163771
                  ),
                }),
                _vm._v(" "),
                _vm.hasAuth("detailbtn", _vm.$route.meta.authList)
                  ? _c("el-table-column", {
                      attrs: {
                        label: "操作",
                        fixed: "right",
                        align: _vm.tableConfig.align,
                        width: "100",
                        "class-name": "small-padding fixed-width",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.getDetails(row, "approval")
                                      },
                                    },
                                  },
                                  [_vm._v("详情")]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        832676239
                      ),
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c("pagination", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.total > 0,
                  expression: "total>0",
                },
              ],
              attrs: {
                total: _vm.total,
                page: _vm.listQuery.pageNo,
                limit: _vm.listQuery.pageSize,
              },
              on: {
                "update:page": function ($event) {
                  return _vm.$set(_vm.listQuery, "pageNo", $event)
                },
                "update:limit": function ($event) {
                  return _vm.$set(_vm.listQuery, "pageSize", $event)
                },
                pagination: _vm.getList,
              },
            }),
            _vm._v(" "),
            _c("digital", {
              ref: "digitalDialog",
              attrs: { "dialog-title": _vm.dialogTitle },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
      ? _c("section", [_vm._m(0)])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }