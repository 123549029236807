"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getExportExcel = getExportExcel;
exports.getPage = getPage;
var _request = _interopRequireDefault(require("@/utils/request"));
var base = '';

// 奖品核对表格
function getPage(params) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/activityPrizeCheck/page',
    method: 'get',
    params: params
  });
}
// 奖品核对导出
function getExportExcel(params) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/activityPrizeCheck/exportExcel',
    method: 'get',
    responseType: 'blob',
    params: params
  });
}