"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.del = del;
exports.getList = getList;
exports.getcodedataall = getcodedataall;
exports.queryMarkDetail = queryMarkDetail;
exports.queryMarkForPage = queryMarkForPage;
exports.searchDetail = searchDetail;
exports.synthesizesearch = synthesizesearch;
var _request = _interopRequireDefault(require("@/utils/request"));
var _qs = _interopRequireDefault(require("qs"));
var modelUrl = '/api-prevent';
// var modelUrl = ''
// 稽查分页查询
function getList(params) {
  return (0, _request.default)({
    url: modelUrl + '/logis/search',
    method: 'get',
    params: params
  });
}

// 稽查记录详情
function searchDetail(params) {
  return (0, _request.default)({
    url: modelUrl + '/logis/searchDetail',
    method: 'get',
    params: params
  });
}

// 稽查记录删除
function del(params) {
  return (0, _request.default)({
    url: modelUrl + '/logis/delete',
    method: 'delete',
    params: params
  });
}

// 数码查询分页列表
function queryMarkForPage(params) {
  return (0, _request.default)({
    url: '/api-mark/markSelect/queryMarkForPage',
    method: 'get',
    params: params
  });
}

// 数码详情查询
function queryMarkDetail(params) {
  return (0, _request.default)({
    url: '/api-mark/markSelect/queryMarkDetail/' + params,
    method: 'get'
  });
}

// 稽查/溯源/物流信息查询
function synthesizesearch(params) {
  return (0, _request.default)({
    url: modelUrl + '/synthesize/search',
    method: 'get',
    params: params
  });
}
// 根据数码查询相关详细信息
function getcodedataall(param) {
  return (0, _request.default)({
    url: '/api-mark/markSelect/saas/queryMark',
    method: 'get',
    params: param,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
}