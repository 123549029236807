var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
      ? _c(
          "div",
          { staticClass: "page-section" },
          [
            _c(
              "div",
              { attrs: { id: "seach-box" } },
              [
                _c(
                  "el-form",
                  {
                    ref: "searchform",
                    staticClass: "search-condition",
                    attrs: {
                      model: _vm.listQuery,
                      "label-position": _vm.formConfig.labelPosition,
                      "label-width": _vm.formConfig.labelWidth,
                      "label-suffix": ":",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "cols" },
                      [
                        _c(
                          "el-row",
                          {
                            staticStyle: { "margin-bottom": "0" },
                            attrs: { gutter: 20 },
                          },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "markOrderNo",
                                      label: "制码订单号",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        clearable: "",
                                        value: _vm.listQuery.markOrderNo,
                                        placeholder: "制码订单号",
                                        maxlength: "20",
                                      },
                                      on: {
                                        input: function (e) {
                                          return (_vm.listQuery.markOrderNo =
                                            _vm.validSe(e))
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "supplierType",
                                      label: "供应商类型",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "filter-item",
                                        attrs: {
                                          clearable: "",
                                          "value-key": "id",
                                          placeholder: "选择供应商类型",
                                        },
                                        model: {
                                          value: _vm.listQuery.supplierType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.listQuery,
                                              "supplierType",
                                              $$v
                                            )
                                          },
                                          expression: "listQuery.supplierType",
                                        },
                                      },
                                      _vm._l(
                                        _vm.selectOptions.supplierType,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.id,
                                            attrs: {
                                              label: item.name,
                                              value: item.id,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "supplierName",
                                      label: "供应商名称",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        maxlength: "25",
                                        clearable: "",
                                        placeholder: "供应商名称",
                                      },
                                      model: {
                                        value: _vm.listQuery.supplierName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.listQuery,
                                            "supplierName",
                                            $$v
                                          )
                                        },
                                        expression: "listQuery.supplierName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.handleShow,
                                    expression: "handleShow",
                                  },
                                ],
                                attrs: { span: 6 },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "generateStatus",
                                      label: "生成状态",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "filter-item",
                                        attrs: {
                                          clearable: "",
                                          "value-key": "id",
                                          placeholder: "选择生成状态",
                                        },
                                        model: {
                                          value: _vm.listQuery.generateStatus,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.listQuery,
                                              "generateStatus",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "listQuery.generateStatus",
                                        },
                                      },
                                      _vm._l(
                                        _vm.selectOptions.auditStatus,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.id,
                                            attrs: {
                                              label: item.name,
                                              value: item.id,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.handleShow ||
                                      !_vm.formConfig.btnFollow,
                                    expression:
                                      "handleShow||!formConfig.btnFollow",
                                  },
                                ],
                                attrs: { span: 6 },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "applyStyle",
                                      label: "申请方式",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "filter-item",
                                        attrs: {
                                          clearable: "",
                                          "value-key": "id",
                                          placeholder: "选择申请方式",
                                        },
                                        model: {
                                          value: _vm.listQuery.applyStyle,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.listQuery,
                                              "applyStyle",
                                              $$v
                                            )
                                          },
                                          expression: "listQuery.applyStyle",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: {
                                            label: "套标申请",
                                            value: 1,
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: {
                                            label: "后关联申请",
                                            value: 2,
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: {
                                            label: "制盖申请",
                                            value: 3,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.handleShow,
                                    expression: "handleShow",
                                  },
                                ],
                                attrs: { span: 6 },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "beginDate",
                                      label: "开始日期",
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        type: "date",
                                        format: "yyyy-MM-dd",
                                        "value-format": "yyyy-MM-dd",
                                      },
                                      model: {
                                        value: _vm.listQuery.beginDate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.listQuery,
                                            "beginDate",
                                            $$v
                                          )
                                        },
                                        expression: "listQuery.beginDate",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.handleShow,
                                    expression: "handleShow",
                                  },
                                ],
                                attrs: { span: 6 },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "endDate",
                                      label: "结束日期",
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        type: "date",
                                        format: "yyyy-MM-dd",
                                        "value-format": "yyyy-MM-dd",
                                      },
                                      model: {
                                        value: _vm.listQuery.endDate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.listQuery,
                                            "endDate",
                                            $$v
                                          )
                                        },
                                        expression: "listQuery.endDate",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.formConfig.btnFollow
                              ? _c(
                                  "el-col",
                                  {
                                    staticClass: "head-btn-group",
                                    style: {
                                      width: _vm.formConfig.btnAreaHasShowMore,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { "label-width": "0" } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: { slot: "label" },
                                            slot: "label",
                                          },
                                          [_vm._v(" ")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: _vm.btnConfig.searchBtn
                                                .type,
                                              size: _vm.btnConfig.size,
                                              icon: _vm.btnConfig.searchBtn
                                                .icon,
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.listQuery.current = 1
                                                _vm.getList()
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.btnConfig.searchBtn.text
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: _vm.btnConfig.resetBtn.type,
                                              size: _vm.btnConfig.size,
                                              icon: _vm.btnConfig.resetBtn.icon,
                                            },
                                            on: { click: _vm.handleFilter },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.btnConfig.resetBtn.text
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                _vm.handleShow = !_vm.handleShow
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.handleShow
                                                    ? "收起"
                                                    : "展开"
                                                ) +
                                                "\n                  "
                                            ),
                                            _c("i", {
                                              class: _vm.handleShow
                                                ? "el-icon-arrow-up"
                                                : "el-icon-arrow-down",
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.formConfig.btnFollow
              ? _c("el-divider", { staticClass: "btnDivider" })
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "btnArea" }),
            _vm._v(" "),
            _c(
              "div",
              { attrs: { id: "table-box" } },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.listLoading,
                        expression: "listLoading",
                      },
                    ],
                    key: _vm.tableKey,
                    staticStyle: { width: "100%" },
                    attrs: {
                      height: _vm.tabHeight,
                      data: _vm.list,
                      border: _vm.tableConfig.border,
                      stripe: _vm.tableConfig.stripe,
                      fit: "",
                      "highlight-current-row": "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "index",
                        width: "100",
                        label: "序号",
                        align: _vm.tableConfig.align,
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "制码订单号",
                        align: _vm.tableConfig.align,
                        "min-width": "150",
                        prop: "markOrderNo",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "供应商类型",
                        align: _vm.tableConfig.align,
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("supplierType")(
                                        scope.row.supplierType
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2209439551
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "供应商名称",
                        align: _vm.tableConfig.align,
                        "min-width": "100",
                        prop: "supplierName",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "申请方式",
                        align: _vm.tableConfig.align,
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.applyStyle == 1 &&
                                scope.row.applyMarkType == 1
                                  ? _c("span", [_vm._v("套标申请")])
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.applyStyle == 2 &&
                                scope.row.applyMarkType == 1
                                  ? _c("span", [_vm._v("后关联申请")])
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.applyMarkType == 2
                                  ? _c("span", [_vm._v("制盖申请")])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3199768545
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "来源",
                        width: "120",
                        align: _vm.tableConfig.align,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.applySource == 2
                                  ? _c("span", [_vm._v("系统自动生成")])
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.applySource == 1
                                  ? _c("span", [_vm._v("平台申请")])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1576744029
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "制码类型",
                        align: _vm.tableConfig.align,
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.applyMarkType == 1
                                  ? _c("span", [_vm._v("制标")])
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.applyMarkType == 2
                                  ? _c("span", [_vm._v("制盖")])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1332406338
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "数量(万)",
                        align: _vm.tableConfig.align,
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.markNum
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text", size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.getDetails(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(scope.row.markNum))]
                                    )
                                  : _c("span", [
                                      _vm._v(_vm._s(scope.row.markNum)),
                                    ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3340484673
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "生成时间",
                        align: _vm.tableConfig.align,
                        "min-width": "165",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatDate")(
                                        scope.row.generateTime
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1568103254
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "生码状态",
                        align: _vm.tableConfig.align,
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.generateStatus == 2
                                  ? _c("span", { staticClass: "success" }, [
                                      _vm._v("生成成功"),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.generateStatus == 3
                                  ? _c("span", { staticClass: "danger" }, [
                                      _vm._v("生成失败"),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.generateStatus == 1
                                  ? _c("span", { staticClass: "info" }, [
                                      _vm._v("生成中"),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1205542706
                      ),
                    }),
                    _vm._v(" "),
                    _vm.hasAuth("updatebtn", _vm.$route.meta.authList)
                      ? _c("el-table-column", {
                          attrs: {
                            label: "操作",
                            fixed: "right",
                            align: _vm.tableConfig.align,
                            width: "100",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    row.generateStatus == 3
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleUpdate(
                                                  row,
                                                  "set"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("重新生成")]
                                        )
                                      : _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              disabled: "",
                                              type: "text",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleUpdate(
                                                  row,
                                                  "edit"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("重新生成")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            677522794
                          ),
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("pagination", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.total > 0,
                      expression: "total>0",
                    },
                  ],
                  attrs: {
                    total: _vm.total,
                    page: _vm.listQuery.current,
                    limit: _vm.listQuery.size,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.listQuery, "current", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.listQuery, "size", $event)
                    },
                    pagination: _vm.getList,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                attrs: {
                  "close-on-click-modal": false,
                  "append-to-body": true,
                  title: "码量明细",
                  visible: _vm.dialogFormVisible,
                  top: "3%",
                  width: "70%",
                  "before-close": _vm.clear,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogFormVisible = $event
                  },
                },
              },
              [
                _c(
                  "el-form",
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.downlistLoading,
                            expression: "downlistLoading",
                          },
                        ],
                        key: _vm.tableKey,
                        staticStyle: { width: "100%" },
                        attrs: {
                          height: "400",
                          data: _vm.downlist,
                          border: _vm.tableConfig.border,
                          stripe: _vm.tableConfig.stripe,
                          fit: "",
                          "highlight-current-row": "",
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            type: "index",
                            width: "120",
                            label: "序号",
                            align: _vm.tableConfig.align,
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "包装层级",
                            "min-width": "",
                            align: _vm.tableConfig.align,
                            "show-overflow-tooltip": "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("packLevel")(scope.row.packLevel)
                                      )
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2384631140
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "码类型",
                            align: _vm.tableConfig.align,
                            prop: "markType",
                            "show-overflow-tooltip": "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("codeType")(scope.row.markType)
                                      )
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            153499004
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "URL",
                            align: _vm.tableConfig.align,
                            prop: "urlAddress",
                            "show-overflow-tooltip": "",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "实际数量（万）",
                            align: _vm.tableConfig.align,
                            prop: "markNum",
                            "show-overflow-tooltip": "",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            _vm.dialogFormVisible = false
                          },
                        },
                      },
                      [_vm._v("关闭")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
      ? _c("section", [_vm._m(0)])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }