var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "制码申请详情",
        width: "75% !important",
        visible: _vm.dialogApplyVisible,
        top: "3%",
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogApplyVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { attrs: { id: "form-box" } },
        [
          _c(
            "el-steps",
            {
              attrs: {
                "finish-status": "success",
                active: _vm.active,
                "align-center": "",
              },
            },
            [
              _c("el-step", { attrs: { title: "制码申请" } }),
              _vm._v(" "),
              _c("el-step", { attrs: { title: "审核" } }),
              _vm._v(" "),
              _c("el-step", { attrs: { title: "制码生成" } }),
              _vm._v(" "),
              _c("el-step", { attrs: { title: "制码下载" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "dataForm",
              staticStyle: { width: "100%", "margin-top": "30px" },
              attrs: {
                model: _vm.temp,
                "label-position": "right",
                "label-width": "100px",
              },
            },
            [
              _c(
                "div",
                { staticClass: "filter-container" },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { lg: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "postInfo-container-item",
                              staticStyle: { "margin-bottom": "2px" },
                              attrs: {
                                prop: "markOrderNo",
                                "label-width": "120px",
                                label: "制码订单号:",
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.temp.markOrderNo
                                      ? _vm.temp.markOrderNo
                                      : ""
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { lg: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "postInfo-container-item",
                              staticStyle: { "margin-bottom": "2px" },
                              attrs: {
                                prop: "applyStyle",
                                "label-width": "120px",
                                label: "申请方式:",
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("applyStyle")(_vm.temp.applyStyle)
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _vm.showOrderNum
                        ? _c(
                            "el-col",
                            { attrs: { lg: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "postInfo-container-item",
                                  staticStyle: { "margin-bottom": "2px" },
                                  attrs: {
                                    prop: "orderNum",
                                    "label-width": "120px",
                                    label: "订单数量:",
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.temp.orderNum
                                          ? _vm.temp.orderNum + "（万套）"
                                          : ""
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { lg: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "postInfo-container-item",
                              staticStyle: { "margin-bottom": "2px" },
                              attrs: {
                                prop: "applicationType",
                                "label-width": "120px",
                                label: " 应用场景:",
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("applicationType")(
                                      _vm.temp.applicationType
                                    )
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { lg: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "postInfo-container-item",
                              staticStyle: { "margin-bottom": "2px" },
                              attrs: {
                                prop: "expireDate",
                                "label-width": "120px",
                                label: "数码过期时间:",
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.temp.expireDate
                                      ? _vm.temp.expireDate
                                      : ""
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.temp.applyStyle == 1
                    ? _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { lg: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "postInfo-container-item",
                                  staticStyle: { "margin-bottom": "2px" },
                                  attrs: {
                                    prop: "packRatioCode",
                                    "label-width": "120px",
                                    label: "包装比例:",
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.setTemp.packScaleName
                                          ? _vm.setTemp.packScaleName
                                          : ""
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.temp.applyStyle == 1
                    ? _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { lg: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "postInfo-container-item",
                                  staticStyle: { "margin-bottom": "2px" },
                                  attrs: {
                                    prop: "packScaleDesc",
                                    "label-width": "120px",
                                    label: "比例描述:",
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.setTemp.packScaleDesc
                                          ? _vm.setTemp.packScaleDesc
                                          : ""
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { lg: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "postInfo-container-item",
                              staticStyle: { "margin-bottom": "2px" },
                              attrs: {
                                prop: "supplierName",
                                "label-width": "120px",
                                label: "供应商:",
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.temp.supplierName
                                      ? _vm.temp.supplierName
                                      : ""
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { lg: 12 } },
                        [
                          _c("el-form-item", {
                            staticClass:
                              "postInfo-container-item hiddenInputWrap",
                            staticStyle: {
                              "margin-bottom": "20px",
                              height: "40px",
                            },
                            attrs: {
                              prop: "flagGroupId",
                              "label-width": "120px",
                              label: "标识组:",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.showafterBox
                    ? _c(
                        "el-row",
                        { staticStyle: { "padding-left": "120px" } },
                        [
                          _vm.showCareNum
                            ? _c(
                                "el-col",
                                { attrs: { lg: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "postInfo-container-item",
                                      staticStyle: { "margin-bottom": "2px" },
                                      attrs: {
                                        prop: "chestNum",
                                        "label-width": "58px",
                                        label: "箱数量:",
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.chestNum
                                              ? _vm.chestNum + "（万个）"
                                              : ""
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showBoxNum
                            ? _c(
                                "el-col",
                                { attrs: { lg: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "postInfo-container-item",
                                      staticStyle: { "margin-bottom": "2px" },
                                      attrs: {
                                        prop: "boxNum",
                                        "label-width": "58px",
                                        label: "盒数量:",
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.boxNum
                                              ? _vm.boxNum + "（万个）"
                                              : ""
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showBottleNum
                            ? _c(
                                "el-col",
                                { attrs: { lg: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "postInfo-container-item",
                                      staticStyle: { "margin-bottom": "2px" },
                                      attrs: {
                                        prop: "bottleNum",
                                        "label-width": "58px",
                                        label: "瓶数量:",
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.bottleNum
                                              ? _vm.bottleNum + "（万个）"
                                              : ""
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.temp.flagGroupCode != ""
                    ? _c(
                        "div",
                        {
                          staticStyle: { "margin-bottom": "10px" },
                          attrs: { id: "table-box" },
                        },
                        [
                          _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.codelistLoading,
                                  expression: "codelistLoading",
                                },
                              ],
                              key: _vm.tableKey,
                              staticStyle: { width: "100%" },
                              attrs: {
                                data: _vm.flagelist,
                                "max-height": "350",
                                "tooltip-effect": "dark",
                                border: "",
                                fit: "",
                                "highlight-current-row": "",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  type: "index",
                                  width: "120",
                                  align: _vm.tableConfig.align,
                                  label: "序号",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "码类型",
                                  align: _vm.tableConfig.align,
                                  width: "80",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("codeType")(
                                                  scope.row.markType
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3427812999
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "包装层级",
                                  align: _vm.tableConfig.align,
                                  "min-width": "120",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("levelType")(
                                                  scope.row.packLevel,
                                                  scope.row.markType
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3914337215
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "URL",
                                  align: _vm.tableConfig.align,
                                  prop: "urlAddress",
                                  "min-width": "120",
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "图片",
                                  align: _vm.tableConfig.align,
                                  width: "100",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("el-image", {
                                            staticStyle: {
                                              width: "80px",
                                              height: "80px",
                                            },
                                            attrs: {
                                              src: scope.row.imgAddress
                                                ? scope.row.imgAddress
                                                : _vm.imgUrl,
                                              "preview-src-list": [
                                                scope.row.imgAddress
                                                  ? scope.row.imgAddress
                                                  : _vm.imgUrl,
                                              ],
                                              fit: "contain",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1805687109
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "实际数量(万)",
                                  align: _vm.tableConfig.align,
                                  width: "120",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _vm.temp.applyStyle == 1
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("packSum")(
                                                      scope.row.packLevel,
                                                      _vm.temp.orderNum
                                                    )
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.temp.applyStyle == 2 &&
                                          scope.row.packLevel == 2
                                            ? _c("span", [
                                                _vm._v(_vm._s(_vm.bottleNum)),
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.temp.applyStyle == 2 &&
                                          scope.row.packLevel == 3
                                            ? _c("span", [
                                                _vm._v(_vm._s(_vm.boxNum)),
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.temp.applyStyle == 2 &&
                                          scope.row.packLevel == 4
                                            ? _c("span", [
                                                _vm._v(_vm._s(_vm.chestNum)),
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.temp.applyStyle == 3
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.temp.orderNum)
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  4012460244
                                ),
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("pagination", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.total > 0,
                                expression: "total>0",
                              },
                            ],
                            attrs: {
                              total: _vm.total,
                              page: _vm.listQuery.current,
                              limit: _vm.listQuery.size,
                              "auto-scroll": false,
                            },
                            on: {
                              "update:page": function ($event) {
                                return _vm.$set(
                                  _vm.listQuery,
                                  "current",
                                  $event
                                )
                              },
                              "update:limit": function ($event) {
                                return _vm.$set(_vm.listQuery, "size", $event)
                              },
                              pagination: _vm.getModelList,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }