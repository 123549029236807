"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _newlyAdded = _interopRequireDefault(require("./component/newlyAdded"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManageprizeSettingsetWordsetWordGroupindex';
var _default = {
  name: 'MarketingManageprizeSettingsetWordsetWordGroupindex',
  components: {
    Pagination: _Pagination.default,
    newlyAdded: _newlyAdded.default
  },
  data: function data() {
    return {
      queryParams: {
        pageSize: 10,
        pageNo: 1,
        prizeName: '',
        status: '',
        rule: ''
      },
      total: 0,
      listLoadig: false,
      tableData: [{}],
      dialogVisible: false,
      title: '',
      isFontAll: false
    };
  },
  activated: function activated() {
    this.queryList();
  },
  deactivated: function deactivated() {
    var name = cachName;
    var cache = this.getCaches(this, name);
    if (!cache) {
      this.tableData = [];
      this.total = 0;
    }
  },
  methods: {
    // 搜索条件
    queryList: function queryList() {
      this.queryParams.pageNo = 1;
    },
    // 重置搜索条件
    restQueryList: function restQueryList() {},
    // 新增按钮
    handleAdd: function handleAdd() {
      this.title = '新增集字奖品';
      this.dialogVisible = true;
    },
    // 导出按钮
    exportFile: function exportFile() {},
    // 查看详情
    handleDetails: function handleDetails() {},
    // 编辑
    handleUpdate: function handleUpdate() {},
    // 启用停用
    stop: function stop() {},
    showStatus: function showStatus(row) {
      if (row.status == 1) return '启用';
      return '停用';
    },
    handleCancel: function handleCancel() {
      this.dialogVisible = false;
    }
  }
};
exports.default = _default;