"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _select = require("@/api/warehouse/select");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'TablePackset',
  components: {
    Pagination: _Pagination.default
  },
  filters: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    inOutType: {
      type: String,
      default: ''
    },
    shouFaType: {
      type: String,
      default: ''
    },
    purchaseType: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      listLoading: false,
      tableKey: '111',
      tabHeight: '100%',
      searchForm: {
        companyCode: '',
        companyName: '',
        inOutType: '',
        shouFaType: '',
        isEnable: 1,
        current: 1,
        size: 10
      },
      tableData: [],
      total: 0
    };
  },
  watch: {
    visible: function visible(val) {
      if (val) {
        this.searchForm.inOutType = this.inOutType;
        this.searchForm.shouFaType = this.shouFaType;
        this.searchForm.purchaseType = this.purchaseType;
        this.getList();
      } else {
        this.searchForm.inOutType = '';
        this.searchForm.shouFaType = '';
      }
    }
  },
  mounted: function mounted() {},
  methods: {
    getList: function getList() {
      var _this = this;
      var that = this;
      this.listLoading = true;
      console.log(that.searchForm, '请求参数');
      (0, _select.getDbUnits)(that.searchForm).then(function (res) {
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            that.tableData = res.data.data.records;
            that.total = Number(res.data.data.total);
          } else {
            that.tableData = [];
            that.total = Number(res.data.data.total);
          }
        }
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    resetForm: function resetForm(formName) {
      this.searchForm = {
        companyCode: '',
        companyName: '',
        inOutType: this.inOutType,
        shouFaType: this.shouFaType,
        isEnable: 1,
        current: 1,
        size: 10
      };
      // this.searchForm.inOutType = this.inOutType
      // this.searchForm.shouFaType = this.shouFaType
      this.getList();
    },
    selCancel: function selCancel() {
      this.resetForm();
      this.$emit('close');
    },
    submit: function submit(row) {
      this.$emit('change', row);
      this.selCancel();
    }
  }
};
exports.default = _default;