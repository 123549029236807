var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "hyyy-container" }, [
    _c(
      "div",
      { staticClass: "app-container" },
      [
        _c(
          "el-row",
          [
            _c(
              "el-col",
              [
                _c(
                  "el-card",
                  { staticClass: "box-card" },
                  [
                    _c("div", { staticClass: "hyyy-top-tit" }, [
                      _c("div", { staticClass: "hyyy-top-tit-lift" }, [
                        _c("span", {
                          staticStyle: { "font-weight": "400", color: "#333" },
                          domProps: {
                            textContent: _vm._s(_vm.$route.meta.title),
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "hyyy-top-tit-right" }),
                    ]),
                    _vm._v(" "),
                    _c("el-divider", { staticStyle: { margin: "0" } }),
                    _vm._v(" "),
                    _c(
                      "el-form",
                      {
                        ref: "formInline",
                        staticClass: "searchForm demo-form-inline",
                        staticStyle: { padding: "20px" },
                        attrs: {
                          model: _vm.formInline,
                          inline: true,
                          gutter: 10,
                          "label-position": "top",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "cols" },
                          [
                            _c(
                              "el-row",
                              { attrs: { gutter: 20 } },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 6 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "产品名称：",
                                          prop: "prodName",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "filter-item",
                                          attrs: { placeholder: "产品名称" },
                                          model: {
                                            value: _vm.formInline.prodName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formInline,
                                                "prodName",
                                                $$v
                                              )
                                            },
                                            expression: "formInline.prodName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 6 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "产品分类：",
                                          prop: "categoryName",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: { placeholder: "请选择" },
                                            on: { change: _vm.getfl1 },
                                            model: {
                                              value: _vm.formInline.pcategoryId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formInline,
                                                  "pcategoryId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "formInline.pcategoryId",
                                            },
                                          },
                                          _vm._l(_vm.options, function (item) {
                                            return _c(
                                              "el-option",
                                              {
                                                key: item.categoryId,
                                                attrs: {
                                                  label: item.categoryName,
                                                  value: item.categoryId,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(item.categoryName)
                                                ),
                                              ]
                                            )
                                          }),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { attrs: { span: 6 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: { slot: "label" },
                                            slot: "label",
                                          },
                                          [_vm._v(" ")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-select",
                                          {
                                            attrs: { placeholder: "请选择" },
                                            model: {
                                              value: _vm.formInline.categoryId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formInline,
                                                  "categoryId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "formInline.categoryId",
                                            },
                                          },
                                          _vm._l(_vm.options1, function (item) {
                                            return _c(
                                              "el-option",
                                              {
                                                key: item.categoryId,
                                                attrs: {
                                                  label: item.categoryName,
                                                  value: item.categoryId,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(item.categoryName)
                                                ),
                                              ]
                                            )
                                          }),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.handleShow,
                                        expression: "handleShow",
                                      },
                                    ],
                                    attrs: { span: 6 },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "状态：",
                                          prop: "status",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: { placeholder: "请选择" },
                                            model: {
                                              value: _vm.formInline.status,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formInline,
                                                  "status",
                                                  $$v
                                                )
                                              },
                                              expression: "formInline.status",
                                            },
                                          },
                                          [
                                            _c("el-option", {
                                              attrs: {
                                                label: "全部",
                                                value: "",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("el-option", {
                                              attrs: {
                                                label: "未上架",
                                                value: "0",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("el-option", {
                                              attrs: {
                                                label: "已上架",
                                                value: "1",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  { staticClass: "hyyy-head-btn" },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { "label-width": "0" } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: { slot: "label" },
                                            slot: "label",
                                          },
                                          [_vm._v(" ")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "normalBtn",
                                            attrs: { type: "primary" },
                                            on: {
                                              click: function ($event) {
                                                _vm.formInline.current = 1
                                                _vm.fetchData()
                                              },
                                            },
                                          },
                                          [_vm._v("查询")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "normalBtn",
                                            on: {
                                              click: function ($event) {
                                                return _vm.restForm(
                                                  "formInline"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("重置")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                _vm.handleShow = !_vm.handleShow
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  _vm.handleShow
                                                    ? "收起"
                                                    : "展开"
                                                ) +
                                                "\n                      "
                                            ),
                                            _c("i", {
                                              class: _vm.handleShow
                                                ? "el-icon-arrow-up"
                                                : "el-icon-arrow-down",
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("el-row", {
                          staticStyle: { "text-align": "center" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-row",
          { staticStyle: { "margin-top": "20px" } },
          [
            _c(
              "el-col",
              [
                _c(
                  "el-card",
                  { staticClass: "box-card", staticStyle: { padding: "20px" } },
                  [
                    _c(
                      "div",
                      { staticClass: "btnWrap" },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "normalBtn",
                            attrs: { type: "primary", icon: "el-icon-plus" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.addOrUpdateHandle()
                              },
                            },
                          },
                          [_vm._v("新增")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "normalBtn",
                            attrs: {
                              type: "danger",
                              disabled: _vm.dataListSelections.length <= 0,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteHandle()
                              },
                            },
                          },
                          [_vm._v("批量删除")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.listLoading,
                            expression: "listLoading",
                          },
                        ],
                        attrs: {
                          data: _vm.dataList,
                          "element-loading-text": "Loading",
                          fit: "",
                          "header-cell-style": {
                            "background-color": "#fafafa",
                            color: "rgba(0, 0, 0,0.85)",
                            "font-weight": "500",
                          },
                          "row-style": {
                            color: "rgba(0, 0, 0,0.65)",
                            "font-weight": "400",
                          },
                        },
                        on: { "selection-change": _vm.handleSelectionChange },
                      },
                      [
                        _c("el-table-column", {
                          attrs: { type: "selection", width: "55" },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "产品名称",
                            prop: "prodName",
                            align: "center",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "产品图片", align: "center" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("img", {
                                    staticStyle: { width: "50px" },
                                    attrs: { src: scope.row.pic, alt: "" },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "产品分类",
                            prop: "categoryStr",
                            align: "center",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "积分", prop: "jf", align: "center" },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "价格", prop: "jg", align: "center" },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "已兑/可兑", align: "center" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(scope.row.ydsl) +
                                        "/" +
                                        _vm._s(scope.row.totalStocks)
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "状态", align: "center" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.status === 1
                                    ? _c("span", [_vm._v("已上架")])
                                    : _c("span", [_vm._v("未上架")]),
                                  _vm._v(" "),
                                  _c("el-switch", {
                                    attrs: {
                                      "active-value": 1,
                                      "inactive-value": 0,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.checkstatus(
                                          $event,
                                          scope.row.prodId,
                                          scope.$index
                                        )
                                      },
                                    },
                                    model: {
                                      value: scope.row.status,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "status", $$v)
                                      },
                                      expression: "scope.row.status",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "操作",
                            fixed: "right",
                            width: "200",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.status === 1
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "button configText",
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.showDetail(
                                                    scope.row.prodId
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("预览")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "button configText",
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addOrUpdateHandle(
                                                    scope.row.prodId
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("编辑")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "button configText",
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.showDetail(
                                                    scope.row.prodId
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("预览")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-button",
                                            {
                                              staticClass:
                                                "button configText red",
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteHandle(
                                                    scope.row.prodId
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("删除")]
                                          ),
                                        ],
                                        1
                                      ),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("pagination", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.total > 0,
                          expression: "total > 0",
                        },
                      ],
                      attrs: {
                        total: _vm.total,
                        page: _vm.formInline.current,
                        limit: _vm.formInline.size,
                      },
                      on: {
                        "update:page": function ($event) {
                          return _vm.$set(_vm.formInline, "current", $event)
                        },
                        "update:limit": function ($event) {
                          return _vm.$set(_vm.formInline, "size", $event)
                        },
                        pagination: _vm.fetchData,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }