var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.dialogVisible,
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.mloading,
              expression: "mloading",
            },
          ],
          ref: "scanForm",
          staticClass: "info-items",
          attrs: {
            model: _vm.scanData,
            rules: _vm.rules,
            "label-position": "right",
            "label-width": "110px",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "销售出库单号:", prop: "billNo" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "350px" },
                        attrs: { disabled: "", readonly: true },
                        model: {
                          value: _vm.scanData.billNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.scanData, "billNo", $$v)
                          },
                          expression: "scanData.billNo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "发货单位:", prop: "outOrgName" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "350px" },
                        attrs: { readonly: "", disabled: "" },
                        model: {
                          value: _vm.scanData.outOrgName,
                          callback: function ($$v) {
                            _vm.$set(_vm.scanData, "outOrgName", $$v)
                          },
                          expression: "scanData.outOrgName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "发货库房:", prop: "outStoreName" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "350px" },
                        attrs: { readonly: "", disabled: "" },
                        model: {
                          value: _vm.scanData.outStoreName,
                          callback: function ($$v) {
                            _vm.$set(_vm.scanData, "outStoreName", $$v)
                          },
                          expression: "scanData.outStoreName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "收货单位:", prop: "inOrgName" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticStyle: { width: "350px" },
                          attrs: { readonly: "" },
                          model: {
                            value: _vm.scanData.inOrgName,
                            callback: function ($$v) {
                              _vm.$set(_vm.scanData, "inOrgName", $$v)
                            },
                            expression: "scanData.inOrgName",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: { slot: "append", icon: "el-icon-search" },
                            on: {
                              click: function ($event) {
                                return _vm.getInUnit("in")
                              },
                            },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "收货人:", prop: "receiptUser" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "350px" },
                        attrs: { readonly: "", disabled: "" },
                        model: {
                          value: _vm.scanData.receiptUser,
                          callback: function ($$v) {
                            _vm.$set(_vm.scanData, "receiptUser", $$v)
                          },
                          expression: "scanData.receiptUser",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品明细:", prop: "materialCode" } },
                    [
                      _c(
                        "el-table",
                        {
                          key: _vm.tableKey,
                          staticStyle: { width: "100%", "margin-top": "1px" },
                          attrs: {
                            data: _vm.productList,
                            border: _vm.tableConfig.border,
                            stripe: _vm.tableConfig.stripe,
                            fit: "",
                            "highlight-current-row": "",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "序号",
                              type: "index",
                              width: "80",
                              align: _vm.tableConfig.align,
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "产品编码",
                              prop: "productCode",
                              align: _vm.tableConfig.align,
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "产品名称",
                              prop: "productName",
                              align: _vm.tableConfig.align,
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "规格",
                              prop: "packRadioDesc",
                              align: _vm.tableConfig.align,
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "计划最大单位数",
                              prop: "planScanNum",
                              align: _vm.tableConfig.align,
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(row.planScanNum) +
                                          _vm._s(row.packList[0])
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "计划最小单位数",
                              prop: "planScanAtoNum",
                              align: _vm.tableConfig.align,
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(row.planScanAtoNum) +
                                          _vm._s(
                                            row.packList[
                                              row.packList.length - 1
                                            ]
                                          )
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { staticClass: "dialog-footer" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", loading: _vm.btn_loading },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm()
                            },
                          },
                        },
                        [_vm._v("提 交")]
                      ),
                      _vm._v(" "),
                      _c("el-button", { on: { click: _vm.handleClose } }, [
                        _vm._v("关 闭"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("allotUnitSelect", {
        attrs: {
          visible: _vm.unitShow,
          "in-out-type": _vm.inOutType,
          "shou-fa-type": _vm.shouFaType,
          "supplier-ids": _vm.supplierIds,
          "purchase-type": _vm.purchaseType,
        },
        on: { close: _vm.closeUnit, change: _vm.fetchUnit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }