"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _activityReport = require("@/api/marketingStatic/activityReport");
var _activityFlow = require("@/api/marketingManage/activityFlow");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _index = _interopRequireDefault(require("@/views/terminalManagement/auditLog/channelSelectDialog/index"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingStaticactivityReportprizeRewardlogoRewardindex'; // 缓存名字为当前path去掉/
var _default = {
  name: 'MarketingStaticactivityReportprizeRewardlogoRewardindex',
  components: {
    Pagination: _Pagination.default,
    ChannelSelectDialog: _index.default
  },
  data: function data() {
    return {
      activeList: [],
      terminalName: '',
      formInline: {
        prizeName: null,
        // 奖品名称
        prizeType: null,
        //	奖品类型, 100红包, 200实物, 999返利核销
        receiveUserType: null,
        // 接收人身份,1消费者,2终端,3大区负责人,4策划部负责人
        scanType: null,
        // 扫码类型, 1盖外码,2盒码,3箱码
        schemeCode: null,
        // 方案名称
        sendEndTime: null,
        // 结束时间
        sendStartTime: null,
        // 开始时间
        seqNo: null,
        // 流水号
        zdCode: null // 终端编码
      },

      rangeTime: '',
      params: {
        pageNo: 1,
        pageSize: 10
      },
      tableData: [],
      total: 0,
      listLoading: false,
      tabHeight: '100%',
      downLoading: false
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
    that.getactivelist();
  },
  activated: function activated() {
    var hasCache1 = this.tableData.length > 0;
    if (this.$route.params.schemeCode) {
      this.formInline.schemeCode = this.$route.params.schemeCode;
      this.formInline.prizeName = this.$route.params.prizeName;
      this.formInline.sendStartTime = this.$route.params.reportStartTime;
      this.formInline.sendEndTime = this.$route.params.reportEndTime;
      this.rangeTime = [this.$route.params.reportStartTime, this.$route.params.reportEndTime];
    }
    if (this.formInline.prizeName || !hasCache1) {
      this.prizeSendRecord();
    }
  },
  deactivated: function deactivated() {
    var name = cachName;
    var cache = this.getCaches(this, name);
    if (!cache) {
      this.tableData = [];
      this.total = 0;
    }
  },
  methods: {
    // 选择终端
    choseTerminal: function choseTerminal() {
      this.$refs.channelSelect.proSelVisible = true;
    },
    selectTerminal: function selectTerminal(val) {
      this.formInline.zdCode = val.channelCode;
      this.terminalName = val.channelName;
    },
    getactivelist: function getactivelist() {
      var that = this;
      (0, _activityFlow.getwayList)({
        pageSize: 100,
        pageNo: 1
      }).then(function (response) {
        console.log(response.data);
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          that.activeList = response.data.data.records || [];
        }
      });
    },
    // 获取错误数码兼容列表
    prizeSendRecord: function prizeSendRecord() {
      var that = this;
      that.listLoading = true;
      (0, _activityReport.prizeSendRecord)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.params), this.formInline)).then(function (res) {
        if (res.data.data.errCode) {
          that.$message.error(res.data.data.errMsg);
          return;
        } else {
          if (res.data.data != null) {
            that.total = parseInt(res.data.data.total);
            that.tableData = res.data.data.records;
            setTimeout(function () {
              that.listLoading = false;
            }, 1 * 800);
          } else {
            that.total = 0;
            that.tableData = [];
            that.listLoading = false;
          }
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    sj: function sj(res) {
      if (res) {
        this.formInline.sendStartTime = res[0];
        this.formInline.sendEndTime = res[1];
      } else {
        this.formInline.sendStartTime = '';
        this.formInline.sendEndTime = '';
      }
    },
    // 搜索按钮
    onSearch: function onSearch() {
      this.params.pageNo = 1;
      this.prizeSendRecord();
    },
    // 搜索重置
    onReset: function onReset(formName) {
      this.rangeTime = '';
      this.terminalName = '';
      this.params.pageNo = 1;
      this.formInline = {
        prizeName: null,
        // 奖品名称
        prizeType: null,
        //	奖品类型, 100红包, 200实物, 999返利核销
        receiveUserType: null,
        // 接收人身份,1消费者,2终端,3大区负责人,4策划部负责人
        scanType: null,
        // 扫码类型, 1盖外码,2盒码,3箱码
        schemeCode: null,
        // 方案名称
        sendEndTime: null,
        // 结束时间
        sendStartTime: null,
        // 开始时间
        seqNo: null,
        // 流水号
        zdCode: null
      };
      this.$refs[formName].resetFields();
      this.prizeSendRecord();
    },
    // 导出
    download: function download() {
      var _this = this;
      this.downLoading = true;
      var submitData = (0, _utils.filterKeyNull)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.params), this.formInline));
      // console.log(submitData);
      submitData = JSON.parse(JSON.stringify(submitData));
      (0, _activityReport.exportPrizeSend)(submitData).then(function (res) {
        _this.downLoading = false;
        if (res.status != 200) {
          _this.$message.error(res.data.msg);
        } else {
          var blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel;charset=utf-8'
          });
          var downloadElement = document.createElement('a');
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = '奖品发放日志.xlsx';
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
      }).catch(function () {
        _this.downLoading = false;
      });
    }
  }
};
exports.default = _default;