var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-img" }, [
    _c(
      "div",
      {
        staticClass: "background",
        style: "background-image:url('" + _vm.list.background + "');",
      },
      [
        _c("el-image", {
          staticClass: "title",
          attrs: { lazy: "", src: _vm.list.title },
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "cards" },
          [
            _c(
              "el-row",
              { attrs: { gutter: 20 } },
              _vm._l(_vm.list.cards, function (item, index) {
                return _c(
                  "el-col",
                  { key: index, attrs: { span: 8 } },
                  [
                    _c("el-image", {
                      staticClass: "card",
                      attrs: { lazy: "", src: item.backImg },
                    }),
                  ],
                  1
                )
              }),
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }