"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteById = deleteById;
exports.flagTemplatedeleteById = flagTemplatedeleteById;
exports.labelList = labelList;
exports.modelList = modelList;
exports.queryPackLevelByMark = queryPackLevelByMark;
exports.queryPackLevelByMarkV2 = queryPackLevelByMarkV2;
exports.saveFlagGroup = saveFlagGroup;
exports.saveFlagTemplate = saveFlagTemplate;
exports.updateFlagName = updateFlagName;
exports.updateFlagTemplate = updateFlagTemplate;
var _request = _interopRequireDefault(require("@/utils/request"));
/**
 * 加载所有标识组
 */
function labelList() {
  return (0, _request.default)({
    url: '/api-mark/flagGroup/queryForList',
    method: 'get'
  });
}
function saveFlagGroup(params) {
  return (0, _request.default)({
    url: '/api-mark/flagGroup/saveFlagGroup',
    method: 'post',
    params: params
  });
}
function updateFlagName(params) {
  return (0, _request.default)({
    url: '/api-mark/flagGroup/updateFlagName',
    method: 'put',
    params: params
  });
}
function deleteById(data) {
  return (0, _request.default)({
    url: '/api-mark/flagGroup/deleteById/' + data,
    method: 'delete'
  });
}
function modelList(params) {
  return (0, _request.default)({
    url: '/api-mark/flagTemplate/queryForPage',
    method: 'get',
    params: params
  });
}
function saveFlagTemplate(data) {
  return (0, _request.default)({
    url: '/api-mark/flagTemplate/saveFlagTemplate',
    method: 'post',
    data: data
  });
}
function updateFlagTemplate(data) {
  return (0, _request.default)({
    url: '/api-mark/flagTemplate/updateFlagTemplate',
    method: 'put',
    data: data
  });
}
function flagTemplatedeleteById(data) {
  return (0, _request.default)({
    url: '/api-mark/flagTemplate/deleteById/' + data,
    method: 'delete'
  });
}

// 根据码类型动态获取包装层级
function queryPackLevelByMark(params) {
  return (0, _request.default)({
    url: '/api-mark/flagTemplate/queryPackLevelByMark',
    method: 'get',
    params: params
  });
}
function queryPackLevelByMarkV2(params) {
  return (0, _request.default)({
    url: '/api-mark/flagTemplate/queryPackLevelByMarkV2',
    method: 'get',
    params: params
  });
}