import { render, staticRenderFns } from "./relatUnlockPrize.vue?vue&type=template&id=225eb502&scoped=true&"
import script from "./relatUnlockPrize.vue?vue&type=script&lang=js&"
export * from "./relatUnlockPrize.vue?vue&type=script&lang=js&"
import style0 from "./relatUnlockPrize.vue?vue&type=style&index=0&id=225eb502&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "225eb502",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('225eb502')) {
      api.createRecord('225eb502', component.options)
    } else {
      api.reload('225eb502', component.options)
    }
    module.hot.accept("./relatUnlockPrize.vue?vue&type=template&id=225eb502&scoped=true&", function () {
      api.rerender('225eb502', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/terminalManagement/marketingActivities/activityManage/components/relatUnlockPrize.vue"
export default component.exports