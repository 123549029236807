"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _massSend = require("@/api/wxhyyy/massSend.js");
var _wxHy = require("@/api/wxhyyy/wxHy");
var _customMenu = require("@/api/wxhyyy/customMenu");
var _admincut = _interopRequireDefault(require("@/components/admincut"));
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable no-unused-vars */

var baseUrl = '/wh';
var _default = {
  components: {
    admincut: _admincut.default
  },
  filters: {
    filterTime: function filterTime(value) {
      if (!value) return '';
      if (value.length > 0) {
        var time = new Date(value);
        var y = time.getFullYear();
        var m = time.getMonth() + 1 > 10 ? time.getMonth() + 1 : '0' + (time.getMonth() + 1);
        var d = time.getDate() > 9 ? time.getDate() : '0' + time.getDate();
        var h = time.getHours();
        var i = time.getMinutes() > 9 ? time.getMinutes() : '0' + time.getMinutes();
        var s = time.getSeconds() > 9 ? time.getSeconds() : '0' + time.getSeconds();
        return y + ':' + m + ':' + d + ' ' + h + ':' + i + ':' + s;
      }
      return value;
    }
  },
  data: function data() {
    return {
      viplist: '',
      // 公众号切换
      myHeaders: {
        Authorization: 'Bearer ' + (0, _auth.getToken)(),
        appId: sessionStorage.getItem('appId')
      },
      value: '',
      newsIds: [],
      // 图文id列表  如果选的图文，必填
      mediaType: '1',
      // 1图文，2图片，3视频，4音频
      mediaId: '',
      // 素材MEDIA_ID 如果选的图片或者视频，音频 必填
      sendType: '',
      // 1.立即发送，2定时发送
      sendTime: '',
      // 定时发送时间
      sendfundis: false,
      // 表单
      sendObj: {
        // 标签
        bq: '',
        bqList: [],
        // 来源
        ly: '',
        lyList: [],
        // 关注时间
        gzsj: '',
        gzsjNum: '',
        // 互动频次
        hdpc: '',
        // 互动时间
        hdsj: '',
        // 是否会员
        sfshy: '',
        hyidList: [],
        hdsjNum: '',
        // 性别
        sex: ''
      },
      gzMiniTime: '',
      gzMaxTime: '',
      // 标签数据
      labelOptions: [],
      // 选择的标签
      labelOption: [],
      // 来源数据
      sourceOptions: [],
      // 选中的来源
      sourceOption: [],
      options: [],
      // 关注时间
      gzTimeList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      // 上传文件列表
      fileList: [],
      // 定时发送时间限制
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < new Date().getTime();
        }
      },
      // 类型弹窗
      dialogVisible: false,
      // 类型标题
      dialogTitle: '',
      // 来源弹窗
      sourceDialog: false,
      // 标签弹窗
      labelDialog: false,
      // 获取图文
      listQuery1: {
        keyWord: '',
        pageNo: 1,
        pageSize: 12
      },
      // 获取图片视频音频
      listQuery2: {
        mediaType: '',
        pageNo: 1,
        pageSize: 12
      },
      baseUrl: '',
      total: 0,
      twData: [],
      mediaData: [],
      isSelected: 'a',
      itemData: '',
      msgData: [],
      picData: '',
      voiceData: '',
      videoData: '',
      // posturl: baseUrl + '/api-memberscrm/member/admin/file/upload/single',
      posturl: process.env.VUE_APP_BASE_API + '/api-memberscrm/member/admin/file/upload/single',
      sendnum: '',
      remainnum: ''
    };
  },
  beforeCreate: function beforeCreate() {
    var _this = this;
    if (this.$route.query.upId) {
      (0, _massSend.selectSendById)({
        id: this.$route.query.upId,
        appId: sessionStorage.getItem('appId')
      }).then(function (resp) {
        resp = resp.data;
        if (resp.code === 200) {
          if (resp.data) {
            resp.data.url = resp.data.filesDO.url;
            _this.mediaType = resp.data.mediaType;
            if (resp.data.mediaType === '1') {
              _this.msgData.push(resp.data);
              console.log(_this.msgData);
            } else if (resp.data.mediaType === '2') {
              _this.picData = resp.data;
            } else if (resp.data.mediaType === '3') {
              _this.voiceData = resp.data;
            } else if (resp.data.mediaType === '4') {
              _this.videoData = resp.data;
            }
          }
        }
        console.log(resp);
      });
    }
    // newsById({ id: 162 }).then(resp => {
    //   console.log(resp)
    // })
  },
  created: function created() {
    var _this2 = this;
    this.baseUrl = _wxHy.base;
    if (this.$route.query.ids) {
      var ids = JSON.parse(sessionStorage.getItem('id'));
      this.newsIds = ids;
      ids.appId = sessionStorage.getItem('appId');
      (0, _massSend.selectNewsList)(ids).then(function (resp) {
        resp = resp.data;
        if (resp.code === 200) {
          _this2.msgData = resp.data;
          console.log(_this2.msgData);
        }
      });
    }
    (0, _wxHy.searchSelectBySends)().then(function (res) {
      res = res.data;
      console.log(res);
      if (res.code === 200) {
        _this2.viplist = res.data || [];
      } else {
        _this2.$message(res.msg);
      }
    });
    this.sendObj.bq = '0';
    this.sendObj.ly = '0';
    this.sendObj.gzsj = '0';
    this.sendObj.hdpc = '1';
    this.sendObj.hdsj = '1';
    this.sendObj.sex = '0';
    this.sendObj.sfshy = '0';
    this.sendType = '1';
    console.log(localStorage.getItem('cid'));
  },
  mounted: function mounted() {
    var _this3 = this;
    (0, _massSend.getAllTag)({
      appId: sessionStorage.getItem('appId')
    }).then(function (resp) {
      resp = resp.data;
      var _resp = resp,
        code = _resp.code,
        data = _resp.data,
        msg = _resp.msg;
      if (code === 200) {
        if (data) {
          _this3.labelOptions = data;
        }
      } else {
        _this3.$message(msg);
      }
    });
    (0, _massSend.getUserCode)({
      codeName: '',
      pageNo: 1,
      pageSize: 100,
      appId: sessionStorage.getItem('appId')
    }).then(function (resp) {
      resp = resp.data;
      var _resp2 = resp,
        code = _resp2.code,
        data = _resp2.data,
        msg = _resp2.msg;
      if (code === 200) {
        if (data) {
          _this3.sourceOptions = data.records;
        }
      } else {
        _this3.$message(msg);
      }
    });
    (0, _massSend.sendForNumber)({
      appId: sessionStorage.getItem('appId')
    }).then(function (res) {
      res = res.data;
      var _res = res,
        code = _res.code,
        data = _res.data,
        msg = _res.msg;
      if (code === 200) {
        _this3.sendnum = data;
        _this3.remainnum = 4 - data;
      } else {
        _this3.$message(msg);
      }
    });
  },
  methods: {
    // 群发类型
    tabClick: function tabClick(event) {
      this.mediaType = event.name;
    },
    // 从资源库选择
    materialFun: function materialFun(type) {
      this.dialogVisible = true;
      this.isSelected = 'a';
      this.listQuery2.pageNo = 1;
      this.itemData = '';
      if (type === 1) {
        this.dialogTitle = '选择图文信息';
        this.getList();
        this.listQuery1.pageNo = 1;
      } else if (type === 2) {
        this.listQuery2.mediaType = 3;
        this.dialogTitle = '选择图片';
        this.getList2();
      } else if (type === 3) {
        this.listQuery2.mediaType = 2;
        this.dialogTitle = '选择语音';
        this.getList2();
      } else if (type === 4) {
        this.listQuery2.mediaType = 1;
        this.dialogTitle = '选择视频';
        this.getList2();
      }
    },
    // 弹窗关闭与确定
    dialogVisibleFun: function dialogVisibleFun(val) {
      this.dialogVisible = false;
      this.isSelected = 'a';
      if (val === 'no') {
        this.itemData = '';
      } else if (val === 'yes') {
        if (this.mediaType === '1') {
          this.msgData = [this.itemData];
          this.newsIds = [this.msgData[0].id];
          this.picData = '';
          this.voiceData = '';
          this.videoData = '';
        } else if (this.mediaType === '2') {
          this.picData = this.itemData;
          this.mediaId = this.picData.mediaId;
          this.msgData = '';
          this.voiceData = '';
          this.videoData = '';
        } else if (this.mediaType === '3') {
          this.voiceData = this.itemData;
          this.mediaId = this.voiceData.mediaId;
          this.msgData = '';
          this.videoData = '';
          this.picData = '';
        } else if (this.mediaType === '4') {
          this.videoData = this.itemData;
          this.mediaId = this.videoData.mediaId;
          this.msgData = '';
          this.voiceData = '';
          this.picData = '';
        }
      }
    },
    // 选中
    selectItem: function selectItem(item, index) {
      console.log(item);
      this.isSelected = index;
      this.itemData = item;
    },
    // 删除
    deleteItem: function deleteItem(val) {
      if (val === 1) {
        this.msgData = [];
      } else if (val === 2) {
        this.picData = '';
      } else if (val === 3) {
        this.voiceData = '';
      } else if (val === 4) {
        this.videoData = '';
      }
    },
    // 标签选择
    labelFun: function labelFun(val) {
      if (val === '1') {
        this.sendObj.bq = '1';
        this.labelDialog = true;
      } else {
        this.sendObj.bqList = [];
        this.labelOption = [];
      }
    },
    labelCheckFun: function labelCheckFun(val) {
      var _this4 = this;
      var arr = [];
      val.map(function (key, index) {
        _this4.labelOptions.map(function (item) {
          if (key === item.tagName) {
            arr.push(item.tagId);
          }
        });
      });
      this.sendObj.bqList = arr;
    },
    // 来源选择
    checkboxFun: function checkboxFun(val) {
      var _this5 = this;
      var arr = [];
      val.map(function (key, index) {
        _this5.sourceOptions.map(function (item) {
          console.log(item);
          if (key === item.codeName) {
            arr.push(item.id);
          }
        });
      });
      this.sendObj.lyList = arr;
    },
    sourceFun: function sourceFun(val) {
      if (val === '1') {
        this.sendObj.ly = '1';
        this.sourceDialog = true;
      } else {
        this.sendObj.lyList = [];
        this.sourceOption = [];
      }
    },
    // 关注时间
    zgTimeFun: function zgTimeFun(val) {
      if (val === '0') {
        this.gzMiniTime = '';
        this.gzMaxTime = '';
      } else if (val === '1') {
        this.gzMaxTime = '';
      } else if (val === '2') {
        this.gzMiniTime = '';
      }
    },
    // 互动时间
    hdTimeFun: function hdTimeFun(val) {
      if (val !== '7') {
        this.sendObj.hdsjNum = '';
      }
    },
    sendTypeFun: function sendTypeFun(val) {
      if (val === '1') {
        this.sendTime = '';
      }
    },
    changemin: function changemin(e) {
      var that = this;
      that.$message.closeAll();
      var reg = /^[0-9]+$/gi;
      if (reg.test(e) && e > 0) {
        that.sendObj.gzsjNum = e;
      } else {
        that.$message.info('请输入正确时间');
      }
      // console.log(a)
    },
    changemax: function changemax(e) {
      var that = this;
      that.$message.closeAll();
      var reg = /^[0-9]+$/gi;
      if (reg.test(e) && e > 0) {
        that.sendObj.gzsjNum = e;
      } else {
        that.$message.info('请输入正确时间');
      }
    },
    miniTime: function miniTime(val) {
      this.sendObj.gzsjNum = val;
    },
    maxTime: function maxTime(val) {
      this.sendObj.gzsjNum = val;
    },
    handleInput: function handleInput(e) {
      var reg = /^[0-9]+$/;
      if (reg.test(e.target.value)) {
        this.sendObj.hdsjNum = e.target.value;
      } else if (e.target.value.length < this.sendObj.hdsjNum.length) {
        this.sendObj.hdsjNum = e.target.value;
      }
      this.$refs.inputss.value = this.sendObj.hdsjNum;
    },
    // 关闭标签来源弹窗
    closedDialog: function closedDialog(name, type) {
      if (type === 1) {
        if (name === 'no') {
          this.sendObj.bqList = [];
          this.labelOption = [];
          this.labelDialog = false;
          this.sendObj.bq = '0';
        } else if (name === 'yes') {
          if (this.sendObj.bqList.length > 0) {
            this.labelDialog = false;
          } else {
            this.$message('请选择');
          }
        }
      } else if (type === 2) {
        if (name === 'no') {
          this.sendObj.lyList = [];
          this.sourceOption = [];
          this.sourceDialog = false;
          this.sendObj.ly = '0';
        } else if (name === 'yes') {
          if (this.sendObj.lyList.length > 0) {
            this.sourceDialog = false;
          } else {
            this.$message('请选择');
          }
        }
      }
    },
    // 上传成功
    uploadSuccess: function uploadSuccess(file) {
      if (this.mediaType === '2') {
        this.picData = file.data;
        this.mediaId = file.data.id;
      } else if (this.mediaType === '3') {
        this.voiceData = file.data;
        this.mediaId = file.data.id;
      } else if (this.mediaType === '4') {
        this.videoData = file.data;
        this.mediaId = file.data.id;
      }
    },
    // 上传失败
    uploadError: function uploadError() {
      this.$message.error('上传失败');
    },
    // 上传前判断
    beforeUpload: function beforeUpload(file) {
      if (this.mediaType === '2') {
        var isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          this.$message.closeAll();
          this.$message.error('上传图片大小不能超过 2MB!');
        }
        return isLt2M;
      } else if (this.mediaType === '3') {
        var _isLt2M = file.size / 1024 / 1024 < 2;
        if (!_isLt2M) {
          this.$message.closeAll();
          this.$message.error('上传语音大小不能超过 2MB!');
        }
        return _isLt2M;
      } else if (this.mediaType === '4') {
        var _isLt2M2 = file.size / 1024 / 1024 < 10;
        if (!_isLt2M2) {
          this.$message.closeAll();
          this.$message.error('上传语音大小不能超过 10MB!');
        }
        return _isLt2M2;
      }
    },
    // 翻页
    currentChange: function currentChange(val) {
      this.listQuery1.pageNo = val;
      this.getList();
    },
    // 获取图文列表
    getList: function getList() {
      var _this6 = this;
      this.listQuery1.appId = sessionStorage.getItem('appId');
      (0, _customMenu.getTxt)(this.listQuery1).then(function (res) {
        res = res.data;
        if (res.code === 200) {
          _this6.twData = res.data.records;
          _this6.total = parseInt(res.data.total);
        }
      });
    },
    currentChange1: function currentChange1(val) {
      this.listQuery2.pageNo = val;
      this.getList2();
    },
    // 获取图片，音频，视频列表
    getList2: function getList2() {
      var _this7 = this;
      this.listQuery2.appId = sessionStorage.getItem('appId');
      (0, _customMenu.getMedia)(this.listQuery2).then(function (res) {
        console.log(res);
        res = res.data;
        if (res.code === 200) {
          _this7.mediaData = res.data.records;
          _this7.total = parseInt(res.data.total);
        }
      });
    },
    // 立刻发送
    sendFun: function sendFun(val) {
      var _this8 = this;
      this.status = val;
      var newsIds = this.newsIds,
        mediaType = this.mediaType,
        mediaId = this.mediaId,
        sendType = this.sendType,
        sendTime = this.sendTime,
        sendObj = this.sendObj,
        status = this.status;
      if ((mediaType === '1' && newsIds.length !== 0 || mediaType !== '1' && mediaId !== '') && (sendObj.bq !== '0' && sendObj.bqList.length > 0 || sendObj.bq === '0') && (sendObj.ly !== '0' && sendObj.lyList.length > 0 || sendObj.ly === '0') && (sendObj.gzsj > '0' && sendObj.gzsjNum > 0 || sendObj.gzsj === '0') && sendObj.hdpc !== '' && (sendObj.hdsj !== '' && sendObj.hdsj < 7 || sendObj.hdsj === '7' && sendObj.hdsjNum !== '') && (sendObj.sfshy === '0' || sendObj.sfshy === '1' || sendObj.sfshy === '2' && sendObj.hyidList.length > 0) && sendObj.sex !== '' && (sendType !== '1' && sendTime !== '' || sendType === '1')) {
        if (this.status === '1') {
          this.$confirm('消息群发后将无法撤销，是否确认群发？', '群发确认', {
            confirmButtonText: '确定群发',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {
            _this8.sendfundis = true;
            (0, _massSend.createSend)({
              newsIds: newsIds,
              mediaType: mediaType,
              mediaId: mediaId,
              sendType: sendType,
              sendTime: sendTime,
              status: status,
              sendObj: JSON.stringify(sendObj)
            }).then(function (resp) {
              var that = _this8;
              resp = resp.data;
              console.log(resp);
              if (resp.code === 200) {
                that.$message({
                  type: 'success',
                  message: '群发成功!'
                  // duration: 1000,
                  // onClose: () => {
                  //   this.$router.go(-1)
                  // }
                });

                that.$router.push({
                  name: 'wxAccountswxgzhwxManagemassglindex',
                  query: {}
                });
              } else {
                that.$message.error(resp.msg);
              }
              that.sendfundis = false;
            });
            // }
          }).catch(function () {
            _this8.$message({
              type: 'info',
              message: '已取消群发'
            });
          });
        } else {
          this.sendfundis = true;
          this.$confirm('是否确定消息保存暂不发送？', '保存群发确认', {
            confirmButtonText: '确定保存',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {
            _this8.sendfundis = false;
            if (_this8.$route.query.upId) {
              (0, _massSend.createSend)({
                // id: this.$route.query.id,
                // id: this.$route.query.upId,
                newsIds: newsIds,
                mediaType: mediaType,
                mediaId: mediaId,
                sendType: sendType,
                sendTime: sendTime,
                status: status,
                sendObj: JSON.stringify(sendObj)
              }).then(function (resp) {
                resp = resp.data;
                var that = _this8;
                if (resp.code === 200) {
                  that.$router.push({
                    name: 'wxAccountswxgzhwxManagemassglindex',
                    query: {}
                  });
                  that.$message({
                    type: 'success',
                    message: '保存成功!'
                  });
                }
              });
            } else {
              (0, _massSend.createSend)({
                newsIds: newsIds,
                mediaType: mediaType,
                mediaId: mediaId,
                sendType: sendType,
                sendTime: sendTime,
                status: status,
                sendObj: JSON.stringify(sendObj),
                appId: sessionStorage.getItem('appId')
              }).then(function (resp) {
                resp = resp.data;
                console.log(resp);
                if (resp.code === 200) {
                  var that = _this8;
                  that.$message({
                    type: 'success',
                    message: '保存成功!'
                    // duration: 1000,
                    // onClose: () => {
                    //   that.$router.go(-1)
                    // }
                  });
                  // that.$router.go(-1)
                  // that.$router.push({
                  //   name: 'massgl'
                  // })
                  // this.$router.go(-1)
                  that.$router.push({
                    name: 'wxAccountswxgzhwxManagemassglindex',
                    query: {}
                  });
                } else {
                  that.$message.error(resp.msg);
                }
                that.sendfundis = false;
              });
            }
          }).catch(function () {
            _this8.$message({
              type: 'info',
              message: '已取消群发'
            });
            _this8.sendfundis = false;
          });
        }
      } else {
        this.$message.closeAll();
        if (mediaType === '1' && newsIds.length === 0) {
          this.$message.error('图文信息为空');
          return;
        }
        if (mediaType === '2' && mediaId === '') {
          this.$message.error('图片信息为空');
          return;
        }
        if (mediaType === '3' && mediaId === '') {
          this.$message.error('语言信息为空');
          return;
        }
        if (mediaType === '4' && mediaId === '') {
          this.$message.error('视频信息为空');
          return;
        }
        if (sendObj.bq === '' || sendObj.bq !== '0' && sendObj.bqList.length === 0) {
          this.$message.error('请选择用户标签');
          return;
        }
        if (sendObj.ly === '' || sendObj.ly !== '0' && sendObj.lyList.length === 0) {
          this.$message.error('请选择用户来源');
          return;
        }
        if (sendObj.gzsj === '' || sendObj.gzsj > 0 && sendObj.gzsjNum === '') {
          this.$message.error('请定义关注时间');
          return;
        }
        if (sendObj.hdpc === '') {
          this.$message.error('请选择互动频次');
          return;
        }
        if (sendObj.sfshy === '' || sendObj.sfshy === '2' && sendObj.hyidList.length === 0) {
          this.$message.error('请选择是否会员');
          return;
        }
        if (sendObj.hdsj === '' || sendObj.hdsj === '7' && sendObj.hdsjNum === '') {
          this.$message.error('自定义互动时间为空');
          return;
        }
        if (sendObj.sex === '') {
          this.$message.error('请选择性别');
          return;
        }
        if (sendType === '' || sendType !== '1' && sendTime === '') {
          this.$message.error('请选择发送时间');
          return;
        }
      }
    },
    // 取消
    cancelFun: function cancelFun() {
      var _this9 = this;
      this.$confirm('取消后将无法撤销，你的操作将被删除，是否确认取消？', '取消操作', {
        confirmButtonText: '确定取消',
        cancelButtonText: '关闭操作',
        type: 'warning'
      }).then(function () {
        _this9.$message({
          type: 'success',
          message: '取消成功!'
        });
        _this9.$router.go(-1);
      }).catch(function () {
        _this9.$message({
          type: 'info',
          message: '已取消操作'
        });
      });
    }
  }
};
exports.default = _default;