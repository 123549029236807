"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addPolicyMange = addPolicyMange;
exports.delPolicyMange = delPolicyMange;
exports.getActInfoByPolicy = getActInfoByPolicy;
exports.getBlackList = getBlackList;
exports.getDebateParam = getDebateParam;
exports.getFlHdList = getFlHdList;
exports.getFlNdList = getFlNdList;
exports.getFlZcList = getFlZcList;
exports.getOrgRanges = getOrgRanges;
exports.getPolicyMangeDetail = getPolicyMangeDetail;
exports.getQdDJ = getQdDJ;
exports.getQdFl = getQdFl;
exports.getRebateCompy = getRebateCompy;
exports.getRebateDetailSchemeConfig = getRebateDetailSchemeConfig;
exports.getRebateRangeById = getRebateRangeById;
exports.getRebateSchemeById = getRebateSchemeById;
exports.getRebateSchemeConfig = getRebateSchemeConfig;
exports.getRebateSchemeProBySchemeId = getRebateSchemeProBySchemeId;
exports.getRebateSchemes = getRebateSchemes;
exports.getRebateTypes = getRebateTypes;
exports.getSalesCompy = getSalesCompy;
exports.passFlSh = passFlSh;
exports.rejectFlSh = rejectFlSh;
exports.saveBlackList = saveBlackList;
exports.saveDebateParam = saveDebateParam;
exports.saveHdFlData = saveHdFlData;
exports.setRebateType = setRebateType;
exports.stopHdList = stopHdList;
exports.upLoadFile = upLoadFile;
exports.updateBlack = updateBlack;
exports.updatePolicyMange = updatePolicyMange;
var _request = _interopRequireDefault(require("@/utils/request"));
/**
 * 获取参数
 * **/

function getDebateParam() {
  return (0, _request.default)({
    url: '/api-rebate/pzRebateAllocation/getRebateAllocation',
    method: 'get'
  });
}

/***
* 保存参数
**/
function saveDebateParam(data) {
  return (0, _request.default)({
    url: '/api-rebate/pzRebateAllocation/conservationRebateAllocation',
    method: 'post',
    data: data
  });
}

/****
* 获取返利活动列表
**/
function getFlHdList(current, size, data) {
  return (0, _request.default)({
    url: '/api-rebate/pzRebateScheme/getMonthRebateScheme?current=' + current + '&size=' + size,
    method: 'post',
    data: data
  });
}

/**
 * 获取返利年度列表
 * **/

function getFlNdList(current, size, data) {
  return (0, _request.default)({
    url: '/api-rebate/pzRebateScheme/getYearRebateScheme?current=' + current + '&size=' + size,
    method: 'post',
    data: data
  });
}

/***
*根据pid获取活动方案详情
**/
function getRebateSchemeById(param) {
  return (0, _request.default)({
    url: '/api-rebate/pzRebateScheme/getRebateSchemeById',
    method: 'get',
    params: param
  });
}

/***
* 获取返利活动产品
**/
function getRebateSchemeProBySchemeId(param) {
  return (0, _request.default)({
    url: '/api-rebate/pzRebateSchemePro/getRebateSchemeProBySchemeId',
    method: 'get',
    params: param
  });
}

/**
 * 获取返利范围
 * **/
function getRebateRangeById(param) {
  return (0, _request.default)({
    url: '/api-rebate/pzRebateSchemeOrg/getRebateSchemeOrgBySchemeId',
    method: 'get',
    params: param
  });
}

/**
 * 获取返利方案配置详情
**/
function getRebateSchemeConfig(param) {
  return (0, _request.default)({
    url: '/api-rebate/pzRebateSchemeConfig/getRebateSchemeConfigForDetail',
    method: 'get',
    params: param
  });
}

/**
 * 获取黑名单
**/

function getBlackList(param) {
  return (0, _request.default)({
    url: '/api-rebate/pzRebateBlacklist/getRebateBlacklist',
    method: 'get',
    params: param
  });
}

/**
 * 保存黑名单
 * **/
function saveBlackList(data) {
  return (0, _request.default)({
    url: '/api-rebate/pzRebateBlacklist/conservationRebateBlacklist',
    method: 'post',
    data: data
  });
}

/**
* 编辑黑名单
* **/

function updateBlack(param) {
  return (0, _request.default)({
    url: '/api-rebate/pzRebateBlacklist/getRebateBlacklist',
    method: 'get',
    params: param
  });
}

/**
 *  活动返利批量终止
 * **/

function stopHdList(param) {
  return (0, _request.default)({
    url: '/api-rebate/pzRebateScheme/stop',
    method: 'put',
    params: param
  });
}

/***
* 获取渠道分类
* ***/

function getQdFl(param) {
  return (0, _request.default)({
    url: '/api-basic/jcChannelClass/getList',
    method: 'post',
    params: param
  });
}

/***
* 获取渠道等级
* ***/

function getQdDJ(param) {
  return (0, _request.default)({
    url: '/api-basic/jcChannelGrade/getList',
    method: 'post',
    params: param
  });
}

/**
* 返利政策
* **/

function getFlZcList(param) {
  return (0, _request.default)({
    url: '/api-rebate/zcRebatePolicy/pageList',
    method: 'post',
    params: param
  });
}

/***
* 保存返利活动数据
**/

function saveHdFlData(data) {
  return (0, _request.default)({
    url: '/api-rebate/pzRebateScheme/saveSubmissionRebateScheme',
    method: 'post',
    data: data
  });
}

/***
* 审核通过
**/

function passFlSh(param) {
  return (0, _request.default)({
    url: '/api-rebate/pzRebateScheme/auditApproved',
    method: 'put',
    params: param
  });
}

/***
* 审核拒绝
**/

function rejectFlSh(param) {
  return (0, _request.default)({
    url: '/api-rebate/pzRebateScheme/refusalToReview',
    method: 'put',
    params: param
  });
}

/**
 *  获取全部的销售公司
 * **/
function getSalesCompy(data) {
  return (0, _request.default)({
    url: '/api-basic/orgInfo/getXgOrgInfo',
    method: 'post',
    data: data
  });
}

/****
  获取返利对象
***/
function getRebateCompy(param) {
  return (0, _request.default)({
    url: '/api-rebate/pzRebateAllocationItem/getCashRebate',
    method: 'get',
    params: param
  });
}

/****
 获取活动信息根据政策编号
***/
function getActInfoByPolicy(data) {
  return (0, _request.default)({
    url: '/api-uspyx/actconfig/getActInfoByPolicy',
    method: 'post',
    data: data
  });
}

/****
 获取返利查看列表
***/
function getRebateSchemes(current, size, data) {
  return (0, _request.default)({
    url: '/api-rebate/pzRebateScheme/getRebateScheme?current=' + current + '&size=' + size,
    method: 'post',
    data: data
  });
}

/***
  政策管理类型设置
***/
function setRebateType(param) {
  return (0, _request.default)({
    url: '/api-rebate/zcRebatePolicy/setPolicyType',
    method: 'post',
    params: param
  });
}

//获取配置渠道类型
function getRebateTypes() {
  return (0, _request.default)({
    url: '/api-rebate/pzRebateSchemeConfig/getChannelType',
    method: 'get'
  });
}

//根据id获取组织机构范围
function getOrgRanges(param) {
  return (0, _request.default)({
    url: '/api-rebate/pzRebateSchemeOrg/getRebateSchemeOrgTreeBySchemeId',
    method: 'get',
    params: param
  });
}

/****详情编辑****/
function getRebateDetailSchemeConfig(param) {
  return (0, _request.default)({
    url: '/api-rebate/pzRebateSchemeConfig/getRebateSchemeConfigForEdit',
    method: 'get',
    params: param
  });
}

//文件上传
function upLoadFile(data) {
  return (0, _request.default)({
    url: '/api-basic/jc/file/upload/uploadFile',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    method: 'post',
    data: data
  });
}

//获取政策管理详情
function getPolicyMangeDetail(param) {
  return (0, _request.default)({
    url: '/api-rebate/zcRebatePolicy/detail',
    method: 'post',
    params: param
  });
}

//添加政策管理
function addPolicyMange(data) {
  return (0, _request.default)({
    url: '/api-rebate/zcRebatePolicy/add',
    method: 'post',
    data: data
  });
}

//修改政策管理
function updatePolicyMange(data) {
  return (0, _request.default)({
    url: '/api-rebate/zcRebatePolicy/update',
    method: 'post',
    data: data
  });
}

//删除政策管理
function delPolicyMange(param) {
  return (0, _request.default)({
    url: '/api-rebate/zcRebatePolicy/delete',
    method: 'post',
    params: param
  });
}