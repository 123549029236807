"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _log = require("@/api/log/log");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManageoperationalToolsresetCaptchaindex'; // 缓存名字为当前path去掉/
var _default = {
  name: 'MarketingManageoperationalToolsresetCaptchaindex',
  data: function data() {
    return {
      tableData: [],
      listLoading: false,
      rangeTime: '',
      queryParams: {
        markCode: null
      },
      formReset: {
        validCode: null
      },
      rules: {
        validCode: [{
          required: true,
          message: '请输入验证码',
          trigger: 'blur'
        }, {
          min: 4,
          max: 4,
          message: '验证码长度必须为四位',
          trigger: 'blur'
        }]
      },
      dialogVisible: false
    };
  },
  activated: function activated() {
    // console.log(this.queryParams);
    // var hasCache1 = this.tableData.length > 0 ? true : false;
    // //其他页面体跳转到本页
    // if (!hasCache1) {
    //   this.queryMarkVlid();
    // }
  },
  deactivated: function deactivated() {
    // var name = cachName;
    // var cache = this.getCaches(this, name);
    // if (!cache) {
    //   this.tableData = [];
    //   this.total = 0;
    // }
  },
  methods: {
    queryMarkVlid: function queryMarkVlid() {
      var _this = this;
      this.listLoading = true;
      (0, _log.queryMarkVlid)(this.queryParams).then(function (res) {
        _this.tableData = [];
        console.log(res.data.data);
        if (res.data.code == 200) {
          _this.tableData.push(res.data.data);
        }
        setTimeout(function () {
          _this.listLoading = false;
        }, 1 * 800);
      }).catch(function (err) {
        _this.listLoading = false;
      });
    },
    querylist: function querylist() {
      this.queryMarkVlid();
    },
    handleReset: function handleReset(res) {
      this.dialogVisible = true;
    },
    cancel: function cancel() {
      this.dialogVisible = false;
      this.$refs['formReset'].resetFields();
    },
    confirm: function confirm() {
      var _this2 = this;
      this.$refs['formReset'].validate(function (valid) {
        if (valid) {
          (0, _log.restValidCode)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, _this2.queryParams), _this2.formReset)).then(function (res) {
            console.log(res.data);
            if (!res.data.data) {
              _this2.$message.error(res.data.msg);
            } else {
              _this2.$message.success('重置验证码成功');
            }
            _this2.dialogVisible = false;
            _this2.queryMarkVlid();
            _this2.$refs['formReset'].resetFields();
          }).catch(function (err) {
            _this2.dialogVisible = false;
          });
        }
      });
    }
  }
};
exports.default = _default;