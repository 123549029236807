"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
var _content = require("@/api/systems/content");
var _policyDialog = _interopRequireDefault(require("@/components/policyDialog"));
var _org = require("@/api/basic/org");
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _tinyMce = _interopRequireDefault(require("@/components/tiny-mce"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'systemcontentmanageindex';
var _default = {
  name: 'Systemcontentmanageindex',
  components: {
    Pagination: _Pagination.default,
    TinyMce: _tinyMce.default,
    policySelect: _policyDialog.default,
    Treeselect: _vueTreeselect.default
  },
  filters: {
    contentType: function contentType(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          1: '培训',
          2: '政策'
        };
        return statusMap[value];
      }
    },
    orgInfos: function orgInfos(value) {
      if (!value || value.length <= 0) {
        return '';
      } else {
        var arr = [];
        value.forEach(function (val) {
          if (val) arr.push(val.orgName);
        });
        return arr.join(',');
      }
    },
    roleType: function roleType(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var obj = {
          '000': '企业用户',
          101: '经销商用户',
          102: '终端用户',
          200: '供应商用户'
        };
        var arr = value.split(',');
        var aName = [];
        arr.forEach(function (val) {
          if (val) aName.push(obj[val]);
        });
        return aName.join(',');
      }
    },
    roleName: function roleName(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var obj = {
          '000': '企业用户',
          101: '经销商用户',
          102: '终端用户',
          200: '供应商用户'
        };
        return obj[value];
      }
    }
  },
  data: function data() {
    return {
      roleType: [{
        id: '000',
        label: '企业用户'
      }, {
        id: '101',
        label: '经销商用户'
      }, {
        id: '102',
        label: '终端用户'
      }, {
        id: '200',
        label: '供应商用户'
      }],
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < new Date().getTime() - 24 * 60 * 60 * 1000;
        }
      },
      handleShow: false,
      centerDialogVisible: false,
      PolicyDialog: false,
      dialogVisible: false,
      scanVisible: false,
      uploading: false,
      zcVisible: false,
      title: '',
      total: 0,
      searchForm: {
        current: 1,
        endTime: null,
        contentName: null,
        orgId: null,
        contentType: null,
        size: 20,
        startTime: null,
        scopeId: null
      },
      ruleForm: {
        appendixUrl: '',
        contentInfo: '',
        contentName: '',
        contentType: '',
        policyId: '',
        scopeId: '',
        roleType: null,
        orgIds: null,
        isEnable: 0,
        daterange: []
      },
      rules: {
        contentName: [{
          required: true,
          message: '培训名称不能为空，请维护',
          trigger: 'change'
        }],
        scopeId: [{
          required: true,
          message: '培训分类不能为空，请维护',
          trigger: 'submit'
        }],
        orgIds: [{
          required: true,
          message: '培训范围不能为空，请维护',
          trigger: 'submit'
        }],
        roleType: [{
          required: true,
          message: '培训机构类型不能为空，请维护',
          trigger: 'submit'
        }],
        daterange: [{
          required: true,
          message: '培训日期不能为空，请维护',
          trigger: 'submit'
        }],
        contentInfo: [{
          required: true,
          message: '培训说明不能为空，请维护',
          trigger: 'submit'
        }]
      },
      rules1: {
        contentName: [{
          required: true,
          message: '政策名称不能为空，请维护',
          trigger: 'change'
        }],
        scopeId: [{
          required: true,
          message: '政策分类不能为空，请维护',
          trigger: 'submit'
        }],
        orgIds: [{
          required: true,
          message: '公开区域不能为空，请维护',
          trigger: 'submit'
        }],
        roleType: [{
          required: true,
          message: '公开范围不能为空，请维护',
          trigger: 'submit'
        }],
        daterange: [{
          required: true,
          message: '政策日期不能为空，请维护',
          trigger: 'submit'
        }],
        contentInfo: [{
          required: true,
          message: '政策内容不能为空，请维护',
          trigger: 'submit'
        }]
      },
      listLoading: false,
      mloading: false,
      btnLoading: false,
      tabHeight: '100%',
      tableData: [],
      page2: false,
      loading: false,
      fileList: [],
      businessUser: '',
      orgList: [],
      orgOptions: [],
      normalList: [],
      scopeList: [],
      scanData: {
        appendixUrl: '',
        contentInfo: '',
        contentName: '',
        contentType: '',
        createTime: '',
        creatorId: '',
        endTime: '',
        isDelete: '',
        isEnable: '',
        orgInfos: [],
        roleList: [],
        policyId: '',
        roleType: null,
        scopeName: '',
        startTime: ''
      },
      apis: []
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    var that = this;
    var hasCache = !!(that.tableData && that.tableData.length > 0);
    var authList = that.$route.meta.authList;
    that.authId('searchbtn', authList);
    that.apis = authList;
    if (!hasCache) {
      that.getcontentScope();
      that.getList();
      that.getOrgListAll();
      that.getOrg();
    }
  },
  // 缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = this.getCaches(that, name);
    if (!cache) {
      that.tableData = [];
      that.total = 0;
    }
  },
  methods: {
    // 获取政策
    chosePolice: function chosePolice() {
      var that = this;
      that.PolicyDialog = true;
    },
    getPolice: function getPolice(val) {
      console.log(val, 'val');
      var that = this;
      that.businessUser = val.policyName;
      that.ruleForm.policyId = val.pid;
    },
    clearpolicyId: function clearpolicyId() {
      this.businessUser = '';
      this.ruleForm.policyId = '';
    },
    uploadfile: function uploadfile(file, fileList) {
      var formData = new FormData();
      var isLt2M = file.size / 1024 / 1024 < 100;
      var _this = this;
      // if (!isJPG && !isPNG) {
      //   this.$message.error('上传图片只能是 PNG/JPG 格式!')
      // }
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过 100Mb!');
        return;
      }
      this.uploading = true;
      formData.append('file', file.raw);
      (0, _content.uploadImg)(formData).then(function (res) {
        _this.uploading = false;
        if (res.data.code == 200) {
          _this.fileList = [];
          _this.ruleForm.appendixUrl = res.data.data;
        } else {
          _this.handleRemove(file, fileList);
          _this.$message.error(res.data.msg + '，请重新上传！');
          return;
        }
      }).catch(function () {
        _this.uploading = false;
      });
    },
    handleRemove: function handleRemove(file, fileList) {
      this.fileList = [];
    },
    getcontentScope: function getcontentScope() {
      var _this2 = this;
      (0, _content.contentScopelist)({
        contentType: ''
      }).then(function (res) {
        // console.log(res, 'resd')
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this2.scopeList = res.data.data;
          } else {
            _this2.scopeList = [];
          }
        }
      });
    },
    getOrg: function getOrg() {
      var that = this;
      (0, _org.fetchOrgList)({
        orgStatus: 1
      }).then(function (res) {
        if (res.data.code == 200) {
          var rData = res.data.data;
          var arr = [];
          that.orgOriginData = rData;
          if (rData.length >= 0) {
            rData.forEach(function (item) {
              var obj = {};
              obj.id = item.pid;
              obj.label = item.orgName;
              obj.pid = item.pid;
              obj.parentPid = item.parentPid;
              arr.push(obj);
            });
            that.orgOptions = (0, _utils.transTreeData)(arr, 'pid', 'parentPid', 'children');
          }
        }
      });
    },
    getOrgListAll: function getOrgListAll() {
      var that = this;
      (0, _org.fetchOrgList)({
        orgCodeAndName: '',
        orgStatus: 1,
        orgTypeList: ['2', '3']
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          that.orgList = response.data.data;
        }
      });
    },
    getList: function getList() {
      var that = this;
      that.authId('searchbtn', that.apis);
      that.listLoading = true;
      // var submitData = filterKeyNull(that.searchForm)
      (0, _content.contentInfopage)(that.searchForm).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.total = parseInt(response.data.data.total);
            that.tableData = response.data.data.records;
          } else {
            that.total = 0;
            that.tableData = [];
          }
          that.listLoading = false;
        }
      }).catch(function (res) {
        that.listLoading = false;
      });
    },
    resetForm: function resetForm() {
      this.searchForm = {
        current: 1,
        endTime: null,
        contentName: null,
        orgId: null,
        contentType: null,
        size: 20,
        startTime: null,
        scopeId: null,
        policyId: null
      };
      this.getList();
    },
    add: function add() {
      this.authId('addbtn', this.apis);
      this.centerDialogVisible = true;
    },
    centerClose: function centerClose() {
      this.centerDialogVisible = false;
    },
    addPX: function addPX() {
      this.title = '添加培训';
      this.dialogVisible = true;
      this.centerDialogVisible = false;
    },
    addZC: function addZC() {
      this.title = '添加政策';
      this.zcVisible = true;
      this.centerDialogVisible = false;
    },
    scan: function scan(row) {
      var _this3 = this;
      this.authId('detailbtn', this.apis);
      this.scanVisible = true;
      this.mloading = true;
      if (row.contentType == 1) {
        this.title = '查看培训';
      } else {
        this.title = '查看政策';
      }
      (0, _content.contentInfodetail)({
        pId: row.pid
      }).then(function (res) {
        _this3.mloading = false;
        if (res.data.code == 200) {
          _this3.scanData = res.data.data;
          if (res.data.data.policyId) _this3.getName(res.data.data.policyId);
          _this3.scanData.roleList = res.data.data.roleType.split(',');
        } else {
          _this3.$message.error(res.data.msg);
        }
      }).catch(function () {
        _this3.mloading = false;
      });
    },
    edit: function edit(row) {
      var _this4 = this;
      this.authId('updatebtn', this.apis);
      if (row.contentType == 1) {
        this.title = '编辑培训';
        this.dialogVisible = true;
      } else {
        this.title = '编辑政策';
        this.zcVisible = true;
      }
      this.mloading = true;
      (0, _content.contentInfodetail)({
        pId: row.pid
      }).then(function (res) {
        _this4.mloading = false;
        if (res.data.code == 200) {
          var data = res.data.data;
          if (data.policyId) _this4.getName(data.policyId);
          _this4.ruleForm.pid = data.pid;
          _this4.ruleForm.appendixUrl = data.appendixUrl;
          _this4.ruleForm.contentInfo = data.contentInfo;
          _this4.ruleForm.contentName = data.contentName;
          _this4.ruleForm.contentType = data.contentType;
          _this4.ruleForm.policyId = data.policyId;
          _this4.ruleForm.scopeId = data.scopeId * 1;
          _this4.ruleForm.isEnable = data.isEnable;
          _this4.ruleForm.daterange = [data.startTime, data.endTime];
          _this4.ruleForm.roleType = data.roleType.split(',');
          var arr = [];
          if (data.orgInfos && data.orgInfos.length) {
            data.orgInfos.forEach(function (val) {
              arr.push(val.orgId);
            });
          }
          _this4.ruleForm.orgIds = arr;
        } else {
          _this4.$message.error(res.data.msg);
        }
      }).catch(function () {
        _this4.mloading = false;
      });
    },
    getName: function getName(id) {
      var _this5 = this;
      (0, _content.zcRebatePolicy)({
        pId: id
      }).then(function (res) {
        if (res.data.code == 200) {
          _this5.businessUser = res.data.data.policyName;
        } else {
          _this5.$message.error(res.data.msg);
        }
      });
    },
    del: function del(id) {
      var _this6 = this;
      this.$confirm('此操作将永久删除该数据,是否继续?', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this6.authId('deletebtn', _this6.apis);
        (0, _content.contentInfodelete)({
          pId: id
        }).then(function (res) {
          if (res.data.code != 200) {
            _this6.$message.error(res.data.msg);
          } else {
            _this6.$message({
              message: '操作成功',
              type: 'success'
            });
            _this6.getList();
          }
        });
      }).catch(function () {});
    },
    handleClose: function handleClose() {
      this.ruleForm = {
        appendixUrl: '',
        contentInfo: '',
        contentName: '',
        contentType: '',
        policyId: '',
        scopeId: '',
        roleType: null,
        orgIds: null,
        isEnable: 0,
        daterange: []
      };
      this.fileList = [];
      this.businessUser = '';
      this.$refs.ruleForm.resetFields();
      this.dialogVisible = false;
      this.zcVisible = false;
    },
    handleClose1: function handleClose1() {
      console.log(111);
      this.ruleForm = {
        appendixUrl: '',
        contentInfo: '',
        contentName: '',
        contentType: '',
        policyId: '',
        scopeId: '',
        roleType: null,
        orgIds: null,
        isEnable: 0,
        daterange: []
      };
      this.fileList = [];
      this.businessUser = '';
      this.$refs.ruleForm.resetFields();
      this.dialogVisible = false;
      this.zcVisible = false;
    },
    submitForm: function submitForm(val) {
      var _this7 = this;
      // console.log(this.ruleForm, 'this.ruleForm')
      if (this.uploading) {
        this.$message('文件上传中请稍后。。。');
        return;
      }
      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          _this7.btnLoading = true;
          var submitData = JSON.parse(JSON.stringify((0, _utils.filterKeyNull)(_this7.ruleForm)));
          submitData.startTime = _this7.ruleForm.daterange[0];
          submitData.endTime = _this7.ruleForm.daterange[1];
          if (submitData.startTime == submitData.endTime) {
            _this7.btnLoading = false;
            _this7.$message.error('开始日期不能与结束日期相同');
            return;
          }
          submitData.contentType = val;
          submitData.roleType = _this7.ruleForm.roleType ? _this7.ruleForm.roleType.join(',') : '';
          delete submitData.daterange;
          if (_this7.ruleForm.pid) {
            (0, _content.contentInfoedit)(submitData).then(function (res) {
              if (res.data.code != 200) {
                _this7.$message.error(res.data.msg);
              } else {
                _this7.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this7.handleClose();
                _this7.getList();
              }
              _this7.btnLoading = false;
            }).catch(function () {
              _this7.btnLoading = false;
            });
          } else {
            (0, _content.contentInfosave)(submitData).then(function (res) {
              if (res.data.code != 200) {
                _this7.$message.error(res.data.msg);
              } else {
                _this7.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this7.handleClose();
                _this7.getList();
              }
              _this7.btnLoading = false;
            }).catch(function () {
              _this7.btnLoading = false;
            });
          }
        }
      });
    },
    scanClose: function scanClose() {
      this.scanVisible = false;
      this.businessUser = '';
      this.scanData = {
        appendixUrl: '',
        contentInfo: '',
        contentName: '',
        contentType: '',
        createTime: '',
        creatorId: '',
        endTime: '',
        isDelete: '',
        isEnable: '',
        orgInfos: [],
        roleList: [],
        policyId: '',
        roleType: null,
        scopeName: '',
        startTime: ''
      };
    }
  }
};
exports.default = _default;