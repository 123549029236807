var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "searchForm",
      staticClass: "search-condition",
      attrs: {
        model: _vm.searchForm,
        "status-icon": "",
        "label-position": _vm.formConfig.labelPosition,
        "label-width": _vm.formConfig.labelWidth,
      },
    },
    [
      _c(
        "div",
        { staticClass: "cols" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "orgCode", label: "所属企业:" } },
                    [
                      _c("treeselect", {
                        attrs: {
                          multiple: false,
                          "flatten-search-results": true,
                          placeholder: "请选择组织...",
                          options: _vm.orgOptions,
                        },
                        on: { select: _vm.getOrgVal, input: _vm.emptyStores },
                        model: {
                          value: _vm.searchForm.orgCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "orgCode", $$v)
                          },
                          expression: "searchForm.orgCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "storeCode", label: "库房名称:" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择" },
                          on: { focus: _vm.getDownHouses },
                          model: {
                            value: _vm.searchForm.storeCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "storeCode", $$v)
                            },
                            expression: "searchForm.storeCode",
                          },
                        },
                        _vm._l(_vm.TypeDataComs.StoreList, function (item) {
                          return _c("el-option", {
                            key: item.pId,
                            attrs: {
                              label: item.storeHouseName,
                              value: item.pId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "productCode", label: "产品名称:" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          "suffix-icon": "el-icon-search",
                          clearable: "",
                          placeholder: "请输入",
                        },
                        on: {
                          focus: _vm.openProductWin,
                          clear: _vm.clearProduct,
                        },
                        model: {
                          value: _vm.productCode,
                          callback: function ($$v) {
                            _vm.productCode = $$v
                          },
                          expression: "productCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("product-dialog", { ref: "selProduct", on: { change: _vm.setSel } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }