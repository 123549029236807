var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "额外定投",
        visible: _vm.dialogVisible,
        "append-to-body": true,
        width: "1000px !important",
        "before-close": _vm.goBack,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.goBack,
      },
    },
    [
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c(
            "div",
            { staticClass: "page-section" },
            [
              _c("div", { staticClass: "btnArea" }, [
                _c(
                  "div",
                  { staticClass: "head-btn-group" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: _vm.btnConfig.addBtn.type,
                          size: _vm.btnConfig.size,
                          icon: _vm.btnConfig.addBtn.icon,
                          disabled: _vm.isActivityOverdue,
                        },
                        on: { click: _vm.creat },
                      },
                      [_vm._v("新建额外定投")]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  attrs: {
                    data: _vm.list,
                    height: "500",
                    fit: "",
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "定投类型",
                      width: "140",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            row.awardPrizeRange == 0
                              ? _c("span", [_vm._v("经纬度定投")])
                              : _vm._e(),
                            _vm._v(" "),
                            row.awardPrizeRange == 1
                              ? _c("span", [_vm._v("数码定投")])
                              : _vm._e(),
                            _vm._v(" "),
                            row.awardPrizeRange == 2
                              ? _c("span", [_vm._v("用户ID定投")])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "定投内容",
                      "min-width": "120",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("div", [
                              row.awardPrizeRange == 0
                                ? _c(
                                    "div",
                                    _vm._l(
                                      row.extraLocationItems,
                                      function (item, index) {
                                        return _c("div", { key: index }, [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(item.latitude) +
                                              "," +
                                              _vm._s(item.longitude) +
                                              "\n                "
                                          ),
                                        ])
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              row.awardPrizeRange == 1 ? _c("div") : _vm._e(),
                              _vm._v(" "),
                              row.awardPrizeRange == 2 ? _c("div") : _vm._e(),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "用户ID",
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return _vm._l(row.userIdList, function (item, index) {
                            return _c("div", { key: index }, [
                              _vm._v(_vm._s(item)),
                            ])
                          })
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "奖品名称",
                      prop: "prizeItemsList[0].prizeName",
                      width: "140",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "奖品类型",
                      width: "140",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("prizeType")(
                                    row.prizeItemsList[0].prizeType
                                  )
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "投放时间",
                      prop: "createTime",
                      width: "160",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("div", [_vm._v(_vm._s(row.putStartTime))]),
                            _vm._v(" "),
                            _c("div", [_vm._v(_vm._s(row.putEndTime))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      align: _vm.tableConfig.align,
                      width: "140",
                      fixed: "right",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.edit(scope.row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-dropdown",
                              { staticClass: "table-dropdown" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "el-dropdown-link" },
                                  [
                                    _vm._v(
                                      "\n                更多\n                "
                                    ),
                                    _c("i", {
                                      staticClass:
                                        "el-icon-arrow-down el-icon--right",
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-dropdown-menu",
                                  {
                                    attrs: { slot: "dropdown" },
                                    slot: "dropdown",
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "a-btn",
                                        on: {
                                          click: function ($event) {
                                            return _vm.up(
                                              scope.row,
                                              scope.$index
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("升序")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "a-btn",
                                        on: {
                                          click: function ($event) {
                                            return _vm.down(
                                              scope.row,
                                              scope.$index
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("降序")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "a-btn",
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteItem(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.searchForm.current,
                  limit: _vm.searchForm.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.searchForm, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.searchForm, "size", $event)
                  },
                  pagination: _vm.getDataList,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.creatVisible
            ? _c("creatInvest", {
                attrs: {
                  type: _vm.editType,
                  actId: _vm.actIdCurrent,
                  investId: _vm.investId,
                  "dialog-visible": _vm.creatVisible,
                },
                on: { close: _vm.closeCreat },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { staticClass: "normalBtn", on: { click: _vm.goBack } },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }