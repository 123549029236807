"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _basic = require("@/api/fake_expel/basic");
var _utils = require("@/utils");
var _echarts = _interopRequireDefault(require("echarts"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      value1: '',
      value2: '',
      option1: {},
      option2: {},
      chart1: '',
      activeIndex: '1',
      list: [{
        channelName: '渠道A',
        percent1: '35',
        percent2: '10',
        isAdd: 1
      }, {
        channelName: '渠道B',
        percent1: '35',
        percent2: '10',
        isAdd: 0
      }, {
        channelName: '渠道C',
        percent1: '35',
        percent2: '10',
        isAdd: 1
      }, {
        channelName: '渠道D',
        percent1: '35',
        percent2: '10',
        isAdd: 0
      }, {
        channelName: '渠道E',
        percent1: '35',
        percent2: '10',
        isAdd: 1
      }, {
        channelName: '渠道F',
        percent1: '35',
        percent2: '10',
        isAdd: 1
      }],
      customColor: 'linear-gradient(180deg, #98B6FF 0%, #5F7FFF 100%)'
    };
  },
  activated: function activated() {
    var that = this;
    that.chart1 = _echarts.default.init(this.$refs.trend);
    that.loadChart1();
  },
  methods: {
    loadChart1: function loadChart1() {
      var that = this;
      that.chart1 = _echarts.default.init(this.$refs.trend);
      var option = {
        backgroundColor: '#fff',
        "title": {
          "text": "销售额趋势",
          x: "center",
          y: "4%",
          textStyle: {
            color: '#252525FF',
            fontSize: '22'
          },
          subtextStyle: {
            color: '#252525FF',
            fontSize: '16'
          },
          show: false
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            corssStyle: {
              color: '#C5D3DCFF'
            },
            lineStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: '#E5ECF1FF'
                }, {
                  offset: 0.5,
                  color: '#E5ECF1FF'
                }, {
                  offset: 1,
                  color: '#E5ECF1FF'
                }],
                global: false
              }
            }
          },
          backgroundColor: '#fff',
          padding: [10, 10],
          extraCssText: 'box-shadow: 2px 2px 2px 0 rgba(163,163,163,0.2)',
          textStyle: {
            color: '#252525FF',
            fontSize: 13
          },
          formatter: function formatter(param) {
            return '<div style="width:150px;">' + '<div class="cell">' + '<div class="cell-primary">' + '<div>' + param[0].name + '</div>' + '<div style="padding:10px 0 0 0">' + param[0].value + '箱/件</div>' + '</div>' + '<div class="cell-ft" style="position:absolute;top:10px;right:20px;">' + '<img style="width:14px" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAEKADAAQAAAABAAAAEAAAAAA0VXHyAAAB6klEQVQ4Ea1Tv0sjURD+ZjeEXFQs5VywShqVIGfuHzg4xfawshH8Ufijsr1/4bjqxMbC0sLCK1RQsLA2hXAeoluJEcFKTGJWN2+cecmLG0W44l6x875533wzb2ce4dU6CW+GY45nARoDY8AeEy4B3vfhr4/kP54mQ8iBU+Z0Pbz+KXiBmT3nT1oiMoLXMrn+lSGiRz2zAs3g8h4zviQD3tsT4TCTCyZUxGbSzB3BRNtCil4EKCbQkcPKbVULat65cdIum6jmp7IBm1rRGPyW/5AG8RS6vB1U+RbMWRXS6/jwCqkY8ZwovtxZCCauHWQy3ePRU3XCNEzfh970Xv3ucVd4NlgFNGGDGnMpMH0VqD67RPmv4LOHqDJTzAU/1FkKr75LcEXSXkpkszNN9hiVzsv3DO62WD/kzRfz/ettnNgch1fTMNhwLvkvlbcC7lQsed43C43ZZFAkPctKBb6jqEBKnFIWBp1T8CJyga3gExCrX+7Uoza6KM/IIKzp3i6JFQE+6BCQQaLwuqiEEmhVLYGX1DLhc+J3qWf/bRuV2VoevEndGpgt53PWtdFOYumi/EvaYrM4grWEP8Qw0qNCh1+ACKyO5oPl/zPKOtM626qqpb3O5rCeKce9A/W3X6Mj2dGW6bQD9g/P+RlwWuOsy3r3kwAAAABJRU5ErkJggg=="/>' + '</div>' + '</div></div>';
          }
        },
        grid: {
          top: '14%',
          left: 20,
          right: 20,
          bottom: 20,
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
          axisLine: {
            lineStyle: {
              color: 'transparent'
            }
          },
          axisLabel: {
            margin: 10,
            color: '#252525FF',
            textStyle: {
              fontSize: 13
            }
          }
        }],
        yAxis: [{
          name: '箱/件',
          nameTextStyle: {
            color: '#252525FF',
            fontSize: 13,
            left: 0
          },
          axisLabel: {
            formatter: '{value}',
            color: '#252525FF'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#E5ECF1FF'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: '#E5ECF1FF'
            }
          }
        }],
        series: [{
          type: 'line',
          showSymbol: false,
          symbolSize: 34,
          symbol: 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAlCAYAAADFniADAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAJaADAAQAAAABAAAAJQAAAACQyAEPAAADzElEQVRYCc1Yv2sUQRT+Zvcud94Zk4tJRCEKCtqKAYnEgDZWgpUWFkE0ELCw8A+xEBEDiUoKC62EVDYWGkgTsbVQiIIQE3L54Z33a2+cb5N3bjZ7m90jZ7LN23nvm/k+3s68mVmFmI/WWl19iVSxgKxWSFoOEnYGdrUCO9kBxynCqduoKY1qJovC+zsoK2VaMR4VFTs4oZOZMrqdDDIUELUfhdpFFIsprM6Pq2qUfruKuvla29/yyJmMHIkyYBjGZHD9dA75N7eUE4YLFXVtWmdX19CvbYTiwgj8MSNHd3fh17tRVfDHpN2U7PJTnSsDOQHutU0B+Y/3VT5oXCvIOfJc97VTEDk5PnmC+Hdkqt0Z8osIyti2THEOtTtDflHkI6/X3xDFVcZJ7Q3+r3fykl/4GqK47PdylQlBFEte8gvWFcXCuBd1SAZtxZKfOtjXFcVK3cpA0qenhN7jazhLK75WrOhIcC+79AxZxNqdNinPLON2so4xS+OU6ykB/etYqFqY/NqLV3GFVZNmP9V6WV15odOFPzgRZwBmpG8Dj+w6hpv1cyzMLnXi4Uoay80wQf7sIfy0uNsHBcN8OwQpVOwkfpjNqCL9KJg4aUe11JPg8SNqB+L4ySRDSqE6dB7zY9dxwbYxUHNQmZrB3NxnDGqNJHHEx/mU7nGI56E4ojiHBE9B4zcwZAR10Jcwlm36BePFiy/MUo/FA1oYyBvjXGpMavOpmCFvXN5d/9anJD7OqqQeK86BLVVGjxDbCSxKhsQnln7Gpe3tJ75mlnosngybAfz+cgor4nNqOOaYOSRtr6WfcfF5+4mvmaUei2fqZgC/n8u7rrDg+jU6JmfwyY9h2/WbON+Jj1MWqMfiIZ+doz4sjILlKpt4iznJGC3b9AvGixdfmHUvHRef6KPaQlcY0B87t4RpKQtujHXKzCH3k21liH4W0C99GPX3D2urOtYsXoPCQEExVmoSNmJGiFPFgNmq3E9Gv1T0BibiC/Uo7n3DUzgZZxXK+Dv2PhPgHGp17+Mkn72H7+5xeOSx7isl0ClkcS3rEJc9V1mcSe3nSdew8eGBWnKrOS+KppK2LMoVEnPj9Qtimzpo3fMUb668KNKxXw/55QbdOA7z5sqL4n6IIi/5hXvbFYu3ivzvf5VYQO22ucNY9N6YG5kiMQO8h7VbhHd88nkFMbYtUwLmzbVUan3iyzi72XTarLa7asmP25YpCRLY7oxx/CBB1BCYKRF34P66iLAD939KhNHKnzxegxy9Wd+88WbvtkI9WUVhT//k+cn+xz/Pv4LioBm84q/tAAAAAElFTkSuQmCC',
          lineStyle: {
            normal: {
              width: 2,
              color: new _echarts.default.graphic.LinearGradient(0, 1, 0, 0, [{
                offset: 0,
                color: '#5F7FFFFF'
              }, {
                offset: 1,
                color: '#5F7FFFFF'
              }]),
              shadowColor: 'rgba(95,127,255, 0.7)',
              shadowBlur: 20,
              shadowOffsetY: 1
            }
          },
          itemStyle: {
            color: "#FFF",
            borderColor: "#5F7FFF",
            borderWidth: 2,
            shadowColor: 'rgba(0, 0, 0, 0)',
            shadowBlur: 5,
            shadowOffsetY: 2,
            shadowOffsetX: 2
          },
          label: {
            normal: {
              show: false
            }
          },
          areaStyle: {
            normal: {
              color: new _echarts.default.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(95,127,255,0.1)'
              }, {
                offset: 1,
                color: 'rgba(152,182,255,0.1)'
              }], false),
              shadowColor: 'rgba(152,182,255,0.1)',
              shadowBlur: 20
            }
          },
          data: [300, 450, 479, 229, 210, 250, 120, 140, 90, 100, 120, 300]
        }]
      };
      that.chart1.setOption(option);
      that.chart1.resize();
    },
    handleSelect: function handleSelect() {}
  }
};
exports.default = _default;