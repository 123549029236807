var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.hasAuth("searchbtn", _vm.apis)
      ? _c(
          "div",
          [
            _c(
              "el-form",
              {
                ref: "formInline",
                staticClass: "search-condition",
                attrs: {
                  model: _vm.formInline,
                  "label-position": _vm.formConfig.labelPosition,
                  "label-width": _vm.formConfig.labelWidth,
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "cols" },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "产品名称：",
                                  prop: "prodName",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticClass: "filter-item",
                                  attrs: { placeholder: "产品名称" },
                                  model: {
                                    value: _vm.formInline.prodName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formInline, "prodName", $$v)
                                    },
                                    expression: "formInline.prodName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "状态：", prop: "status" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "请选择" },
                                    model: {
                                      value: _vm.formInline.status,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formInline, "status", $$v)
                                      },
                                      expression: "formInline.status",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { label: "全部", value: "" },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: { label: "未上架", value: "0" },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: { label: "已上架", value: "1" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.formConfig.btnFollow
                          ? _c(
                              "el-col",
                              {
                                staticClass: "head-btn-group",
                                style: { width: _vm.formConfig.btnArea },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { "label-width": "0" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "label" },
                                        slot: "label",
                                      },
                                      [_vm._v(" ")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: _vm.btnConfig.searchBtn.type,
                                          size: _vm.btnConfig.size,
                                          icon: _vm.btnConfig.searchBtn.icon,
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.formInline.current = 1
                                            _vm.fetchData()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.btnConfig.searchBtn.text)
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: _vm.btnConfig.resetBtn.type,
                                          size: _vm.btnConfig.size,
                                          icon: _vm.btnConfig.resetBtn.icon,
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.restForm("formInline")
                                            _vm.fetchData()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.btnConfig.resetBtn.text)
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _vm.formConfig.btnFollow
              ? _c("el-divider", { staticClass: "btnDivider" })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "btnArea" },
              [
                !_vm.formConfig.btnFollow
                  ? _c(
                      "el-col",
                      {
                        staticClass: "head-btn-group",
                        staticStyle: { width: "230px" },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: _vm.btnConfig.searchBtn.type,
                              size: _vm.btnConfig.size,
                              icon: _vm.btnConfig.searchBtn.icon,
                            },
                            on: {
                              click: function ($event) {
                                _vm.formInline.current = 1
                                _vm.fetchData()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: _vm.btnConfig.resetBtn.type,
                              size: _vm.btnConfig.size,
                              icon: _vm.btnConfig.resetBtn.icon,
                            },
                            on: {
                              click: function ($event) {
                                _vm.restForm("formInline")
                                _vm.fetchData()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "head-btn-group" },
                  [
                    _vm.hasAuth("addbtn", _vm.apis)
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: _vm.btnConfig.addBtn.type,
                              size: _vm.btnConfig.size,
                              icon: _vm.btnConfig.addBtn.icon,
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.addOrUpdateHandle(null)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.btnConfig.addBtn.text))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasAuth("alldelete", _vm.apis)
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: _vm.btnConfig.delBtn.type,
                              size: _vm.btnConfig.size,
                              icon: _vm.btnConfig.delBtn.icon,
                              disabled: _vm.dataListSelections.length <= 0,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteHandle()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.btnConfig.delBtn.text))]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                attrs: {
                  height: _vm.tabHeight,
                  data: _vm.dataList,
                  "element-loading-text": "Loading",
                  border: _vm.tableConfig.border,
                  stripe: _vm.tableConfig.stripe,
                  fit: "",
                },
                on: { "selection-change": _vm.handleSelectionChange },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    type: "selection",
                    width: "55",
                    align: _vm.tableConfig.align,
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "产品名称",
                    prop: "prodName",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "产品图片", align: _vm.tableConfig.align },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("img", {
                              staticStyle: { width: "50px" },
                              attrs: { src: scope.row.pic, alt: "" },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3090970387
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "价格",
                    prop: "price",
                    align: _vm.tableConfig.align,
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "库存",
                    prop: "totalStocks",
                    align: _vm.tableConfig.align,
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "状态",
                    width: "100",
                    align: _vm.tableConfig.align,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.status === 1
                              ? _c("el-tag", { attrs: { size: "small" } }, [
                                  _vm._v("已上架"),
                                ])
                              : _c(
                                  "el-tag",
                                  { attrs: { size: "small", type: "danger" } },
                                  [_vm._v("未上架")]
                                ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    111210057
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "创建时间",
                    "min-width": "100",
                    align: _vm.tableConfig.align,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.createTime))]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2774630583
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    align: _vm.tableConfig.align,
                    label: "操作",
                    fixed: "right",
                    width: "200",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm.hasAuth("updatebtn", _vm.apis)
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "button configText",
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addOrUpdateHandle(
                                          scope.row.prodId
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("编辑")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.hasAuth("previewbtn", _vm.apis)
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "button configText",
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showDetail(scope.row.prodId)
                                      },
                                    },
                                  },
                                  [_vm._v("预览")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.hasAuth("deletebtn", _vm.apis)
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "button configText red",
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteHandle(
                                          scope.row.prodId
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1251838833
                  ),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("pagination", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.total > 0,
                  expression: "total>0",
                },
              ],
              attrs: {
                total: _vm.total,
                page: _vm.formInline.current,
                limit: _vm.formInline.size,
              },
              on: {
                "update:page": function ($event) {
                  return _vm.$set(_vm.formInline, "current", $event)
                },
                "update:limit": function ($event) {
                  return _vm.$set(_vm.formInline, "size", $event)
                },
                pagination: _vm.fetchData,
              },
            }),
            _vm._v(" "),
            _vm.prodInfoIs
              ? _c("prodInfo", {
                  ref: "prodInfo",
                  attrs: {
                    "prod-info-is-fun": _vm.prodInfoIsFun,
                    "fetch-data": _vm.fetchData,
                  },
                })
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.hasAuth("searchbtn", _vm.apis) ? _c("section", [_vm._m(0)]) : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }