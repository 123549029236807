"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dayJoinSum = dayJoinSum;
exports.selectConfig = selectConfig;
exports.selectDay = selectDay;
exports.selectEffect = selectEffect;
exports.surplusCount = surplusCount;
exports.sweepCode = sweepCode;
var _request = _interopRequireDefault(require("@/utils/request"));
// var baseUrl = '/api'
var baseUrl = process.env.VUE_APP_VERSION1;
// 日报-----获取活动下拉列表
function selectConfig(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/tss/selectConfig',
    method: 'get',
    params: params
  });
}
// 活动统计--日报
function selectDay(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/tss/selectDay',
    method: 'get',
    params: params
  });
}
// 活动奖池剩余/数码使用情况
function surplusCount(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/tss/surplusCount',
    method: 'get',
    params: params
  });
}
// 效果统计--活动统计
function selectEffect(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/tss/selectEffect',
    method: 'get',
    params: params
  });
}
// 效果统计-活动参与统计
function dayJoinSum(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/tss/dayJoinSum',
    method: 'get',
    params: params
  });
}
// 效果统计-扫码分布
function sweepCode(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/tss/sweepCode',
    method: 'get',
    params: params
  });
}