var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    [
      _c(
        "el-row",
        { staticClass: "jfuser" },
        [
          _c("el-col", { attrs: { span: 24 } }, [
            _c("div", { staticClass: "hyyy-container" }, [
              _c("div", { staticClass: "hyyy-content" }, [
                _c("div", { staticClass: "hyyy-header" }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "hyyy-main",
                    staticStyle: { "padding-left": "0" },
                  },
                  [
                    _c(
                      "el-card",
                      [
                        _c("div", { staticClass: "hyyy-top-tit" }, [
                          _c("div", { staticClass: "hyyy-top-tit-lift" }, [
                            _c("span", {
                              staticStyle: {
                                "font-weight": "400",
                                color: "#333",
                              },
                              domProps: {
                                textContent: _vm._s(_vm.$route.meta.title),
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "hyyy-top-tit-right" }),
                        ]),
                        _vm._v(" "),
                        _c("el-divider", { staticStyle: { margin: "0" } }),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 24, md: 24 } },
                              [
                                _c(
                                  "el-form",
                                  {
                                    ref: "formInline",
                                    staticClass: "demo-form-inline",
                                    attrs: {
                                      inline: true,
                                      model: _vm.formInline,
                                      "label-position": "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "cols" },
                                      [
                                        _c(
                                          "el-row",
                                          { attrs: { gutter: 20 } },
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 6 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "用户昵称：",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      attrs: {
                                                        placeholder:
                                                          "请输入用户昵称",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.formInline
                                                            .customerName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.formInline,
                                                            "customerName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "formInline.customerName",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 6 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "省份/城市：",
                                                    },
                                                  },
                                                  [
                                                    _c("el-cascader", {
                                                      attrs: {
                                                        options: _vm.dqlist,
                                                        props: _vm.props,
                                                        clearable: "",
                                                      },
                                                      on: {
                                                        change: _vm.xzsfcs,
                                                      },
                                                      model: {
                                                        value: _vm.pocity,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.pocity = $$v
                                                        },
                                                        expression: "pocity",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 6 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "客户来源：",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        attrs: {
                                                          placeholder: "请选择",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.formInline
                                                              .source,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.formInline,
                                                              "source",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "formInline.source",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-option",
                                                          {
                                                            attrs: {
                                                              label: "不限",
                                                              value: "",
                                                            },
                                                          },
                                                          [_vm._v("不限")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-option",
                                                          {
                                                            attrs: {
                                                              label: "积分商城",
                                                              value: "1",
                                                            },
                                                          },
                                                          [_vm._v("积分商城")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-option",
                                                          {
                                                            attrs: {
                                                              label: "会员卡",
                                                              value: "2",
                                                            },
                                                          },
                                                          [_vm._v("会员卡")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-option",
                                                          {
                                                            attrs: {
                                                              label:
                                                                "微信公众号",
                                                              value: "3",
                                                            },
                                                          },
                                                          [_vm._v("微信公众号")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-option",
                                                          {
                                                            attrs: {
                                                              label: "一物一码",
                                                              value: "4",
                                                            },
                                                          },
                                                          [_vm._v("一物一码")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-col",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: _vm.handleShow,
                                                    expression: "handleShow",
                                                  },
                                                ],
                                                attrs: { span: 6 },
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: { label: "性别：" },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        attrs: {
                                                          placeholder: "请选择",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.formInline.sex,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.formInline,
                                                              "sex",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "formInline.sex",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-option",
                                                          {
                                                            attrs: {
                                                              label: "不限",
                                                              value: "",
                                                            },
                                                          },
                                                          [_vm._v("不限")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-option",
                                                          {
                                                            attrs: {
                                                              label: "男",
                                                              value: "1",
                                                            },
                                                          },
                                                          [_vm._v("男")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-option",
                                                          {
                                                            attrs: {
                                                              label: "女",
                                                              value: "2",
                                                            },
                                                          },
                                                          [_vm._v("女")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-col",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: _vm.handleShow,
                                                    expression: "handleShow",
                                                  },
                                                ],
                                                attrs: { span: 6 },
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  { attrs: { label: "标签" } },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        attrs: {
                                                          multiple: "",
                                                          placeholder:
                                                            "请选择标签",
                                                          "multiple-limit": "5",
                                                        },
                                                        on: {
                                                          change: _vm.xzbqlist,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.formInline
                                                              .tagId,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.formInline,
                                                              "tagId",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "formInline.tagId",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.tagslist,
                                                        function (item) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: item.lableName,
                                                              attrs: {
                                                                value: item.id,
                                                                label:
                                                                  item.lableName,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-col",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: _vm.handleShow,
                                                    expression: "handleShow",
                                                  },
                                                ],
                                                attrs: { span: 6 },
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "是否关注微信：",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        attrs: {
                                                          placeholder: "请选择",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.formInline
                                                              .isFollowWechat,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.formInline,
                                                              "isFollowWechat",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "formInline.isFollowWechat",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-option",
                                                          {
                                                            attrs: {
                                                              label: "不限",
                                                              value: "",
                                                            },
                                                          },
                                                          [_vm._v("不限")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-option",
                                                          {
                                                            attrs: {
                                                              label: "是",
                                                              value: "1",
                                                            },
                                                          },
                                                          [_vm._v("是")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-option",
                                                          {
                                                            attrs: {
                                                              label: "否",
                                                              value: "2",
                                                            },
                                                          },
                                                          [_vm._v("否")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-col",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: _vm.handleShow,
                                                    expression: "handleShow",
                                                  },
                                                ],
                                                attrs: { span: 6 },
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "是否是会员：",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        attrs: {
                                                          placeholder: "请选择",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.formInline
                                                              .isMember,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.formInline,
                                                              "isMember",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "formInline.isMember",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-option",
                                                          {
                                                            attrs: {
                                                              label: "不限",
                                                              value: "",
                                                            },
                                                          },
                                                          [_vm._v("不限")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-option",
                                                          {
                                                            attrs: {
                                                              label: "是",
                                                              value: "1",
                                                            },
                                                          },
                                                          [_vm._v("是")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-option",
                                                          {
                                                            attrs: {
                                                              label: "否",
                                                              value: "2",
                                                            },
                                                          },
                                                          [_vm._v("否")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-col",
                                              { staticClass: "hyyy-head-btn" },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      "label-width": "0",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        attrs: {
                                                          slot: "label",
                                                        },
                                                        slot: "label",
                                                      },
                                                      [_vm._v(" ")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-button-group",
                                                      [
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "primary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.onSubmit(
                                                                  _vm.formInline
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("查询")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "primary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.onReset(
                                                                  "formInline"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("重置")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "primary",
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.opentagging,
                                                            },
                                                          },
                                                          [_vm._v("打标签")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "primary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.dowmdetail(
                                                                  _vm.formInline
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("下载明细")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: { type: "text" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.handleShow =
                                                              !_vm.handleShow
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                              " +
                                                            _vm._s(
                                                              _vm.handleShow
                                                                ? "收起"
                                                                : "展开"
                                                            ) +
                                                            "\n                              "
                                                        ),
                                                        _c("i", {
                                                          class: _vm.handleShow
                                                            ? "el-icon-arrow-up"
                                                            : "el-icon-arrow-down",
                                                        }),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-card",
                      {
                        staticStyle: { "margin-top": "30px", padding: "10px" },
                      },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "el-table",
                                  {
                                    directives: [
                                      {
                                        name: "loading",
                                        rawName: "v-loading",
                                        value: _vm.userload,
                                        expression: "userload",
                                      },
                                    ],
                                    ref: "multipleTable",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      data: _vm.tableData,
                                      "tooltip-effect": "dark",
                                      sortable: "custom",
                                      "default-sort": {
                                        prop: "syjf",
                                        order: "descending",
                                      },
                                    },
                                    on: {
                                      "sort-change": _vm.srchang,
                                      select: _vm.handleSelection,
                                      "cell-click": _vm.cellck,
                                      "selection-change":
                                        _vm.handleSelectionChange,
                                    },
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: { type: "selection", width: "55" },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "customerName",
                                        label: "客户名称",
                                        align: "center",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        label: "用户昵称",
                                        align: "center",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                _vm._s(scope.row.userName)
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        label: "性别",
                                        align: "center",
                                        prop: "sex",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "age",
                                        label: "年龄",
                                        align: "center",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "phone",
                                        label: "手机号",
                                        align: "center",
                                        width: "110",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        label: "省份/城市",
                                        align: "center",
                                        width: "160",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(scope.row.province) +
                                                    "/" +
                                                    _vm._s(scope.row.city)
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        label: "客户来源",
                                        "show-overflow-tooltip": "",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("span", {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    scope.row.source
                                                  ),
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        label: "是否关注微信",
                                        align: "center",
                                        prop: "isFollowWechat",
                                        width: "110",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("span", {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    scope.row.isFollowWechat
                                                  ),
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        label: "是否是会员",
                                        align: "center",
                                        prop: "isMember",
                                        width: "100",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("span", {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    scope.row.isMember
                                                  ),
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "appName",
                                        label: "公众号名称",
                                        align: "center",
                                        width: "100",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: { label: "会员类型" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("div", {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    scope.row.memberType
                                                  ),
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "memberLevel",
                                        label: "会员等级",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        label: "总积分",
                                        align: "center",
                                        prop: "allIntegral",
                                        sortable: "",
                                        width: "110",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        label: "剩余积分",
                                        align: "center",
                                        prop: "integral",
                                        sortable: "",
                                        width: "130",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        label: "标签",
                                        width: "200",
                                        align: "center",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._l(
                                                scope.row.talist,
                                                function (tag) {
                                                  return _c(
                                                    "el-tag",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            tag.lableType ==
                                                            "2",
                                                          expression:
                                                            "tag.lableType == '2'",
                                                        },
                                                      ],
                                                      key: tag.lableId,
                                                      staticStyle: {
                                                        "margin-right": "5px",
                                                        "margin-bottom": "5px",
                                                      },
                                                      attrs: {
                                                        "disable-transitions": false,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(tag.lableNname)
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              _vm._v(" "),
                                              _vm._l(
                                                scope.row.talist,
                                                function (tag) {
                                                  return _c(
                                                    "el-tag",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            tag.lableType !=
                                                            "2",
                                                          expression:
                                                            "tag.lableType != '2'",
                                                        },
                                                      ],
                                                      key: tag.lableId,
                                                      staticStyle: {
                                                        "margin-right": "5px",
                                                        "margin-bottom": "5px",
                                                      },
                                                      attrs: {
                                                        closable: "",
                                                        "disable-transitions": false,
                                                      },
                                                      on: {
                                                        close: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleClosetag(
                                                            tag,
                                                            scope.row
                                                              .moWxLableCustomerDOList,
                                                            scope.row
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(tag.lableNname)
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        scope.row
                                                          .moWxLableCustomerDOList
                                                          .length > 2,
                                                      expression:
                                                        "\n                            scope.row.moWxLableCustomerDOList.length > 2\n                          ",
                                                    },
                                                  ],
                                                },
                                                [_vm._v("...")]
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        label: "客户生成时间",
                                        width: "120",
                                        align: "center",
                                        "show-overflow-tooltip": "",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "el-row",
                                                [
                                                  _c("el-col", [
                                                    _vm._v(
                                                      "\n                            " +
                                                        _vm._s(
                                                          scope.row.createTime
                                                        ) +
                                                        "\n                          "
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("el-pagination", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.total > 0,
                                      expression: "total > 0",
                                    },
                                  ],
                                  staticStyle: { "text-align": "left" },
                                  attrs: {
                                    background: "",
                                    "current-page": _vm.formInline.pageNo,
                                    "page-size": _vm.formInline.pageSize,
                                    layout: "total,prev, pager, next",
                                    total: _vm.total,
                                  },
                                  on: {
                                    "current-change": _vm.handleCurrentChange,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "所有标签",
                visible: _vm.dialogVisiblebq,
                width: "630px",
                "before-close": _vm.handleClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisiblebq = $event
                },
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c("div", { staticClass: "tipsa" }, [
                        _vm._v(
                          "\n            提示：自动标签为符合条件自动匹配的标签，不可以进行删除，手动标签可删除\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.bqlist, function (tag) {
                        return _c(
                          "el-tag",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: tag.lableType == "2",
                                expression: "tag.lableType == '2'",
                              },
                            ],
                            key: tag.id,
                            staticClass: "tagbq",
                            staticStyle: { "margin-right": "5px" },
                            attrs: { "disable-transitions": false },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(tag.lableNname) +
                                "\n          "
                            ),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _vm._l(_vm.bqlist, function (tag) {
                        return _c(
                          "el-tag",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: tag.lableType != "2",
                                expression: "tag.lableType != '2'",
                              },
                            ],
                            key: tag.id,
                            staticClass: "tagbq",
                            staticStyle: { "margin-right": "5px" },
                            attrs: {
                              closable: "",
                              "disable-transitions": false,
                            },
                            on: {
                              close: function ($event) {
                                return _vm.handleClosetag(
                                  tag,
                                  _vm.bqlist,
                                  _vm.rowtag
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(tag.lableNname) +
                                "\n          "
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "打标签",
                visible: _vm.dialogVisible,
                width: "30%",
                "before-close": _vm.handleClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "span",
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.dbqlist,
                        callback: function ($$v) {
                          _vm.dbqlist = $$v
                        },
                        expression: "dbqlist",
                      },
                    },
                    _vm._l(_vm.taglist, function (item, index) {
                      return _c(
                        "el-checkbox",
                        { key: index, attrs: { label: item.id } },
                        [_vm._v(_vm._s(item.lableName))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.tagging } },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }