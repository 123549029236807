"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QueryActivityUserDetail = QueryActivityUserDetail;
exports.getActivity = getActivity;
exports.getActivityList = getActivityList;
exports.getAgentList = getAgentList;
exports.getExprotActivity = getExprotActivity;
exports.getPrizeList = getPrizeList;
exports.getProductList = getProductList;
exports.getWinningLottery = getWinningLottery;
exports.queryExprotActivityForPage = queryExprotActivityForPage;
exports.sendExprotRequest = sendExprotRequest;
var _request = _interopRequireDefault(require("@/utils/request"));
var base = '';

// 获取中奖流水列表
// export function getWinningLottery(params) {
//   return request({
//     url: base + '/market-config/activityPrizeRecord/activityPrizeRecordPage',
//     method: 'get',
//     params
//   })
// }
function getWinningLottery(current, size, data) {
  return (0, _request.default)({
    url: base + "/market-center/activityPrizeRecord/joinActivityRecordPage?current=".concat(current, "&size=").concat(size),
    method: 'post',
    data: data
  });
}
// 活动参与记录详情 /market-center/activityPrizeRecord/queryActivityUserDetail
function QueryActivityUserDetail(params) {
  return (0, _request.default)({
    url: base + '/market-center/activityPrizeRecord/queryActivityUserDetail',
    method: 'post',
    params: params
  });
}
// 活动参与记录导出
function getExprotActivity(params) {
  return (0, _request.default)({
    url: base + '/market-center/activityPrizeRecord/exprotActivity',
    method: 'get',
    responseType: 'blob',
    params: params
  });
}
function sendExprotRequest(params) {
  return (0, _request.default)({
    url: base + '/market-center/activityPrizeRecord/exprotActivity',
    method: 'get',
    params: params
  });
}
// 活动名称列表下拉
function getActivity() {
  return (0, _request.default)({
    url: base + '/market-config/activityConfig/comboBox',
    method: 'post'
  });
}
// 活动列表下拉
function getActivityList() {
  return (0, _request.default)({
    url: base + '/market-center/activityComBox/activityList',
    method: 'get'
  });
}
// 经销商列表下拉
function getAgentList() {
  return (0, _request.default)({
    url: base + '/market-center/activityComBox/agentList',
    method: 'get'
  });
}
// 奖项列表下拉
function getPrizeList() {
  return (0, _request.default)({
    url: base + '/market-center/activityComBox/prizeList',
    method: 'get'
  });
}
// 活动产品列表下拉
function getProductList() {
  return (0, _request.default)({
    url: base + '/market-center/activityComBox/productList',
    method: 'get'
  });
}
// 查询导出明细列表
function queryExprotActivityForPage(params) {
  return (0, _request.default)({
    url: base + '/market-center/activityPrizeRecord/queryExprotActivityForPage',
    method: 'get',
    params: params
  });
}