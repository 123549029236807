"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/es6.regexp.split");
var _compyuser = require("@/api/systems/compyuser");
var _dictionary = require("@/api/systems/dictionary");
var _salesArea = require("@/api/basic/salesArea");
var _utils = require("@/utils");
var _pwdconfig = require("@/api/systems/pwdconfig");
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
var _jsMd = _interopRequireDefault(require("js-md5"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Add',
  components: {
    Treeselect: _vueTreeselect.default
  },
  props: {
    add: Boolean
  },
  data: function data() {
    return {
      addData: {
        loginAccount: '',
        userName: '',
        userType: 0,
        orgId: null,
        regionId: null,
        loginPwd: '',
        reloginPwd: '',
        phoneNum: '',
        groupCode: '',
        postCode: '',
        email: '',
        isEnable: true,
        remark: ''
      },
      regionList: [],
      groupList: [],
      postList: [],
      dialogVisible: false,
      loading: false,
      value1: '',
      value2: true,
      option: [{
        value: '1',
        label: '系统'
      }],
      rules: {
        loginAccount: [{
          required: true,
          message: '请输入用户名',
          trigger: 'blur'
        }],
        userName: [{
          required: true,
          message: '请输入姓名',
          trigger: 'blur'
        }],
        orgId: [{
          required: true,
          message: '请选择所属组织',
          trigger: 'blur'
        }],
        regionId: [{
          required: false,
          message: '请选择销售组织',
          trigger: 'blur'
        }],
        loginPwd: [{
          required: true,
          validator: _utils.validatePassword,
          trigger: 'blur'
        }],
        reloginPwd: [{
          required: true,
          message: '请输入确认密码',
          trigger: 'blur'
        }],
        phoneNum: [{
          required: true,
          validator: _compyuser.checkPhone,
          trigger: 'blur'
        }],
        email: [{
          required: true,
          validator: _compyuser.checkEmail,
          trigger: 'blur'
        }]
      },
      dstate: false,
      value: null,
      orgs: [],
      safeRules: {}
    };
  },
  watch: {
    add: function add(val) {
      var that = this;
      if (val == true) {
        that.dialogVisible = true;
        that.orgs = that.$parent.options;
        // 销售组织
        that.getregionList();
        // 分组
        that.getDirSel('FZ100', 'groupList');
        // 岗位
        that.getDirSel('GC100', 'postList');
        // 获取安全规则
        that.getRuleDetail();
      } else {
        that.dialogVisible = false;
        that.orgs = [];
      }
    }
  },
  mounted: function mounted() {},
  methods: {
    getDirSel: function getDirSel(code, list) {
      var _this = this;
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: code
      }).then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this[list] = res.data.data;
          } else {
            _this[list] = [];
          }
        }
      });
    },
    getregionList: function getregionList() {
      var that = this;
      (0, _salesArea.selectLargeRegionByType)({
        regionType: 1
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          that.regionList = response.data.data;
        }
      });
    },
    handleClose: function handleClose() {
      var that = this;
      that.$parent.addShow = false;
      that.resetForm('addForm');
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;
      var that = this;
      that.$refs[formName].validate(function (valid) {
        if (valid) {
          var addData = (0, _utils.deepClone)(that.addData);
          var param = {};
          var loginAccount = addData.loginAccount;
          var pwd = "".concat(loginAccount.trim(), "+").concat(addData.loginPwd);
          param.loginAccount = addData.loginAccount;
          param.userName = addData.userName;
          param.orgId = addData.orgId;
          param.regionId = addData.regionId;
          param.loginPwd = (0, _jsMd.default)(pwd);
          param.phoneNum = addData.phoneNum;
          param.email = addData.email;
          param.isEnable = addData.isEnable;
          param.remark = addData.remark;
          param.groupCode = addData.groupCode;
          param.postCode = addData.postCode;
          param.userType = 0;
          if (param.isEnable == false) {
            param.isEnable = 0;
          } else {
            param.isEnable = 1;
          }
          if (that.addData.loginPwd != that.addData.reloginPwd) {
            that.$message.error('确认密码与输入密码不一致，请重新输入！');
            that.loading = false;
            that.dsate = false;
            return;
          }
          // 密码安全等级校验
          var weekCheck = that.safeRules.weakCheck.split(',');
          if (weekCheck.includes(that.addData.loginPwd)) {
            _this2.$confirm('密码安全等级太低,建议立即修改', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              showClose: false,
              showCancelButton: false,
              type: 'warning',
              center: true
            }).then(function () {
              that.addData.loginPwd = '';
              that.addData.reloginPwd = '';
            }).catch(function () {});
            return;
          }
          that.doAddUsers(param);
          /** 判断钉钉是否开启**/
          // getDDSwitch()
          //   .then((item) => {
          //     var key = item.data.data
          //     return key
          //   })
          //   .then((res) => {
          //     if (Number(res) == 1) {
          //       // 检验手机号的唯一性
          //       that.checkPhoneOnly(param)
          //     } else {
          //       // that.doAddUsers(param)
          //     }
          //   })
        } else {
          that.loading = false;
          that.dsate = false;
        }
      });
    },
    checkPhoneOnly: function checkPhoneOnly(param) {
      var that = this;
      var only = false;
      var phoneData = {};
      phoneData.mobile = that.addData.phoneNum;
      (0, _compyuser.checkOnly)(phoneData).then(function (res) {
        if (res.data.code != 200) {
          only = false;
          that.$message.error(res.data.msg);
        } else {
          only = true;
        }
        return only;
      }).then(function (res) {
        if (res) {
          that.doAddUsers(param);
        }
      });
    },
    doAddUsers: function doAddUsers(param) {
      var that = this;
      that.loading = true;
      that.dsate = true;
      (0, _compyuser.addCompyUser)(param).then(function (res) {
        if (res.data.code == 200) {
          that.$message({
            type: 'success',
            message: '添加成功'
          });
          that.loading = false;
          that.dsate = false;
          that.resetForm('addForm');
          that.$parent.getList();
        } else {
          that.$message.error(res.data.msg);
          that.loading = false;
          that.dsate = false;
        }
      }).catch(function () {
        that.loading = false;
        that.dsate = false;
      });
    },
    resetForm: function resetForm(formName) {
      var that = this;
      that.$refs[formName].resetFields();
      that.$parent.addShow = false;
      that.dialogVisible = false;
    },
    // 获取安全规则
    getRuleDetail: function getRuleDetail() {
      var _this3 = this;
      this.loading = true;
      (0, _pwdconfig.getPwdRuleList)().then(function (res) {
        if (res.data.data.code == 200) {
          if (res.data.data.data && res.data.data.data.length > 0) {
            _this3.safeRules = res.data.data.data[0];
            if (res.data.data.data[0].strongCheck == 1) {
              _this3.rules.loginPwd = [{
                required: true,
                trigger: 'blur',
                validator: function validator(rule, value, callback) {
                  var exReg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{9,20}$/;
                  if (!value) {
                    callback(new Error('请输入，密码长度9-20位，至少包含一个字母、数字、特殊字符'));
                  } else {
                    if (exReg.test(value)) {
                      callback();
                    } else {
                      callback(new Error('输入的密码长度9-20位，至少包含一个字母、数字、特殊字符'));
                    }
                  }
                }
              }];
            }
            if (res.data.data.data[0].strongCheck == 0) {
              _this3.rules.loginPwd = [{
                required: true,
                trigger: 'blur',
                validator: function validator(rule, value, callback) {
                  var exReg = /^[A-Za-z\d$@!%*#?&]{7,20}$/;
                  if (!value) {
                    callback(new Error('请输入，密码长度7-20位'));
                  } else {
                    if (exReg.test(value)) {
                      callback();
                    } else {
                      callback(new Error('输入的密码长度7-20位'));
                    }
                  }
                }
              }];
            }
          } else {
            _this3.safeRules = {};
          }
        }
        _this3.loading = false;
      }).catch(function () {
        _this3.loading = false;
      });
    }
  }
};
exports.default = _default;