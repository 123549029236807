var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-analyze-wrap" }, [
    _c(
      "div",
      { staticClass: "user-analyze-content" },
      [
        _c("div", { staticClass: "admincut-wrap" }),
        _vm._v(" "),
        _c("div", { staticClass: "user-analyze-top" }, [
          _c("div", { staticClass: "user-analyze-title" }, [
            _vm._v("用户分析"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "user-analyze-nav" },
            [
              _c(
                "router-link",
                { attrs: { to: "/wxAccounts/dataAlysis/yhfx" } },
                [_vm._v("用户分析")]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                { attrs: { to: "/wxAccounts/dataAlysis/yhData" } },
                [_vm._v("用户数据")]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("router-view"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }