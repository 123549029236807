"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.function.name");
var _racket_return = require("@/api/mark/coding_service/racket_return/racket_return");
var _waves = _interopRequireDefault(require("@/directive/waves"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var markType = [{
  id: 1,
  name: '未知'
}, {
  id: 2,
  name: '盖内码'
}, {
  id: 3,
  name: '盖外码'
}, {
  id: 4,
  name: '未知'
}, {
  id: 5,
  name: '未知'
}];
var _default = {
  name: 'ComplexTable',
  components: {
    Pagination: _Pagination.default
  },
  directives: {
    waves: _waves.default
  },
  filters: {
    markTypev: function markTypev(v) {
      var t = '盖层级';
      for (var i in markType) {
        if (markType[i].id == v) {
          t = markType[i].name;
          break;
        }
      }
      return t;
    }
  },
  data: function data() {
    return {
      tableKey: 0,
      total: 0,
      listLoading: false,
      excelOut_loading: false,
      list: null,
      dialogTitle: '错误码量详情',
      rowInfo: {
        digit: ''
      },
      dialogDigitalVisible: false,
      listQuery: {
        current: 1,
        size: 20,
        clientUploadId: ''
      },
      Column: {
        gueneShow: true
      },
      rules: {}
    };
  },
  watch: {},
  created: function created() {},
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _racket_return.queryErrMarkForPage)(this.listQuery).then(function (response) {
        var data = response.data;
        if (data.code != 200) {
          _this.listLoading = false;
          _this.$message.error(data.msg);
          return true;
        }
        _this.list = data.data.records;
        _this.total = Number(data.data.total);
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    Reset: function Reset() {
      this.dialogDigitalVisible = false;
    }
  }
};
exports.default = _default;