"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.to-string");
var _companySelectDialog = _interopRequireDefault(require("@/components/companySelectDialog"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _dictionary = require("@/api/systems/dictionary");
var _index = require("@/api/warehouse/checkout/index");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var TypeDataComs = {
  status: []
};
var _default = {
  name: 'table-suply',
  components: {
    Pagination: _Pagination.default,
    companySelectDialog: _companySelectDialog.default
  },
  filters: {
    formatDate: function (_formatDate) {
      function formatDate(_x) {
        return _formatDate.apply(this, arguments);
      }
      formatDate.toString = function () {
        return _formatDate.toString();
      };
      return formatDate;
    }(function (time) {
      var date = new Date(time);
      return formatDate(date, 'yyyy-MM-dd hh:mm:ss');
    }),
    fliterStatus: function fliterStatus(val) {
      var name = '';
      TypeDataComs.status.forEach(function (c) {
        if (c.figure == Number(val)) {
          name = c.name;
          return;
        }
      });
      return name;
    }
  },
  props: {
    codeVisible: Boolean,
    otherTypeId: String
  },
  data: function data() {
    return {
      searchForm: {
        current: 1,
        size: 10,
        otherTradeBillQuery: {
          billNo: '',
          startTime: '',
          endTime: '',
          inOrgId: '',
          outOrgId: '',
          otherTypeId: ''
        }
      },
      tableData: [],
      tableKey: '00010',
      total: 0,
      listLoading: false,
      handleShow: false,
      companyShow: false,
      searchInOrgName: '',
      searchOutOrgName: '',
      inOutType: '',
      companyType: ''
    };
  },
  watch: {
    codeVisible: function codeVisible(val) {
      if (val) {
        this.searchForm.otherTradeBillQuery.otherTypeId = this.otherTypeId;
        this.getDirSel();
        this.getList();
      }
    }
  },
  mounted: function mounted() {
    var that = this;
  },
  methods: {
    getDirSel: function getDirSel() {
      var _this = this;
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: 'CCDJMXZT'
      }).then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            TypeDataComs.status = res.data.data;
          } else {
            TypeDataComs.status = [];
          }
        }
      });
    },
    openCompany: function openCompany(name, companyType, inOutType) {
      this.inUseName = name;
      this.companyType = companyType;
      this.inOutType = inOutType;
      this.companyShow = true;
    },
    setCompany: function setCompany(val) {
      this[this.inUseName] = val.companyName;
      switch (this.inUseName) {
        case 'searchInOrgName':
          this.searchForm.otherTradeBillQuery.inOrgId = val.companyId;
          break;
        case 'searchOutOrgName':
          this.searchForm.otherTradeBillQuery.outOrgId = val.companyId;
          break;
      }
    },
    companyClose: function companyClose() {
      this.inUseName = '';
      this.companyType = '';
      this.inOutType = '';
      this.companyShow = false;
    },
    handleFilter: function handleFilter(_type) {
      this.searchForm.current = 1;
      this.searchForm.size = 10;
      this.getList();
    },
    resetForm: function resetForm(formName) {
      this.searchInOrgName = '';
      this.searchOutOrgName = '';
      this.searchForm.current = 1;
      this.searchForm.otherTradeBillQuery.billNo = '';
      this.searchForm.otherTradeBillQuery.startTime = '';
      this.searchForm.otherTradeBillQuery.endTime = '';
      this.searchForm.otherTradeBillQuery.inOrgId = '';
      this.searchForm.otherTradeBillQuery.outOrgId = '';
      this.getList();
    },
    getList: function getList() {
      var _this2 = this;
      var that = this;
      that.listLoading = true;
      var current = that.searchForm.current;
      var size = that.searchForm.size;
      var otherTradeBillQuery = that.searchForm.otherTradeBillQuery;
      (0, _index.getRelateOutCode)(current, size, otherTradeBillQuery).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          if (response.data.data != null) {
            that.tableData = response.data.data.records;
            that.total = parseInt(response.data.data.total);
          } else {
            that.tableData = [];
            that.total = 0;
          }
        }
        that.listLoading = false;
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    getflow: function getflow(row) {
      this.$emit('change', row);
      this.handleClose();
    },
    handleClick: function handleClick() {
      var that = this;
      that.handleShow = !that.handleShow;
    },
    handleClose: function handleClose() {
      var that = this;
      that.$emit('close');
    }
  }
};
exports.default = _default;