var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    [
      _c(
        "div",
        { staticClass: "hyyy-container" },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-card",
                    [
                      _c("div", { staticClass: "hyyy-top-tit" }, [
                        _c(
                          "div",
                          { staticClass: "hyyy-top-tit-lift" },
                          [
                            _c("span", {
                              staticStyle: {
                                "font-weight": "400",
                                color: "#333",
                              },
                              domProps: {
                                textContent: _vm._s(_vm.$route.meta.title),
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  placement: "top-start",
                                  title: "提示",
                                  width: "500",
                                  trigger: "hover",
                                },
                              },
                              [
                                _c("div", [
                                  _c("p", [_vm._v("说明：")]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v(
                                      "\n                      渠道二维码是通过设置二维码名称来标注公众号二维码，进而使公众号二维码带有参数，方便统计数据。\n                    "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", [_vm._v("作用：")]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v(
                                      "\n                      1.\n                      可进行各种场景标注，例如双十一活动、某某旗舰店、兆信防伪、销售命名、产品命名等等，进而可统计各个场景渠道的推广效果。\n                    "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v(
                                      "\n                      2.设置好二维码后就可以精准的推送消息给到对应场景渠道下的粉丝。\n                    "
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("i", {
                                  staticClass: "el-icon-question",
                                  staticStyle: {
                                    "font-size": "20px",
                                    color: "#e6a23c",
                                  },
                                  attrs: { slot: "reference" },
                                  slot: "reference",
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "hyyy-top-tit-right" },
                          [
                            _c("admincut", {
                              staticStyle: { "margin-top": "7px" },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-divider", { staticStyle: { margin: "0" } }),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { staticClass: "hyyy-content" },
                        [
                          _c("el-main", [
                            _c(
                              "div",
                              { staticClass: "search-content" },
                              [
                                _c(
                                  "el-form",
                                  {
                                    staticClass: "demo-form-inline",
                                    attrs: {
                                      "label-width": "100px",
                                      model: _vm.listQuery,
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "cols" },
                                      [
                                        _c(
                                          "el-row",
                                          { attrs: { gutter: 20 } },
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 6 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "0",
                                                    },
                                                    attrs: {
                                                      label: "二维码名称",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      attrs: {
                                                        placeholder: "请输入",
                                                        maxlength: "20",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.listQuery
                                                            .codeName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.listQuery,
                                                            "codeName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "listQuery.codeName",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 6 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "0",
                                                    },
                                                    attrs: {
                                                      label: "创建时间",
                                                    },
                                                  },
                                                  [
                                                    _c("el-date-picker", {
                                                      attrs: {
                                                        "value-format":
                                                          "yyyy-MM-dd",
                                                        type: "daterange",
                                                        "range-separator": "-",
                                                        "start-placeholder":
                                                          "开始日期",
                                                        "end-placeholder":
                                                          "结束日期",
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.getDateRange,
                                                      },
                                                      model: {
                                                        value: _vm.createTime,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.createTime = $$v
                                                        },
                                                        expression:
                                                          "createTime",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-col",
                                              {
                                                staticClass: "hyyy-head-btn",
                                                staticStyle: { width: "371px" },
                                                attrs: { span: 6 },
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        attrs: {
                                                          slot: "label",
                                                        },
                                                        slot: "label",
                                                      },
                                                      [_vm._v(" ")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-button",
                                                      {
                                                        staticStyle: {
                                                          "font-size": "14px",
                                                        },
                                                        attrs: {
                                                          type: "primary",
                                                          size: "small",
                                                        },
                                                        on: {
                                                          click: _vm.queryList,
                                                        },
                                                      },
                                                      [_vm._v("查询")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-button",
                                                      {
                                                        staticStyle: {
                                                          "font-size": "14px",
                                                        },
                                                        attrs: {
                                                          type: "primary",
                                                          size: "small",
                                                        },
                                                        on: {
                                                          click: _vm.creatCode,
                                                        },
                                                      },
                                                      [_vm._v("创建二维码")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-button",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "loading",
                                                            rawName:
                                                              "v-loading",
                                                            value:
                                                              _vm.downLoading,
                                                            expression:
                                                              "downLoading",
                                                          },
                                                        ],
                                                        staticStyle: {
                                                          "font-size": "14px",
                                                        },
                                                        attrs: {
                                                          type: "default",
                                                          size: "small",
                                                        },
                                                        on: {
                                                          click: _vm.downLoad,
                                                        },
                                                      },
                                                      [_vm._v("下载明细")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "table-container" },
                              [
                                _c("div", {
                                  staticClass: "btns-group align-right",
                                }),
                                _vm._v(" "),
                                _c(
                                  "el-table",
                                  {
                                    directives: [
                                      {
                                        name: "loading",
                                        rawName: "v-loading",
                                        value: _vm.loading,
                                        expression: "loading",
                                      },
                                    ],
                                    staticStyle: { width: "100%" },
                                    attrs: { data: _vm.tableData, border: "" },
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "codeName",
                                        align: "center",
                                        "show-overflow-tooltip": "",
                                        label: "二维码名称",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "scanNumber",
                                        align: "center",
                                        sortable: "",
                                        label: "累计扫码人数",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "addFansNumber",
                                        align: "center",
                                        sortable: "",
                                        label: "净增粉丝数",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "cancelNumber",
                                        align: "center",
                                        sortable: "",
                                        label: "已取消关注数",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "replyStatus",
                                        align: "center",
                                        label: "消息回复状态",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var row = ref.row
                                            return [
                                              row.replyStatus == "0"
                                                ? _c("span", [_vm._v("未设置")])
                                                : _c("span", [
                                                    _vm._v("已设置"),
                                                  ]),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "createTime",
                                        align: "center",
                                        label: "创建时间",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        align: "center",
                                        label: "操作",
                                        width: "380",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var row = ref.row
                                            return [
                                              _c(
                                                "el-button",
                                                {
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                  },
                                                  attrs: {
                                                    type: "default",
                                                    size: "mini",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.replymsg(row)
                                                    },
                                                  },
                                                },
                                                [_vm._v("消息回复")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-button",
                                                {
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                  },
                                                  attrs: {
                                                    type: "default",
                                                    size: "mini",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.downImg(row)
                                                    },
                                                  },
                                                },
                                                [_vm._v("预览")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-button",
                                                {
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                  },
                                                  attrs: {
                                                    type: "danger",
                                                    size: "mini",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.DoDelete(row)
                                                    },
                                                  },
                                                },
                                                [_vm._v("删除")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-button",
                                                {
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                  },
                                                  attrs: {
                                                    type: "primary",
                                                    size: "mini",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.scan(row)
                                                    },
                                                  },
                                                },
                                                [_vm._v("查看数据")]
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("pagination", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.total > 0,
                                      expression: "total > 0",
                                    },
                                  ],
                                  attrs: {
                                    layout:
                                      "total, sizes, prev, pager, next, jumper",
                                    total: _vm.total,
                                    page: _vm.listQuery.pageNo,
                                    limit: _vm.listQuery.pageSize,
                                  },
                                  on: {
                                    "update:page": function ($event) {
                                      return _vm.$set(
                                        _vm.listQuery,
                                        "pageNo",
                                        $event
                                      )
                                    },
                                    "update:limit": function ($event) {
                                      return _vm.$set(
                                        _vm.listQuery,
                                        "pageSize",
                                        $event
                                      )
                                    },
                                    pagination: _vm.getCodeList,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "创建二维码", visible: _vm.dialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "100px", model: _vm.listQuery2 } },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: {
                    "margin-bottom": "10px",
                    width: "500px",
                    margin: "0 auto",
                  },
                  attrs: { label: "二维码名称:" },
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "20" },
                    model: {
                      value: _vm.listQuery2.codeName,
                      callback: function ($$v) {
                        _vm.$set(_vm.listQuery2, "codeName", $$v)
                      },
                      expression: "listQuery2.codeName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { padding: "0 14px", "text-align": "center" } },
            [
              _vm._v(
                "\n      提示：生成二维码需要一定时间，需手动刷新后再进行预览二维码\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "btn-group" },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "font-size": "14px" },
                  attrs: {
                    type: "primary",
                    size: "small",
                    disabled: _vm.disabled,
                  },
                  on: { click: _vm.DoSave },
                },
                [_vm._v("保存")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: { "font-size": "14px" },
                  attrs: { type: "default", size: "small" },
                  on: { click: _vm.DoCancel },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "msgdlo",
          attrs: { title: "", visible: _vm.dialogMsg },
          on: {
            "update:visible": function ($event) {
              _vm.dialogMsg = $event
            },
          },
        },
        [
          _c("h2", [_vm._v("消息回复")]),
          _vm._v(" "),
          _c("h6", [
            _vm._v("渠道二维码来源用户关注公众号后收到的自动回复内容"),
          ]),
          _vm._v(" "),
          _c("p", [_vm._v("二维码名称：自动回复内容")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "btn-group" },
            [
              _c(
                "el-tabs",
                {
                  staticStyle: { height: "280px", "margin-bottom": "20px" },
                  attrs: { type: "border-card" },
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.modelData.contentType,
                    callback: function ($$v) {
                      _vm.$set(_vm.modelData, "contentType", $$v)
                    },
                    expression: "modelData.contentType",
                  },
                },
                [
                  _c("el-tab-pane", { attrs: { name: "1" } }, [
                    _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                      _c("i", { staticClass: "el-icon-date" }),
                      _vm._v("文字"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "richTxtc" },
                      [
                        _c("tiny-mce", {
                          ref: "aaa",
                          staticStyle: {
                            width: "100%",
                            "margin-bottom": "30px",
                          },
                          attrs: {
                            "tinymce-height": 150,
                            plugins: _vm.plugins,
                            toolbar: _vm.toolbar,
                          },
                          on: { input: _vm.asdf },
                          model: {
                            value: _vm.msgData,
                            callback: function ($$v) {
                              _vm.msgData = $$v
                            },
                            expression: "msgData",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-tab-pane", { attrs: { name: "2" } }, [
                    _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                      _c("i", { staticClass: "el-icon-picture-outline" }),
                      _vm._v(" 图片"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "richTxtc" },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.picData.length == 0 ? true : false,
                                expression:
                                  "picData.length == 0 ? true : false",
                              },
                            ],
                            staticClass: "flex-box",
                            staticStyle: { height: "180px" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "flex-primary align-center" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "success" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.choseElement("3")
                                      },
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "el-icon-folder" }),
                                    _vm._v(" 从素材库选择"),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.picData.length == 0 ? false : true,
                                expression:
                                  "picData.length == 0 ? false : true",
                              },
                            ],
                            attrs: { gutter: 20, model: _vm.picData },
                          },
                          [
                            _c("el-col", { attrs: { span: 16 } }, [
                              _c("div", { staticClass: "stxt" }, [
                                _c("div", { staticClass: "imgc" }, [
                                  _c("img", {
                                    attrs: { src: _vm.picData.url },
                                  }),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("el-col", { attrs: { span: 4 } }, [
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeItem("3")
                                        },
                                      },
                                    },
                                    [_vm._v("替换素材")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: { click: _vm.viewImg },
                                    },
                                    [_vm._v("预览")]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-tab-pane", { attrs: { name: "3" } }, [
                    _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                      _c("i", { staticClass: "el-icon-service" }),
                      _vm._v("语音"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "richTxtc" },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.voiceData.length == 0 ? true : false,
                                expression:
                                  "voiceData.length == 0 ? true : false",
                              },
                            ],
                            staticClass: "flex-box",
                            staticStyle: { height: "180px" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "flex-primary align-center" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "success" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.choseElement("2")
                                      },
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "el-icon-folder" }),
                                    _vm._v(" 从素材库选择"),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.voiceData.length == 0 ? false : true,
                                expression:
                                  "voiceData.length == 0 ? false : true",
                              },
                            ],
                            attrs: { gutter: 20, model: _vm.voiceData },
                          },
                          [
                            _c("el-col", { attrs: { span: 16 } }, [
                              _c("div", { staticClass: "stxt" }, [
                                _c("div", { staticClass: "flex-box" }, [
                                  _c("div", { staticClass: "flex-primary" }, [
                                    _c("p", [
                                      _vm._v(_vm._s(_vm.voiceData.name)),
                                    ]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "50px",
                                      margin: "0 auto",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-service",
                                      staticStyle: { "font-size": "50px" },
                                    }),
                                  ]
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("el-col", { attrs: { span: 4 } }, [
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeItem("2")
                                        },
                                      },
                                    },
                                    [_vm._v("替换素材")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.getAudio(_vm.voiceData)
                                        },
                                      },
                                    },
                                    [_vm._v("下载")]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-tab-pane", { attrs: { name: "4" } }, [
                    _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                      _c("i", { staticClass: "el-icon-picture-outline" }),
                      _vm._v(" 视频"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "richTxtc" },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.videoData.length == 0 ? true : false,
                                expression:
                                  "videoData.length == 0 ? true : false",
                              },
                            ],
                            staticClass: "flex-box",
                            staticStyle: { height: "180px" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "flex-primary align-center" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "success" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.choseElement("1")
                                      },
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "el-icon-folder" }),
                                    _vm._v(" 从素材库选择"),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.videoData.length == 0 ? false : true,
                                expression:
                                  "videoData.length == 0 ? false : true",
                              },
                            ],
                            attrs: { gutter: 20, model: _vm.videoData },
                          },
                          [
                            _c("el-col", { attrs: { span: 16 } }, [
                              _c("div", { staticClass: "stxt" }, [
                                _c("div", { staticClass: "flex-box" }, [
                                  _c("div", { staticClass: "flex-primary" }, [
                                    _c("p", [
                                      _vm._v(_vm._s(_vm.videoData.name)),
                                    ]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "50px",
                                      margin: "0 auto",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-caret-right",
                                      staticStyle: {
                                        "font-size": "50px",
                                        border: "1px solid #ddd",
                                        "border-radius": "50%",
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("el-col", { attrs: { span: 4 } }, [
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeItem("1")
                                        },
                                      },
                                    },
                                    [_vm._v("替换素材")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.getVideo(
                                            _vm.videoData.mediaId
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("预览")]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: { "font-size": "14px" },
                  attrs: {
                    type: "primary",
                    size: "small",
                    disabled: _vm.disabled,
                  },
                  on: { click: _vm.Savemsg },
                },
                [_vm._v("保存")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: { "font-size": "14px" },
                  attrs: { type: "default", size: "small" },
                  on: { click: _vm.Nomsg },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "请选择图片",
            "append-to-body": "",
            visible: _vm.dialogVisible7,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible7 = $event
            },
          },
        },
        [
          _c("div", { staticClass: "txt-container" }, [
            _c(
              "div",
              { staticClass: "txt-height-auto" },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  _vm._l(_vm.mediaData, function (item, index) {
                    return _c("el-col", { key: index, attrs: { span: 8 } }, [
                      _c(
                        "div",
                        {
                          class: {
                            stxt: true,
                            selected: _vm.isSelected == index ? true : false,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.selectItem(item, index)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "imgc" }, [
                            _c("img", { attrs: { src: item.uploadUrl } }),
                          ]),
                        ]
                      ),
                    ])
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              page: _vm.listQuery2.pageNo,
              limit: _vm.listQuery2.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.listQuery2, "pageNo", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.listQuery2, "pageSize", $event)
              },
              pagination: _vm.getList2,
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "btn-group" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.makeSure("2")
                    },
                  },
                },
                [_vm._v("确定")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.DoCancel("2")
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "请选择语音",
            "append-to-body": "",
            visible: _vm.dialogVisible3,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible3 = $event
            },
          },
        },
        [
          _c("div", { staticClass: "txt-container" }, [
            _c(
              "div",
              { staticClass: "txt-height-auto" },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  _vm._l(_vm.mediaData, function (item, index) {
                    return _c("el-col", { key: index, attrs: { span: 8 } }, [
                      _c(
                        "div",
                        {
                          class: {
                            stxt: true,
                            selected: _vm.isSelected == index ? true : false,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.selectItem(item, index)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "flex-box" }, [
                            _c("div", { staticClass: "flex-primary" }, [
                              _c("p", [_vm._v(_vm._s(item.name))]),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  _vm._s(_vm._f("filterTime")(item.createTime))
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c("i", {
                                staticClass: "el-icon-service",
                                staticStyle: { "font-size": "20px" },
                              }),
                            ]),
                          ]),
                        ]
                      ),
                    ])
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              page: _vm.listQuery2.pageNo,
              limit: _vm.listQuery2.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.listQuery2, "pageNo", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.listQuery2, "pageSize", $event)
              },
              pagination: _vm.getList2,
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "btn-group" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.makeSure("3")
                    },
                  },
                },
                [_vm._v("确定")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.DoCancel("3")
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "请选择视频",
            "append-to-body": "",
            visible: _vm.dialogVisible4,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible4 = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              ref: "singleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.mediaData,
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "name", label: "名称", width: "180" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "fileSize", label: "大小", width: "180" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "状态", width: "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.mediaId == "" || null
                          ? _c("el-col", [_vm._v("未通过")])
                          : _c("el-col", [_vm._v("通过")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "createTime", label: "更新时间", width: "230" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.createTime) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", width: "330" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.selectItem(scope.row, scope.$index)
                              },
                            },
                          },
                          [_vm._v("选中")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              page: _vm.listQuery2.pageNo,
              limit: _vm.listQuery2.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.listQuery2, "pageNo", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.listQuery2, "pageSize", $event)
              },
              pagination: _vm.getList2,
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "btn-group" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.makeSure("4")
                    },
                  },
                },
                [_vm._v("确定")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.DoCancel("4")
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "视频", visible: _vm.dialogVisible6 },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible6 = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: { width: "70%", height: "450px", margin: "0 auto" },
            },
            [
              _c(
                "video",
                {
                  attrs: {
                    width: "100%",
                    height: "390",
                    controls: "controls",
                    src: _vm.video,
                  },
                },
                [_vm._v("\n        您的浏览器不支持video标签！\n      ")]
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "图片", visible: _vm.dialogVisible8 },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible8 = $event
            },
          },
        },
        [
          _c("div", { staticStyle: { "min-height": "400px" } }, [
            _c("img", {
              staticStyle: { width: "60%", display: "block", margin: "0 auto" },
              attrs: { src: _vm.preImg },
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "数据分析", visible: _vm.dialogVisible2 },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible2 = $event
            },
          },
        },
        [
          _c("div", { staticStyle: { "font-size": "14px", padding: "10px" } }, [
            _vm._v("\n      二维码名称：" + _vm._s(_vm.nameTxt) + "\n    "),
          ]),
          _vm._v(" "),
          _c(
            "el-row",
            { attrs: { gutter: 20, type: "flex" } },
            [
              _c(
                "el-col",
                { staticStyle: { width: "240px" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "font-size": "14px" },
                      attrs: { type: _vm.primary1 },
                      on: { click: _vm.getSevenDay },
                    },
                    [_vm._v("最近7天")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "font-size": "14px" },
                      attrs: { type: _vm.primary2 },
                      on: { click: _vm.getThirtyDay },
                    },
                    [_vm._v("最近30天")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 11 } },
                [
                  _c("el-date-picker", {
                    class: { "is-active": _vm.isActive },
                    staticStyle: { width: "100%" },
                    attrs: {
                      "value-format": "yyyy-MM-dd",
                      type: "daterange",
                      "range-separator": "-",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                    },
                    on: { change: _vm.getChangeDate },
                    model: {
                      value: _vm.value1,
                      callback: function ($$v) {
                        _vm.value1 = $$v
                      },
                      expression: "value1",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { padding: "20px 10px" } },
            [
              _c(
                "el-row",
                {
                  attrs: {
                    type: "flex",
                    justify: "center",
                    gutter: 10,
                    model: _vm.modelData,
                  },
                },
                [
                  _c("el-col", { attrs: { span: 5 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "navItem",
                        class: { selected: _vm.selected == 1 },
                        on: {
                          click: function ($event) {
                            return _vm.getLine("1")
                          },
                        },
                      },
                      [
                        _vm._v("\n            累计扫码人数\n            "),
                        _c("span", { staticClass: "num" }, [
                          _vm._v(_vm._s(_vm.modelData.scanNumber)),
                        ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 5 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "navItem",
                        class: { selected: _vm.selected == 2 },
                        on: {
                          click: function ($event) {
                            return _vm.getLine("2")
                          },
                        },
                      },
                      [
                        _vm._v("\n            净增粉丝数\n            "),
                        _c("span", { staticClass: "num" }, [
                          _vm._v(_vm._s(_vm.modelData.addFansNumber)),
                        ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 5 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "navItem",
                        class: { selected: _vm.selected == 3 },
                        on: {
                          click: function ($event) {
                            return _vm.getLine("3")
                          },
                        },
                      },
                      [
                        _vm._v("\n            已取消关注数\n            "),
                        _c("span", { staticClass: "num" }, [
                          _vm._v(_vm._s(_vm.modelData.cancelNumber)),
                        ]),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  { staticClass: "echarts" },
                  [
                    _c("line-chart", {
                      staticStyle: { margin: "0 auto" },
                      attrs: { "chart-data": _vm.lineChartData },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }