var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        "append-to-body": true,
        title: "选择关联单号",
        visible: _vm.codeVisible,
        "before-close": _vm.handleClose,
        width: "1000px !important",
      },
      on: {
        "update:visible": function ($event) {
          _vm.codeVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c("h3", { staticClass: "h-title" }, [_vm._v("其他出库")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page-section" },
            [
              _c(
                "el-form",
                {
                  ref: "searchForm",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.searchForm,
                    "status-icon": "",
                    "label-suffix": ":",
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cols" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 10 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { "margin-bottom": "5px" },
                                  attrs: { prop: "billNo", label: "单号" },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                      maxlength: "20",
                                    },
                                    model: {
                                      value: _vm.searchForm.billNo,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.searchForm, "billNo", $$v)
                                      },
                                      expression: "searchForm.billNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { "margin-bottom": "5px" },
                                  attrs: { prop: "inOrgId", label: "收货单位" },
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      attrs: { readonly: "" },
                                      model: {
                                        value: _vm.searchInOrgName,
                                        callback: function ($$v) {
                                          _vm.searchInOrgName = $$v
                                        },
                                        expression: "searchInOrgName",
                                      },
                                    },
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          slot: "append",
                                          icon: "el-icon-search",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openCompany(
                                              "searchInOrgName",
                                              1,
                                              3
                                            )
                                          },
                                        },
                                        slot: "append",
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 8 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { "margin-bottom": "5px" },
                                  attrs: {
                                    prop: "outOrgId",
                                    label: "发货单位",
                                  },
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      attrs: { readonly: "" },
                                      model: {
                                        value: _vm.searchOutOrgName,
                                        callback: function ($$v) {
                                          _vm.searchOutOrgName = $$v
                                        },
                                        expression: "searchOutOrgName",
                                      },
                                    },
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          slot: "append",
                                          icon: "el-icon-search",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openCompany(
                                              "searchOutOrgName",
                                              2,
                                              3
                                            )
                                          },
                                        },
                                        slot: "append",
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 8 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { "margin-bottom": "5px" },
                                  attrs: {
                                    prop: "startTime",
                                    label: "起始时间",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "date",
                                      placeholder: "创建起始日期",
                                      "value-format": "yyyy-MM-dd",
                                    },
                                    model: {
                                      value: _vm.searchForm.startTime,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "startTime",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.startTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.handleShow,
                                  expression: "handleShow",
                                },
                              ],
                              attrs: { span: 8 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { "margin-bottom": "5px" },
                                  attrs: { prop: "endTime", label: "结束时间" },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "date",
                                      placeholder: "创建结束日期",
                                      "value-format": "yyyy-MM-dd",
                                    },
                                    model: {
                                      value: _vm.searchForm.endTime,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.searchForm, "endTime", $$v)
                                      },
                                      expression: "searchForm.endTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "danger",
                                    size: "mini",
                                    icon: "el-icon-search",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleFilter("search")
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                查询\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    icon: "el-icon-refresh",
                                  },
                                  on: { click: _vm.resetForm },
                                },
                                [
                                  _vm._v(
                                    "\n                重置\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: { click: _vm.handleClick },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.handleShow ? "收起" : "展开") +
                                      "\n                "
                                  ),
                                  _c("i", {
                                    class: _vm.handleShow
                                      ? "el-icon-arrow-up"
                                      : "el-icon-arrow-down",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  key: _vm.tableKey,
                  staticStyle: { width: "100%" },
                  attrs: {
                    height: "450",
                    data: _vm.tableData,
                    border: "",
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      align: "center",
                      width: "80",
                      label: "序号",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "单号",
                      prop: "billNo",
                      "min-width": "155",
                      align: "center",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "关联单号",
                      prop: "relateNo",
                      "min-width": "155",
                      align: "center",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "业务类型",
                      prop: "otherTypeName",
                      "min-width": "140",
                      align: "center",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "发货单位",
                      prop: "outOrgName",
                      "min-width": "140",
                      align: "center",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "收货单位",
                      prop: "inOrgName",
                      "min-width": "140",
                      align: "center",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "发货库房",
                      prop: "outStoreName",
                      "min-width": "140",
                      align: "center",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "完成进度",
                      "min-width": "140",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("el-progress", {
                              attrs: {
                                "text-inside": true,
                                "stroke-linecap": "square",
                                "stroke-width": 16,
                                percentage: Number(row.progress),
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "单据状态",
                      "min-width": "140",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm._f("fliterStatus")(row.billStatus))
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "创建人",
                      prop: "createUser",
                      "min-width": "140",
                      align: "center",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "创建时间",
                      prop: "createTime",
                      "min-width": "180",
                      align: "center",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      fixed: "right",
                      align: "center",
                      width: "100",
                      "class-name": "small-padding fixed-width",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.getflow(row)
                                  },
                                },
                              },
                              [_vm._v("选择")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  layout: "total,sizes,prev, pager, next, jumper",
                  total: _vm.total,
                  page: _vm.searchForm.current,
                  limit: _vm.searchForm.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.searchForm, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.searchForm, "size", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.companyShow
            ? _c("companySelectDialog", {
                attrs: {
                  visible: _vm.companyShow,
                  inOutType: _vm.inOutType,
                  companyType: _vm.companyType,
                },
                on: { change: _vm.setCompany, close: _vm.companyClose },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }