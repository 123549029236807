"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _log = require("@/api/terminal/log");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _productSelectDialog = _interopRequireDefault(require("@/components/productSelectDialog"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = "marketingManagelogoreplacementLogindex";
var _default = {
  components: {
    Pagination: _Pagination.default,
    productSelectDialog: _productSelectDialog.default
  },
  name: "marketingManagelogoreplacementLogindex",
  data: function data() {
    return {
      params: {
        pageNo: 1,
        pageSize: 10
      },
      total: 10,
      formInline: {
        drawEndTime: "",
        drawStartTime: "",
        endTime: "",
        productId: "",
        productName: "",
        searchField: "",
        startTime: "",
        username: ""
      },
      rangeTime1: "",
      rangeTime2: "",
      listLoading: false,
      tableData: [],
      tabHeight: "100%"
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    var hasCache1 = this.tableData.length > 0 ? true : false;
    if (!hasCache1) {
      this.loglist();
    }
  },
  deactivated: function deactivated() {
    var name = cachName;
    var cache = this.getCaches(this, name);
    if (!cache) {
      this.tableData = [];
      this.total = 0;
    }
  },
  methods: {
    loglist: function loglist() {
      var that = this;
      that.listLoading = true;
      (0, _log.loglist)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.params), this.formInline)).then(function (res) {
        if (res.data.data.errCode) {
          that.$message.error(res.data.data.errMsg);
          that.listLoading = false;
          return;
        } else {
          if (res.data.data != null) {
            that.total = parseInt(res.data.data.total);
            that.tableData = res.data.data.records;
            setTimeout(function () {
              that.listLoading = false;
            }, 1 * 800);
          } else {
            that.total = 0;
            that.tableData = [];
            that.listLoading = false;
          }
        }
      }).catch(function (err) {
        that.listLoading = false;
      });
    },
    // 时间选择
    sj1: function sj1(res) {
      if (res) {
        this.formInline.startTime = res[0];
        this.formInline.endTime = res[1];
      } else {
        this.formInline.startTime = "";
        this.formInline.endTime = "";
      }
    },
    sj2: function sj2(res) {
      if (res) {
        this.formInline.drawStartTime = res[0];
        this.formInline.drawEndTime = res[1];
      } else {
        this.formInline.drawStartTime = "";
        this.formInline.drawEndTime = "";
      }
    },
    //选择产品
    choseProduct: function choseProduct() {
      this.$refs.proSelect.proSelVisible = true;
    },
    selectPro: function selectPro(val) {
      this.formInline.productId = val.pId;
      this.formInline.productName = val.productName;
    },
    // 搜索
    search: function search() {
      this.params.pageNo = 1;
      this.loglist();
    },
    // 重置搜索框
    reset: function reset() {
      this.params.pageNo = 1;
      this.$refs["formInline"].resetFields();
      this.rangeTime1 = "";
      this.rangeTime2 = "";
      this.formInline.startTime = "";
      this.formInline.endTime = "";
      this.formInline.drawStartTime = "";
      this.formInline.drawEndTime = "";
      this.formInline.productName = "";
      this.loglist();
    }
  }
};
exports.default = _default;