var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "资源管理-新增",
        visible: _vm.dialogVisible,
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.mloading,
              expression: "mloading",
            },
          ],
          ref: "formData",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "资源编码", prop: "resourceCode" } },
            [
              _c("el-input", {
                attrs: { maxlength: "50" },
                on: {
                  input: function (e) {
                    return (_vm.formData.resourceCode = _vm.validTe(e))
                  },
                },
                model: {
                  value: _vm.formData.resourceCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "resourceCode", $$v)
                  },
                  expression: "formData.resourceCode",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "资源名称", prop: "resourceName" } },
            [
              _c("el-input", {
                attrs: { maxlength: "20" },
                model: {
                  value: _vm.formData.resourceName,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "resourceName", $$v)
                  },
                  expression: "formData.resourceName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          false
            ? _c(
                "el-form-item",
                { attrs: { label: "上级名称", prop: "parentName" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.formData.parentName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "parentName", $$v)
                      },
                      expression: "formData.parentName",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "所属系统", prop: "clientCode" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { disabled: _vm.type == "Y" },
                  model: {
                    value: _vm.formData.clientCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "clientCode", $$v)
                    },
                    expression: "formData.clientCode",
                  },
                },
                _vm._l(_vm.cilentTypes, function (item) {
                  return _c("el-option", {
                    key: item.figure,
                    attrs: { label: item.name, value: item.figure },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "资源类型", prop: "resourceType" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  on: { change: _vm.getKey },
                  model: {
                    value: _vm.formData.resourceType,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "resourceType", $$v)
                    },
                    expression: "formData.resourceType",
                  },
                },
                _vm._l(_vm.resourceTypes, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "资源分类", prop: "menuClass" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.formData.menuClass,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "menuClass", $$v)
                    },
                    expression: "formData.menuClass",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("系统菜单")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("业务菜单")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "页面URL", prop: "resourceUrl" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.formData.resourceUrl,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "resourceUrl", $$v)
                  },
                  expression: "formData.resourceUrl",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.formData.resourcePath, function (item, index) {
            return _c(
              "el-form-item",
              {
                key: index,
                attrs: { id: "houurl", label: index > 0 ? "" : "后台接口URL" },
              },
              [
                _c("el-input", {
                  model: {
                    value: item.url,
                    callback: function ($$v) {
                      _vm.$set(item, "url", $$v)
                    },
                    expression: "item.url",
                  },
                }),
                _vm._v(" "),
                index == 0
                  ? _c("i", {
                      staticClass: "el-icon-circle-plus",
                      staticStyle: {
                        color: "#5f7fff",
                        "font-size": "24px",
                        "margin-left": "10px",
                      },
                      on: { click: _vm.addurl },
                    })
                  : _vm._e(),
                _vm._v(" "),
                index > 0
                  ? _c("i", {
                      staticClass: "el-icon-remove",
                      staticStyle: {
                        color: "#5f7fff",
                        "font-size": "24px",
                        "margin-left": "10px",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.deleteurl(index)
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          }),
          _vm._v(" "),
          (_vm.key == "2" ? true : false)
            ? _c(
                "el-form-item",
                { attrs: { label: "资源ID", prop: "resourceId" } },
                [
                  _c("el-input", {
                    ref: "resourceId",
                    attrs: { maxlength: "20" },
                    model: {
                      value: _vm.formData.resourceId,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "resourceId", $$v)
                      },
                      expression: "formData.resourceId",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "排序序号", prop: "resourceSort" } },
            [
              _c("el-input", {
                attrs: { maxlength: "4" },
                model: {
                  value: _vm.formData.resourceSort,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "resourceSort", $$v)
                  },
                  expression: "formData.resourceSort",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "备注", prop: "remark" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  maxlength: "100",
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.formData.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "remark", $$v)
                  },
                  expression: "formData.remark",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-form-item", [
            _c(
              "div",
              { staticClass: "align-right" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", disabled: _vm.dstate },
                    on: {
                      click: function ($event) {
                        return _vm.submitForm("formData")
                      },
                    },
                  },
                  [_vm._v("确定")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.resetForm("formData")
                      },
                    },
                  },
                  [_vm._v("取消")]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }