"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _qrcodejs = _interopRequireDefault(require("qrcodejs2"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManagewriteOffModulesalesmanRegisterindex'; // 缓存名字为当前path去掉/
var _default = {
  name: 'MarketingManagewriteOffModulesalesmanRegisterindex',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {};
  },
  activated: function activated() {
    // var hasCache1 = this.tableData.length > 0
    // if (!hasCache1) {
    //   this.getSalesmanList()
    // }
    this.creatQrCode();
  },
  deactivated: function deactivated() {
    var name = cachName;
    var cache = this.getCaches(this, name);
    if (!cache) {
      // this.tableData = []
      // this.total = 0
    }
  },
  methods: {
    creatQrCode: function creatQrCode() {
      var qrcode = new _qrcodejs.default(this.$refs.qrCodeUrl, {
        text: 'https://yx.hysp.cn/e/ywy/index.html?c_id=1397753354437734402',
        // 需要转换为二维码的内容
        width: 200,
        height: 200,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: _qrcodejs.default.CorrectLevel.H
      });
    }
  }
};
exports.default = _default;