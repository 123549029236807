"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _compyuser = require("@/api/systems/compyuser");
var _dictionary = require("@/api/systems/dictionary");
var _salesArea = require("@/api/basic/salesArea");
var _utils = require("@/utils");
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Edit',
  components: {
    Treeselect: _vueTreeselect.default
  },
  props: {
    editData: Object,
    edit: Boolean
  },
  data: function data() {
    return {
      dialogVisible: false,
      mloading: false,
      dsate: false,
      options: [],
      regionList: [],
      groupList: [],
      postList: [],
      formData: {},
      rules: {
        loginAccount: [{
          required: true,
          message: '请输入用户名',
          trigger: 'blur'
        }],
        userName: [{
          required: true,
          message: '请输入姓名',
          trigger: 'blur'
        }],
        orgId: [{
          required: true,
          message: '请选择所属组织',
          trigger: 'blur'
        }],
        regionId: [{
          required: false,
          message: '请选择销售组织',
          trigger: 'blur'
        }],
        loginPwd: [{
          required: true,
          validator: _compyuser.checkPwd,
          trigger: 'blur'
        }],
        reloginPwd: [{
          required: true,
          message: '请选择所属组织',
          trigger: 'blur'
        }],
        // postCode: [
        //   {
        //     required: true,
        //     message: '请选择岗位',
        //     trigger: 'blur'
        //   }
        // ],
        // groupCode: [
        //   {
        //     required: true,
        //     message: '请选择分组',
        //     trigger: 'blur'
        //   }
        // ],
        phoneNum: [{
          required: true,
          validator: _compyuser.checkPhone,
          trigger: 'blur'
        }],
        email: [{
          required: true,
          validator: _compyuser.checkEmail,
          trigger: 'blur'
        }]
      },
      dstate: false
    };
  },
  watch: {
    edit: function edit(val) {
      var that = this;
      if (val == true) {
        that.dialogVisible = true;
        that.options = that.$parent.options;
        that.formData = (0, _utils.deepClone)(that.$parent.editData);
        that.formData.postCode = Number(that.formData.postCode) || null;
        that.formData.groupCode = Number(that.formData.groupCode) || null;
      } else {
        that.dialogVisible = false;
        that.formData = {};
      }
    }
  },
  mounted: function mounted() {
    // 销售组织
    this.getregionList();
    // 分组
    this.getDirSel('FZ100', 'groupList');
    // 岗位
    this.getDirSel('GC100', 'postList');
  },
  methods: {
    getregionList: function getregionList() {
      var that = this;
      (0, _salesArea.selectLargeRegionByType)({
        regionType: 1
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          that.regionList = response.data.data;
        }
      });
    },
    getDirSel: function getDirSel(code, list) {
      var _this = this;
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: code
      }).then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this[list] = res.data.data;
          } else {
            _this[list] = [];
          }
        }
      });
    },
    handleClose: function handleClose() {
      var that = this;
      that.resetForm('editForm');
    },
    submitForm: function submitForm(formName) {
      var that = this;
      var only = false;
      that.$refs[formName].validate(function (valid) {
        if (valid) {
          var param = JSON.parse(JSON.stringify(that.formData));
          param.userType = 0;
          if (param.isEnable == true) {
            param.isEnable = 1;
          } else {
            param.isEnable = 0;
          }

          /** 判断钉钉是否开启**/
          (0, _compyuser.getDDSwitch)().then(function (item) {
            console.log(item, 1);
            var key = item.data.data;
            return key;
          }).then(function (res) {
            console.log(res, 2);
            if (Number(res) == 1) {
              // 检验手机号的唯一性
              that.checkPhoneOnly(param);
            } else {
              that.doEditUsers(param);
            }
          });
        }
      });
    },
    checkPhoneOnly: function checkPhoneOnly(param) {
      var that = this;
      var only = false;
      var phoneData = {};
      phoneData.mobile = that.addData.phoneNum;
      (0, _compyuser.checkOnly)(phoneData).then(function (res) {
        console.log(res, 3);
        if (res.data.code != 200) {
          only = false;
          that.$message.error(res.data.msg);
        } else {
          only = true;
        }
        return only;
      }).then(function (res) {
        console.log(res, 4);
        if (res) {
          that.doEditUsers(param);
        }
      });
    },
    doEditUsers: function doEditUsers(param) {
      var that = this;
      that.dsate = true;
      (0, _compyuser.editUserInfo)(param).then(function (res) {
        if (res.data.code == 200) {
          that.$message({
            type: 'success',
            message: '修改成功'
          });
          that.mloading = false;
          that.dsate = false;
          that.resetForm('editForm');
          that.$parent.getList();
        } else {
          that.mloading = false;
          that.dsate = false;
          that.$message.error(res.data.msg);
        }
      }).catch(function () {
        that.mloading = false;
        that.dsate = false;
      });
    },
    resetForm: function resetForm(formName) {
      var that = this;
      that.$refs[formName].resetFields();
      that.$parent.editShow = false;
      that.dialogVisible = false;
    }
  }
};
exports.default = _default;