"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _typeof2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
require("core-js/modules/web.dom.iterable");
var _suplyrole = require("@/api/systems/suplyrole");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _edit = _interopRequireDefault(require("./components/edit"));
var _scan = _interopRequireDefault(require("./components/scan"));
var _add = _interopRequireDefault(require("./components/add"));
var _auth = _interopRequireDefault(require("./components/auth"));
var _scanAuth = _interopRequireDefault(require("./components/scanAuth"));
var _user = require("../../../../api/user");
var _chanelrole = require("../../../../api/systems/chanelrole");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'systemrolesmangesuplyroleindex';
var _default = {
  name: 'Systemrolesmangesuplyroleindex',
  components: {
    Pagination: _Pagination.default,
    edit: _edit.default,
    scan: _scan.default,
    add: _add.default,
    auth: _auth.default,
    scanAuth: _scanAuth.default
  },
  filters: {
    formatTime: function formatTime(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        return (0, _utils.parseTime)(value, '{y}-{m}-{d}');
      }
    }
  },
  data: function data() {
    return {
      listQuery: {
        roleName: '',
        clientCode: '',
        roleTypeDetail: '',
        pageNo: 1,
        pageSize: 20
      },
      sysOptions: [],
      systemOptions: [],
      list: [],
      tableKey: 'suplyrole',
      total: 0,
      listLoading: false,
      multipleSelection: [],
      page2: false,
      endShow: true,
      operate: false,
      tabHeight: '100%',
      apis: [],
      addShow: false,
      editData: {
        pid: '',
        roleName: '',
        roleCode: '',
        isEnable: '',
        remark: '',
        clientCodes: [],
        roleTypeDetail: ''
      },
      editShow: false,
      scanData: {},
      scanShow: false,
      scanRoleName: '',
      authData: {},
      authShow: false,
      scanAuthData: [],
      scanAuthShow: false,
      parentIds: []
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    var that = this;
    var hasCache = !!(that.list && that.list.length > 0);
    var authList = that.$route.meta.authList;
    var setHeaderId = that.authId('searchbtn', authList);
    that.apis = authList;
    if (!hasCache) {
      that.getSysData();
      that.getDhTypes();
      that.getList();
    }
  },
  // 缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = this.getCaches(that, name);
    if (!cache) {
      that.list = [];
      that.total = 0;
    }
  },
  methods: {
    getList: function getList() {
      var that = this;
      var setHeaderId = that.authId('searchbtn', that.apis);
      that.listLoading = true;
      (0, _suplyrole.getRoleList)(that.listQuery).then(function (res) {
        if (res.data.code == 200) {
          that.list = res.data.data.records;
          that.list.forEach(function (item) {
            item.roleTypeName = that.turnName(item.roleTypeDetail);
          });
          console.log(that.list);
          that.total = parseInt(res.data.data.total);
          setTimeout(function () {
            that.listLoading = false;
          }, 1 * 800);
        } else {
          that.$message.error(res.data.msg);
          that.listLoading = false;
          return;
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    turnName: function turnName(val) {
      var that = this;
      var data = that.sysOptions;
      var name = '';
      if (data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          if (data[i].dictVal == val) {
            name = data[i].dictDesc;
            break;
          }
        }
      }
      return name;
    },
    // 获取系统数据
    getSysData: function getSysData() {
      var that = this;
      var param = {};
      param.type = 0;
      (0, _chanelrole.getSystem)(param).then(function (res) {
        if (res.data.code == 200) {
          that.systemOptions = res.data.data;
        } else {
          that.systemOptions = [];
        }
      });
    },
    DoSearch: function DoSearch() {
      var that = this;
      that.listQuery = (0, _utils.filterKeyNull)(that.listQuery);
      that.listQuery.pageNo = 1;
      that.getList();
    },
    DoReset: function DoReset(formName) {
      var that = this;
      that.listQuery.roleName = '';
      that.listQuery.roleTypeDetail = '';
      that.listQuery.clientCode = '';
      that.listQuery.pageNo = 1;
      that.getList();
    },
    // 获取供货类型数据
    getDhTypes: function getDhTypes() {
      var that = this;
      var param = {};
      param.dictCode = 'SUPPLIER_TYPE';
      (0, _user.getDictionaryListByCode)(param).then(function (res) {
        if (res.data.code == 200) {
          that.sysOptions = res.data.data;
        } else {
          that.sysOptions = [];
        }
      });
    },
    // 添加
    DoAdd: function DoAdd() {
      var that = this;
      var setHeaderId = that.authId('addbtn', that.apis);
      that.$nextTick(function () {
        that.addShow = true;
      });
    },
    DoScan: function DoScan(data) {
      var that = this;
      var setHeaderId = that.authId('detailbtn', that.apis);
      that.scanData = (0, _utils.deepClone)(data);
      that.$nextTick(function () {
        that.scanShow = true;
      });
    },
    DoEdit: function DoEdit(data) {
      var that = this;
      var setHeaderId = that.authId('updatebtn', that.apis);
      // that.editData = deepClone(data)
      // that.editShow = true

      var param = {};
      param.roleId = data.pid;
      (0, _suplyrole.getRoleDetail)(param).then(function (res) {
        if (res.data.code == 200) {
          var clients = [];
          var resData = res.data.data;
          that.editData.pid = resData.pid;
          that.editData.roleName = resData.roleName;
          that.editData.roleCode = resData.roleCode;
          that.editData.remark = resData.remark;
          console.log((0, _typeof2.default)(resData.roleTypeDetail), '???');
          that.editData.roleTypeDetail = resData.roleTypeDetail;
          if (resData.isEnable == 1) {
            that.editData.isEnable = true;
          } else {
            that.editData.isEnable = false;
          }
          if (resData.clients.length > 0) {
            resData.clients.forEach(function (item) {
              clients.push(item.clientCode);
            });
            that.editData.clientCodes = clients;
          } else {
            that.editData.clientCodes = [];
          }
        } else {
          that.editData = (0, _utils.deepClone)(data);
          that.editData.clientCodes = [];
        }
        that.editShow = true;
      }).catch(function () {
        that.editData = (0, _utils.deepClone)(data);
        that.editData.clientCodes = [];
        that.editShow = true;
      });
    },
    // 选择启动项
    handleSelectionChange: function handleSelectionChange(val) {
      var that = this;
      that.multipleSelection = val;
    },
    // 禁用
    DoStop: function DoStop(data) {
      var that = this;
      var setHeaderId = that.authId('stopbtn', that.apis);
      var param = {};
      param.roleId = data.pid;
      that.$confirm('是否禁用?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _suplyrole.stopRole)(param).then(function (res) {
          if (res.data.code == 200) {
            that.$message({
              type: 'success',
              message: '禁用成功'
            });
            that.listQuery.current = 1;
            that.getList();
          } else {
            that.$message.error(res.data.msg);
          }
        });
      }).catch(function () {
        console.log('禁用已取消');
      });
    },
    // 启用
    DoStart: function DoStart(data) {
      var that = this;
      var setHeaderId = that.authId('startbtn', that.apis);
      var param = {};
      param.roleId = data.pid;
      that.$confirm('是否启用?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _suplyrole.startRole)(param).then(function (res) {
          if (res.data.code == 200) {
            that.$message({
              type: 'success',
              message: '启用成功'
            });
            that.listQuery.current = 1;
            that.getList();
          } else {
            that.$message.error(res.data.msg);
          }
        });
      }).catch(function () {
        console.log('启用已取消');
      });
    },
    // 批量启用
    getStarts: function getStarts() {
      var that = this;
      var setHeaderId = that.authId('allstart', that.apis);
      var sdata = that.multipleSelection;
      if (sdata.length > 0) {
        var param = [];
        sdata.forEach(function (item) {
          param.push(item.pid);
        });
        var subData = {};
        subData.roleIds = param.join(',');
        that.$confirm('是否启用?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          (0, _suplyrole.startItems)(subData).then(function (res) {
            if (res.data.code == 200) {
              that.$message({
                type: 'success',
                message: '启用成功'
              });
              that.listQuery.current = 1;
              that.getList();
            } else {
              that.$message.error(res.data.msg);
              return;
            }
          });
        }).catch(function () {
          console.log('已取消');
        });
      } else {
        that.$message.error('请选择要启动的项');
      }
    },
    // 批量禁用
    getStops: function getStops() {
      var that = this;
      var setHeaderId = that.authId('allstop', that.apis);
      var sdata = that.multipleSelection;
      if (sdata.length > 0) {
        var param = [];
        sdata.forEach(function (item) {
          param.push(item.pid);
        });
        var subData = {};
        subData.roleIds = param.join(',');
        that.$confirm('是否禁用?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          (0, _suplyrole.stopItems)(subData).then(function (res) {
            if (res.data.code == 200) {
              that.$message({
                type: 'success',
                message: '禁用成功'
              });
              that.listQuery.current = 1;
              that.getList();
            } else {
              that.$message.error(res.data.msg);
              return;
            }
          });
        }).catch(function () {
          console.log('已取消');
        });
      } else {
        that.$message.error('请选择要禁用的项');
      }
    },
    // 获取数据权限
    getRoleAuth: function getRoleAuth(data) {
      var that = this;
      that.authId('authassign', that.apis);
      var resData = [];
      var param = {};
      that.parentIds = [];
      that.authData.roleName = data.roleName;
      that.authData.roleCode = data.roleCode;
      param.roleCode = data.roleCode;
      (0, _suplyrole.getGhAuths)(param).then(function (res) {
        if (res.data.code == 200) {
          var allData = res.data.data;
          if (allData.length > 0) {
            allData.forEach(function (item) {
              var obj = {};
              obj.pid = item.parentCode;
              obj.id = item.resourceCode;
              obj.resourceName = item.resourceName;
              resData.push(obj);
            });
          }
        } else {
          resData = [];
        }
        return resData;
      }).then(function (res) {
        var fromData = res;
        (0, _suplyrole.getGhRoleAuth)(param).then(function (response) {
          console.log(response, '角色已有');
          if (response.data.code == 200) {
            var rData = response.data.data;
            var current = [];
            var checkedArr = [];
            that.authData.originData = rData;
            if (rData.length > 0) {
              rData.forEach(function (item) {
                var obj = {};
                obj.pid = item.parentCode;
                obj.id = item.resourceCode;
                obj.resourceName = item.resourceName;
                current.push(obj);
                checkedArr.push(item.resourceCode);
              });
              var toData = (0, _utils.transTreeData)(current, 'id', 'pid', 'children');
              that.calleArr(toData);
              var parentIds = that.parentIds;
              for (var i = 0; i < parentIds.length; i++) {
                for (var j = 0; j < checkedArr.length; j++) {
                  if (parentIds[i] == checkedArr[j]) {
                    checkedArr.splice(j, 1);
                  }
                }
              }
              that.authData.defaultCheckedKeys = checkedArr;
              that.authData.toData = [];
              that.authData.fromData = (0, _utils.transTreeData)(fromData, 'id', 'pid', 'children');
            } else {
              that.authData.defaultCheckedKeys = [];
              that.authData.toData = [];
              that.authData.fromData = (0, _utils.transTreeData)(fromData, 'id', 'pid', 'children');
            }
            console.log(that.authData, '123456');
          }
          that.authShow = true;
        }).catch(function () {
          that.authData.toData = [];
          that.authData.defaultCheckedKeys = [];
          if (fromData.length > 0) {
            that.authData.fromData = (0, _utils.transTreeData)(data, 'id', 'pid', 'children');
          } else {
            that.authData.fromData = [];
          }
          that.authShow = true;
        });
      }).catch(function () {
        that.authData.toData = [];
        that.authData.fromData = [];
        that.authData.defaultCheckedKeys = [];
        that.authShow = true;
      });
    },
    hideAuth: function hideAuth() {
      var that = this;
      that.authShow = false;
    },
    calleArr: function calleArr(arr) {
      var that = this;
      for (var i in arr) {
        var data = arr[i];
        if (data.children) {
          that.parentIds.push(data.id);
          that.calleArr(data.children);
        }
      }
    },
    // 查看数据权限
    scanRoleAuth: function scanRoleAuth(data) {
      var that = this;
      var setHeaderId = that.authId('scanauth', that.apis);
      that.scanRoleName = data.roleName;
      var param = {};
      param.roleCode = data.roleCode;
      (0, _suplyrole.getGhRoleAuth)(param).then(function (res) {
        if (res.data.code == 200) {
          if (res.data.data.length > 0) {
            that.scanAuthData = (0, _utils.transTreeData)(res.data.data, 'resourceCode', 'parentCode', 'children');
          } else {
            that.scanAuthData = [];
          }
        } else {
          that.scanAuthData = [];
        }
        that.$nextTick(function () {
          that.scanAuthShow = true;
        });
      }).catch(function () {
        that.scanAuthData = [];
        that.$nextTick(function () {
          that.scanAuthShow = true;
        });
      });
    }
  }
};
exports.default = _default;