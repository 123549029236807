var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    [
      _c(
        "el-row",
        { staticClass: "jfuser" },
        [
          _c("el-col", { attrs: { span: 24 } }, [
            _c("div", { staticClass: "hyyy-container" }, [
              _c("div", { staticClass: "hyyy-content" }, [
                _c("div", { staticClass: "hyyy-header" }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "hyyy-main",
                    staticStyle: { "padding-left": "0" },
                  },
                  [
                    _c(
                      "el-card",
                      [
                        _c("div", { staticClass: "hyyy-top-tit" }, [
                          _c("div", { staticClass: "hyyy-top-tit-lift" }, [
                            _c("span", {
                              staticStyle: {
                                "font-weight": "400",
                                color: "#333",
                              },
                              domProps: {
                                textContent: _vm._s(_vm.$route.meta.title),
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "hyyy-top-tit-right" }),
                        ]),
                        _vm._v(" "),
                        _c("el-divider", { staticStyle: { margin: "0" } }),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 24, md: 24 } },
                              [
                                _c(
                                  "el-form",
                                  {
                                    staticClass: "demo-form-inline",
                                    attrs: {
                                      inline: true,
                                      model: _vm.formInline,
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "cols" },
                                      [
                                        _c(
                                          "el-row",
                                          { attrs: { gutter: 20 } },
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 6 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "标签名称",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      attrs: {
                                                        placeholder:
                                                          "请输入标签名称",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.formInline
                                                            .lableName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.formInline,
                                                            "lableName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "formInline.lableName",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 6 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "是否同步微信",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        attrs: {
                                                          placeholder: "请选择",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.formInline
                                                              .isSynchronizationWechat,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.formInline,
                                                              "isSynchronizationWechat",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "formInline.isSynchronizationWechat",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-option",
                                                          {
                                                            attrs: {
                                                              label: "不限",
                                                              value: "",
                                                            },
                                                          },
                                                          [_vm._v("不限")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c("el-option", {
                                                          attrs: {
                                                            value: "1",
                                                            label: "是",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("el-option", {
                                                          attrs: {
                                                            value: "2",
                                                            label: "否",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-col",
                                              { staticClass: "hyyy-head-btn" },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      "label-width": "0",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        attrs: {
                                                          slot: "label",
                                                        },
                                                        slot: "label",
                                                      },
                                                      [_vm._v(" ")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          type: "primary",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.onSubmit(
                                                              _vm.formInline
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("查询")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          type: "danger",
                                                        },
                                                        on: {
                                                          click: _vm.onReset,
                                                        },
                                                      },
                                                      [_vm._v("重置")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-card",
                      { staticStyle: { "margin-top": "20px" } },
                      [
                        _c(
                          "el-row",
                          { staticStyle: { padding: "20px" } },
                          [
                            _c(
                              "el-col",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      icon: "el-icon-plus",
                                      type: "primary",
                                    },
                                    on: { click: _vm.xjbq },
                                  },
                                  [_vm._v("新建标签")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "el-table",
                                  {
                                    directives: [
                                      {
                                        name: "loading",
                                        rawName: "v-loading",
                                        value: _vm.vipload,
                                        expression: "vipload",
                                      },
                                    ],
                                    ref: "multipleTable",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      background: "",
                                      data: _vm.tableData,
                                      "tooltip-effect": "dark",
                                      sortable: "custom",
                                      "default-sort": {
                                        prop: "syjf",
                                        order: "descending",
                                      },
                                    },
                                    on: {
                                      "sort-change": _vm.srchang,
                                      "selection-change":
                                        _vm.handleSelectionChange,
                                    },
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: { type: "selection", width: "55" },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "lableName",
                                        label: "标签名称",
                                        "show-overflow-tooltip": "",
                                        align: "center",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        label: "微信粉丝数",
                                        align: "center",
                                        prop: "wechatNum",
                                        sortable: "",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        label: "客户数",
                                        align: "center",
                                        sortable: "",
                                        prop: "customerNum",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        label: "标签类型",
                                        align: "center",
                                        prop: "lableType",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        label: "是否同步微信",
                                        align: "center",
                                        prop: "isSynchronizationWechat",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        label: "打标签条件",
                                        align: "center",
                                        prop: "conditionStr",
                                        "show-overflow-tooltip": "",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        label: "操作",
                                        width: "240",
                                        fixed: "right",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    size: "mini",
                                                    type: "danger",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleinfo(
                                                        scope.row.id,
                                                        1
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("删除")]
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("el-pagination", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.total > 0,
                                      expression: "total > 0",
                                    },
                                  ],
                                  staticStyle: { "text-align": "left" },
                                  attrs: {
                                    background: "",
                                    "current-page": _vm.formInline.pageNo,
                                    "page-size": _vm.formInline.pageSize,
                                    layout: "total,prev, pager, next",
                                    total: _vm.total,
                                  },
                                  on: {
                                    "current-change": _vm.handleCurrentChange,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择活动",
            visible: _vm.diahd,
            width: "622px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.diahd = $event
            },
          },
        },
        [
          _c(
            "span",
            [
              _c(
                "el-checkbox-group",
                {
                  staticClass: "cphdlist",
                  on: { change: _vm.hdzs },
                  model: {
                    value: _vm.form.moWxLableConditionDOList[0].lableObj.idList,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.form.moWxLableConditionDOList[0].lableObj,
                        "idList",
                        $$v
                      )
                    },
                    expression:
                      "form.moWxLableConditionDOList[0].lableObj.idList",
                  },
                },
                _vm._l(_vm.hdlist, function (item, index) {
                  return _c(
                    "el-checkbox",
                    { key: index, attrs: { label: item.id } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.diahd = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.diahd = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择产品",
            visible: _vm.diacp,
            width: "622px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.diacp = $event
            },
          },
        },
        [
          _c(
            "span",
            [
              _c(
                "el-checkbox-group",
                {
                  staticClass: "cphdlist",
                  on: { change: _vm.cpzs },
                  model: {
                    value: _vm.form.moWxLableConditionDOList[1].lableObj.idList,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.form.moWxLableConditionDOList[1].lableObj,
                        "idList",
                        $$v
                      )
                    },
                    expression:
                      "form.moWxLableConditionDOList[1].lableObj.idList",
                  },
                },
                _vm._l(_vm.cplist, function (item, index) {
                  return _c(
                    "el-checkbox",
                    { key: index, attrs: { label: item.id } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.diacp = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.diacp = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "打标签",
            visible: _vm.dialogVisible,
            width: "1154px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "span",
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form",
                        {
                          ref: "form",
                          staticClass: "demo-form-block",
                          attrs: { model: _vm.form, "label-width": "150px" },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "标签名称" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "300px" },
                                attrs: {
                                  maxlength: "50",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.form.lableName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "lableName", $$v)
                                  },
                                  expression: "form.lableName",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "是否同步到微信" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  staticClass: "radio",
                                  model: {
                                    value: _vm.form.isSynchronizationWechat,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "isSynchronizationWechat",
                                        $$v
                                      )
                                    },
                                    expression: "form.isSynchronizationWechat",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: "1" } }, [
                                    _vm._v("是"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: "2" } }, [
                                    _vm._v("否"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "标签类型" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  staticClass: "radio",
                                  model: {
                                    value: _vm.form.lableType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "lableType", $$v)
                                    },
                                    expression: "form.lableType",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: "2" } }, [
                                    _vm._v("自动标签"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: "1" } }, [
                                    _vm._v("手动标签"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.form.lableType == "2",
                                  expression: "form.lableType == '2'",
                                },
                              ],
                              attrs: { label: "标签规则" },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  staticClass: "radio",
                                  model: {
                                    value: _vm.form.lableRule,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "lableRule", $$v)
                                    },
                                    expression: "form.lableRule",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: "1" } }, [
                                    _vm._v("满足任意一个选中的条件即可"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: "2" } }, [
                                    _vm._v("必须满足所有选中的条件才可"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.form.lableType == "2",
                                  expression: "form.lableType == '2'",
                                },
                              ],
                              attrs: { label: "标签条件" },
                            },
                            [
                              _c(
                                "el-row",
                                {
                                  attrs: {
                                    type: "flex",
                                    justify: "flex-start",
                                  },
                                },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 3 } },
                                    [
                                      _c(
                                        "el-radio-group",
                                        {
                                          staticClass: "radio bqtj",
                                          staticStyle: { "margin-top": "12px" },
                                          on: { change: _vm.xztjlist },
                                          model: {
                                            value: _vm.radioss,
                                            callback: function ($$v) {
                                              _vm.radioss = $$v
                                            },
                                            expression: "radioss",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio",
                                            { attrs: { label: "1" } },
                                            [_vm._v("活动条件")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-radio",
                                            { attrs: { label: "2" } },
                                            [_vm._v("扫码条件")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-radio",
                                            { attrs: { label: "3" } },
                                            [_vm._v("积分条件")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-radio",
                                            { attrs: { label: "4" } },
                                            [_vm._v("微信条件")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-radio",
                                            { attrs: { label: "5" } },
                                            [_vm._v("静态条件")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    { staticClass: "cltype" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "checkflex" },
                                        [
                                          _c(
                                            "el-checkbox",
                                            {
                                              attrs: {
                                                disabled: _vm.disabled1,
                                                "true-label": "1",
                                                "false-label": "",
                                              },
                                              model: {
                                                value:
                                                  _vm.form
                                                    .moWxLableConditionDOList[0]
                                                    .lableObj.type,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form
                                                      .moWxLableConditionDOList[0]
                                                      .lableObj,
                                                    "type",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "\n                        form.moWxLableConditionDOList[0]\n                          .lableObj.type\n                      ",
                                              },
                                            },
                                            [_vm._v("参加过活动")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              class: [
                                                _vm.hdzslist1.length > 0
                                                  ? "mtf"
                                                  : "",
                                              ],
                                            },
                                            [
                                              _c(
                                                "el-link",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "10px",
                                                  },
                                                  attrs: {
                                                    underline: false,
                                                    type: "primary",
                                                    disabled: _vm.disabled1,
                                                  },
                                                  on: { click: _vm.dkdishd },
                                                },
                                                [_vm._v("选择活动")]
                                              ),
                                              _vm._v(" "),
                                              _vm._l(
                                                _vm.hdzslist1,
                                                function (tag) {
                                                  return _c(
                                                    "el-tag",
                                                    {
                                                      key: tag.id,
                                                      staticStyle: {
                                                        margin: "5px",
                                                      },
                                                      attrs: {
                                                        closable: "",
                                                        "disable-transitions": false,
                                                      },
                                                      on: {
                                                        close: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleClosetag(
                                                            "1",
                                                            tag.id,
                                                            _vm.form
                                                              .moWxLableConditionDOList[0]
                                                              .lableObj.idList,
                                                            tag,
                                                            _vm.hdzslist
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(_vm._s(tag.name))]
                                                  )
                                                }
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.hdzslist1.length ==
                                                        4,
                                                      expression:
                                                        "hdzslist1.length == 4",
                                                    },
                                                  ],
                                                },
                                                [_vm._v("...")]
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "checkflex" },
                                        [
                                          _c(
                                            "el-checkbox",
                                            {
                                              attrs: {
                                                disabled: _vm.disabled2,
                                                "true-label": "2",
                                                "false-label": "",
                                              },
                                              model: {
                                                value:
                                                  _vm.form
                                                    .moWxLableConditionDOList[1]
                                                    .lableObj.type,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form
                                                      .moWxLableConditionDOList[1]
                                                      .lableObj,
                                                    "type",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "\n                        form.moWxLableConditionDOList[1]\n                          .lableObj.type\n                      ",
                                              },
                                            },
                                            [_vm._v("扫过产品防伪码")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              class: [
                                                _vm.cpzslist1.length > 0
                                                  ? "mtf"
                                                  : "",
                                              ],
                                            },
                                            [
                                              _c(
                                                "el-link",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "10px",
                                                  },
                                                  attrs: {
                                                    underline: false,
                                                    type: "primary",
                                                    disabled: _vm.disabled2,
                                                  },
                                                  on: { click: _vm.dkdiscp },
                                                },
                                                [_vm._v("选择产品")]
                                              ),
                                              _vm._v(" "),
                                              _vm._l(
                                                _vm.cpzslist1,
                                                function (tag) {
                                                  return _c(
                                                    "el-tag",
                                                    {
                                                      key: tag.id,
                                                      staticStyle: {
                                                        "margin-right": "5px",
                                                        "margin-left": "10px",
                                                      },
                                                      attrs: {
                                                        closable: "",
                                                        "disable-transitions": false,
                                                      },
                                                      on: {
                                                        close: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleClosetag(
                                                            "2",
                                                            tag.id,
                                                            _vm.form
                                                              .moWxLableConditionDOList[1]
                                                              .lableObj.idList,
                                                            tag,
                                                            _vm.cpzslist
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(_vm._s(tag.name))]
                                                  )
                                                }
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.cpzslist1.length ==
                                                        4,
                                                      expression:
                                                        "cpzslist1.length == 4",
                                                    },
                                                  ],
                                                },
                                                [_vm._v("...")]
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-checkbox",
                                        {
                                          attrs: {
                                            disabled: _vm.disabled2,
                                            "true-label": "3",
                                            "false-label": "",
                                            label: "3",
                                          },
                                          model: {
                                            value:
                                              _vm.form
                                                .moWxLableConditionDOList[2]
                                                .lableObj.type,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form
                                                  .moWxLableConditionDOList[2]
                                                  .lableObj,
                                                "type",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                      form.moWxLableConditionDOList[2].lableObj\n                        .type\n                    ",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    累积扫码次数\n                    "
                                          ),
                                          _c("el-input", {
                                            staticStyle: { width: "80px" },
                                            attrs: {
                                              value:
                                                _vm.form
                                                  .moWxLableConditionDOList[2]
                                                  .lableObj.startNum,
                                              disabled: _vm.disabled2,
                                              maxlength: "3",
                                            },
                                            on: {
                                              input: function (e) {
                                                return (_vm.form.moWxLableConditionDOList[2].lableObj.startNum =
                                                  _vm.numFun(e))
                                              },
                                            },
                                          }),
                                          _vm._v("次到\n                    "),
                                          _c("el-input", {
                                            staticStyle: { width: "80px" },
                                            attrs: {
                                              value:
                                                _vm.form
                                                  .moWxLableConditionDOList[2]
                                                  .lableObj.endNum,
                                              maxlength: "3",
                                              disabled: _vm.disabled2,
                                            },
                                            on: {
                                              input: function (e) {
                                                return (_vm.form.moWxLableConditionDOList[2].lableObj.endNum =
                                                  _vm.numFun(e))
                                              },
                                            },
                                          }),
                                          _vm._v("次\n                  "),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-checkbox",
                                        {
                                          attrs: {
                                            disabled: _vm.disabled2,
                                            "true-label": "4",
                                            "false-label": "",
                                            label: "4",
                                          },
                                          model: {
                                            value:
                                              _vm.form
                                                .moWxLableConditionDOList[3]
                                                .lableObj.type,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form
                                                  .moWxLableConditionDOList[3]
                                                  .lableObj,
                                                "type",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                      form.moWxLableConditionDOList[3].lableObj\n                        .type\n                    ",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    扫码时间\n                    "
                                          ),
                                          _c("el-date-picker", {
                                            attrs: {
                                              size: "large",
                                              type: "daterange",
                                              align: "right",
                                              "unlink-panels": "",
                                              "value-format": "yyyy-MM-dd",
                                              "range-separator": "-",
                                              "start-placeholder": "开始日期",
                                              "end-placeholder": "结束日期",
                                              disabled: _vm.disabled2,
                                              "picker-options":
                                                _vm.pickerOptions,
                                            },
                                            model: {
                                              value: _vm.time1,
                                              callback: function ($$v) {
                                                _vm.time1 = $$v
                                              },
                                              expression: "time1",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-checkbox",
                                        {
                                          attrs: {
                                            disabled: _vm.disabled3,
                                            "true-label": "5",
                                            "false-label": "",
                                            label: "5",
                                          },
                                          model: {
                                            value:
                                              _vm.form
                                                .moWxLableConditionDOList[4]
                                                .lableObj.type,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form
                                                  .moWxLableConditionDOList[4]
                                                  .lableObj,
                                                "type",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                      form.moWxLableConditionDOList[4].lableObj\n                        .type\n                    ",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    累积获得积分\n                    "
                                          ),
                                          _c("el-input", {
                                            staticStyle: { width: "80px" },
                                            attrs: {
                                              value:
                                                _vm.form
                                                  .moWxLableConditionDOList[4]
                                                  .lableObj.startNum,
                                              maxlength: "6",
                                              disabled: _vm.disabled3,
                                            },
                                            on: {
                                              input: function (e) {
                                                return (_vm.form.moWxLableConditionDOList[4].lableObj.startNum =
                                                  _vm.numFun(e))
                                              },
                                            },
                                          }),
                                          _vm._v(
                                            "积分到\n                    "
                                          ),
                                          _c("el-input", {
                                            staticStyle: { width: "80px" },
                                            attrs: {
                                              value:
                                                _vm.form
                                                  .moWxLableConditionDOList[4]
                                                  .lableObj.endNum,
                                              maxlength: "6",
                                              disabled: _vm.disabled3,
                                            },
                                            on: {
                                              input: function (e) {
                                                return (_vm.form.moWxLableConditionDOList[4].lableObj.endNum =
                                                  _vm.numFun(e))
                                              },
                                            },
                                          }),
                                          _vm._v("积分\n                  "),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-checkbox",
                                        {
                                          staticClass: "wxgzsj",
                                          attrs: {
                                            disabled: _vm.disabled4,
                                            "true-label": "6",
                                            "false-label": "",
                                            label: "6",
                                          },
                                          model: {
                                            value:
                                              _vm.form
                                                .moWxLableConditionDOList[5]
                                                .lableObj.type,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form
                                                  .moWxLableConditionDOList[5]
                                                  .lableObj,
                                                "type",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                      form.moWxLableConditionDOList[5].lableObj\n                        .type\n                    ",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    微信关注时间\n                    "
                                          ),
                                          _c(
                                            "el-radio-group",
                                            {
                                              attrs: {
                                                disabled: _vm.disabled4,
                                              },
                                              on: { change: _vm.mowxdeiogz },
                                              model: {
                                                value:
                                                  _vm.form
                                                    .moWxLableConditionDOList[5]
                                                    .lableObj.radioType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form
                                                      .moWxLableConditionDOList[5]
                                                      .lableObj,
                                                    "radioType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "\n                        form.moWxLableConditionDOList[5]\n                          .lableObj.radioType\n                      ",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio",
                                                { attrs: { label: "1" } },
                                                [
                                                  _vm._v(
                                                    "\n                        最近\n                        "
                                                  ),
                                                  _c(
                                                    "span",
                                                    [
                                                      _c("el-input", {
                                                        staticStyle: {
                                                          width: "80px",
                                                        },
                                                        attrs: {
                                                          value:
                                                            _vm.form
                                                              .moWxLableConditionDOList[5]
                                                              .lableObj.dayNum,
                                                          maxlength: "3",
                                                          disabled:
                                                            _vm.disabled4,
                                                        },
                                                        on: {
                                                          input: function (e) {
                                                            return (_vm.form.moWxLableConditionDOList[5].lableObj.dayNum =
                                                              _vm.numFun(e))
                                                          },
                                                        },
                                                      }),
                                                      _vm._v(
                                                        "天\n                        "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-radio",
                                                { attrs: { label: "2" } },
                                                [
                                                  _c("el-date-picker", {
                                                    attrs: {
                                                      size: "large",
                                                      type: "daterange",
                                                      align: "right",
                                                      "unlink-panels": "",
                                                      "value-format":
                                                        "yyyy-MM-dd",
                                                      "range-separator": "-",
                                                      "start-placeholder":
                                                        "开始日期",
                                                      "end-placeholder":
                                                        "结束日期",
                                                      disabled: _vm.disabled4,
                                                      "picker-options":
                                                        _vm.pickerOptions,
                                                    },
                                                    model: {
                                                      value: _vm.time2,
                                                      callback: function ($$v) {
                                                        _vm.time2 = $$v
                                                      },
                                                      expression: "time2",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-checkbox",
                                        {
                                          staticClass: "wxgzsj",
                                          attrs: {
                                            disabled: _vm.disabled4,
                                            "true-label": "7",
                                            "false-label": "",
                                            label: "7",
                                          },
                                          model: {
                                            value:
                                              _vm.form
                                                .moWxLableConditionDOList[6]
                                                .lableObj.type,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form
                                                  .moWxLableConditionDOList[6]
                                                  .lableObj,
                                                "type",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                      form.moWxLableConditionDOList[6].lableObj\n                        .type\n                    ",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    取消微信关注时间\n                    "
                                          ),
                                          _c(
                                            "el-radio-group",
                                            {
                                              attrs: {
                                                disabled: _vm.disabled4,
                                              },
                                              on: { change: _vm.mowxdeioqxgz },
                                              model: {
                                                value:
                                                  _vm.form
                                                    .moWxLableConditionDOList[6]
                                                    .lableObj.radioType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form
                                                      .moWxLableConditionDOList[6]
                                                      .lableObj,
                                                    "radioType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "\n                        form.moWxLableConditionDOList[6]\n                          .lableObj.radioType\n                      ",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio",
                                                { attrs: { label: "1" } },
                                                [
                                                  _vm._v(
                                                    "\n                        最近\n                        "
                                                  ),
                                                  _c(
                                                    "span",
                                                    [
                                                      _c("el-input", {
                                                        staticStyle: {
                                                          width: "80px",
                                                        },
                                                        attrs: {
                                                          value:
                                                            _vm.form
                                                              .moWxLableConditionDOList[6]
                                                              .lableObj.dayNum,
                                                          disabled:
                                                            _vm.disabled4,
                                                          maxlength: "3",
                                                        },
                                                        on: {
                                                          input: function (e) {
                                                            return (_vm.form.moWxLableConditionDOList[6].lableObj.dayNum =
                                                              _vm.numFun(e))
                                                          },
                                                        },
                                                      }),
                                                      _vm._v(
                                                        "天\n                        "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-radio",
                                                { attrs: { label: "2" } },
                                                [
                                                  _c("el-date-picker", {
                                                    attrs: {
                                                      size: "large",
                                                      type: "daterange",
                                                      align: "right",
                                                      "unlink-panels": "",
                                                      "value-format":
                                                        "yyyy-MM-dd",
                                                      "range-separator": "-",
                                                      "start-placeholder":
                                                        "开始日期",
                                                      "end-placeholder":
                                                        "结束日期",
                                                      disabled: _vm.disabled4,
                                                      "picker-options":
                                                        _vm.pickerOptions,
                                                    },
                                                    model: {
                                                      value: _vm.time3,
                                                      callback: function ($$v) {
                                                        _vm.time3 = $$v
                                                      },
                                                      expression: "time3",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-checkbox",
                                        {
                                          attrs: {
                                            disabled: _vm.disabled4,
                                            "true-label": "8",
                                            "false-label": "",
                                            label: "8",
                                          },
                                          model: {
                                            value:
                                              _vm.form
                                                .moWxLableConditionDOList[7]
                                                .lableObj.type,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form
                                                  .moWxLableConditionDOList[7]
                                                  .lableObj,
                                                "type",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                      form.moWxLableConditionDOList[7].lableObj\n                        .type\n                    ",
                                          },
                                        },
                                        [_vm._v("渠道二维码来源用户")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-checkbox",
                                        {
                                          attrs: {
                                            disabled: _vm.disabled4,
                                            "true-label": "9",
                                            "false-label": "",
                                            label: "9",
                                          },
                                          model: {
                                            value:
                                              _vm.form
                                                .moWxLableConditionDOList[8]
                                                .lableObj.type,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form
                                                  .moWxLableConditionDOList[8]
                                                  .lableObj,
                                                "type",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                      form.moWxLableConditionDOList[8].lableObj\n                        .type\n                    ",
                                          },
                                        },
                                        [_vm._v("积分商城来源用户")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-checkbox",
                                        {
                                          attrs: {
                                            disabled: _vm.disabled4,
                                            "true-label": "10",
                                            "false-label": "",
                                            label: "10",
                                          },
                                          model: {
                                            value:
                                              _vm.form
                                                .moWxLableConditionDOList[9]
                                                .lableObj.type,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form
                                                  .moWxLableConditionDOList[9]
                                                  .lableObj,
                                                "type",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                      form.moWxLableConditionDOList[9].lableObj\n                        .type\n                    ",
                                          },
                                        },
                                        [_vm._v("会员卡来源用户")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-checkbox",
                                        {
                                          attrs: {
                                            disabled: _vm.disabled5,
                                            "true-label": "11",
                                            "false-label": "",
                                            label: "11",
                                          },
                                          model: {
                                            value:
                                              _vm.form
                                                .moWxLableConditionDOList[10]
                                                .lableObj.type,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form
                                                  .moWxLableConditionDOList[10]
                                                  .lableObj,
                                                "type",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                      form.moWxLableConditionDOList[10].lableObj\n                        .type\n                    ",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    用户年龄\n                    "
                                          ),
                                          _c("el-input", {
                                            staticStyle: { width: "80px" },
                                            attrs: {
                                              value:
                                                _vm.form
                                                  .moWxLableConditionDOList[10]
                                                  .lableObj.startNum,
                                              maxlength: "3",
                                              disabled: _vm.disabled5,
                                            },
                                            on: {
                                              input: function (e) {
                                                return (_vm.form.moWxLableConditionDOList[10].lableObj.startNum =
                                                  _vm.numFun(e))
                                              },
                                            },
                                          }),
                                          _vm._v("岁到\n                    "),
                                          _c("el-input", {
                                            staticStyle: { width: "80px" },
                                            attrs: {
                                              value:
                                                _vm.form
                                                  .moWxLableConditionDOList[10]
                                                  .lableObj.endNum,
                                              maxlength: "3",
                                              disabled: _vm.disabled5,
                                            },
                                            on: {
                                              input: function (e) {
                                                return (_vm.form.moWxLableConditionDOList[10].lableObj.endNum =
                                                  _vm.numFun(e))
                                              },
                                            },
                                          }),
                                          _vm._v("岁\n                  "),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-checkbox",
                                        {
                                          staticClass: "wxgzsj",
                                          attrs: {
                                            disabled: _vm.disabled5,
                                            "true-label": "12",
                                            "false-label": "",
                                            label: "12",
                                          },
                                          model: {
                                            value:
                                              _vm.form
                                                .moWxLableConditionDOList[11]
                                                .lableObj.type,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form
                                                  .moWxLableConditionDOList[11]
                                                  .lableObj,
                                                "type",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                      form.moWxLableConditionDOList[11].lableObj\n                        .type\n                    ",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    用户性别\n                    "
                                          ),
                                          _c(
                                            "el-radio-group",
                                            {
                                              attrs: {
                                                disabled: _vm.disabled5,
                                              },
                                              model: {
                                                value:
                                                  _vm.form
                                                    .moWxLableConditionDOList[11]
                                                    .lableObj.radioType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form
                                                      .moWxLableConditionDOList[11]
                                                      .lableObj,
                                                    "radioType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "\n                        form.moWxLableConditionDOList[11]\n                          .lableObj.radioType\n                      ",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio",
                                                { attrs: { label: 1 } },
                                                [_vm._v("男")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-radio",
                                                { attrs: { label: 2 } },
                                                [_vm._v("女")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-checkbox",
                                        {
                                          attrs: {
                                            disabled: _vm.disabled5,
                                            "true-label": "13",
                                            "false-label": "",
                                            label: "13",
                                          },
                                          model: {
                                            value:
                                              _vm.form
                                                .moWxLableConditionDOList[12]
                                                .lableObj.type,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form
                                                  .moWxLableConditionDOList[12]
                                                  .lableObj,
                                                "type",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                      form.moWxLableConditionDOList[12].lableObj\n                        .type\n                    ",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    客户来源\n                    "
                                          ),
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                disabled: _vm.disabled5,
                                                multiple: "",
                                                placeholder: "请选择",
                                              },
                                              model: {
                                                value:
                                                  _vm.form
                                                    .moWxLableConditionDOList[12]
                                                    .lableObj.idList,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form
                                                      .moWxLableConditionDOList[12]
                                                      .lableObj,
                                                    "idList",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "\n                        form.moWxLableConditionDOList[12]\n                          .lableObj.idList\n                      ",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-option",
                                                {
                                                  attrs: {
                                                    label: "积分商城",
                                                    value: "1",
                                                  },
                                                },
                                                [_vm._v("积分商城")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-option",
                                                {
                                                  attrs: {
                                                    label: "会员卡",
                                                    value: "2",
                                                  },
                                                },
                                                [_vm._v("会员卡")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-option",
                                                {
                                                  attrs: {
                                                    label: "微信公众号",
                                                    value: "3",
                                                  },
                                                },
                                                [_vm._v("微信公众号")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-option",
                                                {
                                                  attrs: {
                                                    label: "一物一码",
                                                    value: "4",
                                                  },
                                                },
                                                [_vm._v("一物一码")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("el-form-item"),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.mscjbq(_vm.form)
                                    },
                                  },
                                },
                                [_vm._v("立即创建")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      _vm.dialogVisible = false
                                    },
                                  },
                                },
                                [_vm._v("取消")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }