"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interface = require("@/api/systems/interface");
var _dictionary = require("@/api/systems/dictionary");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SysteminterfaceConfigstorageindex',
  data: function data() {
    return {
      yxloading: false,
      yxData: {},
      yxbtnLoading: false,
      dxbtnLoading: false,
      yxtestbtnLoading: false,
      dxtestbtnLoading: false,
      yxRules: {
        storeName: [{
          required: true,
          message: 'OSS不能为空，请维护',
          trigger: 'change'
        }],
        accessSecret: [{
          required: true,
          message: 'Secret不能为空，请维护',
          trigger: 'change'
        }],
        accessKey: [{
          required: true,
          message: 'Client ID不能为空，请维护',
          trigger: 'change'
        }],
        endpoint: [{
          required: true,
          message: 'Endpoint不能为空，请维护',
          trigger: 'change'
        }],
        markBucketName: [{
          required: true,
          message: 'Mark不能为空，请维护',
          trigger: 'change'
        }],
        prodBucketName: [{
          required: true,
          message: 'Prod不能为空，请维护',
          trigger: 'change'
        }],
        imgBucketName: [{
          required: true,
          message: 'img不能为空，请维护',
          trigger: 'change'
        }],
        defaultBucketName: [{
          required: true,
          message: 'Default不能为空，请维护',
          trigger: 'change'
        }]
      },
      typeList: [],
      cardStyle: {
        padding: '12px 20px'
      }
    };
  },
  activated: function activated() {
    this.getList();
    this.getDirSel('FILE_STORE_TYPE');
  },
  methods: {
    getDirSel: function getDirSel(code) {
      var _this = this;
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: code
      }).then(function (res) {
        console.log(res.data.data);
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this.typeList = res.data.data;
          } else {
            _this.typeList = [];
          }
        }
      });
    },
    submitForm: function submitForm() {
      this.list = [];
      this.total = 0;
      this.listQuery.current = 1;
      this.getList();
    },
    getList: function getList() {
      this.list = [];
      this.total = 0;
      this.getyx();
      // this.getdx()
      // this.gettemp()
    },
    getyx: function getyx() {
      var _this2 = this;
      this.yxloading = true;
      (0, _interface.fileStoredetail)().then(function (res) {
        var data = res.data;
        _this2.yxloading = false;
        if (data.code == 200) {
          if (data.data != null) {
            _this2.yxData = data.data;
          }
        } else {
          _this2.$message.error(data.msg);
        }
      }).catch(function () {
        _this2.yxloading = false;
      });
    },
    yxChange: function yxChange(val) {
      this.yxData.serverAddress = '';
      this.yxData.port = '';
      this.yxData.secureConnection = false;
    },
    dxChange: function dxChange(val) {
      this.yxData.serverAddress = '';
      this.yxData.secureConnection = false;
    },
    submit: function submit(type) {
      var _this3 = this;
      if (type == 'yx') {
        this.$refs.yxData.validate(function (valid) {
          if (valid) {
            _this3.yxbtnLoading = true;
            var param = JSON.parse(JSON.stringify(_this3.yxData));
            (0, _interface.fileStoresubmit)(param).then(function (res) {
              _this3.yxbtnLoading = false;
              var data = res.data;
              if (data.code == 200) {
                _this3.$message.success('提交成功!');
                _this3.getList();
              } else {
                _this3.$message.error(data.msg);
              }
            }).catch(function () {
              _this3.yxbtnLoading = false;
            });
          } else {
            _this3.$message.error('请完善信息');
          }
        });
      }
    },
    yxtest: function yxtest() {}
  }
};
exports.default = _default;