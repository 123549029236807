"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = require("@/api/mark/code_status/new_api/index");
var _waves = _interopRequireDefault(require("@/directive/waves"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'ComplexTable',
  components: {},
  directives: {
    waves: _waves.default
  },
  data: function data() {
    return {
      activeName: 'freeze',
      dialogFormVisible: false,
      btn_loading: false,
      temp: {
        descr: '',
        target: ''
      },
      object: 1,
      object_options: [{
        id: 1,
        name: '单码'
      }, {
        id: 2,
        name: '制码订单号'
      }, {
        id: 3,
        name: '生产批次'
      }, {
        id: 4,
        name: '交货单'
      }],
      rules: {
        target: [{
          required: true,
          message: '请输入对象编号',
          trigger: 'blur'
        }],
        descr: [{
          required: true,
          message: '输入冻结原因',
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {},
  methods: {
    Rest: function Rest() {
      this.object = 1;
      this.$refs.searchform.resetFields();
    },
    onSubmitFreeze: function onSubmitFreeze() {
      this.authId('addbtn', this.$route.meta.authList);
      if (this.object == 1) {
        // 单码
        this.lockByMark();
        return true;
      }
      if (this.object == 2) {
        // 制码订单号
        this.lockByPack();
        return true;
      }
      if (this.object == 3) {
        // 生产批次
        this.lockByBatch();
        return true;
      }
      if (this.object == 4) {
        // 交货单
        this.lockByBill();
        return true;
      }
    },
    lockByBatch: function lockByBatch() {
      var _this = this;
      // 根据生产批次号冻结
      this.$refs['searchform'].validate(function (valid) {
        if (valid) {
          var tips = '确认要根据生产批次号冻结吗?';
          _this.$confirm(tips, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {
            _this.btn_loading = true;
            (0, _index.lockByBatch)(_this.temp).then(function (response) {
              _this.btn_loading = false;
              var data = response.data;
              if (data.code != 200) {
                _this.$message.error(data.msg);
                return true;
              }
              _this.$message({
                message: data.msg,
                type: 'success'
              });
              _this.$refs.searchform.resetFields();
            }).catch(function () {
              _this.btn_loading = false;
            });
          }).catch(function () {});
        }
      });
    },
    lockByBill: function lockByBill() {
      var _this2 = this;
      // 根据交货单号冻结
      this.$refs['searchform'].validate(function (valid) {
        if (valid) {
          var tips = '确认要根据交货单号冻结吗?';
          _this2.$confirm(tips, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {
            _this2.btn_loading = true;
            (0, _index.lockByBill)(_this2.temp).then(function (response) {
              _this2.btn_loading = false;
              var data = response.data;
              if (data.code != 200) {
                _this2.$message.error(data.msg);
                return true;
              }
              _this2.$message({
                message: data.msg,
                type: 'success'
              });
              _this2.$refs.searchform.resetFields();
            }).catch(function () {
              _this2.btn_loading = false;
            });
          }).catch(function () {});
        }
      });
    },
    lockByMark: function lockByMark() {
      var _this3 = this;
      // 单码冻结
      this.$refs['searchform'].validate(function (valid) {
        if (valid) {
          var tips = '确认要根据单码冻结吗?';
          _this3.$confirm(tips, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {
            _this3.btn_loading = true;
            (0, _index.lockByMark)(_this3.temp).then(function (response) {
              _this3.btn_loading = false;
              var data = response.data;
              if (data.code != 200) {
                _this3.$message.error(data.msg);
                return true;
              }
              _this3.$message({
                message: data.msg,
                type: 'success'
              });
              _this3.$refs.searchform.resetFields();
            }).catch(function () {
              _this3.btn_loading = false;
            });
          }).catch(function () {});
        }
      });
    },
    lockByPack: function lockByPack() {
      var _this4 = this;
      // 根据制码订单号冻结
      this.$refs['searchform'].validate(function (valid) {
        if (valid) {
          var tips = '确认要根据制码订单号冻结吗?';
          _this4.$confirm(tips, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {
            _this4.btn_loading = true;
            (0, _index.lockByPack)(_this4.temp).then(function (response) {
              _this4.btn_loading = false;
              var data = response.data;
              if (data.code != 200) {
                _this4.$message.error(data.msg);
                return true;
              }
              _this4.$message({
                message: data.msg,
                type: 'success'
              });
              _this4.$refs.searchform.resetFields();
            }).catch(function () {
              _this4.btn_loading = false;
            });
          }).catch(function () {});
        }
      });
    }
  }
};
exports.default = _default;