var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "请选择客户",
        visible: _vm.customVisible,
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.customVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "page-container" }, [
        _c(
          "div",
          { staticClass: "page-section" },
          [
            _c(
              "el-form",
              {
                ref: "listQuery",
                staticClass: "search-condition",
                attrs: {
                  model: _vm.listQuery,
                  "status-icon": "",
                  "label-width": "80px",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "filter-container" },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 7 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "orgId", label: "所属组织:" } },
                              [
                                _c("treeselect", {
                                  attrs: {
                                    multiple: false,
                                    "flatten-search-results": true,
                                    placeholder: "请选择组织...",
                                    options: _vm.orgOptions,
                                  },
                                  model: {
                                    value: _vm.listQuery.orgId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.listQuery, "orgId", $$v)
                                    },
                                    expression: "listQuery.orgId",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 7 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "name", label: "关键字:" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "档案编码/名称/外编码",
                                  },
                                  model: {
                                    value: _vm.listQuery.name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.listQuery, "name", $$v)
                                    },
                                    expression: "listQuery.name",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { "label-width": "20px" } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary", size: "mini" },
                                    on: { click: _vm.DoSearch },
                                  },
                                  [
                                    _c("i", { staticClass: "el-icon-search" }),
                                    _vm._v(" 查询\n                "),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.DoReset(_vm.listQuery)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "el-icon-refresh" }),
                                    _vm._v(" 重置\n                "),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                key: _vm.tableKey,
                staticStyle: { width: "100%" },
                attrs: { data: _vm.list, border: "", fit: "" },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    label: "档案编码",
                    prop: "groupArchivesCode",
                    align: "center",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "外部编码",
                    prop: "outCode",
                    align: "center",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "档案名称",
                    prop: "groupArchivesName",
                    align: "center",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "联系人",
                    prop: "contacts",
                    width: "200",
                    align: "center",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "手机号",
                    prop: "phone",
                    width: "100",
                    align: "center",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "团购类型",
                    prop: "groupBuyTypeName",
                    width: "100",
                    align: "center",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "所属组织",
                    prop: "orgName",
                    width: "100",
                    align: "center",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "业务员",
                    prop: "salesmanName",
                    width: "100",
                    align: "center",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "状态",
                    prop: "archivesStatusStr",
                    align: "center",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "创建时间",
                    prop: "createTime",
                    width: "120",
                    align: "center",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _vm._v(_vm._s(_vm._f("formatTime")(row.createtime))),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "操作",
                    align: "center",
                    width: "180",
                    fixed: "right",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.DoSelect(row)
                                },
                              },
                            },
                            [_vm._v("选择")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("pagination", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.total > 0,
                  expression: "total > 0",
                },
              ],
              attrs: {
                layout: "total, sizes, prev, pager, next, jumper",
                total: _vm.total,
                page: _vm.listQuery.pageNo,
                limit: _vm.listQuery.pageSize,
              },
              on: {
                "update:page": function ($event) {
                  return _vm.$set(_vm.listQuery, "pageNo", $event)
                },
                "update:limit": function ($event) {
                  return _vm.$set(_vm.listQuery, "pageSize", $event)
                },
                pagination: _vm.getList,
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }