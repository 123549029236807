"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _unlockPrizeRule = require("@/api/market/unlockPrizeRule");
var _externalGamesConfig = require("@/api/market/externalGamesConfig");
var _creat = _interopRequireDefault(require("./components/creat"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManageexternalGamesConfiggameConfigindex';
var _default = {
  name: 'MarketingManageexternalGamesConfiggameConfigindex',
  components: {
    Pagination: _Pagination.default,
    creat: _creat.default
  },
  filters: {
    filterButtonStatus: function filterButtonStatus(status) {
      return status == '1' ? '停用' : '启用';
    }
  },
  data: function data() {
    return {
      handleShow: false,
      editType: '',
      creatVisible: false,
      ruleCode: '',
      searchForm: {
        pageNo: 1,
        pageSize: 20
      },
      rangeTime: '',
      total: 0,
      tableData: [],
      listLoading: false
    };
  },
  activated: function activated() {
    this.queryForPage();
  },
  deactivated: function deactivated() {
    var name = cachName;
    var cache = this.getCaches(this, name);
    if (!cache) {
      this.tableData = [];
      this.total = 0;
    }
  },
  methods: {
    gameConfigDelete: function gameConfigDelete() {
      var _this = this;
      if (!this.multipleSelection.length) {
        return this.$message.error('请至少选择一个赛事配置');
      }
      (0, _externalGamesConfig.gameConfigDelete)(this.multipleSelection.map(function (item) {
        return item.id;
      })).then(function (res) {
        if (res.data && res.data.data.errCode) {
          return _this.$message.error(res.data.data.errMsg);
        } else {
          _this.queryForPage();
          return _this.$message.success('删除成功');
        }
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 获取表格
    queryForPage: function queryForPage() {
      var _this2 = this;
      this.listLoading = true;
      (0, _externalGamesConfig.getGameConfig)(this.searchForm, this.searchForm.pageNo, this.searchForm.pageSize).then(function (res) {
        if (res.data.data.errCode) {
          _this2.$message.error(res.data.data.errMsg);
          return;
        } else {
          _this2.listLoading = false;
          _this2.tableData = res.data.data.records;
          _this2.total = res.data.data.total;
        }
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    creat: function creat() {
      this.editType = 'add';
      this.creatVisible = true;
    },
    editUnlockPrize: function editUnlockPrize(row) {
      this.editType = 'edit';
      this.ruleCode = row.unlockRuleCode;
      this.creatVisible = true;
    },
    closeCreat: function closeCreat() {
      this.creatVisible = false;
      this.queryForPage();
    },
    onOrOffOperation: function onOrOffOperation(data) {
      var _this3 = this;
      var isOn = data.status == '1' ? '停用' : '启用';
      var isOnFlag = data.status == '1' ? '2' : '1';
      this.$confirm("\u662F\u5426".concat(isOn, "\u8BE5\u6570\u636E\u9879?"), {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this3.gameConfigUpdateStatus({
          id: data.id,
          status: parseInt(isOnFlag)
        }, isOn);
      }).catch(function (err) {
        console.log(err);
      });
    },
    isOperateUnlockPrzeRule: function isOperateUnlockPrzeRule(data, isOn) {
      var _this4 = this;
      if (isOn === '启用') {
        this.onOrOffUnlockPrzeRule(data, isOn);
        return;
      }
      (0, _unlockPrizeRule.getActCountByRuleCode)(data).then(function (res) {
        if (res.data.code == 200) {
          var actCount = Number(res.data.data);
          if (actCount !== 0) {
            var strMessage = "\u8BE5\u89C4\u5219\u5DF2\u7ECF\u5173\u8054".concat(actCount, "\u672A\u8FC7\u671F\u7684\u4E2A\u6D3B\u52A8\uFF0C\u4ECD\u8981\u505C\u7528\u8BF7\u5148\u53D6\u6D88\u5173\u8054");
            _this4.$confirm(strMessage, {
              confirmButtonText: '确定',
              showCancelButton: false
            });
            return;
          }
          _this4.onOrOffUnlockPrzeRule(data, isOn);
        } else {
          _this4.$message.error(res.data.msg);
          return;
        }
      });
    },
    gameConfigUpdateStatus: function gameConfigUpdateStatus(data, isOn) {
      var _this5 = this;
      (0, _externalGamesConfig.gameConfigUpdateStatus)(data).then(function (res) {
        if (res.data.code == 200) {
          if (res.data.data && res.data.data.errCode) {
            return _this5.$message.error(res.data.data.errMsg);
          } else {
            _this5.queryForPage();
            _this5.$message.success("".concat(isOn, "\u6210\u529F"));
          }
        } else {
          _this5.$message.error(res.data.msg);
          return;
        }
      });
    },
    // 搜索
    search: function search() {
      this.searchForm.pageNo = 1;
      this.queryForPage();
    },
    // 重置
    reset: function reset() {
      this.$refs['searchForm'].resetFields();
      this.rangeTime = '';
      this.searchForm = {
        pageNo: 1,
        pageSize: 20
      };
      this.queryForPage();
    }
  }
};
exports.default = _default;