"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _externalGamesConfig = require("@/api/market/externalGamesConfig");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  props: {
    visibleGame: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      searchForm: {
        pageNo: 1,
        pageSize: 20,
        gameName: '',
        matchId: ''
      },
      total: 0,
      tableData: [],
      listLoading: false,
      multipleSelection: []
    };
  },
  mounted: function mounted() {
    this.queryForPage();
  },
  methods: {
    queryForPage: function queryForPage() {
      var _this = this;
      this.listLoading = true;
      (0, _externalGamesConfig.getGameList)(this.searchForm, this.searchForm.pageNo, this.searchForm.pageSize).then(function (res) {
        if (res.data.data.errCode) {
          _this.$message.error(res.data.data.errMsg);
          return;
        } else {
          _this.listLoading = false;
          _this.tableData = res.data.data.records;
          _this.total = res.data.data.total;
        }
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    search: function search() {
      this.searchForm.pageNo = 1;
      this.queryForPage();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    reset: function reset() {
      this.$refs['searchForm'].resetFields();
      this.searchForm = {
        pageNo: 1,
        pageSize: 20
      };
      this.queryForPage();
    },
    handleClose: function handleClose() {
      this.$emit('close');
    },
    handleSave: function handleSave() {
      if (this.multipleSelection.length != 1) {
        return this.$message.error('只能选择一个赛事');
      }
      var selectDataGame = this.multipleSelection[0];
      console.log(selectDataGame);
      this.$emit('selectDataGame', selectDataGame);
    }
  }
};
exports.default = _default;