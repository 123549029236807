"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _typeof2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.replace");
var _wxgzh = require("@/api/wxhyyy/wxgzh");
var _wxHy = require("@/api/wxhyyy/wxHy");
var _auth = require("@/utils/auth");
var _admincut = _interopRequireDefault(require("@/components/admincut"));
var _tinyMce = _interopRequireDefault(require("@/components/tiny-mce1"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-redeclare */

// import editor from '@/components/kindeditor'

var baseUrl = '';
var _default = {
  components: {
    admincut: _admincut.default,
    TinyMce: _tinyMce.default
  },
  filters: {
    ToText: function ToText(HTML) {
      var input = HTML;
      return input.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, '').replace(/<[^>]+?>/g, '').replace(/\s+/g, ' ').replace(/ /g, ' ').replace(/>/g, ' ');
    }
  },
  data: function data() {
    return {
      // 视频列表选中
      viloaing: false,
      currvi: null,
      previ: false,
      viSrc: '',
      voload: false,
      viload: false,
      vord: false,
      vird: false,
      autoup: false,
      toolbar: 'link',
      plugins: 'link',
      editorText: '直接初始化值',
      // 双向同步的变量
      editorText1: '直接初始化值1',
      editorTextCopy: '',
      // content-change 事件回掉改变的对象
      myHeaders: {
        Authorization: 'Bearer ' + (0, _auth.getToken)(),
        'appId': sessionStorage.getItem('appId')
      },
      ius: 0,
      // loading
      loading1: true,
      loading2: true,
      loading3: true,
      // 上传地址
      // posturl: baseUrl + '/api-memberscrm/member/admin/file/upload/single',
      posturl: process.env.VUE_APP_BASE_API + '/api-memberscrm/member/admin/file/upload/single',
      // posturl: baseUrl + '/api/v1/api-memberscrm/member/admin/file/upload/single',
      posturl3: process.env.VUE_APP_BASE_API + '/api-memberscrm/member/admin/file/upload/single4',
      // posturl3: baseUrl + '/api/v1/api-memberscrm/member/admin/file/upload/single4',
      // 图片列表
      Lists: [],
      // 语音列表
      voLists: [],
      // 视频列表
      viLists: [],
      // 视频上传
      videoForm: {
        // id: '',
        name: ''
      },
      videoUp: false,
      // 语音上传
      voiceForm: {
        // id: '',
        name: ''
      },
      voiceUp: false,
      circleUrl: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
      activeName: 'first',
      // 回复文本框
      bgztext: '',
      sdtext: '',
      // 被关注回复输入框
      bzghf: {
        content: '',
        contentType: 1,
        status: '',
        type: 1
      },
      bzgclj: {
        content: '',
        url: ''
      },
      bzgdialogVisible: false,
      // 收到消息回复
      sdhf: {
        content: '',
        contentType: 1,
        status: '',
        type: 2
      },
      sdclj: {
        content: '',
        url: ''
      },
      sddialogVisible: false,
      value1: false,
      value2: false,
      value3: false,
      isDsb: true,
      isDsb1: true,
      input2: '',
      // 素材库选择
      dialogVisibletp: false,
      dialogVisibleyy: false,
      dialogVisiblesp: false,
      //

      // 搜索
      options: [],
      value: [],
      list: [],
      loading: false,
      // 添加关键词
      centerDialogVisible: false,
      form: {
        hfType: '',
        name: '',
        wxContentReplyDOList: [{
          type: '',
          content: ''
        }],
        wxKeywordDOList: [{
          type: '',
          val: ''
        }]
      },
      options1: [{
        value: 1,
        label: '部分匹配'
      }, {
        value: 2,
        label: '完全匹配'
      }],
      keyWordtype: [{
        name: '1'
      }, {
        name: '1'
      }, {
        name: '1'
      }, {
        name: '1'
      }, {
        name: '1'
      }],
      // 关键词图片 语音 视频
      cen: [{
        content: ''
      }],
      gjcimg: [{
        con: ''
      }, {
        con: ''
      }, {
        con: ''
      }, {
        con: ''
      }, {
        con: ''
      }],
      gjcvo: [{
        name: '',
        url: ''
      }, {
        name: '',
        url: ''
      }, {
        name: '',
        url: ''
      }, {
        name: '',
        url: ''
      }, {
        name: '',
        url: ''
      }],
      gjcvi: [{
        name: '',
        url: ''
      }, {
        name: '',
        url: ''
      }, {
        name: '',
        url: ''
      }, {
        name: '',
        url: ''
      }, {
        name: '',
        url: ''
      }],
      formLabelWidth: '120px',
      formLabelWidth1: '50px',
      // 表格
      tableData: [],
      // 回复单选
      radio: '1',
      // 上传
      fileList: [],
      // 查询数据类型
      formInline: {
        pageNo: 1,
        pageSize: 10
      },
      total: 0,
      // 分页类型
      mdtype: null,
      procurrentRow: '',
      vocurrentRow: '',
      vicurrentRow: '',
      currentRow: '',
      // 图片拼接地址
      twImgurl: 'http://dev20gw.panpass.cn/images/',
      scImgurl: '',
      // 展示地址
      proUrl: '',
      voUrl: '',
      viUrl: '',
      clickCheck: -1,
      // 关键词查询
      keycode: {
        keyword: '',
        pageNo: 1,
        pageSize: 10
      },
      keycodeList: [{
        data: 1,
        name: 2,
        address: 3,
        addnum: 4
      }],
      keyload: true,
      tabPosition: '文字',
      keytit: '关键词',
      tabinx: 0
    };
  },
  watch: {
    'bgztext': function bgztext(newval, oldval) {
      var that = this;
      // if(newval.replace(/(^\s*)|(\s*$)/g, '') == '') {
      //   return
      // }
      if (newval) {
        if (newval.length > 600) {
          this.$message.warning('文字超出限制');
          return;
        }
        that.bzghf.content = that.totet(newval);
      }

      // if (that.bgztext) {
      //   if (
      //     that.bgztext.replace(/(^\s*)|(\s*$)/g, '') == '' &&
      //   that.proUrl == '' &&
      //   that.voUrl == '' &&
      //   that.viUrl == ''
      //   ) {
      //     that.isDsb = true
      //   } else {
      //     that.isDsb = false
      //   }
      // }
    },

    'bzghf.content': function bzghfContent(newval, oldval) {
      var that = this;
      console.log(newval);
      // if (that.bgztext) {
      if (that.bgztext.replace(/(^\s*)|(\s*$)/g, '') == '' && that.proUrl == '' && that.voUrl == '' && that.viUrl == '') {
        that.isDsb = true;
      } else {
        that.isDsb = false;
      }
      // }
    },

    'sdtext': function sdtext(newval, oldval) {
      var that = this;
      if (newval) {
        if (newval.length > 600) {
          that.$message.warning('文字超出限制');
          return;
        }
        that.sdhf.content = that.totet(newval);
      }

      // if (that.sdtext) {
      // if (
      //   that.sdtext.replace(/(^\s*)|(\s*$)/g, '') == '' &&
      // that.proUrl == '' &&
      // that.voUrl == '' &&
      // that.viUrl == ''
      // ) {
      //   that.isDsb1 = true
      // } else {
      //   that.isDsb1 = false
      // }
      // }
    },

    'sdhf.content': function sdhfContent(newval, oldval) {
      var that = this;
      // if (that.sdtext) {
      if (that.sdtext.replace(/(^\s*)|(\s*$)/g, '') == '' && that.proUrl == '' && that.voUrl == '' && that.viUrl == '') {
        that.isDsb1 = true;
      } else {
        that.isDsb1 = false;
      }
      // }
    }
  },
  created: function created() {
    this.twImgurl = _wxHy.base;
    this.scImgurl = _wxHy.basesc;
  },
  mounted: function mounted() {
    var _this = this;
    this.selectKeybut();
    this.getsearchByType();
    (0, _wxgzh.findKeyword)(this.keycode).then(function (res) {
      // console.log('resssssssss',res)
      var res = res.data;
      // console.log(res)
      if (res.code * 1 === 200) {
        _this.keycodeList = res.data.records;
        _this.total = res.data.total;
        _this.keyload = false;
      } else {
        _this.$message.warning(res.msg);
      }
    });
  },
  methods: {
    xzLoadvi: function xzLoadvi(index, val) {
      this.$refs.singleTable.setCurrentRow(val);
      this.vicurrentRow = val.mediaId;
      if (val.url) {
        this.viUrl = {
          name: val.name,
          url: this.scImgurl + val.url
        };
      } else {
        this.viUrl = {
          name: val.name,
          url: null
        };
      }
      // if (val.name) {
      //   this.viUrl = val.name
      // } else {
      //   this.viUrl = ''
      // }
      this.clickCheck = index;
      console.log(this.viUrl);
      if (this.bzghf.type == 1) {
        this.bzghf.content = this.vicurrentRow;
      }
      if (this.sdhf.type == 2) {
        this.sdhf.content = this.vicurrentRow;
      }
      if (this.activeName == 'third') {
        this.gjcvi[this.tabinx] = this.viUrl;
        this.form.wxContentReplyDOList[this.tabinx].content = this.vicurrentRow;
      }
    },
    previewvi: function previewvi(index, row) {
      var _this2 = this;
      (0, _wxgzh.materialVideoInfo)({
        mediaId: row.mediaId,
        appId: sessionStorage.getItem('appId')
      }).then(function (res) {
        console.log(res);
        var res = res.data;
        _this2.previ = true;
        _this2.viSrc = res.data;
      });
    },
    imghandleCurrentChange: function imghandleCurrentChange(val) {
      var _this3 = this;
      console.log("\u5F53\u524D\u9875: ".concat(val));
      this.imgloaing = true;
      this.formInline.pageNo = val;
      var n = {
        mediaType: 3,
        pageNo: this.formInline.pageNo,
        pageSize: this.formInline.pageSize
      };
      (0, _wxgzh.searchResource)(n).then(function (res) {
        console.log(res);
        var res = res.data;
        if (!res.code === 200) {
          return _this3.$message.warning(res.msg);
        }
        _this3.Lists = res.data.records;
        _this3.loading1 = false;
        _this3.total = res.data.total;
      });
    },
    vohandleCurrentChange: function vohandleCurrentChange(val) {
      var _this4 = this;
      this.formInline.pageNo = val;
      this.mdtype = 2;
      var n = {
        mediaType: this.mdtype,
        pageNo: this.formInline.pageNo,
        pageSize: this.formInlinepageSize
      };
      (0, _wxgzh.searchResource)(n).then(function (res) {
        console.log(res);
        var res = res.data;
        if (!res.code == 200) {
          return _this4.$message.warning(res.msg);
        }
        _this4.voLists = res.data.records;
        _this4.voLists.forEach(function (element) {
          console.log(element);
          element.name == null ? element.name = '未命名' : element.name;
        });
        _this4.total = res.data.total;
      });
    },
    vihandleCurrentChange: function vihandleCurrentChange(val) {
      var _this5 = this;
      this.viloaing = true;
      this.formInline.pageNo = val;
      this.mdtype = 1;
      var n = {
        mediaType: this.mdtype,
        pageNo: this.formInline.pageNo,
        pageSize: this.formInlinepageSize,
        appId: sessionStorage.getItem('appId')
      };
      (0, _wxgzh.searchResource)(n).then(function (res) {
        console.log(res);
        var res = res.data;
        if (!res.code === 200) {
          return _this5.$message.warning(res.msg);
        } else {
          _this5.viloaing = false;
          _this5.viLists = res.data.records;
          _this5.total = res.data.total;
        }
      });
    },
    delcontent: function delcontent(index, rows) {
      var _this6 = this;
      this.$confirm('此操作将删除该回复内容, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        rows.splice(index, 1);
        _this6.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(function () {
        _this6.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    ToText: function ToText(HTML) {
      var input = HTML;
      return input.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, '').replace(/<[^>]+?>/g, '').replace(/\s+/g, ' ').replace(/ /g, ' ').replace(/>/g, ' ');
    },
    totet: function totet(val) {
      return val.replace(/<\/?p[^>]*>/gi, '');
    },
    onContentChange: function onContentChange(val) {
      // if (val.length > 600) {
      //   this.$message.warning('文字超出限制')
      //   return
      // }
      // this.editorTextCopy = this.totet(val)
      // console.log(this.editorTextCopy)
      // var that = this
      // console.log('dddd' + val)
      // if (val.length > 600) {
      //   this.$message.warning('文字超出限制')
      //   return
      // }
      // that.sdhf.content = that.totet(val)
    },
    afterChange: function afterChange() {},
    // 删除自动回复信息
    delautomsg: function delautomsg(val) {
      var _this7 = this;
      console.log(this.activeName);
      if (this.activeName == 'first') {
        var a = {
          type: 1,
          appId: sessionStorage.getItem('appId')
        };
        this.bzghf.content = '';
        this.bgztext = '';
        this.proUrl = '';
        this.voUrl = '';
        this.viUrl = '';
      } else if (this.activeName == 'second') {
        var a = {
          type: 2,
          appId: sessionStorage.getItem('appId')
        };
        this.sdhf.content = '';
        this.sdtext = '';
        this.proUrl = '';
        this.voUrl = '';
        this.viUrl = '';
      }
      console.log(a);
      (0, _wxgzh.deleteFollowReply)(a, a.appId).then(function (res) {
        var res = res.data;
        if (res.code * 1 === 200) {
          _this7.$message.success('删除成功');
        } else {
          _this7.$message.warning('删除失败');
        }
      });
    },
    // 被关注回复
    // bgzhf() {
    //   console.log(this.value1)
    // const n = {
    //   isUse: this.value1
    // }
    // updateKeyWordBut(n).then(res => {
    //   if (res.code * 1 === 200) {
    //     this.$message.success('保存成功')
    //   } else {
    //     this.$message.warning(res.msg)
    //   }
    // })
    // },
    // 关键词回复开关
    gjchf: function gjchf() {
      var _this8 = this;
      console.log(this.value3);
      var n = {
        isUse: this.value3,
        appId: sessionStorage.getItem('appId')
      };
      (0, _wxgzh.updateKeyWordBut)(n).then(function (res) {
        var res = res.data;
        if (res.code * 1 === 200) {
          _this8.$message.success('保存成功');
        } else {
          _this8.$message.warning(res.msg);
        }
      });
    },
    // 编辑关键词表格
    editkey: function editkey(val) {
      var that = this;
      // this.centerDialogVisible = true
      that.keytit = '修改关键词';
      console.log(val);
      var n = {
        id: val.hfId,
        appId: sessionStorage.getItem('appId')
      };
      // that.cen = [{ content: '' }]
      // that.gjcimg = [{ con: '' }]
      // that.gjcvo = [{ name: '', url: '' }]
      // that.gjcvi = [{ name: '', url: '' }]

      (0, _wxgzh.selectByKeyWordId)(n).then(function (res) {
        var res = res.data;
        console.log(res);
        if (res.code === 200) {
          // const val = res.data
          console.log(res.data);
          that.form = res.data;
          console.log(that.form);
          // if (res.data.wxContentReplyDOList[0].filesDO === null) {
          //   that.$message.warning('数据有误')
          //   that.centerDialogVisible = false
          //   return false
          // }
          var arr = res.data.wxContentReplyDOList;
          arr.forEach(function (v, i) {
            that.keyWordtype[i].name = v.type;
            var a = {
              content: ''
            };
            that.cen.push(a);
            that.gjcimg.push({
              con: ''
            });
            that.gjcvo.push({
              name: '',
              url: ''
            });
            that.gjcvi.push({
              name: '',
              url: ''
            });
            // if(v.type == '1'){
            //   this.cen[i].content = v.centent
            // }else if(v.type == '2')
            // if (v.filesDO === null) {

            // }
            switch (v.type) {
              case '1':
                that.cen[i].content = v.content;
                break;
              case '3':
                that.gjcimg[i].con = v.filesDO.url;
                break;
              case '4':
                that.gjcvo[i].url = v.filesDO.url;
                that.gjcvo[i].name = v.filesDO.name;
                break;
              case '5':
                that.gjcvi[i].url = v.filesDO.url;
                that.gjcvi[i].name = v.filesDO.name;
                break;
            }
            // console.log(that.gjcimg)
          });

          that.centerDialogVisible = true;
        } else {
          that.$message.warning('操作失败，请重试');
        }
      });
    },
    // 删除关键词表格
    deleteRow: function deleteRow(index, row, rows) {
      var that = this;
      var n = {
        hfId: row.hfId,
        appId: sessionStorage.getItem('appId')
      };
      (0, _wxgzh.deleteDataByHfId)(n).then(function (res) {
        var res = res.data;
        if (res.code * 1 === 200) {
          rows.splice(index, 1);
          that.$message.success('删除成功');
          that.$router.go(0);
        } else {
          that.$message.warning(res.msg);
        }
      });
      // rows.splice(index, 1)
    },
    getsearchByType: function getsearchByType() {
      var _this9 = this;
      console.log(this.mdtype);
      this.mdtype = 1;
      var n = {
        type: this.mdtype,
        appId: sessionStorage.getItem('appId')
      };
      console.log(n);
      (0, _wxgzh.searchByType)(n).then(function (res) {
        var res = res.data;
        if (res.code * 1 === 200) {
          if (res.data) {
            console.log('weewrewrwer');
          }
          console.log(res);
          console.log(res.data.hasOwnProperty('wxReplyDTO'));
          if (res.data.hasOwnProperty('wxReplyDTO')) {
            if (res.data.wxReplyDTO.status == '1') {
              _this9.value1 = true;
            } else {
              _this9.value1 = false;
            }
          }
          if (res.data.wxReplyDTO.contentType) {
            switch (res.data.wxReplyDTO.contentType) {
              case '1':
                _this9.tabPosition = '文字';
                _this9.bgztext = res.data.wxReplyDTO.content;
                break;
              case '3':
                _this9.tabPosition = '图片';
                _this9.proUrl = res.data.file.url;
                break;
              case '4':
                _this9.tabPosition = '语音';
                _this9.voUrl = {
                  name: res.data.file.name,
                  url: _this9.twImgurl + res.data.file.url
                };
                break;
              case '5':
                _this9.tabPosition = '视频';
                _this9.viUrl = {
                  name: res.data.file.name,
                  url: _this9.twImgurl + res.data.file.url
                };
                break;
              default:
                _this9.tabPosition = '文字';
                _this9.bgztext = res.data.wxReplyDTO.content;
            }
          }
        }
      });
    },
    findkey: function findkey() {
      var _this10 = this;
      (0, _wxgzh.findKeyword)(this.keycode).then(function (res) {
        var res = res.data;
        if (res.code * 1 === 200) {
          if (res.data.total * 1 === 0) {
            _this10.keycodeList = '';
            _this10.$message.warning('未找到关键字');
          }
          _this10.keycodeList = res.data.records;
          _this10.keyload = false;
          _this10.total = res.data.total;
        } else {
          _this10.$message.warning(res.msg);
        }
      });
    },
    // 关键字分页
    twhandleCurrentChange: function twhandleCurrentChange(val) {
      this.keycode.pageNo = val;
      this.keyload = true;
      this.findkey();
    },
    changetext: function changetext(index) {
      this.form.wxContentReplyDOList[index].content = this.cen[index].content;
      console.log(this.cen[index].content);
    },
    // 获取内容类型
    handleClick1: function handleClick1(index, event) {
      this.tabinx = index;
      switch (event.index) {
        case '0':
          this.form.wxContentReplyDOList[index].type = 1;
          break;
        case '1':
          this.form.wxContentReplyDOList[index].type = 3;
          break;
        case '2':
          this.form.wxContentReplyDOList[index].type = 4;
          break;
        case '3':
          this.form.wxContentReplyDOList[index].type = 5;
          break;
        default:
          this.form.wxContentReplyDOList[index].type = 1;
      }
      console.log(index);
      console.log(this.form);
    },
    // 添加关键词
    addKeyws: function addKeyws() {
      this.keytit = '添加关键词';
      this.centerDialogVisible = true;
      this.proUrl = '';
      this.voUrl = '';
      this.viUrl = '';
      this.form.name = '';
      this.form.wxContentReplyDOList = [{
        type: 1,
        content: ''
      }];
      this.form.wxKeywordDOList = [{
        type: '',
        val: ''
      }];
      this.cen = [{
        content: ''
      }];
      this.gjcimg = [{
        con: ''
      }];
      this.gjcvo = [{
        name: '',
        url: ''
      }];
      this.gjcvi = [{
        name: '',
        url: ''
      }];
      console.log(this.gjcimg);
    },
    // 添加文本框
    addcontent: function addcontent(rows) {
      if (rows.length == 5) {
        this.$message.warning('最多创建5条回复');
        return;
      }
      var n = {
        type: 1,
        content: ''
      };
      var a = this.tabinx;
      a++;
      this.tabinx = a;
      rows.push(n);
      this.cen.push({
        type: '',
        content: ''
      });
      this.gjcimg.push({
        con: ''
      });
      this.gjcvo.push({
        name: '',
        url: ''
      });
      this.gjcvi.push({
        name: '',
        url: ''
      });
    },
    // 查询关键词
    searchkeycode: function searchkeycode() {
      var _this11 = this;
      this.keyload = true;
      console.log(this.keycode);
      if (this.keycode.keyword.replace(/(^\s*)|(\s*$)/g, '') == '') {
        this.$message.warning('请输入有效字符');
      }
      console.log(this.keycode);
      (0, _wxgzh.findKeyword)(this.keycode).then(function (res) {
        var res = res.data;
        console.log(res);
        if (res.code * 1 === 200) {
          if (res.data.total * 1 === 0) {
            _this11.keycodeList = '';
            _this11.$message.warning('未找到关键字');
          }
          _this11.keycodeList = res.data.records;
          _this11.keyload = false;
        } else {
          _this11.$message.warning(res.msg);
        }
      });
    },
    // 提交关键词
    addKeyWordReply: function addKeyWordReply(val) {
      var _this12 = this;
      console.log(val);
      if (val.hasOwnProperty('id')) {
        console.log(val);
        val.appId = sessionStorage.getItem('appId');
        (0, _wxgzh.updateKeyWordReply)(val).then(function (res) {
          var res = res.data;
          if (res.code * 1 === 200) {
            _this12.$message.success('修改成功');
            _this12.keyload = true;
            (0, _wxgzh.findKeyword)(_this12.keycode).then(function (res) {
              var res = res.data;
              console.log(res);
              if (!res.code == 200) {
                _this12.$message.warning(res.msg);
              }
              _this12.keycodeList = res.data.records;
              _this12.keyload = false;
            });
          } else {
            _this12.$message.warning(res.msg);
            return;
          }
        });
      } else {
        val.appId = sessionStorage.getItem('appId');
        (0, _wxgzh.insertKeyWordReply)(val, val.appId).then(function (res) {
          var res = res.data;
          if (res.code * 1 === 200) {
            _this12.$message.success('提交成功');
            _this12.keyload = true;
            (0, _wxgzh.findKeyword)(_this12.keycode).then(function (res) {
              var res = res.data;
              if (res.code * 1 === 200) {
                _this12.keycodeList = res.data.records;
                _this12.keyload = false;
              } else {
                _this12.$message.warning(res.msg);
              }
            });
          } else {
            _this12.$message.warning(res.msg);
            return;
          }
        });
      }
      this.centerDialogVisible = false;
    },
    // 增减关键词
    addKeyword: function addKeyword(index, rows) {
      if (rows.length == 10) {
        this.$message.warning('最多创建10条关键词');
        return;
      }
      var n = {
        type: '',
        val: ''
      };
      rows.push(n);
    },
    reduceKeyword: function reduceKeyword(index, rows) {
      rows.splice(index, 1);
    },
    // 选中语音列表
    handleCurrentChangevo: function handleCurrentChangevo(val) {
      console.log(val);
      console.log(this.activeName);
      this.vocurrentRow = val.mediaId;
      this.voUrl = {
        name: val.name,
        url: this.twImgurl + val.url
      };
      if (this.bzghf.type == 1) {
        this.bzghf.content = this.vocurrentRow;
      }
      if (this.sdhf.type == 2) {
        this.sdhf.content = this.vocurrentRow;
      }
      if (this.activeName == 'third') {
        this.gjcvo[this.tabinx] = this.voUrl;
        this.form.wxContentReplyDOList[this.tabinx].content = this.vocurrentRow;
      }
    },
    pitchOn: function pitchOn(index, row) {
      console.log(row);
    },
    // 选中视频列表
    tabVi: function tabVi(index, val) {
      console.log(index);
      console.log(val);
      this.vicurrentRow = val.mediaId;
      if (val.url) {
        this.viUrl = {
          name: val.name,
          url: this.scImgurl + val.url
        };
      } else {
        this.viUrl = {
          name: val.name,
          url: null
        };
      }
      this.clickCheck = index;
      console.log(this.viUrl);
      if (this.bzghf.type == 1) {
        this.bzghf.content = this.vicurrentRow;
      }
      if (this.sdhf.type == 2) {
        this.sdhf.content = this.vicurrentRow;
      }
      if (this.activeName == 'third') {
        this.gjcvi[this.tabinx] = this.viUrl;
        this.form.wxContentReplyDOList[this.tabinx].content = this.vicurrentRow;
      }
    },
    // 选择图片
    tabImg: function tabImg(index, val) {
      this.procurrentRow = val.mediaId;
      this.proUrl = val.url;
      this.clickCheck = index;
      if (this.bzghf.type == 1) {
        this.bzghf.content = this.procurrentRow;
        console.log(this.bzghf.content);
      }
      if (this.sdhf.type == 2) {
        this.sdhf.content = this.procurrentRow;
      }
      if (this.activeName == 'third') {
        this.gjcimg[this.tabinx].con = val.url;
        this.form.wxContentReplyDOList[this.tabinx].content = this.procurrentRow;
      }
    },
    // 删除图片语音视频展示
    delProurl: function delProurl(index) {
      this.proUrl = '';
      console.log(index);
      console.log((0, _typeof2.default)(index));
      if (typeof index === 'number') {
        this.gjcimg[index].con = '';
      }
      console.log(this.gjcimg);
    },
    // 删除语音展示
    delvourl: function delvourl(index) {
      this.voUrl = '';
      if (typeof index === 'number') {
        this.gjcvo[index].url = '';
      }
    },
    // 删除视频展示
    delviurl: function delviurl(index) {
      this.viUrl = '';
      if (typeof index === 'number') {
        this.gjcvi[index].url = '';
      }
    },
    // 图片列表
    getProimg: function getProimg() {
      var _this13 = this;
      this.dialogVisibletp = true;
      this.formInline.pageNo = 1;
      this.mdtype = 3;
      var n = {
        mediaType: this.mdtype,
        pageNo: this.formInline.pageNo,
        pageSize: this.formInlinepageSize,
        appId: sessionStorage.getItem('appId')
      };
      (0, _wxgzh.searchResource)(n).then(function (res) {
        console.log(res);
        var res = res.data;
        if (!res.code == 200) {
          return _this13.$message.warning(res.msg);
        }
        _this13.Lists = res.data.records;
        _this13.loading1 = false;
        console.log(_this13.loading1);
        _this13.total = res.data.total;
        console.log(_this13.Lists);
        var s = _this13.Lists.length - 1;
        console.log(_this13.clickCheck);
      });
    },
    // 语音列表
    getProvoice: function getProvoice() {
      var _this14 = this;
      this.dialogVisibleyy = true;
      this.formInline.pageNo = 1;
      this.mdtype = 2;
      var n = {
        mediaType: this.mdtype,
        pageNo: this.formInline.pageNo,
        pageSize: this.formInlinepageSize,
        appId: sessionStorage.getItem('appId')
      };
      (0, _wxgzh.searchResource)(n).then(function (res) {
        console.log(res);
        var res = res.data;
        if (!res.code == 200) {
          return _this14.$message.warning(res.msg);
        }
        _this14.voLists = res.data.records;
        _this14.voLists.forEach(function (element) {
          console.log(element);
          element.name == null ? element.name = '未命名' : element.name;
        });
        _this14.total = res.data.total;
        // this.voLists.content = "http://testsaas.panpass.com/images/group1/M00/00/14/CghTnl3mOo2AYJV0AAAAAAAAAAA760.mp3"
        _this14.loading2 = false;
      });
    },
    // 视频列表
    getProvideo: function getProvideo() {
      var _this15 = this;
      this.formInline.pageNo = 1;
      this.clickCheck = -1;
      this.dialogVisiblesp = true;
      this.mdtype = 1;
      var n = {
        mediaType: this.mdtype,
        pageNo: this.formInline.pageNo,
        pageSize: this.formInlinepageSize,
        appId: sessionStorage.getItem('appId')
      };
      (0, _wxgzh.searchResource)(n).then(function (res) {
        console.log(res);
        var res = res.data;
        if (!res.code == 200) {
          return _this15.$message.warning(res.msg);
        }
        _this15.viLists = res.data.records;
        _this15.total = res.data.total;
        _this15.loading3 = false;
      });
    },
    // 上传语音
    vohandleSuccess: function vohandleSuccess(file, fileList) {
      // this.voiceForm.id = file.data.id
      this.$message.success('上传成功');
      this.voiceUp = false;
      this.$refs.uploadvo.clearFiles();
      this.voiceForm.name = '';
    },
    voSize: function voSize(file) {
      var isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.closeAll();
        this.$message.error('上传语音大小不能超过 2MB!');
      }
      if (!(file.type === 'audio/mp3' || file.type === '')) {
        this.$message.closeAll();
        this.$message.error('上传正确的语音文件!');
        return false;
      }
      return isLt2M;
    },
    upLoadvo: function upLoadvo() {
      if (this.voiceForm.name.replace(/(^\s*)|(\s*$)/g, '') === '' || this.voiceForm.name.length > 14) {
        return this.$message.warning('请输入有效名称');
      }
      if (!this.vord) {
        return this.$message.warning('请上传文件');
      }
      this.$refs.uploadvo.submit();
      // updateFiles(this.voiceForm).then(res => {
      //   if (!res.code == 200) {
      //     this.$message.error(res.msg)
      //   }
      //   this.$message.success('上传成功')
      //   this.voiceUp = false
      // })
    },
    vochange: function vochange(file, fileList) {
      if (file.status === 'ready') {
        this.vord = true;
      }
    },
    // 上传视频
    vihandleSuccess: function vihandleSuccess(file, fileList) {
      this.$message.success('上传成功');
      this.videoUp = false;
      this.$refs.uploadvi.clearFiles();
      this.videoForm.name = '';
      // this.$message.success("上传成功")
      // console.log(file)
      // this.videoForm.id = file.data.id
    },
    viSize: function viSize(file) {
      var isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.closeAll();
        this.$message.error('上传语音大小不能超过 10MB!');
      }
      return isLt2M;
    },
    upLoadvi: function upLoadvi() {
      if (this.videoForm.name.replace(/(^\s*)|(\s*$)/g, '') == '' || this.videoForm.name.length > 10) {
        return this.$message.warning('请输入有效名称');
      }
      if (!this.vird) {
        return this.$message.warning('请上传文件');
      }
      this.$refs.uploadvi.submit();
      // this.videoForm.appId = sessionStorage.getItem('appId')
      // updateFiles(this.videoForm).then(res => {
      //   if (!res.code == 200) {
      //     this.$message.error(res.msg)
      //   }
      //   this.$message.success('上传成功')
      //   this.videoUp = false
      // })
    },
    vichange: function vichange(file, fileList) {
      if (file.status === 'ready') {
        this.vird = true;
      }
    },
    // 上传图片
    handleSuccess: function handleSuccess(file, fileList) {
      // let o = URL.createObjectURL(file);
      // this.Lists.push(fileList)
      // this.Lists.push({name:fileList.name,url:URL.createObjectURL(fileList.raw)})
      this.$message.success('上传成功');
    },
    imgSize: function imgSize(file) {
      var isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.closeAll();
        this.$message.error('上传图片大小不能超过 2MB!');
      }
      return isLt2M;
    },
    handError: function handError() {
      this.$message.error('上传失败');
    },
    remoteMethod: function remoteMethod(query) {
      var _this16 = this;
      if (query !== '') {
        this.loading = true;
        setTimeout(function () {
          _this16.loading = false;
          _this16.options = _this16.list.filter(function (item) {
            return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.options = [];
      }
    },
    // 导航
    handleClick: function handleClick(tab, event) {
      var _this17 = this;
      if (tab.index == 0) {
        this.proUrl = '';
        this.clickCheck = -1;
        this.voUrl = '';
        this.viUrl = '';
        this.mdtype = 1;
        var n = {
          type: this.mdtype,
          appId: sessionStorage.getItem('appId')
        };
        (0, _wxgzh.searchByType)(n).then(function (res) {
          if (res.code * 1 === 200) {
            _this17.isDsb = false;
            if (res.data.hasOwnProperty('wxReplyDTO')) {
              if (res.data.wxReplyDTO.status == '1') {
                _this17.value1 = true;
              } else {
                _this17.value1 = false;
              }
            }
            if (res.data.wxReplyDTO.contentType) {
              _this17.bzghf.contentType = res.data.wxReplyDTO.contentType;
              switch (res.data.wxReplyDTO.contentType) {
                case '1':
                  _this17.tabPosition = '文字';
                  _this17.bgztext = res.data.wxReplyDTO.content;
                  break;
                case '3':
                  _this17.tabPosition = '图片';
                  _this17.proUrl = res.data.file.url;
                  break;
                case '4':
                  _this17.tabPosition = '语音';
                  _this17.voUrl = {
                    name: res.data.file.name,
                    url: _this17.scImgurl + res.data.file.url
                  };
                  break;
                case '5':
                  _this17.tabPosition = '视频';
                  _this17.viUrl = {
                    name: res.data.file.name,
                    url: res.data.file.url ? _this17.scImgurl + res.data.file.url : null
                  };
                  break;
                default:
                  _this17.tabPosition = '文字';
                  _this17.bgztext = res.data.wxReplyDTO.content;
              }
            }
          }
        });
      }
      if (tab.index == 1) {
        this.proUrl = '';
        this.clickCheck = -1;
        this.voUrl = '';
        this.viUrl = '';
        this.mdtype = 2;
        var _n = {
          type: this.mdtype,
          appId: sessionStorage.getItem('appId')
        };
        (0, _wxgzh.searchByType)(_n).then(function (res) {
          var res = res.data;
          if (res.code * 1 === 200) {
            _this17.isDsb1 = false;
            console.log(res);
            if (res.data.hasOwnProperty('wxReplyDTO')) {
              if (res.data.wxReplyDTO.status == '1') {
                _this17.value2 = true;
              } else {
                _this17.value2 = false;
              }
            }
            if (res.data.wxReplyDTO.contentType) {
              _this17.sdhf.contentType = res.data.wxReplyDTO.contentType;
              switch (res.data.wxReplyDTO.contentType) {
                case '1':
                  _this17.tabPosition = '文字';
                  _this17.sdtext = res.data.wxReplyDTO.content;
                  break;
                case '3':
                  _this17.tabPosition = '图片';
                  _this17.proUrl = res.data.file.url;
                  break;
                case '4':
                  _this17.tabPosition = '语音';
                  _this17.voUrl = {
                    name: res.data.file.name,
                    url: _this17.scImgurl + res.data.file.url
                  };
                  break;
                case '5':
                  _this17.tabPosition = '视频';
                  _this17.viUrl = {
                    name: res.data.file.name,
                    url: res.data.file.url ? _this17.scImgurl + res.data.file.url : null
                  };
                  break;
                default:
                  _this17.tabPosition = '文本';
                  _this17.sdtext = res.data.wxReplyDTO.content;
              }
            }
          }
        });
      }
      if (tab.index == 2) {
        // selectKeyWordBut().then(res => {
        //   console.log(res)
        //   if (res.code * 1 === 200) {
        //     this.value3 = res.data.type
        //   } else {
        //     this.$message.warning(res.msg)
        //   }
        // })
        this.selectKeybut();
      }
    },
    // 获取关键词回复开关
    selectKeybut: function selectKeybut() {
      var _this18 = this;
      // const n = { appId: sessionStorage.getItem('appId') }
      // console.log(n)
      this.findkey();
      (0, _wxgzh.selectKeyWordBut)().then(function (res) {
        var res = res.data;
        console.log(res);
        if (res.code * 1 === 200) {
          if (res.data.type * 1 === 1) {
            _this18.activeName = 'third';
          }
          _this18.value3 = res.data.type;
        } else {
          _this18.$message.warning(res.msg);
        }
      });
    },
    // 上传
    handleRemove: function handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview: function handlePreview(file) {
      console.log(file);
    },
    handleExceed: function handleExceed(files, fileList) {
      this.$message.warning("\u5F53\u524D\u9650\u5236\u9009\u62E9 1 \u4E2A\u6587\u4EF6\uFF0C\u672C\u6B21\u9009\u62E9\u4E86 ".concat(files.length, " \u4E2A\u6587\u4EF6\uFF0C\u5171\u9009\u62E9\u4E86 ").concat(fileList.length, " \u4E2A\u6587\u4EF6"));
    },
    handleClose: function handleClose(done) {
      done();
    },
    handdc2: function handdc2(activeName, oldActiveName) {
      this.bzghf.content = '';
    },
    // 回复文本类型
    handleClick2: function handleClick2(tab, event) {
      // this.bzghf.content = ''
      console.log(tab.index);
      switch (tab.index) {
        case '0':
          this.bzghf.contentType = 1;
          // this.proUrl = ''
          // this.voUrl = ''
          // this.viUrl = ''
          break;
        case '1':
          this.bzghf.contentType = 3;
          // this.bgztext = ' '
          // this.voUrl = ''
          // this.viUrl = ''
          break;
        case '2':
          this.bzghf.contentType = 4;
          // this.bgztext = ' '
          // this.proUrl = ''
          // this.viUrl = ''
          break;
        case '3':
          this.bzghf.contentType = 5;
          // this.bgztext = ' '
          // this.proUrl = ''
          // this.voUrl = ''

          break;
        // default:
        //   this.bzghf.contentType = 1
        //   this.bzghf.content = this.totet(this.bgztext)
      }
    },
    handdc3: function handdc3(activeName, oldActiveName) {
      this.sdhf.content = '';
    },
    handleClick3: function handleClick3(tab, event) {
      // this.sdhf.content = ''
      var that = this;
      console.log(that.sdhf);
      console.log(tab);
      switch (tab.index) {
        case '0':
          that.sdhf.contentType = 1;
          // that.proUrl = ''
          // that.voUrl = ''
          // that.viUrl = ''
          break;
        case '1':
          // that.sdtext = ' '
          // that.voUrl = ''
          // that.viUrl = ''
          that.sdhf.contentType = 3;
          break;
        case '2':
          that.sdhf.contentType = 4;
          // that.sdtext = ' '
          // that.proUrl = ''
          // that.viUrl = ''
          break;
        case '3':
          that.sdhf.contentType = 5;
          // that.sdtext = ' '
          // that.proUrl = ''
          // that.voUrl = ''
          break;
      }
    },
    // 被关注回复保存
    savedata: function savedata() {
      var _this19 = this;
      if (this.value1 == true) {
        this.bzghf.status = 1;
      } else {
        this.bzghf.status = 2;
      }
      // this.bzghf.content = this.bgztext

      this.bzghf.appId = sessionStorage.getItem('appId');
      console.log(this.bzghf);
      (0, _wxgzh.saveReply)(this.bzghf).then(function (res) {
        console.log(res);
        var res = res.data;
        if (res.code == 200) {
          _this19.$message({
            message: '保存成功',
            type: 'success'
          });
        } else {
          _this19.$message.error('保存失败');
        }
      });
    },
    // 收到消息回复保存
    savedata1: function savedata1() {
      var _this20 = this;
      if (this.value2 == true) {
        this.sdhf.status = 1;
      } else {
        this.sdhf.status = 2;
      }
      // this.sdhf.appId = sessionStorage.getItem('appId')
      console.log(this.sdhf);
      (0, _wxgzh.saveReply)(this.sdhf, this.sdhf.appId).then(function (res) {
        console.log(res);
        var res = res.data;
        if (res.code === 200) {
          _this20.$message({
            message: '保存成功',
            type: 'success'
          });
        } else {
          _this20.$message.error('保存失败');
        }
      });
    }
  }
};
exports.default = _default;