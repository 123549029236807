var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "page-container" }, [
    _c("div", { staticClass: "page-section" }, [
      _c(
        "section",
        [
          _c(
            "el-form",
            {
              ref: "searchForm",
              staticClass: "search-condition",
              attrs: {
                model: _vm.queryParams,
                "status-icon": "",
                "label-suffix": ":",
                "label-position": _vm.formConfig.labelPosition,
                "label-width": _vm.formConfig.labelWidth,
              },
            },
            [
              _c(
                "div",
                { staticClass: "cols" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "数码(袋/盒码)",
                                prop: "scanCode",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "filter-item",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  maxlength: "30",
                                  clearable: "",
                                  placeholder: "请输入",
                                },
                                model: {
                                  value: _vm.queryParams.scanCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryParams, "scanCode", $$v)
                                  },
                                  expression: "queryParams.scanCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "产品编码", prop: "productCode" },
                            },
                            [
                              _c("el-input", {
                                staticClass: "filter-item",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  maxlength: "30",
                                  clearable: "",
                                  placeholder: "请输入",
                                },
                                model: {
                                  value: _vm.queryParams.productCode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryParams,
                                      "productCode",
                                      $$v
                                    )
                                  },
                                  expression: "queryParams.productCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "产品名称", prop: "productName" },
                            },
                            [
                              _c("el-input", {
                                staticClass: "filter-item",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  maxlength: "30",
                                  clearable: "",
                                  placeholder: "请输入",
                                },
                                model: {
                                  value: _vm.queryParams.productName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryParams,
                                      "productName",
                                      $$v
                                    )
                                  },
                                  expression: "queryParams.productName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "奖励类型", prop: "awardType" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.queryParams.awardType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryParams,
                                        "awardType",
                                        $$v
                                      )
                                    },
                                    expression: "queryParams.awardType",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "全部", value: "" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "红包", value: "1" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "活动编码/名称",
                                prop: "activityCodeAndName",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "filter-item",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  maxlength: "30",
                                  clearable: "",
                                  placeholder: "请输入",
                                },
                                model: {
                                  value: _vm.queryParams.activityCodeAndName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryParams,
                                      "activityCodeAndName",
                                      $$v
                                    )
                                  },
                                  expression: "queryParams.activityCodeAndName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "消费者ID", prop: "consumerId" },
                            },
                            [
                              _c("el-input", {
                                staticClass: "filter-item",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  maxlength: "50",
                                  clearable: "",
                                  placeholder: "请输入",
                                },
                                model: {
                                  value: _vm.queryParams.consumerId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryParams, "consumerId", $$v)
                                  },
                                  expression: "queryParams.consumerId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "消费者昵称",
                                prop: "consumerNickName",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "filter-item",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  maxlength: "50",
                                  clearable: "",
                                  placeholder: "请输入",
                                },
                                model: {
                                  value: _vm.queryParams.consumerNickName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryParams,
                                      "consumerNickName",
                                      $$v
                                    )
                                  },
                                  expression: "queryParams.consumerNickName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "奖励时间", prop: "rangeTime" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "daterange",
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  clearable: false,
                                },
                                model: {
                                  value: _vm.queryParams.rangeTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryParams, "rangeTime", $$v)
                                  },
                                  expression: "queryParams.rangeTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.formConfig.btnFollow
                        ? _c(
                            "el-col",
                            {
                              staticClass: "head-btn-group",
                              style: {
                                width: _vm.formConfig.btnAreaHasShowMore,
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { "label-width": "0" } },
                                [
                                  _c(
                                    "div",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [_vm._v(" ")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.searchBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.searchBtn.icon,
                                      },
                                      on: { click: _vm.getList },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.btnConfig.searchBtn.text)
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.resetBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.resetBtn.icon,
                                      },
                                      on: { click: _vm.resetForm },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.btnConfig.resetBtn.text)
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _vm.formConfig.btnFollow
            ? _c("el-divider", { staticClass: "btnDivider" })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "btnArea" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: _vm.btnConfig.downLoadBtn.type,
                    size: _vm.btnConfig.size,
                    icon: _vm.btnConfig.downLoadBtn.icon,
                    loading: _vm.downLoading,
                  },
                  on: { click: _vm.exportExcel },
                },
                [_vm._v("导出")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                height: _vm.tabHeight,
                border: _vm.tableConfig.border,
                stripe: _vm.tableConfig.stripe,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "序号",
                  align: _vm.tableConfig.align,
                  width: "80",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "markCode",
                  label: "数码(袋/盒码)",
                  align: _vm.tableConfig.align,
                  "min-width": "140",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "productCode",
                  label: "产品编码",
                  align: _vm.tableConfig.align,
                  "min-width": "140",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "productName",
                  label: "产品名称",
                  align: _vm.tableConfig.align,
                  "min-width": "140",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "awardType",
                  label: "奖励类型",
                  align: _vm.tableConfig.align,
                  "min-width": "100",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.awardType == 1
                          ? _c("span", [_vm._v("红包")])
                          : _vm._e(),
                        _vm._v(" "),
                        row.awardType == 2
                          ? _c("span", [_vm._v("积分")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "awardDetail",
                  label: "奖励(元/积分)",
                  align: _vm.tableConfig.align,
                  "min-width": "140",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "headingUrl",
                  label: "终端微信头像",
                  align: _vm.tableConfig.align,
                  "min-width": "100",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("el-image", {
                          staticStyle: { width: "60px", height: "60px" },
                          attrs: { src: row.headingUrl, fit: "cover" },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "terminalNickName",
                  label: "终端微信昵称",
                  align: _vm.tableConfig.align,
                  "min-width": "120",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "openId",
                  label: "终端openid",
                  align: _vm.tableConfig.align,
                  "min-width": "100",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "terminalId",
                  label: "终端用户ID",
                  align: _vm.tableConfig.align,
                  "min-width": "100",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "activityCode",
                  label: "活动编码",
                  align: _vm.tableConfig.align,
                  "min-width": "140",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "activityName",
                  label: "活动名称",
                  align: _vm.tableConfig.align,
                  "min-width": "140",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "consumerHeadingUrl",
                  label: "消费者头像",
                  align: _vm.tableConfig.align,
                  "min-width": "100",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("el-image", {
                          staticStyle: { width: "60px", height: "60px" },
                          attrs: { src: row.consumerHeadingUrl, fit: "cover" },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "consumerNickName",
                  label: "消费者昵称",
                  align: _vm.tableConfig.align,
                  "min-width": "100",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "consumerOpenId",
                  label: "消费者openid",
                  align: _vm.tableConfig.align,
                  "min-width": "120",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "consumerId",
                  label: "消费者微信ID",
                  align: _vm.tableConfig.align,
                  "min-width": "100",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "时间",
                  align: _vm.tableConfig.align,
                  "min-width": "140",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              false
                ? _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      width: "100",
                      align: _vm.tableConfig.align,
                      fixed: "right",
                      "class-name": "small-padding fixed-width",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleclick(row, "look")
                                    },
                                  },
                                },
                                [_vm._v("查看")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      102213490
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm.total > 0
            ? _c("pagination", {
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                  page: _vm.queryParams.pageNo,
                  limit: _vm.queryParams.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.queryParams, "pageNo", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.queryParams, "pageSize", $event)
                  },
                  pagination: _vm.getList,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }