var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
      ? _c(
          "div",
          { staticClass: "page-section" },
          [
            _c("div", { staticClass: "search-condition" }, [
              _c(
                "div",
                { attrs: { id: "seach-box" } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "searchForm",
                      attrs: {
                        model: _vm.temp,
                        "label-position": _vm.formConfig.labelPosition,
                        "label-width": _vm.formConfig.labelWidth,
                        "label-suffix": ":",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "cols" },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 5 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "supplierName",
                                        label: "供应商名称",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "请输入供应商名称",
                                          maxlength: "40",
                                        },
                                        model: {
                                          value: _vm.listQuery.supplierName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.listQuery,
                                              "supplierName",
                                              $$v
                                            )
                                          },
                                          expression: "listQuery.supplierName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 5 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "supplierType",
                                        label: "供应商类型",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "filter-item",
                                          attrs: {
                                            clearable: "",
                                            "value-key": "id",
                                            placeholder: "选择供应商类型",
                                          },
                                          model: {
                                            value: _vm.listQuery.supplierType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.listQuery,
                                                "supplierType",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "listQuery.supplierType",
                                          },
                                        },
                                        _vm._l(
                                          _vm.supplierType,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.id,
                                              attrs: {
                                                label: item.name,
                                                value: item.id,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 5 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "startTime",
                                        label: "开始日期",
                                      },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          type: "date",
                                          format: "yyyy-MM-dd",
                                          "value-format": "yyyy-MM-dd",
                                          align: "right",
                                        },
                                        model: {
                                          value: _vm.listQuery.startTime,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.listQuery,
                                              "startTime",
                                              $$v
                                            )
                                          },
                                          expression: "listQuery.startTime",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 5 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "endTime",
                                        label: "结束日期",
                                      },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          type: "date",
                                          format: "yyyy-MM-dd",
                                          "value-format": "yyyy-MM-dd",
                                          align: "right",
                                        },
                                        model: {
                                          value: _vm.listQuery.endTime,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.listQuery,
                                              "endTime",
                                              $$v
                                            )
                                          },
                                          expression: "listQuery.endTime",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 4 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { "label-width": "0" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v(" ")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.searchBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.searchBtn.icon,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleFilter("search")
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.searchBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.resetBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.resetBtn.icon,
                                          },
                                          on: { click: _vm.reset },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.resetBtn.text)
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "btnArea" },
              [
                _c("el-col", {
                  staticClass: "head-btn-group",
                  staticStyle: { width: "205px" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { attrs: { id: "table-box" } },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.listLoading,
                        expression: "listLoading",
                      },
                    ],
                    key: _vm.tableKey,
                    staticStyle: { width: "100%" },
                    attrs: {
                      height: _vm.tabHeight,
                      data: _vm.list,
                      "tooltip-effect": "dark",
                      border: _vm.tableConfig.border,
                      stripe: _vm.tableConfig.stripe,
                      fit: "",
                      "highlight-current-row": "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "index",
                        width: "100",
                        label: "序号",
                        align: _vm.tableConfig.align,
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "供应商名称",
                        align: _vm.tableConfig.align,
                        "min-width": "200",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(scope.row.supplierName)),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        385097077
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "供应商类型",
                        align: _vm.tableConfig.align,
                        width: "130",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("supplierTypeFilter")(
                                        scope.row.supplierType
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        4287112319
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "负责人",
                        align: _vm.tableConfig.align,
                        width: "130",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(scope.row.personLiable)),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        4164912604
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "负责人电话", width: "130" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(scope.row.personLiablePhone)),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1438915264
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "创建时间",
                        align: _vm.tableConfig.align,
                        width: "200",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(scope.row.createTime)),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2774630583
                      ),
                    }),
                    _vm._v(" "),
                    _vm.hasAuth("detailbtn", _vm.$route.meta.authList) ||
                    _vm.hasAuth("updatebtn", _vm.$route.meta.authList)
                      ? _c("el-table-column", {
                          attrs: {
                            label: "操作",
                            fixed: "right",
                            align: _vm.tableConfig.align,
                            width: "180",
                            "class-name": "small-padding fixed-width",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _vm.hasAuth(
                                      "detailbtn",
                                      _vm.$route.meta.authList
                                    )
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.settingLabel(
                                                  row,
                                                  "detail"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("详情")]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.hasAuth(
                                      "updatebtn",
                                      _vm.$route.meta.authList
                                    )
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.settingLabel(
                                                  row,
                                                  "update"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("配置标识组")]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2970943891
                          ),
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("pagination", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.total > 0,
                      expression: "total>0",
                    },
                  ],
                  attrs: {
                    total: _vm.total,
                    page: _vm.listQuery.pageNo,
                    limit: _vm.listQuery.pageSize,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.listQuery, "pageNo", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.listQuery, "pageSize", $event)
                    },
                    pagination: _vm.getList,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                attrs: {
                  "close-on-click-modal": false,
                  "append-to-body": true,
                  title: _vm.textMap[_vm.dialogStatus],
                  width: "70%",
                  visible: _vm.dialogLabelVisible,
                  top: "3%",
                  "before-close": _vm.handleClose,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogLabelVisible = $event
                  },
                },
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "labelDataForm",
                    staticStyle: { width: "100%" },
                    attrs: {
                      model: _vm.temp,
                      rules: _vm.labelRules,
                      "label-position": "right",
                      "label-width": "120px",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "filter-container" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "postInfo-container-item",
                            staticStyle: { "margin-bottom": "2px" },
                            attrs: {
                              prop: "supplierName",
                              label: "供应商名称:",
                            },
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.temp.supplierName))])]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "postInfo-container-item",
                            staticStyle: { "margin-bottom": "2px" },
                            attrs: { prop: "orderNum", label: "供应商类型:" },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("supplierTypeFilter")(
                                    _vm.temp.supplierType
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "postInfo-container-item",
                            staticStyle: {
                              "margin-bottom": "2px",
                              height: "auto",
                            },
                            attrs: { prop: "flagCodes", label: "标识组配置:" },
                          },
                          [
                            _c(
                              "el-row",
                              { attrs: { type: "flex", gutter: 10 } },
                              [
                                _c(
                                  "el-col",
                                  {
                                    staticClass: "page-section",
                                    staticStyle: {
                                      width: "220px",
                                      height: "470px",
                                      overflow: "hidden",
                                      "overflow-y": "auto",
                                    },
                                  },
                                  [
                                    _c("el-tree", {
                                      directives: [
                                        {
                                          name: "loading",
                                          rawName: "v-loading",
                                          value: _vm.loaidng,
                                          expression: "loaidng",
                                        },
                                      ],
                                      ref: "tree",
                                      attrs: {
                                        data: _vm.labelList,
                                        props: _vm.defaultProps,
                                        "highlight-current": true,
                                        accordion: "",
                                        "show-checkbox": "",
                                        "node-key": "pid",
                                        "filter-node-method": _vm.filterNode,
                                      },
                                      on: {
                                        "check-change": _vm.handleCheckChange,
                                        "node-click": _vm.handleNodeClick,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var node = ref.node
                                              return _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    width: "calc(100% - 40px)",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      attrs: {
                                                        effect: "dark",
                                                        content:
                                                          node.data.flagName,
                                                        placement: "top-start",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "tree_name",
                                                          staticStyle: {
                                                            "font-size": "14px",
                                                            overflow:
                                                              "hidden !important",
                                                            "text-overflow":
                                                              "ellipsis !important",
                                                            "white-space":
                                                              "nowrap !important",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              node.data.flagName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2193164351
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  {
                                    staticStyle: {
                                      width: "calc(100% - 220px)",
                                      "margin-bottom": "0",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "el-row page-section" },
                                      [
                                        _c(
                                          "div",
                                          { attrs: { id: "table-box" } },
                                          [
                                            _c(
                                              "el-table",
                                              {
                                                directives: [
                                                  {
                                                    name: "loading",
                                                    rawName: "v-loading",
                                                    value: _vm.modeLoading,
                                                    expression: "modeLoading",
                                                  },
                                                ],
                                                key: _vm.tableKey,
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  height: "400px",
                                                  data: _vm.modelList,
                                                  "tooltip-effect": "dark",
                                                  border: "",
                                                  fit: "",
                                                  "highlight-current-row": "",
                                                },
                                              },
                                              [
                                                _c("el-table-column", {
                                                  attrs: {
                                                    type: "index",
                                                    width: "100",
                                                    label: "序号",
                                                    align:
                                                      _vm.tableConfig.align,
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("el-table-column", {
                                                  attrs: {
                                                    label: "码类型",
                                                    align:
                                                      _vm.tableConfig.align,
                                                    "min-width": "120",
                                                    "show-overflow-tooltip": "",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function (scope) {
                                                          return [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "codeType"
                                                                  )(
                                                                    scope.row
                                                                      .markType
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    3427812999
                                                  ),
                                                }),
                                                _vm._v(" "),
                                                _c("el-table-column", {
                                                  attrs: {
                                                    label: "包装层级",
                                                    align:
                                                      _vm.tableConfig.align,
                                                    "min-width": "120",
                                                    "show-overflow-tooltip": "",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function (scope) {
                                                          return [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "levelType"
                                                                  )(
                                                                    scope.row
                                                                      .packLevel,
                                                                    scope.row
                                                                      .markType
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    3914337215
                                                  ),
                                                }),
                                                _vm._v(" "),
                                                _c("el-table-column", {
                                                  attrs: {
                                                    label: "URL",
                                                    align:
                                                      _vm.tableConfig.align,
                                                    prop: "urlAddress",
                                                    "min-width": "220",
                                                    "show-overflow-tooltip": "",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("el-table-column", {
                                                  attrs: {
                                                    label: "创建时间",
                                                    align:
                                                      _vm.tableConfig.align,
                                                    width: "180",
                                                    prop: "createTime",
                                                    "show-overflow-tooltip": "",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("pagination", {
                                              attrs: {
                                                total: _vm.modelTotal,
                                                page: _vm.labelQuery.current,
                                                limit: _vm.labelQuery.size,
                                                layout:
                                                  "total, prev, pager, next",
                                              },
                                              on: {
                                                "update:page": function (
                                                  $event
                                                ) {
                                                  return _vm.$set(
                                                    _vm.labelQuery,
                                                    "current",
                                                    $event
                                                  )
                                                },
                                                "update:limit": function (
                                                  $event
                                                ) {
                                                  return _vm.$set(
                                                    _vm.labelQuery,
                                                    "size",
                                                    $event
                                                  )
                                                },
                                                pagination: _vm.getModelList,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c("el-button", { on: { click: _vm.handleClose } }, [
                      _vm._v(
                        _vm._s(_vm.dialogStatus == "update" ? "取 消" : "关 闭")
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.dialogStatus == "update"
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              loading: _vm.btn_loading,
                              type: "primary",
                            },
                            on: { click: _vm.saveLabelCode },
                          },
                          [_vm._v("确 定")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
      ? _c("section", [_vm._m(0)])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }