var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.mloading,
          expression: "mloading",
        },
      ],
      attrs: {
        "close-on-click-modal": false,
        title: "出库单信息",
        visible: _vm.detailVisible,
        "before-close": _vm.handleClose,
        width: "1200px !important",
      },
      on: {
        "update:visible": function ($event) {
          _vm.detailVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "stepArea" },
        [
          _c("div", { staticClass: "billTitle" }, [
            _c("img", { attrs: { src: require("@/images/topimg.png") } }),
            _vm._v(
              "\n      费用酒出库单 " +
                _vm._s(_vm.temp.tradeBillAllVO.billNo) +
                "\n      "
            ),
            _c(
              "span",
              {
                staticClass: "billType",
                class: _vm._f("typeClass")(_vm.temp.tradeBillAllVO.billStatus),
              },
              [
                _vm._v(
                  _vm._s(
                    _vm._f("TypeDataCom")(
                      _vm.temp.tradeBillAllVO.billStatus,
                      "djzt"
                    )
                  )
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "topRight" }, [
              _c("img", { attrs: { src: require("@/images/avatar.png") } }),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.temp.tradeBillAllVO.createUser))]),
              _vm._v(" "),
              _c("span", { staticStyle: { "margin-left": "10px" } }, [
                _vm._v(
                  _vm._s(_vm._f("textDate")(_vm.temp.tradeBillAllVO.createTime))
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-steps",
            {
              staticStyle: { "margin-top": "20px" },
              attrs: { active: _vm.active, "finish-status": "success" },
            },
            [
              _c("el-step", { attrs: { title: "创建" } }),
              _vm._v(" "),
              _c("el-step", { attrs: { title: "下载" } }),
              _vm._v(" "),
              _c("el-step", { attrs: { title: "扫码" } }),
              _vm._v(" "),
              _c("el-step", { attrs: { title: "结单" } }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "itemTitle" }, [_vm._v("基本信息")]),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("订单号:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.temp.tradeBillAllVO.relateNo)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("发货单位:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.temp.tradeBillAllVO.outOrgName)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("发货库房:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.temp.tradeBillAllVO.outStoreName)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("收货单位:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.temp.tradeBillAllVO.inOrgName)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("拆单状态:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm.temp.tradeBillAllVO.splitStatus == 0
                  ? _c("span", [_vm._v("未拆单")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.temp.tradeBillAllVO.splited == 1
                  ? _c("span", [_vm._v("已拆单")])
                  : _vm._e(),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "middleLine" }),
      _vm._v(" "),
      _c("div", { staticClass: "itemTitle" }, [
        _vm._v("\n    产品信息\n    "),
        _c("img", { attrs: { src: require("@/images/pro.png") } }),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "detailTable",
          staticStyle: { width: "100%", "margin-top": "1px" },
          attrs: { size: "mini", data: _vm.temp.tradeProductVOS, fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "产品品牌",
              prop: "prodBrandName",
              align: "center",
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "产品分类",
              prop: "prodClassName",
              align: "center",
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "产品编码",
              "min-width": "100",
              prop: "productCode",
              align: "center",
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "产品名称",
              prop: "productName",
              "min-width": "140",
              align: "center",
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "包装比例",
              prop: "packRadioDesc",
              align: "center",
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "计划箱",
              prop: "planScanNum",
              align: "center",
              width: "100",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.planScanNum))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "计划盒",
              prop: "planScanAtoNum",
              align: "center",
              width: "100",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.planScanAtoNum))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "实扫箱",
              prop: "realScanNum",
              align: "center",
              width: "100",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        staticStyle: { padding: "0" },
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.codeDetail(row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(row.realScanNum))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "实扫盒",
              prop: "realScanAtoNum",
              align: "center",
              width: "100",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        staticStyle: { padding: "0" },
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.codeDetail(row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(row.realScanAtoNum))]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "middleLine" }),
      _vm._v(" "),
      _c(
        "el-collapse",
        {
          staticClass: "detailCollapse",
          attrs: { accordion: "" },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-collapse-item",
            { attrs: { title: "操作记录", name: "1" } },
            [
              _c("template", { slot: "title" }, [_vm._v("操作记录")]),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%", "margin-top": "1px" },
                  attrs: {
                    data: _vm.temp.tradeOperateLogDTOS,
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "序号",
                      type: "index",
                      width: "55",
                      align: "center",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作类型",
                      prop: "operateType",
                      align: "center",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              _vm._s(_vm._f("operateType")(row.operateType))
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作描述",
                      prop: "operateDesc",
                      align: "center",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作人",
                      prop: "operateUser",
                      align: "center",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作时间",
                      prop: "createTime",
                      align: "center",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _vm._v(_vm._s(_vm._f("textDate")(row.createTime))),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作备注",
                      prop: "remark",
                      align: "center",
                      "show-overflow-tooltip": "",
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [_c("el-button", { on: { click: _vm.handleClose } }, [_vm._v("关闭")])],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: "数码详情",
            visible: _vm.codeVisible,
            top: "3%",
            width: "70%",
            "before-close": _vm.codeClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.codeVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.codeListLoading,
                      expression: "codeListLoading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    "max-height": "500",
                    data: _vm.codeList,
                    "tooltip-effect": "dark",
                    border: "",
                    fit: "",
                    "highlight-current-row": "",
                    "row-key": "markCode",
                    lazy: "",
                    load: _vm.load,
                    "tree-props": _vm.treeProps,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      width: "80",
                      label: "序号",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [_c("span", [_vm._v(_vm._s(scope.row.seqNo))])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "层级",
                      prop: "id",
                      align: "left",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm._f("packLevel")(scope.row.packLevel))
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "数码",
                      align: "center",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.markCode))]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.codetotal > 0,
                    expression: "codetotal > 0",
                  },
                ],
                attrs: {
                  layout: "total,sizes,prev, pager, next, jumper",
                  total: _vm.codetotal,
                  page: _vm.detailForm.current,
                  limit: _vm.detailForm.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.detailForm, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.detailForm, "size", $event)
                  },
                  pagination: _vm.getCodeList,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.codeClose } }, [
                _vm._v("关闭"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }