var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c("h3", { staticClass: "h-title" }, [_vm._v("终端档案")]),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  ref: "searchForm",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.searchForm,
                    "status-icon": "",
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                    "label-suffix": ":",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cols" },
                    [
                      _c(
                        "el-row",
                        {
                          staticStyle: { "margin-bottom": "0" },
                          attrs: { gutter: 20 },
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6, prop: "keyword" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "终端编码/名称/店主姓名/手机号",
                                    prop: "keyword",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.searchForm.keyword,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.searchForm, "keyword", $$v)
                                      },
                                      expression: "searchForm.keyword",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.formConfig.btnFollow
                            ? _c(
                                "el-col",
                                {
                                  staticClass: "head-btn-group",
                                  style: {
                                    width: _vm.formConfig.btnAreaHasShowMore,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { "label-width": "0" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v(" ")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.searchBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.searchBtn.icon,
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.searchForm.current = 1
                                              _vm.getList()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.searchBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.resetBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.resetBtn.icon,
                                          },
                                          on: { click: _vm.resetForm },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.resetBtn.text)
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.formConfig.btnFollow
                ? _c("el-divider", { staticClass: "btnDivider" })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btnArea" },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "head-btn-group",
                      staticStyle: { width: "230px" },
                    },
                    [
                      _vm.hasAuth("addbtn", _vm.$route.meta.authList) ||
                      _vm.hasAuth("exportbtn", _vm.$route.meta.authList) ||
                      _vm.hasAuth("allinport", _vm.$route.meta.authList) ||
                      _vm.hasAuth("batchaddywybtn", _vm.$route.meta.authList) ||
                      _vm.hasAuth(
                        "batchupdateywybtn",
                        _vm.$route.meta.authList
                      ) ||
                      _vm.hasAuth("batchdeleteywybtn", _vm.$route.meta.authList)
                        ? _c(
                            "div",
                            { staticClass: "head-btn-group" },
                            [
                              _vm.hasAuth("addbtn", _vm.$route.meta.authList)
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.addBtn.type,
                                        size: _vm.btnConfig.size,
                                        plain: _vm.btnConfig.plain,
                                        icon: _vm.btnConfig.addBtn.icon,
                                      },
                                      on: { click: _vm.add },
                                    },
                                    [_vm._v(_vm._s(_vm.btnConfig.addBtn.text))]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "el-button-group",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "margin-left": "20px",
                                  },
                                },
                                [
                                  _vm.hasAuth(
                                    "allinport",
                                    _vm.$route.meta.authList
                                  )
                                    ? _c(
                                        "el-button",
                                        {
                                          staticStyle: {
                                            "border-radius": "4px",
                                          },
                                          attrs: {
                                            type: _vm.btnConfig.upLoadBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.upLoadBtn.icon,
                                            plain: _vm.btnConfig.plain,
                                          },
                                          on: { click: _vm.uploadShow },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.upLoadBtn.text)
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.hasAuth(
                                    "exportbtn",
                                    _vm.$route.meta.authList
                                  )
                                    ? _c(
                                        "el-button",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                            "border-radius": "4px",
                                          },
                                          attrs: {
                                            type: _vm.btnConfig.downLoadBtn
                                              .type,
                                            size: _vm.btnConfig.size,
                                            plain: _vm.btnConfig.plain,
                                            icon: _vm.btnConfig.downLoadBtn
                                              .icon,
                                            loading: _vm.downLoading,
                                          },
                                          on: { click: _vm.download },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.btnConfig.downLoadBtn.text
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "section",
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      key: _vm.tableKey,
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.tableData,
                        height: _vm.tabHeight,
                        border: _vm.tableConfig.border,
                        stripe: _vm.tableConfig.stripe,
                        fit: "",
                        "highlight-current-row": "",
                      },
                      on: { "selection-change": _vm.handleSelectionChange },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "终端编码",
                          prop: "channelCode",
                          "min-width": "120",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "终端店名称",
                          prop: "channelName",
                          "min-width": "150",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "店主姓名",
                          "min-width": "100",
                          prop: "contacts",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "店主手机号",
                          "min-width": "150",
                          prop: "phone",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "收货地址",
                          "min-width": "200",
                          prop: "detailedAddress",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "店主身份证",
                          "min-width": "200",
                          prop: "cardNo",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "归属片区",
                          "min-width": "110",
                          prop: "regionName",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "营业执照",
                          "min-width": "150",
                          prop: "creditCode",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "创建人",
                          prop: "userName",
                          "min-width": "150",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          fixed: "right",
                          align: _vm.tableConfig.align,
                          width: "200",
                          "class-name": "small-padding fixed-width",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm.hasAuth(
                                    "detailbtn",
                                    _vm.$route.meta.authList
                                  )
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.detail(row)
                                            },
                                          },
                                        },
                                        [_vm._v("详情")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.hasAuth(
                                    "updatebtn",
                                    _vm.$route.meta.authList
                                  )
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.DoEdit(row)
                                            },
                                          },
                                        },
                                        [_vm._v("修改")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.hasAuth(
                                    "deletebtn",
                                    _vm.$route.meta.authList
                                  ) ||
                                  _vm.hasAuth(
                                    "startbtn",
                                    _vm.$route.meta.authList
                                  ) ||
                                  _vm.hasAuth(
                                    "stopbtn",
                                    _vm.$route.meta.authList
                                  ) ||
                                  (_vm.hasAuth(
                                    "jumpbtn",
                                    _vm.$route.meta.authList
                                  ) &&
                                    _vm.hasPage(
                                      "/basic-data/channelmange/purchasingsupply/index",
                                      _vm.$route.meta.pageList
                                    )) ||
                                  _vm.hasAuth(
                                    "checkbtn",
                                    _vm.$route.meta.authList
                                  )
                                    ? _c(
                                        "el-dropdown",
                                        { staticClass: "table-dropdown" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "el-dropdown-link" },
                                            [
                                              _vm._v(
                                                "\n                更多\n                "
                                              ),
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-arrow-down el-icon--right",
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-dropdown-menu",
                                            {
                                              attrs: { slot: "dropdown" },
                                              slot: "dropdown",
                                            },
                                            [
                                              _vm.hasAuth(
                                                "startbtn",
                                                _vm.$route.meta.authList
                                              ) ||
                                              _vm.hasAuth(
                                                "stopbtn",
                                                _vm.$route.meta.authList
                                              )
                                                ? _c(
                                                    "el-dropdown-item",
                                                    [
                                                      _vm.hasAuth(
                                                        "startbtn",
                                                        _vm.$route.meta.authList
                                                      ) &&
                                                      row.archivesStatus != 1
                                                        ? _c(
                                                            "el-button",
                                                            {
                                                              attrs: {
                                                                type: "text",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.setInUse(
                                                                      row.pId
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("启用")]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.hasAuth(
                                                        "stopbtn",
                                                        _vm.$route.meta.authList
                                                      ) &&
                                                      row.archivesStatus != 0
                                                        ? _c(
                                                            "el-button",
                                                            {
                                                              attrs: {
                                                                type: "text",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.setUnUse(
                                                                      row.pId
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("禁用")]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.hasAuth(
                                                "deletebtn",
                                                _vm.$route.meta.authList
                                              )
                                                ? _c(
                                                    "el-dropdown-item",
                                                    [
                                                      row.archivesStatus != 2
                                                        ? _c(
                                                            "el-button",
                                                            {
                                                              attrs: {
                                                                type: "text",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.DoDel(
                                                                      row.pId
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("删除")]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.hasAuth(
                                                "jumpbtn",
                                                _vm.$route.meta.authList
                                              ) &&
                                              _vm.hasPage(
                                                "/basic-data/channelmange/purchasingsupply/index",
                                                _vm.$route.meta.pageList
                                              )
                                                ? _c(
                                                    "el-dropdown-item",
                                                    [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "text",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.$router.push(
                                                                {
                                                                  path: "/basic-data/channelmange/purchasingsupply/index",
                                                                  query: {
                                                                    cName:
                                                                      row.channelName,
                                                                  },
                                                                }
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("查看采供关系")]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.hasAuth(
                                                "checkbtn",
                                                _vm.$route.meta.authList
                                              )
                                                ? _c(
                                                    "el-dropdown-item",
                                                    [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "text",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.setCheckStrong(
                                                                row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "收发货验证修改"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1253775153
                        ),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("pagination", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.total > 0,
                        expression: "total > 0",
                      },
                    ],
                    attrs: {
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.total,
                      page: _vm.searchForm.current,
                      limit: _vm.searchForm.size,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(_vm.searchForm, "current", $event)
                      },
                      "update:limit": function ($event) {
                        return _vm.$set(_vm.searchForm, "size", $event)
                      },
                      pagination: _vm.getList,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: "收发货验证修改",
            visible: _vm.centerDialogVisible,
            width: "600px!important",
          },
          on: {
            "update:visible": function ($event) {
              _vm.centerDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.checkStrong,
                callback: function ($$v) {
                  _vm.checkStrong = $$v
                },
                expression: "checkStrong",
              },
            },
            [
              _c("el-radio", { attrs: { label: 1 } }, [_vm._v("强验证")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 0 } }, [_vm._v("弱验证")]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.checkStrong == 1,
                  expression: "checkStrong == 1",
                },
              ],
              staticStyle: { margin: "20px 0", "font-size": "16px" },
            },
            [
              _vm._v("\n      强验证下以下情况不允许进行出入库:\n      "),
              _vm._l(_vm.strongMsgList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticStyle: { margin: "10px", "font-size": "16px" },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(index * 1 + 1 + ".") +
                        _vm._s(item.errorName) +
                        "\n      "
                    ),
                  ]
                )
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.strongMsgList.length > 0,
                      expression: "!strongMsgList.length > 0",
                    },
                  ],
                  staticStyle: { margin: "10px", "font-size": "16px" },
                },
                [_vm._v("\n        1.非出库数码(入库校验)\n      ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.strongMsgList.length > 0,
                      expression: "!strongMsgList.length > 0",
                    },
                  ],
                  staticStyle: { margin: "10px", "font-size": "16px" },
                },
                [_vm._v("\n        2.数码未签收\n      ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.strongMsgList.length > 0,
                      expression: "!strongMsgList.length > 0",
                    },
                  ],
                  staticStyle: { margin: "10px", "font-size": "16px" },
                },
                [_vm._v("\n        3.数码重复出库\n      ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.strongMsgList.length > 0,
                      expression: "!strongMsgList.length > 0",
                    },
                  ],
                  staticStyle: { margin: "10px", "font-size": "16px" },
                },
                [_vm._v("\n        4.数码重复入库\n      ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.strongMsgList.length > 0,
                      expression: "!strongMsgList.length > 0",
                    },
                  ],
                  staticStyle: { margin: "10px", "font-size": "16px" },
                },
                [_vm._v("\n        5.数码不在库存中\n      ")]
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.checkStrong == 0,
                  expression: "checkStrong == 0",
                },
              ],
              staticStyle: { margin: "10px 0", "font-size": "16px" },
            },
            [_vm._v("\n      弱验证下只进行数码是否真实的校验\n    ")]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.centerDialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.btnLoading },
                  on: { click: _vm.setStrong },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: _vm.title,
            visible: _vm.dialogVisible,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.mloading,
                  expression: "mloading",
                },
              ],
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "100px",
                "label-suffix": ":",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "终端店名称", prop: "channelName" } },
                [
                  _c("el-input", {
                    attrs: { disabled: _vm.disabled, maxlength: "30" },
                    on: {
                      input: function (e) {
                        return (_vm.ruleForm.channelName = _vm.validZDMC(e))
                      },
                    },
                    model: {
                      value: _vm.ruleForm.channelName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "channelName", $$v)
                      },
                      expression: "ruleForm.channelName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "店主姓名", prop: "contacts" } },
                [
                  _c("el-input", {
                    attrs: { disabled: _vm.disabled, maxlength: "10" },
                    model: {
                      value: _vm.ruleForm.contacts,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "contacts", $$v)
                      },
                      expression: "ruleForm.contacts",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "店主手机号", prop: "phone" } },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.disabled,
                      value: _vm.ruleForm.phone,
                      maxlength: "11",
                    },
                    on: {
                      input: function (e) {
                        return (_vm.ruleForm.phone = _vm.valENnum(e))
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "收货地址", prop: "detailedAddress" } },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.disabled,
                      placeholder: "",
                      maxlength: "50",
                    },
                    model: {
                      value: _vm.ruleForm.detailedAddress,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "detailedAddress", $$v)
                      },
                      expression: "ruleForm.detailedAddress",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "店主身份证", prop: "cardNo" } },
                [
                  _c("el-input", {
                    attrs: { disabled: _vm.disabled, maxlength: "18" },
                    on: { blur: _vm.validatecardNo },
                    model: {
                      value: _vm.ruleForm.cardNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "cardNo", $$v)
                      },
                      expression: "ruleForm.cardNo",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "营业执照", prop: "creditCode" } },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.disabled,
                      placeholder: "请输入统一社会信用代码",
                      maxlength: "50",
                    },
                    model: {
                      value: _vm.ruleForm.creditCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "creditCode", $$v)
                      },
                      expression: "ruleForm.creditCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-upload",
                    {
                      attrs: {
                        disabled: _vm.disabled,
                        action: _vm.uploadImg,
                        headers: _vm.myHeaders,
                        limit: 1,
                        accept: ".jpg, .jpeg, .png, .PNG, .JPG, .JPEG",
                        "list-type": "picture-card",
                        "file-list": _vm.imgList,
                        "on-change": _vm.selectImg,
                        "on-remove": _vm.handleRemove,
                        "before-upload": _vm.beforeAvatarUpload,
                        "on-success": _vm.imgUploadSuccess,
                        "on-error": _vm.uploadFileFail,
                      },
                    },
                    [_c("i", { staticClass: "el-icon-plus" })]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "dialog-footer" },
                [
                  !_vm.disabled
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", loading: _vm.btnLoading },
                          on: { click: _vm.submitForm },
                        },
                        [_vm._v("确 定")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-button", { on: { click: _vm.handleClose } }, [
                    _vm._v("取 消"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: _vm.title,
            visible: _vm.scanVisible,
            "before-close": _vm.scanClose,
            width: "1000px !important",
          },
          on: {
            "update:visible": function ($event) {
              _vm.scanVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.mloading,
                  expression: "mloading",
                },
              ],
            },
            [
              _c(
                "el-descriptions",
                { attrs: { title: "基本信息", column: 2, border: "" } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "终端店编码" } },
                    [_vm._v(_vm._s(_vm.scanData.channelCode))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "终端店名称" } },
                    [_vm._v(_vm._s(_vm.scanData.channelName))]
                  ),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "店主姓名" } }, [
                    _vm._v(_vm._s(_vm.scanData.contacts)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "店主手机号" } },
                    [_vm._v(_vm._s(_vm.scanData.phone))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "店主身份证" } },
                    [_vm._v(_vm._s(_vm.scanData.cardNo))]
                  ),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "收货地址" } }, [
                    _vm._v(_vm._s(_vm.scanData.detailedAddress)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "创建人" } }, [
                    _vm._v(_vm._s(_vm.scanData.userName)),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "创建时间" } }, [
                    _vm._v(_vm._s(_vm._f("textDate")(_vm.scanData.createTime))),
                  ]),
                  _vm._v(" "),
                  _c("el-descriptions-item", { attrs: { label: "所属大区" } }, [
                    _vm._v(_vm._s(_vm.scanData.regionName)),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "middleLine" }),
              _vm._v(" "),
              _c(
                "el-descriptions",
                {
                  attrs: {
                    id: "channelbox",
                    column: 1,
                    border: "",
                    direction: "vertical",
                  },
                },
                [
                  _c("el-descriptions-item", { attrs: { label: "备注" } }, [
                    _vm._v(_vm._s(_vm.scanData.remarks)),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "middleLine" }),
              _vm._v(" "),
              _c(
                "el-descriptions",
                { attrs: { title: "营业执照", column: 1, border: "" } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "统一社会信用代码" } },
                    [_vm._v(_vm._s(_vm.scanData.creditCode))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "营业执照" } },
                    [
                      _c(
                        "el-row",
                        _vm._l(_vm.scanData.imgList, function (item, index) {
                          return _c("el-image", {
                            key: index,
                            staticStyle: {
                              width: "220px",
                              height: "140px",
                              "margin-right": "20px",
                            },
                            attrs: {
                              src: item,
                              "preview-src-list": _vm.scanData.imgList,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "default", plain: "" },
                  on: { click: _vm.scanClose },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.mloading,
              expression: "mloading",
            },
          ],
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: "批量导入——终端档案",
            visible: _vm.uploadVisible,
            "before-close": _vm.uploadClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.uploadVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "uploadForm",
              attrs: { model: _vm.uploadForm, rulse: _vm.uploadRules },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "下载模板", prop: "orgName" } },
                [
                  _c(
                    "a",
                    {
                      staticClass: "down",
                      attrs: { href: "./Excel/终端档案导入模板.xlsx" },
                    },
                    [_vm._v("终端档案导入Excel模板")]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { display: "flex" },
                  attrs: { prop: "orgName", label: "文件上传" },
                },
                [
                  _c(
                    "el-row",
                    { staticClass: "head-btn-group", attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-input", {
                            attrs: { disabled: true },
                            model: {
                              value: _vm.uploadForm.uploadFile,
                              callback: function ($$v) {
                                _vm.$set(_vm.uploadForm, "uploadFile", $$v)
                              },
                              expression: "uploadForm.uploadFile",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-upload",
                            {
                              ref: "upload",
                              staticClass: "upload-demo",
                              attrs: {
                                headers: _vm.myHeaders,
                                action: _vm.uploadUrl,
                                name: "file",
                                data: _vm.uploadForm,
                                accept: ".xls, .xlsx",
                                "file-list": _vm.fileList,
                                "show-file-list": false,
                                "auto-upload": false,
                                "on-change": _vm.selectFile,
                                "on-success": _vm.uploadFileRes,
                                "on-error": _vm.uploadFileFail,
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    slot: "trigger",
                                    size: "mini",
                                    type: "primary",
                                  },
                                  slot: "trigger",
                                },
                                [_vm._v("打开")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    loading: _vm.btnLoading,
                                    disabled: _vm.btnDisabled,
                                  },
                                  on: { click: _vm.submitUpload },
                                },
                                [_vm._v("导入")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { staticStyle: { "font-size": "12px" } }, [
                _c("p", [_vm._v("备注：")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n          1.必录项：终端店名称、店主姓名、店主手机号;\n        "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: { "margin-top": "1px" },
              attrs: {
                border: "",
                fit: "",
                data: _vm.uploadData,
                height: "350px",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  property: "promptCase",
                  label: "提示列",
                  "min-width": "120",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  property: "rowNum",
                  label: "行号",
                  "min-width": "50",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  property: "channelName",
                  label: "终端店名称",
                  "min-width": "95",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  property: "contacts",
                  label: "店主姓名",
                  "min-width": "90",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  property: "phone",
                  label: "店主手机号",
                  "min-width": "90",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  property: "detailedAddress",
                  label: "收货地址",
                  "min-width": "120",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  property: "cardNo",
                  label: "店主身份证",
                  "min-width": "120",
                  "show-overflow-tooltip": "",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: "业务员列表",
            visible: _vm.dialogSalesmanList,
            "before-close": _vm.selCancel,
            width: "850px !important",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogSalesmanList = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "page-container" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { staticClass: "page-section" },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "searchForm",
                          staticClass: "search-condition",
                          attrs: {
                            model: _vm.querysalesman,
                            "status-icon": "",
                            "label-suffix": ":",
                            "label-position": _vm.formConfig.labelPosition,
                            "label-width": _vm.formConfig.labelWidth,
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "cols" },
                            [
                              _c(
                                "el-row",
                                { attrs: { gutter: 20 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8, prop: "salesman" } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "salesManName",
                                            label: "用户名",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              clearable: "",
                                              placeholder: "业务员用户名",
                                            },
                                            model: {
                                              value:
                                                _vm.querysalesman
                                                  .salesManAccount,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.querysalesman,
                                                  "salesManAccount",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "querysalesman.salesManAccount",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8, prop: "salesman" } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "salesManName",
                                            label: "姓名",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              clearable: "",
                                              placeholder: "业务员姓名",
                                            },
                                            model: {
                                              value:
                                                _vm.querysalesman.salesManName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.querysalesman,
                                                  "salesManName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "querysalesman.salesManName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "head-btn-group",
                                      style: { width: _vm.formConfig.btnArea },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { "label-width": "0" } },
                                        [
                                          _c("div", {
                                            attrs: { slot: "label" },
                                            slot: "label",
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "el-button-group",
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: _vm.btnConfig
                                                      .searchBtn.type,
                                                    icon: _vm.btnConfig
                                                      .searchBtn.icon,
                                                    plain:
                                                      _vm.btnConfig.searchBtn
                                                        .plain,
                                                  },
                                                  on: { click: _vm.querylist },
                                                },
                                                [_vm._v("搜索")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: _vm.btnConfig.resetBtn
                                                      .type,
                                                    icon: _vm.btnConfig.resetBtn
                                                      .icon,
                                                    plain:
                                                      _vm.btnConfig.resetBtn
                                                        .plain,
                                                  },
                                                  on: {
                                                    click: _vm.resetQuerylist,
                                                  },
                                                },
                                                [_vm._v("重置")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "section",
                        [
                          _c(
                            "el-table",
                            {
                              ref: "multipleTable",
                              staticStyle: { width: "100%" },
                              attrs: {
                                data: _vm.salesmanList,
                                "row-key": "salesManId",
                                height: "400px",
                                border: "",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "salesManAccount",
                                  label: "用户名",
                                  align: "center",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "salesManName",
                                  label: "姓名",
                                  align: "center",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "操作",
                                  align: "center",
                                  width: "200px",
                                  "class-name": "small-padding fixed-width",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.submitSalesmanList(
                                                  row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("选择")]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("pagination", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.salesmanTotal > 0,
                                expression: "salesmanTotal > 0",
                              },
                            ],
                            attrs: {
                              layout: "total, sizes, prev, pager, next, jumper",
                              total: _vm.salesmanTotal,
                              page: _vm.current,
                              limit: _vm.salesmanSize,
                            },
                            on: {
                              "update:page": function ($event) {
                                _vm.current = $event
                              },
                              "update:limit": function ($event) {
                                _vm.salesmanSize = $event
                              },
                              pagination: function ($event) {
                                return _vm.getcomBoxSalesManlist()
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("personnel-checkbox-dialog", {
        ref: "perCheckbox",
        attrs: {
          data: _vm.salesmanIds,
          "dealer-code": _vm.ruleForm.dealerCode,
          "is-readonly": true,
        },
        on: {
          "update:data": function ($event) {
            _vm.salesmanIds = $event
          },
          "update:dealerCode": function ($event) {
            return _vm.$set(_vm.ruleForm, "dealerCode", $event)
          },
          "update:dealer-code": function ($event) {
            return _vm.$set(_vm.ruleForm, "dealerCode", $event)
          },
          change: _vm.setSel,
        },
      }),
      _vm._v(" "),
      _vm.isShow
        ? _c("enterpriseUsers", {
            ref: "bagDialog",
            attrs: {
              id: _vm.ids,
              "sales-man-user-id": _vm.salesManUserId,
              "multiple-list": _vm.oldList,
              "sales-man-nameq": _vm.salesManNameq,
            },
            on: {
              close: _vm.closePriseUsers,
              change: _vm.getMultipleSelection,
              getData: _vm.getData,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("channel-select-dialog", {
        ref: "channelSelect",
        attrs: { "channel-type": 2, "channel-type-can-change": true },
        on: { change: _vm.setCSel },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }