"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _record = require("@/api/warehouse/record/record");
var _index = require("@/api/warehouse/checkout/index");
var _select = require("@/api/warehouse/select");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _qrcodejs = _interopRequireDefault(require("qrcodejs2"));
var _utils = require("@/utils");
var _search = _interopRequireDefault(require("./components/search"));
var _insert = _interopRequireDefault(require("./components/insert"));
var _details_flow = _interopRequireDefault(require("./components/details_flow"));
var _printDialog = _interopRequireDefault(require("@/components/printDialog"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var qrcode = '';
var TypeDataComs = {
  status: [],
  source: [],
  factory: [],
  typeSpec: []
};
var _default = {
  name: 'WarehouseServicewarehousingotherother',
  components: {
    Pagination: _Pagination.default,
    search: _search.default,
    detailsflow: _details_flow.default,
    insert: _insert.default,
    printDialog: _printDialog.default
  },
  filters: {
    filterStatus: function filterStatus(code) {
      var vars = TypeDataComs['status'];
      var name = '';
      for (var i = 0; i < vars.length; i++) {
        if (vars[i].figure == code) {
          name = vars[i].name;
          break;
        }
      }
      return name;
    },
    filterSource: function filterSource(code) {
      var vars = TypeDataComs['source'];
      var name = '';
      for (var i = 0; i < vars.length; i++) {
        if (vars[i].figure == code) {
          name = vars[i].name;
          break;
        }
      }
      return name;
    },
    tagStatus: function tagStatus(val) {
      var status = {
        1: '',
        2: '',
        3: '',
        4: 'success',
        5: 'success',
        6: 'success',
        7: 'success',
        8: 'warning',
        9: 'danger',
        10: 'warning',
        11: 'warning',
        12: 'danger',
        13: 'success'
      };
      return status[val];
    }
  },
  data: function data() {
    return {
      billType: 103,
      tableData: [],
      searchForm: {
        current: 1,
        size: 20
      },
      tradeBillQuery: {
        billNo: '',
        billSource: '',
        billStatus: '',
        billType: 103,
        endTime: '',
        productId: '',
        startTime: '',
        billInOthersQuery: {
          inOrgId: '',
          inStoreId: '',
          otherTypeId: '1659125501056643074',
          outOrgId: ''
        }
      },
      tableKey: 'rn',
      total: 0,
      codetotal: 0,
      listLoading: false,
      downLoading: false,
      page2: false,
      multipleSelection: [],
      tabHeight: '100%',
      TypeDataComs: {
        status: [],
        source: [],
        factory: [],
        typeSpec: []
      },
      code_title: '二维码查看',
      codeVisible: false,
      billNo: '',
      typeNum: 2,
      printShow: false,
      pShow: false,
      insertVisible: false,
      submitType: 1,
      otherTypeName: ''
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    this.authId('searchbtn', this.$route.meta.authList);
    this.getstoreTypeInfoList('DJZT');
    this.getstoreTypeInfoList('DJLY');
    console.log(this.$route.query.type, 'this.$route.query.type');
    TypeDataComs = this.TypeDataComs;
    // if (this.$route.query.type) {
    //   this.tradeBillQuery.billInOthersQuery.otherTypeId = this.$route.query.type
    //   this.$refs.searchMain.otherTypeName = '盘盈入库'
    //   this.$refs.searchMain.searchForm.billInOthersQuery.otherTypeId = this.$route.query.type
    // } else {
    //   this.tradeBillQuery.billInOthersQuery.otherTypeId = ''
    //   this.$refs.searchMain.otherTypeName = ''
    //   this.$refs.searchMain.searchForm.billInOthersQuery.otherTypeId = ''
    // }

    this.getList();
  },
  methods: {
    // 获取单据状态，单据来源
    getstoreTypeInfoList: function getstoreTypeInfoList(code) {
      var _this = this;
      var param = {};
      param.code = code;
      this.TypeDataComs.status = [];
      this.TypeDataComs.source = [];
      (0, _select.getDicItem)(param).then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
          return;
        } else {
          if (param.code == 'DJZT') {
            _this.TypeDataComs.status = res.data.data;
          }
          if (param.code == 'DJLY') {
            _this.TypeDataComs.source = res.data.data;
          }
          TypeDataComs = _this.TypeDataComs;
        }
      }).catch(function () {});
    },
    searchList: function searchList(data) {
      console.log(data);
      this.tradeBillQuery = (0, _utils.deepClone)(data);
      this.getList();
    },
    getList: function getList() {
      var _this2 = this;
      this.authId('searchbtn', this.$route.meta.authList);
      var that = this;
      that.listLoading = true;
      (0, _index.queryForPage)(this.searchForm.current, this.searchForm.size, this.tradeBillQuery).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.tableData = response.data.data.records;
            that.total = parseInt(response.data.data.total);
            setTimeout(function () {
              that.listLoading = false;
            }, 1 * 500);
          } else {
            that.tableData = [];
            that.total = 0;
          }
        }
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    setShop: function setShop(val) {
      this.searchForm.productLineCode = '';
      this.searchForm.teamCode = '';
      if (!val) {
        if (!this.searchForm.factoryCode) {
          this.sellineList = JSON.parse(JSON.stringify(this.lineList));
          this.selteamList = JSON.parse(JSON.stringify(this.teamList));
        } else {
          this.sellineList = [];
          this.selteamList = [];
        }
        return;
      }
      // var item = this.workshopList.filter(function(number) {
      //   return number.factoryCode == val
      // })

      this.sellineList = this.lineList.filter(function (number) {
        return number.parentId == val;
      });
      this.selteamList = this.teamList.filter(function (number) {
        return number.parentId == val;
      });
    },
    setScanShop: function setScanShop(val) {
      this.scanData.productLineCode = '';
      this.scanData.teamCode = '';
      if (!val) {
        this.scanlineList = [];
        this.scanteamList = [];
        return;
      }
      // var item = this.workshopList.filter(function(number) {
      //   return number.factoryCode == val
      // })
      this.scanlineList = this.lineList.filter(function (number) {
        return number.parentId == val;
      });
      this.scanteamList = this.teamList.filter(function (number) {
        return number.parentId == val;
      });
    },
    add: function add() {
      var that = this;
      this.authId('addbtn', this.$route.meta.authList);
      that.submitType = 1;
      that.billType = 103;
      that.billNo = '';
      that.insertVisible = true;
      that.otherTypeName = '';
    },
    doUpdate: function doUpdate(row) {
      var that = this;
      this.authId('updatebtn', this.$route.meta.authList);
      that.submitType = 2;
      that.billType = row.billType;
      that.billNo = row.billNo;
      that.otherTypeName = row.otherTypeName || '退货入库';
      that.insertVisible = true;
    },
    closeInsert: function closeInsert() {
      var that = this;
      that.insertVisible = false;
    },
    cancelOr: function cancelOr(row) {
      var _this3 = this;
      this.authId('cxjd', this.$route.meta.authList);
      this.$confirm('撤销完成后可继续原订单进行发货<br/>注意：撤销结单时涉及相关业务处理，可能用时较长，具体可在详情中进行查询。', '是否撤销结单', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _record.revertFinish)(row.pid).then(function (res) {
          var data = res.data;
          if (data.code != 200) {
            _this3.$message.error(data.msg);
            return false;
          }
          _this3.$message({
            message: '操作成功',
            type: 'success'
          });
          _this3.getList();
        });
      }).catch(function () {
        _this3.authId('searchbtn', _this3.$route.meta.authList);
      });
    },
    del: function del(row) {
      var _this4 = this;
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this4.authId('deletebtn', _this4.$route.meta.authList);
        (0, _index.InfoDel)(row.pid).then(function (res) {
          _this4.authId('searchbtn', _this4.$route.meta.authList);
          var data = res.data;
          if (data.code != 200) {
            _this4.$message.error(data.msg);
            return false;
          }
          _this4.$message({
            message: res.data.msg,
            type: 'success'
          });
          _this4.getList();
        });
      }).catch(function () {
        _this4.authId('searchbtn', _this4.$route.meta.authList);
      });
    },
    // 获取审核详情
    getflow: function getflow(row, qx) {
      this.authId('detailbtn', this.$route.meta.authList);
      this.$refs.flowMain.show(row, qx);
    },
    // 导出功能
    download: function download() {
      var _this5 = this;
      this.authId('exportbtn', this.$route.meta.authList);
      this.downLoading = true;
      this.downLoading = true;
      (0, _index.excelOut)(this.tradeBillQuery).then(function (res) {
        _this5.authId('searchbtn', _this5.$route.meta.authList);
        if (res.status != 200) {
          _this5.$message.error(res.data.msg);
        } else {
          var blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel'
          });
          var downloadElement = document.createElement('a');
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = '退货入库.xls';
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
        _this5.downLoading = false;
      }).catch(function () {
        _this5.authId('searchbtn', _this5.$route.meta.authList);
        _this5.downLoading = false;
      });
    },
    // 打印
    printTb: function printTb(row) {
      this.authId('printbtn', this.$route.meta.authList);
      var that = this;
      that.billNo = row.billNo;
      that.typeNum = 2;
      that.pShow = true;
      that.printShow = true;
    },
    // 关闭打印
    printClose: function printClose() {
      var that = this;
      that.billNum = '';
      that.pShow = false;
      that.printShow = false;
      this.authId('searchbtn', this.$route.meta.authList);
    },
    // 二维码功能
    getQrCode: function getQrCode(row) {
      this.authId('qrcodebtn', this.$route.meta.authList);
      this.codeVisible = true;
      this.$nextTick(function () {
        if (qrcode) {
          document.getElementById('qrcode').innerHTML = '';
        }
        qrcode = new _qrcodejs.default(this.$refs.qrCodeUrl, {
          text: row.billNo,
          // 需要转换为二维码的内容
          width: 200,
          height: 200,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: _qrcodejs.default.CorrectLevel.H
        });
      });
    },
    handleCodeClose: function handleCodeClose() {
      this.codeVisible = false;
      this.authId('searchbtn', this.$route.meta.authList);
    }
  }
};
exports.default = _default;