"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _admincut = _interopRequireDefault(require("@/components/admincut"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
/* eslint-disable vue/no-unused-components */
var _default = {
  components: {
    admincut: _admincut.default
  }
};
exports.default = _default;