var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c("search", { ref: "searchMain" }),
              _vm._v(" "),
              _vm.formConfig.btnFollow
                ? _c("el-divider", { staticClass: "btnDivider" })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btnArea" },
                [
                  _vm.formConfig.btnFollow
                    ? _c(
                        "el-col",
                        {
                          staticClass: "head-btn-group",
                          staticStyle: { width: "177px" },
                        },
                        [
                          _c(
                            "el-button-group",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.searchBtn.type,
                                    size: _vm.btnConfig.size,
                                    plain: "",
                                    icon: _vm.btnConfig.searchBtn.icon,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$refs.searchMain.handleFilter(
                                        "search"
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.resetBtn.type,
                                    size: _vm.btnConfig.size,
                                    plain: "",
                                    icon: _vm.btnConfig.resetBtn.icon,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$refs.searchMain.handleFilter(
                                        "rest"
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAuth("addbtn", _vm.$route.meta.authList)
                    ? _c(
                        "div",
                        { staticClass: "head-btn-group" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.addBtn.type,
                                size: _vm.btnConfig.size,
                                plain: "",
                                icon: _vm.btnConfig.addBtn.icon,
                              },
                              on: { click: _vm.add },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.addBtn.text))]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  key: _vm.tableKey,
                  staticStyle: { width: "100%" },
                  attrs: {
                    height: _vm.tabHeight,
                    data: _vm.tableData,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      align: _vm.tableConfig.align,
                      width: "120",
                      label: "序号",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "所属企业",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [_c("div", [_vm._v(_vm._s(row.orgName))])]
                          },
                        },
                      ],
                      null,
                      false,
                      3208442318
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "库房名称",
                      prop: "storageName",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品编号",
                      prop: "productCode",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品名称",
                      prop: "productName",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "设置最低库存",
                      prop: "vmin",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "设置最高库存",
                      prop: "vmax",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _vm.hasAuth("updatebtn", _vm.$route.meta.authList) ||
                  _vm.hasAuth("deletebtn", _vm.$route.meta.authList)
                    ? _c("el-table-column", {
                        attrs: { label: "操作", align: _vm.tableConfig.align },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm.hasAuth(
                                    "updatebtn",
                                    _vm.$route.meta.authList
                                  )
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.ShowModifyById(row)
                                            },
                                          },
                                        },
                                        [_vm._v("编辑")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.hasAuth(
                                    "deletebtn",
                                    _vm.$route.meta.authList
                                  )
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.del(row)
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3867620821
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  layout: "total,sizes,prev, pager, next, jumper",
                  total: _vm.total,
                  page: _vm.searchForm.current,
                  limit: _vm.searchForm.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.searchForm, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.searchForm, "size", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c("insert", { ref: "insertMain" }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: "编辑",
            visible: _vm.dialogFormVisible,
            "before-close": _vm.CloseModifyById,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "设置最低库存（箱）",
                    "label-width": "180px",
                  },
                },
                [
                  _c("el-input-number", {
                    attrs: { size: "small", min: 1, max: 1000 },
                    model: {
                      value: _vm.form.vmin,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "vmin", $$v)
                      },
                      expression: "form.vmin",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "设置最高库存（箱）",
                    "label-width": "180px",
                  },
                },
                [
                  _c("el-input-number", {
                    attrs: { size: "small", min: 1, max: 1000 },
                    model: {
                      value: _vm.form.vmax,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "vmax", $$v)
                      },
                      expression: "form.vmax",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.CloseModifyById } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.btn_loading },
                  on: { click: _vm.SaveModifyById },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }