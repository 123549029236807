var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _c(
      "div",
      { staticClass: "page-section" },
      [
        _c(
          "el-form",
          {
            ref: "wechatOpenPlatRuleForm",
            staticClass: "demo-ruleForm",
            staticStyle: { padding: "20px 20px 0" },
            attrs: {
              model: _vm.wechatOpenPlatForm,
              rules: _vm.wechatOpenPlatRules,
              "label-width": "250px",
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "第三方平台APPID:", prop: "disparkAppid" } },
              [
                _c("el-input", {
                  model: {
                    value: _vm.wechatOpenPlatForm.disparkAppid,
                    callback: function ($$v) {
                      _vm.$set(_vm.wechatOpenPlatForm, "disparkAppid", $$v)
                    },
                    expression: "wechatOpenPlatForm.disparkAppid",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "第三方平台APP SECRET：",
                  prop: "disparkSecret",
                },
              },
              [
                _c("el-input", {
                  model: {
                    value: _vm.wechatOpenPlatForm.disparkSecret,
                    callback: function ($$v) {
                      _vm.$set(_vm.wechatOpenPlatForm, "disparkSecret", $$v)
                    },
                    expression: "wechatOpenPlatForm.disparkSecret",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "消息校验Token：", prop: "disparkToken" } },
              [
                _c("el-input", {
                  model: {
                    value: _vm.wechatOpenPlatForm.disparkToken,
                    callback: function ($$v) {
                      _vm.$set(_vm.wechatOpenPlatForm, "disparkToken", $$v)
                    },
                    expression: "wechatOpenPlatForm.disparkToken",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "消息加解密Key：", prop: "disparkKey" } },
              [
                _c("el-input", {
                  model: {
                    value: _vm.wechatOpenPlatForm.disparkKey,
                    callback: function ($$v) {
                      _vm.$set(_vm.wechatOpenPlatForm, "disparkKey", $$v)
                    },
                    expression: "wechatOpenPlatForm.disparkKey",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.submitWechatOpenPlat(
                          "wechatOpenPlatRuleForm"
                        )
                      },
                    },
                  },
                  [_vm._v("保存")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.resetWechatOpenPlatForm(
                          "wechatOpenPlatRuleForm"
                        )
                      },
                    },
                  },
                  [_vm._v("取消")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }