"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addDelivery = addDelivery;
exports.categoryAdd = categoryAdd;
exports.categoryInfo = categoryInfo;
exports.categoryTable = categoryTable;
exports.delCategory = delCategory;
exports.delIndexImg = delIndexImg;
exports.delNotice = delNotice;
exports.delProd = delProd;
exports.delProdTag = delProdTag;
exports.delProds = delProds;
exports.delSpec = delSpec;
exports.delivery = delivery;
exports.delspec = delspec;
exports.getByOrgId = getByOrgId;
exports.getIntegralRule = getIntegralRule;
exports.getProdPage = getProdPage;
exports.indexImg = indexImg;
exports.indexImgInfo = indexImgInfo;
exports.indexImgList = indexImgList;
exports.insertRule = insertRule;
exports.listCategory = listCategory;
exports.listSpec = listSpec;
exports.listSpecValue = listSpecValue;
exports.listTagList = listTagList;
exports.notice = notice;
exports.noticeInfo = noticeInfo;
exports.orderList = orderList;
exports.prodAdd = prodAdd;
exports.prodInfo = prodInfo;
exports.prodTagAdd = prodTagAdd;
exports.prodTagInfo = prodTagInfo;
exports.prodTagList = prodTagList;
exports.soldExcel = soldExcel;
exports.specAdd = specAdd;
exports.specList = specList;
exports.upNotice = upNotice;
exports.updateRuleById = updateRuleById;
exports.uploadImg = uploadImg;
exports.uporderInfo = uporderInfo;
exports.waitingConsignmentExcel = waitingConsignmentExcel;
var _request = _interopRequireDefault(require("@/utils/request"));
var baseUrl = '';
// 产品列表
function getProdPage(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-shopadmin/prod/prod/page',
    method: 'get',
    params: _request.default.adornParams(params)
  });
}
// 删除产品
function delProd(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-shopadmin/prod/prod/' + params,
    method: 'delete',
    params: params
  });
}
// 批量删除
function delProds(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-shopadmin/prod/prod/batch',
    method: 'delete',
    params: params
  });
}
// 获取产品数据
function prodInfo(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-shopadmin/prod/prod/info/' + params,
    method: 'get',
    params: _request.default.adornParams()
  });
}
// 产品添加/修改
function prodAdd(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-shopadmin/prod/prod',
    method: data.prodId ? 'put' : 'post',
    data: data
  });
}

// 获取分类信息
function listCategory(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-shopadmin/prod/category/listCategory',
    method: 'get',
    params: _request.default.adornParams(params)
  });
}

// 分类列表
function categoryTable(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-shopadmin/prod/category/table',
    method: 'get',
    params: _request.default.adornParams(params)
  });
}
// 删除分类
function delCategory(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-shopadmin/prod/category/' + params,
    method: 'delete',
    params: params
  });
}
// 分类详情
function categoryInfo(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-shopadmin/prod/category/info/' + params,
    method: 'get',
    params: _request.default.adornParams()
  });
}
// 分类修改/增加
function categoryAdd(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-shopadmin/prod/category',
    method: data.categoryId ? 'put' : 'post',
    // data: request.adornData(data)
    data: data
  });
}
// 获取所有的分组信息
function listTagList(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-shopadmin/prod/prodTag/listTagList',
    method: 'get',
    params: _request.default.adornParams()
  });
}
// 分组列表
function prodTagList(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-shopadmin/prod/prodTag/page',
    method: 'get',
    params: _request.default.adornParams(params)
  });
}
// 删除分组
function delProdTag(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-shopadmin/prod/prodTag/' + params,
    method: 'delete',
    params: params
  });
}
// 分组详情
function prodTagInfo(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-shopadmin/prod/prodTag/info/' + params,
    method: 'get',
    params: _request.default.adornParams()
  });
}
// 分组修改/增加
function prodTagAdd(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-shopadmin/prod/prodTag',
    method: data.id ? 'put' : 'post',
    data: data
  });
}
// 规格列表
function specList(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-shopadmin/prod/spec/page',
    method: 'get',
    params: _request.default.adornParams(params)
  });
}
// 规格
function listSpec(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-shopadmin/prod/spec/list',
    method: 'get',
    params: _request.default.adornParams()
  });
}
// 删除规格
function delSpec(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-shopadmin/prod/spec/' + params,
    method: 'delete',
    params: params
  });
}
// 添加\修改规格
function specAdd(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-shopadmin/prod/spec',
    method: data.propId ? 'put' : 'post',
    data: data
  });
}
// 如果数据库已有该规格名，则获取根据id获取该规格名称含有的规格值
function listSpecValue(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-shopadmin/prod/spec/listSpecValue/' + params,
    method: 'get',
    params: _request.default.adornParams()
  });
}

// 根据企业id查询积分详情
function getByOrgId(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/api/integral/getByOrgId',
    method: 'post',
    data: data
  });
}

// 获取当前机构的积分规则设置
function getIntegralRule(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/api/irule/getIntegralRule',
    method: 'post',
    data: data
  });
}

// 更新积分规则
function updateRuleById(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/api/irule/updateRuleById',
    method: 'post',
    data: data
  });
}

// 增加积分规则
function insertRule(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-uspyx/api/irule/insertRule',
    method: 'post',
    data: data
  });
}
// 首页轮轮播图列表
function indexImgList(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-shopadmin/admin/indexImg/page',
    method: 'get',
    params: _request.default.adornParams(params)
  });
}
// 获取轮播图详情
function indexImgInfo(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-shopadmin/admin/indexImg/info/' + params,
    method: 'get',
    params: params
  });
}
// 添加/更新轮播图
function indexImg(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-shopadmin/admin/indexImg',
    method: data.imgId ? 'put' : 'post',
    data: data
  });
}
// 删除轮播图
function delIndexImg(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-shopadmin/admin/indexImg',
    method: 'delete',
    data: data
  });
}
// 公告列表
function notice(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-shopadmin/shop/notice/page',
    method: 'get',
    params: params
  });
}
// 删除公告
function delNotice(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-shopadmin/shop/notice/' + params,
    method: 'delete',
    params: params
  });
}
// 公告详情
function noticeInfo(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-shopadmin/shop/notice/info/' + params,
    method: 'get',
    params: params
  });
}
// 添加/更新公告
function upNotice(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-shopadmin/shop/notice',
    method: data.id ? 'put' : 'post',
    data: data
  });
}
// 订单列表
function orderList(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-shopadmin/order/order/page',
    method: 'get',
    params: params
  });
}
// 删除订单

function delspec(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-shopadmin/prod/spec/' + params,
    method: 'delete',
    data: _request.default.adornData(ids, false)
  });
}
// 修改订单
function uporderInfo(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-shopadmin/order/order/orderInfo/' + params,
    method: 'get',
    params: _request.default.adornParams()
  });
}
// 快递列表
function delivery(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-shopadmin/admin/delivery/list',
    method: 'get',
    params: _request.default.adornParams()
  });
}
// 快递记录
function addDelivery(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-shopadmin/order/order/delivery',
    method: 'put',
    data: _request.default.adornParams(data)
  });
}
// 导出发货订单
function waitingConsignmentExcel(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-shopadmin/order/order/waitingConsignmentExcel',
    method: 'get',
    params: _request.default.adornParams(params),
    responseType: 'blob' // 解决文件下载乱码问题
  });
}
// 导出销售记录
function soldExcel(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-shopadmin/order/order/soldExcel',
    method: 'get',
    params: _request.default.adornParams(params),
    responseType: 'blob' // 解决文件下载乱码问题
  });
}
// 上传图片
function uploadImg(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-basic/jc/file/upload/uploadFile',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: data
  });
}