"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _org = require("@/api/basic/org");
var _factory = require("@/api/basic/factory");
var _storeHouse = require("@/api/basic/storeHouse");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _expel = require("../../../api/fake_expel/expel/expel");
var _utils2 = require("../../../utils");
var _zheCode = require("../../../api/zheCode");
var _store = require("../../../api/store");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Storehousefile',
  components: {
    Pagination: _Pagination.default
  },
  filters: {
    formatterUserType: function formatterUserType(val) {
      var map = {
        0: '企业用户',
        1: '渠道用户',
        2: '供应商用户',
        3: '系统用户',
        4: '浙品码注册用户'
      };
      return map[val];
    }
  },
  data: function data() {
    return {
      handleShow: false,
      props: {
        value: 'pId',
        label: 'orgName',
        children: 'children',
        checkStrictly: true
      },
      dialogVisible: false,
      title: '',
      daterange: [],
      total: 0,
      createTime: [],
      searchForm: {
        current: 1,
        size: 20,
        classId: null,
        endTime: '',
        markCode: '',
        mobile: '',
        orgId: null,
        pinNo: '',
        productCode: '',
        productModel: '',
        productName: '',
        regionName: '',
        startTime: '',
        userId: null,
        userType: null
      },
      depList: [],
      ruleForm: {
        // factOrgType: 1,
        factoryOrgId: '',
        orgId: '',
        departId: '',
        isEnable: 1,
        remarks: '',
        storeHouseName: '',
        storeHouseCode: ''
      },
      rules: {
        storeHouseName: [{
          required: true,
          message: '库房名称不能为空，请维护',
          trigger: 'submit'
        }]
        // storeHouseCode: [
        //   {
        //     required: true,
        //     message: '库房编码不能为空，请维护',
        //     trigger: 'submit'
        //   }
        // ]
      },

      listLoading: false,
      mloading: false,
      btnLoading: false,
      tabHeight: '100%',
      tableData: [],
      page2: false,
      options: [],
      value: [],
      list: [],
      loading: false,
      orgList: [],
      orgSelList: [],
      factoryList: [],
      normalList: [],
      status: '',
      downLoading: false,
      saleorgList: [],
      channelList: [],
      userTypeList: [{
        value: 0,
        label: '企业用户'
      }, {
        value: 1,
        label: '渠道用户'
      }, {
        value: 2,
        label: '供应商用户'
      }, {
        value: 3,
        label: '系统用户'
      }, {
        value: 4,
        label: '浙品码注册用户'
      }]
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = window.innerHeight - 380 + 'px';
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    this.getList();
    this.getOrgListAll();
    this.getSaleOrgs();
    this.getChannelClassList();
  },
  methods: {
    changeCreateTime: function changeCreateTime(val) {
      if (val) {
        this.searchForm.startTime = val[0];
        this.searchForm.endTime = val[1];
      }
    },
    changetab: function changetab() {
      this.$forceUpdate();
    },
    changeorg: function changeorg(orgId) {
      var _this = this;
      this.ruleForm.departId = '';
      (0, _factory.getChildOrgInfos)({
        orgId: orgId
      }).then(function (response) {
        if (response.data.code != 200) {
          _this.$message.error(response.data.msg);
        } else {
          _this.depList = response.data.data;
        }
      });
    },
    getSaleOrgs: function getSaleOrgs() {
      var _this2 = this;
      (0, _org.fetchOrgList)({}).then(function (res) {
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this2.saleorgList = res.data.data;
          } else {
            _this2.saleorgList = [];
          }
        }
      });
    },
    getChannelClassList: function getChannelClassList() {
      var _this3 = this;
      (0, _store.findAllChannelClass)({}).then(function (res) {
        if (res.data.code != 200) {
          _this3.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this3.channelList = res.data.data;
          } else {
            _this3.channelList = [];
          }
        }
      });
    },
    getFactoryAll: function getFactoryAll(orgId) {
      var _this4 = this;
      (0, _factory.factoryInfoAllList)({
        type: 1,
        isEnable: 1,
        orgId: orgId
      }).then(function (res) {
        if (res.data.code != 200) {
          _this4.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this4.factoryList = res.data.data;
          } else {
            _this4.factoryList = [];
          }
          sessionStorage.setItem('factoryList', JSON.stringify(_this4.factoryList));
        }
      });
    },
    openFactory: function openFactory() {
      if (!this.ruleForm.orgId) {
        this.$message.error('请先选择所属公司');
        return;
      }
      this.getFactoryAll(this.ruleForm.orgId);
    },
    getOrgListAll: function getOrgListAll() {
      var that = this;
      (0, _org.fetchOrgList)({
        orgCodeAndName: '',
        orgStatus: 1,
        orgType: 1
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          that.orgList = response.data.data;
        }
      });
    },
    getOrgListSel: function getOrgListSel() {
      var that = this;
      that.orgSelList = [];
      (0, _org.fetchOrgList)({
        orgCodeAndName: '',
        orgStatus: 1,
        orgType: 1
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          that.orgSelList = response.data.data;
        }
      });
    },
    getList: function getList() {
      this.authId('searchbtn', this.$route.meta.authList);
      var that = this;
      that.listLoading = true;
      var submitData = (0, _utils2.deepClone)(that.searchForm);
      (0, _zheCode.recordPage)(submitData).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.total = parseInt(response.data.data.total);
            that.tableData = response.data.data.records;
          } else {
            that.total = 0;
            that.tableData = [];
          }
          that.listLoading = false;
        }
      }).catch(function (res) {
        that.listLoading = false;
      });
    },
    resetForm: function resetForm() {
      this.searchForm = {
        current: 1,
        size: 20,
        classId: null,
        endTime: '',
        markCode: '',
        mobile: '',
        orgId: null,
        pinNo: '',
        productCode: '',
        productModel: '',
        productName: '',
        regionName: '',
        startTime: '',
        userId: null,
        userType: null
      };
      this.daterange = [];
      this.getList();
    },
    filterparentList: function filterparentList(val) {
      var that = this;
      return this.normalList.filter(function (number) {
        if (number.pId == val) {
          that.ruleForm.factoryOrgId.unshift(val);
          if (number.parentPid) {
            that.filterparentList(number.parentPid);
          }
        }
      });
    },
    add: function add() {
      this.authId('addbtn', this.$route.meta.authList);
      this.title = '添加库房';
      this.status = '';
      this.getOrgListSel();
      // this.getFactoryAll()
      this.dialogVisible = true;
    },
    // 导出功能
    download: function download() {
      var _this5 = this;
      this.authId('exportbtn', this.$route.meta.authList);
      this.downLoading = true;
      var submitData = (0, _utils2.deepClone)(this.searchForm);
      delete submitData.size;
      delete submitData.current;
      (0, _zheCode.downRecord)(submitData).then(function (res) {
        _this5.authId('searchbtn', _this5.$route.meta.authList);
        if (res.status != 200) {
          _this5.$message.error(res.data.msg);
        } else {
          var blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel'
          });
          var downloadElement = document.createElement('a');
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = '浙品码合成日志.xls';
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
        _this5.downLoading = false;
      }).catch(function () {
        _this5.authId('searchbtn', _this5.$route.meta.authList);
        _this5.downLoading = false;
      });
    },
    edit: function edit(row) {
      this.status = '';
      this.authId('updatebtn', this.$route.meta.authList);
      this.title = '编辑库房';
      this.getOrgListSel();
      this.getFactoryAll(row.orgId);
      // this.getFactoryAll()
      if (row.isEnable == 1) {
        this.status = '启用';
      }
      this.ruleForm.pId = row.pId;
      this.ruleForm.orgId = row.orgId;
      this.changeorg(this.ruleForm.orgId);
      // this.ruleForm.factOrgType = row.factOrgType
      this.ruleForm.isEnable = row.isEnable;
      this.ruleForm.remarks = row.remarks;
      this.ruleForm.storeHouseName = row.storeHouseName;
      this.ruleForm.storeHouseCode = row.storeHouseCode;
      this.ruleForm.departId = row.departId;
      // if (row.factOrgType == 2) {
      //   this.ruleForm.factoryOrgId = []
      //   this.filterparentList(row.factoryOrgId)
      // } else {
      this.ruleForm.factoryOrgId = row.factoryOrgId;
      // }
      this.dialogVisible = true;
    },
    del: function del(id) {
      var _this6 = this;
      this.$confirm('此操作将永久删除该数据,是否继续?', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this6.authId('deletebtn', _this6.$route.meta.authList);
        (0, _storeHouse.storeHouseInfoDel)({
          isDelete: 2,
          pId: id
        }).then(function (res) {
          _this6.authId('searchbtn', _this6.$route.meta.authList);
          if (res.data.code != 200) {
            _this6.$message.error(res.data.msg);
          } else {
            _this6.$message({
              message: '操作成功',
              type: 'success'
            });
            _this6.getList();
          }
        });
      }).catch(function () {
        _this6.authId('searchbtn', _this6.$route.meta.authList);
      });
    },
    handleClose: function handleClose() {
      this.ruleForm = {
        // factOrgType: 1,
        factoryOrgId: '',
        orgId: '',
        isEnable: 1,
        remarks: '',
        storeHouseName: '',
        storeHouseCode: ''
      };
      this.$refs.ruleForm.resetFields();
      this.dialogVisible = false;
      this.authId('searchbtn', this.$route.meta.authList);
    },
    submitForm: function submitForm() {
      var _this7 = this;
      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          _this7.btnLoading = true;
          var submitData = JSON.parse(JSON.stringify((0, _utils.filterKeyNull)(_this7.ruleForm)));
          if (_this7.ruleForm.pId) {
            (0, _storeHouse.storeHouseInfoUpdate)(submitData).then(function (res) {
              if (res.data.code != 200) {
                _this7.$message.error(res.data.msg);
              } else {
                _this7.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this7.handleClose();
                _this7.getList();
              }
              _this7.btnLoading = false;
            }).catch(function () {
              _this7.btnLoading = false;
            });
          } else {
            (0, _storeHouse.storeHouseInfoInsert)(submitData).then(function (res) {
              if (res.data.code != 200) {
                _this7.$message.error(res.data.msg);
              } else {
                _this7.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this7.handleClose();
                _this7.getList();
              }
              _this7.btnLoading = false;
            }).catch(function () {
              _this7.btnLoading = false;
            });
          }
        }
      });
    }
  }
};
exports.default = _default;