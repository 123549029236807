"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actTemplatePage = actTemplatePage;
exports.addActivity = addActivity;
exports.addOrEditWay = addOrEditWay;
exports.addrPocess = addrPocess;
exports.allBindCodeList = allBindCodeList;
exports.editStatus = editStatus;
exports.endProcessDetail = endProcessDetail;
exports.exportBindCodeList = exportBindCodeList;
exports.getProcessDetail = getProcessDetail;
exports.getProcessList = getProcessList;
exports.getwayList = getwayList;
exports.startProcess = startProcess;
var _request = _interopRequireDefault(require("@/utils/request"));
var base = '';

// 活动流程列表
function getProcessList(data) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/process/getProcessList',
    method: 'post',
    data: data
  });
}

// 添加活动模板列表
function addActivity(params) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/activityTemplateInfo/templateInfoVoList',
    method: 'get',
    params: params
  });
}

// 添加流程列表
function addrPocess(data) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/process/saveProcess',
    method: 'post',
    data: data
  });
}

// 启用活动流程详情实物
function startProcess(params) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/process/startProcessDetail',
    method: 'post',
    params: params
  });
}

// 停用活动流程详情实物
function endProcessDetail(params) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/process/endProcessDetail',
    method: 'post',
    params: params
  });
}

// 查看流程中的活动页面
function actTemplatePage(params) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/process/actTemplatePage',
    method: 'get',
    params: params
  });
}

// 获得活动流程详情
function getProcessDetail(params) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/process/getProcessDetail',
    method: 'post',
    params: params
  });
}

// 获得活动方案列表
function getwayList(data) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/activityScheme/getList',
    method: 'post',
    data: data
  });
}

// 创建或修改活动方案列表
function addOrEditWay(data) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/activityScheme/addOrEdit',
    method: 'post',
    data: data
  });
}

// 修改状态
function editStatus(data) {
  return (0, _request.default)({
    url: base + '/market-zdConfig/activityScheme/editStatus',
    method: 'post',
    data: data
  });
}

// 箱码绑定记录
function allBindCodeList(data) {
  return (0, _request.default)({
    url: base + '/market-zdCenter/bindCode/allBindCodeList',
    method: 'post',
    data: data
  });
}

// 导出绑定记录
function exportBindCodeList(data) {
  return (0, _request.default)({
    url: base + '/market-zdCenter/bindCode/exportBindCodeList',
    method: 'post',
    responseType: 'blob',
    data: data
  });
}