var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-container" }, [
      _c("div", { staticClass: "box" }, [
        _c("img", {
          staticStyle: { width: "711px", height: "317px" },
          attrs: { src: require("../../images/loginSide.png"), alt: "" },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }