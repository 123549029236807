var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "box", staticClass: "cardBox tabBox" }, [
    _c(
      "div",
      { staticClass: "app-container" },
      [
        _c(
          "el-row",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoading,
                expression: "listLoading",
              },
            ],
          },
          [
            _c(
              "el-col",
              [
                _c(
                  "el-steps",
                  {
                    attrs: {
                      active: _vm.active,
                      space: 800,
                      "align-center": "",
                      "finish-status": "success",
                    },
                  },
                  [
                    _c("el-step", { attrs: { title: "基础信息" } }),
                    _vm._v(" "),
                    _c("el-step", { attrs: { title: "活动页设置" } }),
                    _vm._v(" "),
                    false
                      ? _c("el-step", { attrs: { title: "信息收集页" } })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.active === 0,
                          expression: "active === 0",
                        },
                      ],
                      staticStyle: { padding: "40px" },
                      attrs: { label: "基础信息", name: "basic" },
                    },
                    [
                      _c(
                        "el-row",
                        { staticClass: "row-bg", attrs: { type: "flex" } },
                        [
                          _c(
                            "el-col",
                            { attrs: { lg: 24, md: 24, sm: 24 } },
                            [
                              _c(
                                "el-form",
                                {
                                  ref: "basicForm",
                                  staticStyle: { margin: "10 auto" },
                                  attrs: {
                                    rules: _vm.rules,
                                    model: _vm.basicForm,
                                    "label-position": "right",
                                    "label-width": "120px",
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "模版名称:",
                                        prop: "templateName",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "请输入不能超过25个汉字",
                                          type: "text",
                                          maxlength: "25",
                                          disabled: _vm.disabled,
                                        },
                                        model: {
                                          value: _vm.basicForm.templateName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.basicForm,
                                              "templateName",
                                              $$v
                                            )
                                          },
                                          expression: "basicForm.templateName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "备注:",
                                        prop: "templateRemark",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: _vm.disabled
                                            ? ""
                                            : "请输入不能超过25个汉字",
                                          type: "textarea",
                                          maxlength: "25",
                                          autosize: { minRows: 4, maxRows: 6 },
                                          "show-word-limit": "",
                                          disabled: _vm.disabled,
                                        },
                                        model: {
                                          value: _vm.basicForm.templateRemark,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.basicForm,
                                              "templateRemark",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "basicForm.templateRemark",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "状态:",
                                        prop: "templateStatus",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio-group",
                                        {
                                          staticClass: "radio",
                                          attrs: { disabled: _vm.disabled },
                                          model: {
                                            value: _vm.basicForm.templateStatus,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.basicForm,
                                                "templateStatus",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "basicForm.templateStatus",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio",
                                            { attrs: { label: "1" } },
                                            [_vm._v("启用")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-radio",
                                            { attrs: { label: "2" } },
                                            [_vm._v("停用")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.active == 1,
                          expression: "active == 1",
                        },
                      ],
                      staticClass: "templatess",
                      attrs: { label: "活动页", name: "active" },
                    },
                    [
                      _c(
                        "el-row",
                        { staticStyle: { padding: "30px 0" } },
                        [
                          _c(
                            "el-col",
                            {
                              staticStyle: { "padding-bottom": "20px" },
                              attrs: { md: 10, sm: 24 },
                            },
                            [
                              _c("div", { staticClass: "phoneWrap" }, [
                                _c("div", { staticClass: "phoneOuter" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "phoneInner",
                                      style:
                                        "background-image: url('" +
                                        _vm.contentForm.background +
                                        "');",
                                    },
                                    [
                                      _c("div", { staticClass: "title-c" }, [
                                        _c(
                                          "div",
                                          { staticClass: "title" },
                                          [
                                            _vm.contentForm.title
                                              ? _c("el-image", {
                                                  staticStyle: {
                                                    width: "100%",
                                                    display: "block",
                                                  },
                                                  attrs: {
                                                    fit: "scale-down",
                                                    src: _vm.contentForm.title,
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "defaultTitle" },
                                              [
                                                _vm._v(
                                                  "\n                            " +
                                                    _vm._s(
                                                      _vm.contentForm.title
                                                        ? ""
                                                        : "标题"
                                                    ) +
                                                    "\n                          "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "prizebg-c" }, [
                                        _c(
                                          "div",
                                          { staticClass: "scratch-container" },
                                          [
                                            _vm.contentForm.scratchBottomBg
                                              ? _c("el-image", {
                                                  staticClass: "scratch-bgImg",
                                                  staticStyle: {
                                                    width: "100%",
                                                    display: "block",
                                                  },
                                                  attrs: {
                                                    fit: "scale-down",
                                                    src: _vm.contentForm
                                                      .scratchBottomBg,
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "defaultTitle" },
                                              [
                                                _vm._v(
                                                  "\n                            " +
                                                    _vm._s(
                                                      _vm.contentForm
                                                        .scratchBottomBg
                                                        ? ""
                                                        : "刮奖背景"
                                                    ) +
                                                    "\n                          "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "scratch-cover-bgcolor",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "scratchBtnBg",
                                                  },
                                                  [
                                                    _vm.contentForm.scratchBtnBg
                                                      ? _c("el-image", {
                                                          staticClass:
                                                            "scratch-btnBgImg",
                                                          staticStyle: {
                                                            width: "100%",
                                                            display: "block",
                                                          },
                                                          attrs: {
                                                            fit: "scale-down",
                                                            src: _vm.contentForm
                                                              .scratchBtnBg,
                                                          },
                                                        })
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.contentForm
                                                            .scratchBtnBg
                                                            ? ""
                                                            : "刮奖按钮"
                                                        )
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { md: 14, sm: 24 } },
                            [
                              _c(
                                "el-form",
                                {
                                  ref: "contentForm",
                                  staticStyle: { margin: "10 auto" },
                                  attrs: {
                                    rules: _vm.contentRules,
                                    model: _vm.contentForm,
                                    "label-position": "right",
                                    "label-width": "100px",
                                  },
                                },
                                [
                                  _c(
                                    "el-row",
                                    { attrs: { gutter: 10 } },
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 24 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "背景:",
                                                prop: "background",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-input",
                                                {
                                                  attrs: {
                                                    disabled: _vm.disabled,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.contentForm
                                                        .background,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.contentForm,
                                                        "background",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "contentForm.background",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "template",
                                                    { slot: "append" },
                                                    [
                                                      _c("Upload-img", {
                                                        ref: "background",
                                                        attrs: {
                                                          data: _vm.contentForm
                                                            .background,
                                                          disabled:
                                                            _vm.disabled,
                                                          "limit-count": 1,
                                                          text: "建议尺寸:(750px*1334px)",
                                                        },
                                                        on: {
                                                          "update:data":
                                                            function ($event) {
                                                              return _vm.$set(
                                                                _vm.contentForm,
                                                                "background",
                                                                $event
                                                              )
                                                            },
                                                          getdata: _vm.setBgImg,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                2
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "imgbg" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "uploadTip",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "建议尺寸:(750px*1334px)"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.contentForm.background
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "showLoadBg",
                                                        },
                                                        [
                                                          _c("el-image", {
                                                            staticStyle: {
                                                              width: "100px",
                                                              height: "100px",
                                                            },
                                                            attrs: {
                                                              fit: "scale-down",
                                                              src: _vm
                                                                .contentForm
                                                                .background,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 24 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "标题:",
                                                prop: "title",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-input",
                                                {
                                                  attrs: {
                                                    disabled: _vm.disabled,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.contentForm.title,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.contentForm,
                                                        "title",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "contentForm.title",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "template",
                                                    { slot: "append" },
                                                    [
                                                      _c("Upload-img", {
                                                        ref: "title",
                                                        attrs: {
                                                          data: _vm.contentForm
                                                            .title,
                                                          disabled:
                                                            _vm.disabled,
                                                          "limit-count": 1,
                                                          text: "建议尺寸:(750px*340px)",
                                                        },
                                                        on: {
                                                          "update:data":
                                                            function ($event) {
                                                              return _vm.$set(
                                                                _vm.contentForm,
                                                                "title",
                                                                $event
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                2
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "imgbg" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "uploadTip",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "建议尺寸:(750px*340px)"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.contentForm.title
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "showLoadBg",
                                                        },
                                                        [
                                                          _c("el-image", {
                                                            staticStyle: {
                                                              width: "100px",
                                                              height: "100px",
                                                            },
                                                            attrs: {
                                                              fit: "scale-down",
                                                              src: _vm
                                                                .contentForm
                                                                .title,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 24 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "刮奖背景:",
                                                prop: "scratchBottomBg",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-input",
                                                {
                                                  attrs: {
                                                    disabled: _vm.disabled,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.contentForm
                                                        .scratchBottomBg,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.contentForm,
                                                        "scratchBottomBg",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "contentForm.scratchBottomBg",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "template",
                                                    { slot: "append" },
                                                    [
                                                      _c("Upload-img", {
                                                        ref: "scratchBottomBg",
                                                        attrs: {
                                                          data: _vm.contentForm
                                                            .scratchBottomBg,
                                                          disabled:
                                                            _vm.disabled,
                                                          "limit-count": 1,
                                                          text: "建议尺寸:（345px*268)",
                                                        },
                                                        on: {
                                                          "update:data":
                                                            function ($event) {
                                                              return _vm.$set(
                                                                _vm.contentForm,
                                                                "scratchBottomBg",
                                                                $event
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                2
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "imgbg" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "uploadTip",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "建议尺寸:(345px*268)"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.contentForm
                                                    .scratchBottomBg
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "showLoadBg",
                                                        },
                                                        [
                                                          _c("el-image", {
                                                            staticStyle: {
                                                              width: "100px",
                                                              height: "100px",
                                                            },
                                                            attrs: {
                                                              fit: "scale-down",
                                                              src: _vm
                                                                .contentForm
                                                                .scratchBottomBg,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 24 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "刮奖按钮:",
                                                prop: "scratchBtnBg",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-input",
                                                {
                                                  attrs: {
                                                    disabled: _vm.disabled,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.contentForm
                                                        .scratchBtnBg,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.contentForm,
                                                        "scratchBtnBg",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "contentForm.scratchBtnBg",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "template",
                                                    { slot: "append" },
                                                    [
                                                      _c("Upload-img", {
                                                        ref: "scratchBtnBg",
                                                        attrs: {
                                                          data: _vm.contentForm
                                                            .scratchBtnBg,
                                                          disabled:
                                                            _vm.disabled,
                                                          "limit-count": 1,
                                                          text: "建议尺寸:（185px*48px)",
                                                        },
                                                        on: {
                                                          "update:data":
                                                            function ($event) {
                                                              return _vm.$set(
                                                                _vm.contentForm,
                                                                "scratchBtnBg",
                                                                $event
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                2
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "imgbg" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "uploadTip",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "建议尺寸:(185px*48px)"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.contentForm.scratchBtnBg
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "showLoadBg",
                                                        },
                                                        [
                                                          _c("el-image", {
                                                            staticStyle: {
                                                              width: "100px",
                                                              height: "100px",
                                                            },
                                                            attrs: {
                                                              fit: "scale-down",
                                                              src: _vm
                                                                .contentForm
                                                                .scratchBtnBg,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 24 } },
                                        [
                                          _c(
                                            "el-row",
                                            { attrs: { type: "flex" } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "中奖背景颜色:",
                                                    prop: "activeBgColor",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    [
                                                      _c("el-color-picker", {
                                                        attrs: {
                                                          disabled:
                                                            _vm.disabled,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.contentForm
                                                              .activeBgColor,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contentForm,
                                                              "activeBgColor",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contentForm.activeBgColor",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", [
                                                    _vm._v(
                                                      "注意:中奖背景颜色未选择颜色将为透明"
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "中奖字体颜色:",
                                                    prop: "activeBgFontColor",
                                                    "label-width": "115px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    [
                                                      _c("el-color-picker", {
                                                        attrs: {
                                                          disabled:
                                                            _vm.disabled,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.contentForm
                                                              .activeBgFontColor,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.contentForm,
                                                              "activeBgFontColor",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "contentForm.activeBgFontColor",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.active == 2,
                          expression: "active == 2",
                        },
                      ],
                      staticClass: "templatess",
                      attrs: { label: "信息收集页", name: "info" },
                    },
                    [
                      _c(
                        "el-row",
                        { staticStyle: { padding: "30px" } },
                        [
                          _c(
                            "el-col",
                            {
                              staticStyle: { "padding-bottom": "20px" },
                              attrs: { md: 8, sm: 24 },
                            },
                            [
                              _c("div", { staticClass: "phoneWrap" }, [
                                _c("div", { staticClass: "phoneOuter" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "phoneInner",
                                      style:
                                        "background-image: url(" +
                                        _vm.bgImg +
                                        ");",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "phoneForm" },
                                        [
                                          _c(
                                            "el-form",
                                            {
                                              attrs: { "label-width": "100px" },
                                            },
                                            _vm._l(
                                              _vm.renderList,
                                              function (item, index) {
                                                return _c(
                                                  "div",
                                                  { key: index },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          label: item.fieldName,
                                                          prop:
                                                            "renderList." +
                                                            index +
                                                            ".filelName",
                                                        },
                                                      },
                                                      [
                                                        item.fieldType == "1"
                                                          ? _c("el-input")
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        item.fieldType == "2"
                                                          ? _c("el-switch")
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        item.fieldType == "3"
                                                          ? _c("el-input")
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "phoneBtn",
                                          style:
                                            "background-image: url(" +
                                            _vm.infoForm.collectionBtn +
                                            ");",
                                        },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(
                                                _vm.infoForm.collectionBtn
                                                  ? ""
                                                  : "按钮"
                                              ) +
                                              "\n                      "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { md: 16, sm: 24 } },
                            [
                              _c(
                                "el-form",
                                {
                                  ref: "infoForm",
                                  staticStyle: { margin: "10 auto" },
                                  attrs: {
                                    rules: _vm.infoRules,
                                    model: _vm.infoForm,
                                    "label-position": "right",
                                    "label-width": "160px",
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "位置定位:",
                                        prop: "openLocation",
                                      },
                                    },
                                    [
                                      _c("el-switch", {
                                        attrs: {
                                          "active-color": "#4181FF",
                                          "inactive-color": "#dcdfe6",
                                          "active-value": "1",
                                          "inactive-value": "2",
                                        },
                                        model: {
                                          value: _vm.infoForm.openLocation,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.infoForm,
                                              "openLocation",
                                              $$v
                                            )
                                          },
                                          expression: "infoForm.openLocation",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "数据收集:",
                                        prop: "openDataCollection",
                                      },
                                    },
                                    [
                                      _c("el-switch", {
                                        attrs: {
                                          "active-color": "#4181FF",
                                          "inactive-color": "#dcdfe6",
                                          "active-value": "1",
                                          "inactive-value": "2",
                                        },
                                        on: { change: _vm.switchChange },
                                        model: {
                                          value:
                                            _vm.infoForm.openDataCollection,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.infoForm,
                                              "openDataCollection",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "infoForm.openDataCollection",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.checkBoxList.length > 0
                                    ? _c(
                                        "el-form-item",
                                        { attrs: { label: "收集项:" } },
                                        [
                                          _c(
                                            "el-checkbox-group",
                                            {
                                              on: {
                                                change:
                                                  _vm.handleCheckedInfoChange,
                                              },
                                              model: {
                                                value: _vm.checkList,
                                                callback: function ($$v) {
                                                  _vm.checkList = $$v
                                                },
                                                expression: "checkList",
                                              },
                                            },
                                            _vm._l(
                                              _vm.checkBoxList,
                                              function (item, index) {
                                                return _c("el-checkbox", {
                                                  key: index,
                                                  attrs: {
                                                    disabled:
                                                      _vm.infoForm
                                                        .openDataCollection ==
                                                        "2" || _vm.disabled,
                                                    label: item.fieldName,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.infoForm.openDataCollection != "2"
                                    ? _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "按钮:",
                                            prop: "collectionBtn",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              attrs: { disabled: _vm.disabled },
                                              model: {
                                                value:
                                                  _vm.infoForm.collectionBtn,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.infoForm,
                                                    "collectionBtn",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "infoForm.collectionBtn",
                                              },
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "append" },
                                                [
                                                  _c("Upload-img", {
                                                    attrs: {
                                                      data: _vm.infoForm
                                                        .collectionBtn,
                                                      disabled: _vm.disabled,
                                                      "limit-count": 1,
                                                      text: "建议尺寸:（200px*40px)",
                                                    },
                                                    on: {
                                                      "update:data": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.infoForm,
                                                          "collectionBtn",
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            2
                                          ),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "imgbg" }, [
                                            _c(
                                              "div",
                                              { staticClass: "uploadTip" },
                                              [_vm._v("建议尺寸:(200px*40px)")]
                                            ),
                                            _vm._v(" "),
                                            _vm.infoForm.collectionBtn
                                              ? _c(
                                                  "div",
                                                  { staticClass: "showLoadBg" },
                                                  [
                                                    _c("el-image", {
                                                      staticStyle: {
                                                        width: "100px",
                                                        height: "100px",
                                                      },
                                                      attrs: {
                                                        fit: "scale-down",
                                                        src: _vm.infoForm
                                                          .collectionBtn,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              "text-align": "right",
                              "margin-top": "20px",
                              "margin-bottom": "30px",
                            },
                            attrs: { span: 24 },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "normalBtn",
                                on: { click: _vm.goBack },
                              },
                              [_vm._v("取消")]
                            ),
                            _vm._v(" "),
                            _vm.active !== 0 && _vm.active <= _vm.activeMax
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "normalBtn",
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.subForm("up")
                                      },
                                    },
                                  },
                                  [_vm._v("上一步")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.active == 0 || _vm.active < _vm.activeMax
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "normalBtn",
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.subForm("down")
                                      },
                                    },
                                  },
                                  [_vm._v("下一步")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.active == _vm.activeMax
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "normalBtn",
                                    attrs: {
                                      type: "primary",
                                      disabled:
                                        _vm.disabledLoading || _vm.disabled,
                                      loading: _vm.disabledLoading,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.subForm("down")
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                保存\n              "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }