var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.title,
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "show-close": false,
        width: "700px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticStyle: { "padding-right": "20px" },
          attrs: { "label-width": "120px", model: _vm.form, rules: _vm.rules },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "奖品名称:", prop: "prizeName" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入奖品名称",
                  maxlength: "30",
                  disabled: _vm.isDisable,
                },
                model: {
                  value: _vm.form.prizeName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "prizeName", $$v)
                  },
                  expression: "form.prizeName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "字卡:", prop: "word" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入字符",
                  maxlength: "1",
                  disabled: _vm.isDisable,
                },
                model: {
                  value: _vm.form.word,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "word", $$v)
                  },
                  expression: "form.word",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "奖品说明:", prop: "explain" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "集字可获取对应奖品",
                  maxlength: "60",
                  disabled: _vm.isDisable && _vm.title == "详情",
                },
                model: {
                  value: _vm.form.explain,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "explain", $$v)
                  },
                  expression: "form.explain",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "有效截止日期:", prop: "rangeTime" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  disabled: _vm.isDisable,
                  type: "datetimerange",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  format: "yyyy-MM-dd HH:mm:ss",
                  "default-time": ["00:00:00", "23:59:59"],
                },
                model: {
                  value: _vm.form.rangeTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "rangeTime", $$v)
                  },
                  expression: "form.rangeTime",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                disabled: _vm.isDisable,
                loading: _vm.btnLoading,
              },
              on: {
                click: function ($event) {
                  return _vm.handleSave("form")
                },
              },
            },
            [_vm._v("保存")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.handleCancel("form")
                },
              },
            },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }