"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _statisticalForm = require("@/api/terminal/statisticalForm");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = ''; // 缓存名字为当前path去掉/
var _default = {
  name: '',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      formInline: {
        markCode: '',
        validCode: '',
        wechatIdOrOpenId: '',
        productCodeOrName: '',
        terminalCodeOrName: '',
        phoneNo: '',
        authStatus: '',
        rangeTime: '',
        startTime: '',
        endTime: '',
        pageNo: 1,
        pageSize: 10
      },
      tableData: [],
      total: 0,
      listLoading: false,
      tabHeight: '100%',
      // 导出按钮加载
      downLoading: false
    };
  },
  computed: {},
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    var hasCache1 = this.tableData.length > 0;
    if (!hasCache1) {
      this.errorCodeListPage();
    }
  },
  deactivated: function deactivated() {
    var name = cachName;
    var cache = this.getCaches(this, name);
    if (!cache) {
      this.tableData = [];
      this.total = 0;
    }
  },
  methods: {
    // 获取错误数码兼容列表
    errorCodeListPage: function errorCodeListPage() {
      var that = this;
      that.listLoading = true;
      if (this.formInline.rangeTime && this.formInline.rangeTime.length > 0) {
        this.formInline.startTime = this.formInline.rangeTime[0];
        this.formInline.endTime = this.formInline.rangeTime[1];
      } else {
        this.formInline.startTime = '';
        this.formInline.endTime = '';
      }
      var sdata = JSON.parse(JSON.stringify(this.formInline));
      delete sdata.rangeTime;
      (0, _statisticalForm.IntPage)(sdata).then(function (res) {
        console.log(res.data);
        if (res.data.data.errCode) {
          that.listLoading = false;
          that.$message.error(res.data.data.errMsg);
          return;
        } else {
          if (res.data.data != null) {
            that.total = parseInt(res.data.data.total);
            that.tableData = res.data.data.records;
            setTimeout(function () {
              that.listLoading = false;
            }, 1 * 800);
          } else {
            that.total = 0;
            that.tableData = [];
            that.listLoading = false;
          }
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    // 搜索按钮
    onSearch: function onSearch() {
      this.errorCodeListPage();
    },
    // 搜索重置
    onReset: function onReset(formName) {
      this.$refs[formName].resetFields();
      this.errorCodeListPage();
    },
    // 导出
    download: function download() {
      var _this = this;
      this.downLoading = true;
      if (this.formInline.rangeTime && this.formInline.rangeTime.length > 0) {
        this.formInline.startTime = this.formInline.rangeTime[0];
        this.formInline.endTime = this.formInline.rangeTime[1];
      } else {
        this.formInline.startTime = '';
        this.formInline.endTime = '';
      }
      var submitData = JSON.parse(JSON.stringify(this.formInline));
      (0, _statisticalForm.IntExcel)(submitData).then(function (res) {
        _this.downLoading = false;
        if (res.status != 200) {
          _this.$message.error(res.data.msg);
        } else {
          var blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel;charset=utf-8'
          });
          var downloadElement = document.createElement('a');
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = '箱内码日志.xlsx';
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
      }).catch(function () {
        _this.downLoading = false;
      });
    }
  }
};
exports.default = _default;