var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "items" },
    [
      _c(
        "el-row",
        { attrs: { type: "flex", gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 6 } }, [
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "item-head" }, [
                _c("div", { staticClass: "cell" }, [
                  _c("div", { staticClass: "cell-primary" }, [
                    _c("img", {
                      staticClass: "arrowimg",
                      attrs: {
                        src: require("../../../../images/arrowUp1.png"),
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "default-color" }, [
                      _vm._v("8%"),
                    ]),
                    _vm._v(" "),
                    _c("span", [_vm._v("同昨日")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "cell-ft" },
                    [
                      _c(
                        "el-menu",
                        {
                          staticClass: "el-menu-demo",
                          attrs: {
                            "default-active": _vm.activeIndex,
                            mode: "horizontal",
                          },
                          on: { select: _vm.handleSelect },
                        },
                        [
                          _c("el-menu-item", { attrs: { index: "1" } }, [
                            _vm._v("今日"),
                          ]),
                          _vm._v(" "),
                          _c("el-menu-item", { attrs: { index: "2" } }, [
                            _vm._v("本周"),
                          ]),
                          _vm._v(" "),
                          _c("el-menu-item", { attrs: { index: "3" } }, [
                            _vm._v("本月"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "item-body" }, [
                _c("div", { staticClass: "cell" }, [
                  _c("div", { staticClass: "cell-hd" }, [
                    _c("img", {
                      staticClass: "eicon-mid",
                      attrs: { src: require("../../../../images/eicon-1.png") },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "cell-primary pad-20" }, [
                    _c("div", { staticClass: "number" }, [_vm._v("12345678")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "unit" }, [
                      _vm._v("生产量（箱/件）"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "cell-ft" }, [
                    _c("img", {
                      staticClass: "eicon-small",
                      attrs: { src: require("../../../../images/eicon-2.png") },
                    }),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "item-foot" }, [
                _c("div", { staticClass: "align-right" }, [
                  _vm._v("日均"),
                  _c("span", { staticClass: "default-color" }, [
                    _vm._v("6，788"),
                  ]),
                  _vm._v("箱/件"),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 6 } }, [
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "item-head" }, [
                _c("div", { staticClass: "cell" }, [
                  _c("div", { staticClass: "cell-primary" }, [
                    _c("img", {
                      staticClass: "arrowimg",
                      attrs: {
                        src: require("../../../../images/arrowUp1.png"),
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "default-color" }, [
                      _vm._v("8%"),
                    ]),
                    _vm._v(" "),
                    _c("span", [_vm._v("同昨日")]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "item-body" }, [
                _c("div", { staticClass: "cell" }, [
                  _c("div", { staticClass: "cell-hd" }, [
                    _c("img", {
                      staticClass: "eicon-mid",
                      attrs: { src: require("../../../../images/eicon-3.png") },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "cell-primary pad-20" }, [
                    _c("div", { staticClass: "number" }, [_vm._v("12345678")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "unit" }, [
                      _vm._v("库存（箱/件）"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "cell-ft" }, [
                    _c("img", {
                      staticClass: "eicon-small",
                      attrs: { src: require("../../../../images/eicon-2.png") },
                    }),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 6 } }, [
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "item-head" }, [
                _c("div", { staticClass: "cell" }, [
                  _c("div", { staticClass: "cell-primary" }, [
                    _c("img", {
                      staticClass: "arrowimg",
                      attrs: {
                        src: require("../../../../images/arrowDn1.png"),
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "error-color" }, [_vm._v("8%")]),
                    _vm._v(" "),
                    _c("span", [_vm._v("同昨日")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "cell-ft" },
                    [
                      _c(
                        "el-menu",
                        {
                          staticClass: "el-menu-demo",
                          attrs: {
                            "default-active": _vm.activeIndex,
                            mode: "horizontal",
                          },
                          on: { select: _vm.handleSelect },
                        },
                        [
                          _c("el-menu-item", { attrs: { index: "1" } }, [
                            _vm._v("今日"),
                          ]),
                          _vm._v(" "),
                          _c("el-menu-item", { attrs: { index: "2" } }, [
                            _vm._v("本周"),
                          ]),
                          _vm._v(" "),
                          _c("el-menu-item", { attrs: { index: "3" } }, [
                            _vm._v("本月"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "item-body" }, [
                _c("div", { staticClass: "cell" }, [
                  _c("div", { staticClass: "cell-hd" }, [
                    _c("img", {
                      staticClass: "eicon-mid",
                      attrs: { src: require("../../../../images/eicon-5.png") },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "cell-primary pad-20" }, [
                    _c("div", { staticClass: "number" }, [_vm._v("12345678")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "unit" }, [
                      _vm._v("销售量（箱/件）"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "cell-ft" }, [
                    _c("img", {
                      staticClass: "eicon-small",
                      attrs: { src: require("../../../../images/eicon-2.png") },
                    }),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "item-foot" }, [
                _c("div", { staticClass: "align-right" }, [
                  _vm._v("日均"),
                  _c("span", { staticClass: "default-color" }, [
                    _vm._v("6，788"),
                  ]),
                  _vm._v("箱/件"),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 6 } }, [
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "item-head" }, [
                _c("div", { staticClass: "cell" }, [
                  _c("div", { staticClass: "cell-primary" }, [
                    _c("img", {
                      staticClass: "arrowimg",
                      attrs: {
                        src: require("../../../../images/arrowUp1.png"),
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "default-color" }, [
                      _vm._v("8%"),
                    ]),
                    _vm._v(" "),
                    _c("span", [_vm._v("同昨日")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "cell-ft" },
                    [
                      _c(
                        "el-menu",
                        {
                          staticClass: "el-menu-demo",
                          attrs: {
                            "default-active": _vm.activeIndex,
                            mode: "horizontal",
                          },
                          on: { select: _vm.handleSelect },
                        },
                        [
                          _c("el-menu-item", { attrs: { index: "1" } }, [
                            _vm._v("今日"),
                          ]),
                          _vm._v(" "),
                          _c("el-menu-item", { attrs: { index: "2" } }, [
                            _vm._v("本周"),
                          ]),
                          _vm._v(" "),
                          _c("el-menu-item", { attrs: { index: "3" } }, [
                            _vm._v("本月"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "item-body" }, [
                _c("div", { staticClass: "cell" }, [
                  _c("div", { staticClass: "cell-hd" }, [
                    _c("img", {
                      staticClass: "eicon-mid",
                      attrs: { src: require("../../../../images/eicon-4.png") },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "cell-primary pad-20" }, [
                    _c("div", { staticClass: "number" }, [_vm._v("12345678")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "unit" }, [
                      _vm._v("红包发放金额（元）"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "cell-ft" }, [
                    _c("img", {
                      staticClass: "eicon-small",
                      attrs: { src: require("../../../../images/eicon-2.png") },
                    }),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "item-foot" }, [
                _c("div", { staticClass: "align-right" }, [
                  _vm._v("日均"),
                  _c("span", { staticClass: "default-color" }, [
                    _vm._v("6，788"),
                  ]),
                  _vm._v("箱/件"),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }