var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c("h3", [_vm._v("产品信息")]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%", "margin-top": "1px" },
          attrs: {
            size: "mini",
            data: _vm.proList,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { type: "expand" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%", "margin-top": "1px" },
                        attrs: {
                          size: "mini",
                          data: row.children,
                          border: "",
                          fit: "",
                          "highlight-current-row": "",
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            label: "拆分行号",
                            prop: "numSplit",
                            width: "80",
                            align: _vm.tableConfig.align,
                            "show-overflow-tooltip": "",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "产品编码",
                            prop: "prodCodeCode",
                            align: _vm.tableConfig.align,
                            "show-overflow-tooltip": "",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "产品名称",
                            prop: "prodCodeName",
                            align: _vm.tableConfig.align,
                            "show-overflow-tooltip": "",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "计划数量",
                            prop: "sumPlan",
                            align: _vm.tableConfig.align,
                            width: "160",
                            "show-overflow-tooltip": "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("span", [_vm._v(_vm._s(row.sumPlan))]),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "最小单位数量",
                            prop: "sumPlanAto",
                            align: _vm.tableConfig.align,
                            width: "180",
                            "show-overflow-tooltip": "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(row.sumPlanAto)),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _vm._v(" "),
                        _vm.setinfo
                          ? [
                              _c("el-table-column", {
                                attrs: {
                                  label: "完成进度",
                                  width: "140",
                                  align: _vm.tableConfig.align,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var row = ref.row
                                        return [
                                          _c("el-progress", {
                                            attrs: {
                                              "text-inside": true,
                                              "stroke-linecap": "square",
                                              "stroke-width": 16,
                                              percentage: Number(row.progress),
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "扫码操作人",
                                  prop: "progress",
                                  align: _vm.tableConfig.align,
                                  width: "150",
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "上传时间",
                                  prop: "dateCreate",
                                  align: _vm.tableConfig.align,
                                  width: "150",
                                  "show-overflow-tooltip": "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var row = ref.row
                                        return [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("formatDate")(
                                                row.dateCreate
                                              )
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "操作",
                                  align: _vm.tableConfig.align,
                                  width: "180",
                                  fixed: "right",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var row = ref.row
                                        return [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.CodeInfoList(row)
                                                },
                                              },
                                            },
                                            [_vm._v("数码明细")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.anomalousCodeInfoList(
                                                    row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("异常数码")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "行号",
              prop: "numRow",
              align: _vm.tableConfig.align,
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "产品分类",
              prop: "prodClassName",
              align: _vm.tableConfig.align,
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "产品编码",
              prop: "prodCodeCode",
              align: _vm.tableConfig.align,
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "产品名称",
              prop: "prodCodeName",
              align: _vm.tableConfig.align,
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "包装比例",
              prop: "packScaleExpression",
              align: _vm.tableConfig.align,
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "单位",
              prop: "packUnit",
              align: _vm.tableConfig.align,
              width: "50",
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "计划数量",
              prop: "sumPlan",
              align: _vm.tableConfig.align,
              width: "160",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.sumPlan))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "最小单位数量",
              prop: "sumPlanAto",
              align: _vm.tableConfig.align,
              width: "180",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.sumPlanAto))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _vm.setinfo
            ? [
                _c("el-table-column", {
                  attrs: {
                    label: "完成进度",
                    width: "140",
                    align: _vm.tableConfig.align,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("el-progress", {
                              attrs: {
                                "text-inside": true,
                                "stroke-linecap": "square",
                                "stroke-width": 16,
                                percentage: Number(row.progress),
                              },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    4049290661
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "扫码操作人",
                    prop: "progress",
                    align: _vm.tableConfig.align,
                    width: "150",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "上传时间",
                    prop: "dateCreate",
                    align: _vm.tableConfig.align,
                    width: "150",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              _vm._s(_vm._f("formatDate")(row.dateCreate))
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1561096801
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "操作",
                    align: _vm.tableConfig.align,
                    width: "180",
                    fixed: "right",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.CodeInfoList(row)
                                  },
                                },
                              },
                              [_vm._v("数码明细")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.anomalousCodeInfoList(row)
                                  },
                                },
                              },
                              [_vm._v("异常数码")]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1493537613
                  ),
                }),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.showedmolition
            ? _c("el-table-column", {
                attrs: {
                  label: "操作",
                  align: _vm.tableConfig.align,
                  width: "80",
                  fixed: "right",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          row.children.length <= 0
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDemolition(row, 1)
                                    },
                                  },
                                },
                                [_vm._v("拆单")]
                              )
                            : _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDemolition(row, 2)
                                    },
                                  },
                                },
                                [_vm._v("取消拆单")]
                              ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  639552435
                ),
              })
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: "拆单",
            "append-to-body": "",
            visible: _vm.demolitionVisible,
            "before-close": _vm.handleDemolitionClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.demolitionVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formDom",
              attrs: { rules: _vm.formData.rules, model: _vm.formData },
            },
            [
              _c("h3", [_vm._v("计划总数量：" + _vm._s(_vm.formData.nums))]),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%", "margin-top": "1px" },
                  attrs: {
                    size: "mini",
                    data: _vm.formData.tableData,
                    border: "",
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "行号",
                      prop: "numSplit",
                      width: "55",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品编码",
                      prop: "prodCodeCode",
                      width: "100",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品名称",
                      prop: "prodCodeName",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "计划产量(箱)",
                      prop: "sumPlan",
                      align: _vm.tableConfig.align,
                      width: "160",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop:
                                    "tableData." + scope.$index + ".sumPlan",
                                  rules: _vm.formData.rules.sumPlan,
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "计划产量" },
                                  model: {
                                    value: scope.row.sumPlan,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        scope.row,
                                        "sumPlan",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "scope.row.sumPlan",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "计量单位",
                      align: _vm.tableConfig.align,
                      width: "180",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("minSum")(
                                    row.sumPlan,
                                    row.packCodeName,
                                    row
                                  )
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      align: _vm.tableConfig.align,
                      width: "120",
                      fixed: "right",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm.addshow
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDemolition(
                                          scope.row,
                                          3
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("增加")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.$index > 0
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDemolition(
                                          scope.$index,
                                          4
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.btn_loading },
                  on: { click: _vm.DemolitionSave },
                },
                [_vm._v("保 存")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.handleDemolitionClose } }, [
                _vm._v("关 闭"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("code-info", { ref: "CodeInfoListMain", attrs: { num: _vm.num } }),
      _vm._v(" "),
      _c("anomalous-info", {
        ref: "anomalousCodeInfoListMain",
        attrs: { num: _vm.num },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }