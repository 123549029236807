var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "magmanager" } },
    [
      _c(
        "el-container",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.contentload,
              expression: "contentload",
            },
          ],
        },
        [
          _c(
            "div",
            { staticClass: "hyyy-container" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-card",
                        [
                          _c("div", { staticClass: "hyyy-top-tit" }, [
                            _c("div", { staticClass: "hyyy-top-tit-lift" }, [
                              _c("span", {
                                staticStyle: {
                                  "font-weight": "400",
                                  color: "#333",
                                },
                                domProps: {
                                  textContent: _vm._s(_vm.$route.meta.title),
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "hyyy-top-tit-right" },
                              [
                                _c("admincut", {
                                  staticStyle: { "margin-top": "7px" },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("el-divider", { staticStyle: { margin: "0" } }),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            { staticClass: "hyyy-content" },
                            [
                              _c(
                                "el-main",
                                [
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 24 } },
                                        [
                                          _c(
                                            "el-form",
                                            {
                                              staticClass: "demo-form-inline",
                                              attrs: {
                                                inline: true,
                                                model: _vm.formInline,
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "cols" },
                                                [
                                                  _c(
                                                    "el-row",
                                                    { attrs: { gutter: 20 } },
                                                    [
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 6 } },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "用户昵称",
                                                              },
                                                            },
                                                            [
                                                              _c("el-input", {
                                                                attrs: {
                                                                  placeholder:
                                                                    "请输入用户昵称",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .formInline
                                                                      .nickname,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.formInline,
                                                                        "nickname",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "formInline.nickname",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 6 } },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "最新互动时间",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-date-picker",
                                                                {
                                                                  attrs: {
                                                                    size: "large",
                                                                    type: "daterange",
                                                                    align:
                                                                      "right",
                                                                    "unlink-panels":
                                                                      "",
                                                                    "value-format":
                                                                      "yyyy-MM-dd",
                                                                    "range-separator":
                                                                      "-",
                                                                    "start-placeholder":
                                                                      "开始日期",
                                                                    "end-placeholder":
                                                                      "结束日期",
                                                                    "picker-options":
                                                                      _vm.pickerOptions,
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.value2,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.value2 =
                                                                          $$v
                                                                      },
                                                                    expression:
                                                                      "value2",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 6 } },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "回复状态",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-select",
                                                                {
                                                                  attrs: {
                                                                    placeholder:
                                                                      "请选择回复状态",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .formInline
                                                                        .msgStatus,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.formInline,
                                                                          "msgStatus",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "formInline.msgStatus",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-option",
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          "所有消息",
                                                                        value:
                                                                          " ",
                                                                      },
                                                                    }
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "el-option",
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          "未回复",
                                                                        value:
                                                                          "0",
                                                                      },
                                                                    }
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "el-option",
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          "已回复",
                                                                        value:
                                                                          "1",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-col",
                                                        {
                                                          staticClass:
                                                            "hyyy-head-btn",
                                                        },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                "label-width":
                                                                  "0",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  attrs: {
                                                                    slot: "label",
                                                                  },
                                                                  slot: "label",
                                                                },
                                                                [_vm._v(" ")]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "el-button",
                                                                {
                                                                  attrs: {
                                                                    type: "primary",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.onSubmit,
                                                                  },
                                                                },
                                                                [_vm._v("查询")]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "el-button",
                                                                {
                                                                  attrs: {
                                                                    type: "primary",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.onReset,
                                                                  },
                                                                },
                                                                [_vm._v("重置")]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("el-form-item"),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-table",
                                        {
                                          directives: [
                                            {
                                              name: "loading",
                                              rawName: "v-loading",
                                              value: _vm.msgload,
                                              expression: "msgload",
                                            },
                                          ],
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            data: _vm.tableData,
                                            "row-key": _vm.getRowKeys,
                                            "expand-row-keys": _vm.expands,
                                          },
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: { type: "expand" },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function (props) {
                                                  return [
                                                    _c(
                                                      "el-form",
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          [
                                                            _c("tiny-mce", {
                                                              ref: "aaa",
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                              attrs: {
                                                                "tinymce-height": 200,
                                                                plugins:
                                                                  _vm.plugins,
                                                                toolbar:
                                                                  _vm.toolbar,
                                                              },
                                                              on: {
                                                                input: _vm.asdf,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.content[
                                                                    props.$index
                                                                  ].val,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .content[
                                                                        props
                                                                          .$index
                                                                      ],
                                                                      "val",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "content[props.$index].val",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-form-item",
                                                          [
                                                            _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  type: "primary",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.send(
                                                                        props.$index,
                                                                        props.row
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [_vm._v("发送")]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "el-button",
                                                              {
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.packup(
                                                                        props.$index
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [_vm._v("收起")]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "用户昵称",
                                              prop: "nickname",
                                              align: "center",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "标签",
                                              align: "center",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return _vm._l(
                                                    scope.row.tagInfoList,
                                                    function (tag) {
                                                      return _c(
                                                        "el-tag",
                                                        {
                                                          key: tag.tagId,
                                                          staticClass: "tag",
                                                          attrs: {
                                                            "disable-transitions": false,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(tag.tagName)
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  )
                                                },
                                              },
                                            ]),
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "最新消息",
                                              align: "center",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c(
                                                      "el-row",
                                                      [
                                                        scope.row.msgType ==
                                                        "text"
                                                          ? _c("el-col", [
                                                              _vm._v(
                                                                "\n                            " +
                                                                  _vm._s(
                                                                    scope.row
                                                                      .msgContent
                                                                  ) +
                                                                  "\n                          "
                                                              ),
                                                            ])
                                                          : _c(
                                                              "el-col",
                                                              [
                                                                _c(
                                                                  "el-button",
                                                                  {
                                                                    attrs: {
                                                                      type: "primary",
                                                                      size: "mini",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.looks(
                                                                            scope
                                                                              .row
                                                                              .mediaId,
                                                                            scope.row
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "详情"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "回复状态",
                                              align: "center",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c(
                                                      "el-row",
                                                      [
                                                        scope.row.msgStatus == 0
                                                          ? _c("el-col", [
                                                              _vm._v("未回复"),
                                                            ])
                                                          : _c("el-col", [
                                                              _vm._v("已回复"),
                                                            ]),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "最新回复时间",
                                              align: "center",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c(
                                                      "el-row",
                                                      [
                                                        _c("el-col", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm._f(
                                                                "filterTime"
                                                              )(
                                                                scope.row
                                                                  .createTime
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "操作",
                                              align: "center",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: { size: "mini" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleEdit(
                                                              scope.row.userId,
                                                              scope.row
                                                                .nickname,
                                                              scope.row
                                                                .tagInfoList
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("详情")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          type: "primary",
                                                          size: "mini",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.unfold(
                                                              scope.$index,
                                                              scope.row
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("回复消息")]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("el-pagination", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.total > 0,
                                            expression: "total > 0",
                                          },
                                        ],
                                        staticStyle: { "text-align": "left" },
                                        attrs: {
                                          background: "",
                                          "current-page": _vm.formInline.pageNo,
                                          "page-size": _vm.formInline.pageSize,
                                          layout: "total,prev, pager, next",
                                          total: _vm.total,
                                        },
                                        on: {
                                          "current-change":
                                            _vm.handleCurrentChange,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "msginfo",
          attrs: {
            title: "消息详情",
            visible: _vm.dialogVisible,
            width: "50%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "span",
            [
              _c("h3", [_vm._v("基础信息")]),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _vm._v("用户信息：" + _vm._s(_vm.username)),
                  ]),
                  _vm._v(" "),
                  _vm.tags.length > 0
                    ? _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _vm._v("\n          标签：\n          "),
                          _vm._l(_vm.tags, function (tag) {
                            return _c(
                              "el-tag",
                              {
                                key: tag.tagId,
                                staticClass: "tag",
                                attrs: { "disable-transitions": false },
                              },
                              [_vm._v(_vm._s(tag.tagName))]
                            )
                          }),
                        ],
                        2
                      )
                    : _c("el-col", [_vm._v("标签：无标签")]),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c("h3", [_vm._v("消息轨迹：")]),
              _vm._v(" "),
              _c(
                "div",
                { ref: "main", staticClass: "rowhei" },
                _vm._l(_vm.msglist, function (item) {
                  return _c(
                    "el-row",
                    {
                      key: item.id,
                      ref: "content",
                      refInFor: true,
                      staticClass: "msginfolist",
                    },
                    [
                      _c("el-col", { attrs: { span: 9 } }, [
                        _vm._v(_vm._s(_vm._f("filterTime")(item.createTime))),
                      ]),
                      _vm._v(" "),
                      item.msgSource == "2" || item.msgSource == "3"
                        ? _c("el-col", { attrs: { span: 10 } }, [
                            _vm._v("\n            公众号：\n            "),
                            item.msgType == "text"
                              ? _c("span", [_vm._v(_vm._s(item.msgContent))])
                              : _c(
                                  "span",
                                  [
                                    _c(
                                      "el-link",
                                      _vm._b(
                                        { attrs: { type: "primary" } },
                                        "el-link",
                                        {
                                          href: _vm.twImgurl + item.msgContent,
                                        },
                                        false
                                      ),
                                      [_vm._v("预览")]
                                    ),
                                  ],
                                  1
                                ),
                          ])
                        : _c("el-col", { attrs: { span: 10 } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.nickName) +
                                ":\n            "
                            ),
                            item.msgType == "text"
                              ? _c("span", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.msgContent) +
                                      "\n            "
                                  ),
                                ])
                              : _c("span", [
                                  new Date() - new Date(item.createTime) >
                                  3600 * 1000 * 24 * 3
                                    ? _c("span", [
                                        _c("span", [
                                          _vm._v(
                                            "【不支持查看超过三天的语音，图片，视频】"
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "disab" }),
                                      ])
                                    : _c(
                                        "span",
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "primary",
                                                size: "mini",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.looks(
                                                    item.mediaId,
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("详情")]
                                          ),
                                        ],
                                        1
                                      ),
                                ]),
                          ]),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }