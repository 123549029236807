"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _select = require("@/api/warehouse/select");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'TablePackset',
  components: {
    Pagination: _Pagination.default
  },
  props: {
    typeSelVisible: Boolean,
    orderType: {
      type: [Number, String],
      default: 1
    }
  },
  data: function data() {
    return {
      listLoading: false,
      mloading: false,
      tableKey: '111',
      tabHeight: '100%',
      searchForm: {
        current: 1,
        size: 10,
        orderType: this.orderType
      },
      tableData: [],
      total: 0,
      typeList: [{
        name: '其他入库',
        id: 1
      }, {
        name: '其他出库',
        id: 2
      }]
    };
  },
  mounted: function mounted() {
    var that = this;
    that.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      var that = this;
      this.listLoading = true;
      var submitData = (0, _utils.filterKeyNull)(that.searchForm);
      (0, _select.getYwTypes)(submitData).then(function (res) {
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            that.tableData = res.data.data.records;
            that.total = Number(res.data.data.total);
          } else {
            that.total = 0;
            that.tableData = [];
          }
        }
        _this.listLoading = false;
      });
    },
    resetForm: function resetForm(formName) {
      this.searchForm = {
        current: 1,
        size: 20,
        orderType: 1
      };
      this.getList();
    },
    selCancel: function selCancel() {
      this.resetForm();
      this.$emit('handerClose');
    },
    submit: function submit(row) {
      this.$emit('change', row);
      this.selCancel();
    }
  }
};
exports.default = _default;