var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mod-prod-sku-table" },
    [
      _c(
        "el-form-item",
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%", "margin-top": "20px" },
              attrs: {
                data: _vm.value,
                border: "",
                "header-cell-style": {
                  "background-color": "#fafafa",
                  color: "rgba(0, 0, 0,0.85)",
                  "font-weight": "500",
                },
                "span-method": _vm.tableSpanMethod,
              },
            },
            [
              _vm.tableLeftTitles.length
                ? _c("el-table-column", {
                    attrs: { prop: "pic", label: "图片", width: "180" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("Upload-img", {
                                ref: "imgs" + scope.$index,
                                attrs: {
                                  disabled: _vm.disabled,
                                  data: scope.row.pic,
                                  text: "",
                                },
                                on: {
                                  "update:data": function ($event) {
                                    return _vm.$set(scope.row, "pic", $event)
                                  },
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2299976029
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.tableLeftTitles, function (leftTitle, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: { label: leftTitle },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              _vm._s(
                                scope.row.properties
                                  .split(";")
                                  [index].split(":")[1]
                              )
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
              _vm._v(" "),
              _vm.tableLeftTitles.length
                ? _c("el-table-column", {
                    attrs: {
                      prop: "prodName",
                      label: "商品名称",
                      width: "200",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.disabled,
                                  type: "textarea",
                                },
                                model: {
                                  value: scope.row.prodName,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "prodName", $$v)
                                  },
                                  expression: "scope.row.prodName",
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3315238089
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "stocks", label: "市场价" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          directives: [
                            {
                              name: "only-number",
                              rawName: "v-only-number",
                              value: {
                                min: 0,
                                precision: 2,
                                set: scope.row,
                                name: "oriPrice",
                              },
                              expression:
                                "{ min:0,precision:2,set: scope.row, name:'oriPrice' }",
                            },
                          ],
                          attrs: { disabled: _vm.disabled, maxlength: "10" },
                          model: {
                            value: scope.row.oriPrice,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "oriPrice", $$v)
                            },
                            expression: "scope.row.oriPrice",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "price", label: "积分" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: scope.row.price,
                              expression: "scope.row.price",
                            },
                            {
                              name: "only-number",
                              rawName: "v-only-number",
                              value: {
                                min: 1,
                                precision: 0,
                                set: scope.row,
                                name: "price",
                              },
                              expression:
                                "{ min:1,precision:0,set: scope.row, name:'price' }",
                            },
                          ],
                          staticClass: "el-input__inner",
                          attrs: {
                            type: "text",
                            disabled: _vm.disabled,
                            maxlength: "10",
                          },
                          domProps: { value: scope.row.price },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(scope.row, "price", $event.target.value)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "jejg", label: "所需金额" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          attrs: { disabled: _vm.disabled, maxlength: "10" },
                          on: {
                            change: function (e) {
                              return (scope.row.jejg = _vm.numFun(e))
                            },
                          },
                          model: {
                            value: scope.row.jejg,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "jejg", $$v)
                            },
                            expression: "scope.row.jejg",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "stocks", label: "库存" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          directives: [
                            {
                              name: "only-number",
                              rawName: "v-only-number",
                              value: {
                                min: 1,
                                precision: 0,
                                set: scope.row,
                                name: "stocks",
                              },
                              expression:
                                "{ min:1,precision:0,set: scope.row, name:'stocks' }",
                            },
                          ],
                          attrs: { disabled: _vm.disabled, maxlength: "10" },
                          model: {
                            value: scope.row.stocks,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "stocks", $$v)
                            },
                            expression: "scope.row.stocks",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "weight",
                  label: "商品重量(kg)",
                  "min-width": "80",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          directives: [
                            {
                              name: "only-number",
                              rawName: "v-only-number",
                              value: {
                                min: 0,
                                precision: 2,
                                set: scope.row,
                                name: "weight",
                              },
                              expression:
                                "{ min:0,precision:2,set: scope.row, name:'weight' }",
                            },
                          ],
                          attrs: { disabled: _vm.disabled, maxlength: "10" },
                          model: {
                            value: scope.row.weight,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "weight", $$v)
                            },
                            expression: "scope.row.weight",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              !_vm.disabled
                ? _c("el-table-column", {
                    attrs: { label: "操作" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.status
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeSkuStatus(
                                            "" + scope.$index
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("禁用")]
                                  )
                                : _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeSkuStatus(
                                            "" + scope.$index
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("启用")]
                                  ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2270951142
                    ),
                  })
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }