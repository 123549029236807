"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _tinyMce = _interopRequireDefault(require("@/components/tiny-mce"));
var _protocol = require("@/api/generalSettings2/protocol");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    TinyMce: _tinyMce.default
  },
  data: function data() {
    return {
      title: '新增终端协议',
      type: '',
      id: '',
      dialogFormVisible: false,
      ruleForm: {
        agreementName: '',
        type: '1',
        signScenario: '1',
        signNum: '2',
        remark: '',
        loginNotify: '1',
        content: ''
      },
      rules: {
        agreementName: [{
          required: true,
          message: '协议名称不能为空',
          trigger: 'blur'
        }],
        type: [{
          required: true,
          message: '请选择协议类型',
          trigger: 'change'
        }],
        signScenario: [{
          required: true,
          message: '请选择签署场景',
          trigger: 'change'
        }],
        signNum: [{
          required: true,
          message: '请选择签署次数',
          trigger: 'change'
        }],
        content: [{
          required: true,
          message: '协议正文不能为空',
          trigger: 'change'
        }]
      }
    };
  },
  mounted: function mounted() {},
  methods: {
    upDateShow: function upDateShow(type, data) {
      var _this = this;
      this.dialogFormVisible = true;
      this.type = type;
      if (type == 'add') {
        this.id = '';
        this.title = '新增终端协议';
        this.$nextTick(function () {
          _this.ruleForm = {
            agreementName: '',
            type: '1',
            signScenario: '1',
            signNum: '2',
            remark: '',
            loginNotify: '1',
            content: ''
          };
        });
      } else if (type == 'edit') {
        this.title = '编辑终端协议';
        this.id = data.agreementId;
        this.ruleForm = {
          agreementName: data.agreementName,
          type: data.type,
          signScenario: '1',
          signNum: data.signNum,
          remark: data.remark,
          loginNotify: data.loginNotify,
          content: data.content
        };
      }
      // console.log(this.ruleForm.content, '0111')
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (_this2.type == 'add') {
            (0, _protocol.postCreate)(_this2.ruleForm).then(function (res) {
              console.log(res.data);
              if (res.data.data.errCode == 500) {
                _this2.$message.error(res.data.data.errMsg);
                return;
              } else {
                if (res.data.data.code == 200) {
                  _this2.$message.success(res.data.data.msg);
                  _this2.resetForm();
                  // this.dialogFormVisible = false
                  _this2.$parent.getwechatQueryList();
                }
              }
            }).catch(function (err) {
              console.log(err);
            });
          } else if (_this2.type == 'edit') {
            (0, _protocol.postUpdate)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, _this2.ruleForm), {}, {
              agreementId: _this2.id
            })).then(function (res) {
              console.log(res.data);
              if (res.data.data.errCode == 500) {
                _this2.$message.error(res.data.data.errMsg);
                return;
              } else {
                if (res.data.data.code == 200) {
                  _this2.$message.success(res.data.data.msg);
                  _this2.resetForm();
                  // this.dialogFormVisible = false
                  _this2.$parent.getwechatQueryList();
                }
              }
            }).catch(function (err) {
              console.log(err);
            });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm: function resetForm(formName) {
      this.ruleForm = {
        agreementName: '',
        type: '1',
        signScenario: '1',
        signNum: '2',
        remark: '',
        loginNotify: '1',
        content: ''
      };
      this.dialogFormVisible = false;
    }
  }
};
exports.default = _default;