"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addDataDictionary = addDataDictionary;
exports.addDataDictionaryItem = addDataDictionaryItem;
exports.delDataDictionary = delDataDictionary;
exports.delDataDictionaryItem = delDataDictionaryItem;
exports.getDataDictionaryItemList = getDataDictionaryItemList;
exports.getDataDictionaryList = getDataDictionaryList;
exports.getItemByDataDictionaryByCode = getItemByDataDictionaryByCode;
exports.regainDataDictionary = regainDataDictionary;
exports.thoroughDelDataDictionary = thoroughDelDataDictionary;
exports.updateDataDictionary = updateDataDictionary;
exports.updateDataDictionaryItem = updateDataDictionaryItem;
var _request = _interopRequireDefault(require("@/utils/request"));
// 数据字典列表
function getDataDictionaryList(params) {
  return (0, _request.default)({
    url: '/api-user/dataDictionary/getDataDictionaryList',
    method: 'get',
    params: params
  });
}

// 添加数据字典
function addDataDictionary(data) {
  return (0, _request.default)({
    url: '/api-user/dataDictionary/addDataDictionary',
    method: 'post',
    data: data
  });
}

// 修改数据字典
function updateDataDictionary(data) {
  return (0, _request.default)({
    url: '/api-user/dataDictionary/updateDataDictionary',
    method: 'post',
    data: data
  });
}

// 数据字典删除
function delDataDictionary(params) {
  return (0, _request.default)({
    url: '/api-user/dataDictionary/delDataDictionary',
    method: 'delete',
    params: params
  });
}

// 数据字典取回
function regainDataDictionary(params) {
  return (0, _request.default)({
    url: '/api-user/dataDictionary/regainDataDictionary',
    method: 'put',
    params: params
  });
}

// 数据字典彻底删除
function thoroughDelDataDictionary(params) {
  return (0, _request.default)({
    url: '/api-user/dataDictionary/thoroughDelDataDictionary',
    method: 'delete',
    params: params
  });
}

// 数据字典详情列表查询
function getDataDictionaryItemList(params) {
  return (0, _request.default)({
    url: '/api-user/dataDictionaryItem/getDataDictionaryItemList',
    method: 'get',
    params: params
  });
}

// 数据字典详情列表查询
function getItemByDataDictionaryByCode(params) {
  return (0, _request.default)({
    url: '/api-user/dataDictionaryItem/getItemByDataDictionaryByCode',
    method: 'get',
    params: params
  });
}

// 添加数据字典详情
function addDataDictionaryItem(data) {
  return (0, _request.default)({
    url: '/api-user/dataDictionaryItem/addDataDictionaryItem',
    method: 'post',
    data: data
  });
}

// 修改数据字典详情
function updateDataDictionaryItem(data) {
  return (0, _request.default)({
    url: '/api-user/dataDictionaryItem/updateDataDictionaryItem',
    method: 'post',
    data: data
  });
}

// 数据字典详情删除
function delDataDictionaryItem(params) {
  return (0, _request.default)({
    url: '/api-user/dataDictionaryItem/delDataDictionaryItem',
    method: 'delete',
    params: params
  });
}