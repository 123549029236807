"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _ordergl = require("@/api/warehouse/ordergl");
var _dictionary = require("@/api/systems/dictionary");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var TypeDataComs = {
  orderStatus: []
};
var _default = {
  name: 'Order',
  filters: {
    purchaseComOwnerType: function purchaseComOwnerType(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          1: '渠道客户',
          2: '团购客户',
          3: '销售公司'
        };
        return statusMap[value];
      }
    },
    auditNodeName: function auditNodeName(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          2: '确款',
          1: '预审'
        };
        return statusMap[value];
      }
    },
    payWayText: function payWayText(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          2: '先货后款',
          1: '先款后货'
        };
        return statusMap[value];
      }
    },
    orderStatusText: function orderStatusText(value) {
      var name = '';
      TypeDataComs.orderStatus.forEach(function (c) {
        if (c.figure == value) {
          name = c.name;
          return;
        }
      });
      return name;
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      payWay: [{
        name: '先款后货',
        code: 1
      }, {
        name: '先货后款',
        code: 2
      }],
      orderStatus: [],
      // 发货单位所属组织id
      sendComId: '',
      productList: [],
      storeList: [],
      addData: {
        pid: '',
        orderType: 2,
        orderNo: '',
        placeOrderDate: '',
        paymentStyle: '',
        purchaseComId: '',
        purchaseComType: 3,
        applyUser: '',
        receiveComId: '',
        receiveComType: '',
        receiveContacts: '',
        receivePhone: '',
        receiveAddress: '',
        sendOutComId: '',
        sendOutComType: '',
        orderStatus: 1,
        remark: '',
        // 产品列表
        orderDetailDTOS: []
      },
      loading: false
    };
  },
  mounted: function mounted() {
    this.getDirSel('CCXSDDZT', 'orderStatus');
    this.getDetailData(this.id);
  },
  methods: {
    getDirSel: function getDirSel(code, list) {
      var _this = this;
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: code
      }).then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this[list] = res.data.data;
            TypeDataComs[list] = res.data.data;
          } else {
            _this[list] = [];
            TypeDataComs[list] = [];
          }
        }
      });
    },
    tableRowClassName: function tableRowClassName(_ref) {
      var row = _ref.row,
        rowIndex = _ref.rowIndex;
      if (row.bottleNum == row.actualBottleNum) {
        console.log(999);
        return 'warning-row';
      }
      return '';
    },
    maxNumX: function maxNumX(row) {
      var num = 0;
      var hasTakeNum = row.actualChestNum || 0;
      var outNum = row.storeInventory || 0;
      num = row.chestNum - hasTakeNum;
      // outNum = 50
      if (row.isComProd == 2) {
        console.log(111);
        return num;
      } else {
        return Math.min(num, outNum);
      }
    },
    mixNumP: function mixNumP(row) {
      var num = row.takeGoodNum || 0;
      var arr = row.packScaleExpression.split('*');
      return num * arr[arr.length - 1];
    },
    maxNumP: function maxNumP(row) {
      var arr = row.packScaleExpression.split('*');
      var num = 0;
      var hasTakeNum = row.actualBottleNum || 0;
      var outNum = row.storeInventory * arr[arr.length - 1] || 0;
      num = row.bottleNum - hasTakeNum;
      if (row.isComProd == 2) {
        console.log(222);
        return num;
      } else {
        return Math.min(num, outNum);
      }
    },
    takeGoodNumChange: function takeGoodNumChange(currentValue, oldValue, row) {
      var arr = row.packScaleExpression.split('*');
      row.takeGoodAtoNum = currentValue * arr[arr.length - 1];
    },
    getStoreList: function getStoreList(pid) {
      var _this2 = this;
      (0, _ordergl.byOrgFactoryId)({
        orgOrFactoryId: pid
      }).then(function (res) {
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this2.storeList = res.data.data;
          } else {
            _this2.storeList = [];
          }
        }
      });
    },
    setStore: function setStore(val) {
      var _this3 = this;
      var _loop = function _loop(i) {
        (0, _ordergl.getCurrentInventory)({
          productId: _this3.productList[i].pId,
          sendStoreId: val,
          sendComId: _this3.sendComId
        }).then(function (res) {
          _this3.productList[i].storeInventory = res.data.data || 0;
          _this3.resetList();
        });
      };
      for (var i = 0; i < this.productList.length; i++) {
        _loop(i);
      }
    },
    resetList: function resetList() {
      this.productList = JSON.parse(JSON.stringify(this.productList));
    },
    // 获取详情数据
    getDetailData: function getDetailData(id) {
      var _this4 = this;
      this.loading = true;
      (0, _ordergl.extractDetailById)(id).then(function (res) {
        if (res.data.code == 200) {
          var data = res.data.data;
          var proArr = [];
          _this4.addData = res.data.data;
          data.orderDetailDTOS.forEach(function (item) {
            var obj = {
              pId: item.productId,
              productCode: item.productCode,
              productName: item.productName,
              productModel: item.productSpec,
              packScaleExpression: item.packRaidoExpress,
              chestNum: item.chestNum,
              bottleNum: item.bottleNum,
              extractNum: item.extractNum,
              actualChestNum: item.actualChestNum,
              actualBottleNum: item.actualBottleNum,
              isComProd: item.isComProd
            };
            if (obj.bottleNum != obj.actualBottleNum) {
              proArr.unshift(obj);
            } else {
              proArr.push(obj);
            }
          });
          _this4.productList = proArr;
          _this4.addData.sendOutStoreId = '';
          _this4.purchaseComName = data.purchaseComName;
          _this4.receiveComName = data.receiveComName;
          _this4.sendOutComName = data.sendOutComName;
          _this4.getStoreList(data.sendOutComId);
          _this4.sendComId = data.sendOutComId;
        } else {
          _this4.$message.error(res.data.msg);
        }
        _this4.loading = false;
      }).catch(function () {
        _this4.loading = false;
      });
      // .then(() => {
      //   if (this.addData.orderStatus > 1) {
      //     this.auditLoading = true
      //     getAuditLog({ pId: id })
      //       .then(res => {
      //         if (res.data.code == 200) {
      //           this.auditList = res.data.data
      //         } else {
      //           that.$message.error('审核日志获取失败')
      //         }
      //         this.auditLoading = false
      //       })
      //       .catch(() => {
      //         this.auditLoading = false
      //       })
      //   }
      // })
    },
    handleClose: function handleClose() {
      this.$emit('close');
    },
    change: function change() {
      this.$emit('change');
    },
    take: function take() {
      var _this5 = this;
      this.loading = true;
      var arr = [];
      var sum = 0;
      if (!this.addData.sendOutStoreId) {
        this.$message.error('请选择发货库房');
        this.loading = false;
        return;
      }
      for (var i = 0; i < this.productList.length; i++) {
        this.productList[i].takeGoodAtoNum = this.productList[i].takeGoodAtoNum || 0;
        sum += this.productList[i].takeGoodAtoNum;
        // if (
        //   !this.productList[i].extractNum &&
        //   this.productList[i].hasTakeNum != this.productList[i].chestNum
        // ) {
        //   // if (!this.productList[i].extractNum) {
        //   this.$message.error(
        //     '在产品明细列表第' + (i + 1) + '行请输入数量且不能为0'
        //   )
        //   return
        // }
        if (this.productList[i].takeGoodAtoNum) {
          // var aNum = this.productList[i].packScaleExpression.split('*')
          var obj = {
            takeGoodAtoNum: this.productList[i].takeGoodAtoNum,
            takeGoodNum: this.productList[i].takeGoodNum || 0,
            productId: this.productList[i].pId
          };
          arr.push(obj);
        }
      }
      if (sum == 0) {
        this.$message.error('产品提货量不能全部为0');
        this.loading = false;
        return;
      }
      (0, _ordergl.takeGoodsForOrder)({
        orderNo: this.addData.orderNo,
        orderProdAOS: arr,
        sendStoreId: this.addData.sendOutStoreId
      }).then(function (res) {
        _this5.loading = false;
        if (res.data.code != 200) {
          _this5.$message.error(res.data.msg);
          return;
        }
        _this5.$message({
          type: 'success',
          message: '提货成功'
        });
        _this5.$emit('change');
      }).catch(function () {
        _this5.loading = false;
      });
    }
  }
};
exports.default = _default;