var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        { staticClass: "page-section" },
        [
          _c(
            "el-form",
            {
              ref: "formInline",
              staticClass: "search-condition",
              attrs: {
                model: _vm.formInline,
                "status-icon": "",
                "label-position": _vm.formConfig.labelPosition,
                "label-width": "100px",
              },
            },
            [
              _c(
                "div",
                { staticClass: "cols" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "searchField",
                                label: "关键词搜索:",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "filter-item",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  clearable: "",
                                  placeholder: "手机号|用户ID|姓名|openid",
                                },
                                model: {
                                  value: _vm.formInline.searchField,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formInline,
                                      "searchField",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "formInline.searchField",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "searchField",
                                label: "用户昵称:",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "filter-item",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  clearable: "",
                                  placeholder: "请输入用户昵称",
                                },
                                model: {
                                  value: _vm.formInline.nickName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formInline,
                                      "nickName",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "formInline.nickName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "status", label: "状态:" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择",
                                  },
                                  model: {
                                    value: _vm.formInline.status,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formInline, "status", $$v)
                                    },
                                    expression: "formInline.status",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    key: "0",
                                    attrs: { label: "全部", value: "" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    key: "1",
                                    attrs: { label: "正常", value: "0" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    key: "2",
                                    attrs: { label: "限制", value: "1" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    key: "3",
                                    attrs: { label: "黑名单", value: "2" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "创建时间:" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "daterange",
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  "range-separator": "至",
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期",
                                  clearable: "",
                                },
                                on: { change: _vm.sj },
                                model: {
                                  value: _vm.rangeTime,
                                  callback: function ($$v) {
                                    _vm.rangeTime = $$v
                                  },
                                  expression: "rangeTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.formConfig.btnFollow
                        ? _c(
                            "el-col",
                            {
                              staticClass: "head-btn-group",
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { "label-width": "0" } },
                                [
                                  _c(
                                    "div",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [_vm._v(" ")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.searchBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.searchBtn.icon,
                                      },
                                      on: { click: _vm.onSearch },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.btnConfig.searchBtn.text)
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.resetBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.resetBtn.icon,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onReset("formInline")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.btnConfig.resetBtn.text)
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("el-divider", { staticClass: "btnDivider" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "phead" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: _vm.btnConfig.downLoadBtn.type,
                            size: _vm.btnConfig.size,
                            icon: _vm.btnConfig.downLoadBtn.icon,
                            loading: _vm.downLoading,
                          },
                          on: { click: _vm.download },
                        },
                        [_vm._v(_vm._s(_vm.btnConfig.downLoadBtn.text))]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: _vm.btnConfig.upLoadBtn.type,
                            size: _vm.btnConfig.size,
                            icon: _vm.btnConfig.upLoadBtn.icon,
                          },
                          on: { click: _vm.uploadShow },
                        },
                        [_vm._v(_vm._s(_vm.btnConfig.upLoadBtn.text))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                height: _vm.tabHeight,
                data: _vm.tableData,
                border: _vm.tableConfig.border,
                stripe: _vm.tableConfig.stripe,
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  index: _vm.getTableItemIndex,
                  label: "序号",
                  width: "80",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "headimgurl", label: "头像", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-image", {
                          staticStyle: { width: "70px", height: "70px" },
                          attrs: { src: scope.row.headimgurl, fit: "cover" },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "wechatName",
                  label: "用户昵称",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "memberId",
                  label: "用户ID",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "ipAddress",
                  label: "最近ip",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "phoneNo",
                  label: "手机号",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "userName",
                  label: "姓名",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "status",
                  label: "状态",
                  width: "90",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.status == 0
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v("正常"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        row.status == 1
                          ? _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v("限制"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        row.status == 2
                          ? _c("el-tag", { attrs: { type: "info" } }, [
                              _vm._v("黑名单"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "activityTimes",
                  label: "抽奖次数",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "dayLimitDraw",
                  label: "单人每天抽奖上限",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "sourceName",
                  label: "来源",
                  "show-overflow-tooltip": "",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "280",
                  fixed: "right",
                  align: _vm.tableConfig.align,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "a-btn",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDetail(scope.row)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "a-btn",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.Switchstate(scope.row, 1)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                scope.row.status == 1 ? "取消限制" : "限制"
                              )
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "a-btn",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.Switchstate(scope.row, 2)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                scope.row.status == 2 ? "取消黑名单" : "黑名单"
                              )
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              page: _vm.params.pageNo,
              limit: _vm.params.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.params, "pageNo", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.params, "pageSize", $event)
              },
              pagination: _vm.getUserPage,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "限制用户权限设置",
            visible: _vm.dialogRestrict,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogRestrict = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form } },
            [
              _c(
                "el-row",
                { staticStyle: { "padding-left": "40px" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-checkboxgu",
                            {
                              model: {
                                value: _vm.form.checked1,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "checked1", $$v)
                                },
                                expression: "form.checked1",
                              },
                            },
                            [_vm._v("启用单日限制中奖次数")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c("el-input", {
                            staticStyle: { width: "120px" },
                            model: {
                              value: _vm.form.number1,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "number1", $$v)
                              },
                              expression: "form.number1",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticStyle: { "padding-left": "40px" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-checkbox",
                            {
                              model: {
                                value: _vm.form.checked2,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "checked2", $$v)
                                },
                                expression: "form.checked2",
                              },
                            },
                            [_vm._v("启用单日限制中奖次数")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c("el-input", {
                            staticStyle: { width: "120px" },
                            model: {
                              value: _vm.form.number2,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "number2", $$v)
                              },
                              expression: "form.number2",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticStyle: { "padding-left": "40px" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-checkbox",
                            {
                              model: {
                                value: _vm.form.checked3,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "checked3", $$v)
                                },
                                expression: "form.checked3",
                              },
                            },
                            [_vm._v("启用单日限制中奖次数")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogRestrict = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogRestrict = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "tabs",
          attrs: {
            title: _vm.title,
            visible: _vm.dialogVisible,
            width: "1000px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-tabs",
            {
              attrs: { type: "card" },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "用户管理", name: "first" } },
                [
                  _c(
                    "el-form",
                    {
                      staticClass: "search-condition",
                      attrs: {
                        inline: true,
                        model: _vm.salesmanFrom,
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "用户ID :" } },
                                [
                                  _c("div", [
                                    _vm._v(_vm._s(_vm.salesmanFrom.memberId)),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "用户姓名 :" } },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: _vm.isEdit },
                                    model: {
                                      value: _vm.salesmanFrom.userName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.salesmanFrom,
                                          "userName",
                                          $$v
                                        )
                                      },
                                      expression: "salesmanFrom.userName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "扫码记录数 :" } },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.salesmanFrom.activityTimes) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "unionID :" } },
                                [
                                  _c("div", [
                                    _vm._v(_vm._s(_vm.salesmanFrom.unionId)),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "用户手机号 :" } },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: _vm.isEdit },
                                    model: {
                                      value: _vm.salesmanFrom.phoneNo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.salesmanFrom,
                                          "phoneNo",
                                          $$v
                                        )
                                      },
                                      expression: "salesmanFrom.phoneNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "微信昵称 :" } },
                                [
                                  _c("div", [
                                    _vm._v(_vm._s(_vm.salesmanFrom.wechatName)),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "最近IP :" } },
                                [
                                  _c("div", [
                                    _vm._v(_vm._s(_vm.salesmanFrom.ipAddress)),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "关联openid :" } },
                        [
                          _c("el-input", {
                            attrs: { type: "textarea", disabled: "" },
                            model: {
                              value: _vm.salesmanFrom.openId,
                              callback: function ($$v) {
                                _vm.$set(_vm.salesmanFrom, "openId", $$v)
                              },
                              expression: "salesmanFrom.openId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.cancel()
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _vm.editType != "preview"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.confirm()
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.mloading,
              expression: "mloading",
            },
          ],
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: "批量导入——活动用户管理",
            visible: _vm.uploadVisible,
            "before-close": _vm.uploadClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.uploadVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "uploadForm",
              attrs: {
                inline: true,
                model: _vm.uploadForm,
                rulse: _vm.uploadRules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "下载模板", prop: "orgName" } },
                [
                  _c(
                    "a",
                    {
                      staticClass: "down",
                      attrs: {
                        href: "/Excel/hdyhgl.xlsx",
                        target: "_blank",
                        download: "活动用户管理.xlsx",
                      },
                    },
                    [_vm._v("活动用户管理导入Excel模板")]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "orgName", label: "文件上传" } },
                [
                  _c(
                    "el-row",
                    { staticClass: "head-btn-group", attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-input", {
                            attrs: { disabled: true },
                            model: {
                              value: _vm.uploadForm.uploadFile,
                              callback: function ($$v) {
                                _vm.$set(_vm.uploadForm, "uploadFile", $$v)
                              },
                              expression: "uploadForm.uploadFile",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-upload",
                            {
                              ref: "upload",
                              staticClass: "upload-demo",
                              attrs: {
                                headers: _vm.myHeaders,
                                action: _vm.uploadUrl,
                                limit: 1,
                                name: "file",
                                accept: ".xls, .xlsx",
                                "file-list": _vm.fileList,
                                "show-file-list": false,
                                "auto-upload": false,
                                "on-change": _vm.selectFile,
                                "on-success": _vm.uploadFileRes,
                                "on-error": _vm.uploadFileFail,
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    slot: "trigger",
                                    size: "mini",
                                    type: "primary",
                                  },
                                  slot: "trigger",
                                },
                                [_vm._v("打开")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "primary" },
                                  on: { click: _vm.submitUpload },
                                },
                                [_vm._v("导入")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "活动用户管理详情",
            visible: _vm.look,
            width: "1000px !important",
          },
          on: {
            "update:visible": function ($event) {
              _vm.look = $event
            },
            close: _vm.handleClose,
          },
        },
        [
          _c("div", { staticClass: "header" }, [_vm._v("基础信息")]),
          _vm._v(" "),
          _c("div", { staticClass: "box" }, [
            _c(
              "div",
              { staticStyle: { "margin-right": "15px" } },
              [
                _c("el-image", {
                  staticStyle: { width: "100px", height: "100px" },
                  attrs: { src: _vm.salesmanFrom.headimgurl, fit: "cover" },
                }),
                _vm._v(" "),
                false
                  ? _c("div", { staticClass: "text" }, [_vm._v("头像")])
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "el-row",
                  {
                    staticStyle: { "min-height": "150px" },
                    attrs: { gutter: 20 },
                  },
                  [
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c("div", { staticClass: "vue-from-block" }, [
                        _c("div", { staticClass: "form-div form-div-label" }, [
                          _vm._v("微信昵称 ："),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "content" }, [
                          _vm._v(_vm._s(_vm.salesmanFrom.wechatName)),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c(
                        "div",
                        { staticClass: "vue-from-block" },
                        [
                          _c(
                            "div",
                            { staticClass: "form-div form-div-label" },
                            [
                              _vm._v(
                                "\n                状态 ：\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.salesmanFrom.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.salesmanFrom, "status", $$v)
                                },
                                expression: "salesmanFrom.status",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                { attrs: { label: 0, disabled: "" } },
                                [_vm._v("正常")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio",
                                { attrs: { label: 1, disabled: "" } },
                                [_vm._v("限制")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio",
                                { attrs: { label: 2, disabled: "" } },
                                [_vm._v("黑名单")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c("div", { staticClass: "vue-from-block" }, [
                        _c("div", { staticClass: "form-div form-div-label" }, [
                          _vm._v("用户ID ："),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "content" }, [
                          _vm._v(_vm._s(_vm.salesmanFrom.memberId)),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c("div", { staticClass: "vue-from-block" }, [
                        _c("div", { staticClass: "form-div form-div-label" }, [
                          _vm._v("unionID ："),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "content" }, [
                          _vm._v(_vm._s(_vm.salesmanFrom.unionId)),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c("div", { staticClass: "vue-from-block" }, [
                        _c("div", { staticClass: "form-div form-div-label" }, [
                          _vm._v("最近IP ："),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "content" }, [
                          _vm._v(_vm._s(_vm.salesmanFrom.ipAddress)),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c("div", { staticClass: "vue-from-block" }, [
                        _c("div", { staticClass: "form-div form-div-label" }, [
                          _vm._v("关联openid ："),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "content" }, [
                          _vm._v(_vm._s(_vm.salesmanFrom.openId)),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.isBasics
            ? _c(
                "div",
                [
                  _c("el-divider", { staticClass: "btnDivider move" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "header" }, [
                    _vm._v("扩展信息(收集)"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        "el-row",
                        {
                          staticStyle: { "min-height": "150px" },
                          attrs: { gutter: 20 },
                        },
                        [
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("div", { staticClass: "vue-from-block" }, [
                              _c(
                                "div",
                                { staticClass: "form-div form-div-label" },
                                [_vm._v("姓名 ：")]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "content" }, [
                                _vm._v("张三"),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("div", { staticClass: "vue-from-block" }, [
                              _c(
                                "div",
                                { staticClass: "form-div form-div-label" },
                                [_vm._v("性别 ：")]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "content" }, [
                                _vm._v("女"),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("div", { staticClass: "vue-from-block" }, [
                              _c(
                                "div",
                                { staticClass: "form-div form-div-label" },
                                [_vm._v("出生日期 ：")]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "content" }, [
                                _vm._v("2000-02-02"),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("div", { staticClass: "vue-from-block" }, [
                              _c(
                                "div",
                                { staticClass: "form-div form-div-label" },
                                [_vm._v("联系方式 ：")]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "content" }, [
                                _vm._v("13123456789"),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("div", { staticClass: "vue-from-block" }, [
                              _c(
                                "div",
                                { staticClass: "form-div form-div-label" },
                                [_vm._v("邮箱 ：")]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "content" }, [
                                _vm._v("123@123.com"),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("div", { staticClass: "vue-from-block" }, [
                              _c(
                                "div",
                                { staticClass: "form-div form-div-label" },
                                [_vm._v("地址:")]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "content" }, [
                                _vm._v("北京市天安门广场"),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("div", { staticClass: "vue-from-block" }, [
                              _c(
                                "div",
                                { staticClass: "form-div form-div-label" },
                                [_vm._v("公司:")]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "content" }, [
                                _vm._v("北京能力有限公司"),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("div", { staticClass: "vue-from-block" }, [
                              _c(
                                "div",
                                { staticClass: "form-div form-div-label" },
                                [_vm._v("爱好:")]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "content" }, [
                                _vm._v("篮球 足球 乒乓球"),
                              ]),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("el-divider", { staticClass: "btnDivider move" }),
          _vm._v(" "),
          _c("div", { staticClass: "header flex" }, [
            _c("span", [_vm._v("参与活动记录")]),
            _vm._v(" "),
            _c("div", [
              _vm._v(
                "\n        参与活动记录 ： " +
                  _vm._s(_vm.total2) +
                  " 条 累计中奖 ：\n        " +
                  _vm._s(_vm.salesmanFrom.winningNum) +
                  " 次\n      "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading2,
                      expression: "listLoading2",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.listData,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "index", label: "序号", width: "80" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "markCode",
                      label: "参与数码",
                      width: "200",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "productName",
                      label: "商品",
                      width: "140",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "dealerName",
                      label: "所属经销商",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "scanTime",
                      label: "扫码时间",
                      width: "180",
                      "show-overflow-tooltip": "",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "prizeName",
                      label: "活动/中奖",
                      width: "150",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  scope.row.prizeName == "谢谢惠顾"
                                    ? "未中奖"
                                    : scope.row.prizeName
                                ) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total2 > 0,
                    expression: "total2 > 0",
                  },
                ],
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total2,
                  page: _vm.params2.pageNo,
                  limit: _vm.params2.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.params2, "pageNo", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.params2, "pageSize", $event)
                  },
                  pagination: _vm.getUserReferenceRecordPage,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "" },
                  on: { click: _vm.handleClose },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }