"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _compyuser = require("@/api/systems/compyuser");
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'TablePackset',
  components: {
    Pagination: _Pagination.default,
    Treeselect: _vueTreeselect.default
  },
  props: {},
  data: function data() {
    return {
      proSelVisible: false,
      listLoading: false,
      mloading: false,
      tableKey: '111',
      tabHeight: '100%',
      filterText: '',
      activeClass: '',
      listQuery: {
        current: 1,
        size: 20
      },
      searchForm: {
        loginAccount: '',
        userName: '',
        phoneNum: '',
        isEnable: '',
        orgList: [],
        roleList: [],
        userType: 0
      },
      tableData: [],
      options: [],
      total: 0
    };
  },
  watch: {
    proSelVisible: function proSelVisible(val) {
      if (val) {
        this.getOrg();
        this.getList();
      }
    }
  },
  methods: {
    getOrg: function getOrg() {
      var that = this;
      (0, _compyuser.getQyOrg)().then(function (res) {
        if (res.data.code == 200) {
          var rData = res.data.data;
          var arr = [];
          that.orgOriginData = rData;
          if (rData.length >= 0) {
            rData.forEach(function (item) {
              var obj = {};
              obj.id = item.pid;
              obj.label = item.orgName;
              obj.pid = item.pid;
              obj.parentPid = item.parentPid;
              arr.push(obj);
            });
            that.options = (0, _utils.transTreeData)(arr, 'pid', 'parentPid', 'children');
          }
        }
      });
    },
    getList: function getList() {
      var that = this;
      this.listLoading = true;
      (0, _compyuser.getCompanyList)(that.listQuery, that.searchForm).then(function (response) {
        if (response.data.code == 200) {
          that.tableData = response.data.data.records;
          that.total = parseInt(response.data.data.total);
        } else {
          that.$message.error(response.data.msg);
        }
        that.listLoading = false;
      }).catch(function (res) {
        that.listLoading = false;
      });
      // productArchivesList(submitData).then(res => {
      //   if (res.data.code != 200) {
      //     that.$message.error(res.data.msg)
      //   } else {
      //     if (res.data.data != null) {
      //       that.tableData = res.data.data.records
      //       that.total = Number(res.data.data.total)
      //     } else {
      //       that.total = 0
      //       that.tableData = []
      //     }
      //   }
      // this.listLoading = false
      // })
    },
    resetForm: function resetForm(formName) {
      this.listQuery = {
        current: 1,
        size: 20
      };
      this.searchForm = {
        loginAccount: '',
        userName: '',
        phoneNum: '',
        isEnable: '',
        orgList: [],
        roleList: [],
        userType: 0
      };
      this.filterText = '';
      this.getList();
    },
    selCancel: function selCancel() {
      this.resetForm();
      this.proSelVisible = false;
    },
    submit: function submit(row) {
      this.$emit('change', row);
      this.selCancel();
    }
  }
};
exports.default = _default;