import { render, staticRenderFns } from "./params.vue?vue&type=template&id=4865ec51&scoped=true&"
import script from "./params.vue?vue&type=script&lang=js&"
export * from "./params.vue?vue&type=script&lang=js&"
import style0 from "./params.vue?vue&type=style&index=0&id=4865ec51&scoped=scoped&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4865ec51",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4865ec51')) {
      api.createRecord('4865ec51', component.options)
    } else {
      api.reload('4865ec51', component.options)
    }
    module.hot.accept("./params.vue?vue&type=template&id=4865ec51&scoped=true&", function () {
      api.rerender('4865ec51', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/chanelmange/rebate/config/params.vue"
export default component.exports