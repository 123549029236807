"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _user = require("@/api/user");
var _pwdconfig = require("@/api/systems/pwdconfig");
var _jsMd = _interopRequireDefault(require("js-md5"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {},
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    var validateUsername = function validateUsername(rule, value, callback) {
      if (!value) {
        callback(new Error('请输入用户名！'));
      } else {
        callback();
      }
    };
    var validatePassword = function validatePassword(rule, value, callback) {
      var exReg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{9,20}$/;
      if (!value) {
        callback(new Error('密码长度9-20位，至少包含一个字母、数字、特殊字符'));
      } else {
        if (exReg.test(value)) {
          callback();
        } else {
          callback(new Error('输入的密码长度9-20位，至少包含一个字母、数字、特殊字符'));
        }
      }
    };
    var checkMixZm = function checkMixZm(rule, value, callback) {
      if (!value) {
        return callback(new Error('请输入验证码'));
      } else {
        var reg = /^[0-9a-zA-Z]*$/g;
        value = value.replace(/\s+/g, '');
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error('请输入正确的验证码，不含有特殊字符'));
        }
      }
    };
    return {
      ruleForm1: {
        loginAccount: '',
        validCode: '',
        cookieId: '',
        mobile: '',
        smsCode: '',
        email: '',
        emailCode: '',
        type: '0'
      },
      ruleForm2: {
        password: '',
        repassword: ''
      },
      ruleForm3: {},
      rules: {
        type: [{
          required: true,
          trigger: 'blur'
        }],
        loginAccount: [{
          required: true,
          trigger: 'blur',
          validator: validateUsername
        }],
        validCode: [{
          required: true,
          trigger: 'blur',
          validator: checkMixZm
        }],
        mobile: [{
          required: true,
          trigger: 'blur'
          // validator: checkMobile
        }],

        email: [{
          required: true,
          trigger: 'blur'
          // validator: checkMobile
        }],

        smsCode: [{
          required: true,
          trigger: 'blur',
          message: '请输入手机验证码'
        }],
        emailCode: [{
          required: true,
          trigger: 'blur',
          message: '请输入邮箱验证码'
        }],
        password: [{
          required: true,
          trigger: 'blur',
          validator: validatePassword
        }]
      },
      firstinfo: {},
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
      showText: '获取验证码',
      showText1: '获取验证码',
      yanzhengtext: '获取验证码',
      codeSrc: '',
      codeSrc2: '',
      imgLoading: false,
      step1: false,
      firstStep: false,
      step2: false,
      secStep: false,
      step3: false,
      thrStep: false,
      active: 0,
      loginFormVisible: true,
      timeOut: false,
      timeOut1: false,
      timeOut2: false,
      sessionPhone: '',
      passwordType1: 'password',
      passwordType2: 'password',
      passwordType3: 'password',
      codeBtnWord: '获取验证码',
      // 获取验证码按钮文字
      waitTime: 60,
      // 获取验证码按钮失效时间
      getCodeBtnDisable: false,
      loginType: '',
      userName: '',
      safeRules: {}
    };
  },
  computed: {},
  created: function created() {},
  mounted: function mounted() {
    this.getVCode();
    // this.loginType = sessionStorage.getItem('loginType')
    // const session = sessionStorage.getItem('currentUser')
    // if (session) {
    //   this.userName = JSON.parse(sessionStorage.getItem('currentUser')).loginAccount
    // }
    this.getRuleDetail();
  },
  methods: {
    // 显示密码
    showPwd: function showPwd(val) {
      switch (val) {
        case '1':
          if (this.passwordType1 == 'password') {
            this.passwordType1 = 'text';
          } else {
            this.passwordType1 = 'password';
          }
          break;
        case '2':
          if (this.passwordType2 == 'password') {
            this.passwordType2 = 'text';
          } else {
            this.passwordType2 = 'password';
          }
          break;
        case '3':
          if (this.passwordType3 == 'password') {
            this.passwordType3 = 'text';
          } else {
            this.passwordType3 = 'password';
          }
          break;
      }
    },
    changeImg: function changeImg() {
      var that = this;
      that.imgLoading = true;
      that.ruleForm1.validCode = '';
      that.getVCode();
    },
    getVCode: function getVCode() {
      var that = this;
      (0, _user.getvImg)().then(function (res) {
        that.codeSrc2 = res.data.data.vaildImg;
        that.ruleForm1.cookieId = res.data.data.uuidKey;
        that.imgLoading = false;
      });
    },
    getUserinfo: function getUserinfo() {
      var _this = this;
      (0, _user.getUserDetailByLoginAccount)({
        loginAccount: this.ruleForm1.loginAccount
      }).then(function (res) {
        console.log(res.data);
        if (res.data.data.code != 200) {
          _this.$message.error(res.data.data.msg);
          return;
        }
        _this.ruleForm1.mobile = res.data.data.data.phoneNum;
        _this.ruleForm1.email = res.data.data.data.email;
      });
    },
    handleClose: function handleClose() {
      var that = this;
      //   that.dialogVisible = false
      //   that.loginFormVisible = true
      that.active = 0;
      that.showText = '获取验证码';
      that.ruleForm1.type = '0';
      that.ruleForm1.clientId = '';
      that.ruleForm1.loginAccount = '';
      that.ruleForm1.validCode = '';
      that.ruleForm1.mobile = '';
      that.ruleForm1.smsCode = '';
      that.ruleForm1.email = '';
      that.ruleForm1.emailCode = '';
      that.ruleForm2.password = '';
      that.ruleForm2.repassword = '';
      that.$emit('close');
    },
    getFirstStep: function getFirstStep(formName) {
      var that = this;
      that.$refs.ruleForm1.validate(function (valid) {
        if (valid) {
          that.step1 = true;
          that.firstStep = true;
          var sdata = {};
          sdata.loginAccount = that.ruleForm1.loginAccount;
          if (that.ruleForm1.type == 0) {
            sdata.smsCode = that.ruleForm1.smsCode;
            sdata.type = 2;
            (0, _user.postsmsCode)(sdata).then(function (res) {
              console.log(res.data);
              if (res.data.data.msg == 'success') {
                that.step1 = false;
                that.firstStep = false;
                // that.sessionPhone = res.data.data.phoneNum
                that.active = 1;
              } else {
                that.$message.error(res.data.data.msg);
                that.step1 = false;
                that.firstStep = false;
                // that.sessionPhone = ''
                that.active = 0;
                return;
              }
            }).catch(function () {
              that.step1 = false;
              that.firstStep = false;
              that.active = 0;
            });
          } else {
            sdata.emailCode = that.ruleForm1.emailCode;
            (0, _user.postemailCode)(sdata).then(function (res) {
              console.log(res.data);
              if (res.data.data.msg == 'success') {
                that.step1 = false;
                that.firstStep = false;
                // that.sessionPhone = res.data.data.phoneNum
                that.active = 1;
              } else {
                that.$message.error(res.data.data.msg);
                that.step1 = false;
                that.firstStep = false;
                // that.sessionPhone = ''
                that.active = 0;
                return;
              }
            }).catch(function () {
              that.step1 = false;
              that.firstStep = false;
              that.active = 0;
            });
          }
        } else {
          that.step1 = false;
          that.firstStep = false;
          that.active = 0;
        }
      });
    },
    getSecStep: function getSecStep(formName) {
      var that = this;
      that.$refs.ruleForm2.validate(function (valid) {
        if (valid) {
          var pw1 = that.ruleForm2.password;
          var pw2 = that.ruleForm2.repassword;
          if (pw2 != pw1) {
            that.$message.error('两次输入密码不一致，请确认新密码');
            return;
          }
          that.step2 = true;
          that.secStep = true;
          var param = {};
          var md51 = "".concat(that.ruleForm1.loginAccount.trim(), "+").concat(pw1);
          param.loginAccount = that.ruleForm1.loginAccount;
          param.newPwd = (0, _jsMd.default)(md51);
          (0, _user.updatePwdByCheckCode)(param).then(function (res) {
            console.log(res.data);
            if (res.data.code == '200') {
              that.$message({
                message: '设置成功！',
                type: 'success'
              });
              that.active = 2;
              setTimeout(function () {
                that.step2 = false;
                that.secStep = false;
                // that.handleClose()
              }, 1000);
            } else {
              that.$message.error(res.data.msg);
              that.step2 = false;
              that.secStep = false;
              that.active = 1;
              return;
            }
          }).catch(function () {
            that.step2 = false;
            that.secStep = false;
            that.active = 1;
          });
        } else {
          that.step2 = false;
          that.secStep = false;
          that.active = 1;
        }
      });
    },
    getThrStep: function getThrStep() {
      var that = this;
      setTimeout(function () {
        that.step3 = false;
        that.thrStep = false;
        that.handleClose();
      }, 1000);
    },
    getYzCode: function getYzCode(type) {
      var that = this;
      // var phone = that.ruleForm2.mobile
      // var reg = /^1\d{10}$/
      var count = 60;
      // if (!reg.test(phone)) {
      //   that.$message.error('请输入正确的手机号')
      //   return
      // } else {
      //   if (phone != that.sessionPhone) {
      //     that.$message.error('您输入的手机号与此账号绑定手机号不一致！')
      //     return
      //   }
      // }
      var param = {};
      var validCode = {};
      param.loginAccount = that.ruleForm1.loginAccount;
      validCode.cookieId = that.ruleForm1.cookieId;
      validCode.code = that.ruleForm1.validCode;
      // param.mobile = phone
      // param.loginAccount = that.ruleForm1.loginAccount
      // getSendCode(param).then((res) => {
      //   if (res.data.code == 200) {
      //     that.timeOut = true
      //     var timer = setInterval(function() {
      //       count--
      //       if (count > 0) {
      //         that.showText = count + 's'
      //       } else {
      //         clearInterval(timer)
      //         that.timeOut = false
      //         that.showText = '获取验证码'
      //       }
      //     }, 1000)
      //   } else {
      //     that.timeOut = false
      //     that.$message.error(res.data.msg)
      //     return
      //   }
      // })
      if (!validCode.code) {
        that.$message.error('请先输入图像验证码');
        return;
      }
      if (type == 0) {
        param.type = 2;
        (0, _user.getsmsCode)(param, validCode).then(function (res) {
          if (res.data.data.code == 200) {
            that.timeOut = true;
            var timer = setInterval(function () {
              count--;
              if (count > 0) {
                that.showText = count + 's';
              } else {
                clearInterval(timer);
                that.timeOut = false;
                that.showText = '获取验证码';
              }
            }, 1000);
          } else {
            that.timeOut = false;
            that.$message.error(res.data.data.msg);
            return;
          }
        });
      } else {
        (0, _user.getemailCode)(param, validCode).then(function (res) {
          console.log(res.data);
          if (res.data.data.code == 200) {
            that.timeOut = true;
            var timer = setInterval(function () {
              count--;
              if (count > 0) {
                that.showText = count + 's';
              } else {
                clearInterval(timer);
                that.timeOut = false;
                that.showText = '获取验证码';
              }
            }, 1000);
          } else {
            that.timeOut = false;
            that.$message.error(res.data.data.msg);
            return;
          }
        });
      }
    },
    getPrevOne: function getPrevOne() {
      var that = this;
      that.active = 0;
    },
    getPrevSec: function getPrevSec() {
      var that = this;
      that.active = 1;
    },
    // 获取安全规则
    getRuleDetail: function getRuleDetail() {
      var _this2 = this;
      this.loading = true;
      (0, _pwdconfig.getPwdRuleList)().then(function (res) {
        if (res.data.code == 200) {
          if (res.data.data && res.data.data.length > 0) {
            _this2.safeRules = res.data.data[0];
            if (res.data.data[0].strongCheck == 1) {
              _this2.rules.password = [{
                required: true,
                trigger: 'blur',
                validator: function validator(rule, value, callback) {
                  var exReg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{9,20}$/;
                  if (!value) {
                    callback(new Error('请输入，密码长度9-20位，至少包含一个字母、数字、特殊字符'));
                  } else {
                    if (exReg.test(value)) {
                      callback();
                    } else {
                      callback(new Error('输入的密码长度9-20位，至少包含一个字母、数字、特殊字符'));
                    }
                  }
                }
              }];
            }
            if (res.data.data[0].strongCheck == 0) {
              _this2.rules.password = [{
                required: true,
                trigger: 'blur',
                validator: function validator(rule, value, callback) {
                  var exReg = /^[A-Za-z\d$@!%*#?&]{7,20}$/;
                  if (!value) {
                    callback(new Error('请输入，密码长度7-20位'));
                  } else {
                    if (exReg.test(value)) {
                      callback();
                    } else {
                      callback(new Error('输入的密码长度7-20位'));
                    }
                  }
                }
              }];
            }
          } else {
            _this2.safeRules = {};
          }
        }
        _this2.loading = false;
      }).catch(function () {
        _this2.loading = false;
      });
    }
  }
};
exports.default = _default;