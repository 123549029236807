"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.activityQueryPage = activityQueryPage;
exports.batchUnRelationActivity = batchUnRelationActivity;
exports.batchUpdateRuleCode = batchUpdateRuleCode;
exports.createUnlockPrzeRule = createUnlockPrzeRule;
exports.detailUnlockPrzeRule = detailUnlockPrzeRule;
exports.getActCountByPrizeId = getActCountByPrizeId;
exports.getActCountByRuleCode = getActCountByRuleCode;
exports.getUnlockPrzeRuleList = getUnlockPrzeRuleList;
exports.isRelation = isRelation;
exports.onOrOffUnlockPrzeRule = onOrOffUnlockPrzeRule;
exports.relationActivity = relationActivity;
exports.ruleRelation = ruleRelation;
exports.unRelationActivity = unRelationActivity;
exports.updateUnlockPrzeRule = updateUnlockPrzeRule;
var _request = _interopRequireDefault(require("@/utils/request"));
var baseUrl = '';

// 解锁奖品规则分页查询
function getUnlockPrzeRuleList(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/unlockPrizeRule/page',
    method: 'post',
    data: data
  });
}

// 活动关联解锁奖品
function relationActivity(param) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/unlockPrizeRule/relationActivity',
    method: 'get',
    params: param
  });
}

// 活动取消关联解锁奖品
function unRelationActivity(param) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/unlockPrizeRule/unRelationActivity',
    method: 'get',
    params: param
  });
}
function isRelation(param) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/unlockPrizeRule/isRelation',
    method: 'get',
    params: param
  });
}

// 创建奖品规则
function createUnlockPrzeRule(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/unlockPrizeRule/create',
    method: 'post',
    data: data
  });
}

// 编辑奖品规则
function updateUnlockPrzeRule(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/unlockPrizeRule/update',
    method: 'post',
    data: data
  });
}
function detailUnlockPrzeRule(param) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/unlockPrizeRule/detail',
    method: 'get',
    params: param
  });
}
function onOrOffUnlockPrzeRule(param) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/unlockPrizeRule/onOrOff',
    method: 'get',
    params: param
  });
}
function getActCountByPrizeId(param) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/unlockPrizeRule/getActCountByPrizeId',
    method: 'get',
    params: param
  });
}
function getActCountByRuleCode(param) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/unlockPrizeRule/getActCountByRuleCode',
    method: 'get',
    params: param
  });
}
function activityQueryPage(data) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/unlockPrizeRule/activityQueryPage',
    method: 'post',
    data: data
  });
}

// 多活动解锁奖品规则关联情况
function ruleRelation(param) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/unlockPrizeRule/ruleRelation',
    method: 'get',
    params: param
  });
}

// 批量替换活动的解锁奖品规则
function batchUpdateRuleCode(param) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/unlockPrizeRule/batchUpdateRuleCode',
    method: 'get',
    params: param
  });
}

// 解锁奖品规则批量取消关联
function batchUnRelationActivity(param) {
  return (0, _request.default)({
    url: baseUrl + '/market-zdConfig/unlockPrizeRule/batchUnRelationActivity',
    method: 'get',
    params: param
  });
}