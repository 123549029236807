"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
var _default = {
  name: 'Tips',
  props: {
    content: {
      type: String,
      required: true
    }
  },
  methods: {
    tipAlert: function tipAlert() {
      this.$alert('<div style="line-height:21px;"><i class="el-icon-warning" style="font-size:20px;color: #E6A23C;margin-right:10px;"></i>' + this.content + '</div>', {
        showClose: false,
        dangerouslyUseHTMLString: true,
        center: true,
        confirmButtonText: '我知道了'
      });
    }
  }
};
exports.default = _default;