"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _wxbasicConfig = require("@/api/wxhyyy/wxbasicConfig");
var _TipDialog = _interopRequireDefault(require("@/components/TipDialog"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Tipdialog: _TipDialog.default
  },
  filters: {
    stateName: function stateName(index) {
      if (!index) return '';
      var comtype = {
        '2': '未授权',
        '1': '已授权'
      };
      var result = comtype[index];
      return result;
    }
  },
  data: function data() {
    return {
      list: null,
      listLoading: true,
      radio: '',
      newRadio: '',
      setUpDialogVisible: false
    };
  },
  computed: {
    consumer: {
      get: function get() {
        return this.radio;
      },
      set: function set(val) {
        if (!this.radio) {
          this.newRadio = val;
          this.isSetUp();
        }
      }
    }
  },
  created: function created() {
    this.fetchData();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;
      this.listLoading = true;
      (0, _wxbasicConfig.getWcList)().then(function (res) {
        var res = res.data;
        if (res.state === '200') {
          res.data.forEach(function (element) {
            if (element.isDefault * 1 === 1) {
              _this.radio = element.isDefault;
            }
          });
          _this.list = res.data;
        } else {
          _this.$message.closeAll();
          _this.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
        _this.listLoading = false;
      });
    },
    handleAdd: function handleAdd(row) {
      this.$router.push({
        name: 'wxAccountswxgzhpublicConfigaddwxpzadd',
        params: {
          state: '新增'
        }
      });
    },
    del: function del(id) {
      var _this2 = this;
      this.$confirm('您确定要做此吗？', '操作', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _wxbasicConfig.deleteConfig)({
          id: id
        }).then(function (res) {
          var res = res.data;
          if (res.code === 200) {
            _this2.$message({
              message: res.msg,
              type: 'success',
              duration: 3 * 1000
            });
            _this2.fetchData();
          } else {
            _this2.$message({
              message: res.msg,
              type: 'error',
              duration: 3 * 1000
            });
          }
        });
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    handleUpdate: function handleUpdate(row) {
      this.$router.push({
        name: 'wxAccountswxgzhpublicConfigaddwxpzadd',
        params: {
          state: '编辑',
          id: row.id
        }
      });
    },
    isSetUp: function isSetUp() {
      var _this3 = this;
      // this.setUpDialogVisible = true
      this.$confirm('<div style="line-height:21px;"><i class="el-icon-warning" style="font-size:20px;color: #E6A23C;margin-right:10px;"></i>设置后不能调整，请确认是否设置此公众号为消费者公众号。</div>', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(function () {
        _this3.handleSetUp();
      }).catch(function () {
        _this3.setUpHandleClose();
      });
    },
    handleSetUp: function handleSetUp() {
      var _this4 = this;
      // 接口
      (0, _wxbasicConfig.setConsum)({
        id: this.newRadio
      }).then(function (res) {
        var res = res.data;
        if (res.state * 1 === 200) {
          _this4.radio = _this4.newRadio;
          _this4.fetchData();
          _this4.$message.closeAll();
          _this4.$message({
            message: res.msg,
            type: 'success',
            duration: 3 * 1000
          });
        } else {
          _this4.$message.closeAll();
          _this4.$message({
            message: res.msg,
            type: 'error',
            duration: 3 * 1000
          });
        }
      });
    },
    goSet: function goSet() {
      // window.open(process.env.VUE_APP_BASE_API + '/api-memberscrm/member/admin/third/api/auth/goto_auth_url')
      // console.log(process.env.VUE_APP_BASE_API)
      // window.open('http://testsaas.panpass.com/wxservice/openwx/goAuth')
      window.open(process.env.VUE_APP_BASE_API + '/api-memberscrm/member/admin/third/api/auth/goto_auth_url_show');
    },
    goweChatRecord: function goweChatRecord(qyorgId) {
      this.$router.push({
        name: 'weChatRecord'
      });
    },
    setUpHandleClose: function setUpHandleClose() {
      this.radio = '';
    }
  }
};
exports.default = _default;