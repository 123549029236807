"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.activityPrizeRecordPage = activityPrizeRecordPage;
exports.getExprotPrize = getExprotPrize;
exports.restSendPrize = restSendPrize;
var _request = _interopRequireDefault(require("@/utils/request"));
var base = '';

// 奖品失败发放表格
function activityPrizeRecordPage(params) {
  return (0, _request.default)({
    url: base + '/market-config/activityPrizeRecord/activityPrizeRecordPage',
    method: 'get',
    params: params
  });
}
// 奖品失败发放重发
// export function sendPrizeAgain(data) {
//     return request({
//         url: base + '/market-config/activityPrizeRecord/sendPrizeAgain',
//         method: 'post',
//         data,
//         headers: {'Content-Type' : 'application/json'}
//     })
// }
function restSendPrize(data) {
  return (0, _request.default)({
    url: base + '/market-center/activityPrizeRecord/restSendPrize',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json'
    }
  });
}
function getExprotPrize(params) {
  return (0, _request.default)({
    url: base + '/market-config/activityPrizeRecord/exportExcel',
    method: 'get',
    responseType: 'blob',
    params: params
  });
}