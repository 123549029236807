var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
      ? _c(
          "div",
          { staticClass: "page-section" },
          [
            _c(
              "el-form",
              {
                ref: "searchForm",
                staticClass: "search-condition",
                attrs: {
                  model: _vm.searchForm,
                  "status-icon": "",
                  "label-position": _vm.formConfig.labelPosition,
                  "label-width": _vm.formConfig.labelWidth,
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "cols" },
                  [
                    _c(
                      "el-row",
                      {
                        staticStyle: { "margin-bottom": "0" },
                        attrs: { gutter: 20 },
                      },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "原始数码:",
                                  prop: "oldMarkCode",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请输入",
                                    maxlength: "40",
                                  },
                                  model: {
                                    value: _vm.searchForm.oldMarkCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "oldMarkCode",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "searchForm.oldMarkCode",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "新数码:",
                                  prop: "newMarkCode",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请输入",
                                    maxlength: "40",
                                  },
                                  model: {
                                    value: _vm.searchForm.newMarkCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "newMarkCode",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "searchForm.newMarkCode",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "factoryCode",
                                  label: "生产基地:",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      filterable: "",
                                      placeholder: "请选择",
                                    },
                                    model: {
                                      value: _vm.searchForm.factoryCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "factoryCode",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.factoryCode",
                                    },
                                  },
                                  _vm._l(_vm.factoryList, function (item) {
                                    return _c("el-option", {
                                      key: item.pId,
                                      attrs: {
                                        label: item.factoryName,
                                        value: item.factoryCode,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.showCol
                          ? _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: { prop: "farmCode", label: "车间:" },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          clearable: "",
                                          filterable: "",
                                          placeholder: "请选择",
                                        },
                                        model: {
                                          value: _vm.searchForm.farmCode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchForm,
                                              "farmCode",
                                              $$v
                                            )
                                          },
                                          expression: "searchForm.farmCode",
                                        },
                                      },
                                      _vm._l(_vm.shopList, function (item) {
                                        return _c("el-option", {
                                          key: item.pId,
                                          attrs: {
                                            label: item.factoryName,
                                            value: item.factoryCode,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.showCol
                          ? _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "produceLineCode",
                                      label: "产线:",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          clearable: "",
                                          filterable: "",
                                          placeholder: "请选择",
                                        },
                                        model: {
                                          value: _vm.searchForm.produceLineCode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchForm,
                                              "produceLineCode",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "searchForm.produceLineCode",
                                        },
                                      },
                                      _vm._l(
                                        _vm.productlineList,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.pId,
                                            attrs: {
                                              label: item.factoryName,
                                              value: item.factoryCode,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.showCol
                          ? _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: { prop: "teamCode", label: "班组:" },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          clearable: "",
                                          filterable: "",
                                          placeholder: "请选择",
                                        },
                                        model: {
                                          value: _vm.searchForm.teamCode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchForm,
                                              "teamCode",
                                              $$v
                                            )
                                          },
                                          expression: "searchForm.teamCode",
                                        },
                                      },
                                      _vm._l(_vm.teamList, function (item) {
                                        return _c("el-option", {
                                          key: item.pId,
                                          attrs: {
                                            label: item.factoryName,
                                            value: item.factoryCode,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.showCol
                          ? _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "计划单状态",
                                      prop: "taskStatus",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          clearable: "",
                                          placeholder: "请选择",
                                        },
                                        model: {
                                          value: _vm.searchForm.taskStatus,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchForm,
                                              "taskStatus",
                                              $$v
                                            )
                                          },
                                          expression: "searchForm.taskStatus",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: {
                                            label: "已审核，待下载",
                                            value: 10,
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: {
                                            label: "已下载，执行中",
                                            value: 13,
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: {
                                            label: "执行完成",
                                            value: 16,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showCol,
                                expression: "showCol",
                              },
                            ],
                            attrs: { span: 6 },
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "productCode",
                                  label: "产品编号:",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请输入",
                                  },
                                  model: {
                                    value: _vm.searchForm.productCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "productCode",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.productCode",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showCol,
                                expression: "showCol",
                              },
                            ],
                            attrs: { span: 6 },
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "productName",
                                  label: "产品名称:",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请输入",
                                  },
                                  model: {
                                    value: _vm.searchForm.productName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "productName",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.productName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.showCol
                          ? _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "beginDate",
                                      label: "操作起始时间:",
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        type: "date",
                                        placeholder: "选择日期",
                                        "value-format": "yyyy-MM-dd",
                                      },
                                      model: {
                                        value: _vm.searchForm.beginDate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "beginDate",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.beginDate",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.showCol
                          ? _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "endDate",
                                      label: "操作结束时间:",
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        type: "date",
                                        placeholder: "选择日期",
                                        "value-format": "yyyy-MM-dd",
                                      },
                                      model: {
                                        value: _vm.searchForm.endDate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "endDate",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.endDate",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.formConfig.btnFollow
                          ? _c(
                              "el-col",
                              {
                                staticClass: "head-btn-group",
                                style: {
                                  width: _vm.formConfig.btnAreaHasShowMore,
                                },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { "label-width": "0" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "label" },
                                        slot: "label",
                                      },
                                      [_vm._v(" ")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: _vm.btnConfig.searchBtn.type,
                                          size: _vm.btnConfig.size,
                                          icon: _vm.btnConfig.searchBtn.icon,
                                        },
                                        on: { click: _vm.DoSearch },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.btnConfig.searchBtn.text)
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: _vm.btnConfig.resetBtn.type,
                                          size: _vm.btnConfig.size,
                                          icon: _vm.btnConfig.resetBtn.icon,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.resetForm("searchForm")
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.btnConfig.resetBtn.text)
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: { click: _vm.expand },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.expandTxt) +
                                            "\n                "
                                        ),
                                        _c("i", {
                                          class:
                                            _vm.showCol == false
                                              ? "el-icon-arrow-down"
                                              : "el-icon-arrow-up",
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _vm.formConfig.btnFollow
              ? _c("el-divider", { staticClass: "btnDivider" })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                key: _vm.tableKey,
                staticStyle: { width: "100%" },
                attrs: {
                  height: _vm.tabHeight,
                  data: _vm.tableData,
                  border: _vm.tableConfig.border,
                  stripe: _vm.tableConfig.stripe,
                  fit: "",
                  "highlight-current-row": "",
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    type: "index",
                    align: _vm.tableConfig.align,
                    width: "80",
                    label: "序号",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "原始数码",
                    prop: "oldMarkCode",
                    "min-width": "200",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "新数码",
                    prop: "newMarkCode",
                    "min-width": "200",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "数码类型",
                    prop: "packLevel",
                    "min-width": "140",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            row.packLevel == 1
                              ? _c("span", [_vm._v("盖码")])
                              : _vm._e(),
                            _vm._v(" "),
                            row.packLevel == 2
                              ? _c("span", [_vm._v("瓶码")])
                              : _vm._e(),
                            _vm._v(" "),
                            row.packLevel == 3
                              ? _c("span", [_vm._v("盒码")])
                              : _vm._e(),
                            _vm._v(" "),
                            row.packLevel == 4
                              ? _c("span", [_vm._v("箱码")])
                              : _vm._e(),
                            _vm._v(" "),
                            row.packLevel == 5
                              ? _c("span", [_vm._v("托码")])
                              : _vm._e(),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    555229042
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "生产基地",
                    prop: "factory",
                    "min-width": "140",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "车间名称",
                    prop: "shop",
                    "min-width": "140",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "产线名称",
                    prop: "productLine",
                    "min-width": "180",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "操作人",
                    prop: "creatorId",
                    "min-width": "140",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "操作信息",
                    prop: "result",
                    "min-width": "140",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "操作类型",
                    prop: "bizType",
                    "min-width": "140",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            row.bizType == 1
                              ? _c("span", [_vm._v("解除关系")])
                              : _vm._e(),
                            _vm._v(" "),
                            row.bizType == 0
                              ? _c("span", [_vm._v("替换")])
                              : _vm._e(),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1619321093
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "数据来源",
                    prop: "sourceType",
                    "min-width": "140",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            row.sourceType == 1
                              ? _c("span", [_vm._v("平台")])
                              : _vm._e(),
                            _vm._v(" "),
                            row.sourceType == 0
                              ? _c("span", [_vm._v("ERP")])
                              : _vm._e(),
                            _vm._v(" "),
                            row.sourceType == 2
                              ? _c("span", [_vm._v("产线")])
                              : _vm._e(),
                            _vm._v(" "),
                            row.sourceType == 3
                              ? _c("span", [_vm._v("PDA")])
                              : _vm._e(),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3821407263
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "操作时间",
                    prop: "operateTime",
                    "min-width": "165",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "创建时间",
                    prop: "createTime",
                    "min-width": "165",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("pagination", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.total > 0,
                  expression: "total > 0",
                },
              ],
              attrs: {
                layout: "total,sizes,prev, pager, next, jumper",
                total: _vm.total,
                page: _vm.searchForm.pageNo,
                limit: _vm.searchForm.pageSize,
              },
              on: {
                "update:page": function ($event) {
                  return _vm.$set(_vm.searchForm, "pageNo", $event)
                },
                "update:limit": function ($event) {
                  return _vm.$set(_vm.searchForm, "pageSize", $event)
                },
                pagination: _vm.getList,
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
      ? _c("section", [_vm._m(0)])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }