"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
var _utils = require("@/utils");
var _param = require("@/api/chanelmange/param");
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
var _productDialog = _interopRequireDefault(require("@/components/productDialog"));
var _productChose = _interopRequireDefault(require("../component/productChose"));
var _policyDialog = _interopRequireDefault(require("@/components/policyDialog"));
var _scanZc = _interopRequireDefault(require("./scanZc"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'InsertHdfl',
  components: {
    Treeselect: _vueTreeselect.default,
    productMultiSelect: _productDialog.default,
    productChose: _productChose.default,
    policySelect: _policyDialog.default,
    scanZc: _scanZc.default
  },
  filters: {
    // formatDate(time) {
    //   const date = new Date(time)
    //   return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
    // }
  },
  props: {
    insertVisible: {
      type: Boolean,
      default: false
    },
    pid: {
      type: String,
      default: ''
    },
    subType: {
      type: String,
      default: 'add'
    }
  },
  data: function data() {
    return {
      title: '添加返利方案',
      mloading: false,
      PolicyDialog: false,
      proList: [],
      configData: [],
      orgList: [],
      zzOptions: [],
      status: 0,
      submitData: {
        associatedPolicy: '',
        beginTime: '',
        configList: [],
        endTime: '',
        orgList: [],
        pid: '',
        proList: [],
        schemeCode: '',
        schemeDescribe: '',
        schemeName: '',
        schemeState: null,
        schemeType: 1,
        policyCode: ''
      },
      schemeStates: [{
        name: '待提交',
        id: 0
      }, {
        name: '待审核',
        id: 1
      }, {
        name: '已生效',
        id: 2
      }, {
        name: '已终止',
        id: 3
      }],
      schemeTypes: [{
        name: '月度',
        id: 0
      }, {
        name: '年度',
        id: 1
      }],
      triggerActions: [{
        name: '终端签收',
        id: 1
      }, {
        name: '消费者扫码',
        id: 2
      }, {
        name: '下订单',
        id: 3
      }],
      channelTypes: [{
        name: '经销商',
        id: 1
      }, {
        name: '终端',
        id: 2
      }],
      conditions: [{
        name: '采购入库',
        id: 1
      }, {
        name: '销售出库',
        id: 2
      }, {
        name: '销售订单审核',
        id: 3
      }],
      proVisible: false,
      returnData: [],
      singleProVisible: false,
      configIndex: '',
      rowData: '',
      rules: {
        schemeName: [{
          required: true,
          message: '请输入方案名称',
          trigger: 'blur'
        }, {
          min: 1,
          max: 50,
          message: '最大长度为50字符',
          trigger: 'blur'
        }],
        beginTime: [{
          required: true,
          message: '请选择起始时间',
          trigger: 'blur'
        }],
        endTime: [{
          required: true,
          message: '请选择结束时间',
          trigger: 'blur'
        }],
        policyCode: [{
          required: true,
          message: '请选择关联政策',
          trigger: 'blur'
        }]
      },
      actionType: null,
      policyVisible: false,
      policyName: ''
    };
  },
  watch: {
    insertVisible: function insertVisible(val) {
      if (val) {
        this.zzOptions = this.$parent.orgOptions;
        this.mloading = false;
        if (this.subType == 'edit') {
          this.getBasic(this.pid);
          this.getPro(this.pid);
          this.getOrg(this.pid);
          this.getCofig(this.pid);
        } else if (this.subType == 'add') {
          this.status = 0;
          this.proList = [];
          this.configData = [];
          this.orgList = [];
          this.submitData = {
            associatedPolicy: '',
            beginTime: '',
            configList: [],
            endTime: '',
            orgList: [],
            pid: '',
            proList: [],
            schemeCode: '',
            schemeDescribe: '',
            schemeName: '',
            schemeState: 0,
            schemeType: 1,
            policyCode: ''
          };
        }
      } else {
        this.zzOptions = this.$parent.orgOptions;
      }
    }
  },
  created: function created() {},
  mounted: function mounted() {
    // var that = this
  },
  methods: {
    getBasic: function getBasic(arg) {
      var that = this;
      (0, _param.getRebateSchemeById)({
        pid: arg
      }).then(function (res) {
        if (res.data.code == 200) {
          var resData = res.data.data;
          if (resData != null) {
            console.log(resData);
            that.submitData.associatedPolicy = resData.associatedPolicy;
            that.submitData.beginTime = resData.beginTime;
            that.submitData.endTime = resData.endTime;
            that.submitData.pid = that.pid;
            that.submitData.schemeCode = resData.schemeCode;
            that.submitData.schemeDescribe = resData.schemeDescribe;
            that.submitData.schemeName = resData.schemeName;
            that.submitData.schemeType = parseInt(resData.schemeType);
            that.submitData.policyCode = resData.policyCode;
            that.status = parseInt(resData.schemeState);
          }
        }
      });
    },
    getPro: function getPro(arg) {
      var that = this;
      (0, _param.getRebateSchemeProBySchemeId)({
        pid: arg
      }).then(function (res) {
        if (res.data.code == 200) {
          that.proList = res.data.data;
        }
      });
    },
    getOrg: function getOrg(arg) {
      var that = this;
      (0, _param.getRebateRangeById)({
        pid: arg
      }).then(function (res) {
        if (res.data.code == 200) {
          var arr = [];
          var rData = res.data.data;
          if (rData.length > 0) {
            rData.forEach(function (item) {
              arr.push(item.orgId);
            });
          }
          that.orgList = arr;
          that.zzOptions = that.$parent.orgOptions;
        }
      });
    },
    getCofig: function getCofig(arg) {
      var that = this;
      (0, _param.getRebateSchemeConfig)({
        pid: arg
      }).then(function (res) {
        that.configData = [];
        if (res.data.code == 200) {
          var resData = res.data.data;
          that.configData = resData.map(function (item) {
            return {
              buyNum: Number(item.buyNum),
              channelType: item.channelType,
              classId: item.classId,
              gradeId: item.gradeId,
              presentNum: Number(item.presentNum),
              rebatePrerequisite: item.rebatePrerequisite,
              rebateProductId: item.rebateProductId,
              rebateType: item.rebateType,
              triggerAction: item.triggerAction,
              chanelClassList: [{
                className: item.className,
                pId: item.classId,
                channelType: item.channelType
              }],
              chanelLeveList: [{
                gradeName: item.gradeName,
                pId: item.gradeId,
                channelType: item.channelType
              }],
              productName: item.productName
            };
          });
        } else {
          that.$message.error(res.data.msg + ',获取返利配置失败');
        }
      });
    },
    handleClose: function handleClose() {
      var that = this;
      that.submitData = {
        associatedPolicy: '',
        beginTime: '',
        configList: [],
        endTime: '',
        orgList: [],
        pid: '',
        proList: [],
        schemeCode: '',
        schemeDescribe: '',
        schemeName: '',
        schemeState: null,
        schemeType: 1,
        policyCode: ''
      };
      that.policyCode = '';
      that.$emit('close');
    },
    // 添加行
    addRow: function addRow() {
      var that = this;
      var obj = {
        buyNum: '',
        channelType: null,
        classId: '',
        gradeId: '',
        presentNum: '',
        rebatePrerequisite: null,
        rebateProductId: '',
        rebateType: null,
        triggerAction: null,
        chanelClassList: [],
        chanelLeveList: [],
        productName: ''
      };
      that.configData.push(obj);
    },
    // 获取渠道分类，渠道等级,联动
    changeStatus: function changeStatus(val, row) {
      // const that = this
      row.chanelClassList = [];
      row.chanelLeveList = [];
      row.classId = '';
      row.gradeId = '';
      var param = {
        channelType: val,
        classStatus: '1',
        pageNo: 0,
        pageSize: 0
      };
      (0, _param.getQdFl)(param).then(function (res) {
        if (res.data.code == 200) {
          row.chanelClassList = res.data.data;
        }
      }).then(function () {
        var args = {
          channelType: val,
          gradeStatus: '1',
          pageNo: 0,
          pageSize: 0
        };
        (0, _param.getQdDJ)(args).then(function (res) {
          if (res.data.code == 200) {
            row.chanelLeveList = res.data.data;
          }
        });
      });
    },
    // 统一触发动作
    changeSameAction: function changeSameAction(val, row, index) {
      var that = this;
      var configData = that.configData;
      if (val == 2) {
        for (var i = 0; i < configData.length; i++) {
          if (configData[i].triggerAction == 1) {
            configData[i].triggerAction = val;
          }
        }
      }
      if (val == 1) {
        for (var _i = 0; _i < configData.length; _i++) {
          if (configData[_i].triggerAction == 2) {
            configData[_i].triggerAction = val;
          }
        }
      }
      row.buyNum = '';
      row.presentNum = '';
      row.chanelClassList = [];
      row.chanelLeveList = [];
      row.gradeId = '';
      row.classId = '';
      row.productName = '';
      row.rebateProductId = '';
      row.rebateType = null;
    },
    // 获取产品
    addProducts: function addProducts() {
      var that = this;
      that.proVisible = true;
    },
    closePro: function closePro() {
      var that = this;
      that.proVisible = false;
    },
    fetchPros: function fetchPros(val) {
      var that = this;
      var arr = val.map(function (item) {
        return {
          productCode: item.productCode,
          productName: item.productName,
          productClass: item.productClassName,
          productId: item.pId,
          measurementUnit: that.transUnit(item.minScaleName),
          rebateProportion: null
        };
      });
      that.proList = (0, _utils.uniqueJsonArray)(that.proList.concat(arr), 'productId');
    },
    transUnit: function transUnit(name) {
      var unitObj = {
        罐: 1,
        盒: 2,
        箱: 3
      };
      return unitObj[name];
    },
    // 删除产品
    delPro: function delPro(data) {
      var that = this;
      var id = data.productId;
      var proList = that.proList;
      that.$confirm('是否要删除？', '确认信息', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        for (var i = 0; i < proList.length; i++) {
          if (proList[i].productId == id) {
            that.proList.splice(i, 1);
            that.$message({
              type: 'success',
              message: '已删除'
            });
            break;
          }
        }
      }).catch(function () {});
    },
    // 获取单一产品
    showSinglePro: function showSinglePro(row, index) {
      var that = this;
      that.configIndex = index;
      that.rowData = row;
      if (row.triggerAction) {
        that.actionType = row.triggerAction;
        that.singleProVisible = true;
      } else {
        that.$message.error('请选择触发动作');
      }
    },
    getSinglePro: function getSinglePro(val) {
      var that = this;
      var index = Number(that.configIndex);
      that.configData[index].productName = val.productName;
      that.configData[index].rebateProductId = val.pId;
      if (that.actionType == 1 || that.actionType == 2) {
        if (that.configData[index].buyNum != '' && that.configData[index].buyNum != null) {
          that.configData[index].buyNum = '1';
          that.$message({
            type: 'error',
            message: '选择酒券或现金，买赠只允许买1赠N'
          });
        }
      }
    },
    closeSigle: function closeSigle() {
      var that = this;
      that.singleProVisible = false;
    },
    // 复制
    DoCopy: function DoCopy(index, data) {
      var that = this;
      var newObj = (0, _utils.deepClone)(data);
      that.configData.splice(index + 1, 0, newObj);
    },
    // 删除
    DoDel: function DoDel(index, data) {
      var _this = this;
      var that = this;
      that.$confirm('是否要删除？', '确认信息', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        that.configData.splice(index, 1);
        _this.$message({
          type: 'success',
          message: '已删除'
        });
      }).catch(function () {});
    },
    // 获取政策
    chosePolice: function chosePolice() {
      var that = this;
      that.PolicyDialog = true;
    },
    getPolice: function getPolice(value) {
      var that = this;
      that.submitData.associatedPolicy = value.pid;
      that.submitData.policyCode = value.policyCode;
      that.policyName = value.policyName;
      that.$refs.zcbm.focus();
      that.$refs.zcbm.blur();
    },
    scanPolicy: function scanPolicy() {
      var that = this;
      if (that.submitData.policyCode == '') {
        that.$message.error('请选择政策');
        return;
      } else {
        that.policyVisible = true;
      }
    },
    closeScan: function closeScan() {
      this.policyVisible = false;
    },
    // 提交，保存
    submit: function submit(val, formName) {
      var that = this;
      that.mloading = true;
      that.$refs[formName].validate(function (valid) {
        if (valid) {
          // 判断发你参数是否 输入
          var pdata = that.proList;
          var pfound = true;
          if (pdata.length > 0) {
            for (var i = 0; i < pdata.length; i++) {
              if (pdata[i].rebateProportion == '' || pdata[i].rebateProportion == null) {
                that.mloading = false;
                that.$message.error('参与产品列表第' + (i + 1) + '行，请输入返利倍数');
                pfound = false;
                break;
              } else {
                if (parseInt(pdata[i].rebateProportion) == 0 || pdata[i].rebateProportion > 10) {
                  that.mloading = false;
                  that.$message.error('参与产品列表第' + (i + 1) + '行，返利倍数必须为1-10的正整数');
                  pfound = false;
                  break;
                }
                if (pdata[i].rebateProportion - parseInt(pdata[i].rebateProportion) > 0) {
                  that.mloading = false;
                  that.$message.error('参与产品列表第' + (i + 1) + '行，返利倍数必须为1-10的正整数');
                  pfound = false;
                  break;
                }
              }
            }
          } else {
            that.$message.error('请添加产品');
            that.mloading = false;
            return;
          }
          if (!pfound) {
            that.mloading = false;
            return;
          }

          // 判断是否选择返利范围
          if (that.orgList.length == 0) {
            that.$message.error('请选择返利范围');
            that.mloading = false;
            return;
          }

          // 判断返利配置项是否重复
          var cdata = that.configData;
          var find = true;
          if (cdata.length == 0) {
            that.$message.error('请添加配置');
            that.mloading = false;
            return;
          }
          for (var _i2 = 0; _i2 < cdata.length; _i2++) {
            if (cdata[_i2].triggerAction == '' || cdata[_i2].triggerAction == null) {
              that.$message.error('第' + (_i2 + 1) + '行数触发动作不能为空，请重新配置！');
              that.mloading = false;
              find = false;
              break;
            } else if (cdata[_i2].channelType == '' || cdata[_i2].channelType == null) {
              that.$message.error('第' + (_i2 + 1) + '渠道类型不能为空，请重新配置！');
              that.mloading = false;
              find = false;
              break;
            } else if (cdata[_i2].classId == '' || cdata[_i2].classId == null) {
              that.$message.error('第' + (_i2 + 1) + '渠道分类不能为空，请重新配置！');
              that.mloading = false;
              find = false;
              break;
            } else if (cdata[_i2].gradeId == '' || cdata[_i2].gradeId == null) {
              that.$message.error('第' + (_i2 + 1) + '渠道等级不能为空，请重新配置！');
              that.mloading = false;
              find = false;
              break;
            } else if (cdata[_i2].rebateProductId == '' || cdata[_i2].rebateProductId == null) {
              that.$message.error('第' + (_i2 + 1) + '返利奖品不能为空，请重新配置！');
              that.mloading = false;
              find = false;
              break;
            } else if (cdata[_i2].buyNum > 0 && cdata[_i2].buyNum < 1) {
              that.$message.error('第' + (_i2 + 1) + '所买产品数必须为大于等于1的正整数，请重新配置！');
              that.mloading = false;
              find = false;
              break;
            } else if (cdata[_i2].buyNum - parseInt(cdata[_i2].buyNum) > 0) {
              that.$message.error('第' + (_i2 + 1) + '所卖产品数必须为正整数，请重新配置！');
              that.mloading = false;
              find = false;
              break;
            } else if (cdata[_i2].presentNum - parseInt(cdata[_i2].presentNum) > 0) {
              that.$message.error('第' + (_i2 + 1) + '所增产品数必须为正整数，请重新配置！');
              that.mloading = false;
              find = false;
              break;
            }
            for (var j = _i2 + 1; j < cdata.length; j++) {
              if (cdata[_i2].triggerAction == cdata[j].triggerAction && cdata[_i2].channelType == cdata[j].channelType && cdata[_i2].classId == cdata[j].classId && cdata[_i2].gradeId == cdata[j].gradeId && cdata[_i2].rebatePrerequisite == cdata[j].rebatePrerequisite && cdata[_i2].rebateProductId == cdata[j].rebateProductId) {
                find = false;
                that.$message.error('第' + (_i2 + 1) + '行与第' + (j + 1) + '行数据重复，请重新配置！');
                that.mloading = false;
                break;
              }
            }
          }
          if (!find) {
            that.mloading = false;
            return;
          }
          var proList = [];
          var configList = [];
          that.proList.forEach(function (item) {
            var obj = {};
            obj.measurementUnit = parseInt(item.measurementUnit);
            obj.productId = item.productId;
            obj.rebateProportion = Number(item.rebateProportion);
            proList.push(obj);
          });
          that.configData.forEach(function (item) {
            var obj = {};
            obj.buyNum = parseInt(item.buyNum);
            obj.channelType = item.channelType;
            obj.classId = item.classId;
            obj.gradeId = item.gradeId;
            obj.presentNum = parseInt(item.presentNum);
            obj.rebatePrerequisite = item.rebatePrerequisite;
            obj.rebateProductId = item.rebateProductId;
            obj.rebateType = item.rebateType;
            obj.triggerAction = item.triggerAction;
            configList.push(obj);
          });
          that.submitData.orgList = that.orgList;
          that.submitData.proList = proList;
          that.submitData.configList = configList;
          that.submitData.schemeState = parseInt(val);
          that.submitData.pid = that.pid;
          var sdata = (0, _utils.deepClone)(that.submitData);
          delete sdata.policyCode;
          (0, _param.saveHdFlData)(sdata).then(function (res) {
            if (res.data.code == 200) {
              that.mloading = false;
              that.$message({
                type: 'success',
                message: res.data.msg
              });
              that.handleClose();
              that.$parent.getList();
            } else {
              that.mloading = false;
              that.$message.error(res.data.msg);
            }
          }).catch(function () {
            that.mloading = false;
          });
        } else {
          console.log('error submit!!');
          that.mloading = false;
          return false;
        }
      });
    },
    getBuyInputNum: function getBuyInputNum(val, data) {
      var that = this;
      if (data.rebateProductId == '101' || data.productName.indexOf('现金') >= 0) {
        that.$message({
          type: 'error',
          message: '现金情况下，买只允许填写1'
        });
        data.buyNum = '1';
        return;
      }
      if (data.rebateProductId == '102' || data.productName.indexOf('酒券') >= 0) {
        that.$message({
          type: 'error',
          message: '酒券情况下，买只允许填写1'
        });
        data.buyNum = '1';
        return;
      }
    }
  }
};
exports.default = _default;