"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _admincut = _interopRequireDefault(require("@/components/admincut"));
var _tinyMce = _interopRequireDefault(require("@/components/tiny-mce"));
var _wxgzh = require("@/api/wxhyyy/wxgzh");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
/* eslint-disable vue/no-unused-components */
var _default = {
  components: {
    TinyMce: _tinyMce.default,
    admincut: _admincut.default
  },
  data: function data() {
    return {
      toolbar: "forecolor",
      // plugins: `forecolor`,
      disabled: false,
      linkform: '',
      color: '',
      input: '',
      input1: '',
      input2: '',
      input3: '',
      product: ''
    };
  },
  created: function created() {
    var that = this;
    (0, _wxgzh.moselectById)({
      id: sessionStorage.getItem('mbid')
    }).then(function (res) {
      var res = res.data;
      if (res.code === 200) {
        console.log(res);
        that.product = res.data;
        that.input = res.data.custom;
        that.linkform = res.data.type;
        if (res.data.type === '1') {
          that.input3 = res.data.url;
        } else {
          that.input1 = res.data.miniprogramAppid;
          that.input2 = res.data.miniprogramPagepath;
        }
        that.color = res.data.color;
        that.input = res.data.custom;
      } else {
        that.$message.info(res.msg);
      }
    });
  },
  methods: {
    save: function save() {
      var that = this;
      console.log(that.input);
      console.log(that.linkform);
      console.log(that.input3);
      console.log(that.input1);
      if (that.input !== null && (
      // that.color !== '' &&
      that.linkform === '1' && that.input3 !== '' || that.linkform === '2' && that.input1 !== null)) {
        if (that.linkform === '1') {
          var a = {
            custom: that.input,
            color: that.color,
            type: that.linkform,
            url: that.input3,
            id: sessionStorage.getItem('mbid')
          };
          console.log(a);
          (0, _wxgzh.mosave)(a).then(function (res) {
            var res = res.data;
            console.log(res);
            if (res.code === 200) {
              that.$message.success('保存成功');
              that.$router.push({
                name: 'temMsgg',
                params: {}
              });
            }
          });
        } else if (that.linkform === '2') {
          var b = {
            custom: that.input,
            color: that.color,
            type: that.linkform,
            miniprogramAppid: that.input1,
            id: sessionStorage.getItem('mbid')
          };
          console.log(b);
          (0, _wxgzh.mosave)(b).then(function (res) {
            var res = res.data;
            if (res.code === 200) {
              that.$message.success('保存成功');
              that.$router.push({
                name: 'temMsgg',
                params: {}
              });
            }
          });
        }
      } else {
        if (that.input === null) {
          that.$message.error('请填写内容');
          return;
        }
        // if (that.color === '') {
        //   that.$message.error('请选择颜色')
        //   return
        // }
        if (that.linkform === '1' && that.input3 === '' || that.linkform === '2' && that.input1 === null || that.linkform === null) {
          that.$message.error('请选择跳转链接');
          return;
        }
      }
    },
    back: function back() {
      this.$router.go(-1);
    }
  }
};
exports.default = _default;