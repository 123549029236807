var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { padding: "16px 15px", cursor: "pointer" },
      on: { click: _vm.toggleClick },
    },
    [
      _c("img", {
        staticClass: "hamburger",
        class: { "is-active": _vm.isActive },
        staticStyle: { width: "14px", height: "14px" },
        attrs: { src: require("../../images/gd.png") },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }