"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.abnormalOverview = abnormalOverview;
exports.antiFakeReport = antiFakeReport;
exports.dealerRank = dealerRank;
exports.dealerReport = dealerReport;
exports.dealerTendency = dealerTendency;
exports.productReport = productReport;
exports.reportCollect = reportCollect;
var _request = _interopRequireDefault(require("@/utils/request"));
// 概览

// 本月异常情况比例
function abnormalOverview(params) {
  return (0, _request.default)({
    url: '/storage-center/ccDasboard/abnormalOverview',
    method: 'get',
    params: params
  });
}

// 数据异常概况图表
function antiFakeReport(params) {
  return (0, _request.default)({
    url: '/storage-center/ccDasboard/antiFakeReport',
    method: 'get',
    params: params
  });
}

// cc_dasboard_dealerRank 经销商销售排行
function dealerRank(params) {
  return (0, _request.default)({
    url: '/storage-center/ccDasboard/dealerRank',
    method: 'get',
    params: params
  });
}

// 本年销量趋势
function dealerReport(params) {
  return (0, _request.default)({
    url: '/storage-center/ccDasboard/dealerReport',
    method: 'get',
    params: params
  });
}

//  销售数据趋势图
function dealerTendency(params) {
  return (0, _request.default)({
    url: '/storage-center/ccDasboard/dealerTendency',
    method: 'get',
    params: params
  });
}

// 本年产量趋势
function productReport(params) {
  return (0, _request.default)({
    url: '/storage-center/ccDasboard/productReport',
    method: 'get',
    params: params
  });
}

// 首页汇总
function reportCollect(params) {
  return (0, _request.default)({
    url: '/storage-center/ccDasboard/reportCollect',
    method: 'get',
    params: params
  });
}