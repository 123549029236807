"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.endPrize_Word = endPrize_Word;
exports.exportPrize_Word = exportPrize_Word;
exports.getPrizeList = getPrizeList;
exports.getPrize_Word_Detail = getPrize_Word_Detail;
exports.prizeRelevance = prizeRelevance;
exports.savePrize_Word = savePrize_Word;
exports.startPrize_Word = startPrize_Word;
var _request = _interopRequireDefault(require("@/utils/request"));
var base = '';
function savePrize_Word(data) {
  return (0, _request.default)({
    url: base + '/market-config/prize/savePrize_Word',
    method: 'post',
    data: data
  });
}
function getPrizeList(data) {
  return (0, _request.default)({
    url: base + '/market-config/prize/getPrizeList',
    method: 'post',
    data: data
  });
}
function startPrize_Word(params) {
  return (0, _request.default)({
    url: base + '/market-config/prize/startPrize',
    method: 'post',
    params: params
  });
}
function endPrize_Word(params) {
  return (0, _request.default)({
    url: base + '/market-config/prize/endPrize',
    method: 'post',
    params: params
  });
}
function getPrize_Word_Detail(params) {
  return (0, _request.default)({
    url: base + '/market-config/prize/getPrizeDetail',
    method: 'post',
    params: params
  });
}
function exportPrize_Word(data) {
  return (0, _request.default)({
    url: base + '/market-config/prize/getPrizeList_exp',
    responseType: 'blob',
    method: 'post',
    data: data
  });
}

// 获取奖品是否关联活动
function prizeRelevance(params) {
  return (0, _request.default)({
    url: base + '/market-config/prize/prizeRelevance',
    method: 'post',
    params: params
  });
}