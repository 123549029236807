"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addResource = addResource;
exports.checkRequiredWord = exports.checkNum = exports.checkId = void 0;
exports.deleteResource = deleteResource;
exports.editResource = editResource;
exports.exportSourceExcel = exportSourceExcel;
exports.getAllBtnIds = getAllBtnIds;
exports.getApis = getApis;
exports.getSysList = getSysList;
exports.getSystem = getSystem;
exports.queryResourceForList = queryResourceForList;
var _typeof2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
var _request = _interopRequireDefault(require("@/utils/request"));
// 获取系统
function getSystem(param) {
  return (0, _request.default)({
    url: '/api-user/user/comboBox/getAllClient',
    method: 'get',
    params: param
  });
}
// 查询资源菜单--一级一级的查找，不全部给出
function queryResourceForList(data) {
  return (0, _request.default)({
    url: '/api-user/resourceInfo/queryResourceForList',
    method: 'post',
    data: data
  });
}
// 获取资源列表
function getSysList(param) {
  return (0, _request.default)({
    url: '/api-user/resourceInfo/all',
    method: 'get',
    params: param
  });
}
// 添加资源
function addResource(data) {
  return (0, _request.default)({
    url: '/api-user/resourceInfo/addResource',
    method: 'post',
    data: data
  });
}
// 删除资源
function deleteResource(param) {
  return (0, _request.default)({
    url: '/api-user/resourceInfo/delResource',
    method: 'get',
    params: param
  });
}

// 修改资源
function editResource(data) {
  return (0, _request.default)({
    url: '/api-user/resourceInfo/modResource',
    method: 'post',
    data: data
  });
}

// 获取api列标
function getApis(param) {
  return (0, _request.default)({
    url: '/api-user/sys/apiInfo/getByResourceId',
    method: 'post',
    params: param
  });
}

// 获取该菜单下所有按钮
function getAllBtnIds(param) {
  return (0, _request.default)({
    url: '/api-user/resourceInfo/getAllButtonsByResourceCode',
    method: 'post',
    params: param
  });
}

// 导出
function exportSourceExcel(data) {
  return (0, _request.default)({
    url: '/api-user/resourceInfo/exportExcel',
    method: 'post',
    responseType: 'blob',
    data: data
  });
}
var checkRequiredWord = function checkRequiredWord(rule, value, callback) {
  if (value) {
    var reg = /^[A-Za-z0-9\u4e00-\u9fa5]+$/;
    if (reg.test(value)) {
      callback();
    } else {
      return callback(new Error('节点名称只能输入如汉字、字母、数字'));
    }
  } else {
    return callback(new Error('请输入节点名称'));
  }
};
exports.checkRequiredWord = checkRequiredWord;
var checkNum = function checkNum(rule, value, callback) {
  if (value) {
    console.log((0, _typeof2.default)(value));
    var reg = /^[0-9]*$/;
    if (value == null) {
      return callback(new Error('请输入正确的序号，正整数'));
    } else {
      if (reg.test(parseInt(value))) {
        callback();
      } else {
        return callback(new Error('请输入正确的序号，正整数'));
      }
    }
  } else {
    callback();
  }
};
exports.checkNum = checkNum;
var checkId = function checkId(rule, value, callback) {
  if (value) {
    var reg = /^[A-Za-z0-9]+$/;
    if (reg.test(value)) {
      callback();
    } else {
      return callback(new Error('资源ID只能输入字母、数字(建议输入节点名称首字母大写)'));
    }
  } else {
    return callback(new Error('请输入资源ID'));
  }
};
exports.checkId = checkId;