var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c("h3", [_vm._v("审核日志")]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.flowlistLoading,
              expression: "flowlistLoading",
            },
          ],
          key: _vm.flowTableKey,
          staticStyle: { width: "100%", "margin-top": "1px" },
          attrs: {
            size: "mini",
            data: _vm.flowList,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "序号",
              type: "index",
              width: "120",
              align: _vm.tableConfig.align,
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "审核节点",
              prop: "name",
              align: _vm.tableConfig.align,
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "审核结果",
              prop: "type",
              align: _vm.tableConfig.align,
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "审核人",
              prop: "userName",
              align: _vm.tableConfig.align,
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "审核时间",
              prop: "checkTime",
              align: _vm.tableConfig.align,
              "show-overflow-tooltip": "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "审核意见",
              prop: "auditmsg",
              align: _vm.tableConfig.align,
              "show-overflow-tooltip": "",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showflow ? _c("h3", [_vm._v("审核意见")]) : _vm._e(),
      _vm._v(" "),
      _vm.showflow
        ? _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.form.desc,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "desc", $$v)
                      },
                      expression: "form.desc",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm(1)
                        },
                      },
                    },
                    [_vm._v("审核通过")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.btn_loading },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm(2)
                        },
                      },
                    },
                    [_vm._v("审核驳回")]
                  ),
                  _vm._v(" "),
                  _c("el-button", { on: { click: _vm.handleClose } }, [
                    _vm._v("关 闭"),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }