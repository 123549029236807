"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
var _tgItem = require("@/api/chanelmange/tgItem");
var _dealer = require("@/api/basic/dealer");
var _utils = require("@/utils");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'tgdan',
  components: {
    Pagination: _Pagination.default,
    Treeselect: _vueTreeselect.default
  },
  props: {
    customVisible: Boolean
  },
  data: function data() {
    return {
      listQuery: {
        orgId: null,
        name: '',
        archivesStatus: 1,
        pageNo: 1,
        pageSize: 20
      },
      orgOptions: [],
      tableKey: 'tgdn',
      tabHeight: '100%',
      list: [],
      listLoading: false,
      total: 0
    };
  },
  mounted: function mounted() {
    var that = this;
    that.getOrg();
    that.getList();
  },
  filters: {
    formatTime: function formatTime(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        return (0, _utils.parseTime)(value, '{y}-{m}-{d}');
      }
    }
  },
  methods: {
    getList: function getList() {
      var that = this;
      that.listLoading = true;
      var param = {};
      param.orgId = that.listQuery.orgId;
      param.name = that.listQuery.name;
      param.archivesStatus = that.listQuery.archivesStatus;
      param.pageNo = that.listQuery.pageNo;
      param.pageSize = that.listQuery.pageSize;
      (0, _tgItem.getGroupList)(param).then(function (res) {
        if (res.data.code == 200) {
          that.list = res.data.data.records;
          that.total = parseInt(res.data.data.total);
          setTimeout(function () {
            that.listLoading = false;
          }, 1 * 800);
        } else {
          that.$message.error(res.data.msg);
          that.listLoading = false;
          return;
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    getOrg: function getOrg() {
      var that = this;
      (0, _tgItem.getQueryOrg)().then(function (res) {
        if (res.data.code == 200) {
          var rData = res.data.data;
          var arr = [];
          that.orgOriginData = rData;
          if (rData.length >= 0) {
            rData.forEach(function (item) {
              var obj = {};
              obj.id = item.pid;
              obj.label = item.orgName;
              obj.pid = item.pid;
              obj.parentPid = item.parentPid;
              arr.push(obj);
            });
            that.orgOptions = (0, _utils.transTreeData)(arr, 'pid', 'parentPid', 'children');
          }
        }
      });
    },
    DoSearch: function DoSearch() {
      var that = this;
      that.listQuery = (0, _utils.filterKeyNull)(that.listQuery);
      that.listQuery.pageNo = 1;
      that.listQuery.archivesStatus = 1;
      that.getList();
      that.listQuery.orgId = null;
    },
    DoReset: function DoReset(formName) {
      var that = this;
      that.listQuery.orgId = '';
      that.listQuery.pageNo = 1;
      that.listQuery.name = '';
      that.listQuery.archivesStatus = 1;
      that.getList();
    },
    handleClose: function handleClose() {
      var that = this;
      that.$emit('dialogHide');
    },
    DoSelect: function DoSelect(data) {
      var that = this;
      var param = data.pId;
      (0, _tgItem.getGroupDetail)(param).then(function (res) {
        if (res.data.code == 200) {
          that.$emit('select', JSON.stringify(res.data.data));
        } else {
          that.$emit('select', JSON.stringify(data));
        }
      }).catch(function (error) {
        that.$emit('select', JSON.stringify(data));
      });
    }
  }
};
exports.default = _default;