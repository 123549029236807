var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        { staticClass: "page-section" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "search-condition",
              attrs: {
                model: _vm.ruleForm,
                "status-icon": "",
                "label-position": _vm.formConfig.labelPosition,
                "label-width": _vm.formConfig.labelWidth,
                "label-suffix": ":",
              },
            },
            [
              _c(
                "div",
                { staticClass: "cols" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "返利名称/编码",
                                prop: "rebateCodeAndName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { clearable: "", placeholder: "请输入" },
                                model: {
                                  value: _vm.ruleForm.rebateCodeAndName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "rebateCodeAndName",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.rebateCodeAndName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "状态", prop: "activityStatus" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.ruleForm.activityStatus,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "activityStatus",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.activityStatus",
                                  },
                                },
                                _vm._l(
                                  _vm.activityStatus,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.name,
                                        value: item.value,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "关联方案", prop: "schemeName" },
                            },
                            [
                              _c("el-input", {
                                attrs: { clearable: "", placeholder: "请输入" },
                                model: {
                                  value: _vm.ruleForm.schemeName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "schemeName", $$v)
                                  },
                                  expression: "ruleForm.schemeName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "产品时间", prop: "rangeTime" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "daterange",
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  clearable: false,
                                },
                                model: {
                                  value: _vm.ruleForm.rangeTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "rangeTime", $$v)
                                  },
                                  expression: "ruleForm.rangeTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          staticClass: "head-btn-group",
                          style: { width: _vm.formConfig.btnArea },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { "label-width": "0" } },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "label" }, slot: "label" },
                                [_vm._v(" ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.searchBtn.type,
                                    size: _vm.btnConfig.size,
                                    icon: _vm.btnConfig.searchBtn.icon,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getList()
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.resetBtn.type,
                                    size: _vm.btnConfig.size,
                                    icon: _vm.btnConfig.resetBtn.icon,
                                  },
                                  on: { click: _vm.resetForm },
                                },
                                [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("el-divider", { staticClass: "btnDivider" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "btnArea" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: _vm.btnConfig.addBtn.type,
                    size: _vm.btnConfig.size,
                    icon: _vm.btnConfig.addBtn.icon,
                  },
                  on: { click: _vm.creatAct },
                },
                [_vm._v("\n        创建活动\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    type: _vm.btnConfig.downLoadBtn.type,
                    size: _vm.btnConfig.size,
                    icon: _vm.btnConfig.downLoadBtn.icon,
                    loading: _vm.downLoading,
                  },
                  on: { click: _vm.exportExcel },
                },
                [_vm._v("导出")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "section",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  key: _vm.tableKey,
                  staticStyle: { width: "100%" },
                  attrs: {
                    height: _vm.tabHeight,
                    data: _vm.list,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      width: "80",
                      label: "序号",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": "",
                      label: "返利方案编码",
                      align: _vm.tableConfig.align,
                      prop: "rebateCode",
                      "min-width": "140",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": "",
                      label: "返利方案名称",
                      align: _vm.tableConfig.align,
                      prop: "rebateSchemeName",
                      "min-width": "140",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": "",
                      label: "屏蔽终端数量",
                      align: _vm.tableConfig.align,
                      prop: "rebateStoreNum",
                      "min-width": "140",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": "",
                      label: "状态",
                      align: _vm.tableConfig.align,
                      "min-width": "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            row.activityStatus == "0"
                              ? _c("el-tag", [_vm._v("未开始")])
                              : _vm._e(),
                            _vm._v(" "),
                            row.activityStatus == "1"
                              ? _c("el-tag", { attrs: { type: "success" } }, [
                                  _vm._v("进行中"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            row.activityStatus == "2"
                              ? _c("el-tag", { attrs: { type: "danger" } }, [
                                  _vm._v("已结束"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            row.activityStatus == "3"
                              ? _c("el-tag", { attrs: { type: "warning" } }, [
                                  _vm._v("停用"),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  false
                    ? _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": "",
                          label: "审核",
                          align: _vm.tableConfig.align,
                          "min-width": "100",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  row.one4 == 0
                                    ? _c(
                                        "el-tag",
                                        { attrs: { type: "danger" } },
                                        [_vm._v("未审核")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.one4 == 1
                                    ? _c(
                                        "el-tag",
                                        { attrs: { type: "success" } },
                                        [_vm._v("已审核")]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2608305655
                        ),
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": "",
                      label: "关联方案",
                      align: _vm.tableConfig.align,
                      prop: "schemeName",
                      "min-width": "200",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": "",
                      label: "创建时间",
                      align: _vm.tableConfig.align,
                      prop: "createTime",
                      "min-width": "200",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": "",
                      label: "开始时间-结束时间",
                      align: _vm.tableConfig.align,
                      "min-width": "200",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("p", { staticStyle: { margin: "0" } }, [
                              _vm._v(_vm._s(row.startTime)),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticStyle: { margin: "0" } }, [
                              _vm._v(_vm._s(row.endTime)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      fixed: "right",
                      align: _vm.tableConfig.align,
                      width: "150",
                      "class-name": "small-padding fixed-width",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.look(scope.row, 1)
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  disabled:
                                    scope.row.activityStatus == "1" ||
                                    scope.row.activityStatus == "2",
                                  type: "text",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.edit(scope.row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "a-btn",
                                attrs: {
                                  disabled: scope.row.activityStatus == "2",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.setDisable(scope.row)
                                  },
                                },
                              },
                              [
                                scope.row.activityStatus == "0" ||
                                scope.row.activityStatus == "2" ||
                                scope.row.activityStatus == "3"
                                  ? _c("span", [_vm._v("启用")])
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.activityStatus == "1"
                                  ? _c("span", [_vm._v("停用")])
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            false
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { disabled: "", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.look(scope.row, 2)
                                      },
                                    },
                                  },
                                  [_vm._v("审核")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                  page: _vm.ruleForm.pageNo,
                  limit: _vm.ruleForm.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.ruleForm, "pageNo", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.ruleForm, "pageSize", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.dialogVisible
        ? _c("actConfig", {
            ref: "content",
            attrs: {
              "dialog-visible": _vm.dialogVisible,
              type: _vm.type,
              "act-id": _vm.actId,
              "show-only": _vm.readonly,
            },
            on: { close: _vm.close },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("detailsflow", { ref: "flowMain" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }