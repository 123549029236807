var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        { staticClass: "page-section", staticStyle: { "min-height": "86vh" } },
        [
          _c(
            "el-form",
            {
              ref: "searchForm",
              staticClass: "search-condition",
              attrs: { "status-icon": "" },
            },
            [
              _c(
                "div",
                { staticClass: "cols" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择", clearable: "" },
                              on: { change: _vm.changeSel, clear: _vm.clear },
                              model: {
                                value: _vm.queryList.templateCategory,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryList,
                                    "templateCategory",
                                    $$v
                                  )
                                },
                                expression: "queryList.templateCategory",
                              },
                            },
                            _vm._l(
                              _vm.templateCategorys,
                              function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticStyle: { padding: "20px" }, attrs: { gutter: 20 } },
            _vm._l(_vm.list, function (item, index) {
              return _c(
                "el-col",
                {
                  key: index,
                  staticClass: "creatItemWrap",
                  attrs: { span: 6 },
                },
                [
                  _c(
                    "el-card",
                    {
                      attrs: {
                        "body-style": { padding: "0px" },
                        shadow: "hover",
                      },
                    },
                    [
                      _c(
                        "HoverMask",
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "action",
                                fn: function () {
                                  return [
                                    _c(
                                      "el-row",
                                      { staticClass: "maskBtn" },
                                      [
                                        _c(
                                          "el-col",
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.creatAct(item)
                                                  },
                                                },
                                              },
                                              [_vm._v("预览")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        false
                                          ? _c(
                                              "el-col",
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.seltMy(
                                                          item.templateId
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("加入我的模板")]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c("div", { staticClass: "bkImg" }, [
                            item.templateType == "1"
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      height: "460px",
                                      overflow: "hidden",
                                    },
                                  },
                                  [
                                    _c("stard", {
                                      attrs: { data: item.templateElements },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.templateType == "2"
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      height: "460px",
                                      overflow: "hidden",
                                    },
                                  },
                                  [
                                    _c("rotaryTable", {
                                      attrs: { data: item.templateElements },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.templateType == "4"
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      height: "460px",
                                      overflow: "hidden",
                                    },
                                  },
                                  [
                                    _c("word", {
                                      attrs: { data: item.templateElements },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.templateType == "5"
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      height: "460px",
                                      overflow: "hidden",
                                    },
                                  },
                                  [
                                    _c("sudoku", {
                                      attrs: { data: item.templateElements },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.templateType == "6"
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      height: "460px",
                                      overflow: "hidden",
                                    },
                                  },
                                  [
                                    _c("scratchCard", {
                                      attrs: { data: item.templateElements },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.templateType == "7"
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      height: "460px",
                                      overflow: "hidden",
                                    },
                                  },
                                  [
                                    _c("eggTwistMachine", {
                                      attrs: { data: item.templateElements },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.templateType == "8"
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      height: "460px",
                                      overflow: "hidden",
                                    },
                                  },
                                  [
                                    _c("rotaryTable", {
                                      attrs: { data: item.templateElements },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.templateType == "0"
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      height: "460px",
                                      overflow: "hidden",
                                    },
                                  },
                                  [
                                    _c("focus", {
                                      attrs: { data: item.templateElements },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "button",
                                  staticStyle: { color: "#000" },
                                  attrs: { type: "text" },
                                },
                                [_vm._v(_vm._s(item.templateName))]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                "close-on-click-modal": false,
                title: "模板基本设置",
                visible: _vm.dialogVisible,
                "append-to-body": true,
                width: "80% !important",
                "before-close": _vm.close,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _vm.type == "1"
                ? _c("Content", {
                    ref: "content",
                    attrs: {
                      id: _vm.id,
                      type: _vm.type,
                      "edit-type": _vm.editType,
                      "edit-data": _vm.editData,
                      disabled: _vm.disabled,
                    },
                    on: { close: _vm.close },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.type == "2"
                ? _c("ContentRotaryTable", {
                    ref: "content",
                    attrs: {
                      id: _vm.id,
                      type: _vm.type,
                      "edit-type": _vm.editType,
                      "edit-data": _vm.editData,
                      disabled: _vm.disabled,
                    },
                    on: { close: _vm.close },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.type == "4"
                ? _c("Content2", {
                    ref: "content",
                    attrs: {
                      id: _vm.id,
                      type: _vm.type,
                      "edit-type": _vm.editType,
                      "edit-data": _vm.editData,
                      disabled: _vm.disabled,
                    },
                    on: { close: _vm.close },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.type == "5"
                ? _c("ContentSudoku", {
                    ref: "content",
                    attrs: {
                      id: _vm.id,
                      type: _vm.type,
                      "edit-type": _vm.editType,
                      "edit-data": _vm.editData,
                      disabled: _vm.disabled,
                    },
                    on: { close: _vm.close },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.type == "6"
                ? _c("ContentScratchCard", {
                    ref: "content",
                    attrs: {
                      id: _vm.id,
                      type: _vm.type,
                      "edit-type": _vm.editType,
                      "edit-data": _vm.editData,
                      disabled: _vm.disabled,
                    },
                    on: { close: _vm.close },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.type == "7"
                ? _c("ContentEggTwistMachine", {
                    ref: "content",
                    attrs: {
                      id: _vm.id,
                      type: _vm.type,
                      "edit-type": _vm.editType,
                      "edit-data": _vm.editData,
                      disabled: _vm.disabled,
                    },
                    on: { close: _vm.close },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.type == "8"
                ? _c("ContentRotaryTable2", {
                    ref: "content",
                    attrs: {
                      id: _vm.id,
                      type: _vm.type,
                      "edit-type": _vm.editType,
                      "edit-data": _vm.editData,
                      disabled: _vm.disabled,
                    },
                    on: { close: _vm.close },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.type == "0"
                ? _c("Content3", {
                    ref: "content",
                    attrs: {
                      id: _vm.id,
                      type: _vm.type,
                      "edit-type": _vm.editType,
                      "edit-data": _vm.editData,
                      disabled: _vm.disabled,
                    },
                    on: { close: _vm.close },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }