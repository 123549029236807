"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _dealer = require("@/api/basic/dealer");
var _org = require("@/api/basic/org");
var _channel = require("@/api/basic/channel");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'table-packset',
  components: {
    Pagination: _Pagination.default
  },
  props: {
    unitVisible: Boolean
  },
  data: function data() {
    return {
      props: {
        value: 'pId',
        label: 'orgName',
        children: 'children',
        checkStrictly: true
      },
      cityProps: {
        value: 'areaCode',
        label: 'areaName',
        children: 'children',
        checkStrictly: true
      },
      listLoading: false,
      tableKey: '',
      tabHeight: '100%',
      channelOrgId: [],
      classList: [],
      channelTypeOption: [{
        name: '经销商',
        code: 1
      }, {
        name: '终端',
        code: 2
      }],
      area: [],
      areaList: [],
      areaSel: [],
      searchForm: {
        keyword: '',
        archivesStatus: 1,
        channelType: '',
        channelOrgId: '',
        provinceCode: '',
        cityCode: '',
        countyCode: '',
        current: 1,
        size: 10
      },
      tableData: [],
      total: 0,
      orgList: [],
      normalList: [],
      orgInUseList: []
    };
  },
  mounted: function mounted() {
    var that = this;
    if (sessionStorage.getItem('areaList')) {
      that.areaList = [];
      that.areaList = (0, _utils.transTreeData)(JSON.parse(sessionStorage.getItem('areaList')), 'pId', 'parentId', 'children');
    } else {
      that.getAreaAll();
    }
    this.getClassAll();
    this.getOrgListAll();
  },
  watch: {
    unitVisible: function unitVisible(value) {
      var that = this;
      if (value == true) {
        that.searchForm.channelType = 2;
        this.getList();
      }
    }
  },
  methods: {
    //获取所有的渠道分类
    getClassAll: function getClassAll() {
      var that = this;
      (0, _channel.channelClassList)({
        pageNo: 0,
        pageSize: 0,
        channelType: 2
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.classList = response.data.data;
          } else {
            that.classList = [];
          }
        }
      }).catch(function (res) {});
    },
    //获取所有组织
    getOrgListAll: function getOrgListAll() {
      var _this = this;
      var that = this;
      (0, _org.fetchOrgList)({
        orgCodeAndName: '',
        orgStatus: 1
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          var that = _this;
          var datas = response.data.data;
          var Data = (0, _utils.transTreeData)(JSON.parse(JSON.stringify(datas)), 'pId', 'parentPid', 'children');
          that.orgList = JSON.parse(JSON.stringify(Data));
          that.normalList = JSON.parse(JSON.stringify(datas));
        }
      });
    },
    getList: function getList() {
      var that = this;
      that.listLoading = true;
      var submitData = (0, _utils.filterKeyNull)(that.searchForm);
      if (that.channelOrgId) {
        console.log(that.channelOrgId);
        submitData.channelOrgId = that.channelOrgId[that.channelOrgId.length - 1];
      }
      if (that.area) {
        submitData.provinceCode = that.area[0];
        submitData.cityCode = that.area[1] || '';
        submitData.countyCode = that.area[2] || '';
      }
      (0, _channel.channelArchivesList)(submitData).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.total = parseInt(response.data.data.total);
            that.tableData = response.data.data.records;
          } else {
            that.total = 0;
            that.tableData = [];
          }
          setTimeout(function () {
            that.listLoading = false;
          }, 1 * 500);
        }
      }).catch(function (res) {
        that.listLoading = false;
      });
    },
    //获取所有区域
    getAreaAll: function getAreaAll() {
      var that = this;
      (0, _dealer.dealerAreaList)({
        isDelete: 1
      }).then(function (res) {
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            that.areaList = (0, _utils.transTreeData)(JSON.parse(JSON.stringify(res.data.data)), 'pId', 'parentId', 'children');
          } else {
            that.areaList = [];
          }
          sessionStorage.setItem('areaList', JSON.stringify(res.data.data));
        }
      });
    },
    submitForm: function submitForm() {
      this.searchForm.current = 1;
      this.getList();
    },
    resetForm: function resetForm(formName) {
      this.searchForm = {
        keyword: '',
        archivesStatus: 1,
        channelOrgId: '',
        channelType: '',
        provinceCode: '',
        cityCode: '',
        countyCode: '',
        current: 1,
        size: 10
      };
      this.getList();
    },
    detail: function detail(row) {
      var that = this;
      that.$emit('select', JSON.stringify(row));
    },
    handleClose: function handleClose() {
      var that = this;
      that.$emit('dialogHide');
    }
  }
};
exports.default = _default;