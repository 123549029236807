"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.jcList = jcList;
exports.jcListExport = jcListExport;
exports.jcPcDetail = jcPcDetail;
var _request = _interopRequireDefault(require("@/utils/request"));
/*
 * @Author: your name
 * @Date: 2020-12-21 10:40:52
 * @LastEditTime: 2020-12-23 10:53:43
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \saas2.0\src\api\fake_expel\checkIn\index.js
 */

//  稽查入库查询
function jcList(params) {
  return (0, _request.default)({
    url: '/api-channel/bus/checkOrderInfo/jcList',
    method: 'get',
    params: params
  });
}

// 获取详情
function jcPcDetail(params) {
  return (0, _request.default)({
    url: '/api-channel/bus/checkOrderInfo/jcPcDetail',
    method: 'get',
    params: params
  });
}

// 导出
function jcListExport(data) {
  return (0, _request.default)({
    url: '/api-channel/bus/checkOrderInfo/jcListExport',
    method: 'get',
    responseType: 'blob',
    data: data
  });
}