var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _c(
      "div",
      { staticClass: "page-section" },
      [
        _c(
          "el-form",
          {
            ref: "formInline",
            staticClass: "search-condition",
            attrs: {
              model: _vm.formInline,
              "status-icon": "",
              "label-position": _vm.formConfig.labelPosition,
              "label-width": _vm.formConfig.labelWidth,
            },
          },
          [
            _c(
              "div",
              { staticClass: "cols" },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "活动名称:", prop: "" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { clearable: "", placeholder: "请选择" },
                              },
                              [
                                _c("el-option", {
                                  key: "0",
                                  attrs: { label: "全部", value: "" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "中奖状态:", prop: "" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { clearable: "", placeholder: "请选择" },
                              },
                              [
                                _c("el-option", {
                                  key: "0",
                                  attrs: { label: "全部", value: "" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: { "margin-top": "25px" },
                        attrs: { span: 6 },
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "", prop: "" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "手机号/姓名/数码" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "参与时间:", prop: "" } },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                type: "daterange",
                                format: "yyyy-MM-dd",
                                "value-format": "yyyy-MM-dd",
                                "range-separator": "至",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "结束日期",
                                clearable: "",
                              },
                              on: { change: _vm.sj },
                              model: {
                                value: _vm.rangeTime1,
                                callback: function ($$v) {
                                  _vm.rangeTime1 = $$v
                                },
                                expression: "rangeTime1",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.showCol
                      ? _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "数码经销商:", prop: "" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请选择",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      key: "0",
                                      attrs: { label: "全部", value: "" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showCol
                      ? _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "活动产品:" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请选择",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      key: "0",
                                      attrs: { label: "全部", value: "" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showCol
                      ? _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "openid" } },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "手机号/姓名/数码" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showCol
                      ? _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "IP查询" } },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "手机号/姓名/数码" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: _vm.btnConfig.searchBtn.type,
                          size: _vm.btnConfig.size,
                          icon: _vm.btnConfig.searchBtn.icon,
                        },
                      },
                      [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: _vm.btnConfig.resetBtn.type,
                          size: _vm.btnConfig.size,
                          icon: _vm.btnConfig.resetBtn.icon,
                        },
                      },
                      [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      { attrs: { type: "text" }, on: { click: _vm.expand } },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.expandTxt) +
                            "\n            "
                        ),
                        _c("i", {
                          class:
                            _vm.showCol == false
                              ? "el-icon-arrow-down"
                              : "el-icon-arrow-up",
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c("el-divider", { staticClass: "btnDivider" }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "btnArea" },
          [
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: _vm.btnConfig.downLoadBtn.type,
                          size: _vm.btnConfig.size,
                          icon: _vm.btnConfig.downLoadBtn.icon,
                          loading: _vm.downLoading,
                        },
                      },
                      [_vm._v(_vm._s(_vm.btnConfig.downLoadBtn.text))]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoading,
                expression: "listLoading",
              },
            ],
            staticStyle: { width: "100%" },
            attrs: {
              height: _vm.tabHeight,
              data: _vm.tableData,
              border: _vm.tableConfig.border,
              stripe: _vm.tableConfig.stripe,
              fit: "",
              "highlight-current-row": "",
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "code",
                label: "数码",
                width: "200",
                align: _vm.tableConfig.align,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "user",
                label: "扫码用户",
                width: "100",
                align: _vm.tableConfig.align,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "hd",
                label: "所属活动",
                width: "200",
                align: _vm.tableConfig.align,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "openid",
                label: "openid",
                width: "200",
                align: _vm.tableConfig.align,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "zjsl",
                label: "中奖数量(元)",
                width: "100",
                align: _vm.tableConfig.align,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "zjjg",
                label: "中奖结果",
                width: "100",
                align: _vm.tableConfig.align,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "jpxs",
                label: "奖品形式",
                width: "100",
                align: _vm.tableConfig.align,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "ljzt",
                label: "领奖状态",
                width: "100",
                align: _vm.tableConfig.align,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "id",
                label: "用户ID",
                width: "200",
                align: _vm.tableConfig.align,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "ip",
                label: "IP",
                width: "200",
                align: _vm.tableConfig.align,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "iphone",
                label: "联系方式",
                width: "150",
                align: _vm.tableConfig.align,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "address",
                label: "地址",
                width: "200",
                align: _vm.tableConfig.align,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "time",
                label: "参与活动时间",
                width: "200",
                align: _vm.tableConfig.align,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                fixed: "right",
                label: "操作",
                width: "150",
                align: _vm.tableConfig.align,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          staticClass: "a-btn",
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.handleClick(scope.row)
                            },
                          },
                        },
                        [_vm._v("详情")]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("pagination", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.total > 0,
              expression: "total > 0",
            },
          ],
          attrs: {
            layout: "total, sizes, prev, pager, next, jumper",
            total: _vm.total,
            page: _vm.params.pageNo,
            limit: _vm.params.pageSize,
          },
          on: {
            "update:page": function ($event) {
              return _vm.$set(_vm.params, "pageNo", $event)
            },
            "update:limit": function ($event) {
              return _vm.$set(_vm.params, "pageSize", $event)
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }