var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "请选择",
        visible: _vm.unitVisible,
        "before-close": _vm.handleClose,
        width: "1200px !important",
      },
      on: {
        "update:visible": function ($event) {
          _vm.unitVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "page-section" },
        [
          _c(
            "el-form",
            {
              ref: "searchForm",
              staticClass: "search-condition",
              attrs: { model: _vm.searchForm, "status-icon": "" },
            },
            [
              _c(
                "div",
                { staticClass: "filter-container" },
                [
                  _c(
                    "el-form",
                    { attrs: { model: _vm.searchForm, "label-width": "80px" } },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { prop: "keyword", label: "关键字:" },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "编码/名称",
                                    },
                                    model: {
                                      value: _vm.searchForm.keyword,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.searchForm, "keyword", $$v)
                                      },
                                      expression: "searchForm.keyword",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "keyword",
                                    label: "单位类型:",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.searchForm.channelType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "channelType",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.channelType",
                                      },
                                    },
                                    _vm._l(_vm.types, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { "label-width": "20px" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        size: "mini",
                                        icon: "el-icon-search",
                                      },
                                      on: { click: _vm.submitForm },
                                    },
                                    [_vm._v("查询")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        icon: "el-icon-refresh",
                                      },
                                      on: { click: _vm.resetForm },
                                    },
                                    [_vm._v("重置")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "section",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  key: _vm.tableKey,
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.tableData,
                    border: "",
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "单位编码",
                      prop: "channelCode",
                      align: "center",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "单位名称",
                      prop: "channelName",
                      align: "center",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "单位类型",
                      prop: "channelType",
                      align: "center",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            row.channelType == 1
                              ? _c("span", [_vm._v("经销商")])
                              : _vm._e(),
                            _vm._v(" "),
                            row.channelType == 2
                              ? _c("span", [_vm._v("终端")])
                              : _vm._e(),
                            _vm._v(" "),
                            row.channelType == 22
                              ? _c("span", [_vm._v("销售公司")])
                              : _vm._e(),
                            _vm._v(" "),
                            row.channelType == 33
                              ? _c("span", [_vm._v("生产公司")])
                              : _vm._e(),
                            _vm._v(" "),
                            row.channelType == 3
                              ? _c("span", [_vm._v("团购客户")])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  false
                    ? _c("el-table-column", {
                        attrs: {
                          label: "联系人",
                          prop: "contacts",
                          align: "center",
                          "show-overflow-tooltip": "",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  false
                    ? _c("el-table-column", {
                        attrs: {
                          label: "手机号",
                          prop: "phone",
                          align: "center",
                          "show-overflow-tooltip": "",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      fixed: "right",
                      align: "center",
                      width: "80",
                      "class-name": "small-padding fixed-width",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.detail(row)
                                  },
                                },
                              },
                              [_vm._v("选择")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                  page: _vm.searchForm.current,
                  limit: _vm.searchForm.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.searchForm, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.searchForm, "size", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }