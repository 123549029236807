"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _checkout = require("@/api/warehouse/checkout");
var _dictionary = require("@/api/systems/dictionary");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var TypeDataComs = {
  status: [],
  source: [],
  factory: [],
  djzt: []
};
var _default = {
  name: 'TableSuply',
  components: {
    Pagination: _Pagination.default
  },
  filters: {
    packLevel: function packLevel(status) {
      if (!String(status)) return '未知';
      var statusMap = {
        1: '盖码',
        2: '瓶码',
        3: '盒码',
        4: '箱码',
        5: '托码'
      };
      return statusMap[status];
    },
    // 操作类型
    operateType: function operateType(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          1: '创建单据',
          2: '修改单据',
          3: '删除单据',
          4: '拆单',
          5: '审核不通过',
          6: '审核通过',
          7: '单据下载',
          8: '单据扫描',
          9: '正常结单',
          10: '强制结单',
          11: '撤销下载',
          12: '撤销结单'
        };
        return statusMap[value];
      }
    },
    typeClass: function typeClass(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          1: 'primary',
          2: 'primary',
          3: 'primary',
          4: 'primary',
          5: 'primary',
          6: 'primary',
          7: 'success',
          8: 'warning',
          9: 'danger',
          10: 'info',
          11: 'info',
          13: 'success'
        };
        return statusMap[value];
      }
    },
    scanStatus: function scanStatus(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          1: '待扫描',
          2: '部分扫描',
          3: '扫描完成'
        };
        return statusMap[value];
      }
    },
    TypeDataCom: function TypeDataCom(code, keyName) {
      var vars = TypeDataComs[keyName];
      var name = '';
      vars.forEach(function (c) {
        if (c.figure == code) {
          name = c.name;
          return;
        }
      });
      return name;
    }
  },
  props: {},
  data: function data() {
    return {
      activeName: '',
      treeProps: {
        children: 'children',
        hasChildren: 'hasChildren'
      },
      num: '',
      active: 0,
      codetotal: 0,
      detailVisible: false,
      codeVisible: false,
      mloading: false,
      listLoading: false,
      codeListLoading: false,
      scanData: {},
      detailForm: {
        billNo: '',
        productId: '',
        current: 1,
        size: 10
      },
      codeList: [],
      temp: {
        tradeBillAllVO: {},
        tradeOperateLogDTOS: [],
        tradeProductVOS: []
      },
      flowList: [{
        name: '创建',
        userName: '',
        dateCreate: ''
      }, {
        name: '下载',
        userName: '',
        dateCreate: ''
      }, {
        name: '扫描',
        userName: '',
        dateCreate: ''
      }, {
        name: '结单',
        userName: '',
        dateCreate: ''
      }],
      settype: 2,
      showflow: false,
      setinfo: false // 查看详情显示字段
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.getDirSel('1265249615728934914', 'djzt');
  },
  methods: {
    /**
     * settype 1:拆单，2：查看，3：审核
     */
    show: function show(rows, settype) {
      this.settype = settype;
      this.showflow = false;
      if (settype == 3) {
        this.showflow = true;
      }
      this.detailVisible = true;
      this.mloading = true;
      this.num = rows.num;
      this.mloading = false;
      this.getDetail(rows.billNo);
    },
    getDetail: function getDetail(billNo) {
      var _this = this;
      (0, _checkout.getPeoductionDetail)(billNo).then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
        } else {
          _this.temp = res.data.data;
          switch (res.data.data.tradeBillAllVO.billStatus) {
            case 1:
              _this.active = 0;
              break;
            case 2:
              _this.active = 0;
              break;
            case 3:
              _this.active = 1;
              break;
            case 4:
              _this.active = 2;
              break;
            case 5:
              _this.active = 2;
              break;
            case 6:
              _this.active = 3;
              break;
            case 7:
              _this.active = 4;
              break;
            case 8:
              _this.active = 4;
              break;
            case 13:
              _this.active = 4;
              break;
            default:
              _this.active = 0;
          }
        }
      });
    },
    getDirSel: function getDirSel(pid, list) {
      var _this2 = this;
      (0, _dictionary.getDataDictionaryItemList)({
        isEnable: 1,
        pid: pid,
        current: 1,
        size: 2112211
      }).then(function (res) {
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            TypeDataComs[list] = res.data.data.records;
          } else {
            TypeDataComs[list] = [];
          }
        }
      });
      // storeTypeInfoList(type)
      //   .then(res => {
      //     if (res.data.code != 200) {
      //       that.TypeDataComs[keyName] = []
      //       return
      //     } else {
      //       that.TypeDataComs[keyName] = res.data.data
      //     }
      //   })
      //   .catch(() => {
      //     that.TypeDataComs[keyName] = []
      //   })
    },
    handleClose: function handleClose() {
      this.authId('searchbtn', this.$route.meta.authList);
      this.activeName = '';
      this.detailVisible = false;
    },
    codeClose: function codeClose() {
      this.detailForm = {
        billNo: '',
        productId: '',
        current: 1,
        size: 10
      };
      this.codeList = [];
      this.codetotal = 0;
      this.codeVisible = false;
    },
    codeDetail: function codeDetail(row) {
      this.codeVisible = true;
      this.codeListLoading = true;
      this.detailForm.billNo = row.billNo;
      this.detailForm.productId = row.productId;
      this.getCodeList();
    },
    getCodeList: function getCodeList() {
      var _this3 = this;
      (0, _checkout.queryScanMark)(this.detailForm).then(function (res) {
        if (res.data.code != 200) {
          _this3.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            if (res.data.data.records.length > 0) {
              res.data.data.records.forEach(function (item) {
                if (item.packLevel > 2) {
                  item.hasChildren = true;
                }
              });
            }
            _this3.codeList = res.data.data.records;
            _this3.codetotal = parseInt(res.data.data.total);
          } else {
            _this3.codeList = [];
            _this3.codetotal = 0;
          }
        }
        _this3.codeListLoading = false;
      }).catch(function () {
        _this3.codeListLoading = false;
      });
    },
    load: function load(tree, treeNode, resolve) {
      var _this4 = this;
      (0, _checkout.queryChildMark)({
        billNo: this.detailForm.billNo,
        parentMarkCode: tree.markCode,
        productId: this.detailForm.productId
      }).then(function (res) {
        if (res.data.code != 200) {
          _this4.$message.error(res.data.msg);
          resolve([]);
        } else {
          if (res.data.data.length > 0) {
            res.data.data.forEach(function (item) {
              if (item.packLevel > 2) {
                item.hasChildren = true;
              }
            });
          }
          resolve(res.data.data);
        }
      }).catch(function () {
        resolve([]);
      });
    }
  }
};
exports.default = _default;