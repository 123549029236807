"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRuleDetail = getRuleDetail;
exports.saveGlobalRule = saveGlobalRule;
var _request = _interopRequireDefault(require("@/utils/request"));
var base = '';
function getRuleDetail(params) {
  return (0, _request.default)({
    url: base + '/market-config/globalRuleInfo/ruleDetail',
    method: 'get',
    params: params
  });
}
function saveGlobalRule(data) {
  return (0, _request.default)({
    url: base + '/market-config/globalRuleInfo/saveOrUpdate',
    method: 'post',
    data: data
  });
}