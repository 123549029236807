"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _echarts = _interopRequireDefault(require("echarts"));
var _resize = _interopRequireDefault(require("./mixins/resize"));
//
//
//
//

require('echarts/theme/macarons'); // echarts theme
var _default = {
  mixins: [_resize.default],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    },
    autoResize: {
      type: Boolean,
      default: true
    },
    chartData: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler: function handler(val) {
        console.log(val);
        this.setOptions(val);
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.initChart();
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = _echarts.default.init(this.$el, 'macarons');
      this.setOptions(this.chartData);
    },
    setOptions: function setOptions() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        legendData = _ref.legendData,
        seriesData = _ref.seriesData;
      this.chart.setOption({
        legend: {
          data: legendData,
          bottom: '2%'
        },
        color: ['#409EFF', '#263445'],
        xAxis: {
          type: 'category',
          boundaryGap: [0, 0.01],
          axisTick: {
            show: true,
            alignWithLabel: true
          },
          axisLabel: {
            interval: 0
          },
          data: ['超过3个月', '3个月内', '1个月内', '20天内', '10天内', '1天']
        },
        yAxis: {
          type: 'value',
          max: function max(value) {
            return value.max + 10;
          }
        },
        series: [{
          name: legendData[0],
          data: seriesData[0],
          smooth: true,
          type: 'bar'
        }, {
          name: legendData[1],
          data: seriesData[1],
          smooth: true,
          type: 'bar'
        }]
      });
    }
  }
};
exports.default = _default;