"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Pagination',
  props: {
    data: {
      default: '',
      required: true
    },
    text: {
      required: true,
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    limitCount: {
      default: 1,
      type: Number
    }
  },
  data: function data() {
    return {
      // limitCount: 1,
      Listarr: [],
      fileList: [],
      hideUpload: false,
      myHeaders: {
        Authorization: 'Bearer ' + (0, _auth.getToken)()
      }
    };
  },
  computed: {
    // 设置上传地址
    uploadUrl: function uploadUrl() {
      // return process.env.VUE_APP_BASE_API + '/user/api-uspyx/pc/template/upload'
      return process.env.VUE_APP_BASE_API + '/api-basic/jc/file/upload/uploadImg';
    },
    listdata: {
      get: function get() {
        return this.data;
      },
      set: function set(val) {
        this.$emit('update:data', val);
        this.$emit('getdata', val);
      }
    }
  },
  methods: {
    handleAvatarSuccess: function handleAvatarSuccess(res, file, fileList) {
      if (res.code * 1 == 200) {
        // this.src = res.data;
        // this.name = file.name;
        console.log(fileList);
        var arr = [];
        fileList.forEach(function (item, index) {
          var obj = {
            imgName: item.name ? item.name : '',
            imgAddress: item.raw ? item.response.data : item.url,
            imgOrder: (index + 1).toString()
          };
          arr.push(obj);
        });
        this.listdata = arr;
        this.$message.closeAll();
        this.$message({
          message: res.msg,
          type: 'success',
          duration: 3 * 1000
        });
      } else {
        this.$message.closeAll();
        this.$message({
          message: res.msg,
          type: 'error',
          duration: 3 * 1000
        });
      }
    },
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      // const isImg = file.type === 'image/png'
      var isLt2M = file.size / 1024 / 1024 < 0.5;
      // if (!isImg) {
      //   this.$message.error('上传图片只能是 PNG/JPG 格式!')
      // }
      if (!isLt2M) {
        this.$message.closeAll();
        this.$message.error('上传图片大小不能超过 500kb!');
      }
      return isLt2M;
    },
    selectImg: function selectImg(file, fileList) {
      this.hideUpload = fileList.length >= this.limitCount;
      this.fileList = fileList;
    },
    checkType: function checkType() {
      if (this.disabled) {
        return false;
      }
    },
    handleRemove: function handleRemove(file, fileList) {
      if (!this.disabled) {
        this.hideUpload = fileList.length >= this.limitCount;
        this.fileList = fileList;
        var src = '';
        if (this.fileList && this.fileList.length > 0) {
          this.fileList.forEach(function (v, k) {
            if (v.response) {
              v.url = v.response.data;
            }
            if (v.url) {
              if (k == 0) {
                src += v.url;
              } else {
                src += ',' + v.url;
              }
            }
          });
        }
        this.src = src;
        this.name = name;
      }
    },
    handleError: function handleError(res, file) {
      var _this = this;
      if (res.response.status == 401) {
        // 没有token失效的情况下
        if (res.response.data.resp_code == '401') {
          this.$msgbox({
            title: '提示',
            dangerouslyUseHTMLString: true,
            message: '登录超时,请您点击确定,重新登录!',
            showClose: false,
            showCancelButton: false,
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            beforeClose: function beforeClose(action, instance, done) {
              if (action === 'confirm') {
                _this.$store.dispatch('tagsView/delAllViews', null, {
                  root: true
                });
                _this.$router.push('/');
                _this.$store.dispatch('user/logout');
                window.location.reload();
                done();
              } else {
                done();
              }
            }
          });
          return;
        }
        this.$message.error(res.response.data.msg);
      }
    }
  }
};
exports.default = _default;