"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _product = require("@/api/basic/product");
var _user = require("@/api/user");
var _auth = require("@/utils/auth");
var _pack = require("@/api/basic/pack");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'TablePackset',
  components: {
    Pagination: _Pagination.default
  },
  props: {
    mutipleVisible: Boolean
  },
  data: function data() {
    return {
      listLoading: false,
      tableKey: '111',
      tabHeight: '100%',
      filterText: '',
      activeClass: '',
      searchForm: {
        current: 1,
        size: 10,
        archivesStatus: '',
        productBrandId: '',
        productClassId: '',
        productCode: '',
        productName: '',
        containsPackScale: 1
      },
      tableData: [],
      total: 0,
      props: {
        label: 'name',
        children: 'children',
        isLeaf: 'leaf'
      },
      classList: [],
      normalList: [],
      brandList: [],
      packList: [],
      uploadForm: {
        isFuGai: '',
        uploadFile: ''
      },
      uploadRules: {},
      fileList: [],
      multipleSelection: [],
      hasSelect: [],
      activtyId: ''
    };
  },
  watch: {
    productsVisible: function productsVisible(val) {
      var that = this;
      if (val == true) {
        that.getList();
      }
    }
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
    this.getClass();
    this.getBrand();
    this.getPack();
  },
  methods: {
    selecteChange: function selecteChange(val) {
      this.fileList = JSON.parse(JSON.stringify(this.fileList));
    },
    getClass: function getClass() {
      var that = this;
      (0, _product.productClassList)({
        classStatus: 1
      }).then(function (res) {
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            var data = (0, _utils.transTreeData)(JSON.parse(JSON.stringify(res.data.data)), 'pId', 'parentId', 'children');
            that.normalList = res.data.data;
            that.classList = data;
          } else {
            that.normalList = [];
            that.classList = [];
          }
        }
      });
    },
    getBrand: function getBrand() {
      var _this = this;
      (0, _product.productBrandAllListList)().then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            _this.brandList = res.data.data;
          } else {
            _this.brandList = [];
          }
        }
      });
    },
    getPack: function getPack() {
      var _this2 = this;
      (0, _pack.packScaleLeveAlllList)().then(function (res) {
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            _this2.packList = res.data.data;
          } else {
            _this2.packList = [];
          }
        }
      });
    },
    getList: function getList() {
      var _this3 = this;
      var that = this;
      this.listLoading = true;
      var submitData = (0, _utils.filterKeyNull)(that.searchForm);
      (0, _product.productArchivesList)(submitData).then(function (res) {
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            that.tableData = res.data.data.records;
            that.total = Number(res.data.data.total);
          } else {
            that.total = 0;
            that.tableData = [];
          }
        }
        _this3.listLoading = false;
      });
    },
    resetForm: function resetForm(formName) {
      this.searchForm = {
        current: 1,
        size: 10,
        archivesStatus: 1,
        productBrandId: '',
        productClassId: '',
        productCode: '',
        productName: '',
        containsPackScale: 1
      };
      this.filterText = '';
      this.handleFilter();
      this.getList();
    },
    filterNode: function filterNode(value, data) {
      if (!value) return true;
      return data.productClassName.indexOf(value) !== -1;
    },
    handleFilter: function handleFilter() {
      this.$refs.tree.filter(this.filterText);
    },
    scan: function scan(data) {
      if (this.activtyId != data.pId && (!data.children || data.pId)) {
        this.searchForm = {
          current: 1,
          size: 10,
          archivesStatus: 1,
          productBrandId: '',
          productClassId: data.pId == 1 ? '' : data.pId,
          productCode: '',
          productName: '',
          containsPackScale: 1
        };
        this.getList();
      }
    },
    filterparentList: function filterparentList(val) {
      var that = this;
      return this.normalList.filter(function (number) {
        if (number.pId == val) {
          that.ruleForm.productClassId.unshift(val);
          if (number.parentId) {
            that.filterparentList(number.parentId);
          }
        }
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      var that = this;
      that.multipleSelection = val;
      if (val.length > 0) {
        val.forEach(function (item) {
          that.hasSelect.push(item);
        });
        that.hasSelect = that.uniqueArray(that.hasSelect, 'pId');
      }
    },
    // json数组去重
    uniqueArray: function uniqueArray(array, key) {
      var result = [array[0]];
      for (var i = 1; i < array.length; i++) {
        var item = array[i];
        var repeat = false;
        for (var j = 0; j < result.length; j++) {
          if (item[key] == result[j][key]) {
            repeat = true;
            break;
          }
        }
        if (!repeat) {
          result.push(item);
        }
      }
      return result;
    },
    handleClose: function handleClose() {
      var that = this;
      that.searchForm.current = 1;
      that.searchForm.archivesStatus = '';
      that.searchForm.productBrandId = '';
      that.searchForm.productClassId = '';
      that.searchForm.productCode = '';
      that.searchForm.productName = '';
      that.searchForm.containsPackScale = 1;
      that.filterText = '';
      that.multipleSelection = [];
      that.hasSelect = [];
      that.$emit('dialogHide');
    },
    DoClose: function DoClose() {
      var that = this;
      that.handleClose();
    },
    DoSelect: function DoSelect() {
      var that = this;
      var data = that.hasSelect;
      that.$emit('select', JSON.stringify(data));
    },
    DoEmpty: function DoEmpty() {
      var that = this;
      that.hasSelect = [];
      that.multipleSelection = [];
    },
    getState: function getState(val) {},
    DoCancel: function DoCancel(index) {
      var that = this;
      that.hasSelect.splice(index, 1);
    },
    onTableSelect: function onTableSelect(rows, row) {
      var that = this;
      var selected = rows.length && rows.indexOf(row) !== -1;
      if (selected != true) {
        if (that.hasSelect.length > 0) {
          for (var i = 0; i < that.hasSelect.length; i++) {
            if (that.hasSelect[i].pId == row.pId) {
              that.hasSelect.splice(i, 1);
            }
          }
          that.hasSelect = that.hasSelect;
        }
      }
    }
  }
};
exports.default = _default;