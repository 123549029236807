var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        "append-to-body": true,
        title: "数码详情",
        visible: _vm.codeVisible,
        "before-close": _vm.selCancel,
        width: "1200px !important",
      },
      on: {
        "update:visible": function ($event) {
          _vm.codeVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "row-key": "markCode",
                height: "440px",
                border: _vm.tableConfig.border,
                stripe: _vm.tableConfig.stripe,
                fit: "",
                "highlight-current-row": "",
                lazy: "",
                load: _vm.load,
                "tree-props": {
                  children: "children",
                  hasChildren: "hasChildren",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "序号",
                  width: "120",
                  align: _vm.tableConfig.align,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.seqNo))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "层级",
                  prop: "packLevel",
                  align: _vm.tableConfig.align,
                  width: "120",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.packLevel == "4"
                          ? _c("span", [_vm._v("箱")])
                          : _vm._e(),
                        _vm._v(" "),
                        row.packLevel == "3"
                          ? _c("span", [_vm._v("盒")])
                          : _vm._e(),
                        _vm._v(" "),
                        row.packLevel == "2"
                          ? _c("span", [_vm._v("瓶")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "数码",
                  "min-width": "100",
                  "show-overflow-tooltip": "",
                  prop: "markCode",
                  align: _vm.tableConfig.align,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              page: _vm.searchForm.current,
              limit: _vm.searchForm.size,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.searchForm, "current", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.searchForm, "size", $event)
              },
              pagination: _vm.getList,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }