var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cardBox" }, [
    _c(
      "div",
      { staticClass: "app-container jifen-rules" },
      [
        _c(
          "el-row",
          [
            _c(
              "el-col",
              [
                _c(
                  "el-card",
                  { staticClass: "box-card" },
                  [
                    _c("div", { staticClass: "hyyy-top-tit" }, [
                      _c("div", { staticClass: "hyyy-top-tit-lift" }, [
                        _c("span", {
                          domProps: {
                            textContent: _vm._s(_vm.$route.meta.title),
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "hyyy-top-tit-right" }),
                    ]),
                    _vm._v(" "),
                    _c("el-divider", { staticStyle: { margin: "0" } }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "hyyy-container" },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c("h4", [_vm._v("小程序信息")]),
                                _vm._v(" "),
                                _c(
                                  "el-form",
                                  {
                                    attrs: {
                                      model: _vm.form,
                                      "label-width": "120px",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "小程序Appid" } },
                                      [
                                        _c("el-input", {
                                          staticClass: "xcxinp",
                                          staticStyle: { width: "300px" },
                                          model: {
                                            value: _vm.form.appid,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.form, "appid", $$v)
                                            },
                                            expression: "form.appid",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "AppSecret" } },
                                      [
                                        _c("el-input", {
                                          staticClass: "xcxinp",
                                          staticStyle: { width: "300px" },
                                          model: {
                                            value: _vm.form.appsecret,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "appsecret",
                                                $$v
                                              )
                                            },
                                            expression: "form.appsecret",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("el-divider"),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("h4", [_vm._v("获取积分规则")]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "hyyy-main" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        model: {
                                          value: _vm.form.isGmsp,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "isGmsp", $$v)
                                          },
                                          expression: "form.isGmsp",
                                        },
                                      },
                                      [_vm._v("购买商品")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-row",
                                      _vm._l(
                                        _vm.form.moWxRuleMoneyDOList,
                                        function (item, index) {
                                          return _c(
                                            "el-col",
                                            {
                                              key: index,
                                              staticClass: "colpl",
                                              attrs: { span: 24 },
                                            },
                                            [
                                              _c("span", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "20px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                          每消费\n                          "
                                                    ),
                                                    _c("el-input", {
                                                      staticStyle: {
                                                        width: "80px",
                                                      },
                                                      on: {
                                                        input: function (e) {
                                                          return (_vm.form.moWxRuleMoneyDOList[
                                                            index
                                                          ].je = _vm.numFun(e))
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form
                                                            .moWxRuleMoneyDOList[
                                                            index
                                                          ].je,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form
                                                              .moWxRuleMoneyDOList[
                                                              index
                                                            ],
                                                            "je",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.moWxRuleMoneyDOList[index].je",
                                                      },
                                                    }),
                                                    _vm._v(
                                                      "\n\n                          元，可获得\n                          "
                                                    ),
                                                    _c("el-input", {
                                                      staticStyle: {
                                                        width: "80px",
                                                      },
                                                      on: {
                                                        input: function (e) {
                                                          return (_vm.form.moWxRuleMoneyDOList[
                                                            index
                                                          ].hdjf =
                                                            _vm.numFun(e))
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form
                                                            .moWxRuleMoneyDOList[
                                                            index
                                                          ].hdjf,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form
                                                              .moWxRuleMoneyDOList[
                                                              index
                                                            ],
                                                            "hdjf",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.moWxRuleMoneyDOList[index].hdjf",
                                                      },
                                                    }),
                                                    _vm._v(
                                                      "积分\n                          "
                                                    ),
                                                    _c("i", {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm.form
                                                              .moWxRuleMoneyDOList
                                                              .length > 1,
                                                          expression:
                                                            "form.moWxRuleMoneyDOList.length > 1",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "el-icon-remove-outline",
                                                      attrs: { size: "small" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.reduceshoplist(
                                                            index,
                                                            _vm.form
                                                              .moWxRuleMoneyDOList
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-link",
                                                      {
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.addshoplist(
                                                              index,
                                                              _vm.form
                                                                .moWxRuleMoneyDOList
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("添加门槛")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("span"),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "colpl",
                                            attrs: { span: 24 },
                                          },
                                          [
                                            _c("p", [
                                              _vm._v("如有商城可进行关联"),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "colpl",
                                            staticStyle: {
                                              "margin-bottom": "30px",
                                            },
                                            attrs: { span: 24 },
                                          },
                                          [
                                            _vm._v(
                                              "\n                      小程序Appid："
                                            ),
                                            _c("el-input", {
                                              staticStyle: { width: "300px" },
                                              model: {
                                                value: _vm.form.glAppid,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "glAppid",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.glAppid",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "p",
                                              { staticClass: "formTip" },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-warning-outline",
                                                }),
                                                _vm._v(
                                                  "可在微信公众平台-小程序后台>开发>开发设置找到对应的APPID和小程序路径。（前提：该公众号要绑定小程序才可以跳转）\n                      "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-checkbox",
                                      {
                                        model: {
                                          value: _vm.form.isFwyz,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "isFwyz", $$v)
                                          },
                                          expression: "form.isFwyz",
                                        },
                                      },
                                      [_vm._v("防伪验证")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "colpl" },
                                      [
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 24 } },
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "20px",
                                                    },
                                                    attrs: {
                                                      type: "primary",
                                                      size: "mini",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.routeTo(0)
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("添 加 规 则")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-table",
                                                  {
                                                    attrs: {
                                                      data: _vm.list,
                                                      border: "",
                                                      width: "100%",
                                                      "header-cell-style": {
                                                        "background-color":
                                                          "#fafafa",
                                                        color:
                                                          "rgba(0, 0, 0,0.85)",
                                                        "font-weight": "500",
                                                        "text-align": "center",
                                                      },
                                                      "cell-style": {
                                                        "text-align": "center",
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        prop: "name",
                                                        label: "规则名称",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        prop: "amount",
                                                        label: "扫码得积分值",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        prop: "statuse",
                                                        label: "状态",
                                                      },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "default",
                                                          fn: function (scope) {
                                                            return [
                                                              _c("div", [
                                                                _vm._v(
                                                                  "\n                                " +
                                                                    _vm._s(
                                                                      scope.row
                                                                        .statuse ===
                                                                        "1"
                                                                        ? "开启"
                                                                        : " 关闭"
                                                                    ) +
                                                                    "\n                              "
                                                                ),
                                                              ]),
                                                            ]
                                                          },
                                                        },
                                                      ]),
                                                    }),
                                                    _vm._v(" "),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        prop: "address",
                                                        label: "操作",
                                                        align: "center",
                                                        width: "340",
                                                      },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "default",
                                                          fn: function (scope) {
                                                            return [
                                                              _c(
                                                                "el-button",
                                                                {
                                                                  staticStyle: {
                                                                    margin:
                                                                      "5px",
                                                                  },
                                                                  attrs: {
                                                                    type: "success",
                                                                    size: "mini",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.routeTo(
                                                                          2,
                                                                          scope
                                                                            .row
                                                                            .id
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [_vm._v("详情")]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "el-button",
                                                                {
                                                                  staticStyle: {
                                                                    margin:
                                                                      "5px",
                                                                  },
                                                                  attrs: {
                                                                    type: "primary",
                                                                    size: "mini",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.routeTo(
                                                                          1,
                                                                          scope
                                                                            .row
                                                                            .id
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [_vm._v("编辑")]
                                                              ),
                                                              _vm._v(" "),
                                                              scope.row
                                                                .statuse === "2"
                                                                ? _c(
                                                                    "el-button",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          margin:
                                                                            "5px",
                                                                        },
                                                                      attrs: {
                                                                        type: "primary",
                                                                        size: "mini",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.switchFun(
                                                                              "1",
                                                                              scope
                                                                                .row
                                                                                .id
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "开启"
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _c(
                                                                    "el-button",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          margin:
                                                                            "5px",
                                                                        },
                                                                      attrs: {
                                                                        type: "primary",
                                                                        size: "mini",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.switchFun(
                                                                              "2",
                                                                              scope
                                                                                .row
                                                                                .id
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "关闭"
                                                                      ),
                                                                    ]
                                                                  ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "el-button",
                                                                {
                                                                  staticStyle: {
                                                                    margin:
                                                                      "5px",
                                                                  },
                                                                  attrs: {
                                                                    type: "danger",
                                                                    size: "mini",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.deleteFun(
                                                                          scope
                                                                            .row
                                                                            .id
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [_vm._v("删除")]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ]),
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "content-pages",
                                                  },
                                                  [
                                                    _c("Pagination", {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: _vm.total > 0,
                                                          expression:
                                                            "total > 0",
                                                        },
                                                      ],
                                                      attrs: {
                                                        total: _vm.total,
                                                        page: _vm.pageForm
                                                          .pageNo,
                                                        limit:
                                                          _vm.pageForm.pageSize,
                                                      },
                                                      on: {
                                                        "update:page":
                                                          function ($event) {
                                                            return _vm.$set(
                                                              _vm.pageForm,
                                                              "pageNo",
                                                              $event
                                                            )
                                                          },
                                                        "update:limit":
                                                          function ($event) {
                                                            return _vm.$set(
                                                              _vm.pageForm,
                                                              "pageSize",
                                                              $event
                                                            )
                                                          },
                                                        pagination:
                                                          _vm.pagingFun,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-checkbox",
                                      {
                                        model: {
                                          value: _vm.form.isFxhy,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "isFxhy", $$v)
                                          },
                                          expression: "form.isFxhy",
                                        },
                                      },
                                      [_vm._v("分享好友")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "colpl" },
                                      [
                                        _vm._v(
                                          "\n                    每分享1名好友获\n                    "
                                        ),
                                        _c("el-input", {
                                          staticStyle: { width: "80px" },
                                          on: {
                                            input: function (e) {
                                              return (_vm.form.fxhd =
                                                _vm.numFun(e))
                                            },
                                          },
                                          model: {
                                            value: _vm.form.fxhd,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.form, "fxhd", $$v)
                                            },
                                            expression: "form.fxhd",
                                          },
                                        }),
                                        _vm._v(
                                          "\n                    积分。要求：每用户通过分享每天最多可获得\n                    "
                                        ),
                                        _c("el-input", {
                                          staticStyle: { width: "80px" },
                                          on: {
                                            input: function (e) {
                                              return (_vm.form.dayMaxTask =
                                                _vm.numFun(e))
                                            },
                                          },
                                          model: {
                                            value: _vm.form.dayMaxTask,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "dayMaxTask",
                                                $$v
                                              )
                                            },
                                            expression: "form.dayMaxTask",
                                          },
                                        }),
                                        _vm._v(
                                          "积分，且总共可获得\n                    "
                                        ),
                                        _c("el-input", {
                                          staticStyle: { width: "80px" },
                                          on: {
                                            input: function (e) {
                                              return (_vm.form.maxTask =
                                                _vm.numFun(e))
                                            },
                                          },
                                          model: {
                                            value: _vm.form.maxTask,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.form, "maxTask", $$v)
                                            },
                                            expression: "form.maxTask",
                                          },
                                        }),
                                        _vm._v(
                                          "\n                    积分。\n                  "
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-checkbox",
                                      {
                                        model: {
                                          value: _vm.form.isGzgzh,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "isGzgzh", $$v)
                                          },
                                          expression: "form.isGzgzh",
                                        },
                                      },
                                      [_vm._v("关注公众号")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "colpl" },
                                      [
                                        _vm._v(
                                          "\n                    首次关注微信公众号送\n                    "
                                        ),
                                        _c("el-input", {
                                          staticStyle: { width: "80px" },
                                          on: {
                                            input: function (e) {
                                              return (_vm.form.gzhd =
                                                _vm.numFun(e))
                                            },
                                          },
                                          model: {
                                            value: _vm.form.gzhd,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.form, "gzhd", $$v)
                                            },
                                            expression: "form.gzhd",
                                          },
                                        }),
                                        _vm._v(
                                          "\n                    积分\n                    "
                                        ),
                                        _c("div", [_vm._v("公众号绑定")]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "upimg" },
                                          [
                                            _c("span", [
                                              _vm._v("公众号二维码图片:"),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "el-upload",
                                              {
                                                attrs: {
                                                  action: _vm.fstpUrl,
                                                  headers: _vm.myHeaders,
                                                  "before-upload": _vm.imgSize,
                                                  "on-success":
                                                    _vm.handleSuccess,
                                                  "on-error": _vm.handError,
                                                  "show-file-list": false,
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "primary" },
                                                  },
                                                  [_vm._v("上传图片")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("div", [
                                          _c(
                                            "img",
                                            _vm._b(
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.form.gzhUrl != "" ||
                                                      _vm.gzhimgurl != "",
                                                    expression:
                                                      "form.gzhUrl != '' || gzhimgurl != ''",
                                                  },
                                                ],
                                                staticStyle: {
                                                  width: "10%",
                                                  display: "block",
                                                  margin: "10px 68px",
                                                },
                                                attrs: { alt: "" },
                                              },
                                              "img",
                                              { src: _vm.gzhimgurl },
                                              false
                                            )
                                          ),
                                          _vm._v(" "),
                                          _c("p", { staticClass: "formTip" }, [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-warning-outline",
                                            }),
                                            _vm._v(
                                              "通过该处设置的公众号成功关注的用户自动命名为“积分商城渠道用户”\n                      "
                                            ),
                                          ]),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-checkbox",
                                      {
                                        attrs: {
                                          "true-label": "1",
                                          "false-label": "2",
                                        },
                                        on: { change: _vm.wsxx },
                                        model: {
                                          value: _vm.form.isMsg,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "isMsg", $$v)
                                          },
                                          expression: "form.isMsg",
                                        },
                                      },
                                      [_vm._v("完善信息")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "colpl" },
                                      [
                                        _vm._v(
                                          "\n                    完善信息获得\n                    "
                                        ),
                                        _c("el-input", {
                                          staticStyle: { width: "80px" },
                                          on: {
                                            input: function (e) {
                                              return (_vm.form.isMsgValue =
                                                _vm.numFun(e))
                                            },
                                          },
                                          model: {
                                            value: _vm.form.isMsgValue,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "isMsgValue",
                                                $$v
                                              )
                                            },
                                            expression: "form.isMsgValue",
                                          },
                                        }),
                                        _vm._v(
                                          "\n                    积分\n                    "
                                        ),
                                        _c(
                                          "el-link",
                                          {
                                            staticClass: "grxx",
                                            attrs: {
                                              type: "primary",
                                              underline: false,
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.infoDalog = true
                                              },
                                            },
                                          },
                                          [_vm._v("设置")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-dialog",
                                      {
                                        attrs: {
                                          title: "完善信息得积分规则设置",
                                          visible: _vm.infoDalog,
                                          width: "50%",
                                          center: "",
                                          top: "20vh",
                                        },
                                        on: {
                                          "update:visible": function ($event) {
                                            _vm.infoDalog = $event
                                          },
                                          close: _vm.closeFun,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form",
                                          {
                                            ref: "infoForm",
                                            attrs: {
                                              "label-suffix": ":",
                                              "label-width": "80px",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticClass: "bxx",
                                                attrs: {
                                                  label: "必填项",
                                                  prop: "checkList",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-checkbox",
                                                  {
                                                    attrs: {
                                                      "true-label": "1",
                                                      "false-label": "2",
                                                    },
                                                    on: { change: _vm.xmcha },
                                                    model: {
                                                      value: _vm.form.isName,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "isName",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "form.isName",
                                                    },
                                                  },
                                                  [_vm._v("姓名")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-checkbox",
                                                  {
                                                    attrs: {
                                                      "true-label": "1",
                                                      "false-label": "2",
                                                    },
                                                    model: {
                                                      value: _vm.form.isPhone,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "isPhone",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.isPhone",
                                                    },
                                                  },
                                                  [_vm._v("手机")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-checkbox",
                                                  {
                                                    attrs: {
                                                      "true-label": "1",
                                                      "false-label": "2",
                                                    },
                                                    model: {
                                                      value: _vm.form.isSex,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "isSex",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "form.isSex",
                                                    },
                                                  },
                                                  [_vm._v("性别")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-checkbox",
                                                  {
                                                    attrs: {
                                                      "true-label": "1",
                                                      "false-label": "2",
                                                    },
                                                    model: {
                                                      value: _vm.form.isAge,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "isAge",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "form.isAge",
                                                    },
                                                  },
                                                  [_vm._v("生日")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-checkbox",
                                                  {
                                                    attrs: {
                                                      "true-label": "1",
                                                      "false-label": "2",
                                                    },
                                                    model: {
                                                      value: _vm.form.isSsq,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "isSsq",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "form.isSsq",
                                                    },
                                                  },
                                                  [_vm._v("省市区")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-checkbox",
                                                  {
                                                    attrs: {
                                                      "true-label": "1",
                                                      "false-label": "2",
                                                    },
                                                    model: {
                                                      value: _vm.form.isXxdz,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "isXxdz",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "form.isXxdz",
                                                    },
                                                  },
                                                  [_vm._v("详细地址")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "dialog-footer",
                                            attrs: { slot: "footer" },
                                            slot: "footer",
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { size: "small" },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.infoDalog = false
                                                  },
                                                },
                                              },
                                              [_vm._v("取 消")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  size: "small",
                                                  loading: _vm.infoBtn,
                                                  type: "primary",
                                                },
                                                on: { click: _vm.infoSubmit },
                                              },
                                              [_vm._v("确 定")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-checkbox",
                                      {
                                        attrs: {
                                          "true-label": "1",
                                          "false-label": "2",
                                        },
                                        model: {
                                          value: _vm.form.isSrlb,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "isSrlb", $$v)
                                          },
                                          expression: "form.isSrlb",
                                        },
                                      },
                                      [_vm._v("生日礼包")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "colpl" },
                                      [
                                        _c(
                                          "el-radio-group",
                                          {
                                            staticClass: "srlbcls",
                                            model: {
                                              value: _vm.form.isSrlbBs,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "isSrlbBs",
                                                  $$v
                                                )
                                              },
                                              expression: "form.isSrlbBs",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio",
                                              { attrs: { label: "1" } },
                                              [
                                                _vm._v(
                                                  "\n                        生日当天可获得\n                        "
                                                ),
                                                _c("el-input", {
                                                  staticStyle: {
                                                    width: "80px",
                                                  },
                                                  on: {
                                                    input: function (e) {
                                                      return (_vm.form.birthdayTimes =
                                                        _vm.numFun(e))
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.birthdayTimes,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "birthdayTimes",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.birthdayTimes",
                                                  },
                                                }),
                                                _vm._v(
                                                  "\n                        倍积分，且通过生日礼包最多可获得\n                        "
                                                ),
                                                _c("el-input", {
                                                  staticStyle: {
                                                    width: "80px",
                                                  },
                                                  on: {
                                                    input: function (e) {
                                                      return (_vm.form.birthdayMax =
                                                        _vm.numFun(e))
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.form.birthdayMax,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "birthdayMax",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.birthdayMax",
                                                  },
                                                }),
                                                _vm._v(
                                                  "\n                        积分，超过的按照正常积分计算。\n                      "
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-radio",
                                              { attrs: { label: "2" } },
                                              [
                                                _vm._v(
                                                  "\n                        生日当天可获得\n                        "
                                                ),
                                                _c("el-input", {
                                                  staticStyle: {
                                                    width: "80px",
                                                  },
                                                  on: {
                                                    input: function (e) {
                                                      return (_vm.form.birthdayValue =
                                                        _vm.numFun(e))
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.birthdayValue,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "birthdayValue",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.birthdayValue",
                                                  },
                                                }),
                                                _vm._v(
                                                  "\n                        积分\n                      "
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-checkbox",
                                      {
                                        attrs: {
                                          "true-label": "1",
                                          "false-label": "2",
                                        },
                                        model: {
                                          value: _vm.form.isXrlb,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "isXrlb", $$v)
                                          },
                                          expression: "form.isXrlb",
                                        },
                                      },
                                      [_vm._v("新人礼包")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "colpl" },
                                      [
                                        _vm._v(
                                          "\n                    新注册用户可获得\n                    "
                                        ),
                                        _c("el-input", {
                                          staticStyle: { width: "80px" },
                                          on: {
                                            input: function (e) {
                                              return (_vm.form.newPeopleValue =
                                                _vm.numFun(e))
                                            },
                                          },
                                          model: {
                                            value: _vm.form.newPeopleValue,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "newPeopleValue",
                                                $$v
                                              )
                                            },
                                            expression: "form.newPeopleValue",
                                          },
                                        }),
                                        _vm._v(
                                          "\n                    积分\n                  "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("el-divider"),
                                _vm._v(" "),
                                _c("div", { staticClass: "mod-prod-info" }, [
                                  _c(
                                    "div",
                                    { staticClass: "jf-wrap" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "formTitle" },
                                        [
                                          _vm._v(
                                            "\n                      积分过期规则设置\n                      "
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              staticStyle: {
                                                "margin-left": "50px",
                                              },
                                              attrs: {
                                                type: "primary",
                                                size: "mini",
                                              },
                                              on: { click: _vm.gzsz },
                                            },
                                            [_vm._v("设 置")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("p", [
                                        _vm._v(
                                          "\n                      当前规则:\n                      " +
                                            _vm._s(
                                              _vm.dataForm.rtype === "0"
                                                ? "不清零"
                                                : "自然年底清零上一年获得的积分"
                                            ) +
                                            "\n                    "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "el-dialog",
                                        {
                                          attrs: {
                                            title: "规则设置",
                                            visible: _vm.jfDalog,
                                            width: "60%",
                                            center: "",
                                            top: "20vh",
                                          },
                                          on: {
                                            "update:visible": function (
                                              $event
                                            ) {
                                              _vm.jfDalog = $event
                                            },
                                            close: _vm.closeFun,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                padding: "30px 0 0 60px",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio-group",
                                                {
                                                  staticClass: "radio",
                                                  model: {
                                                    value: _vm.rtype,
                                                    callback: function ($$v) {
                                                      _vm.rtype = $$v
                                                    },
                                                    expression: "rtype",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-radio",
                                                    {
                                                      staticStyle: {
                                                        display: "block",
                                                        "margin-bottom": "30px",
                                                      },
                                                      attrs: { label: "0" },
                                                    },
                                                    [_vm._v("不清零")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-radio",
                                                    {
                                                      staticStyle: {
                                                        display: "block",
                                                        "margin-bottom": "10px",
                                                      },
                                                      attrs: { label: "1" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "自然年底清零上一年获得的积分"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    { staticClass: "formTip" },
                                                    [
                                                      _vm._v(
                                                        "\n                            (例：2019年最后一天对2018年当年及以前获得的但未使用的积分进行清零)\n                          "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "dialog-footer",
                                              attrs: { slot: "footer" },
                                              slot: "footer",
                                            },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: { size: "small" },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.jfDalog = false
                                                    },
                                                  },
                                                },
                                                [_vm._v("取 消")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    size: "small",
                                                    loading: _vm.addBtnIs,
                                                    type: "primary",
                                                  },
                                                  on: {
                                                    click: _vm.dataFormSubmit,
                                                  },
                                                },
                                                [_vm._v("确 定")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "explain-wrap" }, [
                                    _c(
                                      "div",
                                      { staticClass: "formTitle" },
                                      [
                                        _vm._v(
                                          "\n                      积分规则说明\n                      "
                                        ),
                                        _c("span", { staticClass: "formTip" }, [
                                          _vm._v(" (前端展示用) "),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              "margin-left": "50px",
                                            },
                                            attrs: {
                                              type: "primary",
                                              size: "mini",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.$router.push({
                                                  name: "wxAccountswxIntegralMallintegralExplainindex",
                                                })
                                              },
                                            },
                                          },
                                          [_vm._v("设 置")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "explain-wrap" }, [
                                    _c(
                                      "div",
                                      { staticClass: "formTitle" },
                                      [
                                        _vm._v(
                                          "\n                      客服信息\n                      "
                                        ),
                                        _c("span", { staticClass: "formTip" }, [
                                          _vm._v(" (前端展示用) "),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              "margin-left": "50px",
                                            },
                                            attrs: {
                                              type: "primary",
                                              size: "mini",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.$router.push({
                                                  name: "wxAccountswxIntegralMallintegralExplainkfindex",
                                                })
                                              },
                                            },
                                          },
                                          [_vm._v("设 置")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "add-wrap" }),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          { attrs: { type: "flex", justify: "start" } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 3 } },
                              [
                                _c("el-button", { on: { click: _vm.back } }, [
                                  _vm._v("取消"),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 3 } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: { click: _vm.saverule },
                                  },
                                  [_vm._v("保存")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }