var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.dialogLoading,
          expression: "dialogLoading",
        },
      ],
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.dialogVisible,
        "append-to-body": true,
        width: "1200px !important",
        "before-close": _vm.goBack,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.goBack,
      },
    },
    [
      _c(
        "div",
        { staticClass: "steps" },
        [
          _c(
            "el-steps",
            {
              attrs: {
                active: _vm.active,
                "align-center": "",
                "finish-status": "success",
              },
            },
            [
              _c("el-step", { attrs: { title: "活动信息" } }),
              _vm._v(" "),
              _c("el-step", { attrs: { title: "活动范围" } }),
              _vm._v(" "),
              _c("el-step", { attrs: { title: "活动奖项" } }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.active == 0,
              expression: "active==0",
            },
          ],
          staticClass: "row-bg",
          attrs: { type: "flex", justify: "space-around" },
        },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form",
                {
                  ref: "pageOneForm",
                  staticStyle: { margin: "10 auto" },
                  attrs: {
                    rules: _vm.pageOneRules,
                    model: _vm.pageOneForm,
                    "label-position": "right",
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-row",
                    {
                      staticStyle: { "margin-bottom": "0" },
                      attrs: { gutter: 10 },
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 14 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "活动对象类型：",
                                prop: "radioValue",
                                "is-required": "",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.pageOneForm.radioValue,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.pageOneForm,
                                        "radioValue",
                                        $$v
                                      )
                                    },
                                    expression: "pageOneForm.radioValue",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: {
                                        disabled: _vm.showOnly,
                                        label: "0",
                                      },
                                    },
                                    [_vm._v("终端店主")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 14 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "活动名称：",
                                prop: "activityName",
                                "is-required": "",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "不能超过25个汉字",
                                  maxlength: "25",
                                  disabled: _vm.showOnly,
                                },
                                model: {
                                  value: _vm.pageOneForm.activityName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.pageOneForm,
                                      "activityName",
                                      $$v
                                    )
                                  },
                                  expression: "pageOneForm.activityName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 14 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { "line-height": "39px" },
                              attrs: {
                                label: "活动时间：",
                                prop: "dataTimeRange",
                                "is-required": "",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "datetimerange",
                                  "range-separator": "-",
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  format: "yyyy-MM-dd HH:mm:ss",
                                  "default-time": ["00:00:00", "23:59:59"],
                                  disabled: _vm.showOnly,
                                },
                                model: {
                                  value: _vm.pageOneForm.dataTimeRange,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.pageOneForm,
                                      "dataTimeRange",
                                      $$v
                                    )
                                  },
                                  expression: "pageOneForm.dataTimeRange",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 14 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "扫码类型：",
                                prop: "codeTypeList",
                                "is-required": "",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value:
                                      _vm.pageOneForm.codeTypeList[0].codeType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.pageOneForm.codeTypeList[0],
                                        "codeType",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "pageOneForm.codeTypeList[0].codeType",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: {
                                        disabled: _vm.showOnly,
                                        label: "0",
                                      },
                                    },
                                    [_vm._v("箱内码+箱外码")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 14 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "资金预算：",
                                prop: "financeBudgetAmount",
                              },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  directives: [
                                    { name: "Number", rawName: "v-Number" },
                                  ],
                                  attrs: { disabled: _vm.showOnly },
                                  model: {
                                    value: _vm.pageOneForm.financeBudgetAmount,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.pageOneForm,
                                        "financeBudgetAmount",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "pageOneForm.financeBudgetAmount",
                                  },
                                },
                                [
                                  _c("template", { slot: "append" }, [
                                    _vm._v("元"),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 14 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "活动模板：",
                                prop: "flowId",
                                "is-required": "",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择",
                                    disabled: _vm.showOnly,
                                  },
                                  model: {
                                    value: _vm.pageOneForm.flowId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.pageOneForm, "flowId", $$v)
                                    },
                                    expression: "pageOneForm.flowId",
                                  },
                                },
                                _vm._l(
                                  _vm.templateList,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.templateName,
                                        value: item.id,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 14 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "策略组：",
                                prop: "strategyGroupId",
                                "is-required": "",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择",
                                    disabled: _vm.showOnly,
                                  },
                                  model: {
                                    value: _vm.pageOneForm.strategyGroupId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.pageOneForm,
                                        "strategyGroupId",
                                        $$v
                                      )
                                    },
                                    expression: "pageOneForm.strategyGroupId",
                                  },
                                },
                                _vm._l(
                                  _vm.strategyList,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.groupName,
                                        value: item.id,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 14 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "资金来源配置：",
                                prop: "financeSourceId",
                                "is-required": "",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择",
                                    disabled: _vm.showOnly,
                                  },
                                  model: {
                                    value: _vm.pageOneForm.financeSourceId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.pageOneForm,
                                        "financeSourceId",
                                        $$v
                                      )
                                    },
                                    expression: "pageOneForm.financeSourceId",
                                  },
                                },
                                _vm._l(_vm.sourceList, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.fundConfigurationName,
                                      value: item.id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 14 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "关联方案：",
                                prop: "schemeCode",
                                "is-required": "",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择关联方案",
                                    disabled: _vm.showOnly,
                                  },
                                  on: { change: _vm.getActPlanName },
                                  model: {
                                    value: _vm.pageOneForm.schemeCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.pageOneForm,
                                        "schemeCode",
                                        $$v
                                      )
                                    },
                                    expression: "pageOneForm.schemeCode",
                                  },
                                },
                                _vm._l(_vm.actPlanList, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.dataName,
                                      value: item.dataCode,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 14 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "活动类型：",
                                prop: "activityTypeCode",
                                "is-required": "",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择活动类型",
                                    disabled:
                                      _vm.showOnly ||
                                      !_vm.pageOneForm.schemeCode,
                                  },
                                  on: { change: _vm.getActTypeName },
                                  model: {
                                    value: _vm.pageOneForm.activityTypeCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.pageOneForm,
                                        "activityTypeCode",
                                        $$v
                                      )
                                    },
                                    expression: "pageOneForm.activityTypeCode",
                                  },
                                },
                                _vm._l(_vm.actTypeList, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.dataName,
                                      value: item.dataCode,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 14 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "促销形式：",
                                prop: "promotionCode",
                                "is-required": "",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择促销形式",
                                    disabled: _vm.showOnly,
                                  },
                                  on: { change: _vm.getActPromotionTypeName },
                                  model: {
                                    value: _vm.pageOneForm.promotionCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.pageOneForm,
                                        "promotionCode",
                                        $$v
                                      )
                                    },
                                    expression: "pageOneForm.promotionCode",
                                  },
                                },
                                _vm._l(
                                  _vm.promotionList,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.dataName,
                                        value: item.dataCode,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 14 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "费用类型：",
                                prop: "costTypeCode",
                                "is-required": "",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择费用类型",
                                    disabled: _vm.showOnly,
                                  },
                                  on: { change: _vm.getActFeeTypeName },
                                  model: {
                                    value: _vm.pageOneForm.costTypeCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.pageOneForm,
                                        "costTypeCode",
                                        $$v
                                      )
                                    },
                                    expression: "pageOneForm.costTypeCode",
                                  },
                                },
                                _vm._l(_vm.feeList, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.dataName,
                                      value: item.dataCode,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 14 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "费用主体：",
                                prop: "costAllocation",
                                "is-required": "",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticStyle: { "margin-bottom": "10px" },
                                  attrs: { disabled: _vm.showOnly },
                                  on: { click: _vm.openJxsFy },
                                },
                                [_vm._v("选取主体")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-table",
                                {
                                  attrs: {
                                    data: _vm.pageOneForm.costAllocation,
                                    "max-height": "500",
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "主体编码",
                                      prop: "subjectCode",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "主体名称",
                                      prop: "subjectName",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "类型",
                                      prop: "bearerType",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            scope.row.bearerType == "1"
                                              ? _c("span", [_vm._v("经销商")])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            scope.row.bearerType == "2"
                                              ? _c("span", [_vm._v("企业")])
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "所属战队",
                                      prop: "warTeamName",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "费用占比",
                                      prop: "bearProportion",
                                      "min-width": "140",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-input",
                                              {
                                                directives: [
                                                  {
                                                    name: "Number1",
                                                    rawName: "v-Number1",
                                                  },
                                                ],
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  disabled: _vm.showOnly,
                                                },
                                                model: {
                                                  value:
                                                    scope.row.bearProportion,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "bearProportion",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.bearProportion",
                                                },
                                              },
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "append" },
                                                  [_vm._v("%")]
                                                ),
                                              ],
                                              2
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: { label: "操作" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            scope.$index >= 1
                                              ? _c("el-button", {
                                                  attrs: {
                                                    disabled: _vm.showOnly,
                                                    type: "primary",
                                                    size: "mini",
                                                    icon: "el-icon-delete",
                                                    circle: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.deleteCostAllocation(
                                                        scope.$index
                                                      )
                                                    },
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 14 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "活动规则：",
                                prop: "activityRuleExplain",
                                "is-required": "",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  maxlength: "500",
                                  rows: 3,
                                  "show-word-limit": "",
                                  disabled: _vm.showOnly,
                                },
                                model: {
                                  value: _vm.pageOneForm.activityRuleExplain,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.pageOneForm,
                                      "activityRuleExplain",
                                      $$v
                                    )
                                  },
                                  expression: "pageOneForm.activityRuleExplain",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 14 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "活动备注：",
                                prop: "activityExplain",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  maxlength: "500",
                                  rows: 3,
                                  "show-word-limit": "",
                                  disabled: _vm.showOnly,
                                },
                                model: {
                                  value: _vm.pageOneForm.activityExplain,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.pageOneForm,
                                      "activityExplain",
                                      $$v
                                    )
                                  },
                                  expression: "pageOneForm.activityExplain",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.active == 1
        ? _c(
            "el-row",
            {
              staticClass: "row-bg",
              attrs: { type: "flex", justify: "space-around" },
            },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "pageTwoForm",
                      staticStyle: { margin: "0 auto" },
                      attrs: {
                        rules: _vm.pageTwoFormRules,
                        model: _vm.pageTwoForm,
                        "label-position": "right",
                        "label-width": "140px",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        {
                          staticStyle: { "margin-bottom": "0" },
                          attrs: { gutter: 20 },
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "布奖方式：",
                                    prop: "activityDimension",
                                    "is-required": "",
                                  },
                                },
                                [
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        on: { change: _vm.changeVD },
                                        model: {
                                          value:
                                            _vm.pageTwoForm.activityDimension,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.pageTwoForm,
                                              "activityDimension",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "pageTwoForm.activityDimension",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          {
                                            attrs: {
                                              disabled: _vm.showOnly,
                                              value: "0",
                                              label: "0",
                                            },
                                          },
                                          [_vm._v("产品布奖")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-radio",
                                          {
                                            attrs: {
                                              disabled: _vm.showOnly,
                                              value: "1",
                                              label: "1",
                                            },
                                          },
                                          [_vm._v("订单布奖")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.pageTwoForm.activityDimension == "1"
                        ? _c(
                            "el-row",
                            {
                              staticStyle: {
                                "margin-left": "0",
                                "margin-right": "0",
                              },
                              attrs: { gutter: 20 },
                            },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "",
                                        prop: "orderList",
                                        "is-required": "",
                                        "label-width": "0",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.addBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.addBtn.icon,
                                            disabled: _vm.showOnly,
                                          },
                                          on: { click: _vm.orderListShow },
                                        },
                                        [
                                          _vm._v(
                                            "\n                添加订单\n              "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.upLoadBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.upLoadBtn.icon,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.uploadShow(5)
                                            },
                                          },
                                        },
                                        [_vm._v("导入")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-table",
                                        {
                                          directives: [
                                            {
                                              name: "el-load-more-table",
                                              rawName: "v-el-load-more-table",
                                              value: {
                                                data: _vm.pageTwoForm.orderList,
                                                setData: function (data) {
                                                  return _vm.setViewData(
                                                    "orderList",
                                                    data
                                                  )
                                                },
                                              },
                                              expression:
                                                "{data: pageTwoForm.orderList, setData: (data) => setViewData('orderList', data)}",
                                            },
                                          ],
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            data: _vm.pageTwoForm.orderList,
                                            height: "325px",
                                          },
                                          on: {
                                            "update:data": function ($event) {
                                              return _vm.$set(
                                                _vm.pageTwoForm,
                                                "orderList",
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "el-table-column",
                                            {
                                              attrs: {
                                                label: "参与范围",
                                                align: "center",
                                              },
                                            },
                                            [
                                              _c("el-table-column", {
                                                attrs: {
                                                  type: "index",
                                                  width: "80",
                                                  label: "序号",
                                                  align: _vm.tableConfig.align,
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-table-column", {
                                                attrs: {
                                                  "show-overflow-tooltip": "",
                                                  label: "单据编号",
                                                  align: _vm.tableConfig.align,
                                                  prop: "orderCode",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-table-column", {
                                                attrs: {
                                                  "show-overflow-tooltip": "",
                                                  label: "发货单位",
                                                  align: _vm.tableConfig.align,
                                                  prop: "seller",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-table-column", {
                                                attrs: {
                                                  "show-overflow-tooltip": "",
                                                  label: "收货单位",
                                                  align: _vm.tableConfig.align,
                                                  prop: "buyer",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (ref) {
                                                        var row = ref.row
                                                        return [
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                row.buyer
                                                              ) +
                                                              "\n                    "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  1494560934
                                                ),
                                              }),
                                              _vm._v(" "),
                                              _c("el-table-column", {
                                                attrs: {
                                                  "show-overflow-tooltip": "",
                                                  label: "发货时间",
                                                  align: _vm.tableConfig.align,
                                                  prop: "sellTime",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-table-column", {
                                                attrs: {
                                                  label: "操作",
                                                  fixed: "right",
                                                  align: _vm.tableConfig.align,
                                                  "class-name":
                                                    "small-padding fixed-width",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c(
                                                            "el-button",
                                                            {
                                                              attrs: {
                                                                type: "text",
                                                                disabled:
                                                                  _vm.showOnly,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.delorder(
                                                                      scope.$index
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("移除")]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  2602735468
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "选择产品：",
                                        prop: "orderProductList",
                                        "label-width": "150",
                                        rules: [
                                          {
                                            type: "array",
                                            required:
                                              _vm.pageTwoForm
                                                .isOpenJoinProduct == 0
                                                ? true
                                                : false,
                                            message: "请选择活动产品",
                                            trigger: "change",
                                          },
                                        ],
                                      },
                                    },
                                    [
                                      _c("el-switch", {
                                        attrs: {
                                          disabled: _vm.showOnly,
                                          "active-value": "0",
                                          "inactive-value": "1",
                                        },
                                        model: {
                                          value:
                                            _vm.pageTwoForm.isOpenJoinProduct,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.pageTwoForm,
                                              "isOpenJoinProduct",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "pageTwoForm.isOpenJoinProduct",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm.pageTwoForm.isOpenJoinProduct == 0
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: _vm.btnConfig.addBtn.type,
                                                size: _vm.btnConfig.size,
                                                icon: _vm.btnConfig.addBtn.icon,
                                                disabled: _vm.showOnly,
                                              },
                                              on: {
                                                click: _vm.productListShow,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                选择产品\n              "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.pageTwoForm.isOpenJoinProduct == 0
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: _vm.btnConfig.upLoadBtn
                                                  .type,
                                                size: _vm.btnConfig.size,
                                                icon: _vm.btnConfig.upLoadBtn
                                                  .icon,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.uploadShow(2)
                                                },
                                              },
                                            },
                                            [_vm._v("导入")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.pageTwoForm.isOpenJoinProduct == 0
                                        ? _c(
                                            "el-table",
                                            {
                                              directives: [
                                                {
                                                  name: "el-load-more-table",
                                                  rawName:
                                                    "v-el-load-more-table",
                                                  value: {
                                                    data: _vm.pageTwoForm
                                                      .orderProductList,
                                                    setData: function (data) {
                                                      return _vm.setViewData(
                                                        "orderProductList",
                                                        data
                                                      )
                                                    },
                                                  },
                                                  expression:
                                                    "{data: pageTwoForm.orderProductList, setData: (data) => setViewData('orderProductList', data)}",
                                                },
                                              ],
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                data: _vm.pageTwoForm
                                                  .orderProductList,
                                                height: "325px",
                                              },
                                              on: {
                                                "update:data": function (
                                                  $event
                                                ) {
                                                  return _vm.$set(
                                                    _vm.pageTwoForm,
                                                    "orderProductList",
                                                    $event
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "el-table-column",
                                                {
                                                  attrs: {
                                                    label: "参与范围",
                                                    align: "center",
                                                  },
                                                },
                                                [
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      type: "index",
                                                      width: "80",
                                                      label: "序号",
                                                      align:
                                                        _vm.tableConfig.align,
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      "show-overflow-tooltip":
                                                        "",
                                                      label: "产品分类编码",
                                                      align:
                                                        _vm.tableConfig.align,
                                                      prop: "productClassCode",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      "show-overflow-tooltip":
                                                        "",
                                                      label: "产品分类名称",
                                                      align:
                                                        _vm.tableConfig.align,
                                                      prop: "productClassName",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      "show-overflow-tooltip":
                                                        "",
                                                      label: "产品编码",
                                                      align:
                                                        _vm.tableConfig.align,
                                                      prop: "productCode",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      "show-overflow-tooltip":
                                                        "",
                                                      label: "产品名称",
                                                      align:
                                                        _vm.tableConfig.align,
                                                      prop: "productName",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      label: "操作",
                                                      fixed: "right",
                                                      align:
                                                        _vm.tableConfig.align,
                                                      "class-name":
                                                        "small-padding fixed-width",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function (scope) {
                                                            return [
                                                              _c(
                                                                "el-button",
                                                                {
                                                                  attrs: {
                                                                    type: "text",
                                                                    disabled:
                                                                      _vm.showOnly,
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.delproduct(
                                                                          scope.$index
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [_vm._v("移除")]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      166783497
                                                    ),
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.pageTwoForm.activityDimension == "0"
                        ? _c(
                            "el-row",
                            {
                              staticStyle: {
                                "margin-left": "0",
                                "margin-right": "0",
                              },
                              attrs: { gutter: 20 },
                            },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "",
                                        prop: "productClassList",
                                        "is-required": "",
                                        "label-width": "0",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.addBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.addBtn.icon,
                                            disabled: _vm.showOnly,
                                          },
                                          on: { click: _vm.ClassShow },
                                        },
                                        [
                                          _vm._v(
                                            "\n                选择产品分类\n              "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.upLoadBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.upLoadBtn.icon,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.uploadShow(1)
                                            },
                                          },
                                        },
                                        [_vm._v("导入")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-table",
                                        {
                                          directives: [
                                            {
                                              name: "el-load-more-table",
                                              rawName: "v-el-load-more-table",
                                              value: {
                                                data: _vm.pageTwoForm
                                                  .productClassList,
                                                setData: function (data) {
                                                  return _vm.setViewData(
                                                    "productClassList",
                                                    data
                                                  )
                                                },
                                              },
                                              expression:
                                                "{data: pageTwoForm.productClassList, setData: (data) => setViewData('productClassList', data)}",
                                            },
                                          ],
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            data: _vm.pageTwoForm
                                              .productClassList,
                                            height: "325px",
                                          },
                                          on: {
                                            "update:data": function ($event) {
                                              return _vm.$set(
                                                _vm.pageTwoForm,
                                                "productClassList",
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "el-table-column",
                                            {
                                              attrs: {
                                                label: "参与范围",
                                                align: "center",
                                              },
                                            },
                                            [
                                              _c("el-table-column", {
                                                attrs: {
                                                  type: "index",
                                                  width: "80",
                                                  label: "序号",
                                                  align: _vm.tableConfig.align,
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-table-column", {
                                                attrs: {
                                                  "show-overflow-tooltip": "",
                                                  label: "产品分类编码",
                                                  align: _vm.tableConfig.align,
                                                  prop: "productClassCode",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-table-column", {
                                                attrs: {
                                                  "show-overflow-tooltip": "",
                                                  label: "产品分类名称",
                                                  align: _vm.tableConfig.align,
                                                  prop: "productClassName",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-table-column", {
                                                attrs: {
                                                  label: "操作",
                                                  fixed: "right",
                                                  align: _vm.tableConfig.align,
                                                  "class-name":
                                                    "small-padding fixed-width",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c(
                                                            "el-button",
                                                            {
                                                              attrs: {
                                                                type: "text",
                                                                disabled:
                                                                  _vm.showOnly,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.delproductClass(
                                                                      scope.$index
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("移除")]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  469540327
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "不参与活动产品：",
                                        prop: "productBlackList",
                                        "label-width": "150",
                                        rules: [
                                          {
                                            type: "array",
                                            required:
                                              _vm.pageTwoForm
                                                .isOpenNotJoinProduct == 0
                                                ? true
                                                : false,
                                            message: "请选择不参与活动产品",
                                            trigger: "change",
                                          },
                                        ],
                                      },
                                    },
                                    [
                                      _c("el-switch", {
                                        attrs: {
                                          disabled: _vm.showOnly,
                                          "active-value": "0",
                                          "inactive-value": "1",
                                        },
                                        model: {
                                          value:
                                            _vm.pageTwoForm
                                              .isOpenNotJoinProduct,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.pageTwoForm,
                                              "isOpenNotJoinProduct",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "pageTwoForm.isOpenNotJoinProduct",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm.pageTwoForm.isOpenNotJoinProduct == 0
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: _vm.btnConfig.addBtn.type,
                                                size: _vm.btnConfig.size,
                                                icon: _vm.btnConfig.addBtn.icon,
                                                disabled: _vm.showOnly,
                                              },
                                              on: {
                                                click: _vm.productListShow,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                选择不参与产品\n              "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.pageTwoForm.isOpenNotJoinProduct == 0
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: _vm.btnConfig.upLoadBtn
                                                  .type,
                                                size: _vm.btnConfig.size,
                                                icon: _vm.btnConfig.upLoadBtn
                                                  .icon,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.uploadShow(2)
                                                },
                                              },
                                            },
                                            [_vm._v("导入")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.pageTwoForm.isOpenNotJoinProduct == 0
                                        ? _c(
                                            "el-table",
                                            {
                                              directives: [
                                                {
                                                  name: "el-load-more-table",
                                                  rawName:
                                                    "v-el-load-more-table",
                                                  value: {
                                                    data: _vm.pageTwoForm
                                                      .productBlackList,
                                                    setData: function (data) {
                                                      return _vm.setViewData(
                                                        "productBlackList",
                                                        data
                                                      )
                                                    },
                                                  },
                                                  expression:
                                                    "{data: pageTwoForm.productBlackList, setData: (data) => setViewData('productBlackList', data)}",
                                                },
                                              ],
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                data: _vm.pageTwoForm
                                                  .productBlackList,
                                                height: "325px",
                                              },
                                              on: {
                                                "update:data": function (
                                                  $event
                                                ) {
                                                  return _vm.$set(
                                                    _vm.pageTwoForm,
                                                    "productBlackList",
                                                    $event
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "el-table-column",
                                                {
                                                  attrs: {
                                                    label: "以下产品禁止参与",
                                                    align: "center",
                                                  },
                                                },
                                                [
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      type: "index",
                                                      width: "80",
                                                      label: "序号",
                                                      align:
                                                        _vm.tableConfig.align,
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      "show-overflow-tooltip":
                                                        "",
                                                      label: "产品编码",
                                                      align:
                                                        _vm.tableConfig.align,
                                                      prop: "productCode",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      "show-overflow-tooltip":
                                                        "",
                                                      label: "产品名称",
                                                      align:
                                                        _vm.tableConfig.align,
                                                      prop: "productName",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      label: "操作",
                                                      fixed: "right",
                                                      align:
                                                        _vm.tableConfig.align,
                                                      "class-name":
                                                        "small-padding fixed-width",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function (scope) {
                                                            return [
                                                              _c(
                                                                "el-button",
                                                                {
                                                                  attrs: {
                                                                    type: "text",
                                                                    disabled:
                                                                      _vm.showOnly,
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.delproduct(
                                                                          scope.$index
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [_vm._v("移除")]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      166783497
                                                    ),
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        {
                          staticStyle: { "margin-bottom": "0" },
                          attrs: { gutter: 20 },
                        },
                        [
                          _vm.pageTwoForm.activityDimension == "0"
                            ? _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "产品时间：" } },
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 2 } },
                                        [
                                          _c("el-switch", {
                                            attrs: {
                                              disabled: _vm.showOnly,
                                              "active-value": "0",
                                              "inactive-value": "1",
                                            },
                                            model: {
                                              value:
                                                _vm.pageTwoForm
                                                  .isOpenTimeControl,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.pageTwoForm,
                                                  "isOpenTimeControl",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "pageTwoForm.isOpenTimeControl",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "",
                                                prop: "timeControlType",
                                                rules: [
                                                  {
                                                    required:
                                                      _vm.pageTwoForm
                                                        .isOpenTimeControl ==
                                                      "0"
                                                        ? true
                                                        : false,
                                                    message: "请选产品时间",
                                                    trigger: "blur",
                                                  },
                                                ],
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    placeholder: "请选择",
                                                    disabled: _vm.showOnly,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.pageTwoForm
                                                        .timeControlType,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.pageTwoForm,
                                                        "timeControlType",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "pageTwoForm.timeControlType",
                                                  },
                                                },
                                                [
                                                  _c("el-option", {
                                                    attrs: {
                                                      label: "产品生产时间",
                                                      value: "0",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("el-option", {
                                                    attrs: {
                                                      label: "产品出库时间",
                                                      value: "1",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 14 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "",
                                                prop: "dataTimeRange",
                                                rules: [
                                                  {
                                                    required:
                                                      _vm.pageTwoForm
                                                        .isOpenTimeControl ==
                                                      "0"
                                                        ? true
                                                        : false,
                                                    message: "请选产品时间",
                                                    trigger: "blur",
                                                  },
                                                ],
                                              },
                                            },
                                            [
                                              _c("el-date-picker", {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  type: "datetimerange",
                                                  "range-separator": "-",
                                                  "start-placeholder":
                                                    "开始日期",
                                                  "end-placeholder": "结束日期",
                                                  "value-format":
                                                    "yyyy-MM-dd HH:mm:ss",
                                                  format: "yyyy-MM-dd HH:mm:ss",
                                                  "default-time": [
                                                    "00:00:00",
                                                    "23:59:59",
                                                  ],
                                                  disabled: _vm.showOnly,
                                                },
                                                model: {
                                                  value:
                                                    _vm.pageTwoForm
                                                      .dataTimeRange,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.pageTwoForm,
                                                      "dataTimeRange",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "pageTwoForm.dataTimeRange",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "终端类型：",
                                    prop: "targetUserList",
                                    "is-required": "",
                                  },
                                },
                                [
                                  _c(
                                    "el-checkbox-group",
                                    {
                                      model: {
                                        value: _vm.pageTwoForm.targetUserList,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.pageTwoForm,
                                            "targetUserList",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "pageTwoForm.targetUserList",
                                      },
                                    },
                                    _vm._l(
                                      _vm.terminalStoreList,
                                      function (item, index) {
                                        return _c(
                                          "el-checkbox",
                                          {
                                            key: index,
                                            attrs: {
                                              disabled: _vm.showOnly,
                                              label: item.targetUserCode,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(item.targetUserName) +
                                                "\n                "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        {
                          staticStyle: {
                            "margin-left": "0",
                            "margin-right": "0",
                          },
                          attrs: { gutter: 20 },
                        },
                        [
                          _vm.pageTwoForm.activityDimension == "0"
                            ? _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "",
                                        prop: "dealerList",
                                        "is-required": "",
                                        "label-width": "0",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.addBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.addBtn.icon,
                                            disabled: _vm.showOnly,
                                          },
                                          on: { click: _vm.h_batchListShow },
                                        },
                                        [
                                          _vm._v(
                                            "\n                添加参与范围\n              "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.upLoadBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.upLoadBtn.icon,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.uploadShow(3)
                                            },
                                          },
                                        },
                                        [_vm._v("导入")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-table",
                                        {
                                          directives: [
                                            {
                                              name: "el-load-more-table",
                                              rawName: "v-el-load-more-table",
                                              value: {
                                                data: _vm.pageTwoForm
                                                  .dealerList,
                                                setData: function (data) {
                                                  return _vm.setViewData(
                                                    "dealerList",
                                                    data
                                                  )
                                                },
                                              },
                                              expression:
                                                "{data: pageTwoForm.dealerList, setData: (data) => setViewData('dealerList', data)}",
                                            },
                                          ],
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            data: _vm.pageTwoForm.dealerList,
                                            height: "325px",
                                          },
                                          on: {
                                            "update:data": function ($event) {
                                              return _vm.$set(
                                                _vm.pageTwoForm,
                                                "dealerList",
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "el-table-column",
                                            {
                                              attrs: {
                                                label: "参与范围",
                                                align: "center",
                                              },
                                            },
                                            [
                                              _c("el-table-column", {
                                                attrs: {
                                                  type: "index",
                                                  width: "80",
                                                  label: "序号",
                                                  align: _vm.tableConfig.align,
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-table-column", {
                                                attrs: {
                                                  "show-overflow-tooltip": "",
                                                  label: "所属经销商编码",
                                                  align: _vm.tableConfig.align,
                                                  prop: "channelDealerCode",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-table-column", {
                                                attrs: {
                                                  "show-overflow-tooltip": "",
                                                  label: "所属经销商名称",
                                                  align: _vm.tableConfig.align,
                                                  prop: "channelDealerName",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "el-table-column",
                                                {
                                                  attrs: {
                                                    label: "操作",
                                                    fixed: "right",
                                                    align:
                                                      _vm.tableConfig.align,
                                                    "class-name":
                                                      "small-padding fixed-width",
                                                  },
                                                },
                                                [
                                                  [
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          type: "text",
                                                          disabled:
                                                            _vm.showOnly,
                                                        },
                                                        on: {
                                                          click: _vm.deldealer,
                                                        },
                                                      },
                                                      [_vm._v("移除")]
                                                    ),
                                                  ],
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              attrs: {
                                span:
                                  _vm.pageTwoForm.activityDimension == "0"
                                    ? 12
                                    : 24,
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "不参与终端：",
                                    prop: "storeBlackList",
                                    "is-required": "",
                                    "label-width": "150",
                                    rules: [
                                      {
                                        type: "array",
                                        required:
                                          _vm.pageTwoForm
                                            .isOpenNotJoinTerminal == 0
                                            ? true
                                            : false,
                                        message: "请选择不参与终端",
                                        trigger: "change",
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      disabled: _vm.showOnly,
                                      "active-value": "0",
                                      "inactive-value": "1",
                                    },
                                    model: {
                                      value:
                                        _vm.pageTwoForm.isOpenNotJoinTerminal,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.pageTwoForm,
                                          "isOpenNotJoinTerminal",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "pageTwoForm.isOpenNotJoinTerminal",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm.pageTwoForm.isOpenNotJoinTerminal == "0"
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.addBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.addBtn.icon,
                                            disabled: _vm.showOnly,
                                          },
                                          on: { click: _vm.terminalListShow },
                                        },
                                        [
                                          _vm._v(
                                            "\n                添加不参与终端\n              "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.pageTwoForm.isOpenNotJoinTerminal == "0"
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.upLoadBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.upLoadBtn.icon,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.uploadShow(4)
                                            },
                                          },
                                        },
                                        [_vm._v("导入")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.pageTwoForm.isOpenNotJoinTerminal == "0"
                                    ? _c(
                                        "el-table",
                                        {
                                          directives: [
                                            {
                                              name: "el-load-more-table",
                                              rawName: "v-el-load-more-table",
                                              value: {
                                                data: _vm.pageTwoForm
                                                  .storeBlackList,
                                                setData: function (data) {
                                                  return _vm.setViewData(
                                                    "storeBlackList",
                                                    data
                                                  )
                                                },
                                              },
                                              expression:
                                                "{data: pageTwoForm.storeBlackList, setData: (data) => setViewData('storeBlackList', data)}",
                                            },
                                          ],
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            data: _vm.pageTwoForm
                                              .storeBlackList,
                                            height: "325px",
                                          },
                                          on: {
                                            "update:data": function ($event) {
                                              return _vm.$set(
                                                _vm.pageTwoForm,
                                                "storeBlackList",
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "el-table-column",
                                            {
                                              attrs: {
                                                label: "以下终端禁止参与",
                                                align: "center",
                                              },
                                            },
                                            [
                                              _c("el-table-column", {
                                                attrs: {
                                                  type: "index",
                                                  width: "80",
                                                  label: "序号",
                                                  align: _vm.tableConfig.align,
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-table-column", {
                                                attrs: {
                                                  "show-overflow-tooltip": "",
                                                  label: "终端编码",
                                                  align: _vm.tableConfig.align,
                                                  prop: "channelStoreCode",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-table-column", {
                                                attrs: {
                                                  "show-overflow-tooltip": "",
                                                  label: "终端名称",
                                                  align: _vm.tableConfig.align,
                                                  prop: "channelStoreName",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "el-table-column",
                                                {
                                                  attrs: {
                                                    label: "操作",
                                                    fixed: "right",
                                                    align:
                                                      _vm.tableConfig.align,
                                                    "class-name":
                                                      "small-padding fixed-width",
                                                  },
                                                },
                                                [
                                                  [
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          type: "text",
                                                          disabled:
                                                            _vm.showOnly,
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.delterminal,
                                                        },
                                                      },
                                                      [_vm._v("移除")]
                                                    ),
                                                  ],
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.active == 2,
              expression: "active==2",
            },
          ],
          staticClass: "row-bg",
          attrs: { type: "flex", justify: "space-around" },
        },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form",
                {
                  ref: "codeForm",
                  staticClass: "topLabelForm",
                  staticStyle: { margin: "10 auto" },
                  attrs: {
                    model: _vm.codeForm,
                    "label-position": "top",
                    "label-width": "120px",
                    rules: _vm.codeFormRules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { staticStyle: { "margin-bottom": "0" } },
                    [
                      _c(
                        "el-row",
                        {
                          staticStyle: { margin: "0" },
                          attrs: { type: "flex", gutter: 10 },
                        },
                        [
                          _c("el-col", { attrs: { md: 3 } }, [
                            _vm._v("奖品设置："),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { md: 4 } }),
                          _vm._v(" "),
                          _c("el-col", { attrs: { md: 5 } }),
                          _vm._v(" "),
                          _c("el-col", { attrs: { md: 4 } }, [
                            _vm._v("活动概率"),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { md: 4 } }, [
                            _vm._v("展示规则"),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { md: 4 } }),
                          _vm._v(" "),
                          _c("el-col", { attrs: { md: 4 } }),
                          _vm._v(" "),
                          _c("el-col", { attrs: { md: 1 } }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm._l(
                        _vm.codeForm.prizeItemsList,
                        function (item, index) {
                          return _c(
                            "el-row",
                            {
                              key: index,
                              staticStyle: { "margin-bottom": "18px" },
                              attrs: { gutter: 10, type: "flex" },
                            },
                            [
                              _c(
                                "el-col",
                                { staticClass: "inlineItem", attrs: { md: 3 } },
                                [
                                  item.prizeType != "0"
                                    ? _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            "label-width": "0",
                                            prop:
                                              "prizeItemsList." +
                                              index +
                                              ".priority",
                                            rules: {
                                              required: true,
                                              message: "请选择奖品等级",
                                              trigger: "change",
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                placeholder: "奖品等级",
                                                disabled:
                                                  _vm.isCopyType ||
                                                  !_vm.isShowPrizeSetBtn,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.checkLevel(
                                                    $event,
                                                    index
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.priority,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "priority",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.priority",
                                              },
                                            },
                                            _vm._l(
                                              _vm.prioritys,
                                              function (item2, index2) {
                                                return _c("el-option", {
                                                  key: index2,
                                                  attrs: {
                                                    label: item2.name,
                                                    value: item2.value,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.prizeType == "0"
                                    ? _c("el-input", {
                                        attrs: {
                                          placeholder: "其他",
                                          disabled:
                                            !item.disable ||
                                            item.prizeType == "0" ||
                                            _vm.isCopyType,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { staticClass: "inlineItem", attrs: { md: 4 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        "label-width": "0",
                                        prop:
                                          "prizeItemsList." +
                                          index +
                                          ".prizeType",
                                        rules: {
                                          required: true,
                                          message: "请选择奖品类型",
                                          trigger: "change",
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: "请选择奖品类型",
                                            disabled: _vm.isCopyType,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.getCurPrizeType(
                                                $event,
                                                index
                                              )
                                            },
                                          },
                                          model: {
                                            value: item.prizeType,
                                            callback: function ($$v) {
                                              _vm.$set(item, "prizeType", $$v)
                                            },
                                            expression: "item.prizeType",
                                          },
                                        },
                                        _vm._l(
                                          _vm.prizeTypes,
                                          function (item3, index3) {
                                            return _c("el-option", {
                                              key: index3,
                                              attrs: {
                                                label: item3.name,
                                                value: item3.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { staticClass: "inlineItem", attrs: { md: 4 } },
                                [
                                  item.prizeType != "0"
                                    ? _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            "label-width": "0",
                                            prop:
                                              "prizeItemsList." +
                                              index +
                                              ".prizeId",
                                            rules: {
                                              required: true,
                                              message: "请选择奖品",
                                              trigger: "change",
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                placeholder: "请选择奖品",
                                                disabled: _vm.isCopyType,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.doSum(index)
                                                },
                                              },
                                              model: {
                                                value: item.prizeId,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "prizeId", $$v)
                                                },
                                                expression: "item.prizeId",
                                              },
                                            },
                                            _vm._l(
                                              item.plist,
                                              function (obj, p) {
                                                return _c("el-option", {
                                                  key: p,
                                                  attrs: {
                                                    label: obj.prizeName,
                                                    value: obj.prizeId,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.prizeType == "0"
                                    ? _c("el-input", {
                                        attrs: {
                                          placeholder: "其他",
                                          disabled:
                                            item.disable ||
                                            item.prizeType == "0" ||
                                            _vm.isCopyType,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { staticClass: "inlineItem", attrs: { md: 5 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        "label-width": "0",
                                        prop:
                                          "prizeItemsList." +
                                          index +
                                          ".prizePr",
                                        rules: {
                                          required: true,
                                          message: "请输入活动概率",
                                          trigger: "blur",
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          directives: [
                                            {
                                              name: "Number2",
                                              rawName: "v-Number2",
                                            },
                                          ],
                                          attrs: {
                                            placeholder: "活动概率",
                                            disabled: _vm.isCopyType,
                                          },
                                          on: {
                                            blur: function ($event) {
                                              return _vm.checkPrizePr(
                                                $event,
                                                index
                                              )
                                            },
                                          },
                                          model: {
                                            value: item.prizePr,
                                            callback: function ($$v) {
                                              _vm.$set(item, "prizePr", $$v)
                                            },
                                            expression: "item.prizePr",
                                          },
                                        },
                                        [
                                          _c("template", { slot: "append" }, [
                                            _vm._v("%"),
                                          ]),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { staticClass: "inlineItem", attrs: { md: 5 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        "label-width": "0",
                                        prop:
                                          "prizeItemsList." +
                                          index +
                                          ".prizePrAlias",
                                        rules: {
                                          required: true,
                                          message: "请输入展示概率",
                                          trigger: "blur",
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          directives: [
                                            {
                                              name: "Number2",
                                              rawName: "v-Number2",
                                            },
                                          ],
                                          attrs: {
                                            placeholder: "展示概率",
                                            disabled: _vm.isCopyType,
                                          },
                                          on: {
                                            blur: function ($event) {
                                              return _vm.checkPrizePrAlias(
                                                $event,
                                                index
                                              )
                                            },
                                          },
                                          model: {
                                            value: item.prizePrAlias,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item,
                                                "prizePrAlias",
                                                $$v
                                              )
                                            },
                                            expression: "item.prizePrAlias",
                                          },
                                        },
                                        [
                                          _c("template", { slot: "append" }, [
                                            _vm._v("%"),
                                          ]),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { staticClass: "inlineItem", attrs: { md: 4 } },
                                [
                                  item.prizeType != "0"
                                    ? _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            "label-width": "0",
                                            prop:
                                              "prizeItemsList." +
                                              index +
                                              ".prizeNum",
                                            rules: {
                                              required: true,
                                              message: "请输入数量",
                                              trigger: "blur",
                                            },
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            directives: [
                                              { name: "Int", rawName: "v-Int" },
                                            ],
                                            attrs: {
                                              placeholder: "数量",
                                              disabled: _vm.isCopyType,
                                            },
                                            on: {
                                              blur: function ($event) {
                                                return _vm.doSum(index)
                                              },
                                            },
                                            model: {
                                              value: item.prizeNum,
                                              callback: function ($$v) {
                                                _vm.$set(item, "prizeNum", $$v)
                                              },
                                              expression: "item.prizeNum",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.prizeType == "0"
                                    ? _c("el-input", {
                                        attrs: {
                                          placeholder: "其他",
                                          disabled:
                                            item.disable ||
                                            item.prizeType == "0" ||
                                            _vm.isCopyType,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { staticClass: "inlineItem", attrs: { md: 4 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        "label-width": "0",
                                        prop:
                                          "prizeItemsList." +
                                          index +
                                          ".prizeBudgetTotalAmount",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          directives: [
                                            {
                                              name: "Number",
                                              rawName: "v-Number",
                                            },
                                          ],
                                          attrs: {
                                            readonly: "",
                                            disabled: false,
                                          },
                                          model: {
                                            value: item.prizeBudgetTotalAmount,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item,
                                                "prizeBudgetTotalAmount",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.prizeBudgetTotalAmount",
                                          },
                                        },
                                        [
                                          _c("template", { slot: "prepend" }, [
                                            _vm._v("预算"),
                                          ]),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  staticClass: "inlineItem",
                                  attrs: { span: 1 },
                                },
                                [
                                  index == 0 && _vm.isShowPrizeSetBtn
                                    ? _c("el-button", {
                                        staticStyle: { float: "right" },
                                        attrs: {
                                          disabled: _vm.isCopyType,
                                          type: "primary",
                                          size: "mini",
                                          icon: "el-icon-plus",
                                          circle: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addCodePrize(index)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  index >= 1 && _vm.isShowPrizeSetBtn
                                    ? _c("el-button", {
                                        staticStyle: { float: "right" },
                                        attrs: {
                                          disabled: _vm.isCopyType,
                                          type: "primary",
                                          size: "mini",
                                          icon: "el-icon-delete",
                                          circle: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.delCodePrize(index)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        {
                          staticStyle: { "margin-bottom": "18px" },
                          attrs: { type: "flex", gutter: 10 },
                        },
                        [
                          _c(
                            "el-col",
                            { staticClass: "inlineItem", attrs: { md: 3 } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "其他", disabled: "" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { staticClass: "inlineItem", attrs: { md: 4 } },
                            [
                              _c("el-input", {
                                attrs: { readonly: "", value: "谢谢惠顾" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { staticClass: "inlineItem", attrs: { md: 4 } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "其他", disabled: "" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { staticClass: "inlineItem", attrs: { md: 5 } },
                            [
                              _c(
                                "el-input",
                                {
                                  directives: [
                                    { name: "Number2", rawName: "v-Number2" },
                                  ],
                                  attrs: {
                                    placeholder: "活动概率",
                                    readonly: "",
                                    disabled: "",
                                  },
                                  model: {
                                    value: _vm.thanksData.prizePr,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.thanksData, "prizePr", $$v)
                                    },
                                    expression: "thanksData.prizePr",
                                  },
                                },
                                [
                                  _c("template", { slot: "append" }, [
                                    _vm._v("%"),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { staticClass: "inlineItem", attrs: { md: 5 } },
                            [
                              _c(
                                "el-input",
                                {
                                  directives: [
                                    { name: "Number2", rawName: "v-Number2" },
                                  ],
                                  attrs: {
                                    placeholder: "展示概率",
                                    readonly: "",
                                    disabled: "",
                                  },
                                  model: {
                                    value: _vm.thanksData.prizePrAlias,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.thanksData,
                                        "prizePrAlias",
                                        $$v
                                      )
                                    },
                                    expression: "thanksData.prizePrAlias",
                                  },
                                },
                                [
                                  _c("template", { slot: "append" }, [
                                    _vm._v("%"),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { staticClass: "inlineItem", attrs: { md: 4 } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "其他", disabled: "" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { staticClass: "inlineItem", attrs: { md: 4 } },
                            [
                              _c(
                                "el-input",
                                {
                                  directives: [
                                    { name: "Number", rawName: "v-Number" },
                                  ],
                                  attrs: { readonly: "", disabled: false },
                                },
                                [
                                  _c("template", { slot: "prepend" }, [
                                    _vm._v("预算"),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { staticClass: "inlineItem", attrs: { span: 1 } },
                            [
                              _vm.isShowPrizeSetBtn
                                ? _c("el-button", {
                                    staticStyle: {
                                      float: "right",
                                      opacity: "0",
                                    },
                                    attrs: {
                                      type: "primary",
                                      size: "mini",
                                      icon: "el-icon-plus",
                                      circle: "",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("el-divider"),
                  _vm._v(" "),
                  _c("p", { staticClass: "elh3" }, [_vm._v("预警设置")]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "labelCenter formRadio",
                      attrs: { prop: "isOpenWarning" },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "formTitle",
                          attrs: { slot: "label" },
                          slot: "label",
                        },
                        [
                          _c("i", {
                            staticClass: "iconfont iconyuandianzhong link",
                          }),
                          _vm._v(" 预警阀值\n          "),
                        ]
                      ),
                      _vm._v(" "),
                      _c("el-switch", {
                        attrs: {
                          "active-value": "0",
                          "inactive-value": "1",
                          disabled: _vm.showOnly,
                        },
                        model: {
                          value: _vm.codeForm.isOpenWarning,
                          callback: function ($$v) {
                            _vm.$set(_vm.codeForm, "isOpenWarning", $$v)
                          },
                          expression: "codeForm.isOpenWarning",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.codeForm.isOpenWarning,
                              expression: "codeForm.isOpenWarning",
                            },
                          ],
                          staticClass: "formTip",
                          staticStyle: { "margin-left": "20px!important" },
                        },
                        [
                          _c("i", { staticClass: "el-icon-warning-outline" }),
                          _vm._v(
                            " 提示：开启后，当消费者未领取兑换的奖品数量/奖品总库存量<=预警阀值，将会给预\n            置邮箱发预警邮件\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.codeForm.isOpenWarning * 1 != 1
                    ? _c(
                        "el-form-item",
                        {
                          attrs: { label: "接收邮箱：", prop: "warningMails" },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder:
                                "请输入接收预警邮箱，多个邮箱用,隔开",
                              disabled: _vm.showOnly,
                            },
                            model: {
                              value: _vm.codeForm.warningMails,
                              callback: function ($$v) {
                                _vm.$set(_vm.codeForm, "warningMails", $$v)
                              },
                              expression: "codeForm.warningMails",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    {
                      staticStyle: { "margin-bottom": "0" },
                      attrs: { gutter: 20 },
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _vm.codeForm.isOpenWarning * 1 != 1
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "预警阀值：",
                                    prop: "warningThreshold",
                                  },
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      directives: [
                                        {
                                          name: "only-number",
                                          rawName: "v-only-number",
                                          value: {
                                            max: 99,
                                            min: 1,
                                            precision: 0,
                                            set: _vm.codeForm,
                                            name: "warningThreshold",
                                          },
                                          expression:
                                            "{ max:99,min:1,precision:0,set: codeForm, name:'warningThreshold' }",
                                        },
                                      ],
                                      attrs: {
                                        placeholder: "",
                                        disabled: _vm.showOnly,
                                      },
                                      model: {
                                        value: _vm.codeForm.warningThreshold,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.codeForm,
                                            "warningThreshold",
                                            $$v
                                          )
                                        },
                                        expression: "codeForm.warningThreshold",
                                      },
                                    },
                                    [
                                      _c("template", { slot: "append" }, [
                                        _vm._v("%"),
                                      ]),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _vm.codeForm.isOpenWarning * 1 != 1
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "预警频率：",
                                    prop: "warningFrequency",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择",
                                        disabled: _vm.showOnly,
                                      },
                                      model: {
                                        value: _vm.codeForm.warningFrequency,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.codeForm,
                                            "warningFrequency",
                                            $$v
                                          )
                                        },
                                        expression: "codeForm.warningFrequency",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "6小时", value: "6" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { label: "12小时", value: "12" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { label: "24小时", value: "24" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { label: "72小时", value: "72" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  staticStyle: { "margin-top": "-10px" },
                  attrs: { "label-position": "left" },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-bottom": "10px" },
                      attrs: { label: "活动预算:" },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "hdysWrap",
                          attrs: { slot: "label" },
                          slot: "label",
                        },
                        [
                          _c("img", {
                            staticClass: "hdys",
                            attrs: { src: require("@/images/hdys.png") },
                          }),
                          _vm._v(" 活动预算:\n          "),
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", { staticStyle: { color: "#337ab7" } }, [
                        _vm._v(_vm._s(_vm.codeTotal) + "元"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { staticClass: "normalBtn", on: { click: _vm.goBack } },
            [_vm._v("关闭")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.active !== 0,
                  expression: "active !==0",
                },
              ],
              staticClass: "normalBtn",
              attrs: { type: "primary" },
              on: { click: _vm.prev },
            },
            [_vm._v("上一步")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.active !== 2,
                  expression: "active !==2",
                },
              ],
              staticClass: "normalBtn",
              attrs: { type: "primary" },
              on: { click: _vm.next },
            },
            [_vm._v("下一步")]
          ),
          _vm._v(" "),
          !_vm.showOnly
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.active === 2,
                      expression: "active ===2",
                    },
                  ],
                  staticClass: "normalBtn",
                  attrs: {
                    type: "primary",
                    disabled: _vm.isDisable,
                    loading: _vm.subLoading,
                  },
                  on: { click: _vm.finish },
                },
                [_vm._v("完成")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.jxsFyVisible
        ? _c("jxsFy", {
            attrs: {
              visible: _vm.jxsFyVisible,
              data: _vm.currentCostAllocation,
            },
            on: {
              change: _vm.getSelectJxsFy,
              close: function ($event) {
                _vm.jxsFyVisible = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.orderVisible
        ? _c("ordersSelect", {
            attrs: {
              visible: _vm.orderVisible,
              data: _vm.pageTwoForm.orderRowList,
            },
            on: {
              change: _vm.getSelectOrders,
              close: function ($event) {
                _vm.orderVisible = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.h_batcheVisibleShow
        ? _c("DealerDialog", {
            ref: "h_batchVisible",
            attrs: {
              "pro-sel-visible": _vm.h_batcheVisibleShow,
              data: _vm.pageTwoForm.channelRowList,
            },
            on: {
              "update:data": function ($event) {
                return _vm.$set(_vm.pageTwoForm, "channelRowList", $event)
              },
              change: _vm.h_setBatch,
              close: function ($event) {
                _vm.h_batcheVisibleShow = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.ClassVisibleShow
        ? _c("ClassifyDialog2", {
            ref: "ClassVisible",
            attrs: {
              "pro-sel-visible": _vm.ClassVisibleShow,
              data: _vm.pageTwoForm.classRowList,
            },
            on: {
              "update:data": function ($event) {
                return _vm.$set(_vm.pageTwoForm, "classRowList", $event)
              },
              change: _vm.setClass,
              close: function ($event) {
                _vm.ClassVisibleShow = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.productVisibleShow
        ? _c("ProDialog", {
            ref: "productVisible",
            attrs: {
              "pro-sel-visible": _vm.productVisibleShow,
              data: _vm.pageTwoForm.productRowList,
            },
            on: {
              "update:data": function ($event) {
                return _vm.$set(_vm.pageTwoForm, "productRowList", $event)
              },
              change: _vm.setProduct,
              close: function ($event) {
                _vm.productVisibleShow = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.terminalVisibleShow
        ? _c("TerminalDialog", {
            ref: "terminalVisible",
            attrs: {
              "pro-sel-visible": _vm.terminalVisibleShow,
              data: _vm.pageTwoForm.terminalRowList,
            },
            on: {
              "update:data": function ($event) {
                return _vm.$set(_vm.pageTwoForm, "terminalRowList", $event)
              },
              change: _vm.setTerminal,
              close: function ($event) {
                _vm.terminalVisibleShow = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.mloading,
              expression: "mloading",
            },
          ],
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: _vm.uploadtitle,
            visible: _vm.uploadVisible,
            "before-close": _vm.uploadClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.uploadVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "uploadForm",
              attrs: {
                inline: true,
                model: _vm.uploadForm,
                rulse: _vm.uploadRules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "下载模板", prop: "orgName" } },
                [
                  _c(
                    "a",
                    {
                      staticClass: "down",
                      attrs: {
                        href: _vm.uptemplateList[_vm.params.importType - 1].url,
                        target: "_blank",
                        download:
                          _vm.uptemplateList[_vm.params.importType - 1].name,
                      },
                    },
                    [_vm._v("导入Excel模板")]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "orgName", label: "文件上传" } },
                [
                  _c(
                    "el-row",
                    { staticClass: "head-btn-group", attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-input", {
                            attrs: { disabled: true },
                            model: {
                              value: _vm.uploadForm.uploadFile,
                              callback: function ($$v) {
                                _vm.$set(_vm.uploadForm, "uploadFile", $$v)
                              },
                              expression: "uploadForm.uploadFile",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-upload",
                            {
                              ref: "upload",
                              staticClass: "upload-demo",
                              attrs: {
                                headers: _vm.myHeaders,
                                action: _vm.uploadUrl,
                                limit: 1,
                                name: "file",
                                accept: ".xls, .xlsx",
                                "file-list": _vm.fileList,
                                "show-file-list": false,
                                "auto-upload": false,
                                "on-change": _vm.selectFile,
                                "on-success": _vm.uploadFileRes,
                                "on-error": _vm.uploadFileFail,
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    slot: "trigger",
                                    size: "mini",
                                    type: "primary",
                                  },
                                  slot: "trigger",
                                },
                                [_vm._v("打开")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "primary" },
                                  on: { click: _vm.submitUpload },
                                },
                                [_vm._v("导入")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }