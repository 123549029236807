var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c(
            "el-row",
            { attrs: { type: "flex" } },
            [
              _c(
                "el-col",
                {
                  staticClass: "page-section",
                  staticStyle: {
                    width: "400px",
                    "max-height": "100vh",
                    border: "0",
                  },
                },
                [
                  _vm.hasAuth("addbtn", _vm.$route.meta.authList)
                    ? _c(
                        "div",
                        {
                          staticClass: "phead",
                          staticStyle: { "padding-bottom": "49px" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "head-btn-group",
                              staticStyle: {
                                position: "relative",
                                top: "0",
                                left: "0",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  type: _vm.btnConfig.addBtn.type,
                                  size: _vm.btnConfig.size,
                                  icon: _vm.btnConfig.addBtn.icon,
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.LabelsCommand("label_create")
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      key: _vm.tableKey,
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.labelList,
                        "tooltip-effect": "dark",
                        fit: "",
                        "highlight-current-row": "",
                        "show-header": false,
                        height: "calc(100vh - 270px)",
                      },
                      on: { "row-click": _vm.handleNodeClick },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "left",
                          "min-width": "100px",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.flagType == 1
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "16px",
                                          height: "16px",
                                          "margin-right": "10px",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/biaoqian.png"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.flagType == 2
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "16px",
                                          height: "16px",
                                          "margin-right": "10px",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/gai.png"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(scope.row.flagName) +
                                      "\n          "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3657210380
                        ),
                      }),
                      _vm._v(" "),
                      _vm.hasAuth("deletebtn", _vm.$route.meta.authList) ||
                      _vm.hasAuth("updatebtn", _vm.$route.meta.authList)
                        ? _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              align: _vm.tableConfig.align,
                              width: "200",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            display: "inline-block",
                                            width: "50px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("relationType")(
                                                row.relationType
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.hasAuth(
                                        "updatebtn",
                                        _vm.$route.meta.authList
                                      )
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function () {
                                                  return _vm.LabelsCommand(
                                                    "label_update",
                                                    row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("编辑名称")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.hasAuth(
                                        "deletebtn",
                                        _vm.$route.meta.authList
                                      )
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function () {
                                                  return _vm.LabelsCommandRemove(
                                                    row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("删除")]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3700155456
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { width: "calc(100% - 400px)" } },
                [
                  _c("div", { attrs: { id: "seach-box" } }, [
                    _c(
                      "div",
                      { staticClass: "page-section" },
                      [
                        _c(
                          "el-form",
                          {
                            ref: "searchform",
                            staticClass: "search-condition",
                            attrs: {
                              model: _vm.listQuery,
                              "label-suffix": ":",
                              "label-position": _vm.formConfig.labelPosition,
                              "label-width": _vm.formConfig.labelWidth,
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "cols" },
                              [
                                _c(
                                  "el-row",
                                  {
                                    staticStyle: { "margin-bottom": "0" },
                                    attrs: { gutter: 20 },
                                  },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop: "markType",
                                              label: "制码类型",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticClass: "filter-item",
                                                attrs: {
                                                  clearable: "",
                                                  "value-key": "id",
                                                  placeholder: "选择制码类型",
                                                },
                                                model: {
                                                  value: _vm.listQuery.markType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.listQuery,
                                                      "markType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "listQuery.markType",
                                                },
                                              },
                                              _vm._l(
                                                _vm.numberType,
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item,
                                                    attrs: {
                                                      label:
                                                        _vm._f("codeType")(
                                                          item
                                                        ),
                                                      value: item,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.formConfig.btnFollow
                                      ? _c(
                                          "el-col",
                                          {
                                            staticClass: "head-btn-group",
                                            style: {
                                              width: _vm.formConfig.btnArea,
                                            },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { "label-width": "0" } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    attrs: { slot: "label" },
                                                    slot: "label",
                                                  },
                                                  [_vm._v(" ")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      type: _vm.btnConfig
                                                        .searchBtn.type,
                                                      size: _vm.btnConfig.size,
                                                      icon: _vm.btnConfig
                                                        .searchBtn.icon,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.listQuery.current = 1
                                                        _vm.getList()
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.btnConfig.searchBtn
                                                          .text
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      type: _vm.btnConfig
                                                        .resetBtn.type,
                                                      size: _vm.btnConfig.size,
                                                      icon: _vm.btnConfig
                                                        .resetBtn.icon,
                                                    },
                                                    on: { click: _vm.reset },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.btnConfig.resetBtn
                                                          .text
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.formConfig.btnFollow
                          ? _c("el-divider", { staticClass: "btnDivider" })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "btnArea" }, [
                          _vm.hasAuth("addbtnA", _vm.$route.meta.authList)
                            ? _c(
                                "div",
                                { staticClass: "head-btn-group" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: _vm.btnConfig.addBtn.type,
                                        size: _vm.btnConfig.size,
                                        icon: _vm.btnConfig.addBtn.icon,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleUpdate(
                                            false,
                                            "label_create"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.btnConfig.addBtn.text))]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { attrs: { id: "table-box" } },
                          [
                            _c(
                              "el-table",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.listLoading,
                                    expression: "listLoading",
                                  },
                                ],
                                key: _vm.tableKey,
                                staticStyle: { width: "100%" },
                                attrs: {
                                  id: "lblGrpTable",
                                  height: _vm.tabHeight,
                                  data: _vm.list,
                                  "row-key": "pid",
                                  border: _vm.tableConfig.border,
                                  stripe: _vm.tableConfig.stripe,
                                  "default-expand-all": "",
                                  "tree-props": {
                                    children: "children",
                                    hasChildren: "hasChildren",
                                  },
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    type: "index",
                                    width: "80",
                                    label: "序号",
                                    align: _vm.tableConfig.align,
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "层级",
                                    align: _vm.tableConfig.align,
                                    "min-width": "120",
                                    "show-overflow-tooltip": "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("packType")(
                                                    scope.row.packLevel
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3006887889
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "码类型",
                                    align: _vm.tableConfig.align,
                                    "min-width": "120",
                                    "show-overflow-tooltip": "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("codeType")(
                                                    scope.row.markType
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3427812999
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "带顺序号",
                                    align: _vm.tableConfig.align,
                                    "min-width": "120",
                                    "show-overflow-tooltip": "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("isHadSerno")(
                                                    scope.row.isHadSerno,
                                                    scope.row.isHadSerno
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2594015585
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "数码长度",
                                    align: _vm.tableConfig.align,
                                    "min-width": "120",
                                    "show-overflow-tooltip": "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("levelType")(
                                                    scope.row.packLevel,
                                                    scope.row.markType
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3914337215
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "创建时间",
                                    align: _vm.tableConfig.align,
                                    width: "180",
                                    prop: "createTime",
                                  },
                                }),
                                _vm._v(" "),
                                _vm.hasAuth(
                                  "deletebtn",
                                  _vm.$route.meta.authList
                                ) ||
                                _vm.hasAuth(
                                  "updatebtn",
                                  _vm.$route.meta.authList
                                ) ||
                                _vm.hasAuth("addbtn", _vm.$route.meta.authList)
                                  ? _c("el-table-column", {
                                      attrs: {
                                        label: "操作",
                                        fixed: "right",
                                        align: _vm.tableConfig.align,
                                        width: "240",
                                        "class-name":
                                          "small-padding fixed-width",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var row = ref.row
                                              return [
                                                _vm.hasAuth(
                                                  "addbtn",
                                                  _vm.$route.meta.authList
                                                )
                                                  ? _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          disabled:
                                                            (row.packLevel ==
                                                              "2" &&
                                                              row.markType ==
                                                                "1") ||
                                                            (row.packLevel ==
                                                              "1" &&
                                                              row.markType ==
                                                                "1"),
                                                          type: "text",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleUpdate(
                                                              row,
                                                              "label_create"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("添加关联码")]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.hasAuth(
                                                  "updatebtn",
                                                  _vm.$route.meta.authList
                                                )
                                                  ? _c(
                                                      "el-button",
                                                      {
                                                        attrs: { type: "text" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleUpdate(
                                                              row,
                                                              "label_update"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("编辑")]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.hasAuth(
                                                  "deletebtn",
                                                  _vm.$route.meta.authList
                                                )
                                                  ? _c(
                                                      "el-button",
                                                      {
                                                        attrs: { type: "text" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleDelete(
                                                              row,
                                                              "deleted"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("删除")]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1245116228
                                      ),
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("pagination", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.total > 0,
                                  expression: "total > 0",
                                },
                              ],
                              attrs: {
                                total: _vm.total,
                                page: _vm.listQuery.current,
                                limit: _vm.listQuery.size,
                              },
                              on: {
                                "update:page": function ($event) {
                                  return _vm.$set(
                                    _vm.listQuery,
                                    "current",
                                    $event
                                  )
                                },
                                "update:limit": function ($event) {
                                  return _vm.$set(_vm.listQuery, "size", $event)
                                },
                                pagination: _vm.getList,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-dialog",
                    {
                      attrs: {
                        "close-on-click-modal": false,
                        "append-to-body": true,
                        title: _vm.textMap[_vm.dialogStatus],
                        visible: _vm.dialogFormVisible,
                        "before-close": _vm.resetData,
                        top: "3%",
                      },
                      on: {
                        "update:visible": function ($event) {
                          _vm.dialogFormVisible = $event
                        },
                      },
                    },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "dataForm",
                          staticClass: "mark_form",
                          attrs: {
                            rules: _vm.rules,
                            model: _vm.temp,
                            "label-position": "right",
                            "label-width": "155px",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "包装层级：", prop: "packLevel" },
                            },
                            [
                              _vm.flagType == 2
                                ? _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.chagelevel(
                                            _vm.temp.packLevel
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.temp.packLevel,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.temp, "packLevel", $$v)
                                        },
                                        expression: "temp.packLevel",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "盖层级", value: 1 },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { label: "瓶层级", value: 2 },
                                      }),
                                    ],
                                    1
                                  )
                                : _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.chagelevel(
                                            _vm.temp.packLevel
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.temp.packLevel,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.temp, "packLevel", $$v)
                                        },
                                        expression: "temp.packLevel",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "箱层级", value: 4 },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { label: "盒层级", value: 3 },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { label: "瓶层级", value: 2 },
                                      }),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "制码类型：", prop: "markType" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "filter-item",
                                  attrs: {
                                    "value-key": "id",
                                    placeholder: "选择制码类型",
                                    disabled: _vm.edit_privilege,
                                  },
                                  model: {
                                    value: _vm.temp.markType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.temp, "markType", $$v)
                                    },
                                    expression: "temp.markType",
                                  },
                                },
                                _vm._l(_vm.typeList, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.name,
                                      value: item.markType,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "URL类型：", prop: "urlType" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  on: { change: _vm.changeUrlTypeHandler },
                                  model: {
                                    value: _vm.selectOptions.urlType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.selectOptions,
                                        "urlType",
                                        $$v
                                      )
                                    },
                                    expression: "selectOptions.urlType",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: {
                                        disabled: _vm.edit_privilege,
                                        label: 1,
                                      },
                                    },
                                    [_vm._v("默认 不带URL")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: {
                                        disabled: _vm.edit_privilege,
                                        label: 2,
                                      },
                                    },
                                    [_vm._v("取URL数据列表")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.selectOptions.urlType == 2
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "urlAddress",
                                    label: "已选url地址：",
                                  },
                                },
                                [
                                  _vm.urlAddress
                                    ? _c("span", [
                                        _vm._v(_vm._s(_vm.urlAddress)),
                                      ])
                                    : _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            size: "mini",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.dialogUrlListVisible = true
                                            },
                                          },
                                        },
                                        [_vm._v("选择URL")]
                                      ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.relationType == 1
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "是否带顺序号：",
                                    prop: "isHadSerno",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      attrs: { disabled: _vm.edit_privilege },
                                      on: { change: _vm.changetab },
                                      model: {
                                        value: _vm.temp.isHadSerno,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.temp, "isHadSerno", $$v)
                                        },
                                        expression: "temp.isHadSerno",
                                      },
                                    },
                                    [
                                      _c("el-radio", { attrs: { label: 0 } }, [
                                        _vm._v("默认 不带"),
                                      ]),
                                      _vm._v(" "),
                                      _c("el-radio", { attrs: { label: 1 } }, [
                                        _vm._v("带顺序号"),
                                      ]),
                                      _vm._v(" "),
                                      _c("el-radio", { attrs: { label: 2 } }, [
                                        _vm._v("顺序号+父级顺序号"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "dialog-footer",
                          attrs: { slot: "footer" },
                          slot: "footer",
                        },
                        [
                          _c("el-button", { on: { click: _vm.resetData } }, [
                            _vm._v("取消"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.btn_loading,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.submitData()
                                },
                              },
                            },
                            [_vm._v("确定")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            width: "900px",
            title: "URL列表",
            visible: _vm.dialogUrlListVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogUrlListVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { attrs: { id: "table-box" } },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  ref: "singleTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.urlList,
                    border: "",
                    "highlight-current-row": "",
                    height: "300",
                  },
                  on: { "row-click": _vm.selectUrlData },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "index", width: "120" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "URL地址" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.urlAddress))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "URL描述", align: _vm.tableConfig.align },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.urlDesc))]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.url_total > 0,
                expression: "url_total > 0",
              },
            ],
            attrs: {
              total: _vm.url_total,
              page: _vm.urllistQuery.pageNo,
              limit: _vm.urllistQuery.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.urllistQuery, "pageNo", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.urllistQuery, "pageSize", $event)
              },
              pagination: _vm.getUrlList,
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogUrlListVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.selectUrlData(0)
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: _vm.textMap[_vm.dialogStatus],
            visible: _vm.dialogLabeFormVisible,
            top: "3%",
            width: "600px!important",
            "before-close": _vm.labelClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogLabeFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "LabeDataForm",
              staticClass: "mark_form",
              attrs: {
                rules: _vm.Laberules,
                model: _vm.labelTemp,
                "label-position": "right",
                "label-width": "155px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "标识组名称:", prop: "flagName" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入标识组名称",
                      maxlength: "40",
                      "show-word-limit": "",
                      value: "",
                      clearable: "",
                    },
                    model: {
                      value: _vm.labelTemp.flagName,
                      callback: function ($$v) {
                        _vm.$set(_vm.labelTemp, "flagName", $$v)
                      },
                      expression: "labelTemp.flagName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "标识组类型:", prop: "flagType" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { clearable: "", placeholder: "请选择" },
                      model: {
                        value: _vm.labelTemp.flagType,
                        callback: function ($$v) {
                          _vm.$set(_vm.labelTemp, "flagType", $$v)
                        },
                        expression: "labelTemp.flagType",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "标签", value: 1 } }),
                      _vm._v(" "),
                      _c("el-option", { attrs: { label: "盖标", value: 2 } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "关联模式:", prop: "relationType" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { clearable: "", placeholder: "请选择" },
                      on: { change: _vm.changetab },
                      model: {
                        value: _vm.labelTemp.relationType,
                        callback: function ($$v) {
                          _vm.$set(_vm.labelTemp, "relationType", $$v)
                        },
                        expression: "labelTemp.relationType",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "套标", value: 1 } }),
                      _vm._v(" "),
                      _c("el-option", { attrs: { label: "后关联", value: 2 } }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.labelClose } }, [
                _vm._v("取消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.btn_loading },
                  on: {
                    click: function ($event) {
                      _vm.dialogStatus === "label_create"
                        ? _vm.labelcreateData()
                        : _vm.labelupdateData()
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }