"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'VueHoverMask',
  methods: {
    handleClick: function handleClick() {
      this.$emit('click');
    }
  }
};
exports.default = _default;