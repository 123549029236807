"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es6.regexp.match");
require("core-js/modules/es6.regexp.replace");
var _vue = _interopRequireDefault(require("vue"));
// 针对 el-input做的限制，只能输入正数
_vue.default.directive('Number', {
  bind: function bind(el) {
    var input = el.getElementsByTagName('input')[0];
    input.onkeyup = function (e) {
      trigger(input, 'input');
    };
    input.onblur = function (e) {
      input.value = input.value.replace(/[^?:1-9\d*|0?(?:\.\d+)?]/g, '');
      input.value = input.value.match(/\d*(\.\d{0,4})?/)[0];
      trigger(input, 'input');
    };
  }
});
var trigger = function trigger(el, type) {
  var e = document.createEvent('HTMLEvents');
  e.initEvent(type, true, true);
  el.dispatchEvent(e);
};