"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _select = require("@/api/warehouse/select");
var _utils = require("@/utils");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var TypeDataComs = {
  orderStatus: []
};
var _default = {
  name: 'Order',
  components: {
    Pagination: _Pagination.default
  },
  filters: {
    tradeStatus: function tradeStatus(value) {
      var name = '';
      TypeDataComs.orderStatus.forEach(function (c) {
        if (c.figure == value) {
          name = c.name;
          return;
        }
      });
      return name;
    },
    orderType: function orderType(val) {
      var types = {
        1: '买赠订单',
        2: '酒券订单',
        3: '团购订单',
        4: '费用酒订单',
        5: '陈列酒订单'
      };
      return types[val];
    },
    orderSource: function orderSource(val) {
      var source = {
        1: 'PC',
        2: 'android ',
        3: 'ios',
        4: '小程序',
        5: '补单',
        6: '同步创建'
      };
      return source[val];
    }
  },
  props: {
    visible: {
      default: false
    },
    purchaseOrgId: {
      default: ''
    },
    productId: {
      default: ''
    },
    saleOrgId: {
      default: ''
    }
  },
  data: function data() {
    return {
      listQuery: {
        current: 1,
        productId: '',
        purchaseOrgId: '',
        saleOrgId: '',
        size: 10
      },
      tableKey: 'orderTable',
      tabHeight: '100%',
      list: [],
      listLoading: false,
      total: 0
    };
  },
  computed: {},
  watch: {
    visible: function visible(val) {
      if (val) {
        this.listQuery.purchaseOrgId = this.purchaseOrgId;
        this.listQuery.productId = this.productId;
        this.listQuery.saleOrgId = this.saleOrgId;
        this.getList();
      } else {
        this.listQuery.purchaseOrgId = '';
        this.listQuery.productId = '';
        this.listQuery.saleOrgId = '';
        return;
      }
    }
  },
  created: function created() {
    var that = this;
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    getList: function getList() {
      var that = this;
      that.listLoading = true;
      var param = that.listQuery;
      (0, _select.getJkStoragesDetail)(param).then(function (res) {
        if (res.data.code == 200) {
          that.list = res.data.data.records;
          that.total = parseInt(res.data.data.total);
          setTimeout(function () {
            that.listLoading = false;
          }, 1 * 800);
        } else {
          that.$message.error(res.data.msg);
          that.listLoading = false;
          return;
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    selCancel: function selCancel() {
      var that = this;
      that.$emit('close');
    }
  }
};
exports.default = _default;