var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.apis)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c(
                "el-form",
                {
                  ref: "listQuery",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.listQuery,
                    "status-icon": "",
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cols" },
                    [
                      _c(
                        "el-row",
                        {
                          staticStyle: { "margin-bottom": "0" },
                          attrs: { gutter: 20 },
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "orderCode",
                                    label: "调整单号:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入单号" },
                                    model: {
                                      value: _vm.listQuery.orderCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.listQuery,
                                          "orderCode",
                                          $$v
                                        )
                                      },
                                      expression: "listQuery.orderCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "channelStr",
                                    label: "经销商:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      "suffix-icon": "el-icon-search",
                                      placeholder: "请输入",
                                      readonly: true,
                                    },
                                    on: {
                                      focus: function ($event) {
                                        return _vm.getUnit("search")
                                      },
                                    },
                                    model: {
                                      value: _vm.channelStr,
                                      callback: function ($$v) {
                                        _vm.channelStr = $$v
                                      },
                                      expression: "channelStr",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { prop: "orgId", label: "所属组织:" },
                                },
                                [
                                  _c("treeselect", {
                                    attrs: {
                                      multiple: false,
                                      "flatten-search-results": true,
                                      placeholder: "请选择组织...",
                                      options: _vm.orgOptions,
                                    },
                                    model: {
                                      value: _vm.listQuery.orgId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.listQuery, "orgId", $$v)
                                      },
                                      expression: "listQuery.orgId",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.showCol
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "startTime",
                                        label: "审核起始日期:",
                                      },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          type: "date",
                                          format: "yyyy-MM-dd",
                                          "value-format": "yyyy-MM-dd",
                                          placeholder: "请选择日期",
                                        },
                                        model: {
                                          value: _vm.listQuery.startTime,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.listQuery,
                                              "startTime",
                                              $$v
                                            )
                                          },
                                          expression: "listQuery.startTime",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showCol
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "endTime",
                                        label: "审核结束日期:",
                                      },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          type: "date",
                                          format: "yyyy-MM-dd",
                                          "value-format": "yyyy-MM-dd",
                                          placeholder: "请选择日期",
                                        },
                                        model: {
                                          value: _vm.listQuery.endTime,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.listQuery,
                                              "endTime",
                                              $$v
                                            )
                                          },
                                          expression: "listQuery.endTime",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.showCol || !_vm.formConfig.btnFollow,
                                  expression:
                                    "showCol || !formConfig.btnFollow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "productStr",
                                    label: "产品名称:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      "suffix-icon": "el-icon-search",
                                      placeholder: "请输入",
                                      readonly: true,
                                    },
                                    on: { focus: _vm.getPname },
                                    model: {
                                      value: _vm.productStr,
                                      callback: function ($$v) {
                                        _vm.productStr = $$v
                                      },
                                      expression: "productStr",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.showCol || !_vm.formConfig.btnFollow,
                                  expression:
                                    "showCol || !formConfig.btnFollow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "stockStatus",
                                    label: "单据状态:",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.listQuery.stockStatus,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.listQuery,
                                            "stockStatus",
                                            $$v
                                          )
                                        },
                                        expression: "listQuery.stockStatus",
                                      },
                                    },
                                    _vm._l(_vm.states, function (item) {
                                      return _c("el-option", {
                                        key: item.code,
                                        attrs: {
                                          label: item.name,
                                          value: item.code,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.formConfig.btnFollow
                            ? _c(
                                "el-col",
                                {
                                  staticClass: "head-btn-group",
                                  attrs: { span: 6 },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { "label-width": "0" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v(" ")]
                                      ),
                                      _vm._v(" "),
                                      _vm.hasAuth("searchbtn", _vm.apis)
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: _vm.btnConfig.searchBtn
                                                  .type,
                                                size: _vm.btnConfig.size,
                                                icon: _vm.btnConfig.searchBtn
                                                  .icon,
                                              },
                                              on: { click: _vm.DoSearch },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.btnConfig.searchBtn.text
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.formConfig.showDvider
                                        ? _c("el-divider", {
                                            attrs: { direction: "vertical" },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.hasAuth("searchbtn", _vm.apis)
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: _vm.btnConfig.resetBtn
                                                  .type,
                                                size: _vm.btnConfig.size,
                                                icon: _vm.btnConfig.resetBtn
                                                  .icon,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.DoReset(
                                                    _vm.listQuery
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.btnConfig.resetBtn.text
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.formConfig.showDvider
                                        ? _c("el-divider", {
                                            attrs: { direction: "vertical" },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.hasAuth("searchbtn", _vm.apis)
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: { click: _vm.expand },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(_vm.expandTxt) +
                                                  "\n                  "
                                              ),
                                              _c("i", {
                                                class:
                                                  _vm.showCol == false
                                                    ? "el-icon-arrow-down"
                                                    : "el-icon-arrow-up",
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.formConfig.btnFollow
                ? _c("el-divider", { staticClass: "btnDivider" })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btnArea" },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "head-btn-group",
                      staticStyle: { width: "230px" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "head-btn-group" },
                        [
                          _vm.hasAuth("addbtn", _vm.apis)
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.addBtn.type,
                                    size: _vm.btnConfig.size,
                                    plain: _vm.btnConfig.addBtn.plain,
                                    icon: _vm.btnConfig.addBtn.icon,
                                  },
                                  on: { click: _vm.DoAdd },
                                },
                                [_vm._v(_vm._s(_vm.btnConfig.addBtn.text))]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hasAuth("importbtn", _vm.apis)
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.downLoadBtn.type,
                                    size: _vm.btnConfig.size,
                                    plain: _vm.btnConfig.downLoadBtn.plain,
                                    icon: _vm.btnConfig.downLoadBtn.icon,
                                    loading: _vm.exportLoading,
                                  },
                                  on: { click: _vm.DoExportAll },
                                },
                                [_vm._v(_vm._s(_vm.btnConfig.downLoadBtn.text))]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "section",
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      key: _vm.tableKey,
                      staticStyle: { width: "100%" },
                      attrs: {
                        height: _vm.tabHeight,
                        data: _vm.list,
                        border: _vm.tableConfig.border,
                        stripe: _vm.tableConfig.stripe,
                        fit: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "调整单号",
                          "min-width": "160",
                          prop: "orderCode",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "经销商编码",
                          prop: "channelCode",
                          "min-width": "120",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "经销商名称",
                          prop: "channelName",
                          "min-width": "120",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "经销商分类",
                          prop: "channelClassName",
                          "min-width": "120",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "所属组织",
                          prop: "orgName",
                          "min-width": "120",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "调整数量",
                          prop: "adjustmentSum",
                          "min-width": "120",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "创建时间",
                          prop: "createTime",
                          "min-width": "170",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "审核时间",
                          prop: "auditTime",
                          "min-width": "170",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "单据状态",
                          prop: "stockStatus",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  row.stockStatus == 0
                                    ? _c(
                                        "el-tag",
                                        { attrs: { type: "danger" } },
                                        [_vm._v("已删除")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.stockStatus == 1
                                    ? _c("el-tag", [_vm._v("待提交")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.stockStatus == 2
                                    ? _c("el-tag", [_vm._v("待审核")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.stockStatus == 3
                                    ? _c(
                                        "el-tag",
                                        { attrs: { type: "success" } },
                                        [_vm._v("已审核")]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2872202910
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          align: _vm.tableConfig.align,
                          width: "200",
                          fixed: "right",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm.hasAuth("detailbtn", _vm.apis)
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.DoScan(row)
                                            },
                                          },
                                        },
                                        [_vm._v("详情")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.stockStatus == 1 &&
                                  _vm.hasAuth("updatebtn", _vm.apis)
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.DoEdit(row)
                                            },
                                          },
                                        },
                                        [_vm._v("修改")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.hasAuth("deletebtn", _vm.apis)
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "text",
                                            disabled: row.stockStatus != 1,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.DoDelete(row)
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3962647970
                        ),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("pagination", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.total > 0,
                        expression: "total > 0",
                      },
                    ],
                    attrs: {
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.total,
                      page: _vm.listQuery.current,
                      limit: _vm.listQuery.size,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(_vm.listQuery, "current", $event)
                      },
                      "update:limit": function ($event) {
                        return _vm.$set(_vm.listQuery, "size", $event)
                      },
                      pagination: _vm.getList,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.apis)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: _vm.title,
            visible: _vm.dialogVisible,
            "before-close": _vm.handleClose,
            "append-to-body": true,
            width: "1000px!important",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("h3", { staticClass: "title" }, [_vm._v("单据信息")]),
          _vm._v(" "),
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { "label-width": "85px" },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "is-required",
                          attrs: { label: "经销商:", prop: "channelName" },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              "suffix-icon": "el-icon-search",
                              readonly: true,
                            },
                            on: {
                              focus: function ($event) {
                                return _vm.getUnit("add")
                              },
                            },
                            model: {
                              value: _vm.channelName,
                              callback: function ($$v) {
                                _vm.channelName = $$v
                              },
                              expression: "channelName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("经销商编码:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.channelCode)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("经销商分类:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.channelClassName)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "vue-from-block" }, [
                      _c("div", { staticClass: "form-div" }, [
                        _vm._v("所属组织:"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.orgName)),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "title" },
            [
              _c("span", { staticStyle: { "margin-right": "20px" } }, [
                _vm._v("产品明细"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.getMore },
                },
                [_vm._v("添加产品")]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "fr" }, [
                _vm.addData.deliverGoodsSum
                  ? _c("strong", [
                      _vm._v(
                        "调整发货合计：" + _vm._s(_vm.addData.deliverGoodsSum)
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.addData.receivingGoodsSum
                  ? _c("strong", [
                      _vm._v(
                        "调整收货合计：" + _vm._s(_vm.addData.receivingGoodsSum)
                      ),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                "max-height": "300",
                data: _vm.mtStockAdjustmentProductParams,
                border: _vm.tableConfig.border,
                stripe: _vm.tableConfig.stripe,
                fit: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "产品编码",
                  prop: "productCode",
                  align: _vm.tableConfig.align,
                  width: "160",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "产品名称",
                  prop: "productName",
                  align: _vm.tableConfig.align,
                  width: "160",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "产品分类",
                  prop: "productClassName",
                  align: _vm.tableConfig.align,
                  width: "160",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "规格",
                  prop: "productModel",
                  align: _vm.tableConfig.align,
                  width: "160",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "包装比例",
                  prop: "packScaleDesc",
                  align: _vm.tableConfig.align,
                  width: "160",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "最小单位",
                  prop: "minScaleName",
                  align: _vm.tableConfig.align,
                  width: "160",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "现存量",
                  prop: "existingSum",
                  align: _vm.tableConfig.align,
                  width: "160",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "可用量",
                  prop: "availableSum",
                  align: _vm.tableConfig.align,
                  width: "160",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "调整后数量",
                  prop: "afterSum",
                  align: _vm.tableConfig.align,
                  width: "160",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("el-input-number", {
                          staticStyle: { width: "110px" },
                          attrs: {
                            placeholder: "请输入(必填)",
                            controls: false,
                            precision: 0,
                            min: 0,
                            max: 99999999,
                          },
                          model: {
                            value: row.afterSum,
                            callback: function ($$v) {
                              _vm.$set(row, "afterSum", $$v)
                            },
                            expression: "row.afterSum",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  fixed: "right",
                  align: _vm.tableConfig.align,
                  width: "160",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.removeItem(row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "title" }, [_vm._v("整单备注")]),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { padding: "0 0 15px 0" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 4, maxRows: 4 },
                  maxlength: "500",
                  "show-word-limit": "",
                  placeholder: "请输入",
                },
                model: {
                  value: _vm.addData.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.addData, "remark", $$v)
                  },
                  expression: "addData.remark",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "title" }, [
            _vm._v("\n        图片附件\n        "),
            _c("span", { staticStyle: { "font-size": "14px" } }, [
              _vm._v("最多5张，只能上传jpg/png文件，且不超过500kb"),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-upload",
            {
              attrs: {
                action: "",
                "list-type": "picture-card",
                limit: 5,
                "auto-upload": false,
                "on-preview": _vm.handlePictureCardPreview,
                "on-remove": _vm.handleRemove,
                "on-change": _vm.uploadImg,
                "on-exceed": _vm.handleExceed,
                "file-list": _vm.uploadImgs,
                accept: ".jpg, .jpeg, .png, .PNG, .JPG, .JPEG",
              },
            },
            [_c("i", { staticClass: "el-icon-plus" })]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "btn-bottom-group align-right" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    loading: _vm.loading,
                    type: "primary",
                    size: "mini",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("2")
                    },
                  },
                },
                [_vm._v("提交")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "default", plain: "", size: "mini" },
                  on: { click: _vm.resetForm },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { "close-on-click-modal": false, visible: _vm.imgVisible },
          on: {
            "update:visible": function ($event) {
              _vm.imgVisible = $event
            },
          },
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title:
              _vm.submitType == "scan"
                ? "经销商库存调整-查看"
                : "经销商库存调整-审核",
            visible: _vm.dialogVisible2,
            "append-to-body": true,
            width: "1000px!important",
            "before-close": _vm.showClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible2 = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "stepArea" },
            [
              _c("div", { staticClass: "billTitle" }, [
                _c("img", { attrs: { src: require("@/images/topimg.png") } }),
                _vm._v(
                  "\n          库存调整单 " +
                    _vm._s(_vm.addData.orderCode) +
                    "\n          "
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-steps",
                {
                  staticStyle: { "margin-top": "20px" },
                  attrs: { active: _vm.active, "finish-status": "success" },
                },
                [
                  _c("el-step", { attrs: { title: "提交" } }),
                  _vm._v(" "),
                  _vm.addData.stockStatus != 0
                    ? _c("el-step", { attrs: { title: "审核" } })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.addData.stockStatus == 0
                    ? _c("el-step", {
                        attrs: { title: "删除", status: "error" },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "itemTitle" }, [_vm._v("单据信息")]),
          _vm._v(" "),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("经销商:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.channelName)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [
                    _vm._v("经销商编码:"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.channelCode)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [
                    _vm._v("经销商分类:"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.channelClassName)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "vue-from-block" }, [
                  _c("div", { staticClass: "form-div" }, [_vm._v("所属组织:")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(_vm._s(_vm.orgName)),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "middleLine" }),
          _vm._v(" "),
          _c("div", { staticClass: "itemTitle" }, [
            _c("span", { staticStyle: { "margin-right": "20px" } }, [
              _vm._v("产品明细"),
            ]),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "fr", staticStyle: { "margin-left": "auto" } },
              [
                _vm.addData.deliverGoodsSum
                  ? _c("strong", [
                      _vm._v(
                        "调整发货合计：" + _vm._s(_vm.addData.deliverGoodsSum)
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.addData.receivingGoodsSum
                  ? _c("strong", [
                      _vm._v(
                        "调整收货合计：" + _vm._s(_vm.addData.receivingGoodsSum)
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticClass: "detailTable",
              staticStyle: { width: "100%" },
              attrs: {
                "max-height": "400",
                data: _vm.mtStockAdjustmentProductParams,
                fit: "",
                border: _vm.tableConfig.border,
                stripe: _vm.tableConfig.stripe,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  "min-width": "120px",
                  align: _vm.tableConfig.align,
                  label: "产品编码",
                  prop: "productCode",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  "min-width": "120px",
                  align: _vm.tableConfig.align,
                  label: "产品名称",
                  prop: "productName",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  "min-width": "120px",
                  align: _vm.tableConfig.align,
                  label: "产品分类",
                  prop: "productClassName",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  "min-width": "120px",
                  align: _vm.tableConfig.align,
                  label: "规格",
                  prop: "productModel",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  "min-width": "120px",
                  align: _vm.tableConfig.align,
                  label: "包装比例",
                  prop: "packScaleDesc",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  "min-width": "120px",
                  align: _vm.tableConfig.align,
                  label: "最小单位",
                  prop: "minScaleName",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  "min-width": "120px",
                  align: _vm.tableConfig.align,
                  label: "现存量",
                  prop: "existingSum",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  "min-width": "120px",
                  align: _vm.tableConfig.align,
                  label: "可用量",
                  prop: "availableSum",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  "min-width": "120px",
                  align: _vm.tableConfig.align,
                  label: "调整后数量",
                  prop: "afterSum",
                  width: "140",
                  "show-overflow-tooltip": "",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "middleLine" }),
          _vm._v(" "),
          _c("div", { staticClass: "itemTitle" }, [_vm._v("整单备注")]),
          _vm._v(" "),
          _c("div", { staticStyle: { padding: "0 0 15px 0" } }, [
            _vm._v(_vm._s(_vm.addData.remark)),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "middleLine" }),
          _vm._v(" "),
          _c("div", { staticClass: "itemTitle" }, [_vm._v("图片附件")]),
          _vm._v(" "),
          _c(
            "div",
            _vm._l(_vm.uploadImgs, function (item, index) {
              return _c("el-image", {
                key: index,
                staticStyle: {
                  width: "80px",
                  height: "80px",
                  margin: "0 10px",
                },
                attrs: {
                  src: item.src,
                  "z-index": 9999,
                  "preview-src-list": _vm.preViewList,
                },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "middleLine" }),
          _vm._v(" "),
          _c(
            "el-collapse",
            {
              staticClass: "detailCollapse",
              attrs: { accordion: "" },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-collapse-item",
                { attrs: { name: "1" } },
                [
                  _c("template", { slot: "title" }, [_vm._v("审核日志")]),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        "max-height": "300",
                        data: _vm.checklist,
                        fit: "",
                        border: _vm.tableConfig.border,
                        stripe: _vm.tableConfig.stripe,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "审核结果",
                          align: _vm.tableConfig.align,
                          prop: "type",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  _vm._s(row.auditStatus == 1 ? "通过" : "驳回")
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "审核人",
                          align: _vm.tableConfig.align,
                          prop: "creatorName",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "审核时间",
                          align: _vm.tableConfig.align,
                          prop: "createTime",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "审核意见",
                          align: _vm.tableConfig.align,
                          prop: "auditOpinion",
                          "show-overflow-tooltip": "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.submitType == "sh"
            ? _c("div", { staticClass: "middleLine" })
            : _vm._e(),
          _vm._v(" "),
          _vm.submitType == "sh"
            ? _c("div", { staticClass: "itemTitle" }, [_vm._v("审核")])
            : _vm._e(),
          _vm._v(" "),
          _vm.submitType == "sh"
            ? _c(
                "div",
                { staticStyle: { padding: "0 0 15px 0" } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "auditForm",
                      attrs: {
                        model: _vm.auditForm,
                        "label-width": "85px",
                        "label-suffix": ":",
                        rules: _vm.auditRule,
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "审核结果", prop: "flag" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.auditForm.flag,
                                callback: function ($$v) {
                                  _vm.$set(_vm.auditForm, "flag", $$v)
                                },
                                expression: "auditForm.flag",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("通过"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v("不通过"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "审核意见", prop: "auditMsg" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              maxlength: "100",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.auditForm.auditMsg,
                              callback: function ($$v) {
                                _vm.$set(_vm.auditForm, "auditMsg", $$v)
                              },
                              expression: "auditForm.auditMsg",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.submitType == "sh"
                ? _c(
                    "el-button",
                    {
                      attrs: { loading: _vm.loading, type: "primary" },
                      on: { click: _vm.audit },
                    },
                    [_vm._v("提交")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.showClose } }, [
                _vm._v("关闭"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("instockUnit", { ref: "channelSelect", on: { change: _vm.setCSel } }),
      _vm._v(" "),
      _c("productChose", { ref: "selProduct", on: { change: _vm.setSel } }),
      _vm._v(" "),
      _vm.proShow
        ? _c("product-dialog", {
            attrs: {
              "is-code": "1",
              "pro-sel-visible": _vm.proShow,
              "channel-id": _vm.channelId,
              data: _vm.mtStockAdjustmentProductParams,
            },
            on: {
              "update:data": function ($event) {
                _vm.mtStockAdjustmentProductParams = $event
              },
              change: _vm.getProducts,
              close: _vm.proClose,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n      "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }