var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
      ? _c(
          "div",
          { staticClass: "page-section" },
          [
            _c(
              "el-form",
              {
                ref: "searchForm",
                staticClass: "search-condition",
                attrs: {
                  model: _vm.searchForm,
                  "status-icon": "",
                  "label-suffix": ":",
                  "label-position": _vm.formConfig.labelPosition,
                  "label-width": _vm.formConfig.labelWidth,
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "cols" },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "sourceNo",
                                  label: "源物流单号",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请输入",
                                    maxlength: "50",
                                  },
                                  model: {
                                    value: _vm.searchForm.sourceNo,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "sourceNo",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "searchForm.sourceNo",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "billNo",
                                  label: "调入物流单号",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请输入",
                                    maxlength: "50",
                                  },
                                  model: {
                                    value: _vm.searchForm.targetNo,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "targetNo",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "searchForm.targetNo",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "billNo",
                                  label: "源经销商编码",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请输入",
                                    maxlength: "50",
                                  },
                                  model: {
                                    value: _vm.searchForm.sourceDealerCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "sourceDealerCode",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "searchForm.sourceDealerCode",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.handleShow,
                                expression: "handleShow",
                              },
                            ],
                            attrs: { span: 6 },
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "billNo",
                                  label: "源经销商名称",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请输入",
                                    maxlength: "50",
                                  },
                                  model: {
                                    value: _vm.searchForm.sourceDealerName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "sourceDealerName",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "searchForm.sourceDealerName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.handleShow,
                                expression: "handleShow",
                              },
                            ],
                            attrs: { span: 6 },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "billNo", label: "产品名称" } },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请输入",
                                    maxlength: "50",
                                  },
                                  model: {
                                    value: _vm.searchForm.productName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "productName",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "searchForm.productName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.handleShow,
                                expression: "handleShow",
                              },
                            ],
                            attrs: { span: 6 },
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { prop: "startTime", label: "起始时间" },
                              },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    type: "date",
                                    placeholder: "选择日期",
                                    "value-format": "yyyy-MM-dd",
                                  },
                                  model: {
                                    value: _vm.searchForm.startTime,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "startTime", $$v)
                                    },
                                    expression: "searchForm.startTime",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.handleShow,
                                expression: "handleShow",
                              },
                            ],
                            attrs: { span: 6 },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "endTime", label: "结束时间" } },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    type: "date",
                                    placeholder: "选择日期",
                                    "value-format": "yyyy-MM-dd",
                                  },
                                  model: {
                                    value: _vm.searchForm.endTime,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "endTime", $$v)
                                    },
                                    expression: "searchForm.endTime",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.formConfig.btnFollow
                          ? _c(
                              "el-col",
                              {
                                staticClass: "head-btn-group",
                                style: {
                                  width: _vm.formConfig.btnAreaHasShowMore,
                                },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { "label-width": "0" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "label" },
                                        slot: "label",
                                      },
                                      [_vm._v(" ")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: _vm.btnConfig.searchBtn.type,
                                          size: _vm.btnConfig.size,
                                          icon: _vm.btnConfig.searchBtn.icon,
                                        },
                                        on: { click: _vm.getList },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.btnConfig.searchBtn.text)
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: _vm.btnConfig.resetBtn.type,
                                          size: _vm.btnConfig.size,
                                          icon: _vm.btnConfig.resetBtn.icon,
                                        },
                                        on: { click: _vm.resetForm },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.btnConfig.resetBtn.text)
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            _vm.handleShow = !_vm.handleShow
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.handleShow ? "收起" : "展开"
                                            ) +
                                            "\n                  "
                                        ),
                                        _c("i", {
                                          class: _vm.handleShow
                                            ? "el-icon-arrow-up"
                                            : "el-icon-arrow-down",
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                key: _vm.tableKey,
                staticStyle: { width: "100%" },
                attrs: {
                  height: _vm.tabHeight,
                  data: _vm.tableData,
                  border: _vm.tableConfig.border,
                  stripe: _vm.tableConfig.stripe,
                  fit: "",
                  "highlight-current-row": "",
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    type: "index",
                    align: _vm.tableConfig.align,
                    width: "80",
                    label: "序号",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "调拨日期",
                    prop: "transTime",
                    "min-width": "160",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm._f("formatDate")(row.transTime)) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1387879358
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "物流源单号",
                    prop: "sourceNo",
                    "min-width": "160",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "调入物流单号",
                    prop: "targetNo",
                    "min-width": "160",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "源经销商编码",
                    prop: "sourceDealerCode",
                    "min-width": "160",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "源经销商名称",
                    prop: "sourceDealerName",
                    "min-width": "160",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "源客户代码",
                    prop: "sourceAccountCode",
                    "min-width": "160",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "源客户名称",
                    prop: "sourceAccountName",
                    "min-width": "160",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "调入经销商编码",
                    prop: "targetDealerCode",
                    "min-width": "160",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "调入经销商名称",
                    prop: "targetDealerName",
                    "min-width": "160",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "调入客户代码",
                    prop: "targetAccountCode",
                    "min-width": "160",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "调入客户名称",
                    prop: "targetAccountName",
                    "min-width": "160",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "NC产品名称",
                    prop: "ncProductName",
                    "min-width": "160",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "产品名称",
                    prop: "productName",
                    "min-width": "160",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "规格",
                    prop: "productSpecification",
                    "min-width": "160",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "计划数量",
                    prop: "planQuantity",
                    "min-width": "160",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "实发数量",
                    prop: "actualQuantity",
                    "min-width": "160",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "创建人",
                    prop: "creatorName",
                    width: "140",
                    align: _vm.tableConfig.align,
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _vm.hasAuth("detailbtn", _vm.$route.meta.authList) ||
                _vm.hasAuth("qrcodebtn", _vm.$route.meta.authList) ||
                _vm.hasAuth("printbtn", _vm.$route.meta.authList) ||
                _vm.hasAuth("cxjdbtn", _vm.$route.meta.authList)
                  ? _c("el-table-column", {
                      attrs: {
                        label: "操作",
                        fixed: "right",
                        align: _vm.tableConfig.align,
                        "min-width": "200",
                        "class-name": "small-padding fixed-width",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm.hasAuth(
                                  "detailbtn",
                                  _vm.$route.meta.authList
                                )
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.getflow(row, 10)
                                          },
                                        },
                                      },
                                      [_vm._v("详情")]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1304488506
                      ),
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c("pagination", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.total > 0,
                  expression: "total > 0",
                },
              ],
              attrs: {
                layout: "total,sizes,prev, pager, next, jumper",
                total: _vm.total,
                page: _vm.searchForm.current,
                limit: _vm.searchForm.size,
              },
              on: {
                "update:page": function ($event) {
                  return _vm.$set(_vm.searchForm, "current", $event)
                },
                "update:limit": function ($event) {
                  return _vm.$set(_vm.searchForm, "size", $event)
                },
                pagination: _vm.getList,
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
      ? _c("section", [_vm._m(0)])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n      "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }