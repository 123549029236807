"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _wechatopenplatform = require("@/api/generalSettings/wechatopenplatform");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      wechatOpenPlatForm: {
        disparkAppid: '',
        disparkSecret: '',
        disparkToken: '',
        disparkKey: ''
      },
      wechatOpenPlatRules: {
        disparkAppid: [{
          required: true,
          message: '请输入第三方平台APPID',
          trigger: 'blur'
        }],
        disparkSecret: [{
          required: true,
          message: '请输入消息校验Token',
          trigger: 'blur'
        }],
        disparkToken: [{
          required: true,
          message: '请输入第三方平台APP SECRET',
          trigger: 'blur'
        }],
        disparkKey: [{
          required: true,
          message: '请输入消息加解密Key',
          trigger: 'blur'
        }]
      }
    };
  },
  activated: function activated() {
    this.queryWechat();
  },
  methods: {
    queryWechat: function queryWechat() {
      var _this = this;
      (0, _wechatopenplatform.queryWechat)().then(function (res) {
        // console.log(res);
        if (res.data.data.errCode) {
          _this.$message.error(res.data.data.errMsg);
          return;
        } else {
          if (res.data.data != null) {
            _this.wechatOpenPlatForm = res.data.data;
          } else {
            _this.wechatOpenPlatForm = [];
          }
        }
      });
    },
    submitWechatOpenPlat: function submitWechatOpenPlat(wechat) {
      var _this2 = this;
      this.$refs[wechat].validate(function (valid) {
        if (valid) {
          (0, _wechatopenplatform.editWechat)(_this2.wechatOpenPlatForm).then(function (res) {
            // console.log(res);
            if (res.data.data.errCode) {
              _this2.$message.error(res.data.data.errCode);
              return;
            } else {
              _this2.$message.success('更新成功');
            }
          });
        }
      });
    },
    resetWechatOpenPlatForm: function resetWechatOpenPlatForm(wechat) {
      this.$refs[wechat].resetFields();
    }
  }
};
exports.default = _default;