var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-section" },
    [
      _c(
        "div",
        { attrs: { id: "seach-box" } },
        [
          _c(
            "el-form",
            {
              ref: "searchform",
              staticClass: "search-condition",
              attrs: {
                model: _vm.listQuery,
                "label-width": "100px",
                "label-suffix": ":",
              },
            },
            [
              _c(
                "div",
                { staticClass: "cols" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "物流码", prop: "wlm" } },
                            [
                              _c("el-input", {
                                attrs: { clearable: "", maxlength: "50" },
                                model: {
                                  value: _vm.listQuery.wlm,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.listQuery, "wlm", $$v)
                                  },
                                  expression: "listQuery.wlm",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.handleShow,
                              expression: "handleShow",
                            },
                          ],
                          attrs: { span: 6 },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "warehouseName",
                                label: "经销商名称",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  clearable: "",
                                  value: _vm.listQuery.warehouseName,
                                  maxlength: "40",
                                },
                                on: {
                                  input: function (e) {
                                    return (_vm.listQuery.warehouseName =
                                      _vm.validTe(e))
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "uploadResult",
                                label: "稽查结果",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "filter-item",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    clearable: "",
                                    "value-key": "id",
                                    placeholder: "全部",
                                  },
                                  model: {
                                    value: _vm.listQuery.uploadResult,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.listQuery,
                                        "uploadResult",
                                        $$v
                                      )
                                    },
                                    expression: "listQuery.uploadResult",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "正常", value: 1 },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "疑似窜货", value: 2 },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "疑似假码", value: 3 },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "未激活", value: 4 },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "产品名称", prop: "productName" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  clearable: "",
                                  value: _vm.listQuery.productName,
                                  maxlength: "40",
                                },
                                on: {
                                  input: function (e) {
                                    return (_vm.listQuery.productName =
                                      _vm.validTe(e))
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.handleShow,
                              expression: "handleShow",
                            },
                          ],
                          attrs: { span: 6 },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "startTime", label: "开始日期" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "date",
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  align: "right",
                                },
                                model: {
                                  value: _vm.listQuery.startTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.listQuery, "startTime", $$v)
                                  },
                                  expression: "listQuery.startTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.handleShow,
                              expression: "handleShow",
                            },
                          ],
                          attrs: { span: 6 },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "endTime", label: "结束日期" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "date",
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  align: "right",
                                },
                                model: {
                                  value: _vm.listQuery.endTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.listQuery, "endTime", $$v)
                                  },
                                  expression: "listQuery.endTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          staticClass: "head-btn-group",
                          staticStyle: { width: "260px" },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                size: "mini",
                                icon: "el-icon-search",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.listQuery.pageNo = 1
                                  _vm.getList()
                                },
                              },
                            },
                            [_vm._v("查询")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini", icon: "el-icon-refresh" },
                              on: { click: _vm.reset },
                            },
                            [_vm._v("重置")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  _vm.handleShow = !_vm.handleShow
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.handleShow ? "收起" : "展开") +
                                  "\n              "
                              ),
                              _c("i", {
                                class: _vm.handleShow
                                  ? "el-icon-arrow-up"
                                  : "el-icon-arrow-down",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { id: "table-box" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              key: _vm.tableKey,
              staticStyle: { width: "100%" },
              attrs: {
                height: _vm.tabHeight,
                data: _vm.list,
                "tooltip-effect": "dark",
                border: "",
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  width: "120",
                  label: "序号",
                  align: _vm.tableConfig.align,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "查询数码",
                  align: _vm.tableConfig.align,
                  "show-overflow-tooltip": "",
                  prop: "wlm",
                  "min-width": "120",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "产品名称",
                  prop: "productName",
                  align: _vm.tableConfig.align,
                  "min-width": "120",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "经销商",
                  align: _vm.tableConfig.align,
                  prop: "warehouseName",
                  "min-width": "220",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "稽查省份",
                  align: _vm.tableConfig.align,
                  prop: "queryProvince",
                  "min-width": "220",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "稽查城市",
                  align: _vm.tableConfig.align,
                  prop: "queryCity",
                  "min-width": "220",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "稽查结果",
                  align: _vm.tableConfig.align,
                  prop: "uploadResult",
                  "min-width": "220",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("uploadResult")(scope.row.uploadResult)
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "备注",
                  align: _vm.tableConfig.align,
                  prop: "remark",
                  "min-width": "220",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  fixed: "right",
                  align: _vm.tableConfig.align,
                  width: "135",
                  "class-name": "small-padding fixed-width",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", loading: _vm.btn_loading },
                            on: {
                              click: function ($event) {
                                return _vm.detail(row)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.del(row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total>0",
              },
            ],
            attrs: {
              total: _vm.total,
              page: _vm.listQuery.pageNo,
              limit: _vm.listQuery.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.listQuery, "pageNo", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.listQuery, "pageSize", $event)
              },
              pagination: _vm.getList,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: "稽查详情",
            visible: _vm.dialogFormVisible,
            "before-close": _vm.resetData,
            top: "3%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              staticClass: "mark_form",
              attrs: {
                model: _vm.temp,
                "label-position": "right",
                "label-width": "155px",
                "label-suffix": ":",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "查询数码", prop: "wlm" } },
                [_c("span", [_vm._v(_vm._s(_vm.temp.wlm ? _vm.temp.wlm : ""))])]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "数码层级", prop: "packLevel" } },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm._f("packLevel")(_vm.temp.packLevel))),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "对应防伪码", prop: "antiNum" } },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.temp.antiNum ? _vm.temp.antiNum : "")),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "数码状态", prop: "numStatus" } },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm._f("numStatus")(_vm.temp.numStatus))),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "产品名称", prop: "productName" } },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.temp.productName ? _vm.temp.productName : "")
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "包装比例", prop: "packScaleExpression" } },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.temp.packScaleExpression
                          ? _vm.temp.packScaleExpression
                          : ""
                      )
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "经销商", prop: "warehouseName" } },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.temp.warehouseName ? _vm.temp.warehouseName : ""
                      )
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "稽查省份", prop: "queryProvince" } },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.temp.queryProvince ? _vm.temp.queryProvince : ""
                      )
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "稽查城市", prop: "queryCity" } },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.temp.queryCity ? _vm.temp.queryCity : "")
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "上传时间", prop: "uploadTime" } },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm._f("formatDate")(_vm.temp.uploadTime))),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "稽查结果", prop: "uploadResult" } },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm._f("uploadResult")(_vm.temp.uploadResult))
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "上传图片", prop: "list" } },
                _vm._l(_vm.temp.list, function (item, index) {
                  return _c(
                    "el-image",
                    {
                      key: index,
                      staticStyle: { width: "150px", height: "150px" },
                      attrs: {
                        src: item,
                        fit: "contain",
                        "preview-src-list": _vm.getSrcList(index),
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "image-slot",
                          attrs: { slot: "placeholder" },
                          slot: "placeholder",
                        },
                        [
                          _vm._v("\n            加载中\n            "),
                          _c("span", { staticClass: "dot" }, [_vm._v("...")]),
                        ]
                      ),
                    ]
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "备注", prop: "remark" } }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.temp.remark ? _vm.temp.remark : "")),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "上传人", prop: "markType" } },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.temp.creator ? _vm.temp.creator : "")),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.resetData } }, [
                _vm._v("关闭"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }