"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _stockscan = require("@/api/chanelmange/stockscan");
var _rebate = require("@/api/chanelmange/rebate");
var _channelSelectDialog = _interopRequireDefault(require("@/components/channelSelectDialog"));
var _detailFlow = _interopRequireDefault(require("./component/detail-flow2"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'chanelmangerebatemangejqhs';
var _default = {
  name: 'chanelmangerebatemangejqhs',
  components: {
    Pagination: _Pagination.default,
    Treeselect: _vueTreeselect.default,
    ChannelSelectDialog: _channelSelectDialog.default,
    detailsflow: _detailFlow.default
  },
  data: function data() {
    return {
      listQuery: {
        channelArchiveName: '',
        channelArchiveType: '2',
        checkerName: '',
        endTime: '',
        no: '',
        orgId: null,
        pageNo: 1,
        pageSize: 20,
        startTime: '',
        status: ''
      },
      tableKey: 'hdflzh',
      tabHeight: '100%',
      list: [],
      listLoading: false,
      total: 0,
      status: [{
        name: '待审',
        id: '0'
      }, {
        name: '审核通过',
        id: '1'
      }, {
        name: '审核拒绝',
        id: '2'
      }, {
        name: '删除/撤回',
        id: '3'
      }],
      orgOptions: [],
      orgOrigin: '',
      showCol: false,
      unitShow: false,
      expandTxt: '展开',
      apis: []
    };
  },
  activated: function activated() {
    var that = this;
    var hasCache = that.list.length > 0 ? true : false;
    var authList = that.$route.meta.authList;
    that.apis = authList;
    var auth = that.hasAuth('searchbtn', authList);
    var setHeaderId = that.authId('searchbtn', that.apis);
    if (!hasCache) {
      if (auth) {
        that.getOrg();
        that.getList();
      }
    }
  },
  //缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = that.getCaches(that, name);
    if (!cache) {
      that.list = [];
      that.total = 0;
    }
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  filters: {
    formatTime: function formatTime(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        return (0, _utils.parseTime)(value, '{y}-{m}-{d}');
      }
    }
  },
  methods: {
    getList: function getList() {
      var that = this;
      var setHeaderId = that.authId('searchbtn', that.apis);
      that.listLoading = true;
      (0, _rebate.getVerifyApplyList)(that.listQuery).then(function (res) {
        if (res.data.code == 200) {
          that.list = res.data.data.records;
          that.total = parseInt(res.data.data.total);
          setTimeout(function () {
            that.listLoading = false;
          }, 1 * 800);
        } else {
          that.$message.error(res.data.msg);
          that.listLoading = false;
          return;
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    getOrg: function getOrg() {
      var that = this;
      (0, _stockscan.getQueryOrg)().then(function (res) {
        if (res.data.code == 200) {
          var rData = res.data.data;
          that.orgOrigin = rData;
          var arr = [];
          if (rData.length >= 0) {
            rData.forEach(function (item) {
              var obj = {};
              obj.id = item.pid;
              obj.label = item.orgName;
              obj.pid = item.pid;
              obj.parentPid = item.parentPid;
              arr.push(obj);
            });
            that.orgOptions = (0, _utils.transTreeData)(arr, 'pid', 'parentPid', 'children');
          }
        }
      });
    },
    clearJxs: function clearJxs() {
      var that = this;
      that.listQuery.channelArchiveName = '';
    },
    clearGhs: function clearGhs() {
      var that = this;
      that.listQuery.checkerName = '';
    },
    DoSearch: function DoSearch() {
      var that = this;
      that.listQuery = (0, _utils.filterKeyNull)(that.listQuery);
      that.listQuery.pageNo = 1;
      that.getList();
      if (that.listQuery.orgId == '') {
        that.listQuery.orgId = null;
      }
    },
    DoReset: function DoReset(formName) {
      var that = this;
      that.listQuery = {
        channelArchiveName: '',
        channelArchiveType: '2',
        checkerName: '',
        endTime: '',
        no: '',
        orgId: null,
        pageNo: 1,
        pageSize: 20,
        startTime: '',
        status: ''
      };
      that.getList();
    },
    //获取返利对象
    getUnit: function getUnit(val) {
      var that = this;
      var setHeaderId = that.authId('searchbtn', that.apis);
      that.unitType = val;
      this.$refs.channelSelect.proSelVisible = true;
    },
    hideUnit: function hideUnit() {
      var that = this;
      that.unitShow = false;
    },
    getUalue: function getUalue(val) {
      var that = this;
      if (that.unitType == 'qd') {
        that.listQuery.channelArchiveName = val.channelName;
      }
      if (that.unitType == 'gh') {
        that.listQuery.checkerName = val.channelName;
      }
      that.unitShow = false;
    },
    //查看详情
    getDetail: function getDetail(rows) {
      var that = this;
      var setHeaderId = that.authId('detailbtn', that.apis);
      that.$refs.detailflow.shows(rows, 'scan');
      that.$refs.detailflow.detailVisible = true;
    },
    //展开收起搜索条件
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    }
  }
};
exports.default = _default;