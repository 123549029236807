"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _typeof2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.number.constructor");
var _client_licensing = require("@/api/mark/client/client_licensing");
var _waves = _interopRequireDefault(require("@/directive/waves"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var temp = {
  flagCode: '',
  imgAddress: '',
  isEnable: 1,
  isUpSame: 1,
  numPair: 0,
  numberType: 0,
  packLevelCode: 3,
  pid: 0,
  tempateName: '',
  urlCode: ''
};
var selectOptions = {
  supplierType: [{
    id: 1,
    name: '标签厂'
  }, {
    id: 2,
    name: '制盖厂'
  }
  // { id: 3, name: '膜厂' },
  // { id: 4, name: '仓储商' }
  ]
};
var _default = {
  name: 'ComplexTable',
  directives: {
    waves: _waves.default
  },
  filters: {
    getSupplierType: function getSupplierType(v) {
      var t = '';
      if (/\d/.test(Number(v))) {
        for (var i in selectOptions.supplierType) {
          if (selectOptions.supplierType[i].id == Number(v)) {
            t = selectOptions.supplierType[i].name;
            break;
          }
        }
      } else {
        t = v;
      }
      return t;
    }
  },
  props: ['dialogTitle', 'parentData', 'dialogBindMacFormVisible', 'btnType'],
  data: function data() {
    return {
      dynamicValidateForm: {
        domains: [{
          ip: '',
          ip_key: 1,
          mac: '',
          mac_key: 1
        }]
      },
      listLoading: false,
      temp: temp,
      btnAdd: true,
      btn_loading: false,
      // parentData: {
      //   supplierName: '',
      //   supplierType: '',
      //   supplierCode: '',
      //   btn_type: '',
      //   pid: ''
      // },
      ruleForm: {
        macAddress: [{
          value: ''
        }],
        ipAddress: [{
          value: ''
        }]
      },
      // dialogBindMacFormVisible: false,
      fromData: {
        supplierCode: '',
        auditReasons: '',
        detailList: [{
          ipAddress: '',
          macAddress: ''
        }]
      },
      dialogStatus: '',
      rules: {
        urlDesc: [{
          required: true,
          message: '输入url描述内容',
          trigger: 'blur'
        }],
        urlAddress: [{
          type: 'url',
          required: true,
          message: '请输入正确的Url地址',
          trigger: 'blur'
        }]
      }
    };
  },
  watch: {
    /* 'temp.pid'(newVal, oldVal){
      if(Number(newVal)==0){
        this.Reset();
      }else{
        this.Details();
      }
    },*/
    'parentData.supplierCode': function parentDataSupplierCode(newVal, oldVal) {
      // this.getsupplierCode(newVal)
    }
  },
  mounted: function mounted() {
    console.log(this.parentData);
    this.getsupplierCode();
  },
  methods: {
    removeDomain: function removeDomain(item) {
      var index = this.dynamicValidateForm.domains.indexOf(item);
      if (index !== -1) {
        this.dynamicValidateForm.domains.splice(index, 1);
      }
    },
    addDomain: function addDomain() {
      this.dynamicValidateForm.domains.push({
        ip: '',
        ip_key: 'ip_' + Date.now(),
        mac: '',
        mac_key: 'mac_' + Date.now()
      });
    },
    Rest: function Rest() {
      this.$refs.dataForm.resetFields();
      this.dynamicValidateForm.domains = [{
        ip: '',
        ip_key: 1,
        mac: '',
        mac_key: 1
      }];
      // this.dialogBindMacFormVisible = false
    },
    getsupplierCode: function getsupplierCode() {
      var _this = this;
      // 指定id查询MAC绑定信息
      this.listLoading = true;
      for (var i in selectOptions.supplierType) {
        if (selectOptions.supplierType[i].id == this.parentData.supplierType) {
          this.parentData.supplierType = selectOptions.supplierType[i].name;
          break;
        }
      }
      (0, _client_licensing.detail)({
        supplierCode: this.parentData.supplierCode
      }).then(function (response) {
        var data = response.data;
        if (data.code != 200) {
          _this.$message.error(data.msg);
          return true;
        }
        data = data.data;
        _this.listLoading = false;
        if (!data || data.length == 0) {
          return true;
        }
        _this.btnAdd = false;
        _this.dynamicValidateForm.domains = [];
        // for (var i in data) {
        _this.fromData.auditReasons = data.auditReasons;
        // var macAddress = []
        // if (data.macAddress != '' && data.macAddress != null) {
        //   macAddress = data.macAddress.split(',')
        // }
        // var ipAddress = []
        // if (data.ipAddress != '' && data.ipAddress != null) {
        //   ipAddress = data.ipAddress.split(',')
        // }
        // console.log(data.ipAddress, 'data.ipAddress')
        // console.log(ipAddress, 'ipAddress')
        // for (var j in macAddress) {
        //   this.dynamicValidateForm.domains.push({
        //     ip: ipAddress[j],
        //     ip_key: 'ip_' + data.pid + '_' + j,
        //     mac: macAddress[j],
        //     mac_key: 'mac_' + data.pid + '_' + j,
        //     pid: data.pid
        //   })
        // }
        _this.dynamicValidateForm.domains = data.detailList.map(function (item, index) {
          return {
            ip: item.ipAddress,
            ip_key: 'ip_' + data.pid + '_' + index,
            mac: item.macAddress,
            mac_key: 'mac_' + data.pid + '_' + index,
            pid: data.pid
          };
        });
        console.log(_this.dynamicValidateForm);
        // }
      });
    },
    createData: function createData() {
      var _this2 = this;
      // 调取- 创建数据保存
      this.fromData.detailList = [];
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          for (var i in _this2.dynamicValidateForm.domains) {
            // if (this.dynamicValidateForm.domains[i].ip) {
            //   this.fromData.detailList.ipAddress.push(
            //     this.dynamicValidateForm.domains[i].ip
            //   )
            // }
            // this.fromData.detailList.macAddress.push(
            //   this.dynamicValidateForm.domains[i].mac
            // )
            _this2.fromData.detailList.push({
              ipAddress: _this2.dynamicValidateForm.domains[i].ip || null,
              macAddress: _this2.dynamicValidateForm.domains[i].mac || null
            });
          }
          _this2.fromData.supplierCode = _this2.parentData.supplierCode;
          _this2.fromData.pId = undefined;
          console.log(_this2.fromData);
          _this2.btn_loading = true;
          (0, _client_licensing.bindingMac)(_this2.fromData).then(function (res) {
            _this2.btn_loading = false;
            var data = res.data;
            if (data.code != 200) {
              _this2.$message.error(data.msg);
              return false;
            }
            _this2.$parent.getList();
            _this2.temp = temp;
            // this.dialogBindMacFormVisible = false
            _this2.handleClose();
            _this2.parentData.supplierCode = '';
            _this2.$message({
              message: '保存成功',
              type: 'success'
            });
          }).catch(function () {
            _this2.btn_loading = false;
          });
        }
      });
    },
    handleUpdate: function handleUpdate(row, data_type) {
      var _this3 = this;
      // 添加，修改，调取事件
      this.temp = Object.assign({}, temp);
      this.$refs.dataForm.resetFields();
      if (row) {
        this.temp = Object.assign({}, row); // copy obj
      }

      this.temp.flagCode = this.listQuery.flagCode;
      this.dialogStatus = data_type;
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this3.$refs['dataForm'].clearValidate();
      });
    },
    updateData: function updateData() {
      var _this4 = this;
      // 调取- 创建数据修改
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _this4.fromData.detailList = [];
          for (var i in _this4.dynamicValidateForm.domains) {
            // if (this.dynamicValidateForm.domains[i].ip) {
            //   this.fromData.detailList.ipAddress.push(
            //     this.dynamicValidateForm.domains[i].ip
            //   )
            // }
            // this.fromData.detailList.macAddress.push(
            //   this.dynamicValidateForm.domains[i].mac
            // )
            // if(this.dynamicValidateForm.domains[i].pid!=null){
            // this.fromData.ids.push(this.dynamicValidateForm.domains[i].pid);
            // }
            _this4.fromData.detailList.push({
              ipAddress: _this4.dynamicValidateForm.domains[i].ip || null,
              macAddress: _this4.dynamicValidateForm.domains[i].mac || null
            });
          }
          _this4.fromData.supplierCode = _this4.parentData.supplierCode;
          _this4.fromData.pid = _this4.parentData.clientAuthId;
          console.log(_this4.dynamicValidateForm.domains);
          console.log(_this4.fromData);
          _this4.btn_loading = true;
          (0, _client_licensing.resetMac)(_this4.fromData).then(function (res) {
            _this4.btn_loading = false;
            var data = res.data;
            if (data.code != 200) {
              _this4.$message.error(res.data.msg);
              return false;
            }
            _this4.$parent.getList();
            _this4.temp = temp;
            _this4.handleClose();
            _this4.parentData.supplierCode = '';
            _this4.$message({
              message: '保存成功',
              type: 'success'
            });
          }).catch(function () {
            _this4.btn_loading = false;
          });
        }
      });
    },
    getKeysetVal: function getKeysetVal(old_v, new_v) {
      for (var i in old_v) {
        if ((0, _typeof2.default)(old_v[i]) === 'object') {
          for (var n in old_v[i]) {
            old_v[i][n] = new_v[i][n];
          }
        } else {
          old_v[i] = new_v[i];
        }
      }
      return old_v;
    },
    handleClose: function handleClose(done) {
      this.Rest();
      this.authId('searchbtn', this.$route.meta.authList);
      this.$emit('close');
    }
  }
};
exports.default = _default;