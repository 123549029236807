var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
      ? _c(
          "div",
          { staticClass: "page-section" },
          [
            _c("search", {
              ref: "searchMain",
              attrs: { "show-col": _vm.showCol },
            }),
            _vm._v(" "),
            _vm.formConfig.btnFollow
              ? _c("el-divider", { staticClass: "btnDivider" })
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "btnArea" }, [
              _vm.hasAuth("exportbtn", _vm.$route.meta.authList)
                ? _c(
                    "div",
                    { staticClass: "head-btn-group" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: _vm.btnConfig.downLoadBtn.type,
                            size: _vm.btnConfig.size,
                            icon: _vm.btnConfig.downLoadBtn.icon,
                            loading: _vm.downLoading,
                          },
                          on: { click: _vm.download },
                        },
                        [_vm._v(_vm._s(_vm.btnConfig.downLoadBtn.text))]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                key: _vm.tableKey,
                ref: "table",
                staticStyle: { width: "100%" },
                attrs: {
                  height: _vm.tabHeight,
                  data: _vm.tableData,
                  border: _vm.tableConfig.border,
                  stripe: _vm.tableConfig.stripe,
                  fit: "",
                  "highlight-current-row": "",
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    type: "index",
                    align: _vm.tableConfig.align,
                    width: "80",
                    label: "序号",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "单号",
                    align: _vm.tableConfig.align,
                    "min-width": "160",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [_c("div", [_vm._v(_vm._s(row.billNo))])]
                        },
                      },
                    ],
                    null,
                    false,
                    527438041
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "单据类型",
                    align: _vm.tableConfig.align,
                    "min-width": "160",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm._f("getbillType")(row.billType))
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3463450556
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "所属企业",
                    align: _vm.tableConfig.align,
                    "min-width": "200",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [_c("div", [_vm._v(_vm._s(row.orgName))])]
                        },
                      },
                    ],
                    null,
                    false,
                    4244543310
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "库房名称",
                    prop: "storeageName",
                    align: _vm.tableConfig.align,
                    "min-width": "200",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "品牌",
                    align: _vm.tableConfig.align,
                    "min-width": "160",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [_c("div", [_vm._v(_vm._s(row.bandName))])]
                        },
                      },
                    ],
                    null,
                    false,
                    3408801789
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "产品名称",
                    align: _vm.tableConfig.align,
                    "min-width": "160",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [_c("div", [_vm._v(_vm._s(row.productName))])]
                        },
                      },
                    ],
                    null,
                    false,
                    4122853759
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "包装比例",
                    align: _vm.tableConfig.align,
                    "min-width": "160",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [_c("div", [_vm._v(_vm._s(row.packRadio))])]
                        },
                      },
                    ],
                    null,
                    false,
                    2228611707
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "实际最大单位数量",
                    "min-width": "160",
                    align: _vm.tableConfig.align,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("div", [_vm._v(_vm._s(row.realChestCount))]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2681862563
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "实际最小单位数量",
                    "min-width": "160",
                    align: _vm.tableConfig.align,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("div", [_vm._v(_vm._s(row.realBottleCount))]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2121376078
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "创建时间",
                    prop: "dateCreate",
                    align: _vm.tableConfig.align,
                    "min-width": "200",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [_c("div", [_vm._v(_vm._s(row.billDate))])]
                        },
                      },
                    ],
                    null,
                    false,
                    1817227116
                  ),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("pagination", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.total > 0,
                  expression: "total > 0",
                },
              ],
              attrs: {
                layout: "total,sizes,prev, pager, next, jumper",
                total: _vm.total,
                page: _vm.searchForm.current,
                limit: _vm.searchForm.size,
              },
              on: {
                "update:page": function ($event) {
                  return _vm.$set(_vm.searchForm, "current", $event)
                },
                "update:limit": function ($event) {
                  return _vm.$set(_vm.searchForm, "size", $event)
                },
                pagination: _vm.getList,
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
      ? _c("section", [_vm._m(0)])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }