var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
      ? _c(
          "div",
          { staticClass: "page-section" },
          [
            _c(
              "el-form",
              {
                ref: "searchform",
                staticClass: "search-condition",
                attrs: {
                  model: _vm.temp,
                  "label-suffix": ":",
                  "label-position": _vm.formConfig.labelPosition,
                  "label-width": _vm.formConfig.labelWidth,
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "cols" },
                  [
                    _c(
                      "el-row",
                      {
                        staticStyle: { "margin-bottom": "0" },
                        attrs: { gutter: 20 },
                      },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "markOrderNo",
                                  label: "制码订单号",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    clearable: "",
                                    value: _vm.listQuery.markOrderNo,
                                    placeholder: "制码订单号",
                                    maxlength: "20",
                                  },
                                  on: {
                                    input: function (e) {
                                      return (_vm.listQuery.markOrderNo =
                                        _vm.validSe(e))
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "supplierName",
                                  label: "供应商名称",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    clearable: "",
                                    maxlength: "25",
                                    placeholder: "供应商名称",
                                  },
                                  model: {
                                    value: _vm.listQuery.supplierName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.listQuery,
                                        "supplierName",
                                        $$v
                                      )
                                    },
                                    expression: "listQuery.supplierName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "supplierType",
                                  label: "供应商类型",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "filter-item",
                                    attrs: {
                                      clearable: "",
                                      "value-key": "id",
                                      placeholder: "选择供应商类型",
                                    },
                                    model: {
                                      value: _vm.listQuery.supplierType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.listQuery,
                                          "supplierType",
                                          $$v
                                        )
                                      },
                                      expression: "listQuery.supplierType",
                                    },
                                  },
                                  _vm._l(
                                    _vm.selectOptions.supplierType,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.handleShow,
                                expression: "handleShow",
                              },
                            ],
                            attrs: { span: 6 },
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "applyStyle",
                                  label: "申请方式",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "filter-item",
                                    attrs: {
                                      clearable: "",
                                      "value-key": "id",
                                      placeholder: "选择申请方式",
                                    },
                                    model: {
                                      value: _vm.listQuery.applyStyle,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.listQuery,
                                          "applyStyle",
                                          $$v
                                        )
                                      },
                                      expression: "listQuery.applyStyle",
                                    },
                                  },
                                  _vm._l(
                                    _vm.selectOptions.applyType,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.handleShow,
                                expression: "handleShow",
                              },
                            ],
                            attrs: { span: 6 },
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { prop: "beginDate", label: "开始日期" },
                              },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    type: "date",
                                    format: "yyyy-MM-dd",
                                    "value-format": "yyyy-MM-dd",
                                  },
                                  model: {
                                    value: _vm.listQuery.beginDate,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.listQuery, "beginDate", $$v)
                                    },
                                    expression: "listQuery.beginDate",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.handleShow,
                                expression: "handleShow",
                              },
                            ],
                            attrs: { span: 6 },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "endDate", label: "结束日期" } },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    type: "date",
                                    format: "yyyy-MM-dd",
                                    "value-format": "yyyy-MM-dd",
                                  },
                                  model: {
                                    value: _vm.temp.endDate,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.temp, "endDate", $$v)
                                    },
                                    expression: "temp.endDate",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.formConfig.btnFollow
                          ? _c(
                              "el-col",
                              {
                                staticClass: "head-btn-group",
                                style: {
                                  width: _vm.formConfig.btnAreaHasShowMore,
                                },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { "label-width": "0" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "label" },
                                        slot: "label",
                                      },
                                      [_vm._v(" ")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: _vm.btnConfig.searchBtn.type,
                                          size: _vm.btnConfig.size,
                                          icon: _vm.btnConfig.searchBtn.icon,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleFilter("search")
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.btnConfig.searchBtn.text)
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: _vm.btnConfig.resetBtn.type,
                                          size: _vm.btnConfig.size,
                                          icon: _vm.btnConfig.resetBtn.icon,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleFilter("rest")
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.btnConfig.resetBtn.text)
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            _vm.handleShow = !_vm.handleShow
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.handleShow ? "收起" : "展开"
                                            ) +
                                            "\n                "
                                        ),
                                        _c("i", {
                                          class: _vm.handleShow
                                            ? "el-icon-arrow-up"
                                            : "el-icon-arrow-down",
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _vm.formConfig.btnFollow
              ? _c("el-divider", { staticClass: "btnDivider" })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "btnArea" },
              [
                _c("el-col", {
                  staticClass: "head-btn-group",
                  staticStyle: { width: "230px" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { attrs: { id: "table-box" } },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.listLoading,
                        expression: "listLoading",
                      },
                    ],
                    key: _vm.tableKey,
                    staticStyle: { width: "100%" },
                    attrs: {
                      height: _vm.tabHeight,
                      data: _vm.list,
                      border: _vm.tableConfig.border,
                      stripe: _vm.tableConfig.stripe,
                      fit: "",
                      "highlight-current-row": "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "index",
                        width: "100",
                        label: "序号",
                        align: _vm.tableConfig.align,
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "制码订单号",
                        align: _vm.tableConfig.align,
                        "min-width": "180",
                        prop: "markOrderNo",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "供应商类型",
                        align: _vm.tableConfig.align,
                        "min-width": "100",
                        prop: "supplierType",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("supplierType")(
                                        scope.row.supplierType
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2209439551
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "供应商名称",
                        align: _vm.tableConfig.align,
                        "min-width": "150",
                        prop: "supplierName",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "开始号段",
                        align: _vm.tableConfig.align,
                        "min-width": "190",
                        prop: "startSerno",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "截至号段",
                        align: _vm.tableConfig.align,
                        "min-width": "150",
                        prop: "endSerno",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "申请方式",
                        align: _vm.tableConfig.align,
                        "min-width": "100",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.applyStyle == 1
                                  ? _c("span", [_vm._v("套标申请")])
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.applyStyle == 2
                                  ? _c("span", [_vm._v("后关联申请")])
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.applyStyle == 3
                                  ? _c("span", [_vm._v("制盖申请")])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2790428410
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "来源",
                        width: "120",
                        align: _vm.tableConfig.align,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.applySource == 2
                                  ? _c("span", [_vm._v("系统自动生成")])
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.applySource == 1
                                  ? _c("span", [_vm._v("平台申请")])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1576744029
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "数码类型",
                        align: _vm.tableConfig.align,
                        "min-width": "110",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v(_vm._s(scope.row.markType)),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2596819942
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "数量(万)",
                        align: _vm.tableConfig.align,
                        "min-width": "80",
                        prop: "markNum",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "下载次数",
                        align: _vm.tableConfig.align,
                        "min-width": "80",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.downTimes > 0
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.getDetails(
                                              scope.row,
                                              "setDownInfo"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(scope.row.downTimes))]
                                    )
                                  : _c("span", [
                                      _vm._v(_vm._s(scope.row.downTimes)),
                                    ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2201414266
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "码包密码",
                        align: _vm.tableConfig.align,
                        fixed: "right",
                        width: "220",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c(
                                  "el-input",
                                  {
                                    staticClass: "input-with-select",
                                    attrs: {
                                      placeholder: "请输入内容",
                                      "show-password": row.password,
                                    },
                                    model: {
                                      value: row.packPwd,
                                      callback: function ($$v) {
                                        _vm.$set(row, "packPwd", $$v)
                                      },
                                      expression: "row.packPwd",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "clipboard",
                                            rawName: "v-clipboard:copy",
                                            value: row.packPwd,
                                            expression: "row.packPwd",
                                            arg: "copy",
                                          },
                                          {
                                            name: "clipboard",
                                            rawName: "v-clipboard:success",
                                            value: _vm.onCopy,
                                            expression: "onCopy",
                                            arg: "success",
                                          },
                                          {
                                            name: "clipboard",
                                            rawName: "v-clipboard:error",
                                            value: _vm.onError,
                                            expression: "onError",
                                            arg: "error",
                                          },
                                        ],
                                        attrs: { slot: "append" },
                                        slot: "append",
                                      },
                                      [
                                        _vm._v(
                                          "\n                复制\n              "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2325582111
                      ),
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("pagination", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.total > 0,
                      expression: "total > 0",
                    },
                  ],
                  attrs: {
                    total: _vm.total,
                    page: _vm.listQuery.current,
                    limit: _vm.listQuery.size,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.listQuery, "current", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.listQuery, "size", $event)
                    },
                    pagination: _vm.getList,
                  },
                }),
                _vm._v(" "),
                _c(
                  "el-dialog",
                  {
                    attrs: {
                      "close-on-click-modal": false,
                      "append-to-body": true,
                      title: _vm.textMap[_vm.dialogStatus],
                      visible: _vm.dialogFormVisible,
                      top: "3%",
                      width: "70%",
                    },
                    on: {
                      "update:visible": function ($event) {
                        _vm.dialogFormVisible = $event
                      },
                    },
                  },
                  [
                    _c(
                      "el-form",
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.downlistLoading,
                                expression: "downlistLoading",
                              },
                            ],
                            key: _vm.tableKey,
                            staticStyle: { width: "100%" },
                            attrs: {
                              height: "400",
                              data: _vm.downlist,
                              border: _vm.tableConfig.border,
                              stripe: _vm.tableConfig.stripe,
                              fit: "",
                              "highlight-current-row": "",
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                type: "index",
                                width: "120",
                                label: "序号",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "下载时间",
                                align: _vm.tableConfig.align,
                                width: "165",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              scope.row.downTime
                                                ? scope.row.downTime.split(
                                                    "."
                                                  )[0]
                                                : ""
                                            )
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                909112102
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "IP地址",
                                align: _vm.tableConfig.align,
                                prop: "ipAddress",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "解压密码",
                                align: _vm.tableConfig.align,
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        !row.show
                                          ? _c("svg-icon", {
                                              attrs: {
                                                "icon-class": "eye-close",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  row.show = true
                                                },
                                              },
                                            })
                                          : _c("span", [
                                              _vm._v(_vm._s(row.packSecurit)),
                                            ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2977621355
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "操作人",
                                align: _vm.tableConfig.align,
                                prop: "downAccount",
                                "show-overflow-tooltip": "",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "dialog-footer",
                        attrs: { slot: "footer" },
                        slot: "footer",
                      },
                      [
                        _c(
                          "el-button",
                          {
                            on: {
                              click: function ($event) {
                                _vm.dialogFormVisible = false
                              },
                            },
                          },
                          [_vm._v("关闭")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
      ? _c("section", [_vm._m(0)])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }