var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c("h3", { staticClass: "h-title" }, [_vm._v("产品分类")]),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  ref: "searchForm",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.searchForm,
                    "status-icon": "",
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cols" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6, prop: "productClassName" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { "margin-bottom": "0" },
                                  attrs: {
                                    prop: "productClassName",
                                    "label-width": "80px",
                                    label: "产品分类名称:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      maxlength: "25",
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.searchForm.productClassName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "productClassName",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.productClassName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6, prop: "classStatus" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { "margin-bottom": "0" },
                                  attrs: {
                                    prop: "classStatus",
                                    "label-width": "50px",
                                    label: "状态:",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.searchForm.classStatus,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "classStatus",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.classStatus",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "全部", value: "" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { label: "启用", value: 1 },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { label: "禁用", value: 0 },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.formConfig.btnFollow
                            ? _c(
                                "el-col",
                                {
                                  staticClass: "head-btn-group",
                                  style: { width: _vm.formConfig.btnArea },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { "label-width": "0" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v(" ")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.searchBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.searchBtn.icon,
                                            plain:
                                              _vm.btnConfig.searchBtn.plain,
                                          },
                                          on: { click: _vm.getList },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.searchBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.resetBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.resetBtn.icon,
                                            plain: _vm.btnConfig.resetBtn.plain,
                                          },
                                          on: { click: _vm.resetForm },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.resetBtn.text)
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.formConfig.btnFollow
                ? _c("el-divider", { staticClass: "btnDivider" })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btnArea" },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "head-btn-group",
                      style: { width: _vm.formConfig.btnArea },
                    },
                    [
                      _vm.hasAuth("insertbtn", _vm.$route.meta.authList)
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.addBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.addBtn.icon,
                                plain: _vm.btnConfig.plain,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.add("parent")
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.addBtn.text))]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "section",
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      key: _vm.tableKey,
                      staticStyle: { width: "100%" },
                      attrs: {
                        id: "procatTable",
                        data: _vm.tableData,
                        height: _vm.tabHeight,
                        "row-key": "pId",
                        border: _vm.tableConfig.border,
                        stripe: _vm.tableConfig.stripe,
                        "tree-props": {
                          children: "children",
                          hasChildren: "hasChildren",
                        },
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "产品分类编码",
                          prop: "productClassCode",
                          align: _vm.tableConfig.align,
                          "min-width": "200",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "产品分类名称",
                          prop: "productClassName",
                          "min-width": "200",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "已关联产品数量",
                          prop: "isProduct",
                          "min-width": "200",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "状态",
                          prop: "classStatus",
                          "min-width": "80",
                          align: _vm.tableConfig.align,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  row.classStatus == 1
                                    ? _c(
                                        "el-tag",
                                        { attrs: { type: "success" } },
                                        [_vm._v("启用")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.classStatus == 0
                                    ? _c(
                                        "el-tag",
                                        { attrs: { type: "danger" } },
                                        [_vm._v("禁用")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.classStatus == 2
                                    ? _c(
                                        "el-tag",
                                        { attrs: { type: "info" } },
                                        [_vm._v("删除")]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          201757613
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "创建人",
                          prop: "creatorName",
                          "min-width": "120",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "创建时间",
                          width: "170",
                          align: _vm.tableConfig.align,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm._v(
                                    _vm._s(_vm._f("textDate")(row.createTime))
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          953579966
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "更新人",
                          prop: "updaterName",
                          "min-width": "120",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "更新时间",
                          "min-width": "170",
                          align: _vm.tableConfig.align,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm._v(
                                    _vm._s(_vm._f("textDate")(row.updateTime))
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          739224459
                        ),
                      }),
                      _vm._v(" "),
                      _vm.hasAuth("insertbtn", _vm.$route.meta.authList) ||
                      _vm.hasAuth("updatebtn", _vm.$route.meta.authList) ||
                      _vm.hasAuth("startbtn", _vm.$route.meta.authList) ||
                      _vm.hasAuth("stopbtn", _vm.$route.meta.authList)
                        ? _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              align: _vm.tableConfig.align,
                              "min-width": "200",
                              fixed: "right",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _vm.hasAuth(
                                        "insertbtn",
                                        _vm.$route.meta.authList
                                      )
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.add(row, "child")
                                                },
                                              },
                                            },
                                            [_vm._v("添加")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.hasAuth(
                                        "updatebtn",
                                        _vm.$route.meta.authList
                                      )
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.edit(row)
                                                },
                                              },
                                            },
                                            [_vm._v("编辑")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.hasAuth(
                                        "stopbtn",
                                        _vm.$route.meta.authList
                                      ) && row.classStatus == 1
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "text",
                                                disabled:
                                                  row.isProduct != 0 ||
                                                  row.hasChild != 0,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.setUnUse(row.pId)
                                                },
                                              },
                                            },
                                            [_vm._v("禁用")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.hasAuth(
                                        "startbtn",
                                        _vm.$route.meta.authList
                                      ) && row.classStatus == 0
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.setInUse(row.pId)
                                                },
                                              },
                                            },
                                            [_vm._v("启用")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.hasAuth(
                                        "deletebtn",
                                        _vm.$route.meta.authList
                                      )
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "text",
                                                disabled:
                                                  row.isProduct != 0 ||
                                                  row.hasChild != 0,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.DoDel(row)
                                                },
                                              },
                                            },
                                            [_vm._v("删除")]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              879727376
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": true,
            title: _vm.title,
            visible: _vm.dialogVisible,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.mloading,
                  expression: "mloading",
                },
              ],
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "120px",
                "label-suffix": ":",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "产品分类编码", prop: "productClassCode" } },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "20",
                      disabled: _vm.title == "编辑产品分类",
                    },
                    on: {
                      input: function (e) {
                        return (_vm.ruleForm.productClassCode =
                          _vm.validCode(e))
                      },
                    },
                    model: {
                      value: _vm.ruleForm.productClassCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "productClassCode", $$v)
                      },
                      expression: "ruleForm.productClassCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "产品分类名称", prop: "productClassName" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "15", disabled: _vm.disabled },
                    model: {
                      value: _vm.ruleForm.productClassName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "productClassName", $$v)
                      },
                      expression: "ruleForm.productClassName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "上级分类", prop: "parentId" } },
                [
                  _c("el-cascader", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      options: _vm.tableData,
                      props: {
                        value: "pId",
                        label: "productClassName",
                        children: "children",
                        checkStrictly: true,
                      },
                      "show-all-levels": false,
                      disabled: _vm.parentIdDisabled,
                    },
                    model: {
                      value: _vm.ruleForm.parentId,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "parentId", $$v)
                      },
                      expression: "ruleForm.parentId",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "状态", prop: "classStatus" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ff4949",
                      "active-value": 1,
                      "inactive-value": 0,
                      "active-text": "启用",
                      "inactive-text": "禁用",
                      disabled: _vm.disabled,
                    },
                    model: {
                      value: _vm.ruleForm.classStatus,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "classStatus", $$v)
                      },
                      expression: "ruleForm.classStatus",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remarks" } },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.disabled,
                      type: "textarea",
                      maxlength: "500",
                      "show-word-limit": "",
                      rows: 3,
                    },
                    model: {
                      value: _vm.ruleForm.remarks,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "remarks", $$v)
                      },
                      expression: "ruleForm.remarks",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              !_vm.disabled
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.btnLoading },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm()
                        },
                      },
                    },
                    [_vm._v("保存")]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.disabled && _vm.isAdd
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.btnLoading },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm(111)
                        },
                      },
                    },
                    [_vm._v("保存并新增")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v(_vm._s(_vm.disabled ? "关 闭" : "取 消")),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }