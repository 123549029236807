"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addEnterpriseCustom = addEnterpriseCustom;
exports.addIntegralAct = addIntegralAct;
exports.deleteIntegralAct = deleteIntegralAct;
exports.gererateQRCode = gererateQRCode;
exports.getEnterpriseCustom = getEnterpriseCustom;
exports.getIntegralAct = getIntegralAct;
exports.getJFChosenProduct = getJFChosenProduct;
exports.integralActDetails = integralActDetails;
exports.integralActOpenOrOlose = integralActOpenOrOlose;
exports.selectPayById = selectPayById;
exports.updaeEnterpriseCustom = updaeEnterpriseCustom;
exports.updateIntegralAct = updateIntegralAct;
exports.updatePayById = updatePayById;
var _request = _interopRequireDefault(require("@/utils/request"));
// var baseUrl = '/api'
var baseUrl = '';
// 创建积分
function addIntegralAct(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/integralAct/addIntegralAct',
    headers: {
      'companyId': localStorage.getItem('cid')
    },
    method: 'POST',
    data: params
  });
}
// 删除
function deleteIntegralAct(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/integralAct/deleteIntegralAct',
    headers: {
      'companyId': localStorage.getItem('cid')
    },
    method: 'GET',
    params: params
  });
}
// 获取积分活动
function getIntegralAct(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/integralAct/getIntegralAct',
    headers: {
      'companyId': localStorage.getItem('cid')
    },
    method: 'POST',
    data: params
  });
}
// 编辑
function updateIntegralAct(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/integralAct/updateIntegralAct',
    headers: {
      'companyId': localStorage.getItem('cid')
    },
    method: 'POST',
    data: params
  });
}
// 状态
function integralActOpenOrOlose(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/integralAct/integralActOpenOrOlose',
    headers: {
      'companyId': localStorage.getItem('cid')
    },
    method: 'GET',
    params: params
  });
}
function integralActDetails(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/integralAct/integralActDetails',
    headers: {
      'companyId': localStorage.getItem('cid')
    },
    method: 'get',
    params: params
  });
}
// 获取积分已选择产品
function getJFChosenProduct(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/integralAct/getJFChosenProduct',
    headers: {
      'companyId': localStorage.getItem('cid')
    },
    method: 'get',
    params: params
  });
}
// 添加信息
function addEnterpriseCustom(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/enterpriseCustom/addEnterpriseCustom',
    headers: {
      'companyId': localStorage.getItem('cid')
    },
    method: 'POST',
    data: params
  });
}
// 查询
function getEnterpriseCustom(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/enterpriseCustom/getEnterpriseCustom',
    headers: {
      'companyId': localStorage.getItem('cid')
    },
    method: 'POST',
    data: params
  });
}
// 修改信息
function updaeEnterpriseCustom(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/enterpriseCustom/updaeEnterpriseCustom',
    headers: {
      'companyId': localStorage.getItem('cid')
    },
    method: 'POST',
    data: params
  });
}
// 修改支付
function updatePayById(data) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/moWxPay/updatePayById',
    headers: {
      'companyId': localStorage.getItem('cid')
    },
    method: 'POST',
    data: data
  });
}
// 查看当前支付
function selectPayById(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/moWxPay/selectPayById',
    headers: {
      'companyId': localStorage.getItem('cid')
    },
    method: 'GET',
    data: params
  });
}
// 查看当前支付二维码
function gererateQRCode(params) {
  return (0, _request.default)({
    url: baseUrl + '/api-memberscrm/member/admin/api/moWxMemberPay/loan/gererateQRCode',
    headers: {
      'companyId': localStorage.getItem('cid')
    },
    responseType: 'arraybuffer',
    method: 'GET',
    data: params
  });
}