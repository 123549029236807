"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _productSelectDialog = _interopRequireDefault(require("@/components/productSelectDialog"));
var _wl = require("@/api/dataAnalyse/wl.js");
var _purchaseDialog = _interopRequireDefault(require("@/components/purchaseDialog"));
// import ChannelSelectDialog from '@/components/channelSelectDialog'
var _default = {
  components: {
    Pagination: _Pagination.default,
    proDialog: _productSelectDialog.default,
    purchaseDialog: _purchaseDialog.default
  },
  data: function data() {
    var _this = this;
    return {
      pickerOptions: {
        onPick: function onPick(_ref) {
          var maxDate = _ref.maxDate,
            minDate = _ref.minDate;
          _this.choiceDate = minDate.getTime();
          if (maxDate) {
            _this.choiceDate = '';
          }
        },
        disabledDate: function disabledDate(time) {
          if (_this.choiceDate) {
            var one = 30 * 24 * 3600 * 1000;
            var minTime = _this.choiceDate - one;
            var maxTime = _this.choiceDate + one;
            return time.getTime() < minTime || time.getTime() > maxTime;
          }
        }
      },
      searchForm: {
        inOrg: '',
        outOrg: '',
        inName: '',
        outName: '',
        materialCode: '',
        productName: '',
        orderNo: '',
        endTime: '',
        startTime: '',
        current: 1,
        size: 10,
        orderType: '1'
      },
      // 模糊搜索
      handleShow: false,
      // 搜索条件隐藏显示
      downLoading: false,
      // 下载
      listLoading: false,
      // 列表加载
      searchBtn: false,
      // 搜索按钮
      sweepBtn: false,
      // 重置
      total: 0,
      // 总数量
      list: [],
      // 列表
      dateVal: [],
      // 时间选择
      dialogVisible: false,
      // 详情弹窗
      dateRule: {
        required: false,
        message: '请选择时间',
        trigger: 'change'
      },
      collectData: {} // 汇总
    };
  },
  mounted: function mounted() {},
  beforeDestroy: function beforeDestroy() {},
  activated: function activated() {
    this.orderTypeFun(this.title);
    this.getDataListFun();
  },
  deactivated: function deactivated() {},
  methods: {
    getDataListFun: function getDataListFun() {
      var _this2 = this;
      this.listLoading = true;
      var searchForm = JSON.parse(JSON.stringify(this.searchForm));
      delete searchForm.productName;
      delete searchForm.inName;
      delete searchForm.outName;
      (0, _wl.queryReportForPage)((0, _objectSpread2.default)({}, searchForm)).then(function (resp) {
        _this2.listLoading = false;
        if (resp.data.code === 200) {
          _this2.list = resp.data.data.pageData.records;
          _this2.total = resp.data.data.pageData.total * 1;
          _this2.collectData = resp.data.data.countData || {};
        }
      });
    },
    orderTypeFun: function orderTypeFun(title) {
      switch (title) {
        case '入库':
          this.searchForm.orderType = '1';
          break;
        case '出库':
          this.searchForm.orderType = '2';
          break;
        case '退货':
          this.searchForm.orderType = '3';
          break;
        case '调货':
          this.searchForm.orderType = '4';
          break;
        default:
          this.searchForm.orderType = '1';
          break;
      }
    },
    searchFun: function searchFun(type) {
      if (type === 0) {
        this.dateVal = [];
        this.list = [];
        this.searchForm = {
          inOrg: '',
          outOrg: '',
          inName: '',
          outName: '',
          materialCode: '',
          productName: '',
          orderNo: '',
          endTime: '',
          startTime: '',
          current: 1,
          size: 10,
          orderType: '1'
        };
        this.orderTypeFun(this.title);
        this.$refs.form.resetFields();
      } else {
        this.searchForm.current = 1;
        // this.$refs.form.validate((valid) => {
        //   if (valid) {
        //     this.getDataListFun()
        //   } else {
        //     console.log('error submit!!')
        //     return false
        //   }
        // })
      }

      this.getDataListFun();
    },
    // 翻页
    pagingFun: function pagingFun() {
      this.getDataListFun();
    },
    details: function details(row) {
      this.dialogVisible = true;
      this.row = row;
    },
    // 日期选择
    dateFun: function dateFun(val) {
      if (val) {
        this.searchForm.endTime = val[1];
        this.searchForm.startTime = val[0];
      } else {
        this.searchForm.endTime = '';
        this.searchForm.startTime = '';
      }
    },
    inputFocusFun: function inputFocusFun(type) {
      if (type == 1) {
        this.$refs.selProduct.proSelVisible = true;
      } else {
        this.channelSelectType = type;
        this.$refs.channelSelect.proSelVisible = true;
      }
    },
    proDataFun: function proDataFun(row) {
      this.searchForm.productName = row.productName;
      this.searchForm.materialCode = row.pid;
    },
    setCSel: function setCSel(val) {
      console.log(val);
      if (this.channelSelectType === 'in') {
        this.searchForm.inOrg = val.pId;
        this.searchForm.inName = val.channelName;
      } else {
        this.searchForm.outOrg = val.pId;
        this.searchForm.outName = val.channelName;
      }
    },
    downLoad: function downLoad() {
      var _this3 = this;
      this.downLoading = true;
      (0, _wl.logisticsStatisticsExcel)(this.searchForm).then(function (res) {
        console.log(res);
        if (res.status != 200) {
          _this3.$message.error(res.data.msg);
        } else {
          var blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel'
          });
          var downloadElement = document.createElement('a');
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = _this3.title + '.xlsx';
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
        _this3.downLoading = false;
      }).catch(function () {
        _this3.downLoading = false;
      });
    }
  }
};
exports.default = _default;