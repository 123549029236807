"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.array.find");
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _policyGroup = require("@/api/policySettings2/policyGroup");
var _policyManagement = require("@/api/policySettings2/policyManagement");
var _activityManage = require("@/api/terminal/activityManage");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingManagepolicySettingspolicyGroupindex';
var _default = {
  name: 'MarketingManagepolicySettingspolicyGroupindex',
  components: {
    Pagination: _Pagination.default
  },
  filters: {
    filterFun: function filterFun(value) {
      if (value.length > 4) {
        value = value.substring(0, 3) + '...';
      }
      return value;
    }
  },
  data: function data() {
    return {
      // 策略列表内容
      strategyData: [],
      // 策略组内策略数
      num: 0,
      title: '',
      dialogNewlyBuild: false,
      // 新建策略组内容
      newlyBuildForm: {
        groupName: '',
        groupId: ''
      },
      // 新建策略组规则验证
      newlyBuildRules: {
        groupName: [{
          required: true,
          message: '请输入策略组名称',
          trigger: 'blur'
        }, {
          min: 0,
          max: 10,
          message: '长度在 0 到 10 个字符',
          trigger: 'blur'
        }]
      },
      dialogActivities: false,
      // 关联活动主题内容
      ActivitiesData: [],
      dialogAddStrategy: false,
      // 关联活动分页
      current: 1,
      size: 5,
      total2: null,
      // 添加策略内容
      strategyList: [],
      formInline: {
        strategyTypeOne: 2,
        strategyTypeTwo: 4
      },
      strategyTypeList: [],
      // 策略类型
      strategyTypeListOne: [],
      // 选择框一级策略类型
      strategyTypeListTwo: [],
      // 选择框二级策略类型
      secondwidth: 280,
      secondwidth2: 260,
      PolicyGroupList: [],
      // 策略组列表
      // 策略组id
      groupId: '',
      zhong: '',
      queryParams: {
        groupId: '',
        pageNo: 1,
        pageSize: 10
      },
      total: 0,
      // 选择添加策略组策略的id集合
      strategyIds: '',
      // 查看策略
      look: false,
      lookdata: [],
      // 表格加载效果
      dataLoading: false,
      listLoading: true,
      // 策略添加
      judgeshow: false,
      judgeshow2: false,
      // 策略二级id
      strategyTypeTwo: '',
      // 取消勾选删除的默认id
      id: ''
    };
  },
  activated: function activated() {
    // console.log(this.PolicyGroupList);
    var that = this;
    var hasCache = !!(that.PolicyGroupList && that.PolicyGroupList.length > 0);
    if (!hasCache) {
      // console.log("请求");
      this.getPolicyGroupList();
      this.getStrategyType();
    }
  },
  // 缓存优化
  deactivated: function deactivated() {
    var that = this;
    var name = cachName;
    var cache = that.getCaches(that, name);
    if (!cache) {
      that.tableData = [];
      that.total = 0;
    }
  },
  created: function created() {},
  methods: {
    // 获取策略类型
    getStrategyType: function getStrategyType() {
      var _this = this;
      (0, _policyManagement.getStrategyType)({
        level: '',
        parentId: ''
      }).then(function (res) {
        // console.log(res.data);
        if (res.data.data && res.data.data.length) {
          _this.strategyTypeList = res.data.data;
          _this.strategyTypeListOne = res.data.data.filter(function (item) {
            return item.strategyTypeLevel == 1;
          });
          _this.strategyTypeListTwo = res.data.data.filter(function (item) {
            return item.strategyTypeLevel == 2;
          });
        }
      });
    },
    // 获取不在策略组策略列表
    getStrategy: function getStrategy(data) {
      var _this2 = this;
      (0, _policyGroup.getstrategyList)(Object.assign((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.queryParams), data))).then(function (res) {
        // console.log(res.data.data);
        if (res.data.code !== 200) {
          _this2.$message.error('获取失败！');
          _this2.listLoading = false;
          return;
        } else {
          if (res.data.data !== null) {
            _this2.strategyList = res.data.data.records;
            _this2.total = res.data.data.total;
            _this2.strategyList.forEach(function (item, index) {
              if (index === 0) {
                // 等表格数据加载完成后
                _this2.$nextTick(function () {
                  // 注意：toggleRowSelection(item, true) 中 item 必须是行数据
                  _this2.$refs.multipleTable.toggleRowSelection(_this2.strategyList[index], true);
                });
              }
            });
            setTimeout(function () {
              _this2.listLoading = false;
            }, 1 * 800);
          } else {
            _this2.strategyList = [];
            _this2.listLoading = false;
          }
        }
      });
    },
    // 获取策略组内策略列表
    getList: function getList() {
      var _this3 = this;
      this.dataLoading = true;
      (0, _policyGroup.getList)({
        groupId: this.groupId
      }).then(function (res) {
        // console.log("请求");

        if (res.data.data.errCode == 500) {
          _this3.dataLoading = false;
          return _this3.$message.error(res.data.data.errMsg);
        }
        if (res.data.data && res.data.data.length) {
          _this3.strategyData = res.data.data;
          setTimeout(function () {
            _this3.dataLoading = false;
          }, 1 * 800);
        } else {
          _this3.strategyData = [];
          _this3.dataLoading = false;
        }
      });
    },
    // 策略类型选择框
    relevance: function relevance(value, data) {
      // 清空二级策略内容
      if (this.formInline.strategyTypeOne == 2) {
        this.formInline.strategyTypeTwo = 4;
      } else {
        this.formInline.strategyTypeTwo = 3;
      }
      // 存储一级策略id
      // this.parentId = value;
      var item = this.strategyTypeList.find(function (ele) {
        return ele.id == value;
      });
      if (item && item.parentId == 0) {
        this.strategyTypeListTwo = this.strategyTypeList.filter(function (ele) {
          return item.id == ele.parentId;
        });
      }
      this.queryParams.pageNo = 1;
      this.getStrategy(this.formInline);
    },
    relevance2: function relevance2(val) {
      if (val == 3) {
        this.formInline.strategyTypeOne = 1;
      } else if (val == 4) {
        this.formInline.strategyTypeOne = 2;
      } else if (val == 5) {
        this.formInline.strategyTypeOne = 2;
      }
      this.queryParams.pageNo = 1;
      this.getStrategy(this.formInline);
    },
    // 关联活动列表
    getActivitiesData: function getActivitiesData() {
      var _this4 = this;
      (0, _activityManage.getConnectedActivities)({
        current: this.current,
        size: this.size,
        strategyGroupId: this.groupId
      }).then(function (res) {
        _this4.ActivitiesData = res.data.data.records;
        _this4.total2 = res.data.data.total;
      });
    },
    // 分页功能
    handleSizeChange: function handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.getStrategy(this.formInline);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.queryParams.pageNo = val;
      this.getStrategy(this.formInline);
    },
    handleSizeChange2: function handleSizeChange2(val) {
      this.size = val;
      this.getActivitiesData();
    },
    handleCurrentChange2: function handleCurrentChange2(val) {
      this.current = val;
      this.getActivitiesData();
    },
    // 复选框选中事件
    handleSelectionChange: function handleSelectionChange(data) {
      console.log(data, '选中');
      var arr = data.map(function (item) {
        return item.id;
      });
      var arr2 = data.map(function (item) {
        return item.strategyTypeTwo;
      });
      this.strategyIds = arr.toString();
      this.strategyTypeTwo = arr2.toString();
      console.log(arr.toString(), '选中处理');
    },
    select: function select(selection, row) {
      console.log(selection, row, '选择操作');
      if (selection.length > 1) {
        var del_row = selection.shift();
        this.$refs.multipleTable.toggleRowSelection(del_row, false);
      }
    },
    selectAll: function selectAll(selection) {
      console.log(selection, '只允许单选');
      if (selection.length > 1) {
        selection.length = 1;
      }
    },
    // 获取策略组列表
    getPolicyGroupList: function getPolicyGroupList() {
      var _this5 = this;
      (0, _policyGroup.getPolicyGroupList)().then(function (res) {
        if (res.data.data && res.data.data.length) {
          var obj = res.data.data.filter(function (item) {
            return item.isDelete == 1;
          });
          var obj1 = obj.filter(function (item) {
            return item.isDefault == 0;
          });
          var obj2 = [];
          obj.forEach(function (item) {
            if (item.isDefault == 0) {
              obj2.unshift(item);
            } else {
              obj2.push(item);
            }
          });
          _this5.PolicyGroupList = obj2;
          console.log(obj1, obj2);
          if (_this5.groupId) {
            var obj3 = _this5.PolicyGroupList.filter(function (item) {
              return item.id == _this5.groupId;
            });
            _this5.zhong = obj3[0].groupName;
            _this5.num = obj3[0].actNum;
          } else {
            _this5.groupId = _this5.PolicyGroupList[0].id;
            _this5.zhong = _this5.PolicyGroupList[0].groupName;
            _this5.num = _this5.PolicyGroupList[0].actNum;
          }
          _this5.getList();
        } else {
          _this5.PolicyGroupList = [];
        }
      });
    },
    // 设为默认策略组
    handleSetPolicyGroup: function handleSetPolicyGroup(id) {
      var _this6 = this;
      (0, _policyGroup.setPolicyGroup)({
        groupId: id
      }).then(function (res) {
        if (res.data.data.errCode == 500) {
          return _this6.$message.error(res.data.data.errMsg);
        }
        _this6.$message.success('设置默认组成功');
        _this6.getPolicyGroupList();
      });
    },
    // 删除策略组
    delStrategy: function delStrategy(id) {
      var _this7 = this;
      // console.log(id);
      this.$confirm("\u662F\u5426\u5220\u9664\u8BE5\u7B56\u7565\u7EC4?", {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function (res) {
        (0, _policyGroup.delPolicyGroup)({
          groupId: id
        }).then(function (res) {
          if (res.data.data.errCode == 500) {
            _this7.$message.error('删除失败' + res.data.data.errMsg);
            return;
          } else {
            _this7.$message.success('删除成功');
            _this7.getPolicyGroupList();
          }
        });
      }).catch(function (err) {
        console.log(err);
      });
    },
    // 点击策略组刷新表格
    click: function click(id) {
      this.groupId = id;
      // this.getList();
      this.getPolicyGroupList();
    },
    // 查看策略
    handleClick: function handleClick(row) {
      // console.log(row);
      this.title = '查看策略配置';
      this.look = true;
      this.lookdata = row;
    },
    // 关闭查看
    handleClose: function handleClose() {
      this.look = false;
      this.lookdata = [];
    },
    // 删除策略
    deleteStrategy: function deleteStrategy(row) {
      var _this8 = this;
      // console.log(this.groupId, row.strategyId);
      this.$confirm("\u662F\u5426\u5220\u9664\u8BE5\u7B56\u7565\u7EC4?", {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function (res) {
        (0, _policyGroup.delPolicy)({
          groupId: _this8.groupId,
          strategyId: row.strategyId
        }).then(function (res) {
          if (res.data.data.errCode == 500) {
            return _this8.$message.error(res.data.data.errMsg);
          }
          _this8.$message.success('删除成功');
          _this8.getList();
        });
      }).catch(function (err) {
        console.log(err);
      });
    },
    // 新建策略组
    newlyBuild: function newlyBuild() {
      this.title = '新建策略组';
      this.dialogNewlyBuild = true;
    },
    // 取消策略组添加
    cancelNewlyBuild: function cancelNewlyBuild(value) {
      this.newlyBuildForm = {};
      this.$refs[value].resetFields();
      this.dialogNewlyBuild = false;
    },
    // 确定策略组添加
    determineNewlyBuild: function determineNewlyBuild(value) {
      var _this9 = this;
      // console.log(this.newlyBuildForm);
      this.$refs[value].validate(function (valid) {
        if (valid) {
          if (_this9.title == '新建策略组') {
            (0, _policyGroup.addPolicyGroup)(_this9.newlyBuildForm).then(function (res) {
              if (res.data.data.errCode == 500) {
                return _this9.$message.error(res.data.data.errMsg);
              }
              _this9.getPolicyGroupList();
              _this9.$message.success('新增成功');
              _this9.newlyBuildForm = {};
              _this9.dialogNewlyBuild = false;
            });
          } else {
            (0, _policyGroup.addPolicyGroup)(_this9.newlyBuildForm).then(function (res) {
              if (res.data.data.errCode == 500) {
                return _this9.$message.error(res.data.data.errMsg);
              }
              _this9.getPolicyGroupList();
              _this9.$message.success('编辑成功');
              _this9.newlyBuildForm = {};
              _this9.dialogNewlyBuild = false;
            });
          }
        }
      });
    },
    // 编辑策略
    editStrategy: function editStrategy(item) {
      this.newlyBuildForm.groupId = item.id;
      this.newlyBuildForm.groupName = item.groupName;
      this.title = '编辑策略组';
      this.dialogNewlyBuild = true;
    },
    // 关联活动主题
    relatedActivities: function relatedActivities() {
      this.dialogActivities = true;
      this.getActivitiesData();
    },
    // 关闭关联活动主题
    closeNewlyBuild: function closeNewlyBuild() {
      this.current = 1;
      this.dialogActivities = false;
    },
    // 添加策略组策略弹框显示
    addStrategy: function addStrategy() {
      this.dialogAddStrategy = true;
      this.queryParams.groupId = this.groupId;
      // console.log(this.groupId, this.queryParams);
      // this.getStrategy(this.formInline)
      this.relevance(this.formInline.strategyTypeOne);
    },
    // 保存添加策略组策略
    preservationStrategy: function preservationStrategy() {
      console.log(this.strategyIds, '保存');
      if (this.strategyIds == '') {
        this.judgeshow2 = true;
        this.id = this.strategyList[0].id;
        return console.log('取消勾选');
      }
      this.checkStrategyType();
    },
    // 取消保存策略
    cancelStrategy: function cancelStrategy() {
      this.formInline.strategyTypeOne = 2;
      this.formInline.strategyTypeTwo = 4;
      this.dialogAddStrategy = false;
      this.strategyIds = '';
      this.getStrategyType();
    },
    // 添加策略组策略
    AddStrategy: function AddStrategy() {
      var _this10 = this;
      this.judgeshow = false;
      (0, _policyGroup.AddStrategy)({
        groupId: this.groupId,
        strategyId: this.strategyIds
      }).then(function (res) {
        // console.log(res.data);
        if (res.data.data.errCode == 500) {
          return _this10.$message.error(res.data.data.errMsg);
        }
        _this10.$message.success('添加策略组成功');
        _this10.formInline.strategyTypeOne = 1;
        _this10.formInline.strategyTypeTwo = 3;
        _this10.dialogAddStrategy = false;
        _this10.getList();
      });
    },
    // 新加判断策略组策略判断
    checkStrategyType: function checkStrategyType() {
      var _this11 = this;
      (0, _policyGroup.checkStrategyType)({
        groupId: this.groupId,
        strategyTypeTwoId: this.strategyTypeTwo
      }).then(function (res) {
        if (res.data.data == true) {
          _this11.judgeshow = true;
        } else {
          _this11.AddStrategy();
        }
      });
    },
    // 取消策略
    delPolicy: function delPolicy(id) {
      var _this12 = this;
      this.judgeshow2 = false;
      (0, _policyGroup.delPolicy)({
        groupId: this.groupId,
        strategyId: id
      }).then(function (res) {
        if (res.data.data.errCode == 500) {
          return _this12.$message.error(res.data.data.errMsg);
        }
        _this12.dialogAddStrategy = false;
        _this12.$message.success('操作成功');
        _this12.getList();
      });
    },
    // 新增弹框
    judgeclose: function judgeclose() {
      this.judgeshow = false;
    },
    judgeclose2: function judgeclose2() {
      this.judgeshow2 = false;
    }
  }
};
exports.default = _default;