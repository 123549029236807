var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.apis)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c(
                "el-menu",
                {
                  staticClass: "el-menu-demo",
                  attrs: {
                    "default-active": _vm.activeIndex,
                    mode: "horizontal",
                  },
                  on: { select: _vm.DoReset },
                },
                [
                  _c("el-menu-item", { attrs: { index: "1" } }, [
                    _vm._v("经销商入库流水"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  ref: "listQuery",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.listQuery,
                    "status-icon": "",
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cols" },
                    [
                      _c(
                        "el-row",
                        {
                          staticStyle: { "margin-bottom": "0" },
                          attrs: { gutter: 20 },
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "billNo", label: "单号:" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.listQuery.billNo,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.listQuery, "billNo", $$v)
                                      },
                                      expression: "listQuery.billNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "入库主户头:",
                                    prop: "accountStr",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      "suffix-icon": "el-icon-search",
                                      clearable: "",
                                      readonly: "",
                                      placeholder: "请输入",
                                    },
                                    on: { focus: _vm.accountChoose },
                                    model: {
                                      value: _vm.accountStr,
                                      callback: function ($$v) {
                                        _vm.accountStr = $$v
                                      },
                                      expression: "accountStr",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.showCol
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "发货单位:",
                                        prop: "sendChannelStr",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          "suffix-icon": "el-icon-search",
                                          clearable: "",
                                          readonly: "",
                                          placeholder: "请输入",
                                        },
                                        on: {
                                          clear: _vm.clearFh,
                                          focus: function ($event) {
                                            return _vm.getUnit("out")
                                          },
                                        },
                                        model: {
                                          value: _vm.sendChannelStr,
                                          callback: function ($$v) {
                                            _vm.sendChannelStr = $$v
                                          },
                                          expression: "sendChannelStr",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.showCol || !_vm.formConfig.btnFollow,
                                  expression:
                                    "showCol || !formConfig.btnFollow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "产品名称:",
                                    prop: "productStr",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      "suffix-icon": "el-icon-search",
                                      clearable: "",
                                      readonly: "",
                                      placeholder: "请输入",
                                    },
                                    on: {
                                      clear: _vm.clearPro,
                                      focus: _vm.choseProName,
                                    },
                                    model: {
                                      value: _vm.productStr,
                                      callback: function ($$v) {
                                        _vm.productStr = $$v
                                      },
                                      expression: "productStr",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.showCol || !_vm.formConfig.btnFollow,
                                  expression:
                                    "showCol || !formConfig.btnFollow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "入库单位:",
                                    prop: "recChannelStr",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      "suffix-icon": "el-icon-search",
                                      clearable: "",
                                      readonly: "",
                                      placeholder: "请输入",
                                    },
                                    on: {
                                      clear: _vm.clearSh,
                                      focus: function ($event) {
                                        return _vm.getUnit("in")
                                      },
                                    },
                                    model: {
                                      value: _vm.recChannelStr,
                                      callback: function ($$v) {
                                        _vm.recChannelStr = $$v
                                      },
                                      expression: "recChannelStr",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.showCol || !_vm.formConfig.btnFollow,
                                  expression:
                                    "showCol || !formConfig.btnFollow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "billType",
                                    label: "单据类型:",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      on: { change: _vm.getbType },
                                      model: {
                                        value: _vm.listQuery.billType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.listQuery,
                                            "billType",
                                            $$v
                                          )
                                        },
                                        expression: "listQuery.billType",
                                      },
                                    },
                                    _vm._l(_vm.billTypes, function (item) {
                                      return _c("el-option", {
                                        key: item.code,
                                        attrs: {
                                          label: item.name,
                                          value: item.code,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "date", label: "入库日期:" } },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "daterange",
                                      format: "yyyy-MM-dd",
                                      "value-format": "yyyy/MM/dd",
                                      "range-separator": "至",
                                      "start-placeholder": "开始日期",
                                      "end-placeholder": "结束日期",
                                    },
                                    model: {
                                      value: _vm.date,
                                      callback: function ($$v) {
                                        _vm.date = $$v
                                      },
                                      expression: "date",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.formConfig.btnFollow
                            ? _c(
                                "el-col",
                                {
                                  staticClass: "head-btn-group",
                                  attrs: { span: 6 },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { "label-width": "0" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v(" ")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.searchBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.searchBtn.icon,
                                          },
                                          on: { click: _vm.DoSearch },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.searchBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      false
                                        ? _c("el-divider", {
                                            attrs: { direction: "vertical" },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.resetBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.resetBtn.icon,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.DoReset(false)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.resetBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      false
                                        ? _c("el-divider", {
                                            attrs: { direction: "vertical" },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: { click: _vm.expand },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(_vm.expandTxt) +
                                              "\n                "
                                          ),
                                          _c("i", {
                                            class:
                                              _vm.showCol == false
                                                ? "el-icon-arrow-down"
                                                : "el-icon-arrow-up",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.formConfig.btnFollow
                ? _c("el-divider", { staticClass: "btnDivider" })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btnArea" },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "head-btn-group",
                      staticStyle: { width: "230px" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "head-btn-group" },
                        [
                          _vm.hasAuth("importbtn", _vm.apis)
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: _vm.btnConfig.downLoadBtn.type,
                                    size: _vm.btnConfig.size,
                                    plain: _vm.btnConfig.downLoadBtn.plain,
                                    icon: _vm.btnConfig.downLoadBtn.icon,
                                    loading: _vm.exportLoading,
                                  },
                                  on: { click: _vm.DoExportAll },
                                },
                                [_vm._v(_vm._s(_vm.btnConfig.downLoadBtn.text))]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "section",
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      key: _vm.tableKey,
                      staticStyle: { width: "100%" },
                      attrs: {
                        height: _vm.tabHeight,
                        data: _vm.list,
                        border: _vm.tableConfig.border,
                        stripe: _vm.tableConfig.stripe,
                        fit: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "单号",
                          "min-width": "160",
                          prop: "billNo",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "入库单位编码",
                          prop: "inOrgCode",
                          align: _vm.tableConfig.align,
                          "min-width": "120",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "入库单位名称",
                          prop: "inOrgName",
                          align: _vm.tableConfig.align,
                          "min-width": "120",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "入库主户头编码",
                          prop: "accountCode",
                          align: _vm.tableConfig.align,
                          "min-width": "120",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "入库主户头名称",
                          prop: "accountName",
                          align: _vm.tableConfig.align,
                          "min-width": "120",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "发货单位编码",
                          prop: "outOrgCode",
                          align: _vm.tableConfig.align,
                          "min-width": "120",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "发货单位名称",
                          prop: "outOrgName",
                          align: _vm.tableConfig.align,
                          "min-width": "120",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "入库日期",
                          "min-width": "170",
                          prop: "statementTime",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "单据类型",
                          prop: "billType",
                          "min-width": "120",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  row.billType == "203"
                                    ? _c("span", [_vm._v("销售出库")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.billType == "204"
                                    ? _c("span", [_vm._v("退货出库")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.billType == "205"
                                    ? _c("span", [_vm._v("其他出库")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.billType == "101"
                                    ? _c("span", [_vm._v("生产入库")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.billType == "103"
                                    ? _c("span", [_vm._v("退货入库")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.billType == "104"
                                    ? _c("span", [_vm._v("采购入库")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.billType == "105"
                                    ? _c("span", [_vm._v("其他入库")])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1883828483
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "产品编码",
                          prop: "productCode",
                          "min-width": "100",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "产品名称",
                          prop: "productName",
                          "min-width": "100",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "包装比例",
                          prop: "packScaleExpression",
                          "min-width": "100",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "应收箱",
                          prop: "planScanNum",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "应收盒/瓶",
                          prop: "planScanAtoNum",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "实收箱",
                          prop: "realScanNum",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "实收盒/瓶",
                          prop: "realScanAtoNum",
                          align: _vm.tableConfig.align,
                          "show-overflow-tooltip": "",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("pagination", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.total > 0,
                        expression: "total > 0",
                      },
                    ],
                    attrs: {
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.total,
                      page: _vm.listQuery.pageNo,
                      limit: _vm.listQuery.pageSize,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(_vm.listQuery, "pageNo", $event)
                      },
                      "update:limit": function ($event) {
                        return _vm.$set(_vm.listQuery, "pageSize", $event)
                      },
                      pagination: _vm.getList,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.apis)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c("purchaseDialog", {
        ref: "channelSelect",
        attrs: { title: "选择单位" },
        on: { change: _vm.getUalue },
      }),
      _vm._v(" "),
      _c("accountDialog", {
        attrs: { visible: _vm.accountShow },
        on: { change: _vm.setAccount, close: _vm.accountClose },
      }),
      _vm._v(" "),
      _c("productChose", { ref: "selProduct", on: { change: _vm.getProduct } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }