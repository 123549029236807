"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.number.constructor");
var _defineProperty2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _ordergl = require("@/api/warehouse/ordergl");
var _org = require("@/api/basic/org");
var _channel = require("@/api/basic/channel");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _factorySelectDialog = _interopRequireDefault(require("@/components/factorySelectDialog"));
var _companySelectDialog = _interopRequireDefault(require("@/components/companySelectDialog"));
var _productDialog = _interopRequireDefault(require("@/components/productDialog"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
var _dictionary = require("@/api/systems/dictionary");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var TypeDataComs = {
  orderStatus: []
};
var _default = {
  name: 'Order',
  components: {
    Pagination: _Pagination.default,
    factorySelectDialog: _factorySelectDialog.default,
    companySelectDialog: _companySelectDialog.default,
    productDialog: _productDialog.default
  },
  filters: {
    orderStatusText: function orderStatusText(value) {
      var name = '';
      TypeDataComs.orderStatus.forEach(function (c) {
        if (c.figure == value) {
          name = c.name;
          return;
        }
      });
      return name;
    },
    formatTime: function formatTime(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        return (0, _utils.parseTime)(value, '{y}-{m}-{d}');
      }
    },
    payWayText: function payWayText(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          2: '先货后款',
          1: '先款后货'
        };
        return statusMap[value];
      }
    },
    chnMoney: function chnMoney(n) {
      if (!/^(0|[1-9]\d*)(\.\d+)?$/.test(n) || n == 0.0) return '';
      var unit = '千百拾亿千百拾万千百拾元角分';
      var str = '';
      var p = n.indexOf('.');
      if (p >= 0) n = n.substring(0, p) + n.substr(p + 1, 2);
      unit = unit.substr(unit.length - n.length);
      for (var i = 0; i < n.length; i++) {
        str += '零壹贰叁肆伍陆柒捌玖'.charAt(n.charAt(i)) + unit.charAt(i);
      }
      return str.replace(/零(千|百|拾|角)/g, '零').replace(/(零)+/g, '零').replace(/零(万|亿|元)/g, '$1').replace(/(亿)万|壹(拾)/g, '$1$2').replace(/^元零?|零分/g, '').replace(/元$/g, '元整');
    }
  },
  data: function data() {
    var _ref;
    return _ref = {
      listQuery: {
        current: 1,
        size: 20,
        beginDate: null,
        endDate: null,
        orderNo: null,
        orderStatus: null,
        orderType: '1',
        businessClass: 1,
        sendOutComId: null,
        storeOrderInfoQuery: {
          queryType: '',
          queryContent: '',
          receiveComId: ''
        }
      },
      payWay: [{
        name: '先款后货',
        code: 1
      }, {
        name: '先货后款',
        code: 2
      }],
      orderStatus: [],
      businessOption: [
      // {
      //   name: '发货库房',
      //   code: 1
      // },
      {
        name: '联系人',
        code: 2
      }, {
        name: '联系电话',
        code: 3
      }, {
        name: '申请人',
        code: 7
      }, {
        name: '采购单位',
        code: 8
      }],
      tableKey: 'orderTable',
      tabHeight: '100%',
      list: [],
      listLoading: false,
      total: 0,
      inUseName: '',
      factoryShow: false,
      companyShow: false,
      productShow: false,
      // 采购单位名称
      purchaseComName: '',
      // 收货单位名称
      receiveComName: '',
      // 查询收货单位名称
      searchReceiveComName: '',
      // 发货单位名称
      sendOutComName: '',
      // 查询发货单位名称
      searchSendOutComName: '',
      orgId: '',
      inOutType: '',
      receiveLoading: false,
      companycanChange: true,
      factorycanChange: true,
      companyType: '',
      factoryType: '',
      isEnable: '',
      productList: [],
      // normalList: [],
      normalFacList: [],
      addData: {
        businessClass: 1,
        pid: '',
        orderType: 1,
        orderNo: '',
        placeOrderDate: (0, _utils.parseTime)(new Date(), '{y}-{m}-{d}'),
        paymentStyle: '',
        purchaseComId: '',
        purchaseComType: '',
        applyUser: '',
        receiveComId: '',
        receiveComType: '',
        receiveContacts: '',
        receivePhone: '',
        receiveAddress: '',
        sendOutComId: '',
        sendOutComType: 1,
        orderStatus: 1,
        remark: '',
        // 产品列表
        orderDetailDTOS: []
      },
      rules: {
        purchaseComId: [{
          required: true,
          message: '采购单位是必填项，请填写！',
          trigger: 'change'
        }],
        paymentStyle: [{
          required: true,
          message: '付款方式是必填项，请填写！',
          trigger: 'change'
        }],
        placeOrderDate: [{
          required: true,
          message: '请选择订单日期',
          trigger: 'change'
        }],
        applyUser: [{
          required: true,
          message: '申请人是必填项，请填写！',
          trigger: 'change'
        }],
        receiveComId: [{
          required: true,
          message: '收货单位是必填项，请填写！',
          trigger: 'change'
        }],
        sendOutComId: [{
          required: true,
          message: '发货单位是必填项，请填写！',
          trigger: 'change'
        }]
      },
      title: '采购订单',
      dialogVisible: false,
      detailShow: false,
      loading: false,
      subType: ''
    }, (0, _defineProperty2.default)(_ref, "isEnable", ''), (0, _defineProperty2.default)(_ref, "showCol", false), (0, _defineProperty2.default)(_ref, "expandTxt", '展开'), _ref;
  },
  computed: {
    totalMoney: {
      get: function get() {
        var totalSumAll = 0;
        this.productList.forEach(function (item) {
          if (!isNaN(item.totalPrice)) {
            totalSumAll += Number(item.totalPrice);
          }
        });
        totalSumAll = totalSumAll.toFixed(2);
        if (isNaN(totalSumAll)) {
          return 0;
        }
        return totalSumAll;
      },
      set: function set(val) {
        this.totalMoney = val;
      }
    }
  },
  activated: function activated() {
    this.getList();
    this.getDirSel('CCCGDDZT', 'orderStatus');
    this.getFacList();
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    getDirSel: function getDirSel(code, list) {
      var _this = this;
      (0, _dictionary.getItemByDataDictionaryByCode)({
        code: code
      }).then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this[list] = res.data.data;
            TypeDataComs[list] = res.data.data;
          } else {
            _this[list] = [];
            TypeDataComs[list] = [];
          }
        }
      });
    },
    checkQueryType: function checkQueryType() {
      if (!this.listQuery.storeOrderInfoQuery.queryType) {
        this.$message.error('请选择查询类型');
      }
    },
    getFacList: function getFacList() {
      var _this2 = this;
      (0, _org.getXgOrgInfo)((0, _defineProperty2.default)({
        orgTypeList: ''
      }, "orgTypeList", ['2', '3'])).then(function (res) {
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            _this2.normalFacList = res.data.data;
            console.log(res.data.data, 'data');
          } else {
            _this2.normalFacList = [];
          }
        }
      });
    },
    getList: function getList() {
      var auth = this.authId('searchbtn', this.$route.meta.authList);
      var that = this;
      that.listLoading = true;
      var param = JSON.parse(JSON.stringify(that.listQuery));
      (0, _ordergl.queryForPage)(param).then(function (res) {
        if (res.data.code == 200) {
          that.list = res.data.data.records;
          that.total = parseInt(res.data.data.total);
          setTimeout(function () {
            that.listLoading = false;
          }, 1 * 800);
        } else {
          that.$message.error(res.data.msg);
          that.listLoading = false;
          return;
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    // 新增
    DoAdd: function DoAdd() {
      var _this3 = this;
      var auth = this.authId('addbtn', this.$route.meta.authList);
      var that = this;
      this.title = '采购订单';
      that.subType = 'add';
      (0, _ordergl.getOrderNo)(1).then(function (res) {
        if (res.data.code == 200) {
          _this3.addData.orderNo = res.data.data;
          that.dialogVisible = true;
          _this3.$nextTick(function () {
            _this3.$refs.addForm.clearValidate();
          });
        } else {
          that.$message.error('订单号获取失败，请重试！');
        }
      });
    },
    /**
     * 单位选择
     * @param {*} name 需要回传修改的名字
     * @param {*} companyType 单位类型 1 收获单位 2发货单位 0采购单位
     * @param {*} inOutType 单据类型 1销售出库 2调拨出库 3其他出库 4生产入库 5调拨入库 6采购入库 7其他入库 11 采购订单 12销售订单
     *
     */
    openCompany: function openCompany(name, companyType, inOutType) {
      this.inUseName = name;
      this.companyType = companyType;
      this.inOutType = inOutType;
      this.companyShow = true;
    },
    setCompany: function setCompany(val) {
      console.log(val, 'val');
      this[this.inUseName] = val.companyName;
      switch (this.inUseName) {
        case 'purchaseComName':
          this.addData.purchaseComId = val.companyId;
          //  0企业 1一级经销商 2终端,3工厂，22销售公司,33生产公司
          switch (val.companyType * 1) {
            case 1:
              this.addData.purchaseComType = 3;
              this.addData.receiveComType = 3;
              break;
            case 22:
              this.addData.purchaseComType = 2;
              this.addData.receiveComType = 2;
              break;
            case 33:
              this.addData.purchaseComType = 1;
              this.addData.receiveComType = 1;
              break;
            default:
              this.addData.purchaseComType = 2;
              this.addData.receiveComType = 2;
          }
          this.addData.receiveComId = val.companyId;
          this.receiveComName = val.companyName;
          this.getReceiveInfo(val);
          break;
        case 'receiveComName':
          console.log(val.companyType, ' val.companyType');
          this.addData.receiveComId = val.companyId;
          switch (val.companyType * 1) {
            case 1:
              this.addData.receiveComType = 3;
              break;
            case 22:
              this.addData.receiveComType = 2;
              break;
            case 33:
              this.addData.receiveComType = 1;
              break;
            default:
              this.addData.receiveComType = 2;
          }
          this.getReceiveInfo(val);
          break;
        case 'searchReceiveComName':
          this.listQuery.storeOrderInfoQuery.receiveComId = val.companyId;
          break;
        case 'searchSendOutComName':
          this.listQuery.sendOutComId = val.companyId;
          break;
        case 'sendOutComName':
          this.addData.sendOutComId = val.companyId;
          switch (val.companyType * 1) {
            case 1:
              this.addData.sendOutComType = 3;
              break;
            case 22:
              this.addData.sendOutComType = 2;
              break;
            case 33:
              this.addData.sendOutComType = 1;
              break;
            default:
              this.addData.sendOutComType = 1;
          }
          break;
      }
    },
    getReceiveInfo: function getReceiveInfo(val) {
      var _this4 = this;
      this.receiveLoading = true;
      (0, _ordergl.getCompanyById)({
        pId: val.companyId
      }).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          var that = _this4;
          var datas = response.data.data;
          _this4.addData.receiveContacts = datas.contacts || '';
          _this4.addData.receivePhone = datas.telephone || datas.phone || '';
          var provinceName = datas.provinceName || '';
          var cityName = datas.cityName || '';
          var countyName = datas.countyName || '';
          var detailedAddress = datas.detailedAddress || '';
          _this4.addData.receiveAddress = provinceName + cityName + countyName + detailedAddress;
        }
        _this4.receiveLoading = false;
      }).catch(function () {
        _this4.receiveLoading = false;
      });
    },
    companyClose: function companyClose() {
      this.inUseName = '';
      this.companyType = '';
      this.orgId = '';
      this.isEnable = '';
      this.companycanChange = true;
      this.companyShow = false;
    },
    resetReceive: function resetReceive() {
      this.receiveComName = '';
      this.addData.receiveComId = '';
      this.addData.receiveContacts = '';
      this.addData.receivePhone = '';
      this.addData.receiveAddress = '';
    },
    /**
     * 工厂选择
     * @param {*} name 需要回传修改的名字
     * @param {*} status 是否允许修改 单位类型
     * @param {*} companyType 单位类型 2 ：销售公司,3: 生产公司,  "" : 全部
     *
     */
    openFactory: function openFactory(name, status, companyType, isEnable) {
      this.inUseName = name;
      this.factorycanChange = status;
      this.factoryType = companyType;
      this.isEnable = isEnable;
      this.factoryShow = true;
    },
    setFactory: function setFactory(val) {
      this[this.inUseName] = val.orgName;
      switch (this.inUseName) {
        case 'sendOutComName':
          this.addData.sendOutComId = val.pid;
          this.addData.sendOutComType = 1;
          break;
        case 'searchSendOutComName':
          this.listQuery.sendOutComId = val.pid;
          break;
      }
    },
    factoryClose: function factoryClose() {
      this.inUseName = '';
      this.factorystatus = '';
      this.isEnable = '';
      this.factoryShow = false;
    },
    openPro: function openPro() {
      this.productShow = true;
    },
    setPro: function setPro(val) {
      var arr = JSON.parse(JSON.stringify(this.productList));
      if (val.length) {
        if (this.productList.length == 0) {
          var obj = {
            // prodUnit: 1,
            chestNum: '',
            bottleNum: '',
            bottleNumOver: 0,
            isUseCoupon: 0
          };
          val.forEach(function (item) {
            item = Object.assign(item, obj);
          });
        } else {
          val.forEach(function (item) {
            // item.prodUnit = 1
            item.chestNum = '';
            item.bottleNum = '';
            item.bottleNumOver = 0;
            item.isUseCoupon = 0;
            arr.forEach(function (el) {
              if (el.pId == item.pId) {
                // item.prodUnit = el.prodUnit
                item.chestNum = el.chestNum;
                item.bottleNum = el.bottleNum;
                item.bottleNumOver = el.bottleNumOver;
                item.isUseCoupon = el.isUseCoupon;
              }
            });
          });
        }
        this.productList = JSON.parse(JSON.stringify(val));
      } else {
        this.productList = [];
      }
    },
    proClose: function proClose() {
      this.productShow = false;
    },
    totalPrice: function totalPrice(row) {
      var value = row.packScaleExpression;
      // var level = row.prodUnit
      var num = row.bottleNum * 1;
      num = num || 0;
      var prize = row.productPrice;
      if (!value || !num) {
        row.totalPrice = 0;
        return 0;
      } else {
        var arr = value.split('*');
        var le = arr.length - 1;
        var total =
        // row.prodUnit == 1
        //   ? num * (arr[le] * 1) * (prize * 1)
        num * (prize * 1);
        row.totalPrice = total.toFixed(2);
        return row.totalPrice;
      }
    },
    resetProdUnit: function resetProdUnit($el, row) {
      row.chestNum = 0;
      row.bottleNum = '';
      this.resetList();
    },
    changeChestNum: function changeChestNum(row) {
      var value = row.packScaleExpression;
      var arr = value.split('*');
      var le = arr.length - 1;
      row.bottleNum = (row.chestNum || 0) * arr[le] + (row.bottleNumOver || 0);
      this.resetList();
    },
    resetList: function resetList() {
      this.productList = JSON.parse(JSON.stringify(this.productList));
    },
    // 删除产品明细列表
    DoSc: function DoSc(row) {
      var _this5 = this;
      var that = this;
      that.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this5.productList.splice(_this5.productList.indexOf(row), 1);
      });
    },
    // 展开收起搜索条件
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    },
    DoSearch: function DoSearch() {
      var that = this;
      that.listQuery.current = 1;
      that.getList();
    },
    DoReset: function DoReset(formName) {
      this.searchReceiveComName = '';
      this.searchSendOutComName = '';
      this.listQuery = {
        current: 1,
        size: 20,
        beginDate: null,
        endDate: null,
        orderNo: null,
        orderStatus: null,
        orderType: '1',
        businessClass: 1,
        sendOutComId: null,
        storeOrderInfoQuery: {
          queryType: '',
          queryContent: '',
          receiveComId: ''
        }
      };
      this.getList();
    },
    // 查看
    DoScan: function DoScan(data) {
      var auth = this.authId('detailbtn', this.$route.meta.authList);
      this.title = '采购订单-查看';
      this.subType = 'scan';
      var id = data.pid;
      this.getDetailData(id, 'scan');
      this.detailShow = true;
    },
    // 修改
    DoEdit: function DoEdit(data) {
      var auth = this.authId('updatebtn', this.$route.meta.authList);
      this.title = '采购订单-修改';
      this.subType = 'edit';
      this.getDetailData(data.pid, 'edit');
      this.dialogVisible = true;
    },
    // 获取详情数据
    getDetailData: function getDetailData(id, type) {
      var _this6 = this;
      this.loading = true;
      (0, _ordergl.queryById)(id).then(function (res) {
        if (res.data.code == 200) {
          var data = res.data.data;
          var arr = [];
          data.orderDetailDTOS.forEach(function (item) {
            var aNum = item.packRaidoExpress.split('*');
            var le = aNum.length - 1;
            var obj = {
              pid: item.pid,
              pId: item.productId,
              productCode: item.productCode,
              productName: item.productName,
              productModel: item.productSpec,
              packScaleExpression: item.packRaidoExpress,
              chestNum: item.chestNum,
              bottleNum: item.bottleNum,
              bottleNumOver: item.bottleNum % aNum[le],
              totalPrice: item.totalPrice,
              productPrice: item.prodPrice,
              isUseCoupon: item.isUseCoupon
            };
            console.log(obj, 999);
            arr.push(obj);
          });
          _this6.productList = arr;
          _this6.addData = res.data.data;
          _this6.purchaseComName = data.purchaseComName;
          _this6.receiveComName = data.receiveComName;
          _this6.sendOutComName = data.sendOutComName;
        } else {
          _this6.$message.error(res.data.msg);
        }
        _this6.loading = false;
      }).catch(function () {
        _this6.loading = false;
      }).then(function () {
        if (type == 'edit') {
          _this6.$nextTick(function () {
            _this6.$refs.addForm.clearValidate();
          });
        }
      });
    },
    // 置空字段
    resetVoid: function resetVoid() {
      this.addData = {
        businessClass: 1,
        pid: '',
        orderType: 1,
        orderNo: '',
        placeOrderDate: (0, _utils.parseTime)(new Date(), '{y}-{m}-{d}'),
        paymentStyle: '',
        purchaseComId: '',
        purchaseComType: '',
        applyUser: '',
        receiveComId: '',
        receiveComType: '',
        receiveContacts: '',
        receivePhone: '',
        receiveAddress: '',
        sendOutComId: '',
        sendOutComType: 1,
        orderStatus: 1,
        remark: '',
        // 产品列表
        orderDetailDTOS: []
      };
      this.productList = [];
      this.purchaseComName = '';
      this.receiveComName = '';
      this.sendOutComName = '';
    },
    handleClose: function handleClose() {
      var that = this;
      this.$refs.addForm.resetFields();
      that.resetVoid();
      that.dialogVisible = false;
      var auth = this.authId('searchbtn', this.$route.meta.authList);
    },
    detailClose: function detailClose() {
      this.resetVoid();
      this.detailShow = false;
      var auth = this.authId('searchbtn', this.$route.meta.authList);
    },
    submitForm: function submitForm(val) {
      var _this7 = this;
      var that = this;
      this.$refs['addForm'].validate(function (valid) {
        if (valid) {
          that.addData.totalMoney = that.totalMoney;
          // if (that.addData.orderTime == '') {
          //   that.$message.error('请选择订单日期')
          //   return
          // }
          if (!that.productList.length) {
            that.$message.error('请选择产品');
            return;
          }
          var plist = that.productList;
          var oldList = JSON.parse(JSON.stringify(that.addData.orderDetailDTOS));
          var newList = [];
          if (plist.length > 0) {
            for (var i = 0; i < plist.length; i++) {
              if (!plist[i].bottleNum || plist[i].bottleNum == 0) {
                that.$message.error('在列表第' + (i + 1) + '行请输入采购数量');
                return;
              }
              if (that.subType == 'add') {
                var obj = {
                  bottleNum: plist[i].bottleNum,
                  chestNum: plist[i].chestNum,
                  // detailStatus: 0,
                  orderNo: that.addData.orderNo,
                  packRaidoExpress: plist[i].packScaleExpression,
                  prodPrice: plist[i].productPrice,
                  // prodUnit: plist[i].prodUnit,
                  productCode: plist[i].productCode,
                  productId: plist[i].pId,
                  productName: plist[i].productName,
                  productSpec: plist[i].productModel,
                  totalPrice: plist[i].totalPrice,
                  isUseCoupon: plist[i].isUseCoupon
                };
                newList.push(obj);
              }
              if (that.subType == 'edit') {
                var obj = {
                  pid: plist[i].pid,
                  bottleNum: plist[i].bottleNum,
                  chestNum: plist[i].chestNum,
                  // detailStatus: 0,
                  orderNo: that.addData.orderNo,
                  packRaidoExpress: plist[i].packScaleExpression,
                  prodPrice: plist[i].productPrice,
                  // prodUnit: plist[i].prodUnit,
                  productCode: plist[i].productCode,
                  productId: plist[i].pId,
                  productName: plist[i].productName,
                  productSpec: plist[i].productModel,
                  totalPrice: plist[i].totalPrice,
                  isUseCoupon: plist[i].isUseCoupon
                };
                oldList.forEach(function (item) {
                  if (item.pid == obj.pid) {
                    obj = Object.assign(item, obj);
                  }
                });
                newList.push(obj);
              }
            }
          }
          that.addData.orderDetailDTOS = newList;
          that.loading = true;
          that.dsate = true;
          var subData = that.addData;
          console.log(subData);
          if (that.subType == 'add') {
            (0, _ordergl.insertOrder)(subData).then(function (res) {
              if (res.data.code == 200) {
                that.$message({
                  type: 'success',
                  message: '添加成功'
                });
                _this7.handleClose();
                that.getList();
              } else {
                that.$message.error(res.data.msg);
              }
              that.loading = false;
              that.dsate = false;
            }).catch(function () {
              that.loading = false;
              that.dsate = false;
            });
          }
          if (that.subType == 'edit') {
            (0, _ordergl.updateOrder)(subData).then(function (res) {
              if (res.data.code == 200) {
                that.$message({
                  type: 'success',
                  message: '修改成功'
                });
                _this7.handleClose();
                that.getList();
              } else {
                that.$message.error(res.data.msg);
              }
              that.loading = false;
              that.dsate = false;
            }).catch(function () {
              that.loading = false;
              that.dsate = false;
            });
          }
        } else {
          _this7.$message.error('请完善信息！');
        }
      });
    },
    mixNumP: function mixNumP(row) {
      var arr = row.packScaleExpression.split('*');
      return arr[arr.length - 1] * 1 - 1;
    }
  }
};
exports.default = _default;