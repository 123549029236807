"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _basic = require("@/api/fake_expel/basic");
var _expel = require("@/api/fake_expel/expel/expel");
var _waves = _interopRequireDefault(require("@/directive/waves"));
var _digital = _interopRequireDefault(require("./digital"));
var _query_map_wl_chan = _interopRequireDefault(require("./query_map_wl_chan"));
var _query_map_jxs_chan = _interopRequireDefault(require("./query_map_jxs_chan"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'ComplexTable',
  components: {
    digital: _digital.default,
    queryMapwlChanDigital: _query_map_wl_chan.default,
    queryMapJxsChanDigital: _query_map_jxs_chan.default
  },
  directives: {
    waves: _waves.default
  },
  props: ['tabHeight'],
  data: function data() {
    return {
      queryMapChan_label: '疑似窜货统计',
      queryMapWlChan_label: '产品疑似窜货统计',
      queryMapJxsChan_label: '经销商/终端疑似窜货统计',
      tableKey: {
        queryMapChan: 0,
        queryMapWlChan: 0,
        queryMapJxsChan: 0
      },
      list: {
        queryMapChan: null,
        queryMapWlChan: null,
        queryMapJxsChan: null
      },
      total: 0,
      downTotal: 0,
      excelOut_loading: false,
      listLoading: {
        queryMapChan: true,
        queryMapWlChan: true,
        queryMapJxsChan: true
      },
      listQuery: {
        startTime: '',
        endTime: '',
        ppwlList: [],
        province: ''
      },
      activeName: 'queryMapChan'
    };
  },
  created: function created() {
    this.btnPowers = (0, _basic.setPermission)(this, 'SHLB');
    this.queryMapChan();
  },
  methods: {
    handleClick: function handleClick(tab, event) {
      if (tab.name == 'queryMapChan') {
        this.queryMapChan();
      }
      if (tab.name == 'queryMapWlChan') {
        this.queryMapWlChan();
      }
      if (tab.name == 'queryMapJxsChan') {
        this.queryMapJxsChan();
      }
    },
    queryMapChan: function queryMapChan() {
      var _this = this;
      // if(this.btnPowers.SHCX.view==false){this.$message.error('权限不足'); return false;}
      this.listLoading.queryMapChan = true;
      (0, _expel.queryMapChan)(this.listQuery).then(function (response) {
        var data = response.data;
        if (data.code != 200) {
          _this.$message.error(data.msg);
          return true;
        }
        _this.list.queryMapChan = data.data;
        _this.listLoading.queryMapChan = false;
      });
    },
    queryMapWlChan: function queryMapWlChan() {
      var _this2 = this;
      this.listLoading.queryMapWlChan = true;
      (0, _expel.queryMapWlChan)(this.listQuery).then(function (response) {
        var data = response.data;
        if (data.code != 200) {
          _this2.$message.error(data.msg);
          return true;
        }
        _this2.list.queryMapWlChan = data.data;
        _this2.listLoading.queryMapWlChan = false;
      });
    },
    queryMapJxsChan: function queryMapJxsChan() {
      var _this3 = this;
      this.listLoading.queryMapJxsChan = true;
      (0, _expel.queryMapJxsChan)(this.listQuery).then(function (response) {
        var data = response.data;
        if (data.code != 200) {
          _this3.$message.error(data.msg);
          return true;
        }
        _this3.list.queryMapJxsChan = data.data;
        _this3.listLoading.queryMapJxsChan = false;
      });
    },
    excelOutMapJxsChan: function excelOutMapJxsChan() {
      var _this4 = this;
      this.excelOut_loading = true;
      (0, _expel.excelOutMapJxsChan)(this.listQuery).then(function (res) {
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = (_this4.listQuery.province == '' ? '全国' : _this4.listQuery.province) + '-防窜预警地图经销商/终端疑似窜货统计' + _this4.listQuery.queryTime + '.xlsx';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
        _this4.excelOut_loading = false;
      });
    },
    searchList: function searchList(data) {
      this.listQuery = (0, _basic.getKeysetVal)(this.listQuery, data);
      this.getList();
    },
    getSetDigital: function getSetDigital(row) {
      // 防窜预警地图疑似窜货统计清单
      this.$refs.Dialogdigital.listQuery.pageNo = 1;
      this.$refs.Dialogdigital.listQuery.pageSize = 10;
      this.$refs.Dialogdigital.listQuery.startTime = this.listQuery.startTime;
      this.$refs.Dialogdigital.listQuery.endTime = this.listQuery.endTime;
      this.$refs.Dialogdigital.listQuery.ppwlList = this.listQuery.ppwlList;
      this.$refs.Dialogdigital.listQuery.proCity = row.ysccPro;
      this.$refs.Dialogdigital.dialogDigitalVisible = true;
    },
    getSetqueryMapWlChan: function getSetqueryMapWlChan(row) {
      // 防窜预警地图物料疑似窜货统计清单
      this.$refs.DialogqueryMapwlChanDigital.listQuery.pageNo = 1;
      this.$refs.DialogqueryMapwlChanDigital.listQuery.pageSize = 10;
      this.$refs.DialogqueryMapwlChanDigital.listQuery.startTime = this.listQuery.startTime;
      this.$refs.DialogqueryMapwlChanDigital.listQuery.endTime = this.listQuery.endTime;
      var arr = [];
      arr.push(row.wldm);
      this.$refs.DialogqueryMapwlChanDigital.listQuery.ppwlList = arr;
      this.$refs.DialogqueryMapwlChanDigital.listQuery.province = this.listQuery.province;
      this.$refs.DialogqueryMapwlChanDigital.dialogDigitalVisible = true;
    },
    getSetqueryMapJxsChan: function getSetqueryMapJxsChan(row) {
      // 防窜预警地图一级经销商疑似窜货统计清单
      this.$refs.DialogqueryMapJxsChanDigital.listQuery.pageNo = 1;
      this.$refs.DialogqueryMapJxsChanDigital.listQuery.pageSize = 10;
      this.$refs.DialogqueryMapJxsChanDigital.listQuery.startTime = this.listQuery.startTime;
      this.$refs.DialogqueryMapJxsChanDigital.listQuery.endTime = this.listQuery.endTime;
      this.$refs.DialogqueryMapJxsChanDigital.listQuery.ppwlList = this.listQuery.ppwlList;
      this.$refs.DialogqueryMapJxsChanDigital.listQuery.dealerCode = row.dealerCode;
      this.$refs.DialogqueryMapJxsChanDigital.listQuery.province = this.listQuery.province;
      this.$refs.DialogqueryMapJxsChanDigital.dialogDigitalVisible = true;
    }
  }
};
exports.default = _default;