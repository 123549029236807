"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ApplySelectList = ApplySelectList;
exports.ListformatDate = ListformatDate;
exports.PackScaleSelectList = PackScaleSelectList;
exports.accMul = accMul;
exports.flagGroupByCode = flagGroupByCode;
exports.flagGroupById = flagGroupById;
exports.formatDate = formatDate;
exports.getKeysetVal = getKeysetVal;
exports.getPackScaleListForMark = getPackScaleListForMark;
exports.getSupplierByCode = getSupplierByCode;
exports.getSysSearchDefDate = getSysSearchDefDate;
exports.normalDate = normalDate;
exports.selectMembraneFactorySupplierList = selectMembraneFactorySupplierList;
exports.setPermission = setPermission;
exports.supplierSearchByNameList = supplierSearchByNameList;
exports.tableHeight = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.array.find");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.regexp.to-string");
var _typeof2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
require("core-js/modules/es6.regexp.constructor");
require("core-js/modules/es6.regexp.replace");
var _request = _interopRequireDefault(require("@/utils/request"));
/* eslint-disable no-redeclare */
/* eslint-disable no-empty */
/* eslint-disable eqeqeq */

/**
 * 根据id 获取指定标签组信息
 * @param {*} pId
 */
function flagGroupById(pId) {
  return (0, _request.default)({
    url: '/api-mark/mark/comboBox/flagGroupById',
    method: 'get',
    params: {
      pId: pId
    },
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}

/**
 * 根据flagCode 获取指定标签组信息
 * @param {*} flagCode
 */
function flagGroupByCode(flagCode) {
  return (0, _request.default)({
    url: '/api-mark/mark/comboBox/flagGroupByCode',
    method: 'get',
    params: {
      flagCode: flagCode
    },
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}

/**
 * 包装层级描述下拉框
 */
function PackScaleSelectList(packCode) {
  return (0, _request.default)({
    url: '/api-basic/basic/comboBox/getPackScaleList',
    method: 'get',
    params: {
      packCode: packCode
    },
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}

/**
 * 包装层级描述下拉框（做了数据的过滤）
 */
function getPackScaleListForMark(packCode) {
  return (0, _request.default)({
    url: '/api-basic/basic/comboBox/getPackScaleListForMark',
    method: 'get',
    params: {
      packCode: packCode
    },
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}

/**
 * 获取膜厂供应商
 */
function selectMembraneFactorySupplierList() {
  return (0, _request.default)({
    url: '/api-basic/basic/comboBox/selectMembraneFactorySupplier',
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}

/**
 * 根据供应商CODE获取供应商信息
 * @param {*} supplierCode
 */
function getSupplierByCode(supplierCode) {
  return (0, _request.default)({
    url: '/api-basic/basic/comboBox/getSupplierByCode',
    method: 'get',
    params: {
      supplierCode: supplierCode
    },
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}

/**
 * 供应商类型下拉框
 */
function supplierSearchByNameList(supplierName) {
  return (0, _request.default)({
    url: '/api-basic/basic/comboBox/supplierSearchByName',
    method: 'get',
    params: {
      supplierName: supplierName
    },
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}

/**
 * 申请方式下拉框
 */
function ApplySelectList() {
  return {
    data: [{
      id: 1,
      name: 'rrrr'
    }],
    code: 200
  };
}
function ListformatDate(row, column) {
  var date = row[column.property];
  console.log(date);
}

/**
 * 格式化时间戳到指定格式日期
 * @param {*} date
 * @param {*} fmt
 */
function formatDate(date, fmt) {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  var o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  };
  for (var k in o) {
    var str = o[k] + '';
    if (new RegExp("(".concat(k, ")")).test(fmt)) {
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : ('00' + str).substr(str.length));
    }
  }
  return fmt;
}

/**
 * 获取指定天数起止日期
 * @param {*} com_days
 */
function getSysSearchDefDate(com_days) {
  var end = new Date();
  var start = new Date();
  var nYear = start.getFullYear();
  var days = 365;
  if (nYear % 4 == 0 && nYear % 100 != 0 || nYear % 400 == 0) {
    days = 366;
  }
  if (com_days != '' && com_days != undefined && com_days != null) {
    days = com_days;
  }
  start.setTime(start.getTime() - 3600 * 1000 * 24 * days);
  return [formatDate(start, 'yyyy-MM-dd'), formatDate(end, 'yyyy-MM-dd')];
}
function normalDate() {
  var end = new Date();
  var start = new Date();
  // var nYear = start.getFullYear()

  // var days = 365
  // if (nYear % 4 == 0 && nYear % 100 != 0 || nYear % 400 == 0) {
  //   days = 366
  // }
  // end.setTime(start.getTime() + 3600 * 1000 * 24 * days)
  return [formatDate(start, 'yyyy-MM-dd'), formatDate(end, 'yyyy-MM-dd')];
}
/**
 * 新对象改变旧对象值
 * @param {*} old_v
 * @param {*} new_v
 */
function getKeysetVal(old_v, new_v) {
  for (var i in old_v) {
    if ((0, _typeof2.default)(old_v[i]) === 'object') {
      for (var n in old_v[i]) {
        old_v[i][n] = new_v[i][n];
      }
    } else {
      if (typeof new_v[i] !== 'undefined') {
        if (new_v[i] == null) {
          new_v[i] = '';
        }
        old_v[i] = new_v[i];
      }
    }
  }
  return old_v;
}
/**
 ** 乘法函数，用来得到精确的乘法结果
 ** 说明：javascript的乘法结果会有误差，在两个浮点数相乘的时候会比较明显。这个函数返回较为精确的乘法结果。
 ** 调用：accMul(arg1,arg2)
 ** 返回值：arg1乘以 arg2的精确结果
 **/
function accMul(arg1, arg2) {
  var m = 0;
  var s1 = arg1.toString();
  var s2 = arg2.toString();
  try {
    m += s1.split('.')[1].length;
  } catch (e) {}
  try {
    m += s2.split('.')[1].length;
  } catch (e) {}
  return Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / Math.pow(10, m);
}
var tableHeight = function tableHeight() {
  // 添加判断语句防止控制台报错
  if (document.getElementsByClassName('search-condition')[0]) {
    var offTop = document.getElementsByClassName('search-condition')[0].getBoundingClientRect().top;
    var winH = document.documentElement.clientHeight || document.body.clientHeight;
    console.log(winH, offTop);
    return winH - offTop - 134;
  }
};
exports.tableHeight = tableHeight;
var setPowers = {
  GYSBSZPZ: [
  // 供应商标识组配置
  {
    resourceCode: 'GYSBSZXQ',
    view: true,
    resourceUrl: '/api/v1/api-mark/flagGroup/getSupplierInfoById',
    resourceName: '供应商标识组详情'
  }, {
    resourceCode: 'GYSBSZGX',
    view: true,
    resourceUrl: '/api/v1/api-mark/flagGroup/saveSupplierRFlag',
    resourceName: '供应商标识组更新'
  }, {
    resourceCode: 'GYSBSZJH',
    view: true,
    resourceUrl: '/api/v1/api-mark/flagGroup/isEnable',
    resourceName: '供应商标识组激活'
  }, {
    resourceCode: 'GYSBSZLBCX',
    view: true,
    resourceUrl: '/api/v1/api-mark/flagGroup/queryForSup',
    resourceName: '供应商标识组列表查询'
  }],
  URLGL: [
  // URL管理
  {
    resourceCode: 'URLCX',
    view: true,
    resourceUrl: '/api/v1/api-mark/urlInfo/queryForList',
    resourceName: 'URL查询'
  }, {
    resourceCode: 'URLTJ',
    view: true,
    resourceUrl: '/api/v1/api-mark/urlInfo/save',
    resourceName: 'URL添加'
  }, {
    resourceCode: 'URLJH',
    view: true,
    resourceUrl: '/api/v1/api-mark/urlInfo/isEnable',
    resourceName: 'URL激活'
  }, {
    resourceCode: 'URLGZ',
    view: true,
    resourceUrl: '/api/v1/api-mark/urlInfo/update',
    resourceName: 'URL更新'
  }, {
    resourceCode: 'URLSC',
    view: true,
    resourceUrl: '/api/v1/api-mark/urlInfo/remove',
    resourceName: 'URL删除'
  }],
  BSZGL: [
  // 标识组管理
  {
    resourceCode: 'BSZTJGXSC',
    view: true,
    resourceUrl: '/api/v1/api-mark/flagGroup/deleteFlag',
    resourceName: '标识组删除'
  }, {
    resourceCode: 'BSZTJGX',
    view: true,
    resourceUrl: '/api/v1/api-mark/flagGroup/updateFlag',
    resourceName: '标识组更新'
  }, {
    resourceCode: 'BSZMBXQ',
    view: true,
    resourceUrl: '/api/v1/api-mark/flagTemplate/getById',
    resourceName: '标示组模板详情'
  }, {
    resourceCode: 'BSZMBCX',
    view: true,
    resourceUrl: '/api/v1/api-mark/flagTemplate/queryForList',
    resourceName: '标示组模板查询'
  }, {
    resourceCode: 'BSZTJ',
    view: true,
    resourceUrl: '/api/v1/api-mark/flagGroup/saveFlag',
    resourceName: '标识组添加'
  }, {
    resourceCode: 'BSZLBCX',
    view: true,
    resourceUrl: '/api/v1/api-mark/flagGroup/queryForALL',
    resourceName: '标识组列表查询'
  }, {
    resourceCode: 'BSZMBGX',
    view: true,
    resourceUrl: '/api/v1/api-mark/flagTemplate/updateFlagTemplate',
    resourceName: '标示组模板更新'
  }, {
    resourceCode: 'BSZMBTJ',
    view: true,
    resourceUrl: '/api/v1/api-mark/flagTemplate/saveFlagTemplate',
    resourceName: '标示组模板添加'
  }, {
    resourceCode: 'BSZMBSC',
    view: true,
    resourceUrl: '/api/v1/api-mark/flagTemplate/delete',
    resourceName: '标示组模板更新删除'
  }],
  KHDSQGL: [
  // 客户端授权管理
  {
    resourceCode: 'SQMTJSQ',
    view: true,
    resourceUrl: '/api/v1/api-mark/clientAuth/applyAuthCode',
    resourceName: '授权码提交申请'
  }, {
    resourceCode: 'MACDZCX',
    view: true,
    resourceUrl: '/api/v1/api-mark/clientAuth/queryMac/supplierCode',
    resourceName: 'MAC地址查询'
  }, {
    resourceCode: 'BCSQM',
    view: true,
    resourceUrl: '/api/v1/api-mark/clientAuth/saveAuthCode',
    resourceName: '保存授权码'
  }, {
    resourceCode: 'KHDSQCX',
    view: true,
    resourceUrl: '/api/v1/api-mark/clientAuth/queryForList',
    resourceName: '客户端授权查询'
  }, {
    resourceCode: 'CZMACDZ',
    view: true,
    resourceUrl: '/api/v1/api-mark/clientAuth/resetMac',
    resourceName: '重置MAC地址'
  }, {
    resourceCode: 'HFSQ',
    view: true,
    resourceUrl: '/api/v1/api-mark/clientAuth/returnMac',
    resourceName: '恢复授权'
  }, {
    resourceCode: 'BDMACDZ',
    view: true,
    resourceUrl: '/api/v1/api-mark/clientAuth/saveMac',
    resourceName: '绑定MAC地址'
  }, {
    resourceCode: 'SQLSJL',
    view: true,
    resourceUrl: '/api/v1/api-mark/clientAuth/history/queryForList',
    resourceName: '历史记录'
  }, {
    resourceCode: 'SCMSQM',
    view: true,
    resourceUrl: '/api/v1/api-mark/clientAuth/createAuthCode',
    resourceName: '生成授权码'
  }, {
    resourceCode: 'JCSQ',
    view: true,
    resourceUrl: '/api/v1/api-mark/clientAuth/removeMac',
    resourceName: '解除授权'
  }],
  KHDMACSQSH: [
  // 客户端MAC授权审核
  {
    resourceCode: 'KHDMACSHXQ',
    view: true,
    resourceUrl: '/api/v1/api-mark/authCode/audit/details',
    resourceName: '客户端MAC授权审核详情'
  }, {
    resourceCode: 'KHDMACSHCX',
    view: true,
    resourceUrl: '/api/v1/api-mark/authCode/queryForStatusList',
    resourceName: '客户端MAC授权审核查询'
  }, {
    resourceCode: 'MACSHTG',
    view: true,
    resourceUrl: '/api/v1/api-mark/authCode/reviewPass',
    resourceName: 'MAC审核通过'
  }, {
    resourceCode: 'MACSHBTG',
    view: true,
    resourceUrl: '/api/v1/api-mark/authCode/reviewNoPass',
    resourceName: 'MAC审核不通过'
  }],
  KHDSQMSH: [
  // 客户端授权码审核
  {
    resourceCode: 'KHDSQMSHTG',
    view: true,
    resourceUrl: '/api/v1/api-mark/authCodeAuditing/reviewPass',
    resourceName: '客户端授权码审核通过'
  }, {
    resourceCode: 'KHDSQMSHCX',
    view: true,
    resourceUrl: '/api/v1/api-mark/authCodeAuditing/queryForList',
    resourceName: '客户端授权码审核查询'
  }, {
    resourceCode: 'KHDSQMSHBTG',
    view: true,
    resourceUrl: '/api/v1/api-mark/authCodeAuditing/reviewNoPass',
    resourceName: '客户端授权码审核不通过'
  }, {
    resourceCode: 'KHDSQMSHMX',
    view: true,
    resourceUrl: '/api/v1/api-mark/authCodeAuditing/audit/details',
    resourceName: '客户端授权码审核明细'
  }],
  BQCSMSQ: [
  // 标签厂数码申请
  {
    resourceCode: 'BQCSMSQMX',
    view: true,
    resourceUrl: '/api/v1/api-mark/mark/flag/searchDetails',
    resourceName: '标签厂数码申请明细'
  }, {
    resourceCode: 'BQCSMSQTJ',
    view: true,
    resourceUrl: '/api/v1/api-mark/mark/flag/insert',
    resourceName: '标签厂数码申请添加'
  }, {
    resourceCode: 'BQCSMSQCX',
    view: true,
    resourceUrl: '/api/v1/api-mark/mark/flag/search',
    resourceName: '标签厂数码申请查询'
  }, {
    resourceCode: 'BQCSMSQSC',
    view: true,
    resourceUrl: '/api/v1/api-mark/mark/flag/delete',
    resourceName: '标签厂数码申请删除'
  }, {
    resourceCode: 'BQCSMSQGX',
    view: true,
    resourceUrl: '/api/v1/api-mark/mark/flag/update',
    resourceName: '标签厂数码申请更新'
  }],
  GCSMSQ: [
  // 制盖厂数码申请
  {
    resourceCode: 'GCSMSQMX',
    view: true,
    resourceUrl: '/api/v1/api-mark/mark/cap/searchDetails',
    resourceName: '盖厂数码申请明细'
  }, {
    resourceCode: 'GCSMSQCX',
    view: true,
    resourceUrl: '/api/v1/api-mark/mark/cap/search',
    resourceName: '盖厂数码申请查询'
  }, {
    resourceCode: 'GCSMSQTJ',
    view: true,
    resourceUrl: '/api/v1/api-mark/mark/cap/insert',
    resourceName: '盖厂数码申请添加'
  }, {
    resourceCode: 'GCSMSQGX',
    view: true,
    resourceUrl: '/api/v1/api-mark/mark/cap/update',
    resourceName: '盖厂数码申请更新'
  }, {
    resourceCode: 'GCSMSQSC',
    view: true,
    resourceUrl: '/api/v1/api-mark/mark/cap/delete',
    resourceName: '盖厂数码申请删除'
  }],
  SHLB: [
  // 审核列表
  {
    resourceCode: 'SHSH',
    view: true,
    resourceUrl: '/api/v1/api-mark/mark/procurement/audit',
    resourceName: '首次审核'
  }, {
    resourceCode: 'SHMX',
    view: true,
    resourceUrl: '/api/v1/api-mark/mark/procurement/auditDetails',
    resourceName: '审核明细'
  }, {
    resourceCode: 'SHCX',
    view: true,
    resourceUrl: '/api/v1/api-mark/mark/procurement/search',
    resourceName: '审核列表查询'
  }],
  QRLB: [
  // 二次审核（确认列表）
  {
    resourceCode: 'QRSH',
    view: true,
    resourceUrl: '/api/v1/api-mark/mark/reconfirm/audit',
    resourceName: '确认审核'
  }, {
    resourceCode: 'QRMX',
    view: true,
    resourceUrl: '/api/v1/api-mark/mark/reconfirm/auditDetails',
    resourceName: '确认明细'
  }, {
    resourceCode: 'QRLBCX',
    view: true,
    resourceUrl: '/api/v1/api-mark/mark/reconfirm/search',
    resourceName: '确认列表查询'
  }],
  ZMSCLB: [
  // 制码生成列表(制码生成管理)
  {
    resourceCode: 'ZMSCLBCX',
    view: true,
    resourceUrl: '/api/v1/api-mark/mark/markStatus/search',
    resourceName: '制码生成列表查询'
  }, {
    resourceCode: 'CXSC',
    view: true,
    resourceUrl: '/api/v1/api-mark/mark/markStatus/generate',
    resourceName: '重新生成'
  }],
  ZMBXZLB: [
  // 制码包下载列表(制码包下载查询)
  {
    resourceCode: 'ZMBXZCX',
    view: true,
    resourceUrl: '/api/v1/api-mark/mark/down/search',
    resourceName: '制码包下载查询'
  }, {
    resourceCode: 'ZMBXZMX',
    view: true,
    resourceUrl: '/api/v1/api-mark/mark/down/searchDetails',
    resourceName: '制码包下载明细'
  }],
  SJBHCLB: [
  // 数据包回传列表(数据包回传查询)
  {
    resourceCode: 'SJBHCCX',
    view: true,
    resourceUrl: '/api/v1/api-mark/mark/markUpload/search',
    resourceName: '数据包回传查询'
  }],
  MFCGL: [
  // 码废除管理
  {
    resourceCode: 'SCPCFC',
    view: true,
    resourceUrl: '/api/v1/api-mark/numStateItem/packageOrderNumAbolish',
    resourceName: '生产批次废除'
  }, {
    resourceCode: 'DMFC',
    view: true,
    resourceUrl: '/api/v1/api-mark/numStateItem/inOrOutAbolish',
    resourceName: '多码废除'
  }, {
    resourceCode: 'AZMRWFC',
    view: true,
    resourceUrl: '/api/v1/api-mark/numStateItem/packageOrderNumAbolish',
    resourceName: '按制码任务废除'
  }, {
    resourceCode: 'DSMFC',
    view: true,
    resourceUrl: '/api/v1/api-mark/numStateItem/singleCodeAbolish',
    resourceName: '单数码废除'
  }]
};

/**
 * 根据当前用户设置操作权限
 */
function setPermission(that, setName) {
  var a = [{
    'resourceCode': 'SCPCFC',
    'resourceName': '生产批次废除',
    'parentCode': 'MFCGL',
    'resourceLevel': '4',
    'resourceUrl': '/api/v1/api-mark/numStateItem/packageOrderNumAbolish',
    'resourceType': '2',
    'resourceId': '3',
    'createTime': 1565321961000,
    'updateTime': 1565321967000,
    'creatorId': null,
    'updaterId': null,
    'pid': '200814'
  }, {
    'resourceCode': 'DMFC',
    'resourceName': '多码废除',
    'parentCode': 'MFCGL',
    'resourceLevel': '4',
    'resourceUrl': '/api/v1/api-mark/numStateItem/inOrOutAbolish',
    'resourceType': '2',
    'resourceId': '3',
    'createTime': 1565321961000,
    'updateTime': 1565321967000,
    'creatorId': null,
    'updaterId': null,
    'pid': '200812'
  }, {
    'resourceCode': 'AZMRWFC',
    'resourceName': '按制码任务废除',
    'parentCode': 'MFCGL',
    'resourceLevel': '4',
    'resourceUrl': '/api/v1/api-mark/numStateItem/packageOrderNumAbolish',
    'resourceType': '2',
    'resourceId': '3',
    'createTime': 1565321961000,
    'updateTime': 1565321967000,
    'creatorId': null,
    'updaterId': null,
    'pid': '200813'
  }, {
    'resourceCode': 'DSMFC',
    'resourceName': '单数码废除',
    'parentCode': 'MFCGL',
    'resourceLevel': '4',
    'resourceUrl': '/api/v1/api-mark/numStateItem/singleCodeAbolish',
    'resourceType': '2',
    'resourceId': '3',
    'createTime': 1565321961000,
    'updateTime': 1565321967000,
    'creatorId': null,
    'updaterId': null,
    'pid': '200811'
  }];
  var b = [];
  for (var i in a) {
    b.push({
      resourceCode: a[i].resourceCode,
      view: true,
      resourceUrl: a[i].resourceUrl,
      resourceName: a[i].resourceName
    });
  }
  // console.log(b);
  // return true;

  var name = that.$route.path.split('=');
  // console.log(name);
  name = setName;
  var powers = typeof setPowers[setName] === 'undefined' ? false : setPowers[setName];
  if (powers == false) return powers;
  var permission = JSON.parse(sessionStorage.getItem('authList'));
  permission.find(function (e) {
    if (e.name == name) {
      e.value.find(function (v, index) {
        powers.find(function (pv, pindex) {
          if (pv.resourceCode == v.resourceCode) {
            powers[pindex].view = true;
          }
        });
      });
    }
  });
  var new_powers = {};
  for (var i in powers) {
    new_powers[powers[i].resourceCode] = {
      view: powers[i].view,
      resourceUrl: powers[i].resourceUrl
    };
  }
  powers = '';
  return new_powers;
}