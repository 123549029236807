"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _awardFailureList = require("@/api/operationalTools/awardFailureList");
var _activityReport = require("@/api/marketingStatic/activityReport");
var _activityFlow = require("@/api/marketingManage/activityFlow");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var cachName = 'marketingStaticactivityReportoperationalToolsawardFailureListindex';
var _default = {
  name: 'MarketingStaticactivityReportoperationalToolsawardFailureListindex',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      activeList: [],
      params: {
        pageNo: 1,
        pageSize: 10
      },
      total: 10,
      formInline: {
        schemeCode: null,
        // 方案名称
        drawEndTime: null,
        // 抽奖结束时间
        drawStartTime: null,
        // 抽奖开始时间
        keyword: null,
        // 用户id/数码
        sendEndTime: null,
        // 发奖结束时间
        sendStartTime: null // 发奖开始时间
      },

      rangeTime1: '',
      rangeTime2: '',
      downLoading: false,
      strategyIds: '',
      listLoading: false,
      tableData: [],
      tabHeight: '100%'
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
    that.getactivelist();
  },
  activated: function activated() {
    if (this.$route.params && this.$route.params.mchBillNo) {
      this.formInline.mchBillNo = this.$route.params.mchBillNo;
    }
    var hasCache1 = this.tableData.length > 0;
    if (this.formInline.mchBillNo || !hasCache1) {
      this.sendFailRecord();
    }
  },
  deactivated: function deactivated() {
    var name = cachName;
    var cache = this.getCaches(this, name);
    if (!cache) {
      this.tableData = [];
      this.total = 0;
    }
  },
  methods: {
    getactivelist: function getactivelist() {
      var that = this;
      (0, _activityFlow.getwayList)({
        pageSize: 100,
        pageNo: 1
      }).then(function (response) {
        console.log(response.data);
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
        } else {
          that.activeList = response.data.data.records || [];
        }
      });
    },
    // 表格
    sendFailRecord: function sendFailRecord() {
      var that = this;
      that.listLoading = true;
      var submitData = (0, _utils.filterKeyNull)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.params), this.formInline));
      submitData = JSON.parse(JSON.stringify(submitData));
      (0, _activityReport.sendFailRecord)(submitData).then(function (res) {
        if (res.data.data.errCode) {
          that.$message.error(res.data.data.errMsg);
          that.listLoading = false;
          return;
        } else {
          if (res.data.data != null) {
            that.total = parseInt(res.data.data.total);
            that.tableData = res.data.data.records;
            setTimeout(function () {
              that.listLoading = false;
            }, 1 * 800);
          } else {
            that.total = 0;
            that.tableData = [];
            that.listLoading = false;
          }
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    // 重发
    restSendPrize: function restSendPrize(id) {
      var that = this;
      (0, _activityReport.doReissuePrize)({
        idList: id
      }).then(function (res) {
        if (res.data.data.errCode) {
          return that.$message.error(res.data.data.errMsg);
        }
        that.$message.success('您选择的记录已成功加入补发队列，请稍后查看补发结果');
        that.sendFailRecord();
      }).catch(function (err) {
        console.log(err);
      });
    },
    // 时间选择
    sj1: function sj1(res) {
      if (res) {
        this.formInline.startTime = res[0];
        this.formInline.endTime = res[1];
      } else {
        this.formInline.startTime = '';
        this.formInline.endTime = '';
      }
    },
    sj2: function sj2(res) {
      if (res) {
        this.formInline.drawStartTime = res[0];
        this.formInline.drawEndTime = res[1];
      } else {
        this.formInline.drawStartTime = '';
        this.formInline.drawEndTime = '';
      }
    },
    // 搜索
    search: function search() {
      this.params.pageNo = 1;
      this.sendFailRecord();
    },
    // 重置搜索框
    reset: function reset() {
      this.rangeTime1 = '';
      this.rangeTime2 = '';
      this.params.pageNo = 1;
      this.formInline = {
        schemeCode: null,
        drawEndTime: null,
        // 抽奖结束时间
        drawStartTime: null,
        // 抽奖开始时间
        keyword: null,
        // 用户id/数码
        sendEndTime: null,
        // 发奖结束时间
        sendStartTime: null // 发奖开始时间
      };

      this.$refs['formInline'].resetFields();
      this.sendFailRecord();
    },
    // 导出
    download: function download() {
      var _this = this;
      this.downLoading = true;
      var submitData = (0, _utils.filterKeyNull)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.params), this.formInline));
      submitData = JSON.parse(JSON.stringify(submitData));
      (0, _activityReport.exportSendFail)(submitData).then(function (res) {
        _this.downLoading = false;
        if (res.status != 200) {
          _this.$message.error(res.data.msg);
        } else {
          var blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel;charset=utf-8'
          });
          var downloadElement = document.createElement('a');
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = '奖品发放失败.xlsx';
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
      }).catch(function () {
        _this.downLoading = false;
      });
    },
    // 复选框选中事件
    handleSelectionChange: function handleSelectionChange(data) {
      var arr = data.map(function (item) {
        return item.id;
      });
      this.strategyIds = arr;
    },
    // 全部补发
    allReplacement: function allReplacement() {
      var _this2 = this;
      if (this.strategyIds == '') {
        return this.$message.info('请选择补发项');
      }
      this.$confirm("\u662F\u5426".concat(this.strategyIds == '' ? '全部补发' : '补发选中', "?"), {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this2.restSendPrize(_this2.strategyIds);
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    // 重发
    handleClick: function handleClick(row) {
      var _this3 = this;
      this.$confirm("\u662F\u5426\u91CD\u53D1?", {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this3.restSendPrize([row.id]);
      }).catch(function () {
        _this3.$message({
          type: 'info',
          message: '已取消'
        });
      });
    }
  }
};
exports.default = _default;