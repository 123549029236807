"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _lithService = require("@/api/lithService");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _basic = require("@/api/mark/basic");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'ErrCodesLog',
  components: {
    Pagination: _Pagination.default
  },
  filters: {
    formatDate: function formatDate(time) {
      var date = new Date(time);
      return (0, _basic.formatDate)(date, 'yyyy-MM-dd hh:mm:ss');
    }
  },
  data: function data() {
    return {
      searchForm: {
        current: 1,
        size: 20,
        otherTradeBillQuery: {
          templateName: '',
          endTime: '',
          batchNo: '',
          newMark: '',
          oldMark: '',
          startTime: ''
        }
      },
      total: 0,
      tableData: [],
      tableKey: '01004',
      tabHeight: '100%',
      listLoading: false,
      expandTxt: '展开',
      showCol: false,
      downLoading: false
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  activated: function activated() {
    this.getList();
  },
  methods: {
    DoSearch: function DoSearch() {
      // if ((this.searchForm.endTime && this.searchForm.startTime) || (!this.searchForm.endTime && !this.searchForm.startTime)) {
      this.getList();
      this.searchForm.current = 1;
      // } else {
      //   this.$message.error('起始时间和结束时间必须同时存在')
      // }
    },
    resetForm: function resetForm(formName) {
      var that = this;
      that.$refs['searchForm'].resetFields();
      that.searchForm = {
        current: 1,
        size: 20,
        otherTradeBillQuery: {
          templateName: '',
          endTime: '',
          batchNo: '',
          newMark: '',
          oldMark: '',
          startTime: ''
        }
      };
      this.getList();
    },
    getList: function getList() {
      var _this = this;
      this.authId('searchbtn', this.$route.meta.authList);
      var that = this;
      if (that.searchForm.startTime && !that.searchForm.endTime) {
        this.$message.error('请选择结束时间');
        return;
      }
      if (!that.searchForm.startTime && that.searchForm.endTime) {
        this.$message.error('请选择起始时间');
        return;
      }
      that.listLoading = true;
      var current = that.searchForm.current;
      var size = that.searchForm.size;
      var otherTradeBillQuery = that.searchForm.otherTradeBillQuery;
      (0, _lithService.selectReplacePage)(current, size, otherTradeBillQuery).then(function (response) {
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null || response.data.data != '') {
            that.tableData = response.data.data.records;
            that.total = parseInt(response.data.data.total);
            setTimeout(function () {
              that.listLoading = false;
            }, 1 * 500);
          } else {
            that.tableData = [];
            that.total = 0;
          }
        }
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    // 导出功能
    downLoad: function downLoad() {
      var _this2 = this;
      this.authId('exportbtn', this.$route.meta.authList);
      this.downLoading = true;
      var param = (0, _utils.deepClone)(this.searchForm);
      param.current = 1;
      param.size = this.total;
      console.log(param);
      exportErrLogData(param).then(function (res) {
        _this2.authId('searchbtn', _this2.$route.meta.authList);
        if (res.status != 200) {
          _this2.$message.error(res.data.msg);
        } else {
          var blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel'
          });
          var downloadElement = document.createElement('a');
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = '异常数码.xlsx';
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
        _this2.downLoading = false;
      }).catch(function () {
        _this2.authId('searchbtn', _this2.$route.meta.authList);
        _this2.downLoading = false;
      });
    },
    // 展开收起搜索条件
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    }
  }
};
exports.default = _default;