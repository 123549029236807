"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _product = require("@/api/basic/product");
var _index = require("@/api/fake_expel/brand/index");
var _waves = _interopRequireDefault(require("@/directive/waves"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  directives: {
    waves: _waves.default
  },
  data: function data() {
    return {
      formData: {
        pageNo: 1,
        pageSize: 10,
        timeType: '1',
        ppwlList: ''
      },
      brandData: '',
      brand: null,
      optBrand: '',
      showSelectBrand: null,
      SelectBrand: [{
        pid: -1,
        brandName: '全部'
      }],
      brandList: [],
      proList: []
    };
  },
  computed: {},
  mounted: function mounted() {
    this.authId('searchbtn', this.$route.meta.authList);
    this.getBrand();
  },
  methods: {
    getBrand: function getBrand() {
      var _this = this;
      (0, _product.productBrandAllListList)().then(function (res) {
        if (res.data.code != 200) {
          _this.$message.error(res.data.msg);
          return;
        } else {
          if (res.data.data != null) {
            _this.brandList = res.data.data;
          } else {
            _this.brandList = [];
          }
        }
      });
    },
    handleFilter: function handleFilter(_type) {
      if (_type == 'rest') {
        // 搜索表单重置
        this.$refs.searchForm.resetFields();
        this.optBrand = '';
        this.brandData = '';
      }
      var lists = [];
      if ((this.optBrand == '' || this.optBrand == null) && this.brandData != '') {
        for (var i in this.proList) {
          lists.push(this.proList[i].materialCode);
        }
      } else {
        for (var obj in this.optBrand) {
          lists.push(this.optBrand[obj]);
        }
      }
      if (this.brandData == '-1') {
        this.optBrand = '';
        lists = [];
      }
      this.formData.pageNo = 1;
      this.formData.ppwlList = lists.join(',');
      this.$parent.searchList(this.formData);
    },
    getqueryBrand: function getqueryBrand() {
      var _this2 = this;
      this.loading = true;
      (0, _index.queryBrand)({}).then(function (response) {
        var data = response.data;
        if (data.code != 200) {
          _this2.$message.error(data.msg);
          return true;
        }
        _this2.loading = false;
        for (var i in data.data) {
          _this2.SelectBrand.push({
            pid: data.data[i].pid,
            brandName: data.data[i].brandName
          });
        }
      });
    },
    getqueryMaterial: function getqueryMaterial() {
      var _this3 = this;
      // this.loading = true
      // if (
      //   this.brandData.pId == '-1' ||
      //   this.brandData.pId == '' ||
      //   this.brandData.pId == null
      // ) {
      //   this.brandList = null
      //   this.optBrand = ''
      //   return false
      // }
      // queryMaterial(this.brandData).then(response => {
      //   var data = response.data
      //   this.brandList = []
      //   if (data.code != 200) {
      //     this.$message.error(data.msg)
      //     return true
      //   }
      //   this.loading = false
      //   if (data.data.length <= 0) {
      //     this.$message.error('当前选择品牌下不存在物料信息')
      //     return true
      //   }
      //   this.brandList = data.data
      // })
      this.loading = true;
      this.selProList = [];
      if (this.brandData == '-1' || this.brandData == '' || this.brandData == null) {
        this.proList = [];
        return false;
      }
      (0, _index.queryMaterial)({
        pId: this.brandData
      }).then(function (response) {
        var data = response.data;
        _this3.proList = [];
        if (data.code != 200) {
          _this3.$message.error(data.msg);
          return true;
        }
        _this3.loading = false;
        if (data.data.length <= 0) {
          _this3.$message.error('当前选择品牌下不存在产品信息');
          return true;
        }
        _this3.proList = data.data;
      }).catch(function () {
        _this3.proList = [];
        _this3.loading = false;
      });
    }
  }
};
exports.default = _default;