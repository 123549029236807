var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "hyyy-container" }, [
    _c(
      "div",
      { staticClass: "hyyy-content" },
      [
        _c(
          "el-row",
          { staticStyle: { "padding-top": "20px" } },
          [
            _c(
              "el-col",
              [
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: {
                          "margin-bottom": "20px",
                          "margin-left": "5%",
                        },
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.routeTo(0)
                          },
                        },
                      },
                      [_vm._v("添 加 规 则")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "90%", margin: "0 auto" },
                        attrs: {
                          data: _vm.list,
                          border: "",
                          "header-cell-style": {
                            "background-color": "#fafafa",
                            color: "rgba(0, 0, 0,0.85)",
                            "font-weight": "500",
                            "text-align": "center",
                          },
                          "cell-style": { "text-align": "center" },
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: { prop: "name", label: "规则名称" },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { prop: "amount", label: "扫码得积分值" },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { prop: "statuse", label: "状态" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          scope.row.statuse === "1"
                                            ? "开启"
                                            : " 关闭"
                                        ) +
                                        "\n                "
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "address",
                            label: "操作",
                            align: "center",
                            width: "340",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { margin: "5px" },
                                      attrs: { type: "primary", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.routeTo(2, scope.row.id)
                                        },
                                      },
                                    },
                                    [_vm._v("详情")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { margin: "5px" },
                                      attrs: { type: "primary", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.routeTo(1, scope.row.id)
                                        },
                                      },
                                    },
                                    [_vm._v("编辑")]
                                  ),
                                  _vm._v(" "),
                                  scope.row.statuse === "2"
                                    ? _c(
                                        "el-button",
                                        {
                                          staticStyle: { margin: "5px" },
                                          attrs: {
                                            type: "primary",
                                            size: "mini",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.switchFun(
                                                "1",
                                                scope.row.id
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("开启")]
                                      )
                                    : _c(
                                        "el-button",
                                        {
                                          staticStyle: { margin: "5px" },
                                          attrs: {
                                            type: "primary",
                                            size: "mini",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.switchFun(
                                                "2",
                                                scope.row.id
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("关闭")]
                                      ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { margin: "5px" },
                                      attrs: { type: "primary", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteFun(scope.row.id)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "content-pagess" },
                      [
                        _c("Pagination", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.total > 0,
                              expression: "total > 0",
                            },
                          ],
                          attrs: {
                            total: _vm.total,
                            page: _vm.pageForm.pageNo,
                            limit: _vm.pageForm.pageSize,
                          },
                          on: {
                            "update:page": function ($event) {
                              return _vm.$set(_vm.pageForm, "pageNo", $event)
                            },
                            "update:limit": function ($event) {
                              return _vm.$set(_vm.pageForm, "pageSize", $event)
                            },
                            pagination: _vm.pagingFun,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }