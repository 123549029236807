var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-analyze-wrap" }, [
    _c(
      "div",
      { staticClass: "user-analyze-content", staticStyle: { padding: "0" } },
      [
        _c(
          "el-row",
          { attrs: { type: "flex", justify: "space-between" } },
          [
            _c(
              "el-col",
              { staticStyle: { "text-align": "center" }, attrs: { span: 3 } },
              [
                _c(
                  "h4",
                  { staticStyle: { "font-weight": "400", color: "#333" } },
                  [
                    _vm._v("\n          积分明细\n          "),
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "bottom-start",
                          transition: "zoom-in-top",
                          width: "400",
                          trigger: "hover",
                        },
                      },
                      [
                        _c("div", [
                          _c("p", [
                            _vm._v(
                              "\n                参与用户数：在时间范围内使用积分商城的用户总数。\n              "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "用户积分消耗：在时间范围内的所有用户消耗的积分数量总和。"
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "积分订单数：在时间范围内的所有的订单数量总和，包含所有待支付、待发货、待收货、已完成的订单。"
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "礼品兑换量：在时间范围内的所有用户成功兑换的礼品数量总和。"
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "支付金额：在时间范围内所有用户支付的金额总和。"
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "已兑换商品数：在时间范围内所有已完成兑换的商品的数量总和。注意：同一个商品购买多次，兑换的商品数为1。"
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("i", {
                          staticClass: "el-icon-question",
                          staticStyle: {
                            "font-size": "20px",
                            color: "#e6a23c",
                          },
                          attrs: { slot: "reference" },
                          slot: "reference",
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c("el-col", { attrs: { span: 6 } }, [_c("admincut")], 1),
          ],
          1
        ),
        _vm._v(" "),
        _c("el-divider", { staticStyle: { margin: "0" } }),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { width: "100%", height: "100%", padding: "20px" } },
          [
            _c("div", { staticClass: "count-wrap" }, [
              _c("div", { staticClass: "time-wrap" }, [
                _vm.isFansContrastDate
                  ? _c(
                      "div",
                      { staticClass: "time-top" },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "daterange",
                            align: "right",
                            "unlink-panels": "",
                            "range-separator": "-",
                            size: "small",
                            "value-format": "yyyy-MM-dd",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                          },
                          model: {
                            value: _vm.fansDate,
                            callback: function ($$v) {
                              _vm.fansDate = $$v
                            },
                            expression: "fansDate",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: { click: _vm.latelyFun },
                          },
                          [_vm._v("最近30天")]
                        ),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      { staticClass: "time-bottom" },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "daterange",
                            align: "right",
                            "unlink-panels": "",
                            size: "small",
                            "range-separator": "-",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            "value-format": "yyyy-MM-dd",
                          },
                          model: {
                            value: _vm.fansDate,
                            callback: function ($$v) {
                              _vm.fansDate = $$v
                            },
                            expression: "fansDate",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v("  —  ")]),
                        _vm._v(" "),
                        _c("el-date-picker", {
                          attrs: {
                            type: "daterange",
                            align: "right",
                            "unlink-panels": "",
                            size: "small",
                            "range-separator": "-",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            "value-format": "yyyy-MM-dd",
                          },
                          model: {
                            value: _vm.fansContrastDate,
                            callback: function ($$v) {
                              _vm.fansContrastDate = $$v
                            },
                            expression: "fansContrastDate",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.isFansContrastFun(false)
                              },
                            },
                          },
                          [_vm._v("取消对比")]
                        ),
                      ],
                      1
                    ),
              ]),
              _vm._v(" "),
              _vm.isFansContrastDate
                ? _c("div", { staticClass: "count-list" }, [
                    _c("ul", [
                      _c("li", [
                        _c("span", [_vm._v("参与用户数")]),
                        _vm._v(" "),
                        _c("strong", [
                          _vm._v(_vm._s(_vm.totalObj.fansCount || 0)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [_vm._v("用户积分消耗")]),
                        _vm._v(" "),
                        _c("strong", [
                          _vm._v(_vm._s(_vm.totalObj.newFansCount || 0)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [_vm._v("积分订单数")]),
                        _vm._v(" "),
                        _c("strong", [
                          _vm._v(_vm._s(_vm.totalObj.cancelFansCount || 0)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [_vm._v("礼品兑换量")]),
                        _vm._v(" "),
                        _c("strong", [
                          _vm._v(
                            _vm._s(_vm.totalObj.netIncreaseFansCount || 0)
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [_vm._v("支付金额")]),
                        _vm._v(" "),
                        _c("strong", [
                          _vm._v(_vm._s(_vm.totalObj.activeUser || 0)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [_vm._v("已兑换商品数")]),
                        _vm._v(" "),
                        _c("strong", [
                          _vm._v(_vm._s(_vm.totalObj.activeRate || 0)),
                        ]),
                      ]),
                    ]),
                  ])
                : _c(
                    "div",
                    { staticClass: "count-table" },
                    [
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { data: _vm.totalList, border: "" },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "date",
                              label: "日期",
                              width: "200",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { prop: "fansCount", label: "粉丝总数" },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "newFansCount",
                              label: "新关注粉丝数",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "cancelFansCount",
                              label: "取消关注粉丝数",
                              width: "120",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "netIncreaseFansCount",
                              label: "净增粉丝数",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { prop: "activeUser", label: "活跃用户数" },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { prop: "activeRate", label: "活跃率" },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { prop: "enableCount", label: "互动次数" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "line-chart-wrap" }, [
              _c(
                "ul",
                { staticClass: "line-chart-btns clearfix" },
                _vm._l(_vm.lineList, function (item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      class: { active: _vm.btnClass === item.id },
                      on: {
                        click: function ($event) {
                          return _vm.linefun(item.id)
                        },
                      },
                    },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "line-chart" },
                [
                  _c("Line-chart", {
                    attrs: { "chart-data": _vm.lineChartData },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "down-table-wrap" },
              [
                _c(
                  "div",
                  { staticClass: "fans-down" },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "daterange",
                        align: "right",
                        "unlink-panels": "",
                        "range-separator": "-",
                        "start-placeholder": "开始日期",
                        "value-format": "yyyy-MM-dd",
                        "end-placeholder": "结束日期",
                        size: "small",
                      },
                      model: {
                        value: _vm.fansTableDate,
                        callback: function ($$v) {
                          _vm.fansTableDate = $$v
                        },
                        expression: "fansTableDate",
                      },
                    }),
                    _vm._v(" "),
                    _vm.records.length > 0
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.downLoad },
                          },
                          [_vm._v("下载明细")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.userDataDownFun },
                          },
                          [_vm._v("下载明细")]
                        ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.records },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { label: "日期", align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm._f("filterTime")(scope.row.createDate)
                                    ) +
                                    "\n              "
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "fansCount",
                        label: "粉丝总数",
                        align: "center",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "newFansCount",
                        label: "新关注粉丝数",
                        align: "center",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "cancelFansCount",
                        label: "取消关注粉丝数",
                        align: "center",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "netIncreaseFansCount",
                        label: "净增粉丝数",
                        align: "center",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "activeUser",
                        label: "活跃用户数",
                        align: "center",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "activeRate",
                        label: "活跃率",
                        align: "center",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "enableCount",
                        label: "互动次数",
                        align: "center",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.records.length > 0
                  ? _c(
                      "div",
                      { staticClass: "pages-wrap" },
                      [
                        _c("el-pagination", {
                          attrs: {
                            "current-page": _vm.current,
                            "page-sizes": _vm.pageSizes,
                            "page-size": _vm.pageSize,
                            layout: "prev, pager, next,sizes, total,jumper",
                            total: _vm.total,
                          },
                          on: {
                            "size-change": _vm.handleSizeChange,
                            "current-change": _vm.handleCurrentChange,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }