"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _BCganged = require("@/api/terminal/BCganged");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  // zdInfo, integralExchangeList, jd
  data: function data() {
    return {
      queryParams: {
        scanCode: '',
        // productCodeAndName: '',
        productCode: '',
        productName: '',
        awardType: '',
        activityCodeAndName: '',
        consumerId: '',
        consumerNickName: '',
        rangeTime: '',
        startTime: '',
        endTime: '',
        pageNo: 1,
        pageSize: 10
      },
      total: 0,
      listLoading: false,
      tableData: [],
      tabHeight: 'calc(100vh - 350px)',
      downLoading: false
    };
  },
  computed: {},
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.tabHeight = (0, _utils.tableHeight)();
    });
  },
  activated: function activated() {
    var hasCache1 = this.tableData.length > 0;
    if (!hasCache1) {
      this.getList();
    }
  },
  methods: {
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      if (this.queryParams.rangeTime && this.queryParams.rangeTime.length > 0) {
        this.queryParams.startTime = this.queryParams.rangeTime[0];
        this.queryParams.endTime = this.queryParams.rangeTime[1];
      } else {
        this.queryParams.startTime = '';
        this.queryParams.endTime = '';
      }
      var sdata = JSON.parse(JSON.stringify(this.queryParams));
      delete sdata.rangeTime;
      (0, _BCganged.postrebateDetail)(sdata).then(function (res) {
        console.log(res.data);
        if (res.data.code == '200') {
          if (!res.data.data.errCode) {
            _this2.tableData = res.data.data.data.records;
            _this2.total = res.data.data.data.total;
            _this2.listLoading = false;
          } else {
            _this2.listLoading = false;
            _this2.$message.error(res.data.data.errMsg);
            return;
          }
        } else {
          _this2.listLoading = false;
          _this2.$message.error(res.data.msg);
          return;
        }
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    resetForm: function resetForm() {
      this.queryParams = {
        scanCode: '',
        // productCodeAndName: '',
        productCode: '',
        productName: '',
        awardType: '',
        activityCodeAndName: '',
        consumerId: '',
        consumerNickName: '',
        rangeTime: '',
        startTime: '',
        endTime: '',
        pageNo: 1,
        pageSize: 10
      };
      this.getList();
    },
    handleShowFun: function handleShowFun() {},
    handleclick: function handleclick() {},
    // 导出
    exportExcel: function exportExcel() {
      var _this3 = this;
      this.downLoading = true;
      if (this.queryParams.rangeTime && this.queryParams.rangeTime.length > 0) {
        this.queryParams.startTime = this.queryParams.rangeTime[0];
        this.queryParams.endTime = this.queryParams.rangeTime[1];
      } else {
        this.queryParams.startTime = '';
        this.queryParams.endTime = '';
      }
      var sdata = JSON.parse(JSON.stringify(this.queryParams));
      delete sdata.rangeTime;
      (0, _BCganged.getExcel)(sdata).then(function (res) {
        _this3.downLoading = false;
        if (res.status !== 200) {
          _this3.$message.error(res.statusText);
          return;
        } else {
          if (res.data) {
            _this3.$message.success('导出成功！');
            var blob = new Blob([res.data], {
              type: 'application/vnd.ms-excel;charset=utf-8'
            });
            var downloadElement = document.createElement('a');
            var href = window.URL.createObjectURL(blob);
            downloadElement.href = href;
            downloadElement.download = '返利明细.xlsx';
            document.body.appendChild(downloadElement);
            downloadElement.click();
            document.body.removeChild(downloadElement);
            window.URL.revokeObjectURL(href);
          }
        }
      }).catch(function () {
        _this3.downLoading = false;
      });
    }
  }
};
exports.default = _default;