"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DoShlist = DoShlist;
exports.addTZD = addTZD;
exports.delTZD = delTZD;
exports.editTZD = editTZD;
exports.exportData = exportData;
exports.getKcTzDetail = getKcTzDetail;
exports.getKcTzPlist = getKcTzPlist;
exports.getQueryOrg = getQueryOrg;
exports.getRkList = getRkList;
exports.insertAndAuditStockAdjustment = insertAndAuditStockAdjustment;
exports.upLoadImg = upLoadImg;
var _request = _interopRequireDefault(require("@/utils/request"));
// 获取组织
function getQueryOrg() {
  var data = {
    userId: '',
    orgCodeAndName: '',
    orgStatus: 1
  };
  return (0, _request.default)({
    url: '/api-basic/orgInfo/orgInfoAll',
    method: 'post',
    data: data
  });
}

// 获取调整单列表
function getRkList(data) {
  return (0, _request.default)({
    url: '/api-channel/mtStockAdjustmentOrder/stockAdjustmentList',
    method: 'post',
    data: data
  });
}

// 库存调整导出
function exportData(data) {
  return (0, _request.default)({
    url: '/api-channel/mtStockAdjustmentOrder/exportExcel',
    responseType: 'blob',
    method: 'post',
    data: data
  });
}

// 图片上传
function upLoadImg(data) {
  return (0, _request.default)({
    url: '/api-basic/jc/file/upload/uploadImg',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    method: 'post',
    data: data
  });
}

// 添加
function addTZD(data) {
  return (0, _request.default)({
    url: '/api-channel/mtStockAdjustmentOrder/insertStockAdjustment',
    method: 'post',
    data: data
  });
}

// 修改
function editTZD(data) {
  return (0, _request.default)({
    url: '/api-channel/mtStockAdjustmentOrder/updateStockAdjustment',
    method: 'post',
    data: data
  });
}

// 删除
function delTZD(param) {
  return (0, _request.default)({
    url: '/api-channel/mtStockAdjustmentOrder/deleteStockAdjustment',
    method: 'get',
    params: param
  });
}
// 详情
function getKcTzDetail(param) {
  return (0, _request.default)({
    url: '/api-channel/mtStockAdjustmentOrder/findAdjustmentById',
    method: 'get',
    params: param
  });
}

// 获取库存列表
function getKcTzPlist(data) {
  return (0, _request.default)({
    url: '/api-channel/stockJxProduct/selectJxStockByIds',
    method: 'post',
    data: data
  });
}

// 审核库存调整单
function DoShlist(data) {
  return (0, _request.default)({
    url: '/api-channel/mtStockAdjustmentOrder/auditStockAdjustment',
    method: 'post',
    data: data
  });
}
function insertAndAuditStockAdjustment(data) {
  return (0, _request.default)({
    url: '/api-channel/mtStockAdjustmentOrder/insertAndAuditStockAdjustment',
    method: 'post',
    data: data
  });
}