"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _flow = require("@/api/warehouse/flow");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'table-packset',
  components: {
    Pagination: _Pagination.default
  },
  props: {
    num: {
      type: String,
      default: function _default() {
        return '';
      }
    }
  },
  data: function data() {
    return {
      active: 0,
      flowList: []
    };
  },
  watch: {
    num: {
      handler: function handler(val, oldval) {
        this.getList(val);
      }
    }
  },
  mounted: function mounted() {
    var that = this;
    this.getList(this.num);
  },
  methods: {
    getList: function getList(num) {
      var _this = this;
      console.log('日志', num);
      var that = this;
      (0, _flow.getFlowList)(num).then(function (res) {
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
        } else {
          _this.flowList = res.data.data;
          _this.flowList.forEach(function (v, index) {
            that.flowList[index]['userInfo'] = String(v.userName) + v.dateCreate;
            if (v.done == 1) {
              that.active = index;
            }
          });
        }
      });
    }
  }
};
exports.default = _default2;