"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ImportBlacklist = ImportBlacklist;
exports.activityRoleUserEdit = activityRoleUserEdit;
exports.activityRoleUserPage = activityRoleUserPage;
exports.detailUpdate = detailUpdate;
exports.getBlacklist = getBlacklist;
exports.getDetails = getDetails;
exports.getLimited = getLimited;
exports.getUserExportExcel = getUserExportExcel;
exports.getUserPage = getUserPage;
exports.getUserReferenceRecordPage = getUserReferenceRecordPage;
exports.yxRoleadd = yxRoleadd;
exports.yxRoleeditEnable = yxRoleeditEnable;
exports.yxRolelist = yxRolelist;
var _request = _interopRequireDefault(require("@/utils/request"));
var base = '';

// 用户分页查询
function getUserPage(params) {
  return (0, _request.default)({
    url: base + '/market-config/activityWechatUser/userPage',
    method: 'get',
    params: params
  });
}

// 用户查询导出
function getUserExportExcel(params) {
  return (0, _request.default)({
    url: base + '/market-config/activityWechatUser/userExportExcel',
    method: 'get',
    responseType: 'blob',
    params: params
  });
}
// 用户基础信息修改
function detailUpdate(data) {
  return (0, _request.default)({
    url: base + '/market-config/activityWechatUser/detailUpdate',
    method: 'post',
    data: data
  });
}
// 用户加入黑名单
function getBlacklist(params) {
  return (0, _request.default)({
    url: base + '/market-config/activityWechatUser/blacklist',
    method: 'get',
    params: params
  });
}
// 限制用户中奖次数
function getLimited(params) {
  return (0, _request.default)({
    url: base + '/market-config/activityWechatUser/limited',
    method: 'get',
    params: params
  });
}
// 用户详情
function getDetails(params) {
  return (0, _request.default)({
    url: base + '/market-config/activityWechatUser/details',
    method: 'get',
    params: params
  });
}
// 用户导入
function ImportBlacklist(file) {
  return (0, _request.default)({
    url: base + '/market-config/activityWechatUser/importBlacklist',
    method: 'post',
    file: file
  });
}
// 用户详情 表格
function getUserReferenceRecordPage(params) {
  return (0, _request.default)({
    url: base + '/market-config/activityWechatUser/userReferenceRecordPage',
    method: 'get',
    params: params
  });
}

// 活动角色列表
function yxRolelist(data) {
  return (0, _request.default)({
    url: base + '/market-config/yxRole/list',
    method: 'post',
    data: data
  });
}
// 活动角色编辑添加
function yxRoleadd(data) {
  return (0, _request.default)({
    url: base + '/market-config/yxRole/add',
    method: 'post',
    data: data
  });
}
// 活动角色批量禁用启用
function yxRoleeditEnable(data) {
  return (0, _request.default)({
    url: base + '/market-config/yxRole/editEnable',
    method: 'post',
    data: data
  });
}

// 活动角色用户列表
function activityRoleUserPage(params) {
  return (0, _request.default)({
    url: base + '/market-config/activityWechatUser/activityRoleUserPage',
    method: 'get',
    params: params
  });
}

// 活动角色用户编辑
function activityRoleUserEdit(data) {
  return (0, _request.default)({
    url: base + '/market-config/activityWechatUser/activityRoleUserEdit',
    method: 'post',
    data: data
  });
}