var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "page-container" },
    [
      _c("div", { staticClass: "page-section" }, [
        _c("h3", { staticClass: "h-title" }, [_vm._v("终端明积分管理")]),
        _vm._v(" "),
        _c(
          "section",
          [
            _c(
              "el-form",
              {
                ref: "searchForm",
                staticClass: "search-condition",
                attrs: {
                  model: _vm.queryParams,
                  "status-icon": "",
                  "label-suffix": ":",
                  "label-position": _vm.formConfig.labelPosition,
                  "label-width": _vm.formConfig.labelWidth,
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "cols" },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "参与数码", prop: "markCode" },
                              },
                              [
                                _c("el-input", {
                                  staticClass: "filter-item",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    maxlength: "50",
                                    clearable: "",
                                    placeholder: "请输入",
                                  },
                                  model: {
                                    value: _vm.queryParams.markCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.queryParams, "markCode", $$v)
                                    },
                                    expression: "queryParams.markCode",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "活动名称",
                                  prop: "activityName",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticClass: "filter-item",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    maxlength: "50",
                                    clearable: "",
                                    placeholder: "请输入",
                                  },
                                  model: {
                                    value: _vm.queryParams.activityName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryParams,
                                        "activityName",
                                        $$v
                                      )
                                    },
                                    expression: "queryParams.activityName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "奖品类型", prop: "prizeType" },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "请选择" },
                                    model: {
                                      value: _vm.queryParams.prizeType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryParams,
                                          "prizeType",
                                          $$v
                                        )
                                      },
                                      expression: "queryParams.prizeType",
                                    },
                                  },
                                  _vm._l(
                                    _vm.prizeTypes,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.name,
                                          value: item.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "领取状态", prop: "status" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "请选择" },
                                    model: {
                                      value: _vm.queryParams.status,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.queryParams, "status", $$v)
                                      },
                                      expression: "queryParams.status",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { label: "未发放", value: "1" },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: { label: "已发放", value: "2" },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: { label: "失败", value: "5" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "核销时间", prop: "rangeTime" },
                              },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    type: "daterange",
                                    "range-separator": "至",
                                    "start-placeholder": "开始日期",
                                    "end-placeholder": "结束日期",
                                    format: "yyyy-MM-dd",
                                    "value-format": "yyyy-MM-dd",
                                    clearable: false,
                                  },
                                  model: {
                                    value: _vm.queryParams.rangeTime,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryParams,
                                        "rangeTime",
                                        $$v
                                      )
                                    },
                                    expression: "queryParams.rangeTime",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.formConfig.btnFollow
                          ? _c(
                              "el-col",
                              {
                                staticClass: "head-btn-group",
                                style: {
                                  width: _vm.formConfig.btnAreaHasShowMore,
                                },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { "label-width": "0" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "label" },
                                        slot: "label",
                                      },
                                      [_vm._v(" ")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: _vm.btnConfig.searchBtn.type,
                                          size: _vm.btnConfig.size,
                                          icon: _vm.btnConfig.searchBtn.icon,
                                        },
                                        on: { click: _vm.getList },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.btnConfig.searchBtn.text)
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: _vm.btnConfig.resetBtn.type,
                                          size: _vm.btnConfig.size,
                                          icon: _vm.btnConfig.resetBtn.icon,
                                        },
                                        on: { click: _vm.resetForm },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.btnConfig.resetBtn.text)
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _vm.formConfig.btnFollow
              ? _c("el-divider", { staticClass: "btnDivider" })
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "btnArea" }, [
              _c(
                "div",
                { staticClass: "head-btn-group" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: _vm.btnConfig.downLoadBtn.type,
                        size: _vm.btnConfig.size,
                        icon: _vm.btnConfig.downLoadBtn.icon,
                      },
                      on: { click: _vm.exportLs },
                    },
                    [_vm._v(_vm._s(_vm.btnConfig.downLoadBtn.text))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: _vm.btnConfig.downLoadBtn.type,
                        size: _vm.btnConfig.size,
                      },
                      on: { click: _vm.openExportDetail },
                    },
                    [_vm._v("导出明细")]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.tableData,
                  height: _vm.tabHeight,
                  border: _vm.tableConfig.border,
                  stripe: _vm.tableConfig.stripe,
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    type: "index",
                    label: "序号",
                    align: _vm.tableConfig.align,
                    width: "80",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "markCode",
                    label: "参与数码",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "schemeName",
                    label: "活动方案",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "activityName",
                    label: "参与活动",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "openid",
                    label: "用户openid",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "wechatUserId",
                    label: "用户ID",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "mchBillNo",
                    label: "商务订单号",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "productName",
                    label: "活动产品",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "agentName",
                    label: "数码经销商",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "winStatus",
                    label: "中奖结果",
                    align: _vm.tableConfig.information,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.winStatus == "0"
                            ? _c("el-tag", { attrs: { type: "danger" } }, [
                                _vm._v("未中奖"),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.winStatus == "1"
                            ? _c("el-tag", [_vm._v("已中奖")])
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "prizeType",
                    label: "奖品形式",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                    formatter: _vm.showPrizeType,
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "prizeName",
                    label: "奖项",
                    align: _vm.tableConfig.align,
                    "min-width": "140",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "winMoney",
                    label: "奖品价值",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "prizeSourceName",
                    label: "奖项来源",
                    align: _vm.tableConfig.align,
                    "min-width": "140",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "prizeType",
                    label: "领取状态",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                    formatter: _vm.showLinqutype,
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "joinDateTime",
                    label: "参与时间",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "sendDateTime",
                    label: "核销时间",
                    align: _vm.tableConfig.align,
                    "min-width": "140",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "操作",
                    width: "100",
                    align: _vm.tableConfig.align,
                    fixed: "right",
                    "class-name": "small-padding fixed-width",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleclick(row, "look")
                                },
                              },
                            },
                            [_vm._v("查看")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.total > 0
              ? _c("pagination", {
                  attrs: {
                    layout: "total, sizes, prev, pager, next, jumper",
                    total: _vm.total,
                    page: _vm.current,
                    limit: _vm.size,
                  },
                  on: {
                    "update:page": function ($event) {
                      _vm.current = $event
                    },
                    "update:limit": function ($event) {
                      _vm.size = $event
                    },
                    pagination: _vm.getList,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "导出明细列表",
            visible: _vm.popupExportDetail,
            width: "900px !important",
          },
          on: {
            "update:visible": function ($event) {
              _vm.popupExportDetail = $event
            },
            close: _vm.hadnleCloseExportDetail,
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.exportDataListLoading,
                      expression: "exportDataListLoading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.exportDataList,
                    border: "",
                    stripe: _vm.tableConfig.stripe,
                    "highlight-current-row": true,
                    height: "550px",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "fileName",
                      label: "文件名称",
                      "min-width": "20%",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "createTime",
                      label: "创建时间",
                      "min-width": "35%",
                      align: _vm.tableConfig.align,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "fileUrl",
                      label: "下载地址",
                      "min-width": "30%",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-link",
                              {
                                attrs: {
                                  type: "primary",
                                  href: scope.row.fileUrl,
                                },
                              },
                              [_vm._v(_vm._s(scope.row.fileUrl))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "status",
                      label: "状态",
                      "min-width": "15%",
                      align: _vm.tableConfig.align,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.status == 0
                              ? _c("el-tag", [_vm._v("进行中")])
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.status == 1
                              ? _c("el-tag", { attrs: { type: "success" } }, [
                                  _vm._v("完成"),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("Pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.exportParams.total > 0,
                    expression: "exportParams.total > 0",
                  },
                ],
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.exportParams.total,
                  page: _vm.exportParams.current,
                  limit: _vm.exportParams.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.exportParams, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.exportParams, "size", $event)
                  },
                  pagination: function ($event) {
                    return _vm.queryExprotActivityForPage(
                      _vm.exportParams.current,
                      _vm.exportParams.size
                    )
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "" },
                  on: { click: _vm.hadnleCloseExportDetail },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }