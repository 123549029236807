"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _typeof2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
require("core-js/modules/es6.function.name");
var _code_freeze_restore_abolish = require("@/api/mark/code_status/code_freeze_restore_abolish/code_freeze_restore_abolish");
var _waves = _interopRequireDefault(require("@/directive/waves"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var listQuery = {
  page: 1,
  limit: 20
};
var temp = {
  pid: 0,
  Reason: ''
};
var _default = {
  name: 'ComplexTable',
  directives: {
    waves: _waves.default
  },
  filters: {},
  data: function data() {
    return {
      activeRestoreName: 'restore_on',
      form_on: {
        code: '',
        cause: '',
        numStatus: 2
      },
      form_ons: {
        bacthNum: '',
        cause: '',
        numStatus: 2
      },
      form_warehouse_receipt: {
        bacthNum: '',
        bacthType: '',
        cause: '',
        lineNum: '',
        numStatus: ''
      },
      warehouse_receipt: {
        orderNum: ''
      },
      tableKey: 0,
      list: null,
      total: 0,
      listLoading: false,
      btn_loading: false,
      restore_show: false,
      listQuery: listQuery,
      temp: temp,
      DialogRestoreAccessVisible: false,
      rules: {
        code: [{
          required: true,
          message: '请输入数码',
          trigger: 'blur'
        }],
        cause: [{
          required: true,
          message: '输入恢复原因',
          trigger: 'blur'
        }]
      },
      ruless: {
        bacthNum: [{
          required: true,
          message: '请输入生产批次',
          trigger: 'blur'
        }],
        cause: [{
          required: true,
          message: '输入恢复原因',
          trigger: 'blur'
        }]
      },
      rules_list: {
        cause: [{
          required: true,
          message: '输入恢复原因',
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {},
  methods: {
    Rest: function Rest() {
      this.DialogRestoreAccessVisible = false;
      this.$refs.ListForm.resetFields();
    },
    handleabolishClick: function handleabolishClick(tab, event) {
      if (tab.name == 'restore_on') {
        this.$refs.codeForm.resetFields();
        this.$refs['codeForm'].clearValidate();
      }
      if (tab.name == 'restore_ons') {
        this.$refs.batchForm.resetFields();
        this.$refs['batchForm'].clearValidate();
      }
      if (tab.name == 'restore_access') {
        this.$refs.abolishForm.resetFields();
      }
    },
    handleFilter: function handleFilter(type) {
      var _this = this;
      if (type == 'warehouse_receipt_search') {
        // 出入库单废除查询
        this.listLoading = true;
        (0, _code_freeze_restore_abolish.inOrOutEffectiveQuery)(this.warehouse_receipt).then(function (response) {
          var data = response.data;
          if (data.code != 200) {
            _this.$message.error(data.msg);
            return true;
          }
          _this.list = null;
          if (data.data != null) {
            _this.list = data.data;
          }
          _this.listLoading = false;
        });
      }
    },
    handleUpdate: function handleUpdate(row, type) {
      if (type == 'warehouse_receipt') {
        this.form_warehouse_receipt.bacthNum = row.billNum;
        this.form_warehouse_receipt.lineNum = row.rowNo;
        this.DialogRestoreAccessVisible = true;
        if (typeof this.$refs.ListForm !== 'undefined') {
          this.$refs.ListForm.resetFields();
          this.$refs.ListForm.clearValidate();
        }
      }
    },
    onSubmitRestore: function onSubmitRestore(row, _type) {
      var _this2 = this;
      // this.$refs[_type].clearValidate();
      this.$refs[_type].validate(function (valid) {
        if (valid) {
          var tips = '确认要恢复该出入库单数码吗?';
          if (_type == 'codeForm') {
            tips = '确认要恢复该单数码吗?';
          }
          if (_type == 'batchForm') {
            tips = '确认要恢复该批次吗?';
          }
          _this2.$confirm(tips, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {
            if (_type == 'codeForm') {
              _this2.codeFormStatus(row);
            }
            if (_type == 'batchForm') {
              _this2.batchFormStatus(row);
            }
            if (_type == 'ListForm') {
              _this2.warehouseReceiptFormStatus(row); // 出入库单恢复
            }
          }).catch(function () {});
        }
      });
    },
    warehouseReceiptFormStatus: function warehouseReceiptFormStatus(data) {
      var _this3 = this;
      // 出入库单恢复
      this.btn_loading = true;
      (0, _code_freeze_restore_abolish.inOrOutEffective)(data).then(function (response) {
        _this3.btn_loading = false;
        var data = response.data;
        if (data.code != 200) {
          _this3.$message.error(data.msg);
          return false;
        }
        _this3.$message({
          message: '出入库单恢复成功',
          type: 'success'
        });
        _this3.$refs.ListForm.resetFields();
        _this3.DialogRestoreAccessVisible = false;
        _this3.handleFilter('warehouse_receipt_search');
      });
    },
    batchFormStatus: function batchFormStatus(data) {
      var _this4 = this;
      // 生产批次恢复
      this.btn_loading = true;
      (0, _code_freeze_restore_abolish.batchNumEffective)(data).then(function (response) {
        _this4.btn_loading = false;
        var data = response.data;
        if (data.code != 200) {
          _this4.$message.error(data.msg);
          return false;
        }
        _this4.$message({
          message: '生产批次恢复成功',
          type: 'success'
        });
        _this4.$refs.codeForm.resetFields();
      });
    },
    codeFormStatus: function codeFormStatus(data) {
      var _this5 = this;
      // 单码恢复
      this.btn_loading = true;
      (0, _code_freeze_restore_abolish.singleCodeEffective)(data).then(function (response) {
        _this5.btn_loading = false;
        var data = response.data;
        if (data.code != 200) {
          _this5.$message.error(data.msg);
          return false;
        }
        _this5.$message({
          message: '单码恢复成功',
          type: 'success'
        });
        _this5.$refs.codeForm.resetFields();
      });
    },
    getKeysetVal: function getKeysetVal(old_v, new_v) {
      for (var i in old_v) {
        if ((0, _typeof2.default)(old_v[i]) === 'object') {
          for (var n in old_v[i]) {
            old_v[i][n] = new_v[i][n];
          }
        } else {
          old_v[i] = new_v[i];
        }
      }
      return old_v;
    }
  }
};
exports.default = _default;