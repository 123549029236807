"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _UploadImg = _interopRequireDefault(require("@/components/UploadImg"));
var _integralMall = require("@/api/integral/integralMall");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

_integralMall.listSpec;
var _default2 = {
  components: {
    UploadImg: _UploadImg.default
  },
  props: {
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    prodName: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      // 数据库中的规格
      dbSpecs: [],
      // 根据选定的规格所查询出来的规格值
      dbSpecValues: [],
      specs: [],
      // 使用的规格
      initing: false,
      updatedIn: true
    };
  },
  computed: {
    tableLeftTitles: function tableLeftTitles() {
      var res = [];
      for (var i = 0; i < this.skuTags.length; i++) {
        var skuTag = this.skuTags[i];
        res.push(skuTag.tagName);
      }
      return res;
    },
    skuTags: {
      get: function get() {
        return this.$store.state.prod.skuTags;
      }
    },
    defalutSku: function defalutSku() {
      return this.$store.state.prod.defalutSku;
    }
  },
  watch: {
    prodName: function prodName() {
      this.skuAddProdName();
    },
    value: function value() {
      var that = this;
      if (that.value.length > 0) {
        for (var i = 0; i < that.value.length; i++) {
          var refName = 'imgs' + i;
        }
      }
    }
  },
  created: function created() {
    var _this = this;
    (0, _integralMall.listSpec)().then(function (res) {
      console.log(res);
      if (res.status === 200) {
        _this.dbSpecs = res.data;
      }
    });
  },
  updated: function updated() {
    // if (!this.updatedIn) return false
    var that = this;
    if (that.value.length > 0) {
      for (var i = 0; i < that.value.length; i++) {
        if (this.value[i].pic) {
          this.$refs['imgs' + i].fileList = [{
            url: this.value[i].pic
          }];
          this.$refs['imgs' + i].hideUpload = true;
        } else {
          if (this.$refs['imgs' + i]) {
            this.$refs['imgs' + i].fileList = [];
            this.$refs['imgs' + i].hideUpload = false;
          }
        }
      }
      // this.updatedIn = false
    }
  },

  methods: {
    init: function init() {
      this.initing = true;
    },
    getTableSpecData: function getTableSpecData() {
      return this.value;
    },
    tableSpanMethod: function tableSpanMethod(_ref) {
      var row = _ref.row,
        column = _ref.column,
        rowIndex = _ref.rowIndex,
        columnIndex = _ref.columnIndex;
    },
    changeSkuStatus: function changeSkuStatus(tagIndex) {
      this.value[tagIndex].status = this.value[tagIndex].status ? 0 : 1;
    },
    skuAddProdName: function skuAddProdName() {
      if (this.initing) {
        return;
      }
      var skuList = [];
      for (var i = 0; i < this.value.length; i++) {
        var sku = Object.assign({}, this.value[i]);
        if (!sku.properties) {
          return;
        }
        sku.skuName = '';
        var properties = sku.properties.split(';');
        for (var propertiesKey in properties) {
          sku.skuName += properties[propertiesKey].split(':')[1] + ' ';
        }
        sku.prodName = this.prodName + ' ' + sku.skuName;
        skuList.push(sku);
      }
      this.$emit('input', skuList);
    }
  }
};
exports.default = _default2;