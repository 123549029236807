"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _factorySelectDialog = _interopRequireDefault(require("@/components/factorySelectDialog"));
var _productDialog = _interopRequireDefault(require("@/components/productDialog"));
var _ordergl = require("@/api/warehouse/ordergl");
var _index = require("@/api/warehouse/checkout/index");
var _org = require("@/api/basic/org");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// var miniUnit = ''
var _default = {
  name: 'TableSuply',
  components: {
    productDialog: _productDialog.default,
    factorySelectDialog: _factorySelectDialog.default
  },
  filters: {},
  data: function data() {
    var _this = this;
    var validatematerialCode = function validatematerialCode(rule, value, callback) {
      if (_this.productList.length == 0 || _this.productList == '' || _this.productList == null) {
        callback(new Error('产品明细不能为空，请维护'));
      } else {
        callback();
      }
    };
    return {
      title: '添加调拨出库',
      billType: '',
      outComName: '',
      inComName: '',
      inUseName: '',
      tableKey: 'pId',
      listLoading: false,
      factoryShow: false,
      justShow: false,
      mloading: false,
      dialogVisible: false,
      btn_loading: false,
      proShow: false,
      isUpdate: true,
      submitType: 1,
      scanData: {
        billoutTransAO: {},
        receiptUser: '',
        type: []
      },
      rowError: [],
      scanData1: {
        billNo: '',
        billType: 202,
        receiptUser: '',
        billoutTransAO: {
          inOrgId: '',
          inOrgType: '',
          inStoreId: '',
          outOrgId: '',
          outOrgType: '',
          outStoreId: ''
        },
        sumPlanAtoNum: 0,
        sumPlanNum: 0,
        tradeBillDetailAOS: []
      },
      disabled: {
        num: false
      },
      orgList: [],
      storeList: [],
      productList: [],
      rules: {
        billNo: [{
          required: true,
          message: '调拨出库单号不能为空，请维护',
          trigger: 'change'
        }],
        'billoutTransAO.outOrgId': [{
          required: true,
          message: '请选择发货单位',
          trigger: 'change'
        }],
        'billoutTransAO.inOrgId': [{
          required: true,
          message: '请选择收货单位',
          trigger: 'change'
        }],
        'billoutTransAO.outStoreId': [{
          required: true,
          message: '请选择发库库房',
          trigger: 'change'
        }],
        materialCode: [{
          required: true,
          trigger: 'change',
          validator: validatematerialCode
        }]
      }
    };
  },
  mounted: function mounted() {
    var that = this;
    (0, _org.fetchOrgList)({
      orgTypeList: ['2', '3']
    }).then(function (res) {
      if (res.data.code != 200) {
        that.$message.error(res.data.msg);
      } else {
        if (res.data.data != null) {
          that.orgList = res.data.data;
        } else {
          that.orgList = [];
        }
      }
    });
  },
  methods: {
    init: function init() {
      this.title = '排产';
      this.listLoading = false;
      this.btn_loading = false;
      this.isUpdate = true;
      this.storeList = [];
      this.productList = [];
      this.outComName = '';
      this.inComName = '';
      this.scanData = JSON.parse(JSON.stringify(this.scanData1));
    },
    show: function show(submitType, billType) {
      this.init();
      this.submitType = submitType;
      this.billType = billType;
      this.disabled.num = false;
      this.mloading = true;
      if (this.$refs['scanForm'] !== undefined) {
        this.$refs['scanForm'].resetFields();
      }
      this.dialogVisible = true;
      this.mloading = false;
    },
    upDateShow: function upDateShow(row) {
      var _this2 = this;
      this.init();
      this.title = '修改调拨出库';
      this.submitType = 2;
      this.isUpdate = false;
      this.disabled.num = true;
      this.mloading = true;
      this.dialogVisible = true;
      (0, _index.getPeoductionDetail)(row.billNo).then(function (res) {
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
        } else {
          var data = res.data.data;
          _this2.scanData.billNo = data.tradeBillAllVO.billNo;
          _this2.scanData.billSource = data.tradeBillAllVO.billSource;
          _this2.scanData.billType = data.tradeBillAllVO.billType;
          _this2.scanData.billoutTransAO.outOrgId = data.tradeBillAllVO.outOrgId;
          _this2.outComName = data.tradeBillAllVO.outOrgName;
          _this2.scanData.billoutTransAO.inOrgId = data.tradeBillAllVO.inOrgId;
          _this2.inComName = data.tradeBillAllVO.inOrgName;
          _this2.scanData.receiptUser = data.tradeBillAllVO.receiptUser;
          _this2.orgList.filter(function (item) {
            if (item.orgName == data.tradeBillAllVO.outOrgName) {
              _this2.scanData.billoutTransAO.outOrgType = item.orgType == 2 ? 2 : 1;
            }
            if (item.orgName == data.tradeBillAllVO.inOrgName) {
              _this2.scanData.billoutTransAO.inOrgType = item.orgType == 2 ? 2 : 1;
            }
          });
          (0, _ordergl.byOrgFactoryId)({
            orgOrFactoryId: data.tradeBillAllVO.outOrgId
          }).then(function (res) {
            if (res.data.code != 200) {
              _this2.$message.error(res.data.msg);
            } else {
              if (res.data.data != null) {
                _this2.storeList = res.data.data;
              } else {
                _this2.storeList = [];
              }
            }
          }).then(function () {
            _this2.storeList.filter(function (val) {
              if (val.storeHouseName == data.tradeBillAllVO.outStoreName) {
                _this2.scanData.billoutTransAO.outStoreId = val.pId;
                _this2.setStore(val.pId);
              }
            });
          });
          if (data.tradeProductVOS && data.tradeProductVOS.length) {
            var arr = [];
            data.tradeProductVOS.forEach(function (item) {
              var list = item.packList;
              var list2 = [];
              list.forEach(function (val) {
                list2.push({
                  unitName: val
                });
              });
              var obj = {
                justShow: true,
                pId: item.productId,
                productCode: item.productCode,
                productName: item.productName,
                packScaleExpression: item.packRadioDesc,
                packUnit: item.packUnit,
                planScanAtoNum: item.planScanAtoNum,
                planScanNum: item.planScanNum,
                minScaleName: item.packList[item.packList.length - 1],
                prodUnit: item.packList[0],
                packScaleLevelItemDTOList: list2
              };
              console.log(item, 'item');
              console.log(item.planScanNum, 'item.planScanNum');
              console.log(obj, 'obj');
              arr.push(obj);
            });
            _this2.productList = arr;
          } else {
            _this2.productList = [];
          }
          console.log(_this2.productList, 999);
          _this2.setStore(_this2.scanData.billoutTransAO.outStoreId);
        }
        _this2.mloading = false;
      }).catch(function () {
        _this2.mloading = false;
      });
    },
    submitForm: function submitForm(temp) {
      var _this3 = this;
      this.setDetail();
      if (this.rowError.length > 0) {
        this.$message.error('产品列表第' + this.rowError.join(',') + '的 计划最小单位数为零!');
        return false;
      }
      this.$refs['scanForm'].validate(function (valid) {
        if (valid) {
          _this3.btn_loading = true;
          if (_this3.submitType == 2) {
            (0, _index.InfoUpdate)(_this3.scanData).then(function (res) {
              _this3.btn_loading = false;
              if (res.data.code != 200) {
                _this3.$message.error(res.data.msg);
              } else {
                _this3.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this3.handleClose();
              }
            }).catch(function () {
              _this3.btn_loading = false;
            });
          } else {
            (0, _index.insertTradeBill)(_this3.scanData).then(function (res) {
              _this3.btn_loading = false;
              if (res.data.code != 200) {
                _this3.$message.error(res.data.msg);
              } else {
                _this3.$message({
                  message: '保存成功',
                  type: 'success'
                });
                _this3.handleClose();
              }
            }).catch(function () {
              _this3.btn_loading = false;
            });
          }
        }
      });
    },
    handleClose: function handleClose() {
      this.authId('searchbtn', this.$route.meta.authList);
      this.dialogVisible = false;
      this.$parent.getList();
    }
  }
};
exports.default = _default;