var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _c(
      "div",
      { staticClass: "page-section" },
      [
        _c(
          "el-form",
          {
            ref: "searchForm",
            staticClass: "search-condition",
            attrs: {
              model: _vm.searchForm,
              "status-icon": "",
              "label-position": _vm.formConfig.labelPosition,
              "label-width": _vm.formConfig.labelWidth,
            },
          },
          [
            _c(
              "div",
              { staticClass: "cols" },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 10 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "赛事名称:" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "赛事名称", clearable: "" },
                              model: {
                                value: _vm.searchForm.gameName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchForm, "gameName", $$v)
                                },
                                expression: "searchForm.gameName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "状态:" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "请选择状态",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.searchForm.status,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "status", $$v)
                                  },
                                  expression: "searchForm.status",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "启用", value: 1 },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "停用", value: 2 },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { "label-width": "0" } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: _vm.btnConfig.searchBtn.type,
                                  size: _vm.btnConfig.size,
                                  icon: _vm.btnConfig.searchBtn.icon,
                                },
                                on: { click: _vm.search },
                              },
                              [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: _vm.btnConfig.resetBtn.type,
                                  size: _vm.btnConfig.size,
                                  icon: _vm.btnConfig.resetBtn.icon,
                                },
                                on: { click: _vm.reset },
                              },
                              [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c("el-divider", { staticClass: "btnDivider" }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "phead" },
          [
            _c(
              "el-row",
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: _vm.btnConfig.addBtn.type,
                      size: _vm.btnConfig.size,
                      icon: _vm.btnConfig.addBtn.icon,
                      disabled: false,
                    },
                    on: { click: _vm.creat },
                  },
                  [_vm._v("新建赛事配置")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: _vm.btnConfig.addBtn.type,
                      size: _vm.btnConfig.size,
                      disabled: false,
                    },
                    on: { click: _vm.gameConfigDelete },
                  },
                  [_vm._v("批量删除")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoading,
                expression: "listLoading",
              },
            ],
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.tableData,
              border: _vm.tableConfig.border,
              stripe: _vm.tableConfig.stripe,
              fit: "",
              "highlight-current-row": "",
            },
            on: { "selection-change": _vm.handleSelectionChange },
          },
          [
            _c("el-table-column", {
              attrs: { type: "selection", width: "60" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "赛事ID",
                prop: "matchId",
                "min-width": "180",
                "show-overflow-tooltip": "",
                align: _vm.tableConfig.align,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "赛事名称",
                prop: "gameName",
                "min-width": "120",
                "show-overflow-tooltip": "",
                align: _vm.tableConfig.align,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "参赛区域",
                prop: "gameArea",
                "min-width": "120",
                "show-overflow-tooltip": "",
                align: _vm.tableConfig.align,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "开始时间-结束时间",
                "min-width": "160",
                "show-overflow-tooltip": "",
                align: _vm.tableConfig.align,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    return [
                      _c("div", [_vm._v(_vm._s(row.startTime))]),
                      _vm._v(" "),
                      _c("div", [_vm._v(_vm._s(row.endTime))]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "创建人",
                prop: "creatorUser",
                "min-width": "120",
                "show-overflow-tooltip": "",
                align: _vm.tableConfig.align,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "状态",
                prop: "status",
                "min-width": "120",
                "show-overflow-tooltip": "",
                align: _vm.tableConfig.align,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    return [
                      row.status == "1"
                        ? _c("el-tag", [_vm._v("启用")])
                        : _vm._e(),
                      _vm._v(" "),
                      row.status == "2"
                        ? _c("el-tag", { attrs: { type: "danger" } }, [
                            _vm._v("停用"),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "备注",
                prop: "remark",
                "min-width": "160",
                "show-overflow-tooltip": "",
                align: _vm.tableConfig.align,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "操作",
                align: _vm.tableConfig.align,
                width: "140",
                fixed: "right",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.onOrOffOperation(scope.row)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm._f("filterButtonStatus")(scope.row.status)
                              ) +
                              "\n          "
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("pagination", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.total > 0,
              expression: "total > 0",
            },
          ],
          attrs: {
            total: _vm.total,
            page: _vm.searchForm.pageNo,
            limit: _vm.searchForm.pageSize,
          },
          on: {
            "update:page": function ($event) {
              return _vm.$set(_vm.searchForm, "pageNo", $event)
            },
            "update:limit": function ($event) {
              return _vm.$set(_vm.searchForm, "pageSize", $event)
            },
            pagination: _vm.queryForPage,
          },
        }),
        _vm._v(" "),
        _vm.creatVisible
          ? _c("creat", {
              attrs: {
                type: _vm.editType,
                "rule-code": _vm.ruleCode,
                "dialog-visible": _vm.creatVisible,
              },
              on: { close: _vm.closeCreat },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }