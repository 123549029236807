var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "integral-wrap" }, [
    _c("div", { staticClass: "page-container" }, [
      _c(
        "div",
        { staticClass: "page-section" },
        [
          _c(
            "el-scrollbar",
            { staticStyle: { height: "100%" } },
            [_c("router-view")],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }