"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.to-string");
var _utils = require("@/utils");
var _index = require("@/api/warehouse/checkout/index");
var _select = require("@/api/warehouse/select");
var _flowHead = _interopRequireDefault(require("../../../flowHead"));
var _flowLog = _interopRequireDefault(require("../../../flowLog"));
var _productList = _interopRequireDefault(require("../../../productList"));
var _codeDetails = _interopRequireDefault(require("@/components/codeDetails"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var miniUnit = '';
var TypeDataComs = {
  status: [],
  source: [],
  factory: []
};
var _default = {
  name: 'TableSuply',
  components: {
    flowHead: _flowHead.default,
    flowLog: _flowLog.default,
    productList: _productList.default,
    codeDetails: _codeDetails.default
  },
  filters: {
    formatDate: function (_formatDate) {
      function formatDate(_x) {
        return _formatDate.apply(this, arguments);
      }
      formatDate.toString = function () {
        return _formatDate.toString();
      };
      return formatDate;
    }(function (time) {
      var date = new Date(time);
      return formatDate(date, 'yyyy-MM-dd hh:mm:ss');
    }),
    filterStatus: function filterStatus(code) {
      var vars = TypeDataComs['status'];
      var name = '';
      for (var i = 0; i < vars.length; i++) {
        if (vars[i].figure == code) {
          name = vars[i].name;
          break;
        }
      }
      return name;
    },
    filterSource: function filterSource(code) {
      var vars = TypeDataComs['source'];
      var name = '';
      for (var i = 0; i < vars.length; i++) {
        if (vars[i].figure == code) {
          name = vars[i].name;
          break;
        }
      }
      return name;
    },
    typeClass: function typeClass(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          '1': 'primary',
          '2': 'primary',
          '3': 'primary',
          '4': 'primary',
          '5': 'primary',
          '6': 'primary',
          '7': 'success',
          '8': 'warning',
          '9': 'danger',
          '10': 'info',
          '11': 'info'
        };
        return statusMap[value];
      }
    },
    operateType: function operateType(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        var statusMap = {
          '1': '创建单据',
          '2': '修改单据',
          '3': '删除单据',
          '4': '拆单',
          '5': '审核不通过',
          '6': '审核通过',
          '7': '单据下载',
          '8': '单据扫描',
          '9': '正常结单',
          '10': '强制结单',
          '11': '撤销下载',
          '12': '撤销结单',
          '14': '已签收'
        };
        return statusMap[value];
      }
    },
    // 最小包装比例
    unitName: function unitName(arr) {
      if (arr == null && !arr) return '';
      var name = '';
      if (arr.length == 2) {
        name = arr[1];
      } else if (arr.length == 3) {
        name = arr[2];
      }
      return name;
    },
    // 最大包装比例
    unitName2: function unitName2(arr) {
      if (arr == null && !arr) return '';
      var name = '';
      if (arr.length) {
        name = arr[0];
      }
      return name;
    }
  },
  props: {},
  data: function data() {
    return {
      num: '',
      active: 0,
      detailVisible: false,
      mloading: false,
      listLoading: false,
      scanData: {},
      temp: {},
      flowList: [],
      settype: 2,
      showflow: false,
      TypeDataComs: {
        status: [],
        source: [],
        factory: []
      },
      detailData: {},
      proList: [],
      tradeOperateLogDTOS: [],
      codeVisible: false,
      billNo: '',
      productId: '',
      ncProductId: '',
      activeName: ''
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var that = this;
    this.getstoreTypeInfoList('DJZT');
    this.getstoreTypeInfoList('DJLY');
    TypeDataComs = this.TypeDataComs;
  },
  methods: {
    /**
     * settype 1:拆单，2：查看，3：审核
     */
    show: function show(rows, settype) {
      var _this = this;
      var that = this;
      (0, _index.getPeoductionDetail)({
        billNo: rows.billNo,
        ncProductId: '',
        productId: ''
      }).then(function (res) {
        if (res.data.code == 200) {
          that.detailData = res.data.data.tradeBillAllVO;
          that.proList = res.data.data.tradeProductVOS;
          that.tradeOperateLogDTOS = res.data.data.tradeOperateLogDTOS;
          switch (res.data.data.tradeBillAllVO.billStatus) {
            case 101:
              _this.active = 1;
              break;
            case 102:
              _this.active = 1;
              break;
            case 103:
              _this.active = 2;
              break;
            case 104:
              _this.active = 3;
              break;
            case 105:
              _this.active = 4;
              break;
            case 106:
              _this.active = 4;
              break;
            case 108:
              _this.active = 4;
              break;
            default:
              _this.active = 0;
          }
        } else {
          that.$message.error(res.data.msg);
          that.detailData = {
            tradeBillAllVO: {
              billNo: ''
            }
          };
          that.proList = [];
          return;
        }
      });
      this.detailVisible = true;
      this.mloading = true;
      this.mloading = false;
    },
    // 获取单据状态，单据来源
    getstoreTypeInfoList: function getstoreTypeInfoList(code) {
      var _this2 = this;
      var param = {};
      param.code = code;
      this.TypeDataComs.status = [];
      this.TypeDataComs.source = [];
      (0, _select.getDicItem)(param).then(function (res) {
        if (res.data.code != 200) {
          _this2.$message.error(res.data.msg);
          return;
        } else {
          if (param.code == 'DJZT') {
            _this2.TypeDataComs.status = res.data.data;
          }
          if (param.code == 'DJLY') {
            _this2.TypeDataComs.source = res.data.data;
          }
          TypeDataComs = _this2.TypeDataComs;
        }
      }).catch(function () {});
    },
    handleClose: function handleClose() {
      this.detailVisible = false;
    },
    // 获取数码详情
    getCodesDetail: function getCodesDetail(value) {
      var that = this;
      that.billNo = value.billNo;
      that.productId = value.productId;
      that.ncProductId = value.ncProductId || '';
      that.codeVisible = true;
    },
    codeHide: function codeHide() {
      var that = this;
      that.codeVisible = false;
    }
  }
};
exports.default = _default;