var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.mloading,
          expression: "mloading",
        },
      ],
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.detailVisible,
        "before-close": _vm.handleClose,
        width: "1000px !important",
      },
      on: {
        "update:visible": function ($event) {
          _vm.detailVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "itemTitle" }, [_vm._v("门店信息")]),
      _vm._v(" "),
      _c(
        "el-row",
        {
          staticStyle: { "flex-direction": "row", "flex-wrap": "wrap" },
          attrs: { gutter: 20, type: "flex" },
        },
        [
          _c("el-col", { attrs: { lg: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("门店编码:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.detailData.terminalCode)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { lg: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("门店名称:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.detailData.terminalName)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { lg: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("门店类型:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.detailData.terminalTypeName)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { lg: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("门店地址:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(
                  _vm._s(_vm.detailData.provinceName) +
                    "-" +
                    _vm._s(_vm.detailData.cityName) +
                    "-" +
                    _vm._s(_vm.detailData.countyName)
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { lg: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("详细地址:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.detailData.detailedAddress)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { lg: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [
                _vm._v("门店定位(经纬度):"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.detailData.longitude) +
                    "|" +
                    _vm._s(_vm.detailData.latitude)
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { lg: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("联系人:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.detailData.contacts)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { lg: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("联系电话:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.detailData.phone)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { lg: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("组织机构代码:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.detailData.organizationCode)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { lg: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("创建人:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.detailData.userName)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { lg: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("创建时间:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.detailData.createdTime)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { lg: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("创建方式:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm._f("createType")(_vm.detailData.createType))),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { lg: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("所属战区编码:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.detailData.saleOrg2Id)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { lg: 16 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("所属战区:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.detailData.saleOrg2Name)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { lg: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("战队编码:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.detailData.saleOrg3Id)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { lg: 16 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("所属战队:")]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.detailData.saleOrg3Name)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { lg: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("门头图片:")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "content flex-img" },
                [
                  _c(
                    "el-image",
                    {
                      staticStyle: { width: "150px", height: "100px" },
                      attrs: {
                        src: _vm.detailData.doorHead,
                        fit: "fill",
                        "preview-src-list": [_vm.detailData.doorHead],
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "error-text",
                          attrs: { slot: "error" },
                          slot: "error",
                        },
                        [_vm._v("\n              暂无图片\n            ")]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.detailData.doorHead
                    ? _c(
                        "el-button",
                        { attrs: { size: "small", type: "primary" } },
                        [
                          _c(
                            "a",
                            {
                              staticStyle: { display: "contents" },
                              attrs: {
                                href:
                                  _vm.detailData.doorHead +
                                  "?response-content-type=application/octet-stream",
                                download: "",
                              },
                            },
                            [_vm._v("下载")]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { lg: 8 } }, [
            _c("div", { staticClass: "vue-from-block" }, [
              _c("div", { staticClass: "form-div" }, [_vm._v("营业执照:")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "content flex-img" },
                [
                  _c(
                    "el-image",
                    {
                      staticStyle: { width: "150px", height: "100px" },
                      attrs: {
                        src: _vm.detailData.businessLicense,
                        fit: "fill",
                        "preview-src-list": [_vm.detailData.businessLicense],
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "error-text",
                          attrs: { slot: "error" },
                          slot: "error",
                        },
                        [_vm._v("\n              暂无图片\n            ")]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.detailData.businessLicense
                    ? _c(
                        "el-button",
                        { attrs: { size: "small", type: "primary" } },
                        [
                          _c(
                            "a",
                            {
                              staticStyle: { display: "contents" },
                              attrs: {
                                href:
                                  _vm.detailData.businessLicense +
                                  "?response-content-type=application/octet-stream",
                                download: "",
                              },
                            },
                            [_vm._v("下载")]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "middleLine" }),
      _vm._v(" "),
      _c("div", { staticClass: "itemTitle" }, [_vm._v("OCR:识别结果")]),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 24 } }, [
            _c(
              "div",
              { staticClass: "vue-from-block" },
              [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.detailData.list, height: "195px" },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        "show-overflow-tooltip": "",
                        label: "公司名称",
                        align: _vm.tableConfig.align,
                        prop: "companyName",
                        "min-width": "200",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        "show-overflow-tooltip": "",
                        label: "法人姓名",
                        align: _vm.tableConfig.align,
                        prop: "legalName",
                        "min-width": "100",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        "show-overflow-tooltip": "",
                        label: "组织代码",
                        align: _vm.tableConfig.align,
                        prop: "organizationCode",
                        "min-width": "200",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        "show-overflow-tooltip": "",
                        label: "注册地址",
                        align: _vm.tableConfig.align,
                        prop: "registerAddress",
                        "min-width": "250",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        "show-overflow-tooltip": "",
                        label: "时间",
                        align: _vm.tableConfig.align,
                        prop: "registerTime",
                        "min-width": "150",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "middleLine" }),
      _vm._v(" "),
      _c("div", { staticClass: "itemTitle" }, [_vm._v("上级经销商")]),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 24 } }, [
            _c(
              "div",
              { staticClass: "vue-from-block" },
              [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.detailData.list2, height: "195px" },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "index",
                        width: "80",
                        label: "序号",
                        align: _vm.tableConfig.align,
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        "show-overflow-tooltip": "",
                        label: "经销商编码",
                        align: _vm.tableConfig.align,
                        prop: "channelCode",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        "show-overflow-tooltip": "",
                        label: "经销商名称",
                        align: _vm.tableConfig.align,
                        prop: "channelName",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "middleLine" }),
      _vm._v(" "),
      _c("div", { staticClass: "itemTitle" }, [_vm._v("流程")]),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 10 } },
            [
              _c(
                "el-timeline",
                { attrs: { reverse: _vm.reverse } },
                [
                  _vm._l(
                    _vm.detailData.auditFlowVoList,
                    function (activity, index) {
                      return _c(
                        "el-timeline-item",
                        { key: index },
                        [
                          _c("el-card", { staticClass: "card" }, [
                            _c("h4", [
                              _vm._v(
                                "【审批】" +
                                  _vm._s(
                                    _vm._f("auditStatus")(activity.auditStatus)
                                  ) +
                                  "  " +
                                  _vm._s(activity.createTime)
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", [_vm._v(_vm._s(activity.userName))]),
                            _vm._v(" "),
                            _c("p", [_vm._v(_vm._s(activity.remark))]),
                          ]),
                        ],
                        1
                      )
                    }
                  ),
                  _vm._v(" "),
                  _c(
                    "el-timeline-item",
                    [
                      _c("el-card", { staticClass: "card" }, [
                        _c("h4", [
                          _vm._v(
                            "【创建】" +
                              _vm._s(
                                _vm._f("createType")(_vm.detailData.createType)
                              ) +
                              "  " +
                              _vm._s(_vm.detailData.createdTime)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", [_vm._v(_vm._s(_vm.detailData.userName))]),
                        _vm._v(" "),
                        _c("p"),
                      ]),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.settype == 2
        ? _c("div", { staticClass: "itemTitle" }, [_vm._v("活动审批")])
        : _vm._e(),
      _vm._v(" "),
      _vm.settype == 2
        ? _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "ruleForm",
                      attrs: {
                        model: _vm.ruleForm,
                        rules: _vm.rules,
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "审批结果:", prop: "auditStatus" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                disabled: _vm.detailShow,
                                placeholder: "请选择",
                              },
                              model: {
                                value: _vm.ruleForm.auditStatus,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "auditStatus", $$v)
                                },
                                expression: "ruleForm.auditStatus",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "审核通过", value: 2 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "审核驳回", value: 3 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注说明:", prop: "remark" } },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: _vm.detailShow,
                              type: "textarea",
                              maxlength: "100",
                              autosize: { minRows: 2, maxRows: 4 },
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.ruleForm.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "remark", $$v)
                              },
                              expression: "ruleForm.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [_vm._v("关闭")]),
          _vm._v(" "),
          _vm.settype == 2
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.detailShow },
                  on: { click: _vm.AuditSubmit },
                },
                [_vm._v("提交")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }