var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-container", [
    _c("div", { staticClass: "hyyy-container" }, [
      _c("div", { staticClass: "hyyy-content" }, [
        _c(
          "div",
          { staticClass: "hyyy-header" },
          [
            _c("div", { staticClass: "hyyy-top-tit" }, [
              _c("div", { staticClass: "hyyy-top-tit-lift" }, [
                _c("span", {
                  staticStyle: { "font-weight": "400", color: "#333" },
                  domProps: { textContent: _vm._s(_vm.$route.meta.title) },
                }),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "hyyy-top-tit-right" },
                [_c("admincut", { staticStyle: { "margin-top": "7px" } })],
                1
              ),
            ]),
            _vm._v(" "),
            _c("el-divider"),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "hyyy-main" },
          [
            _c(
              "el-tabs",
              {
                on: { "tab-click": _vm.handleClick },
                model: {
                  value: _vm.activeName,
                  callback: function ($$v) {
                    _vm.activeName = $$v
                  },
                  expression: "activeName",
                },
              },
              [
                _c(
                  "el-tab-pane",
                  {
                    staticClass: "p20",
                    attrs: { label: "微信同步", name: "1" },
                  },
                  [
                    _c("div", { staticClass: "ttag" }, [
                      _c("p", [
                        _vm._v(
                          "1、微信同步可将授权绑定的公众号部分数据一键同步到本系统中，进而减少企业运营时间。"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", [_vm._v("2、微信的内容每天限制3次修改机会。")]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "3、可同步的内容包换素材库、自定义菜单、自动回复、用户信息、模版消息。"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v("4、同步过程最长5分钟，建议5分钟进行一次同步。"),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v("5、菜单同步只能同步按钮，发送消息需自定义。"),
                      ]),
                      _vm._v(" "),
                      _c("p", [_vm._v("6、自动回复同步无法同步关键词回复")]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c("p", { staticClass: "fsnomal" }, [
                              _vm._v("以下内容至少勾选一项："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-checkbox-group",
                              {
                                model: {
                                  value: _vm.checkList,
                                  callback: function ($$v) {
                                    _vm.checkList = $$v
                                  },
                                  expression: "checkList",
                                },
                              },
                              _vm._l(_vm.synclist, function (item, index) {
                                return _c(
                                  "el-checkbox",
                                  { key: index, attrs: { label: item.id } },
                                  [_vm._v(_vm._s(item.typeName))]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          {
                            staticStyle: { "margin-top": "60px" },
                            attrs: { span: 2, offset: 3 },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.btn },
                              },
                              [_vm._v("执行同步")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-tab-pane",
                  { attrs: { label: "同步记录", name: "2" } },
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading",
                          },
                        ],
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.tableData,
                          border: "",
                          "row-class-name": _vm.tableRowClassName,
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            prop: "createDate",
                            label: "同步时间",
                            align: "center",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "typeName",
                            label: "内容",
                            align: "center",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "createBy",
                            label: "操作人",
                            align: "center",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "deputyIndustry",
                            align: "center",
                            label: "状态",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.deputyIndustry == 1
                                    ? _c("span", [_vm._v("同步成功")])
                                    : _c("span", [_vm._v("同步失败")]),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }