var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.allotLoading,
          expression: "allotLoading",
        },
      ],
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.dialogVisible,
        width: "600px !important",
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "rgba(0, 0, 0, 0)",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.mloading,
              expression: "mloading",
            },
          ],
          ref: "scanForm",
          staticClass: "info-items",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-position": "right",
            "label-width": "130px",
          },
        },
        [
          _vm.visibleType == "whole"
            ? _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "h4",
                        {
                          staticStyle: {
                            width: "130px",
                            "text-align": "center",
                          },
                        },
                        [_vm._v("需要调拨的原单号:")]
                      ),
                      _vm._v(" "),
                      _vm.billDesc.billSource == 6
                        ? _c(
                            "el-form-item",
                            {
                              key: "wNCNO",
                              attrs: { label: "NC物流单号:", prop: "NCNO" },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "350px" },
                                attrs: {
                                  clearable: "",
                                  maxlength: "15",
                                  "show-word-limit": "",
                                  disabled: "",
                                  readonly: "",
                                },
                                model: {
                                  value: _vm.ruleForm.NCNO,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "NCNO", $$v)
                                  },
                                  expression: "ruleForm.NCNO",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm.billDesc.billSource == 2
                        ? _c(
                            "el-form-item",
                            {
                              key: "wbillNo",
                              attrs: { label: "物流单号:", prop: "billNo" },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "350px" },
                                attrs: {
                                  clearable: "",
                                  maxlength: "15",
                                  "show-word-limit": "",
                                  disabled: "",
                                  readonly: "",
                                },
                                model: {
                                  value: _vm.billDesc.billNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.billDesc, "billNo", $$v)
                                  },
                                  expression: "billDesc.billNo",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", [_c("el-divider")], 1),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    [
                      _c(
                        "h4",
                        {
                          staticStyle: {
                            width: "130px",
                            "text-align": "center",
                          },
                        },
                        [_vm._v("需要转入的单号:")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "单号:", prop: "inTransBillNO" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              clearable: "",
                              maxlength: "15",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.ruleForm.inTransBillNO,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "inTransBillNO", $$v)
                              },
                              expression: "ruleForm.inTransBillNO",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.visibleType == "single"
            ? _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "h4",
                        {
                          staticStyle: {
                            width: "130px",
                            "text-align": "right",
                          },
                        },
                        [_vm._v("单据信息:")]
                      ),
                      _vm._v(" "),
                      _vm.billDesc.billSource == 6
                        ? _c(
                            "el-form-item",
                            {
                              key: "NCNO",
                              attrs: { label: "NC物流单号:", prop: "NCNO" },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "350px" },
                                attrs: {
                                  clearable: "",
                                  maxlength: "15",
                                  "show-word-limit": "",
                                  disabled: "",
                                  readonly: "",
                                },
                                model: {
                                  value: _vm.billDesc.NCNO,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.billDesc, "NCNO", $$v)
                                  },
                                  expression: "billDesc.NCNO",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm.billDesc.billSource == 2
                        ? _c(
                            "el-form-item",
                            {
                              key: "billNo",
                              attrs: { label: "物流单号:", prop: "billNo" },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "350px" },
                                attrs: {
                                  clearable: "",
                                  maxlength: "15",
                                  "show-word-limit": "",
                                  disabled: "",
                                  readonly: "",
                                },
                                model: {
                                  value: _vm.billDesc.billNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.billDesc, "billNo", $$v)
                                  },
                                  expression: "billDesc.billNo",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "is-required",
                          attrs: { label: "发货仓库:" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              clearable: "",
                              maxlength: "15",
                              "show-word-limit": "",
                              disabled: "",
                              readonly: "",
                            },
                            model: {
                              value: _vm.billDesc.outStoreName,
                              callback: function ($$v) {
                                _vm.$set(_vm.billDesc, "outStoreName", $$v)
                              },
                              expression: "billDesc.outStoreName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "is-required",
                          attrs: { label: "发货日期:" },
                        },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              type: "date",
                              disabled: "",
                              placeholder: "选择日期",
                              "value-format": "yyyy-MM-dd",
                            },
                            model: {
                              value: _vm.billDesc.billDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.billDesc, "billDate", $$v)
                              },
                              expression: "billDesc.billDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "is-required",
                          attrs: { label: "经销商编码:" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              clearable: "",
                              maxlength: "15",
                              "show-word-limit": "",
                              disabled: "",
                              readonly: "",
                            },
                            model: {
                              value: _vm.billDesc.dealerCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.billDesc, "dealerCode", $$v)
                              },
                              expression: "billDesc.dealerCode",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "is-required",
                          attrs: { label: "经销商名称:" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              clearable: "",
                              maxlength: "15",
                              "show-word-limit": "",
                              disabled: "",
                              readonly: "",
                            },
                            model: {
                              value: _vm.billDesc.dealerName,
                              callback: function ($$v) {
                                _vm.$set(_vm.billDesc, "dealerName", $$v)
                              },
                              expression: "billDesc.dealerName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "is-required",
                          attrs: { label: "主户头:" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              clearable: "",
                              maxlength: "15",
                              "show-word-limit": "",
                              disabled: "",
                              readonly: "",
                            },
                            model: {
                              value: _vm.billDesc.accountName,
                              callback: function ($$v) {
                                _vm.$set(_vm.billDesc, "accountName", $$v)
                              },
                              expression: "billDesc.accountName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "is-required",
                          attrs: { label: "大区编码:" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              maxlength: "15",
                              "show-word-limit": "",
                              disabled: "",
                              readonly: "",
                            },
                            model: {
                              value: _vm.billDesc.regionCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.billDesc, "regionCode", $$v)
                              },
                              expression: "billDesc.regionCode",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "is-required",
                          attrs: { label: "大区名称:" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              clearable: "",
                              maxlength: "15",
                              "show-word-limit": "",
                              disabled: "",
                              readonly: "",
                            },
                            model: {
                              value: _vm.billDesc.regionName,
                              callback: function ($$v) {
                                _vm.$set(_vm.billDesc, "regionName", $$v)
                              },
                              expression: "billDesc.regionName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "NC产品名称:" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              clearable: "",
                              maxlength: "15",
                              "show-word-limit": "",
                              disabled: "",
                              readonly: "",
                            },
                            model: {
                              value: _vm.productDesc.ncproductName,
                              callback: function ($$v) {
                                _vm.$set(_vm.productDesc, "ncproductName", $$v)
                              },
                              expression: "productDesc.ncproductName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品名称:" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              clearable: "",
                              maxlength: "15",
                              "show-word-limit": "",
                              disabled: "",
                              readonly: "",
                            },
                            model: {
                              value: _vm.productDesc.productName,
                              callback: function ($$v) {
                                _vm.$set(_vm.productDesc, "productName", $$v)
                              },
                              expression: "productDesc.productName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "is-required",
                          attrs: { label: "规格:" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              clearable: "",
                              maxlength: "15",
                              "show-word-limit": "",
                              disabled: "",
                              readonly: "",
                            },
                            model: {
                              value: _vm.productDesc.packRadioDesc,
                              callback: function ($$v) {
                                _vm.$set(_vm.productDesc, "packRadioDesc", $$v)
                              },
                              expression: "productDesc.packRadioDesc",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "is-required",
                          attrs: { label: "计划数量:" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              clearable: "",
                              maxlength: "15",
                              "show-word-limit": "",
                              disabled: "",
                              readonly: "",
                            },
                            model: {
                              value: _vm.productDesc.realScanAtoNum,
                              callback: function ($$v) {
                                _vm.$set(_vm.productDesc, "realScanAtoNum", $$v)
                              },
                              expression: "productDesc.realScanAtoNum",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "联系电话:" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              clearable: "",
                              maxlength: "15",
                              "show-word-limit": "",
                              disabled: "",
                              readonly: "",
                            },
                            model: {
                              value: _vm.billDesc.telePhone,
                              callback: function ($$v) {
                                _vm.$set(_vm.billDesc, "telePhone", $$v)
                              },
                              expression: "billDesc.telePhone",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "收货人:" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              clearable: "",
                              maxlength: "15",
                              "show-word-limit": "",
                              disabled: "",
                              readonly: "",
                            },
                            model: {
                              value: _vm.billDesc.receiptUser,
                              callback: function ($$v) {
                                _vm.$set(_vm.billDesc, "receiptUser", $$v)
                              },
                              expression: "billDesc.receiptUser",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "收货地址:" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              clearable: "",
                              maxlength: "15",
                              "show-word-limit": "",
                              disabled: "",
                              readonly: "",
                            },
                            model: {
                              value: _vm.billDesc.receiptAddress,
                              callback: function ($$v) {
                                _vm.$set(_vm.billDesc, "receiptAddress", $$v)
                              },
                              expression: "billDesc.receiptAddress",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "单据备注:" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              clearable: "",
                              maxlength: "15",
                              "show-word-limit": "",
                              disabled: "",
                              readonly: "",
                            },
                            model: {
                              value: _vm.billDesc.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.billDesc, "remark", $$v)
                              },
                              expression: "billDesc.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", [_c("el-divider")], 1),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    [
                      _c(
                        "h4",
                        {
                          staticStyle: {
                            width: "130px",
                            "text-align": "right",
                          },
                        },
                        [_vm._v("需要转入的单号:")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "单号:", prop: "inTransBillNO" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              clearable: "",
                              maxlength: "15",
                              "show-word-limit": "",
                            },
                            on: {
                              input: function (e) {
                                return (_vm.ruleForm.inTransBillNO =
                                  _vm.validSe(e))
                              },
                            },
                            model: {
                              value: _vm.ruleForm.inTransBillNO,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "inTransBillNO", $$v)
                              },
                              expression: "ruleForm.inTransBillNO",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.btn_loading },
              on: {
                click: function ($event) {
                  return _vm.submitForm(_vm.visibleType)
                },
              },
            },
            [_vm._v("提 交")]
          ),
          _vm._v(" "),
          _c("el-button", { on: { click: _vm.handleClose } }, [
            _vm._v("关 闭"),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }