"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.sort");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _instock = require("@/api/chanelmange/instock");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _purchaseDialog = _interopRequireDefault(require("@/components/purchaseDialog"));
var _productSelectDialog = _interopRequireDefault(require("@/components/productSelectDialog"));
var _utils = require("@/utils");
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable no-undef */
var _default = {
  name: 'Tgdan',
  components: {
    Pagination: _Pagination.default,
    Treeselect: _vueTreeselect.default,
    purchaseDialog: _purchaseDialog.default,
    productChose: _productSelectDialog.default
  },
  filters: {
    formatTime: function formatTime(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        return (0, _utils.parseTime)(value, '{y}-{m}-{d}');
      }
    }
  },
  data: function data() {
    return {
      listQuery: {
        inStartDate: '',
        inEndDate: '',
        orgId: null,
        buyerId: '',
        isCode: '',
        billNo: '',
        productId: '',
        status: '',
        pageNo: 1,
        pageSize: 20
      },
      orgOptions: [],
      buyerStr: '',
      productStr: '',
      states: [{
        name: '待入库',
        figure: '1'
      }, {
        name: '全部入库',
        figure: '4'
      }],
      isCode: [{
        name: '无码',
        code: 0
      }, {
        name: '有码',
        code: 1
      }],
      activeValue: 1,
      intiveValue: 0,
      units: [],
      tableKey: 'qcrk',
      tabHeight: '100%',
      list: [],
      listLoading: false,
      total: 0,
      exportLoading: false,
      unitShow: false,
      productShow: false,
      controlType: '',
      addData: {
        clientKey: '',
        fileUrls: '',
        isCode: '',
        isSource: '0',
        orderId: '',
        orderType: 'Q100',
        buyerId: '',
        productItemList: '',
        remark: '',
        saleId: '',
        saleType: '',
        status: 1
      },
      codeParam: {
        code: '',
        id: '',
        productId: '',
        productName: '',
        pageNo: 1,
        pageSize: 10
      },
      channelCode: '',
      channelName: '',
      channelClassName: '',
      channelGradeName: '',
      productsShow: false,
      productItemList: [],
      uploadImgs: [],
      imgVisible: false,
      dialogImageUrl: '',
      scanVisible: false,
      scanData: {},
      editDta: {},
      disabled: true,
      selectLoading: false,
      rules: {
        channelCode: [{
          required: true,
          message: '请输入收货单位编码',
          trigger: 'blur'
        }]
      },
      title: '期初入库-添加',
      dialogVisible: false,
      loading: false,
      dstate: false,
      subType: 'add',
      dialogVisible2: false,
      scanCodeVisible: false,
      checklist: [],
      preViewList: [],
      orderId: '',
      codeData: [],
      total2: 0,
      apis: [{
        authBtn: true,
        resourceCode: 'HQRZ'
      }],
      page2: false,
      showCol: false,
      expandTxt: '展开'
    };
  },
  created: function created() {
    var that = this;
    that.getOrg();
    that.getList();
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  methods: {
    getList: function getList() {
      var that = this;
      that.listLoading = true;
      (0, _instock.getQcRkList)(that.listQuery).then(function (res) {
        if (res.data.code == 200) {
          that.list = res.data.data.records;
          that.total = parseInt(res.data.data.total);
          setTimeout(function () {
            that.listLoading = false;
          }, 1 * 800);
        } else {
          that.$message.error(res.data.msg);
          that.listLoading = false;
          return;
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    getOrg: function getOrg() {
      var that = this;
      (0, _instock.getQueryOrg)().then(function (res) {
        if (res.data.code == 200) {
          var rData = res.data.data;
          var arr = [];
          that.orgOriginData = rData;
          if (rData.length >= 0) {
            rData.forEach(function (item) {
              var obj = {};
              obj.id = item.pid;
              obj.label = item.orgName;
              obj.pid = item.pid;
              obj.parentPid = item.parentPid;
              arr.push(obj);
            });
            that.orgOptions = (0, _utils.transTreeData)(arr, 'pid', 'parentPid', 'children');
          }
        }
      });
    },
    clearSh: function clearSh() {
      var that = this;
      that.listQuery.buyerId = '';
    },
    clearPro: function clearPro() {
      var that = this;
      that.listQuery.productId = '';
    },
    DoSearch: function DoSearch() {
      var that = this;
      that.listQuery = (0, _utils.filterKeyNull)(that.listQuery);
      that.listQuery.pageNo = 1;
      that.getList();
      if (that.listQuery.orgId == '') {
        that.listQuery.orgId = null;
      }
    },
    DoReset: function DoReset(formName) {
      var that = this;
      that.listQuery.inStartDate = '';
      that.listQuery.inEndDate = '';
      that.listQuery.orgId = null;
      that.listQuery.buyerId = '';
      that.buyerStr = '';
      that.listQuery.isCode = '';
      that.listQuery.billNo = '';
      that.listQuery.productId = '';
      that.productStr = '';
      that.listQuery.status = '';
      that.listQuery.pageNo = 1;
      that.getList();
    },
    // 获取收货单位
    getUnit: function getUnit() {
      var _this = this;
      var that = this;
      that.controlType = 'search';
      this.$nextTick(function () {
        _this.$refs.channelSelect.proSelVisible = true;
      });
    },
    getAddUnit: function getAddUnit() {
      var _this2 = this;
      var that = this;
      that.controlType = 'add';
      this.$nextTick(function () {
        _this2.$refs.channelSelect.proSelVisible = true;
      });
    },
    hideUnit: function hideUnit() {
      var that = this;
      that.unitShow = false;
    },
    getUalue: function getUalue(val) {
      var that = this;
      var type = that.controlType;
      if (type == 'search') {
        if (val != '') {
          that.listQuery.buyerId = val.pId;
          that.buyerStr = val.channelName;
        }
      }
      if (type == 'add') {
        if (val != '') {
          that.addData.buyerId = val.pId;
          that.channelCode = val.channelCode;
          that.channelName = val.channelName;
          that.channelClassName = val.channelClassName;
          that.channelGradeName = val.channelGradeName;
        }
      }
    },
    // 获取产品
    getPname: function getPname(val) {
      var that = this;
      that.controlType = val;
      this.$refs.selProduct.proSelVisible = true;
    },
    hideProduct: function hideProduct() {
      var that = this;
      that.listQuery.productId = '';
      that.listQuery.productStr = '';
      that.productShow = false;
    },
    getProduct: function getProduct(val) {
      var that = this;
      var type = that.controlType;
      if (type == 'search') {
        if (val != '') {
          that.listQuery.productId = val.pId;
          that.productStr = val.productName;
        }
      }
      if (type == 'codeScan') {
        if (val != '') {
          that.codeParam.productId = val.pId;
          that.codeParam.productName = val.productName;
        }
      }
      that.productShow = false;
    },
    // 获取产品多选
    getMore: function getMore() {
      var that = this;
      that.productsShow = true;
    },
    hideProducts: function hideProducts() {
      var that = this;
      that.productsShow = false;
    },
    getProducts: function getProducts(val) {
      var that = this;
      var data = val;
      if (data.length > 0) {
        var arr = [];
        data.forEach(function (item) {
          var obj = {};
          obj.gifts = 0;
          obj.num = '';
          obj.packId = '';
          obj.productId = item.pId;
          obj.pid = '';
          obj.sort = 0;
          obj.packNum = item.packNum;
          obj.remarks = item.remarks;
          obj.minScaleName = item.minScaleName;
          obj.productCode = item.productCode;
          obj.productName = item.productName;
          obj.productBrand = item.brandName;
          obj.productModel = item.productModel;
          obj.packScaleDesc = item.packScaleDesc;
          obj.packScaleId = item.packScaleId;
          obj.units = [];
          arr.push(obj);
        });
        that.productItemList = that.uniqueArray(arr, 'productCode');
      } else {
        that.productItemList = [];
      }
      that.productsShow = false;
    },
    // 获取单位
    getUnits: function getUnits(data) {
      var that = this;
      var param = {};
      var arg = data;
      param.pId = arg.packScaleId;
      that.selectLoading = true;
      (0, _instock.getPackUnits)(param).then(function (res) {
        if (res.data != null) {
          arg.units = res.data;
        } else {
          arg.units = [];
        }
        that.selectLoading = false;
      }).catch(function () {
        that.selectLoading = false;
      });
    },
    // 删除新增产品列表
    removeItem: function removeItem(row) {
      var that = this;
      that.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var code = row.productCode;
        var list = that.productItemList;
        for (var i = 0; i < list.length; i++) {
          if (list[i].productCode == code) {
            list.splice(i, 1);
            break;
          }
        }
      });
    },
    // 图片上传删除
    handleRemove: function handleRemove(file, fileList) {
      var that = this;
      var name = file.name;
      if (that.uploadImgs.length > 0) {
        for (var i = 0; i < that.uploadImgs.length; i++) {
          if (name == that.uploadImgs[i].name) {
            that.uploadImgs.splice(i, 1);
            break;
          }
        }
      }
    },
    // 图片预览
    handlePictureCardPreview: function handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.imgVisible = true;
    },
    // 图片上传
    uploadImg: function uploadImg(file, fileList) {
      var that = this;
      var len = that.uploadImgs.length;
      var name = file.name;
      var isJPG = file.raw.type === 'image/jpeg';
      var isPNG = file.raw.type === 'image/png';
      var isLtSize = file.raw.size / 1024 / 1024 <= 0.5;
      if (!isJPG && !isPNG) {
        that.$message.error('上传产品图片只能是 JPG/PNG 格式!');
        return;
      }
      if (!isLtSize) {
        that.$message.error('上传产品图片大小不能超过 500k!');
        return;
      }
      var formData = new FormData();
      formData.append('file', file.raw);
      (0, _instock.upLoadImg)(formData).then(function (res) {
        if (res.data.code == 200) {
          var obj = {};
          obj.name = name;
          obj.src = res.data.data;
          if (len < 5) {
            that.uploadImgs[len] = obj;
          }
        } else {
          that.handleRemove(file, fileList);
          that.$message.error(res.data.msg + '，请重新上传！');
          return;
        }
      });
    },
    handleExceed: function handleExceed(files, fileList) {
      var that = this;
      that.$message.error("\u5F53\u524D\u9650\u5236\u9009\u62E9 5\u4E2A\u6587\u4EF6\uFF0C\u60A8\u5DF2\u9009\u62E9\u4E865\u4E2A");
    },
    // 导出文件流并下载
    DoExportAll: function DoExportAll() {
      var that = this;
      if (that.total > 50000) {
        that.$message.error('抱歉！您要导出的数据量超出了导出数据的上限，最多可导出50000条');
        return;
      }
      that.listQuery.pageNo = 1;
      that.listQuery.pageSize = that.total;
      that.exportLoading = true;
      (0, _instock.exportQcData)(that.listQuery).then(function (res) {
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '期初入库列表.xls';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
        that.exportLoading = false;
      }).catch(function () {
        that.exportLoading = false;
      });
    },
    // 新增
    DoAdd: function DoAdd() {
      var that = this;
      that.title = '期初入库-添加';
      that.subType = 'add';
      that.disabled = true;
      that.addData.clientKey = '';
      that.addData.buyerId = '';
      that.addData.isCode = 0;
      that.addData.fileUrls = '';
      that.addData.isSource = 0;
      that.addData.orderId = '';
      that.addData.orderType = 'Q100';
      that.addData.productItemList = [];
      that.addData.remark = '';
      that.addData.saleId = '';
      that.addData.saleType = '';
      that.addData.status = '';
      that.uploadImgs = [];
      that.productItemList = [];
      that.channelCode = '';
      that.channelName = '';
      that.channelClassName = '';
      that.channelGradeName = '';
      that.$nextTick(function () {
        that.dialogVisible = true;
      });
    },
    // 修改
    DoEdit: function DoEdit(data) {
      var that = this;
      that.title = '期初入库-修改';
      that.orderId = data.orderId;
      var param = {};
      param.billNo = data.no;
      that.getDetailData(param, 'edit');
    },
    // 查看
    DoScan: function DoScan(data) {
      var that = this;
      var param = {};
      param.billNo = data.no;
      that.getDetailData(param, 'scan');
    },
    // 删除
    DoDelete: function DoDelete(data) {
      var that = this;
      var param = {};
      param.id = data.orderId;
      that.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _instock.delQcData)(param).then(function (res) {
          if (res.data.code == 200) {
            that.$message({
              type: 'success',
              message: '删除成功'
            });
            that.getList();
          } else {
            that.$message.error(res.data.msg);
          }
        });
      }).catch(function () {
        console.log('删除已取消');
      });
    },
    // 获取详情
    getDetailData: function getDetailData(param, type) {
      var that = this;
      (0, _instock.getQcDetail)(param).then(function (res) {
        if (res.data.code == 200) {
          if (res.data.data != null) {
            if (res.data.data.base != null) {
              var baseData = res.data.data.base;
              that.subType = 'edit';
              that.disabled = false;
              that.addData.clientKey = '';
              that.addData.buyerId = baseData.buyerId;
              that.addData.isCode = Number(baseData.isCode);
              that.addData.orderId = baseData.orderId;
              that.addData.orderType = baseData.businessType;
              that.addData.isSource = 0;
              that.addData.remark = baseData.remark;
              that.addData.saleId = baseData.sellerId;
              that.addData.saleType = '';
              that.addData.status = '';
              that.addData.productItemList = [];
              that.channelCode = baseData.buyerCode;
              that.channelName = baseData.buyerName;
              that.channelClassName = baseData.buyerClassName;
              that.channelGradeName = baseData.buyerLevel;
              that.addData.fileUrls = '';
              if (baseData.fileUrls != '' && baseData.fileUrls != null) {
                var imgArr = baseData.fileUrls.split(',');
                var list = [];
                var src = [];
                for (var i = 0; i < imgArr.length; i++) {
                  var obj = {};
                  obj.name = i;
                  obj.src = imgArr[i];
                  src.push(obj);
                  list.push(imgArr[i]);
                }
                that.uploadImgs = src;
                that.preViewList = list;
              } else {
                that.uploadImgs = [];
              }
            }
            if (res.data.data.productlist != null) {
              var plist = res.data.data.productlist;
              if (plist.length > 0) {
                var newlist = [];
                plist.forEach(function (item) {
                  var obj = {};
                  obj.gifts = item.gifts;
                  obj.num = item.receiveNum;
                  obj.packId = item.receiveUnitId;
                  obj.productId = item.productId;
                  obj.pid = item.pid;
                  obj.sort = 0;
                  obj.packNum = item.orderNum;
                  obj.remarks = item.remarks;
                  obj.minScaleName = item.minScale;
                  obj.productCode = item.productCode;
                  obj.productName = item.productName;
                  obj.productBrand = item.productBrand;
                  obj.productModel = item.productModel;
                  obj.packScaleDesc = item.packScale;
                  obj.packScaleId = item.packScaleId;
                  obj.units = [{
                    unitName: item.receiveUnit,
                    pId: item.receiveUnitId
                  }];
                  newlist.push(obj);
                });
                that.productItemList = newlist;
              } else {
                that.productItemList = [];
              }
            }
            if (res.data.data.checklist != null) {
              that.checklist = res.data.data.checklist;
            }
            if (type == 'edit') {
              that.dialogVisible = true;
            }
            if (type == 'scan') {
              that.dialogVisible2 = true;
            }
          }
        } else {
          if (type == 'scan') {
            that.$message.error(res.data.msg + '  未获取详细信息，无法查看');
            return;
          }
          if (type == 'edit') {
            that.$message.error(res.data.msg + '  未获取详细信息，无法修改');
            return;
          }
        }
      }).catch(function () {
        console.log('error');
      });
    },
    // 关闭添加、修改弹窗
    handleClose: function handleClose() {
      var that = this;
      that.resetForm();
    },
    resetForm: function resetForm() {
      var that = this;
      that.addData = {
        clientKey: '',
        fileUrls: '',
        isCode: '',
        isSource: '',
        orderId: '',
        orderType: 'Q100',
        outStatus: '',
        productItemList: '',
        remark: '',
        saleId: '',
        saleType: '',
        status: ''
      };
      that.uploadImgs = [];
      that.productItemList = [];
      that.channelCode = '';
      that.channelName = '';
      that.channelClassName = '';
      that.channelGradeName = '';
      that.dialogVisible = false;
    },
    submitForm: function submitForm(key) {
      var that = this;
      if (that.channelCode == '') {
        that.$message.error('请输入或选择收货单位编码');
        return;
      }
      that.addData.clientKey = new Date().getTime();
      that.addData.status = Number(key);
      var products = that.productItemList;
      if (products.length > 0) {
        var parr = [];
        for (var i = 0; i < products.length; i++) {
          var obj = {};
          var item = products[i];
          obj.gifts = item.gifts;
          if (item.num == '') {
            that.$message.error('请输入收货数量');
            return;
          } else {
            obj.num = Number(item.num);
          }
          obj.packId = item.packId;
          obj.productId = item.productId;
          obj.pid = item.pid;
          obj.sort = item.sort;
          parr.push(obj);
        }
        that.addData.productItemList = parr;
      } else {
        that.addData.productItemList = [];
      }
      var imgs = that.uploadImgs;
      if (imgs.length > 0) {
        var arr = [];
        imgs.forEach(function (item) {
          arr.push(item.src);
        });
        that.addData.fileUrls = arr.join(',');
      } else {
        that.addData.fileUrls = '';
      }
      that.loading = true;
      that.dsate = true;
      (0, _instock.updateQcData)(that.addData).then(function (res) {
        if (res.data.code == 200) {
          that.loading = false;
          that.dsate = false;
          that.$message({
            type: 'success',
            message: '成功'
          });
          that.getList();
          that.dialogVisible = false;
        } else {
          that.$message.error(res.data.msg);
          that.loading = false;
          that.dsate = false;
          return;
        }
      }).catch(function () {
        that.loading = false;
        that.dsate = false;
      });
    },
    // 查看数码
    scanCode: function scanCode() {
      var that = this;
      that.scanCodeVisible = true;
      that.getCodeDetails();
    },
    getCodeDetails: function getCodeDetails() {
      var that = this;
      var param = {};
      param.id = that.orderId;
      param.code = that.codeParam.code;
      param.productId = that.codeParam.productId;
      param.pageNo = that.codeParam.pageNo;
      param.pageSize = 10;
      (0, _instock.getCodeDetail)(param).then(function (res) {
        if (res.data.code == 200) {
          that.codeData = res.data.data.records;
          that.total2 = parseInt(res.data.data.total);
        } else {
          that.codeData = [];
          that.total2 = 0;
        }
      });
    },
    // 查看数码搜索
    searchCode: function searchCode() {
      var that = this;
      that.codeParam.pageNo = 1;
      that.getCodeDetails();
    },
    searchReset: function searchReset() {
      that.codeParam.id = '';
      that.codeParam.code = '';
      that.codeParam.productId = '';
      that.codeParam.productName = '';
      that.codeParam.pageNo = 1;
      that.getCodeDetails();
    },
    // json数组去重
    uniqueArray: function uniqueArray(array, key) {
      var result = [array[0]];
      for (var i = 1; i < array.length; i++) {
        var item = array[i];
        var repeat = false;
        for (var j = 0; j < result.length; j++) {
          if (item[key] == result[j][key]) {
            repeat = true;
            break;
          }
        }
        if (!repeat) {
          result.push(item);
        }
      }
      return result;
    },
    // 展开收起搜索条件
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    }
  }
};
exports.default = _default;