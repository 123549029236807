"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _wxgzh = require("@/api/wxhyyy/wxgzh");
var _massSend = require("@/api/wxhyyy/massSend.js");
var _wxHy = require("@/api/wxhyyy/wxHy");
var _admincut = _interopRequireDefault(require("@/components/admincut"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    admincut: _admincut.default
  },
  data: function data() {
    return {
      viplist: [],
      labelDialog: false,
      sourceDialog: false,
      // 标签数据
      labelOptions: [],
      // 选择的标签
      labelOption: [],
      // 来源数据
      sourceOptions: [],
      // 选中的来源
      sourceOption: [],
      sendsName: '',
      sendsType: '',
      // 1.立即发送，2定时发送
      sendsTime: '',
      // 定时发送时间
      tag: '',
      templateId: '',
      bignum: '',
      smallnum: '',
      form: {
        name: '',
        region: '',
        tag: '',
        ditch: '',
        attentiontime: '',
        frequency: '',
        interacttime: '',
        isvip: '',
        integral: '',
        scannum: '',
        gender: '',
        age: ''
      },
      sendsObj: {
        bq: '',
        bqList: '',
        qd: '',
        qdList: '',
        // 关注时间
        gzsj: '',
        gzsjNum: '',
        // 互动频次
        hdpc: '',
        // 互动时间
        hdsj: '',
        hdsjNum: '',
        sfshy: '',
        hyidList: [],
        // 性别
        sex: '',
        age: ''
      },
      // 定时发送时间限制
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < new Date().getTime();
        }
      }
    };
  },
  created: function created() {
    var _this = this;
    var that = this;
    that.getlist();
    (0, _wxHy.searchSelect)().then(function (res) {
      console.log(res);
      if (res.code === 200) {
        _this.viplist = res.data || [];
      } else {
        _this.$message(res.msg);
      }
    });
    (0, _massSend.getAllTag)({
      appId: sessionStorage.getItem('appId')
    }).then(function (resp) {
      var code = resp.code,
        data = resp.data,
        msg = resp.msg;
      if (code === 200) {
        if (data) {
          _this.labelOptions = data;
        }
      } else {
        _this.$message(msg);
      }
    });
    (0, _massSend.getUserCode)({
      codeName: '',
      pageNo: 1,
      pageSize: 100,
      appId: sessionStorage.getItem('appId')
    }).then(function (resp) {
      var code = resp.code,
        data = resp.data,
        msg = resp.msg;
      if (code === 200) {
        if (data) {
          _this.sourceOptions = data.records;
        }
      } else {
        _this.$message(msg);
      }
    });
    that.sendsObj.bq = '0';
    that.sendsObj.ly = '0';
    that.sendsObj.qd = '0';
    that.sendsObj.age = '0';
    that.sendsObj.gzsj = '0';
    that.sendsObj.hdpc = '0';
    that.sendsObj.hdsj = '0';
    that.sendsObj.sex = '0';
    that.sendsObj.sfshy = '0';
  },
  methods: {
    sdname: function sdname(e) {
      var a = e.replace(/\s*/g, '');
      console.log(e);
      this.sendsObj.sendsName = a;
    },
    changsj: function changsj(val) {
      var that = this;
      console.log(val);
      if (val === '0') {
        that.sendsObj.gzsjNum = 0;
        that.bignum = '';
      } else if (val === '1') {
        that.bignum = '';
      } else if (val === '2') {
        that.smallnum = '';
      }
    },
    changemin: function changemin(e) {
      var that = this;
      that.$message.closeAll();
      var reg = /^[0-9]+$/ig;
      if (reg.test(e) && e > 0 && e < 13) {
        that.sendsObj.gzsjNum = e;
      } else {
        that.$message.info('请输入正确时间');
      }
      // console.log(a)
    },
    changemax: function changemax(e) {
      var that = this;
      that.$message.closeAll();
      var reg = /^[0-9]+$/ig;
      if (reg.test(e) && e > 0 && e < 13) {
        that.sendsObj.gzsjNum = e;
      } else {
        that.$message.info('请输入正确时间');
      }
    },
    // 关闭标签来源弹窗
    closedDialog: function closedDialog(name, type) {
      if (type === 1) {
        if (name === 'no') {
          this.sendsObj.bqList = [];
          this.labelOption = [];
          this.labelDialog = false;
          this.form.tag = '0';
        } else if (name === 'yes') {
          if (this.sendsObj.bqList.length > 0) {
            this.labelDialog = false;
          } else {
            this.$message('请选择');
          }
        }
      } else if (type === 2) {
        if (name === 'no') {
          this.sendsObj.qdList = [];
          this.sourceOption = [];
          this.sourceDialog = false;
          this.form.ditch = '0';
        } else if (name === 'yes') {
          if (this.sendsObj.qdList.length > 0) {
            this.sourceDialog = false;
          } else {
            this.$message('请选择');
          }
        }
      }
    },
    // 标签选择
    labelFun: function labelFun(val) {
      if (val === '1') {
        this.form.tag = '1';
        this.labelDialog = true;
      } else {
        this.sendsObj.bqList = [];
        this.labelOption = [];
      }
    },
    labelCheckFun: function labelCheckFun(val) {
      var _this2 = this;
      var arr = [];
      val.map(function (key, index) {
        _this2.labelOptions.map(function (item) {
          if (key === item.tagName) {
            arr.push(item.tagId);
          }
        });
      });
      this.sendsObj.bqList = arr;
    },
    // 来源选择
    checkboxFun: function checkboxFun(val) {
      var _this3 = this;
      var arr = [];
      val.map(function (key, index) {
        _this3.sourceOptions.map(function (item) {
          console.log(item);
          if (key === item.codeName) {
            arr.push(item.id);
          }
        });
      });
      this.sendsObj.qdList = arr;
    },
    sourceFun: function sourceFun(val) {
      if (val === '1') {
        this.form.ditch = '1';
        this.sourceDialog = true;
      } else {
        this.sendsObj.qdList = [];
        this.sourceOption = [];
      }
    },
    onSubmit: function onSubmit() {
      var that = this;
      // if()
      console.log(that.sendsObj);
      if (that.sendsName !== '' && (that.sendsType === '1' || that.sendsType === '2' && that.sendsTime !== '') && that.templateId !== '' && (that.sendsObj.bq === '0' || that.sendsObj.bq === '1' && that.sendsObj.bqList.length > 0) && (that.sendsObj.qd === '0' || that.sendsObj.qd === '1' && that.sendsObj.qdList.length > 0) && (that.sendsObj.gzsj === '0' || that.sendsObj.gzsj !== '0' && that.sendsObj.gzsjNum !== '') && that.sendsObj.hdpc !== '' && (that.sendsObj.hdsj !== '' && that.sendsObj.hdsj < 6 || that.sendsObj.hdsj === '6' && that.sendsObj.hdsjNum !== '') && (that.sendsObj.sfshy === '0' || that.sendsObj.sfshy === '1' || that.sendsObj.sfshy === '2' && that.sendsObj.hyidList.lenght > 0) && that.sendsObj.sex !== '' && that.sendsObj.age !== '') {
        var n = {
          sendsName: that.sendsName,
          sendsType: that.sendsType,
          sendsTime: that.sendsTime,
          templateId: that.templateId,
          sendsObj: JSON.stringify(that.sendsObj),
          appId: sessionStorage.getItem('appId')
        };
        (0, _wxgzh.createSend)(n).then(function (res) {
          if (res.code === 200) {
            that.$message.success('发送成功');
            that.$router.push({
              name: 'temMsgg',
              params: {
                ac: '2'
              }
            });
          } else {
            that.$message.warning(res.msg);
          }
        });
      } else {
        that.$message.closeAll();
        if (that.sendsName === '') {
          that.$message.error('请填写群发名称');
          return;
        }
        if (that.sendsType === '' || that.sendsType === '2' && that.sendsTime === '') {
          that.$message.error('请填写发送时间');
          return;
        }
        if (that.templateId === '') {
          that.$message.error('请选择发送内容');
          return;
        }
        if (that.sendsObj.bq === '' || that.sendsObj.bq === '1' && that.sendsObj.bqList.length === 0) {
          that.$message.error('请选择标签');
          return;
        }
        if (that.sendsObj.qd === '' || that.sendsObj.qd === '1' && that.sendsObj.qdList.length === 0) {
          that.$message.error('请选择渠道');
          return;
        }
        if (that.sendsObj.gzsj === '' || that.sendsObj.gzsj > 0 && that.sendsObj.gzsjNum === '') {
          that.$message.error('请定义关注时间');
          return;
        }
        if (that.sendsObj.hdpc === '') {
          that.$message.error('请选择频次');
          return;
        }
        if (that.sendsObj.hdsj === '' || that.sendsObj.hdsj === '6' && that.sendsObj.hdsjNum === '') {
          that.$message.error('请定义互动时间');
          return;
        }
        if (that.sendsObj.sfshy === '' || that.sendsObj.sfshy === '2' && that.sendsObj.hyidList.lenght === 0) {
          that.$message.error('请选择是否会员');
          return;
        }
        if (that.sendsObj.sex === '') {
          that.$message.error('请选择性别');
          return;
        }
        if (that.sendsObj.age === '') {
          that.$message.error('请选择年龄');
          return;
        }
      }
    },
    getlist: function getlist() {
      var that = this;
      var a = {
        pageNo: 1,
        pageSize: 10,
        appId: sessionStorage.getItem('appId')
      };
      (0, _wxgzh.moWxTemplate)(a).then(function (res) {
        if (res.code === 200) {
          console.log(res);
          that.tag = res.data.records;
        } else {
          that.$message.info(res.msg);
        }
      });
    },
    hdTimeFun: function hdTimeFun(val) {
      console.log(val);
      if (val !== '7') {
        this.sendsObj.hdsjNum = '';
      }
    },
    handleInput: function handleInput(e) {
      console.log(e);
      var reg = /^[0-9]+$/;
      if (reg.test(e.target.value)) {
        this.sendsObj.hdsjNum = e.target.value;
      } else if (e.target.value.length < this.sendsObj.hdsjNum.length) {
        this.sendsObj.hdsjNum = e.target.value;
      }
      this.$refs.inputss.value = this.sendsObj.hdsjNum;
      console.log(this.sendsObj.hdsjNum);
    }
  }
};
exports.default = _default;