"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "TablePackset",
  components: {
    Pagination: _Pagination.default
  },
  filters: {},
  props: {
    // selectedData: {
    //   type: Array,
    //   default() {
    //     return [];
    //   }
    // },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      selLoading: false,
      listLoading: false,
      tableKey: "111",
      tabHeight: "100%",
      classList: [],
      gradeList: [],
      tableData: [],
      multipleSelection: [],
      total: 0,
      selection: [],
      roleCode: "",
      row: {}
    };
  },
  watch: {},
  mounted: function mounted() {
    this.setsel();
  },
  methods: {
    setsel: function setsel() {
      var _this = this;
      this.getList();
      this.selLoading = true;
      this.$nextTick(function () {
        // this.selectedData.forEach(row => {
        //   this.$refs.multipleTable.toggleRowSelection(row, true); // 回显
        // });
        setTimeout(function () {
          _this.$refs.multipleTable.toggleRowSelection(_this.tableData[0]);
          _this.$refs.multipleTable.toggleRowSelection(_this.tableData[0]);
          _this.selLoading = false;
        }, 500);
      });
    },
    clear: function clear() {
      this.$refs.multipleTable.clearSelection();
    },
    getList: function getList() {
      var that = this;
      that.listLoading = true;
      //   var submitData = filterKeyNull(that.searchForm);
      (0, _user.getUserByRoleCode)({
        roleCode: 'cscs'
      }).then(function (response) {
        that.listLoading = false;
        if (response.data.code != 200) {
          that.$message.error(response.data.msg);
          return;
        } else {
          if (response.data.data != null) {
            that.total = parseInt(response.data.data.total);
            that.tableData = response.data.data;
          } else {
            that.total = 0;
            that.tableData = [];
          }
        }
      }).catch(function (res) {
        that.listLoading = false;
      });
    },
    resetForm: function resetForm(formName) {
      this.getList();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
      if (val.length > 1) {
        this.$refs.multipleTable.clearSelection();
        this.$refs.multipleTable.toggleRowSelection(val[val.length - 1], true);
      }
    },
    selCancel: function selCancel() {
      this.resetForm();
      this.clear();
      this.$emit("close");
    },
    submit: function submit() {
      this.$emit("close");
      this.$emit("changeU", {
        checkPhone: this.multipleSelection[0].phoneNum,
        checkUser: this.multipleSelection[0].userName,
        checkUserId: this.multipleSelection[0].pid
      });
    },
    tag1Close: function tag1Close(row) {
      this.$refs.multipleTable.toggleRowSelection(row, false);
    }
  }
};
exports.default = _default;