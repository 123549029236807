"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.number.constructor");
var _rebate = require("@/api/chanelmange/rebate");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'codeScan',
  components: {
    Pagination: _Pagination.default
  },
  props: {
    codeVisible: Boolean,
    params: Object
  },
  data: function data() {
    return {
      listLoading: false,
      listQuery: {
        current: 1,
        size: 10
      },
      param: {},
      tableData: [],
      total: 0
    };
  },
  watch: {
    codeVisible: function codeVisible(val) {
      var that = this;
      if (val == true) {
        that.tableData = [];
        that.param = that.params;
        that.getList();
      } else {
        that.tableData = [];
      }
    }
  },
  methods: {
    getList: function getList() {
      var _this = this;
      var that = this;
      this.listLoading = true;
      var submitData = deepClone(that.param);
      submitData.current = that.listQuery.current;
      submitData.size = that.listQuery.size;
      (0, _rebate.getScanYearCodes)(submitData).then(function (res) {
        if (res.data.code != 200) {
          that.$message.error(res.data.msg);
        } else {
          if (res.data.data != null) {
            that.tableData = that.setName(res.data.data.records);
            console.log(that.tableData);
            that.total = Number(res.data.data.total);
          } else {
            that.total = 0;
            that.tableData = [];
          }
        }
        _this.listLoading = false;
      });
    },
    handleClose: function handleClose() {
      var that = this;
      that.$emit('close');
    },
    //递归属性结构
    setName: function setName(datas) {
      //遍历树  获取id数组
      var that = this;
      for (var i in datas) {
        datas[i].id = that.getOnlyId();
        if (datas[i].children) {
          that.setName(datas[i].children);
        }
      }
      return datas;
    },
    getOnlyId: function getOnlyId(prefix) {
      return new Date().getTime().toString(36) + Math.random().toString(36).slice(2);
    }
  }
};
exports.default = _default;