"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.fill");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    data: String
  },
  data: function data() {
    return {
      list: {
        pies: []
      }
    };
  },
  mounted: function mounted() {
    var _this = this;
    if (this.data) {
      this.list = JSON.parse(this.data);
      this.$nextTick(function () {
        _this.draw();
      });
    }
  },
  methods: {
    draw: function draw() {
      var bgList = this.list.pies.map(function (item) {
        return item.background;
      });
      var canvas = this.$refs.canvas;
      //设置宽高不从css中设置
      canvas.width = canvas.offsetWidth; //设置canvas宽
      canvas.height = canvas.offsetHeight; //设置canvas高
      //  canvas.style.border = "1px solid red";
      //获取上下文
      var ctx = canvas.getContext("2d");
      //画图
      var x0 = canvas.width / 2,
        y0 = canvas.height / 2; // 300 300 圆心
      var radius = canvas.width / 2; //半径
      var tempAngle = -90; //画圆的起始角度
      for (var i = 0; i < bgList.length; i++) {
        var startAngle = tempAngle * Math.PI / 180; //起始弧度
        var angle = 360 / bgList.length;
        var endAngle = (tempAngle + angle) * Math.PI / 180; //结束弧度
        ctx.beginPath();
        ctx.moveTo(x0, y0);
        ctx.fillStyle = bgList[i] || 'transparent';
        ctx.arc(x0, y0, radius, startAngle, endAngle);
        ctx.fill();
        tempAngle += angle;
      }
    }
  }
};
exports.default = _default;