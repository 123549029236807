var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c(
            "div",
            { staticClass: "page-section" },
            [
              _c(
                "el-form",
                {
                  ref: "listQuery",
                  staticClass: "search-condition",
                  attrs: {
                    model: _vm.listQuery,
                    "status-icon": "",
                    "label-position": _vm.formConfig.labelPosition,
                    "label-width": _vm.formConfig.labelWidth,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cols" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "orderNo",
                                    label: "采购订单号:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: _vm.listQuery.orderNo,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.listQuery, "orderNo", $$v)
                                      },
                                      expression: "listQuery.orderNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "sendOutComId",
                                    label: "发货单位:",
                                  },
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      attrs: { readonly: "" },
                                      model: {
                                        value: _vm.searchSendOutComName,
                                        callback: function ($$v) {
                                          _vm.searchSendOutComName = $$v
                                        },
                                        expression: "searchSendOutComName",
                                      },
                                    },
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          slot: "append",
                                          icon: "el-icon-search",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openCompany(
                                              "searchSendOutComName",
                                              2,
                                              11
                                            )
                                          },
                                        },
                                        slot: "append",
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "receiveComId",
                                    label: "收货单位:",
                                  },
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      attrs: { readonly: "" },
                                      model: {
                                        value: _vm.searchReceiveComName,
                                        callback: function ($$v) {
                                          _vm.searchReceiveComName = $$v
                                        },
                                        expression: "searchReceiveComName",
                                      },
                                    },
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          slot: "append",
                                          icon: "el-icon-search",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openCompany(
                                              "searchReceiveComName",
                                              1,
                                              11
                                            )
                                          },
                                        },
                                        slot: "append",
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.showCol
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "orderStartTime",
                                        label: "订单起始日期:",
                                      },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          type: "date",
                                          format: "yyyy-MM-dd",
                                          "value-format": "yyyy-MM-dd",
                                          placeholder: "请选择日期",
                                        },
                                        model: {
                                          value: _vm.listQuery.beginDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.listQuery,
                                              "beginDate",
                                              $$v
                                            )
                                          },
                                          expression: "listQuery.beginDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showCol
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "orderEndTime",
                                        label: "订单结束日期:",
                                      },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          type: "date",
                                          format: "yyyy-MM-dd",
                                          "value-format": "yyyy-MM-dd",
                                          placeholder: "请选择日期",
                                        },
                                        model: {
                                          value: _vm.listQuery.endDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.listQuery,
                                              "endDate",
                                              $$v
                                            )
                                          },
                                          expression: "listQuery.endDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.showCol || !_vm.formConfig.btnFollow,
                                  expression: "showCol||!formConfig.btnFollow",
                                },
                              ],
                              attrs: { span: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "orderStatus",
                                    label: "状态:",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.listQuery.orderStatus,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.listQuery,
                                            "orderStatus",
                                            $$v
                                          )
                                        },
                                        expression: "listQuery.orderStatus",
                                      },
                                    },
                                    _vm._l(_vm.orderStatus, function (item) {
                                      return _c("el-option", {
                                        key: item.figure,
                                        attrs: {
                                          label: item.name,
                                          value: [item.figure],
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.showCol
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "queryType",
                                        label: "查询类型:",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            clearable: "",
                                            placeholder: "请选择",
                                          },
                                          model: {
                                            value:
                                              _vm.listQuery.storeOrderInfoQuery
                                                .queryType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.listQuery
                                                  .storeOrderInfoQuery,
                                                "queryType",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "listQuery.storeOrderInfoQuery.queryType",
                                          },
                                        },
                                        _vm._l(
                                          _vm.businessOption,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.code,
                                              attrs: {
                                                label: item.name,
                                                value: item.code,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showCol
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "queryContent",
                                        label: "查询内容:",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "请选择",
                                          clearable: "",
                                        },
                                        on: { focus: _vm.checkQueryType },
                                        model: {
                                          value:
                                            _vm.listQuery.storeOrderInfoQuery
                                              .queryContent,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.listQuery.storeOrderInfoQuery,
                                              "queryContent",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "listQuery.storeOrderInfoQuery.queryContent",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.formConfig.btnFollow
                            ? _c(
                                "el-col",
                                {
                                  staticClass: "head-btn-group",
                                  staticStyle: {
                                    width: "240px",
                                    "padding-left": "0",
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { "label-width": "0" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v(" ")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.searchBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.searchBtn.icon,
                                          },
                                          on: { click: _vm.DoSearch },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.searchBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("el-divider", {
                                        attrs: { direction: "vertical" },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: _vm.btnConfig.resetBtn.type,
                                            size: _vm.btnConfig.size,
                                            icon: _vm.btnConfig.resetBtn.icon,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.DoReset(_vm.listQuery)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.btnConfig.resetBtn.text)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("el-divider", {
                                        attrs: { direction: "vertical" },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: { click: _vm.expand },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(_vm.expandTxt) +
                                              "\n                "
                                          ),
                                          _c("i", {
                                            class:
                                              _vm.showCol == false
                                                ? "el-icon-arrow-down"
                                                : "el-icon-arrow-up",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.formConfig.btnFollow
                ? _c("el-divider", { staticClass: "btnDivider" })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btnArea" },
                [
                  !_vm.formConfig.btnFollow
                    ? _c(
                        "el-col",
                        {
                          staticClass: "head-btn-group",
                          staticStyle: { width: "230px" },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.searchBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.searchBtn.icon,
                              },
                              on: { click: _vm.DoSearch },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.searchBtn.text))]
                          ),
                          _vm._v(" "),
                          _c("el-divider", {
                            attrs: { direction: "vertical" },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.resetBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.resetBtn.icon,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.DoReset(_vm.listQuery)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.resetBtn.text))]
                          ),
                          _vm._v(" "),
                          _c("el-divider", {
                            attrs: { direction: "vertical" },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: { click: _vm.expand },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.expandTxt) +
                                  "\n          "
                              ),
                              _c("i", {
                                class:
                                  _vm.showCol == false
                                    ? "el-icon-arrow-down"
                                    : "el-icon-arrow-up",
                              }),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAuth("addbtn", _vm.$route.meta.authList)
                    ? _c(
                        "div",
                        { staticClass: "head-btn-group fun-btn-group" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.btnConfig.addBtn.type,
                                size: _vm.btnConfig.size,
                                icon: _vm.btnConfig.addBtn.icon,
                              },
                              on: { click: _vm.DoAdd },
                            },
                            [_vm._v(_vm._s(_vm.btnConfig.addBtn.text))]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  key: _vm.tableKey,
                  staticStyle: { width: "100%" },
                  attrs: {
                    height: _vm.tabHeight,
                    data: _vm.list,
                    border: _vm.tableConfig.border,
                    stripe: _vm.tableConfig.stripe,
                    fit: "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "序号",
                      width: "120",
                      align: _vm.tableConfig.align,
                      type: "index",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "采购订单号",
                      "min-width": "160",
                      prop: "orderNo",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm.hasAuth("detailbtn", _vm.$route.meta.authList)
                                ? _c(
                                    "el-link",
                                    {
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.DoScan(row)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(row.orderNo))]
                                  )
                                : _c("div", [_vm._v(_vm._s(row.orderNo))]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      758945116
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "采购单位",
                      "min-width": "120",
                      prop: "purchaseCom",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "发货单位",
                      "min-width": "120",
                      prop: "sendOutCom",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "收货单位",
                      "min-width": "120",
                      prop: "receiveCom",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "付款方式",
                      "min-width": "90",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                _vm._s(_vm._f("payWayText")(row.paymentStyle))
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3711130961
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "状态",
                      "min-width": "120",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm._f("orderStatusText")(row.orderStatus)
                                )
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3551039611
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "申请人",
                      "min-width": "90",
                      prop: "applyUser",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "创建人",
                      "min-width": "110",
                      prop: "creatorUser",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "下单日期",
                      "min-width": "110",
                      prop: "placeOrderDate",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "备注",
                      "min-width": "110",
                      prop: "remark",
                      align: _vm.tableConfig.align,
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _vm.hasAuth("updatebtn", _vm.$route.meta.authList)
                    ? _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          align: _vm.tableConfig.align,
                          width: "100",
                          fixed: "right",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "text",
                                        disabled: row.orderStatus != 1,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.DoEdit(row)
                                        },
                                      },
                                    },
                                    [_vm._v("修改")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          318432902
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                  page: _vm.listQuery.current,
                  limit: _vm.listQuery.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "size", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAuth("searchbtn", _vm.$route.meta.authList)
        ? _c("section", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                "close-on-click-modal": false,
                title: _vm.title,
                visible: _vm.dialogVisible,
                "before-close": _vm.handleClose,
                width: "1200px !important",
                "append-to-body": true,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                },
                [
                  _c("div", { staticClass: "title" }, [_vm._v("采购信息")]),
                  _vm._v(" "),
                  _c(
                    "el-form",
                    {
                      ref: "addForm",
                      attrs: {
                        model: _vm.addData,
                        "label-width": "90px",
                        rules: _vm.rules,
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "采购订单号:",
                                    prop: "orderNo",
                                  },
                                },
                                [
                                  _vm.subType == "scan" ||
                                  _vm.addData.orderStatus != 1
                                    ? _c("span", [
                                        _vm._v(_vm._s(_vm.addData.orderNo)),
                                      ])
                                    : _c("el-input", {
                                        attrs: { disabled: true },
                                        model: {
                                          value: _vm.addData.orderNo,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.addData,
                                              "orderNo",
                                              $$v
                                            )
                                          },
                                          expression: "addData.orderNo",
                                        },
                                      }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "采购单位:",
                                    prop: "purchaseComId",
                                  },
                                },
                                [
                                  _vm.subType == "scan" ||
                                  _vm.addData.orderStatus != 1
                                    ? _c("span", [
                                        _vm._v(_vm._s(_vm.purchaseComName)),
                                      ])
                                    : _c(
                                        "el-input",
                                        {
                                          attrs: { readonly: true },
                                          model: {
                                            value: _vm.purchaseComName,
                                            callback: function ($$v) {
                                              _vm.purchaseComName = $$v
                                            },
                                            expression: "purchaseComName",
                                          },
                                        },
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              slot: "append",
                                              icon: "el-icon-search",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.openCompany(
                                                  "purchaseComName",
                                                  0,
                                                  11
                                                )
                                              },
                                            },
                                            slot: "append",
                                          }),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "付款方式:",
                                    prop: "paymentStyle",
                                  },
                                },
                                [
                                  _vm.subType == "scan" ||
                                  _vm.addData.orderStatus != 1
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("payWayText")(
                                              _vm.addData.paymentStyle
                                            )
                                          )
                                        ),
                                      ])
                                    : _c(
                                        "el-select",
                                        {
                                          attrs: { placeholder: "请选择" },
                                          model: {
                                            value: _vm.addData.paymentStyle,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.addData,
                                                "paymentStyle",
                                                $$v
                                              )
                                            },
                                            expression: "addData.paymentStyle",
                                          },
                                        },
                                        _vm._l(_vm.payWay, function (item) {
                                          return _c("el-option", {
                                            key: item.code,
                                            attrs: {
                                              label: item.name,
                                              value: item.code,
                                            },
                                          })
                                        }),
                                        1
                                      ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "订单日期:",
                                    prop: "placeOrderDate",
                                  },
                                },
                                [
                                  _vm.subType == "scan" ||
                                  _vm.addData.orderStatus != 1
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.addData.placeOrderDate)
                                        ),
                                      ])
                                    : _c("el-date-picker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          type: "date",
                                          format: "yyyy-MM-dd",
                                          "value-format": "yyyy-MM-dd",
                                          placeholder: "请选择日期",
                                        },
                                        model: {
                                          value: _vm.addData.placeOrderDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.addData,
                                              "placeOrderDate",
                                              $$v
                                            )
                                          },
                                          expression: "addData.placeOrderDate",
                                        },
                                      }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "申请人:",
                                    prop: "applyUser",
                                  },
                                },
                                [
                                  _vm.subType == "scan" ||
                                  _vm.addData.orderStatus != 1
                                    ? _c("span", [
                                        _vm._v(_vm._s(_vm.addData.applyUser)),
                                      ])
                                    : _c("el-input", {
                                        attrs: { maxlength: 30 },
                                        model: {
                                          value: _vm.addData.applyUser,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.addData,
                                              "applyUser",
                                              $$v
                                            )
                                          },
                                          expression: "addData.applyUser",
                                        },
                                      }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "title" }, [_vm._v("收货信息")]),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "收货单位:",
                                    prop: "receiveComId",
                                  },
                                },
                                [
                                  _vm.subType == "scan" ||
                                  _vm.addData.orderStatus != 1
                                    ? _c("span", [
                                        _vm._v(_vm._s(_vm.receiveComName)),
                                      ])
                                    : _c("el-input", {
                                        attrs: { readonly: "" },
                                        model: {
                                          value: _vm.receiveComName,
                                          callback: function ($$v) {
                                            _vm.receiveComName = $$v
                                          },
                                          expression: "receiveComName",
                                        },
                                      }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "联系人:",
                                    prop: "receiveContacts",
                                  },
                                },
                                [
                                  _vm.subType == "scan" ||
                                  _vm.addData.orderStatus != 1
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.addData.receiveContacts)
                                        ),
                                      ])
                                    : _c("el-input", {
                                        directives: [
                                          {
                                            name: "loading",
                                            rawName: "v-loading",
                                            value: _vm.receiveLoading,
                                            expression: "receiveLoading",
                                          },
                                        ],
                                        attrs: { readonly: "" },
                                        model: {
                                          value: _vm.addData.receiveContacts,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.addData,
                                              "receiveContacts",
                                              $$v
                                            )
                                          },
                                          expression: "addData.receiveContacts",
                                        },
                                      }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "联系人电话:",
                                    prop: "receivePhone",
                                  },
                                },
                                [
                                  _vm.subType == "scan" ||
                                  _vm.addData.orderStatus != 1
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.addData.receivePhone)
                                        ),
                                      ])
                                    : _c("el-input", {
                                        directives: [
                                          {
                                            name: "loading",
                                            rawName: "v-loading",
                                            value: _vm.receiveLoading,
                                            expression: "receiveLoading",
                                          },
                                        ],
                                        attrs: { readonly: "" },
                                        model: {
                                          value: _vm.addData.receivePhone,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.addData,
                                              "receivePhone",
                                              $$v
                                            )
                                          },
                                          expression: "addData.receivePhone",
                                        },
                                      }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "收货地址:",
                                    prop: "receiveAddress",
                                  },
                                },
                                [
                                  _vm.subType == "scan" ||
                                  _vm.addData.orderStatus != 1
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.addData.receiveAddress)
                                        ),
                                      ])
                                    : _c("el-input", {
                                        directives: [
                                          {
                                            name: "loading",
                                            rawName: "v-loading",
                                            value: _vm.receiveLoading,
                                            expression: "receiveLoading",
                                          },
                                        ],
                                        attrs: { readonly: "" },
                                        model: {
                                          value: _vm.addData.receiveAddress,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.addData,
                                              "receiveAddress",
                                              $$v
                                            )
                                          },
                                          expression: "addData.receiveAddress",
                                        },
                                      }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "title" }, [_vm._v("供货信息")]),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "发货单位:",
                                    prop: "sendOutComId",
                                  },
                                },
                                [
                                  _vm.subType == "scan" ||
                                  _vm.addData.orderStatus != 1
                                    ? _c("span", [
                                        _vm._v(_vm._s(_vm.sendOutComName)),
                                      ])
                                    : _c(
                                        "el-input",
                                        {
                                          attrs: { readonly: "" },
                                          model: {
                                            value: _vm.sendOutComName,
                                            callback: function ($$v) {
                                              _vm.sendOutComName = $$v
                                            },
                                            expression: "sendOutComName",
                                          },
                                        },
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              slot: "append",
                                              icon: "el-icon-search",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.openCompany(
                                                  "sendOutComName",
                                                  2,
                                                  11
                                                )
                                              },
                                            },
                                            slot: "append",
                                          }),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "title" },
                    [
                      _c("span", { staticStyle: { "margin-right": "20px" } }, [
                        _vm._v("产品明细"),
                      ]),
                      _vm._v(" "),
                      _vm.subType != "scan" && _vm.addData.orderStatus == 1
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "mini" },
                              on: { click: _vm.openPro },
                            },
                            [_vm._v("添加产品")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        "max-height": "300",
                        data: _vm.productList,
                        border: _vm.tableConfig.border,
                        stripe: _vm.tableConfig.stripe,
                        fit: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "序号",
                          type: "index",
                          align: _vm.tableConfig.align,
                          width: "120",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "产品编码",
                          align: _vm.tableConfig.align,
                          prop: "productCode",
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "产品名称",
                          align: _vm.tableConfig.align,
                          prop: "productName",
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "规格",
                          align: _vm.tableConfig.align,
                          prop: "productModel",
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "包装比例",
                          align: _vm.tableConfig.align,
                          prop: "packScaleExpression",
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "箱数",
                          align: _vm.tableConfig.align,
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c("el-input-number", {
                                    staticStyle: { width: "110px" },
                                    attrs: {
                                      controls: false,
                                      precision: 0,
                                      min: 0,
                                      max: 99999999,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeChestNum(row)
                                      },
                                    },
                                    model: {
                                      value: row.chestNum,
                                      callback: function ($$v) {
                                        _vm.$set(row, "chestNum", $$v)
                                      },
                                      expression: "row.chestNum",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3987246532
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "盒数",
                          align: _vm.tableConfig.align,
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c("el-input-number", {
                                    staticStyle: { width: "110px" },
                                    attrs: {
                                      controls: false,
                                      precision: 0,
                                      min: 0,
                                      max: _vm.mixNumP(row),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeChestNum(row)
                                      },
                                    },
                                    model: {
                                      value: row.bottleNumOver,
                                      callback: function ($$v) {
                                        _vm.$set(row, "bottleNumOver", $$v)
                                      },
                                      expression: "row.bottleNumOver",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1336253910
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "总盒数",
                          prop: "bottleNum",
                          align: _vm.tableConfig.align,
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "单价(元/盒)",
                          align: _vm.tableConfig.align,
                          prop: "productPrice",
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "金额",
                          align: _vm.tableConfig.align,
                          prop: "packScaleDesc",
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [_vm._v(_vm._s(_vm.totalPrice(row)))]
                              },
                            },
                          ],
                          null,
                          false,
                          145974175
                        ),
                      }),
                      _vm._v(" "),
                      _vm.subType != "scan" && _vm.addData.orderStatus == 1
                        ? _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              fixed: "right",
                              align: _vm.tableConfig.align,
                              width: "140",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.DoSc(row)
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2104368588
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "countWrap" }, [
                    _c("div", { staticStyle: { "min-width": "250px" } }, [
                      _vm._v("总金额：" + _vm._s(_vm.totalMoney)),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "大写金额：" +
                          _vm._s(_vm._f("chnMoney")(_vm.totalMoney))
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "title" }, [_vm._v("备注")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { padding: "0 0 15px 0" } },
                    [
                      _vm.subType == "scan" || _vm.addData.orderStatus != 1
                        ? _c("span", [_vm._v(_vm._s(_vm.addData.remark))])
                        : _c("el-input", {
                            attrs: {
                              type: "textarea",
                              maxlength: "100",
                              "show-word-limit": "",
                              placeholder: "请输入",
                              autosize: { minRows: 4, maxRows: 4 },
                            },
                            model: {
                              value: _vm.addData.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.addData, "remark", $$v)
                              },
                              expression: "addData.remark",
                            },
                          }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "title" }, [_vm._v("状态")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { padding: "0 0 15px 0" } },
                    [
                      _vm.subType == "scan"
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("orderStatusText")(
                                  _vm.addData.orderStatus
                                )
                              )
                            ),
                          ])
                        : _c(
                            "el-select",
                            {
                              attrs: { disabled: true },
                              model: {
                                value: _vm.addData.orderStatus,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addData, "orderStatus", $$v)
                                },
                                expression: "addData.orderStatus",
                              },
                            },
                            _vm._l(_vm.orderStatus, function (item) {
                              return _c("el-option", {
                                key: item.figure,
                                attrs: {
                                  label: item.name,
                                  value: item.figure * 1,
                                },
                              })
                            }),
                            1
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _vm.subType != "scan"
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm("2")
                            },
                          },
                        },
                        [_vm._v("提交")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "default", plain: "" },
                      on: { click: _vm.handleClose },
                    },
                    [_vm._v("关闭")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.detailShow
        ? _c(
            "el-dialog",
            {
              attrs: {
                "close-on-click-modal": false,
                title: _vm.title,
                visible: _vm.detailShow,
                "before-close": _vm.detailClose,
                width: "1200px !important",
                "append-to-body": true,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.detailShow = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                },
                [
                  _c("div", { staticClass: "itemTitle" }, [_vm._v("采购信息")]),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("采购订单号:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.addData.orderNo)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("采购单位:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.purchaseComName)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("付款方式:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("payWayText")(_vm.addData.paymentStyle)
                              )
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("订单日期:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.addData.placeOrderDate)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("申请人:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.addData.applyUser)),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "middleLine" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "itemTitle" }, [_vm._v("收货信息")]),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("收货单位:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.receiveComName)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("联系人:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.addData.receiveContacts)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("联系人电话:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.addData.receivePhone)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("收货地址:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.addData.receiveAddress)),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "middleLine" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "itemTitle" }, [_vm._v("供货信息")]),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 8 } }, [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _c("div", { staticClass: "form-div" }, [
                            _vm._v("发货单位:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.sendOutComName)),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "middleLine" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "itemTitle" }, [
                    _vm._v("\n        产品信息\n        "),
                    _c("img", { attrs: { src: require("@/images/pro.png") } }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      staticClass: "detailTable",
                      staticStyle: { width: "100%" },
                      attrs: {
                        "max-height": "300",
                        data: _vm.productList,
                        fit: "",
                        border: _vm.tableConfig.border,
                        stripe: _vm.tableConfig.stripe,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "序号",
                          type: "index",
                          align: _vm.tableConfig.align,
                          width: "120",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "产品编码",
                          align: _vm.tableConfig.align,
                          prop: "productCode",
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "产品名称",
                          align: _vm.tableConfig.align,
                          prop: "productName",
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "规格",
                          align: _vm.tableConfig.align,
                          prop: "productModel",
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "包装比例",
                          align: _vm.tableConfig.align,
                          prop: "packScaleExpression",
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "箱数",
                          align: _vm.tableConfig.align,
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm.subType == "scan" ||
                                  _vm.addData.orderStatus != 1
                                    ? _c("span", [_vm._v(_vm._s(row.chestNum))])
                                    : _c(
                                        "div",
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              value: row.chestNum,
                                              maxlength: "9",
                                            },
                                            on: {
                                              input: function (e) {
                                                return (row.chestNum =
                                                  _vm.valENnum(e))
                                              },
                                              change: function ($event) {
                                                return _vm.changeChestNum(
                                                  _vm.$el,
                                                  row
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          902634666
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "盒数",
                          align: _vm.tableConfig.align,
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c("span", [_vm._v(_vm._s(row.bottleNum))]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2664111862
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "单价(元/盒)",
                          align: _vm.tableConfig.align,
                          prop: "packScaleDesc",
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm.subType == "scan" ||
                                  _vm.addData.orderStatus != 1
                                    ? _c("span", [
                                        _vm._v(_vm._s(row.productPrice)),
                                      ])
                                    : _c("el-input", {
                                        directives: [
                                          {
                                            name: "only-number",
                                            rawName: "v-only-number",
                                            value: {
                                              min: 0,
                                              precision: 2,
                                              max: 99999999,
                                              set: row,
                                              name: "productPrice",
                                            },
                                            expression:
                                              "{min:0,precision:2,max:99999999,set: row, name:'productPrice'}",
                                          },
                                        ],
                                        staticStyle: { "text-align": "right" },
                                        on: { change: _vm.resetList },
                                        model: {
                                          value: row.productPrice,
                                          callback: function ($$v) {
                                            _vm.$set(row, "productPrice", $$v)
                                          },
                                          expression: "row.productPrice",
                                        },
                                      }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3422519173
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "金额",
                          align: _vm.tableConfig.align,
                          prop: "packScaleDesc",
                          width: "140",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _vm._v(
                                    _vm._s(
                                      row.totalPrice == 0 ? "-" : row.totalPrice
                                    )
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3699155587
                        ),
                      }),
                      _vm._v(" "),
                      _vm.subType != "scan" && _vm.addData.orderStatus == 1
                        ? _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              fixed: "right",
                              align: _vm.tableConfig.align,
                              width: "140",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.DoSc(row)
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2104368588
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "countWrap" }, [
                    _c(
                      "div",
                      { staticStyle: { "min-width": "250px" } },
                      [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "赠品产品及酒劵兑换产品不计金额",
                              placement: "bottom-start",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "tipBtn",
                                attrs: { type: "text" },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-info tipIcon",
                                }),
                                _vm._v(
                                  "\n              总金额：\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(
                          "\n          " + _vm._s(_vm.totalMoney) + "\n        "
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "大写金额：" +
                          _vm._s(_vm._f("chnMoney")(_vm.totalMoney))
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "middleLine" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "itemTitle" }, [_vm._v("备注")]),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c("el-col", [
                        _c("div", { staticClass: "vue-from-block" }, [
                          _vm.subType == "scan" || _vm.addData.orderStatus != 1
                            ? _c("span", [_vm._v(_vm._s(_vm.addData.remark))])
                            : _vm._e(),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "middleLine" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "itemTitle" }, [_vm._v("状态")]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { padding: "0 0 15px 0" } }, [
                    _vm.subType == "scan"
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("orderStatusText")(_vm.addData.orderStatus)
                            )
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c("el-button", { on: { click: _vm.detailClose } }, [
                    _vm._v("关闭"),
                  ]),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.factoryShow
        ? _c("factorySelectDialog", {
            attrs: {
              visible: _vm.factoryShow,
              "can-change": _vm.factorycanChange,
              "company-type": _vm.factoryType,
              "is-enable": _vm.isEnable,
            },
            on: { change: _vm.setFactory, close: _vm.factoryClose },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.companyShow
        ? _c("companySelectDialog", {
            attrs: {
              visible: _vm.companyShow,
              "in-out-type": _vm.inOutType,
              "company-type": _vm.companyType,
            },
            on: { change: _vm.setCompany, close: _vm.companyClose },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.productShow
        ? _c("product-dialog", {
            ref: "selProduct",
            attrs: {
              "is-code": "1",
              "pro-sel-visible": _vm.productShow,
              data: _vm.productList,
            },
            on: {
              "update:data": function ($event) {
                _vm.productList = $event
              },
              change: _vm.setPro,
              close: _vm.proClose,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }