"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _expel = require("@/api/fake_expel/expel/expel");
var _waves = _interopRequireDefault(require("@/directive/waves"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'ComplexTable',
  components: {
    Pagination: _Pagination.default
  },
  directives: {
    waves: _waves.default
  },
  filters: {},
  data: function data() {
    return {
      tableKey: 0,
      total: 0,
      excelOut_loading: false,
      listLoading: false,
      list: null,
      temp: {},
      dialogDigitalVisible: false,
      data: {
        handlerResult: 1
      },
      listQuery: {
        pageNo: 1,
        pageSize: 10,
        endTime: '',
        ppwlList: [],
        province: '',
        startTime: ''
      },
      Column: {
        gueneShow: true
      },
      rules: {}
    };
  },
  watch: {},
  created: function created() {},
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _expel.queryMapWlChanList)(this.listQuery).then(function (response) {
        var data = response.data;
        _this.listLoading = false;
        _this.list = [];
        _this.total = 0;
        if (data.code != 200) {
          _this.$message.error(data.msg);
          return true;
        }
        _this.list = data.data.records;
        _this.total = Number(data.data.total);
      });
    },
    excelQueryMapWlChanList: function excelQueryMapWlChanList() {
      var _this2 = this;
      this.excelOut_loading = true;
      this.authId('exportbtn', this.$route.meta.authList);
      (0, _expel.excelQueryMapWlChanList)(this.listQuery).then(function (res) {
        _this2.authId('searchbtn', _this2.$route.meta.authList);
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = _this2.listQuery.province + '产品疑似窜货统计个数明细.xlsx';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
        _this2.excelOut_loading = false;
      }).catch(function () {
        _this2.authId('searchbtn', _this2.$route.meta.authList);
        _this2.excelOut_loading = false;
      });
    },
    Reset: function Reset() {
      this.dialogDigitalVisible = false;
    }
  }
};
exports.default = _default;