"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev-test/web-test/baiyunbian/web-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _stockscan = require("@/api/chanelmange/stockscan");
var _rebate = require("@/api/chanelmange/rebate");
var _purchaseDialog = _interopRequireDefault(require("@/components/purchaseDialog"));
var _selectHdFn = _interopRequireDefault(require("./selectHdFn"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _utils = require("@/utils");
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'tgdan',
  components: {
    Pagination: _Pagination.default,
    Treeselect: _vueTreeselect.default,
    instockUnit: _purchaseDialog.default,
    selectHdFn: _selectHdFn.default
  },
  props: {
    detailVisible: Boolean,
    channelId: String,
    moneyType: String
  },
  watch: {
    detailVisible: function detailVisible(val) {
      var that = this;
      if (val) {
        that.listQuery.channelId = that.channelId;
        that.listQuery.moneyType = Number(that.moneyType);
        that.getOrg();
        that.getList();
      }
    }
  },
  data: function data() {
    return {
      listQuery: {
        channelId: null,
        current: 1,
        endTime: null,
        inOrOut: null,
        moneyType: null,
        orgId: null,
        rebateConfigId: null,
        rebateType: null,
        size: 20,
        startTime: null,
        supplierId: null,
        supplierType: null
      },
      dataValue: [],
      channelName: '',
      supplierName: '',
      rebateConfigName: '',
      orgOptions: [],
      productName: '',
      productShow: false,
      unitShow: false,
      tableKey: 'hdflrecord',
      tabHeight: '100%',
      list: [],
      listLoading: false,
      total: 0,
      showCol: false,
      expandTxt: '展开',
      moneyTypes: [{
        name: '现金',
        id: 101
      }, {
        name: '酒券',
        id: 102
      }],
      inOrOuts: [{
        name: '收入',
        id: 1
      }, {
        name: '支出',
        id: 2
      }],
      rebateTypes: [{
        name: '分润入账',
        id: 1
      }, {
        name: '退货扣除',
        id: 2
      }, {
        name: '申请兑付',
        id: 3
      }]
    };
  },
  mounted: function mounted() {
    var that = this;
    var h = (0, _utils.tableHeight)();
    that.$nextTick(function () {
      that.tabHeight = h;
    });
  },
  filters: {
    formatTime: function formatTime(value) {
      if (value == '' || value == null) {
        return '';
      } else {
        return (0, _utils.parseTime)(value, '{y}-{m}-{d}');
      }
    }
  },
  methods: {
    getList: function getList() {
      var that = this;
      that.listLoading = true;
      (0, _rebate.getRebateSurplusFlowDetail)(that.listQuery).then(function (res) {
        if (res.data.code == 200) {
          that.list = res.data.data.records;
          that.total = parseInt(res.data.data.total);
          that.listLoading = false;
        } else {
          that.$message.error(res.data.msg);
          that.listLoading = false;
          return;
        }
      }).catch(function () {
        that.listLoading = false;
      });
    },
    DoSearch: function DoSearch() {
      var that = this;
      that.listQuery.current = 1;
      that.getList();
      if (that.listQuery.orgId == '') {
        that.listQuery.orgId = null;
      }
    },
    DoReset: function DoReset(formName) {
      var that = this;
      that.listQuery = {
        channelId: that.channelId,
        current: 1,
        endTime: null,
        inOrOut: null,
        moneyType: Number(that.moneyType),
        orgId: null,
        rebateConfigId: null,
        rebateType: null,
        size: 20,
        startTime: null,
        supplierId: null,
        supplierType: null
      };
      that.channelName = '';
      that.supplierName = '';
      that.rebateConfigName = '';
      that.listQuery.current = 1;
      that.getList();
    },
    getOrg: function getOrg() {
      var that = this;
      (0, _stockscan.getQueryOrg)().then(function (res) {
        if (res.data.code == 200) {
          var rData = res.data.data;
          var arr = [];
          if (rData.length >= 0) {
            rData.forEach(function (item) {
              var obj = {};
              obj.id = item.pid;
              obj.label = item.orgName;
              obj.pid = item.pid;
              obj.parentPid = item.parentPid;
              arr.push(obj);
            });
            that.orgOptions = (0, _utils.transTreeData)(arr, 'pid', 'parentPid', 'children');
          }
        }
      });
    },
    clearJxs: function clearJxs() {
      var that = this;
      that.channelName = '';
      that.listQuery.channelId = '';
    },
    clearGhs: function clearGhs() {
      var that = this;
      that.supplierName = '';
      that.listQuery.supplierId = '';
      that.listQuery.supplierType = null;
    },
    //获取单位
    getUnit: function getUnit(val) {
      var _this = this;
      var that = this;
      that.unitType = val;
      this.$nextTick(function () {
        _this.$refs.channelSelect.proSelVisible = true;
      });
    },
    hideUnit: function hideUnit() {
      var that = this;
      that.unitShow = false;
    },
    getUalue: function getUalue(val) {
      var that = this;
      if (that.unitType == 'out') {
        that.listQuery.channelId = val.pId;
        that.channelName = val.channelName;
      }
      if (that.unitType == 'in') {
        that.listQuery.supplierId = val.pId;
        that.listQuery.supplierType = val.channelType == 22 ? 0 : val.channelType;
        that.supplierName = val.channelName;
      }
      that.unitShow = false;
    },
    //获取产品
    getProductId: function getProductId() {
      var that = this;
      that.productShow = true;
    },
    hideProduct: function hideProduct() {
      var that = this;
      that.productShow = false;
    },
    getProduct: function getProduct(val) {
      var that = this;
      that.listQuery.productId = JSON.parse(val).pId;
      that.productName = JSON.parse(val).productName;
      that.productShow = false;
    },
    //获取活动方案
    clearConfig: function clearConfig() {
      var that = this;
      that.rebateConfigName = '';
      that.listQuery.rebateConfigId = '';
    },
    showConfig: function showConfig() {
      var that = this;
      that.$refs.hdfn.configVisible = true;
    },
    getConfig: function getConfig(val) {
      var that = this;
      that.rebateConfigName = val.schemeName;
      that.listQuery.rebateConfigId = val.pid;
    },
    handleClose: function handleClose() {
      var that = this;
      that.$parent.detailVisible = false;
    },
    //展开收起搜索条件
    expand: function expand() {
      var that = this;
      that.showCol = !that.showCol;
      if (that.showCol) {
        that.expandTxt = '收起';
      } else {
        that.expandTxt = '展开';
      }
    }
  }
};
exports.default = _default;