var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-upload",
    {
      staticClass: "upload-demo",
      attrs: {
        headers: _vm.myHeaders,
        action: _vm.uploadUrl,
        "file-list": _vm.fileList,
        "list-type": "picture",
        accept: ".jpg, .jpeg, .png, .PNG, .JPG, .JPEG",
        "on-error": _vm.handleError,
        "on-change": _vm.selectImg,
        "on-remove": _vm.handleRemove,
        "before-upload": _vm.beforeAvatarUpload,
        "on-success": _vm.handleAvatarSuccess,
        "before-remove": _vm.checkType,
        name: "file",
      },
    },
    [
      _c("el-button", { attrs: { size: "small", type: "primary" } }, [
        _vm._v("点击上传"),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "el-upload__tip", attrs: { slot: "tip" }, slot: "tip" },
        [_vm._v("只能上传jpg/png文件，且不超过500kb （最多上传五张图片）")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }