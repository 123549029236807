var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "page-container" },
    [
      _c("div", { staticClass: "page-section" }, [
        _c(
          "section",
          [
            _c(
              "el-form",
              {
                ref: "searchForm",
                staticClass: "search-condition",
                attrs: {
                  model: _vm.searchForm,
                  "status-icon": "",
                  "label-suffix": ":",
                  "label-position": _vm.formConfig.labelPosition,
                  "label-width": _vm.formConfig.labelWidth,
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "cols" },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "活动ID", prop: "activityId" },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "请选择" },
                                    model: {
                                      value: _vm.searchForm.activityId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "activityId",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.activityId",
                                    },
                                  },
                                  _vm._l(_vm.activityOptions, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.activityName,
                                        value: item.id,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "活动名称",
                                  prop: "activityName",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "请输入" },
                                  model: {
                                    value: _vm.searchForm.activityName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "activityName",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.activityName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "时间范围", prop: "begin" } },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    type: "daterange",
                                    "range-separator": "-",
                                    "start-placeholder": "开始日期",
                                    "end-placeholder": "结束日期",
                                    format: "yyyy-MM-dd",
                                    "value-format": "yyyy-MM-dd HH:mm:ss",
                                  },
                                  on: { change: _vm.dateChangeFun },
                                  model: {
                                    value: _vm.daterange,
                                    callback: function ($$v) {
                                      _vm.daterange = $$v
                                    },
                                    expression: "daterange",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.formConfig.btnFollow
                          ? _c(
                              "el-col",
                              {
                                staticClass: "head-btn-group",
                                style: {
                                  width: _vm.formConfig.btnAreaHasShowMore,
                                },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { "label-width": "0" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "label" },
                                        slot: "label",
                                      },
                                      [_vm._v(" ")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: _vm.btnConfig.searchBtn.type,
                                          size: _vm.btnConfig.size,
                                          icon: _vm.btnConfig.searchBtn.icon,
                                        },
                                        on: { click: _vm.searchFun },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.btnConfig.searchBtn.text)
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: _vm.btnConfig.resetBtn.type,
                                          size: _vm.btnConfig.size,
                                          icon: _vm.btnConfig.resetBtn.icon,
                                        },
                                        on: { click: _vm.resetForm },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.btnConfig.resetBtn.text)
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c("el-divider", { staticClass: "btnDivider" }),
            _vm._v(" "),
            _c("div", { staticClass: "btnArea" }, [
              _c(
                "div",
                { staticClass: "head-btn-group" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: _vm.btnConfig.downLoadBtn.type,
                        size: _vm.btnConfig.size,
                        icon: _vm.btnConfig.downLoadBtn.icon,
                        loading: _vm.exportLoading,
                      },
                      on: { click: _vm.exportExcelFun },
                    },
                    [_vm._v(_vm._s(_vm.btnConfig.downLoadBtn.text))]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                ref: "table",
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.tableData,
                  height: _vm.tabHeight,
                  border: _vm.tableConfig.border,
                  stripe: _vm.tableConfig.stripe,
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    type: "index",
                    label: "序号",
                    align: _vm.tableConfig.align,
                    width: "50",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "markCode",
                    label: "参与数码",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "activityName",
                    label: "参与活动",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "openid",
                    label: "openid",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "productName",
                    label: "活动产品",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "agentName",
                    label: "数码经销商",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "terminalName",
                    label: "数码门店",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "wechatName",
                    label: "扫码用户",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "wechatUserId",
                    label: "用户ID",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c(
                  "el-table-column",
                  {
                    attrs: {
                      prop: "prizeType",
                      label: "奖品形式",
                      align: _vm.tableConfig.align,
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                    },
                  },
                  [_vm._v("\n          " + _vm._s("实物") + "\n        ")]
                ),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "goodsTypeDescr",
                    label: "核销方式",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "winnerName",
                    label: "收件姓名",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "winnerAddress",
                    label: "收件地区",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "winnerAddressDetail",
                    label: "详细地址",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "winnerPhone",
                    label: "收件联系方式",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "prizeName",
                    label: "奖项",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "deliverStatus",
                    label: "发件状态",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          row.sendingStatus == 1
                            ? _c("el-tag", { attrs: { type: "success" } }, [
                                _vm._v("\n              已发货\n            "),
                              ])
                            : _c("el-tag", { attrs: { type: "info" } }, [
                                _vm._v("\n              未发货\n            "),
                              ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "expressTime",
                    label: "发货时间",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm._f("textDate")(row.expressTime)) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "expressCompany",
                    label: "物流公司",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "expressOrder",
                    label: "物流单号",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "receivingStatus",
                    label: "领取状态",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c(
                            "el-tag",
                            {
                              attrs: {
                                type: _vm._f("statusType")(row.receivingStatus),
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm._f("statusStr")(row.receivingStatus)
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "ipAddress",
                    label: "用户IP",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "",
                    label: "参与活动时间",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm._f("textDate")(row.createTime)) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "createTime",
                    label: "中奖时间",
                    align: _vm.tableConfig.align,
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm._f("textDate")(row.createTime)) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "操作",
                    width: "150",
                    align: _vm.tableConfig.align,
                    fixed: "right",
                    "class-name": "small-padding fixed-width",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.showDialogFun("look", row)
                                },
                              },
                            },
                            [_vm._v("查看")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-dropdown",
                            { staticClass: "table-dropdown" },
                            [
                              _c("span", { staticClass: "el-dropdown-link" }, [
                                _vm._v(
                                  "\n                更多\n                "
                                ),
                                _c("i", {
                                  staticClass:
                                    "el-icon-arrow-down el-icon--right",
                                }),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-dropdown-menu",
                                {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown",
                                },
                                [
                                  _c(
                                    "el-dropdown-item",
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "text",
                                            disabled: [1, "1"].includes(
                                              row.sendingStatus
                                            ),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.openLogistics(
                                                row.receiveId
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 物流发货 ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-dropdown-item",
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            disabled: [0].includes(
                                              row.sendingStatus
                                            ),
                                            type: "text",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.openUpdate(
                                                row.receiveId
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("确认收货")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("pagination", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.total > 0,
                  expression: "total > 0",
                },
              ],
              attrs: {
                layout: "total, sizes, prev, pager, next, jumper",
                total: _vm.total,
                page: _vm.pageObj.pageNo,
                limit: _vm.pageObj.pageSize,
              },
              on: {
                "update:page": function ($event) {
                  return _vm.$set(_vm.pageObj, "pageNo", $event)
                },
                "update:limit": function ($event) {
                  return _vm.$set(_vm.pageObj, "pageSize", $event)
                },
                pagination: _vm.getList,
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      false ? _c("section", [_vm._m(0)]) : _vm._e(),
      _vm._v(" "),
      _vm.detailVisible
        ? _c("detail", {
            attrs: {
              type: _vm.insertType,
              "info-row": _vm.infoRow,
              visible: _vm.detailVisible,
            },
            on: {
              close: function ($event) {
                _vm.detailVisible = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "物流信息",
            visible: _vm.insertVisible,
            width: "width",
            "before-close": _vm.logisticsDialogClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.insertVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "logisticsForm",
              attrs: {
                model: _vm.logisticsForm,
                rules: _vm.logisticsRules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "物流公司", prop: "expressCompany" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        "allow-create": "",
                        filterable: "",
                        "default-first-option": "",
                        placeholder: "",
                        rules: _vm.logisticsRules,
                      },
                      model: {
                        value: _vm.logisticsForm.expressCompany,
                        callback: function ($$v) {
                          _vm.$set(_vm.logisticsForm, "expressCompany", $$v)
                        },
                        expression: "logisticsForm.expressCompany",
                      },
                    },
                    _vm._l(_vm.logisticsOptions, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.label, value: item.label },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "物流单号", prop: "expressOrder" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "20" },
                    model: {
                      value: _vm.logisticsForm.expressOrder,
                      callback: function ($$v) {
                        _vm.$set(_vm.logisticsForm, "expressOrder", $$v)
                      },
                      expression: "logisticsForm.expressOrder",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.logisticsDialogClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.logisticsSubmit },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "更新领取状态",
            visible: _vm.updeteVisible,
            width: "width",
            "before-close": _vm.updeteDialogClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.updeteVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "updateForm", attrs: { model: _vm.updateForm } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "status" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.updateForm.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.updateForm, "status", $$v)
                        },
                        expression: "updateForm.status",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "6" } }, [
                        _vm._v("已领取"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "7" } }, [
                        _vm._v("未领取"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.updeteDialogClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.updeteSubmit } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "empty-container" }, [
      _c("img", { attrs: { src: require("@/images/empty.png") } }),
      _vm._v("抱歉您权限不足，无法访问更多内容！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }